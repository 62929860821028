/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  CheckboxGroupWidget,
  LabelWidget,
  setValue,
  DateWidget,
  getValue,
  setData,
  getData,
  disable,
  enable,
  goTo,
  hide,
  hideColumn
} from "../../shared/WindowImports";

import "./SpecialPayablesInquiry.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import {ContractManagementService} from "../../ContractManagement/Service/ContractManagementService"
import {AccountPayment} from "../../AccountPayment/Service/AccountPayment"
import { VendorLookUpValidation } from "../../Common/Constants";
import VendorLookup from "../../ContractManagement/VendorLookup/VendorLookup";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function AccountPayment_SpecialPayablesInquiry(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));

  // START_USER_CODE-USER_PROPERTIES
  var PreviousChkBoxValue = '1'
  var chk1 = ''
  var chk2 = ''
  var beg_cont_date = null
  var end_cont_date = null
  // var Rtp_sw = 'Y'
  // var Pay_sw = ''
  var CHK_NUM = ''
  var RECV_NUM = ''
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "SpecialPayablesInquiry",
    windowName: "SpecialPayablesInquiry",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "AccountPayment.SpecialPayablesInquiry",
    // START_USER_CODE-USER_SpecialPayablesInquiry_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Special Payment Search",
      scrCode: "PN1100H",
    },
    // END_USER_CODE-USER_SpecialPayablesInquiry_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxActionsAndResults",
      Label: "Close",
      ColSpan: "4",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnCreateSpecialPayable: {
      name: "btnCreateSpecialPayable",
      type: "ButtonWidget",
      parent: "grpbxActionsAndResults",
      Label: "Create Special Payable",
      CharWidth: "45",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCreateSpecialPayable_PROPERTIES

      // END_USER_CODE-USER_btnCreateSpecialPayable_PROPERTIES
    },
    btnOpen: {
      name: "btnOpen",
      type: "ButtonWidget",
      parent: "grpbxActionsAndResults",
      Label: "Open",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOpen_PROPERTIES

      // END_USER_CODE-USER_btnOpen_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxActionsAndResults",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxSpcPayableDetails",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    chkboxInclude: {
      name: "chkboxInclude",
      type: "CheckBoxGroupFieldWidget",
      parent: "grpbxSpcPayableDetails",
      Options: "Include Ready To Pay:1,Include Paid:2",
      ColSpan: "5",
      ColSpanForLargeDesktop: "5",
      ColSpanForTabLandscape: "5",
      ColSpanForTabPotrait: "5",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_chkboxInclude_PROPERTIES

      // END_USER_CODE-USER_chkboxInclude_PROPERTIES
    },
    colAmount: {
      name: "colAmount",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAmount_PROPERTIES

      // END_USER_CODE-USER_colAmount_PROPERTIES
    },
    colChargeLocation: {
      name: "colChargeLocation",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Charge Location",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChargeLocation_PROPERTIES

      // END_USER_CODE-USER_colChargeLocation_PROPERTIES
    },
    colMemo: {
      name: "colMemo",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Memo #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMemo_PROPERTIES

      // END_USER_CODE-USER_colMemo_PROPERTIES
    },
    colSpoolLocation: {
      name: "colSpoolLocation",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Spool Location",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSpoolLocation_PROPERTIES

      // END_USER_CODE-USER_colSpoolLocation_PROPERTIES
    },
    coltxt: {
      name: "coltxt",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Txt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSpoolLocation_PROPERTIES

      // END_USER_CODE-USER_colSpoolLocation_PROPERTIES
    },
    colStatus: {
      name: "colStatus",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStatus_PROPERTIES

      // END_USER_CODE-USER_colStatus_PROPERTIES
    },
    colVendorName: {
      name: "colVendorName",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Vendor Name",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendorName_PROPERTIES

      // END_USER_CODE-USER_colVendorName_PROPERTIES
    },
    ddChargeLocation: {
      name: "ddChargeLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxSpcPayableDetails",
      Label: "Charge Location:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddChargeLocation_PROPERTIES

      // END_USER_CODE-USER_ddChargeLocation_PROPERTIES
    },
    ddItemCode: {
      name: "ddItemCode",
      type: "DropDownFieldWidget",
      parent: "grpbxSpcPayableDetails",
      Label: "Item Code:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddItemCode_PROPERTIES

      // END_USER_CODE-USER_ddItemCode_PROPERTIES
    },
    ddOleicInd: {
      name: "ddOleicInd",
      type: "DropDownFieldWidget",
      parent: "grpbxSpcPayableDetails",
      Label: "Oleic Ind:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleicInd_PROPERTIES

      // END_USER_CODE-USER_ddOleicInd_PROPERTIES
    },
    ddPayStatus: {
      name: "ddPayStatus",
      type: "DropDownFieldWidget",
      parent: "grpbxSpcPayableDetails",
      Label: "Pay Status:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPayStatus_PROPERTIES

      // END_USER_CODE-USER_ddPayStatus_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxSpcPayableDetails",
      Label: "Peanut Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddRemitTo: {
      name: "ddRemitTo",
      type: "DropDownFieldWidget",
      parent: "grpbxSpcPayableDetails",
      Label: "Remit To:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRemitTo_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo_PROPERTIES
    },
    ddSeedGeneration: {
      name: "ddSeedGeneration",
      type: "DropDownFieldWidget",
      parent: "grpbxSpcPayableDetails",
      Label: "Seed Generation:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeedGeneration_PROPERTIES

      // END_USER_CODE-USER_ddSeedGeneration_PROPERTIES
    },
    ddSpoolLocation: {
      name: "ddSpoolLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxSpcPayableDetails",
      Label: "Spool Location:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSpoolLocation_PROPERTIES

      // END_USER_CODE-USER_ddSpoolLocation_PROPERTIES
    },
    gridSearchResults: {
      name: "gridSearchResults",
      type: "GridWidget",
      parent: "grpbxActionsAndResults",
      gridCellsOrder:
        "txtcolSpoolLocation,txtcolChargeLocation,txtcolVendorName,txtcolMemo,txtcolAmount,txtcolStatus",
      ColSpan: "4",
      Pagination: false,
      HasLabel: false,
      Cols: "4",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridSearchResults_PROPERTIES
      isExpandable: true,
      defaultExpand: false,
      expandedCompTemplate: ({ data }) => (
        // <pre>{JSON.stringify(data, null, 2)}</pre>
        <div>
        {data.children ? (
        <div className="py-3 expandable-area col-sm-12 rdt_TableRow">
        {data.children.map((elem, i) => 
          <div className={"row ExpandRow" + " rdt_ExpanderRow"+i} key={"expandRow"+i} onClick={expandRowClicked}>
          <div className="extraRowCell_1 ch-3 TableCell" key="expandCell1">
          </div>
          <div className="extraRowCell_2 ch-3 TableCell" key="expandCell2">
            <label>
            {elem.txtcolChargeLocation}
            </label>
          </div>
          <div className="extraRowCell_3 ch-3 TableCell" key="expandCell3">
          </div>
          <div className="extraRowCell_4 ch-3 TableCell" key="expandCell4">
          </div>
          <div className="extraRowCell_5 ch-3 TableCell" key="expandCell5">
          </div>
          <div className="extraRowCell_6 ch-3 TableCell" key="expandCell6">
          </div>
          </div>
          )}
          </div>
        ): null}
          </div>
      )

      // END_USER_CODE-USER_gridSearchResults_PROPERTIES
    },
    lblCropYear2: {
      name: "lblCropYear2",
      type: "LabelWidget",
      parent: "grpbxActionsAndResults",
      Label: "CropYear",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCropYear2_PROPERTIES

      // END_USER_CODE-USER_lblCropYear2_PROPERTIES
    },
    txtCheck: {
      name: "txtCheck",
      type: "TextBoxWidget",
      parent: "grpbxSpcPayableDetails",
      Label: "Check #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCheck_PROPERTIES

      // END_USER_CODE-USER_txtCheck_PROPERTIES
    },
    txtcolAmount: {
      name: "txtcolAmount",
      type: "TextBoxWidget",
      colName: "colAmount",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAmount_PROPERTIES

      // END_USER_CODE-USER_txtcolAmount_PROPERTIES
    },
    txtcolChargeLocation: {
      name: "txtcolChargeLocation",
      type: "TextBoxWidget",
      colName: "colChargeLocation",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolChargeLocation_PROPERTIES

      // END_USER_CODE-USER_txtcolChargeLocation_PROPERTIES
    },
    txtcolMemo: {
      name: "txtcolMemo",
      type: "TextBoxWidget",
      colName: "colMemo",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolMemo_PROPERTIES

      // END_USER_CODE-USER_txtcolMemo_PROPERTIES
    },
    txtcolSpoolLocation: {
      name: "txtcolSpoolLocation",
      type: "TextBoxWidget",
      colName: "colSpoolLocation",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSpoolLocation_PROPERTIES

      // END_USER_CODE-USER_txtcolSpoolLocation_PROPERTIES
    },
    txtcolStatus: {
      name: "txtcolStatus",
      type: "TextBoxWidget",
      colName: "colStatus",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolStatus_PROPERTIES

      // END_USER_CODE-USER_txtcolStatus_PROPERTIES
    },
    txtcolVendorName: {
      name: "txtcolVendorName",
      type: "TextBoxWidget",
      colName: "colVendorName",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendorName_PROPERTIES

      // END_USER_CODE-USER_txtcolVendorName_PROPERTIES
    },
    // txtEndDate: {
    //   name: "txtEndDate",
    //   type: "TextBoxWidget",
    //   parent: "grpbxSpcPayableDetails",
    //   Label: "-",
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtEndDate_PROPERTIES

    //   // END_USER_CODE-USER_txtEndDate_PROPERTIES
    // },
    dtEnd: {
      name: "dtEnd",
      type: "DateWidget",
      parent: "grpbxSpcPayableDetails",
      Label: "-",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_dtEnd_PROPERTIES
      // END_USER_CODE-USER_dtEnd_PROPERTIES
    },
    txtInvoice: {
      name: "txtInvoice",
      type: "TextBoxWidget",
      parent: "grpbxSpcPayableDetails",
      Label: "Invoice #:",
      LengthRange: { MinLength: 0, MaxLength: 20 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInvoice_PROPERTIES

      // END_USER_CODE-USER_txtInvoice_PROPERTIES
    },
    txtMemo: {
      name: "txtMemo",
      type: "TextBoxWidget",
      parent: "grpbxSpcPayableDetails",
      Label: "Memo #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMemo_PROPERTIES

      // END_USER_CODE-USER_txtMemo_PROPERTIES
    },
    txtPayAmount: {
      name: "txtPayAmount",
      type: "TextBoxWidget",
      parent: "grpbxSpcPayableDetails",
      Label: "Pay Amount:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 16 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPayAmount_PROPERTIES

      // END_USER_CODE-USER_txtPayAmount_PROPERTIES
    },
    // txtStartDate: {
    //   name: "txtStartDate",
    //   type: "TextBoxWidget",
    //   parent: "grpbxSpcPayableDetails",
    //   Label: "Date Range:",
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtStartDate_PROPERTIES

    //   // END_USER_CODE-USER_txtStartDate_PROPERTIES
    // },
    
    dtStart: {
      name: "dtStart",
      type: "DateWidget",
      parent: "grpbxSpcPayableDetails",
      Label: "Date Range:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_dtStart_PROPERTIES
      // END_USER_CODE-USER_dtStart_PROPERTIES
    },
    txtVndrName: {
       name: "txtVndrName",
       type: "TextBoxWidget",
       parent: "grpbxSpcPayableDetails",
       HasLabel: false,
       LengthRange: { MinLength: 0, MaxLength: 256 },
       ofTypeDomain: "d_String",
       ReadOnly:true,
       // START_USER_CODE-USER_txtVndrName_PROPERTIES 
       // END_USER_CODE-USER_txtVndrName_PROPERTIES
       },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxSpcPayableDetails",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    grpbxSpcPayableDetails: {
      name: "grpbxSpcPayableDetails",
      type: "GroupBoxWidget",
      parent: "SpecialPayablesInquiry",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxSpcPayableDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxSpcPayableDetails_PROPERTIES
    },
    grpbxActionsAndResults: {
      name: "grpbxActionsAndResults",
      type: "GroupBoxWidget",
      parent: "SpecialPayablesInquiry",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActionsAndResults_PROPERTIES

      // END_USER_CODE-USER_grpbxActionsAndResults_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#VendorLookup":{},
        "ContractManagement#MainMenu":{},
        "AccountPayment#SpecialPayablesProfile":{},  
      },
      REVERSE: {
        "ContractManagement#VendorLookup":{},
        "ContractManagement#MainMenu":{},
        "AccountPayment#SpecialPayablesProfile":{},
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnClose: {
      DEFAULT:["ContractManagement#MainMenu#DEFAULT#false#Click"],
    }, 
    // btnCreateSpecialPayable: {
    //   DEFAULT:["AccountPayment#SpecialPayablesProfile#DEFAULT#true#Click"],
    // }, 
	  btnVendor: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
    // btnOpen: {
    //   DEFAULT:["AccountPayment#SpecialPayablesProfile#DEFAULT#true#Click"],
    // }
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    setValue(thisObj,"ddSpoolLocation",''),
    setValue(thisObj,"ddPayStatus",''),
    setValue(thisObj,"ddChargeLocation",''),
    setValue(thisObj,"ddPeanutType",''),
    setValue(thisObj,"ddItemCode",''),
    setValue(thisObj,"ddSeedGeneration",''),
    setValue(thisObj,"ddOleicInd",''),
    setValue(thisObj,"ddRemitTo",''),
    setValue(thisObj, 'lblCropYear2', cropYearFromLS()),
    setData(thisObj, 'vendorDetails', '')

    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  useEffect(() => {
    let refresData = getData(thisObj,"refeshGridData");
    if(refresData == "OK"){
      onbtnSearchClick();
    }
    setData(thisObj,"refeshGridData", null)
  }, [getData(thisObj,"refeshGridData")]);

  

  useEffect(() => {
    let vendor_lookup = getData(thisObj, 'vendorDetails')
    if (vendor_lookup !== null && vendor_lookup !== undefined && vendor_lookup !== '') {
      thisObj.setFieldValue("txtVendor", vendor_lookup.VendorNumber);
      let VenNumber = vendor_lookup.VendorNumber
      bFillRemitToList(vendor_lookup.VendorNumber)
      thisObj.setFieldValue("txtVndrName", vendor_lookup.vendorName)
      let js = [];
      js.push({ key: '', description: '>>>Any Remit-To<<<' }) //Set default value
        ContractManagementService.RetieveRemitDetails(VenNumber).then(response => {
          if (response != undefined && response.length > 0) {
            let data = response
            for (var i = 0; i < data.length; i++) {
              var vendorName = data[i].name
              let obj = { key: data[i].number, description: data[i].number + " - " + data[i].name + " - " + data[i].city + " - " + data[i].state }
              js.push(obj)
            }
            setValue(thisObj, "txtVndrName", vendorName)
          }
        })
      thisObj.setState(current => {
        return {
          ...current,
          ddRemitTo: {
            ...state["ddRemitTo"],
            valueList: js,
          }
        }
      })
    }
  },[getData(thisObj, 'vendorDetails')]);

  function FormLoad() {
    bFillSpoolLocation(true);
    bFillChargeLocation(true);
    bFillItemCode();
    bFillPayStatus();
    bFillPeanutType();
    bFillSeedGeneration();
    bFillOleicInd();
    disable(thisObj,'btnOpen');
    CheckSecurity ();
    if ((txtVendor.value) != null && (txtVendor.value) != undefined) {
      ontxtVendorBlur()
    }
    setValue(thisObj,"chkboxInclude",'1');
    setData(thisObj,'Rtp_sw','Y');
    setData(thisObj,'Pay_sw','N');
    setLoading(false)
  }

  const ontxtCheckBlur = () => {
    try{
    let txtCheckValue = getValue(thisObj, 'txtCheck')
    if (txtCheckValue != undefined && txtCheckValue != "" && txtCheckValue != null) {
      setValue(thisObj, "chkboxInclude", '2')
      setData(thisObj,'Rtp_sw','N');
      setData(thisObj,'Pay_sw','Y');
      if (isNaN(txtCheckValue) == false) {
        let CheckNumLength = txtCheckValue.length
        CHK_NUM = txtCheckValue
        if (CheckNumLength < 6 && CheckNumLength > 0) {
          let NewCheckNum = '0'.repeat((6 - CheckNumLength)) + txtCheckValue
          setValue(thisObj, 'txtCheck', NewCheckNum)
        }
      }
      else if (txtCheckValue.slice(0, 1).toUpperCase() == 'S') {
        let CheckNumLength = txtCheckValue.length
        RECV_NUM = txtCheckValue.slice(1, txtCheckValue.length)
        if (CheckNumLength < 6 && CheckNumLength > 0) {
          let NewCheckNum = 'S' + '0'.repeat((6 - CheckNumLength)) + txtCheckValue.slice(1, txtCheckValue.length)
          setValue(thisObj, 'txtCheck', NewCheckNum)
        }
      }
      else {
        showMessage(thisObj, "Invalid Check Number")
      }
    }
  }catch(err){	
    if(err instanceof EvalError){	
      showMessage(thisObj, err.message)	
    }else{	
      showMessage(thisObj, "Error on txtCheck Blur event"	)
    }
    return false
  }
  return true;
  }
  thisObj.ontxtCheckBlur = ontxtCheckBlur;

const CheckSecurity = () => {
  ContractManagementService.RetrieveAccessPermissionDetails('PN1100', '005', 'U').then(response => {
    disable(thisObj, 'btnCreateSpecialPayable')
    if (response[0].permission == 'Y') {
      enable(thisObj, 'btnCreateSpecialPayable')
    }
  })
}

  const onbtnCreateSpecialPayableClick = () => {
    try {    
      var SpecialPayablesProfileDataObj = {}  
        let cmdOpen = document.getElementsByClassName('btnCreateSpecialPayable')[0].childNodes[0].childNodes[0].innerText == "Open";
        if (cmdOpen == true) {
          SpecialPayablesProfileDataObj.cmdOpen = cmdOpen
          SpecialPayablesProfileDataObj.ByPtID = SelectedRow.BuyPtId
          SpecialPayablesProfileDataObj.lblMemo = SelectedRow.txtcolMemo
          SpecialPayablesProfileDataObj.txtVendor = SelectedRow.PayeeVendor
          SpecialPayablesProfileDataObj.lblRemitTo = SelectedRow.PayeeRemit
          SpecialPayablesProfileDataObj.Status = SelectedRow.SpecialStatus
          SpecialPayablesProfileDataObj.lblStatusDetail = SelectedRow.txtcolStatus
          SpecialPayablesProfileDataObj.lblCheckNum = SelectedRow.CheckNum
          SpecialPayablesProfileDataObj.lblRecvNum = SelectedRow.ReceivableNum
          setData(thisObj, 'vendorDetails', null)
          setData(thisObj, "SpecialPayablesProfileData", SpecialPayablesProfileDataObj);
          goTo(thisObj, "AccountPayment#SpecialPayablesProfile#DEFAULT#true#Click");
        }
        else{
          SpecialPayablesProfileDataObj.cmdOpen = cmdOpen
          SpecialPayablesProfileDataObj.Status = "RTP-P"
          SpecialPayablesProfileDataObj.lblChkNum = ""
          SpecialPayablesProfileDataObj.lblRecvNum = ""
          setData(thisObj, 'vendorDetails', null)
          setData(thisObj, "SpecialPayablesProfileData", SpecialPayablesProfileDataObj); 
          goTo(thisObj, "AccountPayment#SpecialPayablesProfile#DEFAULT#true#Click");
        }     

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, "Error on button CreateSpecialPayable Click event")
      }
      return false
    }
    return true;
  };
  thisObj.onbtnCreateSpecialPayableClick = onbtnCreateSpecialPayableClick;

const onbtnVendorClick = () =>{
try{
  setValue(thisObj,"txtVendor", '');
}catch(err){	
  if(err instanceof EvalError){	
    showMessage(thisObj, err.message)	
  }else{	
    showMessage(thisObj, "Error on button Vendor Click event"	)	
  }
  return false
}
return true;
}
thisObj.onbtnVendorClick = onbtnVendorClick;


const OpenClickFunc = () =>{
  if(thisObj.state.gridSearchResults.selected == null){
    alert("no row selected...")
    return
  }
  
  var SpecialPayablesProfileDataObj = {}
  let SelectedRow = thisObj.state.gridSearchResults.selected[0]
  thisObj.state.gridSearchResults.selected = null
  if (SelectedRow != null && SelectedRow != undefined) {
    let cmdOpen = document.getElementsByClassName('btnOpen')[0].childNodes[0].childNodes[0].innerText == "Open";
    if (cmdOpen == true) {
      SpecialPayablesProfileDataObj.cmdOpen = cmdOpen
      SpecialPayablesProfileDataObj.ByPtID = SelectedRow.BuyPtId
      SpecialPayablesProfileDataObj.lblMemo = SelectedRow.txtcolMemo
      SpecialPayablesProfileDataObj.txtVendor = SelectedRow.PayeeVendor
      SpecialPayablesProfileDataObj.lblRemitTo = SelectedRow.PayeeRemit
      SpecialPayablesProfileDataObj.Status = SelectedRow.SpecialStatus
      SpecialPayablesProfileDataObj.lblStatusDetail = SelectedRow.txtcolStatus
      SpecialPayablesProfileDataObj.lblCheckNum = SelectedRow.CheckNum
      SpecialPayablesProfileDataObj.lblRecvNum = SelectedRow.ReceivableNum
      setData(thisObj, "SpecialPayablesProfileData", SpecialPayablesProfileDataObj);
      goTo(thisObj, "AccountPayment#SpecialPayablesProfile#DEFAULT#true#Click");
    }
    else {
      SpecialPayablesProfileDataObj.Status = "RTP-P"
      SpecialPayablesProfileDataObj.lblChkNum = ""
      SpecialPayablesProfileDataObj.lblRecvNum = ""
      setData(thisObj, "SpecialPayablesProfileData", SpecialPayablesProfileDataObj);
      goTo(thisObj, "AccountPayment#SpecialPayablesProfile#DEFAULT#true#Click");
    }
  } else {
    showMessage(thisObj, 'Atleast one row must be selected')
  }
}

  const onbtnOpenClick = () => {
    try {
      OpenClickFunc() 
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, "Error on button Open Click event")
      }
      return false
    }
    return true;
  };
  thisObj.onbtnOpenClick = onbtnOpenClick;


  const onbtnSearchClick = () => {
    try{
    if (thisObj.values.dtStart != undefined) {
      beg_cont_date = convert(getValue(thisObj, "dtStart")['_d'])
      if (beg_cont_date == "aN/aN/NaN") {
        beg_cont_date = null
      }
    }
    if (thisObj.values.dtEnd != undefined) {
      end_cont_date = convert(getValue(thisObj, "dtEnd")['_d'])
      if (end_cont_date == "aN/aN/NaN") {
        end_cont_date = null
      }
    }
    if (beg_cont_date !== null) {
      if (end_cont_date == null || end_cont_date == undefined) {
        end_cont_date = beg_cont_date
      }
    }
    if (end_cont_date !== null) {
      if (beg_cont_date == null || beg_cont_date == undefined) {
        beg_cont_date = end_cont_date
      }
    }
    processSearch();
  }catch(err){	
    if(err instanceof EvalError){	
      showMessage(thisObj, err.message)	
    }else{	
      showMessage(thisObj, "Error on button Search Click event"	)	
    }
    return false
  }
  return true;
  }
  thisObj.onbtnSearchClick = onbtnSearchClick;

  const numberFormat = (value) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(value);

  const processSearch = () =>{
    setLoading(true)
    ontxtCheckBlur()

    let memo_num = getValue(thisObj, 'txtMemo')
    let invoice_num = getValue(thisObj, 'txtInvoice')
    let payee_vendor = getValue(thisObj, 'txtVendor')
    let payee_remit = getValue(thisObj, 'ddRemitTo')
    let item_code = getValue(thisObj, 'ddItemCode' )
    let pay_amount = getValue(thisObj, 'txtPayAmount')
    let spool_buy_pt_id = getValue(thisObj, 'ddSpoolLocation')
    let charge_buy_pt_id = getValue(thisObj, 'ddChargeLocation')
    let start_date = beg_cont_date
    let end_date = end_cont_date
    let pnut_type_id = getValue(thisObj, 'ddPeanutType')
    let seed_gen = getValue(thisObj, 'ddSeedGeneration')
    let oleic_ind = getValue(thisObj, 'ddOleicInd')
    let rtp_sw = getData(thisObj,'Rtp_sw');
    let pay_sw = getData(thisObj,'Pay_sw');
    let status = getValue(thisObj, 'ddPayStatus')
    let Initial = ''
    let chk_num = ''
    let recv_num = ''
    let checknumvalue = getValue(thisObj, 'txtCheck')
    if(checknumvalue != undefined && checknumvalue != null && checknumvalue != ''){
      Initial = checknumvalue.slice(0, 1).toUpperCase()
      if(Initial == 'S'){
        chk_num = 0
        recv_num = RECV_NUM
      }
      else{
        chk_num = CHK_NUM
        recv_num = 0
      }
    }
    

// Function for getting Contract Grouping list based on given criteria
    AccountPayment.RetrieveSpecialPayables(memo_num, invoice_num, payee_vendor, payee_remit, item_code, pay_amount, spool_buy_pt_id, charge_buy_pt_id, start_date, end_date, pnut_type_id, seed_gen, oleic_ind, rtp_sw, pay_sw, status, chk_num, recv_num)
      .then(response => {
        let TransformedRowsArray = [];
        let Array = [];
        let ChildArray = [];
        let Count = 1
        let ChildID = 1
        if (response != undefined && response.length > 0) {
          enable(thisObj, 'btnOpen')
          let data = response;
          let obj = {}
          for (var i = 0; i < data.length; i++) {
           if(data.length == 1){
            obj.RowId = Count
            obj.BuyPtId = data[i].buy_pt_id
            obj.CheckNum = data[i].check_num
            obj.PayeeRemit = data[i].payee_remit
            obj.PayeeVendor = data[i].payee_vendor
            obj.ReceivableNum = data[i].receivable_num
            obj.SpecialStatus = data[i].special_status

            obj.txtcolSpoolLocation = data[i].buy_pt_name
            obj.txtcolChargeLocation = data[i].charge_buy_pt
            obj.txtcolVendorName = data[i].payee_vendor_name
            obj.txtcolStatus = data[i].status_detail
            obj.txtcolMemo = data[i].memo_number
            obj.txtcolAmount = numberFormat(data[i].special_amount)
            obj.txtcoltxt = ChildID
            TransformedRowsArray.push(obj)
            obj = {}
            Count = Count + 1
            ChildID = ChildID + 1
           }
           else{
            if(i==0){
              if((data[i].buy_pt_id !== data[i+1].buy_pt_id) || (data[i].payee_vendor !== data[i+1].payee_vendor) || (data[i].payee_remit !== data[i+1].payee_remit) || (data[i].memo_number !== data[i+1].memo_number) || (data[i].check_num !== data[i+1].check_num) || (data[i].receivable_num !== data[i+1].receivable_num)){         
                obj.RowId = Count
                obj.BuyPtId = data[i].buy_pt_id
                obj.CheckNum = data[i].check_num
                obj.PayeeRemit = data[i].payee_remit
                obj.PayeeVendor = data[i].payee_vendor
                obj.ReceivableNum = data[i].receivable_num
                obj.SpecialStatus = data[i].special_status

                obj.txtcolSpoolLocation = data[i].buy_pt_name
                obj.txtcolChargeLocation = data[i].charge_buy_pt
                obj.txtcolVendorName = data[i].payee_vendor_name
                obj.txtcolStatus = data[i].status_detail
                obj.txtcolMemo = data[i].memo_number
                obj.txtcolAmount = numberFormat(data[i].special_amount)
                obj.txtcoltxt = ChildID
                TransformedRowsArray.push(obj)
                obj = {}
                Count = Count + 1
                ChildID = ChildID + 1
              }
            }
            if(i >0 && i < data.length-1){
              if((data[i].buy_pt_id !== data[i-1].buy_pt_id) || (data[i].payee_vendor !== data[i-1].payee_vendor) || (data[i].payee_remit !== data[i-1].payee_remit) || (data[i].memo_number !== data[i-1].memo_number) || (data[i].check_num !== data[i-1].check_num) || (data[i].receivable_num !== data[i-1].receivable_num)){
                if((data[i].buy_pt_id !== data[i+1].buy_pt_id) || (data[i].payee_vendor !== data[i+1].payee_vendor) || (data[i].payee_remit !== data[i+1].payee_remit) || (data[i].memo_number !== data[i+1].memo_number) || (data[i].check_num !== data[i+1].check_num) || (data[i].receivable_num !== data[i+1].receivable_num)){

                  obj.RowId = Count
                  obj.BuyPtId = data[i].buy_pt_id
                  obj.CheckNum = data[i].check_num
                  obj.PayeeRemit = data[i].payee_remit
                  obj.PayeeVendor = data[i].payee_vendor
                  obj.ReceivableNum = data[i].receivable_num
                  obj.SpecialStatus = data[i].special_status

                  obj.txtcolSpoolLocation = data[i].buy_pt_name
                  obj.txtcolChargeLocation = data[i].charge_buy_pt
                  obj.txtcolVendorName = data[i].payee_vendor_name
                  obj.txtcolStatus = data[i].status_detail
                  obj.txtcolMemo = data[i].memo_number
                  obj.txtcolAmount = numberFormat(data[i].special_amount)
                  obj.txtcoltxt = ChildID
                  TransformedRowsArray.push(obj)
                  obj = {}
                  Count = Count + 1
                  ChildID = ChildID + 1
                }
              }
              else{

                obj.RowId = Count
                obj.BuyPtId = data[i].buy_pt_id
                obj.CheckNum = data[i].check_num
                obj.PayeeRemit = data[i].payee_remit
                obj.PayeeVendor = data[i].payee_vendor
                obj.ReceivableNum = data[i].receivable_num
                obj.SpecialStatus = data[i].special_status

                obj.txtcolSpoolLocation = data[i].buy_pt_name
                obj.txtcolChargeLocation = data[i].charge_buy_pt
                obj.txtcolVendorName = data[i].payee_vendor_name
                obj.txtcolStatus = data[i].status_detail
                obj.txtcolMemo = data[i].memo_number
                obj.txtcolAmount = data[i].special_amount
                obj.txtcoltxt = ChildID
                ChildArray.push(obj)
                obj = {}
                Count = Count + 1

                  if((data[i].buy_pt_id !== data[i+1].buy_pt_id) || (data[i].payee_vendor !== data[i+1].payee_vendor) || (data[i].payee_remit !== data[i+1].payee_remit) || (data[i].memo_number !== data[i+1].memo_number) || (data[i].check_num !== data[i+1].check_num) || (data[i].receivable_num !== data[i+1].receivable_num)){

                    let I = i - (ChildArray.length)

                    let Total_Amount = Number(data[I].special_amount)

                    for(let j=0; j <ChildArray.length; j++){
                      let TotalAmt = ChildArray[j].txtcolAmount
                      Total_Amount = Total_Amount + Number(TotalAmt)
                    }
                    
                      obj.RowId = Count
                      obj.BuyPtId = ChildArray[0].BuyPtId
                      obj.CheckNum = ChildArray[0].CheckNum
                      obj.PayeeRemit = ChildArray[0].PayeeRemit
                      obj.PayeeVendor = ChildArray[0].PayeeVendor
                      obj.ReceivableNum = ChildArray[0].ReceivableNum
                      obj.SpecialStatus = ChildArray[0].SpecialStatus

                      obj.txtcolSpoolLocation = ChildArray[0].txtcolSpoolLocation
                      obj.txtcolChargeLocation = 'Multi...'
                      obj.txtcolVendorName = ChildArray[0].txtcolVendorName
                      obj.txtcolStatus = ChildArray[0].txtcolStatus
                      obj.txtcolMemo = ChildArray[0].txtcolMemo
                      obj.txtcolAmount = numberFormat(Total_Amount)
                      obj.txtcoltxt = ChildID
                      TransformedRowsArray.push(obj)
                      obj = {}
                      Count = Count + 1


                   
                        obj.RowId = Count
                        obj.BuyPtId = ''
                        obj.CheckNum = ''
                        obj.PayeeRemit = ''
                        obj.PayeeVendor = ''
                        obj.ReceivableNum = ''
                        obj.SpecialStatus = ''

                        obj.txtcolSpoolLocation = ''
                        obj.txtcolChargeLocation = data[I].charge_buy_pt
                        obj.txtcolVendorName = ''
                        obj.txtcolStatus = ''
                        obj.txtcolMemo = ''
                        obj.txtcolAmount = ''
                        obj.txtcoltxt = ChildID
                        TransformedRowsArray.push(obj)
                        obj = {}
                        Count = Count + 1

                      for(let j=0; j <ChildArray.length; j++){
                        obj.RowId = Count
                        obj.BuyPtId = ''
                        obj.CheckNum = ''
                        obj.PayeeRemit = ''
                        obj.PayeeVendor = ''
                        obj.ReceivableNum = ''
                        obj.SpecialStatus = ''

                        obj.txtcolSpoolLocation = ''
                        obj.txtcolChargeLocation = ChildArray[j].txtcolChargeLocation
                        obj.txtcolVendorName = ''
                        obj.txtcolStatus = ''
                        obj.txtcolMemo = ''
                        obj.txtcolAmount = ''
                        obj.txtcoltxt = ChildID
                        TransformedRowsArray.push(obj)
                        obj = {}
                        Count = Count + 1
                      }

                      ChildArray = []
                      ChildID = ChildID + 1
                  }
              }
            }
            if(i == data.length-1){
              if((data[i].buy_pt_id !== data[i-1].buy_pt_id) || (data[i].payee_vendor !== data[i-1].payee_vendor) || (data[i].payee_remit !== data[i-1].payee_remit) || (data[i].memo_number !== data[i-1].memo_number) || (data[i].check_num !== data[i-1].check_num) || (data[i].receivable_num !== data[i-1].receivable_num)){
                obj.RowId = Count
                obj.BuyPtId = data[i].buy_pt_id
                obj.CheckNum = data[i].check_num
                obj.PayeeRemit = data[i].payee_remit
                obj.PayeeVendor = data[i].payee_vendor
                obj.ReceivableNum = data[i].receivable_num
                obj.SpecialStatus = data[i].special_status

                obj.txtcolSpoolLocation = data[i].buy_pt_name
                obj.txtcolChargeLocation = data[i].charge_buy_pt
                obj.txtcolVendorName = data[i].payee_vendor_name
                obj.txtcolStatus = data[i].status_detail
                obj.txtcolMemo = data[i].memo_number
                obj.txtcolAmount = numberFormat(data[i].special_amount)
                obj.txtcoltxt = ChildID
                TransformedRowsArray.push(obj)
                obj = {}
                Count = Count + 1
                ChildID = ChildID + 1
              }
              else{
                obj.RowId = Count
                obj.BuyPtId = data[i].buy_pt_id
                obj.CheckNum = data[i].check_num
                obj.PayeeRemit = data[i].payee_remit
                obj.PayeeVendor = data[i].payee_vendor
                obj.ReceivableNum = data[i].receivable_num
                obj.SpecialStatus = data[i].special_status

                obj.txtcolSpoolLocation = data[i].buy_pt_name
                obj.txtcolChargeLocation = data[i].charge_buy_pt
                obj.txtcolVendorName = data[i].payee_vendor_name
                obj.txtcolStatus = data[i].status_detail
                obj.txtcolMemo = data[i].memo_number
                obj.txtcolAmount = data[i].special_amount
                obj.txtcoltxt = ChildID
                ChildArray.push(obj)
                obj = {}
                Count = Count + 1


                let I = i - (ChildArray.length)

                let Total_Amount = Number(data[I].special_amount)

                for (let j = 0; j < ChildArray.length; j++) {
                  let TotalAmt = ChildArray[j].txtcolAmount
                  Total_Amount = Total_Amount + Number(TotalAmt)
                }

                obj.RowId = Count
                obj.BuyPtId = ChildArray[0].BuyPtId
                obj.CheckNum = ChildArray[0].CheckNum
                obj.PayeeRemit = ChildArray[0].PayeeRemit
                obj.PayeeVendor = ChildArray[0].PayeeVendor
                obj.ReceivableNum = ChildArray[0].ReceivableNum
                obj.SpecialStatus = ChildArray[0].SpecialStatus

                obj.txtcolSpoolLocation = ChildArray[0].txtcolSpoolLocation
                obj.txtcolChargeLocation = 'Multi...'
                obj.txtcolVendorName = ChildArray[0].txtcolVendorName
                obj.txtcolStatus = ChildArray[0].txtcolStatus
                obj.txtcolMemo = ChildArray[0].txtcolMemo
                obj.txtcolAmount = numberFormat(Total_Amount)
                obj.txtcoltxt = ChildID
                TransformedRowsArray.push(obj)
                obj = {}
                Count = Count + 1

                obj.RowId = Count
                obj.BuyPtId = ''
                obj.CheckNum = ''
                obj.PayeeRemit = ''
                obj.PayeeVendor = ''
                obj.ReceivableNum = ''
                obj.SpecialStatus = ''

                obj.txtcolSpoolLocation = ''
                obj.txtcolChargeLocation = data[I].charge_buy_pt
                obj.txtcolVendorName = ''
                obj.txtcolStatus = ''
                obj.txtcolMemo = ''
                obj.txtcolAmount = ''
                obj.txtcoltxt = ChildID
                TransformedRowsArray.push(obj)
                obj = {}
                Count = Count + 1

                for (let j = 0; j < ChildArray.length; j++) {
                  obj.RowId = Count
                  obj.BuyPtId = ''
                  obj.CheckNum = ''
                  obj.PayeeRemit = ''
                  obj.PayeeVendor = ''
                  obj.ReceivableNum = ''
                  obj.SpecialStatus = ''

                  obj.txtcolSpoolLocation = ''
                  obj.txtcolChargeLocation = ChildArray[j].txtcolChargeLocation
                  obj.txtcolVendorName = ''
                  obj.txtcolStatus = ''
                  obj.txtcolMemo = ''
                  obj.txtcolAmount = ''
                  obj.txtcoltxt = ChildID
                  TransformedRowsArray.push(obj)
                  obj = {}
                  Count = Count + 1
                }

                ChildArray = []
                ChildID = ChildID + 1
              }
            }
           }
          }
          let MultiOccurredRowArray = addMultiOccurredRowId(TransformedRowsArray);
          const groupedRowsArray = groupMultiOccurredRows(MultiOccurredRowArray);
          setValue(thisObj, "gridSearchResults", groupedRowsArray);
        } else {
          setValue(thisObj, "gridSearchResults", Array);
          alert("No Special Payables found!", false);
          disable(thisObj, 'btnOpen')
          return false;
        }
      })
    setLoading(false)
    return true;
  };
  

  const ongridSearchResultsDblclick = () => {
    try {
      if (thisObj.state.gridSearchResults.selected[0] !== undefined) {
        OpenClickFunc()
      }
      else {
        showMessage(thisObj, "error while selecting contract");
        return false
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "error on double click , please try open button"
        );
      }
    }
  }
  thisObj.ongridSearchResultsDblclick = ongridSearchResultsDblclick;

    //converting date into dd/mm/yyyy formt
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }


  const bFillSpoolLocation = (blnSecure) => {
    let js = []
    js.push({ key: '', description: '>>>All Location<<<' });
    if (blnSecure) {
      ContractManagementService.RetrieveBuyingPointControlDetails('PN1100', '005', null, null, null).then(response => {
        if (response != undefined && response.length > 0) {
          let data = response
          for (var i = 0; i < data.length; i++) {
            let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
            js.push(obj)
          }
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddSpoolLocation: {
              ...state["ddSpoolLocation"],
              valueList: js
            }
          }
        })
      })
    } else {
      ContractManagementService.RetrieveBuyingPointControlDetails(null, null, null, null, null).then(response => {
        if (response != undefined && response.length > 0) {
          let data = response
          for (var i = 0; i < data.length; i++) {
            let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
            js.push(obj)
          }
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddSpoolLocation: {
              ...state["ddSpoolLocation"],
              valueList: js
            }
          }
        })
      })
    }
  }
  const bFillChargeLocation = (blnSecure) => {
    let js = []
    js.push({ key: '', description: '>>>All Location<<<' });
    if (blnSecure) {
      ContractManagementService.RetrieveBuyingPointControlDetails('PN1100', '005', null, null, null).then(response => {
        if (response != undefined && response.length > 0) {
          let data = response
          for (var i = 0; i < data.length; i++) {
            let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
            js.push(obj)
          }
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddChargeLocation: {
              ...state["ddChargeLocation"],
              valueList: js
            }
          }
        })
      })
    } else {
      ContractManagementService.RetrieveBuyingPointControlDetails(null, null, null, null, null).then(response => {
        if (response != undefined && response.length > 0) {
          let data = response
          for (var i = 0; i < data.length; i++) {
            let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
            js.push(obj)
          }
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddChargeLocation: {
              ...state["ddChargeLocation"],
              valueList: js
            }
          }
        })
      })
    }
  }
  const bFillItemCode = () => {
    let pd_code = null
    let check_enable_ind = 'Y'
    let js = [];
    js.push({ key: '', description: '>>>All Codes<<<' }) // default value
    AccountPayment.RetrievePremiumDeductionMasterDetails(pd_code, check_enable_ind).then(response => {
      if (response != undefined && response.length > 0) {
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].pd_code, description: data[i].pd_code + ' - ' + data[i].pd_desc }
          js.push(obj)
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddItemCode: {
            ...state["ddItemCode"],
            valueList: js,
          }
        }
      })
    })
  }
  const bFillPeanutType = () => {
    let js = [];
    js.push({ key: '', description: '>>> All Types <<<' }) //Set default value
    ContractManagementService.RetrievePeanutTypeControls(null).then(response => {
      if (response != undefined && response.length > 0) {
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeName }
          js.push(obj)
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutType: {
            ...state["ddPeanutType"],
            valueList: js,
          }
        }
      })
    })
  }
  const bFillPayStatus = () => {
    let js = []
    js.push({ key: '', description: '>>>Any Status<<<' })
    js.push({ key: 'RTP-P', description: 'Pending' })
    js.push({ key: 'RTP-H', description: 'Hold' })
    js.push({ key: 'RTP-R', description: 'Released' })
    js.push({ key: 'RTP-B', description: 'On Batch' })
    js.push({ key: 'PAY-P', description: 'Paid' })
    js.push({ key: 'PAY-V', description: 'Voided' })
    thisObj.setState(current => {
      return {
        ...current,
        ddPayStatus: {
          ...state["ddPayStatus"],
          valueList: js
        }
      }
    })
  }
  const bFillSeedGeneration = () => {
    let js = []
    js.push({ key: '', description: '>>>Any Seed<<<' })
    js.push({ key: 'F', description: 'Foundation' })
    js.push({ key: 'R', description: 'Registered' })
    js.push({ key: 'C', description: 'Certified' })
    thisObj.setState(current => {
      return {
        ...current,
        ddSeedGeneration: {
          ...state["ddSeedGeneration"],
          valueList: js
        }
      }
    })
  }
  const bFillOleicInd = () => {
    let js = []
    js.push({ key: '', description: '>>>All Oleic<<<' })
    js.push({ key: 'H', description: 'High' })
    js.push({ key: 'M', description: 'Mid' })
    js.push({ key: 'N', description: 'None' })
    thisObj.setState(current => {
      return {
        ...current,
        ddOleicInd: {
          ...state["ddOleicInd"],
          valueList: js
        }
      }
    })
  }

  const onchkboxIncludeChange = () => {
    try{
    let NewChkBoxValue = thisObj.values.chkboxInclude

    if (NewChkBoxValue.length == 0 && PreviousChkBoxValue == '1') {
      setValue(thisObj, "chkboxInclude", '2')
      PreviousChkBoxValue = (getValue(thisObj, 'chkboxInclude'))
      chk1 = false;
      chk2 = true;
      setData(thisObj,'Rtp_sw','N');
      setData(thisObj,'Pay_sw','Y');
      CheckBoxFunc();
      return;
    }
    if (NewChkBoxValue.length == 0 && PreviousChkBoxValue == '2') {
      setValue(thisObj, "chkboxInclude", '1')
      PreviousChkBoxValue = (getValue(thisObj, 'chkboxInclude'))
      chk1 = true;
      chk2 = false;
      setData(thisObj,'Rtp_sw','Y');
      setData(thisObj,'Pay_sw','N');
      CheckBoxFunc();
      return;
    }
    if (NewChkBoxValue.length == 1) {
      PreviousChkBoxValue = (getValue(thisObj, 'chkboxInclude'))
      if (PreviousChkBoxValue[0] == '1') {
        chk1 = true;
        chk2 = false;
        setData(thisObj,'Rtp_sw','Y');
        setData(thisObj,'Pay_sw','N');
        CheckBoxFunc();
        return;
      }
      if (PreviousChkBoxValue[0] == '2') {
        chk1 = false;
        chk2 = true;
        setData(thisObj,'Rtp_sw','N');
        setData(thisObj,'Pay_sw','Y');
        CheckBoxFunc();
        return;
      }
    }
    if (NewChkBoxValue.length == 2) {
      chk1 = true;
      chk2 = true;
      setData(thisObj,'Rtp_sw','Y');
      setData(thisObj,'Pay_sw','Y');
      CheckBoxFunc();
      return;
    }
  }catch(err){	
    if(err instanceof EvalError){	
      showMessage(thisObj, err.message)	
    }else{	
      showMessage(thisObj, "Error on checkbox Include change event"	)	
    }
    return
  }
  return
  }
  thisObj.onchkboxIncludeChange = onchkboxIncludeChange;

  const CheckBoxFunc = () => {
    if (chk2 == false) {
      setValue(thisObj, "txtCheck", '')
      let PayStatus = thisObj.values['ddPayStatus'];
      if (PayStatus.slice(0, 3) == 'PAY') {
        setValue(thisObj, "ddPayStatus", '')
      }
    }
    if (chk2 == true) {
      let PayStatus = thisObj.values['ddPayStatus'];
      if (PayStatus.slice(0, 3) != 'PAY') {
        setValue(thisObj, "ddPayStatus", '')
      }
    }
    if (chk1 == false) {
      let PayStatus = thisObj.values['ddPayStatus'];
      if (PayStatus.slice(0, 3) == 'RTP') {
        setValue(thisObj, "ddPayStatus", '')
      }
    }
    if (chk1 == true) {
      setValue(thisObj, "txtCheck", '')
      let PayStatus = thisObj.values['ddPayStatus'];
      if (PayStatus.slice(0, 3) != 'RTP') {
        setValue(thisObj, "ddPayStatus", '')
      }
    }
  };

  const onddPayStatusChange = async () => {
    try{
      let PayStatus = thisObj.values['ddPayStatus'];
      if (PayStatus.slice(0, 3) == 'RTP') {
        setValue(thisObj, "chkboxInclude", '1')
        setData(thisObj,'Rtp_sw','Y');
        setData(thisObj,'Pay_sw','N');
      }
      if (PayStatus.slice(0, 3) == 'PAY') {
        setValue(thisObj, "chkboxInclude", '2')
        setData(thisObj,'Rtp_sw','N');
        setData(thisObj,'Pay_sw','Y');
      }
  }catch(err){	
    if(err instanceof EvalError){	
      showMessage(thisObj, err.message)	
    }else{	
      showMessage(thisObj, "Error on ddPayStatus change event"	)	
    }
    return false
  }
  return true;
  }
  thisObj.onddPayStatusChange = onddPayStatusChange;

  const ontxtVendorBlur = () => {
    try{
    let txtVendorNumber = txtVendor.value
    let enteredVendorNumber = txtVendor.value
    if (txtVendorNumber.length == 6) {
      bFillRemitToList(txtVendorNumber)
    } else {
      document.getElementsByClassName("txtVndrName")[0].style.visibility = "hidden";
    }
    let js = [];
    js.push({ key: '', description: '>>>Any Remit-To<<<' }) //Set default value
    if (enteredVendorNumber.length == 6) {
      ContractManagementService.RetieveRemitDetails(enteredVendorNumber).then(response => {
        if (response != undefined && response.length > 0) {
          let data = response
          for (var i = 0; i < data.length; i++) {
            var vendorName = data[i].name
            let obj = { key: data[i].number, description: data[i].number + " - " + data[i].name + " - " + data[i].city + " - " + data[i].state }
            js.push(obj)
          }
          setValue(thisObj, "txtVndrName", vendorName)
        }
      })
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddRemitTo: {
          ...state["ddRemitTo"],
          valueList: js,
        }
      }
    })
  }catch(err){	
    if(err instanceof EvalError){	
      showMessage(thisObj, err.message)	
    }else{	
      showMessage(thisObj, "Error on txtVendor change event"	)	
    }
    return false
  }
  return true;
  }
  thisObj.ontxtVendorBlur = ontxtVendorBlur;  
    
//seaching vendorname based on vendorname  
  const bFillRemitToList = (txtVendorNumber) => {
    let venname = null
    let vendornamevisible = false
    ContractManagementService.RetrieveVendorByNumber(txtVendorNumber).then(response => {
      let data = response
      if (data !== null && data !== undefined && data.length > 0) {
        venname = data[0].name
        vendornamevisible = true
      }
      if (vendornamevisible == true) {
        document.getElementsByClassName("txtVndrName")[0].style.visibility = "visible";
        setValue(thisObj, "txtVndrName", venname)
      } else {
        document.getElementsByClassName("txtVndrName")[0].style.visibility = "hidden";
        setValue(thisObj, "txtVndrName", '')
      }
    })
  };

  const ontxtMemoBlur = () => {
    try{
    let Memo_Num = getValue(thisObj, 'txtMemo');
    if (Memo_Num != null && Memo_Num != undefined && Memo_Num != '') {
      if (isNaN(Memo_Num)) {
        showMessage(thisObj, "Invalid Memo Number")
        setValue(thisObj, 'txtMemo', '')
      }
    }
  }catch(err){	
    if(err instanceof EvalError){	
      showMessage(thisObj, err.message)	
    }else{	
      showMessage(thisObj, "Error on txtMemo Blur event"	)	
    }
    return false
  }
  return true;
  }
  thisObj.ontxtMemoBlur = ontxtMemoBlur;

  const ontxtPayAmountBlur = () => {
    try{
    let PayAmount = getValue(thisObj, 'txtPayAmount');
    if (PayAmount != null && PayAmount != undefined && PayAmount != '') {
      if (isNaN(PayAmount)) {
        showMessage(thisObj, "Invalid Pay Amount")
        setValue(thisObj, 'txtPayAmount', '')
      } else {
        let PayAmountFixedInteger = Number(PayAmount).toFixed(2)
        setValue(thisObj, 'txtPayAmount', PayAmountFixedInteger)
      }
    }
  }catch(err){	
    if(err instanceof EvalError){	
      showMessage(thisObj, err.message)	
    }else{	
      showMessage(thisObj, "Error on txtPayAmount Blur event"	)	
    }
    return false
  }
  return true;
  }
  thisObj.ontxtPayAmountBlur = ontxtPayAmountBlur;

  const ontxtMemoChange = () =>{
    let MemoValue = getValue(thisObj, 'txtMemo')
    setValue(thisObj,"txtMemo",validateTextBox(MemoValue))
  };
  thisObj.ontxtMemoChange = ontxtMemoChange

  const ontxtPayAmountChange = () =>{
    let PayAmountValue = getValue(thisObj, 'txtPayAmount')
    setValue(thisObj,"txtPayAmount",validateTextBox1(PayAmountValue))
  };
  thisObj.ontxtPayAmountChange = ontxtPayAmountChange

  const validateTextBox = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) {
        res += data[i]
      }
    }
    return res;
  }

  const validateTextBox1 = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57)||(data[i].charCodeAt(0) == 46)) {
        res += data[i]
      }
    }
    return res;
  }

  function addMultiOccurredRowId(data) {
    let map = new Map();
    let newJs = [];
    let obj = {};
    for (let i = 0; i < data.length; i++) {
      if (map.has(data[i].txtcoltxt)) {
        obj = { ...data[i] };
        obj.multiOccurredRowId = map.get(data[i].txtcoltxt);
      }
      else {
        map.set(data[i].txtcoltxt, data[i].RowId);
        obj = { ...data[i] };
        obj.multiOccurredRowId = null;
      }
      newJs.push(obj);
    }
    return newJs;
  }
  
  function groupMultiOccurredRows(data, multi = null) {
    return data.reduce((r, e) => {
      if (e.multiOccurredRowId == multi) {
        const obj = { ...e };
        const children = groupMultiOccurredRows(data, e.RowId);
        if (children.length) {
          obj.children = children;
        }
        r.push(obj);
      }
      return r;
    }, [])
  }
  
  function expandRowClicked(event) {
    var elems = document.querySelectorAll(".activeExpandRow");
    elems.forEach.call(elems, function (el) {
      el.classList.remove("activeExpandRow");
    });
    var elem = document.querySelectorAll(".icTWDw");
    thisObj.state.gridSearchResults.selected = [];
    elem.forEach.call(elem, function (el) {
      el.classList.remove("icTWDw");
      el.classList.add("eaQpfw");
    });
    if (event.target.classList.contains("TableCell")) {
      event.target.parentElement.classList.value += " activeExpandRow";
    }
    else {
      event.target.classList.value += " activeExpandRow";
    }
  }
  
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            <Loading loading={loading}></Loading>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_SpecialPayablesInquiry*/}

              {/* END_USER_CODE-USER_BEFORE_SpecialPayablesInquiry*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxSpcPayableDetails*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSpcPayableDetails*/}

              <GroupBoxWidget
                conf={state.grpbxSpcPayableDetails}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtMemo*/}

                {/* END_USER_CODE-USER_BEFORE_txtMemo*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtMemo}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtMemo*/}

                {/* END_USER_CODE-USER_AFTER_txtMemo*/}
                {/* START_USER_CODE-USER_BEFORE_ddSpoolLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddSpoolLocation*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSpoolLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSpoolLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddSpoolLocation*/}
                {/* START_USER_CODE-USER_BEFORE_ddPayStatus*/}

                {/* END_USER_CODE-USER_BEFORE_ddPayStatus*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPayStatus}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPayStatus*/}

                {/* END_USER_CODE-USER_AFTER_ddPayStatus*/}
                {/* START_USER_CODE-USER_BEFORE_txtCheck*/}

                {/* END_USER_CODE-USER_BEFORE_txtCheck*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCheck}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCheck*/}

                {/* END_USER_CODE-USER_AFTER_txtCheck*/}
                {/* START_USER_CODE-USER_BEFORE_ddChargeLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddChargeLocation*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddChargeLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddChargeLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddChargeLocation*/}
                {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxInclude*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxInclude*/}

                <CheckboxGroupWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxInclude}
                  screenConf={state}
                ></CheckboxGroupWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxInclude*/}

                {/* END_USER_CODE-USER_AFTER_chkboxInclude*/}
                {/* START_USER_CODE-USER_BEFORE_ddItemCode*/}

                {/* END_USER_CODE-USER_BEFORE_ddItemCode*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddItemCode}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddItemCode*/}

                {/* END_USER_CODE-USER_AFTER_ddItemCode*/}
                {/* START_USER_CODE-USER_BEFORE_ddSeedGeneration*/}

                {/* END_USER_CODE-USER_BEFORE_ddSeedGeneration*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSeedGeneration}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSeedGeneration*/}

                {/* END_USER_CODE-USER_AFTER_ddSeedGeneration*/}
                {/* START_USER_CODE-USER_BEFORE_txtInvoice*/}

                {/* END_USER_CODE-USER_BEFORE_txtInvoice*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtInvoice}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtInvoice*/}

                {/* END_USER_CODE-USER_AFTER_txtInvoice*/}
                {/* START_USER_CODE-USER_BEFORE_txtStartDate*/}

                {/* END_USER_CODE-USER_BEFORE_txtStartDate*/}
                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.dtStart}
                  screenConf={state}
                ></DateWidget>
                {/* <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtStartDate}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget> */}
                {/* START_USER_CODE-USER_AFTER_txtStartDate*/}

                {/* END_USER_CODE-USER_AFTER_txtStartDate*/}
                {/* START_USER_CODE-USER_BEFORE_txtEndDate*/}

                {/* END_USER_CODE-USER_BEFORE_txtEndDate*/}
                <DateWidget
                  onChange={event => invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.dtEnd}
                  screenConf={state}
                ></DateWidget>
                {/* <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtEndDate}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget> */}
                {/* START_USER_CODE-USER_AFTER_txtEndDate*/}

                {/* END_USER_CODE-USER_AFTER_txtEndDate*/}
                {/* START_USER_CODE-USER_BEFORE_ddOleicInd*/}

                {/* END_USER_CODE-USER_BEFORE_ddOleicInd*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddOleicInd}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddOleicInd*/}

                {/* END_USER_CODE-USER_AFTER_ddOleicInd*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                <ButtonWidget
                  conf={state.btnVendor}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(
                      event, handleChange,
                      handleBlur,
                      thisObj
                    )
                  }
                  onBlur={event =>
                    invokeEventHandler(
                      event,
                      handleChange,
                      handleBlur,
                      thisObj
                    )
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVndrName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(
                      event,
                      handleChange,
                      handleBlur,
                      thisObj
                    )
                  }
                ></TextBoxWidget>

                {/* START_USER_CODE-USER_BEFORE_txtPayAmount*/}

                {/* END_USER_CODE-USER_BEFORE_txtPayAmount*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPayAmount}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPayAmount*/}

                {/* END_USER_CODE-USER_AFTER_txtPayAmount*/}
                {/* START_USER_CODE-USER_BEFORE_ddRemitTo*/}

                {/* END_USER_CODE-USER_BEFORE_ddRemitTo*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddRemitTo}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddRemitTo*/}

                {/* END_USER_CODE-USER_AFTER_ddRemitTo*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSpcPayableDetails*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSpcPayableDetails*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActionsAndResults*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActionsAndResults*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget
                conf={state.grpbxActionsAndResults}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                <ButtonWidget
                  conf={state.btnSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                {/* START_USER_CODE-USER_BEFORE_lblCropYear2*/}

                {/* END_USER_CODE-USER_BEFORE_lblCropYear2*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCropYear2}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCropYear2*/}

                {/* END_USER_CODE-USER_AFTER_lblCropYear2*/}
                {/* START_USER_CODE-USER_BEFORE_btnCreateSpecialPayable*/}

                {/* END_USER_CODE-USER_BEFORE_btnCreateSpecialPayable*/}

                <ButtonWidget
                  conf={state.btnCreateSpecialPayable}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCreateSpecialPayable*/}

                {/* END_USER_CODE-USER_AFTER_btnCreateSpecialPayable*/}
                {/* START_USER_CODE-USER_BEFORE_btnOpen*/}

                {/* END_USER_CODE-USER_BEFORE_btnOpen*/}

                <ButtonWidget
                  conf={state.btnOpen}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOpen*/}

                {/* END_USER_CODE-USER_AFTER_btnOpen*/}
                {/* START_USER_CODE-USER_BEFORE_gridSearchResults*/}

                {/* END_USER_CODE-USER_BEFORE_gridSearchResults*/}

                <GridWidget
                  conf={state.gridSearchResults}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridSearchResults}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  setFieldValue={setFieldValue}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridSearchResults*/}

                {/* END_USER_CODE-USER_AFTER_gridSearchResults*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActionsAndResults*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActionsAndResults*/}

              {/* START_USER_CODE-USER_AFTER_SpecialPayablesInquiry*/}

              {/* END_USER_CODE-USER_AFTER_SpecialPayablesInquiry*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(AccountPayment_SpecialPayablesInquiry);
