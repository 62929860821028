/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  setValue,
  setData,
  getData,
  getValue,
  goTo,
  enable,
  disable,
  hide,
  show,
 getSelectedRowNumber
} from "../../shared/WindowImports";

import "./ManualRTPHistSearch.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceApplicationSupportService } from "../Service/SystemMaintenanceApplicationSupportService";
import Loading from "../../../Loader/Loading";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import moment from "moment";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_ManualRTPHistSearch(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  const useridFromLS = () => (sessionStorage.getItem('userid') || '');
  let cmdViewTag = ""
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ManualRTPHistSearch",
    windowName: "ManualRTPHistSearch",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.ManualRTPHistSearch",
    // START_USER_CODE-USER_ManualRTPHistSearch_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Ready to Pay Audit History Search",
      scrCode: "PN0210C",
    },
    // END_USER_CODE-USER_ManualRTPHistSearch_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrClose",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "cmmndCntnrSearchView",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxManualRTPHistSearch",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    btnView: {
      name: "btnView",
      type: "ButtonWidget",
      parent: "cmmndCntnrSearchView",
      Label: "View",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnView_PROPERTIES

      // END_USER_CODE-USER_btnView_PROPERTIES
    },
    cmmndCntnrSearchView: {
      name: "cmmndCntnrSearchView",
      type: "CommandContainerWidget",
      parent: "grpbxManualRTPHistSearch",
      ColSpan: "5",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_cmmndCntnrSearchView_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrSearchView_PROPERTIES
    },
    colAction: {
      name: "colAction",
      type: "GridColumnWidget",
      parent: "gridManualRTPHist",
      Label: "Action",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAction_PROPERTIES

      // END_USER_CODE-USER_colAction_PROPERTIES
    },
    colCounty: {
      name: "colCounty",
      type: "GridColumnWidget",
      parent: "gridManualRTPHist",
      Label: "County",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCounty_PROPERTIES

      // END_USER_CODE-USER_colCounty_PROPERTIES
    },
    colDate: {
      name: "colDate",
      type: "GridColumnWidget",
      parent: "gridManualRTPHist",
      Label: "Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDate_PROPERTIES

      // END_USER_CODE-USER_colDate_PROPERTIES
    },
    colFarm: {
      name: "colFarm",
      type: "GridColumnWidget",
      parent: "gridManualRTPHist",
      Label: "Farm",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFarm_PROPERTIES

      // END_USER_CODE-USER_colFarm_PROPERTIES
    },
    colNumber1: {
      name: "colNumber1",
      type: "GridColumnWidget",
      parent: "gridManualRTPHist",
      Label: "Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNumber1_PROPERTIES

      // END_USER_CODE-USER_colNumber1_PROPERTIES
    },
    colNumber2: {
      name: "colNumber2",
      type: "GridColumnWidget",
      parent: "gridManualRTPHist",
      Label: "Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNumber2_PROPERTIES

      // END_USER_CODE-USER_colNumber2_PROPERTIES
    },
    colOpt: {
      name: "colOpt",
      type: "GridColumnWidget",
      parent: "gridManualRTPHist",
      Label: "Opt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOpt_PROPERTIES

      // END_USER_CODE-USER_colOpt_PROPERTIES
    },
    colPayAmount: {
      name: "colPayAmount",
      type: "GridColumnWidget",
      parent: "gridManualRTPHist",
      Label: "Pay Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPayAmount_PROPERTIES

      // END_USER_CODE-USER_colPayAmount_PROPERTIES
    },
    colState: {
      name: "colState",
      type: "GridColumnWidget",
      parent: "gridManualRTPHist",
      Label: "State",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colState_PROPERTIES

      // END_USER_CODE-USER_colState_PROPERTIES
    },
    colType1: {
      name: "colType1",
      type: "GridColumnWidget",
      parent: "gridManualRTPHist",
      Label: "Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colType1_PROPERTIES

      // END_USER_CODE-USER_colType1_PROPERTIES
    },
    colType2: {
      name: "colType2",
      type: "GridColumnWidget",
      parent: "gridManualRTPHist",
      Label: "Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colType2_PROPERTIES

      // END_USER_CODE-USER_colType2_PROPERTIES
    },
    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxManualRTPHistSearch",
      Label: "Location:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation_PROPERTIES

      // END_USER_CODE-USER_ddLocation_PROPERTIES
    },
    ddRemitTo: {
      name: "ddRemitTo",
      type: "DropDownFieldWidget",
      parent: "grpbxManualRTPHistSearch",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRemitTo_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo_PROPERTIES
    },
    gridManualRTPHist: {
      name: "gridManualRTPHist",
      type: "GridWidget",
      parent: "grpbxManualRTPHistSearch",
      gridCellsOrder:
        "txtcolDate,txtcolAction,txtcolType1,txtcolNumber1,txtcolType2,txtcolNumber2,txtcolOpt,txtcolState,txtcolCounty,txtcolFarm,txtcolPayAmount",
      ColSpan: "5",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridManualRTPHist_PROPERTIES

      // END_USER_CODE-USER_gridManualRTPHist_PROPERTIES
    },
    txtcolAction: {
      name: "txtcolAction",
      type: "TextBoxWidget",
      colName: "colAction",
      parent: "gridManualRTPHist",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAction_PROPERTIES

      // END_USER_CODE-USER_txtcolAction_PROPERTIES
    },
    txtcolCounty: {
      name: "txtcolCounty",
      type: "TextBoxWidget",
      colName: "colCounty",
      parent: "gridManualRTPHist",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCounty_PROPERTIES

      // END_USER_CODE-USER_txtcolCounty_PROPERTIES
    },
    txtcolDate: {
      name: "txtcolDate",
      type: "TextBoxWidget",
      colName: "colDate",
      parent: "gridManualRTPHist",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDate_PROPERTIES

      // END_USER_CODE-USER_txtcolDate_PROPERTIES
    },
    txtcolFarm: {
      name: "txtcolFarm",
      type: "TextBoxWidget",
      colName: "colFarm",
      parent: "gridManualRTPHist",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFarm_PROPERTIES

      // END_USER_CODE-USER_txtcolFarm_PROPERTIES
    },
    txtcolNumber1: {
      name: "txtcolNumber1",
      type: "TextBoxWidget",
      colName: "colNumber1",
      parent: "gridManualRTPHist",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNumber1_PROPERTIES

      // END_USER_CODE-USER_txtcolNumber1_PROPERTIES
    },
    txtcolNumber2: {
      name: "txtcolNumber2",
      type: "TextBoxWidget",
      colName: "colNumber2",
      parent: "gridManualRTPHist",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNumber2_PROPERTIES

      // END_USER_CODE-USER_txtcolNumber2_PROPERTIES
    },
    txtcolOpt: {
      name: "txtcolOpt",
      type: "TextBoxWidget",
      colName: "colOpt",
      parent: "gridManualRTPHist",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOpt_PROPERTIES

      // END_USER_CODE-USER_txtcolOpt_PROPERTIES
    },
    txtcolPayAmount: {
      name: "txtcolPayAmount",
      type: "TextBoxWidget",
      colName: "colPayAmount",
      parent: "gridManualRTPHist",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPayAmount_PROPERTIES

      // END_USER_CODE-USER_txtcolPayAmount_PROPERTIES
    },
    txtcolState: {
      name: "txtcolState",
      type: "TextBoxWidget",
      colName: "colState",
      parent: "gridManualRTPHist",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolState_PROPERTIES

      // END_USER_CODE-USER_txtcolState_PROPERTIES
    },
    txtcolType1: {
      name: "txtcolType1",
      type: "TextBoxWidget",
      colName: "colType1",
      parent: "gridManualRTPHist",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolType1_PROPERTIES

      // END_USER_CODE-USER_txtcolType1_PROPERTIES
    },
    txtcolType2: {
      name: "txtcolType2",
      type: "TextBoxWidget",
      colName: "colType2",
      parent: "gridManualRTPHist",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolType2_PROPERTIES

      // END_USER_CODE-USER_txtcolType2_PROPERTIES
    },
    txtContract: {
      name: "txtContract",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPHistSearch",
      Label: "Contract #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContract_PROPERTIES

      // END_USER_CODE-USER_txtContract_PROPERTIES
    },
    txtDeliveryAgreement: {
      name: "txtDeliveryAgreement",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPHistSearch",
      Label: "Delivery Agreement #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDeliveryAgreement_PROPERTIES

      // END_USER_CODE-USER_txtDeliveryAgreement_PROPERTIES
    },
    txtFarmSuffix: {
      name: "txtFarmSuffix",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPHistSearch",
      Label: "Farm Suffix #:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarmSuffix_PROPERTIES

      // END_USER_CODE-USER_txtFarmSuffix_PROPERTIES
    },
    txtFarm: {
      name: "txtFarm",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPHistSearch",
      Label: "Farm #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarm_PROPERTIES

      // END_USER_CODE-USER_txtFarm_PROPERTIES
    },
    txtFreightMemo: {
      name: "txtFreightMemo",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPHistSearch",
      Label: "Freight Memo #:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFreightMemo_PROPERTIES

      // END_USER_CODE-USER_txtFreightMemo_PROPERTIES
    },
    txtProceeds1: {
      name: "txtProceeds1",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPHistSearch",
      Label: "Proceeds $:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 12 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtProceeds1_PROPERTIES

      // END_USER_CODE-USER_txtProceeds1_PROPERTIES
    },
    txtSC95: {
      name: "txtSC95",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPHistSearch",
      Label: "SC95 #:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSC95_PROPERTIES

      // END_USER_CODE-USER_txtSC95_PROPERTIES
    },
    txtSettlement1007: {
      name: "txtSettlement1007",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPHistSearch",
      Label: "Settlement(1007)#:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSettlement1007_PROPERTIES

      // END_USER_CODE-USER_txtSettlement1007_PROPERTIES
    },
    txtTotalProceeds: {
      name: "txtTotalProceeds",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPHistSearch",
      Label: "Total Proceeds $:",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalProceeds_PROPERTIES

      // END_USER_CODE-USER_txtTotalProceeds_PROPERTIES
    },
    txtTradeInspection: {
      name: "txtTradeInspection",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPHistSearch",
      Label: "Trade Inspection #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradeInspection_PROPERTIES

      // END_USER_CODE-USER_txtTradeInspection_PROPERTIES
    },
    txtTradeSettlement: {
      name: "txtTradeSettlement",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPHistSearch",
      Label: "Trade Settlement #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradeSettlement_PROPERTIES

      // END_USER_CODE-USER_txtTradeSettlement_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPHistSearch",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtVndr: {
      name: "txtVndr",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPHistSearch",
      ColSpan: "2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVndr_PROPERTIES

      // END_USER_CODE-USER_txtVndr_PROPERTIES
    },
    txtWarehouseReceipt: {
      name: "txtWarehouseReceipt",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPHistSearch",
      Label: "Whse Receipt #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWarehouseReceipt_PROPERTIES

      // END_USER_CODE-USER_txtWarehouseReceipt_PROPERTIES
    },
    grpbxManualRTPHistSearch: {
      name: "grpbxManualRTPHistSearch",
      type: "GroupBoxWidget",
      parent: "ManualRTPHistSearch",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxManualRTPHistSearch_PROPERTIES

      // END_USER_CODE-USER_grpbxManualRTPHistSearch_PROPERTIES
    },
    cmmndCntnrClose: {
      name: "cmmndCntnrClose",
      type: "CommandContainerWidget",
      parent: "ManualRTPHistSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrClose_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrClose_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceApplicationSupport#ManualRTPHistView":{},
       },
      REVERSE:{
        "SystemMaintenanceApplicationSupport#ManualRTPHistView":{},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnView:{
        DEFAULT:[
        "SystemMaintenanceApplicationSupport#ManualRTPHistView#DEFAULT#true#Click",]
      },
  };
  const [loading, setLoading] = useState(false);
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
 
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
     formLoad();
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  const [selectedRowNum, setSelectedRowNum] = useState(-1);
  useEffect(() => {
    let vendor_lookup = getData(thisObj, 'vendorDetails')
    if (vendor_lookup !== null && vendor_lookup !== undefined && vendor_lookup !== '') {
      thisObj.setFieldValue("txtVendor", ConvertToPascalCase(vendor_lookup.VendorNumber));
      let VenNumber = vendor_lookup.VendorNumber
      bFillRemitToList(vendor_lookup.VendorNumber)
      thisObj.setFieldValue("txtVndr", vendor_lookup.vendorName)
      let js = [];
      js.push({ key: '', description: '>>>ALL<<<' }) //Set default value
        ContractManagementService.RetieveRemitDetails(VenNumber).then(response => {
          if (response != undefined && response.length > 0) {
            let data = response
            for (var i = 0; i < data.length; i++) {
              var vendorName = data[i].name
              let obj = { key: data[i].remittoid, description: data[i].remittoid + " - " + ConvertToPascalCase(data[i].name) + " - " + ConvertToPascalCase(data[i].city) + " - " + ConvertToPascalCase(data[i].state) }
              js.push(obj)
            }
            setValue(thisObj, "ddRemitTo", js.at(0).key);
            setData(thisObj, "remitToLength", js.length);
            setData(thisObj, 'vendorName', vendorName)
          }
        })
      thisObj.setState(current => {
        return {
          ...current,
          ddRemitTo: {
            ...state["ddRemitTo"],
            valueList: js,
          }
        }
      })
      setData(thisObj, "vendorDetails",null)
    }
  },[getData(thisObj, 'vendorDetails')]);

  const [mbRefresh, setMbRefresh] = useState(false);
  let cropyear = JSON.parse(sessionStorage.getItem('year'));
  let compId = sessionStorage.getItem('compId');

  async function formLoad() {
    setLoading(true);
    disable(thisObj,'txtVndr')
    disable(thisObj,'txtTotalProceeds')
    let mbSuccessfulLoad =await bFillLocations(true)
    if (!mbSuccessfulLoad) {
      document.getElementById("SystemMaintenanceApplicationSupport_ManualRTPHistSearchPopup").childNodes[0].click()
    }
    CheckSecurity()
    setLoading(false);
  }
   //Dropddown for  Location List

  async function bFillLocations(blnSecure) {
    try {
      let data = {};
      if (blnSecure) {
        data = await ContractManagementService.RetrieveBuyingPointControlDetails('PN0210', null, null, null, null)
      } else {
        data = await ContractManagementService.RetrieveBuyingPointControlList(compId(), cropyear(), null, null, null)
      }
      if (data === undefined || data == [] || data == null || data.length == 0) {
        return false;
      } else {
        let js = [];
        js.push({ key: '', description: '>>> All Locations <<<' });
        for (let i = 0; i < data.length; i++) {
          js.push({
            key: data[i].buy_pt_id,
            description: data[i].buy_pt_id.trim() + '-' + ConvertToPascalCase(data[i].buy_pt_name).trim(), buy_pt_name: ConvertToPascalCase(data[i].buy_pt_name).trim() 
          })
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddLocation: {
              ...state['ddLocation'],
              valueList: js
            }
          }
        })
        setValue(thisObj, "ddLocation", js.at(0).key)
      }
      return true
    }  
    catch (error) {
      errorHandler(error)
      return false;
    }
  }

  function ConvertToPascalCase(word){
    return word.replace(/(\w)(\w*)/g, function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
  }

   //Security fuction

async function CheckSecurity () {
  try {
    disable(thisObj, "btnView")
  let response = await ContractManagementService.RetrieveAccessPermissionDetails("PN0210",null,"I",null)
  if(response?.length > 0){
    setData(thisObj, 'cmdViewTag', response[0]?.permission?.toLocaleUpperCase())
  }
  if (response[0]?.permission?.toLocaleUpperCase() == 'Y'){ 
    enable(thisObj, "btnView")
  }
  else{
    disable(thisObj, "btnView")
  }
} 
 catch (err) {
  if (err instanceof EvalError) {
    showMessage(thisObj, err.message);
  } else {
    showMessage(
      thisObj,
      "Error occurred during CheckSecurity event"
    );
  }
  return false;
}
return true;
}

// errorHandler fuction

function errorHandler(err){
  showMessage(thisObj, err instanceof EvalError ? err.message : 'Something went wrong. Please try again.')
}

  // method for vendor on Change event

const ontxtVendorBlur = async() => {
  let txtVendorNumber = txtVendor.value
  txtVendorNumber=getValue(thisObj,'txtVendor')
  if (txtVendorNumber != undefined && txtVendorNumber != null && txtVendorNumber.length == 6) {
    await bFillRemitToList(txtVendorNumber)
  } else {
      setValue(thisObj,'txtVndr','')
      disable(thisObj,'txtVndr')
    document.getElementsByClassName("txtVndr")[0].style.visibility = "hidden";
  }
  let js = [];
  if (txtVendorNumber != undefined && txtVendorNumber != null &&txtVendorNumber.length == 6) {
    await ContractManagementService.RetieveRemitDetails(txtVendorNumber).then(response => {
      if (response != undefined && response.length > 0) {
        let data = response
        js.push({ key: '', description: '>>>ALL<<<' }) 
        for (var i = 0; i < data.length; i++) {
          var vendorName =ConvertToPascalCase(data[i].name)
          let obj = { key: data[i].remittoid, 
            description: data[i].remittoid + " - " + ConvertToPascalCase(data[i].name) + " - " + ConvertToPascalCase(data[i].city) + " - " + ConvertToPascalCase(data[i].state) }
          js.push(obj)
        }
       setData(thisObj, 'vendorName',vendorName );
       setData(thisObj, "remitToLength", js.length);
       setValue(thisObj, "ddRemitTo", js.length > 0 ? js.at(0).key : '' );
      }
    })
  }
  thisObj.setState(current => {
    return {
      ...current,
      ddRemitTo: {
        ...state["ddRemitTo"],
        valueList: js,
      }
    }
  })
  setData(thisObj, "remitToLength",null);
  }
thisObj.ontxtVendorBlur = ontxtVendorBlur;  
  
//seaching vendorname based on vendorname 

const bFillRemitToList = async(txtVendorNumber) => {
  try {
  let venname = null
  let vendornamevisible = false
  await ContractManagementService.RetrieveVendorByNumber(txtVendorNumber).then(response => {
    let data = response
    if (data !== null && data !== undefined && data.length > 0) {
      venname = data[0].name
      vendornamevisible = true
    }
    if (vendornamevisible == true) {
      document.getElementsByClassName("txtVndr")[0].style.visibility = "visible";
      setValue(thisObj, "txtVndr", ConvertToPascalCase(venname))
      disable(thisObj,'txtVndr')
    } else {
      document.getElementsByClassName("txtVndr")[0].style.visibility = "hidden";
      setValue(thisObj, "txtVndr", '')
    }
  })
}
catch (err) {
  if (err instanceof EvalError) {
    showMessage(thisObj, err.message);
  } else {
    showMessage(
      thisObj,
      "Error occurred in setting value to Vendor Number textbox"
    );
  }
}
};

 //Click event of Add Account Vendor button

 const onbtnVendorClick = async () => {
  try{
   goTo(thisObj, "ContractManagement#VendorLookup#DEFAULT#true#Click");
  }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message)
    } else {
      showMessage(thisObj, "Error on Vendor click event")
    }
    return false
  }
};
 thisObj.onbtnVendorClick = onbtnVendorClick;

 //Formvalid function

 const bFormValid = async () => {
  setLoading(true);
  try {
    var bFormValid = false
    let locationList = getValue(thisObj, "ddLocation")
    let vendor = getValue(thisObj, "txtVendor")
    let contractNumber = getValue(thisObj, "txtContract")
    let FV95Number = getValue(thisObj, "txtSC95")
    let Num1007 = getValue(thisObj, "txtSettlement1007")
    let TradeInspNum = getValue(thisObj, "txtTradeInspection")
    let TradeSettleNum = getValue(thisObj, "txtTradeSettlement")
    let WhseRcptNum = getValue(thisObj, "txtWarehouseReceipt")
    let FarmID = getValue(thisObj, "txtFarm")
    let FarmSuffix = getValue(thisObj, "txtFarmSuffix")
    let Proceeds = getValue(thisObj, "txtProceeds1")
    let FreightMemoNumber = getValue(thisObj, "txtFreightMemo")
    let DelvAgree = getValue(thisObj, "txtDeliveryAgreement")
    var item = [
      "Location",
      "Vendor #",
      "Contract #",
      "SC95 #",
      "1007 #",
      "Freight Memo #",
      "Trade Insp #",
      "Trade Settle #",
      "Whse Receipt #",
      "Farm #",
      "Farm Suffix #",
      "Delivery Agreement #",
      "Prceeds $"
    ]
    var alertmessage = "You must specify one of the following:\n";
    for (var i = 0; i < item?.length; i++) {
      alertmessage += (i + 1) + '. ' + item[i] + "\n";
    }
    if ((locationList == "" || locationList == undefined || locationList == null) &&
      (vendor == "" || vendor == undefined || vendor == null) &&
      (contractNumber == "" || contractNumber == undefined || contractNumber == null) &&
      (FV95Number == "" || FV95Number == undefined || FV95Number == null) &&
      (Num1007 == "" || Num1007 == undefined || Num1007 == null) &&
      (TradeInspNum == "" || TradeInspNum == undefined || TradeInspNum == null) &&
      (TradeSettleNum == "" || TradeSettleNum == undefined || TradeSettleNum == null) &&
      (WhseRcptNum == "" || WhseRcptNum == undefined || WhseRcptNum == null) &&
      (FarmID == "" || FarmID == undefined || FarmID == null) &&
      (FarmSuffix == "" || FarmSuffix == undefined || FarmSuffix == null) &&
      (Proceeds == "" || Proceeds == undefined || Proceeds == null) &&
      (FreightMemoNumber == "" || FreightMemoNumber == undefined || FreightMemoNumber == null) &&
      (DelvAgree == "" || DelvAgree == undefined || DelvAgree == null)) {
      alert(alertmessage)
      setLoading(false);
      return;
    }
    bFormValid = true
    setLoading(false);
   return bFormValid;
  }
  catch (error) {
    setLoading(false);
    if (error instanceof EvalError) {
      showMessage(thisObj, error?.message);
    } else {
      showMessage(
        thisObj,
        "Error occurred during Validation event"
      );
    }
    return false;
  }
};

// search btn fuctionality

 const onbtnSearchClick = async() => {
    try {
    if (!await bFormValid()) return;
    let vendor = getValue(thisObj, "txtVendor")
    let contractNumber = getValue(thisObj, "txtContract")
    let FV95Number = getValue(thisObj, "txtSC95")
    let Num1007 = getValue(thisObj, "txtSettlement1007")
    let TradeInspNum = getValue(thisObj, "txtTradeInspection")
    let TradeSettleNum = getValue(thisObj, "txtTradeSettlement")
    let WhseRcptNum = getValue(thisObj, "txtWarehouseReceipt")
    let FarmID = getValue(thisObj, "txtFarm")
    let FarmSuffix = getValue(thisObj, "txtFarmSuffix")
    let Proceeds = getValue(thisObj, "txtProceeds1")
    let FreightMemoNumber = getValue(thisObj, "txtFreightMemo")
    let DelvAgree = getValue(thisObj, "txtDeliveryAgreement")
    if ((vendor == "" || vendor == undefined || vendor == null) &&
    (contractNumber == "" || contractNumber == undefined || contractNumber == null) &&
    (FV95Number == "" || FV95Number == undefined || FV95Number == null) &&
    (Num1007 == "" || Num1007 == undefined || Num1007 == null) &&
    (TradeInspNum == "" || TradeInspNum == undefined || TradeInspNum == null) &&
    (TradeSettleNum == "" || TradeSettleNum == undefined || TradeSettleNum == null) &&
    (WhseRcptNum == "" || WhseRcptNum == undefined || WhseRcptNum == null) &&
    (FarmID == "" || FarmID == undefined || FarmID == null) &&
    (FarmSuffix == "" || FarmSuffix == undefined || FarmSuffix == null) &&
    (Proceeds == "" || Proceeds == undefined || Proceeds == null) &&
    (FreightMemoNumber == "" || FreightMemoNumber == undefined || FreightMemoNumber == null) &&
    (DelvAgree == "" || DelvAgree == undefined || DelvAgree == null)) {
      if (window.confirm("Searching by 'Location Only' may take awhile to return results.\n\nDo you wish to continue?") == true)  {
         await openGrid();
      } 
    }
    else {
      await openGrid();
    }
    
  }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:btnSearch: Click"
        )
      }
      setLoading(false);
      return false
    }
    return true

  };
  thisObj.onbtnSearchClick = onbtnSearchClick;

  // Grid Functionality

   const openGrid = async() => {
    try {
      let totalProceeds = 0;
      let buy_pt_id = getValue(thisObj, "ddLocation", '')
      let payee_vendor = getValue(thisObj, "txtVendor", '')
      let payee_remit = getValue(thisObj, "ddRemitTo", '')
      let audit_cont_num = getValue(thisObj, "txtContract", '')
      let audit_insp_num = getValue(thisObj, "txtSC95", '')
      let audit_settle_num = getValue(thisObj, "txtSettlement1007", '')
      let freight_memo_num = getValue(thisObj, "txtFreightMemo", '')
      let audit_trade_insp = getValue(thisObj, "txtTradeInspection", '')
      let audit_trade_settle = getValue(thisObj, "txtTradeSettlement", '')
      let audit_whse_rcpt = getValue(thisObj, "txtWarehouseReceipt", '')
      let audit_farm_id = getValue(thisObj, "txtFarm", '')
      let audit_farm_suffix = getValue(thisObj, "txtFarmSuffix", '')
      let audit_delv_agree = getValue(thisObj, "txtDeliveryAgreement", '')
      let rtp_proceeds = getValue(thisObj, "txtProceeds1", '')
      let special_type_none = 'NONE'
      let rtp_status_hr = 'HR'
      setLoading(true);
      let data = await SystemMaintenanceApplicationSupportService.RetrieveManualReadyToPaymentHistoryDetails(buy_pt_id,payee_vendor,payee_remit,audit_cont_num,audit_insp_num,audit_settle_num,freight_memo_num, audit_trade_insp,audit_trade_settle,audit_whse_rcpt,audit_farm_id,audit_farm_suffix,audit_delv_agree, rtp_proceeds,special_type_none,rtp_status_hr) 
      if (data.length == 0) {
        alert("An error occured while communicating or updating with the database. Please contact your system administrator if the problem persist.\n\n\nNo RTPs found for specified criteria!")
        setValue(thisObj, "gridManualRTPHist", [])
        setValue(thisObj, "txtTotalProceeds", [])
        disable(thisObj,"btnView")
        setLoading(false);
        return;
      }
        let TransformedRowsArray = [];
      if (data != undefined && data.length > 0) {
          let obj = {};
          for (let i = 0; i < data.length; i++) {
            obj.transaction_num= data[i].transaction_num
            obj.audit_date_time= data[i].audit_date_time
            obj.txtcolDate =moment(data[i].audit_date_time).format("MM/DD/YYYY HH:mm");
            obj.txtcolAction = data[i].audit_action
            obj.txtcolType1 = data[i].pay_type_1
            obj.txtcolNumber1 = data[i].pay_number_1
            obj.txtcolType2 = data[i].pay_type_2
            obj.txtcolNumber2 = data[i].pay_number_2
            obj.txtcolOpt = data[i].pay_option_ind
            obj.txtcolState = data[i].pay_state
            obj.txtcolCounty = data[i].county_name
            obj.txtcolFarm = data[i].pay_farm+ ' - ' +data[i].pay_farm_suffix+ ' - ' +data[i].farm_name
            obj.txtcolPayAmount = data[i].rtp_proceeds
           totalProceeds+= Number(data[i].rtp_proceeds)
           TransformedRowsArray.push(obj)
            obj = {}
          }
          if(data.length > 0){
            TransformedRowsArray.push({
              txtcolDate : "",
              txtcolType1 : "",
              txtcolNumber1 : "",
              txtcolType2 : "",
              txtcolNumber2 : "",
              txtcolState : "",
              txtcolCounty : "",
              txtcolFarm : "",
             txtcolAction : "Totals:",
             txtcolPayAmount : totalProceeds == 0 ? '0.00' : Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(totalProceeds)),
            })
           }
          setValue(thisObj, "gridManualRTPHist", TransformedRowsArray);
          setData(thisObj, "gridManualRTPHist", TransformedRowsArray);
          setValue(thisObj, "txtTotalProceeds",totalProceeds == 0 ? '0.00' : Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(totalProceeds)),);
           setData(thisObj, "txtPayAmount", totalProceeds);
           setLoading(false);
        }
        if(TransformedRowsArray?.length > 1){
          if(getData(thisObj, 'cmdViewTag') == "Y"){
            enable(thisObj, "btnView")
          }
          else{
            disable(thisObj, "btnView")
          }
        }
        if (TransformedRowsArray.length > 0) {
          setSelectedRowNum(0);
          TransformedRowsArray[0].isSelected = true;
        }
       setData(thisObj, 'gridManualRTPHist', TransformedRowsArray);
        thisObj.setState((current) => {
          return {
            ...current,
            gridManualRTPHist: {
              ...current["gridManualRTPHist"],
              valueList: TransformedRowsArray
            },
          }
        });
      }
      catch (err) {
        if (err instanceof EvalError) {
          showMessage(thisObj, err.message)
        }
        else {
          showMessage( thisObj, "Exception in Presubmit of event code of widget:btnSearch: Click")
        }
        setLoading(false);
        return false
      }
      return true
     };
   
    const selectRow = (newRowNum) => {
      const gridData = getValue(thisObj, 'gridManualRTPHist');
      if (newRowNum !== undefined && selectedRowNum !== undefined && gridData?.length) {
        gridData[selectedRowNum].isSelected = false;
        setSelectedRowNum(newRowNum);
        return(newRowNum)
      }
    }
    const ongridManualRTPHistRowSelect = () => {
      const rowNum = getSelectedRowNumber(thisObj, 'gridManualRTPHist');
      let value = getValue(thisObj, 'gridManualRTPHist' )
      if(value!=null&&value!=undefined){
        if(rowNum==(value.length-1))
        {
         thisObj.state.gridManualRTPHist.selected[0]=null
         return;
        }
      }
      selectRow(rowNum);
    }
    thisObj.ongridManualRTPHistRowSelect = ongridManualRTPHistRowSelect

    const onGridRowChange = (rowSelected) => {
      let data3 = rowSelected;
      if (data3.selectedRows) {
        data3 = data3.selectedRows[0];
      }
    };
    thisObj.onGridRowChange = onGridRowChange;
  
    //doubleclick
 
  const ongridManualRTPHistDblclick = () => {
    try {
      let gridData = getValue(thisObj, 'gridManualRTPHist');
      let rowNum = getSelectedRowNumber(thisObj, 'gridManualRTPHist');
       if (rowNum == undefined) {
      rowNum = 0;
      return;
       }
      let frmManualRTPHistView = {}
      frmManualRTPHistView.lblTotalProceeds_Caption = ""
      frmManualRTPHistView.lblTransactionNumber = gridData[rowNum].transaction_num;
      frmManualRTPHistView.txtAuditDateTime = gridData[rowNum].audit_date_time;
      setData(thisObj, 'frmManualRTPHistView', frmManualRTPHistView)
      goTo(thisObj, "SystemMaintenanceApplicationSupport#ManualRTPHistView#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during View button"
        );
      }
      return false;
    }
 
    return true;
   };
  thisObj.ongridManualRTPHistDblclick = ongridManualRTPHistDblclick;

     //View btn Fuctionality

     const onbtnViewClick = () => {
      try {
        let gridData = getValue(thisObj, 'gridManualRTPHist');
        let rowNum = getSelectedRowNumber(thisObj, 'gridManualRTPHist');
         if (rowNum == undefined) {
        rowNum = 0;
        return;
         }
        let frmManualRTPHistView = {}
        frmManualRTPHistView.lblTotalProceeds_Caption = ""
        frmManualRTPHistView.lblTransactionNumber = gridData[rowNum].transaction_num;
        frmManualRTPHistView.txtAuditDateTime = gridData[rowNum].audit_date_time;
        setData(thisObj, 'frmManualRTPHistView', frmManualRTPHistView)
        goTo(thisObj, "SystemMaintenanceApplicationSupport#ManualRTPHistView#DEFAULT#true#Click")
      }
      catch (err) {
        if (err instanceof EvalError) {
          showMessage(thisObj, err.message);
        }
        else {
          showMessage(
            thisObj,
            "Error occurred during View button"
          );
        }
        return false;
      }
   
      return true;
     };
    thisObj.onbtnViewClick = onbtnViewClick;

    const ontxtFreightMemoChange = () => {
      let FreigtmemoValue = getValue(thisObj, 'txtFreightMemo')
      setValue(thisObj, "txtFreightMemo", validateTextBox1(FreigtmemoValue))
    };
    thisObj.ontxtFreightMemoChange = ontxtFreightMemoChange
    const validateTextBox1 = (data) => {
      var res = ''
      for (var i = 0; i < data.length; i++) {
        if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 46)) {
          res += data[i]
        }
      }
      return res;
    }

    const ontxtDeliveryAgreementChange = () => {
      let DeliveryAgreementValue = getValue(thisObj, 'txtDeliveryAgreement')
      setValue(thisObj, "txtDeliveryAgreement", validateTextBox2(DeliveryAgreementValue))
    };
    thisObj.ontxtDeliveryAgreementChange = ontxtDeliveryAgreementChange
    const validateTextBox2 = (data) => {
      var res = ''
      for (var i = 0; i < data.length; i++) {
        if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 46)) {
          res += data[i]
        }
      }
      return res;
    }

      // Blur event for Proceeds box-----

  const ontxtProceeds1Blur = (value) => {
    try {
      let rate = document.getElementById("txtProceeds1").value
      if (rate.length != 0 && rate.length < 10 && isNaN(rate)==false){
        rate = Number(rate).toFixed(2)
      } else if (rate.length == 10) {
        rate = Number(rate).toFixed(1)
      } else if (rate.length == 11) {
        rate = rate + '.'
      }
      setValue(thisObj, 'txtProceeds1', rate);
       
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for txt Proceeds."
        );
      }
      return false;
    }
  };
  thisObj.ontxtProceeds1Blur = ontxtProceeds1Blur;
  const ontxtProceeds1Change = () => {
    let txtCR = getValue(thisObj, "txtProceeds1");
    setValue(thisObj, "txtProceeds1", validateTextBox3(txtCR));
    
  };
  thisObj.ontxtProceeds1Change = ontxtProceeds1Change;
  const validateTextBox3 = (data) => {
    var res = "";
    for (var i = 0; i < data.length; i++) {
      if (
        (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) ||
        data[i].charCodeAt(0) == 46 || data[i].charCodeAt(0) == 45
      ) {
        res += data[i];
      }
    }
    return res;
  };

   
    //Close btn Functionality

    const onbtnCloseClick = () => {
      try {
        document.getElementById("SystemMaintenanceApplicationSupport_ManualRTPHistSearchPopUp").childNodes[0].click()
      } catch (err) {
        if (err instanceof EvalError) {
          showMessage(thisObj, err.message);
        } else {
          showMessage(
            thisObj,
            "Exception in PreSubmit Event code for widget:btnClose event:Click"
          );
        }
        return false;
      }
      return true;
    };
    thisObj.onbtnCloseClick = onbtnCloseClick;

   
  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
             <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ManualRTPHistSearch*/}

              {/* END_USER_CODE-USER_BEFORE_ManualRTPHistSearch*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxManualRTPHistSearch*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxManualRTPHistSearch*/}

              <GroupBoxWidget
                conf={state.grpbxManualRTPHistSearch}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddLocation*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddLocation*/}
                {/* START_USER_CODE-USER_BEFORE_txtTradeInspection*/}

                {/* END_USER_CODE-USER_BEFORE_txtTradeInspection*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTradeInspection}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTradeInspection*/}

                {/* END_USER_CODE-USER_AFTER_txtTradeInspection*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                {/* START_USER_CODE-USER_BEFORE_ddRemitTo*/}

                {/* END_USER_CODE-USER_BEFORE_ddRemitTo*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddRemitTo}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddRemitTo*/}

                {/* END_USER_CODE-USER_AFTER_ddRemitTo*/}
                {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                <ButtonWidget
                  conf={state.btnVendor}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                {/* START_USER_CODE-USER_BEFORE_txtTradeSettlement*/}

                {/* END_USER_CODE-USER_BEFORE_txtTradeSettlement*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTradeSettlement}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTradeSettlement*/}

                {/* END_USER_CODE-USER_AFTER_txtTradeSettlement*/}
                {/* START_USER_CODE-USER_BEFORE_txtContract*/}

                {/* END_USER_CODE-USER_BEFORE_txtContract*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtContract}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtContract*/}

                {/* END_USER_CODE-USER_AFTER_txtContract*/}
                {/* START_USER_CODE-USER_BEFORE_txtVndr*/}

                {/* END_USER_CODE-USER_BEFORE_txtVndr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVndr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVndr*/}

                {/* END_USER_CODE-USER_AFTER_txtVndr*/}
                {/* START_USER_CODE-USER_BEFORE_txtWarehouseReceipt*/}

                {/* END_USER_CODE-USER_BEFORE_txtWarehouseReceipt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWarehouseReceipt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWarehouseReceipt*/}

                {/* END_USER_CODE-USER_AFTER_txtWarehouseReceipt*/}
                {/* START_USER_CODE-USER_BEFORE_txtSC95*/}

                {/* END_USER_CODE-USER_BEFORE_txtSC95*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSC95}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSC95*/}

                {/* END_USER_CODE-USER_AFTER_txtSC95*/}
                {/* START_USER_CODE-USER_BEFORE_txtFarm*/}

                {/* END_USER_CODE-USER_BEFORE_txtFarm*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarm}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFarm*/}

                {/* END_USER_CODE-USER_AFTER_txtFarm*/}
                {/* START_USER_CODE-USER_BEFORE_txtFarmSuffix*/}

                {/* END_USER_CODE-USER_BEFORE_txtFarmSuffix*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarmSuffix}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFarmSuffix*/}

                {/* END_USER_CODE-USER_AFTER_txtFarmSuffix*/}
                {/* START_USER_CODE-USER_BEFORE_txtSettlement1007*/}

                {/* END_USER_CODE-USER_BEFORE_txtSettlement1007*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSettlement1007}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSettlement1007*/}

                {/* END_USER_CODE-USER_AFTER_txtSettlement1007*/}
                {/* START_USER_CODE-USER_BEFORE_txtDeliveryAgreement*/}

                {/* END_USER_CODE-USER_BEFORE_txtDeliveryAgreement*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDeliveryAgreement}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDeliveryAgreement*/}

                {/* END_USER_CODE-USER_AFTER_txtDeliveryAgreement*/}
                {/* START_USER_CODE-USER_BEFORE_txtFreightMemo*/}

                {/* END_USER_CODE-USER_BEFORE_txtFreightMemo*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFreightMemo}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFreightMemo*/}

                {/* END_USER_CODE-USER_AFTER_txtFreightMemo*/}
                {/* START_USER_CODE-USER_BEFORE_txtProceeds1*/}

                {/* END_USER_CODE-USER_BEFORE_txtProceeds1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtProceeds1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtProceeds1*/}

                {/* END_USER_CODE-USER_AFTER_txtProceeds1*/}
                {/* START_USER_CODE-USER_BEFORE_cmmndCntnrSearchView*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndCntnrSearchView*/}

                <CommandContainerWidget
                  conf={state.cmmndCntnrSearchView}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                  <ButtonWidget
                    conf={state.btnSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                  {/* START_USER_CODE-USER_BEFORE_btnView*/}

                  {/* END_USER_CODE-USER_BEFORE_btnView*/}

                  <ButtonWidget
                    conf={state.btnView}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnView*/}

                  {/* END_USER_CODE-USER_AFTER_btnView*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndCntnrSearchView*/}

                {/* END_USER_CODE-USER_AFTER_cmmndCntnrSearchView*/}
                {/* START_USER_CODE-USER_BEFORE_gridManualRTPHist*/}

                {/* END_USER_CODE-USER_BEFORE_gridManualRTPHist*/}

                <GridWidget
                  conf={state.gridManualRTPHist}
                  screenConf={state}
                  linkClick={event =>{
                    if (state.gridManualRTPHist.selected.length > 0) {
                      thisObj.selectedRow = state.gridManualRTPHist.selected[0];
                    }
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridManualRTPHist}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onRowChange={selectedRow => onGridRowChange(selectedRow)}
                  setFieldValue={setFieldValue}
                  refObject={thisObj}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridManualRTPHist*/}

                {/* END_USER_CODE-USER_AFTER_gridManualRTPHist*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalProceeds*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalProceeds*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalProceeds}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalProceeds*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalProceeds*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxManualRTPHistSearch*/}

              {/* END_USER_CODE-USER_AFTER_grpbxManualRTPHistSearch*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrClose*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrClose}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrClose*/}

              {/* START_USER_CODE-USER_AFTER_ManualRTPHistSearch*/}

              {/* END_USER_CODE-USER_AFTER_ManualRTPHistSearch*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceApplicationSupport_ManualRTPHistSearch
);
