/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  CheckboxWidget,
  setValue,
  getValue,
  hideColumn,
  goTo,
  setData,
  getData,
  editRow,
  getGridValue,
  setGridValue
} from "../../shared/WindowImports";

import "./PremiumDeductions.scss";

// START_USER_CODE-USER_IMPORTS
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService"
import { SettlementService } from "../Service/SettlementService";
import Loading from "../../../Loader/Loading";
import styled from "styled-components";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import moment from "moment";
import NullifyCheck from "../../AccountPayment/NullifyCheck/NullifyCheck";
import ContractManagement_header from "../../Header/Header";
import { bool, number } from "yup";
// END_USER_CODE-USER_IMPORTS
function Settlements_PremiumDeductions(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "PremiumDeductions",
    windowName: "PremiumDeductions",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.PremiumDeductions",
    // START_USER_CODE-USER_PremiumDeductions_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Premiums/Deductions",
      scrCode: "PN1050D",
    },
    // END_USER_CODE-USER_PremiumDeductions_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnCol: {
      name: "btnCol",
      type: "ButtonWidget",
      colName: "colButton",
      parent: "gridPremiumDeductions",
      CharWidth: "4",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCol_PROPERTIES

      // END_USER_CODE-USER_btnCol_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    chkboxcol: {
      name: "chkboxcol",
      type: "CheckBoxWidget",
      colName: "colCheckbox",
      parent: "gridPremiumDeductions",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxcol_PROPERTIES
      isMultiSelectAlternate: true
      // END_USER_CODE-USER_chkboxcol_PROPERTIES
    },
    colButton: {
      name: "colButton",
      type: "GridColumnWidget",
      parent: "gridPremiumDeductions",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colButton_PROPERTIES

      // END_USER_CODE-USER_colButton_PROPERTIES
    },
    colCheckbox: {
      name: "colCheckbox",
      type: "GridColumnWidget",
      parent: "gridPremiumDeductions",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCheckbox_PROPERTIES

      // END_USER_CODE-USER_colCheckbox_PROPERTIES
    },
    colPremDeduct: {
      name: "colPremDeduct",
      type: "GridColumnWidget",
      parent: "gridPremiumDeductions",
      Label: "Premium/Deduction",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPremDeduct_PROPERTIES

      // END_USER_CODE-USER_colPremDeduct_PROPERTIES
    },
    colPurchaseAmount: {
      name: "colPurchaseAmount",
      type: "GridColumnWidget",
      parent: "gridPremiumDeductions",
      Label: "Purchase Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPurchaseAmount_PROPERTIES

      // END_USER_CODE-USER_colPurchaseAmount_PROPERTIES
    },
    colStorageAmount: {
      name: "colStorageAmount",
      type: "GridColumnWidget",
      parent: "gridPremiumDeductions",
      Label: "Storage Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStorageAmount_PROPERTIES

      // END_USER_CODE-USER_colStorageAmount_PROPERTIES
    },
    colPdCode: {
      name: "colPdCode",
      type: "GridColumnWidget",
      parent: "gridPremiumDeductions",
      Label: "Pd_Code",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPdCode_PROPERTIES
      Visible: false
      // END_USER_CODE-USER_colPdCode_PROPERTIES
    },


    colRateTypeArr: {
      name: "colRateTypeArr",
      type: "GridColumnWidget",
      parent: "gridPremiumDeductions",
      Label: "Rate Type Array",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRateTypeArr_PROPERTIES
      Visible: false
      // END_USER_CODE-USER_colRateTypeArr_PROPERTIES

    },

    colReqIndArr: {
      name: "colReqIndArr",
      type: "GridColumnWidget",
      parent: "gridPremiumDeductions",
      Label: "Required Ind Array",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colReqIndArr_PROPERTIES
      Visible: false,
      // END_USER_CODE-USER_colReqIndArr_PROPERTIES

    },

    colIsSts: {
      name: "colIsSts",
      type: "TextBoxWidget",
      colName: "colSts",
      parent: "gridPremiumDeductions",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colIsSts_PROPERTIES

      // END_USER_CODE-USER_colIsSts_PROPERTIES
    },
    colSts: {
      name: "colSts",
      type: "GridColumnWidget",
      parent: "gridPremiumDeductions",
      isStatusColumn: true,
      HasLabel: false,
      Visible: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSts_PROPERTIES
      // END_USER_CODE-USER_colSts_PROPERTIES
    },


    colPECellCheckArr: {
      name: "colPECellCheckArr",
      type: "GridColumnWidget",
      parent: "gridPremiumDeductions",
      Label: "Storage Amount",
      Height: "",
      Width: "",

      // START_USER_CODE-USER_colhpavbYlwArr_PROPERTIES
      Visible: false
      // END_USER_CODE-USER_colhpavbYlwArr_PROPERTIES

    },
    colSECellCheckArr: {
      name: "colSECellCheckArr",
      type: "GridColumnWidget",
      parent: "gridPremiumDeductions",
      Label: "Storage Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colhsavbYlwArr_PROPERTIES
      Visible: false
      // END_USER_CODE-USER_colhsavbYlwArr_PROPERTIES
    },


    colhpavbYlwArr: {
      name: "colhpavbYlwArr",
      type: "GridColumnWidget",
      parent: "gridPremiumDeductions",
      Label: "Storage Amount",
      Height: "",
      Width: "",

      // START_USER_CODE-USER_colhpavbYlwArr_PROPERTIES
      Visible: false
      // END_USER_CODE-USER_colhpavbYlwArr_PROPERTIES

    },
    colhsavbYlwArr: {
      name: "colhsavbYlwArr",
      type: "GridColumnWidget",
      parent: "gridPremiumDeductions",
      Label: "Storage Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colhsavbYlwArr_PROPERTIES
      Visible: false
      // END_USER_CODE-USER_colhsavbYlwArr_PROPERTIES
    },




    gridPremiumDeductions: {
      name: "gridPremiumDeductions",
      type: "GridWidget",
      parent: "grpbxPremiumDeductions",
      gridCellsOrder:
        "btnCol,chkboxcol,txtcolPremDeduct,txtcolPurchaseAmount,txtcolStorageAmount,txtcolPdCode,txtcolRateTypeArr,txtcolReqIndArr,colIsSts,txtcolPECellCheckArr,txtcolSECellCheckArr,txtcolhpavbYlwArr,txtcolhsavbYlwArrr",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      isEditable: true,
      toggleRender: true,
      // isMultiSelect: true,
      // START_USER_CODE-USER_gridPremiumDeductions_PROPERTIES
      isCobolTable: true,
      conditionalRowStyles: [
        {
          when: (row) => { return checkIfAtleastOneTrue(row) },
          style: (row) => (setStyles(row))
        },

      ],
      // END_USER_CODE-USER_gridPremDed_PROPERTIES
    }


    ,
    lblPremDeduct: {
      name: "lblPremDeduct",
      type: "LabelWidget",
      parent: "grpbxPremiumDeductions",
      Label: "Premiums/Deductions:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPremDeduct_PROPERTIES

      // END_USER_CODE-USER_lblPremDeduct_PROPERTIES
    },
    txtcolPremDeduct: {
      name: "txtcolPremDeduct",
      type: "TextBoxWidget",
      colName: "colPremDeduct",
      parent: "gridPremiumDeductions",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolPremDeduct_PROPERTIES

      // END_USER_CODE-USER_txtcolPremDeduct_PROPERTIES
    },
    txtcolPurchaseAmount: {
      name: "txtcolPurchaseAmount",
      type: "TextBoxWidget",
      colName: "colPurchaseAmount",
      parent: "gridPremiumDeductions",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: true,
      ReadOnly: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPurchaseAmount_PROPERTIES

      // END_USER_CODE-USER_txtcolPurchaseAmount_PROPERTIES
    },
    txtcolStorageAmount: {
      name: "txtcolStorageAmount",
      type: "TextBoxWidget",
      colName: "colStorageAmount",
      parent: "gridPremiumDeductions",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolStorageAmount_PROPERTIES

      // END_USER_CODE-USER_txtcolStorageAmount_PROPERTIES
    },
    txtcolPdCode: {
      name: "txtcolPdCode",
      type: "TextBoxWidget",
      colName: "colPdCode",
      parent: "gridPremiumDeductions",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPdCode_PROPERTIES

      // END_USER_CODE-USER_txtcolPdCode_PROPERTIES
    },


    txtcolReqIndArr: {

      name: "txtcolReqIndArr",
      type: "TextBoxWidget",
      colName: "colReqIndArr",
      parent: "gridPremiumDeductions",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolReqIndArr_PROPERTIES

      // END_USER_CODE-USER_txtcolReqIndArr_PROPERTIES
    },

    txtcolRateTypeArr: {
      name: "txtcolRateTypeArr",
      type: "TextBoxWidget",
      colName: "colRateTypeArr",
      parent: "gridPremiumDeductions",
      CharWidth: "48",
      Height: "",
      Width: "",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRateTypeArr_PROPERTIES

      // END_USER_CODE-USER_txtcolRateTypeArr_PROPERTIES

    },

    txtcolPECellCheckArr: {
      name: "txtcolPECellCheckArr",
      type: "TextBoxWidget",
      colName: "colPECellCheckArr",
      parent: "gridPremiumDeductions",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolhpavbYlwArr_PROPERTIES
      // END_USER_CODE-USER_txtcolhpavbYlwArr_PROPERTIES
    },




    txtcolSECellCheckArr: {
      name: "txtcolSECellCheckArr",
      type: "TextBoxWidget",
      colName: "colSECellCheckArr",
      parent: "gridPremiumDeductions",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolhsavbYlwArrr_PROPERTIES

      // END_USER_CODE-USER_txtcolhsavbYlwArrr_PROPERTIES

    },






    txtcolhpavbYlwArr: {
      name: "txtcolhpavbYlwArr",
      type: "TextBoxWidget",
      colName: "colhpavbYlwArr",
      parent: "gridPremiumDeductions",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolhpavbYlwArr_PROPERTIES
      // END_USER_CODE-USER_txtcolhpavbYlwArr_PROPERTIES
    },




    txtcolhsavbYlwArrr: {
      name: "txtcolhsavbYlwArrr",
      type: "TextBoxWidget",
      colName: "colhsavbYlwArr",
      parent: "gridPremiumDeductions",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolhsavbYlwArrr_PROPERTIES

      // END_USER_CODE-USER_txtcolhsavbYlwArrr_PROPERTIES

    },







    grpbxPremiumDeductions: {
      name: "grpbxPremiumDeductions",
      type: "GroupBoxWidget",
      parent: "PremiumDeductions",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxPremiumDeductions_PROPERTIES

      // END_USER_CODE-USER_grpbxPremiumDeductions_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "PremiumDeductions",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

  }, [getData(thisObj, "ValuesForPremiumDeductions")]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {

    let VndSplitsc = getData(thisObj, 'PremiumDeductionVendorSplitsClose')
    if (VndSplitsc == true) {
      let epsArrayData = getData(thisObj, 'psArr')
      let epctArrayData = getData(thisObj, 'pctArr')
      let eamtArrayData = getData(thisObj, 'amtArrayAfterEdit') == null ? getData(thisObj, 'amtArr') : getData(thisObj, 'amtArrayAfterEdit')
      let vdPurchageData = getData(thisObj, "dataPassingPremiumDeductionsVendorsplitPurchase")
      
      let vdStorageData = getData(thisObj, "dataPassingPremiumDeductionsVendorsplitStorage")
      
      let rowID = getData(thisObj, 'rowID') === "" ? 0 : getData(thisObj, 'rowID') === undefined ? 0 : getData(thisObj, 'rowID') === null ? 0 : Number(getData(thisObj, 'rowID'))
      let j = 0
      let k = 0
      if (vdPurchageData.length > 0) {
        for (let i = rowID; j < vdPurchageData.length; i++) {
          for (j = 0; j < vdPurchageData.length; j++) {
            if (epsArrayData[i][j] == 'P') {
              if ((vdPurchageData[i].txtPPCT != "") && (vdPurchageData[i].txtPPCT != 'NaN')) {
                epctArrayData[i][j] = vdPurchageData[j].txtPPCT
                eamtArrayData[i][j] = vdPurchageData[j].lblPAMT
              }
            }

          }

        }
      }
      if (vdStorageData.length > 0) {
        for (let i = rowID; k < vdStorageData.length; i++) {
          for (k = 0; k < vdStorageData.length; k++) {
            if (epsArrayData[i][k] == 'S') {
              if (vdStorageData[i].txtSPCT != "" && vdStorageData[i].txtSPCT != 'NaN') {
                epctArrayData[i][k] = vdStorageData[k].txtSPCT
                eamtArrayData[i][k] = vdStorageData[k].lblSAMT
              }
            }
          }

        }

      }
      setData(thisObj, 'epsArrayData', epsArrayData)
      setData(thisObj, 'epctArrayData', epctArrayData)
      setData(thisObj, 'eamtArrayData', eamtArrayData)
    }


    setData(thisObj, 'PremiumDeductionVendorSplitsClose', "");
  }, [getData(thisObj, 'PremiumDeductionVendorSplitsClose')])

  let response = []
  let vendorArr = []
  let amtArr = []
  let pctArr = []
  let psArr = []
  let pctArrCheck = []
  let psArrCheck = []
  let vendorSpecIndArr = []
  let venDSpecIndArr = []
  let pdCodeArr = []
  let pdIndArr = []
  let rateTypeArr = []
  let autoIndCodArr = []
  let inspectIndArr = []
  let requiredIndArr = []
  let beforeRecvIndArr = []
  let lblVendorArr = [] //['','','','','','','','','','','','','','','','','','','','',]
  let mbSeedPremFound = false;
  let samountArr = []
  let histCheckArr = []
  let selectOptArr = []
  let histPamtArr = []
  let histsamtArr = []
  let hcvbYlwArr = []
  let hpavbYlwArr = []
  let hsavbYlwArr = []
  let PECellCheckArr = []
  let SECellCheckArr = []
  let prchpamnt = []
  let prchsamnt = []
  let mfrmParentForm = getData(thisObj, "ParentForm_1007Settlement") === undefined ? false : getData(thisObj, "ParentForm_1007Settlement") === null ? false : getData(thisObj, "ParentForm_1007Settlement") === "" ? false : getData(thisObj, "ParentForm_1007Settlement")
  
  let param = getData(thisObj, "ValuesForPremiumDeductions")
  

  // if (mfrmParentForm) {
  //   if (param[0].lblSeedPremExpected == "Y") {
  //     if (Number(param[0].lblSeedPremNetLBS) > Number(param[0].lblSeedPremApplyLBS) || (mbSeedPremFound === false)) {
  //       if (confirm("Seed Premium was not calculated on all seed pounds. Do you wish to continue?") == true) {
  //         //continue..........
  //       }
  //       else {
  //         return false;
  //       }
  //     }
  //   }
  // }


  let methodCallIndicator = true;
  //let marrVendorInfo = 'P';
  let inspNum = ""
  let buy_pt_id = ""
  let inspect_ind = ""
  let segvalue_p = 0
  let contlist_p = 0
  let appllbs_p = 0
  let vendlist_p = 0
  let netwt_p = 0
  let lskwt_p = 0
  let grosswt_p = 0
  let segvalue_s = 0
  let contlist_s = 0
  let vendlist_s = 0
  let appllbs_s = 0
  let netwt_s = 0
  let lskwt_s = 0
  let grosswt_s = 0
  let basis_grade_amt_p = 0
  let basis_grade_amt_s = 0
  let pdcodes_cure_clean_costs = 0
  let seglessoptpurch = 0
  let contlist_seed = 0
  let appllbs_seed = 0
  let segValPArr = []
  let segValSArr = []
  let correction = getData(thisObj, 'mbCorrectionProcess_forPremiumDeductions') == true ? 'Y' : 'N'
  if (param.length > 0) {
    //marrVendorInfo = 'P';
    inspNum = param[0].sc95Num;
    buy_pt_id = param[0].BuyPtId;
    inspect_ind = param[0].YNboolean;
    segvalue_p = param[0].lblFarmerStockCost;
    contlist_p = param[0].sApplContractList;
    appllbs_p = param[0].sAppliedList;
    vendlist_p = param[0].sPVendorList;
    netwt_p = param[0].dblNetWtP;
    lskwt_p = param[0].dblLSKWtP;
    grosswt_p = param[0].dblGrossWtP;
    segvalue_s = param[0].lblStoresValueOfSeg;
    contlist_s = param[0].sTentContractList;
    appllbs_s = param[0].sTentList;
    vendlist_s = param[0].sSVendorList;
    netwt_s = param[0].dblNetWtS;
    lskwt_s = param[0].dblLSKWtS;
    grosswt_s = param[0].dblGrossWtS;
    basis_grade_amt_p = param[0].lblPurchValueOfLoad;
    basis_grade_amt_s = param[0].lblStoresValueOfLoad;
    pdcodes_cure_clean_costs = param[0].sPDcodes_cure_clean_costs;
    seglessoptpurch = param[0].SubstractionValue;
    contlist_seed = param[0].sSeedContractList;
    appllbs_seed = param[0].sSeedList;
    segValPArr = [];
    segValSArr = [];
    correction = getData(thisObj, 'mbCorrectionProcess_forPremiumDeductions') == true ? 'Y' : 'N';
    methodCallIndicator = true;

  }

  // START_USER_CODE-USER_METHODS

  const FormLoad = async () => {


    if (methodCallIndicator) {
      await ReadPremiumsDeductions()
    }

    if (mfrmParentForm) {
      if (param[0].lblSeedPremExpected == "Y") {
        if (Number(param[0].lblSeedPremNetLBS) > Number(param[0].lblSeedPremApplyLBS) || (mbSeedPremFound === false)) {
          if (confirm("Seed Premium was not calculated on all seed pounds. Do you wish to continue?") == true) {
            //continue..........
          }
          else {
            document.getElementById("Settlements_PremiumDeductionsPopUp").childNodes[0].click();
          }
        }
      }
    }
  }

  function btnShowHideIND(row) {
    let ress = response.filter((obj) => obj.pd_code == row.txtcolPdCode)
    
    if (ress[0].pd_ind == 'P' || ress[0].rate_type == 'AR' || ress[0].rate_type == 'CR_ACT' || ress[0].rate_type == 'CT_ADV' || ress[0].rate_type == 'FUEL' || ress[0].rate_type == 'LEASE' || ress[0].rate_type == 'O_ADM' || ress[0].rate_type == 'SD_ADM' || ress[0].rate_type == 'SR' ||
      ress[0].venD_SPEC_IND == 'Y' || ress[0].vendoR_SPEC_IND1 == 'Y' || ress[0].vendoR_SPEC_IND2 == 'Y' ||
      ress[0].vendoR_SPEC_IND3 == 'Y' || ress[0].vendoR_SPEC_IND4 == 'Y' || ress[0].vendoR_SPEC_IND5 == 'Y' ||
      ress[0].vendoR_SPEC_IND6 == 'Y' || ress[0].vendoR_SPEC_IND7 == 'Y' || ress[0].vendoR_SPEC_IND8 == 'Y' ||
      ress[0].vendoR_SPEC_IND9 == 'Y' || ress[0].vendoR_SPEC_IND10 == 'Y' || ress[0].vendoR_SPEC_IND11 == 'Y' ||
      ress[0].vendoR_SPEC_IND12 == 'Y' || ress[0].vendoR_SPEC_IND13 == 'Y' || ress[0].vendoR_SPEC_IND14 == 'Y' ||
      ress[0].vendoR_SPEC_IND15 == 'Y' || ress[0].vendoR_SPEC_IND16 == 'Y' || ress[0].vendoR_SPEC_IND17 == 'Y' ||
      ress[0].vendoR_SPEC_IND18 == 'Y' || ress[0].vendoR_SPEC_IND19 == 'Y' || ress[0].vendoR_SPEC_IND20 == 'Y'
    ) {
      return true
    } else {
      return false
    }
  }

  function chkboxEnableDisableIND(row) {
    let ress = response.filter((obj) => obj.required_ind == row.txtcolReqIndArr)
    if (ress[0].required_ind == 'Y') {
      return true
    } else {
      return false
    }
  }
  function checkPurchaseTextbox(row) {
    return (row.txtcolPECellCheckArr != "P" && row.txtcolhpavbYlwArr != "PA")

  }
  function checkStorageTextbox(row) {
    return (row.txtcolSECellCheckArr != "S" && row.txtcolhsavbYlwArrr != 'SA')
  }
  function checkIfAtleastOneTrue(row) {
    let check = chkboxEnableDisableIND(row) || checkStorageTextbox(row) || btnShowHideIND(row) || checkPurchaseTextbox(row)
    return check
  }
  function setStyles(row) {
    let style =
      btnShowHideIND(row) && chkboxEnableDisableIND(row) && checkStorageTextbox(row) && checkPurchaseTextbox(row) ? {
        "input[type='checkbox']": {
          pointerEvents: "none",
        },
        button: {
          display: "none"
        },
        '.txtcolPurchaseAmount': {
          pointerEvents: "none",
          border: 'none',
          boxShadow: 'none',
          background: 'transparent',
        },
        '.txtcolStorageAmount': {
          pointerEvents: "none",
          border: 'none',
          boxShadow: 'none',
          background: 'transparent',
        },
      } :

        btnShowHideIND(row) && chkboxEnableDisableIND(row) && checkStorageTextbox(row) && checkPurchaseTextbox(row) == false ? {
          "input[type='checkbox']": {
            pointerEvents: "none",
          },
          button: {
            display: "none"
          },
          '.txtcolStorageAmount': {
            pointerEvents: "none",
            border: 'none',
            boxShadow: 'none',
            background: 'transparent',
          },
        } :

          btnShowHideIND(row) && chkboxEnableDisableIND(row) && checkStorageTextbox(row) == false && checkPurchaseTextbox(row) ? {
            button: {
              display: "none"
            },
            "input[type='checkbox']": {
              pointerEvents: "none",
            },
            '.txtcolPurchaseAmount': {
              pointerEvents: "none",
              border: 'none',
              boxShadow: 'none',
              background: 'transparent',
            },
          } :
            btnShowHideIND(row) && chkboxEnableDisableIND(row) == false && checkStorageTextbox(row) && checkPurchaseTextbox(row) ? {
              button: {
                display: "none"
              },
              '.txtcolStorageAmount': {
                pointerEvents: "none",
                border: 'none',
                boxShadow: 'none',
                background: 'transparent',
              },
              '.txtcolPurchaseAmount': {
                pointerEvents: "none",
                border: 'none',
                boxShadow: 'none',
                background: 'transparent',
              },
            } :
              btnShowHideIND(row) == false && chkboxEnableDisableIND(row) && checkStorageTextbox(row) && checkPurchaseTextbox(row) ? {
                '.txtcolStorageAmount': {
                  pointerEvents: "none",
                  border: 'none',
                  boxShadow: 'none',
                  background: 'transparent',
                },
                '.txtcolPurchaseAmount': {
                  pointerEvents: "none",
                  border: 'none',
                  boxShadow: 'none',
                  background: 'transparent',
                },
                "input[type='checkbox']": {
                  pointerEvents: "none",
                },
              } :
                btnShowHideIND(row) && chkboxEnableDisableIND(row) && checkStorageTextbox(row) == false && checkPurchaseTextbox(row) == false ? {
                  "input[type='checkbox']": {
                    pointerEvents: "none",
                  },
                  button: {
                    display: "none"
                  },
                } :
                  btnShowHideIND(row) && chkboxEnableDisableIND(row) == false && checkStorageTextbox(row) == false && checkPurchaseTextbox(row) ? {
                    '.txtcolPurchaseAmount': {
                      pointerEvents: "none",
                      border: 'none',
                      boxShadow: 'none',
                      background: 'transparent',
                    },
                    button: {
                      display: "none"
                    },
                  } :
                    btnShowHideIND(row) == false && chkboxEnableDisableIND(row) && checkStorageTextbox(row) == false && checkPurchaseTextbox(row) ? {
                      '.txtcolPurchaseAmount': {
                        pointerEvents: "none",
                        border: 'none',
                        boxShadow: 'none',
                        background: 'transparent',
                      },
                      "input[type='checkbox']": {
                        pointerEvents: "none",
                      },
                    } :
                      btnShowHideIND(row) == false && chkboxEnableDisableIND(row) == false && checkStorageTextbox(row) && checkPurchaseTextbox(row) ? {
                        '.txtcolPurchaseAmount': {
                          pointerEvents: "none",
                          border: 'none',
                          boxShadow: 'none',
                          background: 'transparent',
                        },
                        '.txtcolStorageAmount': {
                          pointerEvents: "none",
                          border: 'none',
                          boxShadow: 'none',
                          background: 'transparent',
                        },
                      } :
                        btnShowHideIND(row) == false && chkboxEnableDisableIND(row) && checkStorageTextbox(row) && checkPurchaseTextbox(row) == false ? {
                          "input[type='checkbox']": {
                            pointerEvents: "none",
                          },
                          '.txtcolStorageAmount': {
                            pointerEvents: "none",
                            border: 'none',
                            boxShadow: 'none',
                            background: 'transparent',
                          },
                        } :
                          btnShowHideIND(row) && chkboxEnableDisableIND(row) == false && checkStorageTextbox(row) && checkPurchaseTextbox(row) == false ? {
                            '.txtcolStorageAmount': {
                              pointerEvents: "none",
                              border: 'none',
                              boxShadow: 'none',
                              background: 'transparent',
                            },
                            button: {
                              display: "none"
                            },

                          } :
                            btnShowHideIND(row) && chkboxEnableDisableIND(row) == false && checkStorageTextbox(row) == false && checkPurchaseTextbox(row) == false ? {
                              button: {
                                display: "none"
                              },
                            } :
                              btnShowHideIND(row) == false && chkboxEnableDisableIND(row) && checkStorageTextbox(row) == false && checkPurchaseTextbox(row) == false ? {
                                "input[type='checkbox']": {
                                  pointerEvents: "none",
                                },
                              } :
                                btnShowHideIND(row) == false && chkboxEnableDisableIND(row) == false && checkStorageTextbox(row) && checkPurchaseTextbox(row) == false ? {
                                  '.txtcolStorageAmount': {
                                    pointerEvents: "none",
                                    border: 'none',
                                    boxShadow: 'none',
                                    background: 'transparent',
                                  },
                                } :
                                  btnShowHideIND(row) == false && chkboxEnableDisableIND(row) == false && checkStorageTextbox(row) == false && checkPurchaseTextbox(row) ? {
                                    '.txtcolPurchaseAmount': {
                                      pointerEvents: "none",
                                      border: 'none',
                                      boxShadow: 'none',
                                      background: 'transparent',
                                    },
                                  } : {}

    return style
  }

  const onchkboxcolChange = (event) => {
    let nameEvent = event.target.name
    let arr = nameEvent.split(".")[1]
    let val = getGridValue(thisObj, 'gridPremiumDeductions', 'txtcolPurchaseAmount', arr)
    let vendorSplitData = getGridValue(thisObj, 'gridPremiumDeductions', 'chkboxcol', arr)
    if (getGridValue(thisObj, 'gridPremiumDeductions', 'chkboxcol', arr) == true) {
      samountArr[arr] = getGridValue(thisObj, 'gridPremiumDeductions', 'txtcolPurchaseAmount', arr)
    }
  }
  thisObj.onchkboxcolChange = onchkboxcolChange;


  const ontxtcolPurchaseAmountBlur = (event) => {
    let nameEvent = event.target.name
    let arr = nameEvent.split(".")[1]
    let editedValue = GetPremiumDeductSign(event.target.value, arr)
    setGridValue(thisObj, 'gridPremiumDeductions', 'txtcolPurchaseAmount', arr, editedValue)
    let pctArrayData = getData(thisObj, 'epctArrayData') == null ? getData(thisObj, 'pctArr') : getData(thisObj, 'epctArrayData')
    let amtArrayData = getData(thisObj, 'eamtArrayData') == null ? getData(thisObj, 'amtArr') : getData(thisObj, 'eamtArrayData')
    let psArrayData = getData(thisObj, 'psArr')

    let dVal = 0
    for (let i = 0; i < 20; i++) {
      if (psArrayData[arr][i] == 'P') {
        dVal = (Number(editedValue) * (Number(pctArrayData[arr][i]) * 0.01)).toFixed(2)
        amtArrayData[arr][i] = dVal
      }
      setData(thisObj, 'amtArrayAfterEdit', amtArrayData)
      // _kaledo.thisObj.setState({
      //   ...thisObj.state,
      //   gridPremiumDeductions: {
      //     ...state.gridPremiumDeductions,
      //     toggleRender: !state.gridPremiumDeductions.toggleRender
      //   }
      // })

    }

    
  }
  thisObj.ontxtcolPurchaseAmountBlur = ontxtcolPurchaseAmountBlur;


  function CallAfterEdit(rw, col, Vle) {
    let editedValue = GetPremiumDeductSign(Vle, rw)
    //setGridValue(thisObj, 'gridPremiumDeductions', 'txtcolStorageAmount', rw, editedValue)
    let pctArrayData = getData(thisObj, 'pctArr')
    let amtArrayData = getData(thisObj, 'amtArr')
    let psArrayData = getData(thisObj, 'psArr')
    let dVal = 0
    if (col === 3) {
      for (let i = 0; i < 20; i++) {

        if (psArrayData[rw][i] == 'P') {
          dVal = (Number(editedValue) * (Number(pctArrayData[rw][i]) * 0.01)).toFixed(2)
          amtArrayData[rw][i] = dVal
        }
      }
      // _kaledo.thisObj.setState({
      //   ...thisObj.state,
      //   gridPremiumDeductions: {
      //     ...state.gridPremiumDeductions,
      //     toggleRender: !state.gridPremiumDeductions.toggleRender
      //   }
      // })

    }
    if (col === 4) {
      for (let i = 0; i < 20; i++) {

        if (psArrayData[rw][i] == 'S') {
          dVal = (Number(editedValue) * (Number(pctArrayData[rw][i]) * 0.01)).toFixed(2)
          amtArrayData[rw][i] = dVal
        }
      }
      // _kaledo.thisObj.setState({
      //   ...thisObj.state,
      //   gridPremiumDeductions: {
      //     ...state.gridPremiumDeductions,
      //     toggleRender: !state.gridPremiumDeductions.toggleRender
      //   }
      // })

    }

  }



  const ontxtcolStorageAmountBlur = (event) => {
    let nameEvent = event.target.name
    let arr = nameEvent.split(".")[1]
    let editedValue = GetPremiumDeductSign(event.target.value, arr)
    setGridValue(thisObj, 'gridPremiumDeductions', 'txtcolStorageAmount', arr, editedValue)
    let pctArrayData = getData(thisObj, 'epctArrayData') == null ? getData(thisObj, 'pctArr') : getData(thisObj, 'epctArrayData')
    let amtArrayData = getData(thisObj, 'eamtArrayData') == null ? getData(thisObj, 'amtArr') : getData(thisObj, 'eamtArrayData')
    let psArrayData = getData(thisObj, 'psArr')
    let dVal = 0
    for (let i = 0; i < 20; i++) {
      if (psArrayData[arr][i] == 'S') {
        dVal = (Number(editedValue) * (Number(pctArrayData[arr][i]) * 0.01)).toFixed(2)
        amtArrayData[arr][i] = dVal
      }
    }
    setData(thisObj, 'amtArrayAfterEdit', amtArrayData)
    // _kaledo.thisObj.setState({
    //   ...thisObj.state,
    //   gridPremiumDeductions: {
    //     ...state.gridPremiumDeductions,
    //     toggleRender: !state.gridPremiumDeductions.toggleRender
    //   }
    // })

  }
  thisObj.ontxtcolStorageAmountBlur = ontxtcolStorageAmountBlur;



  const ReadPremiumsDeductions = async () => {
    setLoading(true)
    for (let i = 0; i <= 19; i++) {
      if (param[0].marrVendorInfo[5][i] != "") {
        if (param[0].marrVendorInfo[5][i] == 'P') {
          segValPArr.push(Number(param[0].marrVendorInfo[12][i]) + Number(param[0].marrVendorInfo[17][i]))
          segValSArr.push(0)
        } else {
          segValPArr.push(0)
          segValSArr.push(param[0].marrVendorInfo[12][i])
        }

      }
      else {
        segValPArr.push(0)
        segValSArr.push(0)
      }
    }
    let retrieveInspectHeaderResidueDetails = await SettlementService.RetrievePremiumDeductionScheduleList(inspNum,

      buy_pt_id, inspect_ind, segvalue_p, contlist_p, appllbs_p, vendlist_p, netwt_p, lskwt_p, grosswt_p, segvalue_s,

      contlist_s, appllbs_s, vendlist_s, netwt_s, lskwt_s, grosswt_s, basis_grade_amt_p, basis_grade_amt_s, pdcodes_cure_clean_costs, seglessoptpurch,
      contlist_seed, appllbs_seed,
      segValPArr[0],
      segValPArr[1],
      segValPArr[2],
      segValPArr[3],
      segValPArr[4],
      segValPArr[5],
      segValPArr[6],
      segValPArr[7],
      segValPArr[8],
      segValPArr[9],
      segValPArr[10],
      segValPArr[11],
      segValPArr[12],
      segValPArr[13],
      segValPArr[14],
      segValPArr[15],
      segValPArr[16],
      segValPArr[17],
      segValPArr[18],
      segValPArr[19],
      segValSArr[0],
      segValSArr[1],
      segValSArr[2],
      segValSArr[3],
      segValSArr[4],
      segValSArr[5],
      segValSArr[6],
      segValSArr[7],
      segValSArr[8],
      segValSArr[9],
      segValSArr[10],
      segValSArr[11],
      segValSArr[12],
      segValSArr[13],
      segValSArr[14],
      segValSArr[15],
      segValSArr[16],
      segValSArr[17],
      segValSArr[18],
      segValSArr[19],
      correction

    );
    let jsVP = []
    response = retrieveInspectHeaderResidueDetails;
    if (response.length > 0 && response != undefined) {
      // let jsVP = []
      let obj = {}
      mbSeedPremFound = false

      for (let i = 0; i < response.length; i++) {
        psArrCheck.push(
          [
            response[i].ps1, response[i].ps2, response[i].ps3, response[i].ps4, response[i].ps5,
            response[i].ps6, response[i].ps7, response[i].ps8, response[i].ps9, response[i].ps10,
            response[i].ps11, response[i].ps12, response[i].ps13, response[i].ps14, response[i].ps15,
            response[i].ps16, response[i].ps17, response[i].ps18, response[i].ps19, response[i].ps20
          ]
        )
        pctArrCheck.push(
          [
            response[i].pct1, response[i].pct2, response[i].pct3, response[i].pct4, response[i].pct5,
            response[i].pct6, response[i].pct7, response[i].pct8, response[i].pct9, response[i].pct10,
            response[i].pct11, response[i].pct12, response[i].pct13, response[i].pct14, response[i].pct15,
            response[i].pct16, response[i].pct17, response[i].pct18, response[i].pct19, response[i].pct20
          ]
        )
        if (response[i].hist_check === "Y") {
          hcvbYlwArr.push(["hc"])
          if ((response[i].hist_pamnt != "") && (response[i].hist_pamnt != 0)) {
            prchpamnt.push(response[i].hist_pamnt)
            hpavbYlwArr.push(["PA"])
          }
          else {
            hpavbYlwArr.push(["PNA"])
            prchpamnt.push(["0"])
          }
          if ((response[i].hist_samnt != "") && (response[i].hist_samnt != 0)) {
            prchsamnt.push(response[i].hist_samnt)
            hsavbYlwArr.push(["SA"])
          }
          else {
            hsavbYlwArr.push(["SNA"])
            prchsamnt.push(["0"])
          }
        }
        else {
          hpavbYlwArr.push(["PNA"])
          prchpamnt.push(["0"])
          hsavbYlwArr.push(["SNA"])
          prchsamnt.push(["0"])
        }

      }

      for (let i = 0; i < response.length; i++) {
        if (response[i].rate_type == 'ENTRY') {
          let n = PECellCheckArr.length
          let pushVal = ''
          for (let j = 0; j < 20; j++) {
            if ((psArrCheck[i][j] == 'P') && (Number(pctArrCheck[i][j]) != 0)) {
              pushVal = 'P'
            }
          }
          if(pushVal == 'P'){
            PECellCheckArr.push(["P"])
          }
          if (PECellCheckArr.length <= n) {
            PECellCheckArr.push(["NP"])
          }
          n = SECellCheckArr.length
          pushVal = ''
          for (let j = 0; j < 20; j++) {
            if ((psArrCheck[i][j] == 'S') && (Number(pctArrCheck[i][j]) != 0)) {
              pushVal = 'S'
            }
          }
          if(pushVal == 'S'){
            SECellCheckArr.push(["S"])
          }
          if (SECellCheckArr.length <= n) {
            SECellCheckArr.push(["NS"])
          }
        }
        else {
          PECellCheckArr.push(["NP"])
          SECellCheckArr.push(["NS"])
        }

      }

      
      

      for (let i = 0; i < response.length; i++) {

        obj.txtcolPremDeduct = response[i].pd_desc
        obj.txtcolPurchaseAmount = Number((Number(response[i].pamount) + Number(prchpamnt[i]))).toFixed(2)
        obj.txtcolStorageAmount = Number((Number(response[i].samount) + Number(prchsamnt[i]))).toFixed(2)
        obj.txtcolPdCode = response[i].pd_code
        obj.txtcolRateTypeArr = response[i].rate_type
        obj.txtcolReqIndArr = response[i].required_ind
        obj.txtcolPECellCheckArr = PECellCheckArr[i]
        obj.txtcolSECellCheckArr = SECellCheckArr[i]
        obj.txtcolhpavbYlwArr = hpavbYlwArr[i]
        obj.txtcolhsavbYlwArrr = hsavbYlwArr[i]
        jsVP.push(obj)
        obj = {}


      }
      setValue(thisObj, 'gridPremiumDeductions', jsVP)
      setData(thisObj, 'apiResponse', response)

      // hideColumn(thisObj, "gridPremiumDeductions", "txtcolPdCode")
      // hideColumn(thisObj, "gridPremiumDeductions", "txtcolRateTypeArr")
      // hideColumn(thisObj, "gridPremiumDeductions", "txtcolReqIndArr")
    }

    for (let i = 0; i < response.length; i++) {
      lblVendorArr.push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',])
      samountArr.push(response[i].samount)//4
      vendorArr.push( // 5-24
        [
          response[i].vendor1, response[i].vendor2, response[i].vendor3, response[i].vendor4, response[i].vendor5,
          response[i].vendor6, response[i].vendor7, response[i].vendor8, response[i].vendor9, response[i].vendor10,
          response[i].vendor11, response[i].vendor12, response[i].vendor13, response[i].vendor14, response[i].vendor15,
          response[i].vendor16, response[i].vendor17, response[i].vendor18, response[i].vendor19, response[i].vendor20
        ]
      )
      amtArr.push( //25-44
        [
          response[i].amt1, response[i].amt2, response[i].amt3, response[i].amt4, response[i].amt5,
          response[i].amt6, response[i].amt7, response[i].amt8, response[i].amt9, response[i].amt10,
          response[i].amt11, response[i].amt12, response[i].amt13, response[i].amt14, response[i].amt15,
          response[i].amt16, response[i].amt17, response[i].amt18, response[i].amt19, response[i].amt20
        ]
      )
      pctArr.push( // 45-64
        [
          response[i].pct1, response[i].pct2, response[i].pct3, response[i].pct4, response[i].pct5,
          response[i].pct6, response[i].pct7, response[i].pct8, response[i].pct9, response[i].pct10,
          response[i].pct11, response[i].pct12, response[i].pct13, response[i].pct14, response[i].pct15,
          response[i].pct16, response[i].pct17, response[i].pct18, response[i].pct19, response[i].pct20
        ]
      )
      pdCodeArr.push([response[i].pd_code]) //65
      pdIndArr.push([response[i].pd_ind])   //66
      rateTypeArr.push([response[i].rate_type])  //67
      autoIndCodArr.push([response[i].auto_ind])  //68
      inspectIndArr.push([response[i].inspect_ind])  //69
      requiredIndArr.push([response[i].required_ind])  //70
      beforeRecvIndArr.push([response[i].before_recv_ind])  //71



      psArr.push( //92-110
        [
          response[i].ps1, response[i].ps2, response[i].ps3, response[i].ps4, response[i].ps5,
          response[i].ps6, response[i].ps7, response[i].ps8, response[i].ps9, response[i].ps10,
          response[i].ps11, response[i].ps12, response[i].ps13, response[i].ps14, response[i].ps15,
          response[i].ps16, response[i].ps17, response[i].ps18, response[i].ps19, response[i].ps20
        ]
      )

      if (response[i].rate_type == 'SD_ADM') {
        mbSeedPremFound = true;
      }

      if (response[i].required_ind == 'Y') {
        if (_kaledo.thisObj.values['gridPremiumDeductions'].length > 0) {
          setGridValue(_kaledo.thisObj, 'gridPremiumDeductions', 'chkboxcol', i, true)
        }
      }
      if (response[i].auto_ind == 'Y') {
        if (_kaledo.thisObj.values['gridPremiumDeductions'].length > 0) {
          setGridValue(_kaledo.thisObj, 'gridPremiumDeductions', 'chkboxcol', i, true)
        }
      }
      if (response[i].rate_type == 'ENTRY') {
        for (let j = 0; j < 20; j++) {
          if (psArr[i][j] == 'P' && pctArr[i][j] != 0 || pctArr[i][j] != '0') {
            if (_kaledo.thisObj.values['gridPremiumDeductions'].length > 0) {
              setGridValue(_kaledo.thisObj, 'gridPremiumDeductions', 'txtcolPurchaseAmount', i, response[i].base_rate)
            }
          }
        }

        for (let j = 0; j < 20; j++) {
          if (psArr[i][j] == 'S' && pctArr[i][j] != 0 || pctArr[i][j] != '0') {
            if (_kaledo.thisObj.values['gridPremiumDeductions'].length > 0) {
              setGridValue(_kaledo.thisObj, 'gridPremiumDeductions', 'txtcolStorageAmount', i, response[i].base_rate)
            }
          }
        }
      }
      venDSpecIndArr.push([response[i].VEND_SPEC_IND]) //112

      vendorSpecIndArr.push(
        [
          response[i].vendoR_SPEC_IND1, response[i].vendoR_SPEC_IND2, response[i].vendoR_SPEC_IND3, response[i].vendoR_SPEC_IND4, response[i].vendoR_SPEC_IND5,
          response[i].vendoR_SPEC_IND6, response[i].vendoR_SPEC_IND7, response[i].vendoR_SPEC_IND8, response[i].vendoR_SPEC_IND9, response[i].vendoR_SPEC_IND10,
          response[i].vendoR_SPEC_IND11, response[i].vendoR_SPEC_IND12, response[i].vendoR_SPEC_IND13, response[i].vendoR_SPEC_IND14, response[i].vendoR_SPEC_IND15,
          response[i].vendoR_SPEC_IND16, response[i].vendoR_SPEC_IND17, response[i].vendoR_SPEC_IND18, response[i].vendoR_SPEC_IND19, response[i].vendoR_SPEC_IND20
        ]
      )

      // histCheckArr.push([response[i].hist_check])
      // selectOptArr.push([response[i].selected_opt])
      // histPamtArr.push([response[i].hist_pamnt])
      // histsamtArr.push([response[i].hist_samnt])    
            
      if (mfrmParentForm) {
        for (let j = 0; j < 20; j++) {
          for (let k = 0; k < param[0].marrVendorInfo[0].length; k++) {
            if (vendorArr[i][j] == param[0].marrVendorInfo[0][k]) {
              lblVendorArr[i][j] = param[0].vendorName[k]
            }
          }
        }
        setData(thisObj, 'lblVendorArrData', lblVendorArr)
      }


    }
      setData(thisObj, 'VendorArr', vendorArr)
      setData(thisObj, 'pdCodeArr', pdCodeArr)
      setData(thisObj, 'pctArr', pctArr)
      setData(thisObj, 'amtArr', amtArr)
      setData(thisObj, 'rateTypeArr', rateTypeArr)
      setData(thisObj, 'psArr', psArr)
      setData(thisObj, 'pdIndArr', pdIndArr)

      if (response.length > 0 && response != undefined) {
        for (let i = 0; i < response.length; i++) {
          if (response[i].hist_check === "Y") {
            if ((response[i].hist_pamnt != "") && (response[i].hist_pamnt != 0)) {
              CallAfterEdit(i, 3, response[i].hist_pamnt)
            }
            if ((response[i].hist_samnt != "") && (response[i].hist_samnt != 0)) {
              CallAfterEdit(i, 4, response[i].hist_samnt)
            }
          }

        }
      }
      let gridData = getValue(thisObj, 'gridPremiumDeductions')
      for(var i=0; i<gridData.length; i++){
        gridData[i].txtcolPurchaseAmount = jsVP[i].txtcolPurchaseAmount
        gridData[i].txtcolStorageAmount = jsVP[i].txtcolStorageAmount
      }
      setValue(thisObj, 'gridPremiumDeductions', gridData)
      
    setLoading(false)
  }

  const GetPremiumDeductSign = (strValue, intRow) => {
    let pdIndArray = getData(thisObj, 'pdIndArr')
    let strPermDeduct
    if (strValue == '0.00' || strValue == '0') {
      // GetPremiumDeductSign = strValue
      return parseFloat(strValue)
    }
    if (strValue == '') {
      return '0'
    } else if (isNaN(strValue)) {
      return '0'
    }
    strPermDeduct = pdIndArray[intRow][0]
    switch (strPermDeduct) {
      case 'D':
        if (strValue.slice(0, 1) == '-') {
          return parseFloat(strValue)
        } else {
          if (strValue.slice(0, 1) == '+') {
            return parseFloat(strValue)
          } else {
            return '-' + strValue
          }
        }
        break;
      case "P":
        return parseFloat(strValue)
        break;
      default:
        break;
    }
  }


  const onbtnColClick = (event) => {
    try {
      let rowSelected = event.target.parentElement.parentElement.parentElement
      let rowID = rowSelected.id.substring(4, 5);
      setData(thisObj, "rowID", rowID)
      let valueGrid = getValue(thisObj, "gridPremiumDeductions")[rowID]
      let responseData = getData(thisObj, 'apiResponse')
      let vendorArrayData = getData(thisObj, 'VendorArr')
      let pctArrayData = getData(thisObj, 'epctArrayData') == null ? getData(thisObj, 'pctArr') : getData(thisObj, 'epctArrayData')
      let amtArrayData = getData(thisObj, 'amtArrayAfterEdit') == null ? getData(thisObj, 'amtArr') : getData(thisObj, 'amtArrayAfterEdit')
      let psArrayData = getData(thisObj, 'psArr')
      let lblVendorArrData = getData(thisObj, 'lblVendorArrData')
      let purchaseData = []
      let storageData = []
      let objjj1 = {}
      let objjj2 = {}
      if ((responseData.length > 0) && (Number(valueGrid.txtcolPurchaseAmount) != 0 || Number(valueGrid.txtcolStorageAmount) != 0)) {
        for (let i = 0; i < 20; i++) {
          if (psArrayData[rowID][i] == 'P' && Number(pctArrayData[rowID][i]) != 0) {
            objjj1.vendor = `${vendorArrayData[rowID][i]}-${lblVendorArrData[rowID][i]}`
            objjj1.sharePct = pctArrayData[rowID][i]
            objjj1.shareAmt = amtArrayData[rowID][i]
            purchaseData.push(objjj1)
            objjj1 = {}
          }
          else {
            objjj1.vendor = ""
            objjj1.sharePct = ""
            objjj1.shareAmt = ""
            purchaseData.push(objjj1)
            objjj1 = {}
          }


        }


        for (let i = 0; i < 20; i++) {
          if (psArrayData[rowID][i] == 'S' && Number(pctArrayData[rowID][i]) != 0) {
            objjj2.vendor = `${vendorArrayData[rowID][i]}-${lblVendorArrData[rowID][i]}`
            objjj2.sharePct = pctArrayData[rowID][i]
            objjj2.shareAmt = amtArrayData[rowID][i]
            storageData.push(objjj2)
            objjj2 = {}
          }
          else {
            objjj2.vendor = ""
            objjj2.sharePct = ""
            objjj2.shareAmt = ""
            storageData.push(objjj2)
            objjj2 = {}
          }

        }
        setData(thisObj, 'VPremData-PtotalAmt', valueGrid.txtcolPurchaseAmount)
        setData(thisObj, 'VPremData-StotalAmt', valueGrid.txtcolStorageAmount)
        setData(thisObj, 'VPremData-purchaseData', purchaseData)
        setData(thisObj, 'VPremData-storeageData', storageData)
        goTo(thisObj, "Settlements#PremiumDeductionsVendorSplits#DEFAULT#true#Click")

        return



      }
      if ((Number(valueGrid.txtcolPurchaseAmount) == 0) && (Number(valueGrid.txtcolStorageAmount) == 0)) {
        showMessage(thisObj, 'The vendor splits for this Premium / Deduction can not be changed because it has no value!!!' + 'Cannot Change Vendor Splits!!!', false)
        return
      }
      return true
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error occurred during btnCol click event");
      }
      return false;
    }
  }
  thisObj.onbtnColClick = onbtnColClick;



  const onbtnOkClick = async () => {
    try {
      let responseData = getData(thisObj, 'apiResponse')
      let vendorArrayData = getData(thisObj, 'VendorArr')
      let pdCodeArrayData = getData(thisObj, 'pdCodeArr')
      let rateTypeArrayData = getData(thisObj, 'rateTypeArr')
      let pdIndArrData = getData(thisObj, 'pdIndArr')
      let pctArrayData = getData(thisObj, 'epctArrayData') === null ? getData(thisObj, 'pctArr') : getData(thisObj, 'epctArrayData')
      let amtArrayData
      if (getData(thisObj, 'amtArrayAfterEdit') == null) {
        if (getData(thisObj, 'eamtArrayData') == null) {
          amtArrayData = getData(thisObj, 'amtArr')
        } else {
          amtArrayData = getData(thisObj, 'eamtArrayData')
        }
      } else {
        amtArrayData = getData(thisObj, 'amtArrayAfterEdit')
      }
      //let amtArrayData = getData(thisObj, 'eamtArrayData')===null? getData(thisObj, 'amtArr'): getData(thisObj, 'eamtArrayData')
      let psArrayData = getData(thisObj, 'psArr')

      let LdblPPremiums = 0
      let LdblSPremiums = 0
      let LdblPDeductions = 0
      let LdblSDeductions = 0
      let LdblP = []
      let LdblD = []
      let nn = 0
      let sUrl = ``
      let np = []
      let nd = []
      let LdblPPct = []
      let LdblSPct = []
      for (let i = 0; i <= 20; i++) {
        LdblP[i] = 0
      }
      for (let i = 0; i <= 20; i++) {
        LdblD[i] = 0
      }

      for (let i = 0; i < param[0].cboPSCount; i++) {
        for (let j = 0; j < responseData.length; j++) {
          if (getGridValue(_kaledo.thisObj, 'gridPremiumDeductions', 'chkboxcol', j) == true) {
            if (vendorArrayData[j][i] != '') {
              nn = nn + 1
              sUrl += `&pd_split_vendor${nn}=${vendorArrayData[j][i]}`
              sUrl += `&pd_split_remit${nn}=${'000'}`
              sUrl += `&pd_code${nn}=${pdCodeArrayData[j][0]}`
              sUrl += `&pd_split_share_pct${nn}=${pctArrayData[j][i]}`
              sUrl += `&pd_amount${nn}=${amtArrayData[j][i]}`
              sUrl += `&pd_pounds${nn}=${0}`
              sUrl += `&payee_vendor${nn}=""`
              sUrl += `&payee_remit${nn}=""`
              sUrl += `&pd_rate_type${nn}= ${rateTypeArrayData[j][0]}`
              sUrl += `&pd_ps${nn}= ${psArrayData[j][i]}`
              if (pdIndArrData[j] == "P" && psArrayData[j][i] == "P") {
                LdblPPremiums = LdblPPremiums + Number(amtArrayData[j][i])
                LdblP[i] = LdblP[i] + Number(amtArrayData[j][i])
              }
              if (pdIndArrData[j] == "P" && psArrayData[j][i] == "S") {
                LdblSPremiums = LdblSPremiums + Number(amtArrayData[j][i])
                LdblP[i] = LdblP[i] + Number(amtArrayData[j][i])
              }
              if (pdIndArrData[j] == "D" && psArrayData[j][i] == "P") {
                LdblPDeductions = LdblPDeductions + Number(amtArrayData[j][i])
                LdblD[i] = LdblD[i] + Number(amtArrayData[j][i])
              }
              if (pdIndArrData[j] == "D" && psArrayData[j][i] == "S") {
                LdblSDeductions = LdblSDeductions + Number(amtArrayData[j][i])
                LdblD[i] = LdblD[i] + Number(amtArrayData[j][i])
              }
            }
          }
        }
      }

      if (mfrmParentForm) {
        let LdblPPctTotal = 0

        for (let i = 0; i < param[0].cboPSCount; i++) {
          if (param[0].cboPS[i] == "P") {
            LdblPPctTotal = Number(LdblPPctTotal) + Number(param[0].marrVendorInfo[2][i])
          }
        }

        let LdblSPctTotal = 0
        for (let i = 0; i < param[0].cboPSCount; i++) {
          if (param[0].cboPS[i] == "S") {
            LdblSPctTotal = Number(LdblSPctTotal) + Number(param[0].marrVendorInfo[2][i])
          }
        }

        if (LdblPPctTotal > 0) {
          for (let i = 0; i < param[0].cboPSCount; i++) {
            if (param[0].cboPS[i] == "P") {
              LdblPPct[i + 1] = Number(param[0].marrVendorInfo[2][i]) * 100 / Number(LdblPPctTotal)
            }

          }
        }
        if (LdblSPctTotal > 0) {
          for (let i = 0; i < param[0].cboPSCount; i++) {
            if (param[0].cboPS[i] == "S") {
              LdblSPct[i + 1] = Number(param[0].marrVendorInfo[2][i]) * 100 / Number(LdblSPctTotal)

            }
          }
        }


        for (let i = 0; i < param[0].cboPSCount; i++) { //i=3
          for (let j = 0; j < param[0].cboPSCount && i < vendorArrayData.length; j++) {

            if (vendorArrayData[i][j] == param[0].marrVendorInfo[0][i]) {
              np[i] = LdblP[j]
              nd[i] = LdblD[j]
            }

          }

        }

        for (let i = 0; i < param[0].cboPSCount; i++) { 
          LdblP[i] = np[i] == undefined ? 0.00 : np[i] // cause of undefined is vendorArrayData and param[0].cboPSCount can vary, if cause of undefined is different for any other case please start your debugging from this line upwards
          LdblD[i] = nd[i] == undefined ? 0.00 : nd[i]
        }

        for (let i = 0; i < param[0].cboPSCount; i++) {
          sUrl += `&premium_amt${i + 1}= ${Number(LdblP[i].toFixed(2))}`
          sUrl += `&deduct_amt${i + 1}=${Number(LdblD[i].toFixed(2))}`
        }



        for (let i = 0; i < param[0].cboPSCount; i++) {
          if (param[0].cboPS[i] == "P") {
            let dblproceeds = 0

            dblproceeds = Number(Number(param[0].marrVendorInfo[17][i]).toFixed(2))
            dblproceeds = Number(Number(dblproceeds) + Number(param[0].marrVendorInfo[12][i]).toFixed(2))
            dblproceeds = Number(Number(dblproceeds) + Number(LdblD[i].toFixed(2)))
            dblproceeds = Number(Number(dblproceeds) + Number(LdblP[i].toFixed(2)))
            sUrl += `&proceeds_amt${i + 1}=${dblproceeds.toFixed(2)}`
          }


          if (param[0].cboPS[i] == "S") {
            let dblproceeds = 0
            dblproceeds = Number(Number(dblproceeds) + Number(param[0].marrVendorInfo[12][i]).toFixed(2))
            dblproceeds = Number(Number(dblproceeds) + Number(LdblD[i].toFixed(2)))
            dblproceeds = Number(Number(dblproceeds) + Number(LdblP[i].toFixed(2)))
            sUrl += `&proceeds_amt${i + 1}=${dblproceeds.toFixed(2)}`
          }

        }
        sUrl += `&prem_deduct_count=${nn}`
        let sPDUnselect = "";
        //'build selected optional P/Ds that were manually unchecked.
        for (let j = 0; j < responseData.length; j++) {
          if (getGridValue(_kaledo.thisObj, 'gridPremiumDeductions', 'chkboxcol', j) == false && responseData[0].required_ind == 'Y') {
            sPDUnselect += pdCodeArr[j] + "|"
          }
        }
        let objfrm1007Settlement = {
          "lblPurchPremiums": Number(Number(LdblPPremiums).toFixed(2)),
          "lblPurchDeductions": Number(Number(LdblPDeductions).toFixed(2)),
          "lblPurchProceeds": Number(param[0].lblFarmerStockCost) + Number(LdblPDeductions) + Number(LdblPPremiums),
          "lblStoresSeedPremium": Number(Number(LdblSPremiums).toFixed(2)),
          "lblStoresDeductions": Number(Number(LdblSDeductions).toFixed(2)),
          "lblStorProceeds": Number(param[0].lblStoresValueOfSeg) + Number(LdblSDeductions) + Number(LdblSPremiums),
          "lblPremDeduct": sUrl,
          "lblPremDeductUnselect": sPDUnselect,
        }


        setData(thisObj, "PremiumDeductionScreenData", objfrm1007Settlement)
        setData(thisObj, "closingOfPremiumDeductions", true)
        
        document.getElementById("Settlements_PremiumDeductionsPopUp").childNodes[0].click();
      }
      setData(thisObj, 'amtArrayAfterEdit', null);
      setData(thisObj, 'eamtArrayData', null);
    } catch (error) {
      showMessage(
        thisObj,
        "Error occurred on ok button"
      );
    }

  };
  thisObj.onbtnOkClick = onbtnOkClick;

  const onbtnCancelClick = async () => {

    try {
      setData(thisObj, "mbCancelled", true)
      document.getElementById("Settlements_PremiumDeductionsPopUp").childNodes[0].click();
    }
    catch (error) {
      showMessage(
        thisObj,
        "Error occurred on cancel"
      );
    }

  };
  thisObj.onbtnCancelClick = onbtnCancelClick;


  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
            <Loading loading={loading}></Loading>
              {/* START_USER_CODE-USER_BEFORE_PremiumDeductions*/}

              {/* END_USER_CODE-USER_BEFORE_PremiumDeductions*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxPremiumDeductions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPremiumDeductions*/}

              <GroupBoxWidget
                conf={state.grpbxPremiumDeductions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblPremDeduct*/}

                {/* END_USER_CODE-USER_BEFORE_lblPremDeduct*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPremDeduct}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPremDeduct*/}

                {/* END_USER_CODE-USER_AFTER_lblPremDeduct*/}
                {/* START_USER_CODE-USER_BEFORE_gridPremiumDeductions*/}

                {/* END_USER_CODE-USER_BEFORE_gridPremiumDeductions*/}

                <GridWidget
                  conf={state.gridPremiumDeductions}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridPremiumDeductions}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  refObject={thisObj}
                  key={state.gridPremiumDeductions.toggleRender}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridPremiumDeductions*/}

                {/* END_USER_CODE-USER_AFTER_gridPremiumDeductions*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPremiumDeductions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPremiumDeductions*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_PremiumDeductions*/}

              {/* END_USER_CODE-USER_AFTER_PremiumDeductions*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_PremiumDeductions);
