/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,  
  useAppContext,  
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  CheckboxWidget,
  TextAreaWidget,
  GridWidget,
  setValue,
  getData,
  disable,
  enable,
  getValue,
  setData, 
  goTo,
  DateWidget
} from "../../shared/WindowImports";

import "./ExceptPremRequestSearch.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../Service/ContractManagementService";
import moment from "moment"; //For date time
import CommonContext from '../../Store/CommonContext'
import { returnNumericValues } from './../../Common/Constants';
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function ContractManagement_ExceptPremRequestSearch(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const commonContext = useContext(CommonContext);
  let states = {
    Label: "ExceptPremRequestSearch",
    windowName: "ExceptPremRequestSearch",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.ExceptPremRequestSearch",
    lstPremiumDeductionSchDetail : [],
    // START_USER_CODE-USER_ExceptPremRequestSearch_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Contract Pay Request",
      scrCode: "PN1190C",
    },
    headerDetails: {
      year: 2008,
      companyName: "Golden Peanut Compnay"
    },
    // END_USER_CODE-USER_ExceptPremRequestSearch_PROPERTIES
    chkbxCol: {
      name: "chkbxCol",
      type: "CheckBoxWidget",
      colName: "colChckbx",
      parent: "grpbx1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      ofTypeDomain: "d_boolean",
    },
    colChckbx: {
      name: "colChckbx",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChckbx_PROPERTIES

      // END_USER_CODE-USER_colChckbx_PROPERTIES
    },
    colIsSts: {
      name: "colIsSts",
      type: "TextBoxWidget",
      colName: "colSts",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colIsSts_PROPERTIES

      // END_USER_CODE-USER_colIsSts_PROPERTIES
    },
    colSts: {
      name: "colSts",
      type: "GridColumnWidget",
      parent: "grpbx1007s",
      isStatusColumn: true,
      HasLabel: false,
      Visible: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSts_PROPERTIES

      // END_USER_CODE-USER_colSts_PROPERTIES
    },
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "grpbxExit",
      Label: "Exit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    btnPrintPayReq: {
      name: "btnPrintPayReq",
      type: "ButtonWidget",
      parent: "grpbxPayReq",
      Label: "Print Pay Request",
      CharWidth: "36",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrintPayReq_PROPERTIES

      // END_USER_CODE-USER_btnPrintPayReq_PROPERTIES
    },
    btnRejectPrem: {
      name: "btnRejectPrem",
      type: "ButtonWidget",
      parent: "grpbxPayReq",
      Label: "Reject Premium(s)",
      CharWidth: "35",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnRejectPrem_PROPERTIES

      // END_USER_CODE-USER_btnRejectPrem_PROPERTIES
    },
    btnReprintPayReq: {
      name: "btnReprintPayReq",
      type: "ButtonWidget",
      parent: "grpbxReprintPayReq",
      Label: "Reprint Pay Request",
      CharWidth: "39",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnReprintPayReq_PROPERTIES

      // END_USER_CODE-USER_btnReprintPayReq_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxSearch",
      Label: "Search",
      ColSpan: "2",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxSearch",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    chkboxAll1007s: {
      name: "chkboxAll1007s",
      type: "CheckBoxWidget",
      parent: "grpbx1007s",
      Label: "All 1007s",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxAll1007s_PROPERTIES

      // END_USER_CODE-USER_chkboxAll1007s_PROPERTIES
    },
    ddGeneration: {
      name: "ddGeneration",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Generation:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddGeneration_PROPERTIES

      // END_USER_CODE-USER_ddGeneration_PROPERTIES
    },
    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation_PROPERTIES

      // END_USER_CODE-USER_ddLocation_PROPERTIES
    },
    ddOleic: {
      name: "ddOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Oleic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Peanut Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddPeanutVariety: {
      name: "ddPeanutVariety",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Peanut Variety:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_ddPeanutVariety_PROPERTIES
    },
    ddPremiumCode: {
      name: "ddPremiumCode",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Premium Code:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPremiumCode_PROPERTIES

      // END_USER_CODE-USER_ddPremiumCode_PROPERTIES
    },
    ddSeed: {
      name: "ddSeed",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Seed:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeed_PROPERTIES

      // END_USER_CODE-USER_ddSeed_PROPERTIES
    },
    ddSegType: {
      name: "ddSegType",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Seg Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSegType_PROPERTIES

      // END_USER_CODE-USER_ddSegType_PROPERTIES
    },
    lblTotal: {
      name: "lblTotal",
      type: "LabelWidget",
      parent: "grpbx1007s",
      Label: "Total:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblTotal_PROPERTIES

      // END_USER_CODE-USER_lblTotal_PROPERTIES
    },
    lblAvailble: {
      name: "lblAvailble",
      type: "LabelWidget",
      parent: "grpbx1007s",
      Label: "Available 1007s",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBuyPtId_PROPERTIES

      // END_USER_CODE-USER_lblBuyPtId_PROPERTIES
    },
    // txtarAvailable1007s: {
    //   name: "txtarAvailable1007s",
    //   type: "TextAreaWidget",
    //   parent: "grpbx1007s",
    //   Label: "Available 1007s:",
    //   ColSpan: "5",
    //   RowSpan: "4",
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtarAvailable1007s_PROPERTIES

    //   // END_USER_CODE-USER_txtarAvailable1007s_PROPERTIES
    // },
    col1007: {
      name: "col1007",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      Label: "1007 #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1007_PROPERTIES

      // END_USER_CODE-USER_col1007_PROPERTIES
    },
    colBasisGrd: {
      name: "colBasisGrd",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      Label: "Basis Grade",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBasisGrd_PROPERTIES

      // END_USER_CODE-USER_colBasisGrd_PROPERTIES
    },
    colContrct: {
      name: "colContrct",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      Label: "Contract #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContrct_PROPERTIES

      // END_USER_CODE-USER_colContrct_PROPERTIES
    },
    colExplain: {
      name: "colExplain",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      Label: "Explanation",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colExplain_PROPERTIES

      // END_USER_CODE-USER_colExplain_PROPERTIES
    },
    colFarm: {
      name: "colFarm",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      Label: "Farm",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFarm_PROPERTIES

      // END_USER_CODE-USER_colFarm_PROPERTIES
    },
    colGross: {
      name: "colGross",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      Label: "Gross Wt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGross_PROPERTIES

      // END_USER_CODE-USER_colGross_PROPERTIES
    },

    colTransaction: {
      name: "colTransaction",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      Label: "Transaction",
      Height: "",
      Width: "",
      Visible:false,
      // START_USER_CODE-USER_colLocation_PROPERTIES

      // END_USER_CODE-USER_colLocation_PROPERTIES
    },

    colLocation: {
      name: "colLocation",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      Label: "Location",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLocation_PROPERTIES

      // END_USER_CODE-USER_colLocation_PROPERTIES
    },
    colLskWt: {
      name: "colLskWt",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      Label: "LSK Wt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLskWt_PROPERTIES

      // END_USER_CODE-USER_colLskWt_PROPERTIES
    },
    colNetWt: {
      name: "colNetWt",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      Label: "Net Wt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNetWt_PROPERTIES

      // END_USER_CODE-USER_colNetWt_PROPERTIES
    },
    colOleic: {
      name: "colOleic",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      Label: "Oleic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOleic_PROPERTIES

      // END_USER_CODE-USER_colOleic_PROPERTIES
    },
    colPrem: {
      name: "colPrem",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      Label: "Premium",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPrem_PROPERTIES

      // END_USER_CODE-USER_colPrem_PROPERTIES
    },
    colPremCode: {
      name: "colPremCode",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      Label: "Premium Code",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPremCode_PROPERTIES

      // END_USER_CODE-USER_colPremCode_PROPERTIES
    },
    colPremRate: {
      name: "colPremRate",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      Label: "Premium Rate",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPremRate_PROPERTIES

      // END_USER_CODE-USER_colPremRate_PROPERTIES
    },
    colRevInd: {
      name: "colRevInd",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      Label: "Rev Ind",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRevInd_PROPERTIES

      // END_USER_CODE-USER_colRevInd_PROPERTIES
    },
    colsc95: {
      name: "colsc95",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      Label: "SC95 #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colsc95_PROPERTIES

      // END_USER_CODE-USER_colsc95_PROPERTIES
    },
    colSeed1: {
      name: "colSeed1",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      Label: "Seed",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeed1_PROPERTIES

      // END_USER_CODE-USER_colSeed1_PROPERTIES
    },
    colSeed2: {
      name: "colSeed2",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      Label: "Seed",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeed2_PROPERTIES

      // END_USER_CODE-USER_colSeed2_PROPERTIES
    },
    colSeg: {
      name: "colSeg",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      Label: "Seg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeg_PROPERTIES

      // END_USER_CODE-USER_colSeg_PROPERTIES
    },
    colType: {
      name: "colType",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      Label: "Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colType_PROPERTIES

      // END_USER_CODE-USER_colType_PROPERTIES
    },
    colVendr: {
      name: "colVendr",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      Label: "Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendr_PROPERTIES

      // END_USER_CODE-USER_colVendr_PROPERTIES
    },
    colVrty: {
      name: "colVrty",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      Label: "Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVrty_PROPERTIES

      // END_USER_CODE-USER_colVrty_PROPERTIES
    },
    colwr: {
      name: "colwr",
      type: "GridColumnWidget",
      parent: "gridExcPremReqSrch",
      Label: "WR #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colwr_PROPERTIES

      // END_USER_CODE-USER_colwr_PROPERTIES
    },
    txtcol1007: {
      name: "txtcol1007",
      type: "TextBoxWidget",
      colName: "col1007",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol1007_PROPERTIES

      // END_USER_CODE-USER_txtcol1007_PROPERTIES
    },
    txtcolBasisGrd: {
      name: "txtcolBasisGrd",
      type: "TextBoxWidget",
      colName: "colBasisGrd",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBasisGrd_PROPERTIES

      // END_USER_CODE-USER_txtcolBasisGrd_PROPERTIES
    },
    txtcolContrct: {
      name: "txtcolContrct",
      type: "TextBoxWidget",
      colName: "colContrct",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContrct_PROPERTIES

      // END_USER_CODE-USER_txtcolContrct_PROPERTIES
    },
    txtcolExplain: {
      name: "txtcolExplain",
      type: "TextBoxWidget",
      colName: "colExplain",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolExplain_PROPERTIES

      // END_USER_CODE-USER_txtcolExplain_PROPERTIES
    },
    txtcolFarm: {
      name: "txtcolFarm",
      type: "TextBoxWidget",
      colName: "colFarm",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFarm_PROPERTIES

      // END_USER_CODE-USER_txtcolFarm_PROPERTIES
    },
    txtcolGross: {
      name: "txtcolGross",
      type: "TextBoxWidget",
      colName: "colGross",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolGross_PROPERTIES

      // END_USER_CODE-USER_txtcolGross_PROPERTIES
    },
    txtcolTransaction: {
      name: "txtcolTransaction",
      type: "TextBoxWidget",
      colName: "colTransaction",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      Visible:false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },

    txtcolLocation: {
      name: "txtcolLocation",
      type: "TextBoxWidget",
      colName: "colLocation",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLocation_PROPERTIES

      // END_USER_CODE-USER_txtcolLocation_PROPERTIES
    },
    txtcolLskWt: {
      name: "txtcolLskWt",
      type: "TextBoxWidget",
      colName: "colLskWt",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLskWt_PROPERTIES

      // END_USER_CODE-USER_txtcolLskWt_PROPERTIES
    },
    txtcolNetWt: {
      name: "txtcolNetWt",
      type: "TextBoxWidget",
      colName: "colNetWt",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNetWt_PROPERTIES

      // END_USER_CODE-USER_txtcolNetWt_PROPERTIES
    },
    txtcolOleic: {
      name: "txtcolOleic",
      type: "TextBoxWidget",
      colName: "colOleic",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOleic_PROPERTIES

      // END_USER_CODE-USER_txtcolOleic_PROPERTIES
    },
    txtcolPrem: {
      name: "txtcolPrem",
      type: "TextBoxWidget",
      colName: "colPrem",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPrem_PROPERTIES

      // END_USER_CODE-USER_txtcolPrem_PROPERTIES
    },
    txtcolPremCode: {
      name: "txtcolPremCode",
      type: "TextBoxWidget",
      colName: "colPremCode",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPremCode_PROPERTIES

      // END_USER_CODE-USER_txtcolPremCode_PROPERTIES
    },
    txtcolPremRate: {
      name: "txtcolPremRate",
      type: "TextBoxWidget",
      colName: "colPremRate",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPremRate_PROPERTIES

      // END_USER_CODE-USER_txtcolPremRate_PROPERTIES
    },
    txtcolRevInd: {
      name: "txtcolRevInd",
      type: "TextBoxWidget",
      colName: "colRevInd",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRevInd_PROPERTIES

      // END_USER_CODE-USER_txtcolRevInd_PROPERTIES
    },
    txtcolsc95: {
      name: "txtcolsc95",
      type: "TextBoxWidget",
      colName: "colsc95",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolsc95_PROPERTIES

      // END_USER_CODE-USER_txtcolsc95_PROPERTIES
    },
    txtcolSeed1: {
      name: "txtcolSeed1",
      type: "TextBoxWidget",
      colName: "colSeed1",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeed1_PROPERTIES

      // END_USER_CODE-USER_txtcolSeed1_PROPERTIES
    },
    txtcolSeed2: {
      name: "txtcolSeed2",
      type: "TextBoxWidget",
      colName: "colSeed2",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeed2_PROPERTIES

      // END_USER_CODE-USER_txtcolSeed2_PROPERTIES
    },
    txtcolSeg: {
      name: "txtcolSeg",
      type: "TextBoxWidget",
      colName: "colSeg",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeg_PROPERTIES

      // END_USER_CODE-USER_txtcolSeg_PROPERTIES
    },
    txtcolType: {
      name: "txtcolType",
      type: "TextBoxWidget",
      colName: "colType",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolType_PROPERTIES

      // END_USER_CODE-USER_txtcolType_PROPERTIES
    },
    txtcolVendr: {
      name: "txtcolVendr",
      type: "TextBoxWidget",
      colName: "colVendr",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendr_PROPERTIES

      // END_USER_CODE-USER_txtcolVendr_PROPERTIES
    },
    txtcolVrty: {
      name: "txtcolVrty",
      type: "TextBoxWidget",
      colName: "colVrty",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVrty_PROPERTIES

      // END_USER_CODE-USER_txtcolVrty_PROPERTIES
    },
    txtcolwr: {
      name: "txtcolwr",
      type: "TextBoxWidget",
      colName: "colwr",
      parent: "gridExcPremReqSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolwr_PROPERTIES

      // END_USER_CODE-USER_txtcolwr_PROPERTIES
    },
    gridExcPremReqSrch: {
      name: "gridExcPremReqSrch",
      type: "GridWidget",
      parent: "grpbx1007s",
      gridCellsOrder:
        "colIsSts,txtcolTransaction,txtcolLocation,txtcolVendr,txtcolPremCode,txtcolsc95,txtcol1007,txtcolwr,txtcolPrem,txtcolPremRate,txtcolFarm,txtcolSeg,txtcolType,txtcolVrty,txtcolOleic,txtcolSeed1,txtcolSeed2,txtcolGross,txtcolNetWt,txtcolLskWt,txtcolBasisGrd,txtcolContrct,txtcolRevInd,txtcolExplain",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      isEditable: true,
      isMultiSelect: true,
      // START_USER_CODE-USER_gridExcPremReqSrch_PROPERTIES
      hideAllCheckBox: false,
      needGridChange:true
      // END_USER_CODE-USER_gridExcPremReqSrch_PROPERTIES
    },
    txtarExplanation: {
      name: "txtarExplanation",
      type: "TextAreaWidget",
      parent: "grpbxPayReq",
      Label: "Explanation:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtarExplanation_PROPERTIES
      Mandatory:true,
      // END_USER_CODE-USER_txtarExplanation_PROPERTIES
    },
    txtContract: {
      name: "txtContract",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: "Contract #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContract_PROPERTIES

      // END_USER_CODE-USER_txtContract_PROPERTIES
    },
    // txtDateReq: {
    //   name: "txtDateReq",
    //   type: "TextBoxWidget",
    //   parent: "grpbxPayReq",
    //   Label: "Date Requested:",
    //   LengthRange: { MinLength: 0, MaxLength: 25 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtDateReq_PROPERTIES

    //   // END_USER_CODE-USER_txtDateReq_PROPERTIES
    // },
    txtDateReq: {
      name: "txtDateReq",
      type: "DateTimeWidget",
      parent: "grpbxPayReq",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 16 },
      ofTypeDomain: "d_dateTime",
      // START_USER_CODE-USER_txtDateReq_PROPERTIES

      // END_USER_CODE-USER_txtDateReq_PROPERTIES
    },
    txtFarm: {
      name: "txtFarm",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: "Farm #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarm_PROPERTIES

      // END_USER_CODE-USER_txtFarm_PROPERTIES
    },
    txtFarmSuffix: {
      name: "txtFarmSuffix",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: "Farm Suffix #:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarmSuffix_PROPERTIES

      // END_USER_CODE-USER_txtFarmSuffix_PROPERTIES
    },
    txtGeneration: {
      name: "txtGeneration",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGeneration_PROPERTIES

      // END_USER_CODE-USER_txtGeneration_PROPERTIES
    },
    txtGrossWT: {
      name: "txtGrossWT",
      type: "TextBoxWidget",
      parent: "grpbx1007s",
      Label: "Gross WT",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGrossWT_PROPERTIES
      ReadOnly: true,
      Enabled:false,
      // END_USER_CODE-USER_txtGrossWT_PROPERTIES
    },
    txtVndrName: {
      name: "txtVndrName",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLocation_PROPERTIES
      ReadOnly: true,
      Enabled:false,
      // END_USER_CODE-USER_txtLocation_PROPERTIES
    },
    txtLSKWT: {
      name: "txtLSKWT",
      type: "TextBoxWidget",
      parent: "grpbx1007s",
      Label: "LSK WT",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKWT_PROPERTIES
      ReadOnly: true,
      Enabled:false,
      // END_USER_CODE-USER_txtLSKWT_PROPERTIES
    },
    txtNetWT: {
      name: "txtNetWT",
      type: "TextBoxWidget",
      parent: "grpbx1007s",
      Label: "Net WT",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetWT_PROPERTIES
      ReadOnly: true,
      Enabled:false,
      // END_USER_CODE-USER_txtNetWT_PROPERTIES
    },
    txtOleic: {
      name: "txtOleic",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOleic_PROPERTIES

      // END_USER_CODE-USER_txtOleic_PROPERTIES
    },
    txtPayReq: {
      name: "txtPayReq",
      type: "TextBoxWidget",
      parent: "grpbxReprintPayReq",
      Label: "Pay Request #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPayReq_PROPERTIES

      // END_USER_CODE-USER_txtPayReq_PROPERTIES
    },
    txtPeanuttype: {
      name: "txtPeanuttype",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanuttype_PROPERTIES

      // END_USER_CODE-USER_txtPeanuttype_PROPERTIES
    },
    txtPeanutVariety: {
      name: "txtPeanutVariety",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_txtPeanutVariety_PROPERTIES
    },
    txtPremiumAmount: {
      name: "txtPremiumAmount",
      type: "TextBoxWidget",
      parent: "grpbx1007s",
      Label: "Premium Amount",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPremiumAmount_PROPERTIES
      ReadOnly: true,
      Enabled:false,
      // END_USER_CODE-USER_txtPremiumAmount_PROPERTIES
    },
    txtPremiumCode: {
      name: "txtPremiumCode",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPremiumCode_PROPERTIES

      // END_USER_CODE-USER_txtPremiumCode_PROPERTIES
    },
    txtReqBy: {
      name: "txtReqBy",
      type: "TextBoxWidget",
      parent: "grpbxPayReq",
      Label: "Requested By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtReqBy_PROPERTIES

      // END_USER_CODE-USER_txtReqBy_PROPERTIES
    },
    txtSeed: {
      name: "txtSeed",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeed_PROPERTIES

      // END_USER_CODE-USER_txtSeed_PROPERTIES
    },
    txtSegType: {
      name: "txtSegType",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSegType_PROPERTIES

      // END_USER_CODE-USER_txtSegType_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    grpbxSearch: {
      name: "grpbxSearch",
      type: "GroupBoxWidget",
      parent: "ExceptPremRequestSearch",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxSearch_PROPERTIES

      // END_USER_CODE-USER_grpbxSearch_PROPERTIES
    },
    grpbx1007s: {
      name: "grpbx1007s",
      type: "GroupBoxWidget",
      parent: "ExceptPremRequestSearch",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbx1007s_PROPERTIES

      // END_USER_CODE-USER_grpbx1007s_PROPERTIES
    },
    grpbxPayReq: {
      name: "grpbxPayReq",
      type: "GroupBoxWidget",
      parent: "ExceptPremRequestSearch",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxPayReq_PROPERTIES

      // END_USER_CODE-USER_grpbxPayReq_PROPERTIES
    },
    grpbxReprintPayReq: {
      name: "grpbxReprintPayReq",
      type: "GroupBoxWidget",
      parent: "ExceptPremRequestSearch",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxReprintPayReq_PROPERTIES

      // END_USER_CODE-USER_grpbxReprintPayReq_PROPERTIES
    },
    grpbxExit: {
      name: "grpbxExit",
      type: "GroupBoxWidget",
      parent: "ExceptPremRequestSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxExit_PROPERTIES

      // END_USER_CODE-USER_grpbxExit_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#ReportPreviewExcPrem": {},
        "ContractManagement#VendorLookup": {},
      },
      REVERSE: {
        "ContractManagement#ReportPreviewExcPrem": {},
        "ContractManagement#VendorLookup": {},
      },
    },
  };
  let _buttonServices = { ReportPreviewExcPrem: {} };
  let _buttonNavigation = {    
    btnVendor: {

      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],

    },
  };  
  let cropyear = JSON.parse(sessionStorage.getItem('year'));
  let compId = sessionStorage.getItem('compId');
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');
  let userid = useridFromLS();
  var transactionNumber = '';
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });

  // Start Method for load the Vendor details  
  useEffect(() => {
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    let vendor_lookup = getData(thisObj, 'vendorDetails')
    if (vendor_lookup !== null) {
      thisObj.setFieldValue("txtVendor",vendor_lookup.VendorNumber);
      document.getElementsByClassName("txtVndrName")[0].style.visibility = "visible";
      thisObj.setFieldValue("txtVndrName",vendor_lookup.key);
    }
    else{
      document.getElementsByClassName("txtVndrName")[0].style.visibility = "hidden";
    }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, [getData(thisObj, 'vendorDetails')]);
// End Method for load the Vendor details  


  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS  

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    EnableDisableControl();
    FormLoad();
    setValue(thisObj, "ddLocation", ''),
    setValue(thisObj, "ddPeanutType", ''),
    setValue(thisObj, "ddPeanutVariety", ''),
    setValue(thisObj, "ddPremiumCode", ''),
    setValue(thisObj, "ddSegType", ''),
    setValue(thisObj, "ddOleic", ''),
    setValue(thisObj, "ddSeed", ''),
    setValue(thisObj, "ddGeneration", '')
    setValue(thisObj, "txtGrossWT","0"),
    setValue(thisObj, "txtNetWT","0"),
    setValue(thisObj, "txtLSKWT","0"),
    setValue(thisObj, "txtPremiumAmount","0")
  }, []);

	useEffect(() => {
    let reload = getData(thisObj,'reloadExceptPremRequest')
    if(reload == true){
      onbtnSearchClick()
    }
    setData(thisObj,'reloadExceptPremRequest',false)
  }, [getData(thisObj,'reloadExceptPremRequest')]);

// Start Method for Enable and Disable Controls
  const EnableDisableControl = () => {
    disable(thisObj, "btnPrintPayReq");
    disable(thisObj, "btnRejectPrem");
  }
  // End Method for Enable and Disable Controls

  // Formating for the FarmBlur and Farm suffix Blur TextBox
  const ontxtFarmBlur = () => {
    let FarmNumber = getValue(thisObj, 'txtFarm')
    if (FarmNumber != null && FarmNumber != undefined) {
      let FarmNumLength = FarmNumber.length
      if (FarmNumLength < 7 && FarmNumLength > 0) {
        let NewFrmNum = '0'.repeat((7 - FarmNumLength)) + FarmNumber
        setValue(thisObj, 'txtFarm', NewFrmNum)
      }
    }
  }
  thisObj.ontxtFarmBlur = ontxtFarmBlur

  const ontxtFarmSuffixBlur = () => {
    let FarmNumber = getValue(thisObj, 'txtFarmSuffix')
    if (FarmNumber != null && FarmNumber != undefined) {
      let FarmNumLength = FarmNumber.length
      if (FarmNumLength < 3 && FarmNumLength > 0) {
        let NewFrmNum = '0'.repeat((3 - FarmNumLength)) + FarmNumber
        setValue(thisObj, 'txtFarmSuffix', NewFrmNum)
      }
    }
  }
  thisObj.ontxtFarmSuffixBlur = ontxtFarmSuffixBlur

// Start method to load all the function at form load
  function FormLoad() {
    bFillTypeList(); // Method for binding Peanut Type dropdown
    bFillBuyingPointList(); // Method for binding Buying Points
    bFillPeanutVarietyList(null, null); // Method for binding peanut veriety    
    bFillGenList();  // Method for binding Generation
    bFillSegmentList(); // Method for binding Segments
    bFillOleicList(); // Method for binding Oleic values
    bFillSeedList(); // Method for binding Seed Grower List
    bFillPremCodeList(); // Method for binding Premium Code  
    PremiumCodeListDetails();
    document.getElementsByClassName("txtVndrName")[0].style.visibility = "hidden";
    setValue(thisObj,"txtVendor","")
    setValue(thisObj,"txtVndrName","")
    setData(thisObj,'reloadExceptPremRequest',false)

  }
  // End method to load all the function at form load

  //only numeric value from 0 to 9 allowed functionality(Ascii Functionality)
  

  // START_USER_CODE-USER_METHODS
  // Start Method for  Select All Grid Value
  const onchkboxAll1007sChange = () => {
    try {
      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
      const chk = getValue(thisObj, "chkboxAll1007s");
      const target = document.getElementsByClassName("rdt_TableHeadRow")[0].firstChild.firstChild;
      if (chk) {
        target.click();
      }
      else {
        target.click();
      }

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        // showMessage(
        //   thisObj,
        //   "Exception in PreSubmit Event code for widget:btnExit event:Click"
        // );
      }
      return false;
    }
    return true;
  };
  thisObj.onchkboxAll1007sChange = onchkboxAll1007sChange;
// End Method for  Select All Grid Value

// Start Method for Selected Grid value changing 
  const ongridExcPremReqSrchRowSelect = (rowSelected) => {
    try {
      let data = rowSelected.selectedRows;      
      setData(thisObj, 'rowSelected', rowSelected.selectedRows);
      if (data[0] !== undefined){ 
        let gridSelectedValue = [];
        for(let i = 0; i < data.length; i++){
        if (data[i].txtcolTransaction !== undefined && data[i].txtcolTransaction !== null) {
          let obj = data[i].txtcolTransaction;
          gridSelectedValue.push(obj)
        }
      }
      setData(thisObj, 'txtcolTransaction', gridSelectedValue)
    }
      let grossTotal = 0
      let netWtTotal = 0
      let lstWtTotal = 0
      let premTotal  = 0

      if (data !== undefined && data !== null) {
        for (var i = 0; i < data.length; i++) {
          grossTotal = grossTotal + parseFloat(data[i].txtcolGross)
          netWtTotal = netWtTotal + parseFloat(data[i].txtcolNetWt)
          lstWtTotal = lstWtTotal + parseFloat(data[i].txtcolLskWt)
          premTotal  = premTotal  + parseFloat(data[i].txtcolPrem)
        }
      }
      if (grossTotal == 0 && netWtTotal == 0 && lstWtTotal == 0 && premTotal == 0)
        setValue(thisObj, "txtGrossWT",       "0"),
        setValue(thisObj, "txtNetWT",         "0"),
        setValue(thisObj, "txtLSKWT",         "0"),
        setValue(thisObj, "txtPremiumAmount", "0")
      else
        setValue(thisObj, "txtGrossWT",       grossTotal),
        setValue(thisObj, "txtNetWT",         netWtTotal),
        setValue(thisObj, "txtLSKWT",         lstWtTotal)
        setValue(thisObj, "txtPremiumAmount", premTotal)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Buying Point dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ongridExcPremReqSrchRowSelect = ongridExcPremReqSrchRowSelect;
// End Method for Selected Grid value changing

// Start Method for Reprint functionality  
  const onbtnReprintPayReqClick = async () => {
    try {    
      let req_Memo_Num = getValue(thisObj, "txtPayReq");
      if(req_Memo_Num==undefined || req_Memo_Num==null||req_Memo_Num==''){
        alert("Pay Request # is required !",false);
      }
      else if (req_Memo_Num !== "" || req_Memo_Num !== undefined) {
        var data = await ContractManagementService.RetrieveExceptionPremiumRequests(req_Memo_Num, null)//GetExecPremReqDetail(req_Memo_Num)
        var js = []
        for(var i=0;i<data[0].exceptpremrequestdetails.length;i++){
          var obj = {
            "txtCol1007"     : data[0].exceptpremrequestdetails[i].settle_num , 
            "txtColFarm"     : data[0].exceptpremrequestdetails[i].farm_id +" "+data[0].exceptpremrequestdetails[i].farm_suffix, 
            "txtColContrct"  : data[0].exceptpremrequestdetails[i].contract_num ,
            "txtColInsp"     : data[0].exceptpremrequestdetails[i].insp_num ,
            "txtColWr"       : data[0].exceptpremrequestdetails[i].whse_rcpt_num , 
            "txtColGross"    : data[0].exceptpremrequestdetails[i].gross_wt ,
            "txtColNetWt"    : data[0].exceptpremrequestdetails[i].net_wt ,
            "txtColLskWt"    : data[0].exceptpremrequestdetails[i].lsk_wt ,
            "txtColBasisGrd" : data[0].exceptpremrequestdetails[i].basis_grade_amt ,
            "txtColSeg"      : data[0].exceptpremrequestdetails[i].seg_type ,
            "txtColPnutTyp"  : data[0].exceptpremrequestdetails[i].pnut_type_id ,
            "txtColPnutVrty" : data[0].exceptpremrequestdetails[i].pnut_variety_id ,
            "txtColGen"      : data[0].exceptpremrequestdetails[i].seed_gen ,
            "txtColSeedSpec" : data[0].exceptpremrequestdetails[i].seed_meeting_spec_ind ,
            "txtColOleic"    : data[0].exceptpremrequestdetails[i].oleic_ind ,
            "txtColPremRate" : data[0].exceptpremrequestdetails[i].prem_rate ,
            "txtColPremAmnt" : data[0].exceptpremrequestdetails[i].prem_amount 
           }
           js.push(obj)
        }
        goTo(thisObj,"ContractManagement#ReportPreviewExcPrem#DEFAULT#true#Click")
        commonContext.setExceptPremCreate_MemoNum(req_Memo_Num);
        setData(thisObj, 'frmReportPreviewExceptPrem', js)
      }
      
    
    } catch (error) {

    }
  }
  thisObj.onbtnReprintPayReqClick = onbtnReprintPayReqClick;
// End Method for Reprint functionality

// Start Method for Transactrion_Num and being called in Print pay request 
  async function Transaction_Number(transactionNumber) {
    
    if (transactionNumber != null) {
      let flag = getData(thisObj, "flag");
      var transactionNumber = transactionNumber
      var type = flag
      var user_id = userid
      var requested_by = txtReqBy.value
      var datetime = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString();//(new Date()).toISOString();
      var request_explanation = txtarExplanation.value

      let dataObj = {
        transaction_number: transactionNumber,
        type: type,
        user_id: user_id,
        requested_by: requested_by,
        request_date_time: datetime,
        request_explanation: request_explanation
      }
      let response = await ContractManagementService.CreateExceptionPremiumRequestDetails(dataObj)
      if (flag == "REJECT") {
        showMessage(thisObj, response.message)
        onbtnSearchClick();
        //;setData(thisObj,'reloadExceptPremRequest',true)
      }
      else {
        showMessage(thisObj, response.message, true);
        commonContext.setExceptPremCreate_MemoNum(response.result);//Data pass to ReportPreviewExcPrem Screen
        onbtnSearchClick();
        return true;
      }
    }
    else {
      showMessage(thisObj, "Create request has failed!!! Please retry!!!", false)
    }
    return true;
  }
  // End Method for Transactrion_Num and being called in Print pay request 

  
// Start Method for Button CreatePayReq functionality
  const onbtnCreatePayReqClick = () => {
    try {
      let selectData = getData(thisObj, "rowSelected");
      if (txtarExplanation.value == '') {
        showMessage(thisObj, "Some Explanation is required!!!")
        return
      }
      else if (selectData === null || selectData['length'] === 0) {
        showMessage(thisObj, "Must select atleast one Exception Premium in the result grid!!!");
        return;
      }
      else {
        var data = getValue(thisObj, 'gridExcPremReqSrch');
        let js = [];
        for (var i = 0; i < data.length; i++) {
          if (data[i].isSelected) {
            var obj = {
              "txtCol1007": data[i].txtcol1007,
              "txtColFarm": data[i].txtcolFarm,
              "txtColContrct": data[i].txtcolContrct,
              "txtColInsp": data[i].txtcolsc95,
              "txtColWr": data[i].txtcolwr,
              "txtColGross": data[i].txtcolGross,
              "txtColNetWt": data[i].txtcolNetWt,
              "txtColLskWt": data[i].txtcolLskWt,
              "txtColBasisGrd": data[i].txtcolBasisGrd,
              "txtColSeg": data[i].txtcolSeg,
              "txtColPnutTyp": data[i].txtcolType,
              "txtColPnutVrty": data[i].txtcolVrty,
              "txtColGen": data[i].txtcolSeed1,
              "txtColSeedSpec": data[i].txtcolSeed2,
              "txtColOleic": data[i].txtcolOleic,
              "txtColPremRate": data[i].txtcolPremRate,
              "txtColPremAmnt": data[i].txtcolPrem,
            }
            js.push(obj)
          }
        }
        setData(thisObj, 'frmReportPreviewExceptPrem', js)
        setData(thisObj, "flag", "CREATE");
        var flag = false
        let createData = getData(thisObj, "rowSelected");
        let tranArr = [];
        for (let i = 0; i < createData.length; i++) {
          transactionNumber = createData[i].txtcolTransaction;
          tranArr.push(transactionNumber)
        }
        Transaction_Number((tranArr)?.toString()) // Passing Transaction Numbers in String Format fixed
        goTo(thisObj, "ContractManagement#ReportPreviewExcPrem#DEFAULT#true#Click");     
        onbtnSearchClick();   
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCreatePayReq event:Click"
        );
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnPrintPayReqClick = onbtnCreatePayReqClick;
// End Method for Button CreatePayReq functionality

  // Start Methof for Button RejectPrem Functionality
  const onbtnRejectPremClick = () => {
    try {
      var dateTime = moment(new Date()).format('MM/DD/YYYY hh:mm A');
      if (thisObj.isValid) {
        setData(thisObj, "flag", "REJECT");

        if (thisObj.values.txtDateReq == '' || thisObj.values.txtDateReq == undefined) {
          setValue(thisObj, 'txtDateReq', dateTime)
        };

        let rejectData = getData(thisObj, "rowSelected");
        let tranArr = [];
        for (let i = 0; i < rejectData.length; i++) {
          transactionNumber = rejectData[i].txtcolTransaction;
          tranArr.push(transactionNumber)
        }
        Transaction_Number(tranArr)       
        if (data !== null) {
          var js3 = []
          for (var i = 0; i < data.length; i++) {
            if (data[i].isSelected) {
              js3.push(data[i])
            }
          }
          setData(thisObj, 'frmReportPreviewExceptPrem', js3)
        }
      }
      {
        if (txtarExplanation.value == '') {
          showMessage(thisObj, "Some Explanation is required!!!")
          return
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnRejectPremClick = onbtnRejectPremClick;  
// End Methof for Button RejectPrem Functionality

//textboxes validation
  const ontxtContractChange = () => {
    setValue(thisObj, "txtContract", returnNumericValues(txtContract.value))
  };
  thisObj.ontxtContractChange = ontxtContractChange;

  const ontxtPayReqChange = () => {
    let tempval = thisObj.values.txtPayReq
    tempval = returnNumericValues(tempval)
    setValue(thisObj, "txtPayReq", tempval)
  };
  thisObj.ontxtPayReqChange = ontxtPayReqChange;

// Start Method for search functionality
  const onbtnSearchClick = () => {
    try {
      let prem_vendor     = txtVendor.value
      let prem_code       = thisObj.values['ddPremiumCode']
      let farm_id         = txtFarm.value
      let buy_pt_id       = thisObj.values['ddLocation']
      let farm_suffix     = txtFarmSuffix.value
      let contract_num    = txtContract.value
      let pnut_type_id    = thisObj.values['ddPeanutType']
      let pnut_variety_id = thisObj.values['ddPeanutVariety']
      let seed_ind        = thisObj.values['ddSeed']
      let seed_gen        = thisObj.values['ddGeneration']
      let oleic_ind       = thisObj.values['ddOleic']
      let seg_type        = thisObj.values['ddSegType']      

      if (buy_pt_id == '') {
        alert("Location is required!!!",false);
      } 
      else if (prem_vendor == '') {
        alert("Vendor # is required!!!",false);
      } 
      else if (prem_code == '') {
        alert("Premium Code is required!!!",false);
      } 
      else {
        ContractManagementService.RetrieveExceptionPremiumDetails(buy_pt_id, prem_vendor, prem_code, farm_id, farm_suffix, contract_num, pnut_type_id, pnut_variety_id, seed_ind, seed_gen, oleic_ind, seg_type)
          .then(response => {
            let expPremData = response;        
            let expPremjs = [];
            let expPremobj = {};
            if (expPremData.length != 0 && expPremData != null) {
              enable(thisObj, "btnPrintPayReq");
              enable(thisObj, "btnRejectPrem");
              var dateTime = moment(new Date()).format('MM/DD/YYYY hh:mm A');
              // Comes from login page functionality
              ContractManagementService.RetrieveUserControlDetails(userid).then(response => {
                let userControlData = response;
                let userControljs = [];
                for (var i = 0; i < userControlData.length; i++) {
                  userControljs.push(userControlData[i].userName);
                }
                setValue(thisObj, 'txtReqBy', userControljs[0]);
                setValue(thisObj, 'txtDateReq', dateTime);// Date Time Formatting
              });
            }
            for (var i = 0; i < expPremData.length; i++) {
              expPremobj.txtcolTransaction    = expPremData[i].transaction_num;
              expPremobj.txtcolLocation       = expPremData[i].buy_pt_id   + '-' + expPremData[i].buy_pt_name;
              expPremobj.txtcolVendr          = expPremData[i].prem_vendor + '-' + expPremData[i].vendor_name;
              expPremobj.txtcolPremCode       = expPremData[i].prem_code   + '-' + expPremData[i].pd_desc;
              expPremobj.txtcolsc95           = expPremData[i].insp_num;
              expPremobj.txtcol1007           = expPremData[i].settle_num;
              expPremobj.txtcolwr             = expPremData[i].whse_rcpt_num;
              expPremobj.txtcolPrem           = expPremData[i].prem_amount;
              let tempvar = expPremData[i].prem_rate_basis;
              if(tempvar.slice(tempvar.indexOf(".")+1,tempvar.indexOf(".")+6)!=='00000'){
                tempvar = tempvar.slice(0,tempvar.indexOf(".")+3) + tempvar.slice(tempvar.indexOf(".")+6,tempvar.length)
              }
              else{
                tempvar = tempvar.slice(0,tempvar.indexOf(".")) + tempvar.slice(tempvar.indexOf(".")+6,tempvar.length)
              }
              expPremobj.txtcolPremRate       = tempvar;
              expPremobj.txtcolFarm           = expPremData[i].farm_id + "-" + expPremData[i].farm_suffix;
              expPremobj.txtcolSeg            = expPremData[i].seg_type;
              expPremobj.txtcolType           = expPremData[i].pnut_type_id;
              expPremobj.txtcolVrty           = expPremData[i].pnut_variety_id;
              expPremobj.txtcolOleic          = expPremData[i].oleic_ind;
              expPremobj.txtcolSeed1          = expPremData[i].seed_gen;
              expPremobj.txtcolSeed2          = expPremData[i].seed_meeting_spec_ind;
              expPremobj.txtcolGross          = expPremData[i].gross_wt;
              expPremobj.txtcolNetWt          = expPremData[i].net_wt;
              expPremobj.txtcolLskWt          = expPremData[i].lsk_wt;
              expPremobj.txtcolBasisGrd       = expPremData[i].basis_grade_amt;
              expPremobj.txtcolContrct        = expPremData[i].contract_num;
              expPremobj.txtcolRevInd         = expPremData[i].reversal_ind;
              expPremobj.txtcolExplain        = expPremData[i].explanation;
              expPremobj.support_per_ton      = parseFloat(expPremData[i].support_per_ton);
              expPremjs.push(expPremobj);
              expPremobj = {};
            }
            setValue(thisObj, "gridExcPremReqSrch", expPremjs);
            setData(thisObj,'gridExcPremReqSrch',expPremjs);
          });
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:btnSearch: Click"
        )
      }
      return false
    }
    return true
  };
  thisObj.onbtnSearchClick = onbtnSearchClick;
// End Method for search functionality

// Start Method for getting Vendor Lookup
const ontxtVendorChange = () => {
  try {
    let vendorNumber = txtVendor.value
    if (vendorNumber.length == 6) {
      setVendorNameBasedOnVendorNumber(vendorNumber)
    }
    else {
      document.getElementsByClassName("txtVndrName")[0].style.visibility = "hidden";
    }

  } catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Exception in PreSubmit Event code for widget:txtVondor event:Change"
      );
    }
    return false;
  }
  return true;
};
thisObj.ontxtVendorChange = ontxtVendorChange;


  const setVendorNameBasedOnVendorNumber = (vendorNumber) => {

    let venname = null
    let vendornamevisible = false
    ContractManagementService.RetrieveVendorByNumber(vendorNumber).then(response => {
      let data = response
      if (data !== null && data !== undefined) {
        if(data.length == 0){
          return;
        }
        else{
          venname = data[0].name
          vendornamevisible = true
        }
        }
      if (vendornamevisible == true) {
        document.getElementsByClassName("txtVndrName")[0].style.visibility = "visible";
        setValue(thisObj, "txtVndrName", venname)
      }
      else {
        document.getElementsByClassName("txtVndrName")[0].style.visibility = "hidden";
      }
    })
  }
// End art Method for getting Vendor Lookup

// Start Method for Buying Point dropdown change event
  const onBuyingPointChange = () => {
    try {
      let PremiumCodelist = thisObj.state['ddPremiumCode'].valueList;
      let SelectedPremiumCodeValue = thisObj.values['ddPremiumCode'];
      let premium_code = null
      if (SelectedPremiumCodeValue !== "" && SelectedPremiumCodeValue !== undefined) {
        premium_code = PremiumCodelist.find(elem => elem.key === SelectedPremiumCodeValue).key;
      }

      let BuyingPointList = thisObj.state['ddLocation'].valueList;
      let SelectedBuyingPointValue = thisObj.values['ddLocation'];
      let buyingPointId = null
      if (SelectedBuyingPointValue !== "" && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }
      //Bind premium code based on Buying Point 
      bFillPremCodeList()
      filterPremiumCodeList(buyingPointId)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Buying Point dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddLocationChange = onBuyingPointChange;
// End Method for Buying Point dropdown change event

// Start Method for Peanut Type dropdown change event
  const onPeanutTypeChange = () => {
    try {
      let PeanutTypelist = thisObj.state['ddPeanutType'].valueList;
      let SelectedPeanutTypeValue = thisObj.values['ddPeanutType'];
      let pnut_type_id = PeanutTypelist.find(elem => elem.key === SelectedPeanutTypeValue).key;
      let BuyingPointList = thisObj.state['ddLocation'].valueList;
      let SelectedBuyingPointValue = thisObj.values['ddLocation'];
      let buyingPointId = null
      if (SelectedBuyingPointValue !== null && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }
      //Bind Peanut Variety based on Buying Point and Peanut Type
      bFillPeanutVarietyList(buyingPointId, pnut_type_id)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Peanut Type dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddPeanutTypeChange = onPeanutTypeChange;
// End Method for Peanut Type dropdown change event

// Start Method for binding Premium Code dropdown based on buying points
  function filterPremiumCodeList(buyingPointId) {
    let dataList = getValue(thisObj, "lstPremiumDeductionSchDetail")
    var res = []
    res.push({ key: '', description: '>>>All Premium Codes<<<' })
    for (var i = 0; i < dataList.length; i++) {
      if (dataList[i].buyPtId == buyingPointId) {
        var obj = { key: dataList[i].pdCode, description: dataList[i].pdCode.trim() + '-' + dataList[i].pdDesc.trim() }
        res.push(obj)
      }
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddPremiumCode: {
          ...state["ddPremiumCode"],
          valueList: res,
        }
      }
    })
  }

  const bFillPremCodeList = () => {
    let js = [];
    js.push({ key: '', description: '>>>All Premium Codes<<<' })
    thisObj.setState(current => {
      return {
        ...current,
        ddPremiumCode: {
          ...state["ddPremiumCode"],
          valueList: js
        }
      }
    })
  }


  const PremiumCodeListDetails = (buyingPointId) => {
    // let buy_pt_id = getData(thisObj, 'ddLoc');
    let js = [];
    // js.push({ key: '', description: '>>> All Premium Codes <<<' })
    let res = []
    let pd_code = null
    let inspect_ind = null
    let rate_type = null
    let check_enable_ind = null
    let rcpt_enable_ind = null
    let rpt_1099_ind = null
    let except_prem_ind = 'Y'
    ContractManagementService.RetrivePremiumDeductionScheduleDetails(buyingPointId, pd_code, inspect_ind, rate_type, check_enable_ind, rcpt_enable_ind, rpt_1099_ind, except_prem_ind).then(response => {
      let data = response
      setValue(thisObj, "lstPremiumDeductionSchDetail", response)
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].pdCode, description: data[i].pdCode.trim() + '-' + data[i].pdDesc.trim() }
        if (!res.includes(data[i].pdCode)) {
          js.push(obj)
          res.push(data[i].pdCode)
        }
      }
    })
  }
// End Method for binding Premium Code dropdown based on buying points

// Start Method for binding Seed Grower List
  const bFillSeedList = () => {
    let js = [];
    js.push({ key: '', description: '>>> Both Seeds <<<' })
    js.push({ key: 'Y', description: 'Seed' })
    js.push({ key: 'N', description: 'Non-Seed' })
    thisObj.setState(current => {
      return {
        ...current,
        ddSeed: {
          ...state["ddSeed"],
          valueList: js
        }
      }
    })
  }
// End Method for binding Seed Grower List

// Start Method for binding Oleic values
  const bFillOleicList = () => {
    let js = []
    js.push({ key: '', description: '>>> All Oleic <<<' })
    js.push({ key: 'H', description: 'High' })
    js.push({ key: 'M', description: 'Mid' })
    js.push({ key: 'N', description: 'None' })
    thisObj.setState(current => {
      return {
        ...current,
        ddOleic: {
          ...state["ddOleic"],
          valueList: js
        }
      }
    })
  }
// End Method for binding Oleic values

// Start Method for binding Segment list  
  const bFillSegmentList = () => {
    let js = []
    js.push({ key: '', description: '>>> All Segments <<<' })
    js.push({ key: '1', description: 'Seg 1' })
    js.push({ key: '2', description: 'Seg 2' })
    js.push({ key: '3', description: 'Seg 3' })

    thisObj.setState(current => {
      return {
        ...current,
        ddSegType: {
          ...state["ddSegType"],
          valueList: js
        }
      }
    })
  }
// End Method for binding Segment list  

// Start Method for binding Generation list
  const bFillGenList = () => {
    let js = [];
    js.push({ key: '', description: '>>> All Generation <<<' })
    js.push({ key: 'F', description: 'Foundation' })
    js.push({ key: 'R', description: 'Registered' })
    js.push({ key: 'C', description: 'Certified' })
    thisObj.setState(current => {
      return {
        ...current,
        ddGeneration: {
          ...state["ddGeneration"],
          valueList: js
        }
      }
    })
  }
// End Method for binding Generation list

// Start Method for binding peanut veriety based on buying point
  const bFillPeanutVarietyList = (buyingPointId, pnut_type_id) => {
    let js = [];
    let obj = { key: '', description: ">>> All Varieties <<<" }
    js.push(obj)
    if (pnut_type_id !== null && pnut_type_id !== '') {
      ContractManagementService.RetrievePeanutVarietyControls(buyingPointId, null, null, pnut_type_id, null)
        .then(response => {
          let data = response;
          if (data !== null && data !== undefined) {
            for (var i = 0; i < data.length; i++) {
              if (!(js.includes(data[i].pnut_variety_id))) {
                obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name }
                if (data[i].symbol_ind == "TRADEMARK") {
                  obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + 'ᵀᴹ' }
                }
                if (data[i].symbol_ind == "REGISTERED TRADEMARK") {
                  obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + '®' }
                }
                if (data[i].symbol_ind == "COPYRIGHT") {
                  obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + '©' }
                }
                js.push(obj);
              }
            }
          }
        })
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddPeanutVariety: {
          ...state["ddPeanutVariety"],
          valueList: js
        }
      }
    });
  }
// End Method for binding peanut veriety based on buying point

// Start Method for binding Peanut Type dropdown
  const bFillTypeList = () => {
    let js = [];
    js.push({ key: '', description: '>>> All Types <<<' }) //Set All as default value
    ContractManagementService.RetrievePeanutTypeControls(null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeName }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutType: {
            ...state["ddPeanutType"],
            valueList: js,
          }
        }
      })
    })
  }
// End Method for binding Peanut Type dropdown

// Start Method for binding Buying Points
  const bFillBuyingPointList = () => {
    let js = []
    js.push({ key: '', description: '>>> All Buying Points <<<' });
    ContractManagementService.RetrieveBuyingPointControlDetails('PN1150', null, null, null, null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddLocation: {
            ...state["ddLocation"],
            valueList: js
          }
        }
      })
    })
  }
// End Method for binding Buying Points

  // START_USER_CODE-USER_METHODS
  const onbtnExitClick = () => {
    try {      
      // Modal.close();
      document.getElementsByClassName("close")[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnExit event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnExitClick = onbtnExitClick;

  //Vendor PopUp should come

  const onbtnVendorClick = () => {
    try {
      if(thisObj.isValid!==true){
        goTo(thisObj,"ContractManagement#VendorLookup#DEFAULT#true#Click")
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnVendor event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnVendorClick = onbtnVendorClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({        
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} headerDetails={state.headerDetails} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ExceptPremRequestSearch*/}

              {/* END_USER_CODE-USER_BEFORE_ExceptPremRequestSearch*/}             

              <GroupBoxWidget conf={state.grpbxSearch} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_ddLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddLocation*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddLocation*/}
                
                {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
               
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                <ButtonWidget
                  conf={state.btnVendor}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVndrName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
               
                {/* START_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutVariety}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutVariety*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutVariety*/}   

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPremiumCode}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPremiumCode*/}

                {/* END_USER_CODE-USER_AFTER_ddPremiumCode*/}
                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSeed}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSeed*/}

                {/* END_USER_CODE-USER_AFTER_ddSeed*/}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarm}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFarm*/}

                {/* END_USER_CODE-USER_AFTER_txtFarm*/}               

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarmSuffix}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFarmSuffix*/}

                {/* END_USER_CODE-USER_AFTER_txtFarmSuffix*/}               

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddGeneration}
                  screenConf={state}
                ></DropDownWidget>
                
                {/* START_USER_CODE-USER_BEFORE_txtContract*/}

                {/* END_USER_CODE-USER_BEFORE_txtContract*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtContract}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtContract*/}

                {/* END_USER_CODE-USER_AFTER_txtContract*/}               

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSegType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSegType*/}

                {/* END_USER_CODE-USER_AFTER_ddSegType*/}                
                <LabelWidget
                  values={values}
                  conf={state.lblAvailble}
                  screenConf={state}
                ></LabelWidget>
                <CheckboxWidget
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  rows={values.gridExcPremReqSrch}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxAll1007s}
                  screenConf={state}
                ></CheckboxWidget>
                <ButtonWidget
                  conf={state.btnSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                {/* END_USER_CODE-USER_AFTER_btnSearch*/}                
                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddOleic}
                  screenConf={state}
                ></DropDownWidget> 
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSearch*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSearch*/}              
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbx1007s} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_chkboxAll1007s*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxAll1007s*/}
              
                <GridWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  conf={state.gridExcPremReqSrch}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onRowChange={ selectedRows=> onGridRowChange(selectedRows)}
                  errors={errors}
                  touched={touched}
                  rows={values.gridExcPremReqSrch}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  clearSelRows={state.gridExcPremReqSrch.clearRows}
                  setFieldValue={setFieldValue}
                  refObject={thisObj}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_txtarAvailable1007s*/}

                {/* END_USER_CODE-USER_AFTER_txtarAvailable1007s*/}                

                <LabelWidget
                  values={values}
                  conf={state.lblTotal}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblTotal*/}

                {/* END_USER_CODE-USER_AFTER_lblTotal*/}
                {/* START_USER_CODE-USER_BEFORE_txtPremiumAmount*/}

                {/* END_USER_CODE-USER_BEFORE_txtPremiumAmount*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPremiumAmount}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPremiumAmount*/}

                {/* END_USER_CODE-USER_AFTER_txtPremiumAmount*/}
                {/* START_USER_CODE-USER_BEFORE_txtGrossWT*/}

                {/* END_USER_CODE-USER_BEFORE_txtGrossWT*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtGrossWT}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtGrossWT*/}

                {/* END_USER_CODE-USER_AFTER_txtGrossWT*/}
                {/* START_USER_CODE-USER_BEFORE_txtNetWT*/}

                {/* END_USER_CODE-USER_BEFORE_txtNetWT*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNetWT}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNetWT*/}

                {/* END_USER_CODE-USER_AFTER_txtNetWT*/}
                {/* START_USER_CODE-USER_BEFORE_txtLSKWT*/}

                {/* END_USER_CODE-USER_BEFORE_txtLSKWT*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLSKWT}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLSKWT*/}

                {/* END_USER_CODE-USER_AFTER_txtLSKWT*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbx1007s*/}

              {/* END_USER_CODE-USER_AFTER_grpbx1007s*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxPayReq*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPayReq*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxPayReq} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtReqBy*/}

                {/* END_USER_CODE-USER_BEFORE_txtReqBy*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtReqBy}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtReqBy*/}

                {/* END_USER_CODE-USER_AFTER_txtReqBy*/}
                {/* START_USER_CODE-USER_BEFORE_txtDateReq*/}

                {/* END_USER_CODE-USER_BEFORE_txtDateReq*/}

                {/* <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDateReq}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget> */}
                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDateReq}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_txtDateReq*/}

                {/* END_USER_CODE-USER_AFTER_txtDateReq*/}
                {/* START_USER_CODE-USER_BEFORE_txtarExplanation*/}

                {/* END_USER_CODE-USER_BEFORE_txtarExplanation*/}

                <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  // errors={errors}
                  errors={{ ...errors, "txtarExplanation": values && values['txtarExplanation'] ? '' : "Some Explanation is Required!!!" }}
                  conf={state.txtarExplanation}
                  screenConf={state}
                ></TextAreaWidget>
                {/* START_USER_CODE-USER_AFTER_txtarExplanation*/}

                {/* END_USER_CODE-USER_AFTER_txtarExplanation*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrintPayReq*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrintPayReq*/}

                <ButtonWidget
                  conf={state.btnPrintPayReq}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrintPayReq*/}

                {/* END_USER_CODE-USER_AFTER_btnPrintPayReq*/}
                {/* START_USER_CODE-USER_BEFORE_btnRejectPrem*/}

                {/* END_USER_CODE-USER_BEFORE_btnRejectPrem*/}

                <ButtonWidget
                  conf={state.btnRejectPrem}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnRejectPrem*/}

                {/* END_USER_CODE-USER_AFTER_btnRejectPrem*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPayReq*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPayReq*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxReprintPayReq*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxReprintPayReq*/}

              <GroupBoxWidget
                conf={state.grpbxReprintPayReq}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtPayReq*/}

                {/* END_USER_CODE-USER_BEFORE_txtPayReq*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPayReq}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPayReq*/}

                {/* END_USER_CODE-USER_AFTER_txtPayReq*/}
                {/* START_USER_CODE-USER_BEFORE_btnReprintPayReq*/}

                {/* END_USER_CODE-USER_BEFORE_btnReprintPayReq*/}

                <ButtonWidget
                  conf={state.btnReprintPayReq}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnReprintPayReq*/}

                {/* END_USER_CODE-USER_AFTER_btnReprintPayReq*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxReprintPayReq*/}

              {/* END_USER_CODE-USER_AFTER_grpbxReprintPayReq*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxExit*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxExit*/}

              <GroupBoxWidget conf={state.grpbxExit} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={state.btnExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxExit*/}

              {/* END_USER_CODE-USER_AFTER_grpbxExit*/}

              {/* START_USER_CODE-USER_AFTER_ExceptPremRequestSearch*/}

              {/* END_USER_CODE-USER_AFTER_ExceptPremRequestSearch*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_ExceptPremRequestSearch);
