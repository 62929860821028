/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import jsPDF from 'jspdf';

import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  setValue,
  getData,
  getValue,
  setData,
  disable,
  enable,
  goTo,
  hide,
  show

} from "../../shared/WindowImports";

import "./TradeSettleSearch.scss";

// START_USER_CODE-USER_IMPORTS
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SettlementService } from "../Service/SettlementService";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function Settlements_TradeSettleSearch(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "TradeSettleSearch",
    windowName: "TradeSettleSearch",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.TradeSettleSearch",
    // START_USER_CODE-USER_TradeSettleSearch_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Trade Settlement Search",
      scrCode: "PN1140A",

    },

    // END_USER_CODE-USER_TradeSettleSearch_PROPERTIES
    // btn1: {
    //   name: "btn1",
    //   type: "ButtonWidget",
    //   parent: "grpbxAction",
    //   Label: ".",
    //   CharWidth: "6",

    //   ofTypeDomain: "d_boolean",
    //   // START_USER_CODE-USER_btn1_PROPERTIES

    //   // END_USER_CODE-USER_btn1_PROPERTIES
    // },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxAction",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnCreateTradeSettlement: {
      name: "btnCreateTradeSettlement",
      type: "ButtonWidget",
      parent: "grpbxTradeSettlementCrateOpen",
      Label: "Create Trade Settlement",
      CharWidth: "46",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCreateTradeSettlement_PROPERTIES

      // END_USER_CODE-USER_btnCreateTradeSettlement_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "grpbxAction",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnOpen: {
      name: "btnOpen",
      type: "ButtonWidget",
      parent: "grpbxTradeSettlementCrateOpen",
      Label: "Open",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOpen_PROPERTIES

      // END_USER_CODE-USER_btnOpen_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "grpbxAction",
      Label: "Print",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxSearch",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxSearch",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    ddAgreementType: {
      name: "ddAgreementType",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Agreement Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAgreementType_PROPERTIES

      // END_USER_CODE-USER_ddAgreementType_PROPERTIES
    },
    ddEdibleOil: {
      name: "ddEdibleOil",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "EdibleOil:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddEdibleOil_PROPERTIES

      // END_USER_CODE-USER_ddEdibleOil_PROPERTIES
    },
    ddGeneration: {
      name: "ddGeneration",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Generation",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddGeneration_PROPERTIES

      // END_USER_CODE-USER_ddGeneration_PROPERTIES
    },
    ddGradePricingMehtod: {
      name: "ddGradePricingMehtod",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Grade Pricing Mehtod:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddGradePricingMehtod_PROPERTIES

      // END_USER_CODE-USER_ddGradePricingMehtod_PROPERTIES
    },
    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Location:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation_PROPERTIES

      // END_USER_CODE-USER_ddLocation_PROPERTIES
    },
    ddOleic: {
      name: "ddOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Oleic",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Peanut Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddPeanutVariety: {
      name: "ddPeanutVariety",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Peanut Variety:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_ddPeanutVariety_PROPERTIES
    },
    ddSegType: {
      name: "ddSegType",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Seg Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSegType_PROPERTIES

      // END_USER_CODE-USER_ddSegType_PROPERTIES
    },
    ddStatus: {
      name: "ddStatus",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Status",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddStatus_PROPERTIES

      // END_USER_CODE-USER_ddStatus_PROPERTIES
    },
    gridTrdSettlSrch: {
      name: "gridTrdSettlSrch",
      type: "GridWidget",
      parent: "grpbxTradeSettlementCrateOpen",
      gridCellsOrder:
        "txtStsColm,txtLoc,txtCol,txtSettle,txtStatusC,txtAgree,txtAgreeType,txtVndr,txtPnutType,txtPnutVariety,txtGen,txtSeg,txtNetWeightC,txtTKClbs,txt100ValueC,txtSegValueC",
      Pagination: false,
      isEditable: false,
      ColsForTabLandscape: "13",
      HasLabel: false,
      Cols: "13",
      ColsForTabPotrait: "13",
      ColsForLargeDesktop: "13",
      Height: "",
      Width: "",
      ProcessingText: "gridTradeSettlements",
      needGridChange: true,
      isExpandable: true,
      defaultExpand: false,
      isMultiSelect: false,
      expandedCompTemplate: ({ data }) => (
        <div>
          {data.expArray ? (
            <div className="py-3 expandable-area col-sm-12 rdt_TableRow">
              {data.expArray.map((elem, i) =>
                <div className="py-3 expandable-area col-sm-12 rdt_TableRow selectable" key={"Row" + i}>
                  <div className={"row" + " rdt_ExpanderRow ExpandRow"} key={"ExpanderRow" + i} >
                    <div className="extraRowCell_1 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_2 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_3 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_4 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_5 ch-3" key="expandCell1">
                      <label> {elem.AgreeDetails}</label>
                    </div>
                    <div className="extraRowCell_6 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_7 ch-3" key="expandCell1">
                      <label> {elem.VendorDetails}</label>
                    </div>
                    <div className="extraRowCell_8 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_9 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_10 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_11 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_12 ch-3" key="expandCell2">
                    </div>
                    <div className="extraRowCell_13 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_14 ch-3" key="expandCell1">
                    </div>

                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      )
      // START_USER_CODE-USER_gridTrdSettlSrch_PROPERTIES

      // END_USER_CODE-USER_gridTrdSettlSrch_PROPERTIES
    },
    lbl100Val: {
      name: "lbl100Val",
      type: "LabelWidget",
      parent: "grpbxAction",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl100Val_PROPERTIES

      // END_USER_CODE-USER_lbl100Val_PROPERTIES
    },
    lbl100Value: {
      name: "lbl100Value",
      type: "LabelWidget",
      parent: "grpbxAction",
      Label: "100% Value",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl100Value_PROPERTIES

      // END_USER_CODE-USER_lbl100Value_PROPERTIES
    },
    lblCropYear2: {
      name: "lblCropYear2",
      type: "LabelWidget",
      parent: "grpbxTradeSettlementCrateOpen",
      Label: "Crop Year:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCropYear2_PROPERTIES

      // END_USER_CODE-USER_lblCropYear2_PROPERTIES
    },
    lblNetWt: {
      name: "lblNetWt",
      type: "LabelWidget",
      parent: "grpbxAction",
      Label: "Net Weight:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNetWt_PROPERTIES
      // END_USER_CODE-USER_lblNetWt_PROPERTIES
    },
    lblNetWtVal: {
      name: "lblNetWtVal",
      type: "LabelWidget",
      parent: "grpbxAction",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNetWtVal_PROPERTIES
      formattingReqd: true,

      // END_USER_CODE-USER_lblNetWtVal_PROPERTIES
    },
    lblSegVal: {
      name: "lblSegVal",
      type: "LabelWidget",
      parent: "grpbxAction",
      Label: "Seg Value",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSegVal_PROPERTIES

      // END_USER_CODE-USER_lblSegVal_PROPERTIES
    },
    lblSegValueVal: {
      name: "lblSegValueVal",
      type: "LabelWidget",
      parent: "grpbxAction",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSegValueVal_PROPERTIES

      // END_USER_CODE-USER_lblSegValueVal_PROPERTIES
    },
    lblTkcLbs: {
      name: "lblTkcLbs",
      type: "LabelWidget",
      parent: "grpbxAction",
      Label: "TKC Lbs",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblTkcLbs_PROPERTIES

      // END_USER_CODE-USER_lblTkcLbs_PROPERTIES
    },
    lblTkcLbsVal: {
      name: "lblTkcLbsVal",
      type: "LabelWidget",
      parent: "grpbxAction",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblTkcLbsVal_PROPERTIES

      // END_USER_CODE-USER_lblTkcLbsVal_PROPERTIES
    },
    txt100ValueC: {
      name: "txt100ValueC",
      type: "TextBoxWidget",
      colName: "txtCol100Value",
      parent: "gridTrdSettlSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt100ValueC_PROPERTIES

      // END_USER_CODE-USER_txt100ValueC_PROPERTIES
    },
    txtAgree: {
      name: "txtAgree",
      type: "TextBoxWidget",
      colName: "txtColAgree",
      parent: "gridTrdSettlSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAgree_PROPERTIES

      // END_USER_CODE-USER_txtAgree_PROPERTIES
    },
    txtAgreement: {
      name: "txtAgreement",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: "Agreement #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAgreement_PROPERTIES

      // END_USER_CODE-USER_txtAgreement_PROPERTIES
    },
    txtAgreeType: {
      name: "txtAgreeType",
      type: "TextBoxWidget",
      colName: "txtColAgreeType",
      parent: "gridTrdSettlSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAgreeType_PROPERTIES

      // END_USER_CODE-USER_txtAgreeType_PROPERTIES
    },
    txtCol: {
      name: "txtCol",
      type: "TextBoxWidget",
      colName: "txtColInsp",
      parent: "gridTrdSettlSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCol_PROPERTIES

      // END_USER_CODE-USER_txtCol_PROPERTIES
    },
    txtCol100Value: {
      name: "txtCol100Value",
      type: "GridColumnWidget",
      parent: "gridTrdSettlSrch",
      Label: "100% Value",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtCol100Value_PROPERTIES

      // END_USER_CODE-USER_txtCol100Value_PROPERTIES
    },
    txtColAgree: {
      name: "txtColAgree",
      type: "GridColumnWidget",
      parent: "gridTrdSettlSrch",
      Label: "Agree #",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColAgree_PROPERTIES

      // END_USER_CODE-USER_txtColAgree_PROPERTIES
    },
    txtColAgreeType: {
      name: "txtColAgreeType",
      type: "GridColumnWidget",
      parent: "gridTrdSettlSrch",
      Label: "Agree Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColAgreeType_PROPERTIES

      // END_USER_CODE-USER_txtColAgreeType_PROPERTIES
    },
    txtColGen: {
      name: "txtColGen",
      type: "GridColumnWidget",
      parent: "gridTrdSettlSrch",
      Label: "Gen",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColGen_PROPERTIES

      // END_USER_CODE-USER_txtColGen_PROPERTIES
    },
    txtColInsp: {
      name: "txtColInsp",
      type: "GridColumnWidget",
      parent: "gridTrdSettlSrch",
      Label: "Insp #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColInsp_PROPERTIES

      // END_USER_CODE-USER_txtColInsp_PROPERTIES
    },
    txtColLoc: {
      name: "txtColLoc",
      type: "GridColumnWidget",
      parent: "gridTrdSettlSrch",
      Label: "Loc",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColLoc_PROPERTIES

      // END_USER_CODE-USER_txtColLoc_PROPERTIES
    },
    txtColNetWeight: {
      name: "txtColNetWeight",
      type: "GridColumnWidget",
      parent: "gridTrdSettlSrch",
      Label: "Net Weight",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColNetWeight_PROPERTIES
      formattingReqd: true
      // END_USER_CODE-USER_txtColNetWeight_PROPERTIES
    },
    txtColPnutType: {
      name: "txtColPnutType",
      type: "GridColumnWidget",
      parent: "gridTrdSettlSrch",
      Label: "Pnut Type",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPnutType_PROPERTIES

      // END_USER_CODE-USER_txtColPnutType_PROPERTIES
    },
    txtColPnutVariety: {
      name: "txtColPnutVariety",
      type: "GridColumnWidget",
      parent: "gridTrdSettlSrch",
      Label: "Pnut Variety",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPnutVariety_PROPERTIES

      // END_USER_CODE-USER_txtColPnutVariety_PROPERTIES
    },
    txtColSeg: {
      name: "txtColSeg",
      type: "GridColumnWidget",
      parent: "gridTrdSettlSrch",
      Label: "Seg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColSeg_PROPERTIES

      // END_USER_CODE-USER_txtColSeg_PROPERTIES
    },
    txtColSegValue: {
      name: "txtColSegValue",
      type: "GridColumnWidget",
      parent: "gridTrdSettlSrch",
      Label: "Seg Value",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColSegValue_PROPERTIES

      // END_USER_CODE-USER_txtColSegValue_PROPERTIES
    },
    txtColSettle: {
      name: "txtColSettle",
      type: "GridColumnWidget",
      parent: "gridTrdSettlSrch",
      Label: "Settle #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColSettle_PROPERTIES

      // END_USER_CODE-USER_txtColSettle_PROPERTIES
    },
    txtColStatus: {
      name: "txtColStatus",
      type: "GridColumnWidget",
      parent: "gridTrdSettlSrch",
      Label: "Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColStatus_PROPERTIES

      // END_USER_CODE-USER_txtColStatus_PROPERTIES
    },
    txtColTKClbs: {
      name: "txtColTKClbs",
      type: "GridColumnWidget",
      parent: "gridTrdSettlSrch",
      Label: "TKC lbs",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColTKClbs_PROPERTIES

      // END_USER_CODE-USER_txtColTKClbs_PROPERTIES
    },
    txtColVendor: {
      name: "txtColVendor",
      type: "GridColumnWidget",
      parent: "gridTrdSettlSrch",
      Label: "Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColVendor_PROPERTIES

      // END_USER_CODE-USER_txtColVendor_PROPERTIES
    },
    txtGen: {
      name: "txtGen",
      type: "TextBoxWidget",
      colName: "txtColGen",
      parent: "gridTrdSettlSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGen_PROPERTIES

      // END_USER_CODE-USER_txtGen_PROPERTIES
    },
    txtLoc: {
      name: "txtLoc",
      type: "TextBoxWidget",
      colName: "txtColLoc",
      parent: "gridTrdSettlSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoc_PROPERTIES

      // END_USER_CODE-USER_txtLoc_PROPERTIES
    },
    txtNetWeightC: {
      name: "txtNetWeightC",
      type: "TextBoxWidget",
      colName: "txtColNetWeight",
      parent: "gridTrdSettlSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetWeightC_PROPERTIES
      formattingReqd: true,

      // END_USER_CODE-USER_txtNetWeightC_PROPERTIES
    },
    txtPnutType: {
      name: "txtPnutType",
      type: "TextBoxWidget",
      colName: "txtColPnutType",
      parent: "gridTrdSettlSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPnutType_PROPERTIES

      // END_USER_CODE-USER_txtPnutType_PROPERTIES
    },
    txtPnutVariety: {
      name: "txtPnutVariety",
      type: "TextBoxWidget",
      colName: "txtColPnutVariety",
      parent: "gridTrdSettlSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPnutVariety_PROPERTIES

      // END_USER_CODE-USER_txtPnutVariety_PROPERTIES
    },
    txtSeg: {
      name: "txtSeg",
      type: "TextBoxWidget",
      colName: "txtColSeg",
      parent: "gridTrdSettlSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeg_PROPERTIES

      // END_USER_CODE-USER_txtSeg_PROPERTIES
    },
    txtSegValueC: {
      name: "txtSegValueC",
      type: "TextBoxWidget",
      colName: "txtColSegValue",
      parent: "gridTrdSettlSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSegValueC_PROPERTIES

      // END_USER_CODE-USER_txtSegValueC_PROPERTIES
    },
    txtSettle: {
      name: "txtSettle",
      type: "TextBoxWidget",
      colName: "txtColSettle",
      parent: "gridTrdSettlSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSettle_PROPERTIES

      // END_USER_CODE-USER_txtSettle_PROPERTIES
    },
    txtStatusC: {
      name: "txtStatusC",
      type: "TextBoxWidget",
      colName: "txtColStatus",
      parent: "gridTrdSettlSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStatusC_PROPERTIES

      // END_USER_CODE-USER_txtStatusC_PROPERTIES
    },
    txtStsCol: {
      name: "txtStsCol",
      type: "GridColumnWidget",
      parent: "gridTrdSettlSrch",
      isStatusColumn: true,
      HasLabel: false,
      Visible: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtStsCol_PROPERTIES

      // END_USER_CODE-USER_txtStsCol_PROPERTIES
    },
    txtStsColm: {
      name: "txtStsColm",
      type: "TextBoxWidget",
      colName: "txtStsCol",
      parent: "gridTrdSettlSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStsColm_PROPERTIES

      // END_USER_CODE-USER_txtStsColm_PROPERTIES
    },
    txtTKClbs: {
      name: "txtTKClbs",
      type: "TextBoxWidget",
      colName: "txtColTKClbs",
      parent: "gridTrdSettlSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTKClbs_PROPERTIES  
      // END_USER_CODE-USER_txtTKClbs_PROPERTIES 
    },

    txtTradeInspect: {
      name: "txtTradeInspect",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: "Trade Inspect #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradeInspect_PROPERTIES

      // END_USER_CODE-USER_txtTradeInspect_PROPERTIES
    },
    txtNetWt: {
      name: "txtNetWt",
      type: "TextBoxWidget",
      parent: "grpbxAction",
      Label: "Net Weight:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetWt_PROPERTIES
      formattingReqd: true,
      // END_USER_CODE-USER_txtNetWt_PROPERTIES
    },
    txtSegValue: {
      name: "txtSegValue",
      type: "TextBoxWidget",
      parent: "grpbxAction",
      Label: "Seg Value:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSegValue_PROPERTIES

      // END_USER_CODE-USER_txtSegValue_PROPERTIES
    },
    txtTkcLbsVal: {
      name: "txtTkcLbsVal",
      type: "TextBoxWidget",
      parent: "grpbxAction",
      Label: "Tkc Lbst:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTkcLbsVal_PROPERTIES
      formattingReqd: true,
      // END_USER_CODE-USER_txtTkcLbsVal_PROPERTIES
    },
    txt100Value: {
      name: "txt100Value",
      type: "TextBoxWidget",
      parent: "grpbxAction",
      Label: "100% Value:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt100Value_PROPERTIES

      // END_USER_CODE-USER_txt100Value_PROPERTIES
    },
    txtTradeSettle: {
      name: "txtTradeSettle",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: "Trade Settle #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradeSettle_PROPERTIES

      // END_USER_CODE-USER_txtTradeSettle_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtVndor: {
      name: "txtVndor",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVndor_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtVndor_PROPERTIES
    },
    txtVndr: {
      name: "txtVndr",
      type: "TextBoxWidget",
      colName: "txtColVendor",
      parent: "gridTrdSettlSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVndr_PROPERTIES

      // END_USER_CODE-USER_txtVndr_PROPERTIES
    },
    grpbxSearch: {
      name: "grpbxSearch",
      type: "GroupBoxWidget",
      parent: "TradeSettleSearch",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxSearch_PROPERTIES

      // END_USER_CODE-USER_grpbxSearch_PROPERTIES
    },
    grpbxTradeSettlementCrateOpen: {
      name: "grpbxTradeSettlementCrateOpen",
      type: "GroupBoxWidget",
      parent: "TradeSettleSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxTradeSettlementCrateOpen_PROPERTIES

      // END_USER_CODE-USER_grpbxTradeSettlementCrateOpen_PROPERTIES
    },
    grpbxAction: {
      name: "grpbxAction",
      type: "GroupBoxWidget",
      parent: "TradeSettleSearch",
      Height: "",
      Width: "",
      ColsForTabLandscape: "12",
      HasLabel: false,
      Cols: "12",
      ColsForTabPotrait: "12",
      ColsForLargeDesktop: "12",
      // START_USER_CODE-USER_grpbxAction_PROPERTIES

      // END_USER_CODE-USER_grpbxAction_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "Settlements#TradeSettleProfile": {},
        "ContractManagement#MainMenu": {},
        "ContractManagement#VendorDisclosure": {},
      },
      REVERSE: {
        "Settlements#TradeSettleProfile": {},
        "ContractManagement#MainMenu": {},
        "ContractManagement#VendorDisclosure": {},
      },
    },
  };
  let _buttonServices = { TradeSettleProfile: {} };
  let _buttonNavigation = {
    btnCreateTradeSettlement: {
      DEFAULT: ["Settlements#TradeSettleProfile#DEFAULT#true#Click"],
    },
    btnClose: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    },
    btnVendor: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },

    btnOpen: {
      DEFAULT: ["Settlements#TradeSettleProfile#DEFAULT#true#Click"],
    },
  };

  const [loading, setLoading] = useState(false);

  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };

  useEffect(() => {
    parentWindow(thisObj);

  });

  useEffect(() => {
    let Status = getData(thisObj, 'CompletionStatus')
    if (Status == true) {
      onbtnSearchClick()
    }
    setData(thisObj, 'CompletionStatus', false);
    let Status1 = getData(thisObj, 'TradeSetteleProfileClose')
    if (Status1 == true) {
      onbtnSearchClick()
    }
    setData(thisObj, "TradeSetteleProfileClose", false)
  }, [getData(thisObj, 'CompletionStatus'), getData(thisObj, 'TradeSetteleProfileClose')]);
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    setValue(thisObj, "ddLocation", '');
    setValue(thisObj, "ddAgreementType", '');
    setValue(thisObj, "ddPeanutType", '');
    setValue(thisObj, "ddSegType", '');
    setValue(thisObj, "ddPeanutVariety", '');
    setValue(thisObj, "ddGeneration", '');
    setValue(thisObj, "ddOleic", '');
    setValue(thisObj, "ddEdibleOil", '');
    setValue(thisObj, "ddStatus", '');
    setValue(thisObj, "ddGradePricingMehtod", '');
    setValue(thisObj, 'lblCropYear2', cropYearFromLS());
    setData(thisObj, "txtNetWt", 0);
    setData(thisObj, "txtTkcLbsVal", 0);
    setData(thisObj, "txt100Value", 0);
    setData(thisObj, "txtSegValue", 0);
    setData(thisObj, 'vendorDetails', null);
    Formload();
  }, []);

  useEffect(() => {
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    let closingOfTradeSettleProfile = getData(thisObj, "TradesettlevendorClose")
    if ( closingOfTradeSettleProfile === null || closingOfTradeSettleProfile !=true ) {
    bFillRemitToList()
    }
    setData(thisObj,"TradesettlevendorClose",false)
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, [getData(thisObj, 'vendorDetails')]);


  let accessPermission = ''
  let GetAccessLevel = false;
  async function Formload() {
    bFillBuyingPointList(); // Method for binding Buying Points
    bFillAgreementType();//Method for binding Agreement Type
    bFillTypeList(); // Method for binding Peanut Type dropdown
    bFillSegmentList(); // Method for binding Segments
    bFillVarietyList(null, null);//Method for binding Peanut
    bFillGeneration();//Method for binding generation
    bFillOleicList(); // Method for binding Oleic values
    bFillEdibleOil();//Method for binding Edible Oil
    bFillStatus();//Method for binding Status
    bFillGradePricingMehtod();//Method for binding GradePricingMehtod
    if (await GetSecurityRoles('', 'D')==true) {
      accessPermission = 'D'
    } else if (await GetSecurityRoles('', 'U')==true) {
      accessPermission = 'U'
    } else if (await GetSecurityRoles('', 'I')==true) {
      accessPermission = 'I'
    }
    else {
      showMessage(thisObj, 'Invalid permission')
    }
    if(accessPermission === 'I'){
      disable(thisObj, 'btnCreateTradeSettlement')
      }
    setData(thisObj, 'accessPermission', accessPermission)
    setValue(thisObj, "txtVendor", '')
    setValue(thisObj, "txtVndor", '')
    hide(thisObj, "txtVndor",false)
    disable(thisObj, 'btnOpen')
    disable(thisObj, 'txtNetWt')
    disable(thisObj, 'txtTkcLbsVal')
    disable(thisObj, 'txt100Value')
    disable(thisObj, 'txtSegValue')
    setData(thisObj, "closingOfTradeSettleProfile", false)
  }

  const GetSecurityRoles = async (sub_func_id, accessLevel) => {
    GetAccessLevel = false
    let response = await ContractManagementService.RetrieveAccessPermissionDetails('PN1140', null, accessLevel, null)
    if (response != null && response != undefined) {
      if (response[0].permission == 'Y') {
        return true;
      }
    }
    return false;
  }
  const onBuyingPointChange = () => {
    try {
      let PeanutTypelist = thisObj.state['ddPeanutType'].valueList;
      let SelectedPeanutTypeValue = getValue(thisObj, "ddPeanutType", '')

      let pnut_type_id = null
      if (SelectedPeanutTypeValue !== "" && SelectedPeanutTypeValue !== undefined) {
        pnut_type_id = PeanutTypelist.find(elem => elem.key === SelectedPeanutTypeValue).key;
      }

      let BuyingPointList = thisObj.state['ddLocation'].valueList;
      let SelectedBuyingPointValue = getValue(thisObj, "ddLocation", '')

      let buyingPointId = null
      if (SelectedBuyingPointValue !== "" && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }
      //Bind Peanut Variety based on Buying Point and Peanut Type
      bFillVarietyList(buyingPointId, pnut_type_id)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Buying Point dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddLocationChange = onBuyingPointChange;

  const onPeanutTypeChange = () => {
    try {
      let PeanutTypelist = thisObj.state['ddPeanutType'].valueList;
      let SelectedPeanutTypeValue = getValue(thisObj, "ddPeanutType", '')
      let pnut_type_id = PeanutTypelist.find(elem => elem.key === SelectedPeanutTypeValue).key;
      let BuyingPointList = thisObj.state['ddLocation'].valueList;
      let SelectedBuyingPointValue = getValue(thisObj, "ddLocation", '')
      let buyingPointId = null
      if (SelectedBuyingPointValue !== null && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }
      //Bind Peanut Variety based on Buying Point and Peanut Type
      setValue(thisObj, 'ddPeanutVariety', '')
      bFillVarietyList(buyingPointId, pnut_type_id)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Peanut Type dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddPeanutTypeChange = onPeanutTypeChange;

  const bFillBuyingPointList = () => {
    let js = []
    js.push({ key: '', description: '>>>All Buying Points<<<' });
    ContractManagementService.RetrieveBuyingPointControlDetails('PN1140', null, null, null, null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddLocation: {
            ...state["ddLocation"],
            valueList: js
          }
        }
      })
    })
  }

  //Method for binding Peanut Variety based on Buying Point and Peanut Type
  const bFillVarietyList = (buyingPointId, pnut_type_id) => {
    let js = [];
    let obj = ({ key: '', description: '>>> All Varieties <<<' });
    js.push(obj)
    if (pnut_type_id !== null && pnut_type_id !== '') {
      ContractManagementService.RetrievePeanutVarietyControls(buyingPointId, null, null, pnut_type_id, null)
        .then(response => {
          let data = response;
          if (data !== null && data !== undefined && data.length > 0) {
            let js1 = [];
            for (var i = 0; i < data.length; i++) {
              if (!(js1.includes(data[i].pnut_variety_id))) {
                obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name }
                if (data[i].symbol_ind == "TRADEMARK") {
                  obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + '\u2122' }
                }
                if (data[i].symbol_ind == "REGISTERED TRADEMARK") {
                  obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + '\xAE' }
                }
                if (data[i].symbol_ind == "COPYRIGHT") {
                  obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + '\xA9' }
                }
                js.push(obj);
                js1.push(data[i].pnut_variety_id)
              }
            }
          }
        })
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddPeanutVariety: {
          ...state["ddPeanutVariety"],
          valueList: js
        }
      }
    })
  }

  //Method for binding Edible Oil
  const bFillEdibleOil = () => {
    let js = []
    js.push({ key: '', description: '>>> All <<<' })
    js.push({ key: 'E', description: 'Edible' })
    js.push({ key: 'O', description: 'Oil' })
    thisObj.setState(current => {
      return {
        ...current,
        ddEdibleOil: {
          ...state["ddEdibleOil"],
          valueList: js
        }
      }
    })
  }
  const bFillGradePricingMehtod = () => {
    let js = []
    js.push({ key: '', description: '>>> All <<<' })
    js.push({ key: 'FULL', description: 'FULL' })
    js.push({ key: 'TKC', description: 'TKC' })
    thisObj.setState(current => {
      return {
        ...current,
        ddGradePricingMehtod: {
          ...state["ddGradePricingMehtod"],
          valueList: js
        }
      }
    })
  }

  // Method for Oleic values 
  const bFillOleicList = () => {
    let js = []
    js.push({ key: '', description: '>>> All Oleic <<<' })
    js.push({ key: 'H', description: 'High' })
    js.push({ key: 'M', description: 'Mid' })
    js.push({ key: 'N', description: 'None' })
    thisObj.setState(current => {
      return {
        ...current,
        ddOleic: {
          ...state["ddOleic"],
          valueList: js
        }
      }
    })
  }

  // Method for Status values 
  const bFillStatus = () => {
    let js = []
    js.push({ key: '', description: '>>> All Status <<<' })
    js.push({ key: 'P', description: 'Pending' })
    js.push({ key: 'C', description: 'Complete' })
    js.push({ key: 'V', description: 'Void' })
    thisObj.setState(current => {
      return {
        ...current,
        ddStatus: {
          ...state["ddStatus"],
          valueList: js
        }
      }
    })
  }

  // Method for binding Seed Genration
  const bFillGeneration = () => {
    let js = []
    js.push({ key: '', description: '>>> All Generations <<<' })
    js.push({ key: 'F', description: 'Foundation' })
    js.push({ key: 'C', description: 'Certified' })
    js.push({ key: 'R', description: 'Registered' })
    thisObj.setState(current => {
      return {
        ...current,
        ddGeneration: {
          ...state["ddGeneration"],
          valueList: js
        }
      }
    })
  }

  //Method for binding Agreement Type
  const bFillAgreementType = () => {
    let js = []
    js.push({ key: '', description: '>>> All Types <<<' })
    js.push({ key: 'P', description: 'Trade Purchase' })
    js.push({ key: 'S', description: 'Trade Sale' })
    thisObj.setState(current => {
      return {
        ...current,
        ddAgreementType: {
          ...state["ddAgreementType"],
          valueList: js
        }
      }
    })
  }

  //Method for binding Peanut Type dropdown
  const bFillTypeList = () => {
    let js = []
    js.push({ key: '', description: '>>> All Types <<<' }) //Set All as default value
    ContractManagementService.RetrievePeanutTypeControls(null).then(response => {
      let data = response
      if (response !== undefined && response.length > 0) {
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeName }
          js.push(obj)
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutType: {
            ...state["ddPeanutType"],
            valueList: js,
          }
        }
      })
    })
  }

  // Method for binding Segment list  
  const bFillSegmentList = () => {
    let js = []
    js.push({ key: '', description: '>>> All Segs <<<' })
    js.push({ key: '1', description: '1' })
    js.push({ key: '2', description: '2' })
    js.push({ key: '3', description: '3' })

    thisObj.setState(current => {
      return {
        ...current,
        ddSegType: {
          ...state["ddSegType"],
          valueList: js
        }
      }
    })
  }
  const ontxtVendorChange = () => {
    try {
    let vendorNumber = txtVendor.value;
    if (vendorNumber.length == 6) {
      bFillRemitToList()
      // show(thisObj, "txtVndr");
    }
    else {
      setValue(thisObj, "txtVndor", '');
      hide(thisObj, "txtVndor",false);
    }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(thisObj, "Error occurred during ontxtVendorChange change event");
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtVendorChange = ontxtVendorChange;

  //seaching vendorname based on vendorname  
  const bFillRemitToList = () => {
    let isVendorLookup = false;
    let vendor_lookup = getData(thisObj, 'vendorDetails');
    let vendorNumber, vendorName;
    if (vendor_lookup !== null) {
      vendorNumber = vendor_lookup.VendorNumber;
      vendorName = vendor_lookup.vendorName;
      setValue(thisObj, "txtVndor", vendorName);
      setValue(thisObj, "txtVendor", vendorNumber);
      isVendorLookup = true;
      setData(thisObj, 'vendorDetails', null);
      show(thisObj, 'txtVndor')
      return;
    }

    let vendorValueFromTextBox = getValue(thisObj, 'txtVendor');
    if (vendorValueFromTextBox !== undefined && isVendorLookup == false) {
      vendorNumber = vendorValueFromTextBox;
    }

    if (vendorNumber != "" && vendorNumber != undefined && isVendorLookup == false) {
      ContractManagementService.RetrieveVendorByNumber(vendorNumber).then(response => {
        let data = response;
        if (response !== undefined && response.length > 0) {
          vendorName = data[0].name;
        
        setValue(thisObj, "txtVndor", vendorName);
        setValue(thisObj, "txtVendor", vendorNumber);
        show(thisObj,"txtVndor")
        }
      })
    }
  }

  function formatString(str) {
    // Check if string contains a number
    if (!isNaN(str)) {
      // If yes, format string with commas
      return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      // If no, leave string as it is
      return str;
    }
  }
  const onbtnSearchClick = () => {
    try {

      let func_id = 'PN1140'
      let func_sub_id = null
      let buy_pt_id = getValue(thisObj, "ddLocation", '')
      let trade_insp_num = getValue(thisObj, "txtTradeInspect", '')
      let trade_settle_num = getValue(thisObj, "txtTradeSettle", '')
      let trade_status = getValue(thisObj, "ddStatus", '')
      let purch_sale_ind = getValue(thisObj, "ddAgreementType", '')
      let pnut_type_id = getValue(thisObj, "ddPeanutType", '')
      let pnut_variety_id = getValue(thisObj, "ddPeanutVariety", '')
      let seed_gen = getValue(thisObj, "ddGeneration", '')
      let seg_type = getValue(thisObj, "ddSegType", '')
      let oleic_ind = getValue(thisObj, "ddOleic", '')
      let delv_ind = 'N'
      let grade_pricing_method = getValue(thisObj, "ddGradePricingMehtod", '')
      let edible_oil_ind = getValue(thisObj, "ddEdibleOil", '')
      let agree_num = getValue(thisObj, "txtAgreement", '')
      let split_vendor = getValue(thisObj, "txtVendor", '')
      enable(thisObj, 'btnOpen')
      const numberFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
          style: 'currency',
          currency: 'USD',
          currencySign: 'accounting',
        }).format(value);

      setLoading(true);
      SettlementService.RetrieveTradeSettlementList(func_id, func_sub_id, buy_pt_id, trade_insp_num, trade_settle_num, trade_status, purch_sale_ind, pnut_type_id, pnut_variety_id, seed_gen, seg_type, oleic_ind, delv_ind, grade_pricing_method, edible_oil_ind, agree_num, split_vendor)
        .then(response => {
          if (response != undefined && response.length > 0) {
            let TransformedRowsArray = [];
            let data = response[0].trade_settle_headers;
            let obj = {};
            let totalNetWeight = 0;
            let totalTkcLbs = 0;
            let total100Value = 0;
            let totalSegValue = 0;
            for (let i = 0; i < data.length; i++) {
              obj.txtLoc = data[i].buy_pt_id
              obj.txtCol = data[i].trade_insp_num
              if(data[i].trade_settle_num=="0000000"){
                obj.txtSettle =""
               }
               else if(data[i].trade_settle_num=="0"){
                obj.txtSettle =""
               }
               else {
                obj.txtSettle = data[i].trade_settle_num
               }
              obj.txtStatusC = data[i].trade_status
              switch (data[i].trade_status) {
                case "P":
                  obj.txtStatusC = "Pending";
                  break;
                case "C":
                  obj.txtStatusC = "Complete";
                  break;
                case "V":
                  obj.txtStatusC = "Void";
                  break;
              }
              let AgreeInfo = data[i].agree_info
              if (AgreeInfo.includes("|")) {
                obj.txtAgree = "Multi ..."
                let AgreeInfoArr = AgreeInfo.split("|")
                let AgreeArr = [];
                for (let i = 0; i < AgreeInfoArr.length; i++) {
                  let object = {};
                  object.AgreeDetails = AgreeInfoArr[i];
                  AgreeArr.push(object)
                  obj.expArray = AgreeArr;
                }
              }
              else {
                obj.txtAgree = data[i].agree_info
              }
              obj.txtAgreeType = data[i].purch_sale_ind
              switch (data[i].purch_sale_ind) {
                case "P":
                  obj.txtAgreeType = "Purchase";
                  break;
                case "S":
                  obj.txtAgreeType = "Sale";
                  break;
              }
              let VendorInfo = data[i].vendor_info
              if (VendorInfo.includes("|")) {
                obj.txtVndr = "Multi ..."
                let VendorInfoArr = VendorInfo.split("|")
                let VendorArr = [];
                for (let i = 0; i < VendorInfoArr.length; i++) {
                  let object = {};
                  object.VendorDetails = VendorInfoArr[i];
                  VendorArr.push(object)
                  obj.expArray = VendorArr;
                }
              }
              else {
                obj.txtVndr = data[i].vendor_info
              }
              obj.txtPnutType = data[i].pnut_type_name
              obj.txtPnutVariety = data[i].pnut_variety_name
              switch (data[i].symbol_ind) {
                case "TRADEMARK":
                  obj.txtPnutVariety = data[i].pnut_variety_name + '\u2122';
                  break;

                case "REGISTERED TRADEMARK":
                  obj.txtPnutVariety = data[i].pnut_variety_name + '\xAE';
                  break;

                case "COPYRIGHT":
                  obj.txtPnutVariety = data[i].pnut_variety_name + '\xA9';
                  break;

                default:
                  obj.txtPnutVariety = data[i].pnut_variety_name
              }
              obj.txtGen = data[i].seed_gen
              obj.txtSeg = data[i].seg_type
              obj.txtNetWeightC = data[i].net_wt == null || data[i].net_wt == ''  ? '' : Intl.NumberFormat('en-US').format(data[i].net_wt)
              if (data[i].grade_pricing_method == "TKC") {
                obj.txtNetWeightC = "";
                if (!isNaN(data[i].net_wt) && !isNaN(data[i].lsk_wt) && !isNaN(data[i].smk_pct))
                  obj.txtTKClbs = Math.round((((parseFloat(data[i].net_wt - data[i].lsk_wt)) * (parseFloat(data[i].smk_pct))) / 100) + parseFloat(data[i].lsk_wt));
                totalTkcLbs += Math.round(Number(obj.txtTKClbs));
              }
              obj.txt100ValueC = data[i].basis_grade_amt == null || data[i].basis_grade_amt == '' ? '' : numberFormat(data[i].basis_grade_amt);
              obj.txtSegValueC = data[i].value_of_seg == null || data[i].value_of_seg == '' ? '' : numberFormat(data[i].value_of_seg)
              totalNetWeight += Number(data[i].net_wt);

              if (data[i].grade_pricing_method !== "FULL") {
                totalNetWeight -= Number(data[i].net_wt);
              }
              total100Value += Number(data[i].basis_grade_amt)
              totalSegValue += Number(data[i].value_of_seg)
              TransformedRowsArray.push(obj)
              obj = {}
            }
            setData(thisObj, "txtTkcLbsVal", totalTkcLbs);
            setData(thisObj, "txtNetWt", totalNetWeight);
            setData(thisObj, "txt100Value", total100Value);
            setData(thisObj, "txtSegValue", totalSegValue);
            setValue(thisObj, "gridTrdSettlSrch", TransformedRowsArray);
            setValue(thisObj, "txtNetWt",formatString(totalNetWeight));
            setValue(thisObj, "txtTkcLbsVal",formatString(totalTkcLbs));
            setValue(thisObj, "txt100Value", numberFormat(total100Value));
            setValue(thisObj, "txtSegValue", numberFormat(totalSegValue));
          }
          else {
            setValue(thisObj, 'gridTrdSettlSrch', [])
            setValue(thisObj, "txtNetWt", formatString('0'));
            setValue(thisObj, "txtTkcLbsVal",formatString ('0'));
            setValue(thisObj, "txt100Value", numberFormat('0'));
            setValue(thisObj, "txtSegValue", numberFormat('0'));
            disable(thisObj, 'btnOpen')
          }
          setLoading(false);
        })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:btnSearch: Click"
        )
      }
      setLoading(false);
      return false
    }
    return true
  };
  thisObj.onbtnSearchClick = onbtnSearchClick;
  //openingCreateTradeSettlement
  const onbtnCreateTradeSettlementClick = () => {
    try {
      let Obj = {}
      Obj.LoadTypeBtnCaption = "Add";
      let js = [];
      js.push(Obj);
      setData(thisObj, "CreateValue", js)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "error on Create Tradesettelement Btn")
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnCreateTradeSettlementClick = onbtnCreateTradeSettlementClick;
  //   opening settlementsearch screen
  const onbtnOpenClick = () => {
    try {
      let selectedRows = thisObj.state.gridTrdSettlSrch.selected;
      if (selectedRows[0].txtLoc != undefined && selectedRows[0].txtLoc != null && selectedRows[0].txtLoc != '')
        if (selectedRows[0].txtCol != undefined && selectedRows[0].txtCol != null && selectedRows[0].txtCol != '') {
          let Inspnum = selectedRows[0].txtCol
          let Locnum = selectedRows[0].txtLoc
          let js = [];
          js.push({
            permission: getData(thisObj, 'accessPermission'),
            Inspnum: Inspnum,
            Locnum: Locnum,
            LoadTypeBtnCaption: 'Edit'
          })
          setData(thisObj, "CreateValue", js)
          goTo(thisObj, "Settlements#TradeSettleProfile#DEFAULT#true#Click")
          return true;
        }
        else {
          showMessage(thisObj, "A insp must be selected to open");
          return false
        }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(thisObj, "No Insp Selected");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnOpenClick = onbtnOpenClick;

  //doubleclick
  const ongridTrdSettlSrchDblclick = () => {
    try {
      let selectedRows = thisObj.state.gridTrdSettlSrch.selected;
      if (selectedRows[0].txtLoc != undefined && selectedRows[0].txtLoc != null && selectedRows[0].txtLoc != '')
        if (selectedRows[0].txtCol != undefined && selectedRows[0].txtCol != null && selectedRows[0].txtCol != '') {
          let Inspnum = selectedRows[0].txtCol
          let Locnum = selectedRows[0].txtLoc
          let js = [];
          js.push({
            permission: getData(thisObj, 'accessPermission'),
            Inspnum: Inspnum,
            Locnum: Locnum,
            LoadTypeBtnCaption: 'Edit'
          })
          setData(thisObj, "CreateValue", js)
          goTo(thisObj, "Settlements#TradeSettleProfile#DEFAULT#true#Click")
          return true;
        }
        else {
          showMessage(thisObj, "A insp must be selected to open");
          return false
        }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(thisObj, "No Insp Selected");
      }
      return false;
    }
    return true;
  };
  thisObj.ongridTrdSettlSrchDblclick = ongridTrdSettlSrchDblclick;
  // Method for Export to Excel button
  const onbtnExportToExcelClick = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let datatable = getValue(thisObj, 'gridTrdSettlSrch');
    var fileName = "Trade Settelement Search";
    let excelData = [];
    if (datatable != undefined) {
      for (let i = 0; i < datatable.length; i++) {
        let rowdata = {};
        if (thisObj.state.gridTrdSettlSrch.columns[1].Visible) {
          rowdata["Loc"] = datatable[i].txtLoc;
        }
        if (thisObj.state.gridTrdSettlSrch.columns[2].Visible) {
          rowdata["Insp #"] = datatable[i].txtCol
        }
        if (thisObj.state.gridTrdSettlSrch.columns[3].Visible) {
          if(datatable[i].txtSettle ==''){
            rowdata["Settle #"] = null;
          }
          else{
            rowdata["Settle #"] = ExcelNumericDataConvert(datatable[i].txtSettle);
          }
          
        }
        if (thisObj.state.gridTrdSettlSrch.columns[4].Visible) {
          rowdata["Status"] = datatable[i].txtStatusC;
        }
        if (thisObj.state.gridTrdSettlSrch.columns[5].Visible) {
          if(datatable[i].txtAgree ==''){
            rowdata["Agree #"] = null;
          }
          else{
            rowdata["Agree #"] = ExcelNumericDataConvert(datatable[i].txtAgree);
          }          
        }
        if (thisObj.state.gridTrdSettlSrch.columns[6].Visible) {
          rowdata["Agree Type"] = datatable[i].txtAgreeType;
        }
        if (thisObj.state.gridTrdSettlSrch.columns[7].Visible) {
          rowdata["Vendor"] = datatable[i].txtVndr;
        }
        if (thisObj.state.gridTrdSettlSrch.columns[8].Visible) {
          rowdata["Pnut Type"] = datatable[i].txtPnutType;
        }
        if (thisObj.state.gridTrdSettlSrch.columns[9].Visible) {
          rowdata["Pnut Variety"] = datatable[i].txtPnutVariety;
        }
        if (thisObj.state.gridTrdSettlSrch.columns[10].Visible) {
          if(datatable[i].txtGen ==''){
            rowdata["Gen"] = null;
          }
          else{
            rowdata["Gen"] = datatable[i].txtGen;
          }
        }
        if (thisObj.state.gridTrdSettlSrch.columns[11].Visible) {
          rowdata["Seg"] = ExcelNumericDataConvert(datatable[i].txtSeg);
        }
        if (thisObj.state.gridTrdSettlSrch.columns[12].Visible) {
          if(datatable[i].txtNetWeightC ==''){
            rowdata["Net Weight"] = null;
          }
          else{
            rowdata["Net Weight"] =ExcelNumericDataConvert (datatable[i].txtNetWeightC,[',']);
          }
        }
        if (thisObj.state.gridTrdSettlSrch.columns[13].Visible) {
          if(datatable[i].txtTKClbs ==''){
            rowdata["TKC lbs"] = null;
          }
          else{
            rowdata["TKC lbs"] = ExcelNumericDataConvert (datatable[i].txtTKClbs);
          }
        }
        if (thisObj.state.gridTrdSettlSrch.columns[14].Visible) {
          if(datatable[i].txt100ValueC ==''){
            rowdata["100% Value"] = null;
          }
          else{
            rowdata["100% Value"] =ExcelNumericDataConvert (datatable[i].txt100ValueC ,[',', '$']);
          }         
        }
        if (thisObj.state.gridTrdSettlSrch.columns[15].Visible) {
          if(datatable[i].txtSegValueC ==''){
            rowdata["Seg Value"] = null;
          }
          else{
            rowdata["Seg Value"] = ExcelNumericDataConvert(datatable[i].txtSegValueC,[',', '$']);
          }        
        }
        excelData.push(rowdata);
        if (thisObj.state.gridTrdSettlSrch.columns[5].Visible || thisObj.state.gridTrdSettlSrch.columns[7].Visible) {
          if (datatable[i].expArray != undefined) {
            for (let j = 0; j < datatable[i].expArray.length; j++) {
              let extendedData = {};
              if (thisObj.state.gridTrdSettlSrch.columns[5].Visible) {
                extendedData["Agree #"] = ExcelNumericDataConvert(datatable[i].expArray[j].AgreeDetails);
              }
              if (thisObj.state.gridTrdSettlSrch.columns[7].Visible) {
                extendedData["Vendor"] = datatable[i].expArray[j].VendorDetails;
              }
              excelData.push(extendedData);
            }
          }
        }
      }
      let totals = {};
      let trigger = 0;
      for (let i = 0; i < 12; i++) {
        if (thisObj.state.gridTrdSettlSrch.columns[i].Visible) {
          totals[thisObj.state.gridTrdSettlSrch.gridHeaders[i].Label] = "Totals";
          trigger = 1;
          break;
        }
      }
      if (trigger !== 1) {
        excelData.push('')
      }

      if (thisObj.state.gridTrdSettlSrch.columns[12].Visible) {
        totals["Net Weight"] = ExcelNumericDataConvert(getValue(thisObj, 'txtNetWt'),[',']);
      }
      if (thisObj.state.gridTrdSettlSrch.columns[13].Visible) {
        let totaltkclabel = getData(thisObj, "txtTkcLbsVal")
        totals["TKC lbs"] =ExcelNumericDataConvert (totaltkclabel);
      }
      if (thisObj.state.gridTrdSettlSrch.columns[14].Visible) {
        totals["100% Value"] = ExcelNumericDataConvert(getValue(thisObj, 'txt100Value'),[',', '$']);
      }
      if (thisObj.state.gridTrdSettlSrch.columns[15].Visible) {
        totals["Seg Value"] = ExcelNumericDataConvert(getValue(thisObj, 'txtSegValue'),[',', '$']);
      }
      excelData.push(totals);

    }
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    var FileSaver = require('file-saver');
    FileSaver.saveAs(data, fileName + fileExtension);
  }
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;

  //Method for Print button
  const onbtnPrintClick = () => {
    try {
      var datatable = getValue(thisObj, "gridTrdSettlSrch")
      var res = [];
      let bodyarray = [];
      let headerarray = [];

      if (thisObj.state.gridTrdSettlSrch.columns[1].Visible) {
        headerarray.push("Loc")
      }
      if (thisObj.state.gridTrdSettlSrch.columns[2].Visible) {
        headerarray.push("Insp#")
      }
      if (thisObj.state.gridTrdSettlSrch.columns[3].Visible) {
        headerarray.push("Settle#")
      }
      if (thisObj.state.gridTrdSettlSrch.columns[4].Visible) {
        headerarray.push("Status")
      }
      if (thisObj.state.gridTrdSettlSrch.columns[5].Visible) {
        headerarray.push("Agree#")
      }
      if (thisObj.state.gridTrdSettlSrch.columns[6].Visible) {
        headerarray.push("Agree Type")
      }
      if (thisObj.state.gridTrdSettlSrch.columns[7].Visible) {
        headerarray.push("Vendor")
      }
      if (thisObj.state.gridTrdSettlSrch.columns[8].Visible) {
        headerarray.push("Pnut Type")
      }
      if (thisObj.state.gridTrdSettlSrch.columns[9].Visible) {
        headerarray.push("Pnut Variety")
      }
      if (thisObj.state.gridTrdSettlSrch.columns[10].Visible) {
        headerarray.push("Gen")
      }
      if (thisObj.state.gridTrdSettlSrch.columns[11].Visible) {
        headerarray.push("Seg")
      }
      if (thisObj.state.gridTrdSettlSrch.columns[12].Visible) {
        headerarray.push("Net Weight")
      }
      if (thisObj.state.gridTrdSettlSrch.columns[13].Visible) {
        headerarray.push("TKC lbs")
      }
      if (thisObj.state.gridTrdSettlSrch.columns[14].Visible) {
        headerarray.push("100% Value")
      }
      if (thisObj.state.gridTrdSettlSrch.columns[15].Visible) {
        headerarray.push("Seg Value")
      }

      for (var i = 0; i < datatable.length; i++) {
        let rowdata = []
        if (thisObj.state.gridTrdSettlSrch.columns[1].Visible) {
          rowdata.push(datatable[i].txtLoc)
        }
        if (thisObj.state.gridTrdSettlSrch.columns[2].Visible) {
          rowdata.push(datatable[i].txtCol)
        }
        if (thisObj.state.gridTrdSettlSrch.columns[3].Visible) {
          rowdata.push(datatable[i].txtSettle)
        }
        if (thisObj.state.gridTrdSettlSrch.columns[4].Visible) {
          rowdata.push(datatable[i].txtStatusC)
        }
        if (thisObj.state.gridTrdSettlSrch.columns[5].Visible) {
          rowdata.push(datatable[i].txtAgree)
        }
        if (thisObj.state.gridTrdSettlSrch.columns[6].Visible) {
          rowdata.push(datatable[i].txtAgreeType)
        }
        if (thisObj.state.gridTrdSettlSrch.columns[7].Visible) {
          rowdata.push(datatable[i].txtVndr)
        }
        if (thisObj.state.gridTrdSettlSrch.columns[8].Visible) {
          rowdata.push(datatable[i].txtPnutType)
        }
        if (thisObj.state.gridTrdSettlSrch.columns[9].Visible) {
          rowdata.push(datatable[i].txtPnutVariety)
        }
        if (thisObj.state.gridTrdSettlSrch.columns[10].Visible) {
          rowdata.push(datatable[i].txtGen)
        }
        if (thisObj.state.gridTrdSettlSrch.columns[11].Visible) {
          rowdata.push(datatable[i].txtSeg)
        }
        if (thisObj.state.gridTrdSettlSrch.columns[12].Visible) {
          rowdata.push(datatable[i].txtNetWeightC)
        }
        if (thisObj.state.gridTrdSettlSrch.columns[13].Visible) {
          rowdata.push(datatable[i].txtTKClbs)
        }
        if (thisObj.state.gridTrdSettlSrch.columns[14].Visible) {
          rowdata.push(datatable[i].txt100ValueC)
        }
        if (thisObj.state.gridTrdSettlSrch.columns[15].Visible) {
          rowdata.push(datatable[i].txtSegValueC)
        }
        bodyarray.push(rowdata)
        rowdata = []

        //if end user ask for child rows uncomment below code
        // if (thisObj.state.gridTrdSettlSrch.columns[5].Visible || thisObj.state.gridTrdSettlSrch.columns[7].Visible) {
        //   if (datatable[i].expArray != undefined) {
        //     for (let j = 0; j < datatable[i].expArray.length; j++) {
        //       if (thisObj.state.gridTrdSettlSrch.columns[1].Visible) {
        //         rowdata.push('')
        //       }
        //       if (thisObj.state.gridTrdSettlSrch.columns[2].Visible) {
        //         rowdata.push('')
        //       }
        //       if (thisObj.state.gridTrdSettlSrch.columns[3].Visible) {
        //         rowdata.push('')
        //       }
        //       if (thisObj.state.gridTrdSettlSrch.columns[4].Visible) {
        //         rowdata.push('')
        //       }
        //       if (thisObj.state.gridTrdSettlSrch.columns[5].Visible) {
        //         rowdata.push(datatable[i].expArray[j].AgreeDetails)
        //       }
        //       if (thisObj.state.gridTrdSettlSrch.columns[6].Visible) {
        //         rowdata.push('')
        //       }
        //       if (thisObj.state.gridTrdSettlSrch.columns[7].Visible) {
        //         rowdata.push(datatable[i].expArray[j].VendorDetails)
        //       }
        //       if (thisObj.state.gridTrdSettlSrch.columns[8].Visible) {
        //         rowdata.push('')
        //       }
        //       if (thisObj.state.gridTrdSettlSrch.columns[9].Visible) {
        //         rowdata.push('')
        //       }
        //       if (thisObj.state.gridTrdSettlSrch.columns[10].Visible) {
        //         rowdata.push('')
        //       }
        //       if (thisObj.state.gridTrdSettlSrch.columns[11].Visible) {
        //         rowdata.push('')
        //       }
        //       if (thisObj.state.gridTrdSettlSrch.columns[12].Visible) {
        //         rowdata.push('')
        //       }
        //       if (thisObj.state.gridTrdSettlSrch.columns[13].Visible) {
        //         rowdata.push('')
        //       }
        //       if (thisObj.state.gridTrdSettlSrch.columns[14].Visible) {
        //         rowdata.push('')
        //       }
        //       if (thisObj.state.gridTrdSettlSrch.columns[15].Visible) {
        //         rowdata.push('')
        //       }
        //       bodyarray.push(rowdata)
        //     }
        //   }
        // }
      }

      var obj = new jsPDF();
      obj.autoTable({
        startY: 5,
        startX: 20,
        styles: { fontSize: 6, lineWidth: 0.2, color: 10 },
        body: bodyarray,
        theme: 'plain',
        fontStyle: 'normal',
        head: [
          headerarray
        ]
      });

      var pageCount = obj.internal.getNumberOfPages();
      for (let w = 0; w <= pageCount; w++) {
        obj.setPage(w);
        let pageCurrent = obj.internal.getCurrentPageInfo().pageNumber; //Current Page
        obj.setFontSize(7);
        obj.text('page: ' + pageCurrent + '/' + pageCount + '   Trade Settlement Search - Crop Year' + sessionStorage.getItem('year') + " -    Net Weight:" + getData(thisObj, 'txtNetWt') + "   TKC Lbs:" + getData(thisObj, 'txtTkcLbsVal') + '   Value:' + getData(thisObj, 'txt100Value') + "   Seg Value:" + Number(getData(thisObj, 'txtSegValue')).toFixed(2), 10, obj.internal.pageSize.height - 10);
      }


      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj.save(fileName + '.pdf')
        }
        else {
          alert("Routine:TradeSettleSearch.cmdPrintSearch_Click")
        }
      }
      else {
        alert("Routine:TradeSettleSearch.cmdPrintSearch_Click")
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnPrintscreen event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnPrintClick = onbtnPrintClick;

  const ontxtTradeSettleChange = () => {
    let SettlementValue = getValue(thisObj, 'txtTradeSettle')
    setValue(thisObj, "txtTradeSettle", validateTextBox(SettlementValue))
  };
  thisObj.ontxtTradeSettleChange = ontxtTradeSettleChange

  const validateTextBox = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 46)) {
        res += data[i]
      }
    }
    return res;
  }
  const ontxtTradeInspectChange = () => {
    try {
      let value = getValue(thisObj, 'txtTradeInspect')
      let strRegex = new RegExp(/^[A-Za-z]\d*$/)
      const reg = new RegExp('^[0-9]+$');
      if (strRegex.test(value))
        setValue(thisObj, 'txtTradeInspect', value.toUpperCase())
      else
      if(reg.test(value))
      {
       setValue(thisObj, 'txtTradeInspect', value)
      }
      else{
        setValue(thisObj, 'txtTradeInspect', '')
      }
       }
    catch (err) {
      showMessage(thisObj, err.message)
    }
  }
 thisObj.ontxtTradeInspectChange = ontxtTradeInspectChange
  const onGridRowChange = (rowSelected) => {
    let data3 = rowSelected;
    if (data3.selectedRows) {
      data3 = data3.selectedRows[0];
      disable(thisObj, 'btnOpen')
    }
  };
  thisObj.onGridRowChange = onGridRowChange;
  // START_USER_CODE-USER_METHODS
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_TradeSettleSearch*/}

              {/* END_USER_CODE-USER_BEFORE_TradeSettleSearch*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxSearch*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSearch*/}

              <GroupBoxWidget conf={state.grpbxSearch} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_ddLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddLocation*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddLocation*/}
                {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_txtTradeInspect*/}

                {/* END_USER_CODE-USER_BEFORE_txtTradeInspect*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTradeInspect}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTradeInspect*/}

                {/* END_USER_CODE-USER_AFTER_txtTradeInspect*/}
                {/* START_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutVariety}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutVariety*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutVariety*/}
                {/* START_USER_CODE-USER_BEFORE_txtTradeSettle*/}

                {/* END_USER_CODE-USER_BEFORE_txtTradeSettle*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTradeSettle}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTradeSettle*/}

                {/* END_USER_CODE-USER_AFTER_txtTradeSettle*/}
                {/* START_USER_CODE-USER_BEFORE_ddGeneration*/}

                {/* END_USER_CODE-USER_BEFORE_ddGeneration*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddGeneration}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddGeneration*/}

                {/* END_USER_CODE-USER_AFTER_ddGeneration*/}
                {/* START_USER_CODE-USER_BEFORE_ddStatus*/}

                {/* END_USER_CODE-USER_BEFORE_ddStatus*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddStatus}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddStatus*/}

                {/* END_USER_CODE-USER_AFTER_ddStatus*/}
                {/* START_USER_CODE-USER_BEFORE_ddSegType*/}

                {/* END_USER_CODE-USER_BEFORE_ddSegType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSegType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSegType*/}

                {/* END_USER_CODE-USER_AFTER_ddSegType*/}
                {/* START_USER_CODE-USER_BEFORE_txtAgreement*/}

                {/* END_USER_CODE-USER_BEFORE_txtAgreement*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAgreement}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAgreement*/}

                {/* END_USER_CODE-USER_AFTER_txtAgreement*/}
                {/* START_USER_CODE-USER_BEFORE_ddOleic*/}

                {/* END_USER_CODE-USER_BEFORE_ddOleic*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddOleic}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddOleic*/}

                {/* END_USER_CODE-USER_AFTER_ddOleic*/}
                {/* START_USER_CODE-USER_BEFORE_ddAgreementType*/}

                {/* END_USER_CODE-USER_BEFORE_ddAgreementType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddAgreementType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddAgreementType*/}

                {/* END_USER_CODE-USER_AFTER_ddAgreementType*/}
                {/* START_USER_CODE-USER_BEFORE_ddEdibleOil*/}

                {/* END_USER_CODE-USER_BEFORE_ddEdibleOil*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddEdibleOil}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddEdibleOil*/}

                {/* END_USER_CODE-USER_AFTER_ddEdibleOil*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                <ButtonWidget
                  conf={state.btnVendor}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                {/* START_USER_CODE-USER_BEFORE_ddGradePricingMehtod*/}

                {/* END_USER_CODE-USER_BEFORE_ddGradePricingMehtod*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddGradePricingMehtod}
                  screenConf={state}
                ></DropDownWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVndor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_ddGradePricingMehtod*/}

                {/* END_USER_CODE-USER_AFTER_ddGradePricingMehtod*/}
                {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                <ButtonWidget
                  conf={state.btnSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                {/* END_USER_CODE-USER_AFTER_btnSearch*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSearch*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSearch*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxTradeSettlementCrateOpen*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxTradeSettlementCrateOpen*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget
                conf={state.grpbxTradeSettlementCrateOpen}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblCropYear2*/}

                {/* END_USER_CODE-USER_BEFORE_lblCropYear2*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCropYear2}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCropYear2*/}

                {/* END_USER_CODE-USER_AFTER_lblCropYear2*/}
                {/* START_USER_CODE-USER_BEFORE_btnCreateTradeSettlement*/}

                {/* END_USER_CODE-USER_BEFORE_btnCreateTradeSettlement*/}

                <ButtonWidget
                  conf={state.btnCreateTradeSettlement}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCreateTradeSettlement*/}

                {/* END_USER_CODE-USER_AFTER_btnCreateTradeSettlement*/}
                {/* START_USER_CODE-USER_BEFORE_btnOpen*/}

                {/* END_USER_CODE-USER_BEFORE_btnOpen*/}

                <ButtonWidget
                  conf={state.btnOpen}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOpen*/}

                {/* END_USER_CODE-USER_AFTER_btnOpen*/}
                {/* START_USER_CODE-USER_BEFORE_gridTrdSettlSrch*/}

                {/* END_USER_CODE-USER_BEFORE_gridTrdSettlSrch*/}

                <GridWidget
                  conf={state.gridTrdSettlSrch}
                  screenConf={state}
                  linkClick={event => {
                    if (state.gridTrdSettlSrch.selected.length > 0) {

                      thisObj.selectedRow = state.gridTrdSettlSrch.selected[0];
                    }
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridTrdSettlSrch}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onRowChange={selectedRow => onGridRowChange(selectedRow)}
                  setFieldValue={setFieldValue}
                  refObject={thisObj}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridTrdSettlSrch*/}

                {/* END_USER_CODE-USER_AFTER_gridTrdSettlSrch*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxTradeSettlementCrateOpen*/}

              {/* END_USER_CODE-USER_AFTER_grpbxTradeSettlementCrateOpen*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxAction*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxAction*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxAction} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                <ButtonWidget
                  conf={state.btnExportToExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
                {/* START_USER_CODE-USER_BEFORE_btn1*/}

                {/* END_USER_CODE-USER_BEFORE_btn1*/}

                {/* <ButtonWidget
                  conf={state.btn1}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget> */}
                {/* START_USER_CODE-USER_AFTER_btn1*/}

                {/* END_USER_CODE-USER_AFTER_btn1*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_lblNetWt*/}

                {/* END_USER_CODE-USER_BEFORE_lblNetWt*/}
                {/* 
                <LabelWidget
                  values={values}
                  conf={state.lblNetWt}
                  screenConf={state}
                ></LabelWidget> */}
                {/* START_USER_CODE-USER_AFTER_lblNetWt*/}

                {/* END_USER_CODE-USER_AFTER_lblNetWt*/}
                {/* START_USER_CODE-USER_BEFORE_lblNetWtVal*/}

                {/* END_USER_CODE-USER_BEFORE_lblNetWtVal*/}

                {/* <LabelWidget
                  values={values}
                  conf={state.lblNetWtVal}
                  screenConf={state}
                ></LabelWidget> */}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNetWt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_lblNetWtVal*/}

                {/* END_USER_CODE-USER_AFTER_lblNetWtVal*/}
                {/* START_USER_CODE-USER_BEFORE_lblTkcLbs*/}

                {/* END_USER_CODE-USER_BEFORE_lblTkcLbs*/}

                {/* <LabelWidget
                  values={values}
                  conf={state.lblTkcLbs}
                  screenConf={state}
                ></LabelWidget> */}
                {/* START_USER_CODE-USER_AFTER_lblTkcLbs*/}

                {/* END_USER_CODE-USER_AFTER_lblTkcLbs*/}
                {/* START_USER_CODE-USER_BEFORE_lblTkcLbsVal*/}

                {/* END_USER_CODE-USER_BEFORE_lblTkcLbsVal*/}

                {/* <LabelWidget
                  values={values}
                  conf={state.lblTkcLbsVal}
                  screenConf={state}
                ></LabelWidget> */}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTkcLbsVal}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_lblTkcLbsVal*/}

                {/* END_USER_CODE-USER_AFTER_lblTkcLbsVal*/}
                {/* START_USER_CODE-USER_BEFORE_lbl100Value*/}

                {/* END_USER_CODE-USER_BEFORE_lbl100Value*/}

                {/* <LabelWidget
                  values={values}
                  conf={state.lbl100Value}
                  screenConf={state}
                ></LabelWidget> */}
                {/* START_USER_CODE-USER_AFTER_lbl100Value*/}

                {/* END_USER_CODE-USER_AFTER_lbl100Value*/}
                {/* START_USER_CODE-USER_BEFORE_lbl100Val*/}

                {/* END_USER_CODE-USER_BEFORE_lbl100Val*/}

                {/* <LabelWidget
                  values={values}
                  conf={state.lbl100Val}
                  screenConf={state}
                ></LabelWidget> */}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt100Value}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_lbl100Val*/}

                {/* END_USER_CODE-USER_AFTER_lbl100Val*/}
                {/* START_USER_CODE-USER_BEFORE_lblSegVal*/}

                {/* END_USER_CODE-USER_BEFORE_lblSegVal*/}

                {/* <LabelWidget
                  values={values}
                  conf={state.lblSegVal}
                  screenConf={state}
                ></LabelWidget> */}
                {/* START_USER_CODE-USER_AFTER_lblSegVal*/}

                {/* END_USER_CODE-USER_AFTER_lblSegVal*/}
                {/* START_USER_CODE-USER_BEFORE_lblSegValueVal*/}

                {/* END_USER_CODE-USER_BEFORE_lblSegValueVal*/}

                {/* <LabelWidget
                  values={values}

                  conf={state.lblSegValueVal}
                  screenConf={state}
                ></LabelWidget> */}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSegValue}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_lblSegValueVal*/}

                {/* END_USER_CODE-USER_AFTER_lblSegValueVal*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxAction*/}

              {/* END_USER_CODE-USER_AFTER_grpbxAction*/}

              {/* START_USER_CODE-USER_AFTER_TradeSettleSearch*/}

              {/* END_USER_CODE-USER_AFTER_TradeSettleSearch*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_TradeSettleSearch);
