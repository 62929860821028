/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import {ExcelNumericDataConvert} from "../../Common/CommonFunctions"
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  useAppContext,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  DateWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  GridWidget,
  setValue,
  getData,
  setData,
  getValue,
  enable,
  disable,
  hide,
  show,
  hideColumn,
  goTo,
} from "../../shared/WindowImports";

import "./ContractInquiry.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../Service/ContractManagementService";
import { showColumn } from "../../shared/ParentWindow";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
import CommonContext from "../../Store/CommonContext";
//import { isNum } from "react-toastify/dist/utils";
// END_USER_CODE-USER_IMPORTS
function ContractManagement_ContractInquiry(props) {
  const commonContext1 = useContext(CommonContext);
  const contextType = useAppContext();
  const screenRef = useRef();

  // START_USER_CODE-USER_PROPERTIES
  // const js12 =[
  //   {
  //     txtcolContrct:"1234",txtcolContTyp:"3456"
  //   },
  //   {txtcolContrct:"ABCD",txtcolContTyp:"WXYZ"}
  // ]
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "Contract Inquiry",
    windowName: "ContractInquiry",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.ContractInquiry",
    // START_USER_CODE-USER_ContractInquiry_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Contract Inquiry",
      scrCode: "PN1020A",
    },
    headerDetails: {
      year: 2021,
      companyName: "Golden Peanut Compnay"
    },
    // END_USER_CODE-USER_ContractInquiry_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpAction",
      Label: "Close",
      CharWidth: "13",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnExportExcel: {
      name: "btnExportExcel",
      type: "ButtonWidget",
      parent: "grpAction",
      Label: "Export to Excel",
      CharWidth: "32",
      // START_USER_CODE-USER_btnExportExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportExcel_PROPERTIES
    },
    btnOpen: {
      name: "btnOpen",
      type: "ButtonWidget",
      parent: "grpContractInquiry",
      Label: "Open",
      CharWidth: "11",
      // START_USER_CODE-USER_btnOpen_PROPERTIES

      // END_USER_CODE-USER_btnOpen_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "grpContractInquiry",
      Label: "Print",
      CharWidth: "13",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnPrintSearch: {
      name: "btnPrintSearch",
      type: "ButtonWidget",
      parent: "grpAction",
      Label: "Print Search",
      CharWidth: "26",
      // START_USER_CODE-USER_btnPrintSearch_PROPERTIES

      // END_USER_CODE-USER_btnPrintSearch_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpContractInquiry",
      Label: "Search",
      CharWidth: "15",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    txtVendorname: {
      name: "txtVendorname",
      type: "TextBoxWidget",
      parent: "grpContractInquiry",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",

      // value:sessionStorage.getItem('VendorNumber'),	
      // START_USER_CODE-USER_txtVendorname_PROPERTIES
      ReadOnly: true,
      // END_USER_CODE-USER_txtVendorname_PROPERTIES	
    },
    btnVenLookUp: {
      name: "btnVenLookUp",
      type: "ButtonWidget",
      parent: "grpContractInquiry",
      Label: "...",
      CharWidth: "9",
      // START_USER_CODE-USER_btnVenLookUp_PROPERTIES

      // END_USER_CODE-USER_btnVenLookUp_PROPERTIES
    },
    btnViewUpload: {
      name: "btnViewUpload",
      type: "ButtonWidget",
      parent: "grpContractInquiry",
      Label: "View Uploaded Contract",
      CharWidth: "45",
      // START_USER_CODE-USER_btnViewUpload_PROPERTIES

      // END_USER_CODE-USER_btnViewUpload_PROPERTIES
    },
    chkExp: {
      name: "chkExp",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpAction",
      Options: "AsShown:1,Expanded:2,Collapsed:3",
      ColSpan: "3",
      ColSpanForLargeDesktop: "3",
      ColSpanForTabLandscape: "3",
      ReadOnly: true,
      ColSpanForTabPotrait: "3",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkExp_PROPERTIES

      // END_USER_CODE-USER_chkExp_PROPERTIES
    },
    chkExpCollGri: {
      name: "chkExpCollGri",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpContractInquiry",
      Options: "Expand Grid:1,Collapse Grid:2",
      ColSpan: "3",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkExpCollGri_PROPERTIES

      // END_USER_CODE-USER_chkExpCollGri_PROPERTIES
    },
    chkPrintComm: {
      name: "chkPrintComm",
      type: "CheckBoxWidget",
      parent: "grpContractInquiry",
      Label: "Print Comments",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkPrintComm_PROPERTIES

      // END_USER_CODE-USER_chkPrintComm_PROPERTIES
    },
    drpApplied: {
      name: "drpApplied",
      type: "DropDownFieldWidget",
      parent: "grpContractInquiry",
      Label: "%Applied:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_drpApplied_PROPERTIES

      // END_USER_CODE-USER_drpApplied_PROPERTIES
    },
    drpBuyingPoint: {
      name: "drpBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpContractInquiry",
      Label: "Buying Point:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_drpBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_drpBuyingPoint_PROPERTIES
    },
    drpContractType: {
      name: "drpContractType",
      type: "DropDownFieldWidget",
      parent: "grpContractInquiry",
      Label: "Contract Type:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_drpContractType_PROPERTIES

      // END_USER_CODE-USER_drpContractType_PROPERTIES
    },
    drpDisclosure: {
      name: "drpDisclosure",
      type: "DropDownFieldWidget",
      parent: "grpContractInquiry",
      Label: "Disclosure:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_drpDisclosure_PROPERTIES

      // END_USER_CODE-USER_drpDisclosure_PROPERTIES
    },
    drpGeneration: {
      name: "drpGeneration",
      type: "DropDownFieldWidget",
      parent: "grpContractInquiry",
      Label: "Generation:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_drpGeneration_PROPERTIES

      // END_USER_CODE-USER_drpGeneration_PROPERTIES
    },
    drpOleic: {
      name: "drpOleic",
      type: "DropDownFieldWidget",
      parent: "grpContractInquiry",
      Label: "Oleic:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_drpOleic_PROPERTIES

      // END_USER_CODE-USER_drpOleic_PROPERTIES
    },
    drpOragnic: {
      name: "drpOragnic",
      type: "DropDownFieldWidget",
      parent: "grpContractInquiry",
      Label: "Organic:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_drpOragnic_PROPERTIES

      // END_USER_CODE-USER_drpOragnic_PROPERTIES
    },
    drpSeedGrower: {
      name: "drpSeedGrower",
      type: "DropDownFieldWidget",
      parent: "grpContractInquiry",
      Label: "Seed Grower:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_drpSeedGrower_PROPERTIES

      // END_USER_CODE-USER_drpSeedGrower_PROPERTIES
    },
    drpSeg: {
      name: "drpSeg",
      type: "DropDownFieldWidget",
      parent: "grpContractInquiry",
      Label: "Seg:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_drpSeg_PROPERTIES

      // END_USER_CODE-USER_drpSeg_PROPERTIES
    },
    drpStatus: {
      name: "drpStatus",
      type: "DropDownFieldWidget",
      parent: "grpContractInquiry",
      Label: "Status:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_drpStatus_PROPERTIES

      // END_USER_CODE-USER_drpStatus_PROPERTIES
    },
    drpType: {
      name: "drpType",
      type: "DropDownFieldWidget",
      parent: "grpContractInquiry",
      Label: "Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_drpType_PROPERTIES

      // END_USER_CODE-USER_drpType_PROPERTIES
    },
    drpVariety: {
      name: "drpVariety",
      type: "DropDownFieldWidget",
      parent: "grpContractInquiry",
      Label: "Variety:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_drpVariety_PROPERTIES

      // END_USER_CODE-USER_drpVariety_PROPERTIES
    },
    dtContractDate: {
      name: "dtContractDate",
      type: "DateWidget",
      parent: "grpContractInquiry",
      Label: "Contract Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_dtContractDate_PROPERTIES

      // END_USER_CODE-USER_dtContractDate_PROPERTIES
    },
    dtThru: {
      name: "dtThru",
      type: "DateWidget",
      parent: "grpContractInquiry",
      Label: "--Thru--",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_dtThru_PROPERTIES

      // END_USER_CODE-USER_dtThru_PROPERTIES
    },
    // txtLst: {
    //   name: "txtLst",
    //   type: "TextBoxWidget",
    //   parent: "grpContractInquiry",
    //   Label: "",
    //   HasLabel:false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtPrcnt_PROPERTIES

    //   // END_USER_CODE-USER_txtPrcnt_PROPERTIES
    // },

    lblGeneral: {
      name: "lblGeneral",
      type: "LabelWidget",
      parent: "grpContractInquiry",
      Label: "General",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblGeneral_PROPERTIES

      // END_USER_CODE-USER_lblGeneral_PROPERTIES
    },
    lblLocation: {
      name: "lblLocation",
      type: "LabelWidget",
      parent: "grpContractInquiry",
      Label: "Location",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLocation_PROPERTIES

      // END_USER_CODE-USER_lblLocation_PROPERTIES
    },
    lblPeanut: {
      name: "lblPeanut",
      type: "LabelWidget",
      parent: "grpContractInquiry",
      Label: "Peanut",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPeanut_PROPERTIES

      // END_USER_CODE-USER_lblPeanut_PROPERTIES
    },
    lblTotals: {
      name: "lblTotals",
      type: "LabelWidget",
      parent: "grpAction",
      Label: "Totals",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblTotals_PROPERTIES

      // END_USER_CODE-USER_lblTotals_PROPERTIES
    },
    // lstContractList: {
    //   name: "lstContractList",
    //   type: "ListBoxFieldWidget",
    //   parent: "contractList",
    //   ColSpan: "3",
    //   DataProviderForListBox: "inline",
    //   HasLabel: false,

    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_lstContractList_PROPERTIES

    //   // END_USER_CODE-USER_lstContractList_PROPERTIES
    // },
    col211concor: {
      name: "col211concor",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "211 Concordia",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col211concor_PROPERTIES

      // END_USER_CODE-USER_col211concor_PROPERTIES
    },
    col211gpc: {
      name: "col211gpc",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "211 GPC",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col211gpc_PROPERTIES

      // END_USER_CODE-USER_col211gpc_PROPERTIES
    },
    colApplPounds: {
      name: "colApplPounds",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Applied Pounds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colApplPounds_PROPERTIES

      // END_USER_CODE-USER_colApplPounds_PROPERTIES
    },
    colByngPt: {
      name: "colByngPt",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Buying Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colByngPt_PROPERTIES

      // END_USER_CODE-USER_colByngPt_PROPERTIES
    },
    colCntrctPounds: {
      name: "colCntrctPounds",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Contract Pounds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCntrctPounds_PROPERTIES

      // END_USER_CODE-USER_colCntrctPounds_PROPERTIES
    },
    colContrct: {
      name: "colContrct",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Contract #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContrct_PROPERTIES

      // END_USER_CODE-USER_colContrct_PROPERTIES
    },
    colContTyp: {
      name: "colContTyp",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Cont. Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContTyp_PROPERTIES

      // END_USER_CODE-USER_colContTyp_PROPERTIES
    },
    colDate: {
      name: "colDate",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDate_PROPERTIES

      // END_USER_CODE-USER_colDate_PROPERTIES
    },
    colDryLndAcr: {
      name: "colDryLndAcr",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Dry Land Acres",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDryLndAcr_PROPERTIES

      // END_USER_CODE-USER_colDryLndAcr_PROPERTIES
    },
    colElection: {
      name: "colElection",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Election",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colElection_PROPERTIES

      // END_USER_CODE-USER_colElection_PROPERTIES
    },
    colIrriAcr: {
      name: "colIrriAcr",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Irrigated Acres",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colIrriAcr_PROPERTIES

      // END_USER_CODE-USER_colIrriAcr_PROPERTIES
    },
    colLoanLbsNtPrc: {
      name: "colLoanLbsNtPrc",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Loan Lbs. Not Priced",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLoanLbsNtPrc_PROPERTIES

      // END_USER_CODE-USER_colLoanLbsNtPrc_PROPERTIES
    },
    colLockedPrice: {
      name: "colLockedPrice",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Locked Price",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockedPrice_PROPERTIES

      // END_USER_CODE-USER_colLockedPrice_PROPERTIES
    },
    colMinDisc: {
      name: "colMinDisc",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Minority Disclosure",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMinDisc_PROPERTIES

      // END_USER_CODE-USER_colMinDisc_PROPERTIES
    },
    colOleic: {
      name: "colOleic",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Oleic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOleic_PROPERTIES

      // END_USER_CODE-USER_colOleic_PROPERTIES
    },
    colOpen: {
      name: "colOpen",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Open",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOpen_PROPERTIES

      // END_USER_CODE-USER_colOpen_PROPERTIES
    },
    colPnutType: {
      name: "colPnutType",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Peanut Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPnutType_PROPERTIES

      // END_USER_CODE-USER_colPnutType_PROPERTIES
    },
    colPnutVrty: {
      name: "colPnutVrty",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Peanut Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPnutVrty_PROPERTIES

      // END_USER_CODE-USER_colPnutVrty_PROPERTIES
    },
    colPrice: {
      name: "colPrice",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Price",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPrice_PROPERTIES

      // END_USER_CODE-USER_colPrice_PROPERTIES
    },
    colPricedLbsSign: {
      name: "colPricedLbsSign",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Price Lbs. Signed",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPricedLbsSign_PROPERTIES

      // END_USER_CODE-USER_colPricedLbsSign_PROPERTIES
    },
    colPricedPounds: {
      name: "colPricedPounds",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Priced Pounds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPricedPounds_PROPERTIES

      // END_USER_CODE-USER_colPricedPounds_PROPERTIES
    },
    colPricLbsApp: {
      name: "colPricLbsApp",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Priced Lbs. Approved",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPricLbsApp_PROPERTIES

      // END_USER_CODE-USER_colPricLbsApp_PROPERTIES
    },
    colSeg: {
      name: "colSeg",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Seg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeg_PROPERTIES

      // END_USER_CODE-USER_colSeg_PROPERTIES
    },
    colStatus: {
      name: "colStatus",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStatus_PROPERTIES

      // END_USER_CODE-USER_colStatus_PROPERTIES
    },
    colTntAppld: {
      name: "colTntAppld",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Tentative Applied",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTntAppld_PROPERTIES

      // END_USER_CODE-USER_colTntAppld_PROPERTIES
    },
    colTotlApp: {
      name: "colTotlApp",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Total Application",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTotlApp_PROPERTIES

      // END_USER_CODE-USER_colTotlApp_PROPERTIES
    },
    colVndr: {
      name: "colVndr",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVndr_PROPERTIES

      // END_USER_CODE-USER_colVndr_PROPERTIES
    },
    txtcol211concor: {
      name: "txtcol211concor",
      type: "TextBoxWidget",
      colName: "col211concor",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol211concor_PROPERTIES

      // END_USER_CODE-USER_txtcol211concor_PROPERTIES
    },
    txtcol211gpc: {
      name: "txtcol211gpc",
      type: "TextBoxWidget",
      colName: "col211gpc",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol211gpc_PROPERTIES

      // END_USER_CODE-USER_txtcol211gpc_PROPERTIES
    },
    txtcolApplPounds: {
      name: "txtcolApplPounds",
      type: "TextBoxWidget",
      colName: "colApplPounds",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolApplPounds_PROPERTIES

      // END_USER_CODE-USER_txtcolApplPounds_PROPERTIES
    },
    txtcolByngPt: {
      name: "txtcolByngPt",
      type: "TextBoxWidget",
      colName: "colByngPt",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolByngPt_PROPERTIES

      // END_USER_CODE-USER_txtcolByngPt_PROPERTIES
    },
    txtcolCntrctPounds: {
      name: "txtcolCntrctPounds",
      type: "TextBoxWidget",
      colName: "colCntrctPounds",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCntrctPounds_PROPERTIES

      // END_USER_CODE-USER_txtcolCntrctPounds_PROPERTIES
    },
    txtcolContrct: {
      name: "txtcolContrct",
      type: "TextBoxWidget",
      colName: "colContrct",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContrct_PROPERTIES

      // END_USER_CODE-USER_txtcolContrct_PROPERTIES
    },
    txtcolContTyp: {
      name: "txtcolContTyp",
      type: "TextBoxWidget",
      colName: "colContTyp",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContTyp_PROPERTIES

      // END_USER_CODE-USER_txtcolContTyp_PROPERTIES
    },
    txtcolDate: {
      name: "txtcolDate",
      type: "TextBoxWidget",
      colName: "colDate",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDate_PROPERTIES

      // END_USER_CODE-USER_txtcolDate_PROPERTIES
    },
    txtcolDryLndAcr: {
      name: "txtcolDryLndAcr",
      type: "TextBoxWidget",
      colName: "colDryLndAcr",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDryLndAcr_PROPERTIES

      // END_USER_CODE-USER_txtcolDryLndAcr_PROPERTIES
    },
    txtcolElection: {
      name: "txtcolElection",
      type: "TextBoxWidget",
      colName: "colElection",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolElection_PROPERTIES

      // END_USER_CODE-USER_txtcolElection_PROPERTIES
    },
    txtcolIrriAcr: {
      name: "txtcolIrriAcr",
      type: "TextBoxWidget",
      colName: "colIrriAcr",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolIrriAcr_PROPERTIES

      // END_USER_CODE-USER_txtcolIrriAcr_PROPERTIES
    },
    txtcolLoanLbsNtPrc: {
      name: "txtcolLoanLbsNtPrc",
      type: "TextBoxWidget",
      colName: "colLoanLbsNtPrc",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLoanLbsNtPrc_PROPERTIES

      // END_USER_CODE-USER_txtcolLoanLbsNtPrc_PROPERTIES
    },
    txtcolLockedPrice: {
      name: "txtcolLockedPrice",
      type: "TextBoxWidget",
      colName: "colLockedPrice",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLockedPrice_PROPERTIES

      // END_USER_CODE-USER_txtcolLockedPrice_PROPERTIES
    },
    txtcolMinDisc: {
      name: "txtcolMinDisc",
      type: "TextBoxWidget",
      colName: "colMinDisc",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolMinDisc_PROPERTIES

      // END_USER_CODE-USER_txtcolMinDisc_PROPERTIES
    },
    txtcolOleic: {
      name: "txtcolOleic",
      type: "TextBoxWidget",
      colName: "colOleic",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOleic_PROPERTIES

      // END_USER_CODE-USER_txtcolOleic_PROPERTIES
    },
    txtcolOpen: {
      name: "txtcolOpen",
      type: "TextBoxWidget",
      colName: "colOpen",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOpen_PROPERTIES

      // END_USER_CODE-USER_txtcolOpen_PROPERTIES
    },
    txtcolPnutType: {
      name: "txtcolPnutType",
      type: "TextBoxWidget",
      colName: "colPnutType",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPnutType_PROPERTIES

      // END_USER_CODE-USER_txtcolPnutType_PROPERTIES
    },
    txtcolPnutVrty: {
      name: "txtcolPnutVrty",
      type: "TextBoxWidget",
      colName: "colPnutVrty",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPnutVrty_PROPERTIES

      // END_USER_CODE-USER_txtcolPnutVrty_PROPERTIES
    },
    txtcolPrice: {
      name: "txtcolPrice",
      type: "TextBoxWidget",
      colName: "colPrice",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPrice_PROPERTIES

      // END_USER_CODE-USER_txtcolPrice_PROPERTIES
    },
    txtcolPricedLbsSign: {
      name: "txtcolPricedLbsSign",
      type: "TextBoxWidget",
      colName: "colPricedLbsSign",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPricedLbsSign_PROPERTIES

      // END_USER_CODE-USER_txtcolPricedLbsSign_PROPERTIES
    },
    txtcolPricedPounds: {
      name: "txtcolPricedPounds",
      type: "TextBoxWidget",
      colName: "colPricedPounds",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPricedPounds_PROPERTIES

      // END_USER_CODE-USER_txtcolPricedPounds_PROPERTIES
    },
    txtcolPricLbsApp: {
      name: "txtcolPricLbsApp",
      type: "TextBoxWidget",
      colName: "colPricLbsApp",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPricLbsApp_PROPERTIES

      // END_USER_CODE-USER_txtcolPricLbsApp_PROPERTIES
    },
    txtcolSeg: {
      name: "txtcolSeg",
      type: "TextBoxWidget",
      colName: "colSeg",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeg_PROPERTIES

      // END_USER_CODE-USER_txtcolSeg_PROPERTIES
    },
    txtcolStatus: {
      name: "txtcolStatus",
      type: "TextBoxWidget",
      colName: "colStatus",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolStatus_PROPERTIES

      // END_USER_CODE-USER_txtcolStatus_PROPERTIES
    },
    txtcolTntAppld: {
      name: "txtcolTntAppld",
      type: "TextBoxWidget",
      colName: "colTntAppld",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTntAppld_PROPERTIES

      // END_USER_CODE-USER_txtcolTntAppld_PROPERTIES
    },
    txtcolTotlApp: {
      name: "txtcolTotlApp",
      type: "TextBoxWidget",
      colName: "colTotlApp",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTotlApp_PROPERTIES

      // END_USER_CODE-USER_txtcolTotlApp_PROPERTIES
    },
    txtcolVndr: {
      name: "txtcolVndr",
      type: "TextBoxWidget",
      colName: "colVndr",
      parent: "gridCntrctInq",
      CharWidth: "90",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVndr_PROPERTIES

      // END_USER_CODE-USER_txtcolVndr_PROPERTIES
    },

    //adding missing columns
    colFarm: {
      name: "colFarm",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Farm",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFarm_PROPERTIES

      // END_USER_CODE-USER_colFarm_PROPERTIES
    },
    txtcolFarm: {
      name: "txtcolFarm",
      type: "TextBoxWidget",
      colName: "colFarm",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFarm_PROPERTIES

      // END_USER_CODE-USER_txtcolFarm_PROPERTIES
    },
    colPriceOption: {
      name: "colPriceOption",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Price Option",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPriceOption_PROPERTIES

      // END_USER_CODE-USER_colPriceOption_PROPERTIES
    },
    txtcolPriceOption: {
      name: "txtcolPriceOption",
      type: "TextBoxWidget",
      colName: "colPriceOption",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPriceOption_PROPERTIES

      // END_USER_CODE-USER_txtcolPriceOption_PROPERTIES
    },
    colMaxOption: {
      name: "colMaxOption",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Max Option",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMaxOption_PROPERTIES

      // END_USER_CODE-USER_colMaxOption_PROPERTIES
    },
    txtcolMaxOption: {
      name: "txtcolMaxOption",
      type: "TextBoxWidget",
      colName: "colMaxOption",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolMaxOption_PROPERTIES

      // END_USER_CODE-USER_txtcolMaxOption_PROPERTIES
    },
    colHarvestCompDate: {
      name: "colHarvestCompDate",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Harvest Comp. Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colHarvestCompDate_PROPERTIES

      // END_USER_CODE-USER_colHarvestCompDate_PROPERTIES
    },
    txtcolHarvestCompDate: {
      name: "txtcolHarvestCompDate",
      type: "TextBoxWidget",
      colName: "colHarvestCompDate",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolHarvestCompDate_PROPERTIES

      // END_USER_CODE-USER_txtcolHarvestCompDate_PROPERTIES
    },
    colGrpNo: {
      name: "colGrpNo",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Grp#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGrpNo_PROPERTIES

      // END_USER_CODE-USER_colGrpNo_PROPERTIES
    },
    txtcolGrpNo: {
      name: "txtcolGrpNo",
      type: "TextBoxWidget",
      colName: "colGrpNo",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolGrpNo_PROPERTIES

      // END_USER_CODE-USER_txtcolGrpNo_PROPERTIES
    },
    colGrpPercent: {
      name: "colGrpPercent",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Grp%",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGrpPercent_PROPERTIES

      // END_USER_CODE-USER_colGrpPercent_PROPERTIES
    },
    txtcolGrpPercent: {
      name: "txtcolGrpPercent",
      type: "TextBoxWidget",
      colName: "colGrpPercent",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolGrpPercent_PROPERTIES

      // END_USER_CODE-USER_txtcolGrpPercent_PROPERTIES
    },
    colGen: {
      name: "colGen",
      type: "GridColumnWidget",
      parent: "gridCntrctInq",
      Label: "Gen",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGen_PROPERTIES

      // END_USER_CODE-USER_colGen_PROPERTIES
    },
    txtcolGen: {
      name: "txtcolGen",
      type: "TextBoxWidget",
      colName: "colGen",
      parent: "gridCntrctInq",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolGen_PROPERTIES

      // END_USER_CODE-USER_txtcolGen_PROPERTIES
    },

    //added missing columns in grid

    gridCntrctInq: {
      name: "gridCntrctInq",
      type: "GridWidget",
      parent: "ContractInquiry",
      gridCellsOrder:
        //"txtcolContrct,txtcolContTyp,txtcolDate,txtcolByngPt,txtcolVndr,txtcolPnutType,txtcolPnutVrty,txtcolOleic,txtcolSeg,txtcolStatus,txtcolPrice,txtcolCntrctPounds,txtcolApplPounds,txtcolTntAppld,txtcolTotlApp,txtcolOpen,txtcolDryLndAcr,txtcolIrriAcr,txtcolElection,txtcolLockedPrice,txtcolPricLbsApp,txtcolPricedLbsSign,txtcolPricedPounds,txtcolLoanLbsNtPrc,txtcol211gpc,txtcol211concor,txtcolMinDisc",

        "txtcolContrct,txtcolContTyp,txtcolDate,txtcolByngPt,txtcolVndr,txtcolFarm,txtcolPnutType,txtcolPnutVrty,txtcolGen,txtcolOleic,txtcolSeg,txtcolStatus,txtcolPrice,txtcolCntrctPounds,txtcolApplPounds,txtcolTntAppld,txtcolTotlApp,txtcolOpen,txtcolDryLndAcr,txtcolIrriAcr,txtcolPriceOption,txtcolMaxOption,txtcolElection,txtcolLockedPrice,txtcolPricLbsApp,txtcolPricedLbsSign,txtcolPricedPounds,txtcolLoanLbsNtPrc,txtcolHarvestCompDate,txtcolGrpNo,txtcolGrpPercent,txtcol211gpc,txtcol211concor,txtcolMinDisc",

      Label: "Contract",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridCntrctInq_PROPERTIES
      isExpandable: true,
      defaultExpand: false,
      isMultiSelect: false,
      needGridChange: true,//Change for Grid change
      showExpander:true,
      expandedCompTemplate: ({ data }) => (
        // <pre>{JSON.stringify(data, null, 2)}</pre>
        <div>
          {data.expData ? (
            <div className="py-3 expandable-area col-sm-12 rdt_TableRow">
              {data.expData.map((elem, i) =>
                <div className="py-3 expandable-area col-sm-12 rdt_TableRow selectable" key={"Row" + i}>
                  <div className={"row" + " rdt_ExpanderRow"} key={"ExpanderRow" + i} >
                    <div className="expandIconSpc"></div>
                    {thisObj.state.gridCntrctInq.columns[0].Visible?
                    <div className="extraRowCell_1" key="expandCell1">
                    </div> :<></>}
                    {thisObj.state.gridCntrctInq.columns[1].Visible?                           
                    <div className="extraRowCell_2" key="expandCell2">
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[2].Visible?
                    <div className="extraRowCell_3" key="expandCell3">
                    </div>  :<></>}
                    {thisObj.state.gridCntrctInq.columns[3].Visible?
                     <div className="extraRowCell_5" key="expandCell5">
                     <label className="expndLbl">
                   {elem.expandedcolumn1} {elem.expandedcolumn2 != null ? '- '+elem.expandedcolumn2 : ''}{elem.expandedcolumn3 != null ? '- '+elem.expandedcolumn3 : ''}
                  </label>
                 </div>:
                 <></>}                                    
                    
                    {thisObj.state.gridCntrctInq.columns[4].Visible?
                     <div className="extraRowCell_4" key="expandCell4">
                     </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[5].Visible?
                    <div className="extraRowCell_6" key="expandCell6">   
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[6].Visible?
                    <div className="extraRowCell_7" key="expandCell7">
                       <label className="expndLbl">
                      {elem.expandedcolumn4}
                      </label>
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[7].Visible?
                    <div className="extraRowCell_8" key="expandCell8">
                       
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[8].Visible?
                    <div className="extraRowCell_9" key="expandCell9">
                      <label className="expndLbl">
                      {elem.expandedcolumn5}
                      </label>
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[9].Visible?
                    <div className="extraRowCell_10" key="expandCell10">
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[10].Visible?
                    <div className="extraRowCell_11" key="expandCell11">
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[11].Visible?
                    <div className="extraRowCell_12" key="expandCell12">
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[12].Visible?
                    <div className="extraRowCell_13" key="expandCell13">
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[13].Visible?
                    <div className="extraRowCell_14" key="expandCell14">
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[14].Visible?
                    <div className="extraRowCell_15" key="expandCell15">
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[15].Visible?
                    <div className="extraRowCell_16" key="expandCell16">
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[16].Visible?                    
                    <div className="extraRowCell_17" key="expandCell17">
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[17].Visible?                    
                    <div className="extraRowCell_18" key="expandCell18">
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[18].Visible?
                    <div className="extraRowCell_19" key="expandCell19">
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[19].Visible?
                    <div className="extraRowCell_20" key="expandCell20">
                        <label className="expndLbl">
                      {elem.expandedcolumn6}
                      </label>
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[20].Visible?
                    <div className="extraRowCell_21" key="expandCell21">
                        <label className="expndLbl">
                      {elem.expandedcolumn7}
                      </label>
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[21].Visible?
                    <div className="extraRowCell_22" key="expandCell22">
                        <label className="expndLbl">
                    {elem.expandedcolumn8}
                    </label>
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[22].Visible?
                    <div className="extraRowCell_23" key="expandCell23">
                        <label className="expndLbl">
                    {elem.expandedcolumn9}
                    </label>
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[23].Visible?
                    <div className="extraRowCell_24" key="expandCell24">
                        <label className="expndLbl">
                    {elem.expandedcolumn10}
                    </label>
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[24].Visible?
                    <div className="extraRowCell_25" key="expandCell25">
                        <label className="expndLbl">
                    {elem.expandedcolumn11}
                    </label>
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[25].Visible?
                    <div className="extraRowCell_26" key="expandCell26">
                        <label className="expndLbl">
                    {elem.expandedcolumn12}
                    </label>
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[26].Visible?
                    <div className="extraRowCell_27" key="expandCell27">
                        <label className="expndLbl">
                    {elem.expandedcolumn13}
                    </label>
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[27].Visible?
                    <div className="extraRowCell_28" key="expandCell28">
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[28].Visible?
                    <div className="extraRowCell_29" key="expandCell29">
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[29].Visible?
                    <div className="extraRowCell_30" key="expandCell30">
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[30].Visible?
                    <div className="extraRowCell_31" key="expandCell31">
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[31].Visible?
                    <div className="extraRowCell_32" key="expandCell32">
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[32].Visible?
                    <div className="extraRowCell_33" key="expandCell33">
                    </div>:<></>}
                    {thisObj.state.gridCntrctInq.columns[33].Visible?
                    <div className="extraRowCell_34" key="expandCell34">
                    </div>:<></>}
                    
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      )
      // END_USER_CODE-USER_gridCntrctInq_PROPERTIES
    },
    txtAppliedPounds: {
      name: "txtAppliedPounds",
      type: "TextBoxWidget",
      parent: "grpAction",
      Label: "Applied Pounds",
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAppliedPounds_PROPERTIES
      formattingReqd: true
      // END_USER_CODE-USER_txtAppliedPounds_PROPERTIES
    },
    txtContract: {
      name: "txtContract",
      type: "TextBoxWidget",
      parent: "grpContractInquiry",
      Label: "Contract#:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContract_PROPERTIES

      // END_USER_CODE-USER_txtContract_PROPERTIES
    },
    txtContractPounds: {
      name: "txtContractPounds",
      type: "TextBoxWidget",
      parent: "grpAction",
      Label: "Contract Pounds",
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContractPounds_PROPERTIES
      formattingReqd: true
      // END_USER_CODE-USER_txtContractPounds_PROPERTIES
    },
    txtDryLandAcres: {
      name: "txtDryLandAcres",
      type: "TextBoxWidget",
      parent: "grpAction",
      Label: "Dry Land Acres",
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDryLandAcres_PROPERTIES
      formattingReqd: true
      // END_USER_CODE-USER_txtDryLandAcres_PROPERTIES
    },
    txtGroup: {
      name: "txtGroup",
      type: "TextBoxWidget",
      parent: "grpContractInquiry",
      Label: "Group#:",
      ColSpan: "4",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGroup_PROPERTIES

      // END_USER_CODE-USER_txtGroup_PROPERTIES
    },
    txtIrrigatedAcres: {
      name: "txtIrrigatedAcres",
      type: "TextBoxWidget",
      parent: "grpAction",
      Label: "Irrigated Acres",
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtIrrigatedAcres_PROPERTIES
      formattingReqd: true
      // END_USER_CODE-USER_txtIrrigatedAcres_PROPERTIES
    },
    txtOpen: {
      name: "txtOpen",
      type: "TextBoxWidget",
      parent: "grpAction",
      Label: "Open",
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOpen_PROPERTIES
      formattingReqd: true
      // END_USER_CODE-USER_txtOpen_PROPERTIES
    },
    txtPrcnt: {
      name: "txtPrcnt",
      type: "TextBoxWidget",
      parent: "grpContractInquiry",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPrcnt_PROPERTIES

      // END_USER_CODE-USER_txtPrcnt_PROPERTIES
    },
    txtPrice: {
      name: "txtPrice",
      type: "TextBoxWidget",
      parent: "grpContractInquiry",
      Label: "Price:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPrice_PROPERTIES

      // END_USER_CODE-USER_txtPrice_PROPERTIES
    },
    txtTentativeApplied: {
      name: "txtTentativeApplied",
      type: "TextBoxWidget",
      parent: "grpAction",
      Label: "Tentative Applied",
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTentativeApplied_PROPERTIES
      formattingReqd: true
      // END_USER_CODE-USER_txtTentativeApplied_PROPERTIES
    },
    txtTotalApp: {
      name: "txtTotalApp",
      type: "TextBoxWidget",
      parent: "grpAction",
      Label: "Total Application",
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalApp_PROPERTIES
      formattingReqd: true
      // END_USER_CODE-USER_txtTotalApp_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpContractInquiry",
      Label: "Vendor#:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    grpContractInquiry: {
      name: "grpContractInquiry",
      type: "GroupBoxWidget",
      parent: "ContractInquiry",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "6",
      // START_USER_CODE-USER_grpContractInquiry_PROPERTIES

      // END_USER_CODE-USER_grpContractInquiry_PROPERTIES
    },
    contractList: {
      name: "contractList",
      type: "GroupBoxWidget",
      parent: "ContractInquiry",
      Label: "Contract List:",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_contractList_PROPERTIES

      // END_USER_CODE-USER_contractList_PROPERTIES
    },
    grpAction: {
      name: "grpAction",
      type: "GroupBoxWidget",
      parent: "ContractInquiry",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "12",
      // START_USER_CODE-USER_grpAction_PROPERTIES

      // END_USER_CODE-USER_grpAction_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu": {},

        "ContractManagement#ContractProfile": {},

        "ContractManagement#VendorLookup": {},

        "ContractManagement#FlexMktPrint": {},
      },
      REVERSE: {
        "ContractManagement#MainMenu": {},

        "ContractManagement#ContractProfile": {},

        "ContractManagement#VendorLookup": {},

        "ContractManagement#FlexMktPrint": {},
      },
    },
  };

  let _buttonServices = { MainMenu: {}, ContractProfile: {}, VendorLookup: {}, FlexMktPrint: {} };

  let _buttonNavigation = {
    btnVenLookUp: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
    btnOpen: {
      DEFAULT: ["ContractManagement#ContractProfile#DEFAULT#true#Click"],
    },

    btnClose: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    },

    btnPrint: {
      DEFAULT: ["ContractManagement#ReportPreviewContract#DEFAULT#true#Click"],
    }
  };

  const [loading, setLoading] = useState(false);

  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });

  useEffect(() => {
    let vendor_lookup = getData(thisObj, 'vendorDetails')
    if (vendor_lookup !== null) {
      thisObj.setFieldValue("txtVendor", vendor_lookup.VendorNumber);
      // show(thisObj,"txtVendorname",'')
      document.getElementsByClassName("txtVendorname")[0].style.visibility = "visible";
      thisObj.setFieldValue("txtVendorname", vendor_lookup.key);
    }
  }, [getData(thisObj, 'vendorDetails')]);

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    // let obj = {
    //   txtcolContrct:"1234",txtcolContTyp:"3456"
    // };
    // js12.push(obj)
    // setValue(thisObj,"gridCntrctInq",js12);
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    setData(thisObj,"firstSearch",false);
  }, []);

  useEffect(() => {
    let reload = getData(thisObj, 'reload')
    if (reload == true) {
      onbtnSearchClick()
    }
    setData(thisObj, "reload", false);
  }, [getData(thisObj, 'reload')]);

  const ondrpTypeChange = () => {
    try {
      let list = thisObj.state['drpType'].valueList;
      let val = thisObj.values['drpType'];
      let pnut_type_id = list.find(elem => elem.key === val).key;
      let list2 = thisObj.state['drpBuyingPoint'].valueList;
      let val2 = thisObj.values['drpBuyingPoint'];
      let buyingPointId = null
      if (val2 !== null && val2 !== undefined) {
        buyingPointId = list2.find(elem => elem.key === val2).key;
      }
      //for peanut variety control	
      bFillPeanutVarietyList(buyingPointId, pnut_type_id)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Expection in changing pnut type change"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ondrpTypeChange = ondrpTypeChange;

  const ondrpBuyingPointChange = () => {
    try {
      let list = thisObj.state['drpType'].valueList;
      let val = thisObj.values['drpType'];
      let pnut_type_id = null
      if (val !== "" && val !== undefined) {
        pnut_type_id = list.find(elem => elem.key === val).key;
      }
      let list2 = thisObj.state['drpBuyingPoint'].valueList;
      let val2 = thisObj.values['drpBuyingPoint'];
      let buyingPointId = list2.find(elem => elem.key === val2).key;
      //for peanut variety control	
      bFillPeanutVarietyList(buyingPointId, pnut_type_id)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in drpbuyingpoint change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ondrpBuyingPointChange = ondrpBuyingPointChange;

  //showing and hiding pencentage txtbox
  const ondrpAppliedChange = () => {
    try {
      //let list3= thisObj.state['drpApplied'].valueList	
      let val3 = thisObj.values['drpApplied']
      //let selected= list3.find(elem=>elem.key===val3).key;	
      if (val3 == 'L' || val3 == 'G') {
        //show(thisObj,"txtPrcnt")	
        document.getElementsByClassName("txtPrcnt")[0].style.visibility = "visible";
        setValue(thisObj, "txtPrcnt", '')
      }
      else {
        //hide(thisObj,'txtPrcnt','')	
        document.getElementsByClassName("txtPrcnt")[0].style.visibility = "hidden";
        setValue(thisObj, "txtPrcnt", '')
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in drpApplied change event");
      }
      return false;
    }
    return true;
  };
  thisObj.ondrpAppliedChange = ondrpAppliedChange;

  //opening contractprofile scrren
  const onbtnOpenClick = () => {
    try {
      if (thisObj.state.gridCntrctInq.selected[0].txtcolContrct !== undefined) {
        //Set Value of selected row Contract number
        // setData(thisObj,"contract_num",thisObj.state.gridCntrctInq.selected[0].txtcolContrct);
        // setData(thisObj,"editingoption",'edit')
        let contractnum = thisObj.state.gridCntrctInq.selected[0].txtcolContrct
        commonContext1.setContractProfile_ContractNumber(contractnum);
        commonContext1.setContractProfile_FormState('edit');
        setData(thisObj, "parentScrContractProfile", "Contract Inquiry");
      }
      else {
        showMessage(thisObj, "A contract must be selected to open");
        return false
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "No Contract Selected"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnOpenClick = onbtnOpenClick;

  const ongridCntrctInqDblclick = () => {
    try {
      if (thisObj.state.gridCntrctInq.selected[0].txtcolContrct !== undefined) {
        let contractnum = thisObj.state.gridCntrctInq.selected[0].txtcolContrct
        commonContext1.setContractProfile_ContractNumber(contractnum);
        commonContext1.setContractProfile_FormState('edit');
        goTo(thisObj, "ContractManagement#ContractProfile#DEFAULT#true#Click");
      }
      else {
        showMessage(thisObj, "error while selecting contract");
        return false
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "error on double click , please try open button"
        );
      }
    }
  }
  thisObj.ongridCntrctInqDblclick = ongridCntrctInqDblclick;

  //opening print screen
  const onbtnPrintClick = () => {
    try {
      if (thisObj.state.gridCntrctInq.selected[0].txtcolContrct == null || thisObj.state.gridCntrctInq.selected[0].txtcolContrct == undefined) {
        showMessage(thisObj, "A contract must be selected to print. \n No Contract Selected")
        return false;
      }
      if (!getData(thisObj, "mbSecurityUpdateAfterSigned")) {
        let tempstatus2 = thisObj.state.gridCntrctInq.selected[0].txtcolStatus.toUpperCase()
        if ((tempstatus2 == "APPROVED") || (tempstatus2 == "VOIDED") || (tempstatus2 == "CLOSED") || (tempstatus2 == "CANCELLED")) {
          showMessage(thisObj, "Insufficient security to print contract.")
          return false;
        }
      }
      let contract_number = thisObj.state.gridCntrctInq.selected[0].txtcolContrct
      let comments = thisObj.values.chkPrintComm
      //will sent compid,cropyear, contract number , boolean value for comment checkbox
      //setData(thisObj,"contract_number",contract_number)
      setData(thisObj, "contractType", thisObj.state.gridCntrctInq.selected[0].txtcolContTyp) //thisObj.values.drpContractType)
      setData(thisObj, 'minorDisclosure', thisObj.state.gridCntrctInq.selected[0].txtcolMinDisc)
      setData(thisObj, 'cmdForeignMatarial', 'Y')
      setData(thisObj, 'cmdChemicalAddendum', 'Y')
      commonContext1.setContractProfile_ContractNumber(contract_number);
      commonContext1.setContractProfile_ReportRequest("ContractInquiry");
      setData(thisObj, "comments", comments)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "No Contract Selected")
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnPrintClick = onbtnPrintClick;


  const onbtnViewUploadClick = () => {
    try {
      if (thisObj.state.gridCntrctInq.selected[0].txtcolContrct == null || thisObj.state.gridCntrctInq.selected[0].txtcolContrct == undefined) {
        showMessage(thisObj, "A contract must be selected to view. \n\n No Contract Selected")
        return false;
      }
      let sContract = thisObj.state.gridCntrctInq.selected[0].txtcolContrct
      let redirecturl = null

      ContractManagementService.RetrieveContractDetails(sContract, 'PN1010', null)
        .then(response => {
          let data = response
          let msUploadStatus = null
          let msApprovedUser = null
          if (data !== null && data !== undefined && data.length > 0) {
            msUploadStatus = data[0].upload_status
            msApprovedUser = data[0].approved_user
          } else {
            showMessage(thisObj, "Problem retrieving contract status")
            return false
          }

          if (msUploadStatus.trim() == "I") {
            showMessage(thisObj, "Invalid file was uploaded. \n\n Please rescan the signed contract before it can be approved?")
            return false
          }
          if (msUploadStatus.trim() !== "C") {
            showMessage(thisObj, "Contract has not been processed. \n\n Try again in about 5 minutes?")
            return false
          }
        })
      ContractManagementService.RetrievePeanutStaticValues()
        .then(response => {
          let data = response
          if (data !== undefined && data.length !== 0) {
            if (process.env.REACT_APP_ENVIR == 'LOCAL' || process.env.REACT_APP_ENVIR == 'DEV' || process.env.REACT_APP_ENVIR == 'TEST' || process.env.REACT_APP_ENVIR == 'UAT')
              redirecturl = data[0].pps_defaults.pps_url.test
            else
              redirecturl = data[0].pps_defaults.pps_url.prod
          }
          else {
            return false;
          }
        })
      ContractManagementService.RetieveContractUploadFileDetails(sContract)
        .then(response => {
          let data1 = response
          let createurl = null
          let uplodedfilename = data1.uploadFileName
          let filepath = data1.filePath
          if ((filepath !== null && filepath !== undefined) && (uplodedfilename !== null && uplodedfilename !== undefined) && redirecturl !== null) {
            createurl = redirecturl + '/pps/contracts/' + uplodedfilename
            window.open(createurl)
          } else {
            showMessage(thisObj, "Cannot find an uploaded contract file.")
            return false
          }
        })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        //showMessage(thisObj,"A contract must be selected to view. \n\n No Contract Selected")
        showMessage(thisObj, "A contract must be selected to view.")
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnViewUploadClick = onbtnViewUploadClick;

  //creating excel file in collapse format
  const onbtnExportExcelClick = () => {
    try {
      let status1 = thisObj.values.chkExp
      if (status1 == '1') {
        let mainstatus = thisObj.values.chkExpCollGri
        if (mainstatus == '1') { status1 = '2' }
        if (mainstatus == '2') { status1 = '3' }
      }
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      let excelData = getValue(thisObj, "gridCntrctInq")
      var fileName = "Contract Search"
      const arrayData = []
      for (var i = 0; i < excelData.length; i++) {
        var obj = {}

        if (thisObj.state.gridCntrctInq.columns[0].Visible) {
          obj["contract #"] = ExcelNumericDataConvert(excelData[i].txtcolContrct);
        }
        if (thisObj.state.gridCntrctInq.columns[1].Visible) {
          obj["Cont. Type"] = excelData[i].txtcolContTyp;
        }
        if (thisObj.state.gridCntrctInq.columns[2].Visible) {
          obj["Date"] = excelData[i].txtcolDate;
        }
        if (thisObj.state.gridCntrctInq.columns[3].Visible) {
          obj["Buying Point"] = excelData[i].txtcolByngPt;
        }
        if (thisObj.state.gridCntrctInq.columns[4].Visible) {
          obj["Vendor"] = excelData[i].txtcolVndr;
        }
        if (thisObj.state.gridCntrctInq.columns[5].Visible) {
          obj["Farm"] = excelData[i].txtcolFarm;
        }
        if (thisObj.state.gridCntrctInq.columns[6].Visible) {
          obj["Peanut Type"] = excelData[i].txtcolPnutType;
        }
        if (thisObj.state.gridCntrctInq.columns[7].Visible) {
          obj["Peanut Variety"] = excelData[i].txtcolPnutVrty;
        }
        if (thisObj.state.gridCntrctInq.columns[8].Visible) {
          obj["Gen"] = excelData[i].txtcolGen;
        }
        if (thisObj.state.gridCntrctInq.columns[9].Visible) {
          obj["Oleic"] = excelData[i].txtcolOleic;
        }
        if (thisObj.state.gridCntrctInq.columns[10].Visible) {
          obj["Seg"] = ExcelNumericDataConvert(excelData[i].txtcolSeg);
        }
        if (thisObj.state.gridCntrctInq.columns[11].Visible) {
          obj["Status"] = excelData[i].txtcolStatus;
        }
        if (thisObj.state.gridCntrctInq.columns[12].Visible) {
          obj["Price"] = ExcelNumericDataConvert(excelData[i].txtcolPrice,[',']);
        }
        if (thisObj.state.gridCntrctInq.columns[13].Visible) {
          obj["Contract Pounds"] = ExcelNumericDataConvert(excelData[i].txtcolCntrctPounds,[',']);
        }
        if (thisObj.state.gridCntrctInq.columns[14].Visible) {
          obj["Applied Pounds"] = ExcelNumericDataConvert(excelData[i].txtcolApplPounds,[',']);
        }
        if (thisObj.state.gridCntrctInq.columns[15].Visible) {
          obj["Tentative Applied"] = ExcelNumericDataConvert(excelData[i].txtcolTntAppld,[',']);
        }
        if (thisObj.state.gridCntrctInq.columns[16].Visible) {
          obj["Total Application"] = ExcelNumericDataConvert(excelData[i].txtcolTotlApp,[',']);
        }
        if (thisObj.state.gridCntrctInq.columns[17].Visible) {
          obj["Open"] = ExcelNumericDataConvert(excelData[i].txtcolOpen,[',']);
        }
        if (thisObj.state.gridCntrctInq.columns[18].Visible) {
          obj["Dry Land Acres"] = ExcelNumericDataConvert(excelData[i].txtcolDryLndAcr,[',']);
        }
        if (thisObj.state.gridCntrctInq.columns[19].Visible) {
          obj["Irrigated Acres"] = ExcelNumericDataConvert(excelData[i].txtcolIrriAcr,[',']);
        }
        if (thisObj.state.gridCntrctInq.columns[20].Visible) {
          obj["Price Option"] = excelData[i].txtcolPriceOption;
        }
        if (thisObj.state.gridCntrctInq.columns[21].Visible) {
          obj["Max Option"] = excelData[i].txtcolMaxOption;
        }
        if (thisObj.state.gridCntrctInq.columns[22].Visible) {
          obj["Election"] = excelData[i].txtcolElection;
        }
        if (thisObj.state.gridCntrctInq.columns[23].Visible) {
          obj["Locked Price"] = excelData[i].txtcolLockedPrice;
        }
        if (thisObj.state.gridCntrctInq.columns[24].Visible) {
          obj["Price Lbs Approved"] = excelData[i].txtcolPricLbsApp;
        }
        if (thisObj.state.gridCntrctInq.columns[25].Visible) {
          obj["Price lbs Signed"] = excelData[i].txtcolPricedLbsSign;
        }
        if (thisObj.state.gridCntrctInq.columns[26].Visible) {
          obj["priced pounds"] = excelData[i].txtcolPricedPounds;
        }
        if (thisObj.state.gridCntrctInq.columns[27].Visible) {
          obj["Loan Lbs Not Priced"] = excelData[i].txtcolLoanLbsNtPrc;
        }
        if (thisObj.state.gridCntrctInq.columns[28].Visible) {
          obj["Harvest Comp. Date"] = excelData[i].txtcolHarvestCompDate;
        }
        if (thisObj.state.gridCntrctInq.columns[29].Visible) {
          obj["Grp#"] = ExcelNumericDataConvert(excelData[i].txtcolGrpNo,[',']);
        }
        if (thisObj.state.gridCntrctInq.columns[30].Visible) {
          obj["Grp%"] = ExcelNumericDataConvert(excelData[i].txtcolGrpPercent,[',']);
        }
        if (thisObj.state.gridCntrctInq.columns[31].Visible) {
          obj["211 GPC"] = excelData[i].txtcol211gpc;
        }
        if (thisObj.state.gridCntrctInq.columns[32].Visible) {
          obj["211 Concordia"] = excelData[i].txtcol211concor;
        }
        if (thisObj.state.gridCntrctInq.columns[33].Visible) {
          obj["Minority Disclosure"] = excelData[i].txtcolMinDisc;
        }

        arrayData.push(obj);
        if (status1 == '2') {    /// if expanded is selected
          for (let j = 0; j < excelData[i].expData.length; j++) {
            let obj = {}

            if (thisObj.state.gridCntrctInq.columns[0].Visible) {
              obj["contract #"] = excelData[i].expData[j].expandedcolumn1;
            }
            if (thisObj.state.gridCntrctInq.columns[1].Visible) {
              obj["Cont. Type"] = excelData[i].expData[j].expandedcolumn2;
            }
            if (thisObj.state.gridCntrctInq.columns[2].Visible) {
              obj["Date"] = excelData[i].expData[j].expandedcolumn3;
            }
            if (thisObj.state.gridCntrctInq.columns[6].Visible) {
              obj["Peanut Type"] = excelData[i].expData[j].expandedcolumn4;
            }
            if (thisObj.state.gridCntrctInq.columns[8].Visible) {
              obj["Gen"] = excelData[i].expData[j].expandedcolumn5;
            }
            if (thisObj.state.gridCntrctInq.columns[20].Visible) {
              obj["Price Option"] = excelData[i].expData[j].expandedcolumn6;
            }
            if (thisObj.state.gridCntrctInq.columns[21].Visible) {
              obj["Max Option"] = excelData[i].expData[j].expandedcolumn7;
            }
            if (thisObj.state.gridCntrctInq.columns[22].Visible) {
              obj["Election"] = excelData[i].expData[j].expandedcolumn8;
            }
            if (thisObj.state.gridCntrctInq.columns[23].Visible) {
              obj["Locked Price"] = excelData[i].expData[j].expandedcolumn9;
            }
            if (thisObj.state.gridCntrctInq.columns[24].Visible) {
              obj["Price Lbs Approved"] = excelData[i].expData[j].expandedcolumn10;
            }
            if (thisObj.state.gridCntrctInq.columns[25].Visible) {
              obj["Price lbs Signed"] = excelData[i].expData[j].expandedcolumn11;
            }
            if (thisObj.state.gridCntrctInq.columns[26].Visible) {
              obj["priced pounds"] = excelData[i].expData[j].expandedcolumn12;
            }
            if (thisObj.state.gridCntrctInq.columns[27].Visible) {
              obj["Loan Lbs Not Priced"] = excelData[i].expData[j].expandedcolumn13;
            }

            arrayData.push(obj)
          }
        }
      }
      const ws = XLSX.utils.json_to_sheet(arrayData)
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      var FileSaver = require('file-saver');
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "error on excel to export button")
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnExportExcelClick = onbtnExportExcelClick;

  //expanding and collapsing grid
  const onchkExpCollGriChange = () => {
    try {
      let currentstatus = thisObj.values.chkExpCollGri
      if (currentstatus == '1') {
        thisObj.setState(current => {
          return {
            ...current,
            gridCntrctInq: {
              ...thisObj.state["gridCntrctInq"],
              defaultExpand: true
            }
          }
        })
      }
      else if (currentstatus == '2') {
        thisObj.setState(current => {
          return {
            ...current,
            gridCntrctInq: {
              ...thisObj.state["gridCntrctInq"],
              defaultExpand: false
            }
          }
        })
      }

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "A contract must be selected to view. \n\n No Contract Selected")
      }
      return false;
    }
    return true;
  }
  thisObj.onchkExpCollGriChange = onchkExpCollGriChange;

  //for enabling btnviewupload loading once
  const ongridCntrctInqRowSelect = (rowSelected) => {
    try {
      let data3 = rowSelected;
      if (data3.selectedRows)
        data3 = data3.selectedRows[0];
      let contract_number = data3.txtcolContrct
      let contractstatus = data3.txtcolStatus

      if (contractstatus == "Pending" || contractstatus == "Voided" || contractstatus == "Cancelled" || contract_number == "" || contract_number == null || contract_number == undefined) { disable(thisObj, 'btnViewUpload') }
      else { enable(thisObj, 'btnViewUpload') }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        disable(thisObj, "btnViewUpload")
      }
      return false;
    }
    return true;
  }
  thisObj.ongridCntrctInqRowSelect = ongridCntrctInqRowSelect


  // const onbtnPrintSearchClick = () => {
  //   try {
  //     let tableData = getValue(thisObj, "gridCntrctInq")
  //     var expandedRows = false; // logic to get the expanded row checkbox data
  //     let status1 = thisObj.values.chkExp
  //     if (status1 == '1') {
  //       let mainstatus = thisObj.values.chkExpCollGri
  //       if (mainstatus == '1') { status1 = '2' }
  //       if (mainstatus == '2') { status1 = '3' }
  //     }
  //     if (status1 == '2') {
  //       expandedRows = true;
  //     }
  //     var columnArr = []
  //     let js = []
  //     for (var i = 0; i < tableData.length; i++) {
  //       var obj = [
  //         ['Contract #', tableData[i].txtcolContrct],
  //         ['Cont.Type', tableData[i].txtcolContTyp],
  //         ['Date', tableData[i].txtcolDate],
  //         ['Buying Point', tableData[i].txtcolByngPt],
  //         ['Vendor', tableData[i].txtcolVndr],
  //         ['Farm', tableData[i].txtcolFarm],
  //         ['Peanut Type', tableData[i].txtcolPnutType],
  //         ['Peanut Variety', tableData[i].txtcolPnutVrty],
  //         ['Gen', tableData[i].txtcolGen],
  //         ['Oleic', tableData[i].txtcolOleic],
  //         ['Seg', tableData[i].txtcolSeg],
  //         ['Status', tableData[i].txtcolStatus],
  //         ['Price', tableData[i].txtcolPrice],
  //         ['Contract Pounds', tableData[i].txtcolCntrctPounds],
  //         ['Tentative Applied', tableData[i].txtcolTntAppld],
  //         ['Total Application', tableData[i].txtcolTotlApp],
  //         ['Open', tableData[i].txtcolOpen],
  //         ['Dry Land Acres', tableData[i].txtcolDryLndAcr],
  //         ['Irrigated Acres', tableData[i].txtcolIrriAcr],
  //         ['Price Option', tableData[i].txtcolPriceOption],
  //         ['Max Option', tableData[i].txtcolMaxOption,],
  //         ['Election', tableData[i].txtcolElection],
  //         ['Locked Price', tableData[i].txtcolLockedPrice],
  //         ['Priced Lbs. Approved', tableData[i].txtcolPricLbsApp],
  //         ['Priced Lbs. Signed', tableData[i].txtcolPricedLbsSign],
  //         ['Priced Pounds', tableData[i].txtcolPricedPounds],
  //         ['Loan Lbs.Not Priced', tableData[i].txtcolLoanLbsNtPrc],
  //         ['Harvest Comp.Date', tableData[i].txtcolHarvestCompDate],
  //         ['Grp#', tableData[i].txtcolGrpNo],
  //         ['Grp%', tableData[i].txtcolGrpPercent],
  //         ['211 GPC', tableData[i].txtcol211gpc],
  //         ['211 Concordia', tableData[i].txtcol211concor],
  //         ['Minority Disclosure', tableData[i].txtcolMinDisc]
  //       ]
  //       js.push(obj)
  //       if (expandedRows) {
  //         obj = [
  //           ['EColumn1', tableData[i].expData[0].expandedcolumn1],
  //           ['EColumn2', tableData[i].expData[0].expandedcolumn2],
  //           ['EColumn3', tableData[i].expData[0].expandedcolumn3],
  //           ['EColumn4', tableData[i].expData[0].expandedcolumn4]
  //         ]

  //         if (tableData[i].txtcolContTyp == 'S') {
  //           if (tableData[i].txtcolGen == 'Multi...')
  //             if ('expandedcolumn5' in tableData[i].expData[0])
  //               for (let k = 0; k < tableData[i].expData.length; k++) {
  //                 obj.push(['EColumn5', tableData[i].expData[k].expandedcolumn5])
  //               }//obj.push(['EColumn5', tableData[i].expData[0].expandedcolumn5])
  //         }

  //         if (('M').includes(tableData[i].txtcolContTyp) || ('L').includes(tableData[i].txtcolContTyp)) {
  //           if ('expandedcolumn8' in tableData[i].expData[0])
  //             for (let k = 0; k < tableData[i].expData.length; k++) {
  //               obj.push(['EColumn8', tableData[i].expData[k].expandedcolumn8])
  //             }
  //         }

  //         if (tableData[i].txtcolContTyp == 'L') {
  //           if ('expandedcolumn6' in tableData[i].expData[0])
  //             for (let k = 0; k < tableData[i].expData.length; k++) {
  //               obj.push(['EColumn6', tableData[i].expData[k].expandedcolumn6])
  //             }
  //           if ('expandedcolumn7' in tableData[i].expData[0])
  //             for (let k = 0; k < tableData[i].expData.length; k++) {
  //               obj.push(['EColumn7', tableData[i].expData[k].expandedcolumn7])
  //             }
  //         }

  //         if (tableData[i].txtcolContTyp == 'M') {
  //           if ('expandedcolumn9' in tableData[i].expData[0])
  //             for (let k = 0; k < tableData[i].expData.length; k++) {
  //               obj.push(['EColumn9', tableData[i].expData[k].expandedcolumn9])
  //             }
  //         }

  //         if (('M').includes(tableData[i].txtcolContTyp) || ('L').includes(tableData[i].txtcolContTyp)) {
  //           if ('expandedcolumn10' in tableData[i].expData[0])
  //             for (let k = 0; k < tableData[i].expData.length; k++) {
  //               obj.push(['EColumn10', tableData[i].expData[k].expandedcolumn10])
  //             }
  //           if ('expandedcolumn11' in tableData[i].expData[0])
  //             for (let k = 0; k < tableData[i].expData.length; k++) {
  //               obj.push(['EColumn11', tableData[i].expData[k].expandedcolumn11])
  //             }
  //           if ('expandedcolumn12' in tableData[i].expData[0])
  //             for (let k = 0; k < tableData[i].expData.length; k++) {
  //               obj.push(['EColumn12', tableData[i].expData[k].expandedcolumn12])
  //             }
  //           if ('expandedcolumn13' in tableData[i].expData[0])
  //             for (let k = 0; k < tableData[i].expData.length; k++) {
  //               obj.push(['EColumn13', tableData[i].expData[k].expandedcolumn13])
  //             }
  //         }
  //         // if(tableData[i].txtcolContTyp == 'S'){
  //         //   for(var k =1; k<tableData[i].expData.length; k++){
  //         //     obj[3][1] += ", " + tableData[i].expData[k].expandedcolumn5 //js.push(['EColumn5', tableData[i].expData[k].expandedcolumn5])
  //         //   }
  //         // }
  //         js.push(obj)
  //       }
  //     }

  //     // creating column array for table.
  //     for (var i = 0; i < js.length; i++) {
  //       for (var j = 0; j < js[i].length; j++) {
  //         if (js[i][j][1] != undefined && js[i][j][1] != null) {
  //           if (js[i][j][1] != '') {
  //             columnArr.push(js[i][j][0])
  //           }
  //         }
  //       }
  //     }

  //     // removes the duplicate column values
  //     let res = []//""
  //     for (var i = 0; i < columnArr.length; i++) {
  //       if (!res.includes(columnArr[i])) {
  //         res.push(columnArr[i])
  //       }
  //     }
  //     var headerList = ['Contract #', 'Cont.Type', 'Date', 'Buying Point', 'Vendor', 'Farm', 'Peanut Type', 'Peanut Variety', 'Gen',
  //       'Oleic', 'Seg', 'Status', 'Price', 'Contract Pounds', 'Tentative Applied', 'Total Application', 'Open', 'Dry Land Acres',
  //       'Irrigated Acres', 'Price Option', 'Max Option', 'Election', 'Locked Price', 'Priced Lbs. Approved', 'Priced Lbs. Signed',
  //       'Priced Pounds', 'Loan Lbs.Not Priced', 'Harvest Comp.Date', 'Grp#', 'Grp%', '211 GPC', '211 Concordia', 'Minority Disclosure']
  //     var hData = []
  //     for (var i = 0; i < headerList.length; i++) {
  //       if (res.includes(headerList[i])) {
  //         hData.push(headerList[i])
  //       }
  //     }
  //     res = hData;

  //     columnArr = res
  //     var columnArr1 = columnArr.slice(0, 12)
  //     var columnArr2 = columnArr.slice(12)
  //     if (expandedRows) {
  //       columnArr2 = columnArr.slice(12, 12 + (columnArr.length - columnArr1.length - 4))
  //     }

  //     res = []
  //     var res1 = []
  //     for (var i = 0; i < js.length; i++) {
  //       let d = []
  //       let d1 = []
  //       for (var j = 0; j < js[i].length; j++) {
  //         if (columnArr.includes(js[i][j][0])) {
  //           if (js[i][j][0].trim() == 'Contract #') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Cont.Type') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Date') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Buying Point') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Vendor') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Farm') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Peanut Type') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Peanut Variety') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Gen') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Oleic') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Seg') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Status') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Price') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Contract Pounds') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Tentative Applied') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Total Application') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Open') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Dry Land Acres') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Irrigated Acres') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Price Option') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Max Option') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Election') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Locked Price') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Priced Lbs. Approved') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Priced Lbs. Signed') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Priced Pounds') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Loan Lbs.Not Priced') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Harvest Comp.Date') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Grp#') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Grp%') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == '211 GPC') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == '211 Concordia') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //           if (js[i][j][0].trim() == 'Minority Disclosure') {
  //             if (columnArr2.includes(js[i][j][0].trim())) {
  //               d1.push(js[i][j][1])
  //             }
  //             else {
  //               d.push(js[i][j][1])
  //             }
  //           }
  //         }
  //       }

  //       res.push(d)
  //       res1.push(d1)
  //       if (expandedRows) {
  //         i++;
  //         d = []
  //         d1 = []
  //         d.push(js[i][0][1], js[i][1][1], js[i][2][1], '', '', js[i][3][1])
  //         d1.push('', '', '', '', '', '')
  //         res.push(d)
  //         res1.push(d1)
  //       }
  //     }

  //     var doc = new jsPDF('landscape', 'mm', 'a4');
  //     for(var i=0; i<parseInt((res.length/26) +1); i++) {
  //       autoTable(doc, {
  //         startX: 2,
  //         startY: 2,
  //         theme: 'plain',
  //         headStyles: { fontSize: 8, cellWidth: 'wrap' },
  //         styles: { fontSize: 7, cellWidth: 'wrap', lineWidth: 0.2 },
  //         head: [columnArr1],
  //         body: res.slice(i*26, (i+1)*26)
  //       })
  //       doc.setFontSize(7)
  //       doc.text('Page ' + parseInt((i*2)+1) + ' of ' + parseInt(parseInt((res.length/26)+1)*2), 150, 200)
  //       doc.addPage()
  //       autoTable(doc, {
  //         startX: 2,
  //         startY: 3,
  //         theme: 'plain',
  //         headStyles: { fontSize: 8, cellWidth: 'wrap' },
  //         styles: { fontSize: 7, cellWidth: 'wrap', lineWidth: 0.2, Height: 0.4 },
  //         head: [columnArr2],
  //         body: res1.slice(i*26, (i+1)*26)
  //       })
  //       doc.setFontSize(7)
  //       doc.text('Page ' + parseInt((i*2)+2) + ' of ' + parseInt(parseInt((res.length/26)+1)*2), 150, 200)

  //       if(i <  parseInt(res.length/26)){
  //         doc.addPage()
  //       }
  //     }

  //     var date = new Date();
  //     const numArr = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59]

  //     var fileNameToProcess = date.getFullYear() + numArr[date.getMonth() + 1] + numArr[date.getDate()] + numArr[date.getHours()] +
  //       numArr[date.getMinutes()] + numArr[date.getSeconds()]
  //     doc.save('table_' + fileNameToProcess + '.pdf')
  //   }
  //   catch (err) {
  //     if (err instanceof EvalError) {
  //       showMessage(thisObj, err.message);
  //     } else {
  //       showMessage(thisObj, "error on print search")
  //     }
  //     return false;
  //   }
  //   return true;
  // }
  // thisObj.onbtnPrintSearchClick = onbtnPrintSearchClick;


  const onbtnPrintSearchClick = () => {
    try {
      let excelData = getValue(thisObj, "gridCntrctInq")
      let status1 = thisObj.values.chkExp
      if (status1 == '1') {
        let mainstatus = thisObj.values.chkExpCollGri
        if (mainstatus == '1') { status1 = '2' }
        if (mainstatus == '2') { status1 = '3' }
      }
      let headerarray = []
      let headerarray1 = []
      let headerarray2 = []
      let headerarray3 = []

      if (thisObj.state.gridCntrctInq.columns[0].Visible) {
        headerarray.push("contract #")
      }
      if (thisObj.state.gridCntrctInq.columns[1].Visible) {
        headerarray.push("Cont. Type")
      }
      if (thisObj.state.gridCntrctInq.columns[2].Visible) {
        headerarray.push("Date")
      }
      if (thisObj.state.gridCntrctInq.columns[3].Visible) {
        headerarray.push("Buying Point")
      }
      if (thisObj.state.gridCntrctInq.columns[4].Visible) {
        headerarray.push("Vendor")
      }
      if (thisObj.state.gridCntrctInq.columns[5].Visible) {
        headerarray.push("Farm")
      }
      if (thisObj.state.gridCntrctInq.columns[6].Visible) {
        headerarray.push("Peanut Type")
      }
      if (thisObj.state.gridCntrctInq.columns[7].Visible) {
        headerarray.push("Peanut Variety")
      }
      if (thisObj.state.gridCntrctInq.columns[8].Visible) {
        headerarray.push("Gen")
      }
      if (thisObj.state.gridCntrctInq.columns[9].Visible) {
        headerarray.push("Oleic")
      }
      if (thisObj.state.gridCntrctInq.columns[10].Visible) {
        headerarray.push("Seg")
      }
      if (thisObj.state.gridCntrctInq.columns[11].Visible) {
        headerarray.push("Status")
      }
      if (thisObj.state.gridCntrctInq.columns[12].Visible) {
        headerarray.push("Price")
      }
      if (thisObj.state.gridCntrctInq.columns[13].Visible) {
        headerarray.push("Contract Pounds")
      }
      if (thisObj.state.gridCntrctInq.columns[14].Visible) {
        headerarray.push("Applied Pounds")
      }
      if (thisObj.state.gridCntrctInq.columns[15].Visible) {
        headerarray.push("Tentative Applied")
      }
      if (thisObj.state.gridCntrctInq.columns[16].Visible) {
        headerarray.push("Total Application")
      }
      if (thisObj.state.gridCntrctInq.columns[17].Visible) {
        headerarray.push("Open")
      }
      if (thisObj.state.gridCntrctInq.columns[18].Visible) {
        headerarray.push("Dry Land Acres")
      }
      if (thisObj.state.gridCntrctInq.columns[19].Visible) {
        headerarray.push("Irrigated Acres")
      }
      if (thisObj.state.gridCntrctInq.columns[20].Visible) {
        headerarray.push("Price Option")
      }
      if (thisObj.state.gridCntrctInq.columns[21].Visible) {
        headerarray.push("Max Option")
      }
      if (thisObj.state.gridCntrctInq.columns[22].Visible) {
        headerarray.push("Election")
      }
      if (thisObj.state.gridCntrctInq.columns[23].Visible) {
        headerarray.push("Locked Price")
      }
      if (thisObj.state.gridCntrctInq.columns[24].Visible) {
        headerarray.push("Price Lbs Approved")
      }
      if (thisObj.state.gridCntrctInq.columns[25].Visible) {
        headerarray.push("Price lbs Signed")
      }
      if (thisObj.state.gridCntrctInq.columns[26].Visible) {
        headerarray.push("priced pounds")
      }
      if (thisObj.state.gridCntrctInq.columns[27].Visible) {
        headerarray.push("Loan Lbs Not Priced")
      }
      if (thisObj.state.gridCntrctInq.columns[28].Visible) {
        headerarray.push("Harvest Comp. Date")
      }
      if (thisObj.state.gridCntrctInq.columns[29].Visible) {
        headerarray.push("Grp#")
      }
      if (thisObj.state.gridCntrctInq.columns[30].Visible) {
        headerarray.push("Grp%")
      }
      if (thisObj.state.gridCntrctInq.columns[31].Visible) {
        headerarray.push("211 GPC")
      }
      if (thisObj.state.gridCntrctInq.columns[32].Visible) {
        headerarray.push("211 Concordia")
      }
      if (thisObj.state.gridCntrctInq.columns[33].Visible) {
        headerarray.push("Minority Disclosure")
      }

      if (headerarray.length > 13) {
        headerarray1 = headerarray.slice(0, 12)
        if (headerarray.length > 25) {
          headerarray2 = headerarray.slice(12, 24)
          headerarray3 = headerarray.slice(24)
        }
        else {
          headerarray2 = headerarray.slice(12)
        }
      }

      let bodyarray2 = []
      let bodyarray3 = []
      let bodyarray4 = []
      let bodyarray5 = []



      for (var i = 0; i < excelData.length; i++) {
        let bodyarray = []
        if (thisObj.state.gridCntrctInq.columns[0].Visible) {
          bodyarray.push(excelData[i].txtcolContrct)
        }
        if (thisObj.state.gridCntrctInq.columns[1].Visible) {
          bodyarray.push(excelData[i].txtcolContTyp)
        }
        if (thisObj.state.gridCntrctInq.columns[2].Visible) {
          bodyarray.push(excelData[i].txtcolDate)
        }
        if (thisObj.state.gridCntrctInq.columns[3].Visible) {
          bodyarray.push(excelData[i].txtcolByngPt)
        }
        if (thisObj.state.gridCntrctInq.columns[4].Visible) {
          bodyarray.push(excelData[i].txtcolVndr)
        }
        if (thisObj.state.gridCntrctInq.columns[5].Visible) {
          bodyarray.push(excelData[i].txtcolFarm)
        }
        if (thisObj.state.gridCntrctInq.columns[6].Visible) {
          bodyarray.push(excelData[i].txtcolPnutType)
        }
        if (thisObj.state.gridCntrctInq.columns[7].Visible) {
          bodyarray.push(excelData[i].txtcolPnutVrty)
        }
        if (thisObj.state.gridCntrctInq.columns[8].Visible) {
          bodyarray.push(excelData[i].txtcolGen)
        }
        if (thisObj.state.gridCntrctInq.columns[9].Visible) {
          bodyarray.push(excelData[i].txtcolOleic)
        }
        if (thisObj.state.gridCntrctInq.columns[10].Visible) {
          bodyarray.push(excelData[i].txtcolSeg)
        }
        if (thisObj.state.gridCntrctInq.columns[11].Visible) {
          bodyarray.push(excelData[i].txtcolStatus)
        }
        if (thisObj.state.gridCntrctInq.columns[12].Visible) {
          bodyarray.push(excelData[i].txtcolPrice)
        }
        if (thisObj.state.gridCntrctInq.columns[13].Visible) {
          bodyarray.push(excelData[i].txtcolCntrctPounds)
        }
        if (thisObj.state.gridCntrctInq.columns[14].Visible) {
          bodyarray.push(excelData[i].txtcolApplPounds)
        }
        if (thisObj.state.gridCntrctInq.columns[15].Visible) {
          bodyarray.push(excelData[i].txtcolTntAppld)
        }
        if (thisObj.state.gridCntrctInq.columns[16].Visible) {
          bodyarray.push(excelData[i].txtcolTotlApp)
        }
        if (thisObj.state.gridCntrctInq.columns[17].Visible) {
          bodyarray.push(excelData[i].txtcolOpen)
        }
        if (thisObj.state.gridCntrctInq.columns[18].Visible) {
          bodyarray.push(excelData[i].txtcolDryLndAcr)
        }
        if (thisObj.state.gridCntrctInq.columns[19].Visible) {
          bodyarray.push(excelData[i].txtcolIrriAcr)
        }
        if (thisObj.state.gridCntrctInq.columns[20].Visible) {
          bodyarray.push(excelData[i].txtcolPriceOption)
        }
        if (thisObj.state.gridCntrctInq.columns[21].Visible) {
          bodyarray.push(excelData[i].txtcolMaxOption)
        }
        if (thisObj.state.gridCntrctInq.columns[22].Visible) {
          bodyarray.push(excelData[i].txtcolElection)
        }
        if (thisObj.state.gridCntrctInq.columns[23].Visible) {
          bodyarray.push(excelData[i].txtcolLockedPrice)
        }
        if (thisObj.state.gridCntrctInq.columns[24].Visible) {
          bodyarray.push(excelData[i].txtcolPricLbsApp)
        }
        if (thisObj.state.gridCntrctInq.columns[25].Visible) {
          bodyarray.push(excelData[i].txtcolPricedLbsSign)
        }
        if (thisObj.state.gridCntrctInq.columns[26].Visible) {
          bodyarray.push(excelData[i].txtcolPricedPounds)
        }
        if (thisObj.state.gridCntrctInq.columns[27].Visible) {
          bodyarray.push(excelData[i].txtcolLoanLbsNtPrc)
        }
        if (thisObj.state.gridCntrctInq.columns[28].Visible) {
          bodyarray.push(excelData[i].txtcolHarvestCompDate)
        }
        if (thisObj.state.gridCntrctInq.columns[29].Visible) {
          bodyarray.push(excelData[i].txtcolGrpNo)
        }
        if (thisObj.state.gridCntrctInq.columns[30].Visible) {
          bodyarray.push(excelData[i].txtcolGrpPercent)
        }
        if (thisObj.state.gridCntrctInq.columns[31].Visible) {
          bodyarray.push(excelData[i].txtcol211gpc)
        }
        if (thisObj.state.gridCntrctInq.columns[32].Visible) {
          bodyarray.push(excelData[i].txtcol211concor)
        }
        if (thisObj.state.gridCntrctInq.columns[33].Visible) {
          bodyarray.push(excelData[i].txtcolMinDisc)
        }
        bodyarray2.push(bodyarray)
        if (bodyarray.length > 13) {
          bodyarray3.push(bodyarray.slice(0, 12))
          if (bodyarray.length > 25) {
            bodyarray4.push(bodyarray.slice(12, 24))
            bodyarray5.push(bodyarray.slice(24))
          }
          else {
            bodyarray4.push(bodyarray.slice(12))
          }
        }

        if (status1 == 2) {
          for (let j = 0; j < excelData[i].expData.length; j++) {
            let extendedbodyarray = []
            if (thisObj.state.gridCntrctInq.columns[0].Visible) {
              extendedbodyarray.push(excelData[i].expData[j].expandedcolumn1)
            }
            if (thisObj.state.gridCntrctInq.columns[1].Visible) {
              extendedbodyarray.push(excelData[i].expData[j].expandedcolumn2)
            }
            if (thisObj.state.gridCntrctInq.columns[2].Visible) {
              extendedbodyarray.push(excelData[i].expData[j].expandedcolumn3)
            }
            if (thisObj.state.gridCntrctInq.columns[3].Visible) {
              extendedbodyarray.push("")
            }
            if (thisObj.state.gridCntrctInq.columns[4].Visible) {
              extendedbodyarray.push("")
            }
            if (thisObj.state.gridCntrctInq.columns[5].Visible) {
              extendedbodyarray.push("")
            }
            if (thisObj.state.gridCntrctInq.columns[6].Visible) {
              extendedbodyarray.push(excelData[i].expData[j].expandedcolumn4)
            }
            if (thisObj.state.gridCntrctInq.columns[7].Visible) {
              extendedbodyarray.push("")
            }
            if (thisObj.state.gridCntrctInq.columns[8].Visible) {
              extendedbodyarray.push(excelData[i].expData[j].expandedcolumn5)
            }
            if (thisObj.state.gridCntrctInq.columns[9].Visible) {
              extendedbodyarray.push("")
            }
            if (thisObj.state.gridCntrctInq.columns[10].Visible) {
              extendedbodyarray.push("")
            }
            if (thisObj.state.gridCntrctInq.columns[11].Visible) {
              extendedbodyarray.push("")
            }
            if (thisObj.state.gridCntrctInq.columns[12].Visible) {
              extendedbodyarray.push("")
            }
            if (thisObj.state.gridCntrctInq.columns[13].Visible) {
              extendedbodyarray.push("")
            }
            if (thisObj.state.gridCntrctInq.columns[14].Visible) {
              extendedbodyarray.push("")
            }
            if (thisObj.state.gridCntrctInq.columns[15].Visible) {
              extendedbodyarray.push("")
            }
            if (thisObj.state.gridCntrctInq.columns[16].Visible) {
              extendedbodyarray.push("")
            }
            if (thisObj.state.gridCntrctInq.columns[17].Visible) {
              extendedbodyarray.push("")
            }
            if (thisObj.state.gridCntrctInq.columns[18].Visible) {
              extendedbodyarray.push("")
            }
            if (thisObj.state.gridCntrctInq.columns[19].Visible) {
              extendedbodyarray.push("")
            }
            if (thisObj.state.gridCntrctInq.columns[20].Visible) {
              extendedbodyarray.push(excelData[i].expData[j].expandedcolumn6)
            }
            if (thisObj.state.gridCntrctInq.columns[21].Visible) {
              extendedbodyarray.push(excelData[i].expData[j].expandedcolumn7)
            }
            if (thisObj.state.gridCntrctInq.columns[22].Visible) {
              extendedbodyarray.push(excelData[i].expData[j].expandedcolumn8)
            }
            if (thisObj.state.gridCntrctInq.columns[23].Visible) {
              extendedbodyarray.push(excelData[i].expData[j].expandedcolumn9)
            }
            if (thisObj.state.gridCntrctInq.columns[24].Visible) {
              extendedbodyarray.push(excelData[i].expData[j].expandedcolumn10)
            }
            if (thisObj.state.gridCntrctInq.columns[25].Visible) {
              extendedbodyarray.push(excelData[i].expData[j].expandedcolumn11)
            }
            if (thisObj.state.gridCntrctInq.columns[26].Visible) {
              extendedbodyarray.push(excelData[i].expData[j].expandedcolumn12)
            }
            if (thisObj.state.gridCntrctInq.columns[27].Visible) {
              extendedbodyarray.push(excelData[i].expData[j].expandedcolumn13)
            }
            if (thisObj.state.gridCntrctInq.columns[28].Visible) {
              extendedbodyarray.push("")
            }
            if (thisObj.state.gridCntrctInq.columns[29].Visible) {
              extendedbodyarray.push("")
            }
            if (thisObj.state.gridCntrctInq.columns[30].Visible) {
              extendedbodyarray.push("")
            }
            if (thisObj.state.gridCntrctInq.columns[31].Visible) {
              extendedbodyarray.push("")
            }
            if (thisObj.state.gridCntrctInq.columns[32].Visible) {
              extendedbodyarray.push("")
            }
            if (thisObj.state.gridCntrctInq.columns[33].Visible) {
              extendedbodyarray.push("")
            }
            bodyarray2.push(extendedbodyarray)
            if (extendedbodyarray.length > 13) {
              bodyarray3.push(extendedbodyarray.slice(0, 12))
              if (extendedbodyarray.length > 25) {
                bodyarray4.push(extendedbodyarray.slice(12, 24))
                bodyarray5.push(extendedbodyarray.slice(24))
              }
              else {
                bodyarray4.push(extendedbodyarray.slice(12))
              }
            }
          }
        }
      }

      var obj1 = new jsPDF('landscape', 'mm', 'a4');
      var obj2 = new jsPDF('landscape', 'mm', 'a4');
      let pagelimit = 0

      if (headerarray.length <= 13) {
        pagelimit = 1
        obj1.autoTable({
          //startY: 5,
          //startX: 20,
          headStyles: { fontSize: 8, cellWidth: 'wrap' },
          styles: { fontSize: 7, lineWidth: 0.2, color: 10 },
          theme: 'plain',
          fontStyle: 'normal',
          head: [headerarray],
          body: bodyarray2
        });
      }
      else {
        if ((13 < headerarray.length) && (headerarray.length < 25)) {
          pagelimit = 2
          obj1.autoTable({
            //startY: 5,
            //startX: 20,
            theme: 'plain',
            headStyles: { fontSize: 8, cellWidth: 'wrap' },
            styles: { fontSize: 7, cellWidth: 'wrap', lineWidth: 0.2 },
            head: [headerarray1],
            body: bodyarray3
          })
          obj1.addPage()
          obj1.autoTable({
            //startY: 5,
            //startX: 20,
            theme: 'plain',
            headStyles: { fontSize: 8, cellWidth: 'wrap' },
            styles: { fontSize: 7, cellWidth: 'wrap', lineWidth: 0.2, Height: 0.4 },
            head: [headerarray2],
            body: bodyarray4
          })
        }
        else {
          pagelimit = 3
          obj1.autoTable({
            //startY: 5,
            //startX: 20,
            theme: 'plain',
            headStyles: { fontSize: 8, cellWidth: 'wrap' },
            styles: { fontSize: 7, cellWidth: 'wrap', lineWidth: 0.2 },
            head: [headerarray1],
            body: bodyarray3
          })
          obj1.addPage()
          obj1.autoTable({
            //startY: 5,
            //startX: 20,
            theme: 'plain',
            headStyles: { fontSize: 8, cellWidth: 'wrap' },
            styles: { fontSize: 7, cellWidth: 'wrap', lineWidth: 0.2, Height: 0.4 },
            head: [headerarray2],
            body: bodyarray4
          })

          obj1.addPage()
          obj1.autoTable({
            //startY: 5,
            //startX: 20,
            theme: 'plain',
            headStyles: { fontSize: 8, cellWidth: 'wrap' },
            styles: { fontSize: 7, cellWidth: 'wrap', lineWidth: 0.2, Height: 0.4 },
            head: [headerarray3],
            body: bodyarray5
          })
        }
      }

      //Rearrange the page order
      var pageCount = obj1.internal.getNumberOfPages();
      if (pagelimit == 2) {
        for (let j = 1, k = (pageCount / 2) + 1; j <= (pageCount / 2); j++, k++) {
          obj1.movePage(k, j * 2)
        }
      }
      if (pagelimit == 3 && pageCount > 3) {
        if (pageCount > 3 && pageCount <= 6) {
          obj1.movePage(3, 2)
          obj1.movePage(5, 3)
        }
        if (pageCount > 6 && pageCount <= 9) {
          obj1.movePage(4, 2)
          obj1.movePage(7, 3)
          obj1.movePage(6, 5)
          obj1.movePage(8, 6)
        }
        if (pageCount > 9 && pageCount <=12) {
          obj1.movePage(5, 2)
          obj1.movePage(9, 3)
          obj1.movePage(7, 5)
          obj1.movePage(10, 6)
          obj1.movePage(9, 8)
          obj1.movePage(11, 9)
        }
        if (pageCount > 12 && pageCount <=15) {
          obj1.movePage(6, 2)
          obj1.movePage(11, 3)
          obj1.movePage(8, 5)
          obj1.movePage(12, 6)
          obj1.movePage(10, 8)
          obj1.movePage(13, 9)
          obj1.movePage(12, 10)
          obj1.movePage(14, 11)
        }
        
      }

      for (let w = 0; w <= pageCount; w++) {
        obj1.setPage(w);
        let pageCurrent = obj1.internal.getCurrentPageInfo().pageNumber; //Current Page
        obj1.setFontSize(7);
        obj1.text('page: ' + pageCurrent + '/' + pageCount, 10, obj1.internal.pageSize.height - 10);
      }
      // Save or print the document
      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj1.save(fileName + '.pdf')
        }
        else {
          showMessage(thisObj, "Please enter the file name!", false)
        }
      }
      else {
        alert("Please try different file name", false)
      }

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error on print search button")
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnPrintSearchClick = onbtnPrintSearchClick

  const validateTextBox = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) {
        res += data[i]
      }
    }
    return res;
  }
  const validateTextBox1 = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 46)) {
        res += data[i]
      }
    }
    return res;
  }
  const validateTextBox2 = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      // if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) >= 65 && data[i].charCodeAt(0) <= 90) || (data[i].charCodeAt(0) >= 97 && data[i].charCodeAt(0) <= 112)) {
      //  INC5074771	
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) >= 65 && data[i].charCodeAt(0) <= 90) || (data[i].charCodeAt(0) >= 97 && data[i].charCodeAt(0) <= 122)) {
      res += data[i]
      }
    }
    return res;
  }

  const ontxtContractChange = () => {
    try {
      setValue(thisObj, "txtContract", validateTextBox(txtContract.value))
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtContract change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtContractChange = ontxtContractChange;

  const ontxtGroupChange = () => {
    try {
      setValue(thisObj, "txtGroup", validateTextBox(txtGroup.value))
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtGroup change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtGroupChange = ontxtGroupChange;

  const ontxtPriceChange = () => {
    try {
      setValue(thisObj, "txtPrice", validateTextBox1(txtPrice.value))
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtPrice change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtPriceChange = ontxtPriceChange;


  //converting date into dd/mm/yyyy formt
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  function formatString(str) {
    // Check if string contains a number
    if (!isNaN(str)) {
      // If yes, format string with commas
      return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      // If no, leave string as it is
      return str;
    }
  }


  //searching contract
  const onbtnSearchClick = () => {
    try {

      let func_id = 'PN1020'
      let func_sub_id = null
      let contract_num = txtContract.value
      let gen_produced = thisObj.values.drpGeneration
      let split_vendor = txtVendor.value
      let cont_status = thisObj.values.drpStatus
      let cont_type = thisObj.values.drpContractType
      let buy_pt_id = thisObj.values.drpBuyingPoint
      let state_abbr = null
      let county_id = null
      let farm_id = null
      let pnut_type_id = thisObj.values.drpType
      let pnut_variety_id = thisObj.values.drpVariety
      let seg_type = thisObj.values.drpSeg
      let seed_grower = thisObj.values.drpSeedGrower
      let oleic_ind = thisObj.values.drpOleic
      let organic_ind = thisObj.values.drpOragnic
      let beg_cont_date = null
      if (thisObj.values.dtContractDate !== undefined) {
        beg_cont_date = convert(getValue(thisObj, "dtContractDate")['_d'])
        if (beg_cont_date == "aN/aN/NaN") {
          beg_cont_date = null
        }
      }
      let end_cont_date = null
      if (thisObj.values.dtThru !== undefined) {
        end_cont_date = convert(getValue(thisObj, "dtThru")['_d'])
        if (end_cont_date == "aN/aN/NaN") {
          end_cont_date = null
        }
      }

      let grouping_num = txtGroup.value
      let disclosure = thisObj.values.drpDisclosure
      let searchprice = txtPrice.value

      if (searchprice != "" && (!searchprice.includes('.'))) {
        searchprice = searchprice + ".00"
        setValue(thisObj, 'txtPrice', searchprice);
      }

      let percentage = txtPrcnt.value

      let val3 = thisObj.values['drpApplied']

      if ((contract_num == null || contract_num == undefined || contract_num == "") &&
        (split_vendor == null || split_vendor == undefined || split_vendor == "") &&
        (buy_pt_id == null || buy_pt_id == undefined || buy_pt_id == "")) {
        showMessage(thisObj, "Contract# or Vendor# must be specified for searches across all buying points")
      }
      //else if (val3 == 'L' || val3 == 'G') 

      else if (val3 == 'L' && (percentage == null || percentage == undefined || percentage == "")) {
        showMessage(thisObj, "You must enter a Less Than percentage amount \n Invalid Search Criteria")
      }
      else if (val3 == 'G' && (percentage == null || percentage == undefined || percentage == "")) {
        showMessage(thisObj, "You must enter a Greater Than percentage amount \n Invalid Search Criteria")
      }

      else {
        setLoading(true);
        ContractManagementService.RetriveContractList(func_id, func_sub_id, contract_num, gen_produced,
          split_vendor, cont_status, cont_type, buy_pt_id, state_abbr, county_id, farm_id, pnut_type_id, pnut_variety_id,
          seg_type, seed_grower, oleic_ind, organic_ind, beg_cont_date, end_cont_date, grouping_num, disclosure)
          .then(response => {
            let data = response
            if (data == undefined || data[0] == undefined) {
              showMessage(thisObj, "No Contracts Founds!!")
              let js14 = []
              setValue(thisObj, "gridCntrctInq", js14)
              setValue(thisObj, "txtContractPounds", "0")
              setValue(thisObj, "txtAppliedPounds", "0")
              setValue(thisObj, "txtTentativeApplied", "0")
              setValue(thisObj, "txtTotalApp", "0")
              setValue(thisObj, "txtOpen", "0")
              setValue(thisObj, "txtDryLandAcres", "0")
              setValue(thisObj, "txtIrrigatedAcres", "0")
            } else {

              let js15 = []
              //let expArr = [];

              for (let i = 0; i < data.length; i++) {
                let obj = {}

                let ContLbs = data[i].cont_lbs
                let ApplLbs = data[i].total_appl

                if (ContLbs == '' || ContLbs == null) {
                  ContLbs = 0
                }
                if (ApplLbs == '' || ApplLbs == null) {
                  ApplLbs = 0
                }

                let newcontlb = 1

                let tempstatus = cont_status
                if (tempstatus == "X") { tempstatus = "V" }
                if (ContLbs > 0) { newcontlb = ContLbs }
                if (percentage == "") { percentage = 0 }

                if (
                  ((cont_status == "" || cont_status == undefined) || ((cont_status !== "" && cont_status !== undefined) && tempstatus == data[i].cont_status)) &&
                  ((searchprice == "") || ((searchprice !== "") && (searchprice == data[i].price_per_ton))) &&
                  ((val3 == "") || ((val3 == "N") && (ContLbs > 0) && (ApplLbs == 0)) || ((val3 == "L") && (ContLbs > 0) && (((ApplLbs / newcontlb) * 100) < percentage)) || ((val3 == "G") && (ContLbs > 0) && (((ApplLbs / newcontlb) * 100) > percentage)) || ((val3 == "C") && (ContLbs > 0) && ((ContLbs - ApplLbs) <= 0)))
                ) {
                  // if(data[i].buy_pt_name!==null)
                  //   obj.expandedcolumn1=data[i].buy_pt_name
                  // if(data[i].physical_city!==null)
                  //   obj.expandedcolumn2=data[i].physical_city
                  // if(data[i].physical_state!==null)
                  //   obj.expandedcolumn3=data[i].physical_state
                  // if(data[i].pnut_type_name!==null)
                  //   obj.expandedcolumn4=data[i].pnut_type_name

                  if (data[i].contract_num !== null)
                    obj.txtcolContrct = data[i].contract_num
                  if (data[i].cont_type !== null)
                    obj.txtcolContTyp = data[i].cont_type
                  if (data[i].cont_date !== null)
                    obj.txtcolDate = data[i].cont_date.slice(0, data[i].cont_date.indexOf(" "))
                  if (data[i].buy_pt_id !== null)
                    obj.txtcolByngPt = data[i].buy_pt_id
                  obj.txtcolVndr = data[i].split_vendor + "-" + data[i].split_vendor_name
                  if (data[i].farm_id !== null)
                    obj.txtcolFarm = data[i].farm_id
                  if (data[i].pnut_type_id !== null)
                    obj.txtcolPnutType = data[i].pnut_type_id
                  //obj.txtcolPnutVrty=data[i].seed_grower_pnut_variety_id+"-"+data[i].seed_grower_gen_produced
                  if (data[i].pnut_variety_id !== null)
                    obj.txtcolPnutVrty = data[i].pnut_variety_id
                  if (data[i].gen_produced !== null)
                    obj.txtcolGen = data[i].gen_produced
                  if (data[i].oleic_ind !== null)
                    obj.txtcolOleic = data[i].oleic_ind
                  if (data[i].seg_type !== null)
                    obj.txtcolSeg = data[i].seg_type

                  switch (data[i].cont_status.trim()) {
                    case "P":
                      obj.txtcolStatus = "Pending"
                      break;
                    case "V":
                      if (data[i].auto_cancel_date !== "") {
                        obj.txtcolStatus = "Cancelled"
                      }
                      else {
                        obj.txtcolStatus = "Voided"
                      }
                      break;
                    case "A":
                      obj.txtcolStatus = "Approved"
                      break;
                    case "S":
                      obj.txtcolStatus = "Signed"
                      break;
                    case "C":
                      obj.txtcolStatus = "Closed"
                      break;

                    default: null
                      break;
                  }

                  if (data[i].price_per_ton !== null)
                    obj.txtcolPrice = formatString(data[i].price_per_ton)
                  if (data[i].cont_lbs !== null)
                    obj.txtcolCntrctPounds = formatString(data[i].cont_lbs)
                  if (data[i].appl_lbs !== null)
                    obj.txtcolApplPounds = formatString(data[i].appl_lbs)
                  if (data[i].tent_lbs !== null)
                    obj.txtcolTntAppld = formatString(data[i].tent_lbs)
                  if (data[i].total_appl !== null)
                    obj.txtcolTotlApp = formatString(data[i].total_appl)
                  if (data[i].tentative_open_lbs !== null)
                    obj.txtcolOpen = formatString(data[i].tentative_open_lbs)
                  if (data[i].dry_land_acres !== null)
                    obj.txtcolDryLndAcr = formatString(data[i].dry_land_acres)
                  if (data[i].irrigated_acres !== null)
                    obj.txtcolIrriAcr = formatString(data[i].irrigated_acres)

                  if (data[i].cont_type == "L") {
                    if (data[i].election.length > 0) {
                      if (data[i].election[0].type !== null)
                        obj.txtcolElection = data[i].election[0].type
                      if (data[i].election[0].pay_rebate !== null)
                        obj.txtcolPriceOption = formatString(data[i].election[0].pay_rebate)
                      if (data[i].election[0].max_option !== null)
                        obj.txtcolMaxOption = formatString(data[i].election[0].max_option)
                      if (data[i].election[0].approved_lbs !== null)
                        obj.txtcolPricLbsApp = formatString(data[i].election[0].approved_lbs)
                      if (data[i].election[0].signed_lbs !== null)
                        obj.txtcolPricedLbsSign = formatString(data[i].election[0].signed_lbs)
                      if (data[i].election[0].pending_lbs !== null)
                        obj.txtcolPricedPounds = formatString(data[i].election[0].pending_lbs)
                      if (data[i].election[0].loan_lbs_not_priced !== null)
                        obj.txtcolLoanLbsNtPrc = formatString(data[i].election[0].loan_lbs_not_priced)
                    }
                  }
                  else {
                    if (data[i].election.length > 0) {
                      if (data[i].election[0].type !== null)
                        obj.txtcolElection = data[i].election[0].type
                      if (data[i].election[0].locked_price !== null)
                        obj.txtcolLockedPrice = formatString(data[i].election[0].locked_price)
                      if (data[i].election[0].approved_lbs !== null)
                        obj.txtcolPricLbsApp = formatString(data[i].election[0].approved_lbs)
                      if (data[i].election[0].signed_lbs !== null)
                        obj.txtcolPricedLbsSign = formatString(data[i].election[0].signed_lbs)
                      if (data[i].election[0].pending_lbs !== null)
                        obj.txtcolPricedPounds = formatString(data[i].election[0].pending_lbs)
                      if (data[i].election[0].loan_lbs_not_priced !== null)
                        obj.txtcolLoanLbsNtPrc = formatString(data[i].election[0].loan_lbs_not_priced)
                    }
                  }

                  if (data[i].harvest_complete_date !== null)
                    obj.txtcolHarvestCompDate = data[i].harvest_complete_date.slice(0, data[i].harvest_complete_date.indexOf(" "))
                  if (data[i].grouping_num !== null)
                    obj.txtcolGrpNo = data[i].grouping_num
                  if (data[i].grouping_share !== null)
                    obj.txtcolGrpPercent = data[i].grouping_share
                  if (data[i].form211_gpc !== null)
                    obj.txtcol211gpc = data[i].form211_gpc
                  if (data[i].form211_con !== null)
                    obj.txtcol211concor = data[i].form211_con

                  switch (data[i].disclosure) {
                    case "C":
                      obj.txtcolMinDisc = "Completed"
                      break;
                    case "I":
                      obj.txtcolMinDisc = "Incomplete"
                      break;
                    case "N":
                      obj.txtcolMinDisc = "Not Disclosed"
                      break;

                    default: null
                      break;
                  }

                  let sSGPeanutVarietyID = data[i].seed_grower_pnut_variety_id
                  let sSGPeanutVarietyName = data[i].seed_grower_pnut_variety_name
                  let sSGPeanutVarietySymbol = data[i].seed_grower_symbol_ind
                  let sSGGenProd = data[i].seed_grower_gen_produced

                  let expArr = [];
                  if (sSGPeanutVarietyID !== null && sSGPeanutVarietyID !== undefined && sSGPeanutVarietyID !== "") {
                    if (!(sSGPeanutVarietyID.includes("|"))) {
                      if (sSGPeanutVarietyID !== null && sSGPeanutVarietyID !== undefined) {
                        obj.txtcolGen = sSGPeanutVarietyID + "-" + sSGGenProd
                        let expObjSing = {};
                        if (data[i].buy_pt_name !== null)
                          expObjSing.expandedcolumn1 = data[i].buy_pt_name
                        if (data[i].physical_city !== null)
                          expObjSing.expandedcolumn2 = data[i].physical_city
                        if (data[i].physical_state !== null)
                          expObjSing.expandedcolumn3 = data[i].physical_state
                        if (data[i].pnut_type_name !== null)
                          expObjSing.expandedcolumn4 = data[i].pnut_type_name
                        expArr.push(expObjSing);
                        obj.expData = expArr;
                      }
                    }
                    else {
                      let arrSGPeanutVarietyID = sSGPeanutVarietyID.split("|")
                      let arrSGPeanutVarietyName = sSGPeanutVarietyName.split("|")
                      let arrSGPeanutVarietySymbol = sSGPeanutVarietySymbol.split("|")
                      let arrSGGenProd = sSGGenProd.split("|")

                      if (arrSGPeanutVarietyID.length > 0) {
                        let lengthofvariety = arrSGPeanutVarietyID.length
                        // let obj={}
                        // let expandedcolumn5=[]
                        obj.txtcolGen = "Multi...";
                        for (let j = 0; j < lengthofvariety; j++) { // binding for seed contact with multiple variety
                          let expobj = {}
                          if (j == 0) {
                            if (data[i].buy_pt_name !== null)
                              expobj.expandedcolumn1 = data[i].buy_pt_name
                            if (data[i].physical_city !== null)
                              expobj.expandedcolumn2 = data[i].physical_city
                            if (data[i].physical_state !== null)
                              expobj.expandedcolumn3 = data[i].physical_state
                            if (data[i].pnut_type_name !== null)
                              expobj.expandedcolumn4 = data[i].pnut_type_name
                          }
                          if (arrSGPeanutVarietyID[j].length > 0)
                            expobj.expandedcolumn5 = arrSGPeanutVarietyID[j] + '-' + arrSGGenProd[j]
                          expArr.push(expobj);
                        }
                        obj.expData = expArr;
                      }
                    }
                  }
                  else {

                    if (data[i].election.length > 1) { //binding for flexmarket and floor contracts
                      for (let j = 1; j < data[i].election.length; j++) {
                        let expobj = {}
                        if (j == 1) {
                          if (data[i].buy_pt_name !== null)
                            expobj.expandedcolumn1 = data[i].buy_pt_name
                          if (data[i].physical_city !== null)
                            expobj.expandedcolumn2 = data[i].physical_city
                          if (data[i].physical_state !== null)
                            expobj.expandedcolumn3 = data[i].physical_state
                          if (data[i].pnut_type_name !== null)
                            expobj.expandedcolumn4 = data[i].pnut_type_name
                        }
                        //biding of remaining rows in flexmarket and floor contracts
                        //extendedcolumn 9 is not there in floor contract
                        if (data[i].cont_type == "L") {
                          if (data[i].election[j].type !== null)
                            expobj.expandedcolumn8 = data[i].election[j].type
                          if (data[i].election[j].pay_rebate !== null)
                            expobj.expandedcolumn6 = formatString(data[i].election[j].pay_rebate)
                          if (data[i].election[j].max_option !== null)
                            expobj.expandedcolumn7 = formatString(data[i].election[j].max_option)
                          if (data[i].election[j].approved_lbs !== null)
                            expobj.expandedcolumn10 = formatString(data[i].election[j].approved_lbs)
                          if (data[i].election[j].signed_lbs !== null)
                            expobj.expandedcolumn11 = formatString(data[i].election[j].signed_lbs)
                          if (data[i].election[j].pending_lbs !== null)
                            expobj.expandedcolumn12 = formatString(data[i].election[j].pending_lbs)
                          if (data[i].election[j].loan_lbs_not_priced !== null)
                            expobj.expandedcolumn13 = formatString(data[i].election[j].loan_lbs_not_priced)
                        }
                        else { //extendedcolumn 6 and 7 is not there for flex type contracts
                          if (data[i].election[j].type !== null)
                            expobj.expandedcolumn8 = data[i].election[j].type
                          if (data[i].election[j].locked_price !== null)
                            expobj.expandedcolumn9 = formatString(data[i].election[j].locked_price)
                          if (data[i].election[j].approved_lbs !== null)
                            expobj.expandedcolumn10 = formatString(data[i].election[j].approved_lbs)
                          if (data[i].election[j].signed_lbs !== null)
                            expobj.expandedcolumn11 = formatString(data[i].election[j].signed_lbs)
                          if (data[i].election[j].pending_lbs !== null)
                            expobj.expandedcolumn12 = formatString(data[i].election[j].pending_lbs)
                          if (data[i].election[j].loan_lbs_not_priced !== null)
                            expobj.expandedcolumn13 = formatString(data[i].election[j].loan_lbs_not_priced)
                        }

                        expArr.push(expobj);
                      }
                      obj.expData = expArr;
                    }
                    else {  //normal single row binding
                      let expObjSing = {};
                      if (data[i].buy_pt_name !== null)
                        expObjSing.expandedcolumn1 = data[i].buy_pt_name
                      if (data[i].physical_city !== null)
                        expObjSing.expandedcolumn2 = data[i].physical_city
                      if (data[i].physical_state !== null)
                        expObjSing.expandedcolumn3 = data[i].physical_state
                      if (data[i].pnut_type_name !== null)
                        expObjSing.expandedcolumn4 = data[i].pnut_type_name
                      expArr.push(expObjSing);
                      obj.expData = expArr;
                    }
                  }
                  js15.push(obj);
                }
              }
              setValue(thisObj, "gridCntrctInq", js15);

              let gridvalues = js15
              let valcheck1 = true
              let valcheck2 = true
              let valcheck3 = true
              let valcheck4 = true
              let valcheck5 = true
              let valcheck6 = true
              let valcheck7 = true
              let valcheck8 = true
              let valcheck9 = true
              let valcheck10 = true
              let valcheck11 = true
              let valcheck12 = true
              let valcheck13 = true
              let valcheck14 = true
              let valcheck15 = true
              let valcheck16 = true
              let valcheck17 = true
              let valcheck18 = true
              let valcheck19 = true
              let valcheck20 = true
              let valcheck21 = true
              let valcheck22 = true
              let valcheck23 = true
              let valcheck24 = true
              let valcheck25 = true
              let valcheck26 = true
              let valcheck27 = true
              let valcheck28 = true
              let valcheck29 = true
              let valcheck30 = true
              let valcheck31 = true
              let valcheck32 = true
              let valcheck33 = true
              let valcheck34 = true

              // showColumn(thisObj, "gridCntrctInq", "txtcolContrct") //1
              // showColumn(thisObj, "gridCntrctInq", "txtcolContTyp")  //2
              // showColumn(thisObj, "gridCntrctInq", "txtcolDate")//3
              // showColumn(thisObj, "gridCntrctInq", "txtcolByngPt")//4
              // showColumn(thisObj, "gridCntrctInq", "txtcolVndr")//5
              // showColumn(thisObj, "gridCntrctInq", "txtcolFarm")//6
              // showColumn(thisObj, "gridCntrctInq", "txtcolPnutType")//7
              // showColumn(thisObj, "gridCntrctInq", "txtcolPnutVrty")//8
              // showColumn(thisObj, "gridCntrctInq", "txtcolGen")//9
              // showColumn(thisObj, "gridCntrctInq", "txtcolOleic")//10
              // showColumn(thisObj, "gridCntrctInq", "txtcolSeg")//11
              // showColumn(thisObj, "gridCntrctInq", "txtcolStatus")//12
              // showColumn(thisObj, "gridCntrctInq", "txtcolPrice")//13
              // showColumn(thisObj, "gridCntrctInq", "txtcolCntrctPounds")//14
              // showColumn(thisObj, "gridCntrctInq", "txtcolApplPounds")//15
              // showColumn(thisObj, "gridCntrctInq", "txtcolTntAppld")//16
              // showColumn(thisObj, "gridCntrctInq", "txtcolTotlApp")//17
              // showColumn(thisObj, "gridCntrctInq", "txtcolOpen")//18
              // showColumn(thisObj, "gridCntrctInq", "txtcolDryLndAcr")//19
              // showColumn(thisObj, "gridCntrctInq", "txtcolIrriAcr")//20
              // showColumn(thisObj, "gridCntrctInq", "txtcolPriceOption")//21
              // showColumn(thisObj, "gridCntrctInq", "txtcolMaxOption")//22
              // showColumn(thisObj, "gridCntrctInq", "txtcolElection")//23
              // showColumn(thisObj, "gridCntrctInq", "txtcolLockedPrice")//24
              // showColumn(thisObj, "gridCntrctInq", "txtcolPricLbsApp")//25
              // showColumn(thisObj, "gridCntrctInq", "txtcolPricedLbsSign")//26
              // showColumn(thisObj, "gridCntrctInq", "txtcolPricedPounds")//27
              // showColumn(thisObj, "gridCntrctInq", "txtcolLoanLbsNtPrc")//28
              // showColumn(thisObj, "gridCntrctInq", "txtcolHarvestCompDate")//29
              // showColumn(thisObj, "gridCntrctInq", "txtcolGrpNo")//30
              // showColumn(thisObj, "gridCntrctInq", "txtcolGrpPercent")//31
              // showColumn(thisObj, "gridCntrctInq", "txtcol211gpc")//32
              // showColumn(thisObj, "gridCntrctInq", "txtcol211concor")//33
              // showColumn(thisObj, "gridCntrctInq", "txtcolMinDisc")//34

              for (let i = 0; i < gridvalues.length; i++) {
                if(gridvalues[i].txtcolContrct!==undefined && gridvalues[i].txtcolContrct!==''){
                  valcheck1 = false
                }
                if(gridvalues[i].txtcolContTyp!==undefined && gridvalues[i].txtcolContTyp!==''){
                  valcheck2 = false
                }
                if(gridvalues[i].txtcolDate!==undefined && gridvalues[i].txtcolDate!==''){
                  valcheck3 = false
                }
                if(gridvalues[i].txtcolByngPt!==undefined && gridvalues[i].txtcolByngPt!==''){
                  valcheck4 = false
                }
                if(gridvalues[i].txtcolVndr!=='-'){
                  valcheck5 = false
                }
                if(gridvalues[i].txtcolFarm!==undefined && gridvalues[i].txtcolFarm!==''){
                  valcheck6 = false
                }
                if(gridvalues[i].txtcolPnutType!==undefined && gridvalues[i].txtcolPnutType!==''){
                  valcheck7 = false
                }
                if(gridvalues[i].txtcolPnutVrty!==undefined && gridvalues[i].txtcolPnutVrty!==''){
                  valcheck8 = false
                }
                if(gridvalues[i].txtcolGen!==undefined && gridvalues[i].txtcolGen!==''){
                  valcheck9 = false
                }
                if(gridvalues[i].txtcolOleic!==undefined && gridvalues[i].txtcolOleic!==''){
                  valcheck10 = false
                }
                if(gridvalues[i].txtcolSeg!==undefined && gridvalues[i].txtcolSeg!==''){
                  valcheck11 = false
                }
                if(gridvalues[i].txtcolStatus!==undefined && gridvalues[i].txtcolStatus!==null && gridvalues[i].txtcolStatus!==''){
                  valcheck12 = false
                }
                if(gridvalues[i].txtcolPrice!==undefined && gridvalues[i].txtcolPrice!==''){
                  valcheck13 = false
                }
                if(gridvalues[i].txtcolCntrctPounds!==undefined && gridvalues[i].txtcolCntrctPounds!==''){
                  valcheck14 = false
                }
                if(gridvalues[i].txtcolApplPounds!==undefined && gridvalues[i].txtcolApplPounds!==''){
                  valcheck15 = false
                }
                if(gridvalues[i].txtcolTntAppld!==undefined && gridvalues[i].txtcolTntAppld!==''){
                  valcheck16 = false
                }
                if(gridvalues[i].txtcolTotlApp!==undefined && gridvalues[i].txtcolTotlApp!==''){
                  valcheck17 = false
                }
                if(gridvalues[i].txtcolOpen!==undefined && gridvalues[i].txtcolOpen!==''){
                  valcheck18 = false
                }
                if(gridvalues[i].txtcolDryLndAcr!==undefined && gridvalues[i].txtcolDryLndAcr!==''){
                  valcheck19 = false
                }
                if(gridvalues[i].txtcolIrriAcr!==undefined && gridvalues[i].txtcolIrriAcr!==''){
                  valcheck20 = false
                }
                if(gridvalues[i].txtcolPriceOption!==undefined && gridvalues[i].txtcolPriceOption!==''){
                  valcheck21 = false
                }
                if(gridvalues[i].txtcolMaxOption!==undefined && gridvalues[i].txtcolMaxOption!==''){
                  valcheck22 = false
                }
                if(gridvalues[i].txtcolElection!==undefined && gridvalues[i].txtcolElection!==''){
                  valcheck23 = false
                }
                if(gridvalues[i].txtcolLockedPrice!==undefined && gridvalues[i].txtcolLockedPrice!==''){
                  valcheck24 = false
                }
                if(gridvalues[i].txtcolPricLbsApp!==undefined && gridvalues[i].txtcolPricLbsApp!==''){
                  valcheck25 = false
                }
                if(gridvalues[i].txtcolPricedLbsSign!==undefined && gridvalues[i].txtcolPricedLbsSign!==''){
                  valcheck26 = false
                }
                if(gridvalues[i].txtcolPricedPounds!==undefined && gridvalues[i].txtcolPricedPounds!==''){
                  valcheck27 = false
                }
                if(gridvalues[i].txtcolLoanLbsNtPrc!==undefined && gridvalues[i].txtcolLoanLbsNtPrc!==''){
                  valcheck28 = false
                }
                if(gridvalues[i].txtcolHarvestCompDate!==undefined && gridvalues[i].txtcolHarvestCompDate!==''){
                  valcheck29 = false
                }
                if(gridvalues[i].txtcolGrpNo!==undefined && gridvalues[i].txtcolGrpNo!==''){
                  valcheck30 = false
                }
                if(gridvalues[i].txtcolGrpPercent!==undefined && gridvalues[i].txtcolGrpPercent!==''){
                  valcheck31 = false
                }
                if(gridvalues[i].txtcol211gpc!==undefined && gridvalues[i].txtcol211gpc!==''){
                  valcheck32 = false
                }
                if(gridvalues[i].txtcol211concor!==undefined && gridvalues[i].txtcol211concor!==''){
                  valcheck33 = false
                }
                if(gridvalues[i].txtcolMinDisc!==undefined && gridvalues[i].txtcolMinDisc!==null && gridvalues[i].txtcolMinDisc!==''){
                  valcheck34 = false
                }
                
               }

               for (let i=0;i<34;i++){
                if(!thisObj.state.gridCntrctInq.columns[i].Visible) {
                  setData(thisObj,"firstSearch",true);
                }
               }


              if(getData(thisObj,"firstSearch")){
                if(thisObj.state.gridCntrctInq.columns[0].Visible) {
                  valcheck1 = false
                }
                if(thisObj.state.gridCntrctInq.columns[1].Visible) {
                  valcheck2 = false
                }
                if(thisObj.state.gridCntrctInq.columns[2].Visible) {
                  valcheck3 = false
                }
                if(thisObj.state.gridCntrctInq.columns[3].Visible) {
                  valcheck4 = false
                }
                if(thisObj.state.gridCntrctInq.columns[4].Visible) {
                  valcheck5 = false
                }
                if(thisObj.state.gridCntrctInq.columns[5].Visible) {
                  valcheck6 = false
                }
                if(thisObj.state.gridCntrctInq.columns[6].Visible) {
                  valcheck7 = false
                }
                if(thisObj.state.gridCntrctInq.columns[7].Visible) {
                  valcheck8 = false
                }
                if(thisObj.state.gridCntrctInq.columns[8].Visible) {
                  valcheck9 = false
                }
                if(thisObj.state.gridCntrctInq.columns[9].Visible) {
                  valcheck10 = false
                }
                if(thisObj.state.gridCntrctInq.columns[10].Visible) {
                  valcheck11 = false
                }
                if(thisObj.state.gridCntrctInq.columns[11].Visible) {
                  valcheck12 = false
                }
                if(thisObj.state.gridCntrctInq.columns[12].Visible) {
                  valcheck13 = false
                }
                if(thisObj.state.gridCntrctInq.columns[13].Visible) {
                  valcheck14 = false
                }
                if(thisObj.state.gridCntrctInq.columns[14].Visible) {
                  valcheck15 = false
                }
                if(thisObj.state.gridCntrctInq.columns[15].Visible) {
                  valcheck16 = false
                }
                if(thisObj.state.gridCntrctInq.columns[16].Visible) {
                  valcheck17 = false
                }
                if(thisObj.state.gridCntrctInq.columns[17].Visible) {
                  valcheck18 = false
                }
                if(thisObj.state.gridCntrctInq.columns[18].Visible) {
                  valcheck19 = false
                }
                if(thisObj.state.gridCntrctInq.columns[19].Visible) {
                  valcheck20 = false
                }
                if(thisObj.state.gridCntrctInq.columns[20].Visible) {
                  valcheck21 = false
                }
                if(thisObj.state.gridCntrctInq.columns[21].Visible) {
                  valcheck22 = false
                }
                if(thisObj.state.gridCntrctInq.columns[22].Visible) {
                  valcheck23 = false
                }
                if(thisObj.state.gridCntrctInq.columns[23].Visible) {
                  valcheck24 = false
                }
                if(thisObj.state.gridCntrctInq.columns[24].Visible) {
                  valcheck25 = false
                }
                if(thisObj.state.gridCntrctInq.columns[25].Visible) {
                  valcheck26 = false
                }
                if(thisObj.state.gridCntrctInq.columns[26].Visible) {
                  valcheck27 = false
                }
                if(thisObj.state.gridCntrctInq.columns[27].Visible) {
                  valcheck28 = false
                }
                if(thisObj.state.gridCntrctInq.columns[28].Visible) {
                  valcheck29 = false
                }
                if(thisObj.state.gridCntrctInq.columns[29].Visible) {
                  valcheck30 = false
                }
                if(thisObj.state.gridCntrctInq.columns[30].Visible) {
                  valcheck31 = false
                }
                if(thisObj.state.gridCntrctInq.columns[31].Visible) {
                  valcheck32 = false
                }
                if(thisObj.state.gridCntrctInq.columns[32].Visible) {
                  valcheck33 = false
                }
                if(thisObj.state.gridCntrctInq.columns[33].Visible) {
                  valcheck34 = false
                }
              }
              
              //,,,,,,,,,,,,,,,,,,,,,txtcol211concor,txtcolMinDisc


              if (valcheck1 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolContrct")
              }
              if (valcheck2 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolContTyp")
              }
              if (valcheck3 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolDate")
              }
              if (valcheck4 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolByngPt")
              }
              if (valcheck5 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolVndr")
              }
              if (valcheck6 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolFarm")
              }
              if (valcheck7 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolPnutType")
              }
              if (valcheck8 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolPnutVrty")
              }
              if (valcheck9 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolGen")
              }
              if (valcheck10 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolOleic")
              }
              if (valcheck11 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolSeg")
              }
              if (valcheck12 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolStatus")
              }
              if (valcheck13 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolPrice")
              }
              if (valcheck14 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolCntrctPounds")
              }
              if (valcheck15 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolApplPounds")
              }
              if (valcheck16 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolTntAppld")
              }
              if (valcheck17 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolTotlApp")
              }
              if (valcheck18 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolOpen")
              }
              if (valcheck19 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolDryLndAcr")
              }
              if (valcheck20 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolIrriAcr")
              }
              if (valcheck21 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolPriceOption")
              }
              if (valcheck22 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolMaxOption")
              }
              if (valcheck23 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolElection")
              }
              if (valcheck24 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolLockedPrice")
              }
              if (valcheck25 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolPricLbsApp")
              }
              if (valcheck26 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolPricedLbsSign")
              }
              if (valcheck27 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolPricedPounds")
              }
              if (valcheck28 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolLoanLbsNtPrc")
              }
              if (valcheck29 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolHarvestCompDate")
              }
              if (valcheck30 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolGrpNo")
              }
              if (valcheck31 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolGrpPercent")
              }
              if (valcheck32 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcol211gpc")
              }
              if (valcheck33 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcol211concor")
              }
              if (valcheck34 == true) {
                hideColumn(thisObj, "gridCntrctInq", "txtcolMinDisc")
              }


              let mathdata = js15
              let total1 = 0
              let total2 = 0
              let total3 = 0
              let total4 = 0
              let total5 = 0
              let total6 = 0
              let total7 = 0
              for (let i = 0; i < mathdata.length; i++) {
                if (mathdata[i].txtcolStatus !== "Voided" && mathdata[i].txtcolStatus !== "Cancelled") {
                  if (numfinder(mathdata[i].txtcolCntrctPounds)) { total1 = total1 + 0 }
                  else { total1 = total1 + parseFloat(removeCommas(mathdata[i].txtcolCntrctPounds)) }

                  if (numfinder(mathdata[i].txtcolApplPounds)) { total2 = total2 + 0 }
                  else { total2 = total2 + parseFloat(removeCommas(mathdata[i].txtcolApplPounds)) }

                  if (numfinder(mathdata[i].txtcolTntAppld)) { total3 = total3 + 0 }
                  else { total3 = total3 + parseFloat(removeCommas(mathdata[i].txtcolTntAppld)) }

                  if (numfinder(mathdata[i].txtcolTotlApp)) { total4 = total4 + 0 }
                  else { total4 = total4 + parseFloat(removeCommas(mathdata[i].txtcolTotlApp)) }

                  if (numfinder(mathdata[i].txtcolOpen)) { total5 = total5 + 0 }
                  else { total5 = total5 + parseFloat(removeCommas(mathdata[i].txtcolOpen)) }

                  if (numfinder(mathdata[i].txtcolDryLndAcr)) { total6 = total6 + 0 }
                  else { total6 = total6 + parseFloat(removeCommas(mathdata[i].txtcolDryLndAcr)) }

                  if (numfinder(mathdata[i].txtcolIrriAcr)) { total7 = total7 + 0 }
                  else { total7 = total7 + parseFloat(removeCommas(mathdata[i].txtcolIrriAcr)) }
                }
              }
              if (total1 == 0) { setValue(thisObj, "txtContractPounds", "0") }
              else { setValue(thisObj, "txtContractPounds", total1) }
              if (total2 == 0) { setValue(thisObj, "txtAppliedPounds", "0") }
              else { setValue(thisObj, "txtAppliedPounds", total2) }
              if (total3 == 0) { setValue(thisObj, "txtTentativeApplied", "0") }
              else { setValue(thisObj, "txtTentativeApplied", total3) }
              if (total4 == 0) { setValue(thisObj, "txtTotalApp", "0") }
              else { setValue(thisObj, "txtTotalApp", total4) }
              if (total5 == 0) { setValue(thisObj, "txtOpen", "0") }
              else { setValue(thisObj, "txtOpen", total5) }
              if (total6 == 0) { setValue(thisObj, "txtDryLandAcres", "0") }
              else { setValue(thisObj, "txtDryLandAcres", total6) }
              if (total7 == 0) { setValue(thisObj, "txtIrrigatedAcres", "0") }
              else { setValue(thisObj, "txtIrrigatedAcres", total7) }
            }
            setLoading(false);
          })
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Error on search button click"
        )
      }
      setLoading(false);
      return false
    }
    return true
  }
  thisObj.onbtnSearchClick = onbtnSearchClick;

  function numfinder(inputval) {
    inputval = removeCommas(inputval)
    if (isNaN(inputval) || inputval == '' || inputval == null || inputval == undefined) {
      return true;
    }
    else {
      return false;
    }
  }

  function removeCommas(str) {
    // Use regular expression to remove commas from string
    return str.replace(/,/g, "");
  }


  //peanut variety dropdown 	
  const bFillPeanutVarietyList = (buyingPointId, pnut_type_id) => {
    let js = []
    let obj = { key: '', description: ">>> All Varieties <<<" }
    js.push(obj)

    if (pnut_type_id !== null && pnut_type_id !== '' && pnut_type_id !== undefined) {
      ContractManagementService.RetrievePeanutVarietyControls(buyingPointId, null, null, pnut_type_id, null)
        .then(response => {
          let data = response;
          if (data !== null && data !== undefined && data.length > 0) {
            let js1 = [];
            for (var i = 0; i < data.length; i++) {
              if (!(js1.includes(data[i].pnut_variety_id))) {
                obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name }
                if (data[i].symbol_ind == "TRADEMARK") {
                  obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + 'ᵀᴹ' }
                }
                if (data[i].symbol_ind == "REGISTERED TRADEMARK") {
                  obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + '®' }
                }
                if (data[i].symbol_ind == "COPYRIGHT") {
                  obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + '©' }
                }
                js.push(obj);
                js1.push(data[i].pnut_variety_id)
              }

            }
          }
        })
    }
    thisObj.setState(current => {
      return {
        ...current,
        drpVariety: {
          ...state["drpVariety"],
          valueList: js
        }
      }
    });

  }
  // START_USER_CODE-USER_METHODS
  // on Form Load	and binding dropdowns


  function FormLoad() {
    bFillStatusList();
    bFillContractTypeList();
    bFillBuyingPointList();
    // //bFillStateList();	
    bFillPctAppliedList();
    bFillTypeList();
    bFillSegmentList();
    bFillSeedGrowerList();
    bFillGenerationList();
    bFillOleicList();
    bFillOrganicList();
    bFillDisclosureList();
    GetSecurityRoles();
    bFillPeanutVarietyList();

    //hide(thisObj, 'txtPrcnt', '')
    document.getElementsByClassName("txtPrcnt")[0].style.visibility = "hidden";
    //disable(thisObj,"txtVendorname")
    // hide(thisObj,"txtVendorname",'')
    document.getElementsByClassName("txtVendorname")[0].style.visibility = "hidden";
    setValue(thisObj, 'drpBuyingPoint', '')
    setValue(thisObj, 'drpApplied', '')
    setValue(thisObj, 'drpContractType', '')
    setValue(thisObj, 'drpDisclosure', '')
    setValue(thisObj, 'drpGeneration', '')
    setValue(thisObj, 'drpOleic', '')
    setValue(thisObj, 'drpOragnic', '')
    setValue(thisObj, 'drpSeedGrower', '')
    setValue(thisObj, 'drpSeg', '')
    setValue(thisObj, 'drpStatus', '')
    setValue(thisObj, 'drpType', '')
    setValue(thisObj, 'drpVariety', '')
    setValue(thisObj, 'chkExpCollGri', '2')
    setValue(thisObj, 'chkExp', '1')
    setValue(thisObj, 'txtVendor', '')

    //disable(thisObj,'btnViewUpload')
  }
  const bFillStatusList = () => {
    let js = [];
    js.push({ key: '', description: '>>> All Statuses <<<' })
    js.push({ key: 'P', description: 'Pending' })
    js.push({ key: 'S', description: 'Signed' })
    js.push({ key: 'A', description: 'Approved' })
    js.push({ key: 'V', description: 'Voided' })
    js.push({ key: 'X', description: 'Cancelled' })
    js.push({ key: 'C', description: 'Closed' })
    new Promise(function (resolve, reject) {
      thisObj.setState(current => {
        return {
          ...current,
          drpStatus: {
            ...state["drpStatus"],
            valueList: js
          }
        }
      })
      //setValue(thisObj, 'drpStatus', js.at(0).description);	
    })

  }
  const bFillContractTypeList = () => {
    let js = []
    js.push({ key: '', description: '>>> All Types <<<' })
    js.push({ key: 'O', description: 'Option' })
    js.push({ key: 'C', description: 'Contract' })
    js.push({ key: 'M', description: 'FlexMarket' })
    js.push({ key: 'S', description: 'Seed' })
    js.push({ key: 'L', description: 'Floor' })
    thisObj.setState(current => {
      return {
        ...current,
        drpContractType: {
          ...state["drpContractType"],
          valueList: js
        }
      }
    })
  }
  const bFillBuyingPointList = () => {
    let js = []
    js.push({ key: '', description: '>>>All Buying Points<<<' });
    ContractManagementService.RetrieveBuyingPointControlDetails('PN1020', null, null, null, null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          drpBuyingPoint: {
            ...state["drpBuyingPoint"],
            valueList: js
          }
        }
      })
      //setValue(thisObj, 'drpBuyingPoint', js.at(0).key);	
    })
  }
  const bFillStateList = () => { }

  const bFillPctAppliedList = () => {
    let js = []
    js.push({ key: '', description: '>>>All<<<' })
    js.push({ key: 'N', description: 'None 0%' })
    js.push({ key: 'L', description: 'Less Than' })
    js.push({ key: 'G', description: 'Greater Than' })
    js.push({ key: 'C', description: 'Complete 100%' })
    thisObj.setState(current => {
      return {
        ...current,
        drpApplied: {
          ...state["drpApplied"],
          valueList: js
        }
      }
    })
  }
  const bFillTypeList = () => {
    let js = [];
    js.push({ key: '', description: '>>>All Types<<<' })
    ContractManagementService.RetrievePeanutTypeControls(null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeName }
        js.push(obj)

      }
      thisObj.setState(current => {
        return {
          ...current,
          drpType: {
            ...state["drpType"],
            valueList: js,
            //Options : "SSS:1"	
          }
        }
      })
    })
  }
  const bFillSegmentList = () => {
    let js = []
    js.push({ key: '', description: '>>>All Segments<<<' })
    js.push({ key: '1', description: 'Seg1' })
    js.push({ key: '2', description: 'Seg2' })
    js.push({ key: '3', description: 'Seg3' })
    thisObj.setState(current => {
      return {
        ...current,
        drpSeg: {
          ...state["drpSeg"],
          valueList: js
        }
      }
    })
  }
  const bFillSeedGrowerList = () => {
    let js = []
    js.push({ key: '', description: '>>>Both<<<' })
    js.push({ key: 'Y', description: 'Yes' })
    js.push({ key: 'N', description: 'No' })
    thisObj.setState(current => {
      return {
        ...current,
        drpSeedGrower: {
          ...state["drpSeedGrower"],
          valueList: js
        }
      }
    })
  }
  const bFillGenerationList = () => {
    let js = []
    js.push({ key: '', description: '>>>All Generations<<<' })
    js.push({ key: 'F', description: 'Foundation' })
    js.push({ key: 'R', description: 'Registered' })
    js.push({ key: 'C', description: 'Certified' })
    thisObj.setState(current => {
      return {
        ...current,
        drpGeneration: {
          ...state["drpGeneration"],
          valueList: js
        }
      }
    })
  }
  const bFillOleicList = () => {
    let js = []
    js.push({ key: '', description: '>>>All Oleic<<<' })
    js.push({ key: 'H', description: 'High' })
    js.push({ key: 'M', description: 'Mid' })
    js.push({ key: 'N', description: 'None' })
    thisObj.setState(current => {
      return {
        ...current,
        drpOleic: {
          ...state["drpOleic"],
          valueList: js
        }
      }
    })
  }
  const bFillOrganicList = () => {
    let js = []
    js.push({ key: '', description: '>>>Both<<<' })
    js.push({ key: 'Y', description: 'Yes' })
    js.push({ key: 'N', description: 'No' })
    thisObj.setState(current => {
      return {
        ...current,
        drpOragnic: {
          ...state["drpOragnic"],
          valueList: js
        }
      }
    })
  }
  const bFillDisclosureList = () => {
    let js = []
    js.push({ key: '', description: '>>>All<<<' })
    js.push({ key: 'N', description: 'Not Disclosed' })
    js.push({ key: 'C', description: 'Completed' })
    js.push({ key: 'I', description: 'Incomplete' })
    thisObj.setState(current => {
      return {
        ...current,
        drpDisclosure: {
          ...state["drpDisclosure"],
          valueList: js
        }
      }
    })
  }
  const GetSecurityRoles = () => {
    ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '002', 'U', null).then(response => {
      if (response[0].permission == 'N')
        setData(thisObj, "mbSecurityUpdateAfterSigned", false)
      else
        setData(thisObj, "mbSecurityUpdateAfterSigned", true)

    }
    )
  }

  //hiding and showing vendorname txtbox
  const ontxtVendorChange = () => {
    try {
      setValue(thisObj, "txtVendor", validateTextBox2(txtVendor.value))
      let vendor1 = txtVendor.value
      if (vendor1.length == 6) {
        bFillRemitToList(vendor1)
      }
      else {
        document.getElementsByClassName("txtVendorname")[0].style.visibility = "hidden";
      }

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error on vendor textbox change"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtVendorChange = ontxtVendorChange;

  //seaching vendorname based on vendorname
  const bFillRemitToList = (vendor1) => {

    let venname = null
    let vendornamevisible = false
    ContractManagementService.RetrieveVendorByNumber(vendor1).then(response => {
      let data = response
      if (data !== null && data !== undefined && data.length > 0) {
        venname = data[0].name
        vendornamevisible = true
      }

      if (vendornamevisible == true) {
        document.getElementsByClassName("txtVendorname")[0].style.visibility = "visible";
        setValue(thisObj, "txtVendorname", venname)
      }
      else {
        document.getElementsByClassName("txtVendorname")[0].style.visibility = "hidden";
        setValue(thisObj, "txtVendorname", '')
      }
    })
  }

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} headerDetails={state.headerDetails} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ContractInquiry*/}

              {/* END_USER_CODE-USER_BEFORE_ContractInquiry*/}

              {/* START_USER_CODE-USER_BEFORE_grpContractInquiry*/}

              {/* END_USER_CODE-USER_BEFORE_grpContractInquiry*/}

              <GroupBoxWidget
                conf={state.grpContractInquiry}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblGeneral*/}

                {/* END_USER_CODE-USER_BEFORE_lblGeneral*/}

                <LabelWidget
                  values={values}
                  conf={state.lblGeneral}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblGeneral*/}

                {/* END_USER_CODE-USER_AFTER_lblGeneral*/}
                {/* START_USER_CODE-USER_BEFORE_lblLocation*/}

                {/* END_USER_CODE-USER_BEFORE_lblLocation*/}

                <LabelWidget
                  values={values}
                  conf={state.lblLocation}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblLocation*/}

                {/* END_USER_CODE-USER_AFTER_lblLocation*/}
                {/* START_USER_CODE-USER_BEFORE_lblPeanut*/}

                {/* END_USER_CODE-USER_BEFORE_lblPeanut*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPeanut}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPeanut*/}

                {/* END_USER_CODE-USER_AFTER_lblPeanut*/}
                {/* START_USER_CODE-USER_BEFORE_txtContract*/}

                {/* END_USER_CODE-USER_BEFORE_txtContract*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtContract}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtContract*/}

                {/* END_USER_CODE-USER_AFTER_txtContract*/}
                {/* START_USER_CODE-USER_BEFORE_drpBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_drpBuyingPoint*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.drpBuyingPoint}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_drpBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_drpBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_drpType*/}

                {/* END_USER_CODE-USER_BEFORE_drpType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.drpType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_drpType*/}

                {/* END_USER_CODE-USER_AFTER_drpType*/}
                {/* START_USER_CODE-USER_BEFORE_txtGroup*/}

                {/* END_USER_CODE-USER_BEFORE_txtGroup*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtGroup}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtGroup*/}

                {/* END_USER_CODE-USER_AFTER_txtGroup*/}
                {/* START_USER_CODE-USER_BEFORE_drpVariety*/}

                {/* END_USER_CODE-USER_BEFORE_drpVariety*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.drpVariety}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_drpVariety*/}

                {/* END_USER_CODE-USER_AFTER_drpVariety*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                {/* START_USER_CODE-USER_BEFORE_btnVenLookUp*/}

                {/* END_USER_CODE-USER_BEFORE_btnVenLookUp*/}

                <ButtonWidget
                  conf={state.btnVenLookUp}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVenLookUp*/}

                {/* END_USER_CODE-USER_AFTER_btnVenLookUp*/}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendorname}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_BEFORE_drpSeg*/}

                {/* END_USER_CODE-USER_BEFORE_drpSeg*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.drpSeg}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_drpSeg*/}

                {/* END_USER_CODE-USER_AFTER_drpSeg*/}
                {/* START_USER_CODE-USER_BEFORE_drpDisclosure*/}

                {/* END_USER_CODE-USER_BEFORE_drpDisclosure*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.drpDisclosure}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_drpDisclosure*/}

                {/* END_USER_CODE-USER_AFTER_drpDisclosure*/}
                {/* START_USER_CODE-USER_BEFORE_drpSeedGrower*/}

                {/* END_USER_CODE-USER_BEFORE_drpSeedGrower*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.drpSeedGrower}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_drpSeedGrower*/}

                {/* END_USER_CODE-USER_AFTER_drpSeedGrower*/}
                {/* START_USER_CODE-USER_BEFORE_drpStatus*/}

                {/* END_USER_CODE-USER_BEFORE_drpStatus*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.drpStatus}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_drpStatus*/}

                {/* END_USER_CODE-USER_AFTER_drpStatus*/}
                {/* START_USER_CODE-USER_BEFORE_drpGeneration*/}

                {/* END_USER_CODE-USER_BEFORE_drpGeneration*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.drpGeneration}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_drpGeneration*/}

                {/* END_USER_CODE-USER_AFTER_drpGeneration*/}
                {/* START_USER_CODE-USER_BEFORE_drpContractType*/}

                {/* END_USER_CODE-USER_BEFORE_drpContractType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.drpContractType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_drpContractType*/}

                {/* END_USER_CODE-USER_AFTER_drpContractType*/}
                {/* START_USER_CODE-USER_BEFORE_drpOleic*/}

                {/* END_USER_CODE-USER_BEFORE_drpOleic*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.drpOleic}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_drpOleic*/}

                {/* END_USER_CODE-USER_AFTER_drpOleic*/}
                {/* START_USER_CODE-USER_BEFORE_dtContractDate*/}

                {/* END_USER_CODE-USER_BEFORE_dtContractDate*/}

                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.dtContractDate}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_dtContractDate*/}

                {/* END_USER_CODE-USER_AFTER_dtContractDate*/}
                {/* START_USER_CODE-USER_BEFORE_dtThru*/}

                {/* END_USER_CODE-USER_BEFORE_dtThru*/}

                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.dtThru}
                  screenConf={state}
                ></DateWidget>
                {/* <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLst}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget> */}
                {/* START_USER_CODE-USER_AFTER_dtThru*/}

                {/* END_USER_CODE-USER_AFTER_dtThru*/}
                {/* START_USER_CODE-USER_BEFORE_drpOragnic*/}

                {/* END_USER_CODE-USER_BEFORE_drpOragnic*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.drpOragnic}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_drpOragnic*/}

                {/* END_USER_CODE-USER_AFTER_drpOragnic*/}
                {/* START_USER_CODE-USER_BEFORE_txtPrice*/}

                {/* END_USER_CODE-USER_BEFORE_txtPrice*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPrice}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPrice*/}

                {/* END_USER_CODE-USER_AFTER_txtPrice*/}
                {/* START_USER_CODE-USER_BEFORE_drpApplied*/}

                {/* END_USER_CODE-USER_BEFORE_drpApplied*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.drpApplied}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_drpApplied*/}

                {/* END_USER_CODE-USER_AFTER_drpApplied*/}
                {/* START_USER_CODE-USER_BEFORE_txtPrcnt*/}

                {/* END_USER_CODE-USER_BEFORE_txtPrcnt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPrcnt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>

                {/* START_USER_CODE-USER_AFTER_txtPrcnt*/}

                {/* END_USER_CODE-USER_AFTER_txtPrcnt*/}
                {/* START_USER_CODE-USER_BEFORE_chkExpCollGri*/}

                {/* END_USER_CODE-USER_BEFORE_chkExpCollGri*/}

                <RadioButtonGroupWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkExpCollGri}
                  screenConf={state}
                ></RadioButtonGroupWidget>
                {/* START_USER_CODE-USER_AFTER_chkExpCollGri*/}

                {/* END_USER_CODE-USER_AFTER_chkExpCollGri*/}
                {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                <ButtonWidget
                  conf={state.btnSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                {/* START_USER_CODE-USER_BEFORE_btnViewUpload*/}

                {/* END_USER_CODE-USER_BEFORE_btnViewUpload*/}

                <ButtonWidget
                  conf={state.btnViewUpload}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnViewUpload*/}

                {/* END_USER_CODE-USER_AFTER_btnViewUpload*/}
                {/* START_USER_CODE-USER_BEFORE_chkPrintComm*/}

                {/* END_USER_CODE-USER_BEFORE_chkPrintComm*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkPrintComm}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkPrintComm*/}

                {/* END_USER_CODE-USER_AFTER_chkPrintComm*/}
                {/* START_USER_CODE-USER_BEFORE_btnOpen*/}

                {/* END_USER_CODE-USER_BEFORE_btnOpen*/}

                <ButtonWidget
                  conf={state.btnOpen}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOpen*/}

                {/* END_USER_CODE-USER_AFTER_btnOpen*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpContractInquiry*/}

              {/* END_USER_CODE-USER_AFTER_grpContractInquiry*/}
              {/* START_USER_CODE-USER_BEFORE_contractList*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              {/* END_USER_CODE-USER_BEFORE_contractList*/}
              <GridWidget
                conf={state.gridCntrctInq}
                screenConf={state}
                linkClick={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                //onRowChange={selectedRows=> onGridRowChange(selectedRows)}
                errors={errors}
                touched={touched}
                rows={values.gridCntrctInq}
                onEvent={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                //ongridContractInqDblclick={row=>doubleclicked(row)}

                setFieldValue={setFieldValue}
                refObject={thisObj}
              ></GridWidget>
              {/* <GroupBoxWidget conf={state.contractList} screenConf={state}> */}
              {/* START_USER_CODE-USER_BEFORE_lstContractList*/}

              {/* END_USER_CODE-USER_BEFORE_lstContractList*/}

              {/* <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstContractList}
                  screenConf={state}
                ></ListboxWidget> */}

              {/* START_USER_CODE-USER_AFTER_lstContractList*/}

              {/* END_USER_CODE-USER_AFTER_lstContractList*/}
              {/* </GroupBoxWidget> */}
              {/* START_USER_CODE-USER_AFTER_contractList*/}

              {/* END_USER_CODE-USER_AFTER_contractList*/}
              {/* START_USER_CODE-USER_BEFORE_grpAction*/}

              {/* END_USER_CODE-USER_BEFORE_grpAction*/}
                <img
                src={StripPic}
                className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpAction} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblTotals*/}

                {/* END_USER_CODE-USER_BEFORE_lblTotals*/}

                <LabelWidget
                  values={values}
                  conf={state.lblTotals}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblTotals*/}

                {/* END_USER_CODE-USER_AFTER_lblTotals*/}
                {/* START_USER_CODE-USER_BEFORE_txtContractPounds*/}

                {/* END_USER_CODE-USER_BEFORE_txtContractPounds*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtContractPounds}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtContractPounds*/}

                {/* END_USER_CODE-USER_AFTER_txtContractPounds*/}
                {/* START_USER_CODE-USER_BEFORE_txtAppliedPounds*/}

                {/* END_USER_CODE-USER_BEFORE_txtAppliedPounds*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAppliedPounds}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAppliedPounds*/}

                {/* END_USER_CODE-USER_AFTER_txtAppliedPounds*/}
                {/* START_USER_CODE-USER_BEFORE_txtTentativeApplied*/}

                {/* END_USER_CODE-USER_BEFORE_txtTentativeApplied*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTentativeApplied}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTentativeApplied*/}

                {/* END_USER_CODE-USER_AFTER_txtTentativeApplied*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalApp*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalApp*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalApp}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalApp*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalApp*/}
                {/* START_USER_CODE-USER_BEFORE_txtOpen*/}

                {/* END_USER_CODE-USER_BEFORE_txtOpen*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOpen}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOpen*/}

                {/* END_USER_CODE-USER_AFTER_txtOpen*/}
                {/* START_USER_CODE-USER_BEFORE_txtDryLandAcres*/}

                {/* END_USER_CODE-USER_BEFORE_txtDryLandAcres*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDryLandAcres}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDryLandAcres*/}

                {/* END_USER_CODE-USER_AFTER_txtDryLandAcres*/}
                {/* START_USER_CODE-USER_BEFORE_txtIrrigatedAcres*/}

                {/* END_USER_CODE-USER_BEFORE_txtIrrigatedAcres*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtIrrigatedAcres}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtIrrigatedAcres*/}

                {/* END_USER_CODE-USER_AFTER_txtIrrigatedAcres*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_btnExportExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportExcel*/}

                <ButtonWidget
                  conf={state.btnExportExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportExcel*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrintSearch*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrintSearch*/}

                <ButtonWidget
                  conf={state.btnPrintSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrintSearch*/}

                {/* END_USER_CODE-USER_AFTER_btnPrintSearch*/}
                {/* START_USER_CODE-USER_BEFORE_chkExp*/}

                {/* END_USER_CODE-USER_BEFORE_chkExp*/}

                <RadioButtonGroupWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkExp}
                  screenConf={state}
                ></RadioButtonGroupWidget>
                {/* START_USER_CODE-USER_AFTER_chkExp*/}

                {/* END_USER_CODE-USER_AFTER_chkExp*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpAction*/}

              {/* END_USER_CODE-USER_AFTER_grpAction*/}

              {/* START_USER_CODE-USER_AFTER_ContractInquiry*/}

              {/* END_USER_CODE-USER_AFTER_ContractInquiry*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_ContractInquiry);
