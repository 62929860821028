/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  CheckboxGroupWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  setValue,
   getValue,
   enable,
   disable,
   hide,
   show,
   setData,
   getData
} from "../../shared/WindowImports";

import "./BuyingPointProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import {SystemMaintenanceMasterManagementService} from "../Service/SystemMaintenanceMasterManagementServices"
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import Loading from "../../../Loader/Loading";//Fixed As part of UAT BUG_543829
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { response } from "msw";
import StripPic from "../../../../assets/img/PinStrip.png";
//import for screenshot functionality
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
const CompanyName = () => (sessionStorage.getItem('Companyname'));
const compIdFromLS = () => (sessionStorage.getItem('compId'));
const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_BuyingPointProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  let cmdOk_Caption = ''

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "BuyingPointProfile",
    windowName: "BuyingPointProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.BuyingPointProfile",
    // START_USER_CODE-USER_BuyingPointProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Buying Point Profile",
      scrCode: "PN0020D",
    },
    // END_USER_CODE-USER_BuyingPointProfile_PROPERTIES
    btn1: {
      name: "btn1",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: ".",
      CharWidth: "6",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn1_PROPERTIES

      // END_USER_CODE-USER_btn1_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnMakeInactive: {
      name: "btnMakeInactive",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Make Inactive",
      CharWidth: "28",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnMakeInactive_PROPERTIES

      // END_USER_CODE-USER_btnMakeInactive_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    btnPrintScreen: {
      name: "btnPrintScreen",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print Screen",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrintScreen_PROPERTIES

      // END_USER_CODE-USER_btnPrintScreen_PROPERTIES
    },
    btnVendorCCV: {
      name: "btnVendorCCV",
      type: "ButtonWidget",
      parent: "grpbxCuredCleanVendor",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendorCCV_PROPERTIES

      // END_USER_CODE-USER_btnVendorCCV_PROPERTIES
    },
    btnVendorDCV: {
      name: "btnVendorDCV",
      type: "ButtonWidget",
      parent: "grpbxDefaultCMAVendor",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendorDCV_PROPERTIES

      // END_USER_CODE-USER_btnVendorDCV_PROPERTIES
    },
    chkbocCharacteristics: {
      name: "chkbocCharacteristics",
      type: "CheckBoxGroupFieldWidget",
      parent: "grpbxCharacteristic",
      Options:
        "Non GPC Storage:1,In/Out:2,Leased:3,DPR Exclusion:4,GPC Storage:5,GPC Owned:6,Shelling:7,E-SC95 Select:8,Exclude from KC Transmission:9,Exclude from EWR Transmission:10,Apply to CMA at 1007 time:11,SC95 - 1007 One Document:12",
      ColSpan: "1",
      HasLabel: false,
      RowSpan: "4",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbocCharacteristics_PROPERTIES

      // END_USER_CODE-USER_chkbocCharacteristics_PROPERTIES
    },
    chkboxAdditional1007: {
      name: "chkboxAdditional1007",
      type: "CheckBoxWidget",
      parent: "grpbxControlNumbers",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxAdditional1007_PROPERTIES

      // END_USER_CODE-USER_chkboxAdditional1007_PROPERTIES
    },
    chkboxUseAutomaticTransfer: {
      name: "chkboxUseAutomaticTransfer",
      type: "CheckBoxWidget",
      parent: "grpbxControlNumbers",
      Label: "Use Automatic Transfer",
      ColSpan: "2",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxUseAutomaticTransfer_PROPERTIES

      // END_USER_CODE-USER_chkboxUseAutomaticTransfer_PROPERTIES
    },
    ddArea: {
      name: "ddArea",
      type: "DropDownFieldWidget",
      parent: "grpbxBuyingPoint",
      Label: "Area:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddArea_PROPERTIES

      // END_USER_CODE-USER_ddArea_PROPERTIES
    },
    ddCollectionPt: {
      name: "ddCollectionPt",
      type: "DropDownFieldWidget",
      parent: "grpbxBuyingPoint",
      Label: "Collection Pt:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCollectionPt_PROPERTIES

      // END_USER_CODE-USER_ddCollectionPt_PROPERTIES
    },
    ddCountyPA: {
      name: "ddCountyPA",
      type: "DropDownFieldWidget",
      parent: "grpbxPhysicalAddress",
      Label: "County:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCountyPA_PROPERTIES

      // END_USER_CODE-USER_ddCountyPA_PROPERTIES
    },
    ddFedWhseLic: {
      name: "ddFedWhseLic",
      type: "DropDownFieldWidget",
      parent: "grpbxGovernmentIssued",
      Label: "Fed Whse Lic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddFedWhseLic_PROPERTIES

      // END_USER_CODE-USER_ddFedWhseLic_PROPERTIES
    },
    ddInspectionGramEntry: {
      name: "ddInspectionGramEntry",
      type: "DropDownFieldWidget",
      parent: "grpbxMiscellaneuos",
      Label: "Inspection Gram Entry:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddInspectionGramEntry_PROPERTIES

      // END_USER_CODE-USER_ddInspectionGramEntry_PROPERTIES
    },
    ddInternetType: {
      name: "ddInternetType",
      type: "DropDownFieldWidget",
      parent: "grpbxMiscellaneuos",
      Label: "Internet Type:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddInternetType_PROPERTIES

      // END_USER_CODE-USER_ddInternetType_PROPERTIES
    },
    ddIrrigatedDryland: {
      name: "ddIrrigatedDryland",
      type: "DropDownFieldWidget",
      parent: "grpbxMiscellaneuos",
      Label: "Irrigated/Dryland:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddIrrigatedDryland_PROPERTIES

      // END_USER_CODE-USER_ddIrrigatedDryland_PROPERTIES
    },
    ddRemitToCCV: {
      name: "ddRemitToCCV",
      type: "DropDownFieldWidget",
      parent: "grpbxCuredCleanVendor",
      Label: "Remit To:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRemitToCCV_PROPERTIES

      // END_USER_CODE-USER_ddRemitToCCV_PROPERTIES
    },
    ddRemitToDCV: {
      name: "ddRemitToDCV",
      type: "DropDownFieldWidget",
      parent: "grpbxDefaultCMAVendor",
      Label: "Remit To:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRemitToDCV_PROPERTIES

      // END_USER_CODE-USER_ddRemitToDCV_PROPERTIES
    },
    ddStateMA: {
      name: "ddStateMA",
      type: "DropDownFieldWidget",
      parent: "grpbxMailingAddress",
      Label: "State:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddStateMA_PROPERTIES

      // END_USER_CODE-USER_ddStateMA_PROPERTIES
    },
    ddStatePA: {
      name: "ddStatePA",
      type: "DropDownFieldWidget",
      parent: "grpbxPhysicalAddress",
      Label: "State:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddStatePA_PROPERTIES

      // END_USER_CODE-USER_ddStatePA_PROPERTIES
    },
    ddTransferGramEntry: {
      name: "ddTransferGramEntry",
      type: "DropDownFieldWidget",
      parent: "grpbxMiscellaneuos",
      Label: "Transfer Gram Entry:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddTransferGramEntry_PROPERTIES

      // END_USER_CODE-USER_ddTransferGramEntry_PROPERTIES
    },
    ddTransferMoistureEntry: {
      name: "ddTransferMoistureEntry",
      type: "DropDownFieldWidget",
      parent: "grpbxMiscellaneuos",
      Label: "Transfer Moisture Entry:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddTransferMoistureEntry_PROPERTIES

      // END_USER_CODE-USER_ddTransferMoistureEntry_PROPERTIES
    },
    ddVicamLab: {
      name: "ddVicamLab",
      type: "DropDownFieldWidget",
      parent: "grpbxMiscellaneuos",
      Label: "Vicam Lab:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddVicamLab_PROPERTIES

      // END_USER_CODE-USER_ddVicamLab_PROPERTIES
    },
    grpbxBuyingPoint: {
      name: "grpbxBuyingPoint",
      type: "GroupBoxWidget",
      parent: "grpbxBuyingPointProfile",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_grpbxBuyingPoint_PROPERTIES
    },
    grpbxCharacteristic: {
      name: "grpbxCharacteristic",
      type: "GroupBoxWidget",
      parent: "grpbxBuyingPointProfile",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxCharacteristic_PROPERTIES

      // END_USER_CODE-USER_grpbxCharacteristic_PROPERTIES
    },
    grpbxCompanyArea: {
      name: "grpbxCompanyArea",
      type: "GroupBoxWidget",
      parent: "grpbxBuyingPointProfile",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxCompanyArea_PROPERTIES

      // END_USER_CODE-USER_grpbxCompanyArea_PROPERTIES
    },
    grpbxContactInfo: {
      name: "grpbxContactInfo",
      type: "GroupBoxWidget",
      parent: "grpbxBuyingPointProfile",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxContactInfo_PROPERTIES

      // END_USER_CODE-USER_grpbxContactInfo_PROPERTIES
    },
    grpbxControlNumbers: {
      name: "grpbxControlNumbers",
      type: "GroupBoxWidget",
      parent: "grpbxCntnr2",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxControlNumbers_PROPERTIES

      // END_USER_CODE-USER_grpbxControlNumbers_PROPERTIES
    },
    grpbxCuredCleanVendor: {
      name: "grpbxCuredCleanVendor",
      type: "GroupBoxWidget",
      parent: "grpbxCntnr1",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxCuredCleanVendor_PROPERTIES

      // END_USER_CODE-USER_grpbxCuredCleanVendor_PROPERTIES
    },
    grpbxDefaultCMAVendor: {
      name: "grpbxDefaultCMAVendor",
      type: "GroupBoxWidget",
      parent: "grpbxCntnr21",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxDefaultCMAVendor_PROPERTIES

      // END_USER_CODE-USER_grpbxDefaultCMAVendor_PROPERTIES
    },
    grpbxFTPAdmin: {
      name: "grpbxFTPAdmin",
      type: "GroupBoxWidget",
      parent: "grpbxBuyingPointProfile",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxFTPAdmin_PROPERTIES

      // END_USER_CODE-USER_grpbxFTPAdmin_PROPERTIES
    },
    grpbxGovernmentIssued: {
      name: "grpbxGovernmentIssued",
      type: "GroupBoxWidget",
      parent: "grpbxBuyingPointProfile",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxGovernmentIssued_PROPERTIES

      // END_USER_CODE-USER_grpbxGovernmentIssued_PROPERTIES
    },
    grpbxMailingAddress: {
      name: "grpbxMailingAddress",
      type: "GroupBoxWidget",
      parent: "grpbxCntnr1",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxMailingAddress_PROPERTIES

      // END_USER_CODE-USER_grpbxMailingAddress_PROPERTIES
    },
    grpbxMiscellaneuos: {
      name: "grpbxMiscellaneuos",
      type: "GroupBoxWidget",
      parent: "grpbxCntnr2",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxMiscellaneuos_PROPERTIES

      // END_USER_CODE-USER_grpbxMiscellaneuos_PROPERTIES
    },
    grpbxPhysicalAddress: {
      name: "grpbxPhysicalAddress",
      type: "GroupBoxWidget",
      parent: "grpbxCntnr1",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxPhysicalAddress_PROPERTIES

      // END_USER_CODE-USER_grpbxPhysicalAddress_PROPERTIES
    },
    grpbxCntnr1: {
      name: "grpbxCntnr1",
      type: "GroupBoxWidget",
      parent: "grpbxBuyingPointProfile",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxPhysicalAddress_PROPERTIES

      // END_USER_CODE-USER_grpbxPhysicalAddress_PROPERTIES
    },
    grpbxCntnr2: {
      name: "grpbxCntnr2",
      type: "GroupBoxWidget",
      parent: "grpbxBuyingPointProfile",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxPhysicalAddress_PROPERTIES

      // END_USER_CODE-USER_grpbxPhysicalAddress_PROPERTIES
    },


    lbl1007: {
      name: "lbl1007",
      type: "LabelWidget",
      parent: "grpbxControlNumbers",
      Label: "1007:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl1007_PROPERTIES

      // END_USER_CODE-USER_lbl1007_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblAdditional1007: {
      name: "lblAdditional1007",
      type: "LabelWidget",
      parent: "grpbxControlNumbers",
      Label: "Additional 1007:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAdditional1007_PROPERTIES

      // END_USER_CODE-USER_lblAdditional1007_PROPERTIES
    },
    lblArea: {
      name: "lblArea",
      type: "LabelWidget",
      parent: "grpbxCompanyArea",
      Label: "Area:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblArea_PROPERTIES

      // END_USER_CODE-USER_lblArea_PROPERTIES
    },
    lblAreaValue: {
      name: "lblAreaValue",
      type: "LabelWidget",
      parent: "grpbxCompanyArea",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAreaValue_PROPERTIES

      // END_USER_CODE-USER_lblAreaValue_PROPERTIES
    },
    lblBeg1007: {
      name: "lblBeg1007",
      type: "LabelWidget",
      parent: "grpbxCompanyArea",
      Label: "lbl_beg1007",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBeg1007_PROPERTIES

      // END_USER_CODE-USER_lblBeg1007_PROPERTIES
    },
    lblBeg11007: {
      name: "lblBeg11007",
      type: "LabelWidget",
      parent: "grpbxCompanyArea",
      Label: "lbl_beg1_1007",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBeg11007_PROPERTIES

      // END_USER_CODE-USER_lblBeg11007_PROPERTIES
    },
    lblBegCheck: {
      name: "lblBegCheck",
      type: "LabelWidget",
      parent: "grpbxCompanyArea",
      Label: "lbl_BegCheck",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBegCheck_PROPERTIES

      // END_USER_CODE-USER_lblBegCheck_PROPERTIES
    },
    lblBuyingPoint: {
      name: "lblBuyingPoint",
      type: "LabelWidget",
      parent: "grpbxBuyingPoint",
      Label: "Buying Point",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_lblBuyingPoint_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblCharacteristics: {
      name: "lblCharacteristics",
      type: "LabelWidget",
      parent: "grpbxCharacteristic",
      Label: "Characteristics",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCharacteristics_PROPERTIES

      // END_USER_CODE-USER_lblCharacteristics_PROPERTIES
    },
    lblCompany: {
      name: "lblCompany",
      type: "LabelWidget",
      parent: "grpbxCompanyArea",
      Label: "Company:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCompany_PROPERTIES

      // END_USER_CODE-USER_lblCompany_PROPERTIES
    },
    lblCompanyValue: {
      name: "lblCompanyValue",
      type: "LabelWidget",
      parent: "grpbxCompanyArea",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCompanyValue_PROPERTIES

      // END_USER_CODE-USER_lblCompanyValue_PROPERTIES
    },
    lblContactInfo: {
      name: "lblContactInfo",
      type: "LabelWidget",
      parent: "grpbxContactInfo",
      Label: "Contact Info",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblContactInfo_PROPERTIES

      // END_USER_CODE-USER_lblContactInfo_PROPERTIES
    },
    lblControlNumbers: {
      name: "lblControlNumbers",
      type: "LabelWidget",
      parent: "grpbxControlNumbers",
      Label: "Control Numbers",
      ColSpan: "4",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblControlNumbers_PROPERTIES

      // END_USER_CODE-USER_lblControlNumbers_PROPERTIES
    },
    lblCuredCleanVendor: {
      name: "lblCuredCleanVendor",
      type: "LabelWidget",
      parent: "grpbxCuredCleanVendor",
      Label: "Cured & Clean Vendor",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCuredCleanVendor_PROPERTIES

      // END_USER_CODE-USER_lblCuredCleanVendor_PROPERTIES
    },
    lblDefaultCMAVendor: {
      name: "lblDefaultCMAVendor",
      type: "LabelWidget",
      parent: "grpbxDefaultCMAVendor",
      Label: "Default CMA Vendor",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblDefaultCMAVendor_PROPERTIES

      // END_USER_CODE-USER_lblDefaultCMAVendor_PROPERTIES
    },
    lblEnd1007: {
      name: "lblEnd1007",
      type: "LabelWidget",
      parent: "grpbxCompanyArea",
      Label: "lbl_end1007",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblEnd1007_PROPERTIES

      // END_USER_CODE-USER_lblEnd1007_PROPERTIES
    },
    lblEnd11007: {
      name: "lblEnd11007",
      type: "LabelWidget",
      parent: "grpbxCompanyArea",
      Label: "lbl_end1_1007",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblEnd11007_PROPERTIES

      // END_USER_CODE-USER_lblEnd11007_PROPERTIES
    },
    lblEndCheck: {
      name: "lblEndCheck",
      type: "LabelWidget",
      parent: "grpbxCompanyArea",
      Label: "lbl_EndCheck",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblEndCheck_PROPERTIES

      // END_USER_CODE-USER_lblEndCheck_PROPERTIES
    },
    lblFTPAdmin: {
      name: "lblFTPAdmin",
      type: "LabelWidget",
      parent: "grpbxFTPAdmin",
      Label: "FTP Admin",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFTPAdmin_PROPERTIES

      // END_USER_CODE-USER_lblFTPAdmin_PROPERTIES
    },
    lblGovernmentIssued: {
      name: "lblGovernmentIssued",
      type: "LabelWidget",
      parent: "grpbxGovernmentIssued",
      Label: "Government Issued #'s",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblGovernmentIssued_PROPERTIES

      // END_USER_CODE-USER_lblGovernmentIssued_PROPERTIES
    },
    lblLocationRecordIsInactive: {
      name: "lblLocationRecordIsInactive",
      type: "LabelWidget",
      parent: "grpbxBuyingPointProfile",
      Label: "[ location record is INACTIVE ]",
      ColSpan: "2",
      RowSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLocationRecordIsInactive_PROPERTIES

      // END_USER_CODE-USER_lblLocationRecordIsInactive_PROPERTIES
    },
    lblMailingAddress: {
      name: "lblMailingAddress",
      type: "LabelWidget",
      parent: "grpbxMailingAddress",
      Label: "Mailing Address",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblMailingAddress_PROPERTIES

      // END_USER_CODE-USER_lblMailingAddress_PROPERTIES
    },
    lblMiscellaneous: {
      name: "lblMiscellaneous",
      type: "LabelWidget",
      parent: "grpbxMiscellaneuos",
      Label: "Miscellaneous",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblMiscellaneous_PROPERTIES

      // END_USER_CODE-USER_lblMiscellaneous_PROPERTIES
    },
    lblName: {
      name: "lblName",
      type: "TextBoxWidget",
      parent: "grpbxContactInfo",
      Label: "Name:",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblName_PROPERTIES

      // END_USER_CODE-USER_lblName_PROPERTIES
    },
    lblNext1007: {
      name: "lblNext1007",
      type: "LabelWidget",
      parent: "grpbxCompanyArea",
      Label: "lbl_next1007",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNext1007_PROPERTIES

      // END_USER_CODE-USER_lblNext1007_PROPERTIES
    },
    lblNextCheck: {
      name: "lblNextCheck",
      type: "LabelWidget",
      parent: "grpbxCompanyArea",
      Label: "lbl_nextCheck",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNextCheck_PROPERTIES

      // END_USER_CODE-USER_lblNextCheck_PROPERTIES
    },
    lblNextWhouseCntl: {
      name: "lblNextWhouseCntl",
      type: "LabelWidget",
      parent: "grpbxCompanyArea",
      Label: "lbl_nextWhouseCntl",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNextWhouseCntl_PROPERTIES

      // END_USER_CODE-USER_lblNextWhouseCntl_PROPERTIES
    },
    lblPhysicalAddress: {
      name: "lblPhysicalAddress",
      type: "LabelWidget",
      parent: "grpbxPhysicalAddress",
      Label: "Physical Address",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPhysicalAddress_PROPERTIES

      // END_USER_CODE-USER_lblPhysicalAddress_PROPERTIES
    },
    radioFTPMethod: {
      name: "radioFTPMethod",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxFTPAdmin",
      Options: "MS Inet:1,Standalon WINSCP:2",
      Label: "FTP Method:",
      ColSpan: "3",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioFTPMethod_PROPERTIES

      // END_USER_CODE-USER_radioFTPMethod_PROPERTIES
    },
    radioLogging: {
      name: "radioLogging",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxFTPAdmin",
      Options: "On:1,Off:2",
      Label: "Logging:",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioLogging_PROPERTIES

      // END_USER_CODE-USER_radioLogging_PROPERTIES
    },
    txtAddressMA: {
      name: "txtAddressMA",
      type: "TextBoxWidget",
      parent: "grpbxMailingAddress",
      Label: "Address:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 50 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddressMA_PROPERTIES

      // END_USER_CODE-USER_txtAddressMA_PROPERTIES
    },
    txtAddressPA: {
      name: "txtAddressPA",
      type: "TextBoxWidget",
      parent: "grpbxPhysicalAddress",
      Label: "Address:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 50 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddressPA_PROPERTIES

      // END_USER_CODE-USER_txtAddressPA_PROPERTIES
    },
    txtBalAcctDept: {
      name: "txtBalAcctDept",
      type: "TextBoxWidget",
      parent: "grpbxMiscellaneuos",
      Label: " Bal Acct Dept:",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBalAcctDept_PROPERTIES

      // END_USER_CODE-USER_txtBalAcctDept_PROPERTIES
    },
    txtBalAcctResp: {
      name: "txtBalAcctResp",
      type: "TextBoxWidget",
      parent: "grpbxMiscellaneuos",
      Label: "Bal Acct Resp:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBalAcctResp_PROPERTIES

      // END_USER_CODE-USER_txtBalAcctResp_PROPERTIES
    },
    txtBeg: {
      name: "txtBeg",
      type: "TextBoxWidget",
      parent: "grpbxControlNumbers",
      Label: "Beg:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBeg_PROPERTIES

      // END_USER_CODE-USER_txtBeg_PROPERTIES
    },
    txtBeg1: {
      name: "txtBeg1",
      type: "TextBoxWidget",
      parent: "grpbxControlNumbers",
      Label: "Beg1:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBeg1_PROPERTIES

      // END_USER_CODE-USER_txtBeg1_PROPERTIES
    },
    txtBegCheck: {
      name: "txtBegCheck",
      type: "TextBoxWidget",
      parent: "grpbxControlNumbers",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBegCheck_PROPERTIES

      // END_USER_CODE-USER_txtBegCheck_PROPERTIES
    },
    txtCCCLoc: {
      name: "txtCCCLoc",
      type: "TextBoxWidget",
      parent: "grpbxGovernmentIssued",
      Label: "CCC Loc #:",
      LengthRange: { MinLength: 0, MaxLength: 5 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCCLoc_PROPERTIES

      // END_USER_CODE-USER_txtCCCLoc_PROPERTIES
    },
    txtCheck: {
      name: "txtCheck",
      type: "TextBoxWidget",
      parent: "grpbxControlNumbers",
      Label: "Check:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCheck_PROPERTIES

      // END_USER_CODE-USER_txtCheck_PROPERTIES
    },
    txtCityMA: {
      name: "txtCityMA",
      type: "TextBoxWidget",
      parent: "grpbxMailingAddress",
      Label: "City:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCityMA_PROPERTIES

      // END_USER_CODE-USER_txtCityMA_PROPERTIES
    },
    txtCityPA: {
      name: "txtCityPA",
      type: "TextBoxWidget",
      parent: "grpbxPhysicalAddress",
      Label: "City:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCityPA_PROPERTIES

      // END_USER_CODE-USER_txtCityPA_PROPERTIES
    },
    txtEnd: {
      name: "txtEnd",
      type: "TextBoxWidget",
      parent: "grpbxControlNumbers",
      Label: "End:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEnd_PROPERTIES

      // END_USER_CODE-USER_txtEnd_PROPERTIES
    },
    txtEnd1: {
      name: "txtEnd1",
      type: "TextBoxWidget",
      parent: "grpbxControlNumbers",
      Label: "End1:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEnd1_PROPERTIES

      // END_USER_CODE-USER_txtEnd1_PROPERTIES
    },
    txtEndCheck: {
      name: "txtEndCheck",
      type: "TextBoxWidget",
      parent: "grpbxControlNumbers",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEndCheck_PROPERTIES

      // END_USER_CODE-USER_txtEndCheck_PROPERTIES
    },
    txtFedBuyPt: {
      name: "txtFedBuyPt",
      type: "TextBoxWidget",
      parent: "grpbxGovernmentIssued",
      Label: "Fed Buy Pt #:",
      LengthRange: { MinLength: 0, MaxLength: 5 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFedBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtFedBuyPt_PROPERTIES
    },
    txtGroupID: {
      name: "txtGroupID",
      type: "TextBoxWidget",
      parent: "grpbxBuyingPoint",
      Label: "Group ID:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGroupID_PROPERTIES

      // END_USER_CODE-USER_txtGroupID_PROPERTIES
    },
    txtID: {
      name: "txtID",
      type: "TextBoxWidget",
      parent: "grpbxBuyingPoint",
      Label: "ID:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtID_PROPERTIES

      // END_USER_CODE-USER_txtID_PROPERTIES
    },
    txtName: {
      name: "txtName",
      type: "TextBoxWidget",
      parent: "grpbxBuyingPoint",
      Label: "Name:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtName_PROPERTIES

      // END_USER_CODE-USER_txtName_PROPERTIES
    },
    txtNext: {
      name: "txtNext",
      type: "TextBoxWidget",
      parent: "grpbxControlNumbers",
      Label: "Next",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNext_PROPERTIES

      // END_USER_CODE-USER_txtNext_PROPERTIES
    },
    txtPhone: {
      name: "txtPhone",
      type: "TextBoxWidget",
      parent: "grpbxContactInfo",
      Label: "Phone #:",
      LengthRange: { MinLength: 0, MaxLength: 15 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPhone_PROPERTIES

      // END_USER_CODE-USER_txtPhone_PROPERTIES
    },
    txtPIAcctDept: {
      name: "txtPIAcctDept",
      type: "TextBoxWidget",
      parent: "grpbxMiscellaneuos",
      Label: "PI Acct Dept:",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPIAcctDept_PROPERTIES

      // END_USER_CODE-USER_txtPIAcctDept_PROPERTIES
    },
    txtPIAcctResp: {
      name: "txtPIAcctResp",
      type: "TextBoxWidget",
      parent: "grpbxMiscellaneuos",
      Label: "PI Acct Resp:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPIAcctResp_PROPERTIES

      // END_USER_CODE-USER_txtPIAcctResp_PROPERTIES
    },
    txtProfitCenter: {
      name: "txtProfitCenter",
      type: "TextBoxWidget",
      parent: "grpbxBuyingPoint",
      Label: "Profit Center:",
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtProfitCenter_PROPERTIES

      // END_USER_CODE-USER_txtProfitCenter_PROPERTIES
    },
    txtRetries: {
      name: "txtRetries",
      type: "TextBoxWidget",
      parent: "grpbxFTPAdmin",
      Label: "Retries:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRetries_PROPERTIES

      // END_USER_CODE-USER_txtRetries_PROPERTIES
    },
    txtServiceProvider: {
      name: "txtServiceProvider",
      type: "TextBoxWidget",
      parent: "grpbxMiscellaneuos",
      Label: "Service Provider:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 52 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtServiceProvider_PROPERTIES

      // END_USER_CODE-USER_txtServiceProvider_PROPERTIES
    },
    txtTimerInterval: {
      name: "txtTimerInterval",
      type: "TextBoxWidget",
      parent: "grpbxFTPAdmin",
      Label: "Timer Interval: (milisec)",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTimerInterval_PROPERTIES

      // END_USER_CODE-USER_txtTimerInterval_PROPERTIES
    },
    txtVendorCCV: {
      name: "txtVendorCCV",
      type: "TextBoxWidget",
      parent: "grpbxCuredCleanVendor",
      Label: "Vendor:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorCCV_PROPERTIES

      // END_USER_CODE-USER_txtVendorCCV_PROPERTIES
    },
    txtVendorNameCCV: {
      name: "txtVendorNameCCV",
      type: "TextBoxWidget",
      parent: "grpbxCuredCleanVendor",
      Label: "Vendor:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorNameCCV_PROPERTIES
    
      Readonly: true, 
      Enabled : false
      // END_USER_CODE-USER_txtVendorNameCCV_PROPERTIES
    },
    txtVendorDCV: {
      name: "txtVendorDCV",
      type: "TextBoxWidget",
      parent: "grpbxDefaultCMAVendor",
      Label: "Vendor:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorDCV_PROPERTIES

      // END_USER_CODE-USER_txtVendorDCV_PROPERTIES
    },
    txtVndr: {
      name: "txtVndr",
      type: "TextBoxWidget",
      parent: "grpbxDefaultCMAVendor",
      ColSpan: "2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVndr_PROPERTIES

      Readonly: true, 
      Enabled : false
      // END_USER_CODE-USER_txtVndr_PROPERTIES
    },
    txtWhouse: {
      name: "txtWhouse",
      type: "TextBoxWidget",
      parent: "grpbxControlNumbers",
      Label: "Whouse:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWhouse_PROPERTIES

      // END_USER_CODE-USER_txtWhouse_PROPERTIES
    },
    txtZipMA: {
      name: "txtZipMA",
      type: "TextBoxWidget",
      parent: "grpbxMailingAddress",
      Label: "Zip:",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtZipMA_PROPERTIES

      // END_USER_CODE-USER_txtZipMA_PROPERTIES
    },
    txtZipPA: {
      name: "txtZipPA",
      type: "TextBoxWidget",
      parent: "grpbxPhysicalAddress",
      Label: "Zip:",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtZipPA_PROPERTIES

      // END_USER_CODE-USER_txtZipPA_PROPERTIES
    },
    grpbxBuyingPointProfile: {
      name: "grpbxBuyingPointProfile",
      type: "GroupBoxWidget",
      parent: "BuyingPointProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxBuyingPointProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxBuyingPointProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "BuyingPointProfile",
      ColSpan: "4",
      Height: "",
      Width: "",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#VendorLookup":{},
        "ContractManagement#MainMenu":{},
        "SystemMaintenanceMasterManagement#BuyingPointSetup":{},
      },
      REVERSE: {
        "ContractManagement#VendorLookup":{},
        "ContractManagement#MainMenu":{},
        "SystemMaintenanceMasterManagement#BuyingPointSetup":{},
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnVendorCCV: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
    btnVendorDCV: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    document.getElementById('chkbocCharacteristics7').parentElement.style.display = 'none'
    setData(thisObj, 'pre_vnum', '')
    setData(thisObj, 'pre_vendornum', '')
    setData(thisObj, 'SpecialChkBox', '')
    setData(thisObj, 'PrevSpecialChkBox', '')
    disable(thisObj, 'txtBeg1')
    disable(thisObj, 'txtEnd1')
    let ParentValue = getData(thisObj, 'BPSetupBuyingPointData');
    cmdOk_Caption = ParentValue == undefined || ParentValue == null ? '' : (ParentValue.btnOk).toLocaleUpperCase()
    setData(thisObj, 'ParentData', ParentValue)
    
    Form_Load()
    //removed formactive and paased it in form load to make syncronouse calls Fixed As part of UAT BUG_543829
    document.getElementsByClassName("grpbxFTPAdmin")[0].style.visibility = "hidden";
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  useEffect(() => {
    let vendor_lookup = getData(thisObj, 'vendorDetails')
    if (vendor_lookup !== null && vendor_lookup !== undefined && vendor_lookup !== '') {
      let BtnName = getData(thisObj, 'BtnVendorName')
      if(BtnName == 'btnVendorCCV'){
        thisObj.setFieldValue("txtVendorCCV", vendor_lookup.VendorNumber);
        bFillRemitToList(vendor_lookup.VendorNumber)
        thisObj.setFieldValue("txtVendorNameCCV", vendor_lookup.vendorName)
      }
      if(BtnName == 'btnVendorDCV'){
        thisObj.setFieldValue("txtVendorDCV", vendor_lookup.VendorNumber);
        bFillDefaultCMARemitToList(vendor_lookup.VendorNumber)
        thisObj.setFieldValue("txtVndr", vendor_lookup.vendorName)
      }
      setData(thisObj, 'vendorDetails', null) 
    }
  },[getData(thisObj, 'vendorDetails')]);


  useEffect (() =>{
    return () =>{
      setData(thisObj, "closingOfBuyingPointProfile", true)
    }
  })

  
  const [loading, setLoading] = useState(false);//Fixed As part of UAT BUG_543829

  const form_Set = async () =>{//Fixed As part of UAT BUG_543829 made async
   let ParentValue = getData(thisObj, 'ParentData')
   cmdOk_Caption = (ParentValue.btnOk).toLocaleUpperCase()
    disable(thisObj, 'txtVendorDCV')
    disable(thisObj, 'ddRemitToDCV')
    disable(thisObj, 'btnVendorDCV')
    disable(thisObj, 'ddFedWhseLic')
    disable(thisObj, 'txtCCCLoc')
    if (cmdOk_Caption == 'ADD') {
      document.getElementsByClassName('btnOk')[0].childNodes[0].childNodes[0].innerText = "Add";
      setValue(thisObj, 'lblCompanyValue', CompanyName())
      setValue(thisObj, 'lblAreaValue', ParentValue.lblArea)
      setValue(thisObj, 'ddArea', ParentValue.AreaId)
      setData(thisObj, 'AreaID', ParentValue.AreaId)
      setValue(thisObj, 'ddCollectionPt', ParentValue.CollectionPointId)
      setData(thisObj, 'CollectionPointId', ParentValue.CollectionPointId)
      setValue(thisObj, 'txtNext', '0000000')
      setValue(thisObj, 'txtBeg', '0000000')
      setValue(thisObj, 'txtEnd', '0000000')
      setValue(thisObj, 'txtWhouse', '000000')
      setValue(thisObj, 'txtCheck', '000000')
      setValue(thisObj, 'txtBegCheck', '000000')
      setValue(thisObj, 'txtEndCheck', '000000')
      if(ParentValue.lblInactive_Visible == true){
        document.getElementsByClassName("lblLocationRecordIsInactive")[0].style.visibility = 'visible';
      }
    }
    else if (cmdOk_Caption== 'UPDATE') {
      document.getElementsByClassName('btnOk')[0].childNodes[0].childNodes[0].innerText = "Update";
      if(ParentValue != undefined && ParentValue != null){ 
        setValue(thisObj, 'lblAreaValue', ParentValue.lblArea)
        setValue(thisObj, 'lblCompanyValue', CompanyName())
        setValue(thisObj, 'txtTimerInterval', ParentValue.txtTimerInterval)
        setValue(thisObj, 'txtRetries', ParentValue.txtRetryLimit)
        setValue(thisObj, 'ddArea', ParentValue.AreaId)
        setValue(thisObj, 'ddCollectionPt', ParentValue.CollectionPointId)
        setValue(thisObj, 'txtID', ParentValue.txtID)
        setValue(thisObj, 'txtProfitCenter', ParentValue.profit_center)
        setValue(thisObj, 'txtName', ParentValue.txtBuyingPointName)
        setValue(thisObj, 'txtGroupID', ParentValue.txtGroupID)      
        setValue(thisObj, 'lblName', ParentValue.txtContactName)
        setValue(thisObj, 'txtPhone', ParentValue.txtContactPhone)
        setValue(thisObj, 'txtFedBuyPt', ParentValue.txtMAFedBuyPoint)
        setValue(thisObj, 'ddFedWhseLic', ParentValue.ddFedLic)
        setValue(thisObj, 'txtCCCLoc', ParentValue.txtCCCLocNumber)
        setValue(thisObj, 'txtAddressPA', ParentValue.txtPAAddress)
        setValue(thisObj, 'txtCityPA', ParentValue.txtPACity)
        setValue(thisObj, 'ddStatePA', ParentValue.lstPhyStateAbbr)
        setValue(thisObj, 'txtZipPA', ParentValue.txtPAZipCode)
        setValue(thisObj, 'txtAddressMA', ParentValue.txtMAAddress)
        setValue(thisObj, 'txtCityMA', ParentValue.txtMACity)
        setValue(thisObj, 'ddStateMA', ParentValue.lstMailStateAbbr)        
        setValue(thisObj, 'txtZipMA', ParentValue.txtMAZipCode)
        setValue(thisObj, 'txtNext', ParentValue.txtNext1007)
        setValue(thisObj, 'txtBeg', ParentValue.txtBeg1007)
        setValue(thisObj, 'txtEnd', ParentValue.txtEnd1007)
        setValue(thisObj, 'txtWhouse', ParentValue.txtNextWhouseCntl)
        setValue(thisObj, 'txtCheck', ParentValue.txtNextCheck)
        setValue(thisObj, 'txtBegCheck', ParentValue.txtBegCheck)
        setValue(thisObj, 'txtEndCheck', ParentValue.txtEndCheck)
        setValue(thisObj, 'txtBalAcctResp', ParentValue.txtBalAcctResp)
        setValue(thisObj, 'txtBalAcctDept', ParentValue.txtBalAcctDept)
        setValue(thisObj, 'txtPIAcctResp', ParentValue.txtPlAcctResp)
        setValue(thisObj, 'txtPIAcctDept', ParentValue.txtPlAcctDept)
        setValue(thisObj, 'txtVendorDCV', ParentValue.txtDefaultCMAVendor)
        bFillDefaultCMARemitToList(ParentValue.txtDefaultCMAVendor)
        setValue(thisObj, 'ddRemitToDCV', ParentValue.ddDefaultCMARemitTo)
        setValue(thisObj, 'ddInspectionGramEntry', ParentValue.ddGramEntry)
               if(ParentValue.ddTransferGramEntry_index == '0'){
          setValue(thisObj, 'ddTransferGramEntry', 'Yes')
        }
        if(ParentValue.ddTransferMoistEntry_index == '0'){
          setValue(thisObj, 'ddTransferMoistureEntry', 'Yes')
        }
        setValue(thisObj, 'ddInternetType', ParentValue.ddInternetTypeName)
        setValue(thisObj, 'ddIrrigatedDryland', ParentValue.ddIrrigatedDryland)
        setValue(thisObj, 'ddVicamLab', ParentValue.ddVicamLab)
        setValue(thisObj, 'txtBeg1', ParentValue.txtBeg1_1007)
        setValue(thisObj, 'txtEnd1', ParentValue.txtEnd1_1007)
        setValue(thisObj, 'txtServiceProvider', ParentValue.txtServiceProvider)
        setValue(thisObj, 'txtVendorCCV', ParentValue.txtVendor)
        bFillRemitToList(ParentValue.txtVendor)
        setValue(thisObj, 'ddRemitToCCV', ParentValue.lstRemitTo)
        setValue(thisObj, 'lblAddedByValue', ParentValue.lblAddedBy[0].lblAddedById + '  ' + ParentValue.lblAddedBy[0].txtDate)
        setValue(thisObj, 'lblChangedByValue', ParentValue.lblChangedBy[0].lblChangedById + '  ' + ParentValue.lblChangedBy[0].txtDate)

        if(ParentValue.txtID_enabled == false){
          disable(thisObj, 'txtID')
        }
        if(ParentValue.lblInactive_Visible == true){
          document.getElementsByClassName("lblLocationRecordIsInactive")[0].style.visibility = 'visible';
        }

        let chkCharacteristicslst = []
        if(ParentValue.chkNonGPCStorage == '1'){
          chkCharacteristicslst.push('1')
          enable(thisObj, 'ddFedWhseLic')
          enable(thisObj, 'txtCCCLoc')
        }
        if(ParentValue.chkInOut == '1'){chkCharacteristicslst.push('2')}
        if(ParentValue.chkLeased == '1'){chkCharacteristicslst.push('3')}
        if(ParentValue.chkDPRExclusion == '1'){chkCharacteristicslst.push('4')}
        if(ParentValue.chkGPCStorage == '1'){
          chkCharacteristicslst.push('5')
          enable(thisObj, 'ddFedWhseLic')
          enable(thisObj, 'txtCCCLoc')
        }
        if(ParentValue.chkGPCOwned == '1'){chkCharacteristicslst.push('6')}
        if(ParentValue.chkShelling == '1'){chkCharacteristicslst.push('7')}
        if(ParentValue.chkEFV95UploadSelect == '1'){chkCharacteristicslst.push('8')}
        if(ParentValue.Check_KCTRANS == '1'){chkCharacteristicslst.push('9')}
        if(ParentValue.Check_EWRTRANS == '1'){chkCharacteristicslst.push('10')}
        if(ParentValue.chkCMAInd == '1'){
          chkCharacteristicslst.push('11')
          enable(thisObj, 'txtVendorDCV')
          enable(thisObj, 'ddRemitToDCV')
          enable(thisObj, 'btnVendorDCV')
        }
        if(ParentValue.chkOneDoc == '1'){chkCharacteristicslst.push('12')}

        if(chkCharacteristicslst.includes('1')){
          setData(thisObj, 'PrevSpecialChkBox', '1')
        }
        else if(chkCharacteristicslst.includes('2')){
          setData(thisObj, 'PrevSpecialChkBox', '2')
        }
        else if(chkCharacteristicslst.includes('5')){
          setData(thisObj, 'PrevSpecialChkBox', '5')
        }

        setValue(thisObj, 'chkbocCharacteristics', chkCharacteristicslst)

        if(ParentValue.optFTPLoggingOn == true){
          setValue(thisObj, 'radioLogging', '1')
        }
        else if(ParentValue.optFTPLoggingOff == true){
          setValue(thisObj, 'radioLogging', '2')
        }

        if(ParentValue.optFTP0 == true){
          setValue(thisObj, 'radioFTPMethod', '1')
        }
        else if(ParentValue.optFTP1 == true){
          setValue(thisObj, 'radioFTPMethod', '2')
        }
        
        if(ParentValue.chkAdditionalRange == '1'){
          setValue(thisObj, 'chkboxAdditional1007', '1')
          enable(thisObj, 'txtBeg1')
          enable(thisObj, 'txtEnd1')
        }
        if(ParentValue.chkAutoTransferNum == '1'){setValue(thisObj, 'chkboxUseAutomaticTransfer', '1')}
      }

      //Fixed As part of UAT BUG_543829 Added Missing code as per VB Code.
      if(ParentValue.txtID === (ParentValue.CollectionPointId)){
        disable(thisObj, 'ddArea')
       }

      if(ParentValue?.disableOld === true){
        disable(thisObj, 'txtBalAcctResp')
        disable(thisObj, 'txtBalAcctDept')
        disable(thisObj, 'txtPIAcctResp')
        disable(thisObj, 'txtPIAcctDept')
        document.getElementById('chkbocCharacteristics0').setAttribute('disabled','')
        document.getElementById('chkbocCharacteristics1').setAttribute('disabled','')
        document.getElementById('chkbocCharacteristics2').setAttribute('disabled','')
        document.getElementById('chkbocCharacteristics3').setAttribute('disabled','')
        document.getElementById('chkbocCharacteristics4').setAttribute('disabled','')
        document.getElementById('chkbocCharacteristics5').setAttribute('disabled','')
        document.getElementById('chkbocCharacteristics6').setAttribute('disabled','')
        document.getElementById('chkbocCharacteristics8').setAttribute('disabled','')
        document.getElementById('chkbocCharacteristics9').setAttribute('disabled','')
        document.getElementById('chkbocCharacteristics10').setAttribute('disabled','')
        document.getElementById('chkbocCharacteristics11').setAttribute('disabled','')
        document.getElementById('ddCollectionPt').setAttribute('disabled','')
        disable(thisObj, 'txtID')
        disable(thisObj, 'txtProfitCenter')
        disable(thisObj, 'txtName')
        disable(thisObj, 'txtFedBuyPt')
        disable(thisObj, 'ddFedWhseLic')
        disable(thisObj, 'txtCCCLoc')
      }
    }
  }

  // START_USER_CODE-USER_METHODS
  const Form_Load = async() =>{//Fixed As part of UAT BUG_543829 made async Function
    try{
    setLoading(true)
    let ParentValue = getData(thisObj, 'ParentData')
    setData(thisObj, 'mbFillingScreen', true)
    setData(thisObj, 'mbSuccessfulLoad', true)
    setValue(thisObj, 'lblAddedBy', '')
    setValue(thisObj, 'lblChangedBy', '')
    setValue(thisObj, 'txtVendorNameCCV', '')
    setValue(thisObj, 'txtVndr', '')
    document.getElementsByClassName("lblLocationRecordIsInactive")[0].style.visibility = 'hidden';
    if(ParentValue.cmdActive == "Make Inactive"){
      document.getElementsByClassName('btnMakeInactive')[0].childNodes[0].childNodes[0].innerText = "Make Inactive";
    }
    else {
      document.getElementsByClassName('btnMakeInactive')[0].childNodes[0].childNodes[0].innerText = "RE-Activate";
    }
               
  if(await bFillStateList() == false){//Fixed As part of UAT BUG_543829 made async Await
    setData(thisObj, 'mbSuccessfulLoad', false)
    SuccessfulLoad()
    return
  }
  if(await bFillVicam() == false){//Fixed As part of UAT BUG_543829 made async Await
    setData(thisObj, 'mbSuccessfulLoad', false)
    SuccessfulLoad()
    return
  }
  if(await bIrrigatedDryland() == false){//Fixed As part of UAT BUG_543829 made async Await
    setData(thisObj, 'mbSuccessfulLoad', false)
    SuccessfulLoad()
    return
  }
  if(await bFillGramEntry() == false){//Fixed As part of UAT BUG_543829 made async Await
    setData(thisObj, 'mbSuccessfulLoad', false)
    SuccessfulLoad()
    return
  }

  if(await bFillAreaList() == false){//Fixed As part of UAT BUG_543829 made async Await
    setData(thisObj, 'mbSuccessfulLoad', false)
    SuccessfulLoad()
    return
  }
  if(await bFillCollectionPointList(ParentValue.AreaId) == false){//Fixed As part of UAT BUG_543829 made async Await
    setData(thisObj, 'mbSuccessfulLoad', false)
    SuccessfulLoad()
    return
  }
  if(await bFillFedLicList() == false){//Fixed As part of UAT BUG_543829 made async Await
    setData(thisObj, 'mbSuccessfulLoad', false)
    SuccessfulLoad()
    return
  }
          
  if(await bFillInternetTypeList() == false){//Fixed As part of UAT BUG_543829 made async Await
    setData(thisObj, 'mbSuccessfulLoad', false)
    SuccessfulLoad()
    return
  }

  if(await bFillTransferGramEntry() == false){//Fixed As part of UAT BUG_543829 made async Await
    setData(thisObj, 'mbSuccessfulLoad', false)
    SuccessfulLoad()
    return
  }
          
  //cboTransferGramEntry.ListIndex = 1

  if(await bFillTransferMoistEntry() == false){//Fixed As part of UAT BUG_543829 made async Await
    setData(thisObj, 'mbSuccessfulLoad', false)
    SuccessfulLoad()
    return
  }

  let txtNext1007Value = getValue(thisObj, 'txtNext')
  if(txtNext1007Value != undefined && txtNext1007Value.length < 7){
    txtNext1007Value = '0'.repeat(7 - txtNext1007Value.length) + txtNext1007Value
  }
  setValue(thisObj, 'txtNext', txtNext1007Value)

  let txtWhouseValue = getValue(thisObj, 'txtWhouse')
  if(txtWhouseValue != undefined && txtWhouseValue.length < 6){
    txtWhouseValue = '0'.repeat(6 - txtWhouseValue.length) + txtWhouseValue
  }
  setValue(thisObj, 'txtWhouse', txtWhouseValue)

  let txtCheckValue = getValue(thisObj, 'txtCheck')
  if(txtCheckValue != undefined && txtCheckValue.length < 6){
    txtCheckValue = '0'.repeat(6 - txtCheckValue.length) + txtCheckValue
  }
  setValue(thisObj, 'txtCheck', txtCheckValue)

  await form_Set() //Fixed As part of UAT BUG_543829 made async Await
  await Form_Activate() //Fixed As part of UAT BUG_543829 made async Await
  if(loading){//Fixed As part of UAT BUG_543829 made async Await
    setLoading(false)
  }
  }
  catch(err){	//Fixed As part of UAT BUG_543829 made async Await
    setLoading(false)
    if(err instanceof EvalError){	
      showMessage(thisObj, err.message)	
    }else{	
      showMessage(thisObj, "Error on form load event"	)	
    }
    return false;
  }finally{
    setLoading(false)
  }
  }

  const bFillStateList = async () => {
    let js = []
    let obj = {}
    let state_abbr = null
    let LstrList = [] 
    await SystemMaintenanceMasterManagementService.RetrieveStateControlDetails(state_abbr).then(response=>{
     LstrList = response
    })
    if(LstrList != undefined && LstrList.length > 0) {
      for (let i = 0; i < LstrList.length; i++) {
        obj = { key:LstrList[i].state_abbr, description: LstrList[i].state_name }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddStatePA: {
            ...state["ddStatePA"],
            valueList: js,
          }
        }
      })
      thisObj.setState(current => {
        return {
          ...current,
          ddStateMA: {
            ...state["ddStateMA"],
            valueList: js,
          }
        }
      })
      let ParentValue = getData(thisObj, 'ParentData')
      cmdOk_Caption = (ParentValue.btnOk).toLocaleUpperCase()
      if (cmdOk_Caption == 'ADD') {
        setValue(thisObj, 'ddStatePA', js[0].key);
        setValue(thisObj, 'ddStateMA', js[0].key);
        bFillCountyList(js[0].key)
      }
      if(cmdOk_Caption == 'UPDATE'){
        bFillCountyList(ParentValue.lstPhyStateAbbr)
      }
      
      return true;
    }
    else {
      return false;
    }
  }

  const  bFillCollectionPointList = async (area_id) =>{
    let js = []
    let obj = {}
    setValue(thisObj, 'ddCollectionPt',"")
    let state_abbr = null
    let comp_id = compIdFromLS()
    let crop_year = cropYearFromLS()
    let coll_pt_id = null
    let area_ID = area_id
    let LstrList = []
    await SystemMaintenanceMasterManagementService.RetrieveCollectionPointControlDetails(comp_id, crop_year, coll_pt_id, area_ID).then(response=>{
      LstrList = response
    })
    if(LstrList != undefined && LstrList.length > 0){
      for(let i=0; i <LstrList.length; i++){
        obj = { key :  LstrList[i].coll_pt_id, description : LstrList[i].coll_pt_id}
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddCollectionPt: {
            ...state["ddCollectionPt"],
            valueList: js,
          }
        }
      })
      let data1 = getData(thisObj, 'DDAreaChangeFlag')//Fixed As part of UAT BUG_543829 made async Await
      if( data1 == true){//Fixed As part of UAT BUG_543829 made async Await
        setValue(thisObj, 'ddCollectionPt', js[0].key)
        setData(thisObj, 'DDAreaChangeFlag', false)
      }
      return true;
    }
    else{
      thisObj.setState(current => {
        return {
          ...current,
          ddCollectionPt: {
            ...state["ddCollectionPt"],
            valueList: [],
          }
        }
      })
      return false;
    }
  }

  const  bFillAreaList = async () =>{
    let js = []
    let obj = {}
    setValue(thisObj, 'ddArea',"")
    let area_id = getValue(thisObj,"lblArea")
     let LstrList = await SettlementService.RetrieveAreaControlDetails(null)//Fixed As part of UAT BUG_543829 made async Await
      if(LstrList != undefined && LstrList.length > 0){
      for(let i=0; i <LstrList.length; i++){
        obj = { key : LstrList[i].areaId, description : LstrList[i].areaId}
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddArea: {
            ...state["ddArea"],
            valueList: js,
          }
        }
      })
      return true;
    }
    else{
      return false;
    }
  }

  const  bFillRemitToList = async (vnum) =>{
    let isbFillRemitToList = false
    let js = []
    let obj = {}
    setValue(thisObj, 'ddRemitToCCV', '')
    setValue(thisObj, 'txtVendorNameCCV', '')
    
    let LstrList = []
    await ContractManagementService.RetrieveVendorByNumber(vnum).then(response =>{
      LstrList = response
    })
    if(LstrList != undefined && LstrList.length > 0){
      let lblVendor = LstrList[0].name
      setValue(thisObj, 'txtVendorNameCCV', lblVendor)
    }

    await ContractManagementService.RetieveRemitDetails(vnum).then(response =>{
      LstrList = response
    })

    if(LstrList != undefined && LstrList.length > 0){
      for(let i=0; i <LstrList.length; i++){

        let Value = LstrList[i].remittoid + '-' + LstrList[i].name + '-' + LstrList[i].city + ',' + LstrList[i].state

        Value = Value.replace(/(\w)(\w*)/g,
        function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});

        obj = { key : LstrList[i].remittoid, description : Value }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddRemitToCCV: {
            ...state["ddRemitToCCV"],
            valueList: js,
          }
        }
      })
      isbFillRemitToList = true 
      setData(thisObj, 'pre_vendornum', vnum)
      setValue(thisObj, 'ddRemitToCCV', js[0].key)
    }
    else{
      isbFillRemitToList = false
    }
    return isbFillRemitToList;
  }

  const  bFillCountyList = async (stateAbbrevations) =>{
    let js = []
    let obj = {}
    let buyingPointId = null
    let filterStatus = null
    let county_id = null
    setValue(thisObj, 'ddCountyPA',"")
    let LstrCountyList = []
    await ContractManagementService.RetrieveCountyControlDetails(buyingPointId,filterStatus,stateAbbrevations,county_id).then(response=>{
      LstrCountyList = response
    })
    if(LstrCountyList != undefined && LstrCountyList.length > 0){
      for(let i=0; i <LstrCountyList.length; i++){
        obj = { key : LstrCountyList[i].countyId, description : LstrCountyList[i].countyName}
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddCountyPA: {
            ...state["ddCountyPA"],
            valueList: js,
          }
        }
      })
      let ParentValue = getData(thisObj, 'ParentData')
      cmdOk_Caption = (ParentValue.btnOk).toLocaleUpperCase()
      if (cmdOk_Caption == 'ADD') {
        setValue(thisObj, 'ddCountyPA', js[0].key);
      }
      if (cmdOk_Caption == 'UPDATE') {
        setValue(thisObj, 'ddCountyPA', ParentValue.lstCountyID);
      }
      return true;
    }
    else{
      thisObj.setState(current => {
        return {
          ...current,
          ddCountyPA: {
            ...state["ddCountyPA"],
            valueList: [],
          }
        }
      })
      return false;
    }
  }

  const bFillFedLicList = async () => {
    let js = []
    let obj = {}
    let fed_whouse_license = null
    let buy_pt_id = null
    let lstrxml = []
    setValue(thisObj, 'ddFedWhseLic', '')
    await SystemMaintenanceMasterManagementService.RetrieveWareHouseLicenseControlDetails(fed_whouse_license, buy_pt_id).then(response=>{
      lstrxml = response
    })
    if (lstrxml != undefined && lstrxml.length > 0 && lstrxml[0].whouse_license_controls != undefined) {
      for (let i = 0; i < lstrxml[0].whouse_license_controls.length; i++) {
        obj = { key: lstrxml[0].whouse_license_controls[i].fed_whouse_license, description: lstrxml[0].whouse_license_controls[i].fed_whouse_license }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddFedWhseLic: {
            ...current["ddFedWhseLic"],
            valueList: js,
          }
        }
      })

      let ChkValue = getValue(thisObj, 'chkbocCharacteristics')
      if(ChkValue.includes('1') || ChkValue.includes('5')){
        setValue(thisObj, 'ddFedWhseLic', js[0].key);
      }
      return true;
    }
    else {
      return false;
    }
  }

  const bFillDefaultCMARemitToList = async (vnum) => {
    let isbFillDefaultCMARemitToList = false
    let js = []
    let obj = {}
    setValue(thisObj, 'ddRemitToDCV', '')
   
    let LstrList = []
    await ContractManagementService.RetrieveVendorByNumber(vnum).then(response =>{
      LstrList = response
    })
    if (LstrList != undefined && LstrList.length > 0) {
      let lblVendor = LstrList[0].name
      setValue(thisObj, 'txtVndr', lblVendor)
    }

    await ContractManagementService.RetieveRemitDetails(vnum).then(response =>{
      LstrList = response
    })
    if (LstrList != undefined && LstrList.length > 0) {
      for (let i = 0; i < LstrList.length; i++) {
        let Value = LstrList[i].remittoid + '-' + LstrList[i].name + '-' + LstrList[i].city + ',' + LstrList[i].state

        Value = Value.replace(/(\w)(\w*)/g,
        function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});

        obj = { key : LstrList[i].remittoid, description : Value }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddRemitToDCV: {
            ...state["ddRemitToDCV"],
            valueList: js,
          }
        }
      })
      isbFillDefaultCMARemitToList = true
      setData(thisObj, 'pre_vnum', vnum)
      setValue(thisObj, 'ddRemitToDCV', js[0].key)
    }
    return isbFillDefaultCMARemitToList;
  }
  
const SuccessfulLoad = async () =>{//Fixed As part of UAT BUG_543829 made async Function
  if(getData(thisObj, 'mbSuccessfulLoad') == false){
    document.getElementById('SystemMaintenanceMasterManagement_BuyingPointProfilePopUp').childNodes[0].click();
  }
}

const EnableDisableVendor = async () =>{//Fixed As part of UAT BUG_543829 made async 

  let ChkBoxValue = getValue(_kaledo.thisObj, 'chkbocCharacteristics')

  if(ChkBoxValue.includes('6')){
    if(ChkBoxValue.includes('3')){
      enable(thisObj, 'txtVendorCCV')
      enable(thisObj, 'ddRemitToCCV')
      enable(thisObj, 'btnVendorCCV')
    }
    else{
      setValue(thisObj, 'txtVendorCCV', '')
      setValue(thisObj, 'ddRemitToCCV', '')
      setValue(thisObj, 'txtVendorNameCCV', '')
      disable(thisObj, 'txtVendorCCV')
      disable(thisObj, 'ddRemitToCCV')
      disable(thisObj, 'btnVendorCCV')
    }
  }
  else if(ChkBoxValue.includes('6') == false){
    enable(thisObj, 'txtVendorCCV')
    enable(thisObj, 'ddRemitToCCV')
    enable(thisObj, 'btnVendorCCV')
  }
}

const Form_Activate = async () => {
    let ParentValue = getData(thisObj, 'ParentData')
    cmdOk_Caption = (ParentValue.btnOk).toLocaleUpperCase()
    let lstrxml = []

    setData(thisObj, 'AUTH_FLAG', false)

    let func_id = 'PN0020'
    let access = 'D'
    let func_sub_id = null
    let buy_pt_id = null

    await ContractManagementService.RetrieveAccessPermissionDetails(func_id, func_sub_id, access, buy_pt_id).then(response =>{
      lstrxml = response
    })
    if (lstrxml != undefined && lstrxml.length > 0) {
      if (lstrxml[0].permission == 'N') {
        document.getElementsByClassName("btnMakeInactive")[0].style.visibility = "hidden";
      }
      else {
        document.getElementsByClassName("btnMakeInactive")[0].style.visibility = "visible";
      }
    }

    func_id = 'PN0020'
    func_sub_id = '002'
    access = 'D'
    buy_pt_id = null

    lstrxml =  await ContractManagementService.RetrieveAccessPermissionDetails(func_id, func_sub_id, access, buy_pt_id)//Fixed As part of UAT BUG_543829 made async await
    if (lstrxml != undefined && lstrxml.length > 0) {
      if (lstrxml[0].permission == 'N') {
        setData(thisObj, 'AUTH_FLAG', false)
        document.getElementById('chkbocCharacteristics8').setAttribute('disabled','')
        document.getElementById('chkbocCharacteristics9').setAttribute('disabled','')
      }
      else {
        setData(thisObj, 'AUTH_FLAG', true)
        document.getElementById('chkbocCharacteristics8').removeAttribute('disabled','')
        document.getElementById('chkbocCharacteristics9').removeAttribute('disabled','')
      }
    }

    func_id = 'PN0020'
    func_sub_id = '006'
    access = 'U'
    buy_pt_id = getValue(thisObj, 'txtID')

    lstrxml = await ContractManagementService.RetrieveAccessPermissionDetails(func_id, func_sub_id, access, buy_pt_id)//Fixed As part of UAT BUG_543829 made async await
    if (lstrxml != undefined && lstrxml.length > 0) {
      // if (cmdOk_Caption != 'ADD' && lstrxml[0].permission == 'Y') {
      //   document.getElementsByClassName("grpbxFTPAdmin")[0].style.visibility = "visible";
      // }
      // else {
      //   document.getElementsByClassName("grpbxFTPAdmin")[0].style.visibility = "hidden";
      // }
    }

    await EnableDisableVendor() //Fixed As part of UAT BUG_543829 made async await
}

const onddAreaChange = async () =>{//Fixed As part of UAT BUG_543829 made async function
  try{
    // setValue(thisObj, 'lblAreaValue', getValue(thisObj, 'ddArea'))// Commented if we change the area dropdown label also change but in old its not changing.Need to check.
    setData(thisObj, 'DDAreaChangeFlag', true)
    let area_id = getValue(thisObj,"ddArea")
    await bFillCollectionPointList(area_id)//Fixed As part of UAT BUG_543829 made async await
  }
  catch(err){	
    if(err instanceof EvalError){	
      showMessage(thisObj, err.message)	
    }else{	
      showMessage(thisObj, "Error on Dropdown Area change event"	)	
    }
    return false
  }
  return true;
}
thisObj.onddAreaChange = onddAreaChange;

const onddStatePAChange = () =>{
  try{
    let stateAbbrevations = getValue(thisObj, "ddStatePA")
    if(bFillCountyList(stateAbbrevations) == true){
      setData(thisObj, 'mbSuccessfulLoad', false)
    }
    SuccessfulLoad()
  }
  catch(err){	
    if(err instanceof EvalError){	
      showMessage(thisObj, err.message)	
    }else{	
      showMessage(thisObj, "Error on Dropdown State change event"	)	
    }
    return false
  }
  return true;
}
thisObj.onddStatePAChange = onddStatePAChange;

const onchkboxAdditional1007Change = () =>{
  try{
    let chkValue = getValue(thisObj, 'chkboxAdditional1007')
    if(chkValue == '1'){
      enable(thisObj, 'txtBeg1')
      enable(thisObj, 'txtEnd1')
    }
    else{
      setValue(thisObj, 'txtBeg1', '')
      setValue(thisObj, 'txtEnd1', '')
      disable(thisObj, 'txtBeg1')
      disable(thisObj, 'txtEnd1')
    }
  }
  catch(err){	
    if(err instanceof EvalError){	
      showMessage(thisObj, err.message)	
    }else{	
      showMessage(thisObj, "Error on checkbox additional range change event"	)	
    }
    return false
  }
  return true;
}
thisObj.onchkboxAdditional1007Change = onchkboxAdditional1007Change;


const onchkbocCharacteristicsChange = () =>{
  try{
    let chkValue = getValue(thisObj, 'chkbocCharacteristics')
    let PrevSpecialChkValue = getData(thisObj, 'PrevSpecialChkBox')
    let spclLst = ['1','2','5']

    if(chkValue.includes('1') && PrevSpecialChkValue != '1'){
      setData(thisObj, 'SpecialChkBox', '1')
    }
    else if(chkValue.includes('2') && PrevSpecialChkValue != '2'){
      setData(thisObj, 'SpecialChkBox', '2')
    }
    else if(chkValue.includes('5') && PrevSpecialChkValue != '5'){
      setData(thisObj, 'SpecialChkBox', '5') 
    }

    let SpecialChkValue = getData(thisObj, 'SpecialChkBox')

    if(spclLst.includes(SpecialChkValue) && PrevSpecialChkValue != '' && chkValue.includes(PrevSpecialChkValue) && SpecialChkValue != PrevSpecialChkValue){
      let index = chkValue.indexOf(PrevSpecialChkValue);
        if (index > -1) {
          chkValue.splice(index, 1);
        }
      setValue(thisObj, 'chkbocCharacteristics', chkValue)
      setData(thisObj, 'PrevSpecialChkBox', SpecialChkValue)
    }

    if(chkValue.includes('1') && PrevSpecialChkValue == ''){
      setData(thisObj, 'PrevSpecialChkBox', '1')
    }
    else if(chkValue.includes('2') && PrevSpecialChkValue == ''){
      setData(thisObj, 'PrevSpecialChkBox', '2')
    }
    else if(chkValue.includes('5') && PrevSpecialChkValue == ''){
      setData(thisObj, 'PrevSpecialChkBox', '5')
    }

    if(chkValue.includes('11')){
      enable(thisObj, 'txtVendorDCV')
      enable(thisObj, 'ddRemitToDCV')
      enable(thisObj, 'btnVendorDCV')
    }
    if(chkValue.includes('11') == false){
      setValue(thisObj, 'txtVendorDCV', '')
      setValue(thisObj, 'ddRemitToDCV', '')
      setValue(thisObj, 'txtVndr', '')
      disable(thisObj, 'txtVendorDCV')
      disable(thisObj, 'ddRemitToDCV')
      disable(thisObj, 'btnVendorDCV')
    }

    if(chkValue.includes('1') || chkValue.includes('5')){
      enable(thisObj,'ddFedWhseLic')
      //If cboFedLic.ListIndex = -1 And cboFedLic.ListCount > 0 Then
      //  cboFedLic.ListIndex = 0
      //End If
      enable(thisObj, 'txtCCCLoc')
    }
    if(chkValue.includes('1') == false && chkValue.includes('5') == false){
      setValue(thisObj, 'txtCCCLoc', '')
      disable(thisObj, 'ddFedWhseLic')
      //cboFedLic.ListIndex = -1
      disable(thisObj, 'txtCCCLoc')
    }

    EnableDisableVendor()
    bFillFedLicList()
  }
  catch(err){	
    if(err instanceof EvalError){	
      showMessage(thisObj, err.message)	
    }else{	
      showMessage(thisObj, "Error on Checkbox characteristics change event"	)	
    }
    return false
  }
  return true;
}
thisObj.onchkbocCharacteristicsChange = onchkbocCharacteristicsChange;

const onlblAreaValueChange = () =>{
  try{
    let area_id = getValue(thisObj,"ddArea")
    if(bFillCollectionPointList(area_id) == false){
      setData(thisObj, 'mbSuccessfulLoad', false)
    }
    SuccessfulLoad()
  }
  catch(err){	
    if(err instanceof EvalError){	
      showMessage(thisObj, err.message)	
    }else{	
      showMessage(thisObj, "Error on Area change event"	)	
    }
    return false
  }
  return true;
}
thisObj.onlblAreaValueChange = onlblAreaValueChange;

const ontxtBegChange = () =>{
  let Value = getValue(thisObj, 'txtBeg')
  setValue(thisObj,"txtBeg",validateTextBox(Value))
}
thisObj.ontxtBegChange = ontxtBegChange;

const ontxtBeg1Change = () =>{
  let Value = getValue(thisObj, 'txtBeg1')
  setValue(thisObj,"txtBeg1",validateTextBox(Value))
}
thisObj.ontxtBeg1Change = ontxtBeg1Change;

const ontxtBegCheckChange = () =>{
  let Value = getValue(thisObj, 'txtBegCheck')
  setValue(thisObj,"txtBegCheck",validateTextBox(Value))
}
thisObj.ontxtBegCheckChange = ontxtBegCheckChange;

const ontxtEndChange = () =>{
  let Value = getValue(thisObj, 'txtEnd')
  setValue(thisObj,"txtEnd",validateTextBox(Value))
}
thisObj.ontxtEndChange = ontxtEndChange;

const ontxtEnd1Change = () =>{
  let Value = getValue(thisObj, 'txtEnd1')
  setValue(thisObj,"txtEnd1",validateTextBox(Value))
}
thisObj.ontxtEnd1Change = ontxtEnd1Change;

const ontxtEndCheckChange = () =>{
  let Value = getValue(thisObj, 'txtEndCheck')
  setValue(thisObj,"txtEndCheck",validateTextBox(Value))
}
thisObj.ontxtEndCheckChange = ontxtEndCheckChange;

const ontxtNextChange = () =>{
  let Value = getValue(thisObj, 'txtNext')
  setValue(thisObj,"txtNext",validateTextBox(Value))
}
thisObj.ontxtNextChange = ontxtNextChange;

const ontxtCheckChange = () =>{
  let Value = getValue(thisObj, 'txtCheck')
  setValue(thisObj,"txtCheck",validateTextBox(Value))
}
thisObj.ontxtCheckChange = ontxtCheckChange;

const ontxtWhouseChange = () =>{
  let Value = getValue(thisObj, 'txtWhouse')
  setValue(thisObj,"txtWhouse",validateTextBox(Value))
}
thisObj.ontxtWhouseChange = ontxtWhouseChange;

const validateTextBox = (data) => {
  var res = ''
  for (var i = 0; i < data.length; i++) {
    if (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) {
      res += data[i]
    }
  }
  return res;
}

const ontxtBegBlur = () =>{
  let value = getValue(thisObj, 'txtBeg')
  if (value!= undefined && value!= null && value.length < 7) {
    value = '0'.repeat((7 - value.length)) + value
    setValue(thisObj, 'txtBeg', value)
  }
}
thisObj.ontxtBegBlur = ontxtBegBlur;

const ontxtBegCheckBlur = () =>{
  let value = getValue(thisObj, 'txtBegCheck')
  if (value!=undefined && value!=null && value.length < 6) {
    value = '0'.repeat((6 - value.length)) + value
    setValue(thisObj, 'txtBegCheck', value)
  }
}
thisObj.ontxtBegCheckBlur = ontxtBegCheckBlur;

const ontxtVendorDCVBlur = async () =>{
  let vnum = getValue(thisObj,"txtVendorDCV")
  let FillResult = ''
  await bFillDefaultCMARemitToList(vnum).then(response =>{
    FillResult = response
  })
  if(FillResult == false){
    let prev_vnum = getData(thisObj, 'pre_vnum')
    setValue(thisObj, 'txtVendorDCV', prev_vnum)
    bFillDefaultCMARemitToList(prev_vnum)
  }
}
thisObj.ontxtVendorDCVBlur = ontxtVendorDCVBlur;

const ontxtEndBlur = () =>{
  let value = getValue(thisObj, 'txtEnd')
  if (value!= undefined && value!=null && value.length < 7 ) {
    value = '0'.repeat((7 - value.length)) + value
    setValue(thisObj, 'txtEnd', value)
  }
}
thisObj.ontxtEndBlur = ontxtEndBlur;

const ontxtEndCheckBlur = () =>{
  let value = getValue(thisObj, 'txtEndCheck')
  if (value!= undefined && value!=null &&value.length < 6 ) {
    value = '0'.repeat((6 - value.length)) + value
    setValue(thisObj, 'txtEndCheck', value)
  }
}
thisObj.ontxtEndCheckBlur = ontxtEndCheckBlur;

const ontxtNextBlur = () =>{
  let value = getValue(thisObj, 'txtNext')
  if (value!= undefined && value!=null && value.length < 7) {
    value = '0'.repeat((7 - value.length)) + value
    setValue(thisObj, 'txtNext', value)
  }
}
thisObj.ontxtNextBlur = ontxtNextBlur;

const ontxtCheckBlur = () =>{
  let value = getValue(thisObj, 'txtCheck')
  if (value!= undefined && value!=null && value.length < 6) {
    value = '0'.repeat((6 - value.length)) + value
    setValue(thisObj, 'txtCheck', value)
  }
}
thisObj.ontxtCheckBlur = ontxtCheckBlur;

const ontxtWhouseBlur = () =>{
  let value = getValue(thisObj, 'txtWhouse')
  if (value!= undefined && value!= null && value.length < 6 ) {
    value = '0'.repeat((6 - value.length)) + value
    setValue(thisObj, 'txtWhouse', value)
  }
}
thisObj.ontxtWhouseBlur = ontxtWhouseBlur;

const ontxtVendorCCVBlur = async () =>{
  let vnum = getValue(thisObj,"txtVendorCCV")
  let bFillRemitCCV = ''
  await bFillRemitToList(vnum).then(response =>{
    bFillRemitCCV = response
  })
  if(bFillRemitCCV == false){
    let prev_vendornum = getData(thisObj, 'pre_vendornum')
    setValue(thisObj, 'txtVendorCCV', prev_vendornum)
    bFillRemitToList(prev_vendornum)
  }
}
thisObj.ontxtVendorCCVBlur = ontxtVendorCCVBlur;

const bFillVicam = async () =>{//Fixed As part of UAT BUG_543829 made async function
  let isbFillVicam = false
  setValue(thisObj, 'ddVicamLab', '')
  let js = []
  js.push({ key : 'Yes', description : 'Yes'})
  js.push({ key : 'No', description : 'No'})
  thisObj.setState(current => {
    return {
      ...current,
      ddVicamLab: {
        ...state["ddVicamLab"],
        valueList: js,
      }
    }
  })
 setValue(thisObj, 'ddVicamLab', js[1].description)
  isbFillVicam = true
  return isbFillVicam;
}

  const bFillGramEntry = async () => {//Fixed As part of UAT BUG_543829 made async function
    let isbFillGramEntry = false
    setValue(thisObj, 'ddInspectionGramEntry', '')
    let js = []
    js.push({ key: 'Yes', description: 'Yes' })
    js.push({ key: 'No', description: 'No' })
    thisObj.setState(current => {
      return {
        ...current,
        ddInspectionGramEntry: {
          ...state["ddInspectionGramEntry"],
          valueList: js,
        }
      }
    })
    setValue(thisObj, 'ddInspectionGramEntry', js[1].description)
    isbFillGramEntry = true
    return isbFillGramEntry;
  }

  const bFillTransferGramEntry = async () =>{//Fixed As part of UAT BUG_543829 made async function
    let isbFillTransferGramEntry = false
    setValue(thisObj, 'ddTransferGramEntry', '')
    let js = []
    js.push({ key: 'Yes', description: 'Yes' })
    js.push({ key: 'No', description: 'No' })
    thisObj.setState(current => {
      return {
        ...current,
        ddTransferGramEntry: {
          ...state["ddTransferGramEntry"],
          valueList: js,
        }
      }
    })
    setValue(thisObj,'ddTransferGramEntry',js[1].key)
    isbFillTransferGramEntry = true
    return isbFillTransferGramEntry
  }

 const bFillTransferMoistEntry = async () =>{//Fixed As part of UAT BUG_543829 made async function
    let isbFillTransferMoistEntry = false
    setValue(thisObj, 'ddTransferMoistureEntry', '')
    let js = []
    js.push({ key: 'Yes', description: 'Yes' })
    js.push({ key: 'No', description: 'No' })
    thisObj.setState(current => {
      return {
        ...current,
        ddTransferMoistureEntry: {
          ...state["ddTransferMoistureEntry"],
          valueList: js,
        }
      }
    })
    setValue(thisObj,'ddTransferMoistureEntry',js[1].key)
    isbFillTransferMoistEntry = true
    return isbFillTransferMoistEntry
  }

  const bFillInternetTypeList = async () => {
    let js = []
    let LstrList = [];
    let obj = {}
    obj = { key: '' , description: '>>>Select<<<' }
    js.push(obj)
    await SystemMaintenanceMasterManagementService.RetrieveInternetTypeFormatControlDetails().then(response => {
      LstrList = response;
    })
    if (LstrList != undefined && LstrList.length > 0) {
      for (var i = 0; i < LstrList.length; i++) {
        obj = { key: LstrList[i].internet_type_format_id, description: LstrList[i].internet_type_format_name }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddInternetType: {
            ...state["ddInternetType"],
            valueList: js,
          }
        }
      })
    }
  }

  const bIrrigatedDryland = async() =>{//Fixed As part of UAT BUG_543829 made async function
    setValue(thisObj, 'ddIrrigatedDryland', '')
    let js = []
    js.push({ key: 'Yes', description: 'Yes' })
    js.push({ key: 'No', description: 'No' })
    thisObj.setState(current => {
      return {
        ...current,
        ddIrrigatedDryland: {
          ...state["ddIrrigatedDryland"],
          valueList: js,
        }
      }
    })
    setValue(thisObj,'ddIrrigatedDryland',js[1].key)
    return true;
  }

  const bFormValid = () =>{
    setData(thisObj, 'isbFormValid', false)
    let bDataValid = true

    let txtIDvalue = getValue(thisObj, 'txtID')
    if(txtIDvalue == undefined || txtIDvalue == null || txtIDvalue == ''){
      alert('ID is a required field.')
      document.getElementById("txtID").focus();
      return;
    }

    let txtProfitCentervalue = getValue(thisObj, 'txtProfitCenter')
    if(txtProfitCentervalue == undefined || txtProfitCentervalue == null || txtProfitCentervalue == ''){
      alert('Profit Center is a required field.')
      document.getElementById("txtProfitCenter").focus();
      return;
    }
      
    let txtFedBuyPtvalue = getValue(thisObj, 'txtFedBuyPt')
    if(txtFedBuyPtvalue == undefined || txtFedBuyPtvalue == null || txtFedBuyPtvalue == ''){
      alert('Federal Buying Point ID is a required field.')
      document.getElementById("txtFedBuyPt").focus();
      return;
    }

    let txtBalAcctRespvalue = getValue(thisObj, 'txtBalAcctResp')
    if(txtBalAcctRespvalue == undefined || txtBalAcctRespvalue == null || txtBalAcctRespvalue == ''){
      alert('Bal Acct Resp. is a required field.')
      document.getElementById("txtBalAcctResp").focus();
      return;
    }

    let txtBalAcctDeptvalue = getValue(thisObj, 'txtBalAcctDept')
    if(txtBalAcctDeptvalue == undefined || txtBalAcctDeptvalue == null || txtBalAcctDeptvalue == ''){
      alert('Bal Acct Dept. is a required field.')
      document.getElementById("txtBalAcctDept").focus();
      return;
    }

    let txtPIAcctRespvalue = getValue(thisObj, 'txtPIAcctResp')
    if(txtPIAcctRespvalue == undefined || txtPIAcctRespvalue == null || txtPIAcctRespvalue == ''){
      alert('Pl Acct Resp. is a required field.')
      document.getElementById("txtPIAcctResp").focus();
      return;
    }

    let txtPIAcctDeptvalue = getValue(thisObj, 'txtPIAcctDept')
    if(txtPIAcctDeptvalue == undefined || txtPIAcctDeptvalue == null || txtPIAcctDeptvalue == ''){
      alert('Pl Acct Dept. is a required field.')
      document.getElementById("txtPIAcctDept").focus();
      return;
    }

    let txtWhousevalue = getValue(thisObj, 'txtWhouse')
    if(txtWhousevalue == undefined || txtWhousevalue == null || txtWhousevalue == ''){
      alert('Next Whouse Cntl is a required field.')
      document.getElementById("txtWhouse").focus();
      return;
    }

    let txtCheckvalue = getValue(thisObj, 'txtCheck')
    if(txtCheckvalue == undefined || txtCheckvalue == null || txtCheckvalue == ''){
      alert('Next Check is a required field.')
      document.getElementById("txtCheck").focus();
      return;
    }

    let chkbocCharacteristicsvalue = getValue(thisObj, 'chkbocCharacteristics')
    let txtVendorCCVvalue = getValue(thisObj, 'txtVendorCCV')
    let txtVendorNameCCVvalue = getValue(thisObj, 'txtVendorNameCCV')
    let txtVendorDCVvalue = getValue(thisObj, 'txtVendorDCV')
    let txtVndrValue = getValue(thisObj, 'txtVndr')

    if(chkbocCharacteristicsvalue.includes('6') == false){
      if(txtVendorCCVvalue == undefined || txtVendorCCVvalue == null || txtVendorCCVvalue == ''){
        alert('Cure Clean Vendor is a required field.')
        document.getElementById("txtVendorCCV").focus();
        return;
      }
    }

    if(txtVendorCCVvalue != undefined && txtVendorCCVvalue != null && txtVendorCCVvalue != '' && (txtVendorNameCCVvalue == undefined || txtVendorNameCCVvalue == null || txtVendorNameCCVvalue == '')) {
      alert('Cure Clean Vendor is not a valid Vendor Number.')
      document.getElementById("txtVendorCCV").focus();
      return;
    }

    if(chkbocCharacteristicsvalue.includes('11')){
      if(txtVendorDCVvalue == undefined || txtVendorDCVvalue == null || txtVendorDCVvalue == ''){
        alert('Default CMA Vendor is a required field.')
        document.getElementById("txtVendorDCV").focus();
        return;
      }
    }

    if(txtVendorDCVvalue != undefined && txtVendorDCVvalue != null && txtVendorDCVvalue != '' && (txtVndrValue == undefined || txtVndrValue == null || txtVndrValue == '')){
      alert('Default CMA Vendor is is not a valid Vendor Number.')
      document.getElementById("txtVendorDCV").focus();
      return;
    }

    let ddFedWhseLicvalue = getValue(thisObj, 'ddFedWhseLic')
    if(chkbocCharacteristicsvalue.includes('5') || chkbocCharacteristicsvalue.includes('1')){
      if(ddFedWhseLicvalue == undefined || ddFedWhseLicvalue == null || ddFedWhseLicvalue == ''){
        alert('Fed Whse Lic is a required field.')
        document.getElementById("ddFedWhseLic").focus();
        return;
      }
    }

    let txtCCCLocvalue = getValue(thisObj, 'txtCCCLoc')
    if(chkbocCharacteristicsvalue.includes('5') || chkbocCharacteristicsvalue.includes('1')){
      if(txtCCCLocvalue == undefined || txtCCCLocvalue == null || txtCCCLocvalue == ''){
        alert('CCC Loc No. is a required field.')
        document.getElementById("txtCCCLoc").focus();
        return;
      }
    }

    let txtNextvalue = getValue(thisObj, 'txtNext')
    if(txtNextvalue == undefined || txtNextvalue == null || txtNextvalue == ''){
      alert('Next1007 is a required field.')
      document.getElementById("txtNext").focus();
      return;
    }

    if(txtNextvalue != undefined){
      if(isNaN(txtNextvalue) == false){
        if((Number(txtNextvalue) < 0) || (Number(txtNextvalue) > 9999999)){
          bDataValid = false
        }
      }
      else{
        bDataValid = false
      }
      if(bDataValid == false){
        alert('Next 1007 must be of format ######')
        document.getElementById("txtNext").focus();
        return;
      }
    }

    let txtBegvalue = getValue(thisObj, 'txtBeg')
    let txtBeg1value = getValue(thisObj, 'txtBeg1')
    let txtEndvalue = getValue(thisObj, 'txtEnd')
    let txtEnd1value = getValue(thisObj, 'txtEnd1')

    if(txtBegvalue == undefined || txtBegvalue == null || txtBegvalue == ''){
      alert('Beg 1007 is a required field.')
      document.getElementById("txtBeg").focus();
      return;
    }

    if(txtBegvalue != undefined && txtBegvalue != null && txtBegvalue != ''){
      if(isNaN(txtBegvalue) == false){
        if((Number(txtBegvalue) < 0) || (Number(txtBegvalue) > 9999999)){
          bDataValid = false
        }
      }
      else{
        bDataValid = false
      }
      if(bDataValid == false){
        alert('Beg 1007 must be of format ######')
        document.getElementById("txtBeg").focus();
        return;
      }
    }

    
    if(txtBeg1value != undefined && txtBeg1value != null && txtBeg1value != ''){
      if(isNaN(txtBeg1value) == false){
        if((Number(txtBeg1value) < 0) || (Number(txtBeg1value) > 9999999) || ((Number(txtBeg1value) >= Number(txtBegvalue)) && (Number(txtBeg1value) <= Number(txtEndvalue)))){
          bDataValid = false
        }
      }
      else{
        bDataValid = false
      }
      if(bDataValid == false){
        alert('Beg1 1007 value is overlapping with Beg and End range')
        document.getElementById("txtBeg1").focus();
        return;
      }
    }

    if(txtEndvalue == undefined || txtEndvalue == null || txtEndvalue == ''){
      alert('End 1007 is a required field.')
      document.getElementById("txtEnd").focus();
      return;
    }

    if(txtEndvalue != undefined && txtEndvalue != null && txtEndvalue != ''){
      if(isNaN(txtEndvalue) == false){
        if((Number(txtEndvalue)< 0) || (Number(txtEndvalue) > 9999999)){
          bDataValid = false
        }
      }
      else{
        bDataValid = false
      }
      if(bDataValid == false){
        alert('End 1007 must be of format ######')
        document.getElementById("txtEnd").focus();
        return;
      }
    }

    if(txtEnd1value != undefined && txtEnd1value != null && txtEnd1value != ''){
      if(isNaN(txtEnd1value)==false){
        if((Number(txtEnd1value) < 0) || (Number(txtEnd1value) > 9999999) || ((Number(txtEnd1value) >= Number(txtBeg1value)) && (Number(txtEnd1value) <= Number(txtEndvalue)))){
          bDataValid = false
        }
      }
      else{
        bDataValid = false
      }
      if(bDataValid == false){
        alert('End1 1007 value is overlapping with Beg and End range')
        document.getElementById("txtEnd").focus();
        return;
      }
    }

    let chkboxAdditional1007value = getValue(thisObj, 'chkboxAdditional1007')
    if(chkboxAdditional1007value == '1'){
      if(txtBeg1value == undefined || txtBeg1value == null || txtBeg1value == '' || txtEnd1value == undefined || txtBeg1value == null || txtBeg1value == ''){
        alert('Please enter Beg1 and End1 values or uncheck the Additional 1007 checkbox')
        document.getElementById("txtEnd").focus();
        return;
      }
      else{
        if(((Number(txtNextvalue) < Number(txtBegvalue)) || (Number(txtNextvalue) > Number(txtEndvalue))) && ((Number(txtNextvalue) < Number(txtBeg1value)) || (Number(txtNextvalue) > Number(txtEnd1value)))){
          alert('1007 Next Number must be between 1007 Begining and 1007 Ending numbers.')
          document.getElementById("txtNext").focus();
          return;
        }
      }
    }
    else{
      if((Number(txtNextvalue) < Number(txtBegvalue)) || (Number(txtNextvalue) > Number(txtEndvalue))){
        alert('1007 Next Number must be between 1007 Begining and 1007 Ending numbers.')
        document.getElementById("txtNext").focus();
        return;
      }
    }

    if(chkboxAdditional1007value == '1'){
      if(txtBeg1value == undefined || txtBeg1value == null || Number(txtBeg1value) == 0 || Number(txtBeg1value) == NaN){
        alert('Please enter Beg1 and End1 values or uncheck the Additional 1007 checkbox')
        document.getElementById("chkboxAdditional1007").focus();
        return;
      }
    }

    if(chkboxAdditional1007value == '1'){
      if(txtEnd1value != undefined && txtEnd1value != null && txtEnd1value != ''){
        if(Number(txtBeg1value) > Number(txtEnd1value)){
          alert('Beg1 value should be less than End1 value')
          return;
        }
      }
    }

    if(txtWhousevalue != undefined && txtWhousevalue != null && txtWhousevalue != ''){
      if(isNaN(txtWhousevalue) == false){
        if(Number(txtWhousevalue) < 0 || Number(txtWhousevalue) > 999999 ){
          bDataValid = false
        }
      }
      else{
        bDataValid = false
      }
      if(bDataValid == false){
        alert('Next Whouse Rcpt must be of format ######')
        document.getElementById("txtWhouse").focus();
        return;
      }
    }

    if(txtCheckvalue != undefined && txtCheckvalue != null && txtCheckvalue != ''){
      if(isNaN(txtCheckvalue) == false){
        if(Number(txtCheckvalue) < 0 || Number(txtCheckvalue) > 999999){
          bDataValid = false
        }
      }
      else{
        bDataValid = false
      }

      if(bDataValid == false){
        alert('Next Check must be of format ######')
        document.getElementById("txtCheck").focus();
        return;
      }
    }
    let txtBegCheckvalue = getValue(thisObj, 'txtBegCheck')
    let txtEndCheckValue = getValue(thisObj, 'txtEndCheck')

    if((Number(txtCheckvalue) < Number(txtBegCheckvalue)) || (Number(txtCheckvalue) > Number(txtEndCheckValue))){
      alert('Check Next Number must be between Check Begining and Check Ending numbers.')
      document.getElementById("txtCheck").focus();
      return;
    }

    if(txtBegvalue == undefined || txtBegvalue == null || txtBegvalue ==''){
      alert('Beg check is a required field.')
      document.getElementById("txtBeg").focus();
      return;
    }

    if(txtBegCheckvalue != undefined && txtBegCheckvalue != null && txtBegCheckvalue != ''){
      if(isNaN(txtBegCheckvalue) == false){
        if(Number(txtBegCheckvalue) < 0 || Number(txtBegCheckvalue) > 999999){
          bDataValid = false
        }
      }
      else{
        bDataValid = false
      }

      if(bDataValid == false){
        alert('Beg check must be of format ######')
        document.getElementById("txtBegCheck").focus();
        return;
      }
    }

    if(txtEndCheckValue == undefined || txtEndCheckValue == null || txtEndCheckValue == ''){
      alert('End check is a required field.')
      document.getElementById("txtEndCheck").focus();
      return;
    }

    if(txtEndCheckValue != undefined && txtEndCheckValue != null && txtEndCheckValue != ''){
      if(isNaN(txtEndCheckValue) == false){
        if(Number(txtEndCheckValue) < 0 || Number(txtEndCheckValue) > 999999){
          bDataValid = false
        }
      }
      else{
        bDataValid = false
      }

      if(bDataValid == false){
        alert('End check must be of format ######')
        document.getElementById("txtEndCheck").focus();
        return;
      }
    }

    let txtTimerInterval = getValue(thisObj, 'txtTimerInterval')
    if(isNaN(txtTimerInterval) == false){
      if(txtTimerInterval > 65535){
        alert('The maximum valid timer interval allowed is 65535.')
        return;
      }
    }
          
    setData(thisObj, 'isbFormValid', true)
  }

  const onbtnVendorCCVClick = () =>{
    try{
      setData(thisObj, 'BtnVendorName', 'btnVendorCCV')
    }
    catch(err){	
      if(err instanceof EvalError){	
        showMessage(thisObj, err.message)	
      }else{	
        showMessage(thisObj, "Error on button Vendor Click event"	)	
      }
      return false
    }
    return true;
  }
  thisObj.onbtnVendorCCVClick = onbtnVendorCCVClick;

  const onbtnVendorDCVClick = () =>{
    try{
      setData(thisObj, 'BtnVendorName', 'btnVendorDCV')
    }
    catch(err){	
      if(err instanceof EvalError){	
        showMessage(thisObj, err.message)	
      }else{	
        showMessage(thisObj, "Error on button Vendor Click event"	)	
      }
      return false
    }
    return true;
  }
  thisObj.onbtnVendorDCVClick = onbtnVendorDCVClick;

  const onbtnCancelClick = () =>{
    try{
      setData(thisObj, "closingOfBuyingPointProfileFromCancel", true);
      setData(thisObj, 'CollectionPointId', getValue(thisObj, 'ddCollectionPt'));
      document.getElementById("SystemMaintenanceMasterManagement_BuyingPointProfilePopUp").childNodes[0].click()
    }
    catch(err){	
      if(err instanceof EvalError){	
        showMessage(thisObj, err.message)	
      }else{	
        showMessage(thisObj, "Error on button Cancel Click event"	)	
      }
      return false
    }
    return true;
  }
  thisObj.onbtnCancelClick = onbtnCancelClick;


  const onbtnOkClick = async () =>{
    let ParentValue = getData(thisObj, 'ParentData')
    cmdOk_Caption = (ParentValue.btnOk).toLocaleUpperCase()
    let lstrxml = []
    let sQueryString = ''
    let nOldIndex = 0
    let nNewIndex = 0
    let sVicam = ''
    let sGramEntry = ''
    let SIrrigatedDryland = ''
    let sTransferGramEntry = ''
    let sTransferMoistEntry = ''
    let sFV95Reset = ''

    bFormValid()
    if(getData(thisObj, 'isbFormValid') == false){
      return;
    }
  
    if(getValue(thisObj, 'ddVicamLab') == "Yes"){
      sVicam = "Y"
    }
    else{
      sVicam = "N"
    }
  
    if(getValue(thisObj, 'ddIrrigatedDryland') == "Yes"){
      SIrrigatedDryland = "Y"
    }
    else{
      SIrrigatedDryland = "N"
    }
  
    if((getValue(thisObj, 'ddInspectionGramEntry')).toLocaleUpperCase() == "YES"){
      sGramEntry = "Y"
    }
    else{
      sGramEntry = "N"
    }
  
    if((getValue(thisObj, 'ddTransferGramEntry')).toLocaleUpperCase() == "YES"){
      sTransferGramEntry = "Y"
    }
    else{
      sTransferGramEntry = "N"
    }
  
    if((getValue(thisObj, 'ddTransferMoistureEntry').toLocaleUpperCase() == "YES")){
      sTransferMoistEntry = "Y"
    }
    else{
      sTransferMoistEntry = "N"
    }
  
    sFV95Reset = "N"
  
    let data = {}
  
    data.buy_pt_id = getValue(thisObj, 'txtID') == undefined ? '' : getValue(thisObj, 'txtID')
    let lblVisible_ = document.getElementsByClassName("lblLocationRecordIsInactive")[0].style.visibility
    data.status_ind = lblVisible_ == 'visible' ? 'I' : 'A'
    data.profit_center = getValue(thisObj,'txtProfitCenter') == undefined ? '' : getValue(thisObj,'txtProfitCenter')
    data.area_id = getValue(thisObj, 'ddArea') == undefined ? '' : getValue(thisObj, 'ddArea')
    setData(thisObj, 'AreaID', getValue(thisObj, 'ddArea'))
    data.coll_pt_id = getValue(thisObj, 'ddCollectionPt') == undefined ? '' : getValue(thisObj, 'ddCollectionPt')
    setData(thisObj, 'CollectionPointId', getValue(thisObj, 'ddCollectionPt'))
    data.buy_pt_name = getValue(thisObj, 'txtName') == undefined ? '' : getValue(thisObj, 'txtName')
    data.group_id = getValue(thisObj, 'txtGroupID') == undefined ? '' : getValue(thisObj, 'txtGroupID')
    data.contact_name = getValue(thisObj, 'lblName') == undefined ? '' : getValue(thisObj, 'lblName')
    data.buy_pt_phone = getValue(thisObj, 'txtPhone') == undefined ? '' : getValue(thisObj, 'txtPhone')
    data.speed_dial_num = ''
    data.physical_address = getValue(thisObj, 'txtAddressPA') == undefined ? '' : getValue(thisObj, 'txtAddressPA')
    data.physical_city = getValue(thisObj, 'txtCityPA') == undefined ? '' : getValue(thisObj, 'txtCityPA')
    data.physical_state = getValue(thisObj, 'ddStatePA') == undefined ? '' : getValue(thisObj, 'ddStatePA')
    data.physical_county_id = getValue(thisObj, 'ddCountyPA') == undefined ? '' : getValue(thisObj, 'ddCountyPA')
    data.physical_zip = getValue(thisObj, 'txtZipPA') == undefined ? '' : getValue(thisObj, 'txtZipPA')
    data.mailing_address = getValue(thisObj, 'txtAddressMA') == undefined ? '' : getValue(thisObj, 'txtAddressMA')
    data.mailing_city = getValue(thisObj, 'txtCityMA') == undefined ? '' : getValue(thisObj, 'txtCityMA')
    data.mailing_state = getValue(thisObj, 'ddStateMA') == undefined ? '' : getValue(thisObj, 'ddStateMA')
    data.mailing_zip = getValue(thisObj, 'txtZipMA') == undefined ? '' : getValue(thisObj, 'txtZipMA')
    data.fed_buy_pt_id = getValue(thisObj, 'txtFedBuyPt') == undefined ? '' : getValue(thisObj, 'txtFedBuyPt')
    data.cured_clean_vendor = getValue(thisObj, 'txtVendorCCV') == undefined ? '' : getValue(thisObj, 'txtVendorCCV')
    data.cured_clean_remit = getValue(thisObj, 'ddRemitToCCV') == undefined ? '' : getValue(thisObj, 'ddRemitToCCV')
    data.fed_whouse_license = getValue(thisObj, 'ddFedWhseLic') == undefined ? '' : getValue(thisObj, 'ddFedWhseLic')
    data.ccc_location_id = getValue(thisObj, 'txtCCCLoc') == undefined ? '' : getValue(thisObj, 'txtCCCLoc')
    data.vicam_lab = sVicam == undefined ? '' : sVicam
    data.irr_dry_on = SIrrigatedDryland == undefined ? '' : SIrrigatedDryland
    data.bal_acct_resp = getValue(thisObj, 'txtBalAcctResp') == undefined ? '' : getValue(thisObj, 'txtBalAcctResp')
    data.bal_acct_dept = getValue(thisObj, 'txtBalAcctDept') == undefined ? '' : getValue(thisObj, 'txtBalAcctDept')
    data.pl_acct_resp = getValue(thisObj, 'txtPIAcctResp') == undefined ? '' : getValue(thisObj, 'txtPIAcctResp')
    data.pl_acct_dept = getValue(thisObj, 'txtPIAcctDept') == undefined ? '' : getValue(thisObj, 'txtPIAcctDept')

    if(cmdOk_Caption== 'UPDATE'){
      let dataObj = getData(thisObj, 'ParentData')
      let lbl_next1007 = dataObj.txtNext1007
      let lbl_beg1007 = dataObj.txtBeg1007 
      let lbl_end1007 = dataObj.txtEnd1007 
      let lbl_beg1_1007 = dataObj.txtBeg1_1007 
      let lbl_end1_1007 = dataObj.txtEnd1_1007
      let lbl_nextWhouseCntl = dataObj.txtNextWhouseCntl 
      let lbl_nextCheck = dataObj.txtNextCheck
      let beg_check = dataObj.txtBegCheck
      let end_check = dataObj.txtEndCheck 
      if(getValue(thisObj, 'txtNext')!= lbl_next1007 ){
        data.next_1007 = getValue(thisObj, 'txtNext')
      }
      else {
        data.next_1007="dontupdate"
      } 
      
      if(getValue(thisObj,'txtBeg')!= lbl_beg1007){
        data.beg_1007 = getValue(thisObj, 'txtBeg')
      }
      else {
        data.beg_1007="dontupdate"
      }
    
      if(getValue(thisObj,'txtEnd')!= lbl_end1007){
        data.end_1007 = getValue(thisObj, 'txtEnd')
      }
      else {
        data.end_1007="dontupdate"
      }
      if(getValue(thisObj,'chkboxAdditional1007')=="1"){
        data.additional_1007 = "Y"
        if(getValue(thisObj,'txtBeg1')!= lbl_beg1_1007){
          data.beg1_1007 = getValue(thisObj, 'txtBeg1')
        }
        else {
          data.beg1_1007="dontupdate"
        }     
      }
      else {
        data.additional_1007 = "N"
        data.beg1_1007 ='0'
        data.end1_1007='0'
      }
      if(getValue(thisObj,'chkboxAdditional1007')=="1") {
        if(getValue(thisObj,'txtEnd1')!= lbl_end1_1007){
          data.end1_1007 = getValue(thisObj,'txtEnd1')        
        }
        else {
          data.end1_1007="dontupdate"
        }
       }
       if(getValue(thisObj,'txtWhouse')!= lbl_nextWhouseCntl){
        data.next_whse_cntl = getValue(thisObj, 'txtWhouse')
      }
      else {
        data.next_whse_cntl="dontupdate"
      }
      if(getValue(thisObj,'txtCheck')!= lbl_nextCheck){
        data.next_check = getValue(thisObj, 'txtCheck')
      }
      else {
        data.next_check="dontupdate"
      }
      data.beg_check = getValue(thisObj,'txtBegCheck')
      data.end_check = getValue(thisObj,'txtEndCheck')

      if(getValue(thisObj,'radioLogging')=="1"){
        data.ftp_enable_log = 'Y'
      }
      else {
        data.ftp_enable_log = 'N'
      }

      if(isNaN(getValue(thisObj,'txtTimerInterval'))==false){
        data.ftp_timer_interval = getValue(thisObj,'txtTimerInterval')
      }
      else {
        data.ftp_timer_interval = '18000'
      }

      if(isNaN(getValue(thisObj,'txtRetries'))==false){
        data.ftp_retry_limit = getValue(thisObj,'txtRetries')
      }
      else {
        data.ftp_retry_limit = '10'
      }

      setValue(thisObj, 'ddCollectionPt', ParentValue.CollectionPointId)


      if(getValue(thisObj,'ddArea')!= dataObj.lblArea)
      {
        data.old_area_id = dataObj.lblArea
      }

      if(getValue(thisObj,'ddCollectionPt')!= dataObj.CollectionPointId)
      {
        data.old_coll_pt_id = dataObj.CollectionPointId
      }
      else{
        data.old_coll_pt_id = ''
      }
    }
    else {
      data.next_1007 = getValue(thisObj,'txtNext')
      data.beg_1007 = getValue(thisObj,'txtBeg')
      data.end_1007 = getValue(thisObj,'txtEnd')
      if(getValue(thisObj,'chkboxAdditional1007')=="1"){
        data.additional_1007 = 'Y'
        data.beg1_1007 = getValue(thisObj,'txtBeg1')
        data.end1_1007 = getValue(thisObj,'txtEnd1')
      }
      else {
        data.additional_1007 = 'N'
        data.beg1_1007 = getValue(thisObj,'txtBeg1')
        data.end1_1007 = getValue(thisObj,'txtEnd1') 
      }
        data.next_whse_cntl = getValue(thisObj,'txtWhouse')
        data.next_check = getValue(thisObj,'txtCheck')
        data.beg_check = getValue(thisObj,'txtBegCheck')
        data.end_check = getValue(thisObj,'txtEndCheck')
    }

    data.xfer_gram_entry_ind = sTransferGramEntry
    data.xfer_edit_moist_pct = sTransferMoistEntry
    data.gram_entry_ind = sGramEntry
    data.fv95_reset_ind = sFV95Reset
    data.insp_upload_format_id = "ST"
     
    if(getValue(thisObj,'chkboxUseAutomaticTransfer')=="1") {
      data.xfer_auto_num_ind = 'Y'
    }
    else{
      data.xfer_auto_num_ind = 'N'
    }

    let chkValue = getValue(thisObj,'chkbocCharacteristics')
    if(chkValue.includes('1')) {
      data.non_gpc_storage = 'Y'
    }  
    else{
      data.non_gpc_storage = 'N'
    }

    if(chkValue.includes('5')) {
      data.gpc_storage = 'Y'
    }  
    else{
      data.gpc_storage = 'N'
    }
    if(chkValue.includes('6')) {
      data.gpc_owned = 'Y'
    }  
    else{
      data.gpc_owned = 'N'
    }

    if(chkValue.includes('7')) {
      data.shelling_plant = 'Y'
    }  
    else{
      data.shelling_plant = 'N'
    }

    if(chkValue.includes('2')) {
      data.no_storage = 'Y'
    }  
    else{
      data.no_storage = 'N'
    }

    if(chkValue.includes('3')) {
      data.leased_location = 'Y'
    }  
    else{
      data.leased_location = 'N'
    }

    if(chkValue.includes('4')) {
      data.dpr_exclusion_ind = 'Y'
    }  
    else{
      data.dpr_exclusion_ind = 'N'
    }

    if(chkValue.includes('8')) {
      data.e_fv95_upload_select_ind = 'Y'
    }  
    else{
      data.e_fv95_upload_select_ind = 'N'
    }

    if(chkValue.includes('11')) {
      data.cma_ind = 'Y'
    }  
    else{
      data.cma_ind = 'N'
    }

    if(chkValue.includes('9') && getData(thisObj, 'AUTH_FLAG') ==true ) {
      data.exclude_from_kc_trans = 'Y'
    }  
    else if((chkValue.includes('9')==false) && getData(thisObj, 'AUTH_FLAG') ==true ) { 
      data.exclude_from_kc_trans = 'N'
    }

    if(chkValue.includes('10') && getData(thisObj, 'AUTH_FLAG') ==true ) {
      data.exclude_from_ewr_trans = 'Y'
    }  
    else if((chkValue.includes('10')==false) && getData(thisObj, 'AUTH_FLAG') ==true ) { 
      data.exclude_from_ewr_trans = 'N'
    }

    if(chkValue.includes('12')) {
      data.OneDoc_Ind = 'Y'
    }  
    else{
      data.OneDoc_Ind = 'N'
    }
    
    data.internet_type_format_id = getValue(thisObj,'ddInternetType') == undefined || getValue(thisObj,'ddInternetType') == null ? '' : getValue(thisObj,'ddInternetType')
    data.service_provider = getValue(thisObj,'txtServiceProvider') == undefined || getValue(thisObj,'txtServiceProvider') == null ? '' : getValue(thisObj,'txtServiceProvider')

    if(getValue(thisObj,'radioFTPMethod')== '2'){
      data.FTP_Method = 'S'
    }
    else {
      data.FTP_Method ='I'
    }
    data.default_cma_vendor_num = getValue(thisObj,'txtVendorDCV') == undefined || getValue(thisObj,'txtVendorDCV') == null ? '' : getValue(thisObj,'txtVendorDCV')
    data.default_cma_remit_to = getValue(thisObj,'ddRemitToDCV') == undefined || getValue(thisObj,'ddRemitToDCV') == null ? '' : getValue(thisObj,'ddRemitToDCV')
    let Space = '                                                                                     '
    if (cmdOk_Caption == 'ADD') {
      let coll_pt_id = data.coll_pt_id
      await SystemMaintenanceMasterManagementService.CreateBuyingPointControl(coll_pt_id, data).then(response =>{
        lstrxml = response
      })
      if (lstrxml == undefined || lstrxml.status != 200) {
        if (lstrxml != undefined && lstrxml != null && lstrxml != '' && lstrxml.message != undefined && lstrxml.message != null && lstrxml.message != '') {
          alert("An error occured while updating the database. Please verify that your data is correct and retry. Contact your system administrator if this problem persist!" + Space + lstrxml.message);
          return;
        } else {
          alert("An error occured while updating the database.Please verify that your data is correct and retry.Contact your system administrator if this problem persist! Update Buying Point");
          return;
        }
      }
    }
    else {
      let buy_pt_id = data.buy_pt_id
      await SystemMaintenanceMasterManagementService.UpdateBuyingPointControl(buy_pt_id, data).then(response =>{
        lstrxml = response
      })
      if (lstrxml == undefined || lstrxml.status != 200) {
          if (lstrxml != undefined && lstrxml != null && lstrxml != '' && lstrxml.message != lstrxml && lstrxml.message != null && lstrxml.message != '') {
            alert("An error occured while updating the database. Please verify that your data is correct and retry. Contact your system administrator if this problem persist!" + Space + lstrxml.message);
            return;
          } else {
            alert("An error occured while updating the database.Please verify that your data is correct and retry.Contact your system administrator if this problem persist! Update Buying Point");
            return;
          }
      }
    }
  document.getElementById("SystemMaintenanceMasterManagement_BuyingPointProfilePopUp").childNodes[0].click()
  }
  thisObj.onbtnOkClick = onbtnOkClick;

  const onbtnMakeInactiveClick = () => {
    let ParentValue = getData(thisObj, 'ParentData')
    bFormValid()
    let issbFormValid = getData(thisObj, 'isbFormValid')
    if (issbFormValid != true) {
      return
    }
    let lblVisible = ParentValue.lblInactive_Visible
    if (lblVisible == true) {
      document.getElementsByClassName("lblLocationRecordIsInactive")[0].style.visibility = 'hidden';
      document.getElementsByClassName('btnMakeInactive')[0].childNodes[0].childNodes[0].innerText = "Make Inactive";
    }
    else {
      document.getElementsByClassName("lblLocationRecordIsInactive")[0].style.visibility = 'visible';
      document.getElementsByClassName('btnMakeInactive')[0].childNodes[0].childNodes[0].innerText = "RE-Activate";

    }
    onbtnOkClick()
  }
  thisObj.onbtnMakeInactiveClick = onbtnMakeInactiveClick;

  const onbtnPrintScreenClick = async () => {

    try {
       const targetElement2 = document.getElementsByClassName('grpbxHeader col-12  groupboxwidget-container  mb-3')[1]; // Change to your target element's ID or selector
      const targetElement = document.getElementById('screenShot'); // Change to your target element's ID or selector

      let canvas = await html2canvas(targetElement2)
      let header = canvas.toDataURL('image/png');
        
      html2canvas(targetElement).then(canvas => {
        const screenshotDataUrl = canvas.toDataURL('image/png');
  
        //Generate PDF
        const pdf = new jsPDF();
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(header, 'PNG', 0, 0, imgWidth, 20);
        pdf.addImage(screenshotDataUrl, 'PNG', 0 , 22, imgWidth, imgHeight);

        pdf.save('screenshot.pdf');
      });

      // window.print();

    } catch (err) {
      if(err instanceof EvalError){	
        showMessage(thisObj, err.message)	
      }else{	
        showMessage(thisObj, "Error while capturing Screenshot"	)	
      }
    }
  }
  thisObj.onbtnPrintScreenClick = onbtnPrintScreenClick;

    
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>            
            <Loading loading={loading}></Loading>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_BuyingPointProfile*/}
              <div id="screenShot">
              {/* END_USER_CODE-USER_BEFORE_BuyingPointProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxBuyingPointProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxBuyingPointProfile*/}

              <GroupBoxWidget
                conf={state.grpbxBuyingPointProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxCompanyArea*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxCompanyArea*/}

                <GroupBoxWidget
                  conf={state.grpbxCompanyArea}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblBegCheck*/}

                  {/* END_USER_CODE-USER_BEFORE_lblBegCheck*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblBegCheck}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblBegCheck*/}

                  {/* END_USER_CODE-USER_AFTER_lblBegCheck*/}
                  {/* START_USER_CODE-USER_BEFORE_lblNext1007*/}

                  {/* END_USER_CODE-USER_BEFORE_lblNext1007*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblNext1007}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblNext1007*/}

                  {/* END_USER_CODE-USER_AFTER_lblNext1007*/}
                  {/* START_USER_CODE-USER_BEFORE_lblBeg1007*/}

                  {/* END_USER_CODE-USER_BEFORE_lblBeg1007*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblBeg1007}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblBeg1007*/}

                  {/* END_USER_CODE-USER_AFTER_lblBeg1007*/}
                  {/* START_USER_CODE-USER_BEFORE_lblNextCheck*/}

                  {/* END_USER_CODE-USER_BEFORE_lblNextCheck*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblNextCheck}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblNextCheck*/}

                  {/* END_USER_CODE-USER_AFTER_lblNextCheck*/}
                  {/* START_USER_CODE-USER_BEFORE_lblEndCheck*/}

                  {/* END_USER_CODE-USER_BEFORE_lblEndCheck*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblEndCheck}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblEndCheck*/}

                  {/* END_USER_CODE-USER_AFTER_lblEndCheck*/}
                  {/* START_USER_CODE-USER_BEFORE_lblCompany*/}

                  {/* END_USER_CODE-USER_BEFORE_lblCompany*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblCompany}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblCompany*/}

                  {/* END_USER_CODE-USER_AFTER_lblCompany*/}
                  {/* START_USER_CODE-USER_BEFORE_lblCompanyValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblCompanyValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblCompanyValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblCompanyValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblCompanyValue*/}
                  {/* START_USER_CODE-USER_BEFORE_lblNextWhouseCntl*/}

                  {/* END_USER_CODE-USER_BEFORE_lblNextWhouseCntl*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblNextWhouseCntl}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblNextWhouseCntl*/}

                  {/* END_USER_CODE-USER_AFTER_lblNextWhouseCntl*/}
                  {/* START_USER_CODE-USER_BEFORE_lblBeg11007*/}

                  {/* END_USER_CODE-USER_BEFORE_lblBeg11007*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblBeg11007}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblBeg11007*/}

                  {/* END_USER_CODE-USER_AFTER_lblBeg11007*/}
                  {/* START_USER_CODE-USER_BEFORE_lblArea*/}

                  {/* END_USER_CODE-USER_BEFORE_lblArea*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblArea}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblArea*/}

                  {/* END_USER_CODE-USER_AFTER_lblArea*/}
                  {/* START_USER_CODE-USER_BEFORE_lblAreaValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblAreaValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblAreaValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblAreaValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblAreaValue*/}
                  {/* START_USER_CODE-USER_BEFORE_lblEnd1007*/}

                  {/* END_USER_CODE-USER_BEFORE_lblEnd1007*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblEnd1007}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblEnd1007*/}

                  {/* END_USER_CODE-USER_AFTER_lblEnd1007*/}
                  {/* START_USER_CODE-USER_BEFORE_lblEnd11007*/}

                  {/* END_USER_CODE-USER_BEFORE_lblEnd11007*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblEnd11007}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblEnd11007*/}

                  {/* END_USER_CODE-USER_AFTER_lblEnd11007*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxCompanyArea*/}

                {/* END_USER_CODE-USER_AFTER_grpbxCompanyArea*/}
                {/* START_USER_CODE-USER_BEFORE_lblLocationRecordIsInactive*/}

                {/* END_USER_CODE-USER_BEFORE_lblLocationRecordIsInactive*/}

                <LabelWidget
                  values={values}
                  conf={state.lblLocationRecordIsInactive}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblLocationRecordIsInactive*/}

                {/* END_USER_CODE-USER_AFTER_lblLocationRecordIsInactive*/}

                {/* START_USER_CODE-USER_BEFORE_grpbxFTPAdmin*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxFTPAdmin*/}

                <GroupBoxWidget conf={state.grpbxFTPAdmin} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblFTPAdmin*/}

                  {/* END_USER_CODE-USER_BEFORE_lblFTPAdmin*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblFTPAdmin}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblFTPAdmin*/}

                  {/* END_USER_CODE-USER_AFTER_lblFTPAdmin*/}
                  {/* START_USER_CODE-USER_BEFORE_radioLogging*/}

                  {/* END_USER_CODE-USER_BEFORE_radioLogging*/}

                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioLogging}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_radioLogging*/}

                  {/* END_USER_CODE-USER_AFTER_radioLogging*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTimerInterval*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTimerInterval*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTimerInterval}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTimerInterval*/}

                  {/* END_USER_CODE-USER_AFTER_txtTimerInterval*/}
                  {/* START_USER_CODE-USER_BEFORE_txtRetries*/}

                  {/* END_USER_CODE-USER_BEFORE_txtRetries*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtRetries}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtRetries*/}

                  {/* END_USER_CODE-USER_AFTER_txtRetries*/}
                  {/* START_USER_CODE-USER_BEFORE_radioFTPMethod*/}

                  {/* END_USER_CODE-USER_BEFORE_radioFTPMethod*/}

                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioFTPMethod}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_radioFTPMethod*/}

                  {/* END_USER_CODE-USER_AFTER_radioFTPMethod*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxFTPAdmin*/}

                {/* END_USER_CODE-USER_AFTER_grpbxFTPAdmin*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxCharacteristic*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxCharacteristic*/}

                <GroupBoxWidget
                  conf={state.grpbxCharacteristic}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblCharacteristics*/}

                  {/* END_USER_CODE-USER_BEFORE_lblCharacteristics*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblCharacteristics}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblCharacteristics*/}

                  {/* END_USER_CODE-USER_AFTER_lblCharacteristics*/}
                  {/* START_USER_CODE-USER_BEFORE_chkbocCharacteristics*/}

                  {/* END_USER_CODE-USER_BEFORE_chkbocCharacteristics*/}

                  <CheckboxGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkbocCharacteristics}
                    screenConf={state}
                  ></CheckboxGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_chkbocCharacteristics*/}

                  {/* END_USER_CODE-USER_AFTER_chkbocCharacteristics*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxCharacteristic*/}

                {/* END_USER_CODE-USER_AFTER_grpbxCharacteristic*/}
                
                {/* START_USER_CODE-USER_BEFORE_grpbxBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxBuyingPoint*/}

                <GroupBoxWidget
                  conf={state.grpbxBuyingPoint}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblBuyingPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_lblBuyingPoint*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblBuyingPoint}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblBuyingPoint*/}

                  {/* END_USER_CODE-USER_AFTER_lblBuyingPoint*/}
                  {/* START_USER_CODE-USER_BEFORE_ddArea*/}

                  {/* END_USER_CODE-USER_BEFORE_ddArea*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddArea}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddArea*/}

                  {/* END_USER_CODE-USER_AFTER_ddArea*/}
                  {/* START_USER_CODE-USER_BEFORE_ddCollectionPt*/}

                  {/* END_USER_CODE-USER_BEFORE_ddCollectionPt*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCollectionPt}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddCollectionPt*/}

                  {/* END_USER_CODE-USER_AFTER_ddCollectionPt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtID*/}

                  {/* END_USER_CODE-USER_BEFORE_txtID*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtID}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtID*/}

                  {/* END_USER_CODE-USER_AFTER_txtID*/}
                  {/* START_USER_CODE-USER_BEFORE_txtProfitCenter*/}

                  {/* END_USER_CODE-USER_BEFORE_txtProfitCenter*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtProfitCenter}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtProfitCenter*/}

                  {/* END_USER_CODE-USER_AFTER_txtProfitCenter*/}
                  {/* START_USER_CODE-USER_BEFORE_txtName*/}

                  {/* END_USER_CODE-USER_BEFORE_txtName*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtName}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtName*/}

                  {/* END_USER_CODE-USER_AFTER_txtName*/}
                  {/* START_USER_CODE-USER_BEFORE_txtGroupID*/}

                  {/* END_USER_CODE-USER_BEFORE_txtGroupID*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtGroupID}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtGroupID*/}

                  {/* END_USER_CODE-USER_AFTER_txtGroupID*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_grpbxBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxContactInfo*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxContactInfo*/}

                <GroupBoxWidget
                  conf={state.grpbxContactInfo}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblContactInfo*/}

                  {/* END_USER_CODE-USER_BEFORE_lblContactInfo*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblContactInfo}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblContactInfo*/}

                  {/* END_USER_CODE-USER_AFTER_lblContactInfo*/}
                  {/* START_USER_CODE-USER_BEFORE_lblName*/}

                  {/* END_USER_CODE-USER_BEFORE_lblName*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lblName}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_lblName*/}

                  {/* END_USER_CODE-USER_AFTER_lblName*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPhone*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPhone*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPhone}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPhone*/}

                  {/* END_USER_CODE-USER_AFTER_txtPhone*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxContactInfo*/}

                {/* END_USER_CODE-USER_AFTER_grpbxContactInfo*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxGovernmentIssued*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxGovernmentIssued*/}

                <GroupBoxWidget
                  conf={state.grpbxGovernmentIssued}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblGovernmentIssued*/}

                  {/* END_USER_CODE-USER_BEFORE_lblGovernmentIssued*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblGovernmentIssued}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblGovernmentIssued*/}

                  {/* END_USER_CODE-USER_AFTER_lblGovernmentIssued*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFedBuyPt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFedBuyPt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFedBuyPt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFedBuyPt*/}

                  {/* END_USER_CODE-USER_AFTER_txtFedBuyPt*/}
                  {/* START_USER_CODE-USER_BEFORE_ddFedWhseLic*/}

                  {/* END_USER_CODE-USER_BEFORE_ddFedWhseLic*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddFedWhseLic}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddFedWhseLic*/}

                  {/* END_USER_CODE-USER_AFTER_ddFedWhseLic*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCCCLoc*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCCCLoc*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCCCLoc}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCCCLoc*/}

                  {/* END_USER_CODE-USER_AFTER_txtCCCLoc*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxGovernmentIssued*/}

                {/* END_USER_CODE-USER_AFTER_grpbxGovernmentIssued*/}
                <GroupBoxWidget
                  conf={state.grpbxCntnr1}
                  screenConf={state}
                >
                {/* START_USER_CODE-USER_BEFORE_grpbxPhysicalAddress*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxPhysicalAddress*/}

                <GroupBoxWidget
                  conf={state.grpbxPhysicalAddress}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblPhysicalAddress*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPhysicalAddress*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPhysicalAddress}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPhysicalAddress*/}

                  {/* END_USER_CODE-USER_AFTER_lblPhysicalAddress*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAddressPA*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAddressPA*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAddressPA}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAddressPA*/}

                  {/* END_USER_CODE-USER_AFTER_txtAddressPA*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCityPA*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCityPA*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCityPA}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCityPA*/}

                  {/* END_USER_CODE-USER_AFTER_txtCityPA*/}
                  {/* START_USER_CODE-USER_BEFORE_ddStatePA*/}

                  {/* END_USER_CODE-USER_BEFORE_ddStatePA*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddStatePA}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddStatePA*/}

                  {/* END_USER_CODE-USER_AFTER_ddStatePA*/}
                  {/* START_USER_CODE-USER_BEFORE_ddCountyPA*/}

                  {/* END_USER_CODE-USER_BEFORE_ddCountyPA*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCountyPA}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddCountyPA*/}

                  {/* END_USER_CODE-USER_AFTER_ddCountyPA*/}
                  {/* START_USER_CODE-USER_BEFORE_txtZipPA*/}

                  {/* END_USER_CODE-USER_BEFORE_txtZipPA*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtZipPA}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtZipPA*/}

                  {/* END_USER_CODE-USER_AFTER_txtZipPA*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxPhysicalAddress*/}

                {/* END_USER_CODE-USER_AFTER_grpbxPhysicalAddress*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxMailingAddress*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxMailingAddress*/}

                <GroupBoxWidget
                  conf={state.grpbxMailingAddress}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblMailingAddress*/}

                  {/* END_USER_CODE-USER_BEFORE_lblMailingAddress*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblMailingAddress}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblMailingAddress*/}

                  {/* END_USER_CODE-USER_AFTER_lblMailingAddress*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAddressMA*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAddressMA*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAddressMA}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAddressMA*/}

                  {/* END_USER_CODE-USER_AFTER_txtAddressMA*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCityMA*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCityMA*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCityMA}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCityMA*/}

                  {/* END_USER_CODE-USER_AFTER_txtCityMA*/}
                  {/* START_USER_CODE-USER_BEFORE_ddStateMA*/}

                  {/* END_USER_CODE-USER_BEFORE_ddStateMA*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddStateMA}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddStateMA*/}

                  {/* END_USER_CODE-USER_AFTER_ddStateMA*/}
                  {/* START_USER_CODE-USER_BEFORE_txtZipMA*/}

                  {/* END_USER_CODE-USER_BEFORE_txtZipMA*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtZipMA}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtZipMA*/}

                  {/* END_USER_CODE-USER_AFTER_txtZipMA*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxMailingAddress*/}

                {/* END_USER_CODE-USER_AFTER_grpbxMailingAddress*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxCuredCleanVendor*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxCuredCleanVendor*/}

                <GroupBoxWidget
                  conf={state.grpbxCuredCleanVendor}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblCuredCleanVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_lblCuredCleanVendor*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblCuredCleanVendor}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblCuredCleanVendor*/}

                  {/* END_USER_CODE-USER_AFTER_lblCuredCleanVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendorCCV*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendorCCV*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendorCCV}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendorCCV*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendorCCV*/}
                  {/* START_USER_CODE-USER_BEFORE_btnVendorCCV*/}

                  {/* END_USER_CODE-USER_BEFORE_btnVendorCCV*/}

                  <ButtonWidget
                    conf={state.btnVendorCCV}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnVendorCCV*/}

                  {/* END_USER_CODE-USER_AFTER_btnVendorCCV*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVesndr*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendorNameCCV*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendorNameCCV}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendorNameCCV*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendorNameCCV*/}
                  {/* START_USER_CODE-USER_BEFORE_ddRemitToCCV*/}

                  {/* END_USER_CODE-USER_BEFORE_ddRemitToCCV*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddRemitToCCV}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddRemitToCCV*/}

                  {/* END_USER_CODE-USER_AFTER_ddRemitToCCV*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxCuredCleanVendor*/}

                {/* END_USER_CODE-USER_AFTER_grpbxCuredCleanVendor*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxDefaultCMAVendor*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDefaultCMAVendor*/}

                <GroupBoxWidget
                  conf={state.grpbxDefaultCMAVendor}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblDefaultCMAVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_lblDefaultCMAVendor*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblDefaultCMAVendor}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblDefaultCMAVendor*/}

                  {/* END_USER_CODE-USER_AFTER_lblDefaultCMAVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendorDCV*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendorDCV*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendorDCV}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendorDCV*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendorDCV*/}
                  {/* START_USER_CODE-USER_BEFORE_btnVendorDCV*/}

                  {/* END_USER_CODE-USER_BEFORE_btnVendorDCV*/}

                  <ButtonWidget
                    conf={state.btnVendorDCV}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnVendorDCV*/}

                  {/* END_USER_CODE-USER_AFTER_btnVendorDCV*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVndr*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVndr*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVndr}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVndr*/}

                  {/* END_USER_CODE-USER_AFTER_txtVndr*/}
                  {/* START_USER_CODE-USER_BEFORE_ddRemitToDCV*/}

                  {/* END_USER_CODE-USER_BEFORE_ddRemitToDCV*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddRemitToDCV}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddRemitToDCV*/}

                  {/* END_USER_CODE-USER_AFTER_ddRemitToDCV*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDefaultCMAVendor*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDefaultCMAVendor*/}
                </GroupBoxWidget>
                <GroupBoxWidget
                  conf={state.grpbxCntnr2}
                  screenConf={state}
                >
                {/* START_USER_CODE-USER_BEFORE_grpbxControlNumbers*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxControlNumbers*/}

                <GroupBoxWidget
                  conf={state.grpbxControlNumbers}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblControlNumbers*/}

                  {/* END_USER_CODE-USER_BEFORE_lblControlNumbers*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblControlNumbers}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblControlNumbers*/}

                  {/* END_USER_CODE-USER_AFTER_lblControlNumbers*/}
                  {/* START_USER_CODE-USER_BEFORE_lbl1007*/}

                  {/* END_USER_CODE-USER_BEFORE_lbl1007*/}

                  <LabelWidget
                    values={values}
                    conf={state.lbl1007}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lbl1007*/}

                  {/* END_USER_CODE-USER_AFTER_lbl1007*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNext*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNext*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNext}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNext*/}

                  {/* END_USER_CODE-USER_AFTER_txtNext*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBeg*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBeg*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBeg}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBeg*/}

                  {/* END_USER_CODE-USER_AFTER_txtBeg*/}
                  {/* START_USER_CODE-USER_BEFORE_txtEnd*/}

                  {/* END_USER_CODE-USER_BEFORE_txtEnd*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEnd}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtEnd*/}

                  {/* END_USER_CODE-USER_AFTER_txtEnd*/}
                  {/* START_USER_CODE-USER_BEFORE_lblAdditional1007*/}

                  {/* END_USER_CODE-USER_BEFORE_lblAdditional1007*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblAdditional1007}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblAdditional1007*/}

                  {/* END_USER_CODE-USER_AFTER_lblAdditional1007*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxAdditional1007*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxAdditional1007*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxAdditional1007}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxAdditional1007*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxAdditional1007*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBeg1*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBeg1*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBeg1}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBeg1*/}

                  {/* END_USER_CODE-USER_AFTER_txtBeg1*/}
                  {/* START_USER_CODE-USER_BEFORE_txtEnd1*/}

                  {/* END_USER_CODE-USER_BEFORE_txtEnd1*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEnd1}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtEnd1*/}

                  {/* END_USER_CODE-USER_AFTER_txtEnd1*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWhouse*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWhouse*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWhouse}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWhouse*/}

                  {/* END_USER_CODE-USER_AFTER_txtWhouse*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxUseAutomaticTransfer*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxUseAutomaticTransfer*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxUseAutomaticTransfer}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxUseAutomaticTransfer*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxUseAutomaticTransfer*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCheck*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCheck*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCheck}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCheck*/}

                  {/* END_USER_CODE-USER_AFTER_txtCheck*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBegCheck*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBegCheck*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBegCheck}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBegCheck*/}

                  {/* END_USER_CODE-USER_AFTER_txtBegCheck*/}
                  {/* START_USER_CODE-USER_BEFORE_txtEndCheck*/}

                  {/* END_USER_CODE-USER_BEFORE_txtEndCheck*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEndCheck}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtEndCheck*/}

                  {/* END_USER_CODE-USER_AFTER_txtEndCheck*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxControlNumbers*/}

                {/* END_USER_CODE-USER_AFTER_grpbxControlNumbers*/}
                
                {/* START_USER_CODE-USER_BEFORE_grpbxMiscellaneuos*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxMiscellaneuos*/}

                <GroupBoxWidget
                  conf={state.grpbxMiscellaneuos}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblMiscellaneous*/}

                  {/* END_USER_CODE-USER_BEFORE_lblMiscellaneous*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblMiscellaneous}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblMiscellaneous*/}

                  {/* END_USER_CODE-USER_AFTER_lblMiscellaneous*/}
                  {/* START_USER_CODE-USER_BEFORE_ddVicamLab*/}

                  {/* END_USER_CODE-USER_BEFORE_ddVicamLab*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddVicamLab}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddVicamLab*/}

                  {/* END_USER_CODE-USER_AFTER_ddVicamLab*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBalAcctResp*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBalAcctResp*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBalAcctResp}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBalAcctResp*/}

                  {/* END_USER_CODE-USER_AFTER_txtBalAcctResp*/}
                  {/* START_USER_CODE-USER_BEFORE_ddIrrigatedDryland*/}

                  {/* END_USER_CODE-USER_BEFORE_ddIrrigatedDryland*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddIrrigatedDryland}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddIrrigatedDryland*/}

                  {/* END_USER_CODE-USER_AFTER_ddIrrigatedDryland*/}
                  {/* START_USER_CODE-USER_BEFORE_txtBalAcctDept*/}

                  {/* END_USER_CODE-USER_BEFORE_txtBalAcctDept*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtBalAcctDept}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtBalAcctDept*/}

                  {/* END_USER_CODE-USER_AFTER_txtBalAcctDept*/}
                  {/* START_USER_CODE-USER_BEFORE_ddTransferGramEntry*/}

                  {/* END_USER_CODE-USER_BEFORE_ddTransferGramEntry*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddTransferGramEntry}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddTransferGramEntry*/}

                  {/* END_USER_CODE-USER_AFTER_ddTransferGramEntry*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPIAcctResp*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPIAcctResp*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPIAcctResp}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPIAcctResp*/}

                  {/* END_USER_CODE-USER_AFTER_txtPIAcctResp*/}
                  {/* START_USER_CODE-USER_BEFORE_ddTransferMoistureEntry*/}

                  {/* END_USER_CODE-USER_BEFORE_ddTransferMoistureEntry*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddTransferMoistureEntry}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddTransferMoistureEntry*/}

                  {/* END_USER_CODE-USER_AFTER_ddTransferMoistureEntry*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPIAcctDept*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPIAcctDept*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPIAcctDept}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPIAcctDept*/}

                  {/* END_USER_CODE-USER_AFTER_txtPIAcctDept*/}
                  {/* START_USER_CODE-USER_BEFORE_ddInspectionGramEntry*/}

                  {/* END_USER_CODE-USER_BEFORE_ddInspectionGramEntry*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddInspectionGramEntry}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddInspectionGramEntry*/}

                  {/* END_USER_CODE-USER_AFTER_ddInspectionGramEntry*/}
                  {/* START_USER_CODE-USER_BEFORE_ddInternetType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddInternetType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddInternetType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddInternetType*/}

                  {/* END_USER_CODE-USER_AFTER_ddInternetType*/}
                  {/* START_USER_CODE-USER_BEFORE_txtServiceProvider*/}

                  {/* END_USER_CODE-USER_BEFORE_txtServiceProvider*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtServiceProvider}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtServiceProvider*/}

                  {/* END_USER_CODE-USER_AFTER_txtServiceProvider*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxMiscellaneuos*/}

                {/* END_USER_CODE-USER_AFTER_grpbxMiscellaneuos*/}
                </GroupBoxWidget>
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxBuyingPointProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxBuyingPointProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrintScreen*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrintScreen*/}

                <ButtonWidget
                  conf={state.btnPrintScreen}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrintScreen*/}

                {/* END_USER_CODE-USER_AFTER_btnPrintScreen*/}
                {/* START_USER_CODE-USER_BEFORE_btn1*/}

                {/* END_USER_CODE-USER_BEFORE_btn1*/}

                <ButtonWidget
                  conf={state.btn1}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btn1*/}

                {/* END_USER_CODE-USER_AFTER_btn1*/}
                {/* START_USER_CODE-USER_BEFORE_btnMakeInactive*/}

                {/* END_USER_CODE-USER_BEFORE_btnMakeInactive*/}

                <ButtonWidget
                  conf={state.btnMakeInactive}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnMakeInactive*/}

                {/* END_USER_CODE-USER_AFTER_btnMakeInactive*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_BuyingPointProfile*/}
              </div>
              {/* END_USER_CODE-USER_AFTER_BuyingPointProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_BuyingPointProfile);
