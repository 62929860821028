/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  setData,
  getValue,
  useHistory,
  useLocation,
  useRouteMatch,
  getData,
  Modal,
  setValue,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  getSelectedRowNumber,
  disable,
  enable,
  goTo,
  hide,
  show
} from "../../shared/WindowImports";

import "./TradeAgreeSearch.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService"
import { SettlementService } from "../Service/SettlementService";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
// END_USER_CODE-USER_IMPORTS
function Settlements_TradeAgreeSearch(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "TradeAgreeSearch",
    windowName: "TradeAgreeSearch",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.TradeAgreeSearch",
    // START_USER_CODE-USER_TradeAgreeSearch_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Trade Agreement Search",
      scrCode: "PN1130A",

    },
    headerDetails: {
      year: 2021,
      companyName: "Golden Peanut Compnay"
    },

    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnCreateAgreement: {
      name: "btnCreateAgreement",
      type: "ButtonWidget",
      parent: "grpbxCreateOpenAgrmnt",
      Label: "Create Agreement",
      CharWidth: "33",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCreateAgreement_PROPERTIES

      // END_USER_CODE-USER_btnCreateAgreement_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnOpen: {
      name: "btnOpen",
      type: "ButtonWidget",
      parent: "grpbxCreateOpenAgrmnt",
      Label: "Open",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOpen_PROPERTIES

      // END_USER_CODE-USER_btnOpen_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxSearch",
      Label: "Search",
      CharWidth: "15",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxSearch",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    colAgree: {
      name: "colAgree",
      type: "GridColumnWidget",
      parent: "gridAgrmntSrch",
      Label: "Agree #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAgree_PROPERTIES

      // END_USER_CODE-USER_colAgree_PROPERTIES
    },
    colAgreedLbs: {
      name: "colAgreedLbs",
      type: "GridColumnWidget",
      parent: "gridAgrmntSrch",
      Label: "Agreed Lbs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAgreedLbs_PROPERTIES

      // END_USER_CODE-USER_colAgreedLbs_PROPERTIES
    },
    colAgreeDt: {
      name: "colAgreeDt",
      type: "GridColumnWidget",
      parent: "gridAgrmntSrch",
      Label: "Agree Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAgreeDt_PROPERTIES

      // END_USER_CODE-USER_colAgreeDt_PROPERTIES
    },
    colAppldLbs: {
      name: "colAppldLbs",
      type: "GridColumnWidget",
      parent: "gridAgrmntSrch",
      Label: "Applied Lbs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAppldLbs_PROPERTIES

      // END_USER_CODE-USER_colAppldLbs_PROPERTIES
    },
    colGrdMthd: {
      name: "colGrdMthd",
      type: "GridColumnWidget",
      parent: "gridAgrmntSrch",
      Label: "Grade Method",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGrdMthd_PROPERTIES

      // END_USER_CODE-USER_colGrdMthd_PROPERTIES
    },
    colLoc: {
      name: "colLoc",
      type: "GridColumnWidget",
      parent: "gridAgrmntSrch",
      Label: "Location",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLoc_PROPERTIES

      // END_USER_CODE-USER_colLoc_PROPERTIES
    },
    colOpenLbs: {
      name: "colOpenLbs",
      type: "GridColumnWidget",
      parent: "gridAgrmntSrch",
      Label: "Open Lbs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOpenLbs_PROPERTIES

      // END_USER_CODE-USER_colOpenLbs_PROPERTIES
    },
    colPrice: {
      name: "colPrice",
      type: "GridColumnWidget",
      parent: "gridAgrmntSrch",
      Label: "Price",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPrice_PROPERTIES

      // END_USER_CODE-USER_colPrice_PROPERTIES
    },
    colPriceMethd: {
      name: "colPriceMethd",
      type: "GridColumnWidget",
      parent: "gridAgrmntSrch",
      Label: "Price Method",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPriceMethd_PROPERTIES

      // END_USER_CODE-USER_colPriceMethd_PROPERTIES
    },
    colPys: {
      name: "colPys",
      type: "GridColumnWidget",
      parent: "gridAgrmntSrch",
      Label: "PYS",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPys_PROPERTIES

      // END_USER_CODE-USER_colPys_PROPERTIES
    },
    colSeedGen: {
      name: "colSeedGen",
      type: "GridColumnWidget",
      parent: "gridAgrmntSrch",
      Label: "Seed Gen",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeedGen_PROPERTIES

      // END_USER_CODE-USER_colSeedGen_PROPERTIES
    },
    colSeg: {
      name: "colSeg",
      type: "GridColumnWidget",
      parent: "gridAgrmntSrch",
      Label: "Seg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeg_PROPERTIES

      // END_USER_CODE-USER_colSeg_PROPERTIES
    },
    colTyp: {
      name: "colTyp",
      type: "GridColumnWidget",
      parent: "gridAgrmntSrch",
      Label: "Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTyp_PROPERTIES

      // END_USER_CODE-USER_colTyp_PROPERTIES
    },
    colVndr: {
      name: "colVndr",
      type: "GridColumnWidget",
      parent: "gridAgrmntSrch",
      Label: "Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVndr_PROPERTIES

      // END_USER_CODE-USER_colVndr_PROPERTIES
    },
    colVrty: {
      name: "colVrty",
      type: "GridColumnWidget",
      parent: "gridAgrmntSrch",
      Label: "Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVrty_PROPERTIES

      // END_USER_CODE-USER_colVrty_PROPERTIES
    },
    ddAgreementType: {
      name: "ddAgreementType",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Agreement Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddAgreementType_PROPERTIES

      // END_USER_CODE-USER_ddAgreementType_PROPERTIES
    },
    ddEdibleOil: {
      name: "ddEdibleOil",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "EdibleOil:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddEdibleOil_PROPERTIES

      // END_USER_CODE-USER_ddEdibleOil_PROPERTIES
    },
    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Location #:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation_PROPERTIES

      // END_USER_CODE-USER_ddLocation_PROPERTIES
    },
    ddOliec: {
      name: "ddOliec",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Oliec:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOliec_PROPERTIES

      // END_USER_CODE-USER_ddOliec_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Peanut Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddPeanutVariety: {
      name: "ddPeanutVariety",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Peanut Variety:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_ddPeanutVariety_PROPERTIES
    },
    ddSeedGeneration: {
      name: "ddSeedGeneration",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Seed Generation:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeedGeneration_PROPERTIES

      // END_USER_CODE-USER_ddSeedGeneration_PROPERTIES
    },
    ddSeg: {
      name: "ddSeg",
      type: "DropDownFieldWidget",
      parent: "grpbxSearch",
      Label: "Seg:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeg_PROPERTIES

      // END_USER_CODE-USER_ddSeg_PROPERTIES
    },
    gridAgrmntSrch: {
      name: "gridAgrmntSrch",
      type: "GridWidget",
      parent: "grpbxCreateOpenAgrmnt",
      gridCellsOrder:
        "txtcolLoc,txtcolPys,txtcolAgreeDt,txtcolAgree,txtcolVndruic0,txtcolTyp,txtcolVrty,txtcolSeedGen,txtcolSeg,txtcolGrdMthd,txtcolPriceMethduic0,txtcolPrice,txtcolAgreedLbs,txtcolAppldLbs,txtcolOpenLbs",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",

      isExpandable: true,
      defaultExpand: false,
      isMultiSelect: false,
      needGridChange: true,//Change for Grid change

      expandedCompTemplate: ({ data }) => (

        <div>
          {data.expArray ? (
            <div className="py-3 expandable-area col-sm-12 rdt_TableRow">
              {data.expArray.map((elem, i) =>
                <div className="py-3 expandable-area col-sm-12 rdt_TableRow selectable" key={"Row" + i}>
                  <div className={"row" + " rdt_ExpanderRow ExpandRow"} key={"ExpanderRow" + i} >
                    <div className="extraRowCell_1 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_2 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_3 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_4 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_5 ch-3" key="expandCell1">
                      <label>
                      {elem.SplitNumber + "-" + elem.SplitName}
                      </label>
                    </div>
                    <div className="extraRowCell_6 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_7 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_8 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_9 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_10 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_11 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_12 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_13 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_14 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_15 ch-3" key="expandCell1">
                    </div>
                   
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      )
      // START_USER_CODE-USER_gridAgrmntSrch_PROPERTIES
      // END_USER_CODE-USER_gridAgrmntSrch_PROPERTIES
    },
    lblCropYear2: {
      name: "lblCropYear2",
      type: "LabelWidget",
      parent: "grpbxCreateOpenAgrmnt",
      Label: "Crop Year:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCropYear2_PROPERTIES

      // END_USER_CODE-USER_lblCropYear2_PROPERTIES
    },
    txtAgreedLbs: {
      name: "txtAgreedLbs",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Agreed Lbs",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAgreedLbs_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtAgreedLbs_PROPERTIES
    },
    txtAgreement: {
      name: "txtAgreement",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: "Agreement #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAgreement_PROPERTIES

      // END_USER_CODE-USER_txtAgreement_PROPERTIES
    },
    txtAppliedLbs: {
      name: "txtAppliedLbs",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Applied Lbs",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAppliedLbs_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtAppliedLbs_PROPERTIES
    },
    txtcolAgree: {
      name: "txtcolAgree",
      type: "TextBoxWidget",
      colName: "colAgree",
      parent: "gridAgrmntSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAgree_PROPERTIES

      // END_USER_CODE-USER_txtcolAgree_PROPERTIES
    },
    txtcolAgreedLbs: {
      name: "txtcolAgreedLbs",
      type: "TextBoxWidget",
      colName: "colAgreedLbs",
      parent: "gridAgrmntSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAgreedLbs_PROPERTIES

      // END_USER_CODE-USER_txtcolAgreedLbs_PROPERTIES
    },
    txtcolAgreeDt: {
      name: "txtcolAgreeDt",
      type: "TextBoxWidget",
      colName: "colAgreeDt",
      parent: "gridAgrmntSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAgreeDt_PROPERTIES

      // END_USER_CODE-USER_txtcolAgreeDt_PROPERTIES
    },
    txtcolAppldLbs: {
      name: "txtcolAppldLbs",
      type: "TextBoxWidget",
      colName: "colAppldLbs",
      parent: "gridAgrmntSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAppldLbs_PROPERTIES

      // END_USER_CODE-USER_txtcolAppldLbs_PROPERTIES
    },
    txtcolGrdMthd: {
      name: "txtcolGrdMthd",
      type: "TextBoxWidget",
      colName: "colGrdMthd",
      parent: "gridAgrmntSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolGrdMthd_PROPERTIES

      // END_USER_CODE-USER_txtcolGrdMthd_PROPERTIES
    },
    txtcolLoc: {
      name: "txtcolLoc",
      type: "TextBoxWidget",
      colName: "colLoc",
      parent: "gridAgrmntSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLoc_PROPERTIES

      // END_USER_CODE-USER_txtcolLoc_PROPERTIES
    },
    txtcolOpenLbs: {
      name: "txtcolOpenLbs",
      type: "TextBoxWidget",
      colName: "colOpenLbs",
      parent: "gridAgrmntSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOpenLbs_PROPERTIES

      // END_USER_CODE-USER_txtcolOpenLbs_PROPERTIES
    },
    txtcolPrice: {
      name: "txtcolPrice",
      type: "TextBoxWidget",
      colName: "colPrice",
      parent: "gridAgrmntSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPrice_PROPERTIES

      // END_USER_CODE-USER_txtcolPrice_PROPERTIES
    },
    txtcolPriceMethduic0: {
      name: "txtcolPriceMethduic0",
      type: "TextBoxWidget",
      colName: "colPriceMethd",
      parent: "gridAgrmntSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPriceMethduic0_PROPERTIES

      // END_USER_CODE-USER_txtcolPriceMethduic0_PROPERTIES
    },
    txtcolPys: {
      name: "txtcolPys",
      type: "TextBoxWidget",
      colName: "colPys",
      parent: "gridAgrmntSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPys_PROPERTIES

      // END_USER_CODE-USER_txtcolPys_PROPERTIES
    },
    txtcolSeedGen: {
      name: "txtcolSeedGen",
      type: "TextBoxWidget",
      colName: "colSeedGen",
      parent: "gridAgrmntSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeedGen_PROPERTIES

      // END_USER_CODE-USER_txtcolSeedGen_PROPERTIES
    },
    txtcolSeg: {
      name: "txtcolSeg",
      type: "TextBoxWidget",
      colName: "colSeg",
      parent: "gridAgrmntSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeg_PROPERTIES

      // END_USER_CODE-USER_txtcolSeg_PROPERTIES
    },
    txtcolTyp: {
      name: "txtcolTyp",
      type: "TextBoxWidget",
      colName: "colTyp",
      parent: "gridAgrmntSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTyp_PROPERTIES

      // END_USER_CODE-USER_txtcolTyp_PROPERTIES
    },
    txtcolVndruic0: {
      name: "txtcolVndruic0",
      type: "TextBoxWidget",
      colName: "colVndr",
      parent: "gridAgrmntSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVndruic0_PROPERTIES

      // END_USER_CODE-USER_txtcolVndruic0_PROPERTIES
    },
    txtcolVrty: {
      name: "txtcolVrty",
      type: "TextBoxWidget",
      colName: "colVrty",
      parent: "gridAgrmntSrch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVrty_PROPERTIES

      // END_USER_CODE-USER_txtcolVrty_PROPERTIES
    },
    txtOpenLbs: {
      name: "txtOpenLbs",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Open Lbs",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOpenLbs_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtOpenLbs_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtVndor: {
      name: "txtVndor",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      Label: " ",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },

    grpbxSearch: {
      name: "grpbxSearch",
      type: "GroupBoxWidget",
      parent: "TradeAgreeSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxSearch_PROPERTIES

      // END_USER_CODE-USER_grpbxSearch_PROPERTIES
    },
    grpbxCreateOpenAgrmnt: {
      name: "grpbxCreateOpenAgrmnt",
      type: "GroupBoxWidget",
      parent: "TradeAgreeSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxCreateOpenAgrmnt_PROPERTIES

      // END_USER_CODE-USER_grpbxCreateOpenAgrmnt_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "TradeAgreeSearch",
      Height: "",
      Width: "",
      clonedExtId: "22367",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "Settlements#TradeAgreeProfile": {},
        "ContractManagement#MainMenu": {},
      },
      REVERSE: {
        "Settlements#TradeAgreeProfile": {},
        "ContractManagement#MainMenu": {},

      },
    },
  };
  let _buttonServices = { TradeAgreeProfile: {} };
  let _buttonNavigation = {
    btnCreateAgreement: {
      DEFAULT: ["Settlements#TradeAgreeProfile#DEFAULT#true#Click"],
    },
    btnOpen: {
      DEFAULT: ["Settlements#TradeAgreeProfile#DEFAULT#true#Click"],
    },
    btnVendor: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
    btnClose: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    }
  };

  const [loading, setLoading] = useState(false);
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });

  useEffect(() => {
    let flag = getData(thisObj, "Tradeagreesearchdata");
    if (flag !== 'initial' && flag !== null) {
      setTimeout(() => {
        openGrid()
      }, 2000)
    }
    setData(thisObj, "Tradeagreesearchdata", "initial")
  }, [getData(thisObj, "Tradeagreesearchdata")]);


  useEffect(() => {
    let closingOfTradeSettleSearch = getData(thisObj, "refresData")
    if ( closingOfTradeSettleSearch === null || closingOfTradeSettleSearch != "Ok" ) {
    bFillRemitToList()
    }
    setData(thisObj,"refresData","False")
  }, [getData(thisObj, 'vendorDetails')]);

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    setValue(thisObj, "lblCropYear2", cropYearFromLS())
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    setValue(thisObj, "ddPeanutType", ''),
      setValue(thisObj, "ddPeanutVariety", ''),
      setValue(thisObj, "ddSeg", ''),
      setValue(thisObj, "ddOliec", ''),
      setValue(thisObj, "ddLocation", ''),
      setValue(thisObj, "ddSeedGeneration", ''),
      setValue(thisObj, "ddAgreementType", ''),
      setValue(thisObj, "ddEdibleOil", '')
    setValue(thisObj, "txtVendor", "");
    setValue(thisObj, "txtVndor", "");
    hide(thisObj, "txtVndor",false)
    setData(thisObj, 'txtAgreedLbs', 0)
    setData(thisObj, "txtAppliedLbs", 0);
    setData(thisObj, "txtOpenLbs", 0)
    FormLoad()
  }, []);

  function FormLoad() {
    bFillTypeList(); // Method for binding Peanut Type dropdown
    bFillBuyingPointList();// Method for binding Buying Points
    bFillVarietyList(null, null);//Method for binding Peanut
    bFillSegmentList(); // Method for binding Segments
    bFillOleicList(); // Method for binding Oleic values
    bFillSeedGeneration();//Method for binding Seed generation
    bFillAgreementType();//Method for binding Agreement Type
    bFillEdibleOil();//Method for binding Edible Oil
    disable(thisObj, "btnOpen")
  }


  //Buying Point dropdown change event
  const onBuyingPointChange = () => {
    try {
      let PeanutTypelist = thisObj.state['ddPeanutType'].valueList;
      let SelectedPeanutTypeValue = getValue(thisObj, "ddPeanutType", '')

      let pnut_type_id = null
      if (SelectedPeanutTypeValue !== "" && SelectedPeanutTypeValue !== undefined) {
        pnut_type_id = PeanutTypelist.find(elem => elem.key === SelectedPeanutTypeValue).key;
      }

      let BuyingPointList = thisObj.state['ddLocation'].valueList;
      let SelectedBuyingPointValue = getValue(thisObj, "ddLocation", '')

      let buyingPointId = null
      if (SelectedBuyingPointValue !== "" && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }
      //Bind Peanut Variety based on Buying Point and Peanut Type
      bFillVarietyList(buyingPointId, pnut_type_id)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Buying Point dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddLocationChange = onBuyingPointChange;

  //Method for binding Peanut Type dropdown
  const bFillTypeList = () => {
    let js = []
    js.push({ key: '', description: '>>> All Types <<<' })
    ContractManagementService.RetrievePeanutTypeControls(null).then(response => {
      let data = response

      if (response !== undefined && response.length > 0) {
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeName }
          js.push(obj)
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutType: {
            ...state["ddPeanutType"],
            valueList: js,
          }
        }
      })
    })
  }

  //Peanut Type dropdown change event
  const onPeanutTypeChange = () => {
    try {
      let PeanutTypelist = thisObj.state['ddPeanutType'].valueList;
      let SelectedPeanutTypeValue = getValue(thisObj, "ddPeanutType", '')

      let pnut_type_id = PeanutTypelist.find(elem => elem.key === SelectedPeanutTypeValue).key;


      let BuyingPointList = thisObj.state['ddLocation'].valueList;
      let SelectedBuyingPointValue = getValue(thisObj, "ddLocation", '')
      let buyingPointId = null
      if (SelectedBuyingPointValue !== null && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }

      //Bind Peanut Variety based on Buying Point and Peanut Type
      setValue(thisObj, 'ddPeanutVariety', '')
      bFillVarietyList(buyingPointId, pnut_type_id)

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Peanut Type dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddPeanutTypeChange = onPeanutTypeChange;

  const ontxtVendorChange = () => {
    try {
      let vendorNumber = txtVendor.value;
    if (vendorNumber.length == 6) {
      bFillRemitToList()
      // show(thisObj, "txtVndr");
    }
    else {
      setValue(thisObj, "txtVndor", '');
      hide(thisObj, "txtVndor",false);
    }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during ontxtVendorChange event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtVendorChange = ontxtVendorChange;

  //seaching vendorname based on vendorname
  const bFillRemitToList = () => {
    let isVendorLookup = false;
    let vendor_lookup = getData(thisObj, 'vendorDetails');
    let vendorNumber, vendorName;
    if (vendor_lookup !== null) {
      vendorNumber = vendor_lookup.VendorNumber;
      vendorName = vendor_lookup.vendorName;
      setValue(thisObj, "txtVndor", vendorName);
      setValue(thisObj, "txtVendor", vendorNumber);
      isVendorLookup = true;
      setData(thisObj, 'vendorDetails', null);
      show(thisObj, 'txtVndor')
      return;
    }
    let vendorValueFromTextBox = getValue(thisObj, 'txtVendor');
    if (vendorValueFromTextBox !== undefined && isVendorLookup == false) {
      vendorNumber = vendorValueFromTextBox;
    }
    if (vendorNumber != "" && vendorNumber != undefined && isVendorLookup == false) {
      ContractManagementService.RetrieveVendorByNumber(vendorNumber).then(response => {
        let data = response;
        if (response !== undefined && response.length > 0) {
        vendorName = data[0].name;   
        setValue(thisObj, "txtVndor", vendorName);
        setValue(thisObj, "txtVendor", vendorNumber);
        show(thisObj,"txtVndor")
        }
      })
    }
  }

  const onbtnCreateAgreementClick = () => {
    try {
      let Obj = {}
      Obj.UpdateBtnCaption = "Add";
      Obj.UpdateBtnlblStatus = "Add";
      Obj.UpdateBtnEnabledDelete = "False";
      Obj.UpdateBtnEnabledPrint = "False";

      let js = [];
      js.push(Obj);
      setData(thisObj, "CreateValue", js)

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "error on Create Agreement Btn")
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnCreateAgreementClick = onbtnCreateAgreementClick;

  const onbtnOpenClick = () => {
    try {
      if (thisObj.state.gridAgrmntSrch.selected[0].txtcolAgree !== undefined) {

        let Agreenum = thisObj.state.gridAgrmntSrch.selected[0].txtcolAgree
        let Obj = {}
        Obj.Agreenum = Agreenum;
        Obj.GstrEditMode = "1";
        Obj.UpdateBtnCaption = "Update";
        Obj.UpdateBtnlblStatus = "Update";
        Obj.UpdateBtnEnabledDelete = "True";
        Obj.UpdateBtnEnabledPrint = "True";

        let js = [];
        js.push(Obj);
        setData(thisObj, "CreateValue", js)
      }
      else {
        showMessage(thisObj, "An agreement must be selected to open");
        return false
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "No Agreement Selected"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnOpenClick = onbtnOpenClick;
  const ongridAgrmntSrchDblclick = () => {
    try {
      if (thisObj.state.gridAgrmntSrch.selected[0].txtcolAgree !== undefined) {
        let Agreenum = thisObj.state.gridAgrmntSrch.selected[0].txtcolAgree
        let Obj = {}
        Obj.Agreenum = Agreenum;
        Obj.GstrEditMode = "1";
        Obj.UpdateBtnCaption = "Update";
        Obj.UpdateBtnlblStatus = "Update";
        Obj.UpdateBtnEnabledDelete = "True";
        Obj.UpdateBtnEnabledPrint = "True";
        let js = [];
        js.push(Obj);
        setData(thisObj, "CreateValue", js)
        goTo(thisObj, "Settlements#TradeAgreeProfile#DEFAULT#true#Click")
      }
      else {
        showMessage(thisObj, "An agreement must be selected to open");
        return false
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(thisObj, "No Agreement Selected");
      }
      return false;
    }
    return true;
  };
  thisObj.ongridAgrmntSrchDblclick = ongridAgrmntSrchDblclick;
  let totalAgreedLbs = 0;
  let totalappliedLbs = 0;
  let totalopenLbs = 0;

  function formatString(str) {
    // Check if string contains a number
    if (!isNaN(str)) {
      // If yes, format string with commas
      return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      // If no, leave string as it is
      return str;
    }
  }

  const onbtnSearchClick = () => {
    try {
      openGrid()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:btnSearch: Click"
        )
      }
      setLoading(false);
      return false
    }
    return true

  };
  thisObj.onbtnSearchClick = onbtnSearchClick;

  const openGrid = () => {
    try {
      let func_id = 'PN1130'
      let func_sub_id = null
      let buy_pt_id = getValue(thisObj, "ddLocation", '')
      let agree_num = getValue(thisObj, "txtAgreement", '')
      let agree_type = getValue(thisObj, "ddAgreementType", '')
      let agree_status = null
      let pnut_type_id = getValue(thisObj, "ddPeanutType", '')
      let pnut_variety_id = getValue(thisObj, "ddPeanutVariety", '')
      let seed_gen = getValue(thisObj, "ddSeedGeneration", '')
      let seg_type = getValue(thisObj, "ddSeg", '')
      let oleic_ind = getValue(thisObj, "ddOliec", '')
      let edible_oil = getValue(thisObj, "ddEdibleOil", '')
      let delv_ind = 'N'
      let split_vendo = getValue(thisObj, "txtVendor", '')
      enable(thisObj, "btnOpen")

      setLoading(true);
      SettlementService.RetrieveTradeAgreementDetails(func_id, func_sub_id, buy_pt_id, agree_num, agree_type, agree_status, pnut_type_id, pnut_variety_id, seed_gen, seg_type, oleic_ind, edible_oil, delv_ind, split_vendo)
        .then(response => {
          let TransformedRowsArray = [];
          if (response != undefined && response.length > 0) {
            let data = response;

            let obj = {};

            for (let i = 0; i < data.length; i++) {
              obj.txtcolAgree = data[i].agreement_num
              obj.txtcolLoc = data[i].buy_pt_id
              obj.txtcolPys = data[i].purch_sale_ind
              obj.txtcolAgreeDt = new Date(data[i].agree_date_time).toLocaleDateString();

              let VendorNumber = data[i].split_vendor
              let VendorName = data[i].vendor_name
              if (VendorNumber.includes("|")) {
                let VendorNumberArr = VendorNumber.split("|")
                let VendorNameArr = VendorName.split("|")
                let VendorArr = [];

                for (let i = 0; i < VendorNumberArr.length; i++) {
                  let object = {};
                  object.SplitNumber = VendorNumberArr[i];
                  object.SplitName = VendorNameArr[i];
                  VendorArr.push(object)
                  obj.expArray = VendorArr
                }

                obj.txtcolVndruic0 = "Multi ..."


              }
              else {
                obj.txtcolVndruic0 = data[i].split_vendor + " - " + data[i].vendor_name
              }

              obj.txtcolTyp = data[i].pnut_type_name

              switch (data[i].symbol_ind) {
                case "TRADEMARK":
                  obj.txtcolVrty = data[i].pnut_variety_name + ' ᵀᴹ';
                  break;

                case "REGISTERED TRADEMARK":
                  obj.txtcolVrty = data[i].pnut_variety_name + ' ®';
                  break;

                case "COPYRIGHT":
                  obj.txtcolVrty = data[i].pnut_variety_name + ' ©';
                  break;

                default:
                  obj.txtcolVrty = data[i].pnut_variety_name
              }

              obj.txtcolSeedGen = data[i].seed_gen
              obj.txtcolSeg = data[i].seg_type
              obj.txtcolGrdMthd = data[i].grade_pricing_method

              switch (data[i].firm_basis_opt) {
                case "F":
                  obj.txtcolPriceMethduic0 = "FIRM";
                  break;
                case "B":
                  obj.txtcolPriceMethduic0 = "BASIS";
                  break;
                case "L":
                  obj.txtcolPriceMethduic0 = "FLAT";
                  break;
              }

              if (data[i].grade_pricing_method == "FULL") {
                obj.txtcolPrice = data[i].price_per_ton
              }
              else {
                obj.txtcolPrice = data[i].price_per_lbs
              }

              obj.txtcolAgreedLbs = data[i].agreed_lbs
              totalAgreedLbs = totalAgreedLbs + parseFloat(data[i].agreed_lbs);

              obj.txtcolAppldLbs = data[i].delivered_lbs
              totalappliedLbs = totalappliedLbs + parseFloat(data[i].delivered_lbs);

              obj.txtcolOpenLbs = (data[i].agreed_lbs - data[i].delivered_lbs);
              totalopenLbs = totalopenLbs + parseFloat(data[i].agreed_lbs - data[i].delivered_lbs);

              TransformedRowsArray.push(obj)
              obj = {}
            }


            setValue(thisObj, "gridAgrmntSrch", TransformedRowsArray);
            setValue(thisObj, "txtAgreedLbs", formatString(totalAgreedLbs));
            setData(thisObj, 'txtAgreedLbs', totalAgreedLbs)
            setValue(thisObj, "txtAppliedLbs", formatString(totalappliedLbs));
            setData(thisObj, "txtAppliedLbs", totalappliedLbs);
            setValue(thisObj, "txtOpenLbs", formatString(totalopenLbs))
            setData(thisObj, "txtOpenLbs", totalopenLbs)
          }
          else {
            setLoading(false);
            setValue(thisObj, 'gridAgrmntSrch', [])
            showMessage(thisObj, 'No Agreements Found!!!')
            setValue(thisObj, "txtAgreedLbs", '0');
            setData(thisObj, 'txtAgreedLbs', totalAgreedLbs)
            setValue(thisObj, "txtAppliedLbs", '0');
            setData(thisObj, "txtAppliedLbs", totalappliedLbs);
            setValue(thisObj, "txtOpenLbs", '0')
            setData(thisObj, "txtOpenLbs", totalopenLbs)
          }
          setLoading(false);

        })
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:btnSearch: Click"
        )
      }
      setLoading(false);
      return false
    }
    return true
  }

  //Method for Export to Excel button
  const onbtnExportToExcelClick = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let datatable = getValue(thisObj, 'gridAgrmntSrch');
    var fileName = "Trade Agreement Search";
    let excelData = [];
    if (datatable != undefined) {
      for (let i = 0; i < datatable.length; i++) {
        let rowdata = {};
        if (thisObj.state.gridAgrmntSrch.columns[0].Visible) {
          rowdata["Location"] = datatable[i].txtcolLoc;
        }
        if (thisObj.state.gridAgrmntSrch.columns[1].Visible) {
          rowdata["P/S"] = datatable[i].txtcolPys;
        }
        if (thisObj.state.gridAgrmntSrch.columns[2].Visible) {
          rowdata["Agree Date"] = datatable[i].txtcolAgreeDt;
        }
        if (thisObj.state.gridAgrmntSrch.columns[3].Visible) {
          rowdata["Agree #"] = ExcelNumericDataConvert(datatable[i].txtcolAgree);
        }
        if (thisObj.state.gridAgrmntSrch.columns[4].Visible) {
          rowdata["Vendor"] = datatable[i].txtcolVndruic0;
        }
        if (thisObj.state.gridAgrmntSrch.columns[5].Visible) {
          rowdata["Type"] = datatable[i].txtcolTyp;
        }
        if (thisObj.state.gridAgrmntSrch.columns[6].Visible) {
          rowdata["Variety"] = datatable[i].txtcolVrty;
        }
        if (thisObj.state.gridAgrmntSrch.columns[7].Visible) {
          rowdata["Seed Gen"] = datatable[i].txtcolSeedGen;
        }
        if (thisObj.state.gridAgrmntSrch.columns[8].Visible) {
          rowdata["Seg"] = ExcelNumericDataConvert(datatable[i].txtcolSeg);
        }
        if (thisObj.state.gridAgrmntSrch.columns[9].Visible) {
          rowdata["Grade Method"] = datatable[i].txtcolGrdMthd;
        }
        if (thisObj.state.gridAgrmntSrch.columns[10].Visible) {
          rowdata["Price Method"] = datatable[i].txtcolPriceMethduic0;
        }

        if (thisObj.state.gridAgrmntSrch.columns[11].Visible) {
          rowdata["Price"] = ExcelNumericDataConvert(datatable[i].txtcolPrice);
        }
        if (thisObj.state.gridAgrmntSrch.columns[12].Visible) {
          rowdata["Agreed Lbs"] = ExcelNumericDataConvert(datatable[i].txtcolAgreedLbs);
        }
        if (thisObj.state.gridAgrmntSrch.columns[13].Visible) {
          rowdata["Applied Lbs"] = ExcelNumericDataConvert(datatable[i].txtcolAppldLbs);
        }
        if (thisObj.state.gridAgrmntSrch.columns[14].Visible) {
          rowdata["Open Lbs"] = ExcelNumericDataConvert(datatable[i].txtcolOpenLbs);
        }
        excelData.push(rowdata);

        if (thisObj.state.gridAgrmntSrch.columns[4].Visible) {
          if (datatable[i].expArray != undefined) {
            for (let j = 0; j < datatable[i].expArray.length; j++) {
              if (thisObj.state.gridAgrmntSrch.columns[4].Visible) {
                let extendedData = {};
                extendedData["Vendor"] = datatable[i].expArray[j].SplitNumber + ' - ' + datatable[i].expArray[j].SplitName;
                excelData.push(extendedData);
              }
            }
          }
        }
      }
      
      let totals = {};
      let trigger=0;
      for(let i=0;i<12;i++){
      if (thisObj.state.gridAgrmntSrch.columns[i].Visible ) {
      totals[thisObj.state.gridAgrmntSrch.columns[i].Label] = "Totals";
      trigger=1;
      break;
      }
    }
      if(trigger!==1){
        excelData.push('')
      }
      if (thisObj.state.gridAgrmntSrch.columns[12].Visible) {
      totals["Agreed Lbs"] = ExcelNumericDataConvert(getValue(thisObj, 'txtAgreedLbs',),[','])
      }
      if (thisObj.state.gridAgrmntSrch.columns[13].Visible) {
        totals["Applied Lbs"] = ExcelNumericDataConvert(getValue(thisObj, 'txtAppliedLbs'),[','])
      }
      if (thisObj.state.gridAgrmntSrch.columns[14].Visible) {
      totals["Open Lbs"] = ExcelNumericDataConvert(getValue(thisObj, 'txtOpenLbs'),[',']);
      }

      excelData.push(totals);
    }
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    var FileSaver = require('file-saver');
    FileSaver.saveAs(data, fileName + fileExtension);
  }
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;

  //Method for Print button
  const onbtnPrintClick = () => {
    try {
      var data = getValue(thisObj, "gridAgrmntSrch")

      var res = []
      var headerArray = []
      if (thisObj.state.gridAgrmntSrch.columns[0].Visible) {
        headerArray.push("Location");
      }
      if (thisObj.state.gridAgrmntSrch.columns[1].Visible) {
        headerArray.push("P/S");
      }
      if (thisObj.state.gridAgrmntSrch.columns[2].Visible) {
        headerArray.push("Agree Date");
      }
      if (thisObj.state.gridAgrmntSrch.columns[3].Visible) {
        headerArray.push("Agree #");
      }
      if (thisObj.state.gridAgrmntSrch.columns[4].Visible) {
        headerArray.push("Vendor");
      }
      if (thisObj.state.gridAgrmntSrch.columns[5].Visible) {
        headerArray.push("Type");
      }
      if (thisObj.state.gridAgrmntSrch.columns[6].Visible) {
        headerArray.push("Variety");
      }
      if (thisObj.state.gridAgrmntSrch.columns[7].Visible) {
        headerArray.push("Seed Gen");
      }
      if (thisObj.state.gridAgrmntSrch.columns[8].Visible) {
        headerArray.push("Segment");
      }
      if (thisObj.state.gridAgrmntSrch.columns[9].Visible) {
        headerArray.push("Grade Method");
      }
      if (thisObj.state.gridAgrmntSrch.columns[10].Visible) {
        headerArray.push("Price Method");
      }

      if (thisObj.state.gridAgrmntSrch.columns[11].Visible) {
        headerArray.push("Price");
      }
      if (thisObj.state.gridAgrmntSrch.columns[12].Visible) {
        headerArray.push("Agreed Lbs");
      }
      if (thisObj.state.gridAgrmntSrch.columns[13].Visible) {
        headerArray.push("Applied Lbs");
      }
      if (thisObj.state.gridAgrmntSrch.columns[14].Visible) {
        headerArray.push("Open Lbs");
      }

      for (var i = 0; i < data.length; i++) {
        let NewArray = []

        if (thisObj.state.gridAgrmntSrch.columns[0].Visible) {
          NewArray.push(data[i].txtcolLoc);
        }
        if (thisObj.state.gridAgrmntSrch.columns[1].Visible) {
          NewArray.push(data[i].txtcolPys);
        }
        if (thisObj.state.gridAgrmntSrch.columns[2].Visible) {
          NewArray.push(data[i].txtcolAgreeDt);
        }
        if (thisObj.state.gridAgrmntSrch.columns[3].Visible) {
          NewArray.push(data[i].txtcolAgree);
        }
        if (thisObj.state.gridAgrmntSrch.columns[4].Visible) {
          NewArray.push(data[i].txtcolVndruic0);
        }
        if (thisObj.state.gridAgrmntSrch.columns[5].Visible) {
          NewArray.push(data[i].txtcolTyp);
        }
        if (thisObj.state.gridAgrmntSrch.columns[6].Visible) {
          NewArray.push(data[i].txtcolVrty);
        }
        if (thisObj.state.gridAgrmntSrch.columns[7].Visible) {
          NewArray.push(data[i].txtcolSeedGen);
        }
        if (thisObj.state.gridAgrmntSrch.columns[8].Visible) {
          NewArray.push(data[i].txtcolSeg);
        }
        if (thisObj.state.gridAgrmntSrch.columns[9].Visible) {
          NewArray.push(data[i].txtcolGrdMthd);
        }
        if (thisObj.state.gridAgrmntSrch.columns[10].Visible) {
          NewArray.push(data[i].txtcolPriceMethduic0);
        }

        if (thisObj.state.gridAgrmntSrch.columns[11].Visible) {
          NewArray.push(data[i].txtcolPrice);
        }
        if (thisObj.state.gridAgrmntSrch.columns[12].Visible) {
          NewArray.push(data[i].txtcolAgreedLbs);
        }
        if (thisObj.state.gridAgrmntSrch.columns[13].Visible) {
          NewArray.push(data[i].txtcolAppldLbs);
        }
        if (thisObj.state.gridAgrmntSrch.columns[14].Visible) {
          NewArray.push(data[i].txtcolOpenLbs);
        }

        // res.push([
        //   data[i].txtcolLoc,
        //   data[i].txtcolPys,
        //   data[i].txtcolAgreeDt,
        //   data[i].txtcolAgree,
        //   data[i].txtcolVndruic0,
        //   data[i].txtcolTyp,
        //   data[i].txtcolVrty,
        //   data[i].txtcolSeedGen,
        //   data[i].txtcolSeg,
        //   data[i].txtcolGrdMthd,
        //   data[i].txtcolPriceMethduic0,
        //   data[i].txtcolPrice,
        //   data[i].txtcolAgreedLbs,
        //   data[i].txtcolAppldLbs,
        //   data[i].txtcolOpenLbs
        // ])
        res.push(NewArray)
      }
      // res.push([
      //   'Total',
      //   '',
      //   '',
      //   '',
      //   '',
      //   '',
      //   '',
      //   '',
      //   '',
      //   '',
      //   '',
      //   '',
      //   getData(thisObj, 'txtAgreedLbs'),
      //   getData(thisObj, 'txtAppliedLbs'),
      //   getData(thisObj, 'txtOpenLbs')
      // ])

      var obj = new jsPDF();
      obj.autoTable({
        startY: 5,
        startX: 20,
        styles: { fontSize: 6, lineWidth: 0.2, color: 10 },
        body: res,
        theme: 'plain',
        fontStyle: 'normal',
        head: [headerArray]
      });

      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj.save(fileName + '.pdf')
        }
        else {
          alert("Routine:TradeAgreemntSearch.cmdPrintSearch_Click")
        }
      }
      else {
        alert("Routine:TradeAgreemntSearch.cmdPrintSearch_Click")
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnPrintscreen event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnPrintClick = onbtnPrintClick;


  //Method for binding Peanut Variety based on Buying Point and Peanut Type
  const bFillVarietyList = (buyingPointId, pnut_type_id) => {

    let js = [];
    let obj = ({ key: '', description: '>>> All Varieties <<<' });
    js.push(obj)
    if (pnut_type_id !== null && pnut_type_id !== '') {
      ContractManagementService.RetrievePeanutVarietyControls(buyingPointId, null, null, pnut_type_id, null)
        .then(response => {
          let data = response;

          if (data !== null && data !== undefined && data.length > 0) {
            let js1 = [];
            for (var i = 0; i < data.length; i++) {
              if (!(js1.includes(data[i].pnut_variety_id))) {
                obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name }
                if (data[i].symbol_ind == "TRADEMARK") {
                  obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + 'ᵀᴹ' }
                }
                if (data[i].symbol_ind == "REGISTERED TRADEMARK") {
                  obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + '®' }
                }
                if (data[i].symbol_ind == "COPYRIGHT") {
                  obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + '©' }
                }
                js.push(obj);
                js1.push(data[i].pnut_variety_id)
              }
            }
          }
        })
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddPeanutVariety: {
          ...state["ddPeanutVariety"],
          valueList: js
        }
      }
    });
  }

  // Method for binding Buying Points
  const bFillBuyingPointList = () => {
    let js = []
    js.push({ key: '', description: '>>>All Buying Points<<<' });
    ContractManagementService.RetrieveBuyingPointControlDetails('PN1150', null, null, null, null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddLocation: {
            ...state["ddLocation"],
            valueList: js
          }
        }
      })

    })
  }

  // Method for binding Segment list  
  const bFillSegmentList = () => {
    let js = []
    js.push({ key: '', description: '>>> All Segs <<<' })
    js.push({ key: '1', description: '1' })
    js.push({ key: '2', description: '2' })
    js.push({ key: '3', description: '3' })

    thisObj.setState(current => {
      return {
        ...current,
        ddSeg: {
          ...state["ddSeg"],
          valueList: js
        }
      }
    })
  }

  // Method for binding Seed Genration
  const bFillSeedGeneration = () => {
    let js = []
    js.push({ key: '', description: '>>> All Generations <<<' })
    js.push({ key: 'F', description: 'Foundation' })
    js.push({ key: 'R', description: 'Registered' })
    js.push({ key: 'C', description: 'Certified' })
    thisObj.setState(current => {
      return {
        ...current,
        ddSeedGeneration: {
          ...state["ddSeedGeneration"],
          valueList: js
        }
      }
    })
  }

  // Method for Oleic values 
  const bFillOleicList = () => {
    let js = []
    js.push({ key: '', description: '>>> All Oleic <<<' })
    js.push({ key: 'H', description: 'High' })
    js.push({ key: 'M', description: 'Mid' })
    js.push({ key: 'N', description: 'None' })
    thisObj.setState(current => {
      return {
        ...current,
        ddOliec: {
          ...state["ddOliec"],
          valueList: js
        }
      }
    })
  }
  //Method for binding Agreement Type

  const bFillAgreementType = () => {
    let js = []
    js.push({ key: '', description: '>>> All Types <<<' })
    js.push({ key: 'P', description: 'Trade Purchase' })
    js.push({ key: 'S', description: 'Trade Sale' })
    thisObj.setState(current => {
      return {
        ...current,
        ddAgreementType: {
          ...state["ddAgreementType"],
          valueList: js
        }
      }
    })
  }
  //Method for binding Edible Oil
  const bFillEdibleOil = () => {
    let js = []
    js.push({ key: '', description: '>>> All <<<' })
    js.push({ key: 'E', description: 'Edible' })
    js.push({ key: 'O', description: 'Oil' })
    thisObj.setState(current => {
      return {
        ...current,
        ddEdibleOil: {
          ...state["ddEdibleOil"],
          valueList: js
        }
      }
    })
  }
  const validateTextBox = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) {
        res += data[i]
      }
    }
    return res;
  }
  const ontxtAgreementChange = () => {
    try {
      setValue(thisObj, "txtAgreement", validateTextBox(txtAgreement.value))
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtAgreement change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtAgreementChange = ontxtAgreementChange;

  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} headerDetails={state.headerDetails} />

            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_TradeAgreeSearch*/}

              {/* END_USER_CODE-USER_BEFORE_TradeAgreeSearch*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxSearch*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSearch*/}

              <GroupBoxWidget conf={state.grpbxSearch} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_ddLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddLocation*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddLocation*/}
                {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_txtAgreement*/}

                {/* END_USER_CODE-USER_BEFORE_txtAgreement*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAgreement}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAgreement*/}

                {/* END_USER_CODE-USER_AFTER_txtAgreement*/}
                {/* START_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutVariety}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutVariety*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutVariety*/}
                {/* START_USER_CODE-USER_BEFORE_ddAgreementType*/}

                {/* END_USER_CODE-USER_BEFORE_ddAgreementType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddAgreementType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddAgreementType*/}

                {/* END_USER_CODE-USER_AFTER_ddAgreementType*/}
                {/* START_USER_CODE-USER_BEFORE_ddSeedGeneration*/}

                {/* END_USER_CODE-USER_BEFORE_ddSeedGeneration*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSeedGeneration}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSeedGeneration*/}

                {/* END_USER_CODE-USER_AFTER_ddSeedGeneration*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                <ButtonWidget
                  conf={state.btnVendor}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                {/* START_USER_CODE-USER_BEFORE_ddSeg*/}

                {/* END_USER_CODE-USER_BEFORE_ddSeg*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSeg}
                  screenConf={state}
                ></DropDownWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVndor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_ddSeg*/}

                {/* END_USER_CODE-USER_AFTER_ddSeg*/}
                {/* START_USER_CODE-USER_BEFORE_ddOliec*/}

                {/* END_USER_CODE-USER_BEFORE_ddOliec*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddOliec}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddOliec*/}

                {/* END_USER_CODE-USER_AFTER_ddOliec*/}
                {/* START_USER_CODE-USER_BEFORE_ddEdibleOil*/}

                {/* END_USER_CODE-USER_BEFORE_ddEdibleOil*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddEdibleOil}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddEdibleOil*/}

                {/* END_USER_CODE-USER_AFTER_ddEdibleOil*/}
                {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                <ButtonWidget
                  conf={state.btnSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                {/* END_USER_CODE-USER_AFTER_btnSearch*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSearch*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSearch*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxCreateOpenAgrmnt*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCreateOpenAgrmnt*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget
                conf={state.grpbxCreateOpenAgrmnt}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblCropYear2*/}

                {/* END_USER_CODE-USER_BEFORE_lblCropYear2*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCropYear2}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCropYear2*/}

                {/* END_USER_CODE-USER_AFTER_lblCropYear2*/}
                {/* START_USER_CODE-USER_BEFORE_btnCreateAgreement*/}

                {/* END_USER_CODE-USER_BEFORE_btnCreateAgreement*/}

                <ButtonWidget
                  conf={state.btnCreateAgreement}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCreateAgreement*/}

                {/* END_USER_CODE-USER_AFTER_btnCreateAgreement*/}
                {/* START_USER_CODE-USER_BEFORE_btnOpen*/}

                {/* END_USER_CODE-USER_BEFORE_btnOpen*/}

                <ButtonWidget
                  conf={state.btnOpen}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOpen*/}

                {/* END_USER_CODE-USER_AFTER_btnOpen*/}
                {/* START_USER_CODE-USER_BEFORE_gridAgrmntSrch*/}

                {/* END_USER_CODE-USER_BEFORE_gridAgrmntSrch*/}

                <GridWidget
                  conf={state.gridAgrmntSrch}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridAgrmntSrch}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  setFieldValue={setFieldValue}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridAgrmntSrch*/}

                {/* END_USER_CODE-USER_AFTER_gridAgrmntSrch*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCreateOpenAgrmnt*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCreateOpenAgrmnt*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                <ButtonWidget
                  conf={state.btnExportToExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
                {/* START_USER_CODE-USER_BEFORE_btn1*/}

                {/* END_USER_CODE-USER_BEFORE_btn1*/}

                {/* <ButtonWidget
                  conf={state.btn1}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget> */}
                {/* START_USER_CODE-USER_AFTER_btn1*/}

                {/* END_USER_CODE-USER_AFTER_btn1*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_txtAgreedLbs*/}

                {/* END_USER_CODE-USER_BEFORE_txtAgreedLbs*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAgreedLbs}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAgreedLbs*/}

                {/* END_USER_CODE-USER_AFTER_txtAgreedLbs*/}
                {/* START_USER_CODE-USER_BEFORE_txtAppliedLbs*/}

                {/* END_USER_CODE-USER_BEFORE_txtAppliedLbs*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAppliedLbs}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAppliedLbs*/}

                {/* END_USER_CODE-USER_AFTER_txtAppliedLbs*/}
                {/* START_USER_CODE-USER_BEFORE_txtOpenLbs*/}

                {/* END_USER_CODE-USER_BEFORE_txtOpenLbs*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOpenLbs}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOpenLbs*/}

                {/* END_USER_CODE-USER_AFTER_txtOpenLbs*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_TradeAgreeSearch*/}

              {/* END_USER_CODE-USER_AFTER_TradeAgreeSearch*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_TradeAgreeSearch);


