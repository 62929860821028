/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  getValue,
  setValue,
  enable,
  disable,
  hide,
  show,
  setData,
  getData,
  goTo,
  setFieldValues,
  DateWidget,
  isEnabled
} from "../../shared/WindowImports";

import "./ExtReceiptForfeituresProfile.scss";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SettlementService } from "../../Settlements/Service/SettlementService";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import CommonContext from '../../Store/CommonContext';
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_ExtReceiptForfeituresProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const commonContext = useContext(CommonContext);
  // START_USER_CODE-USER_PROPERTIES
  const useridFromLS = sessionStorage.getItem('userid')
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ExtReceiptForfeituresProfile",
    windowName: "ExtReceiptForfeituresProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.ExtReceiptForfeituresProfile",
    // START_USER_CODE-USER_ExtReceiptForfeituresProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "External Receipt Forfeitures",
      scrCode: "PN1090C",
    },
    // END_USER_CODE-USER_ExtReceiptForfeituresProfile_PROPERTIES
    btnAcctDist: {
      name: "btnAcctDist",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Acct Dist",
      CharWidth: "21",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAcctDist_PROPERTIES

      // END_USER_CODE-USER_btnAcctDist_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnCMAVendor: {
      name: "btnCMAVendor",
      type: "ButtonWidget",
      parent: "grpbxRepayment",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCMAVendor_PROPERTIES

      // END_USER_CODE-USER_btnCMAVendor_PROPERTIES
    },
    btnCopyExternal: {
      name: "btnCopyExternal",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Copy External RCPT",
      CharWidth: "30",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCopyExternal_PROPERTIES

      // END_USER_CODE-USER_btnCopyExternal_PROPERTIES
    },
    btnCreate: {
      name: "btnCreate",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Create",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCreate_PROPERTIES

      // END_USER_CODE-USER_btnCreate_PROPERTIES
    },
    btnDeliveryAgreement: {
      name: "btnDeliveryAgreement",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Delivery Agreement",
      CharWidth: "37",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeliveryAgreement_PROPERTIES

      // END_USER_CODE-USER_btnDeliveryAgreement_PROPERTIES
    },
    btnRelease: {
      name: "btnRelease",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Release",
      CharWidth: "17",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnRelease_PROPERTIES

      // END_USER_CODE-USER_btnRelease_PROPERTIES
    },
    btnRepayVendor: {
      name: "btnRepayVendor",
      type: "ButtonWidget",
      parent: "grpbxRepayment",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnRepayVendor_PROPERTIES

      // END_USER_CODE-USER_btnRepayVendor_PROPERTIES
    },
    btnReversal: {
      name: "btnReversal",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Reversal",
      CharWidth: "19",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnReversal_PROPERTIES

      // END_USER_CODE-USER_btnReversal_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxPayoffDetails",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    chkbox211: {
      name: "chkbox211",
      type: "CheckBoxWidget",
      parent: "grpbxChkboxCntnr",
      Label: "211?",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbox211_PROPERTIES

      // END_USER_CODE-USER_chkbox211_PROPERTIES
    },
    chkboxCCC500: {
      name: "chkboxCCC500",
      type: "CheckBoxWidget",
      parent: "grpbxChkboxCntnr",
      Label: "CCC 500?",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCCC500_PROPERTIES

      // END_USER_CODE-USER_chkboxCCC500_PROPERTIES
    },
    chkboxDeferredRedemption: {
      name: "chkboxDeferredRedemption",
      type: "CheckBoxWidget",
      parent: "grpbxPrchs2",
      Label: "Deferred Redemption?",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxDeferredRedemption_PROPERTIES

      // END_USER_CODE-USER_chkboxDeferredRedemption_PROPERTIES
    },
    ddCMARemit: {
      name: "ddCMARemit",
      type: "DropDownFieldWidget",
      parent: "grpbxRepayment",
      Label: "CMA Remit:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCMARemit_PROPERTIES

      // END_USER_CODE-USER_ddCMARemit_PROPERTIES
    },
    ddCurrentHolder: {
      name: "ddCurrentHolder",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo3",
      Label: "Current Holder:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCurrentHolder_PROPERTIES

      // END_USER_CODE-USER_ddCurrentHolder_PROPERTIES
    },
    ddExtRcptStatus: {
      name: "ddExtRcptStatus",
      type: "DropDownFieldWidget",
      parent: "grpbxReceiptInfo1",
      Label: "External Rcpt Status:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddExtRcptStatus_PROPERTIES

      // END_USER_CODE-USER_ddExtRcptStatus_PROPERTIES
    },
    ddFlavus: {
      name: "ddFlavus",
      type: "DropDownFieldWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Flavus:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddFlavus_PROPERTIES

      // END_USER_CODE-USER_ddFlavus_PROPERTIES
    },
    ddHowStored: {
      name: "ddHowStored",
      type: "DropDownFieldWidget",
      parent: "grpbxReceiptInfo1",
      Label: "How Stored:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddHowStored_PROPERTIES

      // END_USER_CODE-USER_ddHowStored_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Peanut Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddRemitTo: {
      name: "ddRemitTo",
      type: "DropDownFieldWidget",
      parent: "grpbxPayoffDetails",
      Label: "Remit To:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRemitTo_PROPERTIES
      optionMaxLength: 45
      // END_USER_CODE-USER_ddRemitTo_PROPERTIES
    },
    ddRepayRemit: {
      name: "ddRepayRemit",
      type: "DropDownFieldWidget",
      parent: "grpbxRepayment",
      Label: "Repay Remit:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRepayRemit_PROPERTIES

      // END_USER_CODE-USER_ddRepayRemit_PROPERTIES
    },
    ddSeg: {
      name: "ddSeg",
      type: "DropDownFieldWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Seg:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeg_PROPERTIES

      // END_USER_CODE-USER_ddSeg_PROPERTIES
    },
    ddShelled: {
      name: "ddShelled",
      type: "DropDownFieldWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Shelled:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddShelled_PROPERTIES

      // END_USER_CODE-USER_ddShelled_PROPERTIES
    },
    ddStatus: {
      name: "ddStatus",
      type: "DropDownFieldWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Status:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      Height: "1",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddStatus_PROPERTIES

      // END_USER_CODE-USER_ddStatus_PROPERTIES
    },
    ddTradeCategory: {
      name: "ddTradeCategory",
      type: "DropDownFieldWidget",
      parent: "grpbxPayoffDetails",
      Label: "Trade Category:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddTradeCategory_PROPERTIES

      // END_USER_CODE-USER_ddTradeCategory_PROPERTIES
    },
    grpbxChkboxCntnr: {
      name: "grpbxChkboxCntnr",
      type: "GroupBoxWidget",
      parent: "grpbxLoan",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxChkboxCntnr_PROPERTIES

      // END_USER_CODE-USER_grpbxChkboxCntnr_PROPERTIES
    },
    grpbxFactorsForAllPeanuts: {
      name: "grpbxFactorsForAllPeanuts",
      type: "GroupBoxWidget",
      parent: "grpbxExtReceiptForfeitureProfile",
      Height: "",
      Width: "",
      ColsForMobile: "10",
      ColsForTabLandscape: "10",
      HasLabel: false,
      Cols: "10",
      ColsForTabPotrait: "10",
      ColsForLargeDesktop: "10",
      // START_USER_CODE-USER_grpbxFactorsForAllPeanuts_PROPERTIES

      // END_USER_CODE-USER_grpbxFactorsForAllPeanuts_PROPERTIES
    },
    grpbxLoan: {
      name: "grpbxLoan",
      type: "GroupBoxWidget",
      parent: "grpbxRepay",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxLoan_PROPERTIES

      // END_USER_CODE-USER_grpbxLoan_PROPERTIES
    },
    grpbxLoanNum: {
      name: "grpbxLoanNum",
      type: "GroupBoxWidget",
      parent: "grpbxLoan",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxLoanNum_PROPERTIES

      // END_USER_CODE-USER_grpbxLoanNum_PROPERTIES
    },
    grpbxPayoffDetails: {
      name: "grpbxPayoffDetails",
      type: "GroupBoxWidget",
      parent: "grpbxReceiptInfo",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForMobile: "3",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxPayoffDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxPayoffDetails_PROPERTIES
    },
    grpbxPrchs1: {
      name: "grpbxPrchs1",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxPrchs1_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchs1_PROPERTIES
    },
    grpbxPrchs2: {
      name: "grpbxPrchs2",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxPrchs2_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchs2_PROPERTIES
    },
    grpbxPurchase: {
      name: "grpbxPurchase",
      type: "GroupBoxWidget",
      parent: "grpbxStrgPrchs",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxPurchase_PROPERTIES

      // END_USER_CODE-USER_grpbxPurchase_PROPERTIES
    },
    grpbxReceiptInfo: {
      name: "grpbxReceiptInfo",
      type: "GroupBoxWidget",
      parent: "grpbxExtReceiptForfeitureProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxReceiptInfo_PROPERTIES

      // END_USER_CODE-USER_grpbxReceiptInfo_PROPERTIES
    },
    grpbxReceiptInfo1: {
      name: "grpbxReceiptInfo1",
      type: "GroupBoxWidget",
      parent: "grpbxReceiptInfo",
      Height: "",
      Width: "",
      ColsForMobile: "5",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxReceiptInfo1_PROPERTIES

      // END_USER_CODE-USER_grpbxReceiptInfo1_PROPERTIES
    },
    grpbxReceiptInfo2: {
      name: "grpbxReceiptInfo2",
      type: "GroupBoxWidget",
      parent: "grpbxReceiptInfo",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForMobile: "5",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxReceiptInfo2_PROPERTIES

      // END_USER_CODE-USER_grpbxReceiptInfo2_PROPERTIES
    },
    grpbxReceiptInfo3: {
      name: "grpbxReceiptInfo3",
      type: "GroupBoxWidget",
      parent: "grpbxReceiptInfo2",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxReceiptInfo3_PROPERTIES

      // END_USER_CODE-USER_grpbxReceiptInfo3_PROPERTIES
    },
    grpbxRepay: {
      name: "grpbxRepay",
      type: "GroupBoxWidget",
      parent: "grpbxExtReceiptForfeitureProfile",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxRepay_PROPERTIES

      // END_USER_CODE-USER_grpbxRepay_PROPERTIES
    },
    grpbxRepayment: {
      name: "grpbxRepayment",
      type: "GroupBoxWidget",
      parent: "grpbxRepay",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxRepayment_PROPERTIES

      // END_USER_CODE-USER_grpbxRepayment_PROPERTIES
    },
    grpbxStorage: {
      name: "grpbxStorage",
      type: "GroupBoxWidget",
      parent: "grpbxStrgPrchs",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxStorage_PROPERTIES

      // END_USER_CODE-USER_grpbxStorage_PROPERTIES
    },
    grpbxStrgPrchs: {
      name: "grpbxStrgPrchs",
      type: "GroupBoxWidget",
      parent: "grpbxExtReceiptForfeitureProfile",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxStrgPrchs_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgPrchs_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblChangedDate: {
      name: "lblChangedDate",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed Date:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedDate_PROPERTIES

      // END_USER_CODE-USER_lblChangedDate_PROPERTIES
    },
    lblChangedDateValue: {
      name: "lblChangedDateValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedDateValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedDateValue_PROPERTIES
    },
    lblFactorsForAllPeanuts: {
      name: "lblFactorsForAllPeanuts",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Factors For All Peanuts",
      ColSpan: "10",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFactorsForAllPeanuts_PROPERTIES

      // END_USER_CODE-USER_lblFactorsForAllPeanuts_PROPERTIES
    },
    lblLoanNum: {
      name: "lblLoanNum",
      type: "LabelWidget",
      parent: "grpbxLoanNum",
      Label: "Loan Number:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLoanNum_PROPERTIES

      // END_USER_CODE-USER_lblLoanNum_PROPERTIES
    },
    lblPayoffDetails: {
      name: "lblPayoffDetails",
      type: "LabelWidget",
      parent: "grpbxPayoffDetails",
      Label: "Payoff Details",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPayoffDetails_PROPERTIES

      // END_USER_CODE-USER_lblPayoffDetails_PROPERTIES
    },
    lblPct1: {
      name: "lblPct1",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct1_PROPERTIES

      // END_USER_CODE-USER_lblPct1_PROPERTIES
    },
    lblPct10: {
      name: "lblPct10",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct10_PROPERTIES

      // END_USER_CODE-USER_lblPct10_PROPERTIES
    },
    lblPct11: {
      name: "lblPct11",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct11_PROPERTIES

      // END_USER_CODE-USER_lblPct11_PROPERTIES
    },
    lblPct12: {
      name: "lblPct12",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct12_PROPERTIES

      // END_USER_CODE-USER_lblPct12_PROPERTIES
    },
    lblPct13: {
      name: "lblPct13",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct13_PROPERTIES

      // END_USER_CODE-USER_lblPct13_PROPERTIES
    },
    lblPct14: {
      name: "lblPct14",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct14_PROPERTIES

      // END_USER_CODE-USER_lblPct14_PROPERTIES
    },
    lblPct15: {
      name: "lblPct15",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct15_PROPERTIES

      // END_USER_CODE-USER_lblPct15_PROPERTIES
    },
    lblPct16: {
      name: "lblPct16",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct16_PROPERTIES

      // END_USER_CODE-USER_lblPct16_PROPERTIES
    },
    lblPct17: {
      name: "lblPct17",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct17_PROPERTIES

      // END_USER_CODE-USER_lblPct17_PROPERTIES
    },
    lblPct18: {
      name: "lblPct18",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct18_PROPERTIES

      // END_USER_CODE-USER_lblPct18_PROPERTIES
    },
    lblPct19: {
      name: "lblPct19",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct19_PROPERTIES

      // END_USER_CODE-USER_lblPct19_PROPERTIES
    },
    lblPct2: {
      name: "lblPct2",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct2_PROPERTIES

      // END_USER_CODE-USER_lblPct2_PROPERTIES
    },
    lblPct3: {
      name: "lblPct3",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct3_PROPERTIES

      // END_USER_CODE-USER_lblPct3_PROPERTIES
    },
    lblPct4: {
      name: "lblPct4",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct4_PROPERTIES

      // END_USER_CODE-USER_lblPct4_PROPERTIES
    },
    lblPct5: {
      name: "lblPct5",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct5_PROPERTIES

      // END_USER_CODE-USER_lblPct5_PROPERTIES
    },
    lblPct6: {
      name: "lblPct6",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct6_PROPERTIES

      // END_USER_CODE-USER_lblPct6_PROPERTIES
    },
    lblPct7: {
      name: "lblPct7",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct7_PROPERTIES

      // END_USER_CODE-USER_lblPct7_PROPERTIES
    },
    lblPct8: {
      name: "lblPct8",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct8_PROPERTIES

      // END_USER_CODE-USER_lblPct8_PROPERTIES
    },
    lblPct9: {
      name: "lblPct9",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct9_PROPERTIES

      // END_USER_CODE-USER_lblPct9_PROPERTIES
    },
    lblPPB: {
      name: "lblPPB",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "PPB",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPPB_PROPERTIES

      // END_USER_CODE-USER_lblPPB_PROPERTIES
    },
    lblPurchaseInfo: {
      name: "lblPurchaseInfo",
      type: "LabelWidget",
      parent: "grpbxPurchase",
      Label: "Purchase Information",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPurchaseInfo_PROPERTIES

      // END_USER_CODE-USER_lblPurchaseInfo_PROPERTIES
    },
    lblReceiptInfo: {
      name: "lblReceiptInfo",
      type: "LabelWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Receipt Information",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblReceiptInfo_PROPERTIES

      // END_USER_CODE-USER_lblReceiptInfo_PROPERTIES
    },
    lblRepayment: {
      name: "lblRepayment",
      type: "LabelWidget",
      parent: "grpbxRepayment",
      Label: "Repayment",
      ColSpan: "4",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRepayment_PROPERTIES

      // END_USER_CODE-USER_lblRepayment_PROPERTIES
    },
    lblStorage: {
      name: "lblStorage",
      type: "LabelWidget",
      parent: "grpbxStorage",
      Label: "Storage",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblStorage_PROPERTIES

      // END_USER_CODE-USER_lblStorage_PROPERTIES
    },
    radioElectronicPaper: {
      name: "radioElectronicPaper",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxReceiptInfo3",
      Options: "Electronic:1,Paper:2",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioElectronicPaper_PROPERTIES

      // END_USER_CODE-USER_radioElectronicPaper_PROPERTIES
    },
    radioRedemptionMethod: {
      name: "radioRedemptionMethod",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxPrchs1",
      Options: "Loan Payoff:1,Orig. 1007s:2,Receipt Value:3,Shrunk Receipt:4",
      Label: "Redemption Method:",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioRedemptionMethod_PROPERTIES

      // END_USER_CODE-USER_radioRedemptionMethod_PROPERTIES
    },
    txtAmount: {
      name: "txtAmount",
      type: "TextBoxWidget",
      parent: "grpbxPayoffDetails",
      Label: "Amount:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAmount_PROPERTIES

      // END_USER_CODE-USER_txtAmount_PROPERTIES
    },
    txtCalCNetRepayAmt: {
      name: "txtCalCNetRepayAmt",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      Label: "Net Repay Amt:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCalCNetRepayAmt_PROPERTIES

      // END_USER_CODE-USER_txtCalCNetRepayAmt_PROPERTIES
    },
    txtCCCBeginDate: {
      name: "txtCCCBeginDate",
      type: "DateWidget",
      parent: "grpbxStorage",
      Label: "CCC Begin Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    txtCCCCode: {
      name: "txtCCCCode",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "CCC Code:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCCCode_PROPERTIES

      // END_USER_CODE-USER_txtCCCCode_PROPERTIES
    },
    txtCMAVendor: {
      name: "txtCMAVendor",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      ColSpan: "2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCMAVendor_PROPERTIES

      // END_USER_CODE-USER_txtCMAVendor_PROPERTIES
    },
    txtCMAVendorNum: {
      name: "txtCMAVendorNum",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      Label: "CMA Vendor:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCMAVendorNum_PROPERTIES

      // END_USER_CODE-USER_txtCMAVendorNum_PROPERTIES
    },
    txtCnclRMD: {
      name: "txtCnclRMD",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Cncl RMD:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCnclRMD_PROPERTIES

      // END_USER_CODE-USER_txtCnclRMD_PROPERTIES
    },
    txtCommittedTo: {
      name: "txtCommittedTo",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo3",
      Label: "Committed To:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 40 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCommittedTo_PROPERTIES

      // END_USER_CODE-USER_txtCommittedTo_PROPERTIES
    },
    txtCopiedToCropYear: {
      name: "txtCopiedToCropYear",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Copied To Crop Year:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCopiedToCropYear_PROPERTIES

      // END_USER_CODE-USER_txtCopiedToCropYear_PROPERTIES
    },
    txtCrackBroken: {
      name: "txtCrackBroken",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Crack Broken:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCrackBroken_PROPERTIES

      // END_USER_CODE-USER_txtCrackBroken_PROPERTIES
    },
    txtCtrlNum: {
      name: "txtCtrlNum",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Ctrl Num:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCtrlNum_PROPERTIES

      // END_USER_CODE-USER_txtCtrlNum_PROPERTIES
    },
    txtDateCancelled: {
      name: "txtDateCancelled",
      type: "DateWidget",
      parent: "grpbxReceiptInfo3",
      Label: "Date Cancelled:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    txtDeductAmt: {
      name: "txtDeductAmt",
      type: "TextBoxWidget",
      parent: "grpbxPrchs2",
      Label: "Deduct Amt:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDeductAmt_PROPERTIES

      // END_USER_CODE-USER_txtDeductAmt_PROPERTIES
    },
    txtDelvCompletedDt: {
      name: "txtDelvCompletedDt",
      type: "DateWidget",
      parent: "grpbxStorage",
      Enabled: false,
      Label: "Delivery Completed Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    txtDisclrd: {
      name: "txtDisclrd",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Disclrd:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDisclrd_PROPERTIES

      // END_USER_CODE-USER_txtDisclrd_PROPERTIES
    },
    txtDocDelvDt: {
      name: "txtDocDelvDt",
      type: "DateWidget",
      Enabled: false,
      parent: "grpbxStorage",
      Label: "Document Delivery Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    txtELK: {
      name: "txtELK",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "ELK:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtELK_PROPERTIES

      // END_USER_CODE-USER_txtELK_PROPERTIES
    },
    txtEndDate: {
      name: "txtEndDate",
      type: "DateWidget",
      parent: "grpbxStorage",
      Label: "End Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    txtERecptNum: {
      name: "txtERecptNum",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "E-Rcpt Num:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtERecptNum_PROPERTIES

      // END_USER_CODE-USER_txtERecptNum_PROPERTIES
    },
    txtExpDate: {
      name: "txtExpDate",
      type: "TextBoxWidget",
      parent: "grpbxLoan",
      Label: "Exp. Date:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtExpDate_PROPERTIES

      // END_USER_CODE-USER_txtExpDate_PROPERTIES
    },
    txtFancy: {
      name: "txtFancy",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Fancy:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFancy_PROPERTIES

      // END_USER_CODE-USER_txtFancy_PROPERTIES
    },
    txtFarmerStockCost: {
      name: "txtFarmerStockCost",
      type: "TextBoxWidget",
      parent: "grpbxPurchase",
      Label: "Farmer Stock Cost:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarmerStockCost_PROPERTIES

      // END_USER_CODE-USER_txtFarmerStockCost_PROPERTIES
    },
    txtFM: {
      name: "txtFM",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "FM:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFM_PROPERTIES

      // END_USER_CODE-USER_txtFM_PROPERTIES
    },
    txtFreezeDam: {
      name: "txtFreezeDam",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Freeze Dam:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFreezeDam_PROPERTIES

      // END_USER_CODE-USER_txtFreezeDam_PROPERTIES
    },
    txtHulls: {
      name: "txtHulls",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Hulls:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHulls_PROPERTIES

      // END_USER_CODE-USER_txtHulls_PROPERTIES
    },
    txtHullsBright: {
      name: "txtHullsBright",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Hulls Bright:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHullsBright_PROPERTIES

      // END_USER_CODE-USER_txtHullsBright_PROPERTIES
    },
    txtInterestAmt: {
      name: "txtInterestAmt",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      Label: "Interest Amt:",
      ColSpan: "4",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInterestAmt_PROPERTIES

      // END_USER_CODE-USER_txtInterestAmt_PROPERTIES
    },
    txtIssuedDate: {
      name: "txtIssuedDate",
      type: "DateWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Issued Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    txtJumbo: {
      name: "txtJumbo",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Jumbo:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtJumbo_PROPERTIES

      // END_USER_CODE-USER_txtJumbo_PROPERTIES
    },
    txtLastAction: {
      name: "txtLastAction",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo3",
      Label: "Last Action:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLastAction_PROPERTIES

      // END_USER_CODE-USER_txtLastAction_PROPERTIES
    },
    txtLicenseNum: {
      name: "txtLicenseNum",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "License #:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLicenseNum_PROPERTIES

      // END_USER_CODE-USER_txtLicenseNum_PROPERTIES
    },
    txtLnCounty: {
      name: "txtLnCounty",
      type: "TextBoxWidget",
      parent: "grpbxLoanNum",
      Label: "Ln County:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLnCounty_PROPERTIES

      // END_USER_CODE-USER_txtLnCounty_PROPERTIES
    },
    txtLnNumber: {
      name: "txtLnNumber",
      type: "TextBoxWidget",
      parent: "grpbxLoanNum",
      Label: "Ln Number:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 5 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLnNumber_PROPERTIES

      // END_USER_CODE-USER_txtLnNumber_PROPERTIES
    },
    txtLnRpyLockInDt: {
      name: "txtLnRpyLockInDt",
      type: "DateWidget",
      parent: "grpbxLoan",
      Enabled: false,
      Label: "Loan Repay Lock In Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    txtLnState: {
      name: "txtLnState",
      type: "TextBoxWidget",
      parent: "grpbxLoanNum",
      Label: "Ln State:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLnState_PROPERTIES

      // END_USER_CODE-USER_txtLnState_PROPERTIES
    },
    txtLoanAmt: {
      name: "txtLoanAmt",
      type: "TextBoxWidget",
      parent: "grpbxLoan",
      Label: "Loan Amt.:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoanAmt_PROPERTIES

      // END_USER_CODE-USER_txtLoanAmt_PROPERTIES
    },
    txtLoanDt: {
      name: "txtLoanDt",
      type: "DateWidget",
      parent: "grpbxLoan",
      Label: "Loan Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    txtLoanRepayAmt: {
      name: "txtLoanRepayAmt",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      Label: "Loan Repay Amt:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoanRepayAmt_PROPERTIES

      // END_USER_CODE-USER_txtLoanRepayAmt_PROPERTIES
    },
    txtLoanRepayAmtOverride: {
      name: "txtLoanRepayAmtOverride",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      ColSpan: "2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoanRepayAmtOverride_PROPERTIES

      // END_USER_CODE-USER_txtLoanRepayAmtOverride_PROPERTIES
    },
    txtLoanRepayRate: {
      name: "txtLoanRepayRate",
      type: "TextBoxWidget",
      parent: "grpbxPurchase",
      Label: "Loan Repay Rate:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoanRepayRate_PROPERTIES

      // END_USER_CODE-USER_txtLoanRepayRate_PROPERTIES
    },
    txtLSK: {
      name: "txtLSK",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "LSK:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSK_PROPERTIES

      // END_USER_CODE-USER_txtLSK_PROPERTIES
    },
    txtLSKLbs: {
      name: "txtLSKLbs",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo2",
      Label: "LSK Lbs:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKLbs_PROPERTIES

      // END_USER_CODE-USER_txtLSKLbs_PROPERTIES
    },
    txtLSKLbsShrunk: {
      name: "txtLSKLbsShrunk",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKLbsShrunk_PROPERTIES

      // END_USER_CODE-USER_txtLSKLbsShrunk_PROPERTIES
    },
    txtMarketGain1: {
      name: "txtMarketGain1",
      type: "TextBoxWidget",
      parent: "grpbxLoan",
      Label: "Market Gain:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMarketGain1_PROPERTIES

      // END_USER_CODE-USER_txtMarketGain1_PROPERTIES
    },
    txtMarketGain2: {
      name: "txtMarketGain2",
      type: "TextBoxWidget",
      parent: "grpbxLoan",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMarketGain2_PROPERTIES

      // END_USER_CODE-USER_txtMarketGain2_PROPERTIES
    },
    txtMoist: {
      name: "txtMoist",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Moist:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMoist_PROPERTIES

      // END_USER_CODE-USER_txtMoist_PROPERTIES
    },
    txtNetLbs: {
      name: "txtNetLbs",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo2",
      Label: "Net Lbs:",
      ColSpan: "2",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetLbs_PROPERTIES

      // END_USER_CODE-USER_txtNetLbs_PROPERTIES
    },
    txtNetLbsShrunk: {
      name: "txtNetLbsShrunk",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo2",
      HasLabel: false,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetLbsShrunk_PROPERTIES

      // END_USER_CODE-USER_txtNetLbsShrunk_PROPERTIES
    },
    txtNetRepayAmt: {
      name: "txtNetRepayAmt",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      ColSpan: "2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetRepayAmt_PROPERTIES

      // END_USER_CODE-USER_txtNetRepayAmt_PROPERTIES
    },
    txtOptionPayment: {
      name: "txtOptionPayment",
      type: "TextBoxWidget",
      parent: "grpbxPurchase",
      Label: "Option Payment:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOptionPayment_PROPERTIES

      // END_USER_CODE-USER_txtOptionPayment_PROPERTIES
    },
    txtOriginalValue: {
      name: "txtOriginalValue",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Original Value:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOriginalValue_PROPERTIES

      // END_USER_CODE-USER_txtOriginalValue_PROPERTIES
    },
    txtOtherKernels: {
      name: "txtOtherKernels",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Other Kernels:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOtherKernels_PROPERTIES

      // END_USER_CODE-USER_txtOtherKernels_PROPERTIES
    },
    txtOverrideFarm: {
      name: "txtOverrideFarm",
      type: "TextBoxWidget",
      parent: "grpbxPurchase",
      Label: "Override Farm:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOverrideFarm_PROPERTIES

      // END_USER_CODE-USER_txtOverrideFarm_PROPERTIES
    },
    // txtPayoffLocation: {
    //   name: "txtPayoffLocation",
    //   type: "TextBoxWidget",
    //   parent: "grpbxReceiptInfo1",
    //   Label: "Payoff Location:",
    //   ColSpan: "3",
    //   Enabled: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtPayoffLocation_PROPERTIES

    //   // END_USER_CODE-USER_txtPayoffLocation_PROPERTIES
    // },
    ddPayoffLocation: {
      name: "ddPayoffLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Payoff Location:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",
      Height: "1",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddStatus_PROPERTIES

      // END_USER_CODE-USER_ddStatus_PROPERTIES
    },
    btnCreatePayoff: {
      name: "btnCreatePayoff",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Create Pay Off",
      CharWidth: "30",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCreatePayoff_PROPERTIES

      // END_USER_CODE-USER_btnCreatePayoff_PROPERTIES
    },

    btnUpdate: {
      name: "btnUpdate",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Update",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUpdate_PROPERTIES

      // END_USER_CODE-USER_btnUpdate_PROPERTIES
    },
    txtPremiumAmt: {
      name: "txtPremiumAmt",
      type: "TextBoxWidget",
      parent: "grpbxPrchs2",
      Label: "Premium Amt:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPremiumAmt_PROPERTIES

      // END_USER_CODE-USER_txtPremiumAmt_PROPERTIES
    },
    txtProceedsAmt: {
      name: "txtProceedsAmt",
      type: "TextBoxWidget",
      parent: "grpbxPrchs2",
      Label: "Proceeds Amt:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtProceedsAmt_PROPERTIES

      // END_USER_CODE-USER_txtProceedsAmt_PROPERTIES
    },
    txtProducerBeginDate: {
      name: "txtProducerBeginDate",
      type: "DateWidget",
      parent: "grpbxStorage",
      Label: "Producer Begin Date:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    txtRcptNum: {
      name: "txtRcptNum",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Rcpt Num:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRcptNum_PROPERTIES

      // END_USER_CODE-USER_txtRcptNum_PROPERTIES
    },
    txtRepayVendor: {
      name: "txtRepayVendor",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      ColSpan: "2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRepayVendor_PROPERTIES

      // END_USER_CODE-USER_txtRepayVendor_PROPERTIES
    },
    txtRepayVendorNum: {
      name: "txtRepayVendorNum",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      Label: "Repay Vendor:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRepayVendorNum_PROPERTIES

      // END_USER_CODE-USER_txtRepayVendorNum_PROPERTIES
    },
    txtShrinkPct: {
      name: "txtShrinkPct",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Shrink %:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShrinkPct_PROPERTIES

      // END_USER_CODE-USER_txtShrinkPct_PROPERTIES
    },
    txtShrnkPct: {
      name: "txtShrnkPct",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo2",
      Label: "Shrink %",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShrnkPct_PROPERTIES

      // END_USER_CODE-USER_txtShrnkPct_PROPERTIES
    },
    txtShrunkValue: {
      name: "txtShrunkValue",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Shrunk Value:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShrunkValue_PROPERTIES

      // END_USER_CODE-USER_txtShrunkValue_PROPERTIES
    },
    txtSMK: {
      name: "txtSMK",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "SMK:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK_PROPERTIES

      // END_USER_CODE-USER_txtSMK_PROPERTIES
    },
    txtSpotPounds: {
      name: "txtSpotPounds",
      type: "TextBoxWidget",
      parent: "grpbxPurchase",
      Label: "Spot Pounds:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSpotPounds_PROPERTIES

      // END_USER_CODE-USER_txtSpotPounds_PROPERTIES
    },
    txtSpotPrice: {
      name: "txtSpotPrice",
      type: "TextBoxWidget",
      parent: "grpbxPurchase",
      Label: "Spot Price:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSpotPrice_PROPERTIES

      // END_USER_CODE-USER_txtSpotPrice_PROPERTIES
    },
    txtSS: {
      name: "txtSS",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "SS:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSS_PROPERTIES

      // END_USER_CODE-USER_txtSS_PROPERTIES
    },
    txtStorageCharge: {
      name: "txtStorageCharge",
      type: "TextBoxWidget",
      parent: "grpbxPurchase",
      Label: "Storage Charge:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStorageCharge_PROPERTIES

      // END_USER_CODE-USER_txtStorageCharge_PROPERTIES
    },
    txtStrgCreditAmt: {
      name: "txtStrgCreditAmt",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      Label: "Storage Credit Amt:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStrgCreditAmt_PROPERTIES

      // END_USER_CODE-USER_txtStrgCreditAmt_PROPERTIES
    },
    txtStrgCreditAmtOverride: {
      name: "txtStrgCreditAmtOverride",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      ColSpan: "2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStrgCreditAmtOverride_PROPERTIES

      // END_USER_CODE-USER_txtStrgCreditAmtOverride_PROPERTIES
    },
    txtSupportPercent: {
      name: "txtSupportPercent",
      type: "TextBoxWidget",
      parent: "grpbxPurchase",
      Label: "Support Percent:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSupportPercent_PROPERTIES

      // END_USER_CODE-USER_txtSupportPercent_PROPERTIES
    },
    txtTotalDamage: {
      name: "txtTotalDamage",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Total Damage:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalDamage_PROPERTIES

      // END_USER_CODE-USER_txtTotalDamage_PROPERTIES
    },
    txtTotalKernels: {
      name: "txtTotalKernels",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Total Kernels:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalKernels_PROPERTIES

      // END_USER_CODE-USER_txtTotalKernels_PROPERTIES
    },
    txtTotalKernHulls: {
      name: "txtTotalKernHulls",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Total Kern. Hulls:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalKernHulls_PROPERTIES

      // END_USER_CODE-USER_txtTotalKernHulls_PROPERTIES
    },
    txtTotalLbs: {
      name: "txtTotalLbs",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo2",
      Label: "Total Lbs:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 13 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalLbs_PROPERTIES

      // END_USER_CODE-USER_txtTotalLbs_PROPERTIES
    },
    txtTotalLbsShrunk: {
      name: "txtTotalLbsShrunk",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalLbsShrunk_PROPERTIES

      // END_USER_CODE-USER_txtTotalLbsShrunk_PROPERTIES
    },
    txtTotalSMK: {
      name: "txtTotalSMK",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Total SMK:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalSMK_PROPERTIES

      // END_USER_CODE-USER_txtTotalSMK_PROPERTIES
    },
    txtTotalTons: {
      name: "txtTotalTons",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo2",
      Label: "Total Tons:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 13 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalTons_PROPERTIES

      // END_USER_CODE-USER_txtTotalTons_PROPERTIES
    },
    txtTotalTonsShrunk: {
      name: "txtTotalTonsShrunk",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalTonsShrunk_PROPERTIES

      // END_USER_CODE-USER_txtTotalTonsShrunk_PROPERTIES
    },
    txtValueOfSeg: {
      name: "txtValueOfSeg",
      type: "TextBoxWidget",
      parent: "grpbxPurchase",
      Label: "Value of Seg:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValueOfSeg_PROPERTIES

      // END_USER_CODE-USER_txtValueOfSeg_PROPERTIES
    },
    txtValuePerTon: {
      name: "txtValuePerTon",
      type: "TextBoxWidget",
      parent: "grpbxPurchase",
      Label: "Value Per Ton:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValuePerTon_PROPERTIES

      // END_USER_CODE-USER_txtValuePerTon_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Vendor:",
      ColSpan: "5",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtVendorNum: {
      name: "txtVendorNum",
      type: "TextBoxWidget",
      parent: "grpbxPayoffDetails",
      Label: "Vendor:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorNum_PROPERTIES

      // END_USER_CODE-USER_txtVendorNum_PROPERTIES
    },
    txtVICAM: {
      name: "txtVICAM",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "VICAM:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVICAM_PROPERTIES

      // END_USER_CODE-USER_txtVICAM_PROPERTIES
    },
    txtVndrName: {
      name: "txtVndrName",
      type: "TextBoxWidget",
      parent: "grpbxPayoffDetails",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVndrName_PROPERTIES

      // END_USER_CODE-USER_txtVndrName_PROPERTIES
    },
    txtWhseBin: {
      name: "txtWhseBin",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Whse/Bin:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWhseBin_PROPERTIES

      // END_USER_CODE-USER_txtWhseBin_PROPERTIES
    },
    txtWtdAvgStorageDt: {
      name: "txtWtdAvgStorageDt",
      type: "DateWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Weighted Avg. Storage Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    grpbxExtReceiptForfeitureProfile: {
      name: "grpbxExtReceiptForfeitureProfile",
      type: "GroupBoxWidget",
      parent: "ExtReceiptForfeituresProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxExtReceiptForfeitureProfile_PROPERTIES

      // END_USER_CODE-USER_grpbxExtReceiptForfeitureProfile_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "ExtReceiptForfeituresProfile",
      Height: "",
      Width: "",
      ColsForMobile: "7",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
   colorChange()
   setTimeout(() => {
    document.getElementsByClassName('ddExtRcptStatus')[0].firstChild.lastChild.firstChild.firstChild.focus()
   }, 200);
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  useEffect(() => {
    setTabIndex();
  });
  // START_USER_CODE-USER_METHODS

  //global variables
  let flag
  let pData = getData(thisObj, 'ExtReceiptForfeitureProfileData')
  const [loading, setLoading] = useState(false);
  // let mbCopyPermission
  let mbCreate
  let mbManual
const colorChange=()=>{
  if(getData(thisObj,"colorKey")=="Red"){
    document.getElementsByClassName("ddExtRcptStatus")[0].firstChild.firstChild.style.color="Red"
    document.getElementsByClassName("txtCCCCode")[0].firstChild.firstChild.style.color="Red"
    document.getElementsByClassName("ddSeg")[1].firstChild.firstChild.style.color="Red"
    document.getElementsByClassName("ddStatus")[0].firstChild.firstChild.style.color="Red"
    document.getElementsByClassName("ddPeanutType")[2].firstChild.firstChild.style.color="Red"
    document.getElementsByClassName("txtERecptNum")[0].firstChild.firstChild.style.color="Red"
  }
}
  const numberFormat = (value) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value);


  useEffect(() => {
    const EWRdata = getData(thisObj, 'ERFPdata');
    if (EWRdata != null && EWRdata != undefined) {
      releaseAPICall()
    }
  }, [getData(thisObj, 'ERFPdata')]);

  useEffect(() => {
      return () => {
        setData(thisObj,'ExtRctForProClose',true)
        props.thisObj.setGettingData (!props.thisObj.gettingData)
      }
  },[])

  const releaseAPICall = async () => {
    try {
      let data = getData(thisObj, 'ERFPdata')
      let cccCode = getValue(thisObj, 'txtCCCCode')
      let eRctNum = getValue(thisObj, 'txtERecptNum')
      let jss = [{ key: '', description: '' }, { key: 'loaded', description: 'Loaded' }, { key: 'released', description: 'Released' }]
      let obj = {}
      obj.rel_to_holder = data.lblRelToHolder
      obj.rel_to_whse_code = data.lblRelToWhseCode
      obj.rel_to_date_time = data.lblRelToDateTime
      obj.record_status = data.lblRecStatus
      let resp = await WarehouseReceiptService.UpdateExternalReceiptRelease(cccCode, eRctNum, obj)
      if (resp != null && resp != undefined) {
        if (resp.status != 200) {
          showMessage(thisObj, 'Error updating !!!', false)
        } else if (resp.status == 200) {
          setValue(thisObj, 'ddStatus', jss.at(1).key)
          enableControls()
        }
        setData(thisObj, 'ERFPdata', null)
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during releaseAPICall method"
        );
      }
      return false;
    }
  }

  useEffect(() => {
    const vendorDetails = getData(thisObj, 'vendorDetails');
    if (vendorDetails) {
      setValue(thisObj, 'txtVendorNum', vendorDetails?.VendorNumber || '');
      fillRemitListForVendor(vendorDetails?.VendorNumber);
      show(_kaledo.thisObj, "txtVndrName");
      setData(thisObj, 'vendorDetails', null)
    }
  }, [getData(thisObj, 'vendorDetails')]);


  useEffect(() => {
    const newData = getData(thisObj, 'frmExtRctForfCropYearData')
    if (newData) {
      setValue(thisObj, 'txtCopiedToCropYear', newData.new_crop_year)
      enableControls()
      setData(thisObj, 'frmExtRctForfCropYearClose', false)
    }
  }, [getData(thisObj, 'frmExtRctForfCropYearClose')]);

  useEffect(() => {
    formLoad()
  }, []);

  const formLoad = async () => {
    try {
      setLoading(true)
      let pData = getData(thisObj, 'ExtReceiptForfeitureProfileData')
      let resp = await ContractManagementService.RetrieveAccessPermissionDetails('PN1090', '013', 'U')
      if (resp[0].permission == 'Y') {
        setData(thisObj, 'mbCopyPermission', true)
      } else {
        setData(thisObj, 'mbCopyPermission', false)
      }
      flag = true

      disable(thisObj, 'ddPayoffLocation')
      disable(thisObj, 'radioRedemptionMethod')
      disable(thisObj, 'chkboxDeferredRedemption')
      disable(thisObj, 'chkboxCCC500')
      disable(thisObj, 'chkbox211')
      disable(thisObj, 'ddCMARemit')
      disable(thisObj, 'btnCMAVendor')
      disable(thisObj, 'ddRepayRemit')
      disable(thisObj, 'btnRepayVendor')
      hide(thisObj, 'txtRepayVendor')
      hide(thisObj, 'txtCMAVendor')

      if (pData.Create == true) {
        // staticDropDowns()
        let js = [{ key: '', description: '>>>All Location<<<' }]
        thisObj.setState(current => {
          return {
            ...current,
            ddPayoffLocation: {
              ...state["ddPayoffLocation"],
              valueList: js
            }
          }
        })
        setValue(thisObj, 'ddPayoffLocation', js.at(0).key)
        staticDropDowns()

        disable(thisObj, 'ddRemitTo')
        disable(thisObj, 'ddTradeCategory')
        disable(thisObj, 'btnVendor')
        hide(thisObj, "txtVndrName", false);
        formActivate()
      } else if (pData.Create == false) {
        dynamicDropDowns()
        let objVal = {}
        objVal['radioElectronicPaper'] = pData.radioElectronicPaper
        objVal['txtTotalLbs'] = pData.lblTotalPounds  === 0 ? '0' : pData.lblTotalPounds
        objVal['txtRcptNum'] = pData.lblWRNum === 0 ? '0' : pData.lblWRNum
        objVal['txtERecptNum'] = pData.lblERcptNum === 0 ? '0' : pData.lblERcptNum
        objVal['txtCtrlNum'] = pData.lblControlNumber === 0 ? '0' : pData.lblControlNumber
        objVal['txtIssuedDate'] = pData.lblIssueDate
        objVal['txtWtdAvgStorageDt'] = pData.lblStorageDate
        objVal['txtLicenseNum'] = pData.lblLicenseNumber === 0 ? '0' : pData.lblLicenseNumber
        objVal['txtOriginalValue'] = pData.lblOriginalRcptValue === 0 ? '0' : pData.lblOriginalRcptValue
        objVal['txtDateCancelled'] = pData.lblDateCancelled
        objVal['txtCCCCode'] = pData.lblCCCCode === 0 ? '0' : pData.lblCCCCode
        objVal['txtNetLbs'] = pData.lblNetPounds === 0 ? '0' : pData.lblNetPounds
        objVal['txtTotalTons'] = pData.lblTotalTons === 0 ? '0' : pData.lblTotalTons
        objVal['txtLSKLbs'] = pData.lblLSKPounds === 0 ? '0' : pData.lblLSKPounds
        objVal['txtSMK'] = pData.lblSMK === 0 ? '0' : pData.lblSMK
        objVal['txtSS'] = pData.lblSS === 0 ? '0' : pData.lblSS
        objVal['txtTotalSMK'] = pData.lblTotalSMk === 0 ? '0' : pData.lblTotalSMk
        objVal['txtOtherKernels'] = pData.lblOK === 0 ? '0' : pData.lblOK
        objVal['txtTotalDamage'] = pData.lblDAM === 0 ? '0' : pData.lblDAM
        objVal['txtTotalKernels'] = pData.lblTotalKernels === 0 ? '0' : pData.lblTotalKernels
        objVal['txtHulls'] = pData.lblHULLS === 0 ? '0' : pData.lblHULLS
        objVal['txtTotalKernHulls'] = pData.lblTotalKernelsHulls === 0 ? '0' : pData.lblTotalKernelsHulls
        objVal['txtFM'] = pData.lblFM === 0 ? '0' : pData.lblFM
        objVal['txtMoist'] = pData.lblMOIST === 0 ? '0' : pData.lblMOIST
        objVal['txtELK'] = pData.lblELK === 0 ? '0' : pData.lblELK
        objVal['txtCnclRMD'] = pData.lblCNCLRMD === 0 ? '0' : pData.lblCNCLRMD
        objVal['txtFreezeDam'] = pData.lblFreeze === 0 ? '0' : pData.lblFreeze
        objVal['txtDisclrd'] = pData.lblCRBR === 0 ? '0' : pData.lblCRBR
        objVal['lblChangedByValue'] = pData.lblChangedBy
        objVal['lblChangedDateValue'] = pData.lblAddedBy
        objVal['txtFancy'] = pData.lblFancy === 0 ? '0' : pData.lblFancy
        objVal['ddCurrentHolder'] = pData.txtCurrentHolder
        objVal['txtCommittedTo'] = pData.txtCurrentHolderName
        objVal['txtCCCBeginDate'] = pData.txtCCCBeginDate
        objVal['txtEndDate'] = pData.txtEndDate
        objVal['txtLnState'] = pData.txtLnstate === 0 ? '0' : pData.txtLnstate
        objVal['txtLnCounty'] = pData.txtLnCounty === 0 ? '0' : pData.txtLnCounty
        objVal['txtLnNumber'] = pData.txtLnNumber === 0 ? '0' : pData.txtLnNumber
        objVal['txtLoanDt'] = pData.txtLoandate
        objVal['txtCopiedToCropYear'] = pData.lblEWRCropYear
        objVal['txtAmount'] = pData.txtPayoffAmount == '' || pData.txtPayoffAmount == null || pData.txtPayoffAmount == undefined ? '' : numberFormat(pData.txtPayoffAmount)
        await setFieldValues(thisObj, objVal, true)
        if (!['', null, undefined].includes(pData?.txtPayoffVendor)) {
          await fillRemitListForVendor(pData?.txtPayoffVendor)
        } else {
          ontxtVendorNumChange()
        }
        disable(thisObj, 'radioRedemptionMethod')
        disable(thisObj, 'chkboxDeferredRedemption')
        disable(thisObj, 'chkboxCCC500')
        disable(thisObj, 'chkbox211')
        disable(thisObj, 'ddCMARemit')
        disable(thisObj, 'btnCMAVendor')
        disable(thisObj, 'ddRepayRemit')
        disable(thisObj, 'btnRepayVendor')
        bFillBuyPt()
        await FillTradeCategories(pData.FillTradeCategories)
        enableControls()
        await formActivate()
      }

      mbCreate = false
      mbManual = false
      setLoading(false)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
        setLoading(false)
      } else {
        showMessage(
          thisObj,
          "Error occurred during formLoad"
        );
        setLoading(false)
      }
      return false;
    }
  }

  const staticDropDowns = () => {
    try {
      let jss = [{ key: '', description: '' }, { key: 'O', description: 'Open' }, { key: 'C', description: 'Cancelled' }, { key: 'P', description: 'Paper' }, { key: 'V', description: 'Voided' }]
      let jss2 = [{ key: '', description: '' }, { key: 'loaded', description: 'Loaded' }, { key: 'released', description: 'Released' }]
      let jss3 = [{ key: '', description: '' }, { key: 'RUN', description: 'Runner' }, { key: 'SP', description: 'Spanish' }, { key: 'VAL', description: 'Valencia' }, { key: 'VIR', description: 'Virginia' }]
      let jss4 = [{ key: '', description: '' }, { key: '1', description: '1' }, { key: '2', description: '2' }, { key: '3', description: '3' }]
      let jss5 = [{ key: '', description: '' }, { key: 'BULK', description: 'BULK' }, { key: 'BAGS', description: 'BAGS' }]
      let jss6 = [{ key: '', description: '' }, { key: 'Unshelled', description: 'Unshelled' }, { key: 'Shelled', description: 'Shelled' }]
      let jss7 = [{ key: '', description: '' }, { key: 'Yes', description: 'Yes' }, { key: 'No', description: 'No' }]

      thisObj.setState(current => {
        return {
          ...current,
          ddExtRcptStatus: {
            ...state["ddExtRcptStatus"],
            valueList: jss
          }
        }
      })
      setValue(thisObj, 'ddExtRcptStatus', jss.at(0).key);



      thisObj.setState(current => {
        return {
          ...current,
          ddStatus: {
            ...state["ddStatus"],
            valueList: jss2
          }
        }
      })
      setValue(thisObj, 'ddStatus', jss2.at(0).key);

      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutType: {
            ...state["ddPeanutType"],
            valueList: jss3
          }
        }
      })
      setValue(thisObj, 'ddPeanutType', jss3.at(0).key);

      thisObj.setState(current => {
        return {
          ...current,
          ddSeg: {
            ...state["ddSeg"],
            valueList: jss4
          }
        }
      })
      setValue(thisObj, 'ddSeg', jss4.at(0).key);

      thisObj.setState(current => {
        return {
          ...current,
          ddHowStored: {
            ...state["ddHowStored"],
            valueList: jss5
          }
        }
      })
      setValue(thisObj, 'ddHowStored', jss5.at(0).key);

      thisObj.setState(current => {
        return {
          ...current,
          ddShelled: {
            ...state["ddShelled"],
            valueList: jss6
          }
        }
      })
      setValue(thisObj, 'ddShelled', jss6.at(0).key);

      thisObj.setState(current => {
        return {
          ...current,
          ddFlavus: {
            ...state["ddFlavus"],
            valueList: jss7
          }
        }
      })
      setValue(thisObj, 'ddFlavus', jss7.at(0).key);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during staticDropDowns method"
        );
      }
      return false;
    }
  }

  const dynamicDropDowns = () => {
    try {
      let jss = [{ key: '', description: '' }, { key: 'O', description: 'Open' }, { key: 'C', description: 'Cancelled' }, { key: 'P', description: 'Paper' }, { key: 'V', description: 'Voided' }]
      let jss2 = [{ key: '', description: '' }, { key: 'loaded', description: 'Loaded' }, { key: 'released', description: 'Released' }]
      let jss3 = [{ key: '', description: '' }, { key: 'RUN', description: 'Runner' }, { key: 'SP', description: 'Spanish' }, { key: 'VAL', description: 'Valencia' }, { key: 'VIR', description: 'Virginia' }]
      let jss4 = [{ key: '', description: '' }, { key: '1', description: '1' }, { key: '2', description: '2' }, { key: '3', description: '3' }]
      let jss5 = [{ key: '', description: '' }, { key: 'BULK', description: 'BULK' }, { key: 'BAGS', description: 'BAGS' }]
      let jss6 = [{ key: '', description: '' }, { key: 'Unshelled', description: 'Unshelled' }, { key: 'Shelled', description: 'Shelled' }]
      let jss7 = [{ key: '', description: '' }, { key: 'Yes', description: 'Yes' }, { key: 'No', description: 'No' }]

      let js = pData.cmbBuyPtIdValueList
      thisObj.setState(current => {
        return {
          ...current,
          ddPayoffLocation: {
            ...state["ddPayoffLocation"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddPayoffLocation', js.at(0).key)

      thisObj.setState(current => {
        return {
          ...current,
          ddExtRcptStatus: {
            ...state["ddExtRcptStatus"],
            valueList: jss
          }
        }
      })
      setValue(thisObj, 'ddExtRcptStatus', jss.find(ele => ele.key == pData.lblExtrRcptStatus).key)

      thisObj.setState(current => {
        return {
          ...current,
          ddStatus: {
            ...state["ddStatus"],
            valueList: jss2
          }
        }
      })
      setValue(thisObj, 'ddStatus', jss2.find(ele => ele.key == (pData.lblRcptStatus).toLowerCase().trim()).key)

      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutType: {
            ...state["ddPeanutType"],
            valueList: jss3
          }
        }
      })
      setValue(thisObj, 'ddPeanutType', jss3.find(ele => ele.key == pData.lblPeanutType).key);

      thisObj.setState(current => {
        return {
          ...current,
          ddSeg: {
            ...state["ddSeg"],
            valueList: jss4
          }
        }
      })
      setValue(thisObj, 'ddSeg', jss4.find(ele => ele.key == pData.lblSeg).key);

      thisObj.setState(current => {
        return {
          ...current,
          ddHowStored: {
            ...state["ddHowStored"],
            valueList: jss5
          }
        }
      })
      setValue(thisObj, 'ddHowStored', jss5.at(pData.lblHowStored == 0 ? 1 : 2).key);

      thisObj.setState(current => {
        return {
          ...current,
          ddShelled: {
            ...state["ddShelled"],
            valueList: jss6
          }
        }
      })
      setValue(thisObj, 'ddShelled', jss6.at(pData.lblHowStored == 0 ? 1 : 2).key);

      thisObj.setState(current => {
        return {
          ...current,
          ddFlavus: {
            ...state["ddFlavus"],
            valueList: jss7
          }
        }
      })
      setValue(thisObj, 'ddFlavus', jss7.at(pData.lblAFlavus == 0 ? 1 : 2).key);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during dynamicDropDowns method"
        );
      }
      return false;
    }
  }

  const modeSetUp = () => {
    if (pData.Create == true) {
      hide(thisObj, 'btnCopyExternal')
      hide(thisObj, 'btnRelease')
      hide(thisObj, 'btnReversal')
      hide(thisObj, 'btnDeliveryAgreement')
      hide(thisObj, 'btnAcctDist')
      hide(thisObj, 'btnCreatePayoff')
      hide(thisObj, 'btnUpdate')
      show(thisObj, 'btnCreate')

      disable(thisObj, 'ddPayoffLocation')
      disable(thisObj, 'txtVendorNum')
      disable(thisObj, 'ddRemitTo')
      disable(thisObj, 'ddTradeCategory')
      disable(thisObj, 'txtAmount')
      disable(thisObj, 'btnVendor')
    } else {
      show(thisObj, 'btnUpdate')
      hide(thisObj, 'btnCreate')
    }

    if (!(pData.Manual)) {
      disable(thisObj, 'txtRcptNum')
      disable(thisObj, 'txtERecptNum')
      disable(thisObj, 'txtCtrlNum')
      disable(thisObj, 'ddExtRcptStatus')
      disable(thisObj, 'ddStatus')
      disable(thisObj, 'txtIssuedDate')
      disable(thisObj, 'txtWtdAvgStorageDt')
      disable(thisObj, 'txtLicenseNum')
      disable(thisObj, 'txtCCCCode')
      disable(thisObj, 'ddPeanutType')
      disable(thisObj, 'ddSeg')
      disable(thisObj, 'txtOriginalValue')
      disable(thisObj, 'ddHowStored')
      disable(thisObj, 'ddShelled')
      disable(thisObj, 'txtNetLbs')
      disable(thisObj, 'txtTotalLbs')
      disable(thisObj, 'txtTotalTons')
      disable(thisObj, 'txtLSKLbs')
      disable(thisObj, 'ddCurrentHolder')
      disable(thisObj, 'txtCommittedTo')
      disable(thisObj, 'txtDateCancelled')
      disable(thisObj, 'txtVendorNum')
      disable(thisObj, 'btnVendor')
      disable(thisObj, 'ddRemitTo')
      disable(thisObj, 'ddTradeCategory')
      disable(thisObj, 'txtAmount')
      disable(thisObj, 'txtSMK')
      disable(thisObj, 'txtSS')
      disable(thisObj, 'txtTotalSMK')
      disable(thisObj, 'txtOtherKernels')
      disable(thisObj, 'txtTotalDamage')
      disable(thisObj, 'txtTotalKernels')
      disable(thisObj, 'txtTotalKernHulls')
      disable(thisObj, 'txtHulls')
      disable(thisObj, 'txtFM')
      disable(thisObj, 'txtMoist')
      disable(thisObj, 'txtELK')
      disable(thisObj, 'txtFancy')
      disable(thisObj, 'txtDisclrd')
      disable(thisObj, 'txtCnclRMD')
      disable(thisObj, 'txtFreezeDam')
      disable(thisObj, 'ddFlavus')
      disable(thisObj, 'txtEndDate')
      disable(thisObj, 'txtCCCBeginDate')
      disable(thisObj, 'txtLnState')
      disable(thisObj, 'txtLnCounty')
      disable(thisObj, 'txtLnNumber')
      disable(thisObj, 'txtLnRpyLockInDt')
      disable(thisObj, 'txtLoanDt')

      hide(thisObj, 'btnCreate')
      hide(thisObj, 'btnUpdate')
    }
  }


  const bFillBuyPt = async () => {
    try {
      let response = await WarehouseReceiptService.RetrieveExternalReceiptSelectDetails(pData.lblCCCCode, pData.lblERcptNum)
      if (response != null && response != undefined && response.length > 0) {
        for (let i = 0; i < (pData.cmbBuyPtIdValueList).length; i++) {
          if (pData.cmbBuyPtId == response[0].payofF_BUY_PT) {
            setValue(thisObj, 'ddPayoffLocation', (pData.cmbBuyPtIdValueList).find(ele => ele.key == pData.cmbBuyPtId).key)
            return
          }
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillBuyPt method"
        );
      }
      return false;
    }
  }

  const FillTradeCategories = async (SelectedCategory) => {
    try {
      let purchase_sale_indicator
      let tradeAccArr = []
      let jss = []
      // let index
      let data
      let response = await SettlementService.RetrieveTradeCategoryDetails()
      if (response != null && response != undefined && response.length > 0) {
        data = response
        for (let i = 0; i < data.length; i++) {
          purchase_sale_indicator = data[i].purchSaleInd
          if (purchase_sale_indicator == 'P') {
            let obj = { key: data[i].tradeCategory, description: data[i].tradeCategory, index: i, TradeAccount: data[i].tradeAccount }
            jss.push(obj)
            // if (SelectedCategory == obj.tradeCategory) {
            //   index = i
            // }
            tradeAccArr.push(data[i].tradeAccount)
          }
        }
        setData(thisObj, 'tradeAccountArr', tradeAccArr)
        for (let j = 0; j < jss.length; j++) {
          if (jss[j].key == 'Government') {
            setData(thisObj, 'mDefaultTradeCategoryIndex', j)
          }
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddTradeCategory: {
              ...state["ddTradeCategory"],
              valueList: jss
            }
          }
        })
        if (!(['', undefined, null].includes(SelectedCategory))) {
          setValue(thisObj, 'ddTradeCategory', jss.find(ele => ele.key == SelectedCategory).key);
        } else {
          setValue(thisObj, 'ddTradeCategory', jss.at(0).key);
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during FillTradeCategories method"
        );
      }
      return false;
    }
  }

  const enableControls = () => {
    try {
      let selectedCropYear = JSON.parse(sessionStorage.getItem('year'))
      if (getData(thisObj, 'mbCopyPermission') == true) {
        if (['', null, undefined].includes(getValue(_kaledo.thisObj, 'txtCopiedToCropYear'))) {
          if (getValue(_kaledo.thisObj, 'ddStatus') == "released") {
            show(thisObj, 'btnCopyExternal')
          } else {
            hide(thisObj, 'btnCopyExternal')
          }
        } else {
          hide(thisObj, 'btnCopyExternal', false)
          if (JSON.parse(getValue(_kaledo.thisObj, 'txtCopiedToCropYear')) < selectedCropYear) {
            disable(thisObj, 'btnRelease')
            // lblLabels(4) = "EWR Crop Year:".....after fee team help
          } else {
            disable(thisObj, 'btnCreatePayoff')
            disable(thisObj, 'btnDeliveryAgreement')
            disable(thisObj, 'btnReversal')
          }
        }
      } else {
        hide(thisObj, 'btnCopyExternal')
      }
      modeSetUp()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during enableControls method"
        );
      }
      return false;
    }
  }



  const ontxtVendorNumChange = () => {
    try {
      let txtVendorNumber = getValue(_kaledo.thisObj, "txtVendorNum")
      if (txtVendorNumber != undefined && txtVendorNumber != null && txtVendorNumber != '') {
        if (txtVendorNumber.length == 6) {
          fillRemitToList(txtVendorNumber)
          show(_kaledo.thisObj, "txtVndrName");
        }
        else {
          setValue(_kaledo.thisObj, 'txtVndrName', '');
          hide(_kaledo.thisObj, "txtVndrName", false);
          const emptyValueList = [{ key: '', description: '' }];
          _kaledo.thisObj.setState(current => {
            return {
              ...current,
              ddRemitTo: {
                ...state["ddRemitTo"],
                valueList: emptyValueList
              }
            }
          });
          setValue(_kaledo.thisObj, "ddRemitTo", emptyValueList.at(0).key)
        }
      } else {
        setValue(_kaledo.thisObj, 'txtVndrName', '');
        hide(_kaledo.thisObj, "txtVndrName", false);
        const emptyValueList = [{ key: '', description: '' }];
        _kaledo.thisObj.setState(current => {
          return {
            ...current,
            ddRemitTo: {
              ...state["ddRemitTo"],
              valueList: emptyValueList
            }
          }
        });
        setValue(_kaledo.thisObj, "ddRemitTo", emptyValueList.at(0).key)
      }

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during ontxtVendorNumChange click event"
        );
      }
      return false;
    }
  }
  thisObj.ontxtVendorNumChange = ontxtVendorNumChange;

  const onbtnVendorClick = () => {
    try {
      goTo(thisObj, "ContractManagement#VendorLookup#DEFAULT#true#Click");
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnVendorClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnVendorClick = onbtnVendorClick;

  const fillRemitListForVendor = async (vendorTxtValue) => {
    try {
      const remitListLoaded = await fillRemitToList(vendorTxtValue);
      if (!remitListLoaded) {
        const initialVendorTxt = getValue(thisObj, 'txtVendorNum');
        setValue(thisObj, 'txtVendorNum', initialVendorTxt);
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during fillRemitListForVendor method"
        );
      }
      return false;
    }
  };

  const fillRemitToList = async (vendorValue) => {
    try {
      const vendorId = vendorValue
      if (!vendorId) {
        return true;
      }
      const vendorResponse = await ContractManagementService.RetrieveVendorByNumber(vendorId);
      if (!vendorResponse?.length) {
        return false;
      }

      const vendorName = vendorResponse[0]?.name;
      setValue(thisObj, 'txtVndrName', vendorName || '');
      const remitResponse = await ContractManagementService.RetieveRemitDetails(vendorId);

      const remitDetailsList = [];
      remitResponse.forEach(remit => {
        const remitOptionItem = {
          key: remit.remittoid,
          description: ConvertToPascalCase(remit.remittoid) + ' - ' + ConvertToPascalCase(remit.name) + ' - ' + ConvertToPascalCase(remit.city) + ', ' + ConvertToPascalCase(remit.state)
        };
        remitDetailsList.push(remitOptionItem);
      });

      thisObj.setState(current => {
        return {
          ...current,
          ddRemitTo: {
            ...state['ddRemitTo'],
            valueList: remitDetailsList
          }
        }
      });

      if (remitDetailsList.length) {
        setValue(thisObj, 'ddRemitTo', remitDetailsList[0].key);
        setValue(thisObj, 'txtVendorNum', vendorValue);
      }
      return true;
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during fillRemitToList: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  function ConvertToPascalCase(word) {
    return word.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
  }

  const bValid = () => {
    try {
      if (getValue(thisObj, 'ddExtRcptStatus') == '') {
        showMessage(thisObj, 'External receipt status is required.', false)
        if (isEnabled(thisObj, 'ddExtRcptStatus')) {
          document.getElementById('ddExtRcptStatus').focus();
        }
        return false
      }

      if (getValue(thisObj, 'ddStatus') == '') {
        showMessage(thisObj, 'Status is required.', false)
        if (isEnabled(thisObj, 'ddStatus')) {
          document.getElementById('ddStatus').focus();
        }
        return false
      }

      if (['', null, undefined].includes(getValue(thisObj, 'txtERecptNum'))) {
        showMessage(thisObj, 'Receipt number is required.', false)
        if (isEnabled(thisObj, 'txtERecptNum')) {
          document.getElementById('txtERecptNum').focus();
        }
        return false
      }

      if (['', null, undefined].includes(getValue(thisObj, 'txtCCCCode'))) {
        showMessage(thisObj, 'CCC code is required.', false)
        if (isEnabled(thisObj, 'txtCCCCode')) {
          document.getElementById('txtCCCCode').focus();
        }
        return false
      }

      if (getValue(thisObj, 'ddPeanutType') == '') {
        showMessage(thisObj, 'Peanut Type is required.', false)
        if (isEnabled(thisObj, 'ddPeanutType')) {
          document.getElementById('ddPeanutType').focus();
        }
        return false
      }

      if (getValue(thisObj, 'ddSeg') == '') {
        showMessage(thisObj, 'Seg is required.', false)
        if (isEnabled(thisObj, 'ddSeg')) {
          document.getElementById('ddSeg').focus();
        }
        return false
      }

      if (!(['', null, undefined].includes(getValue(thisObj, 'txtIssuedDate')))) {
        if (!(new Date(getValue(thisObj, 'txtIssuedDate')) instanceof Date)) {
          showMessage(thisObj, 'Issue Date is invalid', false)
          if (isEnabled(thisObj, 'txtIssuedDate')) {
            document.getElementById('txtIssuedDate').focus();
          }
          return false
        }
      }
      if (!(['', null, undefined].includes(getValue(thisObj, 'txtWtdAvgStorageDt')))) {
        if (!(new Date(getValue(thisObj, 'txtWtdAvgStorageDt')) instanceof Date)) {
          showMessage(thisObj, 'Storage Date is invalid', false)
          if (isEnabled(thisObj, 'txtWtdAvgStorageDt')) {
            document.getElementById('txtWtdAvgStorageDt').focus();
          }
          return false
        }
      }
      if (!(['', null, undefined].includes(getValue(thisObj, 'txtDateCancelled')))) {
        if (!(new Date(getValue(thisObj, 'txtDateCancelled')) instanceof Date)) {
          showMessage(thisObj, 'Date Cancelled is invalid', false)
          if (isEnabled(thisObj, 'txtDateCancelled')) {
            document.getElementById('txtDateCancelled').focus();
          }
          return false
        }
      }
      if (!(['', null, undefined].includes(getValue(thisObj, 'txtCCCBeginDate')))) {
        if (!(new Date(getValue(thisObj, 'txtCCCBeginDate')) instanceof Date)) {
          showMessage(thisObj, 'CCC Begin Date is invalid', false)
          if (isEnabled(thisObj, 'txtCCCBeginDate')) {
            document.getElementById('txtCCCBeginDate').focus();
          }
          return false
        }
      }
      if (!(['', null, undefined].includes(getValue(thisObj, 'txtEndDate')))) {
        if (!(new Date(getValue(thisObj, 'txtEndDate')) instanceof Date)) {
          showMessage(thisObj, 'End Date is invalid', false)
          if (isEnabled(thisObj, 'txtEndDate')) {
            document.getElementById('txtEndDate').focus();
          }
          return false
        }
      }
      if (!(['', null, undefined].includes(getValue(thisObj, 'txtLoanDt')))) {
        if (!(new Date(getValue(thisObj, 'txtLoanDt')) instanceof Date)) {
          showMessage(thisObj, 'Loan Date is invalid', false)
          if (isEnabled(thisObj, 'txtLoanDt')) {
            document.getElementById('txtLoanDt').focus();
          }
          return false
        }
      }

      let state = getValue(thisObj, 'txtLnState')
      let county = getValue(thisObj, 'txtLnCounty')
      let Num = getValue(thisObj, 'txtLnNumber')
      if (!(['', null, undefined].includes(state)) || !(['', null, undefined].includes(county)) || !(['', null, undefined].includes(Num))) {
        if ((getValue(thisObj, 'txtLnState')).toString().length != 2) {
          showMessage(thisObj, 'The Length of the Loan State field has to be 2 digits.', false)
          if (isEnabled(thisObj, 'txtLnState')) {
            document.getElementById('txtLnState').focus();
          }
          return false
        }
        if ((getValue(thisObj, 'txtLnCounty')).toString().length != 3) {
          showMessage(thisObj, 'The Length of the Loan County field has to be 2 digits.', false)
          if (isEnabled(thisObj, 'txtLnCounty')) {
            document.getElementById('txtLnCounty').focus();
          }
          return false
        }
        if ((getValue(thisObj, 'txtLnNumber')).toString().length != 5) {
          showMessage(thisObj, 'The Length of the Loan Number field has to be 5 digits.', false)
          if (isEnabled(thisObj, 'txtLnNumber')) {
            document.getElementById('txtLnNumber').focus();
          }
          return false
        }
      }

      return true
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bValid method"
        );
      }
      return false;
    }
  }

  const formActivate = async () => {
    try {
      if (flag == true) {
        if (['', undefined, null].includes(getValue(_kaledo.thisObj, 'txtVendorNum')) || ['', undefined, null].includes(getValue(_kaledo.thisObj, 'ddRemitTo')) || ['', undefined, null].includes(getValue(_kaledo.thisObj, 'txtAmount'))) {
          show(thisObj, 'btnCreatePayoff')
          hide(thisObj, 'btnReversal')
          enable(thisObj, 'ddPayoffLocation')
          enable(thisObj, 'txtVendorNum')
          enable(thisObj, 'ddRemitTo')
          enable(thisObj, 'ddTradeCategory')
          enable(thisObj, 'txtAmount')
          enable(thisObj, 'btnVendor')
        } else {
          show(thisObj, 'btnReversal'),
            hide(thisObj, 'btnCreatePayoff')
          disable(thisObj, 'ddPayoffLocation')
          disable(thisObj, 'txtVendorNum')
          disable(thisObj, 'ddRemitTo')
          disable(thisObj, 'ddTradeCategory')
          disable(thisObj, 'txtAmount')
          disable(thisObj, 'btnVendor')
        }
      }

      if ((_kaledo.thisObj.state['ddTradeCategory'].valueList)[0].key != undefined && (_kaledo.thisObj.state['ddTradeCategory'].valueList[0]).key != '') {
        let arr = _kaledo.thisObj.state['ddTradeCategory'].valueList
        let i = getData(thisObj, 'mDefaultTradeCategoryIndex')
        setValue(thisObj, 'ddTradeCategory', arr.at(i).key)
      }
      flag = false

      modeSetUp()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during formActivate method"
        );
      }
      return false;
    }
  }

  const SaveExternalReceipt = async (sAction) => {
    try {
      let rcptNum = getValue(thisObj, 'txtERecptNum') == undefined || getValue(thisObj, 'txtERecptNum') == '' ? null : getValue(thisObj, 'txtERecptNum')
      let isDate = getValue(thisObj, 'txtIssuedDate')
      let cclDate = getValue(thisObj, 'txtDateCancelled')
      let strgDate = getValue(thisObj, 'txtWtdAvgStorageDt')
      let lanDate = getValue(thisObj, 'txtLoanDt')
      let Cdate = getValue(thisObj, 'txtCCCBeginDate')
      let eDate = getValue(thisObj, 'txtEndDate')
      let issueDate = isDate == '' || isDate == undefined ? null : isDate
      if (issueDate != null && typeof isDate == 'string') {
        issueDate = new Date(isDate).toISOString()
      } else if (issueDate != null && typeof isDate == 'object') {
        issueDate = isDate.toISOString()
      }

      let cancelDate = cclDate == '' || cclDate == undefined ? null : cclDate
      if (cancelDate != null && typeof cclDate == 'string') {
        cancelDate = new Date(cclDate).toISOString()
      } else if (cancelDate != null && typeof cclDate == 'object') {
        cancelDate = cclDate.toISOString()
      }
      let loanDate = lanDate == '' || lanDate == undefined ? null : lanDate
      if (loanDate != null && typeof lanDate == 'string') {
        loanDate = new Date(lanDate).toISOString()
      } else if (loanDate != null && typeof lanDate == 'object') {
        loanDate = lanDate.toISOString()
      }
      let CCCdate = Cdate == '' || Cdate == undefined ? null : Cdate
      if (CCCdate != null && typeof Cdate == 'string') {
        CCCdate = new Date(Cdate).toISOString()
      } else if (CCCdate != null && typeof Cdate == 'object') {
        CCCdate = Cdate.toISOString()
      }
      let endDate = eDate == '' || eDate == undefined ? null : eDate
      if (endDate != null && typeof eDate == 'string') {
        endDate = new Date(eDate).toISOString()
      } else if (endDate != null && typeof eDate == 'object') {
        endDate = eDate.toISOString()
      }
      let storagedate = strgDate == '' || strgDate == undefined ? null : strgDate
      if (storagedate != null && typeof strgDate == 'string') {
        storagedate = new Date(strgDate).toISOString()
      } else if (storagedate != null && typeof strgDate == 'object') {
        storagedate = strgDate.toISOString()
      }

      let dataObj = {}
      dataObj.maintenance = sAction
      dataObj.ccc_whse_code = getValue(thisObj, 'txtCCCCode') == undefined ? '' : getValue(thisObj, 'txtCCCCode')
      dataObj.record_status = (getValue(thisObj, 'ddStatus')).toUpperCase()
      dataObj.whse_license_num = getValue(thisObj, 'txtLicenseNum') == undefined ? '' : getValue(thisObj, 'txtLicenseNum')
      dataObj.paper_rcpt_num = getValue(thisObj, 'txtRcptNum') == undefined ? '' : getValue(thisObj, 'txtRcptNum')
      dataObj.net_lbs = getValue(thisObj, 'txtNetLbs') == undefined || getValue(thisObj, 'txtNetLbs') == null ? '' : getValue(thisObj, 'txtNetLbs')
      dataObj.lsk_lbs = getValue(thisObj, 'txtLSKLbs') == undefined || getValue(thisObj, 'txtLSKLbs') == null ? '' : getValue(thisObj, 'txtLSKLbs')
      dataObj.total_lbs = getValue(thisObj, 'txtTotalLbs') == undefined || getValue(thisObj, 'txtTotalLbs') == null ? '' : getValue(thisObj, 'txtTotalLbs')
      dataObj.total_tons = getValue(thisObj, 'txtTotalTons') == undefined || getValue(thisObj, 'txtTotalTons') == null ? '' : getValue(thisObj, 'txtTotalTons')
      dataObj.storage_date = storagedate
      dataObj.storage_container = getValue(thisObj, 'ddHowStored') == 'BULK' ? 'K' : 'G'
      dataObj.storage_status = getValue(thisObj, 'ddShelled') == 'Unshelled' ? 'U' : 'S'
      dataObj.peanut_type_id = getValue(thisObj, 'ddPeanutType')
      dataObj.seg_type = getValue(thisObj, 'ddSeg')
      dataObj.moist_pct = getValue(thisObj, 'txtMoist') == undefined || getValue(thisObj, 'txtMoist') == null ? '' : getValue(thisObj, 'txtMoist')
      dataObj.fm_pct = getValue(thisObj, 'txtFM') == undefined || getValue(thisObj, 'txtFM') == null ? '' : getValue(thisObj, 'txtFM')
      dataObj.elk_pct = getValue(thisObj, 'txtELK') == undefined || getValue(thisObj, 'txtELK') == null ? '' : getValue(thisObj, 'txtELK')
      dataObj.fan_pct = getValue(thisObj, 'txtFancy') == undefined || getValue(thisObj, 'txtFancy') == null ? '' : getValue(thisObj, 'txtFancy')
      dataObj.cr_br_pct = getValue(thisObj, 'txtDisclrd') == undefined || getValue(thisObj, 'txtDisclrd') == null ? '' : getValue(thisObj, 'txtDisclrd')
      dataObj.smk_rs_pct = getValue(thisObj, 'txtSMK') == undefined || getValue(thisObj, 'txtSMK') == null ? '' : getValue(thisObj, 'txtSMK')
      dataObj.ss_pct = getValue(thisObj, 'txtSS') == undefined || getValue(thisObj, 'txtSS') == null ? '' : getValue(thisObj, 'txtSS')
      dataObj.tot_smk_pct = getValue(thisObj, 'txtTotalSMK') == undefined || getValue(thisObj, 'txtTotalSMK') == null ? '' : getValue(thisObj, 'txtTotalSMK')
      dataObj.ok_pct = getValue(thisObj, 'txtOtherKernels') == undefined || getValue(thisObj, 'txtOtherKernels') == null ? '' : getValue(thisObj, 'txtOtherKernels')
      dataObj.tot_dam_pct = getValue(thisObj, 'txtTotalDamage') == undefined || getValue(thisObj, 'txtTotalDamage') == null ? '' : getValue(thisObj, 'txtTotalDamage')
      dataObj.tot_kern_pct = getValue(thisObj, 'txtTotalKernels') == undefined || getValue(thisObj, 'txtTotalKernels') == null ? '' : getValue(thisObj, 'txtTotalKernels')
      dataObj.hul_pct = getValue(thisObj, 'txtHulls') == undefined || getValue(thisObj, 'txtHulls') == null ? '' : getValue(thisObj, 'txtHulls')
      dataObj.flavus_ind = getValue(thisObj, 'ddFlavus')
      dataObj.hul_kern_pct = getValue(thisObj, 'txtTotalKernHulls') == undefined || getValue(thisObj, 'txtTotalKernHulls') == null ? '' : getValue(thisObj, 'txtTotalKernHulls')
      dataObj.frez_dam_pct = getValue(thisObj, 'txtFreezeDam') == undefined || getValue(thisObj, 'txtFreezeDam') == null ? '' : getValue(thisObj, 'txtFreezeDam')
      dataObj.cncl_rmd_pct = getValue(thisObj, 'txtCnclRMD') == undefined || getValue(thisObj, 'txtCnclRMD') == null ? '' : getValue(thisObj, 'txtCnclRMD')
      dataObj.paper_cntl_num = getValue(thisObj, 'txtCtrlNum') == undefined ? '' : getValue(thisObj, 'txtCtrlNum')
      dataObj.receipt_value = getValue(thisObj, 'txtOriginalValue') == undefined || getValue(thisObj, 'txtOriginalValue') == null ? '' : getValue(thisObj, 'txtOriginalValue')
      dataObj.loan_number = `${getValue(thisObj, 'txtLnState') == undefined ? '' : getValue(thisObj, 'txtLnState')}${getValue(thisObj, 'txtLnCounty') == undefined ? '' : getValue(thisObj, 'txtLnCounty')}${getValue(thisObj, 'txtLnNumber') == undefined ? '' : getValue(thisObj, 'txtLnNumber')}`
      dataObj.cur_holder = getValue(thisObj, 'ddCurrentHolder') == undefined ? '' : getValue(thisObj, 'ddCurrentHolder')
      dataObj.ewr_rcpt_status = getValue(thisObj, 'ddExtRcptStatus')
      dataObj.electronic_paper_ind = getValue(thisObj, 'radioElectronicPaper') == 0 ? 'E' : ''
      dataObj.issued_date_time = issueDate
      dataObj.cancel_date_time = cancelDate
      dataObj.curr_holder_name = getValue(thisObj, 'txtCommittedTo') == undefined ? '' : getValue(thisObj, 'txtCommittedTo')
      dataObj.loan_disbursement_date = loanDate
      dataObj.loan_application_date = CCCdate
      dataObj.loan_repayment_date = endDate
      dataObj.chg_user = sessionStorage.getItem('userid');
      dataObj.chg_date_time = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString()
      dataObj.manual_ind = 'Y'
      dataObj.manual_add_user = sessionStorage.getItem('userid');
      dataObj.manual_add_date_time = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString()

      let response = await WarehouseReceiptService.UpdateExternalReceipt(rcptNum, dataObj)
      if (response != null && response != undefined) {
        if (response.status != 200) {
          if (response.status == 500 && response.message == 'Error occurred during operation. Status Code : 500 ; Error Meesage : External receipt already exists.') {
            showMessage(thisObj, (response.message).slice(69), false)
            return false
          }
          if (response.status == 500 && response.message == "Error occurred during operation. Status Code : 500 ; Error Meesage : Cannot find external receipt.") {
            showMessage(thisObj, (response.message).slice(69), false)
            return false
          }
        } else if (response.status == 200) {
          showMessage(thisObj, "External receipt has been saved", true)
          return true
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during SaveExternalReceipt method"
        );
      }
      return false;
    }
  }


  const onbtnCloseClick = () => {
    try {
      setData(thisObj, 'ExtRctForProClose', true)
      document.getElementById("WarehouseReceipts_ExtReceiptForfeituresProfilePopUp").childNodes[0].click();
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnCloseClick event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnCloseClick = onbtnCloseClick;

  const onbtnDeliveryAgreementClick = () => {
    try {
      let dataObj = {}
      dataObj.lblERcptNum = getValue(thisObj, 'txtERecptNum') 
      dataObj.lblPeanutType = getValue(thisObj, 'ddPeanutType')
      dataObj.cmbBuyPtId = getValue(thisObj, 'ddPayoffLocation') 
      dataObj.lblSeg = getValue(thisObj, 'ddSeg') 
      setData(thisObj, 'DelvInAvailAgreementData', dataObj)
      goTo(thisObj, "WarehouseReceipts#DelvInAvailAgreement#DEFAULT#true#Click");
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnDeliveryAgreementClick event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnDeliveryAgreementClick = onbtnDeliveryAgreementClick;

  const onbtnAcctDistClick = () => {
    try {
      setData(thisObj, 'type', 'EXTWHSRECEIPT')
      commonContext.setWR_BuyingPointId(getValue(thisObj, 'ddPayoffLocation') )
      commonContext.setWR_WRNumber(getValue(thisObj, 'txtERecptNum'));
      goTo(thisObj, "ContractManagement#AccountDistributionActivity#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnAcctDistClick event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnAcctDistClick = onbtnAcctDistClick;

  const onbtnCopyExternalClick = () => {
    try {
      if (getData(thisObj, 'mbCopyPermission') == true) {
        let obj = {}
        obj.copyPermission = getData(thisObj, 'mbCopyPermission'),
          obj.lblCCCCode = getValue(thisObj, 'txtCCCCode'),
          obj.lblERcptNum = getValue(thisObj, 'txtERecptNum')
        setData(thisObj, 'ExtRctForfCropYearData', obj)
        goTo(thisObj, "WarehouseReceipts#ExtReceiptForfeituresCropYear#DEFAULT#true#Click");
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnCloseClick event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnCopyExternalClick = onbtnCopyExternalClick;

  const onbtnCreateClick = async () => {
    try {
      if (!bValid()) {
        return
      }

      if (await SaveExternalReceipt('C')) {
        mbCreate = false
        flag = true
        setData(thisObj, 'ExtRctForProClose', true)
        formActivate()
        enableControls()
        document.getElementById("WarehouseReceipts_ExtReceiptForfeituresProfilePopUp").childNodes[0].click();
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnCreateClick event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnCreateClick = onbtnCreateClick;

  const onbtnUpdateClick = async () => {
    try {
      if (!bValid()) {
        return
      }

      if (await SaveExternalReceipt('U')) {
        flag = true
        setData(thisObj, 'ExtRctForProClose', true)
        formActivate()
        enableControls()
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnUpdateClick event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnUpdateClick = onbtnUpdateClick;

  const onbtnCreatePayoffClick = async () => {
    try {
      setLoading(true)

      if (['', null, undefined].includes(getValue(thisObj, 'txtVendorNum')) ||
        ['', null, undefined].includes(getValue(thisObj, 'txtAmount')) ||
        ['', null, undefined].includes(getValue(thisObj, 'ddRemitTo'))) {
        showMessage(thisObj, 'Please Fill all Payoff fields!!!', false)
        if (isEnabled(thisObj, 'txtVendorNum')) {
          document.getElementById('txtVendorNum').focus();
        }
        setLoading(false)
        return
      }

      if (getValue(thisObj, 'ddPayoffLocation') == '') {
        showMessage(thisObj, 'Please select Payoff Buying Point!!!', false)
        if (isEnabled(thisObj, 'ddPayoffLocation')) {
          document.getElementById('ddPayoffLocation').focus();
        }
        setLoading(false)
        return
      }

      let amt = getValue(thisObj, 'txtAmount') 
      if (isNaN(amt.includes('$') ? parseFloat((amt.slice(1)).replace(/,/g, '')) : parseFloat(amt.replace(/,/g, '')))) {
        showMessage(thisObj, 'Invalid Amount. Only valid amount allowed !!!', false)
        if (isEnabled(thisObj, 'txtAmount')) {
          document.getElementById('txtAmount').focus();
        }
        setLoading(false)
        return
      }

      let tradeAccArr = getData(thisObj, 'tradeAccountArr')
      let tradeCatList = thisObj.state['ddTradeCategory'].valueList
      let tradeAccount = tradeCatList?.find(ele => ele.key == getValue(thisObj, 'ddTradeCategory'))?.TradeAccount

      let obj = {}
      obj.action = 'create'
      obj.payoff_amount = Number(amt.includes('$') ? parseFloat((amt.slice(1)).replace(/,/g, '')) :parseFloat(amt.replace(/,/g, ''))) 
      obj.payoff_vendor = getValue(thisObj, 'txtVendorNum')
      obj.payoff_remit = getValue(thisObj, 'ddRemitTo')
      obj.trade_category = getValue(thisObj, 'ddTradeCategory')
      obj.trade_account = tradeAccount == null || tradeAccount == undefined ? '' : tradeAccount
      obj.buy_pt_id = getValue(thisObj, 'ddPayoffLocation')
      obj.user_id = useridFromLS

      let cccCode = getValue(thisObj, 'txtCCCCode')
      let rcptNum = getValue(thisObj, 'txtERecptNum')

      let response = await WarehouseReceiptService.UpdateExternalReceiptPayOff(rcptNum, cccCode, obj)
      if (response.status != 200) {
        if (response.message != '' && response.message != null && response.message != undefined) {
          showMessage(thisObj, `${response.message}`, false)
        }
        showMessage(thisObj, 'Error !!!', false)
      }

      show(thisObj, 'btnReversal')
      hide(thisObj, 'btnCreatePayoff')
      disable(thisObj, 'ddPayoffLocation')
      disable(thisObj, 'txtVendorNum')
      disable(thisObj, 'ddRemitTo')
      disable(thisObj, 'txtAmount')
      disable(thisObj, 'ddTradeCategory')

      setLoading(false)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
        setLoading(false)
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnCreatePayoffClick event"
        );
        setLoading(false)
      }
      return false;
    }
  }
  thisObj.onbtnCreatePayoffClick = onbtnCreatePayoffClick;

  const onbtnReversalClick = async () => {
    try {
      setLoading(true)
      if (confirm("The payment will be reversed. Are you sure?")) {
        hide(thisObj, 'btnReversal')
        show(thisObj, 'btnCreatePayoff')
        enable(thisObj, 'ddPayoffLocation')
        enable(thisObj, 'txtVendorNum')
        enable(thisObj, 'ddRemitTo')
        enable(thisObj, 'txtAmount')
        enable(thisObj, 'ddTradeCategory')
        enable(thisObj, 'btnVendor')

        let amt = getValue(thisObj, 'txtAmount') 

        let obj = {}
        obj.action = 'reverse'
        obj.payoff_amount = Number(amt.includes('$') ? parseFloat((amt.slice(1)).replace(/,/g, '')) :parseFloat(amt.replace(/,/g, ''))) 
        obj.payoff_vendor = getValue(thisObj, 'txtVendorNum')
        obj.payoff_remit = getValue(thisObj, 'ddRemitTo')
        obj.trade_category = ''
        obj.trade_account = ''
        obj.buy_pt_id = getValue(thisObj, 'ddPayoffLocation')
        obj.user_id = useridFromLS

        let cccCode = getValue(thisObj, 'txtCCCCode')
        let rcptNum = getValue(thisObj, 'txtERecptNum')

        let response = await WarehouseReceiptService.UpdateExternalReceiptPayOff(rcptNum, cccCode, obj)
        if (response.status != 200) {
          if (response.message != '' && response.message != null && response.message != undefined) {
            showMessage(thisObj, `${response.message}`, false)
          }
          showMessage(thisObj, 'Error !!!')
        }

        setValue(thisObj, 'txtAmount', '')
        setValue(thisObj, 'txtVendorNum', '')
        ontxtVendorNumChange()
        setValue(thisObj, 'ddPayoffLocation', (pData.cmbBuyPtIdValueList).at(0).key)
        flag = true
        await formActivate()
        enable(thisObj, 'ddPayoffLocation')
        enable(thisObj, 'txtVendorNum')
        enable(thisObj, 'ddRemitTo')
        enable(thisObj, 'txtAmount')
        enable(thisObj, 'ddTradeCategory')
        enable(thisObj, 'btnVendor')
        setLoading(false)
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
        setLoading(false)
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnReversalClick event"
        );
        setLoading(false)
      }
      return false;
    }
  }
  thisObj.onbtnReversalClick = onbtnReversalClick;

  const onbtnReleaseClick = async () => {
    try {
      let dataObj = {
        lblFrmName: "EXTRCPTFORFPROFILE",
        txtReceiptNumber: (getValue(thisObj, 'txtERecptNum')).slice(1, 6)
      }
      setData(thisObj, 'EWRSendUpdateData', dataObj)
      goTo(thisObj, "WarehouseReceipts#EWRSendUpdate#DEFAULT#true#Click");
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnReleaseClick event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnReleaseClick = onbtnReleaseClick;

  const validateDigitsDecimal = (val) => {
    var res = ''
    for (var i = 0; i < val.length; i++) {
      if ((val[i].charCodeAt(0) >= 48 && val[i].charCodeAt(0) <= 57) || val[i].charCodeAt(0) == 46) {
        res += val[i]
      }
    }
    return res;
  }

  const validateDigitsDecimal2 = (val) => {
    var res = ''
    for (var i = 0; i < val.length; i++) {
      if ((val[i].charCodeAt(0) >= 48 && val[i].charCodeAt(0) <= 57) || val[i].charCodeAt(0) == 46 || val[i].charCodeAt(0) == 45) {
        res += val[i]
      }
    }
    return res;
  }

  const validateDigits = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) {
        res += data[i]
      }
    }
    return res;
  }


  const ontxtCnclRMDChange = (e) => {
    setValue(thisObj, 'txtCnclRMD', validateDigitsDecimal(e.target.value))
  }
  thisObj.ontxtCnclRMDChange = ontxtCnclRMDChange;

  const ontxtCnclRMDBlur = () => {
    if ([null, undefined, ''].includes(getValue(thisObj, 'txtCnclRMD'))) {
      return
    }

    if (Number(getValue(thisObj, 'txtCnclRMD')) > 999.99) {
      showMessage(thisObj, 'Cncl RMD must be of format ###.##', false)
      if (isEnabled(thisObj, 'txtCnclRMD')) {
        document.getElementById('txtCnclRMD').focus();
      }
    }
  }
  thisObj.ontxtCnclRMDBlur = ontxtCnclRMDBlur;

  const ontxtDisclrdChange = (e) => {
    setValue(thisObj, 'txtDisclrd', validateDigitsDecimal(e.target.value))
  }
  thisObj.ontxtDisclrdChange = ontxtDisclrdChange;

  const ontxtDisclrdBlur = () => {
    if ([null, undefined, ''].includes(getValue(thisObj, 'txtDisclrd'))) {
      return
    }

    if (Number(getValue(thisObj, 'txtDisclrd')) > 999.99) {
      showMessage(thisObj, 'Discolored must be of format ###.##', false)
      if (isEnabled(thisObj, 'txtDisclrd')) {
        document.getElementById('txtDisclrd').focus();
      }
    }
  }
  thisObj.ontxtDisclrdBlur = ontxtDisclrdBlur;

  const ontxtTotalDamageChange = (e) => {
    setValue(thisObj, 'txtTotalDamage', validateDigitsDecimal(e.target.value))
  }
  thisObj.ontxtTotalDamageChange = ontxtTotalDamageChange;

  const ontxtTotalDamageBlur = () => {
    if ([null, undefined, ''].includes(getValue(thisObj, 'txtTotalDamage'))) {
      return
    }

    if (Number(getValue(thisObj, 'txtTotalDamage')) > 999.99) {
      showMessage(thisObj, 'Total Damage must be of format ###.##', false)
      if (isEnabled(thisObj, 'txtTotalDamage')) {
        document.getElementById('txtTotalDamage').focus();
      }
    }
  }
  thisObj.ontxtTotalDamageBlur = ontxtTotalDamageBlur;

  const ontxtELKChange = (e) => {
    setValue(thisObj, 'txtELK', validateDigitsDecimal(e.target.value))
  }
  thisObj.ontxtELKChange = ontxtELKChange;

  const ontxtELKBlur = () => {
    if ([null, undefined, ''].includes(getValue(thisObj, 'txtELK'))) {
      return
    }

    if (Number(getValue(thisObj, 'txtELK')) > 999.99) {
      showMessage(thisObj, 'ELK must be of format ###.##', false)
      if (isEnabled(thisObj, 'txtELK')) {
        document.getElementById('txtELK').focus();
      }
    }
  }
  thisObj.ontxtELKBlur = ontxtELKBlur;

  const ontxtFancyChange = (e) => {
    setValue(thisObj, 'txtFancy', validateDigitsDecimal(e.target.value))
  }
  thisObj.ontxtFancyChange = ontxtFancyChange;

  const ontxtFancyBlur = () => {
    if ([null, undefined, ''].includes(getValue(thisObj, 'txtFancy'))) {
      return
    }

    if (Number(getValue(thisObj, 'txtFancy')) > 999.99) {
      showMessage(thisObj, 'Fancy must be of format ###.##', false)
      if (isEnabled(thisObj, 'txtFancy')) {
        document.getElementById('txtFancy').focus();
      }
    }
  }
  thisObj.ontxtFancyBlur = ontxtFancyBlur;

  const ontxtFMChange = (e) => {
    setValue(thisObj, 'txtFM', validateDigitsDecimal(e.target.value))
  }
  thisObj.ontxtFMChange = ontxtFMChange;

  const ontxtFMBlur = () => {
    if ([null, undefined, ''].includes(getValue(thisObj, 'txtFM'))) {
      return
    }

    if (Number(getValue(thisObj, 'txtFM')) > 999.99) {
      showMessage(thisObj, 'FM must be of format ###.##', false)
      if (isEnabled(thisObj, 'txtFM')) {
        document.getElementById('txtFM').focus();
      }
    }
  }
  thisObj.ontxtFMBlur = ontxtFMBlur;

  const ontxtHullsChange = (e) => {
    setValue(thisObj, 'txtHulls', validateDigitsDecimal(e.target.value))
  }
  thisObj.ontxtHullsChange = ontxtHullsChange;

  const ontxtHullsBlur = () => {
    if ([null, undefined, ''].includes(getValue(thisObj, 'txtHulls'))) {
      return
    }

    if (Number(getValue(thisObj, 'txtHulls')) > 999.99) {
      showMessage(thisObj, 'Hulls Damage must be of format ###.##', false)
      if (isEnabled(thisObj, 'txtHulls')) {
        document.getElementById('txtHulls').focus();
      }
    }
  }
  thisObj.ontxtHullsBlur = ontxtHullsBlur;

  const ontxtFreezeDamChange = (e) => {
    setValue(thisObj, 'txtFreezeDam', validateDigitsDecimal(e.target.value))
  }
  thisObj.ontxtFreezeDamChange = ontxtFreezeDamChange;

  const ontxtFreezeDamBlur = () => {
    if ([null, undefined, ''].includes(getValue(thisObj, 'txtFreezeDam'))) {
      return
    }

    if (Number(getValue(thisObj, 'txtFreezeDam')) > 999.99) {
      showMessage(thisObj, 'Freeze Damage must be of format ###.##', false)
      if (isEnabled(thisObj, 'txtFreezeDam')) {
        document.getElementById('txtFreezeDam').focus();
      }
    }
  }
  thisObj.ontxtFreezeDamBlur = ontxtFreezeDamBlur;

  const ontxtLSKLbsChange = (e) => {
    setValue(thisObj, 'txtLSKLbs', validateDigits(e.target.value))
  }
  thisObj.ontxtLSKLbsChange = ontxtLSKLbsChange;

  const ontxtMoistChange = (e) => {
    setValue(thisObj, 'txtMoist', validateDigitsDecimal(e.target.value))
  }
  thisObj.ontxtMoistChange = ontxtMoistChange;

  const ontxtMoistBlur = () => {
    if ([null, undefined, ''].includes(getValue(thisObj, 'txtMoist'))) {
      return
    }

    if (Number(getValue(thisObj, 'txtMoist')) > 999.99) {
      showMessage(thisObj, 'Moisture must be of format ###.##', false)
      if (isEnabled(thisObj, 'txtMoist')) {
        document.getElementById('txtMoist').focus();
      }
    }
  }
  thisObj.ontxtMoistBlur = ontxtMoistBlur;

  const ontxtNetLbsChange = (e) => {
    setValue(thisObj, 'txtNetLbs', validateDigits(e.target.value))
  }
  thisObj.ontxtNetLbsChange = ontxtNetLbsChange;

  const ontxtOtherKernelsChange = (e) => {
    setValue(thisObj, 'txtOtherKernels', validateDigitsDecimal(e.target.value))
  }
  thisObj.ontxtOtherKernelsChange = ontxtOtherKernelsChange;

  const ontxtOtherKernelsBlur = () => {
    if ([null, undefined, ''].includes(getValue(thisObj, 'txtOtherKernels'))) {
      return
    }

    if (Number(getValue(thisObj, 'txtOtherKernels')) > 999.99) {
      showMessage(thisObj, 'Other Kernels must be of format ###.##', false)
      if (isEnabled(thisObj, 'txtOtherKernels')) {
        document.getElementById('txtOtherKernels').focus();
      }
    }
  }
  thisObj.ontxtOtherKernelsBlur = ontxtOtherKernelsBlur;

  const ontxtOriginalValueChange = (e) => {
    setValue(thisObj, 'txtOriginalValue', validateDigitsDecimal(e.target.value))
  }
  thisObj.ontxtOriginalValueChange = ontxtOriginalValueChange;

  const ontxtOriginalValueBlur = () => {
    if ([null, undefined, ''].includes(getValue(thisObj, 'txtOriginalValue'))) {
      return
    }

    if (Number(getValue(thisObj, 'txtOriginalValue')) > 9999999.99) {
      showMessage(thisObj, 'Original Value must be of format #######.##', false)
      if (isEnabled(thisObj, 'txtOriginalValue')) {
        document.getElementById('txtOriginalValue').focus();
      }
    }
  }
  thisObj.ontxtOriginalValueBlur = ontxtOriginalValueBlur;

  const ontxtSMKChange = (e) => {
    setValue(thisObj, 'txtSMK', validateDigitsDecimal(e.target.value))
  }
  thisObj.ontxtSMKChange = ontxtSMKChange;

  const ontxtSMKBlur = () => {
    if ([null, undefined, ''].includes(getValue(thisObj, 'txtSMK'))) {
      return
    }

    if (Number(getValue(thisObj, 'txtSMK')) > 999.99) {
      showMessage(thisObj, 'SMK must be of format ###.##', false)
      if (isEnabled(thisObj, 'txtSMK')) {
        document.getElementById('txtSMK').focus();
      }
    }
  }
  thisObj.ontxtSMKBlur = ontxtSMKBlur;

  const ontxtSSChange = (e) => {
    setValue(thisObj, 'txtSS', validateDigitsDecimal(e.target.value))
  }
  thisObj.ontxtSSChange = ontxtSSChange;

  const ontxtSSBlur = () => {
    if ([null, undefined, ''].includes(getValue(thisObj, 'txtSS'))) {
      return
    }

    if (Number(getValue(thisObj, 'txtSS')) > 999.99) {
      showMessage(thisObj, 'SS must be of format ###.##', false)
      if (isEnabled(thisObj, 'txtSS')) {
        document.getElementById('txtSS').focus();
      }
    }
  }
  thisObj.ontxtSSBlur = ontxtSSBlur;

  const ontxtTotalKernelsChange = (e) => {
    setValue(thisObj, 'txtTotalKernels', validateDigitsDecimal(e.target.value))
  }
  thisObj.ontxtTotalKernelsChange = ontxtTotalKernelsChange;

  const ontxtTotalKernelsBlur = () => {
    if ([null, undefined, ''].includes(getValue(thisObj, 'txtTotalKernels'))) {
      return
    }

    if (Number(getValue(thisObj, 'txtTotalKernels')) > 999.99) {
      showMessage(thisObj, 'Total Kernels must be of format ###.##', false)
      if (isEnabled(thisObj, 'txtTotalKernels')) {
        document.getElementById('txtTotalKernels').focus();
      }
    }
  }
  thisObj.ontxtTotalKernelsBlur = ontxtTotalKernelsBlur;

  const ontxtTotalKernHullsChange = (e) => {
    setValue(thisObj, 'txtTotalKernHulls', validateDigitsDecimal(e.target.value))
  }
  thisObj.ontxtTotalKernHullsChange = ontxtTotalKernHullsChange;

  const ontxtTotalKernHullsBlur = () => {
    if ([null, undefined, ''].includes(getValue(thisObj, 'txtTotalKernHulls'))) {
      return
    }

    if (Number(getValue(thisObj, 'txtTotalKernHulls')) > 999.99) {
      showMessage(thisObj, 'Total Kern. Hulls must be of format ###.##', false)
      if (isEnabled(thisObj, 'txtTotalKernHulls')) {
        document.getElementById('txtTotalKernHulls').focus();
      }
    }
  }
  thisObj.ontxtTotalKernHullsBlur = ontxtTotalKernHullsBlur;

  const ontxtTotalLbsChange = (e) => {
    setValue(thisObj, 'txtTotalLbs', validateDigitsDecimal(e.target.value))
  }
  thisObj.ontxtTotalLbsChange = ontxtTotalLbsChange;

  const ontxtTotalLbsBlur = () => {
    if ([null, undefined, ''].includes(getValue(thisObj, 'txtTotalLbs'))) {
      return
    }

    if (Number(getValue(thisObj, 'txtTotalLbs')) > 9999999.99) {
      showMessage(thisObj, 'Total Pounds must be of format #######.##', false)
      if (isEnabled(thisObj, 'txtTotalLbs')) {
        document.getElementById('txtTotalLbs').focus();
      }
    }
  }
  thisObj.ontxtTotalLbsBlur = ontxtTotalLbsBlur;

  const ontxtTotalSMKChange = (e) => {
    setValue(thisObj, 'txtTotalSMK', validateDigitsDecimal(e.target.value))
  }
  thisObj.ontxtTotalSMKChange = ontxtTotalSMKChange;

  const ontxtTotalSMKBlur = () => {
    if ([null, undefined, ''].includes(getValue(thisObj, 'txtTotalSMK'))) {
      return
    }

    if (Number(getValue(thisObj, 'txtTotalSMK')) > 999.99) {
      showMessage(thisObj, 'Total SMK must be of format ###.##', false)
      if (isEnabled(thisObj, 'txtTotalSMK')) {
        document.getElementById('txtTotalSMK').focus();
      }
    }
  }
  thisObj.ontxtTotalSMKBlur = ontxtTotalSMKBlur;

  const ontxtTotalTonsChange = (e) => {
    setValue(thisObj, 'txtTotalTons', validateDigitsDecimal(e.target.value))
  }
  thisObj.ontxtTotalTonsChange = ontxtTotalTonsChange;

  const ontxtTotalTonsBlur = () => {
    if ([null, undefined, ''].includes(getValue(thisObj, 'txtTotalTons'))) {
      return
    }

    if (Number(getValue(thisObj, 'txtTotalTons')) > 9999999.99) {
      showMessage(thisObj, 'Total SMK must be of format #######.##', false)
      if (isEnabled(thisObj, 'txtTotalTons')) {
        document.getElementById('txtTotalTons').focus();
      }
    }
  }
  thisObj.ontxtTotalTonsBlur = ontxtTotalTonsBlur;

  const ontxtLnStateChange = (e) => {
    setValue(thisObj, 'txtLnState', validateDigits(e.target.value))
  }
  thisObj.ontxtLnStateChange = ontxtLnStateChange;

  const ontxtLnNumberChange = (e) => {
    setValue(thisObj, 'txtLnNumber', validateDigits(e.target.value))
  }
  thisObj.ontxtLnNumberChange = ontxtLnNumberChange;

  const ontxtLnCountyChange = (e) => {
    setValue(thisObj, 'txtLnCounty', validateDigits(e.target.value))
  }
  thisObj.ontxtLnCountyChange = ontxtLnCountyChange;

  const ontxtAmountChange = (e) => {
    setValue(thisObj, 'txtAmount', validateDigitsDecimal2(e.target.value))
  }
  thisObj.ontxtAmountChange = ontxtAmountChange;

  const ontxtAmountBlur = (e) => {
    if ((e.target.value).includes('$')) {
      setValue(thisObj, 'txtAmount', numberFormat((e.target.value).slice(1).replace(/,/g, '')))
    } else {
      setValue(thisObj, 'txtAmount', numberFormat(e.target.value))
    }
  }
  thisObj.ontxtAmountBlur = ontxtAmountBlur;

  const setTabIndex = () => {
    let elements = [
      'ddExtRcptStatus',
      'ddStatus',
      'txtRcptNum',
      'txtERecptNum',
      'txtCtrlNum',
      'txtIssuedDate',
      'txtIssuedDateCal',
      'txtWtdAvgStorageDt',
      'txtWtdAvgStorageDt',
      'txtWtdAvgStorageDtCal',
      'txtLicenseNum',
      'txtCCCCode',
      'ddPeanutType',
      'ddSeg',
      'txtOriginalValue',
      'ddHowStored',
      'ddShelled',
      'txtNetLbs',
      'txtTotalLbs',
      'txtTotalTons',
      'txtLSKLbs',
      'ddCurrentHolder',
      'txtCommittedTo',
      'txtDateCancelled',
      'txtDateCancelledCal',
      'radioElectronicPaper0',
      'txtSMK',
      'txtSS',
      'txtTotalSMK',
      'txtOtherKernels',
      'txtTotalDamage',
      'txtTotalKernels',
      'txtHulls',
      'txtTotalKernHulls',
      'txtFM',
      'txtMoist',
      'txtELK',
      'txtFancy',
      'txtDisclrd',
      'txtCnclRMD',
      'txtFreezeDam',
      'ddFlavus',
      'txtCCCBeginDate',
      'txtCCCBeginDateCal',
      'txtEndDate',
      'txtEndDateCal',
      'txtLnState',
      'txtLnCounty',
      'txtLnNumber',
      'txtLoanDt',
      'txtLoanDtCal',
      'btnCreate',
      'btnClose'
    ]
    elements.map((element,index) => {
      let tabElem = document.querySelectorAll(".modal")[1].querySelector(`#${element}`)
      if (tabElem !== null){
        tabElem.tabIndex = index+200
      }
    })
  }

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
        setValues
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        thisObj.setValues = setValues = setValues;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ExtReceiptForfeituresProfile*/}

              {/* END_USER_CODE-USER_BEFORE_ExtReceiptForfeituresProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxExtReceiptForfeitureProfile*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxExtReceiptForfeitureProfile*/}

              <GroupBoxWidget
                conf={state.grpbxExtReceiptForfeitureProfile}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxReceiptInfo*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxReceiptInfo*/}

                <GroupBoxWidget
                  conf={state.grpbxReceiptInfo}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_grpbxReceiptInfo1*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxReceiptInfo1*/}

                  <GroupBoxWidget
                    conf={state.grpbxReceiptInfo1}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lblReceiptInfo*/}

                    {/* END_USER_CODE-USER_BEFORE_lblReceiptInfo*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblReceiptInfo}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblReceiptInfo*/}

                    {/* END_USER_CODE-USER_AFTER_lblReceiptInfo*/}
                    {/* START_USER_CODE-USER_BEFORE_ddExtRcptStatus*/}

                    {/* END_USER_CODE-USER_BEFORE_ddExtRcptStatus*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddExtRcptStatus}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddExtRcptStatus*/}

                    {/* END_USER_CODE-USER_AFTER_ddExtRcptStatus*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPayoffLocation*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPayoffLocation*/}

                    {/* <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPayoffLocation}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget> */}
                    {/* START_USER_CODE-USER_BEFORE_ddPayoffLocation*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPayoffLocation*/}
                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPayoffLocation}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPayoffLocation*/}

                    {/* END_USER_CODE-USER_AFTER_ddPayoffLocation*/}
                    {/* START_USER_CODE-USER_AFTER_txtPayoffLocation*/}

                    {/* END_USER_CODE-USER_AFTER_txtPayoffLocation*/}
                    {/* START_USER_CODE-USER_BEFORE_txtWhseBin*/}

                    {/* END_USER_CODE-USER_BEFORE_txtWhseBin*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtWhseBin}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtWhseBin*/}

                    {/* END_USER_CODE-USER_AFTER_txtWhseBin*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRcptNum*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRcptNum*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRcptNum}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRcptNum*/}

                    {/* END_USER_CODE-USER_AFTER_txtRcptNum*/}
                    {/* START_USER_CODE-USER_BEFORE_txtERecptNum*/}

                    {/* END_USER_CODE-USER_BEFORE_txtERecptNum*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtERecptNum}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtERecptNum*/}

                    {/* END_USER_CODE-USER_AFTER_txtERecptNum*/}
                    {/* START_USER_CODE-USER_BEFORE_txtCtrlNum*/}

                    {/* END_USER_CODE-USER_BEFORE_txtCtrlNum*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCtrlNum}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtCtrlNum*/}

                    {/* END_USER_CODE-USER_AFTER_txtCtrlNum*/}
                    {/* START_USER_CODE-USER_BEFORE_ddStatus*/}

                    {/* END_USER_CODE-USER_BEFORE_ddStatus*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddStatus}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddStatus*/}

                    {/* END_USER_CODE-USER_AFTER_ddStatus*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                    {/* START_USER_CODE-USER_BEFORE_txtIssuedDate*/}

                    {/* END_USER_CODE-USER_BEFORE_txtIssuedDate*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtIssuedDate}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtIssuedDate*/}

                    {/* END_USER_CODE-USER_AFTER_txtIssuedDate*/}
                    {/* START_USER_CODE-USER_BEFORE_txtWtdAvgStorageDt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtWtdAvgStorageDt*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtWtdAvgStorageDt}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtWtdAvgStorageDt*/}

                    {/* END_USER_CODE-USER_AFTER_txtWtdAvgStorageDt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLicenseNum*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLicenseNum*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLicenseNum}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLicenseNum*/}

                    {/* END_USER_CODE-USER_AFTER_txtLicenseNum*/}
                    {/* START_USER_CODE-USER_BEFORE_txtCCCCode*/}

                    {/* END_USER_CODE-USER_BEFORE_txtCCCCode*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCCCCode}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtCCCCode*/}

                    {/* END_USER_CODE-USER_AFTER_txtCCCCode*/}
                    {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                    {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddPeanutType}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                    {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                    {/* START_USER_CODE-USER_BEFORE_ddSeg*/}

                    {/* END_USER_CODE-USER_BEFORE_ddSeg*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddSeg}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddSeg*/}

                    {/* END_USER_CODE-USER_AFTER_ddSeg*/}
                    {/* START_USER_CODE-USER_BEFORE_txtOriginalValue*/}

                    {/* END_USER_CODE-USER_BEFORE_txtOriginalValue*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtOriginalValue}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtOriginalValue*/}

                    {/* END_USER_CODE-USER_AFTER_txtOriginalValue*/}
                    {/* START_USER_CODE-USER_BEFORE_ddHowStored*/}

                    {/* END_USER_CODE-USER_BEFORE_ddHowStored*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddHowStored}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddHowStored*/}

                    {/* END_USER_CODE-USER_AFTER_ddHowStored*/}
                    {/* START_USER_CODE-USER_BEFORE_ddShelled*/}

                    {/* END_USER_CODE-USER_BEFORE_ddShelled*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddShelled}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddShelled*/}

                    {/* END_USER_CODE-USER_AFTER_ddShelled*/}
                    {/* START_USER_CODE-USER_BEFORE_txtCopiedToCropYear*/}

                    {/* END_USER_CODE-USER_BEFORE_txtCopiedToCropYear*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCopiedToCropYear}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtCopiedToCropYear*/}

                    {/* END_USER_CODE-USER_AFTER_txtCopiedToCropYear*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShrinkPct*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShrinkPct*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShrinkPct}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShrinkPct*/}

                    {/* END_USER_CODE-USER_AFTER_txtShrinkPct*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShrunkValue*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShrunkValue*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShrunkValue}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShrunkValue*/}

                    {/* END_USER_CODE-USER_AFTER_txtShrunkValue*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxReceiptInfo1*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxReceiptInfo1*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxReceiptInfo2*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxReceiptInfo2*/}

                  <GroupBoxWidget
                    conf={state.grpbxReceiptInfo2}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_txtNetLbs*/}

                    {/* END_USER_CODE-USER_BEFORE_txtNetLbs*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtNetLbs}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtNetLbs*/}

                    {/* END_USER_CODE-USER_AFTER_txtNetLbs*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTotalLbs*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTotalLbs*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTotalLbs}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTotalLbs*/}

                    {/* END_USER_CODE-USER_AFTER_txtTotalLbs*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTotalTons*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTotalTons*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTotalTons}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTotalTons*/}

                    {/* END_USER_CODE-USER_AFTER_txtTotalTons*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLSKLbs*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLSKLbs*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLSKLbs}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLSKLbs*/}

                    {/* END_USER_CODE-USER_AFTER_txtLSKLbs*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShrnkPct*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShrnkPct*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShrnkPct}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShrnkPct*/}

                    {/* END_USER_CODE-USER_AFTER_txtShrnkPct*/}
                    {/* START_USER_CODE-USER_BEFORE_txtNetLbsShrunk*/}

                    {/* END_USER_CODE-USER_BEFORE_txtNetLbsShrunk*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtNetLbsShrunk}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtNetLbsShrunk*/}

                    {/* END_USER_CODE-USER_AFTER_txtNetLbsShrunk*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTotalLbsShrunk*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTotalLbsShrunk*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTotalLbsShrunk}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTotalLbsShrunk*/}

                    {/* END_USER_CODE-USER_AFTER_txtTotalLbsShrunk*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTotalTonsShrunk*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTotalTonsShrunk*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTotalTonsShrunk}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTotalTonsShrunk*/}

                    {/* END_USER_CODE-USER_AFTER_txtTotalTonsShrunk*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLSKLbsShrunk*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLSKLbsShrunk*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLSKLbsShrunk}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLSKLbsShrunk*/}

                    {/* END_USER_CODE-USER_AFTER_txtLSKLbsShrunk*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxReceiptInfo3*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxReceiptInfo3*/}

                    <GroupBoxWidget
                      conf={state.grpbxReceiptInfo3}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_ddCurrentHolder*/}

                      {/* END_USER_CODE-USER_BEFORE_ddCurrentHolder*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(event, handleChange, handleBlur, thisObj)
                        }
                        onBlur={event =>
                          invokeEventHandler(event, handleChange, handleBlur, thisObj)
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddCurrentHolder}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(event, handleChange, handleBlur, thisObj)
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_BEFORE_txtCommittedTo*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCommittedTo*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCommittedTo}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCommittedTo*/}

                      {/* END_USER_CODE-USER_AFTER_txtCommittedTo*/}
                      {/* START_USER_CODE-USER_BEFORE_txtLastAction*/}

                      {/* END_USER_CODE-USER_BEFORE_txtLastAction*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtLastAction}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtLastAction*/}

                      {/* END_USER_CODE-USER_AFTER_txtLastAction*/}
                      {/* START_USER_CODE-USER_BEFORE_txtDateCancelled*/}

                      {/* END_USER_CODE-USER_BEFORE_txtDateCancelled*/}

                      <DateWidget
                        onChange={event =>
                          invokeEventHandler(event, handleChange, handleBlur, thisObj)
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtDateCancelled}
                        screenConf={state}
                      ></DateWidget>
                      {/* START_USER_CODE-USER_AFTER_txtDateCancelled*/}

                      {/* END_USER_CODE-USER_AFTER_txtDateCancelled*/}
                      {/* START_USER_CODE-USER_BEFORE_radioElectronicPaper*/}

                      {/* END_USER_CODE-USER_BEFORE_radioElectronicPaper*/}

                      <RadioButtonGroupWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.radioElectronicPaper}
                        screenConf={state}
                      ></RadioButtonGroupWidget>
                      {/* START_USER_CODE-USER_AFTER_radioElectronicPaper*/}

                      {/* END_USER_CODE-USER_AFTER_radioElectronicPaper*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxReceiptInfo3*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxReceiptInfo3*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxReceiptInfo2*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxReceiptInfo2*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPayoffDetails*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPayoffDetails*/}

                  <GroupBoxWidget
                    conf={state.grpbxPayoffDetails}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lblPayoffDetails*/}

                    {/* END_USER_CODE-USER_BEFORE_lblPayoffDetails*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblPayoffDetails}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblPayoffDetails*/}

                    {/* END_USER_CODE-USER_AFTER_lblPayoffDetails*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendorNum*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendorNum*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendorNum}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendorNum*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendorNum*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVndrName*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVndrName*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVndrName}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVndrName*/}

                    {/* END_USER_CODE-USER_AFTER_txtVndrName*/}
                    {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                    <ButtonWidget
                      conf={state.btnVendor}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                    {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRemitTo*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRemitTo*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRemitTo}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRemitTo*/}

                    {/* END_USER_CODE-USER_AFTER_ddRemitTo*/}
                    {/* START_USER_CODE-USER_BEFORE_ddTradeCategory*/}

                    {/* END_USER_CODE-USER_BEFORE_ddTradeCategory*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddTradeCategory}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddTradeCategory*/}

                    {/* END_USER_CODE-USER_AFTER_ddTradeCategory*/}
                    {/* START_USER_CODE-USER_BEFORE_txtAmount*/}

                    {/* END_USER_CODE-USER_BEFORE_txtAmount*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtAmount}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtAmount*/}

                    {/* END_USER_CODE-USER_AFTER_txtAmount*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPayoffDetails*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPayoffDetails*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxReceiptInfo*/}

                {/* END_USER_CODE-USER_AFTER_grpbxReceiptInfo*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxFactorsForAllPeanuts*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxFactorsForAllPeanuts*/}

                <GroupBoxWidget
                  conf={state.grpbxFactorsForAllPeanuts}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblFactorsForAllPeanuts*/}

                  {/* END_USER_CODE-USER_BEFORE_lblFactorsForAllPeanuts*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblFactorsForAllPeanuts}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblFactorsForAllPeanuts*/}

                  {/* END_USER_CODE-USER_AFTER_lblFactorsForAllPeanuts*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSMK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSMK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSMK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSMK*/}

                  {/* END_USER_CODE-USER_AFTER_txtSMK*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct1*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct1*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct1}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct1*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct1*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalDamage*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalDamage*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalDamage}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalDamage*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalDamage*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct2*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct2*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct2}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct2*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct2*/}
                  {/* START_USER_CODE-USER_BEFORE_txtHullsBright*/}

                  {/* END_USER_CODE-USER_BEFORE_txtHullsBright*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtHullsBright}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtHullsBright*/}

                  {/* END_USER_CODE-USER_AFTER_txtHullsBright*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct3*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct3*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct3}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct3*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct3*/}
                  {/* START_USER_CODE-USER_BEFORE_txtELK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtELK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtELK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtELK*/}

                  {/* END_USER_CODE-USER_AFTER_txtELK*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct4*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct4*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct4}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct4*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct4*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCnclRMD*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCnclRMD*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCnclRMD}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCnclRMD*/}

                  {/* END_USER_CODE-USER_AFTER_txtCnclRMD*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct5*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct5*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct5}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct5*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct5*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSS*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSS*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSS}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSS*/}

                  {/* END_USER_CODE-USER_AFTER_txtSS*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct6*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct6*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct6}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct6*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct6*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalKernels*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalKernels*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalKernels}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalKernels*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalKernels*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct7*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct7*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct7}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct7*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct7*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLSK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLSK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLSK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLSK*/}

                  {/* END_USER_CODE-USER_AFTER_txtLSK*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct8*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct8*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct8}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct8*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct8*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFancy*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFancy*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFancy}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFancy*/}

                  {/* END_USER_CODE-USER_AFTER_txtFancy*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct9*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct9*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct9}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct9*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct9*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFreezeDam*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFreezeDam*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFreezeDam}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFreezeDam*/}

                  {/* END_USER_CODE-USER_AFTER_txtFreezeDam*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct10*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct10*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct10}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct10*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct10*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalSMK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalSMK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalSMK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalSMK*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalSMK*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct11*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct11*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct11}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct11*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct11*/}
                  {/* START_USER_CODE-USER_BEFORE_txtHulls*/}

                  {/* END_USER_CODE-USER_BEFORE_txtHulls*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtHulls}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtHulls*/}

                  {/* END_USER_CODE-USER_AFTER_txtHulls*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct12*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct12*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct12}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct12*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct12*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFM*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFM*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFM}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFM*/}

                  {/* END_USER_CODE-USER_AFTER_txtFM*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct13*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct13*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct13}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct13*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct13*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDisclrd*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDisclrd*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDisclrd}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDisclrd*/}

                  {/* END_USER_CODE-USER_AFTER_txtDisclrd*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct14*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct14*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct14}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct14*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct14*/}
                  {/* START_USER_CODE-USER_BEFORE_txtJumbo*/}

                  {/* END_USER_CODE-USER_BEFORE_txtJumbo*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtJumbo}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtJumbo*/}

                  {/* END_USER_CODE-USER_AFTER_txtJumbo*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct15*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct15*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct15}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct15*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct15*/}
                  {/* START_USER_CODE-USER_BEFORE_txtOtherKernels*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOtherKernels*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOtherKernels}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOtherKernels*/}

                  {/* END_USER_CODE-USER_AFTER_txtOtherKernels*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct16*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct16*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct16}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct16*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct16*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalKernHulls*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalKernHulls*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalKernHulls}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalKernHulls*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalKernHulls*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct17*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct17*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct17}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct17*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct17*/}
                  {/* START_USER_CODE-USER_BEFORE_txtMoist*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMoist*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMoist}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMoist*/}

                  {/* END_USER_CODE-USER_AFTER_txtMoist*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct18*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct18*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct18}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct18*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct18*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCrackBroken*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCrackBroken*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCrackBroken}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCrackBroken*/}

                  {/* END_USER_CODE-USER_AFTER_txtCrackBroken*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct19*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct19*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct19}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct19*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct19*/}
                  {/* START_USER_CODE-USER_BEFORE_ddFlavus*/}

                  {/* END_USER_CODE-USER_BEFORE_ddFlavus*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddFlavus}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddFlavus*/}

                  {/* END_USER_CODE-USER_AFTER_ddFlavus*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVICAM*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVICAM*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVICAM}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVICAM*/}

                  {/* END_USER_CODE-USER_AFTER_txtVICAM*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPPB*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPPB*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPPB}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPPB*/}

                  {/* END_USER_CODE-USER_AFTER_lblPPB*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxFactorsForAllPeanuts*/}

                {/* END_USER_CODE-USER_AFTER_grpbxFactorsForAllPeanuts*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxStrgPrchs*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxStrgPrchs*/}

                <GroupBoxWidget conf={state.grpbxStrgPrchs} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_grpbxStorage*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStorage*/}

                  <GroupBoxWidget conf={state.grpbxStorage} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_lblStorage*/}

                    {/* END_USER_CODE-USER_BEFORE_lblStorage*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblStorage}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblStorage*/}

                    {/* END_USER_CODE-USER_AFTER_lblStorage*/}
                    {/* START_USER_CODE-USER_BEFORE_txtProducerBeginDate*/}

                    {/* END_USER_CODE-USER_BEFORE_txtProducerBeginDate*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtProducerBeginDate}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtProducerBeginDate*/}

                    {/* END_USER_CODE-USER_AFTER_txtProducerBeginDate*/}
                    {/* START_USER_CODE-USER_BEFORE_txtCCCBeginDate*/}

                    {/* END_USER_CODE-USER_BEFORE_txtCCCBeginDate*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCCCBeginDate}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtCCCBeginDate*/}

                    {/* END_USER_CODE-USER_AFTER_txtCCCBeginDate*/}
                    {/* START_USER_CODE-USER_BEFORE_txtEndDate*/}

                    {/* END_USER_CODE-USER_BEFORE_txtEndDate*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtEndDate}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtEndDate*/}

                    {/* END_USER_CODE-USER_AFTER_txtEndDate*/}
                    {/* START_USER_CODE-USER_BEFORE_txtDocDelvDt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtDocDelvDt*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtDocDelvDt}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtDocDelvDt*/}

                    {/* END_USER_CODE-USER_AFTER_txtDocDelvDt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtDelvCompletedDt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtDelvCompletedDt*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtDelvCompletedDt}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtDelvCompletedDt*/}

                    {/* END_USER_CODE-USER_AFTER_txtDelvCompletedDt*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStorage*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStorage*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPurchase*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPurchase*/}

                  <GroupBoxWidget conf={state.grpbxPurchase} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_lblPurchaseInfo*/}

                    {/* END_USER_CODE-USER_BEFORE_lblPurchaseInfo*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblPurchaseInfo}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblPurchaseInfo*/}

                    {/* END_USER_CODE-USER_AFTER_lblPurchaseInfo*/}
                    {/* START_USER_CODE-USER_BEFORE_txtOverrideFarm*/}

                    {/* END_USER_CODE-USER_BEFORE_txtOverrideFarm*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtOverrideFarm}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtOverrideFarm*/}

                    {/* END_USER_CODE-USER_AFTER_txtOverrideFarm*/}
                    {/* START_USER_CODE-USER_BEFORE_txtFarmerStockCost*/}

                    {/* END_USER_CODE-USER_BEFORE_txtFarmerStockCost*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtFarmerStockCost}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtFarmerStockCost*/}

                    {/* END_USER_CODE-USER_AFTER_txtFarmerStockCost*/}
                    {/* START_USER_CODE-USER_BEFORE_txtStorageCharge*/}

                    {/* END_USER_CODE-USER_BEFORE_txtStorageCharge*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtStorageCharge}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtStorageCharge*/}

                    {/* END_USER_CODE-USER_AFTER_txtStorageCharge*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValueOfSeg*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValueOfSeg*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValueOfSeg}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValueOfSeg*/}

                    {/* END_USER_CODE-USER_AFTER_txtValueOfSeg*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSpotPrice*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSpotPrice*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSpotPrice}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSpotPrice*/}

                    {/* END_USER_CODE-USER_AFTER_txtSpotPrice*/}
                    {/* START_USER_CODE-USER_BEFORE_txtOptionPayment*/}

                    {/* END_USER_CODE-USER_BEFORE_txtOptionPayment*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtOptionPayment}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtOptionPayment*/}

                    {/* END_USER_CODE-USER_AFTER_txtOptionPayment*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSpotPounds*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSpotPounds*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSpotPounds}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSpotPounds*/}

                    {/* END_USER_CODE-USER_AFTER_txtSpotPounds*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValuePerTon*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValuePerTon*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValuePerTon}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValuePerTon*/}

                    {/* END_USER_CODE-USER_AFTER_txtValuePerTon*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLoanRepayRate*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLoanRepayRate*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLoanRepayRate}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLoanRepayRate*/}

                    {/* END_USER_CODE-USER_AFTER_txtLoanRepayRate*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSupportPercent*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSupportPercent*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSupportPercent}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSupportPercent*/}

                    {/* END_USER_CODE-USER_AFTER_txtSupportPercent*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxPrchs1*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxPrchs1*/}

                    <GroupBoxWidget conf={state.grpbxPrchs1} screenConf={state}>
                      {/* START_USER_CODE-USER_BEFORE_radioRedemptionMethod*/}

                      {/* END_USER_CODE-USER_BEFORE_radioRedemptionMethod*/}

                      <RadioButtonGroupWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.radioRedemptionMethod}
                        screenConf={state}
                      ></RadioButtonGroupWidget>
                      {/* START_USER_CODE-USER_AFTER_radioRedemptionMethod*/}

                      {/* END_USER_CODE-USER_AFTER_radioRedemptionMethod*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxPrchs1*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxPrchs1*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxPrchs2*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxPrchs2*/}

                    <GroupBoxWidget conf={state.grpbxPrchs2} screenConf={state}>
                      {/* START_USER_CODE-USER_BEFORE_txtPremiumAmt*/}

                      {/* END_USER_CODE-USER_BEFORE_txtPremiumAmt*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtPremiumAmt}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtPremiumAmt*/}

                      {/* END_USER_CODE-USER_AFTER_txtPremiumAmt*/}
                      {/* START_USER_CODE-USER_BEFORE_txtDeductAmt*/}

                      {/* END_USER_CODE-USER_BEFORE_txtDeductAmt*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtDeductAmt}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtDeductAmt*/}

                      {/* END_USER_CODE-USER_AFTER_txtDeductAmt*/}
                      {/* START_USER_CODE-USER_BEFORE_txtProceedsAmt*/}

                      {/* END_USER_CODE-USER_BEFORE_txtProceedsAmt*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtProceedsAmt}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtProceedsAmt*/}

                      {/* END_USER_CODE-USER_AFTER_txtProceedsAmt*/}
                      {/* START_USER_CODE-USER_BEFORE_chkboxDeferredRedemption*/}

                      {/* END_USER_CODE-USER_BEFORE_chkboxDeferredRedemption*/}

                      <CheckboxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.chkboxDeferredRedemption}
                        screenConf={state}
                      ></CheckboxWidget>
                      {/* START_USER_CODE-USER_AFTER_chkboxDeferredRedemption*/}

                      {/* END_USER_CODE-USER_AFTER_chkboxDeferredRedemption*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxPrchs2*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxPrchs2*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPurchase*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPurchase*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxStrgPrchs*/}

                {/* END_USER_CODE-USER_AFTER_grpbxStrgPrchs*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxRepay*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxRepay*/}

                <GroupBoxWidget conf={state.grpbxRepay} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_grpbxRepayment*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxRepayment*/}

                  <GroupBoxWidget
                    conf={state.grpbxRepayment}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lblRepayment*/}

                    {/* END_USER_CODE-USER_BEFORE_lblRepayment*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblRepayment}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblRepayment*/}

                    {/* END_USER_CODE-USER_AFTER_lblRepayment*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLoanRepayAmt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLoanRepayAmt*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLoanRepayAmt}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLoanRepayAmt*/}

                    {/* END_USER_CODE-USER_AFTER_txtLoanRepayAmt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLoanRepayAmtOverride*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLoanRepayAmtOverride*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLoanRepayAmtOverride}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLoanRepayAmtOverride*/}

                    {/* END_USER_CODE-USER_AFTER_txtLoanRepayAmtOverride*/}
                    {/* START_USER_CODE-USER_BEFORE_txtStrgCreditAmt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtStrgCreditAmt*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtStrgCreditAmt}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtStrgCreditAmt*/}

                    {/* END_USER_CODE-USER_AFTER_txtStrgCreditAmt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtStrgCreditAmtOverride*/}

                    {/* END_USER_CODE-USER_BEFORE_txtStrgCreditAmtOverride*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtStrgCreditAmtOverride}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtStrgCreditAmtOverride*/}

                    {/* END_USER_CODE-USER_AFTER_txtStrgCreditAmtOverride*/}
                    {/* START_USER_CODE-USER_BEFORE_txtInterestAmt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtInterestAmt*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtInterestAmt}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtInterestAmt*/}

                    {/* END_USER_CODE-USER_AFTER_txtInterestAmt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtCalCNetRepayAmt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtCalCNetRepayAmt*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCalCNetRepayAmt}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtCalCNetRepayAmt*/}

                    {/* END_USER_CODE-USER_AFTER_txtCalCNetRepayAmt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtNetRepayAmt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtNetRepayAmt*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtNetRepayAmt}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtNetRepayAmt*/}

                    {/* END_USER_CODE-USER_AFTER_txtNetRepayAmt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRepayVendorNum*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRepayVendorNum*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRepayVendorNum}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRepayVendorNum*/}

                    {/* END_USER_CODE-USER_AFTER_txtRepayVendorNum*/}
                    {/* START_USER_CODE-USER_BEFORE_btnRepayVendor*/}

                    {/* END_USER_CODE-USER_BEFORE_btnRepayVendor*/}

                    <ButtonWidget
                      conf={state.btnRepayVendor}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnRepayVendor*/}

                    {/* END_USER_CODE-USER_AFTER_btnRepayVendor*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRepayVendor*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRepayVendor*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRepayVendor}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRepayVendor*/}

                    {/* END_USER_CODE-USER_AFTER_txtRepayVendor*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRepayRemit*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRepayRemit*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRepayRemit}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRepayRemit*/}

                    {/* END_USER_CODE-USER_AFTER_ddRepayRemit*/}
                    {/* START_USER_CODE-USER_BEFORE_txtCMAVendorNum*/}

                    {/* END_USER_CODE-USER_BEFORE_txtCMAVendorNum*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMAVendorNum}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtCMAVendorNum*/}

                    {/* END_USER_CODE-USER_AFTER_txtCMAVendorNum*/}
                    {/* START_USER_CODE-USER_BEFORE_btnCMAVendor*/}

                    {/* END_USER_CODE-USER_BEFORE_btnCMAVendor*/}

                    <ButtonWidget
                      conf={state.btnCMAVendor}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnCMAVendor*/}

                    {/* END_USER_CODE-USER_AFTER_btnCMAVendor*/}
                    {/* START_USER_CODE-USER_BEFORE_txtCMAVendor*/}

                    {/* END_USER_CODE-USER_BEFORE_txtCMAVendor*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMAVendor}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtCMAVendor*/}

                    {/* END_USER_CODE-USER_AFTER_txtCMAVendor*/}
                    {/* START_USER_CODE-USER_BEFORE_ddCMARemit*/}

                    {/* END_USER_CODE-USER_BEFORE_ddCMARemit*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddCMARemit}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddCMARemit*/}

                    {/* END_USER_CODE-USER_AFTER_ddCMARemit*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxRepayment*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxRepayment*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxLoan*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxLoan*/}

                  <GroupBoxWidget conf={state.grpbxLoan} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_grpbxLoanNum*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxLoanNum*/}

                    <GroupBoxWidget
                      conf={state.grpbxLoanNum}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_lblLoanNum*/}

                      {/* END_USER_CODE-USER_BEFORE_lblLoanNum*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblLoanNum}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblLoanNum*/}

                      {/* END_USER_CODE-USER_AFTER_lblLoanNum*/}
                      {/* START_USER_CODE-USER_BEFORE_txtLnState*/}

                      {/* END_USER_CODE-USER_BEFORE_txtLnState*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtLnState}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtLnState*/}

                      {/* END_USER_CODE-USER_AFTER_txtLnState*/}
                      {/* START_USER_CODE-USER_BEFORE_txtLnCounty*/}

                      {/* END_USER_CODE-USER_BEFORE_txtLnCounty*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtLnCounty}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtLnCounty*/}

                      {/* END_USER_CODE-USER_AFTER_txtLnCounty*/}
                      {/* START_USER_CODE-USER_BEFORE_txtLnNumber*/}

                      {/* END_USER_CODE-USER_BEFORE_txtLnNumber*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtLnNumber}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtLnNumber*/}

                      {/* END_USER_CODE-USER_AFTER_txtLnNumber*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxLoanNum*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxLoanNum*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxChkboxCntnr*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxChkboxCntnr*/}

                    <GroupBoxWidget
                      conf={state.grpbxChkboxCntnr}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_chkbox211*/}

                      {/* END_USER_CODE-USER_BEFORE_chkbox211*/}

                      <CheckboxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.chkbox211}
                        screenConf={state}
                      ></CheckboxWidget>
                      {/* START_USER_CODE-USER_AFTER_chkbox211*/}

                      {/* END_USER_CODE-USER_AFTER_chkbox211*/}
                      {/* START_USER_CODE-USER_BEFORE_chkboxCCC500*/}

                      {/* END_USER_CODE-USER_BEFORE_chkboxCCC500*/}

                      <CheckboxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.chkboxCCC500}
                        screenConf={state}
                      ></CheckboxWidget>
                      {/* START_USER_CODE-USER_AFTER_chkboxCCC500*/}

                      {/* END_USER_CODE-USER_AFTER_chkboxCCC500*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxChkboxCntnr*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxChkboxCntnr*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLnRpyLockInDt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLnRpyLockInDt*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLnRpyLockInDt}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLnRpyLockInDt*/}

                    {/* END_USER_CODE-USER_AFTER_txtLnRpyLockInDt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLoanAmt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLoanAmt*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLoanAmt}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLoanAmt*/}

                    {/* END_USER_CODE-USER_AFTER_txtLoanAmt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtExpDate*/}

                    {/* END_USER_CODE-USER_BEFORE_txtExpDate*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtExpDate}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtExpDate*/}

                    {/* END_USER_CODE-USER_AFTER_txtExpDate*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLoanDt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLoanDt*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLoanDt}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLoanDt*/}

                    {/* END_USER_CODE-USER_AFTER_txtLoanDt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtMarketGain1*/}

                    {/* END_USER_CODE-USER_BEFORE_txtMarketGain1*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtMarketGain1}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtMarketGain1*/}

                    {/* END_USER_CODE-USER_AFTER_txtMarketGain1*/}
                    {/* START_USER_CODE-USER_BEFORE_txtMarketGain2*/}

                    {/* END_USER_CODE-USER_BEFORE_txtMarketGain2*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtMarketGain2}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtMarketGain2*/}

                    {/* END_USER_CODE-USER_AFTER_txtMarketGain2*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxLoan*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxLoan*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxRepay*/}

                {/* END_USER_CODE-USER_AFTER_grpbxRepay*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxExtReceiptForfeitureProfile*/}

              {/* END_USER_CODE-USER_AFTER_grpbxExtReceiptForfeitureProfile*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_btnCopyExternal*/}

                {/* END_USER_CODE-USER_BEFORE_btnCopyExternal*/}

                <ButtonWidget
                  conf={state.btnCopyExternal}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCopyExternal*/}

                {/* END_USER_CODE-USER_AFTER_btnCopyExternal*/}
                {/* START_USER_CODE-USER_BEFORE_btnRelease*/}

                {/* END_USER_CODE-USER_BEFORE_btnRelease*/}

                <ButtonWidget
                  conf={state.btnRelease}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnRelease*/}

                {/* END_USER_CODE-USER_AFTER_btnRelease*/}
                {/* START_USER_CODE-USER_BEFORE_btnReversal*/}

                {/* END_USER_CODE-USER_BEFORE_btnReversal*/}

                <ButtonWidget
                  conf={state.btnReversal}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnReversal*/}

                {/* END_USER_CODE-USER_AFTER_btnReversal*/}
                {/* START_USER_CODE-USER_BEFORE_btnCreatePayoff*/}

                {/* END_USER_CODE-USER_BEFORE_btnCreatePayoff*/}

                <ButtonWidget
                  conf={state.btnCreatePayoff}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCreatePayoff*/}

                {/* END_USER_CODE-USER_AFTER_btnCreatePayoff*/}
                {/* START_USER_CODE-USER_BEFORE_btnDeliveryAgreement*/}

                {/* END_USER_CODE-USER_BEFORE_btnDeliveryAgreement*/}

                <ButtonWidget
                  conf={state.btnDeliveryAgreement}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDeliveryAgreement*/}

                {/* END_USER_CODE-USER_AFTER_btnDeliveryAgreement*/}
                {/* START_USER_CODE-USER_BEFORE_btnAcctDist*/}

                {/* END_USER_CODE-USER_BEFORE_btnAcctDist*/}

                <ButtonWidget
                  conf={state.btnAcctDist}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAcctDist*/}

                {/* END_USER_CODE-USER_AFTER_btnAcctDist*/}
                {/* START_USER_CODE-USER_BEFORE_btnCreate*/}

                {/* END_USER_CODE-USER_BEFORE_btnCreate*/}

                <ButtonWidget
                  conf={state.btnCreate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCreate*/}

                {/* END_USER_CODE-USER_AFTER_btnCreate*/}
                {/* START_USER_CODE-USER_BEFORE_btnUpdate*/}

                {/* END_USER_CODE-USER_BEFORE_btnUpdate*/}

                <ButtonWidget
                  conf={state.btnUpdate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUpdate*/}

                {/* END_USER_CODE-USER_AFTER_btnUpdate*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedDate*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedDate*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedDate}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedDate*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedDate*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedDateValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedDateValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedDateValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedDateValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedDateValue*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_ExtReceiptForfeituresProfile*/}

              {/* END_USER_CODE-USER_AFTER_ExtReceiptForfeituresProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_ExtReceiptForfeituresProfile);
