//Farm Inquiry
/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  goTo,
  setData,
  getData,
  getValue,
  setValue,
  hide,
  show,
} from "../../shared/WindowImports";

import "./FarmInquiry.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import CommonContext from "../../Store/CommonContext";
import Loading from "../../../Loader/Loading";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_FarmInquiry(props) {
  const commonContext1 = useContext(CommonContext);
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "FarmInquiry",
    windowName: "FarmInquiry",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.FarmInquiry",
    // START_USER_CODE-USER_FarmInquiry_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Farm Inquiry",
      scrCode: "PN0030A",
    },
    // END_USER_CODE-USER_FarmInquiry_PROPERTIES
    btn1: {
      name: "btn1",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: ".",
      CharWidth: "6",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn1_PROPERTIES

      // END_USER_CODE-USER_btn1_PROPERTIES
    },
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxFarmList",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnCountyDataForeNUTSImport: {
      name: "btnCountyDataForeNUTSImport",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "County Data for e-NUTS Import",
      CharWidth: "59",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCountyDataForeNUTSImport_PROPERTIES

      // END_USER_CODE-USER_btnCountyDataForeNUTSImport_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxFarmList",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxFarmList",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnFarmDataForAccess: {
      name: "btnFarmDataForAccess",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Farm Data for ACCESS (old)",
      CharWidth: "54",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnFarmDataForAccess_PROPERTIES

      // END_USER_CODE-USER_btnFarmDataForAccess_PROPERTIES
    },
    btnFarmDataForeNUTSImport: {
      name: "btnFarmDataForeNUTSImport",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Farm Data for e-NUTS Import",
      CharWidth: "55",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnFarmDataForeNUTSImport_PROPERTIES

      // END_USER_CODE-USER_btnFarmDataForeNUTSImport_PROPERTIES
    },
    btnPrintSearch: {
      name: "btnPrintSearch",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print Search",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrintSearch_PROPERTIES

      // END_USER_CODE-USER_btnPrintSearch_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxFarmList",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxFarmDetails",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    ddBuyingPoint: {
      name: "ddBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmDetails",
      Label: "Buying Point:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPoint_PROPERTIES
    },
    ddCounty: {
      name: "ddCounty",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmDetails",
      Label: "County:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCounty_PROPERTIES

      // END_USER_CODE-USER_ddCounty_PROPERTIES
    },
    ddFarm: {
      name: "ddFarm",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmDetails",
      Label: "Farm:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddFarm_PROPERTIES

      // END_USER_CODE-USER_ddFarm_PROPERTIES
    },
    ddState: {
      name: "ddState",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmDetails",
      Label: "State:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddState_PROPERTIES

      // END_USER_CODE-USER_ddState_PROPERTIES
    },
    col211Concordia: {
      name: "col211Concordia",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "211 Concordia",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col211Concordia_PROPERTIES

      // END_USER_CODE-USER_col211Concordia_PROPERTIES
    },
    col211GPC: {
      name: "col211GPC",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "211 GPC",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col211GPC_PROPERTIES

      // END_USER_CODE-USER_col211GPC_PROPERTIES
    },
    colCounty: {
      name: "colCounty",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "County",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCounty_PROPERTIES

      // END_USER_CODE-USER_colCounty_PROPERTIES
    },
    colFarmName: {
      name: "colFarmName",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "Farm Name",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFarmName_PROPERTIES

      // END_USER_CODE-USER_colFarmName_PROPERTIES
    },
    colFarmNum: {
      name: "colFarmNum",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "Farm #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFarmNum_PROPERTIES

      // END_USER_CODE-USER_colFarmNum_PROPERTIES
    },
    colIrrDry: {
      name: "colIrrDry",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "Irr/Dry",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colIrrDry_PROPERTIES

      // END_USER_CODE-USER_colIrrDry_PROPERTIES
    },
    colLocation: {
      name: "colLocation",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "Location",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLocation_PROPERTIES

      // END_USER_CODE-USER_colLocation_PROPERTIES
    },
    colRemitNum: {
      name: "colRemitNum",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "Remit #:",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRemitNum_PROPERTIES

      // END_USER_CODE-USER_colRemitNum_PROPERTIES
    },
    colSharePct: {
      name: "colSharePct",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "Share %",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSharePct_PROPERTIES

      // END_USER_CODE-USER_colSharePct_PROPERTIES
    },
    colState: {
      name: "colState",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "State",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colState_PROPERTIES

      // END_USER_CODE-USER_colState_PROPERTIES
    },
    colVendorName: {
      name: "colVendorName",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "Vendor Name",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendorName_PROPERTIES

      // END_USER_CODE-USER_colVendorName_PROPERTIES
    },
    colVendorNum: {
      name: "colVendorNum",
      type: "GridColumnWidget",
      parent: "gridFarmList",
      Label: "Vendor #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendorNum_PROPERTIES

      // END_USER_CODE-USER_colVendorNum_PROPERTIES
    },
    txtcol211Concordia: {
      name: "txtcol211Concordia",
      type: "TextBoxWidget",
      colName: "col211Concordia",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol211Concordia_PROPERTIES

      // END_USER_CODE-USER_txtcol211Concordia_PROPERTIES
    },
    txtcol211GPC: {
      name: "txtcol211GPC",
      type: "TextBoxWidget",
      colName: "col211GPC",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol211GPC_PROPERTIES

      // END_USER_CODE-USER_txtcol211GPC_PROPERTIES
    },
    txtcolCounty: {
      name: "txtcolCounty",
      type: "TextBoxWidget",
      colName: "colCounty",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCounty_PROPERTIES

      // END_USER_CODE-USER_txtcolCounty_PROPERTIES
    },
    txtcolFarmName: {
      name: "txtcolFarmName",
      type: "TextBoxWidget",
      colName: "colFarmName",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFarmName_PROPERTIES

      // END_USER_CODE-USER_txtcolFarmName_PROPERTIES
    },
    txtcolFarmNum: {
      name: "txtcolFarmNum",
      type: "TextBoxWidget",
      colName: "colFarmNum",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFarmNum_PROPERTIES

      // END_USER_CODE-USER_txtcolFarmNum_PROPERTIES
    },
    txtcolIrrDry: {
      name: "txtcolIrrDry",
      type: "TextBoxWidget",
      colName: "colIrrDry",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolIrrDry_PROPERTIES

      // END_USER_CODE-USER_txtcolIrrDry_PROPERTIES
    },
    txtcolLocation: {
      name: "txtcolLocation",
      type: "TextBoxWidget",
      colName: "colLocation",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLocation_PROPERTIES

      // END_USER_CODE-USER_txtcolLocation_PROPERTIES
    },
    txtcolRemitNum: {
      name: "txtcolRemitNum",
      type: "TextBoxWidget",
      colName: "colRemitNum",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRemitNum_PROPERTIES

      // END_USER_CODE-USER_txtcolRemitNum_PROPERTIES
    },
    txtcolSharePct: {
      name: "txtcolSharePct",
      type: "TextBoxWidget",
      colName: "colSharePct",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSharePct_PROPERTIES

      // END_USER_CODE-USER_txtcolSharePct_PROPERTIES
    },
    txtcolState: {
      name: "txtcolState",
      type: "TextBoxWidget",
      colName: "colState",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolState_PROPERTIES

      // END_USER_CODE-USER_txtcolState_PROPERTIES
    },
    txtcolVendorName: {
      name: "txtcolVendorName",
      type: "TextBoxWidget",
      colName: "colVendorName",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendorName_PROPERTIES

      // END_USER_CODE-USER_txtcolVendorName_PROPERTIES
    },
    txtcolVendorNum: {
      name: "txtcolVendorNum",
      type: "TextBoxWidget",
      colName: "colVendorNum",
      parent: "gridFarmList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendorNum_PROPERTIES

      // END_USER_CODE-USER_txtcolVendorNum_PROPERTIES
    },
    gridFarmList: {
      name: "gridFarmList",
      type: "GridWidget",
      parent: "FarmInquiry",
      gridCellsOrder:
        "txtcolLocation,txtcolState,txtcolCounty,txtcolFarmNum,txtcolFarmName,txtcolVendorNum,txtcolRemitNum,txtcolVendorName,txtcolSharePct,txtcol211GPC,txtcol211Concordia,txtcolIrrDry",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridFarmList_PROPERTIES
      isExpandable: true,
      // defaultExpand: false,
      isMultiSelect: false,
      defaultExpand: false,
      showExpander:true,
      needGridChange: true,//Change for Grid change
      expandedCompTemplate: ({ data }) => (
        // <pre>{JSON.stringify(data, null, 2)}</pre>
        <div>
          {data.expArray ? (
            <div className="py-3 expandable-area col-sm-12 rdt_TableRow">
              {data.expArray.map((elem, i) =>
                <div className="py-3 expandable-area col-sm-12 rdt_TableRow selectable" key={"Row" + i}>
                  <div className={"row" + " rdt_ExpanderRow"} key={"ExpanderRow" + i} >
                  <div className="extraRowCell_1 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_2 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_3 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_4 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_5 ch-3" key="expandCell1">
                    </div>
                    <div className="extraRowCell_6 ch-3" key="expandCell1">
                      {elem.split_vendor}
                    </div>
                    <div className="extraRowCell_7 ch-3" key="expandCell2">
                      {elem.split_remit}
                    </div>
                    <div className="extraRowCell_8 ch-3" key="expandCell3">
                      {elem.vendor_name}
                    </div>
                    <div className="extraRowCell_9 ch-3" key="expandCell4">
                    {elem.split_share_pct}
                    </div>
                    <div className="extraRowCell_10 ch-3" key="expandCell5">
                      {elem.form211_gpc}
                    </div>
                    <div className="extraRowCell_11 ch-3" key="expandCell6">
                      {elem.form211_con}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      )
      // END_USER_CODE-USER_gridFarmList_PROPERTIES
    },
    lblFarmList: {
      name: "lblFarmList",
      type: "LabelWidget",
      parent: "grpbxFarmList",
      Label: "Farm List:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFarmList_PROPERTIES

      // END_USER_CODE-USER_lblFarmList_PROPERTIES
    },
    txtVendorName: {
      name: "txtVendorName",
      type: "TextBoxWidget",
      parent: "grpbxFarmDetails",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES
      ReasOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    radioAsShownExpandedCollapsed: {
      name: "radioAsShownExpandedCollapsed",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxActions",
      Options: "As Shown:1,Expanded:2,Collapsed:3",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioAsShownExpandedCollapsed_PROPERTIES

      // END_USER_CODE-USER_radioAsShownExpandedCollapsed_PROPERTIES
    },
    radioExpandCollapseGrid: {
      name: "radioExpandCollapseGrid",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxFarmList",
      Options: "Expand Grid:1,Collapse Grid:2",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioExpandCollapseGrid_PROPERTIES

      // END_USER_CODE-USER_radioExpandCollapseGrid_PROPERTIES
    },
    radioSortBy: {
      name: "radioSortBy",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxFarmDetails",
      Options: "Farm Number:1,Location:2,Farm Name:3,Vendor and Share %:4",
      Label: "Sort By:",
      ColSpan: "2",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioSortBy_PROPERTIES

      // END_USER_CODE-USER_radioSortBy_PROPERTIES
    },
   
    txtBuyingPointID: {
      name: "txtBuyingPointID",
      type: "TextBoxWidget",
      parent: "grpbxFarmDetails",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyingPointID_PROPERTIES

      // END_USER_CODE-USER_txtBuyingPointID_PROPERTIES
    },
    txtCountyID: {
      name: "txtCountyID",
      type: "TextBoxWidget",
      parent: "grpbxFarmDetails",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCountyID_PROPERTIES

      // END_USER_CODE-USER_txtCountyID_PROPERTIES
    },
    txtFarmID: {
      name: "txtFarmID",
      type: "TextBoxWidget",
      parent: "grpbxFarmDetails",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarmID_PROPERTIES

      // END_USER_CODE-USER_txtFarmID_PROPERTIES
    },
    txtNameSearch: {
      name: "txtNameSearch",
      type: "TextBoxWidget",
      parent: "grpbxFarmDetails",
      Label: "Name Search:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNameSearch_PROPERTIES

      // END_USER_CODE-USER_txtNameSearch_PROPERTIES
    },
    txtStateID: {
      name: "txtStateID",
      type: "TextBoxWidget",
      parent: "grpbxFarmDetails",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStateID_PROPERTIES

      // END_USER_CODE-USER_txtStateID_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxFarmDetails",
      Label: "Vendor:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    grpbxFarmDetails: {
      name: "grpbxFarmDetails",
      type: "GroupBoxWidget",
      parent: "FarmInquiry",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxFarmDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxFarmDetails_PROPERTIES
    },
    grpbxFarmList: {
      name: "grpbxFarmList",
      type: "GroupBoxWidget",
      parent: "FarmInquiry",
      Height: "",
      Width: "",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_grpbxFarmList_PROPERTIES

      // END_USER_CODE-USER_grpbxFarmList_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "FarmInquiry",
      Height: "",
      Width: "",
      ColsForTabLandscape: "8",
      HasLabel: false,
      Cols: "8",
      ColsForTabPotrait: "8",
      ColsForLargeDesktop: "8",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceMasterManagement#FarmProfile": {},
        "ContractManagement#MainMenu": {},
        "SystemMaintenanceMasterManagement#FarmGovData": {},
        "ContractManagement#VendorLookup": {},
      },
      REVERSE: {
        "SystemMaintenanceMasterManagement#FarmProfile": {},
        "ContractManagement#MainMenu": {},
        "SystemMaintenanceMasterManagement#FarmGovData": {},
        "ContractManagement#VendorLookup": {},
      }
    }
  
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnAdd: {
    //   DEFAULT: ["SystemMaintenanceMasterManagement#FarmProfile#DEFAULT#true#Click"],
    // },
    btnClose: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    },
    // btnFarmDataForeNUTSImport: {
    //   DEFAULT: ["SystemMaintenanceMasterManagement#FarmGovData#DEFAULT#true#Click"],
    // },
    // btnFarmDataForAccess: {
    //   DEFAULT: ["SystemMaintenanceMasterManagement#FarmGovData#DEFAULT#true#Click"],
    // },
    // btnCountyDataForeNUTSImport: {
    //   DEFAULT: ["SystemMaintenanceMasterManagement#FarmGovData#DEFAULT#true#Click"],
    // },
    btnVendor: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
    // btnEdit: {
    //   DEFAULT: ["SystemMaintenanceMasterManagement#FarmProfile#DEFAULT#true#Click"],
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });

  useEffect(() => {
    let vendor_lookup = getData(thisObj, 'vendorDetails')
    if (vendor_lookup !== null) {
      thisObj.setFieldValue("txtVendor", vendor_lookup.VendorNumber);
      bFillRemitToList(vendor_lookup.VendorNumber)
      thisObj.setFieldValue("txtVendorName", vendor_lookup.vendorName)
      setData(thisObj,'vendorDetails',null)
    }
  }, [getData(thisObj, 'vendorDetails')]);

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    setValue(thisObj, "ddBuyingPoint", ''),
      FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  const[loading,setLoading] = useState(false)

  // START_USER_CODE-USER_METHODS
  function FormLoad() {
    setLoading(true)
    document.getElementsByClassName("txtVendorName")[0].style.visibility = "hidden";
    setValue(thisObj,'txtVendor','')
    setValue(thisObj,'txtVendorName','')
    hide(thisObj, "btnFarmDataForAccess");
    setValue(thisObj, "radioSortBy",'1')
    setValue(thisObj,'radioExpandCollapseGrid','2')
    bFillBuyingPointList();// Method for binding Buying Points
    if ((txtVendor.value) != null && (txtVendor.value) != '' && (txtVendor.value) != undefined) {
      ontxtVendorBlur()
    }
    bFillSateList(null, null, true);//Method for states dropdown

    let js = [];
    let obj = {};
    obj = ({ key: '', description: '>>> All Counties <<<' });
    js.push(obj);
    thisObj.setState(current => {
      return {
        ...current,
        ddCounty: {
          ...state["ddCounty"],
          valueList: js,
        }
      }
    })
    setValue(thisObj, "ddCounty", '')

    let js1 = [];
    let obj1 = {};
    obj1 = ({ key: '', description: '>>> All Farms <<<' });
    js1.push(obj1);
    thisObj.setState(current => {
      return {
        ...current,
        ddFarm: {
          ...state["ddFarm"],
          valueList: js1,
        }
      }
    })
    setValue(thisObj, "ddFarm", '')
    setValue(thisObj,'radioAsShownExpandedCollapsed','1')
    setLoading(false)
  }

  
  const sortBy = () => {
    if (getValue(thisObj, "radioSortBy") == '1') {
      return 'FARM';
    }

    else if (getValue(thisObj, "radioSortBy") == '2') {
      return 'LOCATION';
    }

    else if (getValue(thisObj, "radioSortBy") == '3') {
      return 'NAME';
    }

    else if (getValue(thisObj, "radioSortBy") == '4') {
      return 'VENDOR';
    }

  }


  // Method for binding Buying Points
  const bFillBuyingPointList = () => {
    let js = []
    js.push({ key: '', description: '>>>All Buying Points<<<' });
    ContractManagementService.RetrieveBuyingPointControlDetails('PN0030', null, null, null, null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddBuyingPoint: {
            ...state["ddBuyingPoint"],
            valueList: js
          }
        }
      })

    })
  };

  //Buying Point dropdown change event
  const onBuyingPointChange = () => {
    try {
      let js = [];
      let obj = {};
      obj = ({ key: '', description: '>>> All Counties <<<' });
      js.push(obj);
      thisObj.setState(current => {
        return {
          ...current,
          ddCounty: {
            ...state["ddCounty"],
            valueList: js,
          }
        }
      })
      setValue(thisObj, "ddCounty", '')

      let js1 = [];
      let obj1 = {};
      obj1 = ({ key: '', description: '>>> All Farms <<<' });
      js1.push(obj1);
      thisObj.setState(current => {
        return {
          ...current,
          ddFarm: {
            ...state["ddFarm"],
            valueList: js1,
          }
        }
      })
      setValue(thisObj, "ddFarm", '')


      let BuyingPointList = thisObj.state['ddBuyingPoint'].valueList;
      let SelectedBuyingPointValue = thisObj.values['ddBuyingPoint'];
      let buyingPointId = null
      if (SelectedBuyingPointValue !== "" && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }

      let StateList = thisObj.state['ddState'].valueList;
      let SelectedStateValue = thisObj.values['ddState'];

      let StateAbrr = null
      if (SelectedStateValue !== "" && SelectedStateValue !== undefined) {
        StateAbrr = StateList.find(elem => elem.key === SelectedStateValue).stateAbbrevations;
      }

      bFillSateList(buyingPointId, StateAbrr, true)

    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Buying Point dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddBuyingPointChange = onBuyingPointChange;

  //Method for State Change Event
  const onddStateChange = () => {
    try {

      let js = [];
      let obj = {};
      obj = ({ key: '', description: '>>> All Counties <<<' });
      js.push(obj);
      thisObj.setState(current => {
        return {
          ...current,
          ddCounty: {
            ...state["ddCounty"],
            valueList: js,
          }
        }
      })
      setValue(thisObj, "ddCounty", '')

      let js1 = [];
      let obj1 = {};
      obj1 = ({ key: '', description: '>>> All Farms <<<' });
      js1.push(obj1);
      thisObj.setState(current => {
        return {
          ...current,
          ddFarm: {
            ...state["ddFarm"],
            valueList: js1,
          }
        }
      })
      setValue(thisObj, "ddFarm", '')

      let BuyingPointList = thisObj.state['ddBuyingPoint'].valueList;
      let SelectedBuyingPointValue = thisObj.values['ddBuyingPoint'];
      let buyingPointId = null
      if (SelectedBuyingPointValue !== "" && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }

      let StateList = thisObj.state['ddState'].valueList;
      let SelectedStateValue = thisObj.values['ddState'];

      let StateAbrr = null
      if (SelectedStateValue !== "" && SelectedStateValue !== undefined) {
        StateAbrr = StateList.find(elem => elem.key === SelectedStateValue).stateAbbrevations;
      }

      if (StateAbrr !== '' && StateAbrr !== null) {
        if ((getValue(thisObj, 'ddState')) !== null && (getValue(thisObj, 'ddState')) !== '') {
          bFillCounty(buyingPointId, 'YES', StateAbrr, null)
        }

      }

    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during State dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddStateChange = onddStateChange;

  //Method for County Change

  const onddCountyChange = () => {
    try {
      let BuyingPointList = thisObj.state['ddBuyingPoint'].valueList;
      let SelectedBuyingPointValue = thisObj.values['ddBuyingPoint'];
      let buyingPointId = null
      if (SelectedBuyingPointValue !== "" && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }

      let StateList = thisObj.state['ddState'].valueList;
      let SelectedStateValue = thisObj.values['ddState'];

      let StateAbrr = null
      if (SelectedStateValue !== "" && SelectedStateValue !== undefined) {
        StateAbrr = StateList.find(elem => elem.key === SelectedStateValue).stateAbbrevations;
      }

      let CountyList = thisObj.state['ddCounty'].valueList;
      let SelectedCountyValue = thisObj.values['ddCounty'];

      let CountyId = null
      if (SelectedCountyValue !== "" && SelectedCountyValue !== undefined) {
        CountyId = CountyList.find(elem => elem.key === SelectedCountyValue).CountyId;
      }

      if (CountyId !== null) {
        bFillFarmlist(buyingPointId, StateAbrr, CountyId, null, null)
      }

    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during County dropdown change event"
        );
      }
      return false;
    }
    return true
  }
  thisObj.onddCountyChange = onddCountyChange;

  //Method for Vendor Name after giving the Vendor name
  const ontxtVendorBlur = () => {
    try {
      let txtVendorNumber = getValue(thisObj,'txtVendor')

      if (txtVendorNumber.length == 6) {
        bFillRemitToList(txtVendorNumber)
      }
      else {
        setValue(thisObj,'txtVendorName','')
        document.getElementsByClassName("txtVendorName")[0].style.visibility = "hidden";
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Vendor number change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtVendorBlur = ontxtVendorBlur;

  //seaching vendorname based on Vendor
  const bFillRemitToList = async(txtVendorNumber) => {
    try{
    let venname = null
    let vendornamevisible = false
    let response=await ContractManagementService.RetrieveVendorByNumber(txtVendorNumber)
      let data = response
      if (data !== null && data !== undefined && data.length>0) {
        venname = data[0].name
        vendornamevisible = true
      }

      if (vendornamevisible == true) {
        // show(thisObj,'txtVendorName')
        document.getElementsByClassName("txtVendorName")[0].style.visibility = "visible";
        setValue(thisObj, "txtVendorName", venname)
      }
      else {
        document.getElementsByClassName("txtVendorName")[0].style.visibility = "hidden";
        setValue(thisObj, "txtVendorName", '')
      }
    }
    catch(err){
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred in setting value to Vendor Number textbox"
        );
      }
    }
    };

  //Method for States deopdown
  const bFillSateList = async (buyingPointId, stateAbbrevations, filterStatus) => {
    try {
      let js = [];
      let obj = {};
      obj = ({ key: '', description: '>>> All States <<<' });
      js.push(obj);
      if (!filterStatus) {
        buyingPointId = null
      }
      let response = await ContractManagementService.RetrievePeanutStateControlDetails(buyingPointId, stateAbbrevations, filterStatus)
      let data = response
      if (data !== null && data !== undefined && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          obj = { key: data[i].stateId, description: data[i].stateName, stateAbbrevations: data[i].stateAbbr }

          js.push(obj)
          obj = {}
        }

      }
      thisObj.setState(current => {
        return {
          ...current,
          ddState: {
            ...state["ddState"],
            valueList: js,
          }
        }
      })
      setValue(thisObj, 'ddState', js.at(0).key);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during loading State List"
        );
      }

    }

  };

  //Method for binding County list
  const bFillCounty = async (buyingPointId, filterStatus, stateAbbrevations, countyId) => {
    try {

      let js = [];
      let obj = {};
      obj = ({ key: '', description: '>>> All Counties <<<' });
      js.push(obj);

      if ((getValue(thisObj, 'ddState')) !== null && (getValue(thisObj, 'ddState')) !== "") {
        let response = await ContractManagementService.RetrieveCountyControlDetails(buyingPointId, filterStatus, stateAbbrevations, countyId)
        let data = response
        if (data !== null && data !== undefined && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            obj = { key: data[i].countyName + data[i].countyId, description: data[i].countyName, CountyId: data[i].countyId }
            js.push(obj)
            obj = {}
          }
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddCounty: {
            ...state["ddCounty"],
            valueList: js,
          }
        }
      })
      setValue(thisObj, 'ddCounty', js.at(0).key);

    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during loading Counties event"
        );
      }

    }
  };

  //Method for Farm list
  const bFillFarmlist = async (buy_pt_id, stateAbbrevations, countyId, farmId, farmSuffix) => {
    try {
      let js = [];
      let obj = {};
      obj = ({ key: '', description: ">>> All Farms <<<" })
      js.push(obj);

      let response = await SystemMaintenanceMasterManagementService.RetrieveFarmControlDetails(buy_pt_id, stateAbbrevations, countyId, farmId, farmSuffix)
      let data = response

      if (data !== null && data !== undefined && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          obj = { key: data[i].farmId, description: data[i].farmId + " - " + data[i].farmSuffix + " - " + data[i].farmName }
          js.push(obj)
          obj = {}
        }
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddFarm: {
            ...state["ddFarm"],
            valueList: js,
          }
        }
      })
      setValue(thisObj, 'ddFarm', js.at(0).key);
    }

    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during loading Farm List"
        );
      }
    }
  };

  //expanding and collapsing grid
  const onradioExpandCollapseGridChange = () => {
    try {
      let currentstatus = thisObj.values.radioExpandCollapseGrid

      if (currentstatus == '1') {
        thisObj.setState(current => {
          return {
            ...current,
            gridFarmList: {
              ...thisObj.state["gridFarmList"],
              defaultExpand: true
            }
          }
        })
      }
      else if (currentstatus == '2') {
        thisObj.setState(current => {
          return {
            ...current,
            gridFarmList: {
              ...thisObj.state["gridFarmList"],
              defaultExpand: false
            }
          }
        })
      }

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "A Farm must be selected to view. \n\n No Contract Selected")
      }
      return false;
    }
    return true;
  }
  thisObj.onradioExpandCollapseGridChange = onradioExpandCollapseGridChange;

  //Method for Farm Control search details
  const onbtnSearchClick = async() => {
    try {
      setLoading(true)
      let func_id = 'PN0030'
      let func_sub_id = null;
      let buy_pt_id = thisObj.values['ddBuyingPoint'];

      let StateList = thisObj.state['ddState'].valueList;

      let SelectedStateValue = thisObj.values['ddState'];
      let StateAbrr = null
      if (SelectedStateValue !== "" && SelectedStateValue !== undefined) {
        StateAbrr = StateList.find(elem => elem.key === SelectedStateValue).stateAbbrevations;
      }
      let State_abbr = StateAbrr;


      let countyList = thisObj.state['ddCounty'].valueList
      let SelectedCountyValue = thisObj.values['ddCounty'];
      let county_id = null
      if (SelectedCountyValue !== "" && SelectedCountyValue !== undefined) {
        county_id = countyList.find(elem => elem.key === SelectedCountyValue).CountyId;
      }

      let farmList = thisObj.state['ddFarm'].valueList
      let SelectedFarmValue = thisObj.values['ddFarm'];
      let farm_id = null
      if (SelectedFarmValue !== "" && SelectedFarmValue !== undefined) {
        farm_id = farmList.find(elem => elem.key === SelectedFarmValue).key;
      }


      let farm_suffix = null
      let split_vendor = thisObj.values['txtVendor']

      let search_name = thisObj.values['txtNameSearch']

      let orderby = sortBy();

      if ((split_vendor == null || split_vendor == undefined || split_vendor == "") &&
        (search_name == null || search_name == undefined || search_name == "") &&
        (buy_pt_id == null || buy_pt_id == undefined || buy_pt_id == "")) {
        showMessage(thisObj, "Buying Point or Vendor Number or a Search Name is required!")
      }

      else {
        let response=await SystemMaintenanceMasterManagementService.RetrieveFarmControlSearchDetails(func_id, func_sub_id, buy_pt_id, State_abbr,
          county_id, farm_id, farm_suffix, split_vendor, search_name, orderby)
          
            let TransformedRowsArray = [];

            if (response == undefined || response == '') {
              showMessage(thisObj, 'No Farms Found!!!');
              setValue(thisObj, "gridFarmList", []);
              setLoading(false)
              return;
            }
            if (response != undefined && response.length > 0) {
              let data = response;

              let obj = {};
              for (let i = 0; i < data.length; i++) {
                obj.txtcolLocation = data[i].buy_pt_id + '-' + data[i].buy_pt_name;
                obj.txtcolState = data[i].state_abbr;
                obj.txtcolCounty = data[i].county_id;
                obj.txtcolFarmNum = data[i].farm_id + '-' + data[i].farm_suffix;
                obj.txtcolFarmName = data[i].farm_name;

                let vendorArray=[];
                for(let j=1;j<data[i].vendors.length;j++){
                  vendorArray.push(data[i].vendors[j])
                }
                if (data[i].vendors.length > 1) {
                  obj.expArray=vendorArray
              }

                obj.txtcolVendorNum = data[i].vendors[0].split_vendor;
                obj.txtcolRemitNum = data[i].vendors[0].split_remit;
                obj.txtcolVendorName = data[i].vendors[0].vendor_name;
                obj.txtcolSharePct = data[i].vendors[0].split_share_pct;
                obj.txtcol211GPC = data[i].vendors[0].form211_gpc;
                obj.txtcol211Concordia = data[i].vendors[0].form211_con

                switch (data[i].irr_dry_default) {
                  case 'U':
                    obj.txtcolIrrDry = 'UNKNOWN'
                    break;

                  case 'I':
                    obj.txtcolIrrDry = 'IRRIGATED'
                    break;

                  case 'D':
                    obj.txtcolIrrDry = 'DRYLAND'
                    break;

                  default:
                    obj.txtcolIrrDry = 'UNKNOWN'
                }

                TransformedRowsArray.push(obj)

                obj = {}
              }

              setValue(thisObj, "gridFarmList", TransformedRowsArray);
            }
      }
      setLoading(false)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during loading Search button result"
        );
      }
    }
  };
  thisObj.onbtnSearchClick = onbtnSearchClick;

  // Form data for E-nuts button click event
  
  const onbtnFarmDataForeNUTSImportClick = () => {
    setData(thisObj,'FarmInquiryData', 'FarmData')
    goTo(thisObj, "SystemMaintenanceMasterManagement#FarmGovData#DEFAULT#true#Click")
  }
  thisObj.onbtnFarmDataForeNUTSImportClick = onbtnFarmDataForeNUTSImportClick;

  // County data for E-nuts button click event

  const onbtnCountyDataForeNUTSImportClick = () => {
    setData(thisObj, 'FarmInquiryData','CountyData')
    goTo(thisObj, "SystemMaintenanceMasterManagement#FarmGovData#DEFAULT#true#Click")
  }
  thisObj.onbtnCountyDataForeNUTSImportClick = onbtnCountyDataForeNUTSImportClick;

  //Method for Add button
  const onbtnAddClick = () => {
    try {
      let obj = {}
      obj.btnOkCaption = 'Add'
      obj.buyingPointEnabled = 'True'
      obj.StateEnabled = 'True'
      obj.CountyEnabled = 'True'
      obj.TxtIdEnabled = 'True'
      obj.TxtSuffixEnabled = 'True'

      let js = [];
      js.push(obj);
      setData(thisObj, 'CreateValue', js)
      commonContext1.setFarmInquiry_InquiryEdit('')
      goTo(thisObj, "SystemMaintenanceMasterManagement#FarmProfile#DEFAULT#true#Click");
    }

    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during Add button"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnAddClick = onbtnAddClick;


  //Method for Edit button
  const onbtnEditClick = async () => {
    try {
      let gridValue = thisObj.state.gridFarmList.selected[0];
      if(gridValue==undefined){
        showMessage(thisObj, 'Please select a row to Edit');
        return;
      }
        let state_abbr = thisObj.state.gridFarmList.selected[0].txtcolState
        let buy_pt_id = thisObj.state.gridFarmList.selected[0].txtcolLocation;
        buy_pt_id = buy_pt_id.slice(0, 3);

        let county_id = thisObj.state.gridFarmList.selected[0].txtcolCounty;
        let farm_id = thisObj.state.gridFarmList.selected[0].txtcolFarmNum;
        farm_id = farm_id.slice(0, 7);

        let farm_suffix = thisObj.state.gridFarmList.selected[0].txtcolFarmNum;
        farm_suffix = farm_suffix.slice(8, 11);
        // let IrrDry = thisObj.state.gridFarmList.selected[0].txtcolIrrDry;

        let response = await SystemMaintenanceMasterManagementService.RetrieveFarmControlDetails(buy_pt_id, state_abbr, county_id, farm_id, farm_suffix)
        let data = response;
        let obj = {};
        let editBtnData = [];

        if (data != undefined && data.length > 0) {
          obj.farm_name = data[0].farmName;
          obj.farm_sort_name = data[0].farmSortName;
          obj.add_user = data[0].addUser + " " + moment(data[0].addDateTime).format("M/D/YYYY")
          obj.chg_user = data[0].chgUser + " " + moment(data[0].chgDateTime).format("M/D/YYYY")
          obj.farm_address = data[0].farmAddress
          obj.farm_city = data[0].farmCity
          obj.farm_zip = data[0].farmZip
          obj.farm_phone = data[0].farmPhone
          obj.farm_acres = data[0].farmAcres
          obj.IrrDry = data[0].irrDryDefault
        }
        obj.buyingPointEnabled = false;
        obj.StateEnabled = false;
        obj.CountyEnabled = false;
        obj.txtIdEnabled = false;
        obj.TxtSuffixEnabled = false;
        obj.buyingpoint = buy_pt_id
        obj.stateAbbrv = state_abbr
        obj.county = county_id
        obj.farm_id = farm_id
        obj.farm_suffix=farm_suffix
        editBtnData.push(obj);


        commonContext1.setFarmInquiry_InquiryEdit(editBtnData)
        setData(thisObj, 'CreateValue', "")
        goTo(thisObj, "SystemMaintenanceMasterManagement#FarmProfile#DEFAULT#true#Clic");
      
      
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during Edit button result"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnEditClick = onbtnEditClick;

  //Method for Delete Button
  const onbtnDeleteClick = async () => {
    try {

      let buy_pt_id = thisObj.state.gridFarmList.selected[0];
      if(buy_pt_id==undefined){
        showMessage(thisObj, 'Please select a row to delete');
        return;
      }
      buy_pt_id=buy_pt_id.txtcolLocation
      let state_abbr = thisObj.state.gridFarmList.selected[0].txtcolState;
      let county_id = thisObj.state.gridFarmList.selected[0].txtcolCounty;
      let farm_id = thisObj.state.gridFarmList.selected[0].txtcolFarmNum;
      let farm_id_1 = farm_id.slice(0, 7);

      let farm_suffix = thisObj.state.gridFarmList.selected[0].txtcolFarmNum;
      farm_suffix = farm_suffix.slice(8, 11);

      buy_pt_id = buy_pt_id.slice(0, 3); 

        const confirmBox = window.confirm(
          `Are you sure you wish to delete this Farm '${farm_id}'`
        )
        if (confirmBox == true) {
          let response = await SystemMaintenanceMasterManagementService.RemoveFarmControl(buy_pt_id, state_abbr, county_id, farm_id_1, farm_suffix);
          if (response.status == '400') {
            showMessage(thisObj, 'There are existing inspections for this farm id.  Cannot delete farm.');
          }
          else if(response.status=='200'){
            showMessage(thisObj, response.message,true);
            onbtnSearchClick();
          }
          else {
            showMessage(thisObj, response.message);
          }

        }
        else {
          return ;
        }
      
    }

    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during executing delete button"
        );
      }
    }
  };
  thisObj.onbtnDeleteClick = onbtnDeleteClick;

  //Method for Export to Excel Method
  const onbtnExportToExcelClick = () => {
    try {

      let status1 = thisObj.values.radioAsShownExpandedCollapsed
      if (status1 == '1') {
        let mainstatus = thisObj.values.radioExpandCollapseGrid
        if (mainstatus == '1') { status1 = '2' }
        if (mainstatus == '2') { status1 = '3' }
      }
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      let excelData = getValue(thisObj, "gridFarmList")

      let fileName = "Farm inquiry Search"
      const arrayData = []
      for (let i = 0; i < excelData.length; i++) {
        let obj={};
        if(thisObj.state.gridFarmList.columns[0].Visible){
          obj['Location'] = excelData[i].txtcolLocation
        }
        if(thisObj.state.gridFarmList.columns[1].Visible){
          obj['State'] = excelData[i].txtcolState
        }
        if(thisObj.state.gridFarmList.columns[2].Visible){
          obj['County'] = excelData[i].txtcolCounty
        }
        if(thisObj.state.gridFarmList.columns[3].Visible){
          obj['Farm #'] = excelData[i].txtcolFarmNum
        }
        if(thisObj.state.gridFarmList.columns[4].Visible){
          obj['Farm Name'] = excelData[i].txtcolFarmName
        }
        if(thisObj.state.gridFarmList.columns[5].Visible){
          obj['Vendor #'] = excelData[i].txtcolVendorNum
        }
        if(thisObj.state.gridFarmList.columns[6].Visible){
          obj['Remit #'] = excelData[i].txtcolRemitNum
        }
        if(thisObj.state.gridFarmList.columns[7].Visible){
          obj['Vendor Name'] = excelData[i].txtcolVendorName
        }
        if(thisObj.state.gridFarmList.columns[8].Visible){
          obj['Share%'] = excelData[i].txtcolSharePct
        }
        if(thisObj.state.gridFarmList.columns[9].Visible){
          obj['211 GPC'] = excelData[i].txtcol211GPC
        }
        if(thisObj.state.gridFarmList.columns[10].Visible){
          obj['211 Concordia'] = excelData[i].txtcol211Concordia
        }
        if(thisObj.state.gridFarmList.columns[11].Visible){
          obj['Irr/Dry'] = excelData[i].txtcolIrrDry
        }
        // let obj = {
        //   "Location": excelData[i].txtcolLocation,
        //   "State": excelData[i].txtcolState,
        //   "County": excelData[i].txtcolCounty,
        //   "Farm #": excelData[i].txtcolFarmNum,
        //   "Farm Name": excelData[i].txtcolFarmName,
        //   "Vendor #": excelData[i].txtcolVendorNum,
        //   "Remit #": excelData[i].txtcolRemitNum,
        //   "Vendor Name": excelData[i].txtcolVendorName,
        //   "Share%": excelData[i].txtcolSharePct,
        //   "211 GPC": excelData[i].txtcol211GPC,
        //   "211 Concordia": excelData[i].txtcol211Concordia,
        //   "Irr/Dry": excelData[i].txtcolIrrDry,

        // }
        arrayData.push(obj);
        if (status1 == '2') {
          let objExp = {};
          let expArrayLength=excelData[i].expArray==undefined?0:excelData[i].expArray.length
          for (let j = 0; j <expArrayLength; j++) {
            // let objExp={};
            if(thisObj.state.gridFarmList.columns[5].Visible){
              objExp['Vendor #'] = excelData[i].expArray[j].split_vendor
            }
            if(thisObj.state.gridFarmList.columns[5].Visible){
              objExp['Remit #'] = excelData[i].expArray[j].split_remit
            }
            if(thisObj.state.gridFarmList.columns[5].Visible){
              objExp['Share%'] = excelData[i].expArray[j].split_share_pct
            }
            if(thisObj.state.gridFarmList.columns[5].Visible){
              objExp['211 GPC'] = excelData[i].expArray[j].form211_gpc
            }
            if(thisObj.state.gridFarmList.columns[5].Visible){
              objExp['211 Concordia'] = excelData[i].expArray[j].form211_con
            }
            // objExp = {
            //   "Vendor #": excelData[i].expArray[j].split_vendor,
            //   "Remit #": excelData[i].expArray[j].split_remit,
            //   "Share%": excelData[i].expArray[j].split_share_pct,
            //   "211 GPC": excelData[i].expArray[j].form211_gpc,
            //   "211 Concordia": excelData[i].expArray[j].form211_con,

            // }
            arrayData.push(objExp)
          }

        }
      }
      const ws = XLSX.utils.json_to_sheet(arrayData)
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      var FileSaver = require('file-saver');
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "error on excel to export button")
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;

  //Method for Print Search button
  const onbtnPrintSearchClick = () => {
    try {
      let status1 = thisObj.values.radioAsShownExpandedCollapsed
      if (status1 == '1') {
        let mainstatus = thisObj.values.radioExpandCollapseGrid
        if (mainstatus == '1') { status1 = '2' }
        if (mainstatus == '2') { status1 = '3' }
      }
      var data = getValue(thisObj, "gridFarmList")

      var res = []
      var headerArray = []
      if (thisObj.state.gridFarmList.columns[0].Visible) {
        headerArray.push("Location");
      }
      if (thisObj.state.gridFarmList.columns[1].Visible) {
        headerArray.push("State");
      }
      if (thisObj.state.gridFarmList.columns[2].Visible) {
        headerArray.push("County");
      }
      if (thisObj.state.gridFarmList.columns[3].Visible) {
        headerArray.push("Farm #");
      }
      if (thisObj.state.gridFarmList.columns[4].Visible) {
        headerArray.push("Farm Name");
      }
      if (thisObj.state.gridFarmList.columns[5].Visible) {
        headerArray.push("Vendor #");
      }
      if (thisObj.state.gridFarmList.columns[6].Visible) {
        headerArray.push("Remit #");
      }
      if (thisObj.state.gridFarmList.columns[7].Visible) {
        headerArray.push("Vendor Name");
      }
      if (thisObj.state.gridFarmList.columns[8].Visible) {
        headerArray.push("Share%'");
      }
      if (thisObj.state.gridFarmList.columns[9].Visible) {
        headerArray.push("211 GPC");
      }
      if (thisObj.state.gridFarmList.columns[10].Visible) {
        headerArray.push("211 Concordia");
      }

      if (thisObj.state.gridFarmList.columns[11].Visible) {
        headerArray.push("Irr/Dry");
      }
      
      for (let i = 0; i < data.length; i++) {

        let NewArray=[];
        if (thisObj.state.gridFarmList.columns[0].Visible) {
          NewArray.push(data[i].txtcolLocation);
        }
        if (thisObj.state.gridFarmList.columns[1].Visible) {
          NewArray.push(data[i].txtcolState);
        }
        if (thisObj.state.gridFarmList.columns[2].Visible) {
          NewArray.push(data[i].txtcolCounty);
        }
        if (thisObj.state.gridFarmList.columns[3].Visible) {
          NewArray.push(data[i].txtcolFarmNum);
        }
        if (thisObj.state.gridFarmList.columns[4].Visible) {
          NewArray.push(data[i].txtcolFarmName);
        }
        if (thisObj.state.gridFarmList.columns[5].Visible) {
          NewArray.push(data[i].txtcolVendorNum);
        }
        if (thisObj.state.gridFarmList.columns[6].Visible) {
          NewArray.push(data[i].txtcolRemitNum);
        }
        if (thisObj.state.gridFarmList.columns[7].Visible) {
          NewArray.push(data[i].txtcolVendorName);
        }
        if (thisObj.state.gridFarmList.columns[8].Visible) {
          NewArray.push(data[i].txtcolSharePct);
        }
        if (thisObj.state.gridFarmList.columns[9].Visible) {
          NewArray.push(data[i].txtcol211GPC);
        }
        if (thisObj.state.gridFarmList.columns[10].Visible) {
          NewArray.push(data[i].txtcol211Concordia);
        }

        if (thisObj.state.gridFarmList.columns[11].Visible) {
          NewArray.push(data[i].txtcolIrrDry);
        }
        // res.push([
        //   data[i].txtcolLocation,
        //   data[i].txtcolState,
        //   data[i].txtcolCounty,
        //   data[i].txtcolFarmNum,
        //   data[i].txtcolFarmName,
        //   data[i].txtcolVendorNum,
        //   data[i].txtcolRemitNum,
        //   data[i].txtcolVendorName,
        //   data[i].txtcolSharePct,
        //   data[i].txtcol211GPC,
        //   data[i].txtcol211Concordia,
        //   data[i].txtcolIrrDry,
        // ])
        res.push(NewArray);

        if (status1 == '2') {
          if (data[i].expArray !== undefined) {
            if (data[i].expArray.length > 1) {
              for (let j = 0; j < data[i].expArray.length; j++) {
                res.push([
                  data[i].expArray[j].split_vendor,
                  data[i].expArray[j].split_remit,
                  data[i].expArray[j].split_share_pct,
                  data[i].expArray[j].form211_gpc,
                  data[i].expArray[j].form211_con
                ])
              }
            }
          }
        }
      }

      var obj = new jsPDF();
      obj.autoTable({
        startY: 5,
        startX: 20,
        styles: { fontSize: 7, lineWidth: 0.2, color: 10 },
        body: res,
        theme: 'plain',
        fontStyle: 'normal',
        head: [headerArray]
      });

      var pageCount = obj.internal.getNumberOfPages();
      for (let w = 0; w <= pageCount; w++) {
        obj.setPage(w);
        let pageCurrent = obj.internal.getCurrentPageInfo().pageNumber; //Current Page
        obj.setFontSize(8);
       obj.text(cropYear+ ' CONTRACT SEARCH GRID', 2, obj.internal.pageSize.height - 2);
      }

      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj.save(fileName + '.pdf')
        }
        else {
          alert("Routine:FarmInquiry.cmdPrintSearch_Click")
        }
      }
      else {
        alert("Routine:FarmInquiry.cmdPrintSearch_Click")
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnPrintscreen event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnPrintSearchClick = onbtnPrintSearchClick


  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_FarmInquiry*/}

              {/* END_USER_CODE-USER_BEFORE_FarmInquiry*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxFarmDetails*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxFarmDetails*/}

              <GroupBoxWidget
                conf={state.grpbxFarmDetails}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddBuyingPoint}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_ddBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_txtBuyingPointID*/}

                {/* END_USER_CODE-USER_BEFORE_txtBuyingPointID*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtBuyingPointID}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtBuyingPointID*/}

                {/* END_USER_CODE-USER_AFTER_txtBuyingPointID*/}
                {/* START_USER_CODE-USER_BEFORE_ddState*/}

                {/* END_USER_CODE-USER_BEFORE_ddState*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddState}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddState*/}

                {/* END_USER_CODE-USER_AFTER_ddState*/}
                {/* START_USER_CODE-USER_BEFORE_txtStateID*/}

                {/* END_USER_CODE-USER_BEFORE_txtStateID*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtStateID}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtStateID*/}

                {/* END_USER_CODE-USER_AFTER_txtStateID*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                <ButtonWidget
                  conf={state.btnVendor}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                {/* START_USER_CODE-USER_BEFORE_lblXxx*/}

                {/* END_USER_CODE-USER_BEFORE_lblXxx*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendorName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_lblXxx*/}

                {/* END_USER_CODE-USER_AFTER_lblXxx*/}
                {/* START_USER_CODE-USER_BEFORE_ddCounty*/}

                {/* END_USER_CODE-USER_BEFORE_ddCounty*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddCounty}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddCounty*/}

                {/* END_USER_CODE-USER_AFTER_ddCounty*/}
                {/* START_USER_CODE-USER_BEFORE_txtCountyID*/}

                {/* END_USER_CODE-USER_BEFORE_txtCountyID*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCountyID}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCountyID*/}

                {/* END_USER_CODE-USER_AFTER_txtCountyID*/}
                {/* START_USER_CODE-USER_BEFORE_radioSortBy*/}

                {/* END_USER_CODE-USER_BEFORE_radioSortBy*/}

                <RadioButtonGroupWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.radioSortBy}
                  screenConf={state}
                ></RadioButtonGroupWidget>
                {/* START_USER_CODE-USER_AFTER_radioSortBy*/}

                {/* END_USER_CODE-USER_AFTER_radioSortBy*/}
                {/* START_USER_CODE-USER_BEFORE_txtNameSearch*/}

                {/* END_USER_CODE-USER_BEFORE_txtNameSearch*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNameSearch}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNameSearch*/}

                {/* END_USER_CODE-USER_AFTER_txtNameSearch*/}
                {/* START_USER_CODE-USER_BEFORE_ddFarm*/}

                {/* END_USER_CODE-USER_BEFORE_ddFarm*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddFarm}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddFarm*/}

                {/* END_USER_CODE-USER_AFTER_ddFarm*/}
                {/* START_USER_CODE-USER_BEFORE_txtFarmID*/}

                {/* END_USER_CODE-USER_BEFORE_txtFarmID*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarmID}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFarmID*/}

                {/* END_USER_CODE-USER_AFTER_txtFarmID*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxFarmDetails*/}

              {/* END_USER_CODE-USER_AFTER_grpbxFarmDetails*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxFarmList*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxFarmList*/}

              <GroupBoxWidget conf={state.grpbxFarmList} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblFarmList*/}

                {/* END_USER_CODE-USER_BEFORE_lblFarmList*/}

                <LabelWidget
                  values={values}
                  conf={state.lblFarmList}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblFarmList*/}

                {/* END_USER_CODE-USER_AFTER_lblFarmList*/}
                {/* START_USER_CODE-USER_BEFORE_radioExpandCollapseGrid*/}

                {/* END_USER_CODE-USER_BEFORE_radioExpandCollapseGrid*/}

                <RadioButtonGroupWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.radioExpandCollapseGrid}
                  screenConf={state}
                ></RadioButtonGroupWidget>
                {/* START_USER_CODE-USER_AFTER_radioExpandCollapseGrid*/}

                {/* END_USER_CODE-USER_AFTER_radioExpandCollapseGrid*/}
                {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                <ButtonWidget
                  conf={state.btnSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                <ButtonWidget
                  conf={state.btnEdit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_gridFarmList*/}

                {/* END_USER_CODE-USER_BEFORE_gridFarmList*/}

                <GridWidget
                  conf={state.gridFarmList}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridFarmList}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridFarmList*/}

                {/* END_USER_CODE-USER_AFTER_gridFarmList*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxFarmList*/}

              {/* END_USER_CODE-USER_AFTER_grpbxFarmList*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_btnFarmDataForAccess*/}

                {/* END_USER_CODE-USER_BEFORE_btnFarmDataForAccess*/}

                <ButtonWidget
                  conf={state.btnFarmDataForAccess}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnFarmDataForAccess*/}

                {/* END_USER_CODE-USER_AFTER_btnFarmDataForAccess*/}
                {/* START_USER_CODE-USER_BEFORE_btnFarmDataForeNUTSImport*/}

                {/* END_USER_CODE-USER_BEFORE_btnFarmDataForeNUTSImport*/}

                <ButtonWidget
                  conf={state.btnFarmDataForeNUTSImport}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnFarmDataForeNUTSImport*/}

                {/* END_USER_CODE-USER_AFTER_btnFarmDataForeNUTSImport*/}
                {/* START_USER_CODE-USER_BEFORE_btnCountyDataForeNUTSImport*/}

                {/* END_USER_CODE-USER_BEFORE_btnCountyDataForeNUTSImport*/}

                <ButtonWidget
                  conf={state.btnCountyDataForeNUTSImport}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCountyDataForeNUTSImport*/}

                {/* END_USER_CODE-USER_AFTER_btnCountyDataForeNUTSImport*/}
                {/* START_USER_CODE-USER_BEFORE_btn1*/}

                {/* END_USER_CODE-USER_BEFORE_btn1*/}

                <ButtonWidget
                  conf={state.btn1}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btn1*/}

                {/* END_USER_CODE-USER_AFTER_btn1*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                <ButtonWidget
                  conf={state.btnExportToExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrintSearch*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrintSearch*/}

                <ButtonWidget
                  conf={state.btnPrintSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrintSearch*/}

                {/* END_USER_CODE-USER_AFTER_btnPrintSearch*/}
                {/* START_USER_CODE-USER_BEFORE_radioAsShownExpandedCollapsed*/}

                {/* END_USER_CODE-USER_BEFORE_radioAsShownExpandedCollapsed*/}

                <RadioButtonGroupWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.radioAsShownExpandedCollapsed}
                  screenConf={state}
                ></RadioButtonGroupWidget>
                {/* START_USER_CODE-USER_AFTER_radioAsShownExpandedCollapsed*/}

                {/* END_USER_CODE-USER_AFTER_radioAsShownExpandedCollapsed*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_FarmInquiry*/}

              {/* END_USER_CODE-USER_AFTER_FarmInquiry*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_FarmInquiry);
