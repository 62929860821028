/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { Buffer } from 'buffer';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  setValue,
  getValue,
  setData,
  enable,
  disable,
  getSelectedRowNumber,
  getData,
  goTo,
  hide,
  show,
  getRowCount, 
  getGridValue, hideColumn
} from "../../shared/WindowImports";

import "./FrtAccrlSettlement.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { SystemMaintenanceMasterManagementService } from "../../SystemMaintenanceMasterManagement/Service/SystemMaintenanceMasterManagementServices";
import { ThemeConsumer } from "styled-components";
import { AccountPayment } from "../../AccountPayment/Service/AccountPayment";
import { StockTransferService } from "../Service/StockTransferService";
import StockTransfer from "../StockTransfer/StockTransfer";
import ReportPreviewTransfer from "../ReportPreviewTransfer/ReportPreviewTransfer";
import { get } from "http";
import Loading from "../../../Loader/Loading";
import { clsTransfer } from "../Common/clsTransfer";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function StockTransfer_FrtAccrlSettlement(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const compIdFromLS = () => (sessionStorage.getItem('compId'));
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "FrtAccrlSettlement",
    windowName: "FrtAccrlSettlement",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "StockTransfer.FrtAccrlSettlement",
    // START_USER_CODE-USER_FrtAccrlSettlement_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Freight Accrual Release",
      scrCode: "PN1100A",
    },
    // END_USER_CODE-USER_FrtAccrlSettlement_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxAddRemove",
      Label: ">>>>",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxAction",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnPrintChecks: {
      name: "btnPrintChecks",
      type: "ButtonWidget",
      parent: "grpbxAction",
      Label: "Print Checks",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrintChecks_PROPERTIES

      // END_USER_CODE-USER_btnPrintChecks_PROPERTIES
    },
    btnReleasetoRTP: {
      name: "btnReleasetoRTP",
      type: "ButtonWidget",
      parent: "grpbxAction",
      Label: "Release to RTP",
      CharWidth: "31",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnReleasetoRTP_PROPERTIES

      // END_USER_CODE-USER_btnReleasetoRTP_PROPERTIES
    },
    btnRemove: {
      name: "btnRemove",
      type: "ButtonWidget",
      parent: "grpbxAddRemove",
      Label: "<<<<",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnRemove_PROPERTIES

      // END_USER_CODE-USER_btnRemove_PROPERTIES
    },
    btnSavePreviewSettlement: {
      name: "btnSavePreviewSettlement",
      type: "ButtonWidget",
      parent: "grpbxAction",
      Label: "Save/Preview Settlement",
      CharWidth: "46",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSavePreviewSettlement_PROPERTIES

      // END_USER_CODE-USER_btnSavePreviewSettlement_PROPERTIES
    },
    btnSaveToBatch: {
      name: "btnSaveToBatch",
      type: "ButtonWidget",
      parent: "grpbxAction",
      Label: "Save to Batch",
      CharWidth: "28",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSaveToBatch_PROPERTIES

      // END_USER_CODE-USER_btnSaveToBatch_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxSearchResults",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxDetails",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    colCropYearH: {
      name: "colCropYearH",
      type: "GridColumnWidget",
      parent: "gridHoldFA",
      Label: "Crop Year",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCropYearH_PROPERTIES

      // END_USER_CODE-USER_colCropYearH_PROPERTIES
    },
    colCropYearR: {
      name: "colCropYearR",
      type: "GridColumnWidget",
      parent: "gridReleaseFA",
      Label: "Crop Year",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCropYearR_PROPERTIES

      // END_USER_CODE-USER_colCropYearR_PROPERTIES
    },
    colPayType: {
      name: "colPayType",
      type: "GridColumnWidget",
      parent: "gridHoldFA",
      Label: "Pay Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPayType_PROPERTIES

      // END_USER_CODE-USER_colPayType_PROPERTIES
    },
    colColorH: {
      name: "colColorH",
      type: "GridColumnWidget",
      parent: "gridHoldFA",
      Label: "Color",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colColorH_PROPERTIES

      // END_USER_CODE-USER_colColorH_PROPERTIES
    },
    colPayTypeR: {
      name: "colPayTypeR",
      type: "GridColumnWidget",
      parent: "gridReleaseFA",
      Label: "Pay Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPayTypeR_PROPERTIES

      // END_USER_CODE-USER_colPayTypeR_PROPERTIES
    },
    colColorR: {
      name: "colColorR",
      type: "GridColumnWidget",
      parent: "gridReleaseFA",
      Label: "Color",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colColorR_PROPERTIES

      // END_USER_CODE-USER_colColorR_PROPERTIES
    },
    colProceedH: {
      name: "colProceedH",
      type: "GridColumnWidget",
      parent: "gridHoldFA",
      Label: "Proceed",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colProceedH_PROPERTIES

      // END_USER_CODE-USER_colProceedH_PROPERTIES
    },
    colProceedR: {
      name: "colProceedR",
      type: "GridColumnWidget",
      parent: "gridReleaseFA",
      Label: "Proceed",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colProceedR_PROPERTIES

      // END_USER_CODE-USER_colProceedR_PROPERTIES
    },
    colRecBuyPtH: {
      name: "colRecBuyPtH",
      type: "GridColumnWidget",
      parent: "gridHoldFA",
      Label: "Rec Buy Pt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRecBuyPtH_PROPERTIES

      // END_USER_CODE-USER_colRecBuyPtH_PROPERTIES
    },
    colRecBuyPtR: {
      name: "colRecBuyPtR",
      type: "GridColumnWidget",
      parent: "gridReleaseFA",
      Label: "Rec Buy Pt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRecBuyPtR_PROPERTIES

      // END_USER_CODE-USER_colRecBuyPtR_PROPERTIES
    },
    colShpBuyPtH: {
      name: "colShpBuyPtH",
      type: "GridColumnWidget",
      parent: "gridHoldFA",
      Label: "Shp Buy Pt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colShpBuyPtH_PROPERTIES

      // END_USER_CODE-USER_colShpBuyPtH_PROPERTIES
    },
    colShpBuyPtR: {
      name: "colShpBuyPtR",
      type: "GridColumnWidget",
      parent: "gridReleaseFA",
      Label: "Shp Buy Pt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colShpBuyPtR_PROPERTIES

      // END_USER_CODE-USER_colShpBuyPtR_PROPERTIES
    },
    colTransferNumH: {
      name: "colTransferNumH",
      type: "GridColumnWidget",
      parent: "gridHoldFA",
      Label: "Transfer Num",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTransferNumH_PROPERTIES

      // END_USER_CODE-USER_colTransferNumH_PROPERTIES
    },
    colTransferNumR: {
      name: "colTransferNumR",
      type: "GridColumnWidget",
      parent: "gridReleaseFA",
      Label: "Transfer Num",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTransferNumR_PROPERTIES

      // END_USER_CODE-USER_colTransferNumR_PROPERTIES
    },
    ddCollectionLocation: {
      name: "ddCollectionLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails",
      Label: "Collection Location:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCollectionLocation_PROPERTIES

      // END_USER_CODE-USER_ddCollectionLocation_PROPERTIES
    },
    ddCropYear: {
      name: "ddCropYear",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails",
      Label: "Crop Year:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCropYear_PROPERTIES

      // END_USER_CODE-USER_ddCropYear_PROPERTIES
    },
    ddDestinationLocation: {
      name: "ddDestinationLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails",
      Label: "Destination Location:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestinationLocation_PROPERTIES

      // END_USER_CODE-USER_ddDestinationLocation_PROPERTIES
    },
    ddRemitTo: {
      name: "ddRemitTo",
      type: "DropDownFieldWidget",
      parent: "grpbxInvoiceInfo",
      Label: "Remit To:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRemitTo_PROPERTIES
      SpecialFirstOption: "",
      // END_USER_CODE-USER_ddRemitTo_PROPERTIES
    },
    ddShippingLocation: {
      name: "ddShippingLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails",
      Label: "Shipping Location:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddShippingLocation_PROPERTIES

      // END_USER_CODE-USER_ddShippingLocation_PROPERTIES
    },
    ddSpoolLocation: {
      name: "ddSpoolLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxInvoiceInfo",
      Label: "Spool Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSpoolLocation_PROPERTIES

      // END_USER_CODE-USER_ddSpoolLocation_PROPERTIES
    },
    btncol1: {
      name: "btncol1",
      type: "ButtonWidget",
      colName: "col1",
      parent: "gridHoldFA",
      CharWidth: "4",
      Height: "",
      Width: "",
      HasLabel: false,
      ofTypeDomain: "d_boolean",
    },
    col1: {
      name: "col1",
      type: "GridColumnWidget",
      parent: "gridHoldFA",
      HasLabel: false,
      Height: "",
      Width: "",
    },
    gridHoldFA: {
      name: "gridHoldFA",
      type: "GridWidget",
      parent: "grpbxSearchResults",
      gridCellsOrder:
        "btncol1,txtcolPayType,txtcolCropYearH,txtcolShpBuyPtH,txtcolRecBuyPtH,txtcolTransferNumH,txtcolProceedH,txtcolColorH",
      ColSpan: "1",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      clonedExtId: "58755",
      // START_USER_CODE-USER_gridHoldFA_PROPERTIES
      clientPagination: true,
      DisplaySize: 20,
      isExpandable: true,
      defaultExpand: false,
      isMultiSelect: true,
      conditionalRowStyles: [
        {
          when: (row) => { return row.txtcolColorH === 'Y' },
          style: {
            '.valRowSel':{
              color:'red'
            }
          }
        }
      ],
      expandedCompTemplate: ({ data }) => (
        <div>
          {data.expArray ? (
            <div className="py-3 expandable-area col-sm-12 rdt_TableRow">
              {data.expArray.map((elem, i) =>
                <div className="py-3 expandable-area col-sm-12 rdt_TableRow selectable" key={"Row" + i}>
                  <div className={"row" + " rdt_ExpanderRow"} key={"ExpanderRow" + i} >
                    <div className="expandIconSpc"></div>
                    {thisObj.state.gridHoldFA.columns[0].Visible ?
                      <div className="extraRowCell_1" key="expandCell1">
                      </div> : <></>}
                    {thisObj.state.gridHoldFA.columns[1].Visible ?
                      <div className="extraRowCell_2" key="expandCell2">
                      </div> : <></>}
                    {thisObj.state.gridHoldFA.columns[2].Visible ?
                      <div className="extraRowCell_3" key="expandCell3">
                      </div> : <></>}
                    {thisObj.state.gridHoldFA.columns[3].Visible ?
                      <div className="extraRowCell_4" key="expandCell4">
                        <label>
                          {elem.xfer_num}
                        </label>
                      </div> :
                      <></>}
                    {thisObj.state.gridHoldFA.columns[4].Visible ?
                      <div className="extraRowCell_5" key="expandCell5">
                        <label>
                          {elem.freight_amt}
                        </label>
                      </div> : <></>}
                    {thisObj.state.gridHoldFA.columns[5].Visible ?
                      <div className="extraRowCell_6" key="expandCell6">
                        <label>
                          {elem.frt_pd}
                        </label>
                      </div> : <></>}
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      )
      // END_USER_CODE-USER_gridHoldFA_PROPERTIES
    },
    btncol2: {
      name: "btncol2",
      type: "ButtonWidget",
      colName: "col2",
      parent: "gridReleaseFA",
      CharWidth: "4",
      Height: "",
      Width: "",
      HasLabel: false,
      ofTypeDomain: "d_boolean",
    },
    col2: {
      name: "col2",
      type: "GridColumnWidget",
      parent: "gridReleaseFA",
      HasLabel: false,
      Height: "",
      Width: "",
    },
    gridReleaseFA: {
      name: "gridReleaseFA",
      type: "GridWidget",
      parent: "grpbxSearchResults",
      gridCellsOrder:
        "btncol2,txtcolPayTypeR,txtcolCropYearR,txtcolShpBuyPtR,txtcolRecBuyPtR,txtcolTransferNumR,txtcolProceedR,txtcolColorR",
      ColSpan: "1",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridReleaseFA_PROPERTIES
      clientPagination: true,
      DisplaySize: 20,
      isExpandable: true,
      defaultExpand: false,
      isMultiSelect: true,

      conditionalRowStyles: [
        {
          when: (row) => { return row.txtcolColorR === 'Y' },
          style: {
            '.valRowSel':{
              color:'red'
            }
          }
        }
      ],

      expandedCompTemplate: ({ data }) => (
        <div>
          {data.expArray2 ? (
            <div className="py-3 expandable-area col-sm-12 rdt_TableRow">
              {data.expArray2.map((elem, i) =>
                <div className="py-3 expandable-area col-sm-12 rdt_TableRow selectable" key={"Row" + i}>
                  <div className={"row" + " rdt_ExpanderRow"} key={"ExpanderRow" + i} >
                    <div className="expandIconSpc"></div>
                    {thisObj.state.gridReleaseFA.columns[0].Visible ?
                      <div className="extraRowCell_1" key="expandCell1">
                      </div> : <></>}
                    {thisObj.state.gridReleaseFA.columns[1].Visible ?
                      <div className="extraRowCell_2" key="expandCell2">
                      </div> : <></>}
                    {thisObj.state.gridReleaseFA.columns[2].Visible ?
                      <div className="extraRowCell_3" key="expandCell3">
                      </div> : <></>}
                    {thisObj.state.gridReleaseFA.columns[3].Visible ?
                      <div className="extraRowCell_4" key="expandCell4">
                        <label>
                          {elem.xfer_num}
                        </label>
                      </div> :
                      <></>}
                    {thisObj.state.gridReleaseFA.columns[4].Visible ?
                      <div className="extraRowCell_5" key="expandCell5">
                        <label>
                          {elem.freight_amt}
                        </label>
                      </div> : <></>}
                    {thisObj.state.gridReleaseFA.columns[5].Visible ?
                      <div className="extraRowCell_6" key="expandCell6">
                        <label>
                          {elem.frt_pd}
                        </label>
                      </div> : <></>}
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      )
      // END_USER_CODE-USER_gridReleaseFA_PROPERTIES
    },
    grpbxAddRemove: {
      name: "grpbxAddRemove",
      type: "GroupBoxWidget",
      parent: "grpbxSearchResults",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxAddRemove_PROPERTIES

      // END_USER_CODE-USER_grpbxAddRemove_PROPERTIES
    },
    grpbxCheckInfo: {
      name: "grpbxCheckInfo",
      type: "GroupBoxWidget",
      parent: "grpbxFrtAccrlSettlement",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxCheckInfo_PROPERTIES

      // END_USER_CODE-USER_grpbxCheckInfo_PROPERTIES
    },
    grpbxDetails: {
      name: "grpbxDetails",
      type: "GroupBoxWidget",
      parent: "grpbxFrtAccrlSettlement",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails_PROPERTIES
    },
    grpbxInvoiceInfo: {
      name: "grpbxInvoiceInfo",
      type: "GroupBoxWidget",
      parent: "grpbxFrtAccrlSettlement",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxInvoiceInfo_PROPERTIES

      // END_USER_CODE-USER_grpbxInvoiceInfo_PROPERTIES
    },
    grpbxSearchResults: {
      name: "grpbxSearchResults",
      type: "GroupBoxWidget",
      parent: "grpbxFrtAccrlSettlement",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxSearchResults_PROPERTIES

      // END_USER_CODE-USER_grpbxSearchResults_PROPERTIES
    },
    lblBalance: {
      name: "lblBalance",
      type: "LabelWidget",
      parent: "grpbxCheckInfo",
      Label: "Balance",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBalance_PROPERTIES

      // END_USER_CODE-USER_lblBalance_PROPERTIES
    },
    lblCheckInformation: {
      name: "lblCheckInformation",
      type: "LabelWidget",
      parent: "grpbxCheckInfo",
      Label: "Check Information",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCheckInformation_PROPERTIES

      // END_USER_CODE-USER_lblCheckInformation_PROPERTIES
    },
    lblCropYear: {
      name: "lblCropYear",
      type: "LabelWidget",
      parent: "grpbxAction",
      Label: "lblCropYear2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCropYear_PROPERTIES

      // END_USER_CODE-USER_lblCropYear_PROPERTIES
    },
    lblNoteRedPay: {
      name: "lblNoteRedPay",
      type: "LabelWidget",
      parent: "grpbxInvoiceInfo",
      Label: "Red payables indicates fuel surchage was not calculated.",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNoteRedPay_PROPERTIES

      // END_USER_CODE-USER_lblNoteRedPay_PROPERTIES
    },
    txtARBalance: {
      name: "txtARBalance",
      type: "TextBoxWidget",
      parent: "grpbxCheckInfo",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtARBalance_PROPERTIES

      // END_USER_CODE-USER_txtARBalance_PROPERTIES
    },
    txtCarrierAbbrNum: {
      name: "txtCarrierAbbrNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Carrier Abbreviation #:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCarrierAbbrNum_PROPERTIES

      // END_USER_CODE-USER_txtCarrierAbbrNum_PROPERTIES
    },
    txtCarrierAbbrNum1: {
      name: "txtCarrierAbbrNum1",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      HasLabel:false,
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCarrierAbbrNum1_PROPERTIES
 
      // END_USER_CODE-USER_txtCarrierAbbrNum1_PROPERTIES
    },
    txtCheckNumber: {
      name: "txtCheckNumber",
      type: "TextBoxWidget",
      parent: "grpbxCheckInfo",
      Label: "Check #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCheckNumber_PROPERTIES

      // END_USER_CODE-USER_txtCheckNumber_PROPERTIES
    },
    txtcolCropYearH: {
      name: "txtcolCropYearH",
      type: "TextBoxWidget",
      colName: "colCropYearH",
      parent: "gridHoldFA",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCropYearH_PROPERTIES

      // END_USER_CODE-USER_txtcolCropYearH_PROPERTIES
    },
    txtcolCropYearR: {
      name: "txtcolCropYearR",
      type: "TextBoxWidget",
      colName: "colCropYearR",
      parent: "gridReleaseFA",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCropYearR_PROPERTIES

      // END_USER_CODE-USER_txtcolCropYearR_PROPERTIES
    },
    txtCollectAR: {
      name: "txtCollectAR",
      type: "TextBoxWidget",
      parent: "grpbxCheckInfo",
      Label: "Collect AR:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCollectAR_PROPERTIES

      // END_USER_CODE-USER_txtCollectAR_PROPERTIES
    },
    txtCollectCR: {
      name: "txtCollectCR",
      type: "TextBoxWidget",
      parent: "grpbxCheckInfo",
      Label: "Collect CR:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCollectCR_PROPERTIES

      // END_USER_CODE-USER_txtCollectCR_PROPERTIES
    },
    txtCollectSR: {
      name: "txtCollectSR",
      type: "TextBoxWidget",
      parent: "grpbxCheckInfo",
      Label: "Collect SR:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCollectSR_PROPERTIES

      // END_USER_CODE-USER_txtCollectSR_PROPERTIES
    },
    txtcolPayType: {
      name: "txtcolPayType",
      type: "TextBoxWidget",
      colName: "colPayType",
      parent: "gridHoldFA",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPayType_PROPERTIES

      // END_USER_CODE-USER_txtcolPayType_PROPERTIES
    },
    txtcolColorH: {
      name: "txtcolColorH",
      type: "TextBoxWidget",
      colName: "colPayType",
      parent: "gridHoldFA",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolColorH_PROPERTIES

      // END_USER_CODE-USER_txtcolColorH_PROPERTIES
    },
    txtcolPayTypeR: {
      name: "txtcolPayTypeR",
      type: "TextBoxWidget",
      colName: "colPayTypeR",
      parent: "gridReleaseFA",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPayTypeR_PROPERTIES

      // END_USER_CODE-USER_txtcolPayTypeR_PROPERTIES
    },
    txtcolColorR: {
      name: "txtcolColorR",
      type: "TextBoxWidget",
      colName: "colPayTypeR",
      parent: "gridReleaseFA",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolColorR_PROPERTIES

      // END_USER_CODE-USER_txtcolColorR_PROPERTIES
    },
    txtcolProceedH: {
      name: "txtcolProceedH",
      type: "TextBoxWidget",
      colName: "colProceedH",
      parent: "gridHoldFA",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolProceedH_PROPERTIES

      // END_USER_CODE-USER_txtcolProceedH_PROPERTIES
    },
    txtcolProceedR: {
      name: "txtcolProceedR",
      type: "TextBoxWidget",
      colName: "colProceedR",
      parent: "gridReleaseFA",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolProceedR_PROPERTIES

      // END_USER_CODE-USER_txtcolProceedR_PROPERTIES
    },
    txtcolRecBuyPtH: {
      name: "txtcolRecBuyPtH",
      type: "TextBoxWidget",
      colName: "colRecBuyPtH",
      parent: "gridHoldFA",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRecBuyPtH_PROPERTIES

      // END_USER_CODE-USER_txtcolRecBuyPtH_PROPERTIES
    },
    txtcolRecBuyPtR: {
      name: "txtcolRecBuyPtR",
      type: "TextBoxWidget",
      colName: "colRecBuyPtR",
      parent: "gridReleaseFA",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRecBuyPtR_PROPERTIES

      // END_USER_CODE-USER_txtcolRecBuyPtR_PROPERTIES
    },
    txtcolShpBuyPtH: {
      name: "txtcolShpBuyPtH",
      type: "TextBoxWidget",
      colName: "colShpBuyPtH",
      parent: "gridHoldFA",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolShpBuyPtH_PROPERTIES

      // END_USER_CODE-USER_txtcolShpBuyPtH_PROPERTIES
    },
    txtcolShpBuyPtR: {
      name: "txtcolShpBuyPtR",
      type: "TextBoxWidget",
      colName: "colShpBuyPtR",
      parent: "gridReleaseFA",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolShpBuyPtR_PROPERTIES

      // END_USER_CODE-USER_txtcolShpBuyPtR_PROPERTIES
    },
    txtcolTransferNumH: {
      name: "txtcolTransferNumH",
      type: "TextBoxWidget",
      colName: "colTransferNumH",
      parent: "gridHoldFA",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTransferNumH_PROPERTIES

      // END_USER_CODE-USER_txtcolTransferNumH_PROPERTIES
    },
    txtcolTransferNumR: {
      name: "txtcolTransferNumR",
      type: "TextBoxWidget",
      colName: "colTransferNumR",
      parent: "gridReleaseFA",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTransferNumR_PROPERTIES

      // END_USER_CODE-USER_txtcolTransferNumR_PROPERTIES
    },
    txtCRBalance: {
      name: "txtCRBalance",
      type: "TextBoxWidget",
      parent: "grpbxCheckInfo",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCRBalance_PROPERTIES

      // END_USER_CODE-USER_txtCRBalance_PROPERTIES
    },
    txtEndCheckNumber: {
      name: "txtEndCheckNumber",
      type: "TextBoxWidget",
      parent: "grpbxCheckInfo",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEndCheckNumber_PROPERTIES

      // END_USER_CODE-USER_txtEndCheckNumber_PROPERTIES
    },
    txtFAOnHold: {
      name: "txtFAOnHold",
      type: "TextBoxWidget",
      parent: "grpbxSearchResults",
      Label: "FA On Hold:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFAOnHold_PROPERTIES

      // END_USER_CODE-USER_txtFAOnHold_PROPERTIES
    },
    txtFAToPays: {
      name: "txtFAToPays",
      type: "TextBoxWidget",
      parent: "grpbxSearchResults",
      Label: "FA To Pays:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFAToPays_PROPERTIES

      // END_USER_CODE-USER_txtFAToPays_PROPERTIES
    },
    txtInvoiceNum: {
      name: "txtInvoiceNum",
      type: "TextBoxWidget",
      parent: "grpbxInvoiceInfo",
      Label: "Invoice #:",
      LengthRange: { MinLength: 0, MaxLength: 50 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInvoiceNum_PROPERTIES

      // END_USER_CODE-USER_txtInvoiceNum_PROPERTIES
    },
    txtSRBalance: {
      name: "txtSRBalance",
      type: "TextBoxWidget",
      parent: "grpbxCheckInfo",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSRBalance_PROPERTIES

      // END_USER_CODE-USER_txtSRBalance_PROPERTIES
    },
    txtValueOfCheck: {
      name: "txtValueOfCheck",
      type: "TextBoxWidget",
      parent: "grpbxCheckInfo",
      Label: "Value Of Check:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValueOfCheck_PROPERTIES

      // END_USER_CODE-USER_txtValueOfCheck_PROPERTIES
    },
    txtValueOfRTPs: {
      name: "txtValueOfRTPs",
      type: "TextBoxWidget",
      parent: "grpbxCheckInfo",
      Label: "Value Of RTPs:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValueOfRTPs_PROPERTIES

      // END_USER_CODE-USER_txtValueOfRTPs_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtVendorNum: {
      name: "txtVendorNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorNum_PROPERTIES

      // END_USER_CODE-USER_txtVendorNum_PROPERTIES
    },
    grpbxFrtAccrlSettlement: {
      name: "grpbxFrtAccrlSettlement",
      type: "GroupBoxWidget",
      parent: "FrtAccrlSettlement",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxFrtAccrlSettlement_PROPERTIES

      // END_USER_CODE-USER_grpbxFrtAccrlSettlement_PROPERTIES
    },
    grpbxAction: {
      name: "grpbxAction",
      type: "GroupBoxWidget",
      parent: "FrtAccrlSettlement",
      Height: "",
      Width: "",
      ColsForMobile: "6",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_grpbxAction_PROPERTIES

      // END_USER_CODE-USER_grpbxAction_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#VendorLookup": {},
        "StockTransfer#ReportPreviewTransfer": {},
      },
      REVERSE: {
        "ContractManagement#VendorLookup": {},
        "StockTransfer#ReportPreviewTransfer": {},
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnVendor: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
  };
  let _winServices = {};
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj
    let ClosingOfStockTransferScreen = getData(thisObj, 'ClosingOfStockTransfer')
    let refreshGrid = getData(thisObj, 'refreshGridAccrlSettlement')
    if((ClosingOfStockTransferScreen != undefined && ClosingOfStockTransferScreen != null && ClosingOfStockTransferScreen == true) ||
      (refreshGrid != undefined && refreshGrid != null && refreshGrid == true)){
        ScreenReopen()
    }
  });

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    let ClosingOfStockTransferScreen = getData(thisObj, 'ClosingOfStockTransfer')
    setValue(thisObj, "lblCropYear", cropYearFromLS())
    hideColumn(thisObj, 'gridHoldFA', 'txtcolColorH')
    hideColumn(thisObj, 'gridReleaseFA', 'txtcolColorR')
    Form_Load()
    setData(thisObj, 'ReportTitle', 'Settlement')
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  useEffect(() => {
    let vendor_lookup = getData(thisObj, 'vendorDetails')
    if (vendor_lookup !== null && vendor_lookup !== undefined && vendor_lookup !== '') {
      thisObj.setFieldValue("txtVendorNum", vendor_lookup.VendorNumber);
      ontxtVendorNumBlur()
      // thisObj.setFieldValue("txtVendor", vendor_lookup.vendorName)
      setData(thisObj, 'vendorDetails', '')
    }
  }, [getData(thisObj, 'vendorDetails')]);

  useEffect(() => {
    if (getData(thisObj, 'closingOfReportPreviewCheck') != undefined && getData(thisObj, 'closingOfReportPreviewCheck') != null && getData(thisObj, 'closingOfReportPreviewCheck') == true) {
      ReportPreviewTransferObj()
      setData(thisObj, "closingOfReportPreviewCheck", false)
    }
  }, [getData(thisObj, 'closingOfReportPreviewCheck')]);

  const ScreenReopen = async () =>{
    let strXMLData = getData(thisObj, 'strXMLData')
    let OpenStockTransferScreen = getData(thisObj, 'OpenStockTransfer')
    let refreshGrid = getData(thisObj, 'refreshGridAccrlSettlement')
    let ClosingOfStockTransferScreen = getData(thisObj, 'ClosingOfStockTransfer')

    if(OpenStockTransferScreen != undefined && OpenStockTransferScreen != null && OpenStockTransferScreen != '' && OpenStockTransferScreen == true){
      StockTransferData()
    }
    else if(strXMLData != undefined && strXMLData != null && strXMLData != '' && strXMLData == true &&
      ClosingOfStockTransferScreen != undefined && ClosingOfStockTransferScreen != null && ClosingOfStockTransferScreen != '' && ClosingOfStockTransferScreen == true){
      DoSearch()
      setData(thisObj, 'strXMLData', false)
    }
    else if(strXMLData != undefined && strXMLData != null && strXMLData != '' && strXMLData == true && 
      refreshGrid != undefined && refreshGrid != null && refreshGrid != '' && refreshGrid == true){
      DoSearch()
      setData(thisObj, 'strXMLData', false)
    }
    setData(thisObj, 'OpenStockTransfer', false)
    setData(thisObj, 'refreshGridAccrlSettlement', false)
    setData(thisObj, 'ClosingOfStockTransfer', false)
  }

  const StockTransferData = async () =>{
      let mTransfer=new clsTransfer();
      let Data = getData(thisObj, 'ObjData')
      let finalData= {}
      await mTransfer.Load(Data,Data.mCropYear)
      finalData={
        "mTransfer":mTransfer,
        "TransferRole":"",
        "FromFrtAccrl":true,
        "ReCreate":false
      }
      setData(thisObj, 'StockTransfer', finalData);
      setData(thisObj, "ObjData", {})
      goTo(thisObj, "StockTransfer#StockTransfer#DEFAULT#true#Click")

  }

  const bFillLocationList = async () => {
    try {
      setLoading(true)
      let LstrList = []
      let isbFillLocationList = false

      setValue(thisObj, 'ddSpoolLocation', '')

      let Obj = {}
      let js = []

      Obj = { key: '', description: '' }
      js.push(Obj)

      LstrList = await ContractManagementService.RetrieveBuyingPointControlDetails('PN1100', null, null, null, null)

      if (LstrList != undefined && LstrList != null) {
        for (let i = 0; i < LstrList.length; i++) {
          Obj = {}
          Obj = { key: LstrList[i].buy_pt_id, description: LstrList[i].buy_pt_id + " - " + LstrList[i].buy_pt_name }
          js.push(Obj)
        }
        isbFillLocationList = true
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddSpoolLocation: {
            ...state["ddSpoolLocation"],
            valueList: js
          }
        }
      });

      return isbFillLocationList
    }
    catch {
      showMessage(thisObj, 'Error occurred during bFillLocationList event')
    }
    finally{
      setLoading(false)
    }
  }

  const bFillCropYearList = async () => {
    try {
      setLoading(true)
      let LstrList = []
      let js = []
      let obj = {}

      LstrList = await ContractManagementService.RetrieveCropYears();
      if (LstrList != undefined && LstrList != null) {
        setValue(thisObj, 'ddCropYear', '')
        obj = { key: '', description: ">>> All Years <<<" }
        js.push(obj)

        for (let i = 0; i < LstrList.length; i++) {
          obj = {}
          obj = { key: LstrList[i].cropYear, description: LstrList[i].cropYear }
          js.push(obj)
        }
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddCropYear: {
            ...state["ddCropYear"],
            valueList: js
          }
        }
      })
    }
    catch {
      showMessage(thisObj, 'Error occurred during bFillCropYearList event')
    }
    finally{
      setLoading(false)
    }
  }

  const bFillBuyingPointList_rcv = async () => {
    try {
      setLoading(true)
      let LstrList = []
      let isbFillBuyingPointList_rcv = false
      let js = []
      let obj = {}

      setValue(thisObj, 'ddDestinationLocation', '')

      obj = { key: '', description: ">>> All Buying Points <<<" }
      js.push(obj)

      LstrList = await SettlementService.RetrieveTransferBuyingPointDetails()

      if (LstrList != undefined && LstrList != null) {
        for (let i = 0; i < LstrList.length; i++) {
          obj = {}
          obj = { key: LstrList[i].xfer_buy_pt_id, description: LstrList[i].xfer_buy_pt_id + '-' + LstrList[i].xfer_buy_pt_name }
          js.push(obj)
        }
        isbFillBuyingPointList_rcv = true
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddDestinationLocation: {
            ...state["ddDestinationLocation"],
            valueList: js
          }
        }
      })

      return isbFillBuyingPointList_rcv
    }
    catch {
      showMessage(thisObj, 'Error occurred during bFillBuyingPointList_rcv event')
    }
    finally{
      setLoading(false)
    }
  }

  const bFillCollectionPointList = async () => {
    try {
      setLoading(true)
      let LstrList = []
      let isbFillCollectionPointList = false
      let js = []
      let obj = {}

      setValue(thisObj, 'ddCollectionLocation', '')

      obj = { key: '', description: ">>> All Collection Points <<<" }
      js.push(obj)

      LstrList = await SystemMaintenanceMasterManagementService.RetrieveCollectionPointControlDetails(cropYearFromLS(), cropYearFromLS(), null, null)

      if (LstrList != undefined && LstrList != null) {
        for (let i = 0; i < LstrList.length; i++) {
          obj = {}
          obj = { key: LstrList[i].coll_pt_id, description: LstrList[i].coll_pt_id }
          js.push(obj)
        }
        isbFillCollectionPointList = true
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddCollectionLocation: {
            ...state["ddCollectionLocation"],
            valueList: js
          }
        }
      })

      return isbFillCollectionPointList
    }
    catch {
      showMessage(thisObj, 'Error occurred during bFillCollectionPointList event')
    }
    finally{
      setLoading(false)
    }
  }

  const bFillBuyingPointListForCollectPt = async () => {
    try {
      let LstrList = []
      let isbFillBuyingPointListForCollectPt = false
      let js = []
      let obj = {}

      setValue(thisObj, 'ddShippingLocation', '')
      obj = { key: '', description: ">>> All Buying Points <<<" }
      js.push(obj)

      let coll_pt_id = getValue(thisObj, 'ddCollectionLocation')
      LstrList = await ContractManagementService.RetrieveBuyingPointControlDetails(null, null, null, coll_pt_id, null)

      if (LstrList != undefined && LstrList != null) {
        for (let i = 0; i < LstrList.length; i++) {
          obj = {}
          obj = { key: LstrList[i].buy_pt_id, description: LstrList[i].buy_pt_id + ' - ' + LstrList[i].buy_pt_name }
          js.push(obj)
        }
        isbFillBuyingPointListForCollectPt = true
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddShippingLocation: {
            ...state["ddShippingLocation"],
            valueList: js
          }
        }
      })

      return isbFillBuyingPointListForCollectPt
    }
    catch {
      showMessage(thisObj, 'Error occurred during bFillBuyingPointListForCollectPt event')
    }
  }

  const bFillRemitToList2 = async (vnum) => {
    try {
      setLoading(true)
      let LstrList = []
      let js = []
      let obj = {}
      let isbFillRemitToList2 = false

      LstrList = await ContractManagementService.RetieveRemitDetails(vnum)

      if (LstrList != undefined && LstrList != null) {
        for (let i = 0; i < LstrList.length; i++) {
          obj = {}
          obj = { key: LstrList[i].remittoid, description: LstrList[i].remittoid + ' - ' + ConvertToPascalCase(LstrList[i].name) + ' - ' + ConvertToPascalCase(LstrList[i].city) + ',' + ConvertToPascalCase(LstrList[i].state)}
          js.push(obj)
        }
        isbFillRemitToList2 = true
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddRemitTo: {
            ...state["ddRemitTo"],
            valueList: js
          }
        }
      })

      if (js.length > 0) {
        setValue(thisObj, 'ddRemitTo', js[0].key)
      }

      return isbFillRemitToList2
    }
    catch {
      showMessage(thisObj, 'Error occurred during bFillRemitToList2 event')
    }
    finally{
      setLoading(false)
    }
  }

  function ConvertToPascalCase(word){
    return word.replace(/(\w)(\w*)/g, function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
  }

  const bFillRemitToList = async (vnum) => {
    try {
      setLoading(true)
      let LstrList = []
      let isbFillRemitToList = false

      LstrList = await ContractManagementService.RetrieveVendorByNumber(vnum)

      if (LstrList != undefined && LstrList != null && LstrList.length > 0) {
        setValue(thisObj, 'txtVendor', LstrList[0].name)
        isbFillRemitToList = true
      }

      return isbFillRemitToList
    }
    catch {
      showMessage(thisObj, 'Error occurred during bFillRemitToList event')
    }
    finally{
      setLoading(false)
    }
  }

  const ViewTransferdDetail = async (Value) => {
    setData(thisObj, 'mbViewTransferdDetail', Value)
  }

  const ontxtCollectARBlur = async () => {
    try {
      setLoading(true)
      let txtCollectARValue = getValue(thisObj, 'txtCollectAR')

      if (Number(txtCollectARValue) < 0) {
        showMessage(thisObj, "Negative collection amount not allowed.")
        setValue(thisObj, 'txtCollectAR', '0.00')
        return
      }
      else{
        setValue(thisObj, 'txtCollectAR', Number(txtCollectARValue).toFixed(2))
      }
      await Calc()

    }
    catch {
      showMessage(thisObj, 'Error occurred during ontxtCollectARBlur event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtCollectARBlur = ontxtCollectARBlur;
  
  const ontxtCollectARChange = async () => {
    try {
      setLoading(true)
      let txtCollectARValue = getValue(_kaledo.thisObj, 'txtCollectAR')
      let txtARBalanceValue = getValue(_kaledo.thisObj, 'txtARBalance')
      let txtCollectAREnabledisable = document.getElementById("txtCollectAR").enabled
      if (txtCollectAREnabledisable == false) {
        return;
      }

      if (Number(txtCollectARValue) > Number(txtARBalanceValue)) {
        setValue(thisObj, 'txtCollectAR', Number(txtARBalanceValue).toFixed(2))
      }
      else{
        setValue(thisObj, 'txtCollectAR', validateTextBox(txtCollectARValue))
      }

      await checkAgainstValueOfCheck(getValue(_kaledo.thisObj, 'txtCollectAR'), 'txtCollectAR')
    }
    catch {
      showMessage(thisObj, 'Error occurred during ontxtCollectARChange event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtCollectARChange = ontxtCollectARChange;

  const ontxtCollectCRChange = async () => {
    try {
      setLoading(true)
      let txtCollectCRValue = getValue(thisObj, 'txtCollectCR')
      let txtCRBalanceValue = getValue(thisObj, 'txtCRBalance')
      let txtCollectCREnabledisable = document.getElementById("txtCollectCR").enabled
      if (txtCollectCREnabledisable == false) {
        return
      }

      if (Number(txtCollectCRValue) > Number(txtCRBalanceValue)) {
        setValue(thisObj, 'txtCollectCR', Number(txtCRBalanceValue).toFixed(2))
      }
      else{
        setValue(thisObj, 'txtCollectCR', validateTextBox(txtCollectCRValue))
      }

      await checkAgainstValueOfCheck(getValue(_kaledo.thisObj, 'txtCollectCR'), 'txtCollectCR')

    }
    catch {
      showMessage(thisObj, 'Error occurred during ontxtCollectCRChange event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtCollectCRChange = ontxtCollectCRChange;

  const validateTextBox = (data) => {
    try {
      var res = ''
      for (var i = 0; i < data.length; i++) {
        if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || data[i].charCodeAt(0) == 46) {
          res += data[i]
        }
      }
      return res;
    }
    catch {
      showMessage(thisObj, 'Error occurred during validateTextBox event')
    }
  }

  const ontxtCollectCRBlur = async () => {
    try {
      setLoading(true)
      let txtCollectCRValue = getValue(thisObj, 'txtCollectCR')

      if (Number(txtCollectCRValue) < 0) {
        showMessage(thisObj, "Negative collection amount not allowed.")
        setValue(thisObj, 'txtCollectCR', '0.00')
        return
      }
      else{
        setValue(thisObj, 'txtCollectCR', Number(txtCollectCRValue).toFixed(2))
      }

      await Calc()

    }
    catch {
      showMessage(thisObj, 'Error occurred during ontxtCollectCRBlur event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtCollectCRBlur = ontxtCollectCRBlur;

  const ontxtCollectSRBlur = async () => {
    try {
      setLoading(true)
      let txtCollectSRValue = getValue(thisObj, 'txtCollectSR')
      if (Number(txtCollectSRValue) < 0) {
        showMessage(thisObj, "Negative collection amount not allowed.")
        setValue(thisObj, 'txtCollectSR', '0.00')
        return;
      }
      else{
        setValue(thisObj, 'txtCollectSR', Number(txtCollectSRValue).toFixed(2))
      }

      await Calc()
    }
    catch {
      showMessage(thisObj, 'Error occurred during ontxtCollectSRBlur event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtCollectSRBlur = ontxtCollectSRBlur;

  const ontxtCollectSRChange = async () => {
    try {
      setLoading(true)
      let txtCollectSRValue = getValue(thisObj, 'txtCollectSR')
      let txtSRBalanceValue = getValue(thisObj, 'txtSRBalance')
      let txtCollectSREnabledisable = document.getElementById("txtCollectSR").enabled
      if (txtCollectSREnabledisable == false) {
        return;
      }

      if (Number(txtCollectSRValue) > Number(txtSRBalanceValue)) {
        setValue(thisObj, 'txtCollectSR', Number(txtSRBalanceValue).toFixed(2))
      }
      else{
        setValue(thisObj, 'txtCollectSR', validateTextBox(txtCollectSRValue))
      }
      
      await checkAgainstValueOfCheck(getValue(_kaledo.thisObj, 'txtCollectSR'), 'txtCollectSR')
    }
    catch {
      showMessage(thisObj, 'Error occurred during ontxtCollectSRChange event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtCollectSRChange = ontxtCollectSRChange;

  const ontxtInvoiceNumChange = async () => {
    try {
      setLoading(true)
      let txtInvoiceNumValue = getValue(thisObj, 'txtInvoiceNum')
      setValue(thisObj, 'txtInvoiceNum', validateTextBox1(txtInvoiceNumValue))
    }
    catch {
      showMessage(thisObj, 'Error occurred during ontxtInvoiceNumChange event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtInvoiceNumChange = ontxtInvoiceNumChange;

  const validateTextBox1 = (data) => {
    try {
      var res = ''
      for (var i = 0; i < data.length; i++) {
        if (data[i].charCodeAt(0) != 38) {
          res += data[i]
        }
      }
      return res;
    }
    catch {
      showMessage(thisObj, 'Error occurred during validateTextBox1 event')
    }
  }

  const ontxtCarrierAbbrNumBlur = async (event) => {
    try {
      setLoading(true)
      let lstrxml = []
      let txtCarrierAbbrNumValue = event?.target?.value

      if (txtCarrierAbbrNumValue != undefined && txtCarrierAbbrNumValue != null && txtCarrierAbbrNumValue != '') {
        setValue(thisObj, 'txtCarrierAbbrNum', txtCarrierAbbrNumValue.toUpperCase())
      }

      lstrxml = await AccountPayment.RetrieveScacVendorLookupDetails(null, txtCarrierAbbrNumValue)
      if (lstrxml != undefined && lstrxml != null && lstrxml.length > 0) {
        setValue(thisObj, 'txtVendorNum', lstrxml[0].number)
        await bFillRemitToList(lstrxml[0].number)
        await bFillRemitToList2(lstrxml[0].number)

        await EnableDisable()
      }
    }
    catch {
      showMessage(thisObj, 'Error occurred during ontxtCarrierAbbrNumBlur event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtCarrierAbbrNumBlur = ontxtCarrierAbbrNumBlur;

  const ontxtVendorNumBlur = async (event) => {
    try {
      setLoading(true)
      let lstrxml = []
      let txtVendorNumNumber = event?.target?.value

      let vendorDetails = getData(thisObj, 'vendorDetails')
      if(vendorDetails != undefined && vendorDetails != null && vendorDetails != ''){
        txtVendorNumNumber = vendorDetails.VendorNumber
      }

      lstrxml = await AccountPayment.RetrieveScacVendorLookupDetails(txtVendorNumNumber, null)

      if (lstrxml != undefined && lstrxml != null && lstrxml.length > 0) {
        setValue(thisObj, 'txtCarrierAbbrNum', lstrxml[0].scac)
        await bFillRemitToList(txtVendorNumNumber)
        await bFillRemitToList2(txtVendorNumNumber)

        await EnableDisable()
      }
      if(getData(thisObj, 'searchBlurFlag')){
        await DoSearch()
      }
    }
    catch {
      showMessage(thisObj, 'Error occurred during ontxtVendorNumBlur event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtVendorNumBlur = ontxtVendorNumBlur;

  const checkAgainstValueOfCheck = async (Value, Name) => {
    try {
      setLoading(true)
      let ldblValueOfCheck = 0
      let txtValueOfRTPsValue = getValue(thisObj, 'txtValueOfRTPs')
      let txtCollectSRValue = getValue(_kaledo.thisObj, 'txtCollectSR')
      let txtCollectARValue = getValue(_kaledo.thisObj, 'txtCollectAR')
      let txtCollectCRValue = getValue(_kaledo.thisObj, 'txtCollectCR')

      if (txtValueOfRTPsValue != undefined && txtValueOfRTPsValue != null && txtValueOfRTPsValue != '') {
        if (Name != 'txtCollectSR') {
          ldblValueOfCheck = ldblValueOfCheck + Number(txtCollectSRValue)
        }
        if (Name != 'txtCollectAR') {
          ldblValueOfCheck = ldblValueOfCheck + Number(txtCollectARValue)
        }
        if (Name != 'txtCollectCR') {
          ldblValueOfCheck = ldblValueOfCheck + Number(txtCollectCRValue)
        }
        if ((Number(Value) + ldblValueOfCheck) > Number(txtValueOfRTPsValue)) {
          if (Name == 'txtCollectSR') {
            setValue(thisObj, 'txtCollectSR', (Number(txtValueOfRTPsValue) - ldblValueOfCheck))
          }
          if (Name == 'txtCollectAR') {
            setValue(thisObj, 'txtCollectAR', (Number(txtValueOfRTPsValue) - ldblValueOfCheck))
          }
          if (Name == 'txtCollectCR') {
            setValue(thisObj, 'txtCollectCR', (Number(txtValueOfRTPsValue) - ldblValueOfCheck))
          }
        }

        await Calc()
      }
    }
    catch {
      showMessage(thisObj, 'Error occurred during checkAgainstValueOfCheck event')
    }
    finally{
      setLoading(false)
    }
  }

  const DoSearch = async () => {
    try {
      setLoading(true)
      let lstrxml = []
      let LstrXML2 = []
      let iCol = 0
      let iRow1 = 0
      let iRow2 = 0
      let iList = []
      let js = []
      let js2 = []
      let vsfHold = {}
      let vsfRelease = {}
      let bfirst = false
      let bFuelSurchargeFound = false

      setData(thisObj ,'searchBlurFlag', true)
      let txtVendorNumValue = getValue(_kaledo.thisObj, 'txtVendorNum')
      let txtCarrierAbbrNumValue = getValue(_kaledo.thisObj, 'txtCarrierAbbrNum')

      if ((txtVendorNumValue == undefined || txtVendorNumValue == null || txtVendorNumValue == '') &&
        (txtCarrierAbbrNumValue == undefined || txtCarrierAbbrNumValue == null || txtCarrierAbbrNumValue == '')) {
        showMessage(thisObj, "The Vendor # or Scac # is required!")
        return
      }

      let COLL_PT = getValue(_kaledo.thisObj, 'ddCollectionLocation')
      let SHP_BUY_PT = getValue(_kaledo.thisObj, 'ddShippingLocation')
      let REC_BUY_PT = getValue(_kaledo.thisObj, 'ddDestinationLocation')
      let FRT_SCAC_ID = getValue(_kaledo.thisObj, 'txtCarrierAbbrNum')
      let FRT_VENDOR = getValue(_kaledo.thisObj, 'txtVendorNum')
      let CROP_YEAR = getValue(_kaledo.thisObj, 'ddCropYear')

      lstrxml = await StockTransferService.RetrieveFreightAccrualSearchUnreleased(CROP_YEAR, COLL_PT, SHP_BUY_PT, REC_BUY_PT, FRT_SCAC_ID, FRT_VENDOR)
      if (lstrxml != undefined && lstrxml != null && lstrxml.length > 0) {
        for (let i = 0; i < lstrxml.length; i++) {
          if (lstrxml[i].save_ind == 'Y') {
            setData(thisObj, 'lblVendorNumber', lstrxml[i].frt_vendor)
            vsfRelease.IsSubtotal = true
            vsfRelease.C1_TRANSACTION = lstrxml[i].transaction_num
            vsfRelease.txtcolPayTypeR = "FA"
            vsfRelease.txtcolCropYearR = lstrxml[i].crop_year
            vsfRelease.txtcolShpBuyPtR = lstrxml[i].shp_buy_pt
            vsfRelease.txtcolRecBuyPtR = lstrxml[i].rec_buy_pt
            vsfRelease.txtcolTransferNumR = lstrxml[i].xfer_num
            vsfRelease.FreightAmt = 'Freight  ' + lstrxml[i].freight_amt
            vsfRelease.Frt_Pd = lstrxml[i].frt_pd[0].code + ' ' + lstrxml[i].frt_pd[0].amt

            vsfRelease.txtcolProceedR = Number(lstrxml[i]?.proceeds)?.toFixed(2)
            vsfRelease.C1_XFER_REV = lstrxml[i].xfer_rev
            vsfRelease.C1_XFER_TYP = lstrxml[i].xfer_type
            vsfRelease.C1_STAT_IND = 'R'
            vsfRelease.C1_SAVE_IND = lstrxml[i].save_ind
            vsfRelease.C1_EXP_FUEL = lstrxml[i].expired_fuel

            let object = {};
            let ExpArray2 = []
            for(var x = 0; x < lstrxml[i].frt_pd?.length; x++){
              object.xfer_num = lstrxml[i].xfer_num;
              object.freight_amt = (x == 0 ? 'Freight  ' + lstrxml[i].freight_amt : '')
              object.frt_pd = lstrxml[i].frt_pd[x].code + ' ' + lstrxml[i].frt_pd[x].amt
              ExpArray2.push(object)
              object = {}
            }

            // object.xfer_num = lstrxml[i].xfer_num;
            // object.freight_amt = 'Freight  ' + lstrxml[i].freight_amt;
            // object.frt_pd = lstrxml[i].frt_pd[0].code + ' ' + lstrxml[i].frt_pd[0].amt
            // ExpArray2.push(object)
            vsfRelease.expArray2 = ExpArray2;

            bfirst = true
            bFuelSurchargeFound = false
            LstrXML2 = lstrxml[i].frt_pd

            if (LstrXML2 != undefined && LstrXML2.length > 0) {
              for (let j = 0; j < LstrXML2.length; j++) {
                vsfRelease.IsSubtotal = false
                vsfRelease.C2_XFER_NUM = lstrxml[i].xfer_num
                vsfRelease.C2_PREM_DEDCT = LstrXML2[j].code + ' ' + LstrXML2[j].amt

                if (isNaN(LstrXML2[j].amt) == false) {
                  if ((LstrXML2[j].rate_type == 'FUEL') && (Number(LstrXML2[j].amt) != 0)) {
                    bFuelSurchargeFound = true
                  }
                }

                if (bfirst == true) {
                  vsfRelease.C2_FRT_AMT = " Freight " + lstrxml[i].freight_amt
                  bfirst = false
                }
              }
            }

            if (bFuelSurchargeFound == false) {
              vsfRelease.C1_NO_FUEL = 'Y' // To add cell color **
              vsfRelease.txtcolColorR = 'Y'
              show(thisObj, 'lblNoteRedPay')
            }
            else {
              vsfRelease.C1_NO_FUEL = 'N'
              vsfRelease.txtcolColorR = 'N'
            }

            js2.push(vsfRelease)
            vsfRelease = {}
          }
          else {
            setData(thisObj, 'lblVendorNumber', lstrxml[i].frt_vendor)
            vsfHold.IsSubtotal = true
            vsfHold.C1_TRANSACTION = lstrxml[i].transaction_num
            vsfHold.txtcolPayType = "FA"
            vsfHold.txtcolCropYearH = lstrxml[i].crop_year
            vsfHold.txtcolShpBuyPtH = lstrxml[i].shp_buy_pt
            vsfHold.txtcolRecBuyPtH = lstrxml[i].rec_buy_pt
            vsfHold.txtcolTransferNumH = lstrxml[i].xfer_num
            vsfHold.FreightAmt = 'Freight  ' + lstrxml[i].freight_amt
            vsfHold.Frt_Pd = lstrxml[i].frt_pd[0].code + ' ' + lstrxml[i].frt_pd[0].amt

            let object = {};
            let ExpArray = []
            for(var x = 0; x < lstrxml[i].frt_pd?.length; x++){
              object.xfer_num = lstrxml[i].xfer_num;
              object.freight_amt = (x == 0 ? 'Freight  ' + lstrxml[i].freight_amt : '')
              object.frt_pd = lstrxml[i].frt_pd[x].code + ' ' + lstrxml[i].frt_pd[x].amt
              ExpArray.push(object)
              object = {}
            }
            // object.xfer_num = lstrxml[i].xfer_num;
            // object.freight_amt = 'Freight  ' + lstrxml[i].freight_amt;
            // object.frt_pd = lstrxml[i].frt_pd[0].code + ' ' + lstrxml[i].frt_pd[0].amt
            // ExpArray.push(object)
            vsfHold.expArray = ExpArray;

            vsfHold.txtcolProceedH = Number(lstrxml[i]?.proceeds)?.toFixed(2)
            vsfHold.C1_XFER_REV = lstrxml[i].xfer_rev
            vsfHold.C1_XFER_TYP = lstrxml[i].xfer_type
            vsfHold.C1_STAT_IND = 'H'
            vsfHold.C1_SAVE_IND = lstrxml[i].save_ind
            vsfHold.C1_EXP_FUEL = lstrxml[i].expired_fuel

            bfirst = true
            bFuelSurchargeFound = false
            LstrXML2 = lstrxml[i].frt_pd

            if (LstrXML2 != undefined && LstrXML2.length > 0) {
              for (let j = 0; j < LstrXML2.length; j++) {
                vsfHold.IsSubtotal = false
                vsfHold.C2_XFER_NUM = lstrxml[i].xfer_num
                vsfHold.C2_PREM_DEDCT = LstrXML2[j].code + ' ' + LstrXML2[j].amt

                if (isNaN(LstrXML2[j].amt) == false) {
                  if ((LstrXML2[j].rate_type == 'FUEL') && (Number(LstrXML2[j].amt) != 0)) {
                    bFuelSurchargeFound = true
                  }
                }

                if (bfirst == true) {
                  vsfHold.C2_FRT_AMT = " Freight " + lstrxml[i].freight_amt
                  bfirst = false
                }
              }
            }

            if (bFuelSurchargeFound == false) {
              vsfHold.C1_NO_FUEL = 'Y' // To add cell color **
              vsfHold.txtcolColorH = 'Y'
              show(thisObj, 'lblNoteRedPay')
            }
            else {
              vsfHold.C1_NO_FUEL = 'N'
              vsfHold.txtcolColorH = 'N'
            }

            js.push(vsfHold)
            vsfHold = {}
          }
        }

        setValue(thisObj, 'gridHoldFA', js)
        setValue(thisObj, 'gridReleaseFA', js2)

      }
      else {
        setValue(thisObj, 'gridHoldFA', [])
        setValue(thisObj, 'gridReleaseFA', [])
      }

      lstrxml = await ContractManagementService.RetrieveUserControlDetails(useridFromLS())
      if (lstrxml != undefined && lstrxml != null && lstrxml.length > 0) {
        setData(thisObj, 'lblCollectionPoint', lstrxml[0].buyPtId)

        if (lstrxml[0].buyPtId != undefined && lstrxml[0].buyPtId != null && lstrxml[0].buyPtId != '') {
          setValue(thisObj, 'ddSpoolLocation', lstrxml[0].buyPtId)
        }

        let buy_pt_id = lstrxml[0].buyPtId
        lstrxml = await ContractManagementService.RetrieveBuyingPointControlDetails(null, null, null, null, buy_pt_id)
        if (lstrxml != undefined && lstrxml != null && lstrxml.length > 0) {
          setValue(thisObj, 'txtEndCheckNumber', lstrxml[0].end_check) // FormatCheckNumber **
          setData(thisObj, 'chknumber', lstrxml[0].next_check) // FormatCheckNumber **
          setData(thisObj, 'LstrReceivable_Num', lstrxml[0].new_receivable_no)
          setValue(thisObj, 'txtCheckNumber', lstrxml[0].next_check) // FormatCheckNumber **
        }
      }


      let BuyPtId = getValue(_kaledo.thisObj, 'ddSpoolLocation')
      let acct_vendor = getValue(_kaledo.thisObj, 'txtVendorNum')
      lstrxml = await AccountPayment.RetrieveOpenAccountControlDetails(BuyPtId, acct_vendor)
      if (lstrxml != undefined && lstrxml != null && lstrxml.length > 0) {

        let txtValueOfRTPsValue = Number(getValue(thisObj, 'txtValueOfRTPs'))
        if (lstrxml[0].open_acct_control != undefined && lstrxml[0].open_acct_control != null && lstrxml[0].open_acct_control != '' && lstrxml[0].open_acct_control[0].sr_end_balance != undefined && lstrxml[0].open_acct_control[0].sr_end_balance != null && lstrxml[0].open_acct_control[0].sr_end_balance != '') {
          setValue(thisObj, 'txtSRBalance', lstrxml[0].open_acct_control[0].sr_end_balance)
        }
        else {
          setValue(thisObj, 'txtSRBalance', '0.00')
        }
        setValue(thisObj, 'txtCollectSR', '0.00')
        if ((txtValueOfRTPsValue > 0) && (Number(getValue(_kaledo.thisObj, 'txtSRBalance')))) {
          enable(thisObj, 'txtCollectSR')
        }

        if (lstrxml[0].open_acct_control != undefined && lstrxml[0].open_acct_control != null && lstrxml[0].open_acct_control != '' && lstrxml[0].open_acct_control[0].ar_end_balance != undefined && lstrxml[0].open_acct_control[0].ar_end_balance != null && lstrxml[0].open_acct_control[0].ar_end_balance != '') {
          setValue(thisObj, 'txtARBalance', lstrxml[0].open_acct_control[0].ar_end_balance)
        }
        else {
          setValue(thisObj, 'txtARBalance', '0.00')
        }
        setValue(thisObj, 'txtCollectAR', '0.00')
        if ((txtValueOfRTPsValue > 0) && (Number(getValue(_kaledo.thisObj, 'txtARBalance')))) {
          enable(thisObj, 'txtCollectAR')
        }

        if (lstrxml[0].open_acct_control != undefined && lstrxml[0].open_acct_control != null && lstrxml[0].open_acct_control != '' && lstrxml[0].open_acct_control[0].cr_end_balance != undefined && lstrxml[0].open_acct_control[0].cr_end_balance != null && lstrxml[0].open_acct_control[0].cr_end_balance != '') {
          setValue(thisObj, 'txtCRBalance', lstrxml[0].open_acct_control[0].cr_end_balance)
        }
        else {
          setValue(thisObj, 'txtCRBalance', '0.00')
        }
        setValue(thisObj, 'txtCollectCR', '0.00')
        if ((txtValueOfRTPsValue > 0) && (Number(getValue(_kaledo.thisObj, 'txtCRBalance')))) {
          enable(thisObj, 'txtCollectCR')
        }
      }

      await Calc()
      await EnableDisable()
      await onHoldNumber()
      setData(thisObj, 'searchBlurFlag', false)
    }
    catch {
      showMessage(thisObj, 'Error occurred during DoSearch event')
    }
    finally{
      setLoading(false)
    }
  }

  const EnableDisable = async () => {
    try {
      setLoading(true)
      let txtVendorValue = getValue(_kaledo.thisObj, 'txtVendor')
      let gridHoldFAValue = getValue(thisObj, 'gridHoldFA')
      let gridReleaseFAValue = getValue(thisObj, 'gridReleaseFA')

      if (txtVendorValue == undefined || txtVendorValue == null || txtVendorValue == '') {
        disable(thisObj, 'btnSearch')
      }
      else {
        enable(thisObj, 'btnSearch')
      }

      if (gridHoldFAValue != undefined && gridHoldFAValue.length > 0) {
        enable(thisObj, 'btnAdd')
      }
      else {
        disable(thisObj, 'btnAdd')
      }

      if ((gridHoldFAValue != undefined && gridHoldFAValue.length > 0) || (gridReleaseFAValue != undefined && gridReleaseFAValue.length > 0)) {
        enable(thisObj, 'btnSavePreviewSettlement')
      }
      else {
        disable(thisObj, 'btnSavePreviewSettlement')
      }

      if (gridReleaseFAValue != undefined && gridReleaseFAValue.length > 0) {
        enable(thisObj, 'btnRemove')
        enable(thisObj, 'btnReleasetoRTP')
        enable(thisObj, 'btnPrintChecks')
        enable(thisObj, 'btnSaveToBatch')
      }
      else {
        disable(thisObj, 'btnRemove')
        disable(thisObj, 'btnReleasetoRTP')
        disable(thisObj, 'btnPrintChecks')
        disable(thisObj, 'btnSaveToBatch')
      }
    }
    catch {
      showMessage(thisObj, 'Error occurred during EnableDisable event')
    }
    finally{
      setLoading(false)
    }
  }

  const onddSpoolLocationChange = async () => {
    try {
      setLoading(true)
      let txtCarrierAbbrNumValue = getValue(thisObj, 'txtCarrierAbbrNum')
      await EnableDisable()

      if (txtCarrierAbbrNumValue != undefined && txtCarrierAbbrNumValue != null && txtCarrierAbbrNumValue != '') {
        await DoSearch_AR_SR_CR()
      }
    }
    catch {
      showMessage(thisObj, 'Error occurred during onddSpoolLocationChange event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onddSpoolLocationChange = onddSpoolLocationChange;

  const DoSearch_AR_SR_CR = async () => {
    try {
      setLoading(true)
      let lstrxml = []

      let buy_pt_id = getValue(_kaledo.thisObj, 'ddSpoolLocation')
      let acct_vendor = getValue(_kaledo.thisObj, 'txtVendorNum')
      let txtValueOfRTPsValue = Number(getValue(thisObj, 'txtValueOfRTPs'))

      lstrxml = await AccountPayment.RetrieveOpenAccountControlDetails(buy_pt_id, acct_vendor)
      if (lstrxml != undefined && lstrxml != null && lstrxml.length > 0) {
        setValue(thisObj, 'txtSRBalance', lstrxml[0].open_acct_control[0].sr_end_balance)
        setValue(thisObj, 'txtCollectSR', '0.00')
        if ((txtValueOfRTPsValue > 0) && (Number(getValue(_kaledo.thisObj, 'txtSRBalance')))) {
          enable(thisObj, 'txtCollectSR')
        }

        setValue(thisObj, 'txtARBalance', lstrxml[0].open_acct_control[0].ar_end_balance)
        setValue(thisObj, 'txtCollectAR', '0.00')
        if ((txtValueOfRTPsValue > 0) && (Number(getValue(_kaledo.thisObj, 'txtARBalance')))) {
          enable(thisObj, 'txtCollectAR')
        }

        setValue(thisObj, 'txtCRBalance', lstrxml[0].open_acct_control[0].cr_end_balance)
        setValue(thisObj, 'txtCollectCR', '0.00')
        if ((txtValueOfRTPsValue > 0) && (Number(getValue(_kaledo.thisObj, 'txtCRBalance')))) {
          enable(thisObj, 'txtCollectCR')
        }
      }
    }
    catch {
      showMessage(thisObj, 'Error occurred during DoSearch_AR_SR_CR event')
    }
    finally{
      setLoading(false)
    }
  }

  const onbtnSearchClick = async (e) => {
    try {
      document.getElementById('txtVendorNum').blur()
      e.preventDefault()
      setLoading(true)
        await DoSearch()
    }
    catch {
      showMessage(thisObj, 'Error occurred during onbtnSearchClick event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onbtnSearchClick = onbtnSearchClick;

  const OpenTrasnferInfo = async (iCropYr, strShpBuyPt, strXferTyp, strxfernum) => {
    try {
      setLoading(true)
      let strNoSaveList = ''
      let strSaveList = ''
      let strXML = []

      let gridHoldFAValue = getValue(_kaledo.thisObj, 'gridHoldFA')
      let gridReleaseFAValue = getValue(_kaledo.thisObj, 'gridReleaseFA')

      for (let i = 0; i < gridHoldFAValue.length; i++) {
        if (gridHoldFAValue[i].C1_TRANSACTION != undefined && gridHoldFAValue[i].C1_TRANSACTION != null && gridHoldFAValue[i].C1_TRANSACTION != '') {
          strNoSaveList = strNoSaveList + gridHoldFAValue[i].C1_TRANSACTION + ','
        }
      }

      if (strNoSaveList != undefined && strNoSaveList != null && strNoSaveList != '') {
        strNoSaveList = strNoSaveList.slice(0, -1)
      }

      for (let j = 0; j < gridReleaseFAValue.length; j++) {
        if (gridReleaseFAValue[j].C1_TRANSACTION != undefined && gridReleaseFAValue[j].C1_TRANSACTION != null && gridReleaseFAValue[j].C1_TRANSACTION != '') {
          strSaveList = strSaveList + gridReleaseFAValue[j].C1_TRANSACTION + ','
        }
      }

      if (strSaveList != undefined && strSaveList != null && strSaveList != '') {
        strSaveList = strSaveList.slice(0, -1)
      }

      setData(thisObj, 'mbViewTransferdDetail', true)

      let mTransfer=new clsTransfer();
      let finalData= {}
      let Obj = {
        "mTransferNumber": strxfernum,
        "mTransferType": strXferTyp,
        "mShippingBuyingPointID": strShpBuyPt,
        "mCropYear": iCropYr
      }
      setData(thisObj, 'ObjData', Obj)
      await mTransfer.Load(Obj,iCropYr)
      let freightAccrualCompleted = await mTransfer.FreightAccrualCompleted()

      if(freightAccrualCompleted){
        setData(thisObj, 'mbViewTransferdDetail', false)
        finalData={
          "mTransfer":mTransfer,
          "TransferRole":"",
          "FromFrtAccrl":false,
          "DisplayViewReverse":true,
          "ViewPremiumsDeductionsFrtAccrl":true
        }
        setData(thisObj, 'PremiumDeductionsFrtAccrl', finalData);
        goTo(thisObj, "StockTransfer#PremiumDeductionsFrtAccrl#DEFAULT#true#Click")
      }
      else {
        finalData={
          "mTransfer":mTransfer,
          "TransferRole":"",
          "FromFrtAccrl":true,
          "ReCreate":false
        }
        setData(thisObj, 'StockTransfer', finalData);
        goTo(thisObj, "StockTransfer#StockTransfer#DEFAULT#true#Click")
      }

      let data = {}
      data.no_save_list = strNoSaveList
      data.save_list = strSaveList
      strXML = await StockTransferService.UpdateFreightAccrual(data)
      setData(thisObj, 'strXMLData', false)
      if (strXML != undefined && strXML != null && strXML.status == 200) {
        setData(thisObj, 'strXMLData', true)
      }
    }
    catch {
      showMessage(thisObj, 'Error occurred during OpenTrasnferInfo event')
    }
    finally{
      setLoading(false)
    }
  }

  const Calc = async () => {
    try {
      setLoading(true)
      let nn = 0.00
      let gridReleaseFAValue = getValue(_kaledo.thisObj, 'gridReleaseFA')

      if (gridReleaseFAValue != undefined && gridReleaseFAValue.length > 0) {
        for (let i = 0; i < gridReleaseFAValue.length; i++) {
          if (gridReleaseFAValue[i].C1_TRANSACTION != undefined && gridReleaseFAValue[i].C1_TRANSACTION != null && gridReleaseFAValue[i].C1_TRANSACTION != '') {
            nn = nn + Number(gridReleaseFAValue[i].txtcolProceedR)
          }
        }
      }

      setValue(thisObj, 'txtValueOfRTPs', nn.toFixed(2))

      if (nn <= 0) {
        await ClearTexts()
      }

      let txtCollectARValue = getValue(_kaledo.thisObj, 'txtCollectAR')
      let txtCollectSRValue = getValue(_kaledo.thisObj, 'txtCollectSR')
      let txtCollectCRValue = getValue(_kaledo.thisObj, 'txtCollectCR')

      if (isNaN(txtCollectARValue) != false) {
        txtCollectARValue = 0
      }
      if (isNaN(txtCollectSRValue) != false) {
        txtCollectSRValue = 0
      }
      if (isNaN(txtCollectCRValue) != false) {
        txtCollectCRValue = 0
      }

      setValue(thisObj, 'txtValueOfCheck', (nn - Number(txtCollectARValue) - Number(txtCollectSRValue) - Number(txtCollectCRValue)).toFixed(2))
      ontxtValueOfCheckBlur()

      await EnableDisableTextBoxes()
      await EnableDisable()

    }
    catch {
      showMessage(thisObj, 'Error occurred during Calc event')
    }
    finally{
      setLoading(false)
    }
  }

  const AssignInvoice = async (Memo) => {
    try {
      setLoading(true)
      let lstrxml = []
      let MstrXFER = ""
      let txtInvoiceNumValue = getValue(thisObj, 'txtInvoiceNum')
      let gridReleaseFAValue = getValue(thisObj, 'gridReleaseFA')

      if (txtInvoiceNumValue == undefined || txtInvoiceNumValue == null || txtInvoiceNumValue == '') {
        return;
      }

      if (gridReleaseFAValue != undefined && gridReleaseFAValue.length > 0) {
        for (let i = 0; i < gridReleaseFAValue.length; i++) {
          if (gridReleaseFAValue[i].txtcolCropYearH != undefined && gridReleaseFAValue[i].txtcolCropYearH != null && gridReleaseFAValue[i].txtcolCropYearH != '' &&
            gridReleaseFAValue[i].txtcolShpBuyPtH != undefined && gridReleaseFAValue[i].txtcolShpBuyPtH != null && gridReleaseFAValue[i].txtcolShpBuyPtH != '' &&
            gridReleaseFAValue[i].txtcolTransferNumH != undefined && gridReleaseFAValue[i].txtcolTransferNumH != null && gridReleaseFAValue[i].txtcolTransferNumH != '' &&
            gridReleaseFAValue[i].C1_XFER_TYP != undefined && gridReleaseFAValue[i].C1_XFER_TYP != null && gridReleaseFAValue[i].C1_XFER_TYP != ''
          ) {
            MstrXFER = MstrXFER + gridReleaseFAValue[i].txtcolCropYearH + ','
            MstrXFER = MstrXFER + gridReleaseFAValue[i].txtcolShpBuyPtH + ','
            MstrXFER = MstrXFER + gridReleaseFAValue[i].txtcolTransferNumH + ','
            MstrXFER = MstrXFER + gridReleaseFAValue[i].C1_XFER_TYP + '|'
          }
        }
      }

      let data = {}
      data.xfer_list = MstrXFER
      data.invoice = txtInvoiceNumValue

      let txtVendorNumValue = getValue(thisObj, 'txtVendorNum')

      lstrxml = await StockTransferService.UpdateFaInvoiceOnTransfer(txtVendorNumValue, Memo, data)

    }
    catch {
      showMessage(thisObj, 'Error occurred during AssignInvoice event')
    }
    finally{
      setLoading(false)
    }
  }

  const onbtnSavePreviewSettlementClick = async () => {
    try {
      setLoading(true)
      let strXML = []
      let sNoSaveList = ''
      let sSaveList = ''

      let gridHoldFAValue = getValue(thisObj, 'gridHoldFA')
      let gridReleaseFAValue = getValue(thisObj, 'gridReleaseFA')

      for (let i = 0; i < gridHoldFAValue.length; i++) {
        if (gridHoldFAValue[i].C1_TRANSACTION != undefined && gridHoldFAValue[i].C1_TRANSACTION != null && gridHoldFAValue[i].C1_TRANSACTION != '') {
          sNoSaveList = sNoSaveList + gridHoldFAValue[i].C1_TRANSACTION + ','
        }
      }

      if (sNoSaveList != undefined && sNoSaveList != null && sNoSaveList != '') {
        sNoSaveList = sNoSaveList.slice(0, -1)
      }

      for (let j = 0; j < gridReleaseFAValue.length; j++) {
        if (gridReleaseFAValue[j].C1_TRANSACTION != undefined && gridReleaseFAValue[j].C1_TRANSACTION != null && gridReleaseFAValue[j].C1_TRANSACTION != '') {
          sSaveList = sSaveList + gridReleaseFAValue[j].C1_TRANSACTION + ','
        }
      }

      if (sSaveList != undefined && sSaveList != null && sSaveList != '') {
        sSaveList = sSaveList.slice(0, -1)
      }

      let data = {}
      data.no_save_list = sNoSaveList
      data.save_list = sSaveList
      strXML = await StockTransferService.UpdateFreightAccrual(data)

      if (strXML.status != 200) {
        return;
      }

      let SS = Buffer.from(sSaveList).toString('base64');

      if (gridReleaseFAValue.length > 0) {
        strXML = await StockTransferService.RetrieveSettlementFreightAccrualDocumentDrafts(cropYearFromLS(), SS)

        if (strXML != undefined && strXML.length > 0) {
          let Obj = {}
          Obj.data = strXML
          Obj.Method = 'Fill_FASettlement'
          Obj.MemoNum = ''
          Obj.ReportType = "DRAFT"
          Obj.BtnName = 'btnSavePreviewSettlement'
          setData(thisObj, 'ReportPreviewTransferForFASettlement', Obj)
          goTo(thisObj, 'StockTransfer#ReportPreviewTransfer#DEFAULT#true#Click')
        }
      }
    }
    catch {
      showMessage(thisObj, 'Error occurred during onbtnSavePreviewSettlementClick event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onbtnSavePreviewSettlementClick = onbtnSavePreviewSettlementClick;

  const onbtnAddClick = async () => {
    try {
      setLoading(true)
      let gridHoldFAValue = getValue(thisObj, 'gridHoldFA')
      let gridReleaseFAValue = getValue(thisObj, 'gridReleaseFA')
      let obj = {}
      let SelectedRows = thisObj.state.gridHoldFA.selected
      let SelectedRow = []
      let res = []

      if(SelectedRows != undefined && SelectedRows != null && SelectedRows != ''){
        for(let i = 0; i < SelectedRows.length; i++){
          SelectedRow = SelectedRows[i]

          if (SelectedRow != undefined && SelectedRow != null && SelectedRow != '') {
            obj.C1_EXP_FUEL = SelectedRow.C1_EXP_FUEL
            obj.C1_NO_FUEL = SelectedRow.C1_NO_FUEL
            obj.txtcolColorR = SelectedRow.txtcolColorH
            obj.C1_SAVE_IND = SelectedRow.C1_SAVE_IND
            obj.C1_STAT_IND = 'R'
            obj.C1_TRANSACTION = SelectedRow.C1_TRANSACTION
            obj.C1_XFER_REV = SelectedRow.C1_XFER_REV
            obj.C1_XFER_TYP = SelectedRow.C1_XFER_TYP
            obj.IsSubtotal = SelectedRow.IsSubtotal
            obj.txtcolCropYearR = SelectedRow.txtcolCropYearH
            obj.txtcolPayTypeR = SelectedRow.txtcolPayType
            obj.txtcolProceedR = SelectedRow.txtcolProceedH
            obj.txtcolRecBuyPtR = SelectedRow.txtcolRecBuyPtH
            obj.txtcolShpBuyPtR = SelectedRow.txtcolShpBuyPtH
            obj.txtcolTransferNumR = SelectedRow.txtcolTransferNumH
            obj.FreightAmt = SelectedRow.FreightAmt;
            obj.Frt_Pd = SelectedRow.Frt_Pd
    
            let object = {};
            let ExpArray2 = []
            for(var x = 0; x < SelectedRow.expArray?.length; x++){
              object.xfer_num = SelectedRow.expArray[x].xfer_num;
              object.freight_amt = SelectedRow.expArray[x].freight_amt;
              object.frt_pd = SelectedRow.expArray[x].frt_pd;
              ExpArray2.push(object)
              object = {}
            }
            // object.xfer_num = SelectedRow.txtcolTransferNumH;
            // object.freight_amt = SelectedRow.FreightAmt;
            // object.frt_pd = SelectedRow.Frt_Pd
            // ExpArray2.push(object)
            obj.expArray2 = ExpArray2;
    
            obj.C2_XFER_NUM = SelectedRow.C2_XFER_NUM
            obj.C2_PREM_DEDCT = SelectedRow.C2_PREM_DEDCT
            obj.C2_FRT_AMT = SelectedRow.C2_FRT_AMT
    
            if (SelectedRow.C1_NO_FUEL == 'Y') {
              setData(thisObj, 'bHilite', true)
              show(thisObj, 'lblNoteRedPay')
            }
    
            gridReleaseFAValue.push(obj)
            obj = {}
            res.push(SelectedRow.txtcolTransferNumH)
          }
        }

        let tempArr = []
        for(var i=0; i<gridHoldFAValue?.length; i++){
          if(!res.includes(gridHoldFAValue[i].txtcolTransferNumH)){
            tempArr.push(gridHoldFAValue[i])
          }
        }

        setValue(thisObj, 'gridHoldFA', tempArr)
        setValue(thisObj, 'gridReleaseFA', gridReleaseFAValue)

        await onHoldNumber()
      }
      await Calc()
    }
    catch {
      showMessage(thisObj, 'Error occurred during onbtnAddClick event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onbtnAddClick = onbtnAddClick;

  const onbtnRemoveClick = async () => {
    try {
      setLoading(true)
      let gridHoldFAValue = getValue(thisObj, 'gridHoldFA')
      let gridReleaseFAValue = getValue(thisObj, 'gridReleaseFA')
      let obj = {}

      let SelectedRow = []
      let SelectedRows = thisObj.state.gridReleaseFA.selected
      let res = []

      if(SelectedRows != undefined && SelectedRows != null && SelectedRows != ''){
        for(let i =0; i < SelectedRows.length; i++){
          SelectedRow = SelectedRows[i]

          if (SelectedRow != undefined && SelectedRow != null && SelectedRow != '') {
            obj.C1_EXP_FUEL = SelectedRow.C1_EXP_FUEL
            obj.C1_NO_FUEL = SelectedRow.C1_NO_FUEL
            obj.txtcolColorH = SelectedRow.txtcolColorR
            obj.C1_SAVE_IND = SelectedRow.C1_SAVE_IND
            obj.C1_STAT_IND = 'H'
            obj.C1_TRANSACTION = SelectedRow.C1_TRANSACTION
            obj.C1_XFER_REV = SelectedRow.C1_XFER_REV
            obj.C1_XFER_TYP = SelectedRow.C1_XFER_TYP
            obj.IsSubtotal = SelectedRow.IsSubtotal
            obj.txtcolCropYearH = SelectedRow.txtcolCropYearR
            obj.txtcolPayType = SelectedRow.txtcolPayTypeR
            obj.txtcolProceedH = SelectedRow.txtcolProceedR
            obj.txtcolRecBuyPtH = SelectedRow.txtcolRecBuyPtR
            obj.txtcolShpBuyPtH = SelectedRow.txtcolShpBuyPtR
            obj.txtcolTransferNumH = SelectedRow.txtcolTransferNumR
            obj.FreightAmt = SelectedRow.FreightAmt;
            obj.Frt_Pd = SelectedRow.Frt_Pd
    
            obj.C2_XFER_NUM = SelectedRow.C2_XFER_NUM
            obj.C2_PREM_DEDCT = SelectedRow.C2_PREM_DEDCT
            obj.C2_FRT_AMT = SelectedRow.C2_FRT_AMT
    
            let object = {};
            let ExpArray = []
            for(var x = 0; x < SelectedRow.expArray?.length; x++){
              object.xfer_num = SelectedRow.expArray[x].xfer_num;
              object.freight_amt = SelectedRow.expArray[x].freight_amt;
              object.frt_pd = SelectedRow.expArray[x].frt_pd;
              ExpArray.push(object)
              object = {}
            }
            // object.xfer_num = SelectedRow.txtcolTransferNumR;
            // object.freight_amt = SelectedRow.FreightAmt;
            // object.frt_pd = SelectedRow.Frt_Pd
            // ExpArray.push(object)
            obj.expArray = ExpArray;
    
            if (SelectedRow.C1_NO_FUEL == 'Y') {
              setData(thisObj, 'bHilite', true)
              show(thisObj, 'lblNoteRedPay')
            }
    
            gridHoldFAValue.push(obj)
            obj = {}
            res.push(SelectedRow.txtcolTransferNumR)
            // gridReleaseFAValue.splice(selectedRowNumber, 1)
          }
        }

        let tempArr = []
        for(var i=0; i<gridReleaseFAValue?.length; i++){
          if(!res.includes(gridReleaseFAValue[i].txtcolTransferNumR)){
            tempArr.push(gridReleaseFAValue[i])
          }
        }

        setValue(thisObj, 'gridHoldFA', gridHoldFAValue)
        setValue(thisObj, 'gridReleaseFA', tempArr)

        await onHoldNumber()
      }

      await Calc()
    }
    catch {
      showMessage(thisObj, 'Error occurred during onbtnRemoveClick event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onbtnRemoveClick = onbtnRemoveClick;

  const onbtnCancelClick = async () => {
    document.getElementById("StockTransfer_FrtAccrlSettlementPopUp").childNodes[0].click();
  }
  thisObj.onbtnCancelClick = onbtnCancelClick;

  const onbtnPrintChecksClick = async () => {
    try {
      setLoading(true)
      let RCOUNT = 0
      let s = {}
      let X = ''
      let lstrxml = []
      let LstrReceivable_Num = ''
      let astrTransNum = ''
      let tempVal = ''
      let intCount = 0

      let vNum = getValue(thisObj, 'txtVendorNum')

      lstrxml = await ContractManagementService.RetrieveVendorByNumber(vNum)
      if (lstrxml == undefined || lstrxml == null || lstrxml.length <= 0) {
        showMessage(thisObj, "Vendor not found!")
        return
      }

      if (lstrxml[0].number == undefined || lstrxml[0].number == null || lstrxml[0].number == '') {
        showMessage(thisObj, "Vendor not found!")
        return
      }

      if (lstrxml[0].pay_ind != 'Y') {
        showMessage(thisObj, "The Vendor Master Pay indicator is not set to Y!")
        return
      }

      let ddSpoolLocationValue = getValue(thisObj, 'ddSpoolLocation')
      let ddRemitToValue = getValue(thisObj, 'ddRemitTo')

      if (ddSpoolLocationValue == undefined || ddSpoolLocationValue == null || ddSpoolLocationValue == '') {
        showMessage(thisObj, "The Spool Location is required!")
        return
      }

      if (ddRemitToValue == undefined || ddRemitToValue == null || ddRemitToValue == '') {
        showMessage(thisObj, "A Remit To must be selected!")
        return
      }

      let n = 0
      let txtValueOfCheckValue = getValue(thisObj, 'txtValueOfCheck')
      let isbCheck_AR_CR_SR = await bCheck_AR_CR_SR()
      if ((isbCheck_AR_CR_SR == true) && (Number(txtValueOfCheckValue) > 0)) {
        n = confirm("Do you wish to collect the AR, CR or SR that is due? ")
        if (n == true) {
          //  DoEvents **
          return
        }
      }

      let gridHoldFAValue = getValue(thisObj, 'gridHoldFA')
      let Obj = {}
      let Lst = []

      if (gridHoldFAValue != undefined && gridHoldFAValue != null && gridHoldFAValue.length > 0) {
        for (let i = 0; i < gridHoldFAValue.length; i++) {
          if (gridHoldFAValue[i].C1_STAT_IND == 'R') {
            RCOUNT = RCOUNT + 1
            Obj.tnum = gridHoldFAValue[i].C1_TRANSACTION.toString()
            // tnum.tn = gridHoldFAValue[i].C1_TRANSACTION
            // tnum.ty = "H"
            Lst.push(Obj)
            Obj = {}
          }
        }
      }

      let gridReleaseFAValue = getValue(thisObj, 'gridReleaseFA')

      if (gridReleaseFAValue != undefined && gridReleaseFAValue != null && gridReleaseFAValue.length > 0) {
        for (let i = 0; i < gridReleaseFAValue.length; i++) {
          RCOUNT = RCOUNT + 1
          Obj.tnum = gridReleaseFAValue[i].C1_TRANSACTION.toString()
          // tnum.tn = gridReleaseFAValue[i].C1_TRANSACTION
          // tnum.ty = "R"
          Lst.push(Obj)
          Obj = {}
        }
      }

      if (txtValueOfCheckValue != undefined && Number(txtValueOfCheckValue) > 0) {
        let tempVal = prompt("Enter check number");
        if (tempVal != undefined && isNaN(tempVal) != false) {
          showMessage(thisObj, "Check Number must be numeric")
          return
        }

        if (Number(tempVal) <= 0) {
          showMessage(thisObj, "The Check Number cannot be Zero !")
          return
        }

        let txtEndCheckNumberValue = getValue(thisObj, 'txtEndCheckNumber')

        if (Number(tempVal) > Number(txtEndCheckNumberValue)) {
          showMessage(thisObj, "The Check Number exceeds number range!")
          return
        }

        setData(thisObj, 'chknumber', tempVal)
        setValue(thisObj, 'txtCheckNumber', tempVal)
      }

      let txtCollectCRValue = getValue(_kaledo.thisObj, 'txtCollectCR')
      let txtCollectARValue = getValue(_kaledo.thisObj, 'txtCollectAR')
      let txtCollectSRValue = getValue(_kaledo.thisObj, 'txtCollectSR')
      let txtVendorNumValue = getValue(_kaledo.thisObj, 'txtVendorNum')
      let txtCheckNumberValue = getValue(_kaledo.thisObj, 'txtCheckNumber')
      let ddCollectionLocationValue = getData(thisObj, 'lblCollectionPoint')
      let txtInvoiceNumValue = getValue(_kaledo.thisObj, 'txtInvoiceNum')

      txtInvoiceNumValue = txtInvoiceNumValue == undefined || txtInvoiceNumValue == null || txtInvoiceNumValue == '' ? '' : txtInvoiceNumValue

      let obj = {}
      obj.comp_id = compIdFromLS()
      obj.crop_year = cropYearFromLS()
      obj.buy_pt_id = ddSpoolLocationValue
      obj.cbuy_pt_id = ddCollectionLocationValue
      obj.check_num = txtCheckNumberValue
      obj.user_id = useridFromLS()
      obj.acct_vendor = txtVendorNumValue
      obj.remitto = ddRemitToValue
      obj.invoice = txtInvoiceNumValue
      obj.recount = RCOUNT
      obj.transaction_numbers = Lst
      obj.collectcr = txtCollectCRValue
      obj.collectar = txtCollectARValue
      obj.collectsr = txtCollectSRValue

      lstrxml = await StockTransferService.CreateReleaseFaRepor(obj)

      if (lstrxml.status != 200) {
        showMessage(thisObj, lstrxml.message)
        await GetNextCheckNum(ddSpoolLocationValue)
        await Calc()
        return
      }
      else {
        LstrReceivable_Num = lstrxml?.result[0]?.receivablE_NUM
        if (LstrReceivable_Num != '0' && LstrReceivable_Num?.length > 0 && isNaN(txtCheckNumberValue) == true) {
          LstrReceivable_Num = 'S' + '0'.repeat(5 - LstrReceivable_Num.length) + LstrReceivable_Num
          if (LstrReceivable_Num != txtCheckNumberValue) {
            showMessage(thisObj, "System Void Check: The check was created with a new number ")
            setValue(thisObj, 'txtCheckNumber', LstrReceivable_Num)
          }
        }
        setData(thisObj, 'LstrReceivable_Num', LstrReceivable_Num)

        let intMemo = lstrxml.result[0].memo_num
        let intAuditPayCyr = lstrxml.result[0].audit_pay_cyr
        setData(thisObj, 'intAuditPayCyr', intAuditPayCyr)
        setData(thisObj, 'intMemo', intMemo)

        await AssignInvoice(intMemo)

        txtCheckNumberValue = getValue(_kaledo.thisObj, 'txtCheckNumber')
        if (txtCheckNumberValue != undefined && txtCheckNumberValue != null && txtCheckNumberValue != '' && isNaN(txtCheckNumberValue) == false) {
          let LstrXMLCopy = await AccountPayment.RetrieveBatchCheckPrintDetails(ddSpoolLocationValue, txtCheckNumberValue, txtVendorNumValue)
          if (LstrXMLCopy != undefined && LstrXMLCopy != null && LstrXMLCopy.length > 0) {
            let navigateObj = [{
              vendor: txtVendorNumValue,
              buying_Point: ddSpoolLocationValue,
              check_num: txtCheckNumberValue,
              Title: 'ORIGIN BUYING POINT',
              ScreenName: 'READYTOPAYRELEASE',
            }]
            setData(thisObj, "ReportPreviewCheck", navigateObj)
            goTo(thisObj, "AccountPayment#ReportPreviewCheck#DEFAULT#true#Click");
          }
          else {
            await ReportPreviewTransferObj()
          }
        }
        else {
          await ReportPreviewTransferObj()
        }
      }

      await ClearFields()

    }
    catch {
      showMessage(thisObj, 'Error occurred during onbtnPrintChecksClick event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onbtnPrintChecksClick = onbtnPrintChecksClick;

  const ReportPreviewTransferObj = async () => {
    try {
      setLoading(true)
      let intAuditPayCyr = getData(thisObj, 'intAuditPayCyr')
      let intMemo = getData(thisObj, 'intMemo')

      let LstrList = await StockTransferService.RetrieveSettlementFreightAccrualDocuments(cropYearFromLS(), intAuditPayCyr, intMemo)
      if (LstrList != undefined && LstrList != null && LstrList.length > 0) {
        let Obj = {}
        Obj.data = LstrList
        Obj.Method = 'Fill_FASettlement'
        Obj.MemoNum = intMemo
        Obj.ReportType = ""
        Obj.BtnName = 'btnPrintChecks'
        setData(thisObj, 'ReportPreviewTransferForFASettlement', Obj)
        goTo(thisObj, 'StockTransfer#ReportPreviewTransfer#DEFAULT#true#Click')
      }
    }
    catch {
      showMessage(thisObj, 'Error occurred during ReportPreviewTransferObj event')
    }
    finally{
      setLoading(false)
    }
  }

  const onbtnReleasetoRTPClick = async () => {
    try {
      setLoading(true)
      let RCOUNT = 0
      let n = 0
      let s = ''
      let strXML = []
      let intMemo = 0
      let astrTransNum = {}
      let intCount = 0

      let txtVendorNumValue = getValue(thisObj, 'txtVendorNum')
      strXML = await ContractManagementService.RetrieveVendorByNumber(txtVendorNumValue)

      if (strXML == undefined || strXML == null || strXML.length <= 0) {
        showMessage(thisObj, "Vendor not found!")
        return
      }

      if (strXML[0].number == undefined || strXML[0].number == null || strXML[0].number == '') {
        showMessage(thisObj, "Vendor not found!")
        return
      }

      if (strXML[0].pay_ind != 'Y') {
        showMessage(thisObj, "The Vendor Master Pay indicator is not set to Y!")
        return
      }

      let ddSpoolLocationValue = getValue(thisObj, 'ddSpoolLocation')
      if (ddSpoolLocationValue == undefined || ddSpoolLocationValue == null || ddSpoolLocationValue == '') {
        showMessage(thisObj, "The Spool Location is required!")
        return
      }

      let ddRemitToValue = getValue(thisObj, 'ddRemitTo')
      if (ddRemitToValue == undefined || ddRemitToValue == null || ddRemitToValue == '') {
        showMessage(thisObj, "A Remit To must be selected!")
        return
      }

      let gridReleaseFAValue = getValue(thisObj, 'gridReleaseFA')
      let Obj = {}
      let Lst = []
      if (gridReleaseFAValue != undefined && gridReleaseFAValue.length > 0) {
        for (let i = 0; i < gridReleaseFAValue.length; i++) {
          RCOUNT = RCOUNT + 1
          Obj.stnum = gridReleaseFAValue[i].C1_TRANSACTION.toString()
          // stnum.tn = gridReleaseFAValue[i].C1_TRANSACTION
          // stnum.ty = "R"
          Lst.push(Obj)
          Obj = {}
        }
      }

      if (RCOUNT == 0) {
        showMessage(thisObj, "There were no changes found that need to be saved!")
        return
      }

      let txtInvoiceNumValue = getValue(thisObj, 'txtInvoiceNum')

      if(txtInvoiceNumValue == undefined || txtInvoiceNumValue == null){
        txtInvoiceNumValue = ''
      }

      let data = {}
      data.recount = RCOUNT
      data.transaction_number = Lst
      data.vendor = txtVendorNumValue
      data.remit = ddRemitToValue
      data.buy_pt_id = ddSpoolLocationValue
      data.invoice = txtInvoiceNumValue
      data.comp_id = compIdFromLS()
      data.crop_year = cropYearFromLS()
      data.user_id = useridFromLS()

      strXML = await StockTransferService.CreateSettlementFreightAccrual(data)
      if (strXML.status != 200) {
        showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.')
        return
      }
      else {
        showMessage(thisObj, "The Ready to pay has been created.", true)

        intMemo = (strXML.result[0].successful).trim()
        let intAuditPayCyr = strXML.result[0].audit_pay_cyr

        await AssignInvoice(intMemo)
        let dataObj = {
          new_invoice_num: txtInvoiceNumValue.toUpperCase()
        }
        //change for PRB0053900
        let response = await StockTransferService.UpdateSettlementFreightAcculInvoice(cropYearFromLS(), intMemo, dataObj)
                    if (response != undefined) {
                      if(response.status != '200'){                      
                        showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.')
                return
                      }
                    }
    //change for PRB0053900
        strXML = await StockTransferService.RetrieveSettlementFreightAccrualDocuments(cropYearFromLS(), intAuditPayCyr, intMemo)
        if (strXML != undefined && strXML != null && strXML.length > 0) {
          let Obj = {}
          Obj.data = strXML
          Obj.Method = 'Fill_FASettlement'
          Obj.MemoNum = intMemo
          Obj.ReportType = ""
          Obj.BtnName = 'btnReleasetoRTP'
          setData(thisObj, 'ReportPreviewTransferForFASettlement', Obj)
          goTo(thisObj, 'StockTransfer#ReportPreviewTransfer#DEFAULT#true#Click')
        }
      }

      await ClearFields()
    }
    catch {
      showMessage(thisObj, 'Error occurred during onbtnReleasetoRTPClick event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onbtnReleasetoRTPClick = onbtnReleasetoRTPClick;

  const onbtnSaveToBatchClick = async () => {
    try {
      setLoading(true)
      let LlngRCount = 0
      let LstrCMD = ''
      let lstrxml = []
      let astrTransNum = {}

      let txtVendorNumValue = getValue(thisObj, 'txtVendorNum')
      lstrxml = await ContractManagementService.RetrieveVendorByNumber(txtVendorNumValue)
      if (lstrxml == undefined || lstrxml == null || lstrxml.length <= 0) {
        showMessage(thisObj, "Vendor not found!")
        return
      }

      if (lstrxml[0].number == undefined || lstrxml[0].number == null || lstrxml[0].number == '') {
        showMessage(thisObj, "Vendor not found!")
        return
      }

      if (lstrxml[0].pay_ind != 'Y') {
        showMessage(thisObj, "The Vendor Master Pay indicator is not set to Y!")
        return;
      }

      let ddSpoolLocationValue = getValue(thisObj, 'ddSpoolLocation')

      if (ddSpoolLocationValue == undefined || ddSpoolLocationValue == null || ddSpoolLocationValue == '') {
        showMessage(thisObj, "The Spool Location is required!")
        return;
      }

      let ddRemitToValue = getValue(thisObj, 'ddRemitTo')
      if (ddRemitToValue == undefined || ddRemitToValue == null || ddRemitToValue == '') {
        showMessage(thisObj, "A Remit To must be selected!")
        return;
      }

      let isbCheck_AR_CR_SR = await bCheck_AR_CR_SR()
      let txtValueOfCheckValue = getValue(thisObj, 'txtValueOfCheck')

      if ((isbCheck_AR_CR_SR == true) && (Number(txtValueOfCheckValue) > 0)) {
        let n = confirm("Do you wish to collect the AR, CR or SR that is due? ")
        if (n == true) {
          //  DoEvents **
          return
        }
      }

      let gridHoldFAValue = getValue(thisObj, 'gridHoldFA')
      let obj = {}
      let Lst = []
      if (gridHoldFAValue != undefined && gridHoldFAValue.length > 0) {
        for (let i = 0; i < gridHoldFAValue.length; i++) {
          if (gridHoldFAValue[i].C1_STAT_IND == 'R') {
            LlngRCount = LlngRCount + 1
            obj.tn = gridHoldFAValue[i].C1_TRANSACTION.toString()
            obj.ty = "H"
            Lst.push(obj)
            obj = {}
          }
        }
      }

      let gridReleaseFAValue = getValue(thisObj, 'gridReleaseFA')
      if (gridReleaseFAValue != undefined && gridReleaseFAValue.length > 0) {
        for (let i = 0; i < gridReleaseFAValue.length; i++) {
          LlngRCount = LlngRCount + 1
          obj.tn = gridReleaseFAValue[i].C1_TRANSACTION.toString()
          obj.ty = "B"
          Lst.push(obj)
          obj = {}
        }
      }

      let txtCollectCRValue = getValue(thisObj, 'txtCollectCR')
      let txtCollectARValue = getValue(thisObj, 'txtCollectAR')
      let txtCollectSRValue = getValue(thisObj, 'txtCollectSR')
      let txtInvoiceNumValue = getValue(thisObj, 'txtInvoiceNum')

      let data = {}
      data.rcount = LlngRCount
      data.collectcr = txtCollectCRValue
      data.collectar = txtCollectARValue
      data.collectsr = txtCollectSRValue
      data.iamt = 0
      data.remitto = ddRemitToValue
      data.acct_vendor = txtVendorNumValue
      data.invoice_num = txtInvoiceNumValue
      data.transactions = Lst
      data.agree_num = ''
      data.remarks = ''
      data.user_id = useridFromLS()

      lstrxml = await StockTransferService.UpdateFaBatchReleaseReadyToPayDetails(ddSpoolLocationValue, data)
      if (lstrxml == undefined || lstrxml == null || lstrxml.length <= 0 || lstrxml.status != 200) {
        showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.")
        return
      }
      else {
        showMessage(thisObj, "Check was saved to Batch successfully!", true)
        let Str1 = (lstrxml.result).split(',')
        let intMemo = ''
        if (Str1 != undefined && Str1 != null && Str1 != '' && Str1[1] != undefined && Str1[1] != null && Str1[1] != '') {
          let Str2 = Str1[1].split(':')
          if (Str2 != undefined && Str2 != null && Str2 != '' && Str2[1] != undefined && Str2[1] != null && Str2[1] != '') {
            intMemo = Str2[1]
          }
        }

        await AssignInvoice(intMemo)

        let LstrList = await StockTransferService.RetrieveSettlementFreightAccrualDocuments(cropYearFromLS(), cropYearFromLS(), intMemo)
        if (LstrList != undefined && LstrList != null && LstrList.length > 0) {
          let Obj = {}
          Obj.data = LstrList
          Obj.Method = 'Fill_FASettlement'
          Obj.MemoNum = intMemo
          Obj.ReportType = ""
          Obj.BtnName = 'btnSaveToBatch'
          setData(thisObj, 'ReportPreviewTransferForFASettlement', Obj)
          goTo(thisObj, 'StockTransfer#ReportPreviewTransfer#DEFAULT#true#Click')
        }
      }

      await ClearFields()
    }
    catch {
      showMessage(thisObj, 'Error occurred during onbtnSaveToBatchClick event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onbtnSaveToBatchClick = onbtnSaveToBatchClick;

  const Form_Load = async () => {
    try {
      hide(thisObj, 'lblNoteRedPay', false)
      hide(thisObj, 'txtCheckNumber', false)
      hide(thisObj, 'txtEndCheckNumber', false)

      disable(thisObj, 'txtCollectAR')
      disable(thisObj, 'txtCollectCR')
      disable(thisObj, 'txtCollectSR')

      disable(thisObj, 'btnSavePreviewSettlement')
      disable(thisObj, 'btnSaveToBatch')
      disable(thisObj, 'btnPrintChecks')
      disable(thisObj, 'btnReleasetoRTP')
      disable(thisObj, 'btnSearch')
      disable(thisObj, 'btnAdd')
      disable(thisObj, 'btnRemove')

      setValue(thisObj, 'txtFAToPays', '0')
      setValue(thisObj, 'txtFAOnHold', '0')

      let lstrxml = []

      await bFillCropYearList()
      await bFillCollectionPointList()
      await bFillBuyingPointList_rcv()
      await bFillLocationList()
      await bFillBuyingPointListForCollectPt()

      lstrxml = await ContractManagementService.RetrieveAccessPermissionDetails('PN1100', '001', 'U', null)
      if (lstrxml != undefined && lstrxml[0].permission != undefined && lstrxml[0].permission == 'Y') {
        setData(thisObj, 'cmdRemoveTag', 'Y')
      }

      lstrxml = await ContractManagementService.RetrieveAccessPermissionDetails('PN1100', '004', 'U', null)
      if (lstrxml != undefined && lstrxml[0].permission != undefined && lstrxml[0].permission == 'Y') {
        setData(thisObj, 'cmdAddTag', 'Y')
      }
    }
    catch {
      showMessage(thisObj, 'Error occurred during Form_Load event')
    }
    finally {
      setLoading(false)
    }
  }

  const ontxtValueOfCheckBlur = async () => {
    try {
      setLoading(true)
      let txtValueOfCheckValue = getValue(_kaledo.thisObj, 'txtValueOfCheck')
      let chkNumber = getData(thisObj, 'chknumber')
      let LstrReceivable_Num = getData(thisObj, 'LstrReceivable_Num')
      let FormattedCheckNumber = ''


      if (Number(txtValueOfCheckValue) > 0) {
        setValue(thisObj, 'txtCheckNumber', chkNumber)
      }
      else {
        if (LstrReceivable_Num != undefined && LstrReceivable_Num.length > 0) {
          FormattedCheckNumber = 'S' + '0'.repeat(5 - LstrReceivable_Num.length) + LstrReceivable_Num
          setValue(thisObj, 'txtCheckNumber', FormattedCheckNumber)
        }
      }

      await EnableDisable()
    }
    catch {
      showMessage(thisObj, 'Error occurred during ontxtValueOfCheckBlur event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtValueOfCheckBlur = ontxtValueOfCheckBlur;

  const bCheck_AR_CR_SR = async () => {
    try {
      setLoading(true)
      let isbCheck_AR_CR_SR = false

      let txtCRBalanceValue = getValue(thisObj, 'txtCRBalance')
      let txtCollectCRValue = getValue(thisObj, 'txtCollectCR')
      let txtARBalanceValue = getValue(thisObj, 'txtARBalance')
      let txtCollectARValue = getValue(thisObj, 'txtCollectAR')
      let txtSRBalanceValue = getValue(thisObj, 'txtSRBalance')
      let txtCollectSRValue = getValue(thisObj, 'txtCollectSR')

      if (Number(txtCRBalanceValue) > 0 && Number(txtCollectCRValue) == 0) {
        isbCheck_AR_CR_SR = true
      }

      if (Number(txtSRBalanceValue) > 0 && Number(txtCollectSRValue) == 0) {
        isbCheck_AR_CR_SR = true
      }

      if (Number(txtARBalanceValue) > 0 && Number(txtCollectARValue) == 0) {
        isbCheck_AR_CR_SR = true
      }

      return isbCheck_AR_CR_SR
    }
    catch {
      showMessage(thisObj, 'Error occurred during bCheck_AR_CR_SR event')
    }
    finally {
      setLoading(false)
    }
  }

  const ongridHoldFADblclick = async () => {
    try {
      setLoading(true)
      if (thisObj.state.gridHoldFA.selected[0] !== undefined) {
        await onbtnAddClick()
      }
    }
    catch {
      showMessage(thisObj, 'Error occurred during ongridHoldFADblclick event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ongridHoldFADblclick = ongridHoldFADblclick;

  const ongridReleaseFADblclick = async () => {
    try {
      setLoading(true)
      if (thisObj.state.gridReleaseFA.selected[0] !== undefined) {
        await onbtnRemoveClick()
      }
    }
    catch {
      showMessage(thisObj, 'Error occurred during ongridReleaseFADblclick event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ongridReleaseFADblclick = ongridReleaseFADblclick;

  const onbtncol1Click = async (event) => {
    try {
      setLoading(true)
      let highlight = document.getElementsByClassName("activeRow")
      if (highlight.length > 0) {
        highlight[0].classList.remove("activeRow")
      }
      let rowSelected = event.target.parentElement.parentElement.parentElement
      rowSelected.classList.add("activeRow")
      let rowID = rowSelected.id.substring(4);//changes done for PRB0053900 
      let gridHoldFAValue = getValue(_kaledo.thisObj, 'gridHoldFA')
      let SelectedRow = []
      if (gridHoldFAValue != undefined && gridHoldFAValue != null && gridHoldFAValue.length > 0) {
        SelectedRow = gridHoldFAValue[rowID]
        await OpenTrasnferInfo(SelectedRow.txtcolCropYearH, SelectedRow.txtcolShpBuyPtH, SelectedRow.C1_XFER_TYP, SelectedRow.txtcolTransferNumH)
      }
    }
    catch {
      showMessage(thisObj, 'Error occurred during onbtncol1Click event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onbtncol1Click = onbtncol1Click;

  const onbtncol2Click = async (event) => {
    try {
      setLoading(true)
      let highlight = document.getElementsByClassName("activeRow")
      if (highlight.length > 0) {
        highlight[0].classList.remove("activeRow")
      }
      let rowSelected = event.target.parentElement.parentElement.parentElement
      rowSelected.classList.add("activeRow")
      let rowID = rowSelected.id.substring(4, 5);
      let gridReleaseFAValue = getValue(_kaledo.thisObj, 'gridReleaseFA')
      let SelectedRow = []
      if (gridReleaseFAValue != undefined && gridReleaseFAValue != null && gridReleaseFAValue.length > 0) {
        SelectedRow = gridReleaseFAValue[rowID]
        await OpenTrasnferInfo(SelectedRow.txtcolCropYearR, SelectedRow.txtcolShpBuyPtR, SelectedRow.C1_XFER_TYP, SelectedRow.txtcolTransferNumR)
      }
    }
    catch {
      showMessage(thisObj, 'Error occurred during onbtncol2Click event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onbtncol2Click = onbtncol2Click;

  const ClearFields = async () => {
    try {
      setLoading(true)
      setValue(thisObj, 'ddShippingLocation', '')
      setValue(thisObj, 'ddDestinationLocation', '')
      setValue(thisObj, 'txtCarrierAbbrNum', '')
      setValue(thisObj, 'txtVendorNum', '')
      setValue(thisObj, 'txtVendor', '')
      setData(thisObj, 'txtVendor', '')
      thisObj.setState(current => {
        return {
          ...current,
          ddRemitTo: {
            ...state["ddRemitTo"],
            valueList: []
          }
        }
      })
      setValue(thisObj, 'txtInvoiceNum', '')
      setValue(thisObj, 'txtCheckNumber', '')
      setValue(thisObj, 'txtValueOfRTPs', '')
      setValue(thisObj, 'txtCollectAR', '')
      setValue(thisObj, 'txtCollectSR', '')
      setValue(thisObj, 'txtCollectCR', '')
      setValue(thisObj, 'txtValueOfCheck', '')
      setValue(thisObj, 'txtSRBalance', '')
      setValue(thisObj, 'txtCRBalance', '')
      setValue(thisObj, 'txtARBalance', '')
      setValue(thisObj, 'ddSpoolLocation', '')

      disable(thisObj, 'btnSearch')
      // disable(thisObj, 'btnAdd')
      // disable(thisObj, 'btnRemove')
      // disable(thisObj, 'btnSavePreviewSettlement')
      // disable(thisObj, 'btnSaveToBatch')
      // disable(thisObj, 'btnPrintChecks')
      // disable(thisObj, 'btnReleasetoRTP')

      setValue(thisObj, 'gridHoldFA', [])
      setValue(thisObj, 'gridReleaseFA', [])

      setValue(thisObj, 'txtFAToPays', '')
      setValue(thisObj, 'txtFAOnHold', '')
    }
    catch {
      showMessage(thisObj, 'Error occurred during ClearFields event')
    }
    finally{
      setLoading(false)
    }
  }

  const EnableDisableTextBoxes = async () => {
    try {
      setLoading(true)
      let txtValueOfRTPsValue = getValue(_kaledo.thisObj, 'txtValueOfRTPs')
      let txtSRBalanceValue = getValue(_kaledo.thisObj, 'txtSRBalance')
      let txtARBalanceValue = getValue(_kaledo.thisObj, 'txtARBalance')
      let txtCRBalanceValue = getValue(_kaledo.thisObj, 'txtCRBalance')

      if (Number(txtValueOfRTPsValue) <= 0) {
        await ClearTexts()
        await EnableDisableAll(false)
        return;
      }

      if (Number(txtSRBalanceValue) > 0) {
        enable(thisObj, 'txtCollectSR')
      }

      if (Number(txtARBalanceValue) > 0) {
        enable(thisObj, 'txtCollectAR')
      }

      if (Number(txtCRBalanceValue) > 0) {
        enable(thisObj, 'txtCollectCR')
      }
    }
    catch {
      showMessage(thisObj, 'Error occurred during EnableDisableTextBoxes event')
    }
    finally{
      setLoading(false)
    }
  }

  const EnableDisableAll = async (indBln) => {
    try {
      setLoading(true)
      if (indBln == true) {
        enable(thisObj, 'txtCollectSR')
        enable(thisObj, 'txtCollectCR')
        enable(thisObj, 'txtCollectAR')
      }
      else {
        disable(thisObj, 'txtCollectSR')
        disable(thisObj, 'txtCollectCR')
        disable(thisObj, 'txtCollectAR')
      }
    }
    catch {
      showMessage(thisObj, 'Error occurred during EnableDisableAll event')
    }
    finally{
      setLoading(false)
    }
  }

  const ClearTexts = async () => {
    try {
      setLoading(true)
      setValue(thisObj, 'txtCollectSR', '0.00')
      setValue(thisObj, 'txtCollectAR', '0.00')
      setValue(thisObj, 'txtCollectCR', '0.00')
    }
    catch {
      showMessage(thisObj, 'Error occurred during ClearTexts event')
    }
    finally {
      setLoading(false)
    }
  }

  const onddCollectionLocationChange = async () => {
    try {
      setLoading(true)
      await bFillBuyingPointListForCollectPt()
    }
    catch {
      showMessage(thisObj, 'Error occurred during onddCollectionLocationChange event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onddCollectionLocationChange = onddCollectionLocationChange;

  const GetNextCheckNum = async (BuyPt) => {
    try {
      setLoading(true)
      let lstrxml = []

      lstrxml = await ContractManagementService.RetrieveBuyingPointControlDetails(null, null, null, null, BuyPt)
      if (lstrxml != undefined && lstrxml != null && lstrxml.length > 0) {
        setValue(thisObj, 'txtEndCheckNumber', lstrxml[0].end_check)
        setData(thisObj, 'chknumber', lstrxml[0].next_check) // FormatCheckNumber **
        setData(thisObj, 'LstrReceivable_Num', lstrxml[0].new_receivable_no)
        setValue(thisObj, 'txtCheckNumber', lstrxml[0].next_check)
      }
    }
    catch {
      showMessage(thisObj, 'Error occurred during GetNextCheckNum event')
    }
    finally{
      setLoading(false)
    }
  }

  const ongridHoldFARowSelect = async () => {
    try {
      setLoading(true)
      onHoldNumber()
    }
    catch {
      showMessage(thisObj, 'Error occurred during ongridHoldFARowSelect event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ongridHoldFARowSelect = ongridHoldFARowSelect;

  const ongridReleaseFARowSelect = async () => {
    try {
      setLoading(true)
      onHoldNumber()
    }
    catch {
      showMessage(thisObj, 'Error occurred during ongridReleaseFARowSelect event')
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ongridReleaseFARowSelect = ongridReleaseFARowSelect;

  const onHoldNumber = async () => {
    try {
      setLoading(true)
      let gridHoldFAValue = getValue(_kaledo.thisObj, 'gridHoldFA')
      let gridHoldFARowSelected = thisObj.state.gridHoldFA.selected
      if (gridHoldFAValue != undefined && gridHoldFAValue != null && gridHoldFAValue.length > 0) {
        if (gridHoldFARowSelected != undefined && gridHoldFARowSelected != null && gridHoldFARowSelected != '') {
          let Value1 = gridHoldFAValue.length + ' (' + gridHoldFARowSelected.length + ' Rows Selected)'
          setValue(thisObj, 'txtFAOnHold', Value1)
        }
        else {
          let Value2 = gridHoldFAValue.length + ' (0 Rows Selected)'
          setValue(thisObj, 'txtFAOnHold', Value2)
        }
      }
      else {
        setValue(thisObj, 'txtFAOnHold', '')
      }

      let gridReleaseFAValue = getValue(_kaledo.thisObj, 'gridReleaseFA')
      let gridReleaseFARowSelected = thisObj.state.gridReleaseFA.selected
      if (gridReleaseFAValue != undefined && gridReleaseFAValue != null && gridReleaseFAValue.length > 0) {
        if (gridReleaseFARowSelected != undefined && gridReleaseFARowSelected != null && gridReleaseFARowSelected != '') {
          let Value3 = gridReleaseFAValue.length + ' (' + gridReleaseFARowSelected.length + ' Rows Selected)'
          setValue(thisObj, 'txtFAToPays', Value3)
        }
        else {
          let Value4 = gridReleaseFAValue.length + ' (0 Rows Selected)'
          setValue(thisObj, 'txtFAToPays', Value4)
        }
      }
      else {
        setValue(thisObj, 'txtFAToPays', '')
      }
    }
    catch {
      showMessage(thisObj, 'Error occurred during onHoldNumber event')
    }
    finally{
      setLoading(false)
    }
  }

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              <Loading loading={loading}></Loading>
              {/* START_USER_CODE-USER_BEFORE_FrtAccrlSettlement*/}

              {/* END_USER_CODE-USER_BEFORE_FrtAccrlSettlement*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxFrtAccrlSettlement*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxFrtAccrlSettlement*/}

              <GroupBoxWidget
                conf={state.grpbxFrtAccrlSettlement}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails*/}

                <GroupBoxWidget conf={state.grpbxDetails} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_ddCropYear*/}

                  {/* END_USER_CODE-USER_BEFORE_ddCropYear*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCropYear}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddCropYear*/}

                  {/* END_USER_CODE-USER_AFTER_ddCropYear*/}
                  {/* START_USER_CODE-USER_BEFORE_ddCollectionLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_ddCollectionLocation*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCollectionLocation}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddCollectionLocation*/}

                  {/* END_USER_CODE-USER_AFTER_ddCollectionLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_ddShippingLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_ddShippingLocation*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddShippingLocation}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddShippingLocation*/}

                  {/* END_USER_CODE-USER_AFTER_ddShippingLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_ddDestinationLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_ddDestinationLocation*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestinationLocation}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddDestinationLocation*/}

                  {/* END_USER_CODE-USER_AFTER_ddDestinationLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCarrierAbbrNum1*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCarrierAbbrNum1*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCarrierAbbrNum1}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCarrierAbbrNum1*/}

                  {/* END_USER_CODE-USER_AFTER_txtCarrierAbbrNum1*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCarrierAbbrNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCarrierAbbrNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCarrierAbbrNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCarrierAbbrNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtCarrierAbbrNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendorNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendorNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendorNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendorNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendorNum*/}
                  {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                  <ButtonWidget
                    conf={state.btnVendor}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                  {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendor}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxSearchResults*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxSearchResults*/}

                <GroupBoxWidget
                  conf={state.grpbxSearchResults}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_txtFAOnHold*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFAOnHold*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFAOnHold}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFAOnHold*/}

                  {/* END_USER_CODE-USER_AFTER_txtFAOnHold*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                  <ButtonWidget
                    conf={state.btnSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onMouseDown={onbtnSearchClick}
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFAToPays*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFAToPays*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFAToPays}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFAToPays*/}

                  {/* END_USER_CODE-USER_AFTER_txtFAToPays*/}
                  {/* START_USER_CODE-USER_BEFORE_gridHoldFA*/}

                  {/* END_USER_CODE-USER_BEFORE_gridHoldFA*/}

                  <GridWidget
                    conf={state.gridHoldFA}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridHoldFA}
                    setFieldValue={setFieldValue}
                    refObject = {thisObj}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridHoldFA*/}

                  {/* END_USER_CODE-USER_AFTER_gridHoldFA*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxAddRemove*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxAddRemove*/}

                  <GroupBoxWidget
                    conf={state.grpbxAddRemove}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                    {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                    <ButtonWidget
                      conf={state.btnAdd}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                    {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                    {/* START_USER_CODE-USER_BEFORE_btnRemove*/}

                    {/* END_USER_CODE-USER_BEFORE_btnRemove*/}

                    <ButtonWidget
                      conf={state.btnRemove}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnRemove*/}

                    {/* END_USER_CODE-USER_AFTER_btnRemove*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxAddRemove*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxAddRemove*/}
                  {/* START_USER_CODE-USER_BEFORE_gridReleaseFA*/}

                  {/* END_USER_CODE-USER_BEFORE_gridReleaseFA*/}

                  <GridWidget
                    conf={state.gridReleaseFA}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridReleaseFA}
                    setFieldValue={setFieldValue}
                    refObject = {thisObj}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridReleaseFA*/}

                  {/* END_USER_CODE-USER_AFTER_gridReleaseFA*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxSearchResults*/}

                {/* END_USER_CODE-USER_AFTER_grpbxSearchResults*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxInvoiceInfo*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxInvoiceInfo*/}

                <GroupBoxWidget
                  conf={state.grpbxInvoiceInfo}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblNoteRedPay*/}

                  {/* END_USER_CODE-USER_BEFORE_lblNoteRedPay*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblNoteRedPay}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblNoteRedPay*/}

                  {/* END_USER_CODE-USER_AFTER_lblNoteRedPay*/}
                  {/* START_USER_CODE-USER_BEFORE_txtInvoiceNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtInvoiceNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtInvoiceNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtInvoiceNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtInvoiceNum*/}
                  {/* START_USER_CODE-USER_BEFORE_ddSpoolLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_ddSpoolLocation*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddSpoolLocation}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddSpoolLocation*/}

                  {/* END_USER_CODE-USER_AFTER_ddSpoolLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_ddRemitTo*/}

                  {/* END_USER_CODE-USER_BEFORE_ddRemitTo*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddRemitTo}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddRemitTo*/}

                  {/* END_USER_CODE-USER_AFTER_ddRemitTo*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxInvoiceInfo*/}

                {/* END_USER_CODE-USER_AFTER_grpbxInvoiceInfo*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxCheckInfo*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxCheckInfo*/}

                <GroupBoxWidget conf={state.grpbxCheckInfo} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblCheckInformation*/}

                  {/* END_USER_CODE-USER_BEFORE_lblCheckInformation*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblCheckInformation}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblCheckInformation*/}

                  {/* END_USER_CODE-USER_AFTER_lblCheckInformation*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCheckNumber*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCheckNumber*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCheckNumber}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCheckNumber*/}

                  {/* END_USER_CODE-USER_AFTER_txtCheckNumber*/}
                  {/* START_USER_CODE-USER_BEFORE_txtEndCheckNumber*/}

                  {/* END_USER_CODE-USER_BEFORE_txtEndCheckNumber*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEndCheckNumber}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtEndCheckNumber*/}

                  {/* END_USER_CODE-USER_AFTER_txtEndCheckNumber*/}
                  {/* START_USER_CODE-USER_BEFORE_txtValueOfRTPs*/}

                  {/* END_USER_CODE-USER_BEFORE_txtValueOfRTPs*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtValueOfRTPs}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtValueOfRTPs*/}

                  {/* END_USER_CODE-USER_AFTER_txtValueOfRTPs*/}
                  {/* START_USER_CODE-USER_BEFORE_lblBalance*/}

                  {/* END_USER_CODE-USER_BEFORE_lblBalance*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblBalance}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblBalance*/}

                  {/* END_USER_CODE-USER_AFTER_lblBalance*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCollectSR*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCollectSR*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCollectSR}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCollectSR*/}

                  {/* END_USER_CODE-USER_AFTER_txtCollectSR*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSRBalance*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSRBalance*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSRBalance}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSRBalance*/}

                  {/* END_USER_CODE-USER_AFTER_txtSRBalance*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCollectAR*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCollectAR*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCollectAR}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCollectAR*/}

                  {/* END_USER_CODE-USER_AFTER_txtCollectAR*/}
                  {/* START_USER_CODE-USER_BEFORE_txtARBalance*/}

                  {/* END_USER_CODE-USER_BEFORE_txtARBalance*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtARBalance}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtARBalance*/}

                  {/* END_USER_CODE-USER_AFTER_txtARBalance*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCollectCR*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCollectCR*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCollectCR}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCollectCR*/}

                  {/* END_USER_CODE-USER_AFTER_txtCollectCR*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCRBalance*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCRBalance*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCRBalance}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCRBalance*/}

                  {/* END_USER_CODE-USER_AFTER_txtCRBalance*/}
                  {/* START_USER_CODE-USER_BEFORE_txtValueOfCheck*/}

                  {/* END_USER_CODE-USER_BEFORE_txtValueOfCheck*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtValueOfCheck}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtValueOfCheck*/}

                  {/* END_USER_CODE-USER_AFTER_txtValueOfCheck*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxCheckInfo*/}

                {/* END_USER_CODE-USER_AFTER_grpbxCheckInfo*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxFrtAccrlSettlement*/}

              {/* END_USER_CODE-USER_AFTER_grpbxFrtAccrlSettlement*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxAction*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxAction*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxAction} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblCropYear*/}

                {/* END_USER_CODE-USER_BEFORE_lblCropYear*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCropYear}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCropYear*/}

                {/* END_USER_CODE-USER_AFTER_lblCropYear*/}
                {/* START_USER_CODE-USER_BEFORE_btnSavePreviewSettlement*/}

                {/* END_USER_CODE-USER_BEFORE_btnSavePreviewSettlement*/}

                <ButtonWidget
                  conf={state.btnSavePreviewSettlement}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSavePreviewSettlement*/}

                {/* END_USER_CODE-USER_AFTER_btnSavePreviewSettlement*/}
                {/* START_USER_CODE-USER_BEFORE_btnSaveToBatch*/}

                {/* END_USER_CODE-USER_BEFORE_btnSaveToBatch*/}

                <ButtonWidget
                  conf={state.btnSaveToBatch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSaveToBatch*/}

                {/* END_USER_CODE-USER_AFTER_btnSaveToBatch*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrintChecks*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrintChecks*/}

                <ButtonWidget
                  conf={state.btnPrintChecks}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrintChecks*/}

                {/* END_USER_CODE-USER_AFTER_btnPrintChecks*/}
                {/* START_USER_CODE-USER_BEFORE_btnReleasetoRTP*/}

                {/* END_USER_CODE-USER_BEFORE_btnReleasetoRTP*/}

                <ButtonWidget
                  conf={state.btnReleasetoRTP}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnReleasetoRTP*/}

                {/* END_USER_CODE-USER_AFTER_btnReleasetoRTP*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxAction*/}

              {/* END_USER_CODE-USER_AFTER_grpbxAction*/}

              {/* START_USER_CODE-USER_AFTER_FrtAccrlSettlement*/}

              {/* END_USER_CODE-USER_AFTER_FrtAccrlSettlement*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(StockTransfer_FrtAccrlSettlement);
