/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  setValue,
  disable,
  enable,
  getValue,
  getData,
  setData,
  goTo,
  getGridValue,
  getSelectedRowNumber,
  hideColumn,
} from "../../shared/WindowImports";

import "./ManualRTPSearch.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceApplicationSupportService } from "../Service/SystemMaintenanceApplicationSupportService";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import { response } from "msw";
import { number } from "yup";
import Loading from "../../../Loader/Loading";
import reactRouterDom from "react-router-dom";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_ManualRTPSearch(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ManualRTPSearch",
    windowName: "ManualRTPSearch",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.ManualRTPSearch",
    // START_USER_CODE-USER_ManualRTPSearch_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Ready to Pay Maintenance Search",
      scrCode: "PN0210A",
    },
    // END_USER_CODE-USER_ManualRTPSearch_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "cmmndCntnrSearchActions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnAuditHistory: {
      name: "btnAuditHistory",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Audit History",
      CharWidth: "28",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAuditHistory_PROPERTIES

      // END_USER_CODE-USER_btnAuditHistory_PROPERTIES
    },
    btnChange: {
      name: "btnChange",
      type: "ButtonWidget",
      parent: "cmmndCntnrSearchActions",
      Label: "Change",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnChange_PROPERTIES

      // END_USER_CODE-USER_btnChange_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "cmmndCntnrSearchActions",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "cmmndCntnrSearchActions",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxManualRTPSearch",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    cmmndCntnrSearchActions: {
      name: "cmmndCntnrSearchActions",
      type: "CommandContainerWidget",
      parent: "grpbxManualRTPSearch",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_cmmndCntnrSearchActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrSearchActions_PROPERTIES
    },
    colTransactionNum: {
      name: "colTransactionNum",
      type: "GridColumnWidget",
      parent: "gridManualRTP",
      Label: "Transaction Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_colBuyingPoint_PROPERTIES
    },
    colBuyingPoint: {
      name: "colBuyingPoint",
      type: "GridColumnWidget",
      parent: "gridManualRTP",
      Label: "Buying Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_colBuyingPoint_PROPERTIES
    },
    colCounty: {
      name: "colCounty",
      type: "GridColumnWidget",
      parent: "gridManualRTP",
      Label: "County",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCounty_PROPERTIES

      // END_USER_CODE-USER_colCounty_PROPERTIES
    },
    colFarm: {
      name: "colFarm",
      type: "GridColumnWidget",
      parent: "gridManualRTP",
      Label: "Farm",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFarm_PROPERTIES

      // END_USER_CODE-USER_colFarm_PROPERTIES
    },
    colNumber1: {
      name: "colNumber1",
      type: "GridColumnWidget",
      parent: "gridManualRTP",
      Label: "Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNumber1_PROPERTIES

      // END_USER_CODE-USER_colNumber1_PROPERTIES
    },
    colNumber2: {
      name: "colNumber2",
      type: "GridColumnWidget",
      parent: "gridManualRTP",
      Label: "Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNumber2_PROPERTIES

      // END_USER_CODE-USER_colNumber2_PROPERTIES
    },
    colOptions: {
      name: "colOptions",
      type: "GridColumnWidget",
      parent: "gridManualRTP",
      Label: "Opt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOptions_PROPERTIES

      // END_USER_CODE-USER_colOptions_PROPERTIES
    },
    colPayAmount: {
      name: "colPayAmount",
      type: "GridColumnWidget",
      parent: "gridManualRTP",
      Label: "Pay Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPayAmount_PROPERTIES

      // END_USER_CODE-USER_colPayAmount_PROPERTIES
    },
    colState: {
      name: "colState",
      type: "GridColumnWidget",
      parent: "gridManualRTP",
      Label: "State",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colState_PROPERTIES

      // END_USER_CODE-USER_colState_PROPERTIES
    },
    colType1: {
      name: "colType1",
      type: "GridColumnWidget",
      parent: "gridManualRTP",
      Label: "Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colType1_PROPERTIES

      // END_USER_CODE-USER_colType1_PROPERTIES
    },
    colType2: {
      name: "colType2",
      type: "GridColumnWidget",
      parent: "gridManualRTP",
      Label: "Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colType2_PROPERTIES

      // END_USER_CODE-USER_colType2_PROPERTIES
    },
    colVendor: {
      name: "colVendor",
      type: "GridColumnWidget",
      parent: "gridManualRTP",
      Label: "Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendor_PROPERTIES

      // END_USER_CODE-USER_colVendor_PROPERTIES
    },
    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxManualRTPSearch",
      Label: "Location:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation_PROPERTIES

      // END_USER_CODE-USER_ddLocation_PROPERTIES
    },
    ddOBLLocation: {
      name: "ddOBLLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxManualRTPSearch",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOBLLocation_PROPERTIES

      // END_USER_CODE-USER_ddOBLLocation_PROPERTIES
    },
    gridManualRTP: {
      name: "gridManualRTP",
      type: "GridWidget",
      parent: "grpbxManualRTPSearch",
      gridCellsOrder:
        "txtcolBuyingPoint,txtcolVendor,txtcolType1,txtcolNumber1,txtcolType2,txtcolNumber2,txtcolOptions,txtcolState,txtcolCounty,txtcolFarm,txtcolPayAmount,txtcolTransactionNum",  ////missing column "Transaction Number"
      ColSpan: "5",
      Pagination: false,
      HasLabel: false,
      Cols: "5",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridManualRTP_PROPERTIES

      // END_USER_CODE-USER_gridManualRTP_PROPERTIES
    },

    txtcolTransactionNum: {
      name: "txtcolTransactionNum",
      type: "TextBoxWidget",
      colName: "colTransactionNum",
      parent: "gridManualRTP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtcolBuyingPoint_PROPERTIES
    },
    txtcolBuyingPoint: {
      name: "txtcolBuyingPoint",
      type: "TextBoxWidget",
      colName: "colBuyingPoint",
      parent: "gridManualRTP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtcolBuyingPoint_PROPERTIES
    },
    txtcolCounty: {
      name: "txtcolCounty",
      type: "TextBoxWidget",
      colName: "colCounty",
      parent: "gridManualRTP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCounty_PROPERTIES

      // END_USER_CODE-USER_txtcolCounty_PROPERTIES
    },
    txtcolFarm: {
      name: "txtcolFarm",
      type: "TextBoxWidget",
      colName: "colFarm",
      parent: "gridManualRTP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFarm_PROPERTIES

      // END_USER_CODE-USER_txtcolFarm_PROPERTIES
    },
    txtcolNumber1: {
      name: "txtcolNumber1",
      type: "TextBoxWidget",
      colName: "colNumber1",
      parent: "gridManualRTP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNumber1_PROPERTIES

      // END_USER_CODE-USER_txtcolNumber1_PROPERTIES
    },
    txtcolNumber2: {
      name: "txtcolNumber2",
      type: "TextBoxWidget",
      colName: "colNumber2",
      parent: "gridManualRTP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNumber2_PROPERTIES

      // END_USER_CODE-USER_txtcolNumber2_PROPERTIES
    },
    txtcolOptions: {
      name: "txtcolOptions",
      type: "TextBoxWidget",
      colName: "colOptions",
      parent: "gridManualRTP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOptions_PROPERTIES

      // END_USER_CODE-USER_txtcolOptions_PROPERTIES
    },
    txtcolPayAmount: {
      name: "txtcolPayAmount",
      type: "TextBoxWidget",
      colName: "colPayAmount",
      parent: "gridManualRTP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPayAmount_PROPERTIES

      // END_USER_CODE-USER_txtcolPayAmount_PROPERTIES
    },
    txtcolState: {
      name: "txtcolState",
      type: "TextBoxWidget",
      colName: "colState",
      parent: "gridManualRTP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolState_PROPERTIES

      // END_USER_CODE-USER_txtcolState_PROPERTIES
    },
    txtcolType1: {
      name: "txtcolType1",
      type: "TextBoxWidget",
      colName: "colType1",
      parent: "gridManualRTP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolType1_PROPERTIES

      // END_USER_CODE-USER_txtcolType1_PROPERTIES
    },
    txtcolType2: {
      name: "txtcolType2",
      type: "TextBoxWidget",
      colName: "colType2",
      parent: "gridManualRTP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolType2_PROPERTIES

      // END_USER_CODE-USER_txtcolType2_PROPERTIES
    },
    txtcolVendor: {
      name: "txtcolVendor",
      type: "TextBoxWidget",
      colName: "colVendor",
      parent: "gridManualRTP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendor_PROPERTIES

      // END_USER_CODE-USER_txtcolVendor_PROPERTIES
    },
    txtContract: {
      name: "txtContract",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPSearch",
      Label: "Contract #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContract_PROPERTIES

      // END_USER_CODE-USER_txtContract_PROPERTIES
    },
    txtDeliveryAgreement: {
      name: "txtDeliveryAgreement",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPSearch",
      Label: "Delivery Agreement:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDeliveryAgreement_PROPERTIES

      // END_USER_CODE-USER_txtDeliveryAgreement_PROPERTIES
    },
    txtFarm: {
      name: "txtFarm",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPSearch",
      Label: "Farm #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarm_PROPERTIES

      // END_USER_CODE-USER_txtFarm_PROPERTIES
    },
    txtFarmSuffix: {
      name: "txtFarmSuffix",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPSearch",
      Label: "Farm Suffix #:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarmSuffix_PROPERTIES

      // END_USER_CODE-USER_txtFarmSuffix_PROPERTIES
    },
    txtFreightMemo: {
      name: "txtFreightMemo",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPSearch",
      Label: "Freight Memo #:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFreightMemo_PROPERTIES

      // END_USER_CODE-USER_txtFreightMemo_PROPERTIES
    },
    txtProceeds: {
      name: "txtProceeds",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPSearch",
      Label: "Proceeds:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 12 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtProceeds_PROPERTIES

      // END_USER_CODE-USER_txtProceeds_PROPERTIES
    },
    txtSC95: {
      name: "txtSC95",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPSearch",
      Label: "SC95:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSC95_PROPERTIES

      // END_USER_CODE-USER_txtSC95_PROPERTIES
    },
    txtSettlement1007: {
      name: "txtSettlement1007",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPSearch",
      Label: "Settlement (1007):",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSettlement1007_PROPERTIES

      // END_USER_CODE-USER_txtSettlement1007_PROPERTIES
    },
    txtTotalProceeds: {
      name: "txtTotalProceeds",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPSearch",
      Label: "Total Proceeds $:",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      // START_USER_CODE-USER_txtTotalProceeds_PROPERTIES

      // END_USER_CODE-USER_txtTotalProceeds_PROPERTIES
    },
    txtTradeInspection: {
      name: "txtTradeInspection",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPSearch",
      Label: "Trade Inspection:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradeInspection_PROPERTIES

      // END_USER_CODE-USER_txtTradeInspection_PROPERTIES
    },
    txtTradeSettlement: {
      name: "txtTradeSettlement",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPSearch",
      Label: "Trade Settlement:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradeSettlement_PROPERTIES

      // END_USER_CODE-USER_txtTradeSettlement_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPSearch",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtVndr: {
      name: "txtVndr",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPSearch",
      ColSpan: "2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVndr_PROPERTIES

      // END_USER_CODE-USER_txtVndr_PROPERTIES
    },
    txtWarehouseReceipt: {
      name: "txtWarehouseReceipt",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPSearch",
      Label: "Whse Receipt:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWarehouseReceipt_PROPERTIES

      // END_USER_CODE-USER_txtWarehouseReceipt_PROPERTIES
    },
    grpbxManualRTPSearch: {
      name: "grpbxManualRTPSearch",
      type: "GroupBoxWidget",
      parent: "ManualRTPSearch",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxManualRTPSearch_PROPERTIES

      // END_USER_CODE-USER_grpbxManualRTPSearch_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "ManualRTPSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "5",
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceApplicationSupport#ManualRTPMaint": {},
        "SystemMaintenanceApplicationSupport#ManualRTPHistSearch": {},
      },
      REVERSE: {
        "SystemMaintenanceApplicationSupport#ManualRTPMaint": {},
        "SystemMaintenanceApplicationSupport#ManualRTPHistSearch": {},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnAdd: {
    //   DEFAULT: [
    //     "SystemMaintenanceApplicationSupport#ManualRTPMaint#DEFAULT#true#Click",]
    // },
    // btnAuditHistory: {
    //   DEFAULT: [
    //     "SystemMaintenanceApplicationSupport#ManualRTPHistSearch#DEFAULT#true#Click",]
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    setData(thisObj, 'vendorDetails', '')
    formLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    let dataFrmManualRTPMaint = getData(thisObj, "mbRefresh")
    if (dataFrmManualRTPMaint != null) {
      let vendor = getValue(thisObj, "txtVendor")
      let contractNumber = getValue(thisObj, "txtContract")
      let FV95Number = getValue(thisObj, "txtSC95")
      let Num1007 = getValue(thisObj, "txtSettlement1007")
      let TradeInspNum = getValue(thisObj, "txtTradeInspection")
      let TradeSettleNum = getValue(thisObj, "txtTradeSettlement")
      let WhseRcptNum = getValue(thisObj, "txtWarehouseReceipt")
      let FarmID = getValue(thisObj, "txtFarm")
      let FarmSuffix = getValue(thisObj, "txtFarmSuffix")
      let Proceeds = getValue(thisObj, "txtProceeds")
      let FreightMemoNumber = getValue(thisObj, "txtFreightMemo")
      let DelvAgree = getValue(thisObj, "txtDeliveryAgreement")
      if (dataFrmManualRTPMaint == "true") {
        // if(mbRefresh){
        if ((vendor != "" && vendor != undefined && vendor != null) ||
          (contractNumber != "" && contractNumber != undefined && contractNumber != null) ||
          (FV95Number != "" && FV95Number != undefined && FV95Number != null) ||
          (Num1007 != "" && Num1007 != undefined && Num1007 != null) ||
          (TradeInspNum != "" && TradeInspNum != undefined && TradeInspNum != null) ||
          (TradeSettleNum != "" && TradeSettleNum != undefined && TradeSettleNum != null) ||
          (WhseRcptNum != "" && WhseRcptNum != undefined && WhseRcptNum != null) ||
          (FarmID != "" && FarmID != undefined && FarmID != null) ||
          (FarmSuffix != "" && FarmSuffix != undefined && FarmSuffix != null) ||
          (Proceeds != "" && Proceeds != undefined && Proceeds != null) ||
          (FreightMemoNumber != "" && FreightMemoNumber != undefined && FreightMemoNumber != null) ||
          (DelvAgree != "" && DelvAgree != undefined && DelvAgree != null)) {
          DoSearch()
        } else {
          alert("'Location Only' search results will not be refreshed until next search.")
        }
        // }
      }
      if (dataFrmManualRTPMaint == "Delete") {
        DoSearch();
      }
      formActivate();
    }
    setData(thisObj, "mbRefresh", "")
  }, [getData(thisObj, "mbRefresh")]);
  // START_USER_CODE-USER_METHODS

  const [mbRefresh, setMbRefresh] = useState(false);
  const compIdFromLS = () => (sessionStorage.getItem('compId'));
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  let cmdAddRTP = ""
  let cmdEditRTP = ""
  let cmdDeleteRTP = ""

  useEffect(() => {
    let vendor_lookup = getData(thisObj, 'vendorDetails')
    if (vendor_lookup !== null && vendor_lookup !== undefined && vendor_lookup !== '') {
      thisObj.setFieldValue("txtVendor", vendor_lookup.VendorNumber);
      let VenNumber = vendor_lookup.VendorNumber
      bFillRemitToList(vendor_lookup.VendorNumber)
      thisObj.setFieldValue("txtVndr", vendor_lookup.vendorName)
      let js = [];
      js.push({ key: '', description: '>>>ALL<<<' }) //Set default value
      ContractManagementService.RetieveRemitDetails(VenNumber).then(response => {
        if (response != undefined && response.length > 0) {
          let data = response
          for (var i = 0; i < data.length; i++) {
            var vendorName = data[i].name
            let obj = { key: data[i].remittoid, description: data[i].remittoid + " - " + ConvertToPascalCase(data[i].name) + " - " + ConvertToPascalCase(data[i].city) + " - " + ConvertToPascalCase(data[i].state) }
            js.push(obj)
          }
          // setValue(thisObj, "txtVndr", vendorName)
          setValue(thisObj, "ddOBLLocation", js[0].key);
          setData(thisObj, 'vendorName', vendorName)
          setData(thisObj, "remitToLength", js.length);
        }
      })
      thisObj.setState(current => {
        return {
          ...current,
          ddOBLLocation: {
            ...state["ddOBLLocation"],
            valueList: js,
          }
        }
      })
      setData(thisObj, "vendorDetails", null)
    }
  }, [getData(thisObj, 'vendorDetails')]);

  // Form load 

    async function formLoad() {
    setLoading(true);
    let mbSuccessfulLoad = await bFillLocations(true)
    if (!mbSuccessfulLoad) {
      document.getElementById("SystemMaintenanceApplicationSupport_ManualRTPSearchPopup").childNodes[0].click()
    }
    CheckSecurity();
    hideColumn(thisObj, "gridManualRTP", "txtcolTransactionNum");
    disable(thisObj, "txtVndr");
    // if ((txtVendor.value) != null && (txtVendor.value) != undefined) {
    //   ontxtVendorBlur();
    // }
    setLoading(false);
  }
  // Location binding function

  async function bFillLocations(blnSecure) {
    try {
      let data = {};
      if (blnSecure) {
        data = await ContractManagementService.RetrieveBuyingPointControlDetails('PN0210', null, null, null, null)
      } else {
        data = await ContractManagementService.RetrieveBuyingPointControlList(compIdFromLS(), cropYearFromLS(), null, null, null)
      }
      if (data === undefined || data == [] || data == null || data.length == 0) {
        showMessage(thisObj, "Problem retrieving locations/buying points")
        return false;
      } else {
        let js = [];
        js.push({ key: '', description: '>>>All Locations<<<' });
        for (let i = 0; i < data.length; i++) {
          js.push({
            key: data[i].buy_pt_id,
            description: data[i].buy_pt_id + ' - ' + ConvertToPascalCase(data[i].buy_pt_name).toString()
          })
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddLocation: {
              ...state['ddLocation'],
              valueList: js
            }
          }
        })
        setValue(thisObj, "ddLocation", js.at(0).key)
      }
      return true;
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred in Location dropdown."
        );
      }
      return false;
    }
  }
  // Security check function -- need to verify this function with somone

  async function CheckSecurity() {
    try {
      disable(thisObj, "btnAdd")
      disable(thisObj, "btnChange")
      disable(thisObj, "btnDelete")

      let response = await ContractManagementService.RetrieveAccessPermissionDetails("PN0210", null, "D", null)
      if (response[0].permission === 'Y') {
        enable(thisObj, "btnAdd")
        setData(thisObj, "cmdAddRTP", "Y")
        setData(thisObj, "cmdEditRTP", "Y")
        setData(thisObj, "cmdDeleteRTP", "Y")
      } else {
        let secondResponse = await ContractManagementService.RetrieveAccessPermissionDetails("PN0210", null, "U", null)
        if (secondResponse[0].permission === 'Y') {
          enable(thisObj, "btnAdd")
          setData(thisObj, "cmdAddRTP", "Y")
          setData(thisObj, "cmdEditRTP", "Y")
          setData(thisObj, "cmdDeleteRTP", "N")
        } else {
          let secondResponse = await ContractManagementService.RetrieveAccessPermissionDetails("PN0210", null, "I", null)
          if (secondResponse[0].permission === 'Y') {
            disable(thisObj, "btnAdd")
            setData(thisObj, "cmdAddRTP", "N")
            setData(thisObj, "cmdEditRTP", "N")
            setData(thisObj, "cmdDeleteRTP", "N")
          }
        }
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Check security."
        );
      }
      return false;
    }
  }
  // ----------------Form Valid ----------
  const bFormValid = async () => {
    try {
      setLoading(true);
      let bFormValid = false
      let locationList = getValue(thisObj, "ddLocation")
      let vendor = getValue(thisObj, "txtVendor")
      let contractNumber = getValue(thisObj, "txtContract")
      let FV95Number = getValue(thisObj, "txtSC95")
      let Num1007 = getValue(thisObj, "txtSettlement1007")
      let TradeInspNum = getValue(thisObj, "txtTradeInspection")
      let TradeSettleNum = getValue(thisObj, "txtTradeSettlement")
      let WhseRcptNum = getValue(thisObj, "txtWarehouseReceipt")
      let FarmID = getValue(thisObj, "txtFarm")
      let FarmSuffix = getValue(thisObj, "txtFarmSuffix")
      let Proceeds = getValue(thisObj, "txtProceeds")
      let FreightMemoNumber = getValue(thisObj, "txtFreightMemo")
      let DelvAgree = getValue(thisObj, "txtDeliveryAgreement")
      var item = [
        "Location",
        "Vendor #",
        "Contract #",
        "SC95 #",
        "1007 #",
        "Freight Memo #",
        "Trade Inspection #",
        "Trade Settle #",
        "Whse Receipt #",
        "Farm #",
        "Farm Suffix #",
        "Delivery Agreement #",
        "Prceeds $"
      ]
      var alertmessage = "You must specify one of the following:\n";
      for (var i = 0; i < item?.length; i++) {
        alertmessage += (i + 1) + '. ' + item[i] + "\n";
      }
      if ((locationList == "" || locationList == undefined || locationList == null) &&
        (vendor == "" || vendor == undefined || vendor == null) &&
        (contractNumber == "" || contractNumber == undefined || contractNumber == null) &&
        (FV95Number == "" || FV95Number == undefined || FV95Number == null) &&
        (Num1007 == "" || Num1007 == undefined || Num1007 == null) &&
        (TradeInspNum == "" || TradeInspNum == undefined || TradeInspNum == null) &&
        (TradeSettleNum == "" || TradeSettleNum == undefined || TradeSettleNum == null) &&
        (WhseRcptNum == "" || WhseRcptNum == undefined || WhseRcptNum == null) &&
        (FarmID == "" || FarmID == undefined || FarmID == null) &&
        (FarmSuffix == "" || FarmSuffix == undefined || FarmSuffix == null) &&
        (Proceeds == "" || Proceeds == undefined || Proceeds == null) &&
        (FreightMemoNumber == "" || FreightMemoNumber == undefined || FreightMemoNumber == null) &&
        (DelvAgree == "" || DelvAgree == undefined || DelvAgree == null)) {

        alert(alertmessage)
        setLoading(false);
        return;
      }
      bFormValid = true
      setLoading(false);
      return bFormValid;
    }
    catch (error) {
      setLoading(false);
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Validation event"
        );
      }
      return false;
    }
  }
  // ------------------Grid binding :--------------
  const DoSearch = async () => {
    try {
      setLoading(true);
      let LstrCMD = ''
      let LstrList = ''
      let sNum = ''
      let sNmae = ''

      let buy_pt_id = getValue(thisObj, "ddLocation",);
      let payee_vendor = getValue(thisObj, "txtVendor");
      let payee_remit = getValue(thisObj, "ddOBLLocation");
      let audit_cont_num = getValue(thisObj, "txtContract");
      let audit_insp_num = getValue(thisObj, "txtSC95");
      let audit_settle_num = getValue(thisObj, "txtSettlement1007");
      let freight_memo_num = getValue(thisObj, "txtFreightMemo");
      let audit_trade_insp = getValue(thisObj, "txtTradeInspection");
      let audit_trade_settle = getValue(thisObj, "txtTradeSettlement");
      let audit_whse_rcpt = getValue(thisObj, "txtWarehouseReceipt");
      let audit_farm_id = getValue(thisObj, "txtFarm");
      let audit_farm_suffix = getValue(thisObj, "txtFarmSuffix");
      let audit_delv_agree = getValue(thisObj, "txtDeliveryAgreement");
      let rtp_proceeds = getValue(thisObj, "txtProceeds");
      let special_type_none = "NONE"
      let rtp_status_hr = "HR"
      let dblTotalProceeds = 0
      let vendorName = getData(thisObj, "vendorName")
      let cmdAdd = getData(thisObj, "cmdAddRTP")
      let cmdEdit = getData(thisObj, "cmdEditRTP")
      let cmdDelete = getData(thisObj, "cmdDeleteRTP")
      
      if(![ undefined, null, "" ].includes(audit_farm_suffix)){
        if([ undefined, null, "" ].includes(buy_pt_id)){
          alert('Please select location from the dropdown to proceed further.')
          setLoading(false);
          return
        }
      }
    
      let data = await SystemMaintenanceApplicationSupportService.RetrieveManualReadyToPayDetails(buy_pt_id, payee_vendor, payee_remit, audit_cont_num, audit_insp_num, audit_settle_num, freight_memo_num, audit_trade_insp, audit_trade_settle, audit_whse_rcpt, audit_farm_id, audit_farm_suffix, audit_delv_agree, rtp_proceeds, special_type_none, rtp_status_hr)
      if (data.length == 0 || data?.status == 500) {
        alert("An error occured while communicating or updating with the database. Please contact your system administrator if the problem persist.\n\nNo RTPs found for specified criteria!")
        setValue(thisObj, "gridManualRTP", [])
        setValue(thisObj, "txtTotalProceeds", [])
        disable(thisObj, "btnChange")
        disable(thisObj, "btnDelete")
        setLoading(false);
        return;
      }
      let TransformedRowsArray = [];
      if (data != undefined && data.length > 0) {

        let obj = {};
        for (let i = 0; i < data.length; i++) {
          obj.txtcolTransactionNum = data[i].transaction_num
          obj.txtcolBuyingPoint = data[i].buy_pt_id
          if (payee_vendor == '' || payee_vendor == undefined) {
            sNum = data[i].pay_vendor
            sNmae = ""
            if (data[i].pay_vendor != "") {

              let response1 = await ContractManagementService.RetrieveVendorByNumber(data[i].pay_vendor)
              if (response1 != undefined && response1.length > 0) {
                sNmae = response1[0]?.name
              }
              obj.txtcolVendor = data[i].pay_vendor + '-' + ConvertToPascalCase(response1[0]?.name)
            }
          } else {
            obj.txtcolVendor = payee_vendor + '-' + ConvertToPascalCase(vendorName)
          }
          obj.txtcolType1 = data[i].pay_type_1
          obj.txtcolNumber1 = data[i].pay_number_1
          obj.txtcolType2 = data[i].pay_type_2
          obj.txtcolNumber2 = data[i].pay_number_2
          obj.txtcolOptions = data[i].pay_option_ind
          obj.txtcolState = data[i].pay_state
          obj.txtcolCounty = data[i].county_name
          obj.txtcolFarm = data[i].pay_farm + ' - ' + data[i].pay_farm_suffix + ' - ' + data[i].farm_name
          obj.txtcolPayAmount = data[i].rtp_proceeds
          dblTotalProceeds = dblTotalProceeds + Number(data[i].rtp_proceeds);
          TransformedRowsArray.push(obj)
          obj = {}
        }
        if (data.length > 0) {
          TransformedRowsArray.push({
            txtcolType1: "",
            txtcolNumber1: "",
            txtcolType2: "",
            txtcolNumber2: "",
            txtcolOptions: "",
            txtcolState: "",
            txtcolCounty: "",
            txtcolFarm: "",
            txtcolVendor: "Totals:",
            txtcolPayAmount: dblTotalProceeds == 0 ? '0.00' : Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(dblTotalProceeds)),
          })
        }
        setValue(thisObj, "gridManualRTP", TransformedRowsArray);
        setData(thisObj, "gridManualRTP", TransformedRowsArray);
        setValue(thisObj, "txtcolPayAmount", dblTotalProceeds);
        setData(thisObj, "txtcolPayAmount", dblTotalProceeds);
        setValue(thisObj, "txtTotalProceeds", dblTotalProceeds == 0 ? '0.00' : Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(dblTotalProceeds)))
        setLoading(false);
      }

      if (cmdAdd == "Y") {
        enable(thisObj, "btnAdd")
      } else {
        disable(thisObj, "btnAdd")
      }
      disable(thisObj, "btnChange")
      disable(thisObj, "btnDelete")

      if (TransformedRowsArray.length > 1) {
        if (cmdEdit == "Y" || cmdEdit == "N") {
          enable(thisObj, "btnChange")
        } else {
          disable(thisObj, "btnChange")
        }
        if (cmdDelete == "Y") {
          enable(thisObj, "btnDelete")
        } else {
          disable(thisObj, "btnDelete")
        }
      }
      setMbRefresh(false)
    } catch (error) {
      setLoading(false);
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Search button"
        );
      }
      return false;
    }
  }
//Function to convert to Pascal Case

function ConvertToPascalCase(word){
  return word.replace(/(\w)(\w*)/g, function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});

}
  // Form Activate----- 
  const formActivate = () => {
    try {
      let cmdAdd = getData(thisObj, "cmdAddRTP")
      let cmdEdit = getData(thisObj, "cmdEditRTP")
      let cmdDelete = getData(thisObj, "cmdDeleteRTP")
      let gridData = getData(thisObj, "gridManualRTP")
      if (cmdAdd == "Y") {
        enable(thisObj, "btnAdd")
      } else {
        disable(thisObj, "btnAdd")
      }
      disable(thisObj, "btnChange")
      disable(thisObj, "btnDelete")

      if (gridData > 0) {
        if (cmdEdit == "Y" || cmdEdit == "N") {
          enable(thisObj, "btnChange")
        } else {
          disable(thisObj, "btnChange")
        }
        if (cmdDelete == "Y") {
          enable(thisObj, "btnDelete")
        } else {
          disable(thisObj, "btnDelete")
        }
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during form Activate."
        );
      }
    }
  }

  // vsf Reasult---
  const ongridManualRTPRowSelect = () => {
    try {
      let cmdAdd = getData(thisObj, "cmdAddRTP")
      let cmdEdit = getData(thisObj, "cmdEditRTP")
      let cmdDelete = getData(thisObj, "cmdDeleteRTP")
      let gridValue = getValue(thisObj,"gridManualRTP")
      const rowNum = getSelectedRowNumber(thisObj, 'gridManualRTP');
      if(gridValue != undefined && gridValue != null && rowNum == gridValue.length-1){
          thisObj.state.gridManualRTP.selected[0] = null
      }
      if (cmdAdd == "Y") {
        enable(thisObj, "btnAdd")
      } else {
        disable(thisObj, "btnAdd")
      }
      disable(thisObj, "btnChange")
      disable(thisObj, "btnDelete")

      if (rowNum >= 0) {
        if (cmdEdit == "Y" || cmdEdit == "N") {
          enable(thisObj, "btnChange")
        } else {
          disable(thisObj, "btnChange")
        }
        if (cmdDelete == "Y") {
          enable(thisObj, "btnDelete")
        } else {
          disable(thisObj, "btnDelete")
        }
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during form Activate."
        );
      }
    }
  }
  thisObj.ongridManualRTPRowSelect = ongridManualRTPRowSelect;

  //seaching vendorname based on Vendor

  const bFillRemitToList = async (txtVendorNumber) => {
    try {
      let venname = null
      let vendornamevisible = false
      let response = await ContractManagementService.RetrieveVendorByNumber(txtVendorNumber)
      let data = response
      if (data !== null && data !== undefined && data.length > 0) {
        venname = data[0].name
        vendornamevisible = true
      }
      if (vendornamevisible == true) {
        document.getElementsByClassName("txtVndr")[0].style.visibility = "visible";
        setValue(thisObj, "txtVndr", ConvertToPascalCase(venname))
        disable(thisObj, 'txtVndr')
      } else {
        document.getElementsByClassName("txtVndr")[0].style.visibility = "hidden";
        setValue(thisObj, "txtVndr", '')
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred in setting value to Vendor Number textbox"
        );
      }
    }
  };
  // Vendor click button
  const onbtnVendorClick = async () => {
    try {
      goTo(thisObj, "ContractManagement#VendorLookup#DEFAULT#true#Click");
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, "Error on Vendor click event")
      }
      return false
    }
  };
  thisObj.onbtnVendorClick = onbtnVendorClick;

  const ontxtVendorBlur = async() => {
    try {
      let txtVendorNumber = getValue(thisObj, 'txtVendor')

      if (txtVendorNumber != undefined && txtVendorNumber != null && txtVendorNumber.length == 6) {
        await bFillRemitToList(txtVendorNumber)
      } else {
        setValue(thisObj, 'txtVndr', '')
        disable(thisObj, 'txtVndr')
        document.getElementsByClassName("txtVndr")[0].style.visibility = "hidden";
      }
      let js = [];
      if (txtVendorNumber != undefined && txtVendorNumber != null && txtVendorNumber.length == 6) {
        await ContractManagementService.RetieveRemitDetails(txtVendorNumber).then(response => {
          if (response != undefined && response.length > 0) {
            let data = response
            js.push({ key: '', description: '>>>ALL<<<' }) //Set default value
            for (var i = 0; i < data.length; i++) {
              var vendorName = ConvertToPascalCase(data[i].name)
              let obj = { key: data[i].remittoid, description: data[i].remittoid + " - " + ConvertToPascalCase(data[i].name) + " - " + ConvertToPascalCase(data[i].city) + " - " + ConvertToPascalCase(data[i].state) }
              js.push(obj)
            }
            setValue(thisObj, "ddOBLLocation", js.length > 0 ? js.at(0).key : '' );
            setData(thisObj, "remitToLength", js.length);
            setData(thisObj, 'vendorName', vendorName);
          }
        })
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddOBLLocation: {
            ...state["ddOBLLocation"],
            valueList: js,
          }
        }
      })
      setData(thisObj, "remitToLength", null);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, "Error on txtVendor change event")
      }
      return false
    }
    return true;
  }
  thisObj.ontxtVendorBlur = ontxtVendorBlur;

  // Add Button ----

  const onbtnAddClick = () => {
    let js = [{
      cmdOk: "Add",
      load_form: true,
      lblTransactionNumber: ''
    }]
    setData(thisObj, 'frmManualRTPMaintdata', js)
    goTo(thisObj, "SystemMaintenanceApplicationSupport#ManualRTPMaint#DEFAULT#true#Click")
  };
  thisObj.onbtnAddClick = onbtnAddClick;

  // Delete Button---
  const onbtnDeleteClick = () => {
    let rowNumber = getSelectedRowNumber(thisObj, "gridManualRTP")
    if((rowNumber == null || rowNumber == undefined || rowNumber == '') && rowNumber != 0){
      return;
    }
    let transactionNum = getGridValue(thisObj, "gridManualRTP", "txtcolTransactionNum", rowNumber)
    let js = [{
      cmdOk: "Delete",
      load_form: true,
      lblTransactionNumber: transactionNum
    }]
    setData(thisObj, 'frmManualRTPMaintdata', js)
    goTo(thisObj, "SystemMaintenanceApplicationSupport#ManualRTPMaint#DEFAULT#true#Click")
  };
  thisObj.onbtnDeleteClick = onbtnDeleteClick;

  // Change Button---
  const onbtnChangeClick = () => {
    let cmdEdit = getData(thisObj, "cmdEditRTP") == "Y" ? true : false
    let rowNumber = getSelectedRowNumber(thisObj, "gridManualRTP")
    if((rowNumber == null || rowNumber == undefined || rowNumber == '') && rowNumber != 0){
      return;
    }
    let transactionNum = getGridValue(thisObj, "gridManualRTP", "txtcolTransactionNum", rowNumber)

    let js = [{
      cmdOk: "Change",
      load_form: true,
      lblTransactionNumber: transactionNum,
      cmdOK_enable: cmdEdit
    }]
    setData(thisObj, 'frmManualRTPMaintdata', js)
    goTo(thisObj, "SystemMaintenanceApplicationSupport#ManualRTPMaint#DEFAULT#true#Click")
  };
  thisObj.onbtnChangeClick = onbtnChangeClick;

  // --------------Search Click ------------------

  const onbtnSearchClick = async () => {
    try {
      setLoading(true)
      if (!await bFormValid()) return;
      let vendor = getValue(thisObj, "txtVendor")
      let contractNumber = getValue(thisObj, "txtContract")
      let FV95Number = getValue(thisObj, "txtSC95")
      let Num1007 = getValue(thisObj, "txtSettlement1007")
      let TradeInspNum = getValue(thisObj, "txtTradeInspection")
      let TradeSettleNum = getValue(thisObj, "txtTradeSettlement")
      let WhseRcptNum = getValue(thisObj, "txtWarehouseReceipt")
      let FarmID = getValue(thisObj, "txtFarm")
      let FarmSuffix = getValue(thisObj, "txtFarmSuffix")
      let Proceeds = getValue(thisObj, "txtProceeds")
      let FreightMemoNumber = getValue(thisObj, "txtFreightMemo")
      let DelvAgree = getValue(thisObj, "txtDeliveryAgreement")

      if ((vendor == "" || vendor == undefined || vendor == null) &&
        (contractNumber == "" || contractNumber == undefined || contractNumber == null) &&
        (FV95Number == "" || FV95Number == undefined || FV95Number == null) &&
        (Num1007 == "" || Num1007 == undefined || Num1007 == null) &&
        (TradeInspNum == "" || TradeInspNum == undefined || TradeInspNum == null) &&
        (TradeSettleNum == "" || TradeSettleNum == undefined || TradeSettleNum == null) &&
        (WhseRcptNum == "" || WhseRcptNum == undefined || WhseRcptNum == null) &&
        (FarmID == "" || FarmID == undefined || FarmID == null) &&
        (FarmSuffix == "" || FarmSuffix == undefined || FarmSuffix == null) &&
        (Proceeds == "" || Proceeds == undefined || Proceeds == null) &&
        (FreightMemoNumber == "" || FreightMemoNumber == undefined || FreightMemoNumber == null) &&
        (DelvAgree == "" || DelvAgree == undefined || DelvAgree == null)) {

        if (window.confirm("Searching by 'Location Only' may take awhile to return results.\n\nDo you wish to continue?") == true) {
          DoSearch();

        } else {
          return;
        }
      } else {
        DoSearch();
      }
    } catch (err) {
      setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred on Search click."
        );
      }
    }
  }
  thisObj.onbtnSearchClick = onbtnSearchClick;

  // -------------------- Validations for text boxes --------------

  const ontxtFreightMemoChange = () => {
    let FreigtmemoValue = getValue(thisObj, 'txtFreightMemo')
    setValue(thisObj, "txtFreightMemo", validateTextBox1(FreigtmemoValue))
  };
  thisObj.ontxtFreightMemoChange = ontxtFreightMemoChange


  const validateTextBox1 = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 46)) {
        res += data[i]
      }
    }
    return res;
  }

  const ontxtContractChange = () => {
    let contractChangeValue = getValue(thisObj, 'txtContract')
    setValue(thisObj, "txtContract", validateTextBox2(contractChangeValue))
  };
  thisObj.ontxtContractChange = ontxtContractChange

  const validateTextBox2 = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 46)) {
        res += data[i]
      }
    }
    return res;
  }

  const ontxtSettlement1007Change = () => {
    let settlement1007Value = getValue(thisObj, 'txtSettlement1007')
    setValue(thisObj, "txtSettlement1007", validateTextBox3(settlement1007Value))
  };
  thisObj.ontxtSettlement1007Change = ontxtSettlement1007Change

  const validateTextBox3 = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 46)) {
        res += data[i]
      }
    }
    return res;
  }

  const ontxtTradeSettlementChange = () => {
    let tradeSettlementValue = getValue(thisObj, 'txtTradeSettlement')
    setValue(thisObj, "txtTradeSettlement", validateTextBox4(tradeSettlementValue))
  };
  thisObj.ontxtTradeSettlementChange = ontxtTradeSettlementChange

  const validateTextBox4 = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 46)) {
        res += data[i]
      }
    }
    return res;
  }

  const ontxtWarehouseReceiptChange = () => {
    let warehouseReceiptValue = getValue(thisObj, 'txtWarehouseReceipt')
    setValue(thisObj, "txtWarehouseReceipt", validateTextBox5(warehouseReceiptValue))
  };
  thisObj.ontxtWarehouseReceiptChange = ontxtWarehouseReceiptChange

  const validateTextBox5 = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 46)) {
        res += data[i]
      }
    }
    return res;
  }

  const ontxtDeliveryAgreementChange = () => {
    let deliveryAgreementValue = getValue(thisObj, 'txtDeliveryAgreement')
    setValue(thisObj, "txtDeliveryAgreement", validateTextBox6(deliveryAgreementValue))
  };
  thisObj.ontxtDeliveryAgreementChange = ontxtDeliveryAgreementChange

  const validateTextBox6 = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 46)) {
        res += data[i]
      }
    }
    return res;
  }

  // Close button---
  const onbtnCloseClick = () => {
    try {
      document.getElementById("SystemMaintenanceApplicationSupport_ManualRTPSearchPopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  // Blur event for Proceeds box-----

  const ontxtProceedsBlur = (value) => {
    try {
      let rate = document.getElementById("txtProceeds").value
      if (rate.length != 0 && rate.length < 10 && isNaN(rate) == false) {
        rate = Number(rate).toFixed(2)
      } else if (rate.length == 10) {
        rate = Number(rate).toFixed(1)
      } else if (rate.length == 11) {
        rate = rate + '.'
      }
      setValue(thisObj, 'txtProceeds', rate);
       
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for txt Proceeds."
        );
      }
      return false;
    }
  };
  thisObj.ontxtProceedsBlur = ontxtProceedsBlur;

  // Audit History Button
  
  const onbtnAuditHistoryClick = () => {
    try {
      goTo(thisObj, "SystemMaintenanceApplicationSupport#ManualRTPHistSearch#DEFAULT#true#Click")
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:Audit History event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnAuditHistoryClick = onbtnAuditHistoryClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={values => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            <Loading loading={loading}></Loading>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ManualRTPSearch*/}

              {/* END_USER_CODE-USER_BEFORE_ManualRTPSearch*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxManualRTPSearch*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxManualRTPSearch*/}

              <GroupBoxWidget
                conf={state.grpbxManualRTPSearch}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddLocation*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddLocation*/}
                {/* START_USER_CODE-USER_BEFORE_txtTradeInspection*/}

                {/* END_USER_CODE-USER_BEFORE_txtTradeInspection*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTradeInspection}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTradeInspection*/}

                {/* END_USER_CODE-USER_AFTER_txtTradeInspection*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                {/* START_USER_CODE-USER_BEFORE_ddOBLLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddOBLLocation*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddOBLLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddOBLLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddOBLLocation*/}
                {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                <ButtonWidget
                  conf={state.btnVendor}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                {/* START_USER_CODE-USER_BEFORE_txtTradeSettlement*/}

                {/* END_USER_CODE-USER_BEFORE_txtTradeSettlement*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTradeSettlement}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTradeSettlement*/}

                {/* END_USER_CODE-USER_AFTER_txtTradeSettlement*/}
                {/* START_USER_CODE-USER_BEFORE_txtContract*/}

                {/* END_USER_CODE-USER_BEFORE_txtContract*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtContract}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtContract*/}

                {/* END_USER_CODE-USER_AFTER_txtContract*/}
                {/* START_USER_CODE-USER_BEFORE_txtVndr*/}

                {/* END_USER_CODE-USER_BEFORE_txtVndr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVndr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVndr*/}

                {/* END_USER_CODE-USER_AFTER_txtVndr*/}
                {/* START_USER_CODE-USER_BEFORE_txtWarehouseReceipt*/}

                {/* END_USER_CODE-USER_BEFORE_txtWarehouseReceipt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWarehouseReceipt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWarehouseReceipt*/}

                {/* END_USER_CODE-USER_AFTER_txtWarehouseReceipt*/}
                {/* START_USER_CODE-USER_BEFORE_txtSC95*/}

                {/* END_USER_CODE-USER_BEFORE_txtSC95*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSC95}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSC95*/}

                {/* END_USER_CODE-USER_AFTER_txtSC95*/}
                {/* START_USER_CODE-USER_BEFORE_txtFarm*/}

                {/* END_USER_CODE-USER_BEFORE_txtFarm*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarm}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFarm*/}

                {/* END_USER_CODE-USER_AFTER_txtFarm*/}
                {/* START_USER_CODE-USER_BEFORE_txtFarmSuffix*/}

                {/* END_USER_CODE-USER_BEFORE_txtFarmSuffix*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarmSuffix}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFarmSuffix*/}

                {/* END_USER_CODE-USER_AFTER_txtFarmSuffix*/}
                {/* START_USER_CODE-USER_BEFORE_txtSettlement1007*/}

                {/* END_USER_CODE-USER_BEFORE_txtSettlement1007*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSettlement1007}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSettlement1007*/}

                {/* END_USER_CODE-USER_AFTER_txtSettlement1007*/}
                {/* START_USER_CODE-USER_BEFORE_txtDeliveryAgreement*/}

                {/* END_USER_CODE-USER_BEFORE_txtDeliveryAgreement*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDeliveryAgreement}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDeliveryAgreement*/}

                {/* END_USER_CODE-USER_AFTER_txtDeliveryAgreement*/}
                {/* START_USER_CODE-USER_BEFORE_txtFreightMemo*/}

                {/* END_USER_CODE-USER_BEFORE_txtFreightMemo*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFreightMemo}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFreightMemo*/}

                {/* END_USER_CODE-USER_AFTER_txtFreightMemo*/}
                {/* START_USER_CODE-USER_BEFORE_txtProceeds*/}

                {/* END_USER_CODE-USER_BEFORE_txtProceeds*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtProceeds}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtProceeds*/}

                {/* END_USER_CODE-USER_AFTER_txtProceeds*/}
                {/* START_USER_CODE-USER_BEFORE_cmmndCntnrSearchActions*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndCntnrSearchActions*/}

                <CommandContainerWidget
                  conf={state.cmmndCntnrSearchActions}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                  <ButtonWidget
                    conf={state.btnSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                  <ButtonWidget
                    conf={state.btnAdd}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                  {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                  {/* START_USER_CODE-USER_BEFORE_btnChange*/}

                  {/* END_USER_CODE-USER_BEFORE_btnChange*/}

                  <ButtonWidget
                    conf={state.btnChange}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnChange*/}

                  {/* END_USER_CODE-USER_AFTER_btnChange*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                  <ButtonWidget
                    conf={state.btnDelete}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                  {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndCntnrSearchActions*/}

                {/* END_USER_CODE-USER_AFTER_cmmndCntnrSearchActions*/}
                {/* START_USER_CODE-USER_BEFORE_gridManualRTP*/}

                {/* END_USER_CODE-USER_BEFORE_gridManualRTP*/}

                <GridWidget
                  conf={state.gridManualRTP}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridManualRTP}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridManualRTP*/}

                {/* END_USER_CODE-USER_AFTER_gridManualRTP*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalProceeds*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalProceeds*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalProceeds}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalProceeds*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalProceeds*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxManualRTPSearch*/}

              {/* END_USER_CODE-USER_AFTER_grpbxManualRTPSearch*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_btnAuditHistory*/}

                {/* END_USER_CODE-USER_BEFORE_btnAuditHistory*/}

                <ButtonWidget
                  conf={state.btnAuditHistory}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAuditHistory*/}

                {/* END_USER_CODE-USER_AFTER_btnAuditHistory*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_ManualRTPSearch*/}

              {/* END_USER_CODE-USER_AFTER_ManualRTPSearch*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceApplicationSupport_ManualRTPSearch);
