/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  DateWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  setValue,
  disable,
  enable,
  hide,
  show,
  getValue,
  getData,
  setData,
  hideColumn,
  isEnabled,
  setGridValue,
  goTo,
  getGridValue,
  getRowCount,
  isVisible,
  getSelectedRowNumber,
  getSelectedGridValue
} from "../../shared/WindowImports";

import "./WarehouseReceiptInquiry.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import Loading from "../../../Loader/Loading";
import * as XLSX from 'xlsx/xlsx.mjs';
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_WarehouseReceiptInquiry(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  let userid = sessionStorage.getItem("userid");
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  let compId = sessionStorage.getItem('compId');

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "WarehouseReceiptInquiry",
    windowName: "WarehouseReceiptInquiry",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.WarehouseReceiptInquiry",
    // START_USER_CODE-USER_WarehouseReceiptInquiry_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Warehouse Receipt Search",
      scrCode: "PN1090A",
    },
    // END_USER_CODE-USER_WarehouseReceiptInquiry_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxBtnCntnr",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnClearRPS: {
      name: "btnClearRPS",
      type: "ButtonWidget",
      parent: "grpbxBtnCntnr",
      Label: "Clear RPS",
      CharWidth: "22",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClearRPS_PROPERTIES

      // END_USER_CODE-USER_btnClearRPS_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxBtnCntnr",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnCreateWarehouseReceipt: {
      name: "btnCreateWarehouseReceipt",
      type: "ButtonWidget",
      parent: "grpbxWhsRcptList",
      Label: "Create Warehouse Receipt",
      CharWidth: "48",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCreateWarehouseReceipt_PROPERTIES

      // END_USER_CODE-USER_btnCreateWarehouseReceipt_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "grpbxBtnCntnr",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnExtRcptForfeiture: {
      name: "btnExtRcptForfeiture",
      type: "ButtonWidget",
      parent: "grpbxWhsRcptList",
      Label: "External Receipt Forfeiture",
      CharWidth: "54",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExtRcptForfeiture_PROPERTIES

      // END_USER_CODE-USER_btnExtRcptForfeiture_PROPERTIES
    },
    btnNotReady: {
      name: "btnNotReady",
      type: "ButtonWidget",
      parent: "grpbxFSADocument",
      Label: "Not Ready",
      CharWidth: "21",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnNotReady_PROPERTIES

      // END_USER_CODE-USER_btnNotReady_PROPERTIES
    },
    btnOpen: {
      name: "btnOpen",
      type: "ButtonWidget",
      parent: "grpbxWhsRcptList",
      Label: "Open",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOpen_PROPERTIES

      // END_USER_CODE-USER_btnOpen_PROPERTIES
    },
    btnPrintAllReady: {
      name: "btnPrintAllReady",
      type: "ButtonWidget",
      parent: "grpbxFSADocument",
      Label: "Print All Ready",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrintAllReady_PROPERTIES

      // END_USER_CODE-USER_btnPrintAllReady_PROPERTIES
    },
    btnPrintSearch: {
      name: "btnPrintSearch",
      type: "ButtonWidget",
      parent: "grpbxBtnCntnr",
      Label: "Print Search",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrintSearch_PROPERTIES

      // END_USER_CODE-USER_btnPrintSearch_PROPERTIES
    },
    btnReady: {
      name: "btnReady",
      type: "ButtonWidget",
      parent: "grpbxFSADocument",
      Label: "Ready",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnReady_PROPERTIES

      // END_USER_CODE-USER_btnReady_PROPERTIES
    },
    btnRedeemByLoan: {
      name: "btnRedeemByLoan",
      type: "ButtonWidget",
      parent: "grpbxBtnCntnr",
      Label: "Redeem by Loan",
      CharWidth: "30",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnRedeemByLoan_PROPERTIES

      // END_USER_CODE-USER_btnRedeemByLoan_PROPERTIES
    },
    btnResetToEWRAccepted: {
      name: "btnResetToEWRAccepted",
      type: "ButtonWidget",
      parent: "grpbxBtnCntnr",
      Label: "Reset to EWR Accepted",
      CharWidth: "44",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnResetToEWRAccepted_PROPERTIES

      // END_USER_CODE-USER_btnResetToEWRAccepted_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxWhsRcptList",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },

    chkboxAllFSA: {//for all FSA CheckBopx
      name: "chkboxAllFSA",
      type: "CheckBoxWidget",
      parent: "grpbxWhsRcptList",
      Label: "All FSA",
      // HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxIncludeResidueLoads_PROPERTIES

      // END_USER_CODE-USER_chkboxIncludeResidueLoads_PROPERTIES
    },
    btnUploadLockIn: {
      name: "btnUploadLockIn",
      type: "ButtonWidget",
      parent: "grpbxBtnCntnr",
      Label: "Upload Lock In",
      CharWidth: "30",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUploadLockIn_PROPERTIES

      // END_USER_CODE-USER_btnUploadLockIn_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxDetails1",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    btnViewEWRLog: {
      name: "btnViewEWRLog",
      type: "ButtonWidget",
      parent: "grpbxBtnCntnr",
      Label: "View EWR Log",
      CharWidth: "27",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnViewEWRLog_PROPERTIES

      // END_USER_CODE-USER_btnViewEWRLog_PROPERTIES
    },

    chkbxFSA: {
      name: "chkbxFSA",
      type: "CheckBoxWidget",
      parent: "gridWarehouseReceiptList",
      colName: "chkboxcolFSA",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxPercentOnly_PROPERTIES
      // isMultiSelectAlternate: true,
      Visible: false
      // END_USER_CODE-USER_chkboxPercentOnly_PROPERTIES
    },

    chkboxcolFSA: {
      name: "chkboxcolFSA",
      type: "CheckBoxWidget",
      colName: "colFSA",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxcolFSA_PROPERTIES

      // END_USER_CODE-USER_chkboxcolFSA_PROPERTIES
    },
    chkboxWireXferData: {
      name: "chkboxWireXferData",
      type: "CheckBoxWidget",
      parent: "grpbxDetails1",
      Label: "Wire Xfer Data:",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxWireXferData_PROPERTIES

      // END_USER_CODE-USER_chkboxWireXferData_PROPERTIES
    },
    col211Concordia: {
      name: "col211Concordia",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "211 Concordia",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col211Concordia_PROPERTIES

      // END_USER_CODE-USER_col211Concordia_PROPERTIES
    },
    col211GPC: {
      name: "col211GPC",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "211 GPC",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col211GPC_PROPERTIES

      // END_USER_CODE-USER_col211GPC_PROPERTIES
    },
    colBuyingPoint: {
      name: "colBuyingPoint",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Buying Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_colBuyingPoint_PROPERTIES
    },
    colContNum: {
      name: "colContNum",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Cont # C/O B/F",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContNum_PROPERTIES

      // END_USER_CODE-USER_colContNum_PROPERTIES
    },
    colDeferred: {
      name: "colDeferred",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Deferred",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDeferred_PROPERTIES

      // END_USER_CODE-USER_colDeferred_PROPERTIES
    },
    colDelvAgreeNum: {
      name: "colDelvAgreeNum",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Delv Agree #:",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDelvAgreeNum_PROPERTIES

      // END_USER_CODE-USER_colDelvAgreeNum_PROPERTIES
    },
    colDelvInd: {
      name: "colDelvInd",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Delv Ind",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDelvInd_PROPERTIES

      // END_USER_CODE-USER_colDelvInd_PROPERTIES
    },
    colEndDate: {
      name: "colEndDate",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "End Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colEndDate_PROPERTIES

      // END_USER_CODE-USER_colEndDate_PROPERTIES
    },
    colERcptNum: {
      name: "colERcptNum",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "E-Rcpt #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colERcptNum_PROPERTIES

      // END_USER_CODE-USER_colERcptNum_PROPERTIES
    },
    colEWRRcptType: {
      name: "colEWRRcptType",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "EWR Rcpt Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colEWRRcptType_PROPERTIES

      // END_USER_CODE-USER_colEWRRcptType_PROPERTIES
    },
    colEWRXferStatus: {
      name: "colEWRXferStatus",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "EWR Xfer Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colEWRXferStatus_PROPERTIES

      // END_USER_CODE-USER_colEWRXferStatus_PROPERTIES
    },
    colFarmNo: {
      name: "colFarmNo",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Farm No.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFarmNo_PROPERTIES

      // END_USER_CODE-USER_colFarmNo_PROPERTIES
    },
    colFSA: {
      name: "colFSA",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "FSA",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFSA_PROPERTIES

      // END_USER_CODE-USER_colFSA_PROPERTIES
    },
    colGen: {
      name: "colGen",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Gen",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGen_PROPERTIES

      // END_USER_CODE-USER_colGen_PROPERTIES
    },
    colGroupNum: {
      name: "colGroupNum",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Group #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGroupNum_PROPERTIES

      // END_USER_CODE-USER_colGroupNum_PROPERTIES
    },
    colHolder: {
      name: "colHolder",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Holder",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colHolder_PROPERTIES

      // END_USER_CODE-USER_colHolder_PROPERTIES
    },
    colLastAction: {
      name: "colLastAction",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Last Action",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLastAction_PROPERTIES

      // END_USER_CODE-USER_colLastAction_PROPERTIES
    },
    colLastActionReceived: {
      name: "colLastActionReceived",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Last Action Received",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLastActionReceived_PROPERTIES

      // END_USER_CODE-USER_colLastActionReceived_PROPERTIES
    },
    colLoanStatus: {
      name: "colLoanStatus",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Loan Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLoanStatus_PROPERTIES

      // END_USER_CODE-USER_colLoanStatus_PROPERTIES
    },
    colNetRepay: {
      name: "colNetRepay",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Net Repay",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNetRepay_PROPERTIES

      // END_USER_CODE-USER_colNetRepay_PROPERTIES
    },
    colNetWt: {
      name: "colNetWt",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Net Wt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNetWt_PROPERTIES

      // END_USER_CODE-USER_colNetWt_PROPERTIES
    },
    colOblBin: {
      name: "colOblBin",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Obl. Bin",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOblBin_PROPERTIES

      // END_USER_CODE-USER_colOblBin_PROPERTIES
    },
    colOblPt: {
      name: "colOblPt",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Obl. Pt.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOblPt_PROPERTIES

      // END_USER_CODE-USER_colOblPt_PROPERTIES
    },
    colOblWhs: {
      name: "colOblWhs",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Obl. Whs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOblWhs_PROPERTIES

      // END_USER_CODE-USER_colOblWhs_PROPERTIES
    },
    colOrgBP: {
      name: "colOrgBP",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Org. BP",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOrgBP_PROPERTIES

      // END_USER_CODE-USER_colOrgBP_PROPERTIES
    },
    colReady: {
      name: "colReady",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Ready",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colReady_PROPERTIES

      // END_USER_CODE-USER_colReady_PROPERTIES
    },
    colSeg: {
      name: "colSeg",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Seg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeg_PROPERTIES

      // END_USER_CODE-USER_colSeg_PROPERTIES
    },
    colShrunkNetWt: {
      name: "colShrunkNetWt",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Shrunk Net Wt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colShrunkNetWt_PROPERTIES

      // END_USER_CODE-USER_colShrunkNetWt_PROPERTIES
    },
    colStatus: {
      name: "colStatus",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStatus_PROPERTIES

      // END_USER_CODE-USER_colStatus_PROPERTIES
    },
    colType: {
      name: "colType",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colType_PROPERTIES

      // END_USER_CODE-USER_colType_PROPERTIES
    },
    colValue: {
      name: "colValue",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Value",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colValue_PROPERTIES

      // END_USER_CODE-USER_colValue_PROPERTIES
    },
    colVariety: {
      name: "colVariety",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVariety_PROPERTIES

      // END_USER_CODE-USER_colVariety_PROPERTIES
    },
    colVendor: {
      name: "colVendor",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendor_PROPERTIES

      // END_USER_CODE-USER_colVendor_PROPERTIES
    },
    colWireXferData: {
      name: "colWireXferData",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "Wire Transfer Data",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWireXferData_PROPERTIES

      // END_USER_CODE-USER_colWireXferData_PROPERTIES
    },
    colWRNum: {
      name: "colWRNum",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      Label: "WR #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWRNum_PROPERTIES

      // END_USER_CODE-USER_colWRNum_PROPERTIES
    },
    ddCCC500: {
      name: "ddCCC500",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails3",
      Label: "CCC 500:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCCC500_PROPERTIES

      // END_USER_CODE-USER_ddCCC500_PROPERTIES
    },
    ddContractStatus: {
      name: "ddContractStatus",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails3",
      Label: "Contract Status:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddContractStatus_PROPERTIES

      // END_USER_CODE-USER_ddContractStatus_PROPERTIES
    },
    ddCounty: {
      name: "ddCounty",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "County:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCounty_PROPERTIES

      // END_USER_CODE-USER_ddCounty_PROPERTIES
    },
    ddEWRStatus: {
      name: "ddEWRStatus",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails3",
      Label: "EWR Status:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddEWRStatus_PROPERTIES

      // END_USER_CODE-USER_ddEWRStatus_PROPERTIES
    },
    ddFarm: {
      name: "ddFarm",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Farm:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddFarm_PROPERTIES

      // END_USER_CODE-USER_ddFarm_PROPERTIES
    },
    ddFSADocReady: {
      name: "ddFSADocReady",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails1",
      Label: "FSA Doc Ready:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddFSADocReady_PROPERTIES

      // END_USER_CODE-USER_ddFSADocReady_PROPERTIES
    },
    ddGeneration: {
      name: "ddGeneration",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Generation:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddGeneration_PROPERTIES

      // END_USER_CODE-USER_ddGeneration_PROPERTIES
    },
    ddHolder: {
      name: "ddHolder",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails1",
      Label: "Holder:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddHolder_PROPERTIES

      // END_USER_CODE-USER_ddHolder_PROPERTIES
    },
    ddLoanStatus: {
      name: "ddLoanStatus",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Loan Status:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLoanStatus_PROPERTIES

      // END_USER_CODE-USER_ddLoanStatus_PROPERTIES
    },
    ddObligationPoint: {
      name: "ddObligationPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails1",
      Label: "Obligation Point:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddObligationPoint_PROPERTIES

      // END_USER_CODE-USER_ddObligationPoint_PROPERTIES
    },
    ddOleic: {
      name: "ddOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Oleic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    ddOrganic: {
      name: "ddOrganic",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Organic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOrganic_PROPERTIES

      // END_USER_CODE-USER_ddOrganic_PROPERTIES
    },
    ddOriginalPoint: {
      name: "ddOriginalPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails1",
      Label: "Original Point:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOriginalPoint_PROPERTIES

      // END_USER_CODE-USER_ddOriginalPoint_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Peanut Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddReceiptStatus: {
      name: "ddReceiptStatus",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails3",
      Label: "Receipt Status:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddReceiptStatus_PROPERTIES

      // END_USER_CODE-USER_ddReceiptStatus_PROPERTIES
    },
    ddSeed: {
      name: "ddSeed",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Seed:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeed_PROPERTIES

      // END_USER_CODE-USER_ddSeed_PROPERTIES
    },
    ddSeg: {
      name: "ddSeg",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Seg:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeg_PROPERTIES

      // END_USER_CODE-USER_ddSeg_PROPERTIES
    },
    ddState: {
      name: "ddState",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "State:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddState_PROPERTIES

      // END_USER_CODE-USER_ddState_PROPERTIES
    },
    ddWireXferData: {
      name: "ddWireXferData",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails1",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      valueList: [],

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWireXferData_PROPERTIES

      // END_USER_CODE-USER_ddWireXferData_PROPERTIES
    },
    txtWireXfer: {
      name: "txtWireXfer",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      HasLabel: false,
      // START_USER_CODE-USER_txt1007_PROPERTIES

      // END_USER_CODE-USER_txt1007_PROPERTIES
    },
    txtColStatus: {
      name: "txtColStatus",
      type: "GridColumnWidget",
      parent: "gridWarehouseReceiptList",
      VisibleForLargeDesktop: false,
      VisibleForTabLandscape: false,
      VisibleForTabPotrait: false,
      isStatusColumn: true,
      HasLabel: false,
      Visible: false,
      Height: "",
      VisibleForMobile: false,
      Width: "",
      // START_USER_CODE-USER_txtColStatus_PROPERTIES

      // END_USER_CODE-USER_txtColStatus_PROPERTIES
    },
    txtStatus: {
      name: "txtStatus",
      type: "TextBoxWidget",
      colName: "txtColStatus",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      ReadOnly: true,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStatus_PROPERTIES

      // END_USER_CODE-USER_txtStatus_PROPERTIES
    },
    gridWarehouseReceiptList: {
      name: "gridWarehouseReceiptList",
      type: "GridWidget",
      parent: "grpbxWhsRcptList",
      gridCellsOrder:
        "chkbxFSA,txtcolReady,txtcolWRNum,txtcolGroupNum,txtcolDeferred,txtcolVendor,txtcolFarmNo,txtcolOrgBP,txtcolOblPt,txtcolOblWhs,txtcolOblBin,txtcolType,txtcolVariety,txtcolGen,txtcolSeg,txtcolNetWt,txtcolShrunkNetWt,txtcolValue,txtcolContNum,txtcolStatus,txtcolLoanStatus,txtcolEWRXferStatus,txtcolHolder,txtcolBuyingPoint,txtcolEWRRcptType,txtcolLastAction,txtcolLastActionReceived,txtcolERcptNum,txtcolWireXferData,txtcolDelvAgreeNum,txtcolDelvInd,txtcolNetRepay,txtcolEndDate,txtcol211GPC,txtcol211Concordia,txtStatus",
      ColSpan: "8",
      Pagination: false,
      HasLabel: false,
      Cols: "8",
      Height: "",
      Width: "",
      clonedExtId: "24310",
      // START_USER_CODE-USER_gridWarehouseReceiptList_PROPERTIES
      clientPagination: true,
      DisplaySize: 10,
      expandedRows: [],
      pushExpandedRows: (isExpanded, row) => {
        if (isExpanded) {
          states.gridWarehouseReceiptList.expandedRows.push(row)
        }
        else {
          states.gridWarehouseReceiptList.expandedRows = states.gridWarehouseReceiptList.expandedRows.filter(rows => rows.rowID$ !== row.rowID$)
        }
      },
      isEditable: true,
      isExpandable: true,
      defaultExpand: false,
      needGridChange: false,
      isCobolTable: true,
      conditionalRowStyles: [{
        when: (row) => { return !(row.chkbxFSA !== undefined) },
        style: {
          input: {
            display: 'none'
          }
        }
      },
      {
        when: (row) => { return row.txtcolEWRXferStatus == "Rejected" },
        style: {
          backgroundColor:'red'
        }
      },
      {
      when: (row) => { return row.txtcolEWRXferStatus == "Not Ack'd" },
        style: {
          backgroundColor:'yellow'
        }
      }
    ],

      expandedCompTemplate: ({ data }) => (
        // <pre>{JSON.stringify(data, null, 2)}</pre>
        <div>

          {data.childrow ? (
            <div className="py-3 expandable-area col-sm-12 rdt_TableRow">
              {data.childrow.map((elem, i) =>
                <div className={"row ExpandRow" + " rdt_ExpanderRow" + i} key={"expandRow" + i} >
                  <div className="expandIconSpc"></div>
                  {thisObj.state.gridWarehouseReceiptList.columns[0].Visible ?
                    <div className="extraRowCell_1" key="expandCell1">
                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[1].Visible ?
                    <div className="extraRowCell_2" key="expandCell2">
                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[2].Visible ?
                    <div className="extraRowCell_3" key="expandCell3">
                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[3].Visible ?
                    <div className="extraRowCell_4" key="expandCell5">
                    </div> :
                    <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[4].Visible ?
                    <div className="extraRowCell_5" key="expandCell4">
                      <label>
                        {elem.grp}
                      </label>
                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[5].Visible ?
                    <div className="extraRowCell_6" key="expandCell6">
                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[6].Visible ?
                    <div className="extraRowCell_7" key="expandCell7">

                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[7].Visible ?
                    <div className="extraRowCell_8" key="expandCell8">
                      <label>
                        {elem.OrgBpv}
                      </label>
                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[8].Visible ?
                    <div className="extraRowCell_9" key="expandCell9">
                      <label>
                        {elem.OblPtbl}
                      </label>
                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[9].Visible ?
                    <div className="extraRowCell_10" key="expandCell10">

                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[10].Visible ?
                    <div className="extraRowCell_11" key="expandCell11">
                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[11].Visible ?
                    <div className="extraRowCell_12" key="expandCell12">
                      <label>
                        {elem.PnuttypeVar}
                      </label>
                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[12].Visible ?
                    <div className="extraRowCell_13" key="expandCell13">
                      <label>
                        {elem.PnuttypeVarseg}
                      </label>
                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[13].Visible ?
                    <div className="extraRowCell_14" key="expandCell14">

                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[14].Visible ?
                    <div className="extraRowCell_15" key="expandCell15">
                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[15].Visible ?
                    <div className="extraRowCell_16" key="expandCell16">
                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[16].Visible ?
                    <div className="extraRowCell_17" key="expandCell17">
                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[17].Visible ?
                    <div className="extraRowCell_18" key="expandCell18">
                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[18].Visible ?
                    <div className="extraRowCell_19" key="expandCell19">
                      <label>
                        {elem.Contractnum}
                      </label>
                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[19].Visible ?
                    <div className="extraRowCell_20" key="expandCell20">

                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[20].Visible ?
                    <div className="extraRowCell_21" key="expandCell21">

                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[21].Visible ?
                    <div className="extraRowCell_22" key="expandCell22">

                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[22].Visible ?
                    <div className="extraRowCell_23" key="expandCell23">

                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[23].Visible ?
                    <div className="extraRowCell_24" key="expandCell24">

                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[24].Visible ?
                    <div className="extraRowCell_25" key="expandCell25">

                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[25].Visible ?
                    <div className="extraRowCell_26" key="expandCell26">

                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[26].Visible ?
                    <div className="extraRowCell_27" key="expandCell27">

                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[27].Visible ?
                    <div className="extraRowCell_28" key="expandCell28">
                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[28].Visible ?
                    <div className="extraRowCell_29" key="expandCell29">
                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[29].Visible ?
                    <div className="extraRowCell_30" key="expandCell30">
                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[30].Visible ?
                    <div className="extraRowCell_31" key="expandCell31">
                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[31].Visible ?
                    <div className="extraRowCell_32" key="expandCell32">
                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[32].Visible ?
                    <div className="extraRowCell_33" key="expandCell33">
                    </div> : <></>}
                  {thisObj.state.gridWarehouseReceiptList.columns[33].Visible ?
                    <div className="extraRowCell_34" key="expandCell34">
                    </div> : <></>}

                </div>
              )}
            </div>
          ) : null}
        </div>
      )


      // END_USER_CODE-USER_gridWarehouseReceiptList_PROPERTIES
    },
    grpbxBtnCntnr: {
      name: "grpbxBtnCntnr",
      type: "GroupBoxWidget",
      parent: "grpbxActions",
      ColSpan: "6",
      Height: "",
      Width: "",
      ColsForMobile: "6",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_grpbxBtnCntnr_PROPERTIES

      // END_USER_CODE-USER_grpbxBtnCntnr_PROPERTIES
    },
    grpbxDetails1: {
      name: "grpbxDetails1",
      type: "GroupBoxWidget",
      parent: "grpbxWarehouseReceiptInquiry",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForMobile: "3",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxDetails1_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails1_PROPERTIES
    },
    grpbxDetails2: {
      name: "grpbxDetails2",
      type: "GroupBoxWidget",
      parent: "grpbxWarehouseReceiptInquiry",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxDetails2_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails2_PROPERTIES
    },
    grpbxDetails3: {
      name: "grpbxDetails3",
      type: "GroupBoxWidget",
      parent: "grpbxWarehouseReceiptInquiry",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForMobile: "3",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxDetails3_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails3_PROPERTIES
    },
    grpbxFSADocument: {
      name: "grpbxFSADocument",
      type: "GroupBoxWidget",
      parent: "grpbxWhsRcptList",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForMobile: "3",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxFSADocument_PROPERTIES

      // END_USER_CODE-USER_grpbxFSADocument_PROPERTIES
    },
    grpbxWhsRcptList: {
      name: "grpbxWhsRcptList",
      type: "GroupBoxWidget",
      parent: "grpbxWarehouseReceiptInquiry",
      Height: "",
      Width: "",
      ColsForMobile: "8",
      ColsForTabLandscape: "8",
      HasLabel: false,
      Cols: "8",
      ColsForTabPotrait: "8",
      ColsForLargeDesktop: "8",
      // START_USER_CODE-USER_grpbxWhsRcptList_PROPERTIES

      // END_USER_CODE-USER_grpbxWhsRcptList_PROPERTIES
    },
    lblCropYear: {
      name: "lblCropYear",
      type: "LabelWidget",
      parent: "grpbxWhsRcptList",
      Label: "Crop Year:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCropYear_PROPERTIES

      // END_USER_CODE-USER_lblCropYear_PROPERTIES
    },
    lblFSADocument: {
      name: "lblFSADocument",
      type: "LabelWidget",
      parent: "grpbxFSADocument",
      Label: "FSA Document",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFSADocument_PROPERTIES

      // END_USER_CODE-USER_lblFSADocument_PROPERTIES
    },
    lblWhsRcptList: {
      name: "lblWhsRcptList",
      type: "LabelWidget",
      parent: "grpbxWhsRcptList",
      Label: "Whs Rcpt List:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWhsRcptList_PROPERTIES

      // END_USER_CODE-USER_lblWhsRcptList_PROPERTIES
    },
    radioOptPrint: {
      name: "radioOptPrint",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxActions",
      Options: "As Shown:1,Expanded:2,Collapsed:3,Wire Xfer:4",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioOptPrint_PROPERTIES

      // END_USER_CODE-USER_radioOptPrint_PROPERTIES
    },
    txt1007Num: {
      name: "txt1007Num",
      type: "TextBoxWidget",
      parent: "grpbxDetails2",
      Label: "1007 #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007Num_PROPERTIES

      // END_USER_CODE-USER_txt1007Num_PROPERTIES
    },
    txtcol211Concordia: {
      name: "txtcol211Concordia",
      type: "TextBoxWidget",
      colName: "col211Concordia",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol211Concordia_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcol211Concordia_PROPERTIES
    },
    txtcol211GPC: {
      name: "txtcol211GPC",
      type: "TextBoxWidget",
      colName: "col211GPC",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol211GPC_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcol211GPC_PROPERTIES
    },
    txtcolBuyingPoint: {
      name: "txtcolBuyingPoint",
      type: "TextBoxWidget",
      colName: "colBuyingPoint",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBuyingPoint_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolBuyingPoint_PROPERTIES
    },
    txtcolContNum: {
      name: "txtcolContNum",
      type: "TextBoxWidget",
      colName: "colContNum",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContNum_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolContNum_PROPERTIES
    },
    txtcolDeferred: {
      name: "txtcolDeferred",
      type: "TextBoxWidget",
      colName: "colDeferred",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDeferred_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolDeferred_PROPERTIES
    },
    txtcolDelvAgreeNum: {
      name: "txtcolDelvAgreeNum",
      type: "TextBoxWidget",
      colName: "colDelvAgreeNum",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDelvAgreeNum_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolDelvAgreeNum_PROPERTIES
    },
    txtcolDelvInd: {
      name: "txtcolDelvInd",
      type: "TextBoxWidget",
      colName: "colDelvInd",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDelvInd_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolDelvInd_PROPERTIES
    },
    txtcolEndDate: {
      name: "txtcolEndDate",
      type: "TextBoxWidget",
      colName: "colEndDate",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolEndDate_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolEndDate_PROPERTIES
    },
    txtcolERcptNum: {
      name: "txtcolERcptNum",
      type: "TextBoxWidget",
      colName: "colERcptNum",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolERcptNum_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolERcptNum_PROPERTIES
    },
    txtcolEWRRcptType: {
      name: "txtcolEWRRcptType",
      type: "TextBoxWidget",
      colName: "colEWRRcptType",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolEWRRcptType_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolEWRRcptType_PROPERTIES
    },
    txtcolEWRXferStatus: {
      name: "txtcolEWRXferStatus",
      type: "TextBoxWidget",
      colName: "colEWRXferStatus",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolEWRXferStatus_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolEWRXferStatus_PROPERTIES
    },
    txtcolFarmNo: {
      name: "txtcolFarmNo",
      type: "TextBoxWidget",
      colName: "colFarmNo",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFarmNo_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolFarmNo_PROPERTIES
    },
    txtcolGen: {
      name: "txtcolGen",
      type: "TextBoxWidget",
      colName: "colGen",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolGen_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolGen_PROPERTIES
    },
    txtcolGroupNum: {
      name: "txtcolGroupNum",
      type: "TextBoxWidget",
      colName: "colGroupNum",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolGroupNum_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolGroupNum_PROPERTIES
    },
    txtcolHolder: {
      name: "txtcolHolder",
      type: "TextBoxWidget",
      colName: "colHolder",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolHolder_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolHolder_PROPERTIES
    },
    txtcolLastAction: {
      name: "txtcolLastAction",
      type: "TextBoxWidget",
      colName: "colLastAction",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLastAction_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolLastAction_PROPERTIES
    },
    txtcolLastActionReceived: {
      name: "txtcolLastActionReceived",
      type: "TextBoxWidget",
      colName: "colLastActionReceived",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLastActionReceived_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolLastActionReceived_PROPERTIES
    },
    txtcolLoanStatus: {
      name: "txtcolLoanStatus",
      type: "TextBoxWidget",
      colName: "colLoanStatus",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLoanStatus_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolLoanStatus_PROPERTIES
    },
    txtcolNetRepay: {
      name: "txtcolNetRepay",
      type: "TextBoxWidget",
      colName: "colNetRepay",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNetRepay_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolNetRepay_PROPERTIES
    },
    txtcolNetWt: {
      name: "txtcolNetWt",
      type: "TextBoxWidget",
      colName: "colNetWt",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNetWt_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolNetWt_PROPERTIES
    },
    txtcolOblBin: {
      name: "txtcolOblBin",
      type: "TextBoxWidget",
      colName: "colOblBin",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOblBin_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolOblBin_PROPERTIES
    },
    txtcolOblPt: {
      name: "txtcolOblPt",
      type: "TextBoxWidget",
      colName: "colOblPt",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOblPt_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolOblPt_PROPERTIES
    },
    txtcolOblWhs: {
      name: "txtcolOblWhs",
      type: "TextBoxWidget",
      colName: "colOblWhs",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOblWhs_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolOblWhs_PROPERTIES
    },
    txtcolOrgBP: {
      name: "txtcolOrgBP",
      type: "TextBoxWidget",
      colName: "colOrgBP",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOrgBP_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolOrgBP_PROPERTIES
    },
    txtcolReady: {
      name: "txtcolReady",
      type: "TextBoxWidget",
      colName: "colReady",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolReady_PROPERTIES
      ReadOnly: true,
      // Enabled:false
      // END_USER_CODE-USER_txtcolReady_PROPERTIES
    },
    txtcolSeg: {
      name: "txtcolSeg",
      type: "TextBoxWidget",
      colName: "colSeg",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeg_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolSeg_PROPERTIES
    },
    txtcolShrunkNetWt: {
      name: "txtcolShrunkNetWt",
      type: "TextBoxWidget",
      colName: "colShrunkNetWt",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolShrunkNetWt_PROPERTIES
      ReadOnly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolShrunkNetWt_PROPERTIES
    },
    txtcolStatus: {
      name: "txtcolStatus",
      type: "TextBoxWidget",
      colName: "colStatus",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolStatus_PROPERTIES
      ReadOnly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolStatus_PROPERTIES
    },
    txtcolType: {
      name: "txtcolType",
      type: "TextBoxWidget",
      colName: "colType",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolType_PROPERTIES
      ReadOnly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolType_PROPERTIES
    },
    txtcolValue: {
      name: "txtcolValue",
      type: "TextBoxWidget",
      colName: "colValue",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolValue_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolValue_PROPERTIES
    },
    txtcolVariety: {
      name: "txtcolVariety",
      type: "TextBoxWidget",
      colName: "colVariety",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVariety_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolVariety_PROPERTIES
    },
    txtcolVendor: {
      name: "txtcolVendor",
      type: "TextBoxWidget",
      colName: "colVendor",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendor_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolVendor_PROPERTIES
    },
    txtcolWireXferData: {
      name: "txtcolWireXferData",
      type: "TextBoxWidget",
      colName: "colWireXferData",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWireXferData_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolWireXferData_PROPERTIES
    },
    txtcolWRNum: {
      name: "txtcolWRNum",
      type: "TextBoxWidget",
      colName: "colWRNum",
      parent: "gridWarehouseReceiptList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWRNum_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolWRNum_PROPERTIES
    },
    txtContract: {
      name: "txtContract",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      Label: "Contract #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContract_PROPERTIES

      // END_USER_CODE-USER_txtContract_PROPERTIES
    },
    txtEndDateFrom: {
      name: "txtEndDateFrom",
      type: "DateWidget",
      parent: "grpbxDetails3",
      Label: "End Date:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtEndDateFrom_PROPERTIES

      // END_USER_CODE-USER_txtEndDateFrom_PROPERTIES
    },
    txtEndDateTo: {
      name: "txtEndDateTo",
      type: "DateWidget",
      parent: "grpbxDetails3",
      Label: "-",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtEndDateTo_PROPERTIES

      // END_USER_CODE-USER_txtEndDateTo_PROPERTIES
    },
    txtGroupNum: {
      name: "txtGroupNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails2",
      Label: "Group #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGroupNum_PROPERTIES

      // END_USER_CODE-USER_txtGroupNum_PROPERTIES
    },
    txtLoanNum1: {
      name: "txtLoanNum1",
      type: "TextBoxWidget",
      parent: "grpbxDetails3",
      Label: "Loan #:",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoanNum1_PROPERTIES

      // END_USER_CODE-USER_txtLoanNum1_PROPERTIES
    },
    txtLoanNum2: {
      name: "txtLoanNum2",
      type: "TextBoxWidget",
      parent: "grpbxDetails3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoanNum2_PROPERTIES

      // END_USER_CODE-USER_txtLoanNum2_PROPERTIES
    },
    txtLoanNum3: {
      name: "txtLoanNum3",
      type: "TextBoxWidget",
      parent: "grpbxDetails3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 5 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoanNum3_PROPERTIES

      // END_USER_CODE-USER_txtLoanNum3_PROPERTIES
    },
    txtLockInDateFrom: {
      name: "txtLockInDateFrom",
      type: "DateWidget",
      parent: "grpbxDetails3",
      Label: "Lock-in Date:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtLockInDateFrom_PROPERTIES

      // END_USER_CODE-USER_txtLockInDateFrom_PROPERTIES
    },
    txtLockInDateTo: {
      name: "txtLockInDateTo",
      type: "DateWidget",
      parent: "grpbxDetails3",
      Label: "-",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtLockInDateTo_PROPERTIES

      // END_USER_CODE-USER_txtLockInDateTo_PROPERTIES
    },
    txtMaturityDateFrom: {
      name: "txtMaturityDateFrom",
      type: "DateWidget",
      parent: "grpbxDetails3",
      Label: "Maturity Date:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtMaturityDateFrom_PROPERTIES

      // END_USER_CODE-USER_txtMaturityDateFrom_PROPERTIES
    },
    txtMaturityDateTo: {
      name: "txtMaturityDateTo",
      type: "DateWidget",
      parent: "grpbxDetails3",
      Label: "-",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtMaturityDateTo_PROPERTIES

      // END_USER_CODE-USER_txtMaturityDateTo_PROPERTIES
    },
    txtOblBinNum: {
      name: "txtOblBinNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      Label: "Obl Bin #:",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOblBinNum_PROPERTIES

      // END_USER_CODE-USER_txtOblBinNum_PROPERTIES
    },
    txtOblWhseNum: {
      name: "txtOblWhseNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      Label: "Obl Whse #:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOblWhseNum_PROPERTIES

      // END_USER_CODE-USER_txtOblWhseNum_PROPERTIES
    },
    txtReceiptNum: {
      name: "txtReceiptNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      Label: "Receipt #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtReceiptNum_PROPERTIES

      // END_USER_CODE-USER_txtReceiptNum_PROPERTIES
    },
    txtRedemptionDateFrom: {
      name: "txtRedemptionDateFrom",
      type: "DateWidget",
      parent: "grpbxDetails3",
      Label: "Redemption Date:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtRedemptionDateFrom_PROPERTIES

      // END_USER_CODE-USER_txtRedemptionDateFrom_PROPERTIES
    },
    txtRedemptionDateTo: {
      name: "txtRedemptionDateTo",
      type: "DateWidget",
      parent: "grpbxDetails3",
      Label: "-",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtRedemptionDateTo_PROPERTIES

      // END_USER_CODE-USER_txtRedemptionDateTo_PROPERTIES
    },
    txtTotalNetRepay: {
      name: "txtTotalNetRepay",
      type: "TextBoxWidget",
      parent: "grpbxWhsRcptList",
      Label: "Total Net Repay:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalNetRepay_PROPERTIES

      // END_USER_CODE-USER_txtTotalNetRepay_PROPERTIES
    },
    txtTotalNetWt: {
      name: "txtTotalNetWt",
      type: "TextBoxWidget",
      parent: "grpbxWhsRcptList",
      Label: "Total Net Wt:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalNetWt_PROPERTIES

      // END_USER_CODE-USER_txtTotalNetWt_PROPERTIES
    },
    txtTotalShrunkNetWt: {
      name: "txtTotalShrunkNetWt",
      type: "TextBoxWidget",
      parent: "grpbxWhsRcptList",
      Label: "Total Shrunk Net Wt:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalShrunkNetWt_PROPERTIES

      // END_USER_CODE-USER_txtTotalShrunkNetWt_PROPERTIES
    },
    txtTotalValue: {
      name: "txtTotalValue",
      type: "TextBoxWidget",
      parent: "grpbxWhsRcptList",
      Label: "Total Value:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalValue_PROPERTIES

      // END_USER_CODE-USER_txtTotalValue_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtVendr: {
      name: "txtVendr",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendr_PROPERTIES

      // END_USER_CODE-USER_txtVendr_PROPERTIES
    },
    grpbxWarehouseReceiptInquiry: {
      name: "grpbxWarehouseReceiptInquiry",
      type: "GroupBoxWidget",
      parent: "WarehouseReceiptInquiry",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxWarehouseReceiptInquiry_PROPERTIES

      // END_USER_CODE-USER_grpbxWarehouseReceiptInquiry_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "WarehouseReceiptInquiry",
      Height: "",
      Width: "",
      ColsForMobile: "7",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu": {},
        "WarehouseReceipts#WarehouseReceipt": {},
        "WarehouseReceipts#WarehouseReceiptRedeemByLoan": {},
        "WarehouseReceipts#WarehouseReceiptUpload": {},
        "WarehouseReceipts#WarehouseReceipt": {}
      },
      REVERSE: {
        "ContractManagement#MainMenu": {},
        "WarehouseReceipts#WarehouseReceipt": {},
        "WarehouseReceipts#WarehouseReceiptRedeemByLoan": {},
        "WarehouseReceipts#WarehouseReceiptUpload": {},
        "WarehouseReceipts#WarehouseReceipt": {}
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnVendor: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
    btnCreateWarehouseReceipt: {
      DEFAULT: ["WarehouseReceipts#WarehouseReceipt#DEFAULT#true#Click"],
    },
    btnRedeemByLoan: {
      DEFAULT: [
        "WarehouseReceipts#WarehouseReceiptRedeemByLoan#DEFAULT#true#Click",
      ],
    },
    btnUploadLockIn: {
      DEFAULT: ["WarehouseReceipts#WarehouseReceiptUpload#DEFAULT#true#Click"],
    },
    btnClose: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    },
    btnCreateWarehouseReceipt: {
      DEFAULT: ["WarehouseReceipts#WarehouseReceipt#DEFAULT#true#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  // FOR Count Check,enable,disable,currentval(statrtingcheck box iS "Y"Or "N")
  const [chkCount, setChkCount] = useState(0);
  const [chkenable, setchkenable] = useState(false);
  const [chkdisable, setchkdisable] = useState(false);
  const [currentVal, setcurrentVal] = useState('');

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    setValue(thisObj, "radioOptPrint", "1") //bydefault first radio btn selected
    hide(thisObj, "chkboxAllFSA", false)

    setEventHandlers();

    return () => {
      removeEventHandlers();
    }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {// to enable only call when chkenable state will update
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    if (chkenable == true) {
      let gridData = getValue(thisObj, "gridWarehouseReceiptList")
      enablecheckbx(gridData)
      setchkenable(false)
    }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, [chkenable]);

  useEffect(() => {// to disable only call when chkdisable state will update
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    if (chkdisable == true) {
      let gridData = getValue(thisObj, "gridWarehouseReceiptList")
      disablecheckbx(gridData)
      setchkdisable(false)
    }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, [chkdisable]);

  useEffect(() => {//for vendor textbox.
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    setVendorNameBasedOnVendorNumber()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, [getData(thisObj, 'vendorDetails')]);

  useEffect(() => {// Data Coming from ReportPreview screen
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    let printallFSAScreen = getData(thisObj, "closingOfReportPreviewWR")
    if (printallFSAScreen == true) {
      setChkCount(0)//to set count as 0 when we refreshed the grid
      setchkenable(true)// to enable every check box in grid when we refresh data
      setData(thisObj, "closingOfReportPreviewWR", false)
    }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, [getData(thisObj, 'closingOfReportPreviewWR')]);

  useEffect(() => {//UseEffect for the ViewWhouseReceipt && PremiumDeductionWRPurchase reverse Integration.
    const updatedDatafromChild = getData(thisObj, 'dataComingfromChildScreen');
    if (updatedDatafromChild) {
      const selectedRowData = thisObj.state.gridWarehouseReceiptList.selected[0];
      const selectedRowIndex = selectedRowData?.rowID$
      const gridData = getValue(thisObj, 'gridWarehouseReceiptList');
      if (selectedRowIndex !== -1) { // make sure selected row exists
        const existingGridRowData = gridData[selectedRowIndex];
        gridData[selectedRowIndex] = {
          ...existingGridRowData,
          txtcolStatus: updatedDatafromChild?.txtcolStatus,
        };
        setValue(thisObj, "gridWarehouseReceiptList", gridData)
        // hide(thisObj, "btnCancel", false) need to validate once Product Issue solved.
      }
      setData(thisObj, 'dataComingfromChildScreen', null)
    }
    //or direct Dosearch use it simple.
  }, [getData(thisObj, 'dataComingfromChildScreen')]);

  useEffect(() => {//UseEffect for the Cancle/Uncancel btn pupup Yes/No Condition
    const datacomingpopUp = getData(thisObj, 'returnfromNoBtnClick');
    if (datacomingpopUp) {
      if (datacomingpopUp?.yesNO == "NO") {//If User Click on No
        const LastAction = datacomingpopUp?.LastAction
        const selectedRowData1 = thisObj.state.gridWarehouseReceiptList.selected[0];
        const selectedRowIndex1 = selectedRowData1?.rowID$
        const gridData1 = getValue(thisObj, 'gridWarehouseReceiptList');
        if (datacomingpopUp?.Send_to_ewr == "N") {
          if (selectedRowIndex1 !== -1) { // make sure selected row exists
            const existingGridRowData1 = gridData1[selectedRowIndex1];
            gridData1[selectedRowIndex1] = {
              ...existingGridRowData1,
              txtcolLastAction: LastAction,
            };
            setValue(thisObj, "gridWarehouseReceiptList", gridData1)

            if (LastAction?.trim()?.toUpperCase() == "UCN") {
              document.getElementById("btnCancel").innerText = "Cancel"
            }
            else {
              document.getElementById("btnCancel").innerText = "Uncancel"
            }
            hide(thisObj, "btnCancel", false)
          }
        }
      }
      //==========================================//===================================
      else {//If UserClick on Yes
        const selectedRowData2 = thisObj.state.gridWarehouseReceiptList.selected[0];
        const selectedRowIndex2 = selectedRowData2?.rowID$
        const gridData2 = getValue(thisObj, 'gridWarehouseReceiptList');
        if (selectedRowIndex2 !== -1) { // make sure selected row exists
          const existingGridRowData2 = gridData2[selectedRowIndex2];
          gridData2[selectedRowIndex2] = {
            ...existingGridRowData2
          };
          setValue(thisObj, "gridWarehouseReceiptList", gridData2)
          hide(thisObj, "btnCancel", false)
        }
      }
      setData(thisObj, 'returnfromNoBtnClick', '')
    }
  }, [getData(thisObj, 'returnfromNoBtnClick')]);

  // START_USER_CODE-USER_METHODS

  const [loading, setLoading] = useState(false);
  const [mbCancelUncancelPermissionDeny, setMbCancelUncancelPermissionDeny] = useState(false);
  const [mbResetEWRStatus, setMbResetEWRStatus] = useState(false);
  const [mbClearRPS, setMbClearRPS] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  const FormLoad = async () => {//form load functionality
    setLoading(true);
    setValue(thisObj, "lblCropYear", cropYear);
    setClickCount(0)
    disable(thisObj, "btnReady");
    disable(thisObj, "btnNotReady");
    disable(thisObj, "ddWireXferData")
    disable(thisObj, "txtWireXfer")
    bFillStatusList();
    bFillFSADocReady()
    bFillLoanStatus()
    bFillTypeList();
    bFillLocationList();
    bFillOrgLocationList();
    bFillHolderList();
    bFillStateList();
    bFillSegmentList();
    bFillStatList();
    bFillEWRStatusList();
    bFillOleicList();
    bFillCCC500List();
    bFillGenList();
    bFillSeedList();
    bFillOrganicList();
    setValue(thisObj, "txtVendor", '')
    setValue(thisObj, "txtVendr", '')
    hide(thisObj, "txtVendr", false)

    hide(thisObj, 'btnCancel', false);
    setMbCancelUncancelPermissionDeny(false);
    let response1 = await ContractManagementService.RetrieveAccessPermissionDetails('PN1090', '009', 'U')
    if (response1[0].permission == 'N') {
      setMbCancelUncancelPermissionDeny(true);
    }

    hide(thisObj, 'btnResetToEWRAccepted', false);
    setMbResetEWRStatus(false);
    let response2 = await ContractManagementService.RetrieveAccessPermissionDetails('PN1090', '010', 'U')
    if (response2[0].permission == 'Y') {
      setMbResetEWRStatus(true);
    }

    hide(thisObj, 'btnClearRPS', false);
    setMbClearRPS(false);
    let response3 = await ContractManagementService.RetrieveAccessPermissionDetails('PN1090', '019', 'U')
    if (response3[0].permission == 'Y') {
      setMbClearRPS(true);
    }

    let response4 = await ContractManagementService.RetrieveAccessPermissionDetails('PN1090', '012', 'I')
    if (response4[0].permission != 'Y') {
      hide(thisObj, 'btnExtRcptForfeiture', false);
    }

    let response5 = await ContractManagementService.RetrieveAccessPermissionDetails('PN1090', '016', 'U')
    if (response5[0].permission == 'Y') {
      show(thisObj, 'btnUploadLockIn');
    }

    let response6 = await ContractManagementService.RetrieveAccessPermissionDetails('PN1090', '018', 'U')
    if (response6[0].permission == 'Y') {
      show(thisObj, 'btnRedeemByLoan');
    }
    setLoading(false);
  };

  //--functions start--
  const bFillStatusList = () => {//status dropdown
    try {
      let js = [];
      js.push({ key: "", description: ">>> All Receipt Statuses <<<" });
      js.push({ key: "I", description: "Issued" });
      js.push({ key: "D", description: "Delivered Out" });
      js.push({ key: "L", description: "Delivery In Process" });
      js.push({ key: "V", description: "Voided" });
      js.push({ key: "X", description: "Pending Void" });
      js.push({ key: "P", description: "Purchased" });
      js.push({ key: "F", description: "Pending Forfeited Purchase" });

      thisObj.setState((current) => {
        return {
          ...current,
          ddReceiptStatus: {
            ...state["ddReceiptStatus"],
            valueList: js,
          },
        };
      });
      setValue(thisObj, "ddReceiptStatus", js[0].key);
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during bFillStatusList: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  const bFillFSADocReady = () => {//FSADoc Dropdown
    try {
      let js = [];
      js.push({ key: "", description: ">>> All <<<" });
      js.push({ key: "Yes", description: "Yes" });
      js.push({ key: "No", description: "No" });
      js.push({ key: "Blank", description: "<Blank>" });

      thisObj.setState((current) => {
        return {
          ...current,
          ddFSADocReady: {
            ...state["ddFSADocReady"],
            valueList: js,
          },
        };
      });
      setValue(thisObj, "ddFSADocReady", js[0].key);
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during bFillFSADocReady: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  const bFillLoanStatus = () => {//LoanDropdown
    try {
      let js = [];
      js.push({ key: "", description: ">>> All Loan Statuses <<<" });
      js.push({ key: "Not Forfeited", description: "Not Forfeited" });
      js.push({ key: "Forfeited", description: "Forfeited" });

      thisObj.setState((current) => {
        return {
          ...current,
          ddLoanStatus: {
            ...state["ddLoanStatus"],
            valueList: js,
          },
        };
      });
      setValue(thisObj, "ddLoanStatus", js[0].key);
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during bFillLoanStatus: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  const bFillTypeList = () => {//for Type dropdown
    try {
      let js = [];
      js.push({ key: "", description: ">>> All Types <<<" });
      ContractManagementService.RetrievePeanutTypeControls().then((response) => {
        let data = response;
        for (var i = 0; i < data.length; i++) {
          let obj = {
            key: data[i].pnutTypeId,
            description: data[i].pnutTypeName,
          };
          js.push(obj);
        }
        thisObj.setState((current) => {
          return {
            ...current,
            ddPeanutType: {
              ...state["ddPeanutType"],
              valueList: js,
            },
          };
        });
      });
      setValue(thisObj, "ddPeanutType", js[0].key);
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during bFillTypeList: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  const bFillLocationList = () => {//For Location
    try {
      let js = [];
      js.push({ key: "", description: ">>> All Locations <<<" });
      ContractManagementService.RetrieveBuyingPointControlDetails("PN1090", null, null, null, null).then((response) => {
        let data = response;
        for (var i = 0; i < data.length; i++) {
          let obj = {
            key: data[i].buy_pt_id,
            description:
              data[i].buy_pt_id.trim() + " - " + ConvertToPascalCase(data[i].buy_pt_name.trim()),
          };
          js.push(obj);
        }
        thisObj.setState((current) => {
          return {
            ...current,
            ddObligationPoint: {
              ...state["ddObligationPoint"],
              valueList: js,
            },
          };
        });
        setValue(thisObj, "ddObligationPoint", js[0].key);
        setData(thisObj, "ddObligationPointBuyingPoint", js)
      });
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during bFillLocationList: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  const bFillOrgLocationList = () => {
    try {
      let js = [];
      js.push({ key: "", description: ">>> All Locations <<<" });
      ContractManagementService.RetrieveBuyingPointControlDetails("PN1090", "008", null, null, null).then((response) => {
        let data = response;
        for (var i = 0; i < data.length; i++) {
          let obj = {
            key: data[i].buy_pt_id,
            description:
              data[i].buy_pt_id.trim() + " - " + ConvertToPascalCase(data[i].buy_pt_name.trim()),
          };
          js.push(obj);
        }
        thisObj.setState((current) => {
          return {
            ...current,
            ddOriginalPoint: {
              ...state["ddOriginalPoint"],
              valueList: js,
            },
          };
        });
        setValue(thisObj, "ddOriginalPoint", js[0].key);
      });
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during bFillOrgLocationList: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  const bFillHolderList = () => {//for holder
    try {
      let js = [];
      js.push({ key: "", description: ">>> All Holders <<<" });
      WarehouseReceiptService.RetrieveVendorHolderTransferDetails(compId, cropYear).then((response) => {
        let data = response;
        for (var i = 0; i < data.length; i++) {
          let obj = {
            key: data[i].holder_id,
            description:
              data[i].holder_name.trim(),
          };
          js.push(obj);
        }
        thisObj.setState((current) => {
          return {
            ...current,
            ddHolder: {
              ...state["ddHolder"],
              valueList: js,
            },
          };
        });
        setValue(thisObj, "ddHolder", js[0].key);
      });
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during bFillHolderList: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  const bFillStateList = () => {//for State
    try {
      let jsCounties = [], jsState = [], jsFarms = [];
      jsFarms.push({ key: '', description: '>>> All Farms <<<' })
      thisObj.setState(current => {
        return {
          ...current,
          ddFarm: {
            ...state["ddFarm"],
            valueList: jsFarms,
          }
        }
      })
      setValue(thisObj, 'ddFarm', jsFarms[0].key);

      jsCounties.push({ key: '', description: '>>> All Counties <<<' })
      thisObj.setState(current => {
        return {
          ...current,
          ddCounty: {
            ...state["ddCounty"],
            valueList: jsCounties,
          }
        }
      })
      setValue(thisObj, 'ddCounty', jsCounties[0].key);

      jsState.push({ key: '', description: '>>> All States <<<' })
      ContractManagementService.RetrievePeanutStateControlDetails(null, null, false).then(response => {
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].stateAbbr, description: data[i].stateName }
          jsState.push(obj)
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddState: {
              ...state["ddState"],
              valueList: jsState,
            }
          }
        })
      })
      setValue(thisObj, 'ddState', jsState[0].key);
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during bFillStateList: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  const bFillSegmentList = () => {//for Segment
    try {
      let js = []
      js.push({ key: '', description: '>>> All Segs <<<' })
      js.push({ key: '1', description: 'Seg1' })
      js.push({ key: '2', description: 'Seg2' })
      js.push({ key: '3', description: 'Seg3' })
      thisObj.setState(current => {
        return {
          ...current,
          ddSeg: {
            ...state["ddSeg"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddSeg', js[0].key);
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during bFillSegmentList: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  const bFillStatList = () => {
    try {
      let js = []
      js.push({ key: '', description: '>>> All Contract Statuses <<<' })
      js.push({ key: '1', description: 'Contract Firm' })
      js.push({ key: '2', description: 'Contract Basis' })
      js.push({ key: '3', description: 'Option Basis' })
      js.push({ key: '4', description: 'UnContracted' })
      js.push({ key: '5', description: 'Flex Market Basis' })
      if (cropYear >= 2011) {
        js.push({ key: '6', description: 'Floor Basis' })
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddContractStatus: {
            ...state["ddContractStatus"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddContractStatus', js[0].key);
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during bFillStatList: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  const bFillEWRStatusList = () => {
    try {
      let js = []
      js.push({ key: '', description: '>>> All EWR Statuses <<<' })
      js.push({ key: 'A', description: 'Accepted' })
      js.push({ key: 'R', description: 'Rejected' })
      js.push({ key: 'X', description: "Not Ack'd" })

      thisObj.setState(current => {
        return {
          ...current,
          ddEWRStatus: {
            ...state["ddEWRStatus"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddEWRStatus', js[0].key);
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during bFillEWRStatusList: ${error?.toString()}`
        );
      }
      return false;
    }
  };

  const bFillOleicList = () => {
    let js = []
    js.push({ key: '', description: '>>> All <<<' })
    js.push({ key: 'H', description: 'High' })
    js.push({ key: 'M', description: 'Mid' })
    js.push({ key: 'N', description: 'None' })
    thisObj.setState(current => {
      return {
        ...current,
        ddOleic: {
          ...state["ddOleic"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddOleic', js[0].key);
  };

  const bFillCCC500List = () => {
    let js = []
    js.push({ key: '', description: '>>> All <<<' })
    js.push({ key: 'Y', description: 'Yes' })
    js.push({ key: 'N', description: 'No' })
    thisObj.setState(current => {
      return {
        ...current,
        ddCCC500: {
          ...state["ddCCC500"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddCCC500', js[0].key);
  };

  const bFillGenList = () => {
    let js = []
    js.push({ key: '', description: '>>> All <<<' })
    js.push({ key: 'F', description: 'Foundation' })
    js.push({ key: 'R', description: 'Registered' })
    js.push({ key: 'C', description: 'Certified' })
    thisObj.setState(current => {
      return {
        ...current,
        ddGeneration: {
          ...state["ddGeneration"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddGeneration', js[0].key);
  };

  const bFillSeedList = () => {
    let js = []
    js.push({ key: '', description: '>>Both<<' })
    js.push({ key: 'Y', description: 'Yes' })
    js.push({ key: 'N', description: 'No' })
    thisObj.setState(current => {
      return {
        ...current,
        ddSeed: {
          ...state["ddSeed"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddSeed', js[0].key);
  };

  const bFillOrganicList = () => {
    let js = []
    js.push({ key: '', description: '>>Both<<' })
    js.push({ key: 'Y', description: 'Yes' })
    js.push({ key: 'N', description: 'No' })
    thisObj.setState(current => {
      return {
        ...current,
        ddOrganic: {
          ...state["ddOrganic"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddOrganic', js[0].key);
  };

  const bFillCountyList = async () => {//County Dropdown
    try {
      setLoading(true);
      let jsCounty = [], jsFarms = [];
      let stateValue = getValue(thisObj, "ddState");
      jsCounty.push({ key: '', description: '>>> All Counties <<<' })
      if (stateValue != "" && stateValue != undefined && stateValue != null) {
        let response = await ContractManagementService.RetrieveCountyControlDetails('NA', false, stateValue, null)
        let data = response
        if (data !== null && data !== undefined && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            jsCounty.push({ key: data[i].countyId, description: data[i].countyName })
          }
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddCounty: {
            ...state["ddCounty"],
            valueList: jsCounty,
          }
        }
      })
      setValue(thisObj, 'ddCounty', jsCounty[0].key);

      jsFarms.push({ key: '', description: '>>> All Farms <<<' })
      thisObj.setState(current => {
        return {
          ...current,
          ddFarm: {
            ...state["ddFarm"],
            valueList: jsFarms,
          }
        }
      })
      setValue(thisObj, 'ddFarm', jsFarms[0].key);
      setLoading(false);
    }
    catch (error) {
      setLoading(false);
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during bFillCountyList: ${error?.toString()}`
        );
      }
      return false;
    }
  }

  const bFillFarmList = async () => {//farm dropdown
    try {
      setLoading(true);
      let buy_pt_id = getValue(thisObj, 'ddOriginalPoint');
      let stateAbbrevations = getValue(thisObj, 'ddState');
      let countyId = getValue(thisObj, 'ddCounty');
      let jsFarms = [];
      jsFarms.push({ key: '', description: '>>> All Farms <<<' })
      if ((stateAbbrevations != "" && stateAbbrevations != undefined) && (countyId != "" && countyId != undefined)) {
        let response = await ContractManagementService.RetrieveFarmControlDetails('PN1090', null, buy_pt_id, stateAbbrevations, countyId, null, null)
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: i + '$' + data[i].farm_id + '-' + data[i].farm_suffix, description: data[i].farm_id + ' - ' + data[i].farm_suffix + ' - ' + data[i].farm_name }
          jsFarms.push(obj);
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddFarm: {
            ...state["ddFarm"],
            valueList: jsFarms,
          }
        }
      })
      setValue(thisObj, 'ddFarm', jsFarms[0].key);
      setLoading(false);
    }
    catch (error) {
      setLoading(false);
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during bFillFarmList: ${error?.toString()}`
        );
      }
      return false;
    }
  }

  const onddStateChange = () => {
    bFillCountyList();
  }
  thisObj.onddStateChange = onddStateChange;

  const onddCountyChange = () => {
    bFillFarmList()
  }
  thisObj.onddCountyChange = onddCountyChange;

  const onddOriginalPointChange = () => {
    bFillFarmList();
  }
  thisObj.onddOriginalPointChange = onddOriginalPointChange;

  const onddContractStatusChange = () => {
    enable(thisObj, "txtContract")
    let cboStat = getValue(thisObj, "ddContractStatus")
    if (cboStat == "4") {
      disable(thisObj, "txtContract")
    }
  }
  thisObj.onddContractStatusChange = onddContractStatusChange;

  const ontxtVendorChange = () => {//vendor textbox change
    let vendorNumber = txtVendor.value;
    if (vendorNumber.length == 6) {
      setVendorNameBasedOnVendorNumber()
    }
    else {
      setValue(thisObj, "txtVendr", '');
      hide(thisObj, "txtVendr", false);
    }
  };
  thisObj.ontxtVendorChange = ontxtVendorChange;

  const setVendorNameBasedOnVendorNumber = () => {
    try {
      let isVendorLookup = false;
      let vendor_lookup = getData(thisObj, 'vendorDetails');
      let vendorNumber, vendorName;
      if (vendor_lookup !== null) {
        vendorNumber = vendor_lookup.VendorNumber;
        vendorName = vendor_lookup.vendorName;
        setValue(thisObj, "txtVendr", vendorName);
        setValue(thisObj, "txtVendor", vendorNumber);
        isVendorLookup = true;
        setData(thisObj, 'vendorDetails', null);
        show(thisObj, 'txtVendr')
        return;
      }

      let vendorValueFromTextBox = getValue(thisObj, 'txtVendor');
      if (vendorValueFromTextBox !== undefined && isVendorLookup == false) {
        vendorNumber = vendorValueFromTextBox;
      }

      if (vendorNumber != "" && vendorNumber != undefined && isVendorLookup == false) {
        ContractManagementService.RetrieveVendorByNumber(vendorNumber).then(response => {
          let data = response;
          if (response !== undefined && response.length > 0) {
            vendorName = data[0].name;

            setValue(thisObj, "txtVendr", vendorName);
            setValue(thisObj, "txtVendor", vendorNumber);
            show(thisObj, "txtVendr")
          }
        })
      }
    }
    catch (error) {
      setLoading(false);
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during setVendorNameBasedOnVendorNumber: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  //--functions end----

  //Method for binding Wire xfer data dropdown
  const CheckchkboxWireTransferDatar = () => {//For WireTransfer Dropdown
    let chkboxWireTransferData = document.getElementById('chkboxWireXferData').checked;
    if (chkboxWireTransferData) {
      let js = []
      js.push({ key: 'BNF=/AC-4992OBI=CCC/1/XXXXXX/SCH#XXXX', description: 'BNF=/AC-4992OBI=CCC/1/XXXXXX/SCH#XXXX' })
      js.push({ key: 'XXXXX/CP NAME', description: 'XXXXX/CP NAME' })
      thisObj.setState(current => {
        return {
          ...current,
          ddWireXferData: {
            ...state["ddWireXferData"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddWireXferData', js.at(0).key)
      setValue(thisObj, 'txtWireXfer', js.at(0).key)
      enable(thisObj, 'txtWireXfer')
      enable(thisObj, 'ddWireXferData')
      // document.getElementById('ddWireXferData').disabled = false;
    } else {
      let js = []
      thisObj.setState(current => {
        return {
          ...current,
          ddWireXferData: {
            ...state["ddWireXferData"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddWireXferData', '')
      setValue(thisObj, 'txtWireXfer', '')
      disable(thisObj, 'txtWireXfer')
      disable(thisObj, 'ddWireXferData')
      // document.getElementById('ddWireXferData').disabled = true;
    }

  }

  const onchkboxWireXferDataChange = () => {
    CheckchkboxWireTransferDatar();
  };
  thisObj.onchkboxWireXferDataChange = onchkboxWireXferDataChange;

  const ontxtWireXferChange = () => {
    setValue(thisObj, 'ddWireXferData', getValue(thisObj, 'txtWireXfer'))
  }

  thisObj.ontxtWireXferChange = ontxtWireXferChange

  const onddWireXferDataChange = () => {
    setValue(thisObj, 'txtWireXfer', getValue(thisObj, 'ddWireXferData'))
  }
  thisObj.onddWireXferDataChange = onddWireXferDataChange

  const setEventHandlers = () => {//For Numeric Value only and for 2 textbox special chartacters restiction
    document.getElementById('txtReceiptNum').addEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtContract').addEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtGroupNum').addEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txt1007Num').addEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtOblWhseNum').addEventListener('keypress', restrictSpecialCharacter);
    document.getElementById('txtOblBinNum').addEventListener('keypress', restrictSpecialCharacter)
  };

  const removeEventHandlers = () => {//For Numeric Value only and for 2 textbox special chartacters restiction
    document.getElementById('txtReceiptNum').removeEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtContract').removeEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtGroupNum').removeEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txt1007Num').removeEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtOblWhseNum').removeEventListener('keypress', restrictSpecialCharacter)
    document.getElementById('txtOblBinNum').removeEventListener('keypress', restrictSpecialCharacter)
  };

  const allowOnlyNumbers = (e) => {//only allowing numbers
    try {
      if (!((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode === 8)) {
        e.preventDefault();
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during allowOnlyNumbers: ${error?.toString()}`
        );
      }
    }
  };

  const restrictSpecialCharacter = (e) => {//RestrictSpecial Characters
    try {
      if (!((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode === 8) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 97 && e.keyCode <= 122))) {
        e.preventDefault();
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during restrictSpecialCharacter: ${error?.toString()}`
        );
      }
    }
  };

  //--events start--
  function FormatNumber(value, fraction = 2) {//Formating number in specified format
    let formattedNo;
    if (value != null && value != undefined) {
      if (value.toString().indexOf(",") == -1) {
        formattedNo = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: fraction,
          maximumFractionDigits: fraction,
        }).format(value);
      } else {
        formattedNo = value.toString();
      }
    } else {
      formattedNo = "";
    }
    return formattedNo;
  }

  function FormatNumbers(value, fraction = 0) {//Formating number in specified format
    let formattedNo;
    if (value != null && value != undefined) {
      if (value.toString().indexOf(",") == -1) {
        formattedNo = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: fraction,
          maximumFractionDigits: fraction,
        }).format(value);
      } else {
        formattedNo = value.toString();
      }
    } else {
      formattedNo = "";
    }
    return formattedNo;
  }

  const onbtnSearchClick = async () => {//Search Button Functionality
    try {
      let LoanNumberA = getValue(thisObj, "txtLoanNum1")
      let LoanNumberB = getValue(thisObj, "txtLoanNum2")
      let LoanNumberC = getValue(thisObj, "txtLoanNum3")
      if (((LoanNumberA?.length != undefined && LoanNumberA?.length != null && LoanNumberA?.length != 0) || (LoanNumberB?.length != undefined && LoanNumberB?.length != null && LoanNumberB?.length != 0)) && (LoanNumberC?.length != undefined && LoanNumberC?.length != null && LoanNumberC?.length != 0)) {

        if (LoanNumberA?.length != undefined && LoanNumberA?.length != null && LoanNumberA?.length != 0 && LoanNumberA?.length != 2) {
          alert("The Length of the Loan State field must be 2 digits.")
          document.getElementById("txtLoanNum1").focus();
          return
        }
        if (LoanNumberB?.length != undefined && LoanNumberB?.length != null && LoanNumberB?.length != 0 && LoanNumberB?.length != 3) {
          alert("The Length of the Loan County field must be 3 digits.")
          document.getElementById("txtLoanNum2").focus();
          return
        }
        if (LoanNumberC?.length != undefined && LoanNumberC?.length != null && LoanNumberC?.length != 0 && LoanNumberC?.length != 5) {
          alert("The Length of the Loan Number field must be 5 digits.")
          document.getElementById("txtLoanNum3").focus();
          return
        }
      }

      DoSearch()

    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnSearchClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnSearchClick = onbtnSearchClick;

  function convert(str) {//for date conversion
    if(str !== undefined && str !== null && str !==''){//added this validation As part pf UAT Bug fix for Maturity date & redemption date.
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [mnth, day, date.getFullYear()].join("/");
    }else{
      str = ''
    }
  }

  function ConvertToPascalCase(word) {
    return word.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
  }


  const DoSearch = async () => {//search functionality
    try {
      setLoading(true);
      let LstrList, intCounter, intFieldPos, Flg_Forfeited, Flg_Fill_Grid, n, lCol, lstrxml, LstrXML2, sMinLockInDate, sMaxLockInDate, sLoanNumber, dblTotalNetWt, dblTotalShrunkWt, dblTotalValue, dblTotalNetRepayAmt
      let sMinEndDate, sMaxEndDate
      let sMinMaturityDate, sMaxMaturityDate, sMinRedemptionDate, sMaxRedemptionDate, Farm_suffix

      Flg_Fill_Grid = true
      disable(thisObj, "btnReady")
      disable(thisObj, "btnNotReady")
      hide(thisObj, "btnCancel", false)
      hide(thisObj, "btnResetToEWRAccepted", false)
      hide(thisObj, "btnClearRPS", false)
      setValue(thisObj, 'gridWarehouseReceiptList', [])//making grid value blank everytime 
      let cboLoanStatus = thisObj.state['ddLoanStatus'].valueList;
      let cboBuyingPoint = getValue(thisObj, "ddObligationPoint")
      let cboOrgBuyingPoint = getValue(thisObj, "ddOriginalPoint")
      let WRNumber = getValue(thisObj, "txtReceiptNum")
      let ContractNum = getValue(thisObj, "txtContract")
      let GroupNum = getValue(thisObj, "txtGroupNum")
      let text1007Num = getValue(thisObj, "txt1007Num")
      let VendorNumber = getValue(thisObj, "txtVendor")
      let WireTransferdata = getValue(thisObj, "ddWireXferData")?.trim()
      let LoanNumberA = getValue(thisObj, "txtLoanNum1")
      let LoanNumberB = getValue(thisObj, "txtLoanNum2")
      let LoanNumberC = getValue(thisObj, "txtLoanNum3")
      let EWRStatus = getValue(thisObj, "ddEWRStatus")
      let ddReceiptStatuses = getValue(thisObj, "ddReceiptStatus")
      let ddContractStatuses = getValue(thisObj, "ddContractStatus")
      let txtBinNumber = getValue(thisObj, "txtOblBinNum")

      let cboPeanutType = getValue(thisObj, "ddPeanutType")
      let cboSeg = getValue(thisObj, "ddSeg")
      let cboStat = getValue(thisObj, "ddContractStatus")
      let cboHolder = getValue(thisObj, "ddHolder")
      let cboState = getValue(thisObj, "ddState")
      let cboCounty = getValue(thisObj, "ddCounty")
      let farm_id
      let cboOleic = getValue(thisObj, "ddOleic")
      let cboCCC500 = getValue(thisObj, "ddCCC500")
      let cboGen = getValue(thisObj, "ddGeneration")
      let cboSeed = getValue(thisObj, "ddSeed")
      let cboOrganic = getValue(thisObj, "ddOrganic")
      let txtOblWhseNumber = getValue(thisObj, "txtOblWhseNum")

      let cboFSADocumentReady = getValue(thisObj, "ddFSADocReady")
      if (cboFSADocumentReady == "Blank") {
        cboFSADocumentReady = "NULL"
      }
      document.getElementById("txtReceiptNum").focus();
      if ((cboBuyingPoint == "" || cboBuyingPoint == undefined || cboBuyingPoint == null) && (cboOrgBuyingPoint == "" || cboOrgBuyingPoint == undefined || cboOrgBuyingPoint == null) && (WRNumber == "" || WRNumber == undefined || WRNumber == null)
        && (ContractNum == "" || ContractNum == undefined || ContractNum == null) && (GroupNum == "" || GroupNum == undefined || GroupNum == null) && (text1007Num == "" || text1007Num == undefined || text1007Num == null)
        && (VendorNumber == "" || VendorNumber == undefined || VendorNumber == null) && (WireTransferdata == "" || WireTransferdata == undefined || WireTransferdata == null) && (LoanNumberA == "" || LoanNumberA == undefined || LoanNumberA == null)
        && (LoanNumberB == "" || LoanNumberB == undefined || LoanNumberB == null) && (LoanNumberC == "" || LoanNumberC == undefined || LoanNumberC == null) &&
        ((EWRStatus == "" || EWRStatus == undefined || EWRStatus == null) || (EWRStatus == "A"))
      ) {
        alert("Must enter Obligation or Original location when not specifying Receipt, Contract, Vendor, Group Number, 1007 Number, Wire Transfer, Loan Number, or non-Accepted EWR Status.")
        return;
      }

      if ((cboBuyingPoint == "" || cboBuyingPoint == undefined || cboBuyingPoint == null) && (cboOrgBuyingPoint == "" || cboOrgBuyingPoint == undefined || cboOrgBuyingPoint == null) && (WRNumber == "" || WRNumber == undefined || WRNumber == null)
        && (ContractNum == "" || ContractNum == undefined || ContractNum == null) && (GroupNum == "" || GroupNum == undefined || GroupNum == null) && (text1007Num == "" || text1007Num == undefined || text1007Num == null)
        && (VendorNumber == "" || VendorNumber == undefined || VendorNumber == null) && (WireTransferdata == "" || WireTransferdata == undefined || WireTransferdata == null) && (LoanNumberA != "" || LoanNumberA != undefined || LoanNumberA != null)
        && (LoanNumberB == "" || LoanNumberB == undefined || LoanNumberB == null) && (LoanNumberC == "" || LoanNumberC == undefined || LoanNumberC == null) &&
        ((EWRStatus == "" || EWRStatus == undefined || EWRStatus == null) || (EWRStatus == "A"))
      ) {
        alert("Must specify more search criteria than just the State on the Loan Number.")
        return;
      }

      setValue(thisObj, "txtTotalNetWt", FormatNumbers(0))
      setValue(thisObj, "txtTotalShrunkNetWt", FormatNumbers(0))
      setValue(thisObj, "txtTotalValue", "$" + FormatNumber(0))
      setValue(thisObj, "txtTotalNetRepay", "$" + FormatNumber(0))

      dblTotalNetWt = 0
      dblTotalShrunkWt = 0
      dblTotalValue = 0
      dblTotalNetRepayAmt = 0
      n = 1
      LstrXML2 = "A"

      sMinLockInDate = ""
      sMaxLockInDate = ""
      sMinEndDate = ""
      sMaxEndDate = ""
      sMinMaturityDate = ""
      sMaxMaturityDate = ""
      sMinRedemptionDate = ""
      sMaxRedemptionDate = ""
      sLoanNumber = ""

      let sMinLockInDate1 = getValue(thisObj, "txtLockInDateFrom")
      if (sMinLockInDate1 != undefined) {
        if (sMinLockInDate1.length >= 8) {
          sMinLockInDate1 = sMinLockInDate1.split(' ')[0]
          sMinLockInDate = sMinLockInDate1;
        } else {
          sMinLockInDate = convert(getValue(thisObj, "txtLockInDateFrom")['_d'])
          if (sMinLockInDate == "aN/aN/NaN") {
            sMinLockInDate = ""
          }
        }
      }

      let sMaxLockInDate1 = getValue(thisObj, "txtLockInDateTo")
      if (sMaxLockInDate1 != undefined) {
        if (sMaxLockInDate1.length >= 8) {
          sMaxLockInDate1 = sMaxLockInDate1.split(' ')[0]
          sMaxLockInDate = sMaxLockInDate1;
        } else {
          sMaxLockInDate = convert(getValue(thisObj, "txtLockInDateTo")['_d'])
          if (sMaxLockInDate == "aN/aN/NaN") {
            sMaxLockInDate = ""
          }
        }
      }

      let sMinEndDate1 = getValue(thisObj, "txtEndDateFrom")
      if (sMinEndDate1 != undefined) {
        if (sMinEndDate1.length >= 8) {
          sMinEndDate1 = sMinEndDate1.split(' ')[0]
          sMinEndDate = sMinEndDate1;
        } else {
          sMinEndDate = convert(getValue(thisObj, "txtEndDateFrom")['_d'])
          if (sMaxLockInDate == "aN/aN/NaN") {
            sMinEndDate = ""
          }
        }
      }

      let sMaxEndDate1 = getValue(thisObj, "txtEndDateTo")
      if (sMaxEndDate1 != undefined) {
        if (sMaxEndDate1.length >= 8) {
          sMaxEndDate1 = sMaxEndDate1.split(' ')[0]
          sMaxEndDate = sMaxEndDate1;
        } else {
          sMaxEndDate = convert(getValue(thisObj, "txtEndDateTo")['_d'])
          if (sMaxLockInDate == "aN/aN/NaN") {
            sMaxEndDate = ""
          }
        }
      }

      let sMinMaturityDate1 = getValue(thisObj, "txtMaturityDateFrom")
      if (sMinMaturityDate1 != undefined) {
        if (sMinMaturityDate1.length >= 8) {
          sMinMaturityDate1 = sMinMaturityDate1.split(' ')[0]
          sMinMaturityDate = sMinMaturityDate1;
        } else {
          sMinMaturityDate = convert(getValue(thisObj, "txtMaturityDateFrom")['_d'])
          if (sMaxLockInDate == "aN/aN/NaN") {
            sMinMaturityDate = ""
          }
        }
      }

      let sMaxMaturityDate1 = getValue(thisObj, "txtMaturityDateTo")
      if (sMaxMaturityDate1 != undefined) {
        if (sMaxMaturityDate1.length >= 8) {
          sMaxMaturityDate1 = sMaxMaturityDate1.split(' ')[0]
          sMaxMaturityDate = sMaxMaturityDate1;
        } else {
          sMaxMaturityDate = convert(getValue(thisObj, "txtMaturityDateTo")['_d'])
          if (sMaxLockInDate == "aN/aN/NaN") {
            sMaxMaturityDate = ""
          }
        }
      }

      let sMinRedemptionDate1 = getValue(thisObj, "txtRedemptionDateFrom")
      if (sMinRedemptionDate1 != undefined) {
        if (sMinRedemptionDate1.length >= 8) {
          sMinRedemptionDate1 = sMinRedemptionDate1.split(' ')[0]
          sMinRedemptionDate = sMinRedemptionDate1;
        } else {
          sMinRedemptionDate = convert(getValue(thisObj, "txtRedemptionDateFrom")['_d'])
          if (sMaxLockInDate == "aN/aN/NaN") {
            sMinRedemptionDate = ""
          }
        }
      }

      let sMaxRedemptionDate1 = getValue(thisObj, "txtRedemptionDateTo")
      if (sMaxRedemptionDate1 != undefined) {
        if (sMaxRedemptionDate1.length >= 8) {
          sMaxRedemptionDate1 = sMaxRedemptionDate1.split(' ')[0]
          sMaxRedemptionDate = sMaxRedemptionDate1;
        } else {
          sMaxRedemptionDate = convert(getValue(thisObj, "txtRedemptionDateTo")['_d'])
          if (sMaxLockInDate == "aN/aN/NaN") {
            sMaxRedemptionDate = ""
          }
        }
      }

      let loanemptyB = ""
      let loanemptyA = ""
      let loanemptyC = ""
      if ((LoanNumberA?.length != 0 && LoanNumberA != undefined) || (LoanNumberB?.length != 0 && LoanNumberB != undefined) || (LoanNumberC?.length != 0 && LoanNumberC != undefined)) {
        if (LoanNumberA == undefined || LoanNumberA == null || LoanNumberA == "") {
          loanemptyA = "__"
        }
        else {
          loanemptyA = LoanNumberA
        }
        if (LoanNumberB == undefined || LoanNumberB == null || LoanNumberB == "") {
          loanemptyB = "___"
        }
        else {
          loanemptyB = LoanNumberB
        }
        if (LoanNumberC == undefined || LoanNumberC == null || LoanNumberC == "") {
          loanemptyC = "_____"
        } else {
          loanemptyC = LoanNumberC
        }
        sLoanNumber = loanemptyA + loanemptyB + loanemptyC
      }
      let farm = getValue(thisObj, 'ddFarm');
      if (farm.length > 0) {
        let arr = farm.split('$');
        let values = arr[1].split('-');
        farm_id = values[0];
        Farm_suffix = values[1];
      }
      else {
        farm_id = "";
        Farm_suffix = "";
      }

      let lblTotalWireNetRepayAmt = 0
      // Added additional parameter in api for limit for solution on APPGATEWAY Issue.
        let promise = []
        let limitreached = false
        for (let i = 50; limitreached == false; i += 50) {
           let lstrt = await WarehouseReceiptService.RetrieveWareHouseReceiptList(ddReceiptStatuses, ddContractStatuses, sLoanNumber,
            cboBuyingPoint, WRNumber, GroupNum, text1007Num, ContractNum,
            cboOrgBuyingPoint, VendorNumber, txtOblWhseNumber, txtBinNumber,
            cboPeanutType, cboSeg, sMinEndDate, sMaxEndDate, sMinLockInDate, sMaxLockInDate, EWRStatus,
            sMinRedemptionDate, sMaxRedemptionDate, cboHolder, cboCCC500, cboFSADocumentReady,
            sMinMaturityDate, sMaxMaturityDate, WireTransferdata, cboState,
            cboCounty, farm_id, Farm_suffix, cboOleic, cboSeed, cboOrganic, cboGen,i)
            if(lstrt.length != undefined && lstrt.length != 0){
            if(lstrt.status !=undefined && lstrt?.status != 200){
              limitreached = true;
            }else{
              promise = promise.concat(lstrt)
            }
          }else{
            break;
          }
        }
      LstrList = promise
      let gridData = [];
      let GdtCurrentDate = ((new Date()).getMonth() + 1) + '/' + (new Date()).getDate() + '/' + (new Date()).getFullYear()
      let cboStatusofLoan = getValue(thisObj, "ddLoanStatus")
      if (LstrList?.length > 0) {
        for (let i = 0; i < LstrList?.length; i++) {

          let arraycontract = []
          let contData = [];
          let obj = {};

          Flg_Fill_Grid = true

          if (cboStatusofLoan != undefined && cboStatusofLoan != null && cboStatusofLoan != "") {
            if (LstrList[i]?.adj_loan_maturity_date == "" || LstrList[i]?.adj_loan_maturity_date == null) {
              Flg_Forfeited = false
            }
            else {
              if (LstrList[i]?.chrg_end_date == "") {
                if (new Date(GdtCurrentDate) > new Date(LstrList[i].adj_loan_maturity_date)) {
                  Flg_Forfeited = true
                }
                else {
                  Flg_Forfeited = false
                }
              }
              else {
                if (new Date(LstrList[i].chrg_end_date) > new Date(LstrList[i].adj_loan_maturity_date)) {
                  Flg_Forfeited = true
                }
                else {
                  Flg_Forfeited = false
                }
              }
            }

            if (cboStatusofLoan != undefined && cboStatusofLoan != null && cboStatusofLoan != "") {

              switch (cboStatusofLoan?.trim()) {
                case "Not Forfeited":
                  // Flg_Fill_Grid = true
                  if(Flg_Forfeited){
                    Flg_Fill_Grid = false;
                  }
                  break;
                case "Forfeited":
                  // Flg_Fill_Grid = false
                  if(!Flg_Forfeited){
                    Flg_Fill_Grid = false;
                  }
                  break;
                default:
                  Flg_Fill_Grid = true;
              }
            }
          }

          n = n + LstrList?.length

          if (Flg_Fill_Grid) {
            if ((LstrList[i]?.rcpt_status).toUpperCase() != "V") {
              obj.checkboxallFsa = true
              obj.chkbxFSA = false //checkbox show not show scenario
            }
            obj.txtcolReady = (LstrList[i]?.fsA_DOC_READY_IND)?.toUpperCase();
            obj.txtcolWRNum = (LstrList[i].whse_rcpt_num)?.toString()?.padStart(6, "0");
            obj.txtcolGroupNum = LstrList[i].group_num;

            obj.txtcolDeferred = LstrList[i].deferred_redemption_ind;

            obj.txtcolVendor = LstrList[i].rcpt_vendor + " - " + ConvertToPascalCase(LstrList[i].vendor_name);
            obj.vendorNumber = LstrList[i].rcpt_vendor;

            obj.txtcolFarmNo = LstrList[i].farm_id + " - " + LstrList[i].farm_suffix;

            obj.txtcol211GPC = LstrList[i].form211_gpc;
            obj.txtcol211Concordia = LstrList[i].form211_con;

            obj.txtcolOrgBP = LstrList[i].original_buy_pt;
            obj.txtcolOblPt = LstrList[i].buy_pt_id;
            obj.txtcolOblWhs = LstrList[i].whouse_num;
            obj.txtcolOblBin = LstrList[i].bin_num;
            obj.txtcolType = LstrList[i].pnut_type_id;
            obj.txtcolVariety = LstrList[i].pnut_variety_id;
            obj.txtcolGen = LstrList[i].seed_gen;
            obj.txtcolSeg = LstrList[i].seg_type;
            obj.txtcolNetWt = FormatNumbers(LstrList[i].net_wt);
            obj.txtcolShrunkNetWt = FormatNumbers(LstrList[i].shrink_net_wt);
            obj.txtcolValue = FormatNumber(LstrList[i].rcpt_value);

            if ((LstrList[i].contract_num)?.length > 14) {
              obj.txtcolContNum = "Multi          "
            }
            else if (LstrList[i].contract_num == "") {
              obj.txtcolContNum = "UnContracted  "
            }
            else {
              obj.txtcolContNum = LstrList[i].contract_num
            }

            switch (LstrList[i].rcpt_status?.trim()) {
              case "I":
                obj.txtcolStatus = "Issued"
                break;
              case "P":
                obj.txtcolStatus = "Purchased"
                break;
              case "D":
                obj.txtcolStatus = "Delivered Out"
                break;
              case "L":
                obj.txtcolStatus = "Delivery In Process"
                break;
              case "V":
                obj.txtcolStatus = "Voided"
                break;
              case "X":
                obj.txtcolStatus = "Pending Void"
                break;
              case "F":
                obj.txtcolStatus = "Pending Forfeiture Purchase"
                break;
            }

            let chrg_end_date, loan_matur_date

            if (LstrList[i].chrg_end_date != "" && LstrList[i].chrg_end_date != null) {
              chrg_end_date = convert(LstrList[i].chrg_end_date)
            }
            else {
              chrg_end_date = ""
            }

            if (LstrList[i].adj_loan_maturity_date != "" && LstrList[i].adj_loan_maturity_date != null) {
              loan_matur_date = convert(LstrList[i].adj_loan_maturity_date)
            }
            else {
              loan_matur_date = ""
            }


            if (loan_matur_date == "") {
              obj.txtcolLoanStatus = "NF"
            }
            else {
              if (chrg_end_date == "") {
                if (new Date(GdtCurrentDate) > new Date(loan_matur_date)) {
                  obj.txtcolLoanStatus = "F"
                } else {
                  obj.txtcolLoanStatus = "NF"
                }
              }
              else {
                if (new Date(chrg_end_date) > new Date(loan_matur_date)) {
                  obj.txtcolLoanStatus = "F"
                } else {
                  obj.txtcolLoanStatus = "NF"
                }
              }
            }
            switch (LstrList[i].transmission_status) {
              case "A":
                obj.txtcolEWRXferStatus = "Accepted"
                break;
              case "R":
                obj.txtcolEWRXferStatus = "Rejected"
                break;
              case "D":
                obj.txtcolEWRXferStatus = "Delivered Out"
                break;
              default:
                obj.txtcolEWRXferStatus = "Not Ack'd"
                break;
            }

            // '**** Start *****/
            obj.txtcolHolder = LstrList[i].current_holder;
            obj.txtcolBuyingPoint = LstrList[i].buy_pt_id;
            obj.txtcolEWRRcptType = LstrList[i].ewr_receipt_type;
            obj.txtcolLastAction = LstrList[i].last_action_code;
            obj.txtcolLastActionReceived = LstrList[i].last_action_received;
            obj.txtcolERcptNum = LstrList[i].e_whse_rcpt_num;
            // '**** End ****

            obj.txtcolWireXferData = LstrList[i].wire_xfer_data;
            obj.txtcolDelvAgreeNum = LstrList[i].agreement_num;
            obj.txtcolDelvInd = LstrList[i].delv_ind;

            obj.txtcolNetRepay = FormatNumber(Number(LstrList[i].loan_repay_amt) - Number(LstrList[i].store_repay_amt) + Number(LstrList[i].interest_amt) + Number(LstrList[i].handling_repay_amt));

            if ((LstrList[i].rcpt_status)?.toUpperCase() != "V") {
              if (!isNaN(LstrList[i].net_wt)) {
                dblTotalNetWt = Number(dblTotalNetWt) + Number(LstrList[i].net_wt)
              }
              else {
                dblTotalNetWt = Number(dblTotalNetWt) + Number(0)
              }
              if (!isNaN(LstrList[i].shrink_net_wt)) {
                dblTotalShrunkWt = Number(dblTotalShrunkWt) + Number(LstrList[i].shrink_net_wt)
              }
              else {
                dblTotalShrunkWt = Number(dblTotalShrunkWt) + Number(0)
              }
              if (!isNaN(LstrList[i].rcpt_value)) {
                dblTotalValue = Number(dblTotalValue) + Number(LstrList[i].rcpt_value)
              }
              else {
                dblTotalValue = Number(dblTotalValue) + Number(0)
              }
              if (!isNaN(LstrList[i].loan_repay_amt)) {
                (dblTotalNetRepayAmt) += (Number(LstrList[i].loan_repay_amt) - Number(LstrList[i].store_repay_amt) + Number(LstrList[i].interest_amt) + Number(LstrList[i].handling_repay_amt));
              }
              else {
                dblTotalNetRepayAmt = Number(dblTotalNetRepayAmt) + Number(0)
              }
            }

            if ((LstrList[i].wire_xfer_data != "") && (LstrList[i].wire_xfer_data != null)) {
              (lblTotalWireNetRepayAmt) += ((Number(LstrList[i].loan_repay_amt) - Number(LstrList[i].store_repay_amt) + Number(LstrList[i].interest_amt) + Number(LstrList[i].handling_repay_amt)))
            }

            if (LstrList[i].chrg_end_date != "" && LstrList[i].chrg_end_date != null) {
              obj.txtcolEndDate = convert(LstrList[i]?.chrg_end_date);
            }
            else {
              obj.txtcolEndDate = "";
            }

            let objCont = {}
            let grpNum = LstrList[i]?.group_num
            if (LstrList[i]?.group_num != undefined && LstrList[i]?.group_num != "" && LstrList[i]?.group_num != null) {
              let plitedgrp = (LstrList[i].group_num)?.split(", ")
              if (plitedgrp?.length > 1) {
                objCont.grp = "Group#:" + LstrList[i].group_num
              }
            }
            objCont.OrgBpv = "Org. BP:" + LstrList[i].org_buy_pt_name
            objCont.OblPtbl = "Obl. PT:" + LstrList[i].obl_buy_pt_name
            objCont.PnuttypeVar = "Type:" + LstrList[i].pnut_type_name
            switch ((LstrList[i].symbol_ind.trim()).toUpperCase()) {
              case "TRADEMARK":
                objCont.PnuttypeVarseg = "Variety:" + LstrList[i].pnut_variety_name + " \u2122";
                break;
              case "REGISTERED TRADEMARK":
                objCont.PnuttypeVarseg = "Variety:" + LstrList[i].pnut_variety_name + " \xAE";
                break;
              case "COPYRIGHT":
                objCont.PnuttypeVarseg = "Variety:" + LstrList[i].pnut_variety_name + " \xA9";
                break;
              default:
                objCont.PnuttypeVarseg = "Variety:" + LstrList[i].pnut_variety_name

            }

            if ((LstrList[i].contract_num)?.length > 14) {

              const inputString = (LstrList[i].contract_num)//this login need to check with different combination.
              // const num = inputString.match(/\d+/g);// for getting numeric contract Numbers
              const num = inputString?.split(", ");//O-B Like that formating.s
              if (num) {
                intFieldPos = 17
                let objContract = {}
                for (let j = 0; j < num?.length; j++) {
                  if (j == 0) {
                    objCont.Contractnum = num[j]
                    contData.push(objCont)
                  }
                  else {
                    objContract.grp = ""
                    objContract.OrgBpv = ""
                    objContract.OblPtbl = ""
                    objContract.PnuttypeVar = ""
                    objContract.PnuttypeVarseg = ""
                    objContract.Contractnum = num[j]
                    contData.push(objContract)
                    objContract = {}
                  }
                }
              }
            }
            else {
              contData.push(objCont)
            }
            obj.childrow = (contData)
            gridData.push(obj);
          }
        }
      }
      else {
        setValue(thisObj, 'gridWarehouseReceiptList', gridData)
        return;
      }

      setValue(thisObj, 'gridWarehouseReceiptList', gridData);
      setChkCount(0)//to set count as 0 when we refreshed the grid
      setchkenable(true)// to enable every check box in grid when we refresh data
      setData(thisObj, "gridWarehouseReceiptListData", gridData)
      await SetGridColWidthsForDisplay()//hidecolumn sfunction

      setValue(thisObj, "txtTotalNetWt", FormatNumbers(dblTotalNetWt))
      setValue(thisObj, "txtTotalShrunkNetWt", FormatNumbers(dblTotalShrunkWt))
      setValue(thisObj, "txtTotalValue", "$" + FormatNumber(dblTotalValue))
      setValue(thisObj, "txtTotalNetRepay", "$" + FormatNumber(dblTotalNetRepayAmt))
      setData(thisObj, "toataldollarremo", dblTotalValue)
      setData(thisObj, "repayForPrintWire", lblTotalWireNetRepayAmt)


      if ((cboFSADocumentReady != undefined && cboFSADocumentReady != null && cboFSADocumentReady != "") && (LstrList?.length > 1)) {
        lstrxml = await ContractManagementService.RetrieveAccessPermissionDetails('PN1090', null, 'U')
        if (lstrxml[0].permission == 'Y') {
          show(thisObj, "chkboxAllFSA")
          setValue(thisObj, "chkboxAllFSA", false)
        } else {
          hide(thisObj, "chkboxAllFSA", false)
        }
      }
      else {
        hide(thisObj, "chkboxAllFSA", false)
      }
      document.getElementById("txtLoanNum3").focus();
      document.getElementById("txtLoanNum3").blur()
      setLoading(false);
    }
    catch (error) {
      setLoading(false);
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during DoSearch: ${error?.toString()}`
        );
      }
      return false;
    }
    finally {
      setLoading(false);
    }
  }

  //vsfData_AfterSelChange functionality
  const ongridWarehouseReceiptListRowSelect = (rowSelected) => {
    try {
      let data1 = rowSelected.selectedRows
      if (!data1) {
        return;
      }
      else {
        hide(thisObj, "btnCancel", false)
        if (!mbCancelUncancelPermissionDeny) {
          let gridData1 = getData(thisObj, 'gridWarehouseReceiptListData');
          if ((((data1[0]?.txtcolStatus)?.toUpperCase() == "PURCHASED") || ((data1[0]?.txtcolStatus)?.toUpperCase() == "DELIVERED OUT") || ((data1[0]?.txtcolStatus)?.toUpperCase() == "DELIVERY IN PROCESS") || ((data1[0]?.txtcolStatus)?.toUpperCase() == "VOIDED")) && ((data1[0]?.txtcolEWRXferStatus)?.toUpperCase() == "ACCEPTED")) {
            if ((data1[0]?.txtcolLastAction)?.toUpperCase() == "CAN") {
              document.getElementById("btnCancel").innerText = "Uncancel";
            } else {
              document.getElementById("btnCancel").innerText = "Cancel";
            }
            show(thisObj, "btnCancel")
          }
          else if (((data1[0]?.txtcolStatus)?.toUpperCase() == "ISSUED") && ((data1[0]?.txtcolEWRXferStatus)?.toUpperCase() == "ACCEPTED") && ((data1[0]?.txtcolLastAction)?.toUpperCase() == "CAN")) {
            document.getElementById("btnCancel").innerText = "Uncancel";
            show(thisObj, "btnCancel")
          }
        }

        hide(thisObj, "btnResetToEWRAccepted", false)
        if ((mbResetEWRStatus) && (data1[0]?.txtcolEWRXferStatus)?.toUpperCase() != "ACCEPTED") {
          show(thisObj, "btnResetToEWRAccepted")
        }
        hide(thisObj, "btnClearRPS", false)
        if ((mbClearRPS) && (data1[0]?.txtcolLastActionReceived)?.toUpperCase() == "RPS") {
          show(thisObj, "btnClearRPS")
        }
      }
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during ongridWarehouseReceiptListRowSelect: ${error?.toString()}`
        );
      }
      return false;
    }
  };
  thisObj.ongridWarehouseReceiptListRowSelect = ongridWarehouseReceiptListRowSelect;

  const ongridWarehouseReceiptListPagerORsort = (e) => {//pagination change event
    if (e.target.page) {
      if (chkCount == 0) {//when pagination changes its makes enable disable according to Count.
        setchkenable(true)
      } else {
        setchkdisable(true)
      }
    }
    if (e.target.size) {
      if (chkCount == 0) {//when pagination changes its makes enable disable according to Count.
        setchkenable(true)
      } else {
        setchkdisable(true)
      }
    }
  }
  thisObj.ongridWarehouseReceiptListPagerORsort = ongridWarehouseReceiptListPagerORsort;

  //vsfData_Click check box change events
  const onchkbxFSAChange = async (event) => {
    try {
      setLoading(true)
      let gridData2 = getValue(thisObj, 'gridWarehouseReceiptList');
      if (isVisible(thisObj, 'chkboxAllFSA') != true) {
        disableEnableChkbox(event, gridData2);// Main function for enable disable state will upadte accordingly in this Function for enable disable checkbox.
      }
      disable(thisObj, 'btnReady');
      disable(thisObj, 'btnNotReady');
      if (gridData2.length > 0) {
        let response = await ContractManagementService.RetrieveAccessPermissionDetails('PN1090', null, 'U')
        for (let i = 0; i < gridData2?.length; i++) {
          if (gridData2[i]?.chkbxFSA != undefined) {
            if (response[0].permission == 'Y' && ((gridData2?.txtcolStatus)?.toUpperCase() != "VOIDED") && ((gridData2?.txtcolStatus)?.toUpperCase() != "")) {
              if (gridData2[i]?.chkbxFSA == true) {
                if ((gridData2[i]?.txtcolReady) == "Y") {
                  if (!isEnabled(thisObj, 'btnReady')) {
                    enable(thisObj, 'btnNotReady');
                    disable(thisObj, 'btnReady');
                    break;
                  }
                }
                else {
                  if (!isEnabled(thisObj, 'btnNotReady')) {
                    enable(thisObj, 'btnReady');
                    disable(thisObj, 'btnNotReady');
                    break;
                  }
                }
              }
            }
          }
        }
      }
      if (loading) {
        setLoading(false)
      }
    }
    catch (error) {
      setLoading(false)
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onchkbxFSAChange: ${error?.toString()}`
        );
      }
      return false;
    } finally {
      setLoading(false)
    }
  }
  thisObj.onchkbxFSAChange = onchkbxFSAChange;

  const onchkboxAllFSAChange = async () => {//for all FSA CheckBox
    try {
      let gridVal = getValue(thisObj, 'gridWarehouseReceiptList')
      if (getValue(thisObj, 'chkboxAllFSA') == true) {
        for (let j = 0; j < gridVal?.length; j++) {
          if (gridVal[j]?.checkboxallFsa == true) {
            gridVal[j].chkbxFSA = true
          }
        }
      }
      else {
        for (let j = 0; j < gridVal?.length; j++) {
          if (gridVal[j]?.checkboxallFsa == true) {
            gridVal[j].chkbxFSA = false
          }
        }
      }
      setValue(thisObj, "gridWarehouseReceiptList", gridVal)
      await onchkbxFSAChange()
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onchkboxAllFSAChange: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onchkboxAllFSAChange = onchkboxAllFSAChange;

  const disableEnableChkbox = (event, gridData2) => {//functionality for disabling checkbox in new application
    try {
      if (chkCount == 0) {
        let nameC = event.target.name;
        let readyValId = nameC.substring(25, nameC.length - 9);//It will Give row Id. 
        setcurrentVal(gridData2[readyValId].txtcolReady)//  it Will store "Y"/"N" we are updating the state.
        if (gridData2.length > 0) {
          setchkdisable(true);
        }
      }
      if (event.target.value == true) {// it will monitor the count if we checked check box increase the count
        setChkCount(chkCount + 1);
      }
      if (event.target.value == false) {// it will monitor the count if we checked check box decrease the count
        setChkCount(chkCount - 1);
        if (chkCount == 1) {//optional not need if condition setenable state needed.
          setchkenable(true);
        }
      }
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during disableEnableChkbox: ${error?.toString()}`
        );
      }
      return false;
    }
  }

  const enablecheckbx = (gridData2) => {//functionality for enabling checkbox in new application
    for (let i = 0; i < gridData2.length; i++) {
      if (document.querySelector(".gridWarehouseReceiptList").querySelector(`#row-${i}`) != null && document.querySelector(".gridWarehouseReceiptList").querySelector(`#row-${i}`) != undefined) {
        let chkElement = document.querySelector(".gridWarehouseReceiptList").querySelector(`#row-${i}`).querySelector(".form-check-input")
        if (chkElement) {
          chkElement.disabled = false;
        }
      }
    }
  }

  const disablecheckbx = (gridData2) => {//functionality for disabling checkbox in new application
    try {
      for (let i = 0; i < gridData2.length; i++) {
        if (currentVal == "N" || currentVal == "") {
          if ((gridData2[i]?.txtcolReady) == "Y") {
            if (document.querySelector(".gridWarehouseReceiptList").querySelector(`#row-${i}`) != null && document.querySelector(".gridWarehouseReceiptList").querySelector(`#row-${i}`) != undefined) {
              let chkElement = document.querySelector(".gridWarehouseReceiptList")?.querySelector(`#row-${i}`).querySelector(".form-check-input")
              if (chkElement) {
                chkElement.disabled = true;
              }
            }
          }
        }
        if (currentVal == "Y") {
          if ((gridData2[i]?.txtcolReady) == "N" || (gridData2[i]?.txtcolReady) == "") {
            if (document.querySelector(".gridWarehouseReceiptList").querySelector(`#row-${i}`) != null && document.querySelector(".gridWarehouseReceiptList").querySelector(`#row-${i}`) != undefined) {
              let chkElement = document.querySelector(".gridWarehouseReceiptList").querySelector(`#row-${i}`).querySelector(".form-check-input")
              if (chkElement) {
                chkElement.disabled = true;
              }
            }
          }
        }
      }
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during disablecheckbx: ${error?.toString()}`
        );
      }
      return false;
    }
  }

  const SetGridColWidthsForDisplay = async () => {//hidding column from grid
    try {
      hideColumn(thisObj, "gridWarehouseReceiptList", 'txtcolBuyingPoint')
      hideColumn(thisObj, "gridWarehouseReceiptList", 'txtcolEWRRcptType')
      hideColumn(thisObj, "gridWarehouseReceiptList", 'txtcolLastAction')
      hideColumn(thisObj, "gridWarehouseReceiptList", 'txtcolLastActionReceived')
      hideColumn(thisObj, "gridWarehouseReceiptList", 'txtcolERcptNum')
      hideColumn(thisObj, "gridWarehouseReceiptList", 'txtcolDelvInd')

    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during SetGridColWidthsForDisplay: ${error?.toString()}`
        );
      }
      return false;
    }
  }

  const onbtnClearRPSClick = async () => {//Clear Button Functionality
    try {
      let dataselectedrow =  thisObj.state.gridWarehouseReceiptList.selected[0];

      let COL_WR_NO = dataselectedrow?.txtcolWRNum
      let COL_OBL_BP =  dataselectedrow?.txtcolOblPt
      if (!dataselectedrow) {
        showMessage(thisObj,"Please Select Row to clear the RPS status sent from EWR ",false)
        return;
      }
      else {
        const response = confirm(`Are you sure you wish to clear the RPS status sent from EWR on WR# '${COL_WR_NO}' ?`);
        if (response) {
          let dataObj = {
            user_id: userid
          }
          let response = await WarehouseReceiptService.UpdateWareHouseReceiptClearRpsFromEwr(COL_OBL_BP,COL_WR_NO, dataObj)
          if (response.status != 200) {
            return;
          }
          await DoSearch()
        }
        else{
          return;
        }
      }
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnClearRPSClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnClearRPSClick = onbtnClearRPSClick;

  const onbtnViewEWRLogClick = async () => {//onbtnViewEWRLogClick event
    try {
      let dataselectedrow = thisObj.state.gridWarehouseReceiptList.selected[0];
      let COL_WR_NO = dataselectedrow?.txtcolWRNum
      let COL_OBL_BP = dataselectedrow?.txtcolOblPt
      if (!dataselectedrow) {
        goTo(thisObj, "WarehouseReceipts#ViewEWRLog#DEFAULT#true#Click")
      }
      else {
        const viewEWRLogData = {
          selectedBuyingPoint: COL_OBL_BP, // Buying Point
          txtReceiptNumber: COL_WR_NO // Receipt Number
        };
        setData(thisObj, "viewEWRLogData", viewEWRLogData);
        goTo(thisObj, "WarehouseReceipts#ViewEWRLog#DEFAULT#true#Click")
      }
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnViewEWRLogClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnViewEWRLogClick = onbtnViewEWRLogClick;

  const onbtnResetToEWRAcceptedClick = async () => {//onbtnResetToEWRAcceptedClick event
    try {
      setLoading(true)
      let dataselectedrow = thisObj.state.gridWarehouseReceiptList?.selected[0];
      let COL_WR_NO = dataselectedrow?.txtcolWRNum
      let COL_OBL_BP = dataselectedrow?.txtcolOblPt
      if (!dataselectedrow) {
        showMessage(thisObj,"Please Select Row to ResetToEWR Accepted",false)
        return;
      }
      else {
        if(((dataselectedrow?.txtcolEWRXferStatus))?.toUpperCase() == "REJECTED"){
          let dataObj = {
            "new_status": "A",
            "old_status": "R"
            }
            let s = await WarehouseReceiptService.UpdateEwrTransmissionStatus(COL_OBL_BP, COL_WR_NO, dataObj)
            if (s.status == 200) {
              await DoSearch()
            }
        }
        else{
          // If UCase(GstrVersion) = "TEST" Or UCase(GstrVersion) = "DEVELOPMENT" Then need to check now to get env.
          if((process.env.REACT_APP_ENVIR == 'LOCAL' || process.env.REACT_APP_ENVIR == 'TEST' || process.env.REACT_APP_ENVIR == "DEV"  || process.env.REACT_APP_ENVIR == 'UAT')){//Use the Environment but global variable instead from local and added UAT Env as it added new Env.
            if(((dataselectedrow?.txtcolEWRXferStatus))?.toUpperCase() == "NOT ACK'D"){
              let dataObj1 = {
                "new_status": "A",
                "old_status": "X"
                }
                let s1 = await WarehouseReceiptService.UpdateEwrTransmissionStatus(COL_OBL_BP, COL_WR_NO, dataObj1)
                if (s1.status == 200) {
                  await DoSearch()
                }
            }
            else if(((dataselectedrow?.txtcolStatus)?.toUpperCase()) == "PENDING VOID"){
              let dataObj2 = {
                "user_id": userid
                }
                let s2 = await WarehouseReceiptService.UpdateVoidWareHouseReceipt(COL_OBL_BP, COL_WR_NO, dataObj2)
                if (s2.status == 200) {
                  await DoSearch()
                }
            }
          }
          else{
            alert("The selected receipt MUST be in rejected status to perform this update.")
          }
        }
      }
      if(loading){
        setLoading(false)
      }
    }
    catch (error) {
      setLoading(false)
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnResetToEWRAcceptedClick: ${error?.toString()}`
        );
      }
      return false;
    }finally{
      setLoading(false)
    }
  }
  thisObj.onbtnResetToEWRAcceptedClick = onbtnResetToEWRAcceptedClick;

  const onbtnCancelClick = async () => {//Cancel Button Functionality
    try {
      let cancelbtn = document.getElementById("btnCancel")?.innerText

      let dataselectedrow = thisObj.state.gridWarehouseReceiptList?.selected[0];
      let COL_WR_NO = dataselectedrow?.txtcolWRNum
      let COL_OBL_BP = dataselectedrow?.txtcolOblPt

      if (!dataselectedrow) {
        showMessage(thisObj, "Please Select Row to " + cancelbtn + " record to EWR, for receipt ", false)
        return;
      }
      else {
        let js = {
          cancelbtn : cancelbtn,
          COL_WR_NO : COL_WR_NO,
          COL_OBL_BP : COL_OBL_BP
        }
        setData(thisObj,"dataSendingPopup",js)
        goTo(thisObj, "WarehouseReceipts#WarehouseReceiptInquiryPopUp#DEFAULT#true#Click")
      }
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnCancelClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnCancelClick = onbtnCancelClick;

  const onbtnExtRcptForfeitureClick = async () => {// onbtnExtRcptForfeitureClick functionality
    try {
      const buyingPointValueList = getData(thisObj, "ddObligationPointBuyingPoint")
      setData(thisObj, 'cboBuyingPoint', buyingPointValueList);
      goTo(thisObj, "WarehouseReceipts#ExternalReceiptForfeituresSearch#DEFAULT#true#Click")
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnExtRcptForfeitureClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnExtRcptForfeitureClick = onbtnExtRcptForfeitureClick;

  const onbtnPrintAllReadyClick = async () => {//onbtnPrintAllReadyClick functionality
    try {
      let dataselectedrow = getValue(thisObj, "gridWarehouseReceiptList")
      let frmReportPreviewWR = {}
      let bPrintPreview, bPrintDialog, bYesFlagsExist, bFirstPage, bLastPage
      bYesFlagsExist = false;

      let msPrintDevice = ""
      bPrintPreview = true
      bPrintDialog = false
      bFirstPage = true
      bLastPage = false

      let js = []
      for (let i = 0; i < dataselectedrow?.length; i++) {
        let obj = {}
        if (dataselectedrow[i]?.txtcolReady == "Y") {
          bYesFlagsExist = true;
          bLastPage = true;
          obj.buyingPt = dataselectedrow[i]?.txtcolOblPt;
          obj.wrRcptNum = dataselectedrow[i]?.txtcolWRNum
          obj.COL_ORG_BP = dataselectedrow[i]?.txtcolOrgBP
          obj.docName = "rptWarehouseReceipt_FSADocument"
          js.push(obj)
        }
      }

      if (bYesFlagsExist == false) {
        alert("There are no receipt FSA documents flagged as ready?")
        return;
      }
      frmReportPreviewWR.msPrintDevice = msPrintDevice
      frmReportPreviewWR.bPrintPreview = bPrintPreview
      frmReportPreviewWR.bPrintDialog = bPrintDialog
      frmReportPreviewWR.bFirstPage = bFirstPage
      frmReportPreviewWR.bLastPage = bLastPage

      setData(thisObj, "ReportPreviewWRData", js)//sending data to reportPreview WR
      setData(thisObj, "ReportPreviewWRPrintDetails", frmReportPreviewWR)//sending data to reportPreview WR

      for (let j = 0; j < dataselectedrow?.length; j++) {
        let COL_OBL_ByP = dataselectedrow[j]?.txtcolOblPt
        let COL_WR_No = dataselectedrow[j]?.txtcolWRNum
        if (dataselectedrow[j]?.txtcolReady == "Y") {

          dataselectedrow[j].txtcolReady = "N"
          dataselectedrow[j].chkbxFSA = false
        }
      }
      disable(thisObj, 'btnReady');
      disable(thisObj, 'btnNotReady');
      setValue(thisObj, "gridWarehouseReceiptList", dataselectedrow)
      goTo(thisObj, "WarehouseReceipts#ReportPreviewWR#DEFAULT#true#Click")
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnPrintAllReadyClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnPrintAllReadyClick = onbtnPrintAllReadyClick;

  const onbtnReadyClick = async () => {//Ready Button Functionality
    try {
      setLoading(true);
      let dataselectedrowforReady = getValue(thisObj, "gridWarehouseReceiptList")
      let s
      for (let i = 0; i < dataselectedrowforReady?.length; i++) {
        if (dataselectedrowforReady[i]?.chkbxFSA == true) {
          let COL_OBL_BP = dataselectedrowforReady[i]?.txtcolOblPt
          let COL_WR_NO = dataselectedrowforReady[i]?.txtcolWRNum

          let dataObj = {
            "fsa_status": "Y"
          }
          s = await WarehouseReceiptService.UpdateWareHouseReceiptSetFsaDocument(COL_OBL_BP, COL_WR_NO, dataObj)
          if (s.status == 200) {
            dataselectedrowforReady[i].txtcolReady = "Y"
            dataselectedrowforReady[i].chkbxFSA = false
            setValue(thisObj, "chkboxAllFSA", false)
            disable(thisObj, 'btnReady')
          }
        }
      }
      setValue(thisObj, "gridWarehouseReceiptList", dataselectedrowforReady)
      setChkCount(0)//setting statecount 0
      setchkenable(true)//enabling the all check box.
      setLoading(false);
    }
    catch (error) {
      setLoading(false);
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnReadyClick: ${error?.toString()}`
        );
      }
      return false;
    } finally {
      setLoading(false);
    }
  }
  thisObj.onbtnReadyClick = onbtnReadyClick;

  const onbtnNotReadyClick = async () => {//Not Ready Functionality
    try {
      setLoading(true);
      let dataselectedrowforNotReady = getValue(thisObj, "gridWarehouseReceiptList")
      let s
      for (let i = 0; i < dataselectedrowforNotReady?.length; i++) {
        if (dataselectedrowforNotReady[i]?.chkbxFSA == true) {

          let COL_OBL_BP = dataselectedrowforNotReady[i]?.txtcolOblPt
          let COL_WR_NO = dataselectedrowforNotReady[i]?.txtcolWRNum

          let dataObj = {
            "fsa_status": "N"
          }
          s = await WarehouseReceiptService.UpdateWareHouseReceiptSetFsaDocument(COL_OBL_BP, COL_WR_NO, dataObj)
          if (s.status == 200) {
            dataselectedrowforNotReady[i].txtcolReady = "N"
            dataselectedrowforNotReady[i].chkbxFSA = false
            setValue(thisObj, "chkboxAllFSA", false)
            disable(thisObj, 'btnNotReady')
          }
        }
      }
      setValue(thisObj, "gridWarehouseReceiptList", dataselectedrowforNotReady)
      setChkCount(0)//setting count to 0
      setchkenable(true)// making all check box as enable when kdoen the process.
      setLoading(false);
    }
    catch (error) {
      setLoading(false);
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnNotReadyClick: ${error?.toString()}`
        );
      }
      return false;
    } finally {
      setLoading(false);
    }
  }
  thisObj.onbtnNotReadyClick = onbtnNotReadyClick;

  const onbtnOpenClick = async () => {//Open Button Functionality
    try {
      let dataselectedrow =  thisObj.state.gridWarehouseReceiptList.selected;
      let gridValues =  getValue(thisObj,"gridWarehouseReceiptList")

      if(gridValues !=undefined && gridValues !=null && gridValues!=""){
      if (!dataselectedrow[0]) {
        showMessage(thisObj,"Please Select Any Row From The Grid To Open",false)
        return
      }
      else {
        if(!(isNaN(dataselectedrow[0]?.txtcolWRNum))){
          let buyingPointlist = thisObj.state['ddOriginalPoint'].valueList;
          let buyingPointDescription = '';
          let buyingPointId = dataselectedrow[0]?.txtcolOrgBP;
          if (buyingPointId !== undefined && buyingPointId !== null) {
            buyingPointDescription = buyingPointlist?.find(elem => elem.key == buyingPointId).description;
           }
          let js = {
            buyingPtId    : dataselectedrow[0]?.txtcolOrgBP,
            unlBuyingPtId : dataselectedrow[0]?.txtcolOblPt, 
            wrNum         : dataselectedrow[0]?.txtcolWRNum,
            erHolder      : dataselectedrow[0]?.txtcolHolder, 
            eWRRcptType   : dataselectedrow[0]?.txtcolStatus,
            eRcptNum      : dataselectedrow[0]?.txtcolERcptNum, 
            eWRTranStatus : dataselectedrow[0]?.txtcolEWRXferStatus,
            lblERcptNum :    dataselectedrow[0]?.txtcolERcptNum,
            vendor        : dataselectedrow[0]?.vendorNumber,
            delvIndicator : dataselectedrow[0]?.txtcolDelvInd,
            frmViewWarehouseReceipt:"nothing",
            buyPointName : buyingPointDescription,
            lblBuyingPtIdTag : dataselectedrow[0]?.txtcolBuyingPoint,
            lblEWRReceiptTypeCaption :  dataselectedrow[0]?.txtcolEWRRcptType,
            lbloriginalewrcptType: dataselectedrow[0]?.txtcolEWRRcptType
          }
          setData(thisObj, 'ViewWarehouseRecieptData', js)
          goTo(thisObj,"WarehouseReceipts#ViewWarehouseReceipt#DEFAULT#true#Click")
        }
      }
    }
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnOpenClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnOpenClick = onbtnOpenClick;

  const ongridWarehouseReceiptListDblclick = () => {//Implimented the Double Click functionality to open View WareHouseReceipt screen.
    try{
      onbtnOpenClick()
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during ongridWarehouseReceiptListDblclick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.ongridWarehouseReceiptListDblclick = ongridWarehouseReceiptListDblclick;

  const onbtnExportToExcelClick = () => {//Export to excel functionality
    try {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      let datatable = getValue(thisObj, 'gridWarehouseReceiptList');
      let secondaryRows = thisObj.state.gridWarehouseReceiptList.expandedRows;
      var fileName = "Warehouse Search";
      let type = getValue(thisObj, "radioOptPrint");
      let excelData = [];
      if (datatable != undefined && datatable != null && datatable != "") {
        for (let i = 0; i < datatable.length; i++) {
          let rowdata = {};
          if (thisObj.state.gridWarehouseReceiptList.columns[0].Visible) {
            rowdata["FSA"] = "";
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[1].Visible) {
            rowdata["Ready"] = datatable[i].txtcolReady;
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[2].Visible) {
            rowdata["WR #"] = ExcelNumericDataConvert(datatable[i].txtcolWRNum);
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[3].Visible) {
            rowdata["Group #"] = ExcelNumericDataConvert(datatable[i].txtcolGroupNum);
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[4].Visible) {
            rowdata["Deferred"] = (datatable[i].txtcolDeferred);
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[5].Visible) {
            rowdata["Vendor"] = datatable[i].txtcolVendor;
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[6].Visible) {
            rowdata["Farm No."] = datatable[i].txtcolFarmNo;
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[7].Visible) {
            rowdata["Org. BP"] = datatable[i].txtcolOrgBP;
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[8].Visible) {
            rowdata["Obl. Pt."] = datatable[i].txtcolOblPt;
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[9].Visible) {
            rowdata["Obl. Whs"] = ExcelNumericDataConvert(datatable[i].txtcolOblWhs);
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[10].Visible) {
            rowdata["Obl. Bin"] = ExcelNumericDataConvert(datatable[i].txtcolOblBin);
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[11].Visible) {
            rowdata["Type"] = datatable[i].txtcolType;
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[12].Visible) {
            rowdata["Variety"] = ExcelNumericDataConvert(datatable[i].txtcolVariety);
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[13].Visible) {
            rowdata["Gen"] = (datatable[i].txtcolGen);
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[14].Visible) {
            rowdata["Seg"] = ExcelNumericDataConvert(datatable[i].txtcolSeg);
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[15].Visible) {
            rowdata["Net Wt"] = ExcelNumericDataConvert(datatable[i].txtcolNetWt, [","]);
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[16].Visible) {
            rowdata["Shrunk Net Wt"] = ExcelNumericDataConvert(datatable[i].txtcolShrunkNetWt, [","]);
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[17].Visible) {
            rowdata["Value"] = ExcelNumericDataConvert(datatable[i].txtcolValue, [","]);
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[18].Visible) {
            rowdata["Cont#   C/O B/F"] = datatable[i].txtcolContNum;
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[19].Visible) {
            rowdata["Status"] = datatable[i].txtcolStatus;
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[20].Visible) {
            rowdata["Loan Status"] = datatable[i].txtcolLoanStatus;
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[21].Visible) {
            rowdata["EWR Xfer Status"] = (datatable[i].txtcolEWRXferStatus);
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[22].Visible) {
            rowdata["Holder"] = (datatable[i].txtcolHolder);
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[23].Visible) {
            rowdata["Buying Point"] = (datatable[i].txtcolBuyingPoint);
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[24].Visible) {
            rowdata["EWR Rcpt Type"] = (datatable[i].txtcolEWRRcptType);
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[25].Visible) {
            rowdata["Last Action"] = (datatable[i].txtcolLastAction);
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[26].Visible) {
            rowdata["Last Action Received"] = (datatable[i].txtcolLastActionReceived);
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[27].Visible) {
            rowdata["E-Rcpt #"] = ExcelNumericDataConvert(datatable[i].txtcolERcptNum);
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[28].Visible) {
            rowdata["Wire Transfer Data"] = datatable[i].txtcolWireXferData;
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[29].Visible) {
            rowdata["Delv Agree #"] = ExcelNumericDataConvert(datatable[i].txtcolDelvAgreeNum);
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[30].Visible) {
            rowdata["Delv Ind"] = datatable[i].txtcolDelvInd;
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[31].Visible) {
            rowdata["Net Repay"] = ExcelNumericDataConvert(datatable[i].txtcolNetRepay, [","]);
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[32].Visible) {
            rowdata["End Date"] = datatable[i].txtcolEndDate;
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[33].Visible) {
            rowdata["211 GPC"] = datatable[i].txtcol211GPC;
          }
          if (thisObj.state.gridWarehouseReceiptList.columns[34].Visible) {
            rowdata["211 Concordia"] = datatable[i].txtcol211Concordia;
          }
          excelData.push(rowdata);

          //Logic for the As Shown
          if (type == "1") {
            if (secondaryRows.length > 0) {
              for (let k = 0; k < secondaryRows.length; k++) {
                if (secondaryRows[k].rowID$ == i) {
                  if (datatable[i].childrow != undefined) {
                    for (let j = 0; j < datatable[i].childrow.length; j++) {
                      let extendedData = {};
                      extendedData["Gen"] = datatable[k].childrow[j].grp == undefined ? "" : datatable[k].childrow[j].grp.split('Group#:')[1];
                      extendedData["Org. BP"] = datatable[k].childrow[j].OrgBpv.split('Org. BP:')[1]
                      extendedData["Obl. Pt."] = datatable[k].childrow[j].OblPtbl.split('Obl. PT:')[1]
                      extendedData["Type"] = datatable[k].childrow[j].PnuttypeVar.split('Type:')[1]
                      extendedData["Variety"] = datatable[k].childrow[j].PnuttypeVarseg.split('Variety:')[1]
                      extendedData["Cont#   C/O B/F"] = datatable[k].childrow[j].Contractnum == undefined ? "" : datatable[k].childrow[j].Contractnum
                      excelData.push(extendedData);
                    }
                  }
                }
              }
            }
          }

          if (type == "2") {
            if (datatable[i].childrow != undefined) {
              for (let j = 0; j < datatable[i].childrow.length; j++) {
                let extendedData = {};
                extendedData["Gen"] = datatable[i].childrow[j].grp == undefined ? "" : datatable[i].childrow[j].grp.split('Group#:')[1];
                extendedData["Org. BP"] = datatable[i].childrow[j].OrgBpv.split('Org. BP:')[1]
                extendedData["Obl. Pt."] = datatable[i].childrow[j].OblPtbl.split('Obl. PT:')[1]
                extendedData["Type"] = datatable[i].childrow[j].PnuttypeVar.split('Type:')[1]
                extendedData["Variety"] = datatable[i].childrow[j].PnuttypeVarseg.split('Variety:')[1]
                extendedData["Cont#   C/O B/F"] = datatable[i].childrow[j].Contractnum == undefined ? "" : datatable[i].childrow[j].Contractnum
                excelData.push(extendedData);
              }
            }
          }
        }
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        var FileSaver = require('file-saver');
        FileSaver.saveAs(data, fileName + fileExtension);
      }
    }
    catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred during onbtnExportToExcelClick: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;

  const onbtnPrintSearchClick = () => {//Print button Functioanlity
    try {
      let gridData = getValue(thisObj, "gridWarehouseReceiptList");
      let radioButtonValue = getValue(thisObj, "radioOptPrint");
      if (gridData != undefined && gridData != null && gridData != "") {
        if (radioButtonValue == "4") {
          let header = ["WR #", "Group #", "Deferred", "Farm No.", "Org. BP", "Type", "Variety", "Gen", "Seg", "Wire Transfer Data", "Net Repay", "End Date", "211 GPC", "211 Concordia"];
          let body = [];
          let redRow = [];
          let yelloRow = [];
          let aligncolumnNetrepayWire = header.indexOf("Net Repay");//For Numeric value in pdf align to right side
          let alignwiretransfertoleft = header.indexOf("Wire Transfer Data")

          for (let i = 0; i < gridData.length; i++) {
            if(gridData[i].txtcolWireXferData != "" && gridData[i].txtcolWireXferData != null ){
            if (gridData[i].txtcolEWRXferStatus == "Rejected") {
              redRow.push(i)
            }
            if (gridData[i].txtcolEWRXferStatus == "Not Ack'd") {
              yelloRow.push(i)
            }
            let row = []
            row.push(gridData[i].txtcolWRNum)
            row.push(gridData[i].txtcolGroupNum)
            row.push(gridData[i].txtcolDeferred)
            row.push(gridData[i].txtcolFarmNo)
            row.push(gridData[i].txtcolOrgBP)
            row.push(gridData[i].txtcolType)
            row.push(gridData[i].txtcolVariety)
            row.push(gridData[i].txtcolGen)
            row.push(gridData[i].txtcolSeg)
            row.push(gridData[i].txtcolWireXferData)
            row.push(gridData[i].txtcolNetRepay)
            row.push(gridData[i].txtcolEndDate)
            row.push(gridData[i].txtcol211GPC)
            row.push(gridData[i].txtcol211Concordia)


            body.push(row)
          }
        }

          var obj = new jsPDF({
            orientation: 'landscape',
          });

          obj.autoTable({
            startY: 5,
            startX: 5,
            styles: { fontSize: 5, lineWidth: 0.2, theme: 'striped' },
            margin: { top: 5, left: 0.5 },
            headStyles: { fillColor: '#E0E0E0', textColor: '#000', fontStyle: 'normal', halign: 'center', valign: 'bottom' },
            body: body,
            theme: 'plain',
            fontStyle: 'normal',
            head: [header],
            didParseCell: function (res) {

              for (let i = 0; i < redRow.length; i++) {
                if (res.row.index == redRow[i]) {
                  for (let i = 0; i < header.length; i++) {
                    res.row.cells[i].styles.fillColor = [255, 0, 0]
                  }
                }
              }

              for (let i = 0; i < yelloRow.length; i++) {
                if (res.row.index == yelloRow[i]) {
                  for (let i = 0; i < header.length; i++) {
                    res.row.cells[i].styles.fillColor = [255, 255, 0]
                  }
                }
              }

              //For Numeric value in pdf align to right side
              if ((aligncolumnNetrepayWire == res.column.index ) && res.section !== "head") {
                res.cell.styles.halign = "right"
              } 
              else if(alignwiretransfertoleft == res.column.index && res.section !== "head"){
                res.cell.styles.halign = "left"
              }
              else {
                res.cell.styles.halign = "center"
              }
            },
          });

          //Footer part 
          let totalRepay = getData(thisObj, "repayForPrintWire")
          let pageCount = obj.internal.getNumberOfPages();
          for (let w = 1; w <= pageCount; w++) {
            obj.setPage(w);
            let pageCurrent = w
            obj.setFontSize(10);
            obj.text(Number(sessionStorage.getItem('year')) + '  RECEIPT SEARCH GRID' + ' - ' + 'Total Net Repay Amt = ' + Number(totalRepay)?.toFixed(2), 5, obj.internal.pageSize.height - 10);
            obj.text((pageCurrent).toString(), obj.internal.pageSize.width - 10, obj.internal.pageSize.height - 10);
          }

          let fileName = prompt("Please enter the file name :");
          if (fileName != null && fileName != undefined) {
            if (fileName != '') {
              obj.save(fileName + '.pdf')
            }
            else {
              showMessage(thisObj, "Please enter the file name!", false)
            }
          }
          else {
            alert("Please try different file name", false)
          }


          return;
        }

        let expandedRows = thisObj.state.gridWarehouseReceiptList.expandedRows;

        let headerArray1 = [], headerArray2 = [], bodyArray1 = [], bodyArray2 = [];

        let staticHeader = ["Ready", "WR #", "Group #", "Deferred", "Vendor", "Farm No.", "Org. BP", "Obl. Pt.", "Obl. Whs", "Obl. Bin", "Type", "Variety", "Gen", "Seg", "Net Wt", "Shrunk Net Wt", "Value", "Cont # C/O B/F", "Status", "Loan Status", "EWR Xfer Status", "Holder", "Buying Point", "EWR Rcpt Type", "Last Action", "Last Action Received", "E-Rcpt #", "Wire Transfer Data", "Delv Agree #:", "Delv Ind", "Net Repay", "End Date", "211 GPC", "211 Concordia"]

        let columnName = ["txtcolReady", "txtcolWRNum", "txtcolGroupNum", "txtcolDeferred", "txtcolVendor", "txtcolFarmNo", "txtcolOrgBP", "txtcolOblPt", "txtcolOblWhs", "txtcolOblBin", "txtcolType", "txtcolVariety", "txtcolGen", "txtcolSeg", "txtcolNetWt", "txtcolShrunkNetWt", "txtcolValue", "txtcolContNum", "txtcolStatus", "txtcolLoanStatus", "txtcolEWRXferStatus", "txtcolHolder", "txtcolBuyingPoint", "txtcolEWRRcptType", "txtcolLastAction", "txtcolLastActionReceived", "txtcolERcptNum", "txtcolWireXferData", "txtcolDelvAgreeNum", "txtcolDelvInd", "txtcolNetRepay", "txtcolEndDate", "txtcol211GPC", "txtcol211Concordia"]

        if (gridData !== undefined && gridData !== null && gridData.length > 0) {

          //creating dynamic header
          for (let i = 0, columncount = 0; i < thisObj.state.gridWarehouseReceiptList.columns.length - 1; i++) {
            if (thisObj.state.gridWarehouseReceiptList.columns[i + 1].Visible) {
              columncount <= 17 ? headerArray1.push(staticHeader[i]) : headerArray2.push(staticHeader[i]);
              columncount++;
            }
          }

          //index of columns which requires right alignments
          let aligncolumnNetWt = headerArray1.indexOf("Net Wt");
          let aligncolumnShrunkNetwt = headerArray1.indexOf("Shrunk Net Wt");
          let aligncolumnValue = headerArray1.indexOf("Value");
          let aligncolumnNetrepay1 = headerArray1.indexOf("Net Repay");
          let aligncolumnNetrepay2 = headerArray2.indexOf("Net Repay");

          //creating dynamic body with main and child rows
          for (let i = 0; i < gridData.length; i++) {
            let rowData = [];
            for (let j = 0; j < thisObj.state.gridWarehouseReceiptList.columns.length - 1; j++) {
              if (thisObj.state.gridWarehouseReceiptList.columns[j + 1].Visible) {
                rowData.push(gridData[i][columnName[j]])
              }
            }

            if (rowData.length <= 17) {
              rowData.unshift((i + 1) + ".");
              bodyArray1.push(rowData)
            } else {
              rowData.unshift((i + 1) + ".");
              rowData.splice(19, 0, (i + 1) + ".");
              bodyArray1.push(rowData.slice(0, 19));
              bodyArray2.push(rowData.slice(19, rowData.length));
            }

            //logic for as Shown option in radiobutton
            if (radioButtonValue == '1') {
              if (expandedRows.length > 0) {
                for (let k = 0; k < expandedRows.length; k++) {
                  if (expandedRows[k].rowID$ == i) {
                    if (expandedRows[k].childrow != undefined) {
                      let childArray1 = [], childArray2 = [];
                      for (let j = 0; j < expandedRows[k].childrow.length; j++) {
                        let extendedData = []

                        for (let x = 0; x < thisObj.state.gridWarehouseReceiptList.columns.length - 1; x++) {
                          if (thisObj.state.gridWarehouseReceiptList.columns[x + 1].Visible) {
                            switch (thisObj.state.gridWarehouseReceiptList.columns[x + 1].name) {
                              case "txtcolOrgBP":
                                extendedData.push(expandedRows[k].childrow[j].OrgBpv.split('Org. BP:')[1] == undefined ? '' : expandedRows[k].childrow[j].OrgBpv.split('Org. BP:')[1])
                                break;

                              case "txtcolOblPt":
                                extendedData.push(expandedRows[k].childrow[j].OblPtbl.split('Obl. PT:')[1] == undefined ? '' : expandedRows[k].childrow[j].OblPtbl.split('Obl. PT:')[1])
                                break;

                              case "txtcolType":
                                extendedData.push(expandedRows[k].childrow[j].PnuttypeVar.split('Type:')[1] == undefined ? '' : expandedRows[k].childrow[j].PnuttypeVar.split('Type:')[1])
                                break;

                              case "txtcolVariety":
                                extendedData.push(expandedRows[k].childrow[j].PnuttypeVarseg.split('Variety:')[1] == undefined ? '' : expandedRows[k].childrow[j].PnuttypeVarseg.split('Variety:')[1])
                                break;

                              case "txtcolGroupNum":
                                extendedData.push(expandedRows[k].childrow[j].grp?.split('Group#:')[1] == undefined ? "" : expandedRows[k].childrow[j].grp.split('Group#:')[1])
                                break;

                              case "txtcolContNum":
                                extendedData.push(expandedRows[k].childrow[j].Contractnum == undefined ? "" : expandedRows[k].childrow[j].Contractnum)
                                break;

                              default:
                                extendedData.push('')
                                break;
                            }
                          }
                        }
                        //childArray.push(extendedData);
                        //child row data for 1 row is now in expanded row, now spliting it for 2 pages
                        if (extendedData.length <= 17) {
                          extendedData.unshift('')
                          childArray1.push(extendedData)
                        } else {
                          extendedData.unshift('')
                          extendedData.splice(19, 0, '')
                          childArray1.push(extendedData.slice(0, 19));
                          childArray2.push(extendedData.slice(19, extendedData.length));
                        }
                      }

                      bodyArray1 = bodyArray1.concat(childArray1)

                      if (childArray2.length > 0) {
                        bodyArray2 = bodyArray2.concat(childArray2)
                      }
                    }
                  }
                }
              }
            }

            //logic for expanded option in radiobutton
            if (radioButtonValue == '2') {
              if (gridData[i].childrow !== undefined) {
                for (let j = 0; j < gridData[i].childrow.length; j++) {
                  let childArray = [];

                  for (let k = 0; k < thisObj.state.gridWarehouseReceiptList.columns.length - 1; k++) {
                    if (thisObj.state.gridWarehouseReceiptList.columns[k + 1].Visible) {
                      switch (thisObj.state.gridWarehouseReceiptList.columns[k + 1].name) {
                        case "txtcolOrgBP":
                          childArray.push(gridData[i].childrow[j].OrgBpv.split('Org. BP:')[1])
                          break;

                        case "txtcolOblPt":
                          childArray.push(gridData[i].childrow[j].OblPtbl.split('Obl. PT:')[1])
                          break;

                        case "txtcolType":
                          childArray.push(gridData[i].childrow[j].PnuttypeVar.split('Type:')[1])
                          break;

                        case "txtcolVariety":
                          childArray.push(gridData[i].childrow[j].PnuttypeVarseg.split('Variety:')[1])
                          break;

                        case "txtcolGroupNum":
                          childArray.push(gridData[i].childrow[j].grp == undefined ? "" : gridData[i].childrow[j].grp.split('Group#:')[1])
                          break;

                        case "txtcolContNum":
                          childArray.push(gridData[i].childrow[j].Contractnum == undefined ? "" : gridData[i].childrow[j].Contractnum)
                          break;


                        default:
                          childArray.push('')
                          break;
                      }

                    }
                  }

                  if (childArray.length <= 17) {
                    childArray.unshift('')
                    bodyArray1.push(childArray)
                  } else {
                    childArray.unshift('')
                    childArray.splice(19, 0, '')
                    bodyArray1.push(childArray.slice(0, 19));
                    bodyArray2.push(childArray.slice(19, childArray.length));
                  }

                }
              }
            }
          }

          //logic for row of total at end of table
          let lastRow = [], secondLastRow = []
          let reppay = getData(thisObj, "repayForPrintWire")// for removing "$"symbol getting data withour that symbol
          let totaldollar = getData(thisObj, "toataldollarremo")// for removing "$"symbol getting data withour that symbol
          for (let j = 0; j < thisObj.state.gridWarehouseReceiptList.columns.length - 1; j++) {
            if (thisObj.state.gridWarehouseReceiptList.columns[j + 1].Visible) {
              secondLastRow.push("")

              switch (thisObj.state.gridWarehouseReceiptList.columns[j].name) {
                case "txtcolVariety":
                  lastRow.push("Total :")
                  break;

                case "txtcolNetWt":
                  lastRow.push(getValue(thisObj, "txtTotalNetWt"))
                  break;

                case "txtcolShrunkNetWt":
                  lastRow.push(getValue(thisObj, "txtTotalShrunkNetWt"))
                  break;

                case "txtcolValue":
                  lastRow.push(FormatNumber(totaldollar))
                  break;

                case "txtcolNetRepay":
                  lastRow.push(FormatNumber(reppay))
                  break;

                default:
                  lastRow.push("")
                  break;
              }
            }
          }
          if (secondLastRow.length <= 17) {
            bodyArray1.push(secondLastRow)
            bodyArray1.push(lastRow)
          } else {
            bodyArray1.push(secondLastRow.slice(0, 18));
            bodyArray2.push(secondLastRow.slice(18, secondLastRow.length));
            bodyArray1.push(lastRow.slice(0, 18));
            bodyArray2.push(lastRow.slice(18, lastRow.length));
          }

          let rejectedRows = []
          let pendingRows = []

          //collecting index/row number which need to be colored
          headerArray1.unshift("Sr.No.");
          if (headerArray2.length > 0) {
            headerArray2.unshift("Sr.No.")
          }

          for (let i = 0; i < gridData.length; i++) {
            if (gridData[i].txtcolEWRXferStatus == "Rejected") {
              rejectedRows.push(i)
            }
            if (gridData[i].txtcolEWRXferStatus == "Not Ack'd") {
              pendingRows.push(i)
            }
          }

          var obj = new jsPDF({
            orientation: 'landscape',
          });
          let n = 0
          let incrementValue = 23

          while (n < bodyArray1.length) {
            if (n >= incrementValue) {
              obj.addPage();
            }

            obj.autoTable({
              startX: 5,
              startY: 5,
              styles: { fontSize: 5, lineWidth: 0.2, theme: 'striped' },
              margin: { top: 5, left: 0.5 },
              headStyles: { fillColor: '#E0E0E0', textColor: '#000', fontStyle: 'normal', halign: 'center', valign: 'bottom' },
              body: bodyArray1.slice(n, n + incrementValue),
              theme: 'plain',
              fontStyle: 'normal',
              head: [headerArray1],

              //for coloring row
              didParseCell: function (res) {

                for (let i = 0; i < rejectedRows.length; i++) {
                  if (res.cell.raw == rejectedRows[i] + 1 + ".") {
                    for (let i = 0; i < headerArray1.length; i++) {
                      res.row.cells[i].styles.fillColor = [255, 0, 0]
                    }
                  }
                }

                for (let i = 0; i < pendingRows.length; i++) {
                  if (res.cell.raw == pendingRows[i] + 1 + ".") {
                    for (let i = 0; i < headerArray1.length; i++) {
                      res.row.cells[i].styles.fillColor = [255, 255, 0]
                    }
                  }
                }

                //For Numeric value in pdf align to right side
                if (((aligncolumnNetWt+1) == res.column.index || (aligncolumnShrunkNetwt+1) == res.column.index || (aligncolumnNetrepay1+1) == res.column.index || (aligncolumnValue+1) == res.column.index) && res.section !== "head") {
                  res.cell.styles.halign = "right"
                } else {
                  res.cell.styles.halign = "center"
                }
              },

            });

            if (headerArray2.length > 0) {
              obj.addPage();

              obj.autoTable({
                startX: 5,
                startY: 5,
                styles: { fontSize: 5, lineWidth: 0.2, theme: 'striped' },
                margin: { top: 5, left: 0.5 },
                headStyles: { fillColor: '#E0E0E0', textColor: '#000', fontStyle: 'normal', halign: 'center', valign: 'bottom' },
                body: bodyArray2.slice(n, n + incrementValue),
                theme: 'plain',
                fontStyle: 'normal',
                head: [headerArray2],

                //for coloring row
                didParseCell: function (res) {

                  for (let i = 0; i < rejectedRows.length; i++) {
                    if (res.cell.raw == rejectedRows[i] + 1 + ".") {
                      for (let i = 0; i < headerArray2.length; i++) {
                        res.row.cells[i].styles.fillColor = [255, 0, 0]
                      }
                    }
                  }

                  for (let i = 0; i < pendingRows.length; i++) {
                    if (res.cell.raw == pendingRows[i] + 1 + ".") {
                      for (let i = 0; i < headerArray2.length; i++) {
                        res.row.cells[i].styles.fillColor = [255, 255, 0]
                      }
                    }
                  }

                  //For Numeric value in pdf align to right side
                  if (((aligncolumnNetrepay2+1) == res.column.index) && res.section !== "head") {
                    res.cell.styles.halign = "right"
                  } else {
                    res.cell.styles.halign = "center"
                  }
                },

              });
            }

            n = n + incrementValue;
          }

          //page number and bottom text on page
          let pageCount = obj.internal.getNumberOfPages();
          for (let w = 1; w <= pageCount; w++) {
            obj.setPage(w);
            let pageCurrent = w
            obj.setFontSize(10);
            obj.text(Number(sessionStorage.getItem('year')) + '  RECEIPT SEARCH GRID', 5, obj.internal.pageSize.height - 10);
            obj.text((pageCurrent).toString(), obj.internal.pageSize.width - 10, obj.internal.pageSize.height - 10);
          }


          let fileName = prompt("Please enter the file name :");
          if (fileName != null && fileName != undefined) {
            if (fileName != '') {
              obj.save(fileName + '.pdf')
            }
            else {
              showMessage(thisObj, "Please enter the file name!", false)
            }
          }
          else {
            alert("Please try different file name", false)
          }
        }
      }
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error?.message);
      } else {
        showMessage(
          thisObj,
          `Error occurred on print Search button: ${error?.toString()}`
        );
      }
      return false;
    }
  }
  thisObj.onbtnPrintSearchClick = onbtnPrintSearchClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={values => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_WarehouseReceiptInquiry*/}

              {/* END_USER_CODE-USER_BEFORE_WarehouseReceiptInquiry*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxWarehouseReceiptInquiry*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxWarehouseReceiptInquiry*/}

              <GroupBoxWidget
                conf={state.grpbxWarehouseReceiptInquiry}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails1*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails1*/}

                <GroupBoxWidget conf={state.grpbxDetails1} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_ddOriginalPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_ddOriginalPoint*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddOriginalPoint}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddOriginalPoint*/}

                  {/* END_USER_CODE-USER_AFTER_ddOriginalPoint*/}
                  {/* START_USER_CODE-USER_BEFORE_ddObligationPoint*/}

                  {/* END_USER_CODE-USER_BEFORE_ddObligationPoint*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddObligationPoint}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddObligationPoint*/}

                  {/* END_USER_CODE-USER_AFTER_ddObligationPoint*/}
                  {/* START_USER_CODE-USER_BEFORE_txtReceiptNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtReceiptNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtReceiptNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtReceiptNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtReceiptNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtOblWhseNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOblWhseNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOblWhseNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOblWhseNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtOblWhseNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtContract*/}

                  {/* END_USER_CODE-USER_BEFORE_txtContract*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtContract}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtContract*/}

                  {/* END_USER_CODE-USER_AFTER_txtContract*/}
                  {/* START_USER_CODE-USER_BEFORE_txtOblBinNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOblBinNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOblBinNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOblBinNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtOblBinNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendor}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                  <ButtonWidget
                    conf={state.btnVendor}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                  {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendr*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendr*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendr}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendr*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendr*/}
                  {/* START_USER_CODE-USER_BEFORE_ddHolder*/}

                  {/* END_USER_CODE-USER_BEFORE_ddHolder*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddHolder}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddHolder*/}

                  {/* END_USER_CODE-USER_AFTER_ddHolder*/}
                  {/* START_USER_CODE-USER_BEFORE_ddFSADocReady*/}

                  {/* END_USER_CODE-USER_BEFORE_ddFSADocReady*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddFSADocReady}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddFSADocReady*/}

                  {/* END_USER_CODE-USER_AFTER_ddFSADocReady*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxWireXferData*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxWireXferData*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxWireXferData}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxWireXferData*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxWireXferData*/}
                  {/* START_USER_CODE-USER_BEFORE_ddWireXferData*/}

                  {/* END_USER_CODE-USER_BEFORE_ddWireXferData*/}
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWireXfer}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddWireXferData}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddWireXferData*/}

                  {/* END_USER_CODE-USER_AFTER_ddWireXferData*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails1*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails1*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails2*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails2*/}

                <GroupBoxWidget conf={state.grpbxDetails2} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_ddState*/}

                  {/* END_USER_CODE-USER_BEFORE_ddState*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddState}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddState*/}

                  {/* END_USER_CODE-USER_AFTER_ddState*/}
                  {/* START_USER_CODE-USER_BEFORE_ddCounty*/}

                  {/* END_USER_CODE-USER_BEFORE_ddCounty*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCounty}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddCounty*/}

                  {/* END_USER_CODE-USER_AFTER_ddCounty*/}
                  {/* START_USER_CODE-USER_BEFORE_ddFarm*/}

                  {/* END_USER_CODE-USER_BEFORE_ddFarm*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddFarm}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddFarm*/}

                  {/* END_USER_CODE-USER_AFTER_ddFarm*/}
                  {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddPeanutType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                  {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                  {/* START_USER_CODE-USER_BEFORE_ddGeneration*/}

                  {/* END_USER_CODE-USER_BEFORE_ddGeneration*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddGeneration}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddGeneration*/}

                  {/* END_USER_CODE-USER_AFTER_ddGeneration*/}
                  {/* START_USER_CODE-USER_BEFORE_ddSeed*/}

                  {/* END_USER_CODE-USER_BEFORE_ddSeed*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddSeed}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddSeed*/}

                  {/* END_USER_CODE-USER_AFTER_ddSeed*/}
                  {/* START_USER_CODE-USER_BEFORE_ddSeg*/}

                  {/* END_USER_CODE-USER_BEFORE_ddSeg*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddSeg}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddSeg*/}

                  {/* END_USER_CODE-USER_AFTER_ddSeg*/}
                  {/* START_USER_CODE-USER_BEFORE_ddOleic*/}

                  {/* END_USER_CODE-USER_BEFORE_ddOleic*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddOleic}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddOleic*/}

                  {/* END_USER_CODE-USER_AFTER_ddOleic*/}
                  {/* START_USER_CODE-USER_BEFORE_ddOrganic*/}

                  {/* END_USER_CODE-USER_BEFORE_ddOrganic*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddOrganic}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddOrganic*/}

                  {/* END_USER_CODE-USER_AFTER_ddOrganic*/}
                  {/* START_USER_CODE-USER_BEFORE_ddLoanStatus*/}

                  {/* END_USER_CODE-USER_BEFORE_ddLoanStatus*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddLoanStatus}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddLoanStatus*/}

                  {/* END_USER_CODE-USER_AFTER_ddLoanStatus*/}
                  {/* START_USER_CODE-USER_BEFORE_txtGroupNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtGroupNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtGroupNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtGroupNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtGroupNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txt1007Num*/}

                  {/* END_USER_CODE-USER_BEFORE_txt1007Num*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txt1007Num}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txt1007Num*/}

                  {/* END_USER_CODE-USER_AFTER_txt1007Num*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails2*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails2*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails3*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails3*/}

                <GroupBoxWidget conf={state.grpbxDetails3} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtLockInDateFrom*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLockInDateFrom*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLockInDateFrom}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLockInDateFrom*/}

                  {/* END_USER_CODE-USER_AFTER_txtLockInDateFrom*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLockInDateTo*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLockInDateTo*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLockInDateTo}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLockInDateTo*/}

                  {/* END_USER_CODE-USER_AFTER_txtLockInDateTo*/}
                  {/* START_USER_CODE-USER_BEFORE_txtEndDateFrom*/}

                  {/* END_USER_CODE-USER_BEFORE_txtEndDateFrom*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEndDateFrom}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_txtEndDateFrom*/}

                  {/* END_USER_CODE-USER_AFTER_txtEndDateFrom*/}
                  {/* START_USER_CODE-USER_BEFORE_txtEndDateTo*/}

                  {/* END_USER_CODE-USER_BEFORE_txtEndDateTo*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEndDateTo}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_txtEndDateTo*/}

                  {/* END_USER_CODE-USER_AFTER_txtEndDateTo*/}
                  {/* START_USER_CODE-USER_BEFORE_txtMaturityDateFrom*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMaturityDateFrom*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMaturityDateFrom}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMaturityDateFrom*/}

                  {/* END_USER_CODE-USER_AFTER_txtMaturityDateFrom*/}
                  {/* START_USER_CODE-USER_BEFORE_txtMaturityDateTo*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMaturityDateTo*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMaturityDateTo}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMaturityDateTo*/}

                  {/* END_USER_CODE-USER_AFTER_txtMaturityDateTo*/}
                  {/* START_USER_CODE-USER_BEFORE_txtRedemptionDateFrom*/}

                  {/* END_USER_CODE-USER_BEFORE_txtRedemptionDateFrom*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtRedemptionDateFrom}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_txtRedemptionDateFrom*/}

                  {/* END_USER_CODE-USER_AFTER_txtRedemptionDateFrom*/}
                  {/* START_USER_CODE-USER_BEFORE_txtRedemptionDateTo*/}

                  {/* END_USER_CODE-USER_BEFORE_txtRedemptionDateTo*/}

                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtRedemptionDateTo}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_txtRedemptionDateTo*/}

                  {/* END_USER_CODE-USER_AFTER_txtRedemptionDateTo*/}
                  {/* START_USER_CODE-USER_BEFORE_ddReceiptStatus*/}

                  {/* END_USER_CODE-USER_BEFORE_ddReceiptStatus*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddReceiptStatus}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddReceiptStatus*/}

                  {/* END_USER_CODE-USER_AFTER_ddReceiptStatus*/}
                  {/* START_USER_CODE-USER_BEFORE_ddContractStatus*/}

                  {/* END_USER_CODE-USER_BEFORE_ddContractStatus*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddContractStatus}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddContractStatus*/}

                  {/* END_USER_CODE-USER_AFTER_ddContractStatus*/}
                  {/* START_USER_CODE-USER_BEFORE_ddEWRStatus*/}

                  {/* END_USER_CODE-USER_BEFORE_ddEWRStatus*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddEWRStatus}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddEWRStatus*/}

                  {/* END_USER_CODE-USER_AFTER_ddEWRStatus*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLoanNum1*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLoanNum1*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLoanNum1}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLoanNum1*/}

                  {/* END_USER_CODE-USER_AFTER_txtLoanNum1*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLoanNum2*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLoanNum2*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLoanNum2}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLoanNum2*/}

                  {/* END_USER_CODE-USER_AFTER_txtLoanNum2*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLoanNum3*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLoanNum3*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLoanNum3}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLoanNum3*/}

                  {/* END_USER_CODE-USER_AFTER_txtLoanNum3*/}
                  {/* START_USER_CODE-USER_BEFORE_ddCCC500*/}

                  {/* END_USER_CODE-USER_BEFORE_ddCCC500*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCCC500}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddCCC500*/}

                  {/* END_USER_CODE-USER_AFTER_ddCCC500*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails3*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails3*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxWhsRcptList*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxWhsRcptList*/}

                <GroupBoxWidget
                  conf={state.grpbxWhsRcptList}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblWhsRcptList*/}

                  {/* END_USER_CODE-USER_BEFORE_lblWhsRcptList*/}
                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxAllFSA}
                    screenConf={state}
                  ></CheckboxWidget>

                  <LabelWidget
                    values={values}
                    conf={state.lblWhsRcptList}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblWhsRcptList*/}

                  {/* END_USER_CODE-USER_AFTER_lblWhsRcptList*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxFSADocument*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxFSADocument*/}

                  <GroupBoxWidget
                    conf={state.grpbxFSADocument}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lblFSADocument*/}

                    {/* END_USER_CODE-USER_BEFORE_lblFSADocument*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblFSADocument}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblFSADocument*/}

                    {/* END_USER_CODE-USER_AFTER_lblFSADocument*/}
                    {/* START_USER_CODE-USER_BEFORE_btnReady*/}

                    {/* END_USER_CODE-USER_BEFORE_btnReady*/}

                    <ButtonWidget
                      conf={state.btnReady}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnReady*/}

                    {/* END_USER_CODE-USER_AFTER_btnReady*/}
                    {/* START_USER_CODE-USER_BEFORE_btnNotReady*/}

                    {/* END_USER_CODE-USER_BEFORE_btnNotReady*/}

                    <ButtonWidget
                      conf={state.btnNotReady}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnNotReady*/}

                    {/* END_USER_CODE-USER_AFTER_btnNotReady*/}
                    {/* START_USER_CODE-USER_BEFORE_btnPrintAllReady*/}

                    {/* END_USER_CODE-USER_BEFORE_btnPrintAllReady*/}

                    <ButtonWidget
                      conf={state.btnPrintAllReady}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnPrintAllReady*/}

                    {/* END_USER_CODE-USER_AFTER_btnPrintAllReady*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxFSADocument*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxFSADocument*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                  <ButtonWidget
                    conf={state.btnSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                  {/* START_USER_CODE-USER_BEFORE_btnExtRcptForfeiture*/}

                  {/* END_USER_CODE-USER_BEFORE_btnExtRcptForfeiture*/}

                  <ButtonWidget
                    conf={state.btnExtRcptForfeiture}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnExtRcptForfeiture*/}

                  {/* END_USER_CODE-USER_AFTER_btnExtRcptForfeiture*/}
                  {/* START_USER_CODE-USER_BEFORE_lblCropYear*/}

                  {/* END_USER_CODE-USER_BEFORE_lblCropYear*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblCropYear}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblCropYear*/}

                  {/* END_USER_CODE-USER_AFTER_lblCropYear*/}
                  {/* START_USER_CODE-USER_BEFORE_btnCreateWarehouseReceipt*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCreateWarehouseReceipt*/}

                  <ButtonWidget
                    conf={state.btnCreateWarehouseReceipt}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCreateWarehouseReceipt*/}

                  {/* END_USER_CODE-USER_AFTER_btnCreateWarehouseReceipt*/}
                  {/* START_USER_CODE-USER_BEFORE_btnOpen*/}

                  {/* END_USER_CODE-USER_BEFORE_btnOpen*/}

                  <ButtonWidget
                    conf={state.btnOpen}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnOpen*/}

                  {/* END_USER_CODE-USER_AFTER_btnOpen*/}
                  {/* START_USER_CODE-USER_BEFORE_gridWarehouseReceiptList*/}

                  {/* END_USER_CODE-USER_BEFORE_gridWarehouseReceiptList*/}

                  <GridWidget
                    conf={state.gridWarehouseReceiptList}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onRowChange={selectedRows => onGridRowChange(selectedRows)}
                    errors={errors}
                    touched={touched}
                    rows={values.gridWarehouseReceiptList}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    setFieldValue={setFieldValue}
                    refObject={thisObj}
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridWarehouseReceiptList*/}

                  {/* END_USER_CODE-USER_AFTER_gridWarehouseReceiptList*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalNetWt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalNetWt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalNetWt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalNetWt*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalNetWt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalShrunkNetWt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalShrunkNetWt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalShrunkNetWt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalShrunkNetWt*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalShrunkNetWt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalValue*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalValue*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalValue}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalValue*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalValue*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalNetRepay*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalNetRepay*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalNetRepay}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalNetRepay*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalNetRepay*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxWhsRcptList*/}

                {/* END_USER_CODE-USER_AFTER_grpbxWhsRcptList*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxWarehouseReceiptInquiry*/}

              {/* END_USER_CODE-USER_AFTER_grpbxWarehouseReceiptInquiry*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_grpbxBtnCntnr*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxBtnCntnr*/}

                <GroupBoxWidget conf={state.grpbxBtnCntnr} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_btnViewEWRLog*/}

                  {/* END_USER_CODE-USER_BEFORE_btnViewEWRLog*/}

                  <ButtonWidget
                    conf={state.btnViewEWRLog}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnViewEWRLog*/}

                  {/* END_USER_CODE-USER_AFTER_btnViewEWRLog*/}
                  {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                  <ButtonWidget
                    conf={state.btnCancel}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                  {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                  {/* START_USER_CODE-USER_BEFORE_btnResetToEWRAccepted*/}

                  {/* END_USER_CODE-USER_BEFORE_btnResetToEWRAccepted*/}

                  <ButtonWidget
                    conf={state.btnResetToEWRAccepted}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnResetToEWRAccepted*/}

                  {/* END_USER_CODE-USER_AFTER_btnResetToEWRAccepted*/}
                  {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                  {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                  <ButtonWidget
                    conf={state.btnClose}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnClose*/}

                  {/* END_USER_CODE-USER_AFTER_btnClose*/}
                  {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                  {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                  <ButtonWidget
                    conf={state.btnExportToExcel}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                  {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                  {/* START_USER_CODE-USER_BEFORE_btnPrintSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPrintSearch*/}

                  <ButtonWidget
                    conf={state.btnPrintSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPrintSearch*/}

                  {/* END_USER_CODE-USER_AFTER_btnPrintSearch*/}
                  {/* START_USER_CODE-USER_BEFORE_btnUploadLockIn*/}

                  {/* END_USER_CODE-USER_BEFORE_btnUploadLockIn*/}

                  <ButtonWidget
                    conf={state.btnUploadLockIn}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnUploadLockIn*/}

                  {/* END_USER_CODE-USER_AFTER_btnUploadLockIn*/}
                  {/* START_USER_CODE-USER_BEFORE_btnClearRPS*/}

                  {/* END_USER_CODE-USER_BEFORE_btnClearRPS*/}

                  <ButtonWidget
                    conf={state.btnClearRPS}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnClearRPS*/}

                  {/* END_USER_CODE-USER_AFTER_btnClearRPS*/}
                  {/* START_USER_CODE-USER_BEFORE_btnRedeemByLoan*/}

                  {/* END_USER_CODE-USER_BEFORE_btnRedeemByLoan*/}

                  <ButtonWidget
                    conf={state.btnRedeemByLoan}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnRedeemByLoan*/}

                  {/* END_USER_CODE-USER_AFTER_btnRedeemByLoan*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxBtnCntnr*/}

                {/* END_USER_CODE-USER_AFTER_grpbxBtnCntnr*/}
                {/* START_USER_CODE-USER_BEFORE_radioOptPrint*/}

                {/* END_USER_CODE-USER_BEFORE_radioOptPrint*/}

                <RadioButtonGroupWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.radioOptPrint}
                  screenConf={state}
                ></RadioButtonGroupWidget>
                {/* START_USER_CODE-USER_AFTER_radioOptPrint*/}

                {/* END_USER_CODE-USER_AFTER_radioOptPrint*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_WarehouseReceiptInquiry*/}

              {/* END_USER_CODE-USER_AFTER_WarehouseReceiptInquiry*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_WarehouseReceiptInquiry);
