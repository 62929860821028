//1007GroupSearch
/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  setValue,
  getValue,
  setData,
  getData,
  disable,
  hide,
  show,
  hideColumn,
  enable,
  getSelectedRowNumber,
  getGridValue,
  goTo,
} from "../../shared/WindowImports";

import "./1007GrpSearch.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import Loading from "../../../Loader/Loading";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_1007GrpSearch(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const match = useRouteMatch();
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "1007GrpSearch",
    windowName: "1007GrpSearch",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.1007GrpSearch",
    // START_USER_CODE-USER_1007GrpSearch_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "1007 Grouping Search",
      scrCode: "PN1160A",
    },
    // END_USER_CODE-USER_1007GrpSearch_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnCreate1007Grouping: {
      name: "btnCreate1007Grouping",
      type: "ButtonWidget",
      parent: "gpxbxGrpSearch4",
      Label: "Create 1007 Grouping",
      CharWidth: "41",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCreate1007Grouping_PROPERTIES

      // END_USER_CODE-USER_btnCreate1007Grouping_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnOpen: {
      name: "btnOpen",
      type: "ButtonWidget",
      parent: "gpxbxGrpSearch4",
      Label: "Open",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOpen_PROPERTIES

      // END_USER_CODE-USER_btnOpen_PROPERTIES
    },
    btnPrintSearch: {
      name: "btnPrintSearch",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Print Search",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrintSearch_PROPERTIES

      // END_USER_CODE-USER_btnPrintSearch_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxGrpSearch2",
      Label: "Search:",
      CharWidth: "17",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxGrpSearch1",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    col1007: {
      name: "col1007",
      type: "GridColumnWidget",
      parent: "gridGrpSearch",
      Label: "1007#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1007_PROPERTIES

      // END_USER_CODE-USER_col1007_PROPERTIES
    },
    colBuyPt: {
      name: "colBuyPt",
      type: "GridColumnWidget",
      parent: "gridGrpSearch",
      Label: "Buy Pt.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBuyPt_PROPERTIES

      // END_USER_CODE-USER_colBuyPt_PROPERTIES
    },
    colCmaDma: {
      name: "colCmaDma",
      type: "GridColumnWidget",
      parent: "gridGrpSearch",
      Label: "CMA/DMA",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCmaDma_PROPERTIES

      // END_USER_CODE-USER_colCmaDma_PROPERTIES
    },
    colContract: {
      name: "colContract",
      type: "GridColumnWidget",
      parent: "gridGrpSearch",
      Label: "Contract",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContract_PROPERTIES

      // END_USER_CODE-USER_colContract_PROPERTIES
    },
    colDeferred: {
      name: "colDeferred",
      type: "GridColumnWidget",
      parent: "gridGrpSearch",
      Label: "Deferred",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDeferred_PROPERTIES

      // END_USER_CODE-USER_colDeferred_PROPERTIES
    },
    colFarm: {
      name: "colFarm",
      type: "GridColumnWidget",
      parent: "gridGrpSearch",
      Label: "Farm",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFarm_PROPERTIES

      // END_USER_CODE-USER_colFarm_PROPERTIES
    },
    colGrp: {
      name: "colGrp",
      type: "GridColumnWidget",
      parent: "gridGrpSearch",
      Label: "GRP#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGrp_PROPERTIES

      // END_USER_CODE-USER_colGrp_PROPERTIES
    },
    colGrpDate: {
      name: "colGrpDate",
      type: "GridColumnWidget",
      parent: "gridGrpSearch",
      Label: "Grp. Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGrpDate_PROPERTIES

      // END_USER_CODE-USER_colGrpDate_PROPERTIES
    },
    colCurrentHolder: {
      name: "colCurrentHolder",
      type: "GridColumnWidget",
      parent: "gridGrpSearch",
      Label: "Current Holder ID",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGrpDate_PROPERTIES

      // END_USER_CODE-USER_colGrpDate_PROPERTIES
    },

    colInspDate: {
      name: "colInspDate",
      type: "GridColumnWidget",
      parent: "gridGrpSearch",
      Label: "Insp. Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colInspDate_PROPERTIES

      // END_USER_CODE-USER_colInspDate_PROPERTIES
    },
    colObligationPoint: {
      name: "colObligationPoint",
      type: "GridColumnWidget",
      parent: "gridGrpSearch",
      Label: "Obligation Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colObligationPoint_PROPERTIES

      // END_USER_CODE-USER_colObligationPoint_PROPERTIES
    },
    colSC95: {
      name: "colSC95",
      type: "GridColumnWidget",
      parent: "gridGrpSearch",
      Label: "SC95#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSC95_PROPERTIES

      // END_USER_CODE-USER_colSC95_PROPERTIES
    },
    ddCmaDma: {
      name: "ddCmaDma",
      type: "DropDownFieldWidget",
      parent: "grpbxgGrpSearch3",
      Label: "CMA/DMA:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCmaDma_PROPERTIES

      // END_USER_CODE-USER_ddCmaDma_PROPERTIES
    },
    ddDeferred: {
      name: "ddDeferred",
      type: "DropDownFieldWidget",
      parent: "grpbxgGrpSearch3",
      Label: "Deferred:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDeferred_PROPERTIES

      // END_USER_CODE-USER_ddDeferred_PROPERTIES
    },
    ddGeneration: {
      name: "ddGeneration",
      type: "DropDownFieldWidget",
      parent: "grpbxgGrpSearch3",
      Label: "Generation:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddGeneration_PROPERTIES

      // END_USER_CODE-USER_ddGeneration_PROPERTIES
    },
    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxGrpSearch1",
      Label: "Location",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation_PROPERTIES

      // END_USER_CODE-USER_ddLocation_PROPERTIES
    },
    ddOleic: {
      name: "ddOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxgGrpSearch3",
      Label: "Oleic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxGrpSearch2",
      Label: "Peanut Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddPeanutVariety: {
      name: "ddPeanutVariety",
      type: "DropDownFieldWidget",
      parent: "grpbxgGrpSearch3",
      Label: "Peanut Variety:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_ddPeanutVariety_PROPERTIES
    },
    ddSeed: {
      name: "ddSeed",
      type: "DropDownFieldWidget",
      parent: "grpbxGrpSearch2",
      Label: "Seed:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeed_PROPERTIES

      // END_USER_CODE-USER_ddSeed_PROPERTIES
    },
    ddSegType: {
      name: "ddSegType",
      type: "DropDownFieldWidget",
      parent: "grpbxGrpSearch2",
      Label: "Seg Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSegType_PROPERTIES

      // END_USER_CODE-USER_ddSegType_PROPERTIES
    },
    gpxbxGrpSearch4: {
      name: "gpxbxGrpSearch4",
      type: "GroupBoxWidget",
      parent: "grpbx1007GrpSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_gpxbxGrpSearch4_PROPERTIES

      // END_USER_CODE-USER_gpxbxGrpSearch4_PROPERTIES
    },
    gridGrpSearch: {
      name: "gridGrpSearch",
      type: "GridWidget",
      parent: "gpxbxGrpSearch4",
      gridCellsOrder:
        "txtcolGRP,txtcol1007,txtcolObligationPoint,txtcolFarm,txtcolSC95,txtcolInspDate,txtcolBuyPt,txtcolContract,txtcolCmaDma,txtcolDeferred,txtcolGrpDate,txtcolCurrentHolder",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridGrpSearch_PROPERTIES

      // END_USER_CODE-USER_gridGrpSearch_PROPERTIES
    },
    grpbxgGrpSearch3: {
      name: "grpbxgGrpSearch3",
      type: "GroupBoxWidget",
      parent: "grpbx1007GrpSearch",
      ColSpan: "1",
      ColSpanForLargeDesktop: "1",
      ColSpanForTabLandscape: "1",
      Height: "",
      Width: "",
      ColSpanForTabPotrait: "1",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColSpanForMobile: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxgGrpSearch3_PROPERTIES

      // END_USER_CODE-USER_grpbxgGrpSearch3_PROPERTIES
    },
    grpbxGrpSearch1: {
      name: "grpbxGrpSearch1",
      type: "GroupBoxWidget",
      parent: "grpbx1007GrpSearch",
      ColSpan: "1",
      ColSpanForLargeDesktop: "1",
      ColSpanForTabLandscape: "1",
      Height: "",
      Width: "",
      ColsForMobile: "3",
      ColSpanForTabPotrait: "1",
      HasLabel: false,
      ColSpanForMobile: "1",
      // START_USER_CODE-USER_grpbxGrpSearch1_PROPERTIES

      // END_USER_CODE-USER_grpbxGrpSearch1_PROPERTIES
    },
    grpbxGrpSearch2: {
      name: "grpbxGrpSearch2",
      type: "GroupBoxWidget",
      parent: "grpbx1007GrpSearch",
      ColSpan: "1",
      ColSpanForLargeDesktop: "1",
      ColSpanForTabLandscape: "1",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColSpanForTabPotrait: "1",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColSpanForMobile: "1",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxGrpSearch2_PROPERTIES

      // END_USER_CODE-USER_grpbxGrpSearch2_PROPERTIES
    },
    txt1007: {
      name: "txt1007",
      type: "TextBoxWidget",
      parent: "grpbxGrpSearch1",
      Label: "1007#",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007_PROPERTIES

      // END_USER_CODE-USER_txt1007_PROPERTIES
    },
    txtcol1007: {
      name: "txtcol1007",
      type: "TextBoxWidget",
      colName: "col1007",
      parent: "gridGrpSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol1007_PROPERTIES

      // END_USER_CODE-USER_txtcol1007_PROPERTIES
    },
    txtcolBuyPt: {
      name: "txtcolBuyPt",
      type: "TextBoxWidget",
      colName: "colBuyPt",
      parent: "gridGrpSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtcolBuyPt_PROPERTIES
    },
    txtcolCmaDma: {
      name: "txtcolCmaDma",
      type: "TextBoxWidget",
      colName: "colCmaDma",
      parent: "gridGrpSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCmaDma_PROPERTIES

      // END_USER_CODE-USER_txtcolCmaDma_PROPERTIES
    },
    txtcolContract: {
      name: "txtcolContract",
      type: "TextBoxWidget",
      colName: "colContract",
      parent: "gridGrpSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContract_PROPERTIES

      // END_USER_CODE-USER_txtcolContract_PROPERTIES
    },
    txtcolDeferred: {
      name: "txtcolDeferred",
      type: "TextBoxWidget",
      colName: "colDeferred",
      parent: "gridGrpSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDeferred_PROPERTIES

      // END_USER_CODE-USER_txtcolDeferred_PROPERTIES
    },
    txtcolFarm: {
      name: "txtcolFarm",
      type: "TextBoxWidget",
      colName: "colFarm",
      parent: "gridGrpSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFarm_PROPERTIES

      // END_USER_CODE-USER_txtcolFarm_PROPERTIES
    },
    txtcolGRP: {
      name: "txtcolGRP",
      type: "TextBoxWidget",
      colName: "colGrp",
      parent: "gridGrpSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolGRP_PROPERTIES

      // END_USER_CODE-USER_txtcolGRP_PROPERTIES
    },
    txtcolGrpDate: {
      name: "txtcolGrpDate",
      type: "TextBoxWidget",
      colName: "colGrpDate",
      parent: "gridGrpSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolGrpDate_PROPERTIES

      // END_USER_CODE-USER_txtcolGrpDate_PROPERTIES
    },

    txtcolCurrentHolder: {
      name: "txtcolCurrentHolder",
      type: "TextBoxWidget",
      colName: "colCurrentHolder",
      parent: "gridGrpSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolGrpDate_PROPERTIES

      // END_USER_CODE-USER_txtcolGrpDate_PROPERTIES
    },

    txtcolInspDate: {
      name: "txtcolInspDate",
      type: "TextBoxWidget",
      colName: "colInspDate",
      parent: "gridGrpSearch",
      Label: "textboxwidget35",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolInspDate_PROPERTIES

      // END_USER_CODE-USER_txtcolInspDate_PROPERTIES
    },
    txtcolObligationPoint: {
      name: "txtcolObligationPoint",
      type: "TextBoxWidget",
      colName: "colObligationPoint",
      parent: "gridGrpSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolObligationPoint_PROPERTIES

      // END_USER_CODE-USER_txtcolObligationPoint_PROPERTIES
    },
    txtcolSC95: {
      name: "txtcolSC95",
      type: "TextBoxWidget",
      colName: "colSC95",
      parent: "gridGrpSearch",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSC95_PROPERTIES

      // END_USER_CODE-USER_txtcolSC95_PROPERTIES
    },
    txtFarm: {
      name: "txtFarm",
      type: "TextBoxWidget",
      parent: "grpbxGrpSearch2",
      Label: "Farm #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarm_PROPERTIES

      // END_USER_CODE-USER_txtFarm_PROPERTIES
    },
    txtFarmSuffix: {
      name: "txtFarmSuffix",
      type: "TextBoxWidget",
      parent: "grpbxGrpSearch2",
      Label: "Farm Suffix #:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarmSuffix_PROPERTIES

      // END_USER_CODE-USER_txtFarmSuffix_PROPERTIES
    },
    txtGrouping: {
      name: "txtGrouping",
      type: "TextBoxWidget",
      parent: "grpbxGrpSearch1",
      Label: "Grouping#",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGrouping_PROPERTIES

      // END_USER_CODE-USER_txtGrouping_PROPERTIES
    },
    txtSC95: {
      name: "txtSC95",
      type: "TextBoxWidget",
      parent: "grpbxGrpSearch1",
      Label: "SC95#",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSC95_PROPERTIES

      // END_USER_CODE-USER_txtSC95_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxGrpSearch1",
      Label: "Vendor#",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtVndr: {
      name: "txtVndr",
      type: "TextBoxWidget",
      parent: "grpbxGrpSearch1",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVndr_PROPERTIES

      // END_USER_CODE-USER_txtVndr_PROPERTIES
    },
    grpbx1007GrpSearch: {
      name: "grpbx1007GrpSearch",
      type: "GroupBoxWidget",
      parent: "1007GrpSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbx1007GrpSearch_PROPERTIES

      // END_USER_CODE-USER_grpbx1007GrpSearch_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "1007GrpSearch",
      Label: "cmmndCntnrActions",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "WarehouseReceipts#1007GroupingProfile": {},
        "ContractManagement#VendorLookup": {},
        "WarehouseReceipts#1007GrpSelect": {},
      },
      REVERSE: {
        "WarehouseReceipts#1007GroupingProfile": {},
        "ContractManagement#VendorLookup": {},
        "WarehouseReceipts#1007GrpSelect": {}
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnCancel: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    },
    // btnOpen: {
    //   DEFAULT: ["WarehouseReceipts#1007GroupingProfile#DEFAULT#true#Click"],
    // },
    // btnVendor: {
    //   DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    // },
    // btnCreate1007Grouping: {
    //   DEFAULT: ["WarehouseReceipts#1007GrpSelect#DEFAULT#true#Click"],
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const [selectedRowNum, setSelectedRowNum] = useState(-1);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });

  useEffect(()=>{
    let grpProfileData=getData(thisObj,"parentscreenrefresh");
    if(grpProfileData==true){
      searching();
      setData(thisObj,"parentscreenrefresh",null);
    }
  },[getData(thisObj,"parentscreenrefresh",)])

  useEffect(()=>{
    let grpProfileData=getData(thisObj,"closingOf1007GrpSelect");
    if(grpProfileData==true){
      searching()
      setData(thisObj,"closingOf1007GrpSelect",null);
    }
  },[getData(thisObj,"closingOf1007GrpSelect",)])

  let searching=async()=>{
    let noSearch=1
     await onbtnSearchClick(noSearch);
  }


  useEffect(() => {
    let vendor_lookup = getData(thisObj, 'vendorDetails')
    let pagename = getData(thisObj, "1007GrpSearch_Vendorlookup")//Fixed as part of UAT Fix
    if (vendor_lookup !== null && pagename == true) {
     
      setValue(thisObj,"txtVendor", vendor_lookup.VendorNumber)
      bFillRemitToList(vendor_lookup.VendorNumber)
      setData(thisObj, 'vendorDetails', null)
      setData(thisObj, '1007GrpSearch_Vendorlookup', null)
    }
  }, [getData(thisObj, 'vendorDetails')]);

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    Formload()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  let Formload = async () => {
    setLoading(true)

    await bFillTypeList();
    bFillSeed();
    bFillSegmentList();
    bFillGeneration();
    bFillOleic();
    bFillCMADMA();
    bFilldeferred();
    bFillVarietyList('', '');
    await bFillBuyingPointList();
    disable(thisObj, 'btnOpen')
    hide(thisObj, 'txtVndr', false)
    hideColumn(thisObj,'gridGrpSearch','txtcolCurrentHolder')
    setValue(thisObj,'txtVendor','')
    setLoading(false)
  }

  // START_USER_CODE-USER_METHODS

  function ConvertToPascalCase(word) {
    return word.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
  }

  //seaching vendorname based on Vendor
  const bFillRemitToList = async (txtVendorNumber) => {
    try {
      let venname = null
      let vendornamevisible = false
      let response = await ContractManagementService.RetrieveVendorByNumber(txtVendorNumber)
      let data = response
      if (data !== null && data !== undefined && data.length > 0) {
        venname = ConvertToPascalCase(data[0].name)
        vendornamevisible = true
      }

      if (vendornamevisible == true) {
        // show(thisObj,'txtVendorName')
        // document.getElementsByClassName("txtVndr")[0].style.visibility = "visible";
        show(thisObj, 'txtVndr')
        setValue(thisObj, "txtVndr", venname)
      }
      else {
        // document.getElementsByClassName("txtVndr")[0].style.visibility = "hidden";
        hide(thisObj, 'txtVndr', false)
        setValue(thisObj, "txtVndr", '')
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred in setting value to Vendor Number textbox"
        );
      }
    }
  };

  const ontxtVendorBlur = () => {
    try {
      let txtVendorNumber = getValue(thisObj, 'txtVendor')

      if (txtVendorNumber.length == 6) {
        bFillRemitToList(txtVendorNumber)
      }
      else {
        setValue(thisObj, 'txtVndr', '')
        hide(thisObj, 'txtVndr',false)
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Vendor number change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtVendorBlur = ontxtVendorBlur;

  // peanut type
  const bFillTypeList = async () => {
    let js = [];
    js.push({ key: '', description: '>>> All Types <<<' }) //Set All as default value
    let response = await ContractManagementService.RetrievePeanutTypeControls(null)
    let data = response
    for (let i = 0; i < data.length; i++) {
      let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeName }
      js.push(obj)
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddPeanutType: {
          ...state["ddPeanutType"],
          valueList: js,
        }
      }
    })
    setValue(thisObj, 'ddPeanutType', js.at(0).key);
  }

  // Location
  const bFillBuyingPointList = async () => {
    let js = []
    let lstLocCmaVendor=[];
    js.push({ key: '', description: '>>>All Locations<<<' });
    let response = await ContractManagementService.RetrieveBuyingPointControlDetails('PN1010', null, null, null, null)
    let data = response
    for (let i = 0; i < data.length; i++) {
      let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + ConvertToPascalCase(data[i].buy_pt_name.trim())}
      lstLocCmaVendor.push({buy_pt_id:data[i].buy_pt_id,loccmavendor:data[i].default_cma_vendor_num})
      js.push(obj)
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddLocation: {
          ...state["ddLocation"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddLocation', js.at(0).key);
    setData(thisObj,'lstLocCmaVendor',lstLocCmaVendor)

  }
  // change event
  const onPeanutTypeChange = () => {
    try {
      let PeanutTypelist = thisObj.state['ddPeanutType'].valueList;
      let SelectedPeanutTypeValue = getValue(thisObj, "ddPeanutType", '')
      let pnut_type_id = PeanutTypelist.find(elem => elem.key === SelectedPeanutTypeValue).key;
      let BuyingPointList = thisObj.state['ddLocation'].valueList;
      let SelectedBuyingPointValue = getValue(thisObj, "ddLocation", '')
      let buyingPointId = null
      if (SelectedBuyingPointValue !== null && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
      }
      //Bind Peanut Variety based on Buying Point and Peanut Type
      setValue(thisObj, 'ddPeanutVariety', '')
      bFillVarietyList(pnut_type_id, buyingPointId)
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Peanut Type dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddPeanutTypeChange = onPeanutTypeChange;

  // seed
  const bFillSeed = () => {
    let js = []
    js.push({ key: '', description: '>>> Both Seeds <<<' })
    js.push({ key: 'Y', description: 'Seed' })
    js.push({ key: 'N', description: 'Non-Seed' })
    thisObj.setState(current => {
      return {
        ...current,
        ddSeed: {
          ...state["ddSeed"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddSeed', js.at(0).key);
  }
  // CMA/DMA
  const bFillCMADMA = () => {
    let js = []
    js.push({ key: '', description: '>>> All <<<' })
    js.push({ key: 'C', description: 'CMA' })
    js.push({ key: 'D', description: 'DMA' })
    js.push({ key: 'N', description: 'Non-CMA-DMA' })

    thisObj.setState(current => {
      return {
        ...current,
        ddCmaDma: {
          ...state["ddCmaDma"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddCmaDma', js.at(0).key);
  }
  // deferred
  const bFilldeferred = () => {
    let js = []
    js.push({ key: '', description: '>>> All <<<' })
    js.push({ key: 'Y', description: 'Deferred' })
    js.push({ key: 'N', description: 'Non-Deferred' })

    thisObj.setState(current => {
      return {
        ...current,
        ddDeferred: {
          ...state["ddDeferred"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddDeferred', js.at(0).key);
  }
  // seg type
  const bFillSegmentList = () => {
    let js = []
    js.push({ key: '', description: '>>> All Segments <<<' })
    js.push({ key: '1', description: 'Seg1' })
    js.push({ key: '2', description: 'Seg2' })
    js.push({ key: '3', description: 'Seg3' })

    thisObj.setState(current => {
      return {
        ...current,
        ddSegType: {
          ...state["ddSegType"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddSegType', js.at(0).key);
  }

  // Generation
  const bFillGeneration = () => {
    let js = []
    js.push({ key: '', description: '>>> All Generation <<<' })
    js.push({ key: 'F', description: 'Foundation' })
    js.push({ key: 'R', description: 'Registered' })
    js.push({ key: 'C', description: 'Certified' })

    thisObj.setState(current => {
      return {
        ...current,
        ddGeneration: {
          ...state["ddGeneration"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddGeneration', js.at(0).key);
  }
  // Oleic
  const bFillOleic = () => {
    let js = []
    js.push({ key: '', description: '>>> All Oleic <<<' })
    js.push({ key: 'H', description: 'High' })
    js.push({ key: 'M', description: 'Mid' })
    js.push({ key: 'N', description: 'None' })

    thisObj.setState(current => {
      return {
        ...current,
        ddOleic: {
          ...state["ddOleic"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddOleic', js.at(0).key);
  }

  // Binding the Peanut Variety as per the Peanut Type
  const bFillVarietyList = async (PeanutType, buy_pt_id) => {
    try {
      setLoading(true)
      let js = []
      let obj = {};
      obj = ({ key: '', description: '>>> All Varieties <<<' });
      js.push(obj)
      obj = {};
      if (PeanutType != '') {
        let response = await ContractManagementService.RetrievePeanutVarietyControls(buy_pt_id, null, null, PeanutType, null)
        let data = response;

        if (data !== null && data !== undefined && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            obj = {
              key: data[i].pnut_variety_id,
              description: data[i].pnut_variety_name
            }
            js.push(obj)
            obj = {}
          }
        }
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutVariety: {
            ...state["ddPeanutVariety"],
            valueList: js,
          }
        }
      })
      setValue(thisObj, 'ddPeanutVariety', js.at(0).key);
      setLoading(false)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during loading Peanut Varieties"
        );
      }
    }
  }

  const onbtnSearchClick = async(noSearch)=>{
    try{
      let groupingNumber=getValue(thisObj,'txtGrouping')==undefined?'':getValue(thisObj,'txtGrouping')
      let vendorNumber=getValue(thisObj,'txtVendor')==undefined?'':getValue(thisObj,'txtVendor')
      let location=getValue(thisObj,'ddLocation')==undefined?'':getValue(thisObj,'ddLocation')
      let txt1007=getValue(thisObj,'txt1007')==undefined?'':getValue(thisObj,'txt1007')
      let txtFV95=getValue(thisObj,'txtSC95')==undefined?'':getValue(thisObj,'txtSC95')
      let farmNumber=getValue(thisObj,'txtFarm')==undefined?'':getValue(thisObj,'txtFarm')
      let farmSuffix=getValue(thisObj,'txtFarmSuffix')==undefined?'':getValue(thisObj,'txtFarmSuffix')
      
      if(groupingNumber=='' && vendorNumber=='' && location=='' && txt1007=='' && txtFV95=='' && farmNumber=='' && farmSuffix==''){
        if(noSearch==1){ return;}
        alert('Grouping#, Vendor#, Location#, 1007#, SC95#, Contract#, Farm#, Farm Suffix# must be specified for searches')
        return;
      }
      await DoSearch() 
    }
    catch(err){
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      }
      else {
        showMessage(
          thisObj,
          "Error occurred during Search button Click"
        );
      }
    }
  }
  thisObj.onbtnSearchClick=onbtnSearchClick

  let DoSearch=async()=>{
    try{
    setLoading(true)
    let purch_store_ind='S'
    let settlement_num=getValue(thisObj,'txt1007')
    let buy_pt_id=getValue(thisObj,'ddLocation')
    let insp_num=getValue(thisObj,'txtSC95')
    let split_vendor=getValue(thisObj,'txtVendor')
    let SETTLEMENT_GROUPING_NUM=getValue(thisObj,'txtGrouping')
    let farm_id=getValue(thisObj,'txtFarm')
    let farm_suffix=getValue(thisObj,'txtFarmSuffix')
    let pnut_type_id=getValue(thisObj,'ddPeanutType')
    let PNUT_VARIETY_ID=getValue(thisObj,'ddPeanutVariety')
    let seg_type=getValue(thisObj,'ddSegType')
    let OLEIC_IND=getValue(thisObj,'ddOleic')
    let SEED_GEN=getValue(thisObj,'ddGeneration')
    let SEED_RCPT=getValue(thisObj,'ddSeed')
    let SETTLE_GRP_CMA_DMA_IND=getValue(thisObj,'ddCmaDma')
    let SETTLE_GRP_DEFERRED_IND=getValue(thisObj,'ddDeferred')
    let compId = (sessionStorage.getItem('compId'))

    let response =await WarehouseReceiptService.Retrieve1007GroupList(compId,cropYear,buy_pt_id,insp_num,settlement_num,pnut_type_id,PNUT_VARIETY_ID,farm_suffix,farm_id,null,SEED_RCPT,SEED_GEN,seg_type,OLEIC_IND,null,null,purch_store_ind,null,null,null,split_vendor,null,null,null,SETTLEMENT_GROUPING_NUM,null,SETTLE_GRP_CMA_DMA_IND,SETTLE_GRP_DEFERRED_IND);
    let data=response;
    data.length>0? enable(thisObj,'btnOpen'): disable(thisObj,'btnOpen')

    if(data!=null && data!=undefined && data!=''){
      let obj={};
      let TransformedRowsArray=[];

      for(let i=0;i<data.length;i++){
        obj.txtcolGRP=data[i].settlement_grouping_num
        obj.txtcol1007=data[i].settlement_num
        obj.txtcolObligationPoint=data[i].obl_pt_name
        obj.txtcolFarm=data[i].farm_name
        obj.txtcolSC95=data[i].insp_num
        obj.txtcolInspDate=data[i].insp_date_time
        obj.txtcolBuyPt=data[i].buy_pt_id
        obj.txtcolContract=data[i].contract_num
        obj.txtcolCmaDma=data[i].settle_grp_cma_dma_ind
        obj.txtcolDeferred=data[i].settle_grp_deferred_ind
        obj.txtcolGrpDate=data[i].settle_grp_date
        obj.txtcolCurrentHolder=data[i].settle_grp_holder_id

        TransformedRowsArray.push(obj)
        obj={};
      }
      setValue(thisObj,'gridGrpSearch',TransformedRowsArray)
      setLoading(false)
    }
    else{
      setValue(thisObj,'gridGrpSearch',[])
      setLoading(false)
    }
    if (data.length == 1) {
      setSelectedRowNum(0);
      data[0].isSelected = true;
    }
    else if(data.length >0){
      setSelectedRowNum(0)
    }
    }
    catch (err) {
      setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred on Do Search function"
        );
      }
      return false;
    }
  }

  let onbtnOpenClick=()=>{
    try{
    let gridValue = thisObj.state.gridGrpSearch.selected[0];
      if(gridValue==undefined){
        showMessage(thisObj, 'Please select a row to Open');
        return;
      }
      let buyingPointId=getValue(thisObj,'ddLocation')
      let LocCmaVendorArray=getData(thisObj,'lstLocCmaVendor')
      let LocCmaVendor=LocCmaVendorArray.find(elem=>elem.buy_pt_id==buyingPointId)
      if(LocCmaVendor!=undefined && LocCmaVendor!=null && LocCmaVendor!=''){
        LocCmaVendor=LocCmaVendor.loccmavendor
      }
      else{
        LocCmaVendor=''
      }
    let frm1007GroupingProfileLoadData={};
    let openBtnDataArray=[]
    let buyPtVisible=true;
    let gridData=getValue(thisObj,'gridGrpSearch')
    if(gridData.length>0){
    
    let rowNumber=getSelectedRowNumber(thisObj,'gridGrpSearch');
    let selectedRowValue=getGridValue(thisObj,'gridGrpSearch','txtcolGRP',rowNumber)
    
    for(let i=0;i<gridData.length;i++){
      let row=(i).toString()
      let GRPNumber=getGridValue(thisObj,'gridGrpSearch','txtcolGRP',row)
      if(GRPNumber==selectedRowValue){

        frm1007GroupingProfileLoadData.SettlementGroupingNumber=selectedRowValue
        if(buyPtVisible){
          frm1007GroupingProfileLoadData.buy_pt_idvisible=buyPtVisible
        }
        else{
          frm1007GroupingProfileLoadData.buy_pt_idvisible=false
        }
        buyPtVisible=false
        let grpDate=getGridValue(thisObj,'gridGrpSearch','txtcolGrpDate',row)
        frm1007GroupingProfileLoadData.grpdate=grpDate

        let cmaDma=getGridValue(thisObj,'gridGrpSearch','txtcolCmaDma',row)
        frm1007GroupingProfileLoadData.CMADMA=cmaDma

        let deferred=getGridValue(thisObj,'gridGrpSearch','txtcolDeferred',row)
        frm1007GroupingProfileLoadData.DeferredRedemption=deferred

        let txt1007Number=getGridValue(thisObj,'gridGrpSearch','txtcol1007',row)
        frm1007GroupingProfileLoadData.g1007Number=txt1007Number

        let buyingPointId=getGridValue(thisObj,'gridGrpSearch','txtcolBuyPt',row)
        frm1007GroupingProfileLoadData.buy_pt_id=buyingPointId

        let contract_num=getGridValue(thisObj,'gridGrpSearch','txtcolContract',row)
        if(contract_num=='0'){
          frm1007GroupingProfileLoadData.ContNum_Visible=false
        }
        else{
          frm1007GroupingProfileLoadData.ContNum=contract_num
        }

        let holderNumber=getGridValue(thisObj,'gridGrpSearch','txtcolCurrentHolder',row)
        frm1007GroupingProfileLoadData.Holder=holderNumber

        frm1007GroupingProfileLoadData.loccmavendor=LocCmaVendor

        openBtnDataArray.push(frm1007GroupingProfileLoadData)
        frm1007GroupingProfileLoadData={};
      }
    }
  }
  setData(thisObj,'openButtonData',openBtnDataArray)
  goTo(thisObj,"WarehouseReceipts#1007GroupingProfile#DEFAULT#true#Click")
}
  catch(err){
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    }
    else {
      showMessage(
        thisObj,
        "Error occurred during Open button result"
      );
    }
    return false;
  }
  }
  thisObj.onbtnOpenClick=onbtnOpenClick

  let onbtnCreate1007GroupingClick=()=>{
    goTo(thisObj,"WarehouseReceipts#1007GrpSelect#DEFAULT#true#Click")
  }
  thisObj.onbtnCreate1007GroupingClick=onbtnCreate1007GroupingClick

  let onbtnExportToExcelClick=()=>{
    try{
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let datatable = getValue(thisObj,'gridGrpSearch');
    let fileName = "1007 Group Search";
    let excelData=[];
    if(datatable!=undefined){
      for(let i=0;i<datatable.length;i++){
        let rowdata={};
        if (thisObj.state.gridGrpSearch.columns[0].Visible) {
          rowdata["GRP#"] = ExcelNumericDataConvert(datatable[i].txtcolGRP);
        }
        if (thisObj.state.gridGrpSearch.columns[1].Visible) {
          rowdata["1007#"] = ExcelNumericDataConvert(datatable[i].txtcol1007);
        }
        if (thisObj.state.gridGrpSearch.columns[2].Visible) {
          rowdata["Obligation Point"] = datatable[i].txtcolObligationPoint;
        }
        if (thisObj.state.gridGrpSearch.columns[3].Visible) {
          rowdata["Farm"] = datatable[i].txtcolFarm;
        }
        if (thisObj.state.gridGrpSearch.columns[4].Visible) {
          rowdata["SC95#"] = datatable[i].txtcolSC95;
        }
        if (thisObj.state.gridGrpSearch.columns[5].Visible) {
          rowdata["Insp. Date"] = datatable[i].txtcolInspDate.slice(0,-2);
        }
        if (thisObj.state.gridGrpSearch.columns[6].Visible) {
          rowdata["Buy Pt."] = datatable[i].txtcolBuyPt;
        }
        if (thisObj.state.gridGrpSearch.columns[7].Visible) {
          rowdata["Contract"] = ExcelNumericDataConvert(datatable[i].txtcolContract);
        }
        if (thisObj.state.gridGrpSearch.columns[8].Visible) {
          rowdata["CMA/DMA"] = datatable[i].txtcolCmaDma;
        }
        if (thisObj.state.gridGrpSearch.columns[9].Visible) {
          rowdata["Deferred"] = datatable[i].txtcolDeferred;
        }
        if (thisObj.state.gridGrpSearch.columns[10].Visible) {
          if(datatable[i].txtcolGrpDate.length <= 10){
            rowdata["Grp. Date"] = datatable[i].txtcolGrpDate + " 00:00:00"
          }
          else{
          rowdata["Grp. Date"] = datatable[i].txtcolGrpDate.slice(0,-2);
          }
        }

        if (thisObj.state.gridGrpSearch.columns[11].Visible) {
          rowdata["Current Holder ID"] = datatable[i].txtcolCurrentHolder;
        }
        excelData.push(rowdata)
      }
    }
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    var FileSaver = require('file-saver');
    FileSaver.saveAs(data, fileName + fileExtension);
  }
  catch (err) {
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Error Occurred during the Export to Excel Button Click"
      );
    }
    return false;
  }
  }
  thisObj.onbtnExportToExcelClick=onbtnExportToExcelClick

  let onbtnPrintSearchClick=()=>{
    try{
    let data = getValue(thisObj, "gridGrpSearch")
    let res = []
    let headerArray = []

    if (thisObj.state.gridGrpSearch.columns[0].Visible) {
      headerArray.push("GRP#");
    }
    if (thisObj.state.gridGrpSearch.columns[1].Visible) {
      headerArray.push("1007#");
    }
    if (thisObj.state.gridGrpSearch.columns[2].Visible) {
      headerArray.push("Obligation Point");
    }
    if (thisObj.state.gridGrpSearch.columns[3].Visible) {
      headerArray.push("Farm");
    }
    if (thisObj.state.gridGrpSearch.columns[4].Visible) {
      headerArray.push("SC95#");
    }
    if (thisObj.state.gridGrpSearch.columns[5].Visible) {
      headerArray.push("Insp. Date");
    }
    if (thisObj.state.gridGrpSearch.columns[6].Visible) {
      headerArray.push("Buy Pt.");
    }
    if (thisObj.state.gridGrpSearch.columns[7].Visible) {
      headerArray.push("Contract");
    }
    if (thisObj.state.gridGrpSearch.columns[8].Visible) {
      headerArray.push("CMA/DMA");
    }
    if (thisObj.state.gridGrpSearch.columns[9].Visible) {
      headerArray.push("Deferred");
    }
    if (thisObj.state.gridGrpSearch.columns[10].Visible) {
      headerArray.push("Grp. Date");
    }

    if (thisObj.state.gridGrpSearch.columns[11].Visible) {
      headerArray.push("Current Holder ID");
    }

    for(let i=0;i<data.length;i++){
      let NewArray=[];
      if (thisObj.state.gridGrpSearch.columns[0].Visible) {
        NewArray.push(data[i].txtcolGRP);
      }
      if (thisObj.state.gridGrpSearch.columns[1].Visible) {
        NewArray.push(data[i].txtcol1007);
      }
      if (thisObj.state.gridGrpSearch.columns[2].Visible) {
        NewArray.push(data[i].txtcolObligationPoint);
      }
      if (thisObj.state.gridGrpSearch.columns[3].Visible) {
        NewArray.push(data[i].txtcolFarm);
      }
      if (thisObj.state.gridGrpSearch.columns[4].Visible) {
        NewArray.push(data[i].txtcolSC95);
      }
      if (thisObj.state.gridGrpSearch.columns[5].Visible) {
        NewArray.push(data[i].txtcolInspDate);
      }
      if (thisObj.state.gridGrpSearch.columns[6].Visible) {
        NewArray.push(data[i].txtcolBuyPt);
      }
      if (thisObj.state.gridGrpSearch.columns[7].Visible) {
        NewArray.push(data[i].txtcolContract);
      }
      if (thisObj.state.gridGrpSearch.columns[8].Visible) {
        NewArray.push(data[i].txtcolCmaDma);
      }
      if (thisObj.state.gridGrpSearch.columns[9].Visible) {
        NewArray.push(data[i].txtcolDeferred);
      }
      if (thisObj.state.gridGrpSearch.columns[10].Visible) {
        NewArray.push(data[i].txtcolGrpDate);
      }

      if (thisObj.state.gridGrpSearch.columns[11].Visible) {
        NewArray.push(data[i].txtcolCurrentHolder);
      }

      res.push(NewArray)
    }
    //index of columns which requires right alignments
    let aligncolumn1 = headerArray.indexOf("GRP#");
    let aligncolumn2 = headerArray.indexOf("1007#");
    let aligncolumn3 = headerArray.indexOf("Obligation Point");

    let obj = new jsPDF();
      obj.autoTable({
        startY: 5,
        startX: 20,
        styles: { fontSize: 6, lineWidth: 0.2, color: 10 },
        body: res,
        theme: 'plain',
        fontStyle: 'normal',
        head: [headerArray],

        didParseCell: function (res) {

          if ((aligncolumn1 == res.column.index || aligncolumn2 == res.column.index || aligncolumn3 == res.column.index ) && res.section !== "head") {
            res.cell.styles.halign = "left"
          } else {
            res.cell.styles.halign = "center"
          }
        },
      
      });

      let pageCount = obj.internal.getNumberOfPages();
      for (let w = 0; w <= pageCount; w++) {
        obj.setPage(w);
        let pageCurrent = obj.internal.getCurrentPageInfo().pageNumber; //Current Page
        obj.setFontSize(8);
       obj.text(cropYear+ '  1007 Group Search Results', 2, obj.internal.pageSize.height - 2);
      }

      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj.save(fileName + '.pdf')
        }
        else {
          alert("Routine:1007GroupSearch.cmdPrintSearch_Click")
        }
      }
      else {
        alert("Routine:1007GroupSearch.cmdPrintSearch_Click")
      }
  }
  catch(err){
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Error Occurred in Print Button"
      );
    }
    return false;
  }
  }
  thisObj.onbtnPrintSearchClick=onbtnPrintSearchClick

  let onbtnVendorClick=()=>{
    setData(thisObj, '1007GrpSearch_Vendorlookup', true)
    goTo(thisObj,'ContractManagement#VendorLookup#DEFAULT#true#Click')
  }
  thisObj.onbtnVendorClick=onbtnVendorClick

  let ontxtFarmBlur=()=>{
    let farmText=getValue(thisObj,'txtFarm')
    if(farmText=='' || farmText==undefined || farmText==null){
      return;
    }
    let finalAnswer='';
    let textLength;
    if(farmText.length<7){
      textLength=7-(farmText.length)
    }
    else{
      textLength=7;
    }
    for(let i=0;i<textLength;i++){
      finalAnswer+='0'
    }
    if(textLength!=7){
    setValue(thisObj,'txtFarm',finalAnswer+farmText)
    }
  }
  thisObj.ontxtFarmBlur=ontxtFarmBlur

  let ontxtFarmSuffixBlur=()=>{
    let farmText=getValue(thisObj,'txtFarm')
    if(farmText=='' || farmText==undefined || farmText==null){
      return;
    }

    let farmSuffixText=getValue(thisObj,'txtFarmSuffix')
    if(farmSuffixText=='' || farmSuffixText==undefined || farmSuffixText==null){
      setValue(thisObj,'txtFarmSuffix','000')
      return;
    }
    let finalAnswer='';
    let textLength;
    if(farmSuffixText.length<3){
      textLength=3-(farmSuffixText.length)
    }
    else{
      textLength=3;
    }
    for(let i=0;i<textLength;i++){
      finalAnswer+='0'
    }
    if(textLength!=3){
    setValue(thisObj,'txtFarmSuffix',finalAnswer+farmSuffixText)
    }
  }
  thisObj.ontxtFarmSuffixBlur=ontxtFarmSuffixBlur

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_1007GrpSearch*/}

              {/* END_USER_CODE-USER_BEFORE_1007GrpSearch*/}

              {/* START_USER_CODE-USER_BEFORE_grpbx1007GrpSearch*/}

              {/* END_USER_CODE-USER_BEFORE_grpbx1007GrpSearch*/}

              <GroupBoxWidget
                conf={state.grpbx1007GrpSearch}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxGrpSearch1*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxGrpSearch1*/}

                <GroupBoxWidget conf={state.grpbxGrpSearch1} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtGrouping*/}

                  {/* END_USER_CODE-USER_BEFORE_txtGrouping*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtGrouping}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtGrouping*/}

                  {/* END_USER_CODE-USER_AFTER_txtGrouping*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendor}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                  <ButtonWidget
                    conf={state.btnVendor}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                  {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVndr*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVndr*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVndr}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVndr*/}

                  {/* END_USER_CODE-USER_AFTER_txtVndr*/}
                  {/* START_USER_CODE-USER_BEFORE_ddLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_ddLocation*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddLocation}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddLocation*/}

                  {/* END_USER_CODE-USER_AFTER_ddLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_txt1007*/}

                  {/* END_USER_CODE-USER_BEFORE_txt1007*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txt1007}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txt1007*/}

                  {/* END_USER_CODE-USER_AFTER_txt1007*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSC95*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSC95*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSC95}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSC95*/}

                  {/* END_USER_CODE-USER_AFTER_txtSC95*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxGrpSearch1*/}

                {/* END_USER_CODE-USER_AFTER_grpbxGrpSearch1*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxGrpSearch2*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxGrpSearch2*/}

                <GroupBoxWidget conf={state.grpbxGrpSearch2} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddPeanutType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                  {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                  {/* START_USER_CODE-USER_BEFORE_ddSeed*/}

                  {/* END_USER_CODE-USER_BEFORE_ddSeed*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddSeed}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddSeed*/}

                  {/* END_USER_CODE-USER_AFTER_ddSeed*/}
                  {/* START_USER_CODE-USER_BEFORE_ddSegType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddSegType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddSegType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddSegType*/}

                  {/* END_USER_CODE-USER_AFTER_ddSegType*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFarm*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFarm*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFarm}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFarm*/}

                  {/* END_USER_CODE-USER_AFTER_txtFarm*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFarmSuffix*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFarmSuffix*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFarmSuffix}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFarmSuffix*/}

                  {/* END_USER_CODE-USER_AFTER_txtFarmSuffix*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                  <ButtonWidget
                    conf={state.btnSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxGrpSearch2*/}

                {/* END_USER_CODE-USER_AFTER_grpbxGrpSearch2*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxgGrpSearch3*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxgGrpSearch3*/}

                <GroupBoxWidget
                  conf={state.grpbxgGrpSearch3}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                  {/* END_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddPeanutVariety}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddPeanutVariety*/}

                  {/* END_USER_CODE-USER_AFTER_ddPeanutVariety*/}
                  {/* START_USER_CODE-USER_BEFORE_ddGeneration*/}

                  {/* END_USER_CODE-USER_BEFORE_ddGeneration*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddGeneration}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddGeneration*/}

                  {/* END_USER_CODE-USER_AFTER_ddGeneration*/}
                  {/* START_USER_CODE-USER_BEFORE_ddOleic*/}

                  {/* END_USER_CODE-USER_BEFORE_ddOleic*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddOleic}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddOleic*/}

                  {/* END_USER_CODE-USER_AFTER_ddOleic*/}
                  {/* START_USER_CODE-USER_BEFORE_ddCmaDma*/}

                  {/* END_USER_CODE-USER_BEFORE_ddCmaDma*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCmaDma}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddCmaDma*/}

                  {/* END_USER_CODE-USER_AFTER_ddCmaDma*/}
                  {/* START_USER_CODE-USER_BEFORE_ddDeferred*/}

                  {/* END_USER_CODE-USER_BEFORE_ddDeferred*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDeferred}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddDeferred*/}

                  {/* END_USER_CODE-USER_AFTER_ddDeferred*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxgGrpSearch3*/}

                {/* END_USER_CODE-USER_AFTER_grpbxgGrpSearch3*/}
                {/* START_USER_CODE-USER_BEFORE_gpxbxGrpSearch4*/}

                {/* END_USER_CODE-USER_BEFORE_gpxbxGrpSearch4*/}

                <GroupBoxWidget conf={state.gpxbxGrpSearch4} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_btnCreate1007Grouping*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCreate1007Grouping*/}

                  <ButtonWidget
                    conf={state.btnCreate1007Grouping}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCreate1007Grouping*/}

                  {/* END_USER_CODE-USER_AFTER_btnCreate1007Grouping*/}
                  {/* START_USER_CODE-USER_BEFORE_btnOpen*/}

                  {/* END_USER_CODE-USER_BEFORE_btnOpen*/}

                  <ButtonWidget
                    conf={state.btnOpen}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnOpen*/}

                  {/* END_USER_CODE-USER_AFTER_btnOpen*/}
                  {/* START_USER_CODE-USER_BEFORE_gridGrpSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_gridGrpSearch*/}

                  <GridWidget
                    conf={state.gridGrpSearch}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridGrpSearch}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridGrpSearch*/}

                  {/* END_USER_CODE-USER_AFTER_gridGrpSearch*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_gpxbxGrpSearch4*/}

                {/* END_USER_CODE-USER_AFTER_gpxbxGrpSearch4*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbx1007GrpSearch*/}

              {/* END_USER_CODE-USER_AFTER_grpbx1007GrpSearch*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                <ButtonWidget
                  conf={state.btnExportToExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrintSearch*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrintSearch*/}

                <ButtonWidget
                  conf={state.btnPrintSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrintSearch*/}

                {/* END_USER_CODE-USER_AFTER_btnPrintSearch*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_1007GrpSearch*/}

              {/* END_USER_CODE-USER_AFTER_1007GrpSearch*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_1007GrpSearch);

