/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  DateWidget,
  LabelWidget,
  CheckboxWidget,
  TextAreaWidget,
  setValue,
  disable,
  enable,
  setData,
  getData,
  getValue,
  goTo,
  hide,
  show,
  setLabel,
  setFieldValues,
  hideWidgets,
  showWidgets
} from "../../shared/WindowImports";

import "./ViewSettlement.scss";

import Loading from "../../../Loader/Loading";
import CommonContext from "../../Store/CommonContext";
import ContractManagement_header from "../../Header/Header";
import { SettlementService } from "../Service/SettlementService";
import { AccountPayment } from "../../AccountPayment/Service/AccountPayment";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import moment from "moment";
import StripPic from "../../../../assets/img/PinStrip.png";
function Settlements_ViewSettlement(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  const compIdFromLS = () => (sessionStorage.getItem('compId'));
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');
  const commonContext = useContext(CommonContext);

  let mbSuccessfulLoad = false;
  let mbFormFilling = false;

  let sScaleTickets = '';
  let mbTicket_NUMBERS_CURE_CLEAN = '';
  let mbTicket_CURING_WT_IND = '';
  let mbTicket_CLEANING_WT_IND = '';
  let mbTicket_MOISTURE = 0;
  let mbTicket_CLEAN_WT_OTHER = 0;
  let mbTicket_CURE_WT_OTHER = 0;
  let mbTicket_CURE_SCHEDULE = '';
  let mbTicket_CLEAN_SCHEDULE = '';
  let mbTicket_TOTAL_CURING_COST = 0;
  let mbTicket_TOTAL_CLEANING_COST = 0;
  let mbSupersedePending = false;
  let strHMC = '';
  let lstChangeDateTime;
  let scaleTicketData = [];

  let states = {
    Label: "ViewSettlement",
    windowName: "ViewSettlement",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.ViewSettlement",
    horizontalForm: true,
    headerData: {
      scrName: "View Settlement",
      scrCode: "PN1060B",
    },
    btnAcctDist: {
      name: "btnAcctDist",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Acct Dist",
      CharWidth: "21",
    },
    btnApplications: {
      name: "btnApplications",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Applications",
      CharWidth: "26",
    },
    btnCalculate: {
      name: "btnCalculate",
      type: "ButtonWidget",
      parent: "grpbxValueCalculation",
      Label: "Calculate",
      CharWidth: "20",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Close",
      CharWidth: "13",
    },
    btnCorrection: {
      name: "btnCorrection",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Correction",
      CharWidth: "22",
    },
    btnCorrectionLog: {
      name: "btnCorrectionLog",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Correction Log",
      CharWidth: "30",
    },
    btnCorrectionReprint: {
      name: "btnCorrectionReprint",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Correction Reprint",
      CharWidth: "37",
    },
    btnDeductTrack: {
      name: "btnDeductTrack",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Deduct Track",
      CharWidth: "26",
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxWeightTickets",
      Label: "Inquiry",
      CharWidth: "17",
      ofTypeDomain: "d_boolean",
    },
    btnPayments: {
      name: "btnPayments",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Payments",
      CharWidth: "19",
    },
    btnPremsDeduct: {
      name: "btnPremsDeduct",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Prems/Deduct",
      CharWidth: "26",
    },
    btnReprint: {
      name: "btnReprint",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Reprint",
      CharWidth: "17",
    },
    btnResendToKCMO: {
      name: "btnResendToKCMO",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Resend to KCMO",
      CharWidth: "31",
    },
    btnSupersede: {
      name: "btnSupersede",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Supersede",
      CharWidth: "20",
    },
    btnVoid: {
      name: "btnVoid",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Void",
      CharWidth: "11",
    },
    btnWorksheet: {
      name: "btnWorksheet",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Worksheet",
      CharWidth: "20",
    },
    btnCureClean: {
      name: "btnCureClean",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "CureClean",
      CharWidth: "20",
    },
    chkboxCornFound: {
      name: "chkboxCornFound",
      type: "CheckBoxWidget",
      parent: "grpbxGradeCalculation",
      ColSpan: "2",
      HasLabel: false,
      ofTypeDomain: "d_boolean",
    },
    chkboxFlavusFound: {
      name: "chkboxFlavusFound",
      type: "CheckBoxWidget",
      parent: "grpbxGradeCalculation",
      Enabled: false,
      HasLabel: false,
      ofTypeDomain: "d_boolean",
    },
    lblVirginiaGrading: {
      name: "lblVirginiaGrading",
      type: "LabelWidget",
      parent: "grpbxVirginiaGrading",
      Label: "Virginia Cracked/Broken grading",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVirginiaGrading_PROPERTIES

      // END_USER_CODE-USER_lblVirginiaGrading_PROPERTIES
    },
    txtGrams: {
      name: "txtGrams",
      type: "TextBoxWidget",
      parent: "grpbxVirginiaGrading",
      Label: "Grams:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGrams_PROPERTIES

      // END_USER_CODE-USER_txtGrams_PROPERTIES
    },
    txtPercent: {
      name: "txtPercent",
      type: "TextBoxWidget",
      parent: "grpbxVirginiaGrading",
      Label: "Percent:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPercent_PROPERTIES

      // END_USER_CODE-USER_txtPercent_PROPERTIES
    },
    txtSampleWt: {
      name: "txtSampleWt",
      type: "TextBoxWidget",
      parent: "grpbxVirginiaGrading",
      Label: "Sample Wt:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSampleWt_PROPERTIES

      // END_USER_CODE-USER_txtSampleWt_PROPERTIES
    },
    grpbxVirginiaGrading: {
      name: "grpbxVirginiaGrading",
      type: "GroupBoxWidget",
      parent: "grpbxGradeCalculation",
      ColSpan: "4",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxVirginiaGrading_PROPERTIES

      // END_USER_CODE-USER_grpbxVirginiaGrading_PROPERTIES
    },
    chkboxOrganic: {
      name: "chkboxOrganic",
      type: "CheckBoxWidget",
      parent: "grpbxPeanutInfouic0",
      Label: "Organic",
      HasLabel: false,
      ofTypeDomain: "d_boolean",
    },
    chkboxVicam: {
      name: "chkboxVicam",
      type: "CheckBoxWidget",
      parent: "grpbxPeanutInfouic0",
      Label: "Vicam",
      HasLabel: false,
      ofTypeDomain: "d_boolean",
    },
    colDate: {
      name: "colDate",
      type: "GridColumnWidget",
      parent: "gridWeightTickets",
      Label: "Date",
      Height: "",
      Width: "",
    },
    colScaleTicket: {
      name: "colScaleTicket",
      type: "GridColumnWidget",
      parent: "gridWeightTickets",
      Label: "Scale Ticket #",
      Height: "",
      Width: "",
    },
    colWtOfPeanuts: {
      name: "colWtOfPeanuts",
      type: "GridColumnWidget",
      parent: "gridWeightTickets",
      Label: "Wt. of Peanuts",
      Height: "",
      Width: "",
    },
    txtBuyingPt: {
      name: "txtBuyingPt",
      type: "TextBoxWidget",
      parent: "grpbxPeanutInfouic0",
      Label: "Buying Pt:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtEdibleOil: {
      name: "txtEdibleOil",
      type: "TextBoxWidget",
      parent: "grpbxPeanutInfouic0",
      Label: "Edible Oil:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtObligationPt: {
      name: "txtObligationPt",
      type: "TextBoxWidget",
      parent: "grpbxPeanutInfouic0",
      Label: "Whouse / Bin #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtOleic: {
      name: "txtOleic",
      type: "TextBoxWidget",
      parent: "grpbxPeanutInfouic0",
      Label: "Oleic:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtPeanutType: {
      name: "txtPeanutType",
      type: "TextBoxWidget",
      parent: "grpbxPeanutInfouic0",
      Label: "Peanut Type:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtPeanutVariety: {
      name: "txtPeanutVariety",
      type: "TextBoxWidget",
      parent: "grpbxPeanutInfouic0",
      Label: "Peanut Variety:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSeedGen: {
      name: "txtSeedGen",
      type: "TextBoxWidget",
      parent: "grpbxPeanutInfouic0",
      Label: "Seed Generation:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSeg1: {
      name: "txtSeg1",
      type: "TextBoxWidget",
      parent: "grpbxPeanutInfouic0",
      Label: "Seg:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtWhouseBin: {
      name: "txtWhouseBin",
      type: "TextBoxWidget",
      parent: "grpbxPeanutInfouic0",
      HasLabel: false,
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    ddWhseRcpt0: {
      name: "ddWhseRcpt0",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow0",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      ofTypeDomain: "d_String",
    },
    ddWhseRcpt1: {
      name: "ddWhseRcpt1",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow1",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWhseRcpt1_PROPERTIES

      // END_USER_CODE-USER_ddWhseRcpt1_PROPERTIES
    },
    ddWhseRcpt10: {
      name: "ddWhseRcpt10",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow10",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWhseRcpt10_PROPERTIES

      // END_USER_CODE-USER_ddWhseRcpt10_PROPERTIES
    },
    ddWhseRcpt11: {
      name: "ddWhseRcpt11",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow11",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWhseRcpt11_PROPERTIES

      // END_USER_CODE-USER_ddWhseRcpt11_PROPERTIES
    },
    ddWhseRcpt12: {
      name: "ddWhseRcpt12",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow12",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWhseRcpt12_PROPERTIES

      // END_USER_CODE-USER_ddWhseRcpt12_PROPERTIES
    },
    ddWhseRcpt13: {
      name: "ddWhseRcpt13",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow13",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWhseRcpt13_PROPERTIES

      // END_USER_CODE-USER_ddWhseRcpt13_PROPERTIES
    },
    ddWhseRcpt14: {
      name: "ddWhseRcpt14",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow14",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWhseRcpt14_PROPERTIES

      // END_USER_CODE-USER_ddWhseRcpt14_PROPERTIES
    },
    ddWhseRcpt15: {
      name: "ddWhseRcpt15",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow15",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWhseRcpt15_PROPERTIES

      // END_USER_CODE-USER_ddWhseRcpt15_PROPERTIES
    },
    ddWhseRcpt16: {
      name: "ddWhseRcpt16",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow16",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWhseRcpt16_PROPERTIES

      // END_USER_CODE-USER_ddWhseRcpt16_PROPERTIES
    },
    ddWhseRcpt17: {
      name: "ddWhseRcpt17",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow17",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWhseRcpt17_PROPERTIES

      // END_USER_CODE-USER_ddWhseRcpt17_PROPERTIES
    },
    ddWhseRcpt18: {
      name: "ddWhseRcpt18",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow18",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWhseRcpt18_PROPERTIES

      // END_USER_CODE-USER_ddWhseRcpt18_PROPERTIES
    },
    ddWhseRcpt19: {
      name: "ddWhseRcpt19",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow19",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWhseRcpt19_PROPERTIES

      // END_USER_CODE-USER_ddWhseRcpt19_PROPERTIES
    },
    ddWhseRcpt2: {
      name: "ddWhseRcpt2",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      ofTypeDomain: "d_String",
    },
    ddWhseRcpt3: {
      name: "ddWhseRcpt3",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow3",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWhseRcpt3_PROPERTIES

      // END_USER_CODE-USER_ddWhseRcpt3_PROPERTIES
    },
    ddWhseRcpt4: {
      name: "ddWhseRcpt4",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow4",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWhseRcpt4_PROPERTIES

      // END_USER_CODE-USER_ddWhseRcpt4_PROPERTIES
    },
    ddWhseRcpt5: {
      name: "ddWhseRcpt5",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow5",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWhseRcpt5_PROPERTIES

      // END_USER_CODE-USER_ddWhseRcpt5_PROPERTIES
    },
    ddWhseRcpt6: {
      name: "ddWhseRcpt6",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow6",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWhseRcpt6_PROPERTIES

      // END_USER_CODE-USER_ddWhseRcpt6_PROPERTIES
    },
    ddWhseRcpt7: {
      name: "ddWhseRcpt7",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow7",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWhseRcpt7_PROPERTIES

      // END_USER_CODE-USER_ddWhseRcpt7_PROPERTIES
    },
    ddWhseRcpt8: {
      name: "ddWhseRcpt8",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow8",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWhseRcpt8_PROPERTIES

      // END_USER_CODE-USER_ddWhseRcpt8_PROPERTIES
    },
    ddWhseRcpt9: {
      name: "ddWhseRcpt9",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorSplitRow9",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWhseRcpt9_PROPERTIES

      // END_USER_CODE-USER_ddWhseRcpt9_PROPERTIES
    },
    gridWeightTickets: {
      name: "gridWeightTickets",
      type: "GridWidget",
      parent: "grpbxWeightTickets",
      gridCellsOrder: "txtcolScaleTicket,txtcolDate,txtcolWtOfPeanuts",
      ColSpan: "6",
      Pagination: false,
      HasLabel: false,
      Cols: "6",
      Height: "",
      Width: "",
    },
    grpbxPurchases: {
      name: "grpbxPurchases",
      type: "GroupBoxWidget",
      parent: "grpbxPrchsStrgWtCert",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
    },
    grpbxStorage: {
      name: "grpbxStorage",
      type: "GroupBoxWidget",
      parent: "grpbxPrchsStrgWtCert",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
    },
    grpbxVendorSplitRow0: {
      name: "grpbxVendorSplitRow0",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      clonedExtId: "56358",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
    },
    grpbxVendorSplitRow1: {
      name: "grpbxVendorSplitRow1",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
    },
    grpbxVendorSplitRow10: {
      name: "grpbxVendorSplitRow10",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow10_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow10_PROPERTIES
    },
    grpbxVendorSplitRow11: {
      name: "grpbxVendorSplitRow11",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow11_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow11_PROPERTIES
    },
    grpbxVendorSplitRow12: {
      name: "grpbxVendorSplitRow12",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow12_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow12_PROPERTIES
    },
    grpbxVendorSplitRow13: {
      name: "grpbxVendorSplitRow13",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow13_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow13_PROPERTIES
    },
    grpbxVendorSplitRow14: {
      name: "grpbxVendorSplitRow14",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow14_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow14_PROPERTIES
    },
    grpbxVendorSplitRow15: {
      name: "grpbxVendorSplitRow15",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow15_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow15_PROPERTIES
    },
    grpbxVendorSplitRow16: {
      name: "grpbxVendorSplitRow16",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow16_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow16_PROPERTIES
    },
    grpbxVendorSplitRow17: {
      name: "grpbxVendorSplitRow17",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow17_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow17_PROPERTIES
    },
    grpbxVendorSplitRow18: {
      name: "grpbxVendorSplitRow18",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow18_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow18_PROPERTIES
    },
    grpbxVendorSplitRow19: {
      name: "grpbxVendorSplitRow19",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow19_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow19_PROPERTIES
    },
    grpbxVendorSplitRow2: {
      name: "grpbxVendorSplitRow2",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow2_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow2_PROPERTIES
    },
    grpbxVendorSplitRow3: {
      name: "grpbxVendorSplitRow3",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      clonedExtId: "105108",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow3_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow3_PROPERTIES
    },
    grpbxVendorSplitRow4: {
      name: "grpbxVendorSplitRow4",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow4_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow4_PROPERTIES
    },
    grpbxVendorSplitRow5: {
      name: "grpbxVendorSplitRow5",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow5_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow5_PROPERTIES
    },
    grpbxVendorSplitRow6: {
      name: "grpbxVendorSplitRow6",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow6_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow6_PROPERTIES
    },
    grpbxVendorSplitRow7: {
      name: "grpbxVendorSplitRow7",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow7_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow7_PROPERTIES
    },
    grpbxVendorSplitRow8: {
      name: "grpbxVendorSplitRow8",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow8_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow8_PROPERTIES
    },
    grpbxVendorSplitRow9: {
      name: "grpbxVendorSplitRow9",
      type: "GroupBoxWidget",
      parent: "grpbxVendorSplits",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxVendorSplitRow9_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorSplitRow9_PROPERTIES
    },
    grpbxWeightCert: {
      name: "grpbxWeightCert",
      type: "GroupBoxWidget",
      parent: "grpbxPrchsStrgWtCert",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
    },
    lbl1007Control: {
      name: "lbl1007Control",
      type: "LabelWidget",
      parent: "grpbx1007Controluic0",
      Label: "1007 Control",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    btnFree1007: {
      name: "btnFree1007",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Free 1007",
      CharWidth: "11",
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblCornFound: {
      name: "lblCornFound",
      type: "LabelWidget",
      parent: "grpbxGradeCalculation",
      Label: "Corn Found:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblDelPtObligation: {
      name: "lblDelPtObligation",
      type: "LabelWidget",
      parent: "grpbxPeanutInfouic0",
      Label: "Delivery Point/Obligation:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblFarmInformation: {
      name: "lblFarmInformation",
      type: "LabelWidget",
      parent: "grpbxFarmInformation",
      Label: "Farm Information",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblFlavusFound: {
      name: "lblFlavusFound",
      type: "LabelWidget",
      parent: "grpbxGradeCalculation",
      Label: "Flavus Found:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblGradeCalculation: {
      name: "lblGradeCalculation",
      type: "LabelWidget",
      parent: "grpbxGradeCalculation",
      Label: "Grade Calculation",
      ColSpan: "4",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblGrams1: {
      name: "lblGrams1",
      type: "LabelWidget",
      parent: "grpbxGradeCalculation",
      Label: "Grams",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblGrams2: {
      name: "lblGrams2",
      type: "LabelWidget",
      parent: "grpbxGradeCalculation",
      Label: "Grams",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblHMCConvertedGrades: {
      name: "lblHMCConvertedGrades",
      type: "LabelWidget",
      parent: "grpbxHMC",
      Label: "HMC Converted Grades:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblInspectorRemarks: {
      name: "lblInspectorRemarks",
      type: "LabelWidget",
      parent: "grpbxInspectorRemarks",
      Label: "Inspector Remarks",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblPct1: {
      name: "lblPct1",
      type: "LabelWidget",
      parent: "grpbxGradeCalculation",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblPct2: {
      name: "lblPct2",
      type: "LabelWidget",
      parent: "grpbxGradeCalculation",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblPeanutInformation: {
      name: "lblPeanutInformation",
      type: "LabelWidget",
      parent: "grpbxPeanutInfouic0",
      Label: "Peanut Information",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblAdd1007Apply: {
      name: "lblAdd1007Apply",
      type: "LabelWidget",
      parent: "grpbx1007Controluic0",
      Label: "Additional 1007 Applicant",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtNum: {
      name: "txtNum",
      type: "TextBoxWidget",
      parent: "grpbx1007Controluic0",
      Label: "Number:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtName: {
      name: "txtName",
      type: "TextBoxWidget",
      parent: "grpbx1007Controluic0",
      Label: "Name:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblPerLb: {
      name: "lblPerLb",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "c/lb",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblPerLb2: {
      name: "lblPerLb2",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "c/lb",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblPerLb3: {
      name: "lblPerLb3",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "c/lb",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblPerTon: {
      name: "lblPerTon",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "$/ton",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblPOrS: {
      name: "lblPOrS",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "P/S",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblPounds: {
      name: "lblPounds",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "Pounds",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblCMA: {
      name: "lblCMA",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "CMA Participant",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblPurchases: {
      name: "lblPurchases",
      type: "LabelWidget",
      parent: "grpbxPurchases",
      Label: "Purchases",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblRemitTo0: {
      name: "lblRemitTo0",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow0",
      Label: "Remit To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo0_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo0_PROPERTIES
    },
    lblRemitTo1: {
      name: "lblRemitTo1",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow1",
      Label: "Remit To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo1_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo1_PROPERTIES
    },
    lblRemitTo10: {
      name: "lblRemitTo10",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow10",
      Label: "Remit To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo10_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo10_PROPERTIES
    },
    lblRemitTo11: {
      name: "lblRemitTo11",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow11",
      Label: "Remit To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo11_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo11_PROPERTIES
    },
    lblRemitTo12: {
      name: "lblRemitTo12",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow12",
      Label: "Remit To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo12_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo12_PROPERTIES
    },
    lblRemitTo13: {
      name: "lblRemitTo13",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow13",
      Label: "Remit To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo13_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo13_PROPERTIES
    },
    lblRemitTo14: {
      name: "lblRemitTo14",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow14",
      Label: "Remit To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo14_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo14_PROPERTIES
    },
    lblRemitTo15: {
      name: "lblRemitTo15",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow15",
      Label: "Remit To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo15_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo15_PROPERTIES
    },
    lblRemitTo16: {
      name: "lblRemitTo16",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow16",
      Label: "Remit To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo16_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo16_PROPERTIES
    },
    lblRemitTo17: {
      name: "lblRemitTo17",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow17",
      Label: "Remit To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo17_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo17_PROPERTIES
    },
    lblRemitTo18: {
      name: "lblRemitTo18",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow18",
      Label: "Remit To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo18_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo18_PROPERTIES
    },
    lblRemitTo19: {
      name: "lblRemitTo19",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow19",
      Label: "Remit To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo19_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo19_PROPERTIES
    },
    lblRemitTo2: {
      name: "lblRemitTo2",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow2",
      Label: "Remit To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo2_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo2_PROPERTIES
    },
    lblRemitTo3: {
      name: "lblRemitTo3",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow3",
      Label: "Remit To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo3_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo3_PROPERTIES
    },
    lblRemitTo4: {
      name: "lblRemitTo4",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow4",
      Label: "Remit To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo4_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo4_PROPERTIES
    },
    lblRemitTo5: {
      name: "lblRemitTo5",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow5",
      Label: "Remit To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo5_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo5_PROPERTIES
    },
    lblRemitTo6: {
      name: "lblRemitTo6",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow6",
      Label: "Remit To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo6_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo6_PROPERTIES
    },
    lblRemitTo7: {
      name: "lblRemitTo7",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow7",
      Label: "Remit To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo7_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo7_PROPERTIES
    },
    lblRemitTo8: {
      name: "lblRemitTo8",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow8",
      Label: "Remit To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo8_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo8_PROPERTIES
    },
    lblRemitTo9: {
      name: "lblRemitTo9",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow9",
      Label: "Remit To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemitTo9_PROPERTIES

      // END_USER_CODE-USER_lblRemitTo9_PROPERTIES
    },
    lblSC95ES: {
      name: "lblSC95ES",
      type: "LabelWidget",
      parent: "grpbxFarmInformation",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblSharePct: {
      name: "lblSharePct",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "Share %",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblStorage: {
      name: "lblStorage",
      type: "LabelWidget",
      parent: "grpbxStorage",
      Label: "Storage",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblUnloadedInfo: {
      name: "lblUnloadedInfo",
      type: "LabelWidget",
      parent: "grpbxPeanutInfouic0",
      Label: "Unloaded Info:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblValue0: {
      name: "lblValue0",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow0",
      Label: "Value",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValue0_PROPERTIES

      // END_USER_CODE-USER_lblValue0_PROPERTIES
    },
    lblValue1: {
      name: "lblValue1",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow1",
      Label: "Value",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValue1_PROPERTIES

      // END_USER_CODE-USER_lblValue1_PROPERTIES
    },
    lblValue10: {
      name: "lblValue10",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow10",
      Label: "Value",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValue10_PROPERTIES

      // END_USER_CODE-USER_lblValue10_PROPERTIES
    },
    lblValue11: {
      name: "lblValue11",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow11",
      Label: "Value",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValue11_PROPERTIES

      // END_USER_CODE-USER_lblValue11_PROPERTIES
    },
    lblValue12: {
      name: "lblValue12",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow12",
      Label: "Value",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValue12_PROPERTIES

      // END_USER_CODE-USER_lblValue12_PROPERTIES
    },
    lblValue13: {
      name: "lblValue13",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow13",
      Label: "Value",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValue13_PROPERTIES

      // END_USER_CODE-USER_lblValue13_PROPERTIES
    },
    lblValue14: {
      name: "lblValue14",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow14",
      Label: "Value",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValue14_PROPERTIES

      // END_USER_CODE-USER_lblValue14_PROPERTIES
    },
    lblValue15: {
      name: "lblValue15",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow15",
      Label: "Value",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValue15_PROPERTIES

      // END_USER_CODE-USER_lblValue15_PROPERTIES
    },
    lblValue16: {
      name: "lblValue16",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow16",
      Label: "Value",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValue16_PROPERTIES

      // END_USER_CODE-USER_lblValue16_PROPERTIES
    },
    lblValue17: {
      name: "lblValue17",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow17",
      Label: "Value",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValue17_PROPERTIES

      // END_USER_CODE-USER_lblValue17_PROPERTIES
    },
    lblValue18: {
      name: "lblValue18",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow18",
      Label: "Value",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValue18_PROPERTIES

      // END_USER_CODE-USER_lblValue18_PROPERTIES
    },
    lblValue19: {
      name: "lblValue19",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow19",
      Label: "Value",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValue19_PROPERTIES

      // END_USER_CODE-USER_lblValue19_PROPERTIES
    },
    lblValue2: {
      name: "lblValue2",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow2",
      Label: "Value",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValue2_PROPERTIES

      // END_USER_CODE-USER_lblValue2_PROPERTIES
    },
    lblValue3: {
      name: "lblValue3",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow3",
      Label: "Value",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValue3_PROPERTIES

      // END_USER_CODE-USER_lblValue3_PROPERTIES
    },
    lblValue4: {
      name: "lblValue4",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow4",
      Label: "Value",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValue4_PROPERTIES

      // END_USER_CODE-USER_lblValue4_PROPERTIES
    },
    lblValue5: {
      name: "lblValue5",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow5",
      Label: "Value",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValue5_PROPERTIES

      // END_USER_CODE-USER_lblValue5_PROPERTIES
    },
    lblValue6: {
      name: "lblValue6",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow6",
      Label: "Value",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValue6_PROPERTIES

      // END_USER_CODE-USER_lblValue6_PROPERTIES
    },
    lblValue7: {
      name: "lblValue7",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow7",
      Label: "Value",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValue7_PROPERTIES

      // END_USER_CODE-USER_lblValue7_PROPERTIES
    },
    lblValue8: {
      name: "lblValue8",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow8",
      Label: "Value",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValue8_PROPERTIES

      // END_USER_CODE-USER_lblValue8_PROPERTIES
    },
    lblValue9: {
      name: "lblValue9",
      type: "LabelWidget",
      parent: "grpbxVendorSplitRow9",
      Label: "Value",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValue9_PROPERTIES

      // END_USER_CODE-USER_lblValue9_PROPERTIES
    },
    lblValueCalculation: {
      name: "lblValueCalculation",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "Value Calculation",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblVendor: {
      name: "lblVendor",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "Vendor",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblVendorSplits: {
      name: "lblVendorSplits",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "Vendor Splits:",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblWeightCert: {
      name: "lblWeightCert",
      type: "LabelWidget",
      parent: "grpbxWeightCert",
      Label: "Weight Cert.",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblWeightTickets: {
      name: "lblWeightTickets",
      type: "LabelWidget",
      parent: "grpbxWeightTickets",
      Label: "Weight Tickets",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblWhseRcpt: {
      name: "lblWhseRcpt",
      type: "LabelWidget",
      parent: "grpbxVendorSplits",
      Label: "Whse Rcpt.",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txt1007: {
      name: "txt1007",
      type: "TextBoxWidget",
      parent: "grpbx1007Controluic0",
      Label: "1007 #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtAccessFMLb: {
      name: "txtAccessFMLb",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtAccessFMTon: {
      name: "txtAccessFMTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Excess FM:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtAFlavusLb: {
      name: "txtAFlavusLb",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtAFlavusTon: {
      name: "txtAFlavusTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "A. Flavus:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtarInspectorRemarks: {
      name: "txtarInspectorRemarks",
      type: "TextAreaWidget",
      parent: "grpbxInspectorRemarks",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtBluePanWt: {
      name: "txtBluePanWt",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Blue Pan Wt:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtCleanSampleWt: {
      name: "txtCleanSampleWt",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Clean Sample Wt:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolDate: {
      name: "txtcolDate",
      type: "TextBoxWidget",
      colName: "colDate",
      parent: "gridWeightTickets",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolScaleTicket: {
      name: "txtcolScaleTicket",
      type: "TextBoxWidget",
      colName: "colScaleTicket",
      parent: "gridWeightTickets",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtcolWtOfPeanuts: {
      name: "txtcolWtOfPeanuts",
      type: "TextBoxWidget",
      colName: "colWtOfPeanuts",
      parent: "gridWeightTickets",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtConcealedRMDGr: {
      name: "txtConcealedRMDGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Concealed RMD:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtConcealedRMDPct: {
      name: "txtConcealedRMDPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtContractSpotPricePrchs: {
      name: "txtContractSpotPricePrchs",
      type: "TextBoxWidget",
      parent: "grpbxPurchases",
      Label: "Contract/Spot Price:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtCorrectionCount: {
      name: "txtCorrectionCount",
      type: "TextBoxWidget",
      parent: "grpbx1007Controluic0",
      Label: "Correction Count:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtCounty: {
      name: "txtCounty",
      type: "TextBoxWidget",
      parent: "grpbxFarmInformation",
      Label: "County:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtCrkOrBrkShellsGr: {
      name: "txtCrkOrBrkShellsGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Crk, or Brk Shells:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtCrkOrBrkShellsPct: {
      name: "txtCrkOrBrkShellsPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtCRMD: {
      name: "txtCRMD",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "C RMD:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtDACONum: {
      name: "txtDACONum",
      type: "TextBoxWidget",
      parent: "grpbxWeightCert",
      Label: "DACO #:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtDAM: {
      name: "txtDAM",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "DAM:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtDamagedKRS: {
      name: "txtDamagedKRS",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Damaged KRS:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtDamageLbs: {
      name: "txtDamageLbs",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtDamageSplits: {
      name: "txtDamageSplits",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Damage Splits:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtDamageTon: {
      name: "txtDamageTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Damage:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtDeductionsPrchs: {
      name: "txtDeductionsPrchs",
      type: "TextBoxWidget",
      parent: "grpbxPurchases",
      Label: "- Deductions:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtDeductionsStrg: {
      name: "txtDeductionsStrg",
      type: "TextBoxWidget",
      parent: "grpbxStorage",
      Label: "Deductions:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtDiscoloredShellsGr: {
      name: "txtDiscoloredShellsGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Discolored Shells:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtDiscoloredShellsPct: {
      name: "txtDiscoloredShellsPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtELK: {
      name: "txtELK",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "ELK:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtELKDamage: {
      name: "txtELKDamage",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "ELK Damage:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtELKGr: {
      name: "txtELKGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "ELK:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtELKPct: {
      name: "txtELKPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtELKPremiumLb: {
      name: "txtELKPremiumLb",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtELKPremiumTon: {
      name: "txtELKPremiumTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "ELK Premium:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtEVehicleNum: {
      name: "txtEVehicleNum",
      type: "TextBoxWidget",
      parent: "grpbxWeightTickets",
      Label: "E-Vehicle No.:",
      ColSpan: "6",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtEWeightTkt: {
      name: "txtEWeightTkt",
      type: "TextBoxWidget",
      parent: "grpbxWeightTickets",
      Label: "E-Weight Tkt:",
      ColSpan: "6",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtEWtInclVeh: {
      name: "txtEWtInclVeh",
      type: "TextBoxWidget",
      parent: "grpbxWeightTickets",
      Label: "E-Wt.Incl.Veh:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtEWtOfPnut: {
      name: "txtEWtOfPnut",
      type: "TextBoxWidget",
      parent: "grpbxWeightTickets",
      Label: "E-Wt. of Pnut:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtEWtOfVeh: {
      name: "txtEWtOfVeh",
      type: "TextBoxWidget",
      parent: "grpbxWeightTickets",
      Label: "E-Wt. of Veh:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtExcessMoisture: {
      name: "txtExcessMoisture",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Excess Moisture:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtExcessSplitsLb: {
      name: "txtExcessSplitsLb",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtExcessSplitsTon: {
      name: "txtExcessSplitsTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "ExcessSplits",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtFANCY: {
      name: "txtFANCY",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "FANCY:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtFancyGr: {
      name: "txtFancyGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Fancy:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtFancyPct: {
      name: "txtFancyPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtFarm: {
      name: "txtFarm",
      type: "TextBoxWidget",
      parent: "grpbxFarmInformation",
      Label: "Farm:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtFarmerStockCostPrchs: {
      name: "txtFarmerStockCostPrchs",
      type: "TextBoxWidget",
      parent: "grpbxPurchases",
      Label: "Farmer Stock Cost:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtFM: {
      name: "txtFM",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "FM:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtFMSampleWt: {
      name: "txtFMSampleWt",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "FM Sample Wt:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtForeignMaterial: {
      name: "txtForeignMaterial",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Foreign Material:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtForeignMaterialGr: {
      name: "txtForeignMaterialGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Foreign Material:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtForeignMaterialPct: {
      name: "txtForeignMaterialPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtFreezeDamageGr: {
      name: "txtFreezeDamageGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Freeze Damage:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtFreezeDamagePct: {
      name: "txtFreezeDamagePct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtFRZ: {
      name: "txtFRZ",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "FRZ:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtG: {
      name: "txtG",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "/G:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtGrossWeight: {
      name: "txtGrossWeight",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Gross Weight:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtH: {
      name: "txtH",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "H x :",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtHullBrightness: {
      name: "txtHullBrightness",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Hulls Brightness:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtHullBrightnessPct: {
      name: "txtHullBrightnessPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtHULLS: {
      name: "txtHULLS",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "HULLS:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtHullsGr: {
      name: "txtHullsGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Hulls:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtHullsPct: {
      name: "txtHullsPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtInspectDateTime: {
      name: "txtInspectDateTime",
      type: "DateTimeWidget",
      parent: "grpbxFarmInformation",
      Label: "Inspect Date/Time:",
      LengthRange: { MinLength: 0, MaxLength: 14 },
      ofTypeDomain: "d_dateTime",
    },
    txtJumboGr: {
      name: "txtJumboGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Jumbo:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtJumboPct: {
      name: "txtJumboPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtKernelsREL: {
      name: "txtKernelsREL",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Kernels REL:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtKernelsRPS: {
      name: "txtKernelsRPS",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Kernels RPS:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtKernelValueLb: {
      name: "txtKernelValueLb",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtKernelValueTon: {
      name: "txtKernelValueTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Kernel Value:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtlblTotalDiscountsLb: {
      name: "txtlblTotalDiscountsLb",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtLocation: {
      name: "txtLocation",
      type: "TextBoxWidget",
      parent: "grpbxFarmInformation",
      Label: "Location:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtLSK: {
      name: "txtLSK",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "LSK:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtLSKGr: {
      name: "txtLSKGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "LSK:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtLSKPct: {
      name: "txtLSKPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtLSKvc: {
      name: "txtLSKvc",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "LSK:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtMeterReading: {
      name: "txtMeterReading",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Meter Reading:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtMoistureReading: {
      name: "txtMoistureReading",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Moisture Reading:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtMST: {
      name: "txtMST",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "MST:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtNetValPerTonExclSLK: {
      name: "txtNetValPerTonExclSLK",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Net Value Per Ton Excluding SLK:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtNetWeight: {
      name: "txtNetWeight",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Net Weight:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtNetWeightPrchs: {
      name: "txtNetWeightPrchs",
      type: "TextBoxWidget",
      parent: "grpbxPurchases",
      Label: "(g) Net Weight:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtNetWeightStrg: {
      name: "txtNetWeightStrg",
      type: "TextBoxWidget",
      parent: "grpbxStorage",
      Label: "(G) Net Weight:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtNetWtExclLSK: {
      name: "txtNetWtExclLSK",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Net Weight Excluding LSK:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtOI: {
      name: "txtOI",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "O x I $:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtOK: {
      name: "txtOK",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "OK:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtOptionPayment: {
      name: "txtOptionPayment",
      type: "TextBoxWidget",
      parent: "grpbxPurchases",
      Label: "Option Payment:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtOptionPriceStrg: {
      name: "txtOptionPriceStrg",
      type: "TextBoxWidget",
      parent: "grpbxStorage",
      Label: "Option Price:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtOtherForeignMaterials: {
      name: "txtOtherForeignMaterials",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Other Foreign Materials:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtOtherKernelsGr: {
      name: "txtOtherKernelsGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Other Kernels:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtOtherKernelsPct: {
      name: "txtOtherKernelsPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtPctOfSupportPrchs: {
      name: "txtPctOfSupportPrchs",
      type: "TextBoxWidget",
      parent: "grpbxPurchases",
      Label: "% of Support:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtPctOfSupportStrg: {
      name: "txtPctOfSupportStrg",
      type: "TextBoxWidget",
      parent: "grpbxStorage",
      Label: "% of Support:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtPorS0: {
      name: "txtPorS0",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow0",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPorS0_PROPERTIES

      // END_USER_CODE-USER_txtPorS0_PROPERTIES
    },
    txtPorS1: {
      name: "txtPorS1",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPorS1_PROPERTIES

      // END_USER_CODE-USER_txtPorS1_PROPERTIES
    },
    txtPorS10: {
      name: "txtPorS10",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow10",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPorS10_PROPERTIES

      // END_USER_CODE-USER_txtPorS10_PROPERTIES
    },
    txtPorS11: {
      name: "txtPorS11",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow11",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPorS11_PROPERTIES

      // END_USER_CODE-USER_txtPorS11_PROPERTIES
    },
    txtPorS12: {
      name: "txtPorS12",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow12",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPorS12_PROPERTIES

      // END_USER_CODE-USER_txtPorS12_PROPERTIES
    },
    txtPorS13: {
      name: "txtPorS13",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow13",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPorS13_PROPERTIES

      // END_USER_CODE-USER_txtPorS13_PROPERTIES
    },
    txtPorS14: {
      name: "txtPorS14",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow14",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPorS14_PROPERTIES

      // END_USER_CODE-USER_txtPorS14_PROPERTIES
    },
    txtPorS15: {
      name: "txtPorS15",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow15",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPorS15_PROPERTIES

      // END_USER_CODE-USER_txtPorS15_PROPERTIES
    },
    txtPorS16: {
      name: "txtPorS16",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow16",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPorS16_PROPERTIES

      // END_USER_CODE-USER_txtPorS16_PROPERTIES
    },
    txtPorS17: {
      name: "txtPorS17",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow17",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPorS17_PROPERTIES

      // END_USER_CODE-USER_txtPorS17_PROPERTIES
    },
    txtPorS18: {
      name: "txtPorS18",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow18",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPorS18_PROPERTIES

      // END_USER_CODE-USER_txtPorS18_PROPERTIES
    },
    txtPorS19: {
      name: "txtPorS19",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow19",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPorS19_PROPERTIES

      // END_USER_CODE-USER_txtPorS19_PROPERTIES
    },
    txtPorS2: {
      name: "txtPorS2",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPorS2_PROPERTIES

      // END_USER_CODE-USER_txtPorS2_PROPERTIES
    },
    txtPorS3: {
      name: "txtPorS3",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPorS3_PROPERTIES

      // END_USER_CODE-USER_txtPorS3_PROPERTIES
    },
    txtPorS4: {
      name: "txtPorS4",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow4",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPorS4_PROPERTIES

      // END_USER_CODE-USER_txtPorS4_PROPERTIES
    },
    txtPorS5: {
      name: "txtPorS5",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow5",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPorS5_PROPERTIES

      // END_USER_CODE-USER_txtPorS5_PROPERTIES
    },
    txtPorS6: {
      name: "txtPorS6",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow6",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPorS6_PROPERTIES

      // END_USER_CODE-USER_txtPorS6_PROPERTIES
    },
    txtPorS7: {
      name: "txtPorS7",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow7",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPorS7_PROPERTIES

      // END_USER_CODE-USER_txtPorS7_PROPERTIES
    },
    txtPorS8: {
      name: "txtPorS8",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow8",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPorS8_PROPERTIES

      // END_USER_CODE-USER_txtPorS8_PROPERTIES
    },
    txtPorS9: {
      name: "txtPorS9",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow9",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPorS9_PROPERTIES

      // END_USER_CODE-USER_txtPorS9_PROPERTIES
    },
    txtPounds0: {
      name: "txtPounds0",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow0",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds0_PROPERTIES

      // END_USER_CODE-USER_txtPounds0_PROPERTIES
    },
    txtCMA0: {
      name: "txtCMA0",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow0",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCMA0_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_txtCMA0_PROPERTIES
    },
    txtCMA1: {
      name: "txtCMA1",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly:true,
      Enabled:false
    },
    txtCMA2: {
      name: "txtCMA2",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCMA2_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_txtCMA2_PROPERTIES
    },
    txtCMA3: {
      name: "txtCMA3",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCMA3_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_txtCMA3_PROPERTIES
    },
    txtCMA4: {
      name: "txtCMA4",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow4",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCMA4_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_txtCMA4_PROPERTIES
    },
    txtCMA5: {
      name: "txtCMA5",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow5",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCMA5_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_txtCMA5_PROPERTIES
    },
    txtCMA6: {
      name: "txtCMA6",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow6",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCMA6_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_txtCMA6_PROPERTIES
    },
    txtCMA7: {
      name: "txtCMA7",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow7",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCMA7_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_txtCMA7_PROPERTIES
    },
    txtCMA8: {
      name: "txtCMA8",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow8",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCMA8_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_txtCMA8_PROPERTIES
    },
    txtCMA9: {
      name: "txtCMA9",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow9",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCMA9_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_txtCMA9_PROPERTIES
    },
    txtCMA10: {
      name: "txtCMA10",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow10",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCMA10_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_txtCMA10_PROPERTIES
    },
    txtCMA11: {
      name: "txtCMA11",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow11",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly:true,
      Enabled:false
    },
    txtCMA12: {
      name: "txtCMA12",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow12",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly:true,
      Enabled:false
    },
    txtCMA13: {
      name: "txtCMA13",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow13",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCMA3_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_txtCMA3_PROPERTIES
    },
    txtCMA14: {
      name: "txtCMA14",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow14",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCMA4_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_txtCMA4_PROPERTIES
    },
    txtCMA15: {
      name: "txtCMA15",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow15",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCMA5_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_txtCMA5_PROPERTIES
    },
    txtCMA16: {
      name: "txtCMA16",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow16",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCMA6_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_txtCMA6_PROPERTIES
    },
    txtCMA17: {
      name: "txtCMA17",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow17",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCMA7_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_txtCMA7_PROPERTIES
    },
    txtCMA18: {
      name: "txtCMA18",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow18",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCMA8_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_txtCMA8_PROPERTIES
    },
    txtCMA19: {
      name: "txtCMA19",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow19",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCMA9_PROPERTIES
      ReadOnly:true,
      Enabled:false
      // END_USER_CODE-USER_txtCMA9_PROPERTIES
    },
    txtPounds1: {
      name: "txtPounds1",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds1_PROPERTIES

      // END_USER_CODE-USER_txtPounds1_PROPERTIES
    },
    txtPounds10: {
      name: "txtPounds10",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow10",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds10_PROPERTIES

      // END_USER_CODE-USER_txtPounds10_PROPERTIES
    },
    txtPounds11: {
      name: "txtPounds11",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow11",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds11_PROPERTIES

      // END_USER_CODE-USER_txtPounds11_PROPERTIES
    },
    txtPounds12: {
      name: "txtPounds12",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow12",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds12_PROPERTIES

      // END_USER_CODE-USER_txtPounds12_PROPERTIES
    },
    txtPounds13: {
      name: "txtPounds13",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow13",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds13_PROPERTIES

      // END_USER_CODE-USER_txtPounds13_PROPERTIES
    },
    txtPounds14: {
      name: "txtPounds14",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow14",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds14_PROPERTIES

      // END_USER_CODE-USER_txtPounds14_PROPERTIES
    },
    txtPounds15: {
      name: "txtPounds15",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow15",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds15_PROPERTIES

      // END_USER_CODE-USER_txtPounds15_PROPERTIES
    },
    txtPounds16: {
      name: "txtPounds16",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow16",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds16_PROPERTIES

      // END_USER_CODE-USER_txtPounds16_PROPERTIES
    },
    txtPounds17: {
      name: "txtPounds17",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow17",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds17_PROPERTIES

      // END_USER_CODE-USER_txtPounds17_PROPERTIES
    },
    txtPounds18: {
      name: "txtPounds18",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow18",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds18_PROPERTIES

      // END_USER_CODE-USER_txtPounds18_PROPERTIES
    },
    txtPounds19: {
      name: "txtPounds19",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow19",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds19_PROPERTIES

      // END_USER_CODE-USER_txtPounds19_PROPERTIES
    },
    txtPounds2: {
      name: "txtPounds2",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtPounds3: {
      name: "txtPounds3",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds3_PROPERTIES

      // END_USER_CODE-USER_txtPounds3_PROPERTIES
    },
    txtPounds4: {
      name: "txtPounds4",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow4",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds4_PROPERTIES

      // END_USER_CODE-USER_txtPounds4_PROPERTIES
    },
    txtPounds5: {
      name: "txtPounds5",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow5",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds5_PROPERTIES

      // END_USER_CODE-USER_txtPounds5_PROPERTIES
    },
    txtPounds6: {
      name: "txtPounds6",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow6",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds6_PROPERTIES

      // END_USER_CODE-USER_txtPounds6_PROPERTIES
    },
    txtPounds7: {
      name: "txtPounds7",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow7",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds7_PROPERTIES

      // END_USER_CODE-USER_txtPounds7_PROPERTIES
    },
    txtPounds8: {
      name: "txtPounds8",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow8",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds8_PROPERTIES

      // END_USER_CODE-USER_txtPounds8_PROPERTIES
    },
    txtPounds9: {
      name: "txtPounds9",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow9",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds9_PROPERTIES

      // END_USER_CODE-USER_txtPounds9_PROPERTIES
    },
    txtPremiumsPrchs: {
      name: "txtPremiumsPrchs",
      type: "TextBoxWidget",
      parent: "grpbxPurchases",
      Label: "+ Premiums:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtProbingPattern: {
      name: "txtProbingPattern",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Probing Pattern:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtProceedsPrchs: {
      name: "txtProceedsPrchs",
      type: "TextBoxWidget",
      parent: "grpbxPurchases",
      Label: "Proceeds:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtProceedsStrg: {
      name: "txtProceedsStrg",
      type: "TextBoxWidget",
      parent: "grpbxStorage",
      Label: "Proceeds:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtReading: {
      name: "txtReading",
      type: "TextBoxWidget",
      parent: "grpbxPeanutInfouic0",
      Label: "Reading:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtRemitTo0: {
      name: "txtRemitTo0",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow0",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo0_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo0_PROPERTIES
    },
    txtRemitTo1: {
      name: "txtRemitTo1",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo1_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo1_PROPERTIES
    },
    txtRemitTo10: {
      name: "txtRemitTo10",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow10",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo10_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo10_PROPERTIES
    },
    txtRemitTo11: {
      name: "txtRemitTo11",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow11",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo11_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo11_PROPERTIES
    },
    txtRemitTo12: {
      name: "txtRemitTo12",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow12",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo12_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo12_PROPERTIES
    },
    txtRemitTo13: {
      name: "txtRemitTo13",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow13",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo13_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo13_PROPERTIES
    },
    txtRemitTo14: {
      name: "txtRemitTo14",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow14",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo14_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo14_PROPERTIES
    },
    txtRemitTo15: {
      name: "txtRemitTo15",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow15",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo15_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo15_PROPERTIES
    },
    txtRemitTo16: {
      name: "txtRemitTo16",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow16",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo16_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo16_PROPERTIES
    },
    txtRemitTo17: {
      name: "txtRemitTo17",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow17",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo17_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo17_PROPERTIES
    },
    txtRemitTo18: {
      name: "txtRemitTo18",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow18",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo18_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo18_PROPERTIES
    },
    txtRemitTo19: {
      name: "txtRemitTo19",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow19",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo19_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo19_PROPERTIES
    },
    txtRemitTo2: {
      name: "txtRemitTo2",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo2_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo2_PROPERTIES
    },
    txtRemitTo3: {
      name: "txtRemitTo3",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo3_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo3_PROPERTIES
    },
    txtRemitTo4: {
      name: "txtRemitTo4",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow4",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo4_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo4_PROPERTIES
    },
    txtRemitTo5: {
      name: "txtRemitTo5",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow5",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo5_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo5_PROPERTIES
    },
    txtRemitTo6: {
      name: "txtRemitTo6",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow6",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo6_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo6_PROPERTIES
    },
    txtRemitTo7: {
      name: "txtRemitTo7",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow7",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo7_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo7_PROPERTIES
    },
    txtRemitTo8: {
      name: "txtRemitTo8",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow8",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo8_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo8_PROPERTIES
    },
    txtRemitTo9: {
      name: "txtRemitTo9",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow9",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo9_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo9_PROPERTIES
    },
    txtRevision: {
      name: "txtRevision",
      type: "TextBoxWidget",
      parent: "grpbxFarmInformation",
      Label: "Revision #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtCmaVendor: {
      name: "txtCmaVendor",
      type: "TextBoxWidget",
      parent: "grpbxFarmInformation",
      Label: "CMA Vendor#:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtCmaRemitTo: {
      name: "txtCmaRemitTo",
      type: "TextBoxWidget",
      parent: "grpbxFarmInformation",
      Label: "CMA Remit To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtIrrDryInd: {
      name: "txtIrrDryInd",
      type: "TextBoxWidget",
      parent: "grpbxFarmInformation",
      Label: "Irrigated/Dryland:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSC95: {
      name: "txtSC95",
      type: "TextBoxWidget",
      parent: "grpbxFarmInformation",
      Label: "SC95 #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSeedMeetingSpec: {
      name: "txtSeedMeetingSpec",
      type: "TextBoxWidget",
      parent: "grpbxPeanutInfouic0",
      Label: "Seed Meeting Specification:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSeedPremiumStrg: {
      name: "txtSeedPremiumStrg",
      type: "TextBoxWidget",
      parent: "grpbxStorage",
      Label: "Seed Premium:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSeg: {
      name: "txtSeg",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "Seg:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSettleDate: {
      name: "txtSettleDate",
      type: "DateWidget",
      parent: "grpbx1007Controluic0",
      Label: "Settle Date:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    txtSharePct0: {
      name: "txtSharePct0",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow0",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSharePct1: {
      name: "txtSharePct1",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct1_PROPERTIES

      // END_USER_CODE-USER_txtSharePct1_PROPERTIES
    },
    txtSharePct10: {
      name: "txtSharePct10",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow10",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct10_PROPERTIES

      // END_USER_CODE-USER_txtSharePct10_PROPERTIES
    },
    txtSharePct11: {
      name: "txtSharePct11",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow11",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct11_PROPERTIES

      // END_USER_CODE-USER_txtSharePct11_PROPERTIES
    },
    txtSharePct12: {
      name: "txtSharePct12",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow12",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct12_PROPERTIES

      // END_USER_CODE-USER_txtSharePct12_PROPERTIES
    },
    txtSharePct13: {
      name: "txtSharePct13",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow13",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct13_PROPERTIES

      // END_USER_CODE-USER_txtSharePct13_PROPERTIES
    },
    txtSharePct14: {
      name: "txtSharePct14",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow14",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct14_PROPERTIES

      // END_USER_CODE-USER_txtSharePct14_PROPERTIES
    },
    txtSharePct15: {
      name: "txtSharePct15",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow15",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct15_PROPERTIES

      // END_USER_CODE-USER_txtSharePct15_PROPERTIES
    },
    txtSharePct16: {
      name: "txtSharePct16",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow16",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct16_PROPERTIES

      // END_USER_CODE-USER_txtSharePct16_PROPERTIES
    },
    txtSharePct17: {
      name: "txtSharePct17",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow17",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct17_PROPERTIES

      // END_USER_CODE-USER_txtSharePct17_PROPERTIES
    },
    txtSharePct18: {
      name: "txtSharePct18",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow18",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct18_PROPERTIES

      // END_USER_CODE-USER_txtSharePct18_PROPERTIES
    },
    txtSharePct19: {
      name: "txtSharePct19",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow19",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct19_PROPERTIES

      // END_USER_CODE-USER_txtSharePct19_PROPERTIES
    },
    txtSharePct2: {
      name: "txtSharePct2",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct2_PROPERTIES

      // END_USER_CODE-USER_txtSharePct2_PROPERTIES
    },
    txtSharePct3: {
      name: "txtSharePct3",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct3_PROPERTIES

      // END_USER_CODE-USER_txtSharePct3_PROPERTIES
    },
    txtSharePct4: {
      name: "txtSharePct4",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow4",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct4_PROPERTIES

      // END_USER_CODE-USER_txtSharePct4_PROPERTIES
    },
    txtSharePct5: {
      name: "txtSharePct5",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow5",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct5_PROPERTIES

      // END_USER_CODE-USER_txtSharePct5_PROPERTIES
    },
    txtSharePct6: {
      name: "txtSharePct6",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow6",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct6_PROPERTIES

      // END_USER_CODE-USER_txtSharePct6_PROPERTIES
    },
    txtSharePct7: {
      name: "txtSharePct7",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow7",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct7_PROPERTIES

      // END_USER_CODE-USER_txtSharePct7_PROPERTIES
    },
    txtSharePct8: {
      name: "txtSharePct8",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow8",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct8_PROPERTIES

      // END_USER_CODE-USER_txtSharePct8_PROPERTIES
    },
    txtSharePct9: {
      name: "txtSharePct9",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow9",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct9_PROPERTIES

      // END_USER_CODE-USER_txtSharePct9_PROPERTIES
    },
    txtSMK: {
      name: "txtSMK",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "SMK:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSMK1Gr: {
      name: "txtSMK1Gr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "SMK 1(Screen Size)",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSMK1Pct: {
      name: "txtSMK1Pct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSMK2Gruic0: {
      name: "txtSMK2Gruic0",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "SMK 2(Screen Size)",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSMK2Pct: {
      name: "txtSMK2Pct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSMK3Gr: {
      name: "txtSMK3Gr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "SMK 3(Screen Size)",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSMK3Pct: {
      name: "txtSMK3Pct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSMK4Gr: {
      name: "txtSMK4Gr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "SMK 4(Screen Size)",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSMK4Pct: {
      name: "txtSMK4Pct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSMKRS: {
      name: "txtSMKRS",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "SMKRS:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSMKRSGr: {
      name: "txtSMKRSGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "SMKRS:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSMKRSPct: {
      name: "txtSMKRSPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSoundSplitsGr: {
      name: "txtSoundSplitsGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Sound Splits:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSoundSplitsPct: {
      name: "txtSoundSplitsPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSS: {
      name: "txtSS",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "SS:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtState: {
      name: "txtState",
      type: "TextBoxWidget",
      parent: "grpbxFarmInformation",
      Label: "State:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtStatus: {
      name: "txtStatus",
      type: "TextBoxWidget",
      parent: "grpbx1007Controluic0",
      Label: "Status:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSuperceded1007: {
      name: "txtSuperceded1007",
      type: "TextBoxWidget",
      parent: "grpbx1007Controluic0",
      Label: "Superceded 1007#:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtSupercededSC95: {
      name: "txtSupercededSC95",
      type: "TextBoxWidget",
      parent: "grpbx1007Controluic0",
      Label: "Superceded SC95#:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtTickets: {
      name: "txtTickets",
      type: "TextBoxWidget",
      parent: "grpbxWeightTickets",
      Label: "Tickets:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtTotalDamageGr: {
      name: "txtTotalDamageGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Total Damage:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtTotalDamagePct: {
      name: "txtTotalDamagePct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtTotalDiscountsTon: {
      name: "txtTotalDiscountsTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Total Discounts:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtTotalDollar: {
      name: "txtTotalDollar",
      type: "TextBoxWidget",
      parent: "grpbxWeightTickets",
      Label: "Total $:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtTotalKernels: {
      name: "txtTotalKernels",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "Total Kernels:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtTotalKernelsHulls: {
      name: "txtTotalKernelsHulls",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Total Kernels & Hulls:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtTotalKrnls: {
      name: "txtTotalKrnls",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Total Kernels:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtTotalLb: {
      name: "txtTotalLb",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtTotalLBs: {
      name: "txtTotalLBs",
      type: "TextBoxWidget",
      parent: "grpbxWeightTickets",
      Label: "Total LBs:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtTotalLoadVal: {
      name: "txtTotalLoadVal",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Total Value of Load (g):",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtTotalLoadValuePrchs: {
      name: "txtTotalLoadValuePrchs",
      type: "TextBoxWidget",
      parent: "grpbxPurchases",
      Label: "(p) Total (Value Of Load):",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtTotalLoadValueStrg: {
      name: "txtTotalLoadValueStrg",
      type: "TextBoxWidget",
      parent: "grpbxStorage",
      Label: "(P) Total (Value Of Load):",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtTotalSMK: {
      name: "txtTotalSMK",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Total SMK:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtTotalTon: {
      name: "txtTotalTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Total:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtTotKrnlsHulls: {
      name: "txtTotKrnlsHulls",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "Tot krnls + hulls(excl. lsk):",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtValenciaCleanSampleWt: {
      name: "txtValenciaCleanSampleWt",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Valencia Clean Sample Wt:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtValPerPoundExclSLK: {
      name: "txtValPerPoundExclSLK",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Value Per Pound Excluding LSK:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtValPerPoundInclSLK: {
      name: "txtValPerPoundInclSLK",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Value Per Pound Including LSK:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtValue0: {
      name: "txtValue0",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow0",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue0_PROPERTIES

      // END_USER_CODE-USER_txtValue0_PROPERTIES
    },
    txtValue1: {
      name: "txtValue1",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue1_PROPERTIES

      // END_USER_CODE-USER_txtValue1_PROPERTIES
    },
    txtValue10: {
      name: "txtValue10",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow10",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue10_PROPERTIES

      // END_USER_CODE-USER_txtValue10_PROPERTIES
    },
    txtValue11: {
      name: "txtValue11",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow11",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue11_PROPERTIES

      // END_USER_CODE-USER_txtValue11_PROPERTIES
    },
    txtValue12: {
      name: "txtValue12",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow12",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue12_PROPERTIES

      // END_USER_CODE-USER_txtValue12_PROPERTIES
    },
    txtValue13: {
      name: "txtValue13",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow13",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue13_PROPERTIES

      // END_USER_CODE-USER_txtValue13_PROPERTIES
    },
    txtValue14: {
      name: "txtValue14",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow14",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue14_PROPERTIES

      // END_USER_CODE-USER_txtValue14_PROPERTIES
    },
    txtValue15: {
      name: "txtValue15",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow15",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue15_PROPERTIES

      // END_USER_CODE-USER_txtValue15_PROPERTIES
    },
    txtValue16: {
      name: "txtValue16",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow16",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue16_PROPERTIES

      // END_USER_CODE-USER_txtValue16_PROPERTIES
    },
    txtValue17: {
      name: "txtValue17",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow17",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue17_PROPERTIES

      // END_USER_CODE-USER_txtValue17_PROPERTIES
    },
    txtValue18: {
      name: "txtValue18",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow18",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue18_PROPERTIES

      // END_USER_CODE-USER_txtValue18_PROPERTIES
    },
    txtValue19: {
      name: "txtValue19",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow19",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue19_PROPERTIES

      // END_USER_CODE-USER_txtValue19_PROPERTIES
    },
    txtValue2: {
      name: "txtValue2",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue2_PROPERTIES

      // END_USER_CODE-USER_txtValue2_PROPERTIES
    },
    txtValue3: {
      name: "txtValue3",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue3_PROPERTIES

      // END_USER_CODE-USER_txtValue3_PROPERTIES
    },
    txtValue4: {
      name: "txtValue4",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow4",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue4_PROPERTIES

      // END_USER_CODE-USER_txtValue4_PROPERTIES
    },
    txtValue5: {
      name: "txtValue5",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow5",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue5_PROPERTIES

      // END_USER_CODE-USER_txtValue5_PROPERTIES
    },
    txtValue6: {
      name: "txtValue6",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow6",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue6_PROPERTIES

      // END_USER_CODE-USER_txtValue6_PROPERTIES
    },
    txtValue7: {
      name: "txtValue7",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow7",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue7_PROPERTIES

      // END_USER_CODE-USER_txtValue7_PROPERTIES
    },
    txtValue8: {
      name: "txtValue8",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow8",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue8_PROPERTIES

      // END_USER_CODE-USER_txtValue8_PROPERTIES
    },
    txtValue9: {
      name: "txtValue9",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow9",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue9_PROPERTIES

      // END_USER_CODE-USER_txtValue9_PROPERTIES
    },
    txtValueOfOptionStrg: {
      name: "txtValueOfOptionStrg",
      type: "TextBoxWidget",
      parent: "grpbxStorage",
      Label: "Value of Option:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtValueOfSegPrchs: {
      name: "txtValueOfSegPrchs",
      type: "TextBoxWidget",
      parent: "grpbxPurchases",
      Label: "Value of Seg:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtVendor0: {
      name: "txtVendor0",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow0",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor0_PROPERTIES

      // END_USER_CODE-USER_txtVendor0_PROPERTIES
    },
    txtVendor1: {
      name: "txtVendor1",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor10: {
      name: "txtVendor10",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow10",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor10_PROPERTIES

      // END_USER_CODE-USER_txtVendor10_PROPERTIES
    },
    txtVendor11: {
      name: "txtVendor11",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow11",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor11_PROPERTIES

      // END_USER_CODE-USER_txtVendor11_PROPERTIES
    },
    txtVendor12: {
      name: "txtVendor12",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow12",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor12_PROPERTIES

      // END_USER_CODE-USER_txtVendor12_PROPERTIES
    },
    txtVendor13: {
      name: "txtVendor13",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow13",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor13_PROPERTIES

      // END_USER_CODE-USER_txtVendor13_PROPERTIES
    },
    txtVendor14: {
      name: "txtVendor14",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow14",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor14_PROPERTIES

      // END_USER_CODE-USER_txtVendor14_PROPERTIES
    },
    txtVendor15: {
      name: "txtVendor15",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow15",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor15_PROPERTIES

      // END_USER_CODE-USER_txtVendor15_PROPERTIES
    },
    txtVendor16: {
      name: "txtVendor16",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow16",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor16_PROPERTIES

      // END_USER_CODE-USER_txtVendor16_PROPERTIES
    },
    txtVendor17: {
      name: "txtVendor17",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow17",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor17_PROPERTIES

      // END_USER_CODE-USER_txtVendor17_PROPERTIES
    },
    txtVendor18: {
      name: "txtVendor18",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow18",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor18_PROPERTIES

      // END_USER_CODE-USER_txtVendor18_PROPERTIES
    },
    txtVendor19: {
      name: "txtVendor19",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow19",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor19_PROPERTIES

      // END_USER_CODE-USER_txtVendor19_PROPERTIES
    },
    txtVendor2: {
      name: "txtVendor2",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor2_PROPERTIES

      // END_USER_CODE-USER_txtVendor2_PROPERTIES
    },
    txtVendor3: {
      name: "txtVendor3",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor3_PROPERTIES

      // END_USER_CODE-USER_txtVendor3_PROPERTIES
    },
    txtVendor4: {
      name: "txtVendor4",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow4",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor4_PROPERTIES

      // END_USER_CODE-USER_txtVendor4_PROPERTIES
    },
    txtVendor5: {
      name: "txtVendor5",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow5",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor5_PROPERTIES

      // END_USER_CODE-USER_txtVendor5_PROPERTIES
    },
    txtVendor6: {
      name: "txtVendor6",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow6",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor6_PROPERTIES

      // END_USER_CODE-USER_txtVendor6_PROPERTIES
    },
    txtVendor7: {
      name: "txtVendor7",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow7",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor7_PROPERTIES

      // END_USER_CODE-USER_txtVendor7_PROPERTIES
    },
    txtVendor8: {
      name: "txtVendor8",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow8",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor8_PROPERTIES

      // END_USER_CODE-USER_txtVendor8_PROPERTIES
    },
    txtVendor9: {
      name: "txtVendor9",
      type: "TextBoxWidget",
      parent: "grpbxVendorSplitRow9",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor9_PROPERTIES

      // END_USER_CODE-USER_txtVendor9_PROPERTIES
    },
    txtWtIncludingVehicle: {
      name: "txtWtIncludingVehicle",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Wt. Including Vehicle:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtWtlessFM: {
      name: "txtWtlessFM",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Weight Less FM:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtWtOfVehicle: {
      name: "txtWtOfVehicle",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Wt. of Vehicle:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    grpbxFarmInformation: {
      name: "grpbxFarmInformation",
      type: "GroupBoxWidget",
      parent: "ViewSettlement",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
    },
    grpbx1007Controluic0: {
      name: "grpbx1007Controluic0",
      type: "GroupBoxWidget",
      parent: "ViewSettlement",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
    },
    grpbxWeightTickets: {
      name: "grpbxWeightTickets",
      type: "GroupBoxWidget",
      parent: "ViewSettlement",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
    },
    grpbxPeanutInfouic0: {
      name: "grpbxPeanutInfouic0",
      type: "GroupBoxWidget",
      parent: "ViewSettlement",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
    },
    grpbxTicketVendrSplit: {
      name: "grpbxTicketVendrSplit",
      type: "GroupBoxWidget",
      parent: "ViewSettlement",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
    },
    grpbxVendorSplits: {
      name: "grpbxVendorSplits",
      type: "GroupBoxWidget",
      parent: "ViewSettlement",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
    },
    grpbxInspectorRemarks: {
      name: "grpbxInspectorRemarks",
      type: "GroupBoxWidget",
      parent: "ViewSettlement",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
    },
    grpbxGradeCalculation: {
      name: "grpbxGradeCalculation",
      type: "GroupBoxWidget",
      parent: "ViewSettlement",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
    },
    grpbxHMC: {
      name: "grpbxHMC",
      type: "GroupBoxWidget",
      parent: "ViewSettlement",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
    },
    grpbxValueCalculation: {
      name: "grpbxValueCalculation",
      type: "GroupBoxWidget",
      parent: "ViewSettlement",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
    },
    grpbxPrchsStrgWtCert: {
      name: "grpbxPrchsStrgWtCert",
      type: "GroupBoxWidget",
      parent: "ViewSettlement",
      Height: "",
      Width: "",
      HasLabel: false,
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "ViewSettlement",
      Height: "",
      Width: "",
      ColsForTabLandscape: "9",
      HasLabel: false,
      Cols: "9",
      ColsForTabPotrait: "9",
      ColsForLargeDesktop: "9",
    },
    _dataCopy: {
      FORWARD: {
        "Settlements#CleaningCuringCosts": {},
        "Settlements#ViewContractApplication": {},
        "Settlements#ReviewCorrectionLog": {},
        "ContractManagement#AccountDistributionActivity": {},
      },
      REVERSE: {
        "Settlements#CleaningCuringCosts": {},
        "Settlements#ViewContractApplication": {},
        "Settlements#ReviewCorrectionLog": {},
        "ContractManagement#AccountDistributionActivity": {},
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};

  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };

  useEffect(() => {
    parentWindow(thisObj);
  },[thisObj]); 
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    hide(thisObj, 'btnResendToKCMO', false)
    let viewSettlementScreenData = getData(thisObj, 'viewSettlementData')
    if (viewSettlementScreenData == null || viewSettlementScreenData == undefined) {
      showMessage(thisObj, 'Data was not loaded from the previous screen. \n Please try again')
      return;
    }
    bFillForm(viewSettlementScreenData.inspNum, viewSettlementScreenData.buyPtId);
    setData(thisObj, 'selectedRowReload', true);
  }, []);

  async function GetAllowRenumber() {
    try {
      if (mbSupersedePending)
        return false;
      if (!isNaN(getValue(thisObj, 'txtSC95')))
        return false;

      let response = await ContractManagementService.RetrieveAccessPermissionDetails('PN1060', '005', 'D', thisObj.values.txtLocation.split(' ')[0].trim())
      if (response.length <= 0)
        return false;
      if (response[0].permission.toLocaleUpperCase() == 'Y')
        return true;
    }
    catch (err) {
      errorHandler(err);
      return false;
    }
  }

  async function Form_Activate() {
    try {
      await bCheckRTP();
    }
    catch (err) {
      mbSuccessfulLoad = false;
      errorHandler(err);
    }
  }

  async function bCheckRTP() {
    try {
      let LstrHTTP = '';
      let lstrxml = [];
      let viewSettlementScreenData = getData(thisObj, 'viewSettlementData')
      let screenData = {
        inspNum: viewSettlementScreenData.inspNum, //thisObj.values.txtSC95,
        buyPtId: viewSettlementScreenData.buyPtId  //thisObj.values.txtLocation == null ? null : thisObj.values.txtLocation.split(' ')[0].trim(),
      }

      lstrxml = await ContractManagementService.RetrieveBatchCheckReadyToPayDetails(null, screenData.buyPtId, screenData.inspNum, null, null, thisObj.values.txt1007)
      if (lstrxml.length <= 0) {
        showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.')
        return;
      }
      if (lstrxml[0].record_count > 0) {
        LstrHTTP = '';
        for (var i = 0; i < lstrxml[0].batch.length; i++)
          LstrHTTP += (lstrxml[0].batch[i].payee_vendor + ' ')

        disable(thisObj, 'btnVoid')
        disable(thisObj, 'btnCorrection')
        disable(thisObj, 'btnSupersede')
        let message = 'Pending Batched Check:\n' + 'You must release the pending payment to the following vendor(s) ...\n ' + LstrHTTP.trim() + '\n'
        message += '\n before the following functions can be allowed.\n 1. Void \n 2. Correction \n 3. Supercede'

        alert(message)
        return true;
      }

    }
    catch (err) {
      mbSuccessfulLoad = false;
      errorHandler(err);
      return false;
    }
  }

  function EnableDisableFields(flag) {
    try {
      EnableDisableFarmInformation(flag);
      EnableDisable1007Control(flag);
      EnableDisablePeanutInformation(flag);
      EnableDisableObligationInformation(flag);
      EnableDisableVendorSplit(flag);
      disable(thisObj, 'txtarInspectorRemarks')
      disable(thisObj, 'chkboxCornFound')
    }
    catch (err) {
      errorHandler(err);
    }
  }

  function EnableDisableFarmInformation(flag) {
    try {
      if (flag) {
        enable(thisObj, 'txtState')
        enable(thisObj, 'txtCounty')
        enable(thisObj, 'txtLocation')
        enable(thisObj, 'txtFarm')
        enable(thisObj, 'txtSC95')
        enable(thisObj, 'txtRevision')
        enable(thisObj, 'txtInspectDateTime')
        enable(thisObj, 'txtDACONum')
      }
      else {
        disable(thisObj, 'txtState')
        disable(thisObj, 'txtCounty')
        disable(thisObj, 'txtLocation')
        disable(thisObj, 'txtFarm')
        disable(thisObj, 'txtSC95')
        disable(thisObj, 'txtRevision')
        disable(thisObj, 'txtInspectDateTime')
        disable(thisObj, 'txtDACONum')
      }
    }
    catch (err) {
      errorHandler(err);
    }
  }

  function EnableDisable1007Control(flag) {
    try {
      if (flag) {
        enable(thisObj, 'txt1007')
        enable(thisObj, 'txtStatus')
        enable(thisObj, 'txtSettleDate')
        enable(thisObj, 'txtSuperceded1007')
        enable(thisObj, 'txtSupercededSC95')
        enable(thisObj, 'txtCorrectionCount')
      }
      else {
        disable(thisObj, 'txt1007')
        disable(thisObj, 'txtStatus')
        disable(thisObj, 'txtSettleDate')
        disable(thisObj, 'txtSuperceded1007')
        disable(thisObj, 'txtSupercededSC95')
        disable(thisObj, 'txtCorrectionCount')
      }
    }
    catch (err) {
      errorHandler(err);
    }
  }

  function EnableDisablePeanutInformation(flag) {
    try {
      if (flag) {
        enable(thisObj, 'txtPeanutType')
        enable(thisObj, 'txtPeanutVariety')
        enable(thisObj, 'txtSeedGen')
        enable(thisObj, 'txtSeg1')
        enable(thisObj, 'txtEdibleOil')
        enable(thisObj, 'txtOleic')
        enable(thisObj, 'txtSeedMeetingSpec')
        enable(thisObj, 'txtReading')
        enable(thisObj, 'chkboxOrganic')
        enable(thisObj, 'chkboxVicam')
      }
      else {
        disable(thisObj, 'txtPeanutType')
        disable(thisObj, 'txtPeanutVariety')
        disable(thisObj, 'txtSeedGen')
        disable(thisObj, 'txtSeg1')
        disable(thisObj, 'txtEdibleOil')
        disable(thisObj, 'txtOleic')
        disable(thisObj, 'txtSeedMeetingSpec')
        disable(thisObj, 'txtReading')
        disable(thisObj, 'chkboxOrganic')
        disable(thisObj, 'chkboxVicam')
      }
    }
    catch (err) {
      errorHandler(err);
    }
  }

  function EnableDisableObligationInformation(flag) {
    try {
      if (flag) {
        enable(thisObj, 'txtWhouseBin')
        enable(thisObj, 'txtBuyingPt')
        enable(thisObj, 'txtObligationPt')
      }
      else {
        disable(thisObj, 'txtWhouseBin')
        disable(thisObj, 'txtBuyingPt')
        disable(thisObj, 'txtObligationPt')
      }
    }
    catch (err) {
      errorHandler(err);
    }
  }

  function EnableDisableVendorSplit(flag) {
    try {
      if (flag) {
        for (var i = 0; i < 20; i++) {
          enable(thisObj, 'ddWhseRcpt' + i)
          enable(thisObj, 'txtVendor' + i)
          enable(thisObj, 'txtPorS' + i)
          enable(thisObj, 'txtSharePct' + i)
          enable(thisObj, 'txtPounds' + i)
          enable(thisObj, 'txtRemitTo' + i)
          enable(thisObj, 'txtValue' + i)
        }

      }
      else {
        for (var i = 0; i < 20; i++) {
          disable(thisObj, 'ddWhseRcpt' + i)
          disable(thisObj, 'txtVendor' + i)
          disable(thisObj, 'txtPorS' + i)
          disable(thisObj, 'txtSharePct' + i)
          disable(thisObj, 'txtPounds' + i)
          disable(thisObj, 'txtRemitTo' + i)
          disable(thisObj, 'txtValue' + i)
        }

      }
    }
    catch (err) {
      errorHandler(err);
    }
  }

  function ShowHideVendorsInitially(){
    try {
      let element = ['grpbxVendorSplitRow4', 'grpbxVendorSplitRow5', 'grpbxVendorSplitRow6', 'grpbxVendorSplitRow7', 'grpbxVendorSplitRow8',
      'grpbxVendorSplitRow9', 'grpbxVendorSplitRow10','grpbxVendorSplitRow11' ,'grpbxVendorSplitRow12',
      'grpbxVendorSplitRow13', 'grpbxVendorSplitRow14','grpbxVendorSplitRow15' ,'grpbxVendorSplitRow16',
      'grpbxVendorSplitRow17', 'grpbxVendorSplitRow18','grpbxVendorSplitRow19']
      hideWidgets(thisObj, element)
    }
    catch (err) {
      errorHandler(err);
    }
  }

  const ontxtSC95Change = async () => {
    try {
      mbSuccessfulLoad = true;
      if (!bFillForm())
        mbSuccessfulLoad = false;
    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.ontxtSC95Change = ontxtSC95Change;

  async function bFillForm(tradeInspNum, buyPtId) {
    try {
      let lstrxml = [];
      let LstrXML_Sub = [];
      let sHeader = [];
      let sVendorSplits = [];
      let sVendorApplication = [];
      let sRemarks = '';
      let sPremDeductions = [];
      let nListIndex = 0;
      let nControlIndex = 0;
      let bFound = false;
      let bWRExists = false;
      let lblPeanutTypeTag = '';
      
      let objVal = {}
      ShowHideVendorsInitially();
      EnableDisableFields(false);
      hide(thisObj, 'btnFree1007', false);

      mbFormFilling = true;
      let response = await SettlementService.RetrieveInspectionHeaderList(tradeInspNum, buyPtId, null, null)
      if (response.length <= 0) {
        showMessage(thisObj, 'No data found')
        return '';
      }
      lstrxml = response[0];
      buyPtId = lstrxml.buy_pt_id

      sVendorSplits = lstrxml.inspect_vendor_splits;
      sVendorApplication = lstrxml.inspect_vendor_applications;
      sRemarks = lstrxml.inspect_remarks;
      sScaleTickets = lstrxml.inspect_scale_tickets;
      sPremDeductions = lstrxml.inspect_prem_deducts;

      objVal['txtState'] = lstrxml.state_name == null ? '' : lstrxml.state_name                     
      objVal['txtCounty'] = lstrxml.county_name + ' - ' + lstrxml.county_id                         
      objVal['txtLocation'] = lstrxml.buy_pt_id + ' - ' + lstrxml.buy_pt_nLdblDataRowsame           
      objVal['txtFarm'] = lstrxml.farm_id + ' - ' + lstrxml.farm_suffix + ' - ' + lstrxml.farm_name 
      objVal['txtSC95'] = lstrxml.insp_num                                                          
      objVal['txtRevision'] =lstrxml.revision_num                                                   
      objVal['txtInspectDateTime'] = lstrxml.insp_date_time                                         
      objVal['txtDACONum'] = lstrxml.weight_cert_num                                                

      objVal['txtNum'] = lstrxml.handler_loc_num_2                                                  
      objVal['txtName'] = lstrxml.handler_loc_name_2                                                

      hide(thisObj, 'lblAdd1007Apply')
      hide(thisObj, 'txtNum')
      hide(thisObj, 'txtName')
      if (lstrxml.handler_loc_num_2 !== '') {
        let resp = await ContractManagementService.RetrieveAccessPermissionDetails('PN1030', '005', 'U', buyPtId)
        if (resp[0].permission == 'Y') {
          show(thisObj, 'lblAdd1007Apply')
          show(thisObj, 'txtNum')
          show(thisObj, 'txtName')
        }
      }

      objVal['txtKernelsREL'] = lstrxml.kernel_rels  
      objVal['txtKernelsRPS'] = lstrxml.kernel_rps   
      objVal['txtBluePanWt'] = lstrxml.bluepan_wt    
      objVal['txtMeterReading'] = lstrxml.meter_read 
      objVal['txtDamagedKRS'] = lstrxml.damaged_krs  
      objVal['txtELKDamage'] = lstrxml.elk_damaged   

      if (lstrxml.cma_vendor_num == '') {
        hide(thisObj, 'txtCmaVendor')
        hide(thisObj, 'txtCmaRemitTo')
      }
      else {
        objVal['txtCmaVendor'] = lstrxml.cma_vendor_num 
        objVal['txtCmaRemitTo'] = lstrxml.cma_remit_to  
      }

      if (lstrxml.elec_upload == 'Y')
        objVal['lblSC95ES'] = 'SC95 Electronically Submitted'  
      else
        objVal['lblSC95ES'] = 'SC95 Manually Submitted'       

      if (lstrxml.irr_dry_ind !== null) {
        switch (lstrxml.irr_dry_ind.trim()) {
          case 'I':
            objVal['txtIrrDryInd'] = 'Irrigated'              
            break;
          case 'D':
            objVal['txtIrrDryInd'] = 'Dryland'                
            break;
          case 'U':
            objVal['txtIrrDryInd'] = 'Unknown'                
            hide(thisObj, 'txtIrrDryInd')
            break;
          default:
            objVal['txtIrrDryInd'] = ''                       
            hide(thisObj, 'txtIrrDryInd')
            break;
        }
      }
      else
        objVal['txtIrrDryInd'] = ''                           

      mbSupersedePending = false;
      if (lstrxml.supersede_pending_ind.trim().toLocaleUpperCase() == 'Y') {
        mbSupersedePending = true;
        disable(thisObj, 'btnCorrection')
      }

      lblPeanutTypeTag = lstrxml.pnut_type_id;
      objVal['txtPeanutType'] = lstrxml.pnut_type_name                    

      if (lstrxml.symbol_ind !== null) {
        if (lstrxml.symbol_ind.trim().toLocaleUpperCase() == 'TRADEMARK')
          objVal['txtPeanutVariety'] = lstrxml.pnut_variety_name + ' ᵀᴹ'     
        else if (lstrxml.symbol_ind.trim().toLocaleUpperCase() == 'REGISTERED TRADEMARK')
          objVal['txtPeanutVariety'] = lstrxml.pnut_variety_name + ' ®'      
        else if (lstrxml.symbol_ind.trim().toLocaleUpperCase() == 'COPYRIGHT')
          objVal['txtPeanutVariety'] = lstrxml.pnut_variety_name + ' ©'      
        else
          objVal['txtPeanutVariety'] = lstrxml.pnut_variety_name             
      }
      else
        objVal['txtPeanutVariety'] = lstrxml.pnut_variety_name               

      if (lstrxml.seed_gen == 'F')
        objVal['txtSeedGen'] = 'Foundation'                               
      else if (lstrxml.seed_gen == 'R')
        objVal['txtSeedGen'] = 'Registered'                               
      else if (lstrxml.seed_gen == 'C')
        objVal['txtSeedGen'] = 'Certified'                                
      else if (lstrxml.seed_gen == '' || lstrxml.seed_gen == null)
        objVal['txtSeedGen'] = ''                                         

      if (lstrxml.seg_type == '1')
        objVal['txtSeg1'] = 'Seg 1'                                       
      else if (lstrxml.seg_type == '2')
        objVal['txtSeg1'] = 'Seg 2'                                       
      else if (lstrxml.seg_type == '3')
        objVal['txtSeg1'] = 'Seg 3'                                       

      if (lstrxml.edible_oil_ind == 'E')
        objVal['txtEdibleOil'] = 'Edible'                                 
      else if (lstrxml.edible_oil_ind == 'O')
        objVal['txtEdibleOil'] = 'Oil'                                    
      else if (lstrxml.edible_oil_ind == '')
        objVal['txtEdibleOil'] = ''                                      

      if (lstrxml.oleic_ind == 'H')
        objVal['txtOleic'] = 'High'                                      
      else if (lstrxml.oleic_ind == 'M')
        objVal['txtOleic'] = 'Mid'                                       
      else if (lstrxml.oleic_ind == 'L')
        objVal['txtOleic'] = 'Low'                                       
      else if (lstrxml.oleic_ind == '')
        objVal['txtOleic'] = ''                                          

      if (lstrxml.organic_ind !== null) {
        if (lstrxml.organic_ind.toLocaleUpperCase() == 'Y')
          objVal['chkboxOrganic'] = 1                                  
        else
          objVal['chkboxOrganic'] = 0                                  
      }

      if (lstrxml.seed_meeting_spec_ind !== null) {
        if (lstrxml.seed_meeting_spec_ind.trim().toLocaleUpperCase() == 'Y')
          objVal['txtSeedMeetingSpec'] = 'Yes'                                
        else if (lstrxml.seed_meeting_spec_ind.trim().toLocaleUpperCase() == 'N')
          objVal['txtSeedMeetingSpec'] = 'No'                                 
        else
          objVal['txtSeedMeetingSpec'] = ''                                   
      }
      else
        objVal['txtSeedMeetingSpec'] = ''                                     

      objVal['txtProbingPattern'] = lstrxml.probe_pattern                     

      if (lstrxml.corn_ind.trim().toLocaleUpperCase() == 'Y')
        objVal['chkboxCornFound'] = 1                                         
      else
        objVal['chkboxCornFound'] = 0                                         

      if (lstrxml.flavus_ind.trim().toLocaleUpperCase() == 'Y')
        objVal['chkboxFlavusFound'] = 1                                       
      else
        objVal['chkboxFlavusFound'] = 0                                       

      if (lstrxml.vicam_ind.trim().toLocaleUpperCase() == 'Y') {
        objVal['chkboxVicam'] = 1                                             
        objVal['txtReading'] = lstrxml.vicam_ppb                              
      }
      else {
        objVal['chkboxVicam'] = 0                                             
        objVal['txtReading'] =  ''                                            
      }

      response = await SettlementService.RetrieveWhouseBinInventoryDetails(buyPtId, lstrxml.unld_whse_id, lstrxml.unld_bin_id, null, null)
      if (response.status == 500)
        return;

      LstrXML_Sub = response[0];

      objVal['txtWhouseBin'] = lstrxml.unld_whse_id + ' - ' + lstrxml.unld_bin_id + ' - ' + ([ undefined, null ].includes(LstrXML_Sub) ? '' : LstrXML_Sub.whouseBinId) 

      response = await SettlementService.RetrieveBuyingPointControlDetails('PN1060', null, null, null, lstrxml.obl_buy_id )
      // if (response.length <= 0)
      //   return;
      //INC5264745
      LstrXML_Sub = response[0];
      //objVal['txtBuyingPt'] = lstrxml.obl_buy_id + ' - ' + lstrxml.buy_pt_nLdblDataRowsame 
      let strbuyPtName="";
      if(LstrXML_Sub!=undefined)
      {
      if(LstrXML_Sub.buyPtName!=null && LstrXML_Sub.buyPtName!="" && LstrXML_Sub.buyPtName!=undefined)
      strbuyPtName=LstrXML_Sub.buyPtName;
      }
      objVal['txtBuyingPt'] = lstrxml.obl_buy_id + ' - ' + strbuyPtName
      if (lstrxml.obl_whse_id == '' && lstrxml.obl_bin_id == '')
        objVal['txtObligationPt'] = '' 
      else {
        let resp = await SettlementService.RetrieveWhouseBinInventoryDetails(lstrxml.obl_buy_id, lstrxml.obl_whse_id, lstrxml.obl_bin_id, null, null)
        if (resp.status == 500)
          return;
        LstrXML_Sub = resp[0];
        objVal['txtObligationPt'] = lstrxml.obl_whse_id + ' - ' + lstrxml.obl_bin_id + ' - ' + ([ undefined, null ].includes(LstrXML_Sub) ? '' : LstrXML_Sub.whouseBinId)
      }

      if (lstrxml.insp_status !== null) {
        switch (lstrxml.insp_status.trim().toLocaleUpperCase()) {
          case 'V':
            objVal['txtStatus'] = 'Voided' 
            break;
          case 'N':
            objVal['txtStatus'] = 'No Sale'
            break;
          case 'I':
            if (Number(lstrxml.settlement_num) !== 0) {
              objVal['txtStatus'] = 'In Process - C' 
            }
            else {
              if (Number(lstrxml.superseded_settle) !== 0 && Number(lstrxml.settlement_num) !== 0)
                objVal['txtStatus'] = 'In Process - S' 
              else
                objVal['txtStatus'] = 'Inspected' 
            }
            break;
          case 'S':
            if (lstrxml.kc_trans_ind.trim().toLocaleUpperCase() !== 'Y') {
              objVal['txtStatus'] = 'Unsigned'
            }
            else {
              if (lstrxml.kc_trans_date.trim().toLocaleUpperCase() !== '') {
                if (Number(lstrxml.settle_correct_cnt.trim().toLocaleUpperCase()) > 0 && lstrxml.superseded_settle.trim() !== '' && Number(lstrxml.superseded_settle.trim()) !== 0)
                  objVal['txtStatus'] = 'Settled - SC' + lstrxml.settle_correct_cnt 
                else if (Number(lstrxml.settle_correct_cnt) > 0)
                  objVal['txtStatus'] = 'Settled - C' + lstrxml.settle_correct_cnt 
                else if (lstrxml.superseded_settle.trim() !== '' && Number(lstrxml.superseded_settle.trim()) !== 0)
                  objVal['txtStatus'] = 'Settled - S' 
                else
                  objVal['txtStatus'] = 'Settled' 
              }
              else {
                objVal['txtStatus'] = 'Signed' 
              }
            }
            break;
          default:
            objVal['txtStatus'] = 'Pending' 
            break;
        }
      }
      else
        objVal['txtStatus'] = 'Pending' 

      if (lstrxml.insp_status !== null) {
        if (lstrxml.insp_status.trim().toLocaleUpperCase() == 'I')
          enable(thisObj, 'btnVoid')
        else
          disable(thisObj, 'btnVoid')
      }
      else
        disable(thisObj, 'btnVoid')

      if (lstrxml.insp_status !== null) {
        if (lstrxml.insp_status.trim().toLocaleUpperCase() == 'S') {
          disable(thisObj, 'btnCorrection')
          disable(thisObj, 'btnSupersede')
          enable(thisObj, 'btnReprint')

          if (lstrxml.kc_trans_date !== null) {
            if (lstrxml.kc_trans_date.trim() !== '') {
              if (Number(lstrxml.settle_correct_cnt) > 0 && lstrxml.superseded_settle !== '' && Number(lstrxml.superseded_settle) !== 0)
                disable(thisObj, 'btnReprint')
              else if (Number(lstrxml.settle_correct_cnt) > 0)
                disable(thisObj, 'btnReprint')
            }
          }

          if (lstrxml.kc_trans_ind !== null) {
            if (lstrxml.kc_trans_ind.trim().toLocaleUpperCase() == 'Y') {
              disable(thisObj, 'btnVoid');
              if (lstrxml.kc_trans_date !== '') {
                let resp = await ContractManagementService.RetrieveAccessPermissionDetails('PN1060', '004', 'U', buyPtId)
                if (resp.length > 0) {
                  if (resp[0].permission == 'N')
                    disable(thisObj, 'btnCorrection')
                  else
                    enable(thisObj, 'btnCorrection')
                }

                resp = await ContractManagementService.RetrieveAccessPermissionDetails('PN1060', '005', 'U', buyPtId)
                if (resp.length > 0) {
                  if (resp[0].permission == 'N')
                    disable(thisObj, 'btnSupersede')
                  else
                    enable(thisObj, 'btnSupersede')
                }

                resp = await ContractManagementService.RetrieveAccessPermissionDetails('PN1060', '011', 'U', buyPtId)
                if (resp.length > 0) {
                  if (resp[0].permission == 'N')
                    hide(thisObj, 'btnResendToKCMO', false)
                  else
                    show(thisObj, 'btnResendToKCMO')
                }
              }
            }
            else {
              let resp = await ContractManagementService.RetrieveAccessPermissionDetails('PN1060', '002', 'U', buyPtId)
              if (resp.length > 0) {
                if (resp[0].permission == 'N')
                  disable(thisObj, 'btnVoid')
                else
                  enable(thisObj, 'btnVoid')
              }
              disable(thisObj, 'btnSupersede')
            }
          }
        }
        else {
          if (lstrxml.insp_status.trim().toLocaleUpperCase() == 'V') {
            disable(thisObj, 'btnAcctDist')
            disable(thisObj, 'btnCorrection')
            disable(thisObj, 'btnSupersede')
            disable(thisObj, 'btnVoid')
          }
          disable(thisObj, 'btnReprint')
        }
      }

      objVal['txt1007'] = '0'.repeat(7-lstrxml.settlement_num.length) + lstrxml.settlement_num 
      if (lstrxml.settlement_date !== '') {
        if (Number(new Date(lstrxml.settlement_date).getFullYear()) < 1950)
          objVal['txtSettleDate'] = '' 
        else
          objVal['txtSettleDate'] = lstrxml.settlement_date.split(' ')[0] 
      }
      else
        objVal['txtSettleDate'] = '' 

      objVal['txtCorrectionCount'] = lstrxml.settle_correct_cnt 
      objVal['txtSupercededSC95'] = lstrxml.superseded_insp     
      objVal['txtSuperceded1007'] = lstrxml.superseded_settle   

      objVal['txtEWeightTkt'] = lstrxml.scale_list    
      objVal['txtEVehicleNum'] = lstrxml.vehicle_list 

      objVal['txtEWtInclVeh'] = lstrxml.e_wt_including_vehicle 
      objVal['txtEWtOfVeh'] = lstrxml.e_wt_of_vehicle          
      objVal['txtEWtOfPnut'] = lstrxml.e_wt_of_peanuts         

      objVal['txtFMSampleWt'] = lstrxml.fm_sample       
      objVal['txtCleanSampleWt'] = lstrxml.cln_sample   
      objVal['txtSMKRSGr'] = lstrxml.smk_gr             
      objVal['txtSoundSplitsGr'] = lstrxml.ss_gr        
      objVal['txtOtherKernelsGr'] = lstrxml.ok_gr       
      objVal['txtDamageSplits'] = lstrxml.dam_split_gr  
      objVal['txtTotalDamageGr'] = lstrxml.dam_gr       
      objVal['txtHullsGr'] = lstrxml.hull_gr            
      objVal['txtFreezeDamageGr'] = lstrxml.frez_dam_gr 
      objVal['txtConcealedRMDGr'] = lstrxml.cncl_rmd_gr 
      objVal['txtHullBrightness'] = lstrxml.hul_brit_gr 
      objVal['txtJumboGr'] = lstrxml.jumbo_gr           
      objVal['txtELKGr'] = lstrxml.elk_gr               
      objVal['txtForeignMaterialGr'] = lstrxml.fm_gr    
      objVal['txtLSKGr'] = lstrxml.lsk_gr               
      objVal['txtFancyGr'] = lstrxml.fan_gr             

      objVal['txtSMK1Gr'] = lstrxml.smk_1_pct     
      objVal['txtSMK2Gruic0'] = lstrxml.smk_2_pct 
      objVal['txtSMK3Gr'] = lstrxml.smk_3_pct     
      objVal['txtSMK4Gr'] = lstrxml.smk_4_pct     

      objVal['txtTotalKrnls'] = Number(lstrxml.tkc_pct).toFixed(0)  
      objVal['txtHullBrightnessPct'] = lstrxml.hul_brit_pct         
      objVal['txtJumboPct'] = lstrxml.jumbo_pct                     

      if(lblPeanutTypeTag == 'VA'){
        show(thisObj, 'grpbxVirginiaGrading')
        objVal['txtSampleWt'] = lstrxml.va_cr_br_sample
        objVal['txtGrams'] = lstrxml.va_cr_br_gram
        objVal['txtPercent'] = lstrxml.va_cr_br_pct
        disable(thisObj,'txtSampleWt')
        disable(thisObj, 'txtGrams')
        disable(thisObj,'txtPercent')
      }
      else{
        hide(thisObj, 'grpbxVirginiaGrading', true)
      }

      if (lblPeanutTypeTag == 'VL') {
        show(thisObj, 'txtValenciaCleanSampleWt')
        show(thisObj, 'txtCrkOrBrkShellsGr')
        show(thisObj, 'txtCrkOrBrkShellsPct')
        show(thisObj, 'txtDiscoloredShellsGr')
        show(thisObj, 'txtDiscoloredShellsPct')

        objVal['txtValenciaCleanSampleWt'] = lstrxml.vl_cln_sample 
        objVal['txtCrkOrBrkShellsGr'] = lstrxml.cr_br_gr           
        objVal['txtDiscoloredShellsGr'] = lstrxml.dcol_shel_gr     
        objVal['txtCrkOrBrkShellsPct'] = lstrxml.cr_br_pct         
        objVal['txtDiscoloredShellsPct'] = lstrxml.dcol_shel_pct   
      }
      else {
        hide(thisObj, 'txtValenciaCleanSampleWt')
        hide(thisObj, 'txtCrkOrBrkShellsGr',false)
        hide(thisObj, 'txtCrkOrBrkShellsPct',false)
        hide(thisObj, 'txtDiscoloredShellsGr',false)
        hide(thisObj, 'txtDiscoloredShellsPct',false)
      }

      if (!isNaN(Number(lstrxml.hmoist_pct)) && lstrxml.hmoist_pct !== '')
        objVal['txtMoistureReading'] = lstrxml.hmoist_pct 
      else
        objVal['txtMoistureReading'] = lstrxml.moist_pct 
      strHMC = Number(objVal['txtMoistureReading']) > 10.49 ? 'Y' : 'N';

      if (strHMC == 'Y') {
        if (lstrxml.hmc_seg == '1')
          objVal['txtSeg'] = 'Seg 1' 
        else if (lstrxml.hmc_seg == '2')
          objVal['txtSeg'] = 'Seg 2' 
        else if (lstrxml.hmc_seg == '3')
          objVal['txtSeg'] = 'Seg 3' 

        objVal['txtSMKRSPct'] = lstrxml.hsmk_pct            
        objVal['txtSoundSplitsPct'] = lstrxml.hss_pct       
        objVal['txtOtherKernelsPct'] = lstrxml.hok_pct      
        objVal['txtTotalDamagePct'] = lstrxml.hdam_pct      
        objVal['txtHullsPct'] = lstrxml.hhull_pct           
        objVal['txtFreezeDamagePct'] = lstrxml.frez_dam_pct 
        objVal['txtConcealedRMDPct'] = lstrxml.cncl_rmd_pct 
        objVal['txtELKPct'] = lstrxml.helk_pct              
        objVal['txtForeignMaterialPct'] = lstrxml.hfm_pct   
        objVal['txtLSKPct'] = lstrxml.hlsk_pct              
        objVal['txtFancyPct'] = lstrxml.hfan_pct            

        objVal['txtSeg'] = lstrxml.seg_type                                                                                              
        objVal['txtFM'] = lstrxml.fm_pct                                                                                                 
        objVal['txtLSK'] = lstrxml.lsk_pct                                                                                               
        objVal['txtMST'] = lstrxml.moist_pct                                                                                             
        objVal['txtSMK'] = lstrxml.smk_pct                                                                                               
        objVal['txtSS'] = lstrxml.ss_pct                                                                                                 
        objVal['txtSMKRS'] = Number(Number(lstrxml.smk_pct) + Number(lstrxml.ss_pct)).toFixed(2)                                                                                                                      
        objVal['txtOK'] = lstrxml.ok_pct                                                                                                 
        objVal['txtFRZ'] = lstrxml.frez_dam_pct                                                                                          
        objVal['txtCRMD'] = lstrxml.cncl_rmd_pct                                                                                         
        objVal['txtDAM'] = lstrxml.dam_pct                                                                                               
        objVal['txtTotalKernels'] = Number(Number(lstrxml.smk_pct) + Number(lstrxml.ss_pct) + Number(lstrxml.ok_pct) + Number(lstrxml.dam_pct)).toFixed(2) 
        objVal['txtHULLS'] = lstrxml.hull_pct                                                                                            
        objVal['txtELK'] = lstrxml.elk_pct                                                                                               
        objVal['txtFANCY'] = lstrxml.fan_pct                                                                                             
        objVal['txtTotKrnlsHulls'] = Number(Number(lstrxml.smk_pct) + Number(lstrxml.ss_pct) + Number(lstrxml.ok_pct) + Number(lstrxml.dam_pct) + Number(lstrxml.hull_pct)).toFixed(2)            
      }
      else {
        objVal['txtSMKRSPct'] = lstrxml.smk_pct             
        objVal['txtSoundSplitsPct'] = lstrxml.ss_pct        
        objVal['txtOtherKernelsPct'] = lstrxml.ok_pct       
        objVal['txtTotalDamagePct'] = lstrxml.dam_pct       
        objVal['txtHullsPct'] = lstrxml.hull_pct            
        objVal['txtFreezeDamagePct'] = lstrxml.frez_dam_pct 
        objVal['txtConcealedRMDPct'] = lstrxml.cncl_rmd_pct 
        objVal['txtELKPct'] = lstrxml.elk_pct               
        objVal['txtForeignMaterialPct'] = lstrxml.fm_pct    
        objVal['txtLSKPct'] = lstrxml.lsk_pct               
        objVal['txtFancyPct'] = lstrxml.fan_pct             
      }

      let value1 = strHMC == 'Y' ? lstrxml.hsmk_pct : lstrxml.smk_pct
      let value2 = strHMC == 'Y' ? lstrxml.hss_pct : lstrxml.ss_pct
      let value3 = strHMC == 'Y' ? lstrxml.hok_pct : lstrxml.ok_pct
      let value4 = strHMC == 'Y' ? lstrxml.hdam_pct : lstrxml.dam_pct
      objVal['txtTotalKrnls'] = Number(Number(value1) + Number(value2) + Number(value3) + Number(value4)).toFixed(0) 

      objVal['txtForeignMaterial'] = lstrxml.oth_fm 
      objVal['txtSMK1Gr'] = lstrxml.smk_1_guage     
      objVal['txtSMK2Gruic0'] = lstrxml.smk_2_guage 
      objVal['txtSMK3Gr'] = lstrxml.smk_3_guage     
      objVal['txtSMK4Gr'] = lstrxml.smk_4_guage     

      objVal['txtWtIncludingVehicle'] = lstrxml.tare_wt                        
      objVal['txtWtOfVehicle'] = lstrxml.vehicle_wt                            
      objVal['txtGrossWeight'] = lstrxml.gross_wt                              
      objVal['txtForeignMaterial'] = lstrxml.fm_wt                             
      objVal['txtWtlessFM'] = Number(lstrxml.gross_wt) - Number(lstrxml.fm_wt) 
      objVal['txtExcessMoisture'] = lstrxml.ex_moist_wt                        
      objVal['txtNetWeight'] = lstrxml.net_wt                                  
      objVal['txtLSKvc'] = lstrxml.lsk_wt                                      

      objVal['txtNetWtExclLSK'] = Number(lstrxml.net_wt) - Number(lstrxml.lsk_wt)                        
      objVal['txtKernelValueTon'] = lstrxml.kern_value                                                   
      objVal['txtKernelValueLb'] = (Number(lstrxml.kern_value) / 20).toFixed(2)                          
      objVal['txtELKPremiumTon'] = lstrxml.elk_prem                                                      
      objVal['txtELKPremiumLb'] = (Number(lstrxml.elk_prem) / 20).toFixed(2)                             
      objVal['txtTotalTon'] = Number(lstrxml.kern_value) + Number(lstrxml.elk_prem)                      
      objVal['txtTotalLb'] = (((Number(lstrxml.kern_value) + Number(lstrxml.elk_prem)) / 20)).toFixed(2) 
      objVal['txtDamageTon'] = lstrxml.dam_discount                                                      
      objVal['txtDamageLbs'] = (Number(lstrxml.dam_discount) / 20).toFixed(2)                            
      objVal['txtAccessFMTon'] = lstrxml.ex_fm_discount                                                  
      objVal['txtAccessFMLb'] = (Number(lstrxml.ex_fm_discount) / 20).toFixed(2)                         
      objVal['txtExcessSplitsTon'] = lstrxml.ex_ss_discount                                              
      objVal['txtExcessSplitsLb'] = (Number(lstrxml.ex_ss_discount) / 20).toFixed(2)                    
      objVal['txtAFlavusTon'] = lstrxml.flavus_discount                                                  
      objVal['txtAFlavusLb'] = (Number(lstrxml.flavus_discount) / 20).toFixed(2)                         
      objVal['txtTotalDiscountsTon'] = (Number(lstrxml.dam_discount) + Number(lstrxml.ex_fm_discount) + Number(lstrxml.ex_ss_discount) + Number(lstrxml.flavus_discount)).toFixed(2)
      objVal['txtlblTotalDiscountsLb'] = ((Number(lstrxml.dam_discount) + Number(lstrxml.ex_fm_discount) + Number(lstrxml.ex_ss_discount) + Number(lstrxml.flavus_discount)) / 20).toFixed(2) 
      objVal['txtNetValPerTonExclSLK'] = lstrxml.net_les_lsk_val                                        
      objVal['txtValPerPoundExclSLK'] = (Number(lstrxml.net_les_lsk_val) / 20).toFixed(5)               
      objVal['txtG'] = Number(Number(lstrxml.net_price) * 100).toFixed(5)                                                  
      objVal['txtTotalLoadVal'] = Number(lstrxml.basis_grade_amt).toFixed(2)                                               

      objVal['txtOI'] = ((objVal['txtValPerPoundExclSLK'] * 0.01) * objVal['txtNetWtExclLSK']).toFixed(2)                                                                  
      objVal['txtJ'] = (Number(lstrxml.basis_grade_amt) - (((Number(lstrxml.net_les_lsk_val) / 20) * 0.01) * Number(lstrxml.net_wt - lstrxml.lsk_wt))).toFixed(2) 
      objVal['txtH'] = Number(Number(objVal['txtTotalLoadVal']) - Number(objVal['txtOI'])).toFixed(2)
      if (Number(lstrxml.lsk_wt) == 0)
      document.getElementsByClassName('txtH')[0].firstChild.firstChild.innerHTML = "H x  :"
      else
        document.getElementsByClassName('txtH')[0].firstChild.firstChild.innerHTML = "H x " + Number(Number(objVal['txtH'])/ Number(lstrxml.lsk_wt)).toFixed(2)

      objVal['txtTotalSMK'] = (Number(objVal['txtSoundSplitsPct']) + Number(objVal['txtSMKRSPct']))
      objVal['txtTotalKernelsHulls'] = (Number(lstrxml.smk_pct) + Number(lstrxml.ok_pct) + Number(lstrxml.dam_pct) + Number(lstrxml.ss_pct) + Number(lstrxml.hull_pct)) 

      objVal['lblAddedByValue'] = lstrxml.add_user + ' ' + lstrxml.add_date_time   
      objVal['lblChangedByValue'] = lstrxml.chg_user + ' ' + lstrxml.chg_date_time 

      lstChangeDateTime = lstrxml.chg_date_time;
      setData(thisObj, 'lstChangeDateTime', lstrxml.chg_date_time)

      nControlIndex = 0
      
      objVal['txtNetWeightPrchs'] = '0'           
      objVal['txtNetWeightStrg'] = '0'            

      objVal['txtPremiumsPrchs'] = '0.00'         
      objVal['txtDeductionsPrchs'] = '0.00'       
      objVal['txtProceedsPrchs'] = '0.00'         

      objVal['txtValueOfSegPrchs'] = '0.00'       
      objVal['txtDeductionsStrg'] = '0.00'        
      objVal['txtProceedsStrg'] = '0.00'          

      objVal['txtSeedPremiumStrg'] = '0.00'       
      objVal['txtFarmerStockCostPrchs'] = '0.00'  
      objVal['txtOptionPayment'] = '0.00'         

      bWRExists = false;
      let cboVendor = [];

      let lblPurchNetWeight = 0;
      let lblPurchValueOfLoad = 0;
      let lblFarmerStockCost = 0;
      let lblOptionPayment = 0;
      let lblPurchValueOfSeg = 0;
      let lblPurchPremiums = 0;
      let lblPurchDeductions = 0;
      let lblPurchProceeds = 0;

      let lblStoresNetWeight = 0;
      let lblStoresValueOfLoad = 0;
      let lblStoresValueOfSeg = 0;
      let lblStoresSeedPremium = 0;
      let lblStoresDeductions = 0;
      let lblStorProceeds = 0;
//PRB0052905 - Added to fix the whse rcpt issue for the inspect_vendor_applications has more than one record for vendor splits
      for (let x = 0; x < sVendorSplits.length; x++) {
        let js = []
        for (var i = 0; i < lstrxml.inspect_vendor_applications.length; i++) {
          if (lstrxml.inspect_vendor_applications[i].rcpt_vendor == sVendorSplits[x].split_vendor) {
            bWRExists = true
            let whse_rct_num = "0".repeat(7 - lstrxml.inspect_vendor_applications[i].whse_rcpt_num.length) + lstrxml.inspect_vendor_applications[i].whse_rcpt_num
            js.push({
              key: '',
              description: lstrxml.inspect_vendor_applications[i].rcpt_buy_pt + " - " + whse_rct_num
            })
          }
        }
        if (js.length == 0) {
          js.push({
            key: '',
            description: ''
          })
        }

        thisObj.setState(current => {
          return {
            ...current,
            [`ddWhseRcpt${x}`]: {
              ...state['ddWhseRcpt' + x],
              valueList: js
            }
          }
        })

        objVal['ddWhseRcpt' + x] = js.at(0).key
        setData(thisObj, 'ddWhseRcpt' + x, js.at(0).key);
        enable(thisObj, 'ddWhseRcpt' + x)
      }
      // if (sVendorSplits.length > 0 && js.length == [])
      //   js.push({ key: '', description: '' })

      for (var i = 0; i < sVendorSplits.length; i++) {
        bFound = false;
        // let ddValue = ''

        // if (lstrxml.inspect_vendor_applications.length > 0)
        //   ddValue = lstrxml.inspect_vendor_applications[0].rcpt_buy_pt + " - " + lstrxml.inspect_vendor_applications[0].whse_rcpt_num

        objVal['txtVendor' + i] = sVendorSplits[i].split_vendor + ' - ' + sVendorSplits[i].vendor_name 

        document.getElementById('txtVendor' + i).style.color = "blue";
        // thisObj.setState(current => {
        //   return {
        //     ...current,
        //     [`ddWhseRcpt${i}`]: {
        //       ...state['ddWhseRcpt' + i],
        //       valueList: js
        //     }
        //   }
        // })
        // objVal['ddWhseRcpt' + i] = js.at(0).key 
        // setData(thisObj, 'ddWhseRcpt' + i, js.at(0).key);
        // enable(thisObj, 'ddWhseRcpt' + i)
        if (sVendorSplits[i].split_remit == '000')
          objVal['txtRemitTo' + i] = sVendorSplits[i].split_remit + ' - ' + sVendorSplits[i].vendor_name 
        else
          objVal['txtRemitTo' + i] = sVendorSplits[i].split_remit + ' - ' + sVendorSplits[i].remit_name  

        objVal['txtSharePct' + i] = sVendorSplits[i].split_share_pct.split('.')[0]                        
        objVal['txtPounds' + i] = sVendorSplits[i].net_wt                                                 
        objVal['txtValue' + i] = (Number(sVendorSplits[i].net_wt) * Number(lstrxml.net_price)).toFixed(2) 
        
        if(lstrxml?.cma_vendor_num !== ""){
          show(thisObj, 'lblCMA' , true)
          show(thisObj, 'txtCMA' + i, true)
          objVal['txtCMA' + i] = sVendorSplits[i]?.cma_participant_ind == 'N' ? 'N' : 'Y'
        }
        else{
          hide(thisObj, 'lblCMA' , true)
          hide(thisObj, 'txtCMA' + i, true)
          objVal['txtCMA' + i] = ''
        } 

        if (sVendorSplits[i].purh_stor_ind.trim().toLocaleUpperCase() == 'P') {
          if (sVendorSplits[i].whse_rcpt_num == '' || sVendorSplits[i].whse_rcpt_num == null) {
            objVal['txtPorS' + i] = 'P' 

            lblPurchNetWeight += Number(sVendorSplits[i].net_wt == '' ? 0 : sVendorSplits[i].net_wt)
            lblPurchValueOfLoad += Number(Number(Number(sVendorSplits[i].net_wt == '' ? 0 : sVendorSplits[i].net_wt) * Number(lstrxml.net_price == '' ? 0 : lstrxml.net_price)).toFixed(2))
            lblFarmerStockCost += Number(sVendorSplits[i].value_of_seg == '' ? 0 : sVendorSplits[i].value_of_seg)
            lblOptionPayment += Number(sVendorSplits[i].opt_value_of_seg == '' ? 0 : sVendorSplits[i].opt_value_of_seg)
            lblPurchValueOfSeg += Number(sVendorSplits[i].value_of_seg == '' ? 0 : sVendorSplits[i].value_of_seg) - Number(sVendorSplits[i].opt_value_of_seg == '' ? 0 : sVendorSplits[i].opt_value_of_seg)
            lblPurchPremiums += Number(sVendorSplits[i].premium_amt == '' ? 0 : sVendorSplits[i].premium_amt)
            lblPurchDeductions += Number(sVendorSplits[i].deduct_amt == '' ? 0 : sVendorSplits[i].deduct_amt)
            lblPurchProceeds += Number(sVendorSplits[i].proceeds_amt == '' ? 0 : sVendorSplits[i].proceeds_amt)

            objVal['txtContractSpotPricePrchs'] = sVendorSplits[i].value_per_ton 
            objVal['txtPctOfSupportPrchs'] = sVendorSplits[i].support_pct   

            objVal['txtOptionPriceStrg'] =  '' 
            objVal['txtPctOfSupportStrg'] =  '' 
          }
          else {
            objVal['txtPorS' + i] = 'S' 

            lblStoresNetWeight += Number(sVendorSplits[i].net_wt == '' ? 0 : sVendorSplits[i].net_wt)
            lblStoresValueOfLoad += Number(Number(Number(sVendorSplits[i].net_wt == '' ? 0 : sVendorSplits[i].net_wt) * Number(lstrxml.net_price == '' ? 0 : lstrxml.net_price)).toFixed(2))
            lblStoresValueOfSeg += Number(sVendorSplits[i].value_of_seg == '' ? 0 : sVendorSplits[i].value_of_seg)
            lblStoresSeedPremium += Number(sVendorSplits[i].premium_amt == '' ? 0 : sVendorSplits[i].premium_amt)
            lblStoresDeductions += Number(sVendorSplits[i].deduct_amt == '' ? 0 : sVendorSplits[i].deduct_amt)
            lblStorProceeds += Number(sVendorSplits[i].proceeds_amt == '' ? 0 : sVendorSplits[i].proceeds_amt)

            setValue(thisObj, 'txtOptionPriceStrg', sVendorSplits[i].value_per_ton)
            setValue(thisObj, 'txtPctOfSupportStrg', sVendorSplits[i].support_pct)
            objVal['txtContractSpotPricePrchs'] = '' 
            objVal['txtPctOfSupportPrchs'] = ''      
          }
        }
        else if (sVendorSplits[i].purh_stor_ind.trim().toLocaleUpperCase() == 'S' || sVendorSplits[i].purh_stor_ind.trim().toLocaleUpperCase() == 'R' || sVendorSplits[i].purh_stor_ind.trim().toLocaleUpperCase() == 'D') {
          objVal['txtPorS' + i] = 'S' 

          lblStoresNetWeight += Number(sVendorSplits[i].net_wt == '' ? 0 : sVendorSplits[i].net_wt)
          lblStoresValueOfLoad += Number(Number(Number(sVendorSplits[i].net_wt == '' ? 0 : sVendorSplits[i].net_wt) * Number(lstrxml.net_price == '' ? 0 : lstrxml.net_price)).toFixed(2))
          lblStoresValueOfSeg += Number(sVendorSplits[i].value_of_seg == '' ? 0 : sVendorSplits[i].value_of_seg)
          lblStoresSeedPremium += Number(sVendorSplits[i].premium_amt == '' ? 0 : sVendorSplits[i].premium_amt)
          lblStoresDeductions += Number(sVendorSplits[i].deduct_amt == '' ? 0 : sVendorSplits[i].deduct_amt)
          lblStorProceeds += Number(sVendorSplits[i].proceeds_amt == '' ? 0 : sVendorSplits[i].proceeds_amt)

          objVal['txtOptionPriceStrg'] = sVendorSplits[i].value_per_ton 
          objVal['txtPctOfSupportStrg'] = sVendorSplits[i].support_pct  
          objVal['txtContractSpotPricePrchs'] = '' 
          objVal['txtPctOfSupportPrchs'] = '' 
        }
        nControlIndex++;
      }
      
      for(var j= i; j<20; j++){
        hide(thisObj, 'txtCMA'+ j, true)
      }

      let x1 = new Date()
      objVal['txtNetWeightPrchs'] = lblPurchNetWeight == 0 || lblPurchNetWeight == '' ? '0' : lblPurchNetWeight
      objVal['txtTotalLoadValuePrchs'] = lblPurchValueOfLoad == 0 || lblPurchValueOfLoad == '' ? '0' : Number(lblPurchValueOfLoad).toFixed(2)
      objVal['txtFarmerStockCostPrchs'] = lblFarmerStockCost == 0 || lblFarmerStockCost == '' ? '0' : Number(lblFarmerStockCost).toFixed(2)
      objVal['txtOptionPayment'] = lblOptionPayment == 0 || lblOptionPayment == '' ? '0' : Number(lblOptionPayment).toFixed(2)
      objVal['txtValueOfSegPrchs'] = lblPurchValueOfSeg == 0 || lblPurchValueOfSeg == '' ? '0' : Number(lblPurchValueOfSeg).toFixed(2)
      objVal['txtPremiumsPrchs'] = lblPurchPremiums == 0 || lblPurchPremiums == '' ? '0' : Number(lblPurchPremiums).toFixed(2)
      objVal['txtDeductionsPrchs'] = lblPurchDeductions == 0 || lblPurchDeductions == '' ? '0' : Number(lblPurchDeductions).toFixed(2)
      objVal['txtProceedsPrchs'] = lblPurchProceeds == 0 || lblPurchProceeds == '' ? '0' : Number(lblPurchProceeds).toFixed(2)
      
      objVal['txtNetWeightStrg'] = lblStoresNetWeight == 0 || lblStoresNetWeight == '' ? '0' : lblStoresNetWeight 
      objVal['txtTotalLoadValueStrg'] = lblStoresValueOfLoad == 0 || lblStoresValueOfLoad == '' ? '0.00' : Number(lblStoresValueOfLoad).toFixed(2) 
      objVal['txtValueOfOptionStrg'] = lblStoresValueOfSeg == 0 || lblStoresValueOfSeg == '' ? '0.00' : Number(lblStoresValueOfSeg).toFixed(2) 
      objVal['txtSeedPremiumStrg'] = lblStoresSeedPremium == 0 || lblStoresSeedPremium == '' ? '0.00' : Number(lblStoresSeedPremium).toFixed(2) 
      objVal['txtDeductionsStrg'] = lblStoresDeductions == 0 || lblStoresDeductions == '' ? '0.00' : Number(lblStoresDeductions).toFixed(2) 
      objVal['txtProceedsStrg'] = lblStorProceeds == 0 || lblStorProceeds == '' ? '0.00' : Number(lblStorProceeds).toFixed(2) 

      // js = [{ key: '', description: '' }]
      // objVal['ddWhseRcpt' + i] = js.at(0).key 
      // setData(thisObj, 'ddWhseRcpt' + i, js.at(0).key)

      i--;
      nControlIndex = 0;

      let data = [];
      let data2 = [];
      let totalLbs = 0;
      for (var i = 0; i < sScaleTickets[0].nControlIndex; i++) {
        data.push({
          txtcolDate: sScaleTickets[0].inspect_scale_ticket[i].dry_date.split(' ')[0],
          txtcolScaleTicket: sScaleTickets[0].inspect_scale_ticket[i].dry_ticket,
          txtcolWtOfPeanuts: sScaleTickets[0].inspect_scale_ticket[i].dry_peanut_wt
        })
        data2.push(sScaleTickets[0].inspect_scale_ticket[i])
        totalLbs += (sScaleTickets[0].inspect_scale_ticket[i].dry_peanut_wt == '' ? 0 : Number(sScaleTickets[0].inspect_scale_ticket[i].dry_peanut_wt))
      }

      setData(thisObj, 'scaleTicketData', data2)

      objVal['gridWeightTickets'] = data                    
      objVal['txtTickets'] = data.length                    
      objVal['txtTotalLBs'] = totalLbs                      
      objVal['txtTotalDollar'] = '0'                        
      objVal['txtarInspectorRemarks'] = sRemarks[0].remarks 

      mbTicket_NUMBERS_CURE_CLEAN = lstrxml.ticket_numbers_cure_clean
      mbTicket_CURING_WT_IND = lstrxml.ticket_curing_wt_ind
      mbTicket_CLEANING_WT_IND = lstrxml.ticket_cleaning_wt_ind
      mbTicket_MOISTURE = lstrxml.ticket_moisture
      mbTicket_CLEAN_WT_OTHER = lstrxml.ticket_clean_wt_other
      mbTicket_CURE_WT_OTHER = lstrxml.ticket_cure_wt_other
      mbTicket_CURE_SCHEDULE = lstrxml.ticket_cure_schedule
      mbTicket_CLEAN_SCHEDULE = lstrxml.ticket_clean_schedule
      mbTicket_TOTAL_CURING_COST = lstrxml.ticket_total_curing_cost
      mbTicket_TOTAL_CLEANING_COST = lstrxml.ticket_total_cleaning_cost
      
      objVal['txtTotalDollar'] = (Number(mbTicket_TOTAL_CURING_COST) + Number(mbTicket_TOTAL_CLEANING_COST)) == 0 ? '0' : (Number(mbTicket_TOTAL_CURING_COST) + Number(mbTicket_TOTAL_CLEANING_COST)) 

      mbFormFilling = false;

      if (bWRExists) {
        disable(thisObj, 'btnVoid')
        let response = await ContractManagementService.RetrieveAccessPermissionDetails('PN1060', '007', 'D', buyPtId)
        if (response.length >= 0) {
          if (response[0].permission == 'N')
            hide(thisObj, 'btnFree1007')
          else
            show(thisObj, 'btnFree1007')
        }

        disable(thisObj, 'btnCorrection')
        disable(thisObj, 'btnSupersede')
        if (lstrxml.freE1007.trim() == 'Y') {
          document.getElementById('btnFree1007').innerHTML = "Undo Free 1007"
          if (lstrxml.kc_trans_ind.trim().toLocaleUpperCase() == 'Y') {
            if (lstrxml.kc_trans_date !== '') {
              enable(thisObj, 'btnCorrection')
              enable(thisObj, 'btnSupersede')
            }
          }
        }
      }
      let correctionCount = lstrxml.settle_correct_cnt == '' ? 0 : Number(lstrxml.settle_correct_cnt)
      if (Number(correctionCount) > 0) {
        enable(thisObj, 'btnCorrectionReprint')
        enable(thisObj, 'btnCorrectionLog')
      }
      else {
        disable(thisObj, 'btnCorrectionReprint')
        disable(thisObj, 'btnCorrectionLog')
      }
      
      setFieldValues(thisObj, objVal, true);
      if (!isNaN(lstrxml.insp_num))
        disable(thisObj, 'btnVoid')
      ShowHideVendors(sVendorSplits);
      await Form_Activate()
    }
    catch (err) {
      errorHandler(err);
    }
    finally {
      setLoading(false);
    }
  }

  function ShowHideVendors(sVendorSplits) {
    try {
      let elementsToShow = []
      if (sVendorSplits.length > 4) {
        for (var i = 4; i < sVendorSplits.length; i++) {
          elementsToShow.push('grpbxVendorSplitRow' + i)
        }
        showWidgets(thisObj, elementsToShow)
      }
    }
    catch (err) {
      errorHandler(err);
    }
  }

  const onbtnApplicationsClick = async () => {
    try {
      let js = {
        SC95: getValue(thisObj, 'txtSC95'),
        Insp1007: getValue(thisObj, 'txt1007'),
      }
      setData(thisObj, 'ViewContractApplicationData', js)
      goTo(thisObj, 'Settlements#ViewContractApplication#DEFAULT#true#Click')
    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.onbtnApplicationsClick = onbtnApplicationsClick;

  const onbtnVoidClick = async () => {
    try {
      let lstrxml = [];
      let LstrNumber = '';
      let inspNumber = thisObj.values.txtSC95;
      let number1007 = thisObj.values.txt1007;

      let res = confirm('Are you sure you want to void the Inspection #' + number1007 + '? \n If any checks for this settlement are to be VOIDED... You MUST do that BEFORE voiding a settlement!!!')
      if (!res)
        return;
      LstrNumber = prompt('Confirm the void of the Inspection #' + number1007 + ' by entering the settlement number.')

      if (LstrNumber.trim() == '')
        return;
      if (LstrNumber.trim() == number1007) {
        res = confirm('Doing this operation is IRREVERSABLE!!! \n Are you sure you want to void the settlement #' + number1007 + '. \n If any checks for this settlement are to be VOIDED... You MUST do that BEFORE voiding a settlement!!!')
        if (!res)
          return;

        setLoading(true);
        let lbl = getData(thisObj, 'lstChangeDateTime')
        let dataObj = {
          user_id: useridFromLS(),
          last_date_time: getData(thisObj, 'lstChangeDateTime')
        }

        let lstrxml = await SettlementService.UpdateSettlementVoidDetails(thisObj.values.txtLocation.split(' ')[0].trim(), thisObj.values.txtSC95, dataObj)
        setLoading(false);

        if (lstrxml.message == 'Settlement void updated successfully.') {
          alert('The settlement #' + number1007 + ' has been successfully voided!!!')
          setValue(thisObj, 'txtStatus', 'Void')
          setData(thisObj, 'SC95changeData', {
            flag : true,
            SC95Number : inspNumber
          })
          setData(thisObj, 'selectedRowReload', true);
          goTo(thisObj, 'Settlements#FV95Search#DEFAULT#false#Click')
        }
        else
          alert('An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.')
      }
      else
        alert('The entered settlement number does not match the settlement to be voided!!!')

    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.onbtnVoidClick = onbtnVoidClick;

  const onbtnSupersedeClick = async () => {
    try {
      let strXML = '';
      let strNumber = '';
      let bValidFV95 = false;

      let res = confirm('Are you sure you want to supersede the settlement #' + thisObj.values.txt1007 + '? \n If any checks for this settlement are to be VOIDED... You MUST do that BEFORE superseding a settlement!!!')
      if (!res)
        return;
      strNumber = prompt('Confirm the move to supersede of the settlement #' + thisObj.values.txt1007 + ' by entering the settlement number.')
      if (strNumber == null){
        alert('No settlement number entered.')
        return;
      }        
      if (strNumber.trim() == '') {
        alert('No settlement number entered.')
        return;
      }

      if (strNumber !== thisObj.values.txt1007) {
        alert('The entered settlement number does not match the settlement to be supersedeed!')
        return;
      }

      res = confirm('Doing this operation is IRREVERSABLE!!! \n Are you sure you want to supersede the settlement #' + thisObj.values.txt1007 + ' ? \n If any checks for this settlement are to be VOIDED... You MUST do that BEFORE superseding a settlement!!!')
      if (!res)
        return;

      setLoading(true);
      let dataObj = {
        user_id: useridFromLS(),
        last_date_time: getData(thisObj, 'lstChangeDateTime')
      }

      strXML = await SettlementService.UpdateSettlementSupersedeDetails(thisObj.values.txtLocation.split(' ')[0].trim(), thisObj.values.txtSC95, dataObj)
      setLoading(false);

      if (strXML.message == 'Settlement supersede updated successfully.') {
        alert('The settlement #' + thisObj.values.txt1007 + ' has been successfully moved into supersede state.')
        setValue(thisObj, 'txtStatus', 'Inspection')
      }
      else {
        alert('An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.')
      }

      if (!await GetAllowRenumber()) {
        goTo(thisObj, 'Settlements#FV95Search#DEFAULT#false#Click')
        return;
      }

      res = confirm('Would you like to change the SC95 number?')
      if (!res){
        setData(thisObj, 'selectedRowReload', true);
        goTo(thisObj, 'Settlements#FV95Search#DEFAULT#false#Click')
        return;
      }

      bValidFV95 = false;

      while (!bValidFV95) {
        bValidFV95 = true;

        strNumber = prompt('Please Select New SC95 Number ' + thisObj.values.txtSC95)
        if (strNumber == null){
          alert('SC95 number is required!')
          bValidFV95 = false;
        }          

        if(strNumber == null || strNumber == undefined){
          strNumber = ''
        }
        if (strNumber.trim() == '') {
          alert('SC95 number is required!')
          bValidFV95 = false;
        }
        if (strNumber.trim().length != 7) {
          alert('SC95 must be 7 characters long!')
          bValidFV95 = false;
        }
        if (bValidFV95 && strNumber.trim() == thisObj.values.txtSC95) {
          alert('New SC95 number same as original SC95 number.')
          goTo(thisObj, 'Settlements#FV95Search#DEFAULT#false#Click')
          return;
        }
        if (bValidFV95) {
          strXML = await SettlementService.RetrieveInspectionHeaderList(strNumber, thisObj.values.txtLocation.split(' ')[0].trim(), null, null)
          // if (strXM.length <= 0) {
          //   alert('Error communicating with the database please try again!')
          //   bValidFV95 = false;
          // }
        }
        if(strXML.length > 0){
          if(bValidFV95 && strXML[0].insp_num !== ''){
            alert('Inspection number ' + strNumber.trim() + ' has already been created!')
            bValidFV95 = false;
          }
        }

        if (bValidFV95) {
          let dataObj = {
            user_id: useridFromLS()
          }
          strXML = await SettlementService.UpdateChangeInspectionNumber(compIdFromLS(), cropYearFromLS(), thisObj.values.txtLocation.split(' ')[0].trim(), thisObj.values.txtSC95, strNumber, dataObj)
          if(strXML.status == 500){
            alert('Error communicating with the database please try again!')
            bValidFV95 = false;
          }
        }

        if (bValidFV95 && strXML.message !== 'Change inspection number details successfully.') {
          alert('Change Inspection # was unsuccessful. Please try again.')
          bValidFV95 = false;
        }
        if (bValidFV95) {
          alert('Change Inspection # was successful.')
          setData(thisObj, 'SC95changeData', {
            flag : true,
            SC95Number : strNumber
          })
        }
      }
      setData(thisObj, 'selectedRowReload', true);
      goTo(thisObj, 'Settlements#FV95Search#DEFAULT#false#Click')
      return;
    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.onbtnSupersedeClick = onbtnSupersedeClick;

  const onbtnReprintClick = async () => {
    try {
      let lstrxml = [];
      let buy_pt_id = thisObj.values.txtLocation == null ? null : thisObj.values.txtLocation.split(' ')[0].trim()
      let js = {
        s1007No : thisObj.values.txt1007,
        SC95No: thisObj.values.txtSC95,
        buy_pt_id: thisObj.values.txtLocation == null ? null : thisObj.values.txtLocation.split(' ')[0].trim(),
        headerData: 'REPRINT_FROM_HEADER',
        method_name: 'rptInspectionCertificate'
      }
      lstrxml = await SettlementService.RetrieveCheck1007OneDocumentDetails(buy_pt_id)

      if (lstrxml[0].onedoc_ind == 'Y') {
        js.method_name = 'rptInspectionCertificate_onedoc'
      }
      setData(thisObj, 'ReportPreviewInspectionScreenData', js)
      goTo(thisObj, "Settlements#ReportPreviewInspection#DEFAULT#true#Click")
    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.onbtnReprintClick = onbtnReprintClick;

  const onbtnWorksheetClick = async () => {
    try {
      let js = {
        SC95No: thisObj.values.txtSC95,
        buy_pt_id: thisObj.values.txtLocation == null ? null : thisObj.values.txtLocation.split(' ')[0].trim(),
        headerData: '',
        method_name: 'rptSettlementWorkSheet'
      }
      setData(thisObj, 'ReportPreviewInspectionScreenData', js)
      goTo(thisObj, "Settlements#ReportPreviewInspection#DEFAULT#true#Click")
    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.onbtnWorksheetClick = onbtnWorksheetClick;

  const onbtnPremsDeductClick = async () => {
    try {
      let js = {
        insp_num: thisObj.values.txtSC95,
        buy_pt_id: thisObj.values.txtLocation == null ? null : thisObj.values.txtLocation.split(' ')[0].trim(),
        action: null,
        getvendorinfo: null
      }
      setData(thisObj, "viewPremiumDeductionsOnLoadData", js);
      goTo(thisObj, 'Settlements#ViewPremiumDeductions#DEFAULT#true#Click')
    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.onbtnPremsDeductClick = onbtnPremsDeductClick;

  const onbtnPaymentsClick = async () => {
    try {
      let js = {
        txtTradeInspNum: thisObj.values.txtSC95,
        buy_point_id: thisObj.values.txtLocation == null ? null : thisObj.values.txtLocation.split(' ')[0].trim(),
        lbl1007: thisObj.values.txt1007,
        Function_Name: 'FV95'
      }
      setData(thisObj, 'ReviewPaymentsFormLoadData', js)
      goTo(thisObj, "ContractManagement#ViewPayments#DEFAULT#true#Click");
    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.onbtnPaymentsClick = onbtnPaymentsClick;

  const onbtnResendToKCMOClick = async () => {
    try {
      let res = confirm('Are you sure you want to resend the 1007 data to Kansas City, MO for # ' + thisObj.values.txt1007 + ' ?')
      if (!res)
        return;

      let dataObj = {
        user_id: useridFromLS(),
        comp_id: compIdFromLS(),
        crop_year: cropYearFromLS()?.toString(),
        parm_name_01: "",
        parm_value_01: "",
        parm_name_02: "",
        parm_value_02: "",
        parm_name_03: "BUY_PT_ID",
        parm_value_03: thisObj.values.txtLocation == null ? null : thisObj.values.txtLocation.split(' ')[0].trim(),
        parm_name_04: "SETTLEMENT_NUM",
        parm_value_04: thisObj.values.txt1007,
        parm_name_05: "RESEND_DATETIME",
        parm_value_05: "",
        parm_name_06: "",
        parm_value_06: "",
        parm_name_07: "",
        parm_value_07: "",
        parm_name_08: "",
        parm_value_08: "",
        parm_name_09: "",
        parm_value_09: "",
        parm_name_10: "",
        parm_value_10: "",
        parm_name_11: "",
        parm_value_11: "",
        parm_name_12: "",
        parm_value_12: "",
        parm_name_13: "",
        parm_value_13: "",
        parm_name_14: "",
        parm_value_14: "",
        parm_name_15: "",
        parm_value_15: "",
        parm_name_16: "",
        parm_value_16: "",
        parm_name_17: "",
        parm_value_17: "",
        parm_name_18: "",
        parm_value_18: "",
        parm_name_19: "",
        parm_value_19: "",
        parm_name_20: "",
        parm_value_20: ""
      }
      let strXML = await AccountPayment.CreateBatchJobRequestDetails('ResendFSA1007doc', dataObj)

      if (strXML?.status !== 200){
        showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n\nError:\n\n' + strXML?.message)
      }
      else{
        alert('1007 # ' + thisObj.values.txt1007 + ' will be resent to Kansas City, MO tonight along with the regular nightly file transmission.')
      }
    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.onbtnResendToKCMOClick = onbtnResendToKCMOClick;

  const onbtnDeleteClick = async () => {
    try {
      if(thisObj.state.gridWeightTickets.selected.length <= 0){
        showMessage(thisObj, 'Please select scale ticket.')
        return;
      }

      let data = getData(thisObj, 'scaleTicketData')
      var res = data.find( item => item.dry_ticket == thisObj.state.gridWeightTickets.selected[0].txtcolScaleTicket)   
      data = [ res ]
      let ticketInquiryData = {
        dryTicketData: {
          ticketNumber: data[0].dry_ticket,
          date: moment(data[0].dry_date).format('DD/MM/YYYY'),
          carrier: data[0].dry_carrier,
          vehicleNum: data[0].dry_vehicle_id,
          wtInclVehicle: data[0].dry_tare_wt,
          wtVehicle: data[0].dry_vehicle_wt
        },
        greenTicketData: {
          ticketNumber: data[0].green_ticket,
          date: moment(data[0].green_date).format('DD/MM/YYYY'),
          carrier: data[0].green_carrier,
          vehicleNum: data[0].green_vehicle_id,
          wtInclVehicle: data[0].green_tare_wt,
          wtVehicle: data[0].green_vehicle_wt
        },
        weigherName: data[0].weigher_name
      }
      let Array = []
      let arrTicketInfo = data[0].dry_ticket + "|" + data[0].dry_date + "|" + data[0].dry_carrier + "|" + data[0].dry_dispatch + "|" + data[0].dry_vehicle_id + "|" + data[0].dry_tare_wt + "|" + data[0].dry_vehicle_wt + "|" + data[0].dry_peanut_wt + "|"
      arrTicketInfo += data[0].green_ticket + "|" + data[0].green_date + "|" + data[0].green_carrier + "|" + data[0].green_dispatch + "|" + data[0].green_vehicle_id + "|" + data[0].green_tare_wt + "|" + data[0].green_vehicle_wt + "|" + data[0].green_peanut_wt + "|" + data[0].weigher_name
      Array.push(arrTicketInfo)
      setData(thisObj, 'UpdateData', [{ arrTicketInfo }])

      let js = {
        lblHMC_IND: Number(getValue(thisObj, 'txtMoistureReading')) > 10.49 ? 'Y' : 'N',
        ticketUploadMod: '',
        lblBuyingPoint: thisObj.values.txtLocation == null ? null : thisObj.values.txtLocation.split(' ')[0].trim(),
        cmdOk_Caption: "Inquiry",
        lblElecUploadInd: getValue(thisObj, 'lblSC95ES') == 'SC95 Electronically Submitted' ? 'Y' : 'N',
        inspNum: thisObj.values.txtSC95,
        arrTicketInfo: Array
      }
      setData(thisObj, 'frmScaleTickets', js)
      goTo(thisObj, "Settlements#ScaleTickets#DEFAULT#true#Click")
    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick;

  const onbtnDeductTrackClick = async () => {
    try {
      let js = {
        buy_point_id: thisObj.values.txtLocation == null ? null : thisObj.values.txtLocation.split(' ')[0].trim(),
        source_type: '1007_SETTLE',
        id_number_1: thisObj.values.txtSC95,
        id_number_2: thisObj.values.txt1007
      }
      setData(thisObj, "ReviewDeductionTrackingFormLoadData", js);
      goTo(thisObj, "Settlements#ReviewDeductionTracking#DEFAULT#true#Click")
    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.onbtnDeductTrackClick = onbtnDeductTrackClick;

  const onbtnCorrectionClick = async () => {
    try {
      let lstrxml = [];
      let LstrNumber = '';

      let res = confirm('Are you sure you want to correct the settlement #' + thisObj.values.txt1007 + ' ? \n If any checks for this settlement are to be VOIDED... You MUST do that BEFORE correcting a settlement!!!')
      if (!res)
        return;

      LstrNumber = prompt('Confirm the move to correction of the settlement #' + thisObj.values.txt1007 + ' by entering the settlement number.')
      if (LstrNumber == '')
        return;
      if (LstrNumber == thisObj.values.txt1007) {
        res = confirm('Doing this operation is IRREVERSABLE!!! \n Are you sure you want to correct the settlement #' + thisObj.values.txt1007 + '\n If any checks for this settlement are to be VOIDED... You MUST do that BEFORE correcting a settlement!!!')
        if (!res)
          return;

        setLoading(true);
        let dataObj = {
          user_id: useridFromLS(),
          last_date_time: getData(thisObj, 'lstChangeDateTime')
        }

        lstrxml = await SettlementService.UpdateSettlementCorrectionDetails(thisObj.values.txtLocation.split(' ')[0].trim(), thisObj.values.txtSC95, dataObj)
        setLoading(false);
        if (lstrxml.message == 'Settlement correction updated successfully.') {
          //This changes has been done to rectify issue raised in INC5311102
          alert('The settlement #' + thisObj.values.txt1007 + ' has been successfully moved into correction state!!!')
          setValue(thisObj, 'txtStatus', 'Inspection')
          setData(thisObj, 'selectedRowReload', true);
          goTo(thisObj, 'Settlements#FV95Search#DEFAULT#false#Click')
          return;
        }
        else
          alert('An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.')
      }
      else
        alert('The entered settlement number does not match the settlement to be corrected!!!')
    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.onbtnCorrectionClick = onbtnCorrectionClick;

  const onbtnCorrectionLogClick = async () => {
    try {
      let js = {
        insp_num: thisObj.values.txtSC95,
        buy_pt_id: thisObj.values.txtLocation == null ? null : thisObj.values.txtLocation.split(' ')[0].trim(),
        action: null,
        getvendorinfo: null
      }
      setData(thisObj, "correctionLogOnLoadData", js);
      goTo(thisObj, 'Settlements#ReviewCorrectionLog#DEFAULT#true#Click')
    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.onbtnCorrectionLogClick = onbtnCorrectionLogClick;

  const onbtnCorrectionReprintClick = async () => {
    try {
      let js = {
        SC95No: thisObj.values.txtSC95,
        buy_pt_id: thisObj.values.txtLocation == null ? null : thisObj.values.txtLocation.split(' ')[0].trim(),
        headerData: '',
        method_name: 'CORRECTION DOCUMENT'
      }
      setData(thisObj, 'ReportPreviewInspectionScreenData', js)
      goTo(thisObj, "Settlements#ReportPreviewInspection#DEFAULT#true#Click")
    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.onbtnCorrectionReprintClick = onbtnCorrectionReprintClick;

  const onbtnCloseClick = async () => {
    try {
      document.getElementById("Settlements_ViewSettlementPopUp").childNodes[0].click();
    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.onbtnCloseClick = onbtnCloseClick;

  const onbtnAcctDistClick = async () => {
    try {
      let buyingPt = thisObj.values.txtLocation == null ? null : thisObj.values.txtLocation.split(' ')[0].trim()
      commonContext.setViewSettlement_BuyingPointId(buyingPt)
      commonContext.setViewSettlement_AuditInspNum(thisObj.values.txtSC95)
      setData(thisObj, 'type', 'SETTLEMENT')
      goTo(thisObj, 'ContractManagement#AccountDistributionActivity#DEFAULT#true#Click')
    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.onbtnAcctDistClick = onbtnAcctDistClick;

  const onbtnCureCleanClick = async () => {
    try {
      let js = {
        insp_num: thisObj.values.txtSC95,
        buy_pt_id: thisObj.values.txtLocation == null ? null : thisObj.values.txtLocation.split(' ')[0].trim(),
        action: null
      }
      setData(thisObj, "CleaningCuringCostsOnLoadData", js);
      setData(thisObj, "parentForm", { Name: 'frmViewSettlement' });
      goTo(thisObj, 'Settlements#CleaningCuringCosts#DEFAULT#true#Click')
    }
    catch (err) {
      errorHandler(err);
    }
  }
  thisObj.onbtnCureCleanClick = onbtnCureCleanClick;

  const onbtnFree1007Click = async () => {
    try {
      if (document.getElementById('btnFree1007').innerHTML == "Free 1007") {
        let resppp = await SettlementService.RetrieveCheckWareHouseStatusDetails(thisObj.values.txtSC95)
        if (!resppp?.length) {
          showMessage(thisObj, resppp.message, false)
          return;
        }

        let SC95No = thisObj.values.txtSC95
        let buy_pt_id = thisObj.values.txtLocation == null ? null : thisObj.values.txtLocation.split(' ')[0].trim()
        let data = {}
        data.free1007flag = 'Y'

        let resp1 = await SettlementService.updateFree1007Flag(buy_pt_id, SC95No, data)
        if (resp1.status != 200) {
          showMessage(thisObj, resp1.message, false)
          return;
        }

        document.getElementById('btnFree1007').innerHTML = "Undo Free 1007"
        enable(thisObj, 'btnCorrection')
        enable(thisObj, 'btnSupersede')
      }
      else {
        let SC95No = thisObj.values.txtSC95
        let buy_pt_id = thisObj.values.txtLocation == null ? null : thisObj.values.txtLocation.split(' ')[0].trim()
        let data = {}
        data.free1007flag = 'N'

        let resp3 = await SettlementService.updateFree1007Flag(buy_pt_id, SC95No, data)
        if (resp3.status != 200) {
          showMessage(thisObj, resp3.message, false)
          return;
        }

        document.getElementById('btnFree1007').innerHTML = "Free 1007"
        disable(thisObj, 'btnCorrection')
        disable(thisObj, 'btnSupersede')
      }
    }
    catch (err) {
      errorHandler(err)
    }
  }
  thisObj.onbtnFree1007Click = onbtnFree1007Click;

  function errorHandler(err) {
    showMessage(thisObj,
      err instanceof EvalError
        ? err.message
        : 'Something went wrong. Please try again later.'
    )
  }

  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
        setValues
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        thisObj.setValues = setValues = setValues;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />

            <h1 className="pageHeader">{state.Label}</h1>

            <Form noValidate className="row">
              <GroupBoxWidget
                conf={state.grpbxFarmInformation}
                screenConf={state}
              >
                <LabelWidget
                  values={values}
                  conf={state.lblFarmInformation}
                  screenConf={state}
                ></LabelWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtState}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblSC95ES}
                  screenConf={state}
                ></LabelWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCounty}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLocation}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarm}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSC95}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRevision}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtInspectDateTime}
                  screenConf={state}
                ></DateWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCmaVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCmaRemitTo}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtIrrDryInd}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>

              </GroupBoxWidget>

              <GroupBoxWidget
                conf={state.grpbx1007Controluic0}
                screenConf={state}
              >
                <LabelWidget
                  values={values}
                  conf={state.lbl1007Control}
                  screenConf={state}
                ></LabelWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt1007}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtStatus}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSettleDate}
                  screenConf={state}
                ></DateWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSuperceded1007}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSupercededSC95}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCorrectionCount}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblAdd1007Apply}
                  screenConf={state}
                ></LabelWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNum}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
              </GroupBoxWidget>
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget
                conf={state.grpbxTicketVendrSplit}
                screenConf={state}
              >
                <GroupBoxWidget
                  conf={state.grpbxWeightTickets}
                  screenConf={state}
                >
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEWeightTkt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEVehicleNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEWtInclVeh}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEWtOfVeh}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEWtOfPnut}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <LabelWidget
                    values={values}
                    conf={state.lblWeightTickets}
                    screenConf={state}
                  ></LabelWidget>
                  <ButtonWidget
                    conf={state.btnDelete}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></ButtonWidget>
                  <GridWidget
                    conf={state.gridWeightTickets}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridWeightTickets}
                    onEvent={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></GridWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTickets}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalLBs}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalDollar}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>

                </GroupBoxWidget>



                <GroupBoxWidget conf={state.grpbxVendorSplits} screenConf={state}>
                  <LabelWidget
                    values={values}
                    conf={state.lblVendorSplits}
                    screenConf={state}
                  ></LabelWidget>
                  <LabelWidget
                    values={values}
                    conf={state.lblWhseRcpt}
                    screenConf={state}
                  ></LabelWidget>
                  <LabelWidget
                    values={values}
                    conf={state.lblVendor}
                    screenConf={state}
                  ></LabelWidget>
                  <LabelWidget
                    values={values}
                    conf={state.lblPOrS}
                    screenConf={state}
                  ></LabelWidget>
                  <LabelWidget
                    values={values}
                    conf={state.lblSharePct}
                    screenConf={state}
                  ></LabelWidget>
                  <LabelWidget
                    values={values}
                    conf={state.lblPounds}
                    screenConf={state}
                  ></LabelWidget>
                   <LabelWidget
                    values={values}
                    conf={state.lblCMA}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow0*/}
  
                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow0*/}
  
                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow0}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWhseRcpt0*/}
  
                    {/* END_USER_CODE-USER_BEFORE_ddWhseRcpt0*/}
  
                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWhseRcpt0}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWhseRcpt0*/}
  
                    {/* END_USER_CODE-USER_AFTER_ddWhseRcpt0*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor0*/}
  
                    {/* END_USER_CODE-USER_BEFORE_txtVendor0*/}
  
                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor0}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor0*/}
  
                    {/* END_USER_CODE-USER_AFTER_txtVendor0*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPorS0*/}
  
                    {/* END_USER_CODE-USER_BEFORE_txtPorS0*/}
  
                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPorS0}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPorS0*/}

                    {/* END_USER_CODE-USER_AFTER_txtPorS0*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct0*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct0*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct0}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct0*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct0*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds0*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds0*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds0}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                     <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMA0}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds0*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds0*/}
                    {/* START_USER_CODE-USER_BEFORE_lblRemitTo0*/}

                    {/* END_USER_CODE-USER_BEFORE_lblRemitTo0*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblRemitTo0}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblRemitTo0*/}

                    {/* END_USER_CODE-USER_AFTER_lblRemitTo0*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo0*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo0*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo0}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo0*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo0*/}
                    {/* START_USER_CODE-USER_BEFORE_lblValue0*/}

                    {/* END_USER_CODE-USER_BEFORE_lblValue0*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblValue0}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblValue0*/}

                    {/* END_USER_CODE-USER_AFTER_lblValue0*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue0*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue0*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue0}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue0*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue0*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow0*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow0*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow1*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow1*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow1}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWhseRcpt1*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWhseRcpt1*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWhseRcpt1}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWhseRcpt1*/}

                    {/* END_USER_CODE-USER_AFTER_ddWhseRcpt1*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor1*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor1*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor1}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor1*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor1*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPorS1*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPorS1*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPorS1}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPorS1*/}

                    {/* END_USER_CODE-USER_AFTER_txtPorS1*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct1*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct1*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct1}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct1*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct1*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds1*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds1*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds1}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                     <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMA1}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds1*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds1*/}
                    {/* START_USER_CODE-USER_BEFORE_lblRemitTo1*/}

                    {/* END_USER_CODE-USER_BEFORE_lblRemitTo1*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblRemitTo1}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblRemitTo1*/}

                    {/* END_USER_CODE-USER_AFTER_lblRemitTo1*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo1*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo1*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo1}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo1*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo1*/}
                    {/* START_USER_CODE-USER_BEFORE_lblValue1*/}

                    {/* END_USER_CODE-USER_BEFORE_lblValue1*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblValue1}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblValue1*/}

                    {/* END_USER_CODE-USER_AFTER_lblValue1*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue1*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue1*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue1}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue1*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue1*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow1*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow1*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow2*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow2*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow2}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWhseRcpt2*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWhseRcpt2*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWhseRcpt2}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWhseRcpt2*/}

                    {/* END_USER_CODE-USER_AFTER_ddWhseRcpt2*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor2*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor2*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor2}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor2*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor2*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPorS2*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPorS2*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPorS2}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPorS2*/}

                    {/* END_USER_CODE-USER_AFTER_txtPorS2*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct2*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct2*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct2}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct2*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct2*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds2*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds2*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds2}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                     <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMA2}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds2*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds2*/}
                    {/* START_USER_CODE-USER_BEFORE_lblRemitTo2*/}

                    {/* END_USER_CODE-USER_BEFORE_lblRemitTo2*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblRemitTo2}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblRemitTo2*/}

                    {/* END_USER_CODE-USER_AFTER_lblRemitTo2*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo2*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo2*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo2}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo2*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo2*/}
                    {/* START_USER_CODE-USER_BEFORE_lblValue2*/}

                    {/* END_USER_CODE-USER_BEFORE_lblValue2*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblValue2}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblValue2*/}

                    {/* END_USER_CODE-USER_AFTER_lblValue2*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue2*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue2*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue2}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue2*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue2*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow2*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow2*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow3*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow3*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow3}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWhseRcpt3*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWhseRcpt3*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWhseRcpt3}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWhseRcpt3*/}

                    {/* END_USER_CODE-USER_AFTER_ddWhseRcpt3*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor3*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor3*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor3}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor3*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor3*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPorS3*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPorS3*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPorS3}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPorS3*/}

                    {/* END_USER_CODE-USER_AFTER_txtPorS3*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct3*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct3*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct3}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct3*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct3*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds3*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds3*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds3}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                     <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMA3}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds3*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds3*/}
                    {/* START_USER_CODE-USER_BEFORE_lblRemitTo3*/}

                    {/* END_USER_CODE-USER_BEFORE_lblRemitTo3*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblRemitTo3}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblRemitTo3*/}

                    {/* END_USER_CODE-USER_AFTER_lblRemitTo3*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo3*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo3*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo3}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo3*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo3*/}
                    {/* START_USER_CODE-USER_BEFORE_lblValue3*/}

                    {/* END_USER_CODE-USER_BEFORE_lblValue3*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblValue3}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblValue3*/}

                    {/* END_USER_CODE-USER_AFTER_lblValue3*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue3*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue3*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue3}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue3*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue3*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow3*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow3*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow4*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow4*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow4}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWhseRcpt4*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWhseRcpt4*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWhseRcpt4}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWhseRcpt4*/}

                    {/* END_USER_CODE-USER_AFTER_ddWhseRcpt4*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor4*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor4*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor4}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor4*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor4*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPorS4*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPorS4*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPorS4}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPorS4*/}

                    {/* END_USER_CODE-USER_AFTER_txtPorS4*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct4*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct4*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct4}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct4*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct4*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds4*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds4*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds4}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                     <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMA4}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds4*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds4*/}
                    {/* START_USER_CODE-USER_BEFORE_lblRemitTo4*/}

                    {/* END_USER_CODE-USER_BEFORE_lblRemitTo4*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblRemitTo4}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblRemitTo4*/}

                    {/* END_USER_CODE-USER_AFTER_lblRemitTo4*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo4*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo4*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo4}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo4*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo4*/}
                    {/* START_USER_CODE-USER_BEFORE_lblValue4*/}

                    {/* END_USER_CODE-USER_BEFORE_lblValue4*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblValue4}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblValue4*/}

                    {/* END_USER_CODE-USER_AFTER_lblValue4*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue4*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue4*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue4}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue4*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue4*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow4*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow4*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow5*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow5*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow5}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWhseRcpt5*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWhseRcpt5*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWhseRcpt5}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWhseRcpt5*/}

                    {/* END_USER_CODE-USER_AFTER_ddWhseRcpt5*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor5*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor5*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor5}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor5*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor5*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPorS5*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPorS5*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPorS5}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPorS5*/}

                    {/* END_USER_CODE-USER_AFTER_txtPorS5*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct5*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct5*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct5}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct5*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct5*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds5*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds5*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds5}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                     <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMA5}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds5*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds5*/}
                    {/* START_USER_CODE-USER_BEFORE_lblRemitTo5*/}

                    {/* END_USER_CODE-USER_BEFORE_lblRemitTo5*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblRemitTo5}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblRemitTo5*/}

                    {/* END_USER_CODE-USER_AFTER_lblRemitTo5*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo5*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo5*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo5}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo5*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo5*/}
                    {/* START_USER_CODE-USER_BEFORE_lblValue5*/}

                    {/* END_USER_CODE-USER_BEFORE_lblValue5*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblValue5}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblValue5*/}

                    {/* END_USER_CODE-USER_AFTER_lblValue5*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue5*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue5*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue5}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue5*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue5*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow5*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow5*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow6*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow6*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow6}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWhseRcpt6*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWhseRcpt6*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWhseRcpt6}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWhseRcpt6*/}

                    {/* END_USER_CODE-USER_AFTER_ddWhseRcpt6*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor6*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor6*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor6}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor6*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor6*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPorS6*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPorS6*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPorS6}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPorS6*/}

                    {/* END_USER_CODE-USER_AFTER_txtPorS6*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct6*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct6*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct6}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct6*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct6*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds6*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds6*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds6}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                     <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMA6}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds6*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds6*/}
                    {/* START_USER_CODE-USER_BEFORE_lblRemitTo6*/}

                    {/* END_USER_CODE-USER_BEFORE_lblRemitTo6*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblRemitTo6}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblRemitTo6*/}

                    {/* END_USER_CODE-USER_AFTER_lblRemitTo6*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo6*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo6*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo6}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo6*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo6*/}
                    {/* START_USER_CODE-USER_BEFORE_lblValue6*/}

                    {/* END_USER_CODE-USER_BEFORE_lblValue6*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblValue6}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblValue6*/}

                    {/* END_USER_CODE-USER_AFTER_lblValue6*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue6*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue6*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue6}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue6*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue6*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow6*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow6*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow7*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow7*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow7}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWhseRcpt7*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWhseRcpt7*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWhseRcpt7}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWhseRcpt7*/}

                    {/* END_USER_CODE-USER_AFTER_ddWhseRcpt7*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor7*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor7*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor7}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor7*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor7*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPorS7*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPorS7*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPorS7}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPorS7*/}

                    {/* END_USER_CODE-USER_AFTER_txtPorS7*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct7*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct7*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct7}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct7*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct7*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds7*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds7*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds7}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                     <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMA7}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds7*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds7*/}
                    {/* START_USER_CODE-USER_BEFORE_lblRemitTo7*/}

                    {/* END_USER_CODE-USER_BEFORE_lblRemitTo7*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblRemitTo7}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblRemitTo7*/}

                    {/* END_USER_CODE-USER_AFTER_lblRemitTo7*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo7*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo7*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo7}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo7*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo7*/}
                    {/* START_USER_CODE-USER_BEFORE_lblValue7*/}

                    {/* END_USER_CODE-USER_BEFORE_lblValue7*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblValue7}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblValue7*/}

                    {/* END_USER_CODE-USER_AFTER_lblValue7*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue7*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue7*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue7}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue7*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue7*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow7*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow7*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow8*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow8*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow8}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWhseRcpt8*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWhseRcpt8*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWhseRcpt8}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWhseRcpt8*/}

                    {/* END_USER_CODE-USER_AFTER_ddWhseRcpt8*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor8*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor8*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor8}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor8*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor8*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPorS8*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPorS8*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPorS8}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPorS8*/}

                    {/* END_USER_CODE-USER_AFTER_txtPorS8*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct8*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct8*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct8}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct8*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct8*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds8*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds8*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds8}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                     <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMA8}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds8*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds8*/}
                    {/* START_USER_CODE-USER_BEFORE_lblRemitTo8*/}

                    {/* END_USER_CODE-USER_BEFORE_lblRemitTo8*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblRemitTo8}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblRemitTo8*/}

                    {/* END_USER_CODE-USER_AFTER_lblRemitTo8*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo8*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo8*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo8}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo8*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo8*/}
                    {/* START_USER_CODE-USER_BEFORE_lblValue8*/}

                    {/* END_USER_CODE-USER_BEFORE_lblValue8*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblValue8}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblValue8*/}

                    {/* END_USER_CODE-USER_AFTER_lblValue8*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue8*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue8*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue8}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue8*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue8*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow8*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow8*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow9*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow9*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow9}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWhseRcpt9*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWhseRcpt9*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWhseRcpt9}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWhseRcpt9*/}

                    {/* END_USER_CODE-USER_AFTER_ddWhseRcpt9*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor9*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor9*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor9}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor9*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor9*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPorS9*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPorS9*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPorS9}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPorS9*/}

                    {/* END_USER_CODE-USER_AFTER_txtPorS9*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct9*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct9*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct9}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct9*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct9*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds9*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds9*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds9}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                     <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMA9}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds9*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds9*/}
                    {/* START_USER_CODE-USER_BEFORE_lblRemitTo9*/}

                    {/* END_USER_CODE-USER_BEFORE_lblRemitTo9*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblRemitTo9}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblRemitTo9*/}

                    {/* END_USER_CODE-USER_AFTER_lblRemitTo9*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo9*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo9*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo9}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo9*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo9*/}
                    {/* START_USER_CODE-USER_BEFORE_lblValue9*/}

                    {/* END_USER_CODE-USER_BEFORE_lblValue9*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblValue9}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblValue9*/}

                    {/* END_USER_CODE-USER_AFTER_lblValue9*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue9*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue9*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue9}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue9*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue9*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow9*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow9*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow10*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow10*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow10}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWhseRcpt10*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWhseRcpt10*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWhseRcpt10}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWhseRcpt10*/}

                    {/* END_USER_CODE-USER_AFTER_ddWhseRcpt10*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor10*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor10*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor10}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor10*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor10*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPorS10*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPorS10*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPorS10}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPorS10*/}

                    {/* END_USER_CODE-USER_AFTER_txtPorS10*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct10*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct10*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct10}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct10*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct10*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds10*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds10*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds10}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                     <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMA10}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds10*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds10*/}
                    {/* START_USER_CODE-USER_BEFORE_lblRemitTo10*/}

                    {/* END_USER_CODE-USER_BEFORE_lblRemitTo10*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblRemitTo10}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblRemitTo10*/}

                    {/* END_USER_CODE-USER_AFTER_lblRemitTo10*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo10*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo10*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo10}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo10*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo10*/}
                    {/* START_USER_CODE-USER_BEFORE_lblValue10*/}

                    {/* END_USER_CODE-USER_BEFORE_lblValue10*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblValue10}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblValue10*/}

                    {/* END_USER_CODE-USER_AFTER_lblValue10*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue10*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue10*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue10}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue10*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue10*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow10*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow10*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow11*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow11*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow11}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWhseRcpt11*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWhseRcpt11*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWhseRcpt11}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWhseRcpt11*/}

                    {/* END_USER_CODE-USER_AFTER_ddWhseRcpt11*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor11*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor11*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor11}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor11*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor11*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPorS11*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPorS11*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPorS11}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPorS11*/}

                    {/* END_USER_CODE-USER_AFTER_txtPorS11*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct11*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct11*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct11}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct11*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct11*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds11*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds11*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds11}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                     <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMA11}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds11*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds11*/}
                    {/* START_USER_CODE-USER_BEFORE_lblRemitTo11*/}

                    {/* END_USER_CODE-USER_BEFORE_lblRemitTo11*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblRemitTo11}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblRemitTo11*/}

                    {/* END_USER_CODE-USER_AFTER_lblRemitTo11*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo11*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo11*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo11}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo11*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo11*/}
                    {/* START_USER_CODE-USER_BEFORE_lblValue11*/}

                    {/* END_USER_CODE-USER_BEFORE_lblValue11*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblValue11}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblValue11*/}

                    {/* END_USER_CODE-USER_AFTER_lblValue11*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue11*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue11*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue11}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue11*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue11*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow11*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow11*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow12*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow12*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow12}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWhseRcpt12*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWhseRcpt12*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWhseRcpt12}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWhseRcpt12*/}

                    {/* END_USER_CODE-USER_AFTER_ddWhseRcpt12*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor12*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor12*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor12}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor12*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor12*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPorS12*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPorS12*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPorS12}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPorS12*/}

                    {/* END_USER_CODE-USER_AFTER_txtPorS12*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct12*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct12*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct12}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct12*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct12*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds12*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds12*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds12}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                     <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMA12}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds12*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds12*/}
                    {/* START_USER_CODE-USER_BEFORE_lblRemitTo12*/}

                    {/* END_USER_CODE-USER_BEFORE_lblRemitTo12*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblRemitTo12}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblRemitTo12*/}

                    {/* END_USER_CODE-USER_AFTER_lblRemitTo12*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo12*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo12*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo12}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo12*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo12*/}
                    {/* START_USER_CODE-USER_BEFORE_lblValue12*/}

                    {/* END_USER_CODE-USER_BEFORE_lblValue12*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblValue12}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblValue12*/}

                    {/* END_USER_CODE-USER_AFTER_lblValue12*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue12*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue12*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue12}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue12*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue12*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow12*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow12*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow13*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow13*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow13}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWhseRcpt13*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWhseRcpt13*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWhseRcpt13}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWhseRcpt13*/}

                    {/* END_USER_CODE-USER_AFTER_ddWhseRcpt13*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor13*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor13*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor13}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor13*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor13*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPorS13*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPorS13*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPorS13}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPorS13*/}

                    {/* END_USER_CODE-USER_AFTER_txtPorS13*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct13*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct13*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct13}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct13*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct13*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds13*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds13*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds13}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                     <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMA13}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds13*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds13*/}
                    {/* START_USER_CODE-USER_BEFORE_lblRemitTo13*/}

                    {/* END_USER_CODE-USER_BEFORE_lblRemitTo13*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblRemitTo13}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblRemitTo13*/}

                    {/* END_USER_CODE-USER_AFTER_lblRemitTo13*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo13*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo13*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo13}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo13*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo13*/}
                    {/* START_USER_CODE-USER_BEFORE_lblValue13*/}

                    {/* END_USER_CODE-USER_BEFORE_lblValue13*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblValue13}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblValue13*/}

                    {/* END_USER_CODE-USER_AFTER_lblValue13*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue13*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue13*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue13}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue13*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue13*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow13*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow13*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow14*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow14*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow14}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWhseRcpt14*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWhseRcpt14*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWhseRcpt14}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWhseRcpt14*/}

                    {/* END_USER_CODE-USER_AFTER_ddWhseRcpt14*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor14*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor14*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor14}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor14*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor14*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPorS14*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPorS14*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPorS14}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPorS14*/}

                    {/* END_USER_CODE-USER_AFTER_txtPorS14*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct14*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct14*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct14}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct14*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct14*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds14*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds14*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds14}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                     <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMA14}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds14*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds14*/}
                    {/* START_USER_CODE-USER_BEFORE_lblRemitTo14*/}

                    {/* END_USER_CODE-USER_BEFORE_lblRemitTo14*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblRemitTo14}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblRemitTo14*/}

                    {/* END_USER_CODE-USER_AFTER_lblRemitTo14*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo14*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo14*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo14}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo14*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo14*/}
                    {/* START_USER_CODE-USER_BEFORE_lblValue14*/}

                    {/* END_USER_CODE-USER_BEFORE_lblValue14*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblValue14}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblValue14*/}

                    {/* END_USER_CODE-USER_AFTER_lblValue14*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue14*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue14*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue14}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue14*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue14*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow14*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow14*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow15*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow15*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow15}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWhseRcpt15*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWhseRcpt15*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWhseRcpt15}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWhseRcpt15*/}

                    {/* END_USER_CODE-USER_AFTER_ddWhseRcpt15*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor15*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor15*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor15}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor15*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor15*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPorS15*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPorS15*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPorS15}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPorS15*/}

                    {/* END_USER_CODE-USER_AFTER_txtPorS15*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct15*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct15*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct15}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct15*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct15*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds15*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds15*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds15}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                     <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMA15}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds15*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds15*/}
                    {/* START_USER_CODE-USER_BEFORE_lblRemitTo15*/}

                    {/* END_USER_CODE-USER_BEFORE_lblRemitTo15*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblRemitTo15}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblRemitTo15*/}

                    {/* END_USER_CODE-USER_AFTER_lblRemitTo15*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo15*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo15*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo15}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo15*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo15*/}
                    {/* START_USER_CODE-USER_BEFORE_lblValue15*/}

                    {/* END_USER_CODE-USER_BEFORE_lblValue15*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblValue15}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblValue15*/}

                    {/* END_USER_CODE-USER_AFTER_lblValue15*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue15*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue15*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue15}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue15*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue15*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow15*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow15*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow16*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow16*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow16}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWhseRcpt16*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWhseRcpt16*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWhseRcpt16}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWhseRcpt16*/}

                    {/* END_USER_CODE-USER_AFTER_ddWhseRcpt16*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor16*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor16*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor16}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor16*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor16*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPorS16*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPorS16*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPorS16}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPorS16*/}

                    {/* END_USER_CODE-USER_AFTER_txtPorS16*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct16*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct16*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct16}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct16*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct16*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds16*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds16*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds16}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                     <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMA16}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds16*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds16*/}
                    {/* START_USER_CODE-USER_BEFORE_lblRemitTo16*/}

                    {/* END_USER_CODE-USER_BEFORE_lblRemitTo16*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblRemitTo16}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblRemitTo16*/}

                    {/* END_USER_CODE-USER_AFTER_lblRemitTo16*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo16*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo16*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo16}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo16*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo16*/}
                    {/* START_USER_CODE-USER_BEFORE_lblValue16*/}

                    {/* END_USER_CODE-USER_BEFORE_lblValue16*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblValue16}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblValue16*/}

                    {/* END_USER_CODE-USER_AFTER_lblValue16*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue16*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue16*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue16}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue16*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue16*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow16*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow16*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow17*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow17*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow17}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWhseRcpt17*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWhseRcpt17*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWhseRcpt17}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWhseRcpt17*/}

                    {/* END_USER_CODE-USER_AFTER_ddWhseRcpt17*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor17*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor17*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor17}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor17*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor17*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPorS17*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPorS17*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPorS17}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPorS17*/}

                    {/* END_USER_CODE-USER_AFTER_txtPorS17*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct17*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct17*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct17}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct17*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct17*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds17*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds17*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds17}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                     <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMA17}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds17*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds17*/}
                    {/* START_USER_CODE-USER_BEFORE_lblRemitTo17*/}

                    {/* END_USER_CODE-USER_BEFORE_lblRemitTo17*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblRemitTo17}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblRemitTo17*/}

                    {/* END_USER_CODE-USER_AFTER_lblRemitTo17*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo17*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo17*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo17}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo17*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo17*/}
                    {/* START_USER_CODE-USER_BEFORE_lblValue17*/}

                    {/* END_USER_CODE-USER_BEFORE_lblValue17*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblValue17}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblValue17*/}

                    {/* END_USER_CODE-USER_AFTER_lblValue17*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue17*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue17*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue17}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue17*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue17*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow17*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow17*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow18*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow18*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow18}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWhseRcpt18*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWhseRcpt18*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWhseRcpt18}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWhseRcpt18*/}

                    {/* END_USER_CODE-USER_AFTER_ddWhseRcpt18*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor18*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor18*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor18}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor18*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor18*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPorS18*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPorS18*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPorS18}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPorS18*/}

                    {/* END_USER_CODE-USER_AFTER_txtPorS18*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct18*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct18*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct18}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct18*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct18*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds18*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds18*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds18}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                     <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMA18}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds18*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds18*/}
                    {/* START_USER_CODE-USER_BEFORE_lblRemitTo18*/}

                    {/* END_USER_CODE-USER_BEFORE_lblRemitTo18*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblRemitTo18}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblRemitTo18*/}

                    {/* END_USER_CODE-USER_AFTER_lblRemitTo18*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo18*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo18*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo18}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo18*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo18*/}
                    {/* START_USER_CODE-USER_BEFORE_lblValue18*/}

                    {/* END_USER_CODE-USER_BEFORE_lblValue18*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblValue18}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblValue18*/}

                    {/* END_USER_CODE-USER_AFTER_lblValue18*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue18*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue18*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue18}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue18*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue18*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow18*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow18*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVendorSplitRow19*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVendorSplitRow19*/}

                  <GroupBoxWidget
                    conf={state.grpbxVendorSplitRow19}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_ddWhseRcpt19*/}

                    {/* END_USER_CODE-USER_BEFORE_ddWhseRcpt19*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddWhseRcpt19}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddWhseRcpt19*/}

                    {/* END_USER_CODE-USER_AFTER_ddWhseRcpt19*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor19*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor19*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor19}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor19*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor19*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPorS19*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPorS19*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPorS19}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPorS19*/}

                    {/* END_USER_CODE-USER_AFTER_txtPorS19*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct19*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct19*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct19}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct19*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct19*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPounds19*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPounds19*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPounds19}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                     <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMA19}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPounds19*/}

                    {/* END_USER_CODE-USER_AFTER_txtPounds19*/}
                    {/* START_USER_CODE-USER_BEFORE_lblRemitTo19*/}

                    {/* END_USER_CODE-USER_BEFORE_lblRemitTo19*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblRemitTo19}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblRemitTo19*/}

                    {/* END_USER_CODE-USER_AFTER_lblRemitTo19*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRemitTo19*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRemitTo19*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRemitTo19}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRemitTo19*/}

                    {/* END_USER_CODE-USER_AFTER_txtRemitTo19*/}
                    {/* START_USER_CODE-USER_BEFORE_lblValue19*/}

                    {/* END_USER_CODE-USER_BEFORE_lblValue19*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblValue19}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblValue19*/}

                    {/* END_USER_CODE-USER_AFTER_lblValue19*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValue19*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValue19*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValue19}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValue19*/}

                    {/* END_USER_CODE-USER_AFTER_txtValue19*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVendorSplitRow19*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVendorSplitRow19*/}
                </GroupBoxWidget>
              </GroupBoxWidget>
              <GroupBoxWidget
                conf={state.grpbxPeanutInfouic0}
                screenConf={state}
              >
                <LabelWidget
                  values={values}
                  conf={state.lblPeanutInformation}
                  screenConf={state}
                ></LabelWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPeanutType}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPeanutVariety}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeedGen}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeg1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOleic}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxOrganic}
                  screenConf={state}
                ></CheckboxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtEdibleOil}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeedMeetingSpec}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxVicam}
                  screenConf={state}
                ></CheckboxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtReading}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblUnloadedInfo}
                  screenConf={state}
                ></LabelWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWhouseBin}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblDelPtObligation}
                  screenConf={state}
                ></LabelWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtBuyingPt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtObligationPt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>

              </GroupBoxWidget>
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget
                conf={state.grpbxInspectorRemarks}
                screenConf={state}
              >
                <LabelWidget
                  values={values}
                  conf={state.lblInspectorRemarks}
                  screenConf={state}
                ></LabelWidget>
                <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtarInspectorRemarks}
                  screenConf={state}
                ></TextAreaWidget>
              </GroupBoxWidget>
              <GroupBoxWidget
                conf={state.grpbxGradeCalculation}
                screenConf={state}
              >
                <LabelWidget
                  values={values}
                  conf={state.lblGradeCalculation}
                  screenConf={state}
                ></LabelWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFMSampleWt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtProbingPattern}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCleanSampleWt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtValenciaCleanSampleWt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblCornFound}
                  screenConf={state}
                ></LabelWidget>
                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxCornFound}
                  screenConf={state}
                ></CheckboxWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblGrams1}
                  screenConf={state}
                ></LabelWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblPct1}
                  screenConf={state}
                ></LabelWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblGrams2}
                  screenConf={state}
                ></LabelWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblPct2}
                  screenConf={state}
                ></LabelWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMKRSGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMKRSPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFreezeDamageGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFreezeDamagePct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMK1Gr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMK1Pct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtConcealedRMDGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtConcealedRMDPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMK2Gruic0}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMK2Pct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCrkOrBrkShellsGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCrkOrBrkShellsPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMK3Gr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMK3Pct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDiscoloredShellsGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDiscoloredShellsPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMK4Gr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMK4Pct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtHullBrightness}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtHullBrightnessPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSoundSplitsGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSoundSplitsPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtJumboGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtJumboPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalSMK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtELKGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtELKPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOtherKernelsGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOtherKernelsPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtForeignMaterialGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtForeignMaterialPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDamageSplits}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOtherForeignMaterials}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalDamageGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalDamagePct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLSKGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLSKPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalKrnls}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtMoistureReading}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtHullsGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtHullsPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFancyGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFancyPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalKernelsHulls}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblFlavusFound}
                  screenConf={state}
                ></LabelWidget>
                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxFlavusFound}
                  screenConf={state}
                ></CheckboxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtKernelsREL}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtMeterReading}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtKernelsRPS}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtBluePanWt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDamagedKRS}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtELKDamage}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                  <GroupBoxWidget
                  conf={state.grpbxVirginiaGrading}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblVirginiaGrading*/}

                  {/* END_USER_CODE-USER_BEFORE_lblVirginiaGrading*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblVirginiaGrading}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblVirginiaGrading*/}

                  {/* END_USER_CODE-USER_AFTER_lblVirginiaGrading*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSampleWt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSampleWt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSampleWt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSampleWt*/}

                  {/* END_USER_CODE-USER_AFTER_txtSampleWt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtGrams*/}

                  {/* END_USER_CODE-USER_BEFORE_txtGrams*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtGrams}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtGrams*/}

                  {/* END_USER_CODE-USER_AFTER_txtGrams*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPercent*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPercent*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPercent}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPercent*/}

                  {/* END_USER_CODE-USER_AFTER_txtPercent*/}
                </GroupBoxWidget>
              </GroupBoxWidget>

              <GroupBoxWidget conf={state.grpbxHMC} screenConf={state}>
                <LabelWidget
                  values={values}
                  conf={state.lblHMCConvertedGrades}
                  screenConf={state}
                ></LabelWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeg}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFM}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLSK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtMST}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSS}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMKRS}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFRZ}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCRMD}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalKernels}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDAM}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtHULLS}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtELK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFANCY}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotKrnlsHulls}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
              </GroupBoxWidget>

              <GroupBoxWidget
                conf={state.grpbxValueCalculation}
                screenConf={state}
              >
                <LabelWidget
                  values={values}
                  conf={state.lblValueCalculation}
                  screenConf={state}
                ></LabelWidget>
                <ButtonWidget
                  conf={state.btnCalculate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWtIncludingVehicle}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWtOfVehicle}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtGrossWeight}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtForeignMaterial}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWtlessFM}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtExcessMoisture}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNetWeight}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLSKvc}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNetWtExclLSK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblPerTon}
                  screenConf={state}
                ></LabelWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblPerLb}
                  screenConf={state}
                ></LabelWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtKernelValueTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtKernelValueLb}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtELKPremiumTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtELKPremiumLb}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalLb}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDamageTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDamageLbs}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAccessFMTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAccessFMLb}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtExcessSplitsTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtExcessSplitsLb}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAFlavusTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAFlavusLb}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalDiscountsTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtlblTotalDiscountsLb}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNetValPerTonExclSLK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtValPerPoundExclSLK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblPerLb2}
                  screenConf={state}
                ></LabelWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtValPerPoundInclSLK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOI}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtH}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalLoadVal}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtG}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblPerLb3}
                  screenConf={state}
                ></LabelWidget>
              </GroupBoxWidget>

              <GroupBoxWidget
                conf={state.grpbxPrchsStrgWtCert}
                screenConf={state}
              >
                <GroupBoxWidget conf={state.grpbxPurchases} screenConf={state}>
                  <LabelWidget
                    values={values}
                    conf={state.lblPurchases}
                    screenConf={state}
                  ></LabelWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNetWeightPrchs}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalLoadValuePrchs}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtContractSpotPricePrchs}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPctOfSupportPrchs}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFarmerStockCostPrchs}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtValueOfSegPrchs}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOptionPayment}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPremiumsPrchs}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDeductionsPrchs}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtProceedsPrchs}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                </GroupBoxWidget>

                <GroupBoxWidget conf={state.grpbxStorage} screenConf={state}>
                  <LabelWidget
                    values={values}
                    conf={state.lblStorage}
                    screenConf={state}
                  ></LabelWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNetWeightStrg}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalLoadValueStrg}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOptionPriceStrg}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPctOfSupportStrg}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtValueOfOptionStrg}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSeedPremiumStrg}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDeductionsStrg}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtProceedsStrg}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                </GroupBoxWidget>

                <GroupBoxWidget conf={state.grpbxWeightCert} screenConf={state}>
                  <LabelWidget
                    values={values}
                    conf={state.lblWeightCert}
                    screenConf={state}
                  ></LabelWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDACONum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                  ></TextBoxWidget>
                </GroupBoxWidget>
              </GroupBoxWidget>
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                <ButtonWidget
                  conf={state.btnFree1007}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnVoid}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnReprint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnWorksheet}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnCureClean}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnPremsDeduct}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnPayments}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnDeductTrack}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                <ButtonWidget
                  conf={state.btnResendToKCMO}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnCorrection}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnSupersede}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnCorrectionReprint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnApplications}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnAcctDist}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnCorrectionLog}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
              </GroupBoxWidget>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_ViewSettlement);