/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  disable,
  setData,
  getData,
  setValue,
  getValue,
  hideColumn,
  goTo,
  getSelectedRowNumber, 
  setGridValue
} from "../../shared/WindowImports";

import "./VendorSearch.scss";

// START_USER_CODE-USER_IMPORTS

import * as XLSX from 'xlsx/xlsx.mjs';
import jsPDF from 'jspdf';
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { response } from "msw";
import { RetrieveCuringCleaningCostsDetails } from "../../Settlements/Service/SettlementService";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_VendorSearch(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES
  let cropyear = JSON.parse(sessionStorage.getItem('year'));
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "VendorSearch",
    windowName: "VendorSearch",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.VendorSearch",
    // START_USER_CODE-USER_VendorSearch_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Vendor Inquiry",
      scrCode: "PN0350A",
    },
    // END_USER_CODE-USER_VendorSearch_PROPERTIES
    btn1: {
      name: "btn1",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: ".",
      CharWidth: "6",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn1_PROPERTIES

      // END_USER_CODE-USER_btn1_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnOpen: {
      name: "btnOpen",
      type: "ButtonWidget",
      parent: "grpbxVendorList",
      Label: "Open",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOpen_PROPERTIES

      // END_USER_CODE-USER_btnOpen_PROPERTIES
    },
    btnPrintSearch: {
      name: "btnPrintSearch",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Print Search",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrintSearch_PROPERTIES

      // END_USER_CODE-USER_btnPrintSearch_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxVendorList",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    ddBuyingPoint: {
      name: "ddBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorDetails",
      Label: "Buying Point:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPoint_PROPERTIES
    },
    gridVendorList: {
      name: "gridVendorList",
      type: "GridWidget",
      parent: "grpbxVendorList",
      gridCellsOrder:
        "txtVendorNumberC,txtVendorNameC,txtPhysicalAddress,txtMailingAddress,txtCustomerNumber,txtPhone1,txtPhone2,txtCellular,txtFax,txtEmail1,txtEmail2,txtEmail3,txt211GPC,txt211Concordia,txtLienHolders,txtWomenOwned,txtMinorityOwned,txtVeteranOwned",
      Pagination: false,
      ColsForTabLandscape: "13",
      HasLabel: false,
      Cols: "13",
      ColsForTabPotrait: "13",
      ColsForLargeDesktop: "13",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridVendorList_PROPERTIES
      conditionalRowStyles: [
        {
          when: (row)=>{
            return row.child
          },
          style:{
            color: 'white',
          },
        }
      ]
      // END_USER_CODE-USER_gridVendorList_PROPERTIES
    },
    lblMinOf3Chars1: {
      name: "lblMinOf3Chars1",
      type: "LabelWidget",
      parent: "grpbxVendorDetails",
      Label: "(min of 3 chars)",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblMinOf3Chars1_PROPERTIES

      // END_USER_CODE-USER_lblMinOf3Chars1_PROPERTIES
    },
    lblMinOf3Chars2: {
      name: "lblMinOf3Chars2",
      type: "LabelWidget",
      parent: "grpbxVendorDetails",
      Label: "(min of 3 chars)",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblMinOf3Chars2_PROPERTIES

      // END_USER_CODE-USER_lblMinOf3Chars2_PROPERTIES
    },
    lblVendorList: {
      name: "lblVendorList",
      type: "LabelWidget",
      parent: "grpbxVendorList",
      Label: "Vendor List:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendorList_PROPERTIES

      // END_USER_CODE-USER_lblVendorList_PROPERTIES
    },
    txt211Concordia: {
      name: "txt211Concordia",
      type: "TextBoxWidget",
      colName: "txtCol211Concordia",
      parent: "gridVendorList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt211Concordia_PROPERTIES

      // END_USER_CODE-USER_txt211Concordia_PROPERTIES
    },
    txt211GPC: {
      name: "txt211GPC",
      type: "TextBoxWidget",
      colName: "txtCol211GPC",
      parent: "gridVendorList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt211GPC_PROPERTIES

      // END_USER_CODE-USER_txt211GPC_PROPERTIES
    },
    txtCol211Concordia: {
      name: "txtCol211Concordia",
      type: "GridColumnWidget",
      parent: "gridVendorList",
      Label: "211 Concordia",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtCol211Concordia_PROPERTIES

      // END_USER_CODE-USER_txtCol211Concordia_PROPERTIES
    },
    txtCol211GPC: {
      name: "txtCol211GPC",
      type: "GridColumnWidget",
      parent: "gridVendorList",
      Label: "211 GPC",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtCol211GPC_PROPERTIES

      // END_USER_CODE-USER_txtCol211GPC_PROPERTIES
    },
    txtColCustomerNumber: {
      name: "txtColCustomerNumber",
      type: "GridColumnWidget",
      parent: "gridVendorList",
      Label: "Customer Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColCustomerNumber_PROPERTIES

      // END_USER_CODE-USER_txtColCustomerNumber_PROPERTIES
    },
    txtColEmail1: {
      name: "txtColEmail1",
      type: "GridColumnWidget",
      parent: "gridVendorList",
      Label: "Email #1",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColEmail1_PROPERTIES

      // END_USER_CODE-USER_txtColEmail1_PROPERTIES
    },
    txtColFax: {
      name: "txtColFax",
      type: "GridColumnWidget",
      parent: "gridVendorList",
      Label: "Fax",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColFax_PROPERTIES

      // END_USER_CODE-USER_txtColFax_PROPERTIES
    },
    txtColLienHolders: {
      name: "txtColLienHolders",
      type: "GridColumnWidget",
      parent: "gridVendorList",
      Label: "Lien Holders",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColLienHolders_PROPERTIES

      // END_USER_CODE-USER_txtColLienHolders_PROPERTIES
    },
    txtColMailingAddress: {
      name: "txtColMailingAddress",
      type: "GridColumnWidget",
      parent: "gridVendorList",
      Label: "Mailing Address",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColMailingAddress_PROPERTIES

      // END_USER_CODE-USER_txtColMailingAddress_PROPERTIES
    },
    txtColMinorityOwned: {
      name: "txtColMinorityOwned",
      type: "GridColumnWidget",
      parent: "gridVendorList",
      Label: "Minority Owned",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColMinorityOwned_PROPERTIES

      // END_USER_CODE-USER_txtColMinorityOwned_PROPERTIES
    },
    txtColPhone1: {
      name: "txtColPhone1",
      type: "GridColumnWidget",
      parent: "gridVendorList",
      Label: "Phone #1",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPhone1_PROPERTIES

      // END_USER_CODE-USER_txtColPhone1_PROPERTIES
    },
    txtColPhone2: {
      name: "txtColPhone2",
      type: "GridColumnWidget",
      parent: "gridVendorList",
      Label: "Phone #2",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPhone2_PROPERTIES

      // END_USER_CODE-USER_txtColPhone2_PROPERTIES
    },
    txtColCellular: {
      name: "txtColCellular",
      type: "GridColumnWidget",
      parent: "gridVendorList",
      Label: "Phone #2",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColCellular_PROPERTIES

      // END_USER_CODE-USER_txtColCellular_PROPERTIES
    },
    txtColEmail2: {
      name: "txtColEmail2",
      type: "GridColumnWidget",
      parent: "gridVendorList",
      Label: "Phone #2",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColEmail2_PROPERTIES

      // END_USER_CODE-USER_txtColEmail2_PROPERTIES
    },
    txtColEmail3: {
      name: "txtColEmail3",
      type: "GridColumnWidget",
      parent: "gridVendorList",
      Label: "Phone #2",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColEmail3_PROPERTIES

      // END_USER_CODE-USER_txtColEmail3_PROPERTIES
    },
    txtColPhysicalAddress: {
      name: "txtColPhysicalAddress",
      type: "GridColumnWidget",
      parent: "gridVendorList",
      Label: "Physical Address",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPhysicalAddress_PROPERTIES

      // END_USER_CODE-USER_txtColPhysicalAddress_PROPERTIES
    },
    txtColVendorName: {
      name: "txtColVendorName",
      type: "GridColumnWidget",
      parent: "gridVendorList",
      Label: "Vendor Name",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColVendorName_PROPERTIES

      // END_USER_CODE-USER_txtColVendorName_PROPERTIES
    },
    txtColVendorNumber: {
      name: "txtColVendorNumber",
      type: "GridColumnWidget",
      parent: "gridVendorList",
      Label: "Vendor Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColVendorNumber_PROPERTIES

      // END_USER_CODE-USER_txtColVendorNumber_PROPERTIES
    },
    txtColVeteranOwned: {
      name: "txtColVeteranOwned",
      type: "GridColumnWidget",
      parent: "gridVendorList",
      Label: "Veteran Owned",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColVeteranOwned_PROPERTIES

      // END_USER_CODE-USER_txtColVeteranOwned_PROPERTIES
    },
    txtColWomenOwned: {
      name: "txtColWomenOwned",
      type: "GridColumnWidget",
      parent: "gridVendorList",
      Label: "Women Owned",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColWomenOwned_PROPERTIES

      // END_USER_CODE-USER_txtColWomenOwned_PROPERTIES
    },
    txtCustomerNumber: {
      name: "txtCustomerNumber",
      type: "TextBoxWidget",
      colName: "txtColCustomerNumber",
      parent: "gridVendorList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCustomerNumber_PROPERTIES

      // END_USER_CODE-USER_txtCustomerNumber_PROPERTIES
    },
    txtEmail1: {
      name: "txtEmail1",
      type: "TextBoxWidget",
      colName: "txtColEmail1",
      parent: "gridVendorList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEmail1_PROPERTIES

      // END_USER_CODE-USER_txtEmail1_PROPERTIES
    },
    txtFax: {
      name: "txtFax",
      type: "TextBoxWidget",
      colName: "txtColFax",
      parent: "gridVendorList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFax_PROPERTIES

      // END_USER_CODE-USER_txtFax_PROPERTIES
    },
    txtLienHolders: {
      name: "txtLienHolders",
      type: "TextBoxWidget",
      colName: "txtColLienHolders",
      parent: "gridVendorList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLienHolders_PROPERTIES

      // END_USER_CODE-USER_txtLienHolders_PROPERTIES
    },
    txtMailingAddress: {
      name: "txtMailingAddress",
      type: "TextBoxWidget",
      colName: "txtColMailingAddress",
      parent: "gridVendorList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMailingAddress_PROPERTIES

      // END_USER_CODE-USER_txtMailingAddress_PROPERTIES
    },
    txtMinorityOwned: {
      name: "txtMinorityOwned",
      type: "TextBoxWidget",
      colName: "txtColMinorityOwned",
      parent: "gridVendorList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMinorityOwned_PROPERTIES

      // END_USER_CODE-USER_txtMinorityOwned_PROPERTIES
    },
    txtPhone1: {
      name: "txtPhone1",
      type: "TextBoxWidget",
      colName: "txtColPhone1",
      parent: "gridVendorList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPhone1_PROPERTIES

      // END_USER_CODE-USER_txtPhone1_PROPERTIES
    },
    txtPhone2: {
      name: "txtPhone2",
      type: "TextBoxWidget",
      colName: "txtColPhone2",
      parent: "gridVendorList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPhone2_PROPERTIES

      // END_USER_CODE-USER_txtPhone2_PROPERTIES
    },
    txtCellular: {
      name: "txtCellular",
      type: "TextBoxWidget",
      colName: "txtColCellular",
      parent: "gridVendorList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCellular_PROPERTIES

      // END_USER_CODE-USER_txtCellular_PROPERTIES
    },
    txtEmail2: {
      name: "txtEmail2",
      type: "TextBoxWidget",
      colName: "txtColEmail2",
      parent: "gridVendorList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEmail2_PROPERTIES

      // END_USER_CODE-USER_txtEmail2_PROPERTIES
    },
    txtEmail3: {
      name: "txtEmail3",
      type: "TextBoxWidget",
      colName: "txtColEmail3",
      parent: "gridVendorList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEmail3_PROPERTIES

      // END_USER_CODE-USER_txtEmail3_PROPERTIES
    },
    txtPhysicalAddress: {
      name: "txtPhysicalAddress",
      type: "TextBoxWidget",
      colName: "txtColPhysicalAddress",
      parent: "gridVendorList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPhysicalAddress_PROPERTIES

      // END_USER_CODE-USER_txtPhysicalAddress_PROPERTIES
    },
    txtVendorName: {
      name: "txtVendorName",
      type: "TextBoxWidget",
      parent: "grpbxVendorDetails",
      Label: "Vendor Name:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorName_PROPERTIES

      // END_USER_CODE-USER_txtVendorName_PROPERTIES
    },
    txtVendorNameC: {
      name: "txtVendorNameC",
      type: "TextBoxWidget",
      colName: "txtColVendorName",
      parent: "gridVendorList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorNameC_PROPERTIES

      // END_USER_CODE-USER_txtVendorNameC_PROPERTIES
    },
    txtVendorNumber: {
      name: "txtVendorNumber",
      type: "TextBoxWidget",
      parent: "grpbxVendorDetails",
      Label: "Vendor Number:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorNumber_PROPERTIES

      // END_USER_CODE-USER_txtVendorNumber_PROPERTIES
    },
    txtVendorNumberC: {
      name: "txtVendorNumberC",
      type: "TextBoxWidget",
      colName: "txtColVendorNumber",
      parent: "gridVendorList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorNumberC_PROPERTIES

      // END_USER_CODE-USER_txtVendorNumberC_PROPERTIES
    },
    txtVeteranOwned: {
      name: "txtVeteranOwned",
      type: "TextBoxWidget",
      colName: "txtColVeteranOwned",
      parent: "gridVendorList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVeteranOwned_PROPERTIES

      // END_USER_CODE-USER_txtVeteranOwned_PROPERTIES
    },
    txtWomenOwned: {
      name: "txtWomenOwned",
      type: "TextBoxWidget",
      colName: "txtColWomenOwned",
      parent: "gridVendorList",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWomenOwned_PROPERTIES

      // END_USER_CODE-USER_txtWomenOwned_PROPERTIES
    },
    grpbxVendorDetails: {
      name: "grpbxVendorDetails",
      type: "GroupBoxWidget",
      parent: "VendorSearch",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxVendorDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorDetails_PROPERTIES
    },
    grpbxVendorList: {
      name: "grpbxVendorList",
      type: "GroupBoxWidget",
      parent: "VendorSearch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxVendorList_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorList_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "VendorSearch",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceApplicationSupport#VendorMaintenance": {}
      },
      REVERSE: {
        "SystemMaintenanceApplicationSupport#VendorMaintenance": {}
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnOpen: {
    //   DEFAULT: ["SystemMaintenanceApplicationSupport#VendorMaintenance#DEFAULT#true#Click"],
    // },
    btnClose: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    }
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    setValue(thisObj, "ddBuyingPoint", '');
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    if (getData(thisObj, 'closingVendorMaintainance') == true) {
      onbtnSearchClick()
      setData(thisObj, 'closingVendorMaintainance', false)
    }
  }, [getData(thisObj, 'closingVendorMaintainance')])

  // START_USER_CODE-USER_METHODS
  function FormLoad() {
    bFillBuyingPointList(); // Method for binding Buying Points   
    setLoading(false);
  }

  // Method for binding Buying Points ---START
  const bFillBuyingPointList = () => {
    let js = []
    js.push({ key: '', description: '>>>All Buying Points<<<' });
    ContractManagementService.RetrieveBuyingPointControlDetails('PN0350', null, null, null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddBuyingPoint: {
            ...state["ddBuyingPoint"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddBuyingPoint', js.at(0).key);
    })
  }
  // Method for binding Buying Points ---END

  // search btn implimentation --- Start
  const onbtnSearchClick = async () => {
    setLoading(true)
    let LstrCMD = [];
    let lstrxml = [];
    let LstrXML2 = [];
    let bHide_C_VENDOR_NUM = false
    let bVendorNumCount = 0
    let bHide_C_VENDOR_NAME = false
    let bVendorNameCount = 0
    let bHide_C_PHYSICAL_ADDRESS = false
    let bPhyAddCount = 0
    let bHide_C_MAIL_ADDRESS = false
    let bMailAddCount = 0
    let bHide_C_CUSTOMER_NUMBER = false
    let bCusNumCount = 0
    let bHide_C_PHONE1 = false
    let bPhone1Count = 0
    let bHide_C_PHONE_AP2 = false
    let bPhone2Count = 0
    let bHide_C_CELL = false
    let bCellCount = 0
    let bHide_C_FAX = false
    let bFaxCount = 0
    let bHide_C_EMAIL1 = false
    let bEmail1Count = 0
    let bHide_C_EMAIL2 = false
    let bEmail2Count = 0
    let bHide_C_EMAIL3 = false
    let bEmail3Count = 0
    let bHide_C_FORM211_GPC = false
    let bForm211GPCCount = 0
    let bHide_C_FORM211_CON = false
    let bForm211CONCount = 0
    let bHide_C_LIEN_COUNT = false
    let bLienCount = 0
    let bHide_C_WOMEN_OWNED = false
    let bWomenOwnCount = 0
    let bHide_C_MINORITY_OWNED = false
    let bMinOwnedCount = 0
    let bHide_C_VETERAN_OWNED = false
    let bVenOwnedCount = 0
    try {
      let buypoint = getValue(thisObj, "ddBuyingPoint")
      let vendorNum = getValue(thisObj, 'txtVendorNumber') == undefined ? '' : getValue(thisObj, 'txtVendorNumber')
      let vendorName = getValue(thisObj, 'txtVendorName') == undefined ? '' : getValue(thisObj, 'txtVendorName')

      if ((buypoint == "" || buypoint == undefined || buypoint == null) && (vendorNum.length < 3) && (vendorName.length < 3)) {
        alert("Must select a Buying Point from the list, or specify at minimum, 3 characters of the vendor number or the vendor name to perform a search!")
        return;
      }

      if (vendorNum.length >= 3) {
        vendorNum = vendorNum.replace('&', '+')
        //vendorNum = 
      } else {
        vendorNum = ''
      }
      if (vendorName.length >= 3) {
        //vname = txtVendorName.value
      }
      else {
        vendorName = ''
      }
      if (buypoint !== "") {
        buypoint = buypoint.trim()
      }

      LstrCMD = await ContractManagementService.RetrieveVendorEmailDetails(buypoint, 'N', null, null, vendorName, vendorNum)
      if (LstrCMD.length == 0) {
        showMessage(thisObj, "No Vendors Found!!!")
        setValue(thisObj, "gridVendorList", [])
        setLoading(false);
        return;
      }

      let js = []
      let Lst = []
      let LstObj = {}
      let obj = {}
      for (var i = 0; i < LstrCMD.length; i++) {

        obj.txtVendorNumberC = LstrCMD[i].vendorNumber;
        if (LstrCMD[i].vendorNumber == "") {
          bHide_C_VENDOR_NUM = true;
          bVendorNumCount++
        }

        obj.txtVendorNameC = LstrCMD[i].vendorSortName;
        if (LstrCMD[i].vendorSortName == "") {
          bHide_C_VENDOR_NAME = true;
          bVendorNameCount++
        }

        obj.txtPhysicalAddress = LstrCMD[i].vendorPhysAddr1 + "," + LstrCMD[i].vendorCity + "," + LstrCMD[i].vendorState + " " + LstrCMD[i].vendorPhysZip;
        if (LstrCMD[i].vendorPhysAddr1 + "," + LstrCMD[i].vendorCity + "," + LstrCMD[i].vendorState + " " + LstrCMD[i].vendorPhysZip == "") {
          bHide_C_PHYSICAL_ADDRESS = true;
          bPhyAddCount++
        }

        obj.txtMailingAddress = LstrCMD[i].vendorAddr1 + "," + LstrCMD[i].vendorCity + "," + LstrCMD[i].vendorState + " " + LstrCMD[i].vendorPhysZip;
        if (LstrCMD[i].vendorAddr1 + "," + LstrCMD[i].vendorCity + "," + LstrCMD[i].vendorState + " " + LstrCMD[i].vendorPhysZip == "") {
          bHide_C_MAIL_ADDRESS = true;
          bMailAddCount++
        }

        obj.txtCustomerNumber = LstrCMD[i].vendorCustno;
        if (LstrCMD[i].vendorCustno == "") {
          bHide_C_CUSTOMER_NUMBER = true;
          bCusNumCount++
        }

        obj.txtPhone1 = LstrCMD[i].vendorphoneap;
        if (LstrCMD[i].vendorphoneap == "") {
          bHide_C_PHONE1 = true;
          bPhone1Count++
        }

        obj.txtPhone2 = LstrCMD[i].vendorPhone;
        if (LstrCMD[i].vendorPhone == "") {
          bHide_C_PHONE_AP2 = true;
          bPhone2Count++
        }

        obj.txtCellular = LstrCMD[i].vendorCellNumber;
        if (LstrCMD[i].vendorCellNumber == "") {
          bHide_C_CELL = true;
          bCellCount++
        }

        obj.txtFax = LstrCMD[i].vendorFax;
        if (LstrCMD[i].vendorFax == "") {
          bHide_C_FAX = true;
          bFaxCount++
        }

        obj.txtEmail1 = LstrCMD[i].vendorEmail1;
        if (LstrCMD[i].vendorEmail1 == "") {
          bHide_C_EMAIL1 = true;
          bEmail1Count++
        }

        obj.txtEmail2 = LstrCMD[i].vendorEmail2;
        if (LstrCMD[i].vendorEmail2 == "") {
          bHide_C_EMAIL2 = true;
          bEmail2Count++
        }

        obj.txtEmail3 = LstrCMD[i].vendorEmail3;
        if (LstrCMD[i].vendorEmail3 == "") {
          bHide_C_EMAIL3 = true;
          bEmail3Count++
        }

        obj.txt211GPC = LstrCMD[i].form211_gpc;
        if (LstrCMD[i].form211_gpc == "") {
          bHide_C_FORM211_GPC = true;
          bForm211GPCCount++
        }

        obj.txt211Concordia = LstrCMD[i].form211_con;
        if (LstrCMD[i].form211_con == "") {
          bHide_C_FORM211_CON = true;
          bForm211CONCount++
        }

        obj.txtLienHolders = LstrCMD[i].lienholderName;
        if (LstrCMD[i].lienholderName == "") {
          bHide_C_LIEN_COUNT = true;
          bLienCount++
        }

        let womenowned = LstrCMD[i].womenOwned;
        switch (womenowned) {
          case "":
            obj.txtWomenOwned = "Not Disclosed";
            break;
          case "N":
            obj.txtWomenOwned = "No";
            break;
          case "Y":
            obj.txtWomenOwned = "Yes";
            break;
        }
        if (LstrCMD[i].womenOwned == "") {
          bHide_C_WOMEN_OWNED = true;
          bWomenOwnCount++
        }

        let minorityowned = LstrCMD[i].minorityOwned;
        switch (minorityowned) {
          case "":
            obj.txtMinorityOwned = "Not Disclosed";
            break;
          case "N":
            obj.txtMinorityOwned = "No";
            break;
          case "Y":
            obj.txtMinorityOwned = "Yes";
            break;

        }
        if (LstrCMD[i].minorityOwned == "") {
          bHide_C_MINORITY_OWNED = true;
          bMinOwnedCount++
        }

        let veteranowned = LstrCMD[i].veteranOwned;
        switch (veteranowned) {
          case "":
            obj.txtVeteranOwned = "Not Disclosed";
            break;
          case "N":
            obj.txtVeteranOwned = "No";
            break;
          case "Y":
            obj.txtVeteranOwned = "Yes";
            break;
        }
        if (LstrCMD[i].veteranOwned == "") {
          bHide_C_VETERAN_OWNED = true;
          bVenOwnedCount++
        }

        if (i > 0) {
          if (LstrCMD[i - 1].vendorNumber == LstrCMD[i].vendorNumber) {
            LstObj.txtVendorNumberC = ''
            LstObj.txtVendorNameC = ''
            LstObj.txtPhysicalAddress = ''
            LstObj.txtMailingAddress = ''
            LstObj.txtCustomerNumber = ''
            LstObj.txtPhone1 = ''
            LstObj.txtPhone2 = ''
            LstObj.txtCellular = ''
            LstObj.txtFax = ''
            LstObj.txtEmail1 = ''
            LstObj.txtEmail2 = ''
            LstObj.txtEmail3 = ''
            LstObj.txt211GPC = ''
            LstObj.txt211Concordia = ''
            LstObj.txtLienHolders = LstrCMD[i].lienholderName;
            LstObj.txtWomenOwned = ''
            LstObj.txtMinorityOwned = ''
            LstObj.txtVeteranOwned = ''
            obj.child = true
            Lst.push(LstObj)
            LstObj = {}
          }
          else {
            obj.child = false
            Lst.push(obj)
          }
        }
        else {
          obj.child = false
          Lst.push(obj)
        }

        js.push(obj)
        obj = {}

      }
      setData(thisObj, 'ParentOnlyGrid', Lst)
      // Verfing the columns data ----

      if (bVendorNumCount == LstrCMD.length) {
        hideColumn(thisObj, 'gridVendorList', 'txtVendorNumberC')
      }
      if (bVendorNameCount == LstrCMD.length) {
        hideColumn(thisObj, 'gridVendorList', 'txtVendorNameC')
      }
      if (bPhyAddCount == LstrCMD.length) {
        hideColumn(thisObj, 'gridVendorList', 'txtPhysicalAddress')
      }
      if (bMailAddCount == LstrCMD.length) {
        hideColumn(thisObj, 'gridVendorList', 'txtMailingAddress')
      }
      if (bCusNumCount == LstrCMD.length) {
        hideColumn(thisObj, 'gridVendorList', 'txtCustomerNumber')
      }
      if (bPhone1Count == LstrCMD.length) {
        hideColumn(thisObj, 'gridVendorList', 'txtPhone1')
      }
      if (bPhone2Count == LstrCMD.length) {
        hideColumn(thisObj, 'gridVendorList', 'txtPhone2')
      }
      if (bCellCount == LstrCMD.length) {
        hideColumn(thisObj, 'gridVendorList', 'txtCellular')
      }
      if (bFaxCount == LstrCMD.length) {
        hideColumn(thisObj, 'gridVendorList', 'txtFax')
      }
      if (bEmail1Count == LstrCMD.length) {
        hideColumn(thisObj, 'gridVendorList', 'txtEmail1')
      }
      if (bEmail2Count == LstrCMD.length) {
        hideColumn(thisObj, 'gridVendorList', 'txtEmail2')
      }
      if (bEmail3Count == LstrCMD.length) {
        hideColumn(thisObj, 'gridVendorList', 'txtEmail3')
      }
      if (bForm211GPCCount == LstrCMD.length) {
        hideColumn(thisObj, 'gridVendorList', 'txt211GPC')
      }
      if (bForm211CONCount == LstrCMD.length) {
        hideColumn(thisObj, 'gridVendorList', 'txt211Concordia')
      }
      if (bLienCount == LstrCMD.length) {
        hideColumn(thisObj, 'gridVendorList', 'txtLienHolders')
      }
      if (bWomenOwnCount == LstrCMD.length) {
        hideColumn(thisObj, 'gridVendorList', 'txtWomenOwned')
      }
      if (bMinOwnedCount == LstrCMD.length) {
        hideColumn(thisObj, 'gridVendorList', 'txtMinorityOwned')
      }
      if (bVenOwnedCount == LstrCMD.length) {
        hideColumn(thisObj, 'gridVendorList', 'txtVeteranOwned')
      }

      setValue(thisObj, "gridVendorList", js)
      setLoading(false);

    }
    catch (err) {
      setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception in Presubmit of event code of widget:btnSearch: Click"
        )
      }
    } finally {
      setLoading(false)
    }

  }
  thisObj.onbtnSearchClick = onbtnSearchClick;

  // search btn implimentation --- End

  // Export to excel functionality -- Start

  const onbtnExportToExcelClick = async () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    // let excelData = getValue(thisObj, "gridVendorList")
    let excelData = getData(thisObj, 'ParentOnlyGrid')
    if (excelData !== null || excelData !== undefined || excelData !== '') {
      var fileName = "Vendor Inquiry"
      const arrayData = []
      for (var i = 0; i < excelData.length; i++) {
        var obj = {}
        if (thisObj.state.gridVendorList.columns[0].Visible) {
          obj["Vendor Number"] = excelData[i].txtVendorNumberC
        }
        if (thisObj.state.gridVendorList.columns[1].Visible) {
          obj["Vendor Name"] = excelData[i].txtVendorNameC
        }
        if (thisObj.state.gridVendorList.columns[2].Visible) {
          obj["Physical Address"] = excelData[i].txtPhysicalAddress
        }
        if (thisObj.state.gridVendorList.columns[3].Visible) {
          obj["Mailing Address"] = excelData[i].txtMailingAddress
        }
        if (thisObj.state.gridVendorList.columns[4].Visible) {
          obj["Customer Number"] = excelData[i].txtCustomerNumber
        }
        if (thisObj.state.gridVendorList.columns[5].Visible) {
          obj["Phone #1"] = excelData[i].txtPhone1
        }
        if (thisObj.state.gridVendorList.columns[6].Visible) {
          obj["Phone #2"] = excelData[i].txtPhone2
        }
        if (thisObj.state.gridVendorList.columns[7].Visible) {
          obj["Cellular"] = excelData[i].txtCellular
        }
        if (thisObj.state.gridVendorList.columns[8].Visible) {
          obj["Fax"] = excelData[i].txtFax
        }
        if (thisObj.state.gridVendorList.columns[9].Visible) {
          obj["Email #1"] = excelData[i].txtEmail1
        }
        if (thisObj.state.gridVendorList.columns[10].Visible) {
          obj["Email #2"] = excelData[i].txtEmail2
        }
        if (thisObj.state.gridVendorList.columns[11].Visible) {
          obj["Email #3"] = excelData[i].txtEmail3
        }
        if (thisObj.state.gridVendorList.columns[12].Visible) {
          obj["211 GPC"] = excelData[i].txt211GPC
        }
        if (thisObj.state.gridVendorList.columns[13].Visible) {
          obj["211 Concordia"] = excelData[i].txt211Concordia
        }
        if (thisObj.state.gridVendorList.columns[14].Visible) {
          obj["Lien Holders"] = excelData[i].txtLienHolders
        }
        if (thisObj.state.gridVendorList.columns[15].Visible) {
          obj["Women Owned"] = excelData[i].txtWomenOwned
        }
        if (thisObj.state.gridVendorList.columns[16].Visible) {
          obj["Minority Owned"] = excelData[i].txtMinorityOwned
        }
        if (thisObj.state.gridVendorList.columns[17].Visible) {
          obj["Veteran Owned"] = excelData[i].txtVeteranOwned
        }


        arrayData.push(obj);
      }
      const ws = XLSX.utils.json_to_sheet(arrayData)
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      var FileSaver = require('file-saver');
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  }
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;
  // Export to excel functionality -- End

  // Print Button Functionality --Start
  const onbtnPrintSearchClick = () => {
    try {
      var obj = new jsPDF('landscape', 'mm', 'a4');
      // let datatable = getData(thisObj, "gridVendorList");
      let datatable = getData(thisObj, 'ParentOnlyGrid')
      if (datatable != undefined && datatable != null && datatable != "") {
        let data = []
        let data2 = []
        let headerarray = []
        let headerarray1 = []
        let headerarray2 = []

        if (thisObj.state.gridVendorList.columns[0].Visible) {
          headerarray.push("Vendor Number")
        }
        if (thisObj.state.gridVendorList.columns[1].Visible) {
          headerarray.push("Vendor Name")
        }
        if (thisObj.state.gridVendorList.columns[2].Visible) {
          headerarray.push("Physical Address")
        }
        if (thisObj.state.gridVendorList.columns[3].Visible) {
          headerarray.push("Mailing Address")
        }
        if (thisObj.state.gridVendorList.columns[4].Visible) {
          headerarray.push("Customer Number")
        }
        if (thisObj.state.gridVendorList.columns[5].Visible) {
          headerarray.push("Phone #1")
        }
        if (thisObj.state.gridVendorList.columns[6].Visible) {
          headerarray.push("Phone #2")
        }
        if (thisObj.state.gridVendorList.columns[7].Visible) {
          headerarray.push("Cellular")
        }
        if (thisObj.state.gridVendorList.columns[8].Visible) {
          headerarray.push("Fax")
        }
        if (thisObj.state.gridVendorList.columns[9].Visible) {
          headerarray.push("Email #1")
        }
        if (thisObj.state.gridVendorList.columns[10].Visible) {
          headerarray.push("Email #2")
        }
        if (thisObj.state.gridVendorList.columns[11].Visible) {
          headerarray.push("Email #3")
        }
        if (thisObj.state.gridVendorList.columns[12].Visible) {
          headerarray.push("211 GPC")
        }
        if (thisObj.state.gridVendorList.columns[13].Visible) {
          headerarray.push("211 Concordia")
        }
        if (thisObj.state.gridVendorList.columns[14].Visible) {
          headerarray.push("Lien Holders")
        }
        if (thisObj.state.gridVendorList.columns[15].Visible) {
          headerarray.push("Women Owned")
        }
        if (thisObj.state.gridVendorList.columns[16].Visible) {
          headerarray.push("Minority Owned")
        }
        if (thisObj.state.gridVendorList.columns[17].Visible) {
          headerarray.push("Veteran Owned")
        }

        if (headerarray.length > 9) {
          headerarray1 = headerarray.slice(0, 9)
          headerarray2 = headerarray.slice(9, 18)
        }
        else {
          headerarray1 = headerarray.slice(0, 9)
        }

        let Obj = { datatable: cropyear + "Vendor Search Results " }
        let count = 0;
        for (var i = 0; i < datatable.length; i++) {
          let bodyarray = []
          let childArray1 = []
          let childArray2 = []

          if (thisObj.state.gridVendorList.columns[0].Visible) {
            bodyarray.push(datatable[i].txtVendorNumberC)
          }
          if (thisObj.state.gridVendorList.columns[1].Visible) {
            bodyarray.push(datatable[i].txtVendorNameC)
          }
          if (thisObj.state.gridVendorList.columns[2].Visible) {
            bodyarray.push(datatable[i].txtPhysicalAddress)
          }
          if (thisObj.state.gridVendorList.columns[3].Visible) {
            bodyarray.push(datatable[i].txtMailingAddress)
          }
          if (thisObj.state.gridVendorList.columns[4].Visible) {
            bodyarray.push(datatable[i].txtCustomerNumber)
          }
          if (thisObj.state.gridVendorList.columns[5].Visible) {
            bodyarray.push(datatable[i].txtPhone1)
          }
          if (thisObj.state.gridVendorList.columns[6].Visible) {
            bodyarray.push(datatable[i].txtPhone2)
          }
          if (thisObj.state.gridVendorList.columns[7].Visible) {
            bodyarray.push(datatable[i].txtCellular)
          }
          if (thisObj.state.gridVendorList.columns[8].Visible) {
            bodyarray.push(datatable[i].txtFax)
          }
          if (thisObj.state.gridVendorList.columns[9].Visible) {
            bodyarray.push(datatable[i].txtEmail1)
          }
          if (thisObj.state.gridVendorList.columns[10].Visible) {
            bodyarray.push(datatable[i].txtEmail2)
          }
          if (thisObj.state.gridVendorList.columns[11].Visible) {
            bodyarray.push(datatable[i].txtEmail3)
          }
          if (thisObj.state.gridVendorList.columns[12].Visible) {
            bodyarray.push(datatable[i].txt211GPC)
          }
          if (thisObj.state.gridVendorList.columns[13].Visible) {
            bodyarray.push(datatable[i].txt211Concordia)
          }
          if (thisObj.state.gridVendorList.columns[14].Visible) {
            bodyarray.push(datatable[i].txtLienHolders)
          }
          if (thisObj.state.gridVendorList.columns[15].Visible) {
            bodyarray.push(datatable[i].txtWomenOwned)
          }
          if (thisObj.state.gridVendorList.columns[16].Visible) {
            bodyarray.push(datatable[i].txtMinorityOwned)
          }
          if (thisObj.state.gridVendorList.columns[17].Visible) {
            bodyarray.push(datatable[i].txtVeteranOwned)
          }

          if (bodyarray.length > 9) {

            data.push(bodyarray.slice(0, 9))
            data2.push(bodyarray.slice(9, 18))
          }
          // }
          else {
            data.push(bodyarray.slice(0, 9))

          }
        }

        let pagelimit = 0
        var Array = []
        // Obj = { datatable: cropyear + " " + "Vendor Search Results " }
        // Array.push(Obj)
        let N = 0;

        while (N < data.length) {
          // first set of 9 columns
          obj.autoTable({
            headStyles: { fontSize: 6, cellWidth: 'wrap' },
            styles: { fontSize: 6, lineWidth: 0.2 },
            headStyles: { fillColor: '#E0E0E0', textColor: '#000', fontStyle: 'normal', halign: 'center', valign: 'bottom' },
            margin: { top: 1, left: 6 },
            theme: 'plain',
            fontStyle: 'normal',
            head: [
              headerarray1
            ],
            body: data.slice(N, N + 28)
          })

          // footer of first 9 columns
          obj.autoTable({
            startY: 195,
            startX: 5,
            styles: { fontSize: 8, lineWidth: 0, color: 10 },
            margin: { bottom: 1, left: 6 },
            body: Array,
            theme: 'plain',
            fontStyle: 'normal',
          });

          obj.addPage();//Adding New page

          // remaining set of columns
          obj.autoTable({
            headStyles: { fontSize: 6, cellWidth: 'wrap' },
            styles: { fontSize: 6, lineWidth: 0.2 },
            headStyles: { fillColor: '#E0E0E0', textColor: '#000', fontStyle: 'normal', halign: 'center', valign: 'bottom' },
            margin: { top: 1, left: 6 },
            theme: 'plain',
            fontStyle: 'normal',
            head: [
              headerarray2
            ],
            body: data2.slice(N, N + 28)
          })

          // footer of remaining columns
          obj.autoTable({
            startY: 195,
            startX: 5,
            styles: { fontSize: 8, lineWidth: 0, color: 10 },
            margin: { bottom: 1, left: 0.5 },
            body: Array,
            theme: 'plain',
            fontStyle: 'normal',
          });
          N = N + 28;
        }
        // Page count 
        var pageCount = obj.internal.getNumberOfPages();
        for (let w = 1; w <= pageCount; w++) {
          obj.setPage(w);
          obj.setFontSize(8);
          obj.text(cropyear +' Vendor Search Results ' , 6, 200);
          obj.text(280, 200, String(w))
          
        }

        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf')
          }
          else {
            alert("Failed to print , please provide proper name to file")
          }
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnPrintClick click event"
        );
      }
    }
  }
  thisObj.onbtnPrintSearchClick = onbtnPrintSearchClick;

  // open Button Functionality --Start

  const onbtnOpenClick = async () => {
    try {

      let SelectedRow = thisObj.state.gridVendorList.selected[0]
      let gridVendorListDataObj = {}
      if (SelectedRow != null && SelectedRow != undefined) {
        gridVendorListDataObj.vendorNumber = SelectedRow.txtVendorNumberC
        gridVendorListDataObj.txtPhone = SelectedRow.txtPhone1
        gridVendorListDataObj.txtCell = SelectedRow.txtCellular
        gridVendorListDataObj.lblFax = SelectedRow.txtFax
        gridVendorListDataObj.txtEmail1 = SelectedRow.txtEmail1
        gridVendorListDataObj.txtEmail2 = SelectedRow.txtEmail2
        gridVendorListDataObj.txtEmail3 = SelectedRow.txtEmail3
        gridVendorListDataObj.optWomanOwned = SelectedRow.txtWomenOwned
        gridVendorListDataObj.optMinotityOwned = SelectedRow.txtMinorityOwned
        gridVendorListDataObj.optVeteranOwned = SelectedRow.txtVeteranOwned

        setData(thisObj, "frmVendorMaintenance", gridVendorListDataObj);
        goTo(thisObj, "SystemMaintenanceApplicationSupport#VendorMaintenance#DEFAULT#true#Click")
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnOpenClick click event"
        );
      }
    }
  }
  thisObj.onbtnOpenClick = onbtnOpenClick;
  // open Button Functionality --End

  // END_USER_CODE-USER_METHODS

  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            <Loading loading={loading}></Loading>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_VendorSearch*/}

              {/* END_USER_CODE-USER_BEFORE_VendorSearch*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxVendorDetails*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxVendorDetails*/}

              <GroupBoxWidget
                conf={state.grpbxVendorDetails}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddBuyingPoint}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_ddBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendorNumber*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendorNumber*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendorNumber}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendorNumber*/}

                {/* END_USER_CODE-USER_AFTER_txtVendorNumber*/}
                {/* START_USER_CODE-USER_BEFORE_lblMinOf3Chars1*/}

                {/* END_USER_CODE-USER_BEFORE_lblMinOf3Chars1*/}

                <LabelWidget
                  values={values}
                  conf={state.lblMinOf3Chars1}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblMinOf3Chars1*/}

                {/* END_USER_CODE-USER_AFTER_lblMinOf3Chars1*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendorName*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendorName*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendorName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendorName*/}

                {/* END_USER_CODE-USER_AFTER_txtVendorName*/}
                {/* START_USER_CODE-USER_BEFORE_lblMinOf3Chars2*/}

                {/* END_USER_CODE-USER_BEFORE_lblMinOf3Chars2*/}

                <LabelWidget
                  values={values}
                  conf={state.lblMinOf3Chars2}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblMinOf3Chars2*/}

                {/* END_USER_CODE-USER_AFTER_lblMinOf3Chars2*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxVendorDetails*/}

              {/* END_USER_CODE-USER_AFTER_grpbxVendorDetails*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxVendorList*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxVendorList*/}

              <GroupBoxWidget conf={state.grpbxVendorList} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblVendorList*/}

                {/* END_USER_CODE-USER_BEFORE_lblVendorList*/}

                <LabelWidget
                  values={values}
                  conf={state.lblVendorList}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblVendorList*/}

                {/* END_USER_CODE-USER_AFTER_lblVendorList*/}
                {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                <ButtonWidget
                  conf={state.btnSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                {/* START_USER_CODE-USER_BEFORE_btnOpen*/}

                {/* END_USER_CODE-USER_BEFORE_btnOpen*/}

                <ButtonWidget
                  conf={state.btnOpen}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOpen*/}

                {/* END_USER_CODE-USER_AFTER_btnOpen*/}
                {/* START_USER_CODE-USER_BEFORE_gridVendorList*/}

                {/* END_USER_CODE-USER_BEFORE_gridVendorList*/}

                <GridWidget
                  conf={state.gridVendorList}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridVendorList}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridVendorList*/}

                {/* END_USER_CODE-USER_AFTER_gridVendorList*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxVendorList*/}

              {/* END_USER_CODE-USER_AFTER_grpbxVendorList*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                <ButtonWidget
                  conf={state.btnExportToExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                {/* START_USER_CODE-USER_BEFORE_btn1*/}

                {/* END_USER_CODE-USER_BEFORE_btn1*/}

                <ButtonWidget
                  conf={state.btn1}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btn1*/}

                {/* END_USER_CODE-USER_AFTER_btn1*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrintSearch*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrintSearch*/}

                <ButtonWidget
                  conf={state.btnPrintSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrintSearch*/}

                {/* END_USER_CODE-USER_AFTER_btnPrintSearch*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_VendorSearch*/}

              {/* END_USER_CODE-USER_AFTER_VendorSearch*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceApplicationSupport_VendorSearch);
