/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  ButtonWidget,
  CheckboxGroupWidget,
  setData,
  hide,
  getData,
  show,
  setValue,
  goTo
} from "../../shared/WindowImports";
import "./ReportPreviewInspection.scss";

// START_USER_CODE-USER_IMPORTS
import Loading from "../../../Loader/Loading";
import ContractManagement_header from "../../Header/Header";
import { SettlementService } from "../Service/SettlementService";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import moment from "moment";
import { GetReportLogoImage, GetReportLogoImageDimension } from "./../../Common/Constants";

// END_USER_CODE-USER_IMPORTS
function Settlements_ReportPreviewInspection(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const imgUrl = GetReportLogoImage(window.location.origin)
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  const compIdFromLS = () => (sessionStorage.getItem('compId'));
  const imgDimension = GetReportLogoImageDimension()

  let Obj = {}
  let data = {}

  let msPDF_SettlementNo = ''
  let msPDF_AgreementNo = ''
  let msPDF_InspectionNo = ''
  let msPDF_BP = ''
  let msReport = '';
  let Action = '';
  let Status = '';
  let pmViewDoc = false;
  let mbPrintSelected = false;
  let mbPrintFirst = false;
  let mbPrintPDF = false;
  let strDev1007PrintOpt = "";
  let exitFlag = false;
  let selectedCheckBox = [];
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ReportPreviewInspection",
    windowName: "ReportPreviewInspection",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.ReportPreviewInspection",
    horizontalForm:true,
    // START_USER_CODE-USER_ReportPreviewInspection_PROPERTIES
    headerData: {
      scrName: "Print Preview",
      scrCode: "PN9000A",
    },
    renderUrl : '', 
    renderCode : '',
    // END_USER_CODE-USER_ReportPreviewInspection_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxReportPreviewInspection",
      Label: "Close",
      CharWidth: "13",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "grpbxReportPreviewInspection",
      Label: "Print",
      CharWidth: "13",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnPrintSetup: {
      name: "btnPrintSetup",
      type: "ButtonWidget",
      parent: "grpbxReportPreviewInspection",
      Label: "Print Setup",
      CharWidth: "24",
      // START_USER_CODE-USER_btnPrintSetup_PROPERTIES

      // END_USER_CODE-USER_btnPrintSetup_PROPERTIES
    },
    btnSaveAsPDF: {
      name: "btnSaveAsPDF",
      type: "ButtonWidget",
      parent: "grpbxReportPreviewInspection",
      Label: "Save as PDF",
      CharWidth: "25",
      // START_USER_CODE-USER_btnSaveAsPDF_PROPERTIES

      // END_USER_CODE-USER_btnSaveAsPDF_PROPERTIES
    },
    chkboxReprint: {
      name: "chkboxReprint",
      type: "CheckBoxGroupFieldWidget",
      parent: "grpbxReportPreviewInspection",
      Options: "APPLICANT Reprint:1,FSIS Reprint:2,SELLER Reprint:3",
      ColSpan: "3",
      ColSpanForLargeDesktop: "7",
      ColSpanForTabLandscape: "7",
      ColSpanForTabPotrait: "7",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxReprint_PROPERTIES

      // END_USER_CODE-USER_chkboxReprint_PROPERTIES
    },
    grpbxPreview: {
      name: "grpbxPreview",
      type: "GroupBoxWidget",
      parent: "grpbxReportPreviewInspection",
      ColSpan: "7",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "7",
      // START_USER_CODE-USER_grpbxPreview_PROPERTIES

      // END_USER_CODE-USER_grpbxPreview_PROPERTIES
    },
    grpbxReportPreviewInspection: {
      name: "grpbxReportPreviewInspection",
      type: "GroupBoxWidget",
      parent: "ReportPreviewInspection",
      Height: "",
      Width: "",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxReportPreviewInspection_PROPERTIES

      // END_USER_CODE-USER_grpbxReportPreviewInspection_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};

  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);

  const reportType = {
    correctionDocument : 'CORRECTION DOCUMENT',
    settleWorkSheet    : 'rptSettlementWorkSheet',
    inspectionOneDoc   : 'rptInspectionCertificate_onedoc',
    inspectionTwoDoc   : 'rptInspectionCertificate'
  }

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };

  useEffect(() => {
    parentWindow(thisObj);
  });

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    hide(thisObj, 'btnPrintSetup')
    hide(thisObj, 'btnSaveAsPDF')
    GetApiData();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() =>{
    return () => {
      setData(thisObj, "closingOfReportPreviewInspection", true)
    }
  });
  // START_USER_CODE-USER_METHODS

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  async function GetApiData(){
    hide(thisObj, 'chkboxReprint', false)
    await ReadFile();
  }

async function ReadFile() {
    var renderCode = await GetHtmlCode()
    if(renderCode != '' && renderCode != null && renderCode != undefined){
      state.renderCode = renderCode
      state.renderUrl = getBlobURL(renderCode, 'text/html')
      document.getElementById("docFrame").setAttribute("src", state.renderUrl);
    } 
    // else{
    //   document.getElementById("Settlements_ReportPreviewInspectionPopUp").childNodes[0].click();
    // }
    setLoading(false);
}

async function GetHtmlCode() {
  var renderCode = ''
  let reportData = getData(thisObj, "ReportPreviewInspectionScreenData")
  let FV95SearchReprint= getData(thisObj,"FV95SearchReprint");
  if(reportData == null  || reportData == undefined){
    showMessage(thisObj, "Details cannot be fetched. Please try again")
    return;
  }
  if(reportData.length > 0 && FV95SearchReprint =="Y")
  {
    for (let c = 0; c < reportData.length; c++) {
    if(reportData[c].docType !== undefined){
      if(reportData[c].docType == '1007Worksheet'){
        setData(thisObj, "worksheet_printed_successfully", true)
      }
    }
    
    if(reportData[c].method_name == reportType.correctionDocument)
      renderCode += await rptCorrectionDocument(reportData[c].SC95No, reportData[c].buy_pt_id, false, false)
    else if(reportData[c].method_name == reportType.settleWorkSheet)
      renderCode += await rptSettlementWorkSheet(reportData[c].SC95No)
    else if(reportData[c].method_name == reportType.inspectionOneDoc){
      //show(thisObj, 'chkboxReprint')    
      if(reportData[c].type == "REPRINT_WITH_CHECKBOX"){
        if(thisObj.values.chkboxReprint == undefined){
          thisObj.values.chkboxReprint = [ '1', '2', '3' ]
        } else if(thisObj.values.chkboxReprint.length == 0){
          thisObj.values.chkboxReprint = [ '1', '2', '3' ]
        }
        else{
          thisObj.values.chkboxReprint = thisObj.values.chkboxReprint
        }
      }
      
      if(reportData[c].docType == undefined)
      reportData[c].docType = 'Applicant Original'
          
      if(reportData[c].docType.split(',').length > 1)
        renderCode += await rptInspectionReprint(reportData[c].docType.split(','), reportData[c], reportType.inspectionOneDoc)
      else
        renderCode += await rptInspectionCertificate_onedoc(reportData[c], reportData[c].docType.split(',')[0])
    }
    else if(reportData[c].method_name == reportType.inspectionTwoDoc){
     // show(thisObj, 'chkboxReprint')
      
      if(thisObj.values.chkboxReprint == undefined){
        thisObj.values.chkboxReprint = [ '1', '2', '3' ]
      } else if(thisObj.values.chkboxReprint.length == 0){
        thisObj.values.chkboxReprint = [ '1', '2', '3' ]
      }
      else{
        thisObj.values.chkboxReprint = thisObj.values.chkboxReprint
      }
      
      if(reportData[c].docType == undefined)
      reportData[c].docType = 'Applicant Original'
          
      if(reportData[c].docType.split(',').length > 1)
        renderCode += await rptInspectionReprint(reportData[c].docType.split(','), reportData[c], reportType.inspectionTwoDoc)
      else
        setData(thisObj,"ReportPreviewInspectionScreenDatadocType",reportData[c].docType);
        renderCode += await rptInspectionCertificate(reportData[c].SC95No, reportData[c].s1007No)
    }
    else
      renderCode += ''
  }
  }
  else
  {
  if(reportData.docType !== undefined){
    if(reportData.docType == '1007Worksheet'){
      setData(thisObj, "worksheet_printed_successfully", true)
    }
  }
  
  if(reportData.method_name == reportType.correctionDocument)
    renderCode = await rptCorrectionDocument(reportData.SC95No, reportData.buy_pt_id, false, false)
  else if(reportData.method_name == reportType.settleWorkSheet)
    renderCode = await rptSettlementWorkSheet(reportData.SC95No)
  else if(reportData.method_name == reportType.inspectionOneDoc){
    show(thisObj, 'chkboxReprint')    
    if(reportData.type == "REPRINT_WITH_CHECKBOX"){
      if(thisObj.values.chkboxReprint == undefined){
        thisObj.values.chkboxReprint = [ '1', '2', '3' ]
      } else if(thisObj.values.chkboxReprint.length == 0){
        thisObj.values.chkboxReprint = [ '1', '2', '3' ]
      }
      else{
        thisObj.values.chkboxReprint = thisObj.values.chkboxReprint
      }
    }
    
    if(reportData.docType == undefined)
      reportData.docType = 'Applicant Original'
        
    if(reportData.docType.split(',').length > 1)
      renderCode = await rptInspectionReprint(reportData.docType.split(','), reportData, reportType.inspectionOneDoc)
    else
      renderCode = await rptInspectionCertificate_onedoc(reportData, reportData.docType.split(',')[0])
  }
  else if(reportData.method_name == reportType.inspectionTwoDoc){
    show(thisObj, 'chkboxReprint')
    
    if(thisObj.values.chkboxReprint == undefined){
      thisObj.values.chkboxReprint = [ '1', '2', '3' ]
    } else if(thisObj.values.chkboxReprint.length == 0){
      thisObj.values.chkboxReprint = [ '1', '2', '3' ]
    }
    else{
      thisObj.values.chkboxReprint = thisObj.values.chkboxReprint
    }
    
    if(reportData.docType == undefined)
      reportData.docType = 'Applicant Original'
        
    if(reportData.docType.split(',').length > 1)
      renderCode = await rptInspectionReprint(reportData.docType.split(','), reportData, reportType.inspectionTwoDoc)
    else
      renderCode = await rptInspectionCertificate(reportData.SC95No, reportData.s1007No)
  }
  else
    renderCode = ''
}
  if(renderCode == '' || renderCode == undefined || renderCode == null){
    showMessage('No suitable type of report found.')
    return '';
  }
  return renderCode;
}

async function rptInspectionReprint(rcptArr, data, methodName){
  try{
    let renderCode = ''
    for(var i=0; i<rcptArr.length; i++){  
      let js = {
        SC95No      : data.SC95No,
        WaterMark   : data.WaterMark == undefined ? 'Test' : data.WaterMark,
        buy_pt_id   : data.buy_pt_id,
        docType     : rcptArr[i],
        method_name : methodName,
        pmViewDoc   : data.pmViewDoc == undefined ? false : data.pmViewDoc,
        s1007No     : data.s1007No,
        status      : data.status,
        type        : data.type
      }

      if(rcptArr.docType == 'WS'){
        js.method_name = reportType.settleWorkSheet
        setData(thisObj, 'ReportPreviewInspectionScreenData', js)
        let res = confirm("Do you want to print the worksheet ?")
        if(!res)
          return;        
      }        
      setData(thisObj, 'ReportPreviewInspectionScreenData', js)
      setLoading(true)
      renderCode = await Print()
    }
    return renderCode;
  }
  catch(err){
    errorHandler(err)
  }
}

const rptInspectionCertificate = async(FV95number, Settle_num) => {
  // try{
    let lstrxml = "";
    let LstrXML_Rev = "";
    let LstrXMLContracts = "";
    let LstrXMLSeedContracts = "";
    let LstrXML_Subs = "";
    let LstrXML_Q_Remarks = "";
    let LstrXML_Q_RemSeed = "";
    let LintVendorSplit_P = 0;
    let LintVendorSplit_S = 0;
    let LstrApplicantName = "";
    let colVendorPS = [];
    let sPrevPremCode = "";
    let nIteration = 0;
    let bDisplayTons = false;
    let sContract = "";
    let sSeedContract = "";
    let bNeedsAttachment = false;
    let nVendorIndex = 0;
    let sAdditionVendorInfo = "";
    let sGpcOwned = "";
    let Lstrfedname = "";
    let LblnPrint1007Storage = false;
    let nQRemarkLines = 0;
    let nQRemarksOnLine = 0;
    let g = "";
    let nMinHistoryRev = 0;
    let dblOptionPayment = 0;
    let sSymbol = "";
    let TypIC = {};
    let LstrWtTicketNos = ''
    let LstrVehicleNos = ''
    TypIC.Purch_LBS_LineG = ''
    TypIC.Purch_xLineP = 0
    TypIC.Purch_ValOfSegment = 0
    
    msReport = "1007";

    let reportData = getData(thisObj, 'ReportPreviewInspectionScreenData')
    let reportDatadocType = getData(thisObj,"ReportPreviewInspectionScreenDatadocType");
    TypIC.DocReceipient =reportData.docType == undefined ? reportDatadocType : reportData.docType;
    //TypIC.DocReceipient = reportData.docType

    if(FV95number.trim() == ''){
      TypIC.msPDF_SettlementNo = Settle_num;
      TypIC.msPDF_InspectionNo = cropYearFromLS() < 2015 ? 'NO_FV95' : 'NO_SC95'
      TypIC.ComputerSerial = Settle_num;
      TypIC.BuyingPoint_Location = ""
      let data = {
        func_id : 'PN1060',
        settlement_num : Settle_num
      }
      let lstrxml = await SettlementService.RetrieveInspectionHeaderDetails(data)
      if(lstrxml.length > 0){
        TypIC.msPDF_BP = ''
        lstrxml = await ContractManagementService.RetrieveBuyingPointControlDetails('PN9000', null, null, null, '2CY')
        if(lstrxml.length > 0){
          TypIC.BuyingPoint_Location = ''
        }
      }
      else{
        TypIC.msPDF_BP = "NO_LOC"
      }      
    }
    else{
      nMinHistoryRev = 0

      if(Action.toLocaleUpperCase() == 'REPRINT'){
        LstrXML_Rev = await SettlementService.RetrieveInspectHeaderPrint1007Details(FV95number)
        if(isNaN(Settle_num))
          Settle_num = LstrXML_Rev[0].settlement_num;
        nMinHistoryRev = isNaN(LstrXML_Rev[0].min_history_rev) ? 0 : Number(LstrXML_Rev[0].min_history_rev)
      }
      if(Status.toLocaleUpperCase() == 'VOID' && nMinHistoryRev > 0){
        TypIC.RESIDUE_IND = ""
        lstrxml = await SettlementService.RetrieveInspectionHeaderVoidReprintList(FV95number, Settle_num , '1007', null, null)
      }
      else if(Status.toLocaleUpperCase() == 'RESIDUE'){
        TypIC.RESIDUE_IND = "RESIDUE"
        lstrxml = await SettlementService.RetrieveInspectHeaderResidueDetails(null, FV95number)
      }
      else{
        TypIC.RESIDUE_IND = ""
        lstrxml = await SettlementService.RetrieveInspectionHeaderList(FV95number, null, '1007', null)
        if(lstrxml.length <= 0){
          goTo(thisObj, 'Settlements#FV95Search#DEFAULT#false#Click')
          return true;
        }
      }
      if(lstrxml.length > 0){
        TypIC.ContractList = ""
        TypIC.msPDF_SettlementNo = Settle_num.trim()
        TypIC.msPDF_InspectionNo = FV95number.trim()
        TypIC.msPDF_BP = lstrxml[0].buy_pt_id

        if(TypIC.RESIDUE_IND !== ''){
          TypIC.TypeOfInspection = lstrxml[0].type_of_inspection
          TypIC.ContractList = ""
          TypIC.SeedContractList = ""
          TypIC.Q_Remarks_Seed = ""
        }
        else{
          LstrXMLContracts = await ContractManagementService.RetrieveApplicationContractDetails(null, null, FV95number, null, null)
          // if(LstrXMLContracts.length <= 0)
          //   return;

          sContract = ""
          TypIC.ContractList = ""
          for(var i=0; i<LstrXMLContracts.length; i++){
            if(sContract !== LstrXMLContracts[i].contract_num){
              TypIC.ContractList += (LstrXMLContracts[i].contract_num + ",")
              sContract = LstrXMLContracts[i].contract_num
            }
          }
          if(TypIC.ContractList.length > 0)
            TypIC.ContractList = TypIC.ContractList.slice(-1)
  
          LstrXMLContracts = await ContractManagementService.RetrieveApplicationContractDetails(0, null, FV95number, null, null)
          // if(LstrXMLContracts.length <= 0)
          //   return;
          sSeedContract = ""
          TypIC.SeedContractList = ""
          for(var i=0; i<LstrXMLContracts.length; i++){
            if(sContract !== LstrXMLContracts[i].contract_num){
              TypIC.SeedContractList += (LstrXMLContracts[i].contract_num + ",")
              sSeedContract = LstrXMLContracts[i].contract_num
            }
          }
          if(TypIC.SeedContractList.length > 0)
            TypIC.SeedContractList = TypIC.SeedContractList.slice(-1)
  
          LstrXML_Q_RemSeed = lstrxml[0].q_seed_remarks
          TypIC.Q_Remarks_Seed = ""

          for(var i=0; i<LstrXML_Q_RemSeed.length; i++){
            TypIC.Q_Remarks_Seed += ('SAgree: ' + LstrXML_Q_RemSeed[i].contract_num)
            TypIC.Q_Remarks_Seed += (' Vendor: ' + LstrXML_Q_RemSeed[i].split_vendor)
            TypIC.Q_Remarks_Seed += (' Lbs: ' + LstrXML_Q_RemSeed[i].appl_lbs_adj)
            TypIC.Q_Remarks_Seed += ' '
          }
        }
        TypIC.SettlementDate = lstrxml[0].settlement_date
        
        if(TypIC.RESIDUE_IND !== ""){
          if(TypIC.TypeOfInspection.trim() == ""){
            TypIC.RESIDUE_IND = "RESIDUE"
            TypIC.OperatorSellerName_COCode_FarmNo = "00-000-0000000-000" + "     NOT GIVEN"
          }
          else{
            TypIC.RESIDUE_IND = "RESALE"
            TypIC.OperatorSellerName_COCode_FarmNo = lstrxml[0].seller_num + " " + lstrxml[0].seller_name
          }
        }
        else{
          TypIC.OperatorSellerName_COCode_FarmNo = lstrxml[0].state_id + " " + lstrxml[0].county_id + " " + lstrxml[0].farm_id + " " + lstrxml[0].farm_suffix
        }

        TypIC.BuyingPoint_Location = lstrxml[0].buy_pt_fed_id + " "  + lstrxml[0].buy_pt_physical_city + ", " + lstrxml[0].buy_pt_physical_state
        TypIC.AREA_ID = lstrxml[0].area_id
        TypIC.ComputerSerial = lstrxml[0].settlement_num
        TypIC.BottomRightNumber = lstrxml[0].weight_cert_num
        TypIC.VCSerial = ""
        TypIC.VendorList = ""

        colVendorPS = []
        bNeedsAttachment = false
        sAdditionVendorInfo = ''
        nVendorIndex = 1

        TypIC.VendorList = ''
        TypIC.ProducerName1 = ''
        TypIC.ProducerName2 = ''
        TypIC.ProducerName3 = ''
        TypIC.ProducerName4 = ''
        
        LstrXML_Subs = TypIC.RESIDUE_IND !== '' ? [] : lstrxml[0].inspect_vendor_splits
        for(var i=0; i<LstrXML_Subs.length; i++){
          switch(nVendorIndex.toString()){
            case "1":
              TypIC.ProducerName1 = LstrXML_Subs[i].vendor_name
              if(LstrXML_Subs[i].split_vendor != '' && LstrXML_Subs[i].split_vendor != null && LstrXML_Subs[i].split_vendor != undefined){
                TypIC.VendorList = TypIC.VendorList + LstrXML_Subs[i].split_vendor
              }
              if(LstrXML_Subs[i].split_share_pct != '' && LstrXML_Subs[i].split_share_pct != null && LstrXML_Subs[i].split_share_pct != undefined){
                TypIC.Share_Percentage1 = Number(LstrXML_Subs[i].split_share_pct).toFixed(3)
              }
              else{
                TypIC.Share_Percentage1 = ""
              }
              if(LstrXML_Subs[i].purh_stor_ind == "P"){
                TypIC.Purch_LBS_LineG = Number(TypIC.Purch_LBS_LineG) + Number(LstrXML_Subs[i].net_wt)
                TypIC.Purch_xLineP += Number(LstrXML_Subs[i].basis_grade_amt)
                TypIC.Purch_xPercentage = LstrXML_Subs[i].support_pct
                TypIC.Purch_ValOfSegment = Number(TypIC.Purch_ValOfSegment) + Number(LstrXML_Subs[i].value_of_seg) - Number(LstrXML_Subs[i].opt_value_of_seg)
                dblOptionPayment = dblOptionPayment + Number(LstrXML_Subs[i].opt_value_of_seg)
                TypIC.Purch_NetAmount = Number(TypIC.Purch_NetAmount == undefined ? 0 : TypIC.Purch_NetAmount) + Number(LstrXML_Subs[i].proceeds_amt)
                LintVendorSplit_P = LintVendorSplit_P + 1
              }
              else if(LstrXML_Subs[i].purh_stor_ind == "S"){
                TypIC.Store_LBS_LineG = LstrXML_Subs[i].net_wt
                TypIC.Store_xLineP = LstrXML_Subs[i].basis_grade_amt
                TypIC.Store_xPercentage = LstrXML_Subs[i].support_pct
                TypIC.Store_ValOfSegment = Number(LstrXML_Subs[i].value_of_seg) - Number(LstrXML_Subs[i].opt_value_of_seg)
                dblOptionPayment = dblOptionPayment + Number(LstrXML_Subs[i].opt_value_of_seg)
                LintVendorSplit_S = LintVendorSplit_S + 1
              }
              break;
            
            case "2":
              TypIC.ProducerName2 = LstrXML_Subs[i].vendor_name
              if(LstrXML_Subs[i].split_vendor != '' && LstrXML_Subs[i].split_vendor != null && LstrXML_Subs[i].split_vendor != undefined){
                TypIC.VendorList = TypIC.VendorList & ", " + LstrXML_Subs[i].split_vendor
              }
              if(LstrXML_Subs[i].split_share_pct != '' && LstrXML_Subs[i].split_share_pct != null && LstrXML_Subs[i].split_share_pct != undefined){
                TypIC.Share_Percentage2 = (Number(LstrXML_Subs[i].split_share_pct)).toFixed(3)
              }
              else{
                TypIC.Share_Percentage2 = ""
              }
              if(LstrXML_Subs[i].purh_stor_ind == "P"){
                TypIC.Purch_LBS_LineG = Number(TypIC.Purch_LBS_LineG) + Number(LstrXML_Subs[i].net_wt)
                TypIC.Purch_xLineP += Number(LstrXML_Subs[i].basis_grade_amt)
                TypIC.Purch_xPercentage = LstrXML_Subs[i].support_pct
                TypIC.Purch_ValOfSegment = Number(TypIC.Purch_ValOfSegment) + Number(LstrXML_Subs[i].value_of_seg) - Number(LstrXML_Subs[i].opt_value_of_seg)
                TypIC.Purch_NetAmount = Number(TypIC.Purch_NetAmount == undefined ? 0 : TypIC.Purch_NetAmount) + Number(LstrXML_Subs[i].proceeds_amt)
                dblOptionPayment = dblOptionPayment + Number(LstrXML_Subs[i].opt_value_of_seg)
                LintVendorSplit_P = LintVendorSplit_P + 1
              }
              else if(LstrXML_Subs[i].purh_stor_ind == "S"){
                TypIC.Store_LBS_LineG = Number(TypIC.Store_LBS_LineG == undefined ? 0 :TypIC.Store_LBS_LineG) + Number(LstrXML_Subs[i].net_wt)
                TypIC.Store_xLineP = Number(TypIC.Store_xLineP) + Number(LstrXML_Subs[i].basis_grade_amt)
                TypIC.Store_xPercentage = Number(LstrXML_Subs[i].support_pct)
                TypIC.Store_ValOfSegment = Number(TypIC.Store_ValOfSegment) + Number(LstrXML_Subs[i].value_of_seg) - Number(LstrXML_Subs[i].opt_value_of_seg)
                dblOptionPayment = dblOptionPayment + Number(LstrXML_Subs[i].opt_value_of_seg)
                LintVendorSplit_S = LintVendorSplit_S + 1
              }
              break;          
            case "3":
              TypIC.ProducerName3 = LstrXML_Subs[i].vendor_name
              if(LstrXML_Subs[i].split_vendor != '' && LstrXML_Subs[i].split_vendor != null && LstrXML_Subs[i].split_vendor != undefined){
                TypIC.VendorList = TypIC.VendorList + ", " + LstrXML_Subs[i].split_vendor
              }
              if(LstrXML_Subs[i].split_share_pct != '' && LstrXML_Subs[i].split_share_pct != null && LstrXML_Subs[i].split_share_pct != undefined){
                TypIC.Share_Percentage3 = (Number(LstrXML_Subs[i].split_share_pct)).toFixed(3)
              }
              else{
                TypIC.Share_Percentage3 = ""
              }
              if(LstrXML_Subs[i].purh_stor_ind == "P"){
                TypIC.Purch_LBS_LineG = Number(TypIC.Purch_LBS_LineG) + Number(LstrXML_Subs[i].net_wt)
                TypIC.Purch_xLineP += Number(LstrXML_Subs[i].basis_grade_amt)
                TypIC.Purch_xPercentage = Number(LstrXML_Subs[i].support_pct)
                TypIC.Purch_ValOfSegment = Number(TypIC.Purch_ValOfSegment) + Number(LstrXML_Subs[i].value_of_seg) - Number(LstrXML_Subs[i].opt_value_of_seg)//  Val(GetInside(LstrXML_Subs[i], "<value_of_seg>", "</value_of_seg>")) - Val(GetInside(LstrXML_Subs, "<opt_value_of_seg>", "</opt_value_of_seg>"))
                dblOptionPayment = dblOptionPayment + Number(LstrXML_Subs[i].opt_value_of_seg)
                TypIC.Purch_NetAmount = Number(TypIC.Purch_NetAmount == undefined ? 0 : TypIC.Purch_NetAmount) + Number(LstrXML_Subs[i].proceeds_amt)
                LintVendorSplit_P = LintVendorSplit_P + 1
              }
              else if(LstrXML_Subs[i].purh_stor_ind == "S"){
                TypIC.Store_LBS_LineG = Number(TypIC.Store_LBS_LineG == undefined ? 0 :TypIC.Store_LBS_LineG) + Number(LstrXML_Subs[i].net_wt)
                TypIC.Store_xLineP = Number(TypIC.Store_xLineP) + Number(LstrXML_Subs[i].basis_grade_amt)
                TypIC.Store_xPercentage = Number(LstrXML_Subs[i].support_pct)
                TypIC.Store_ValOfSegment = Number(TypIC.Store_ValOfSegment) + Number(LstrXML_Subs[i].value_of_seg) - Number(LstrXML_Subs[i].opt_value_of_seg)
                dblOptionPayment = dblOptionPayment + LstrXML_Subs[i].opt_value_of_seg
                LintVendorSplit_S = LintVendorSplit_S + 1
              }
              break;
            case "4":
              TypIC.ProducerName4 = LstrXML_Subs[i].vendor_name
              if(LstrXML_Subs[i].split_vendor != '' && LstrXML_Subs[i].split_vendor != null && LstrXML_Subs[i].split_vendor != undefined){
                TypIC.VendorList = TypIC.VendorList + ", " + LstrXML_Subs.split_vendor
              }
              if(LstrXML_Subs[i].split_share_pct != '' && LstrXML_Subs[i].split_share_pct != null && LstrXML_Subs[i].split_share_pct != undefined){
                TypIC.Share_Percentage4 = (Number(LstrXML_Subs[i].split_share_pct)).toFixed(3)
              }
              else{
                TypIC.Share_Percentage4 = ""
              }
              if(LstrXML_Subs[i].purh_stor_ind != '' && LstrXML_Subs[i].purh_stor_ind != null && LstrXML_Subs[i].purh_stor_ind != undefined){
                TypIC.Purch_LBS_LineG = Number(TypIC.Purch_LBS_LineG) + Number(LstrXML_Subs[i].net_wt)
                TypIC.Purch_xLineP += Number(LstrXML_Subs[i].basis_grade_amt)
                TypIC.Purch_xPercentage = Number(LstrXML_Subs[i].support_pct)
                TypIC.Purch_ValOfSegment = Number(TypIC.Purch_ValOfSegment) + Number(LstrXML_Subs[i].value_of_seg) - Number(LstrXML_Subs[i].opt_value_of_seg)
                dblOptionPayment = dblOptionPayment + Number(LstrXML_Subs[i].opt_value_of_seg)
                TypIC.Purch_NetAmount = Number(TypIC.Purch_NetAmount == undefined ? 0 : TypIC.Purch_NetAmount) + Number(LstrXML_Subs[i].proceeds_amt)
                LintVendorSplit_P = LintVendorSplit_P + 1
              }
              else if(LstrXML_Subs[i].purh_stor_ind == "S"){
                TypIC.Store_LBS_LineG = Number(TypIC.Store_LBS_LineG == undefined ? 0 :TypIC.Store_LBS_LineG) + Number(LstrXML_Subs[i].net_wt)
                TypIC.Store_xLineP = Number(TypIC.Store_xLineP) + Number(LstrXML_Subs[i].basis_grade_amt)
                TypIC.Store_xPercentage = LstrXML_Subs[i].support_pct
                TypIC.Store_ValOfSegment = Number(TypIC.Store_ValOfSegment) + Number(LstrXML_Subs[i].value_of_seg) - Number(LstrXML_Subs[i].opt_value_of_seg)
                dblOptionPayment = dblOptionPayment + Number(LstrXML_Subs[i].opt_value_of_seg)
                LintVendorSplit_S = LintVendorSplit_S + 1
              }
              break;          
            default:
              bNeedsAttachment = true
              if(LstrXML_Subs[i].split_vendor != '' && LstrXML_Subs[i].split_vendor != null && LstrXML_Subs[i].split_vendor != undefined){
                TypIC.VendorList = TypIC.VendorList + ", " + LstrXML_Subs[i].split_vendor
              }
              sAdditionVendorInfo = sAdditionVendorInfo + LstrXML_Subs[i].vendor_name + "," + LstrXML_Subs[i].split_share_pct + "," + LstrXML_Subs[i].split_vendor + "|"
              if(LstrXML_Subs.purh_stor_ind == "P"){
                TypIC.Purch_LBS_LineG = Number(TypIC.Purch_LBS_LineG) + Number(LstrXML_Subs[i].net_wt)
                TypIC.Purch_xLineP += Number(LstrXML_Subs[i].basis_grade_amt)
                TypIC.Purch_xPercentage = Number(LstrXML_Subs[i].support_pct)
                TypIC.Purch_ValOfSegment = Number(TypIC.Purch_ValOfSegment) + Number(LstrXML_Subs[i].value_of_seg) - Number(LstrXML_Subs[i].opt_value_of_seg)
                dblOptionPayment = dblOptionPayment + Number(LstrXML_Subs[i].opt_value_of_seg)
                TypIC.Purch_NetAmount = Number(TypIC.Purch_NetAmount == undefined ? 0 : TypIC.Purch_NetAmount) + Number(LstrXML_Subs[i].proceeds_amt)
                LintVendorSplit_P = LintVendorSplit_P + 1
              }
              else if(LstrXML_Subs[i].purh_stor_ind == "S"){
                TypIC.Store_LBS_LineG = Number(TypIC.Store_LBS_LineG == undefined ? 0 :TypIC.Store_LBS_LineG) + Number(LstrXML_Subs[i].net_wt)
                TypIC.Store_xLineP = Number(TypIC.Store_xLineP) + Number(LstrXML_Subs[i].basis_grade_amt)
                TypIC.Store_xPercentage = LstrXML_Subs[i].support_pct
                TypIC.Store_ValOfSegment = Number(TypIC.Store_ValOfSegment) + Number(LstrXML_Subs[i].value_of_seg) - Number(LstrXML_Subs[i].opt_value_of_seg)
                dblOptionPayment = dblOptionPayment + Number(LstrXML_Subs[i].opt_value_of_seg)
                LintVendorSplit_S = LintVendorSplit_S + 1
              }
              break;
          }
          nVendorIndex++
        }

        if(TypIC.RESIDUE_IND !== ''){
          TypIC.L13_Fed_Whs_Name = ""
          TypIC.DeliveryPoint_IF_NOT_SAME_AS_WHSE = ""
          TypIC.RemarksGovtVerbage1 = ""
          TypIC.RemarksGovtVerbage2 = ""
          TypIC.RemarksGovtVerbage3 = ""
        }
        else{
          TypIC.RemarksGovtVerbage1 = "United States Warehouse Act <br> Nut Weight Certificate"
          Lstrfedname = await SettlementService.RetrieveFedWareHouseLicenseNameDetails(null, null)
          if(Lstrfedname.length <=0)
            return;
          
          for(var i=0; i<Lstrfedname.length; i++){
            TypIC.L13_Fed_Whs_Name = Lstrfedname[i].fed_whs_lic_name.trim()
          }
          let value1 = lstrxml[0].buy_pt_id + '-' + lstrxml[0].unld_whse_id + '-' + lstrxml[0].unld_bin_id
          let value2 = lstrxml[0].obl_buy_id + '-' + lstrxml[0].obl_whse_id + '-' + lstrxml[0].obl_bin_id

          if(value1 !== value2){
            LstrXML_Subs = await SettlementService.RetrieveBuyingPointControlDetails('PN9000', null, null, null, lstrxml[0].obl_buy_id)
            if(LstrXML_Subs.length > 0){
              TypIC.DeliveryPoint_IF_NOT_SAME_AS_WHSE = LstrXML_Subs[0].buyPtName
              sGpcOwned = LstrXML_Subs[0].gpcOwned
              if(sGpcOwned == 'Y'){
                TypIC.RemarksGovtVerbage2 = LstrXML_Subs[0].buyPtId + " - " +  LstrXML_Subs[0].physicalCity +  ", " + LstrXML_Subs[0].physicalState
              }
              else{
                TypIC.RemarksGovtVerbage2 =LstrXML_Subs[0].buyPtId + " - " + LstrXML_Subs[0].buyPtName +  ", " + LstrXML_Subs[0].physicalCity + ", " + LstrXML_Subs[0].physicalState
              }
            }
          }
          else{
            LstrXML_Subs = await SettlementService.RetrieveBuyingPointControlDetails('PN9000', null, null, null, lstrxml[0].buy_pt_id)
            // if(LstrXML_Subs.length <= 0)
            //   return;
            // TypIC.DeliveryPoint_IF_NOT_SAME_AS_WHSE = ''
            sGpcOwned = LstrXML_Subs.length == 0 ? '' : LstrXML_Subs[0].gpcOwned
            if(sGpcOwned == 'Y'){
              TypIC.RemarksGovtVerbage2 = lstrxml[0].buy_pt_id + " - " +  lstrxml[0].buy_pt_physical_city +  ", " + lstrxml[0].buy_pt_physical_state
            }
            else{
              TypIC.RemarksGovtVerbage2 =lstrxml[0].buy_pt_id> + " - " + lstrxml[0].buy_pt_name +  ", " + lstrxml[0].buy_pt_physical_city + ", " + lstrxml[0].buy_pt_physical_state
            }
          }

          TypIC.RemarksGovtVerbage3 = "This Certificate is issued by a weigher licensed under the United States Warehouse Act and the regulations thereunder."
        }

        LstrXML_Subs = await ContractManagementService.RetrievePeanutPriceScheduleDetails(TypIC.AREA_ID, lstrxml[0].pnut_type_id)
        if(LstrXML_Subs.length >0){
          TypIC.lsk_per_pounds = Number(LstrXML_Subs[9].strFieldData).toFixed(2)
        }

        LstrApplicantName = await ContractManagementService.RetrieveCompanyDetails(compIdFromLS())
        if(LstrApplicantName.length >0){
          LstrApplicantName = LstrApplicantName[0].compName;
        }

        LstrXML_Subs = await  SettlementService.RetrieveAreaControlDetails(TypIC.AREA_ID)
        if(LstrXML_Subs[0].displayLbsTon == 'P'){
          bDisplayTons = false
        }
        else{
          bDisplayTons = true
        }    
          // return;

        if(TypIC.RESIDUE_IND == 'RESIDUE'){
          TypIC.TypeOfInspection = ""
          TypIC.Applicant_01_Name_Number = Number(lstrxml[0].handler_loc_num_2) + " " + lstrxml[0].handler_loc_name_2
          TypIC.Applicant_02_Name_Number = ""
          TypIC.Applicant_03_Name_Number = ""
        }
        else if(lstrxml[0].handler_loc_num_2 !== ''){
          TypIC.TypeOfInspection = "RESALE"
          TypIC.Applicant_01_Name_Number = Number(lstrxml[0].handler_loc_num_2) + " " + lstrxml[0].handler_loc_name_2
          TypIC.Applicant_02_Name_Number = ""
          TypIC.Applicant_03_Name_Number = ""
        }
        else{
          if(lstrxml[0].handler_loc_num_2 != ''){
            TypIC.TypeOfInspection = "RESALE"
            TypIC.Applicant_01_Name_Number = Number(lstrxml[0].handler_loc_num_2) + " " + lstrxml[0].handler_loc_name_2
            TypIC.Applicant_02_Name_Number = lstrxml[0].handler_loc_num + " " + LstrApplicantName
            TypIC.Applicant_03_Name_Number = ""
          }
          else{
            TypIC.TypeOfInspection = ""
            TypIC.Applicant_01_Name_Number = Number(LstrXML_Subs[0].handlerLocNum) + " " + LstrApplicantName
            TypIC.Applicant_02_Name_Number = ""
            TypIC.Applicant_03_Name_Number = ""
          }
        }

        TypIC.QuotaBalanceAfterThisSale = ""
        TypIC.ContractBalanceAfterThisSale = ""
        TypIC.DateInspected = moment(lstrxml[0].insp_date_time).format('MM/DD/YYYY')
        TypIC.WarehouseDesc_BinNo = lstrxml[0].unld_whse_id + "-"  + lstrxml[0].unld_bin_id
        TypIC.InspectionMemoNo_FV_95 = lstrxml[0].insp_num

        TypIC.TypeStorage = TypIC.RESIDUE_IND == "" ? "COMM." : ''
        TypIC.ConvertedPenaltyRate = ""
        TypIC.NoOfBags = ""
        TypIC.Bulk = "X"

        TypIC.Type = lstrxml[0].pnut_type_id
        TypIC.SMK_GR = lstrxml[0].smk_gr
        TypIC.SS_GR = lstrxml[0].ss_gr
        TypIC.OK_GR = lstrxml[0].ok_gr
        TypIC.DAM_SPLIT_GR = lstrxml[0].dam_split_gr
        TypIC.DAM_GR = lstrxml[0].dam_gr
        TypIC.HULL_GR = lstrxml[0].hull_gr
        TypIC.FREZ_DAM_GR = lstrxml[0].frez_dam_gr
        TypIC.CNCL_RMD_GR = lstrxml[0].cncl_rmd_gr
        TypIC.CR_BR_GR = lstrxml[0].cr_br_gr
        TypIC.DCOL_SHEL_GR = lstrxml[0].dcol_shel_gr
        TypIC.HUL_BRIT_GR = lstrxml[0].hul_brit_gr
        TypIC.JUMBO_GR = lstrxml[0].jumbo_gr
        TypIC.ELK_GR = lstrxml[0].elk_gr
        TypIC.FM_GR = lstrxml[0].fm_gr
        TypIC.LSK_GR = lstrxml[0].lsk_gr
        TypIC.FAN_GR = lstrxml[0].fan_gr

        TypIC.FM_SAMPLE = lstrxml[0].fm_sample
        TypIC.CLN_SAMPLE = lstrxml[0].cln_sample
        TypIC.VL_CLN_SAMPLE = lstrxml[0].vl_cln_sample
        TypIC.OTH_FM = lstrxml[0].oth_fm
        TypIC.FLAVUS_IND = lstrxml[0].flavus_ind

        //TypIC.IRR_DRY_IND = CodeToEnglish("LANDTYPE", UCase(Trim(GetInside(lstrxml, "<irr_dry_ind>", "</irr_dry_ind>"))))
        TypIC.CropYear = lstrxml[0].crop_year
        TypIC.Time = moment(lstrxml[0].insp_date_time).format('hh:mm A')
        TypIC.ProbingPattern = lstrxml[0].probe_pattern
        TypIC.Dirt = ""
        TypIC.Sticks = ""
        TypIC.Rocks = ""
        TypIC.TrashRaisins = ""
        TypIC.OtherSpecify = ""
        TypIC.Purch_DED_1_MktgAssess = ''
        TypIC.Store_DED_1_MktgAssess = ''
        TypIC.Purch_DED_2_Tax_SerFee = ''
        TypIC.Store_DED_2_Tax_SerFee = ''
        TypIC.Purch_DED_3 = ''
        TypIC.Store_DED_3 = ''
        TypIC.Store_DED_4 = ''
        TypIC.Purch_DED_4 = ''
        TypIC.Purch_DED_5 = ''
        TypIC.Store_DED_5 = ''
        TypIC.Purch_DED_6 = ''
        TypIC.Store_DED_6 = ''
        
        if(lstrxml[0].hmoist_pct == ''){
          TypIC.Seg = lstrxml[0].seg_type
          TypIC.ForiegnMaterial = lstrxml[0].fm_pct == '' ? 0 : lstrxml[0].fm_pct
          TypIC.CrackedBrokenShells = lstrxml[0].cr_br_pct == '' ? 0 : lstrxml[0].cr_br_pct
          TypIC.DiscoloredShells = lstrxml[0].dcol_shel_pct == '' ? 0 : lstrxml[0].dcol_shel_pct
          TypIC.LSK = lstrxml[0].lsk_pct == '' ? 0 : lstrxml[0].lsk_pct
          TypIC.Fancy = lstrxml[0].fan_pct == '' ? 0 : lstrxml[0].fan_pct

          TypIC.Moisture = lstrxml[0].moist_pct == '' ? 0 : lstrxml[0].moist_pct
          TypIC.ELK = lstrxml[0].elk_pct == '' ? 0 : lstrxml[0].elk_pct
          TypIC.SMK = lstrxml[0].smk_pct == '' ? 0 : lstrxml[0].smk_pct
          TypIC.SoundSplits = lstrxml[0].ss_pct == '' ? 0 : lstrxml[0].ss_pct
          TypIC.Total_SMK_SoundSplits = Number(TypIC.SMK) + Number(TypIC.SoundSplits)
          TypIC.FreezeDamage = lstrxml[0].frez_dam_pct == '' ? '0.00' : lstrxml[0].frez_dam_pct

          TypIC.OtherKernals = lstrxml[0].ok_pct == '' ? 0 : lstrxml[0].ok_pct
          TypIC.ConcealedRMD = lstrxml[0].cncl_rmd_pct == '' ? '0.00' : lstrxml[0].cncl_rmd_pct
          TypIC.Damage = lstrxml[0].dam_pct == '' ? 0 : lstrxml[0].dam_pct
          TypIC.TotalKernals = Number(TypIC.SMK) + Number(TypIC.SoundSplits) + Number(TypIC.OtherKernals) + Number(TypIC.Damage)
          TypIC.Hulls = lstrxml[0].hull_pct == '' ? 0 : lstrxml[0].hull_pct
          TypIC.Total_Kernals_Hulls = Number(TypIC.TotalKernals) + Number(TypIC.Hulls)
        }
        else{
          TypIC.Seg = lstrxml[0].hmc_seg
          TypIC.HMC_SEG = lstrxml[0].seg_type
          TypIC.ForiegnMaterial = lstrxml[0].hfm_pct == '' ? 0 : lstrxml[0].hfm_pct
          TypIC.HMC_FM_PCT = lstrxml[0].fm_pct == '' ? 0 : lstrxml[0].fm_pct
          TypIC.CrackedBrokenShells = lstrxml[0].cr_br_pct == '' ? 0 : lstrxml[0].cr_br_pct
          TypIC.DiscoloredShells = lstrxml[0].dcol_shel_pct == '' ? 0 : lstrxml[0].dcol_shel_pct
          TypIC.LSK = lstrxml[0].hlsk_pct == '' ? 0 : lstrxml[0].hlsk_pct
          TypIC.HMC_LSK_PCT = lstrxml[0].lsk_pct == '' ? 0 : lstrxml[0].lsk_pct
          TypIC.Fancy = lstrxml[0].hfan_pct == '' ? 0 : lstrxml[0].hfan_pct

          TypIC.HMC_FANCY_PCT = lstrxml[0].fan_pct == '' ? 0 : lstrxml[0].fan_pct
          TypIC.Moisture = lstrxml[0].hmoist_pct == '' ? 0 : lstrxml[0].hmoist_pct
          TypIC.HMC_MOIST = lstrxml[0].moist_pct == '' ? 0 : lstrxml[0].moist_pct

          TypIC.ELK = lstrxml[0].helk_pct == '' ? 0 : lstrxml[0].helk_pct
          TypIC.HMC_ELK_PCT = lstrxml[0].elk_pct == '' ? 0 : lstrxml[0].elk_pct

          TypIC.SMK = lstrxml[0].hsmk_pct == '' ? 0 : lstrxml[0].hsmk_pct
          TypIC.HMC_SMK_PCT = lstrxml[0].smk_pct == '' ? 0 : lstrxml[0].smk_pct

          TypIC.SoundSplits = lstrxml[0].hss_pct == '' ? 0 : lstrxml[0].hss_pct
          TypIC.HMC_SS_PCT = lstrxml[0].ss_pct == '' ? 0 : lstrxml[0].ss_pct

          TypIC.Total_SMK_SoundSplits = Number(TypIC.SMK) + Number(TypIC.SoundSplits)
          TypIC.HMC_SMKRS_PCT = Number(TypIC.HMC_SMK_PCT) + Number(TypIC.HMC_SS_PCT)

          TypIC.FreezeDamage = lstrxml[0].frez_dam_pct == "" ? '0.00' : lstrxml[0].frez_dam_pct
          TypIC.HMC_FRZ_PCT = lstrxml[0].frez_dam_pct == "" ? '0.00' : lstrxml[0].frez_dam_pct
            
          TypIC.OtherKernals = lstrxml[0].hok_pct == '' ? 0 : lstrxml[0].hok_pct
          TypIC.HMC_OK_PCT = lstrxml[0].ok_pct == '' ? 0 : lstrxml[0].ok_pct          

          TypIC.ConcealedRMD = lstrxml[0].cncl_rmd_pct == '' ? '0.00' : lstrxml[0].cncl_rmd_pct
          TypIC.HMC_CRMD_PCT = lstrxml[0].cncl_rmd_pct == '' ? '0.00' : lstrxml[0].cncl_rmd_pct


          TypIC.Damage = lstrxml[0].hdam_pct == '' ? 0 : lstrxml[0].hdam_pct
          TypIC.HMC_DAM_PCT = lstrxml[0].dam_pct == '' ? 0 : lstrxml[0].dam_pct
          
          TypIC.TotalKernals = Number(TypIC.SMK) + Number(TypIC.SoundSplits) + Number(TypIC.OtherKernals) + Number(TypIC.Damage)
          TypIC.HMC_TOTKERN_PCT = Number(TypIC.HMC_SMK_PCT) + Number(TypIC.HMC_SS_PCT) + Number(TypIC.HMC_OK_PCT) + Number(TypIC.HMC_DAM_PCT)

          TypIC.Hulls = lstrxml[0].hhull_pct == '' ? 0 : lstrxml[0].hhull_pct
          TypIC.HMC_HULL_PCT = lstrxml[0].hull_pct == '' ? 0 : lstrxml[0].hull_pct

          TypIC.Total_Kernals_Hulls = Number(TypIC.TotalKernals) + Number(TypIC.Hulls)
          TypIC.HMC_Total_Kernals_Hulls = Number(TypIC.HMC_TOTKERN_PCT) + Number(TypIC.HMC_HULL_PCT)
        }
        TypIC.VA_CrackedBroken = lstrxml[0].va_cr_br_pct

        let LstrWtTicketNos_XML = lstrxml[0].inspect_scale_tickets
        LstrWtTicketNos = LstrWtTicketNos_XML.length > 0 && LstrWtTicketNos_XML[0].inspect_scale_ticket != undefined && LstrWtTicketNos_XML[0].inspect_scale_ticket[0].dry_ticket != undefined ? LstrWtTicketNos_XML[0].inspect_scale_ticket[0].dry_ticket : ''
        LstrVehicleNos = LstrWtTicketNos_XML.length > 0 && LstrWtTicketNos_XML[0].inspect_scale_ticket != undefined && LstrWtTicketNos_XML[0].inspect_scale_ticket[0].dry_vehicle_id != undefined ? LstrWtTicketNos_XML[0].inspect_scale_ticket[0].dry_vehicle_id : ''

        let s = ''

        TypIC.Signiture = LstrWtTicketNos_XML[0].inspect_scale_ticket[0].weigher_name

        let Len = (TypIC.Signiture).length
        for (var i = Len - 1 ; i >= 0; i--) {
          if (TypIC.Signiture != "") {
            let s = TypIC.Signiture[i]
            if (isNaN(s)) {
              TypIC.WeigherName = TypIC.Signiture.slice(0, i+1)
              TypIC.WeigherLicense = TypIC.Signiture.slice(i+1,Len)
              break;
            }
          }
        }
        
        TypIC.WeigherName = TypIC.WeigherName == undefined ? '' : TypIC.WeigherName.replace('#', '')
        TypIC.WeigherName = TypIC.WeigherName == undefined ? '' : TypIC.WeigherName.replace('-', '').trim()

        if(LstrWtTicketNos_XML[0].inspect_scale_tickets != undefined){
        for(var i=0; i<LstrWtTicketNos_XML[0].inspect_scale_tickets.length; i++){
          if(LstrWtTicketNos_XML[i].dry_ticket !== '')
            LstrWtTicketNos += (', ' + LstrWtTicketNos_XML[i].dry_ticket)
          if(LstrWtTicketNos_XML[i].dry_vehicle_id !== '')
          LstrVehicleNos += (', ' + LstrWtTicketNos_XML[i].dry_vehicle_id)
        }}

        TypIC.Elec_Upload_Ind = lstrxml[0].elec_upload
        if(lstrxml[0].elec_upload == 'Y' && lstrxml[0].scale_list !== ''){
          LstrWtTicketNos = lstrxml[0].scale_list
        }
        TypIC.WeightTicketNo = LstrWtTicketNos
        TypIC.VehicleNo = LstrVehicleNos

        //TypIC.RemarksDate =  ? lstrxml[0].insp_date_time : lstrxml[0].settlement_date
        if(TypIC.AREA_ID.toLocaleUpperCase().trim() == 'SW')
          TypIC.RemarksDate = lstrxml[0].insp_date_time.split('/')[0] + '/' + lstrxml[0].insp_date_time.split('/')[1]
        else
          TypIC.RemarksDate = lstrxml[0].settlement_date.split('/')[0] + '/' + lstrxml[0].settlement_date.split('/')[1]
        
        TypIC.Remarks = lstrxml[0].q_remarks

        if(TypIC.Seg == "1"){
          if(lstrxml[0].seed_gen.toLocaleUpperCase().trim() == 'C')
            TypIC.RemarksSeed = "Applicant States Certified Seed"
          else if(lstrxml[0].seed_gen.toLocaleUpperCase().trim() == 'F')
            TypIC.RemarksSeed = "Applicant States Foundation Seed"  
          else if(lstrxml[0].seed_gen.toLocaleUpperCase().trim() == 'R')
            TypIC.RemarksSeed = "Applicant States Registered Seed"
          else
            TypIC.RemarksSeed = ""
        }

        TypIC.RemarksSupersede = ""
        if(lstrxml[0].superseded_settle !== "" &&  lstrxml[0].superseded_settle !== undefined && lstrxml[0].superseded_settle !== null){
          if(Number(lstrxml[0].superseded_settle) !== 0){
            let superseded_settle = lstrxml[0].superseded_settle.trim()
            if(superseded_settle.length < 7 && superseded_settle.length > 0){
              superseded_settle = '0'.repeat(7-superseded_settle.length) + superseded_settle
            }
            TypIC.RemarksSupersede = "This 1007 Certificate Supersedes: " + superseded_settle
          }
        }
        
        TypIC.A_WeightInclVehicle = isNaN(lstrxml[0].tare_wt) == false ? lstrxml[0].tare_wt : 0
        TypIC.B_WeightOfVehicle   = isNaN(lstrxml[0].vehicle_wt) == false ? lstrxml[0].vehicle_wt : 0
        TypIC.C_GrossWeight_AMinusB = isNaN(lstrxml[0].gross_wt) == false ? lstrxml[0].gross_wt : 0
        TypIC.D_ForeignMaterial_PerOfFMxC = isNaN(lstrxml[0].fm_wt) == false ? lstrxml[0].fm_wt : 0
        TypIC.E_WeightLessFM_CMinusD = Number(TypIC.C_GrossWeight_AMinusB) - Number(TypIC.D_ForeignMaterial_PerOfFMxC)
        TypIC.F_ExcessMoisture_PerOfEMxE = isNaN(lstrxml[0].ex_moist_wt) == false   ? lstrxml[0].ex_moist_wt : 0
        TypIC.G_NetWeight_EMinusF = isNaN(lstrxml[0].net_wt) == false  ? lstrxml[0].net_wt : 0


        if(TypIC.RESIDUE_IND == "RESIDUE"){
          TypIC.H_LSK_PerOfLSKxC = ""
          TypIC.I_NetWeightExclLSK_GMinusH = ""
          TypIC.J_KernalValPerLB_ExclLSK = ""
          TypIC.K_ELKPremium = ""
          TypIC.L_Total_JPlusK = ""
          TypIC.M_a_Damage = ""
          TypIC.M_b_ExcessFM = ""
          TypIC.M_c_ExcessSplits = ""
          TypIC.M_d_Total = ""
          TypIC.N_NetValLB_ExclLSK_LMinusM = ""
          TypIC.o = ""
          TypIC.P_ValLBInclLSK_a_NxI = ""
          TypIC.P_ValLBInclLSK_b_HxCents = ""
          TypIC.P_Total = ""
          TypIC.P_Total_DivByG = ""
        }
        else if(TypIC.RESIDUE_IND == "RESALE"){
          TypIC.H_LSK_PerOfLSKxC = lstrxml[0].lsk_wt
          TypIC.I_NetWeightExclLSK_GMinusH = Number(Val(TypIC.G_NetWeight_EMinusF)) - Number(Val(TypIC.H_LSK_PerOfLSKxC))

          if(TypIC.A_WeightInclVehicle !== ''){
            if(bDisplayTons){
              TypIC.J_KernalValPerLB_ExclLSK = Number(lstrxml[0].kern_value).toFixed(2)
              TypIC.K_ELKPremium = lstrxml[0].elk_prem.toFixed(2)
              TypIC.L_Total_JPlusK = Number(Number(TypIC.J_KernalValPerLB_ExclLSK) + Number(TypIC.K_ELKPremium)).toFixed(2)
              TypIC.M_a_Damage = lstrxml[0].dam_discount.toFixed(2)
              TypIC.M_b_ExcessFM = lstrxml[0].ex_fm_discount.toFixed(2)
              TypIC.M_c_ExcessSplits = lstrxml[0].ex_ss_discount.toFixed(2)
              TypIC.M_d_Total = (Number(TypIC.M_a_Damage) + Number(TypIC.M_b_ExcessFM) + Number(TypIC.M_c_ExcessSplits)).toFixed(2)
              TypIC.N_NetValLB_ExclLSK_LMinusM = (Number(TypIC.L_Total_JPlusK) - Number(TypIC.M_d_Total)).toFixed(2)
              TypIC.o = (TypIC.N_NetValLB_ExclLSK_LMinusM / 20).toFixed(5)
              TypIC.P_ValLBInclLSK_a_NxI = (Number(TypIC.o) * Number(TypIC.I_NetWeightExclLSK_GMinusH) * 0.01).toFixed(2)
              TypIC.P_ValLBInclLSK_b_HxCents = (Number(TypIC.H_LSK_PerOfLSKxC) * Number(TypIC.lsk_per_pounds)).toFixed(2)
              TypIC.P_Total = (Number(TypIC.P_ValLBInclLSK_a_NxI) + Number(TypIC.P_ValLBInclLSK_b_HxCents)).toFixed(2)
              TypIC.P_Total_DivByG = (Number(lstrxml[0].net_price) * 100).toFixed(5)
            }
            else{
              TypIC.J_KernalValPerLB_ExclLSK = Number(Number(lstrxml[0].kern_value)/20).toFixed(5)
              TypIC.K_ELKPremium = (Number(lstrxml[0].elk_prem)/20).toFixed(5)
              TypIC.L_Total_JPlusK = Number(Number(TypIC.J_KernalValPerLB_ExclLSK) + Number(TypIC.K_ELKPremium)).toFixed(5)
              TypIC.M_a_Damage = (Number(lstrxml[0].dam_discount)/20).toFixed(5)
              TypIC.M_b_ExcessFM = (Number(lstrxml[0].ex_fm_discount)/20).toFixed(5)
              TypIC.M_c_ExcessSplits = (Number(lstrxml[0].ex_ss_discount)/20).toFixed(5)
              TypIC.M_d_Total = (Number(TypIC.M_a_Damage) + Number(TypIC.M_b_ExcessFM) + Number(TypIC.M_c_ExcessSplits)).toFixed(5)
              TypIC.N_NetValLB_ExclLSK_LMinusM = (Number(TypIC.L_Total_JPlusK) - Number(TypIC.M_d_Total)).toFixed(5)
              TypIC.o = ""
              TypIC.P_ValLBInclLSK_a_NxI = (Number(TypIC.o) * Number(TypIC.I_NetWeightExclLSK_GMinusH) * 0.01).toFixed(5)
              TypIC.P_ValLBInclLSK_b_HxCents = (Number(TypIC.H_LSK_PerOfLSKxC) * Number(TypIC.lsk_per_pounds)).toFixed(5)
              TypIC.P_Total = (Number(TypIC.P_ValLBInclLSK_a_NxI) + Number(TypIC.P_ValLBInclLSK_b_HxCents)).toFixed(5)
              TypIC.P_Total_DivByG = (Number(lstrxml[0].net_price) * 100).toFixed(5)
            }
          }
          
          TypIC.Purch_ApplicantNo = "1"
          TypIC.Purch_LBS_LineG = lstrxml[0].net_wt == undefined || lstrxml[0].net_wt == '' ? '' : Number(lstrxml[0].net_wt)
          TypIC.Purch_xLineP =  Number(lstrxml[0].basis_grade_amt)
          TypIC.Purch_xPercentage = lstrxml[0].support_pct
          TypIC.Purch_ValOfSegment = lstrxml[0].value_of_seg
          TypIC.Purch_NetAmount = lstrxml[0].proceeds_amt
          TypIC.Signiture = LstrWtTicketNos_XML[0].inspect_scale_ticket[0].weigher_name

          let Len = (TypIC.Signiture).length
          for (var i = Len - 1 ; i >= 0; i--) {
            if (TypIC.Signiture != "") {
              let s = TypIC.Signiture[i]
              if (isNaN(s)) {
                TypIC.WeigherName = TypIC.Signiture.slice(0, i+1)
                TypIC.WeigherLicense = TypIC.Signiture.slice(i+1,Len)
                break;
              }
            }
          }

          TypIC.WeigherName = TypIC.WeigherName == undefined ? '' : TypIC.WeigherName.replace('#', '')
          TypIC.WeigherName = TypIC.WeigherName == undefined ? '' : TypIC.WeigherName.replace('-', '').trim()

        }
        else{
          TypIC.H_LSK_PerOfLSKxC = lstrxml[0].lsk_wt
          TypIC.I_NetWeightExclLSK_GMinusH = Number(TypIC.G_NetWeight_EMinusF) - Number(TypIC.H_LSK_PerOfLSKxC)

          if(bDisplayTons){
            TypIC.J_KernalValPerLB_ExclLSK = Number(lstrxml[0].kern_value).toFixed(2)
            TypIC.K_ELKPremium = Number(lstrxml[0].elk_prem).toFixed(2)
            TypIC.L_Total_JPlusK = Number(Number(TypIC.J_KernalValPerLB_ExclLSK) + Number(TypIC.K_ELKPremium)).toFixed(2)
            TypIC.M_a_Damage = Number(lstrxml[0].dam_discount).toFixed(2)
            TypIC.M_b_ExcessFM = Number(lstrxml[0].ex_fm_discount).toFixed(2)
            TypIC.M_c_ExcessSplits = Number(lstrxml[0].ex_ss_discount).toFixed(2)
            TypIC.M_d_Total = (Number(TypIC.M_a_Damage) + Number(TypIC.M_b_ExcessFM) + Number(TypIC.M_c_ExcessSplits)).toFixed(2)
            TypIC.N_NetValLB_ExclLSK_LMinusM = (Number(TypIC.L_Total_JPlusK) - Number(TypIC.M_d_Total)).toFixed(2)
            TypIC.o = (Number(TypIC.N_NetValLB_ExclLSK_LMinusM) / 20).toFixed(5)
            TypIC.P_ValLBInclLSK_a_NxI = (Number(TypIC.o) * Number(TypIC.I_NetWeightExclLSK_GMinusH) * 0.01).toFixed(2)
            TypIC.P_ValLBInclLSK_b_HxCents = (Number(TypIC.H_LSK_PerOfLSKxC) * Number(TypIC.lsk_per_pounds)).toFixed(2)
            TypIC.P_Total = (Number(TypIC.P_ValLBInclLSK_a_NxI) + Number(TypIC.P_ValLBInclLSK_b_HxCents)).toFixed(2)
            TypIC.P_Total_DivByG = (Number(lstrxml[0].net_price) * 100).toFixed(5)
          }
          else{
            TypIC.J_KernalValPerLB_ExclLSK = Number(Number(lstrxml[0].kern_value)/20).toFixed(5)
            TypIC.K_ELKPremium = (Number(lstrxml[0].elk_prem)/20).toFixed(5)
            TypIC.L_Total_JPlusK = (Number(TypIC.J_KernalValPerLB_ExclLSK) + Number(TypIC.K_ELKPremium)).toFixed(5)
            TypIC.M_a_Damage = (Number(lstrxml[0].dam_discount)/20).toFixed(5)
            TypIC.M_b_ExcessFM = (Number(lstrxml[0].ex_fm_discount)/20).toFixed(5)
            TypIC.M_c_ExcessSplits = (Number(lstrxml[0].ex_ss_discount)/20).toFixed(5)
            TypIC.M_d_Total = (Number(TypIC.M_a_Damage) + Number(TypIC.M_b_ExcessFM) + Number(TypIC.M_c_ExcessSplits)).toFixed(5)
            TypIC.N_NetValLB_ExclLSK_LMinusM = (Number(TypIC.L_Total_JPlusK) - Number(TypIC.M_d_Total)).toFixed(5)
            TypIC.o = ""
            TypIC.P_ValLBInclLSK_a_NxI = ((Number(TypIC.N_NetValLB_ExclLSK_LMinusM) * Number(TypIC.I_NetWeightExclLSK_GMinusH)) * 0.01).toFixed(5)
            TypIC.P_ValLBInclLSK_b_HxCents = (Number(TypIC.H_LSK_PerOfLSKxC) * Number(TypIC.lsk_per_pounds)).toFixed(5)
            TypIC.P_Total = (Number(TypIC.P_ValLBInclLSK_a_NxI) + Number(TypIC.P_ValLBInclLSK_b_HxCents)).toFixed(5)
            TypIC.P_Total_DivByG = (Number(lstrxml[0].net_price) * 100).toFixed(5)
          }

          sPrevPremCode = ""
          nIteration = 1

          LstrXML_Subs = lstrxml[0].inspect_prem_deducts
          let remarkArr1 = [ {}, {} ]
          let MarrRemarksPremiumDeductions = ''
        for(var i=0; i<LstrXML_Subs.length; i++){
          LblnPrint1007Storage = LstrXML_Subs[i].print_1007_storage == 'Y'
          if(LstrXML_Subs[i].apply_order == '1'){
            if(nIteration == 1){
              nIteration = 1
            }
            else{
              showMessage(thisObj, "Invalid Apply Orders on Premium Deductions")
              return;
            }
          }
                    
          if(LstrXML_Subs[i].apply_order == '2'){
            if(nIteration <= 2){
              nIteration = 2
            }
            else{
              showMessage(thisObj, "Invalid Apply Orders on Premium Deductions")
              return;
            }
          }
                    
          if((LstrXML_Subs[i].apply_order != '1') && (LstrXML_Subs[i].apply_order != '2') && (nIteration <= 2)){
            nIteration = 2
          }

          if((LstrXML_Subs[i].apply_order != 1) && (LstrXML_Subs[i].apply_order != 2)){
            if(sPrevPremCode != (LstrXML_Subs[i].pd_code)){
              nIteration = nIteration + 1
            }
          }

          if(nIteration == 1){
            let foundRes = []            
            if(lstrxml[0].inspect_vendor_splits.length !== 0){
              foundRes = lstrxml[0].inspect_vendor_splits.find(item => { return item.split_vendor === LstrXML_Subs[i].pd_vendor })
            }
            
            var purhStorInd = foundRes.length == 0 ? 'P' : foundRes.purh_stor_ind
            if(purhStorInd == "P"){
              TypIC.Purch_DED_1_MktgAssess = Number(TypIC.Purch_DED_1_MktgAssess) + Number(LstrXML_Subs[i].pd_amount)
            }
            else{
              if(LblnPrint1007Storage){
                TypIC.Store_DED_1_MktgAssess = Number(TypIC.Store_DED_1_MktgAssess) +  Number(LstrXML_Subs[i].pd_amount)
              }
              else{
                TypIC.Store_DED_1_MktgAssess = Number(TypIC.Store_DED_1_MktgAssess) + Number(0)
              }
              TypIC.Store_NetAmount = Number(TypIC.Store_NetAmount) + Number(LstrXML_Subs[i].pd_amount)
            }
            sPrevPremCode = LstrXML_Subs[i].pd_code
          }
                    
          if(nIteration == 2){
            let foundRes = []
            if(lstrxml[0].inspect_vendor_splits.length !== 0){
              foundRes = lstrxml[0].inspect_vendor_splits.find(item => { return item.split_vendor === LstrXML_Subs[i].pd_vendor })
            }
            
            var purhStorInd = foundRes.length == 0 ? 'P' : foundRes.purh_stor_ind
            if(purhStorInd == "P"){
              TypIC.Purch_DED_2_Tax_SerFee = Number(TypIC.Purch_DED_2_Tax_SerFee) + Number(LstrXML_Subs[i].pd_amount)
            }
            else{
              if(LblnPrint1007Storage){
                TypIC.Store_DED_2_Tax_SerFee = Number(TypIC.Store_DED_2_Tax_SerFee) + Number(LstrXML_Subs[i].pd_amount)
              }
              else{
                TypIC.Store_DED_2_Tax_SerFee = Number(TypIC.Store_DED_2_Tax_SerFee) + Number(0)
              }               
                TypIC.Store_NetAmount += Number(LstrXML_Subs[i].pd_amount)
            }
            sPrevPremCode = LstrXML_Subs[i].pd_code
          }
                    
          if(nIteration == 3){
            TypIC.DED_3 = LstrXML_Subs[i].pd_code

            let foundRes = []
            if(lstrxml[0].inspect_vendor_splits.length !== 0){
              foundRes = lstrxml[0].inspect_vendor_splits.find(item => { return item.split_vendor === LstrXML_Subs[i].pd_vendor })
            }
            
            var purhStorInd = foundRes.length == 0 ? 'P' : foundRes.purh_stor_ind

            if(purhStorInd == "P"){
              TypIC.Purch_DED_3 = Number(TypIC.Purch_DED_3) + Number(LstrXML_Subs[i].pd_amount)
            }
            else{
              if(LblnPrint1007Storage == true){
                TypIC.Store_DED_3 = Number(TypIC.Store_DED_3) + Number(LstrXML_Subs[i].pd_amount)
              }
              else{
                TypIC.Store_DED_3 = Number(TypIC.Store_DED_3) + Number(0)
              }
                TypIC.Store_NetAmount += Number(LstrXML_Subs[i].pd_amount)
            }
            sPrevPremCode = LstrXML_Subs[i].pd_code
          }
                    
          if(nIteration == 4){
            TypIC.DED_4 = LstrXML_Subs[i].pd_code

            let foundRes = []
            if(lstrxml[0].inspect_vendor_splits.length !== 0){
              foundRes = lstrxml[0].inspect_vendor_splits.find(item => { return item.split_vendor === LstrXML_Subs[i].pd_vendor })
            }
            
            var purhStorInd = foundRes.length == 0 ? 'P' : foundRes.purh_stor_ind

            if(purhStorInd == "P"){
              TypIC.Purch_DED_4 = Number(TypIC.Purch_DED_4) + Number(LstrXML_Subs[i].pd_amount)
            }
            else{
              if(LblnPrint1007Storage == true){
                TypIC.Store_DED_4 = Number(TypIC.Store_DED_4) + Number(LstrXML_Subs[i].pd_amount)
              }
              else{
                TypIC.Store_DED_4 = Number(TypIC.Store_DED_4) + Number(0)
              }
              TypIC.Store_NetAmount += Number(LstrXML_Subs[i].pd_amount)
            }
            sPrevPremCode = LstrXML_Subs[i].pd_code
          }
                    
          if(nIteration == 5){
            TypIC.DED_5 = LstrXML_Subs[i].pd_code

            let foundRes = []
            if(lstrxml[0].inspect_vendor_splits.length !== 0){
              foundRes = lstrxml[0].inspect_vendor_splits.find(item => { return item.split_vendor === LstrXML_Subs[i].pd_vendor })
            }
            
            var purhStorInd = foundRes.length == 0 ? 'P' : foundRes.purh_stor_ind

            if(purhStorInd == "P"){
              TypIC.Purch_DED_5 = Number(TypIC.Purch_DED_5) + Number(LstrXML_Subs[i].pd_amount)
            }
            else{
              if(LblnPrint1007Storage == true){
                TypIC.Store_DED_5 = Number(TypIC.Store_DED_5) + Number(LstrXML_Subs[i].pd_amount)
              }
              else{
                TypIC.Store_DED_5 = Number(TypIC.Store_DED_5) + Number(0)
              }
               TypIC.Store_NetAmount += Number(LstrXML_Subs[i].pd_amount)
            }
            sPrevPremCode = LstrXML_Subs[i].pd_code
          }
                    
          if(nIteration == 6){
            TypIC.DED_6 = LstrXML_Subs[i].pd_code

            let foundRes = []
            if(lstrxml[0].inspect_vendor_splits.length !== 0){
              foundRes = lstrxml[0].inspect_vendor_splits.find(item => { return item.split_vendor === LstrXML_Subs[i].pd_vendor })
            }
            
            var purhStorInd = foundRes.length == 0 ? '' : foundRes.purh_stor_ind

            // if(purhStorInd == 'P'){              
            //   if(remarkArr1[0][LstrXML_Subs[i].pd_code] == undefined){  
            //     remarkArr1[0][LstrXML_Subs[i].pd_code] = Number(LstrXML_Subs[i].pd_amount)
            //   }
            //   else{
            //     remarkArr1[0][LstrXML_Subs[i].pd_code] += Number(LstrXML_Subs[i].pd_amount)
            //   }
            // }
            // else if(purhStorInd == 'S'){
            //   if(remarkArr1[1][LstrXML_Subs[i].pd_code] == undefined){  
            //     remarkArr1[1][LstrXML_Subs[i].pd_code] = Number(LstrXML_Subs[i].pd_amount)
            //   }
            //   else{
            //     remarkArr1[1][LstrXML_Subs[i].pd_code] += Number(LstrXML_Subs[i].pd_amount)
            //   }
            // }

            if(purhStorInd == "P"){
              TypIC.Purch_DED_6 = Number(TypIC.Purch_DED_6) + Number(LstrXML_Subs[i].pd_amount)
            }
            else{
              if(LblnPrint1007Storage == true){
                TypIC.Store_DED_6 = Number(TypIC.Store_DED_6) + Number(LstrXML_Subs[i].pd_amount)
              }
              else{
                TypIC.Store_DED_6 = Number(TypIC.Store_DED_6) + Number(0)
              }
              TypIC.Store_NetAmount += Number(LstrXML_Subs[i].pd_amount)
            }
            sPrevPremCode = LstrXML_Subs[i].pd_code
          }
          
          if(nIteration > 6){
            let foundRes = []
            if(lstrxml[0].inspect_vendor_splits.length !== 0){
              foundRes = lstrxml[0].inspect_vendor_splits.find(item => { return item.split_vendor === LstrXML_Subs[i].pd_vendor })
            }
            var purhStorInd = foundRes.length == 0 ? '' : foundRes.purh_stor_ind

            if(TypIC.DED_6 != "OTHER"){
              MarrRemarksPremiumDeductions == TypIC.DED_6
              if(LstrXML_Subs.pd_vendor == "P"){ 
                MarrRemarksPremiumDeductions == TypIC.Purch_DED_6
              }
              
              MarrRemarksPremiumDeductions = TypIC.Store_DED_6              
              sPrevPremCode = LstrXML_Subs[i].pd_code
              if((purhStorInd) || (LblnPrint1007Storage == true)){
                  MarrRemarksPremiumDeductions = LstrXML_Subs[i].pd_code
              }
              
              if(purhStorInd == 'P'){
                if(remarkArr1[0][TypIC.DED_6] == undefined){  
                  remarkArr1[0][TypIC.DED_6] = Number(TypIC.Purch_DED_6)
                }
                else{
                  remarkArr1[0][TypIC.DED_6] += Number(TypIC.Purch_DED_6)
                }
              }
              else if(purhStorInd == 'S'){
                if(remarkArr1[1][TypIC.DED_6] == undefined){  
                  remarkArr1[1][TypIC.DED_6] = Number(TypIC.Store_DED_6)
                }
                else{
                  remarkArr1[1][TypIC.DED_6] += Number(TypIC.Store_DED_6)
                }
              }
            } 
            TypIC.DED_6 = "OTHER"          
            
            if(purhStorInd == 'P'){
              if(remarkArr1[0][LstrXML_Subs[i].pd_code] == undefined){  
                remarkArr1[0][LstrXML_Subs[i].pd_code] = Number(LstrXML_Subs[i].pd_amount)
              }
              else{
                remarkArr1[0][LstrXML_Subs[i].pd_code] += Number(LstrXML_Subs[i].pd_amount)
              }
            }
            else if(purhStorInd == 'S'){
              if(remarkArr1[1][LstrXML_Subs[i].pd_code] == undefined){  
                remarkArr1[1][LstrXML_Subs[i].pd_code] = Number(LstrXML_Subs[i].pd_amount)
              }
              else{
                remarkArr1[1][LstrXML_Subs[i].pd_code] += Number(LstrXML_Subs[i].pd_amount)
              }
            }
            
            if(sPrevPremCode != LstrXML_Subs[i].pd_code){
              if(LstrXML_Subs.pd_vendor == 'P'){
                MarrRemarksPremiumDeductions == LstrXML_Subs.pd_code
                MarrRemarksPremiumDeductions == LstrXML_Subs.pd_amount
              }
              else{
                if(LblnPrint1007Storage == true){
                  MarrRemarksPremiumDeductions == LstrXML_Subs.pd_code
                  MarrRemarksPremiumDeductions == LstrXML_Subs.pd_amount
                }
              }
            }
            else{
              if(LstrXML_Subs.pd_vendor == 'P'){
                MarrRemarksPremiumDeductions = MarrRemarksPremiumDeductions + Number(LstrXML_Subs.pd_amount)
              }
              else{
                if(LblnPrint1007Storage == true){
                  MarrRemarksPremiumDeductions = MarrRemarksPremiumDeductions + Number(LstrXML_Subs.pd_amount)
                }
              }
            }
                        
            sPrevPremCode = LstrXML_Subs[i].pd_code
                        
            foundRes = []
            if(lstrxml[0].inspect_vendor_splits.length !== 0){
              foundRes = lstrxml[0].inspect_vendor_splits.find(item => { return item.split_vendor === LstrXML_Subs[i].pd_vendor })
            }
            
            var purhStorInd = foundRes.length == 0 ? 'P' : foundRes.purh_stor_ind
            if(purhStorInd == "P"){
              TypIC.Purch_DED_6 = Number(TypIC.Purch_DED_6) + Number(LstrXML_Subs[i].pd_amount)
            }
            else{
              if(LblnPrint1007Storage == true){
                TypIC.Store_DED_6 = Number(TypIC.Store_DED_6) + Number(LstrXML_Subs[i].pd_amount)
              }
              else{
                TypIC.Store_DED_6 = Number(TypIC.Store_DED_6) + Number(0)
              }
              TypIC.Store_NetAmount += Number(LstrXML_Subs[i].pd_amount)
            }
          }
        }

        if(dblOptionPayment > 0){
          if(TypIC.DED_3 == '' || TypIC.DED_3 == undefined || TypIC.DED_3 == null){
            TypIC.DED_3 = "OPT"
            TypIC.Purch_DED_3 = dblOptionPayment
          }
          else if(TypIC.DED_4 == '' || TypIC.DED_4 == undefined || TypIC.DED_4 == null){
            TypIC.DED_4 = "OPT"
            TypIC.Purch_DED_4 = dblOptionPayment
          }
          else if(TypIC.DED_5 == "" || TypIC.DED_5 == undefined || TypIC.DED_5 == null){
            TypIC.DED_5 = "OPT"
            TypIC.Purch_DED_5 = dblOptionPayment
          }
          else if(TypIC.DED_6 == "" || TypIC.DED_6 == undefined || TypIC.DED_6 == null){
            TypIC.DED_6 = "OPT"
            TypIC.Purch_DED_6 = dblOptionPayment
          }
          else{
            MarrRemarksPremiumDeductions == "OPT"
            MarrRemarksPremiumDeductions == ""
            MarrRemarksPremiumDeductions == dblOptionPayment
            remarkArr1[0]['OPT'] = dblOptionPayment
          }
        }

        let qRemarksPremDedP = ''
        let qRemarksPremDedS = ''
        for(let [key, value] of Object.entries(remarkArr1[0])){
          qRemarksPremDedP += (key + ' ' + Number(value).toFixed(2) + ' ')
        }
        if(qRemarksPremDedP !== ''){
          qRemarksPremDedP = 'PUR: ' + qRemarksPremDedP
        }

        for(let [key, value] of Object.entries(remarkArr1[1])){
          qRemarksPremDedS += (key + ' ' + Number(value).toFixed(2) + ' ')
        }
        if(qRemarksPremDedS !== ''){
          qRemarksPremDedS = 'STO: ' + qRemarksPremDedS
        }        
        
        TypIC.QRemarksPremDed = qRemarksPremDedS + '<br/>' + qRemarksPremDedP + '<br/>'
        
        if(isNaN(TypIC.Store_DED_6) == false){
          TypIC.Store_DED_6 = TypIC.Store_DED_6
        }

        TypIC.Store_ApplicantNo = LintVendorSplit_S > 0 ? 1 : ''
                
          if((LintVendorSplit_P > 0) && (LintVendorSplit_S == 0)){
            TypIC.Purch_ApplicantNo = "1"
          }
          else if((LintVendorSplit_P > 0) && (LintVendorSplit_S > 0)){
            TypIC.Purch_ApplicantNo = "2"
          }
        }

        TypIC.V_Remarks = lstrxml.length == 0 || lstrxml[0].inspect_remarks == undefined || lstrxml[0].inspect_remarks[0].remarks == undefined ? '' : lstrxml[0].inspect_remarks[0].remarks
        TypIC.V_Remarks += lstrxml.length == 0 || lstrxml[0].remarks == undefined ? '' : lstrxml[0].remarks

        if(lstrxml.length == 0 || lstrxml == undefined || lstrxml[0].symbol_ind == undefined){
          sSymbol = ""
        }
        else{
          switch(lstrxml[0].symbol_ind.toLocaleUpperCase().trim()){
            case "TRADEMARK":
              sSymbol = " " + `&trade;`
              break
            case "REGISTERED TRADEMARK":
              sSymbol = " " + `&reg;`
              break
            case "COPYRIGHT":
              sSymbol = " " + `&copy;`
              break
            default:
              sSymbol = ""
          }
      }

        TypIC.Variety_Description = lstrxml[0].pnut_variety_name + sSymbol
        TypIC.Q_Remarks = ""
        
        let sQContract = []
        let sQVendor = []
        let sQPounds = []
        let sHoldContract = []

        if(TypIC.RESIDUE_IND !== ""){
          bNeedsAttachment = false
          TypIC.Q_Remarks_Attachment = ""
        }
        else{
          LstrXML_Q_Remarks = lstrxml[0].q_remarks
          nQRemarkLines = 1
          nQRemarksOnLine = 0
          sContract = "FIRST"

          for(var i=0; i<LstrXML_Q_Remarks.length; i++){
            sQContract = LstrXML_Q_Remarks[i].contract_num == "999999" ? "&nbsp;None&nbsp;" : LstrXML_Q_Remarks[i].contract_num
            sQVendor = LstrXML_Q_Remarks[i].split_vendor
            sQPounds = LstrXML_Q_Remarks[i].net_wt

            if(nQRemarkLines < 5){
              nQRemarksOnLine++;
              if(sHoldContract !== sQContract && nQRemarksOnLine > 1 && sHoldContract.toLocaleUpperCase() !== 'FIRST'){
                TypIC.Q_Remarks += '<br/>'
                nQRemarksOnLine = 1
                nQRemarkLines++
              }
              
              TypIC.Q_Remarks += ("Contract " + sQContract + "  " )
              TypIC.Q_Remarks += ("Vendor " + sQVendor + "  ")
              TypIC.Q_Remarks += ("Pounds " + Intl.NumberFormat('en-US').format(sQPounds) + "  ")
              if(nQRemarksOnLine > 2){
                TypIC.Q_Remarks = TypIC.Q_Remarks 
                nQRemarksOnLine = 0
                nQRemarkLines++
              }
              sHoldContract = sQContract
            }
            else{
              if(nQRemarkLines == 5){
                nQRemarksOnLine = 0
                sHoldContract = "FIRST"
                nQRemarkLines++
              }
              bNeedsAttachment = true
              nQRemarksOnLine++
              if(sHoldContract != sQContract && nQRemarksOnLine > 1 && sHoldContract != "FIRST"){
                TypIC.Q_Remarks_Attachment = TypIC.Q_Remarks_Attachment + '<br/>'
                nQRemarksOnLine = 1
                nQRemarkLines++
              }
              
              TypIC.Q_Remarks_Attachment = TypIC.Q_Remarks_Attachment + "Contract " + sQContract + "  "
              TypIC.Q_Remarks_Attachment = TypIC.Q_Remarks_Attachment + "Vendor " + sQVendor + "  "
              TypIC.Q_Remarks_Attachment = TypIC.Q_Remarks_Attachment + "Pounds " + Intl.NumberFormat('en-US').format(sQPounds); + "  "
                          
              if(nQRemarksOnLine > 1){
                TypIC.Q_Remarks_Attachment = TypIC.Q_Remarks_Attachment
                nQRemarksOnLine = 0
                nQRemarkLines++
              }
  
              sHoldContract = sQContract
            }
          }
  
          if(TypIC.VA_CrackedBroken != ""){
            if(nQRemarkLines < 5){
              if(nQRemarksOnLine = 1){TypIC.Q_Remarks = TypIC.Q_Remarks}
              TypIC.Q_Remarks = TypIC.Q_Remarks + "VA Cracked and Broken: " + TypIC.VA_CrackedBroken + "%"
              nQRemarkLines++
            }
            else{
              bNeedsAttachment = true
              if(nQRemarksOnLine = 1){TypIC.Q_Remarks_Attachment = TypIC.Q_Remarks_Attachment} 
              TypIC.Q_Remarks_Attachment = TypIC.Q_Remarks_Attachment + "VA Cracked and Broken: " + TypIC.VA_CrackedBroken + "%"
              nQRemarkLines++
            }
          }
        }
        TypIC.inspector_name = lstrxml[0].inspector_name
        TypIC.signer_name = lstrxml[0].signer_name
        TypIC.Elec_Upload_Ind = lstrxml[0].elec_upload
        TypIC.Vehicle_ID_List = lstrxml[0].vehicle_id_list
      }
    }

    // let s = getData(thisObj, 'ReportPreviewInspectionScreenData').WaterMark != undefined ? getData(thisObj, 'ReportPreviewInspectionScreenData').WaterMark : 'Test'
    // TypIC.WaterMark = s.replace(/(\w)(\w*)/g, function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
    // if(TypIC.WaterMark == 'Void'){
    //   TypIC.WaterMark = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test ' + 'Void'
    // }    
    // else if(TypIC.WaterMark == 'No Sale'){
    //   TypIC.WaterMark = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test ' + 'No Sale'
    // } 
    // else if(TypIC.WaterMark == 'Superseded Sc95'){
    //   TypIC.WaterMark = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test ' + 'Superseded'
    // }    
    // else if(TypIC.WaterMark == 'Superseded 1007'){
    //   if(lstrxml[0].insp_status == 'V'){
    //     TypIC.WaterMark = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test ' + 'Superseded'
    //   }      
    //   else if(lstrxml[0].insp_status == 'I'){
    //     TypIC.WaterMark = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test '
    //   }
    // }

    TypIC.WaterMark = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test'
    
    let RenderCode = await InspectionCertificate_Laser(TypIC)
    return RenderCode;

  // }
  // catch(err){
  //   errorHandler(err)
  // }
}

const InspectionCertificate_Laser = async(TypIC) =>{
    
  let LintY = 0
  let nLineBreaks = 0
  let nStartCharacter = 0
  let nEndCharacter = 0
  let nBreakCharacter = 0

  let strTemp = ''
  let strTempVehicleNo = ''
  let strTempWeightNo = ''
  let strTempRemarks = ''
  let strTempVerbiage = ''
  let intCnt = 0
 
  let vsp_Viewer = {}

  let bExtraWeightTicks = false
  
  data.R2C3 = TypIC.ComputerSerial

  data.FarmNo = TypIC.OperatorSellerName_COCode_FarmNo
  data.R2C2 = TypIC.BuyingPoint_Location

  data.Farm_Producer_Name1 = TypIC.ProducerName1
  data.Farm_Producer_Name2 = TypIC.ProducerName2
  data.Farm_Producer_Name3 = TypIC.ProducerName3
  data.Farm_Producer_Name4 = TypIC.ProducerName4
  
  data.R2_1C1 = TypIC.Share_Percentage1
  data.R2_1C2 = TypIC.Share_Percentage2
  data.R2_1C3 = TypIC.Share_Percentage3
  data.R2_1C4 = TypIC.Share_Percentage4
  
  data.R2_1C2_11 = " 1. " + TypIC.Applicant_01_Name_Number
  data.R2_1C2_12 = " 2. " + TypIC.Applicant_02_Name_Number
  data.R2_1C2_13 = " 3. " + TypIC.Applicant_03_Name_Number


  data.R3C2= TypIC.WarehouseDesc_BinNo
  data.R3C3 = TypIC.DeliveryPoint_IF_NOT_SAME_AS_WHSE
  
  data.R4C2 = TypIC.TypeStorage
  
  strTempVehicleNo = ""
  strTempWeightNo = "" 
  let bExtraVehicleIds = false
  if(TypIC.Elec_Upload_Ind == "Y"){     
     if(TypIC.Vehicle_ID_List != ""){
        data.R5C1 = TypIC.Vehicle_ID_List
     }
     else{
          strTempVehicleNo = TypIC.VehicleNo
          data.R5C1 =  strTempVehicleNo
     }
  }
  else{
      strTempVehicleNo = TypIC.VehicleNo
      data.R5C1 = strTempVehicleNo
  }
      
 bExtraWeightTicks = false
 if(TypIC.Elec_Upload_Ind == "Y"){
     strTempWeightNo = TypIC.WeightTicketNo
     data.R5C2 = strTempWeightNo
 }
 else{
  data.R5C2 = getTicketNo(intCnt, TypIC.WeightTicketNo, bExtraWeightTicks)
}       
  
  if(msPDF_InspectionNo != "NO_FV95" && msPDF_InspectionNo != "NO_SC95"){
      if(TypIC.RESIDUE_IND != "RESALE"){
      }
      else{
      }
  }
  
  
  if(cropYearFromLS() < 2015){
    data.R6C1 = "(FV-95)"
  }
  else{
    data.R6C1 = "(SC-95)"
  }
  

  data.R6C2 = TypIC.InspectionMemoNo_FV_95
  data.R6C3 = TypIC.TypeOfInspection
  data.R6C4 = TypIC.DateInspected
  
  data.R6_1C1 = TypIC.HMC_SEG
  data.R6_1C3 = TypIC.A_WeightInclVehicle
  
  data.R7C10= TypIC.B_WeightOfVehicle + " lbs. "

  data.R7C1 = TypIC.NoOfBags
  data.R7C2 = TypIC.Bulk
  data.R7C3 = TypIC.Type
  data.R7C4 = TypIC.Seg
  data.R7C5 = TypIC.CropYear
  data.R7C6 = TypIC.Time

  data.R7_1C2 = TypIC.HMC_FM_PCT

  data.R7_1C4 = TypIC.C_GrossWeight_AMinusB

  data.R8C1 = Number(TypIC.ForiegnMaterial).toFixed(0) + " % "

  
  data.R8C6 = TypIC.HMC_LSK_PCT

  data.R8C8 = TypIC.D_ForeignMaterial_PerOfFMxC

  data.R9C2 = Number(TypIC.LSK).toFixed(0) + " % "

  if(TypIC.Type == "VL"){
    data.R9C3 = TypIC.CrackedBrokenShells + " % "
  }
  else{
    data.R9C3 = " "
  }

  data.R9C8 = TypIC.HMC_MOIST

  data.R9C10 = TypIC.E_WeightLessFM_CMinusD + " lbs. "

  data.R10C1 = Number(TypIC.Moisture).toFixed(0) + " % "

  
  if(TypIC.Type == "VL"){
    data.R10C2 = TypIC.DiscoloredShells + " % "
  }
  else{
    data.R10C2 = " "
  }

  data.R10C6 = TypIC.HMC_SMK_PCT

  data.R10C8 = TypIC.F_ExcessMoisture_PerOfEMxE

  data.R11C1 = TypIC.FreezeDamage + " % "

  data.R11C8 = TypIC.HMC_SS_PCT

  data.R11C10 = TypIC.G_NetWeight_EMinusF

  data.R12C1 = TypIC.ConcealedRMD + " % "
  
  if(TypIC.Type == "VA"){
    data.R12C2 = TypIC.Fancy + " % "
  }
  else{
    data.R12C2 = " "
  }

  data.R12C6 = TypIC.HMC_SMKRS_PCT 
  
  data.R12C8 = TypIC.H_LSK_PerOfLSKxC

  if(TypIC.Type == "VA"){
    data.R13C1 = TypIC.ELK + " % "
  }
  else{
    data.R13C1 = " "
  }

  data.R13C8 = TypIC.HMC_OK_PCT 

  data.R13C10 = TypIC.I_NetWeightExclLSK_GMinusH + " lbs. "

  data.R14C11 = Number(TypIC.SMK).toFixed(0)
  data.R14C12 = Number(TypIC.SoundSplits).toFixed(0)
  data.R14C13 = Number(TypIC.Total_SMK_SoundSplits).toFixed(0)
  
  data.R14C8 = TypIC.HMC_FRZ_PCT

  data.R14C10 = TypIC.J_KernalValPerLB_ExclLSK
  
  data.R15C2 = TypIC.V_Remarks + '</br>'
  data.RemarkDate = moment(TypIC.RemarksDate).format('MM/DD')
  data.RemarksSupersede = TypIC.RemarksSupersede == undefined ? '' : TypIC.RemarksSupersede
  
  strTempRemarks = ""
  strTempRemarks = TypIC.Remarks
  
  nLineBreaks = 0
  nStartCharacter = 1
  nEndCharacter = nStartCharacter
  if(bExtraVehicleIds){
      strTempRemarks = "* Additional Vehicle Nos.: " + strTempVehicleNo + strTempRemarks
  }
  strTempVehicleNo = ""

  if(TypIC.ProbingPattern == ""){
      vsp_Viewer.TextBox = strTempRemarks
  }
  else{
      vsp_Viewer.TextBox = "Probe Pattern: " + TypIC.ProbingPattern + strTempRemarks
  }

  strTempRemarks = ""

  data.R15C1 = Number(TypIC.OtherKernals).toFixed(0) + " % "
  
  data.R15C8 = TypIC.HMC_CRMD_PCT

  data.R15C10 = TypIC.K_ELKPremium

  data.R16C1 = Number(TypIC.Damage).toFixed(0) + " % "
  
  data.R16C8 = TypIC.HMC_TOTKERN_PCT 

  data.R16C10 = TypIC.L_Total_JPlusK == undefined || TypIC.L_Total_JPlusK == null || TypIC.L_Total_JPlusK == ''  ? '0.00' : Number(TypIC.L_Total_JPlusK).toFixed(2)
  
  data.R17C1 = Number(TypIC.TotalKernals).toFixed(0) + " % "
  
  data.R17C10 = Number(TypIC.M_d_Total).toFixed(3)
  

  data.R18C1 = Number(TypIC.Hulls).toFixed(0) + " % "
  
  data.R18C8 = TypIC.HMC_DAM_PCT
  
  data.R18C9 = Number(TypIC.M_a_Damage).toFixed(3)
  data.R18C10 = Number(TypIC.M_b_ExcessFM).toFixed(3)
  data.R18C11 = Number(TypIC.M_c_ExcessSplits).toFixed(3)
  
  data.R19C1 = Number(TypIC.Total_Kernals_Hulls).toFixed(2) + " % "

  data.R19C8 = TypIC.HMC_HULL_PCT
  
  data.R19C10 = TypIC.N_NetValLB_ExclLSK_LMinusM == undefined || TypIC.N_NetValLB_ExclLSK_LMinusM == null || TypIC.N_NetValLB_ExclLSK_LMinusM == '' ? '' : Number(TypIC.N_NetValLB_ExclLSK_LMinusM).toFixed(3)
  
  data.R20C8 = TypIC.HMC_ELK_PCT
  
  data.R21C8 = TypIC.HMC_FANCY_PCT

  data.R21C10= TypIC.o

  data.R22C6 = TypIC.HMC_Total_Kernals_Hulls 

  data.R22C7_N = Number(TypIC.P_ValLBInclLSK_a_NxI).toFixed(2)
  
  data.R22C7_M = TypIC.lsk_per_pounds
  data.R22C7_D = TypIC.P_ValLBInclLSK_b_HxCents == undefined || TypIC.P_ValLBInclLSK_b_HxCents == null || TypIC.P_ValLBInclLSK_b_HxCents == '' ? Number(0).toFixed(2) : Number(TypIC.P_ValLBInclLSK_b_HxCents).toFixed(2)

  data.R24_1C3 = Number(TypIC.P_Total).toFixed(2)
  data.R24_1C4 = TypIC.P_Total_DivByG
  

  data.R25C4 = TypIC.Store_ApplicantNo
  data.R25C6 = TypIC.Purch_ApplicantNo
  

  data.R26_1C3 = TypIC.Store_LBS_LineG
  data.R26_1C4 = TypIC.Purch_LBS_LineG 
  
  
  if(TypIC.Purch_xLineP != ""){
    data.R27C3 = TypIC.Purch_xLineP == "" || TypIC.Purch_xLineP == undefined || TypIC.Purch_xLineP == null ? '' : Number(TypIC.Purch_xLineP)
  }
  

  if(TypIC.Purch_xPercentage != ""){
    data.R28C3 = TypIC.Purch_xPercentage != "" && TypIC.Purch_xPercentage != undefined && TypIC.Purch_xPercentage != null ? Number(TypIC.Purch_xPercentage) : '' 
  }
  else{
    data.R28C3 = " "
  }

  if(TypIC.inspector_name == ""){
    data.R28C1_Name = TypIC.signer_name
  }
  else{
    data.R28C1_Name = TypIC.inspector_name
  }

  if(TypIC.AREA_ID == "SW" && (TypIC.inspector_name != "" || TypIC.signer_name != "")){
    data.R28C1_Date = TypIC.DateInspected
  }
  
  
  data.R28_1C2 = ''
  if(TypIC.Purch_ValOfSegment != "" && TypIC.Purch_ValOfSegment != undefined && TypIC.Purch_ValOfSegment != null){
    data.R28_1C2 = Number(TypIC.Purch_ValOfSegment)
  }else{
    data.R28_1C2 = ''
  }
  
  if(TypIC.Store_DED_1_MktgAssess != "" && TypIC.Store_DED_1_MktgAssess != undefined && TypIC.Store_DED_1_MktgAssess != null){
    data.R29C1 =  Number(TypIC.Store_DED_1_MktgAssess)
  }
  if(TypIC.Purch_DED_1_MktgAssess != "" && TypIC.Purch_DED_1_MktgAssess != undefined && TypIC.Purch_DED_1_MktgAssess != null){
    data.R29C5  = Number(TypIC.Purch_DED_1_MktgAssess)
  } 
  
  data.R30C1 = TypIC.WeigherName

  if(TypIC.Store_DED_2_Tax_SerFee != "" && TypIC.Store_DED_2_Tax_SerFee != undefined && TypIC.Store_DED_2_Tax_SerFee != null){
    data.R30C3 =  Number(TypIC.Store_DED_2_Tax_SerFee)
  }
  if(TypIC.Purch_DED_2_Tax_SerFee != "" && TypIC.Purch_DED_2_Tax_SerFee != undefined && TypIC.Purch_DED_2_Tax_SerFee != null){
    data.R30C5 =  Number(TypIC.Purch_DED_2_Tax_SerFee)
  }


  if(TypIC.Store_DED_3 != "" || TypIC.Purch_DED_3 != ""){
    data.R30_1C2 = TypIC.DED_3
  }

  if(TypIC.Store_DED_3 != ""){
    data.R30_1C3 = Number(TypIC.Store_DED_3)
  }

  if(TypIC.Purch_DED_3 != ""){
    data.R30_1C4 = Number(TypIC.Purch_DED_3)
  }
 
  
data.R31C1 = TypIC.WeigherLicense
  

  if(TypIC.Store_DED_4 != "" || TypIC.Purch_DED_4 != ""){
    data.R31C3 = TypIC.DED_4
  }

  if(TypIC.Store_DED_4 != ""){
    data.R31C4 = Number(TypIC.Store_DED_4)
  }

  if(TypIC.Purch_DED_4 != ""){
    data.R31C5 = Number(TypIC.Purch_DED_4)
  }

  data.R32C1 = moment(TypIC.SettlementDate).format('MM/DD/YYYY')

  
  if(TypIC.Store_DED_5 != "" || TypIC.Purch_DED_5 != ""){
    data.R32C3 = TypIC.DED_5
  }

  if(TypIC.Store_DED_5 != ""){
    data.R32C4 = Number(TypIC.Store_DED_5)
  }

  if(TypIC.Purch_DED_5 != ""){
    data.R32C5 = Number(TypIC.Purch_DED_5)
  }
   
  
  if(TypIC.Store_DED_6 != "" || TypIC.Purch_DED_6 != ""){
    data.R32_1C1 = TypIC.DED_6
  }

  if(TypIC.Store_DED_6 != ""){
    data.R32_1C2 = Number(TypIC.Store_DED_6)
  }

  if(TypIC.Purch_DED_6 != ""){
    data.R32_1C3 = Number(TypIC.Purch_DED_6)
  }


  data.BottomRightNum = TypIC.BottomRightNumber
  if(msPDF_InspectionNo != "NO_FV95" && msPDF_InspectionNo != "NO_SC95"){
      if(TypIC.Variety_Description != ""){
        TypIC.Variety_Description = TypIC.Variety_Description == undefined ? '': TypIC.Variety_Description
        TypIC.IRR_DRY_IND = TypIC.IRR_DRY_IND == undefined ? '': TypIC.IRR_DRY_IND
        TypIC.RemarksSeed = TypIC.RemarksSeed == undefined ? '': TypIC.RemarksSeed
        data.PeanutVariety = TypIC.Variety_Description + TypIC.IRR_DRY_IND + TypIC.RemarksSeed
      }
  }

  TypIC.L13_Fed_Whs_Name = TypIC.L13_Fed_Whs_Name == undefined ? '' : TypIC.L13_Fed_Whs_Name
  TypIC.RemarksGovtVerbage2 = TypIC.RemarksGovtVerbage2 == undefined ? '' : TypIC.RemarksGovtVerbage2
  
  strTempVerbiage = ""
  if(msPDF_InspectionNo != "NO_FV95" && msPDF_InspectionNo != "NO_SC95"){
      if(TypIC.RemarksGovtVerbage2 != "- , ,"){
          strTempVerbiage = TypIC.L13_Fed_Whs_Name + ", (" + TypIC.RemarksGovtVerbage2 + ")"
      }
  }

  data.WarehouseDetail = strTempVerbiage == undefined || strTempVerbiage == null || strTempVerbiage == '' ? ''  : strTempVerbiage + '<br/>'
  
  let strAddTick = ''
  let intNumber = 0
  let nLineVariance = 0
  let strTemp2 = ''
      

  let bStoragePD = false
  let bPurchasePD = false
      
  strTemp = ""
  
  strTemp = ""
  strTemp2 = ""
  
  if(TypIC.QRemarksPremDed == undefined || TypIC.QRemarksPremDed == null || TypIC.QRemarksPremDed == ''){
    data.QRemarks = ''
  }
  else{
    data.QRemarks = TypIC.QRemarksPremDed + '</br>'
  }

  if(TypIC.Q_Remarks == undefined || TypIC.Q_Remarks == null || TypIC.Q_Remarks == ''){
    data.QRemarks += ''
  }
  else{
    data.QRemarks += TypIC.Q_Remarks + '</br>'
  }
  
  if(TypIC.Q_Remarks_Seed != ""){
    data.QRemarks = TypIC.Q_Remarks_Seed + '</br></br>' + TypIC.Q_Remarks + '</br></br>'
    nLineVariance++;
  }
  
  if(TypIC.Purch_NetAmount != undefined && TypIC.Purch_NetAmount.toString() != '' && Number(TypIC.Purch_NetAmount) >= 0 )
  {
    data.R33C5 = Number(TypIC.Purch_NetAmount).toFixed(2)
  }
  else
  {
    data.R33C5 = ''
  }
  // if(TypIC.Purch_NetAmount == undefined || TypIC.Purch_NetAmount == null)
  //   data.R33C5 = ''
  // else if(TypIC.Purch_NetAmount != "")
  //   data.R33C5 = TypIC.Purch_NetAmount >= 0 ? Number(TypIC.Purch_NetAmount) : Number(0)

  let insDate = TypIC.DateInspected
  let settDate = TypIC.SettlementDate

  if (insDate != "" && settDate != "") {
    if ((new Date(new Date(insDate).setDate(new Date(insDate).getDate() + 3))) > new Date(TypIC.SettlementDate)) {
      data.R33_1C1 = moment(new Date(new Date(settDate).setDate(new Date(settDate).getDate()))).format('MM/DD/YYYY')
    }
    else {
      data.R33_1C1 = moment(new Date(new Date(insDate).setDate(new Date(insDate).getDate() + 3))).format('MM/DD/YYYY')
    }
  }
  else {
    data.R33_1C1 = ''
  }

  data.R34C1 = TypIC.DocReceipient //'Applicant Original'
  data.WaterMark = TypIC.WaterMark
  let renCode = rptInspectionCertificateTemplate(data)
  return(renCode)
  
}

async function rptInspectionCertificateTemplate(data){
  data.FarmNo = data.FarmNo == undefined || data.FarmNo == NaN || data.FarmNo == null || data.FarmNo == 'NaN' ? '' : data.FarmNo
  data.Farm_Producer_Name1 = data.Farm_Producer_Name1 == undefined || data.Farm_Producer_Name1 == NaN || data.Farm_Producer_Name1 == null || data.Farm_Producer_Name1 == 'NaN' ? '' : data.Farm_Producer_Name1
  data.Farm_Producer_Name2 = data.Farm_Producer_Name2 == undefined || data.Farm_Producer_Name2 == NaN || data.Farm_Producer_Name2 == null || data.Farm_Producer_Name2 == 'NaN' ? '' : data.Farm_Producer_Name2
  data.Farm_Producer_Name3 = data.Farm_Producer_Name3 == undefined || data.Farm_Producer_Name3 == NaN || data.Farm_Producer_Name3 == null || data.Farm_Producer_Name3 == 'NaN' ? '' : data.Farm_Producer_Name3
  data.Farm_Producer_Name4 = data.Farm_Producer_Name4 == undefined || data.Farm_Producer_Name4 == NaN || data.Farm_Producer_Name4 == null || data.Farm_Producer_Name4 == 'NaN' ? '' : data.Farm_Producer_Name4

  data.R2C2 = data.R2C2 == undefined || data.R2C2 == NaN || data.R2C2 == null || data.R2C2 == 'NaN' ? '' : data.R2C2
  data.R2C3 = data.R2C3 == undefined || data.R2C3 == NaN || data.R2C3 == null || data.R2C3 == 'NaN' ? '' : data.R2C3
  if(data.R2C3.length<7 && data.R2C3.length >0){
    data.R2C3 = '0'.repeat((7-data.R2C3.length)) + data.R2C3
  }
  data.R2_1C1 = data.R2_1C1 == undefined || data.R2_1C1 == NaN ||  data.R2_1C1 == null || data.R2_1C1 == 'NaN' ? '' : data.R2_1C1
  data.R2_1C2 = data.R2_1C2 == undefined || data.R2_1C2 == NaN || data.R2_1C2 == null || data.R2_1C2 == 'NaN' ? '' : data.R2_1C2
  data.R2_1C3 = data.R2_1C3 == undefined || data.R2_1C3 == NaN || data.R2_1C3 == null || data.R2_1C3 == 'NaN' ? '' : data.R2_1C3
  data.R2_1C4 = data.R2_1C4 == undefined || data.R2_1C4 == NaN || data.R2_1C4 == null || data.R2_1C4 == 'NaN' ? '' : data.R2_1C4
  data.R2_1C2_11 = data.R2_1C2_11 == undefined || data.R2_1C2_11 == NaN || data.R2_1C2_11 == null || data.R2_1C2_11 == 'NaN' ? '' : data.R2_1C2_11
  data.R2_1C2_12 = data.R2_1C2_12 == undefined || data.R2_1C2_12 == NaN || data.R2_1C2_12 == null || data.R2_1C2_12 == 'NaN' ? '' : data.R2_1C2_12
  data.R2_1C2_13 = data.R2_1C2_13 == undefined || data.R2_1C2_13 == NaN || data.R2_1C2_13 == null || data.R2_1C2_13 == 'NaN' ? '' : data.R2_1C2_13
  data.R3C2 = data.R3C2 == undefined || data.R3C2 == NaN || data.R3C2 == null || data.R3C2 == 'NaN' ? '' : data.R3C2
  data.R3C3 = data.R3C3 == undefined || data.R3C3 == NaN || data.R3C3 == null || data.R3C3 == 'NaN' ? '' : data.R3C3
  data.R4C2 = data.R4C2 == undefined || data.R4C2 == NaN || data.R4C2 == null || data.R4C2 == 'NaN' ? '' : data.R4C2
  data.R5C1 = data.R5C1 == undefined || data.R5C1 == NaN || data.R5C1 == null || data.R5C1 == 'NaN' ? '' : data.R5C1
  data.R5C2 = data.R5C2 == undefined || data.R5C2 == NaN || data.R5C2 == null || data.R5C2 == 'NaN' ? '' : data.R5C2
  data.R6C1 = data.R6C1 == undefined || data.R6C1 == NaN || data.R6C1 == null || data.R6C1 == 'NaN' ? '' : data.R6C1
  data.R6C2 = data.R6C2 == undefined || data.R6C2 == NaN || data.R6C2 == null || data.R6C2 == 'NaN' ? '' : data.R6C2
  data.R6C3 = data.R6C3 == undefined || data.R6C3 == NaN || data.R6C3 == null || data.R6C3 == 'NaN' ? '' : data.R6C3
  data.R6C4 = data.R6C4 == undefined || data.R6C4 == NaN || data.R6C4 == null || data.R6C4 == 'NaN' ? '' : data.R6C4
  data.R6_1C1 = data.R6_1C1 == undefined || data.R6_1C1 == NaN || data.R6_1C1 == null || data.R6_1C1 == 'NaN' ? '' : data.R6_1C1
  data.R6_1C3 = data.R6_1C3 == undefined || data.R6_1C3 == NaN || data.R6_1C3 == null || data.R6_1C3 == 'NaN' ? '' : data.R6_1C3
  data.R7C1 = data.R7C1 == undefined || data.R7C1 == NaN || data.R7C1 == null || data.R7C1 == 'NaN' ? '' : data.R7C1
  data.R7C2 = data.R7C2 == undefined || data.R7C2 == NaN || data.R7C2 == null || data.R7C2 == 'NaN' ? '' : data.R7C2
  data.R7C3 = data.R7C3 == undefined || data.R7C3 == NaN || data.R7C3 == null || data.R7C3 == 'NaN' ? '' : data.R7C3
  data.R7C4 = data.R7C4 == undefined || data.R7C4 == NaN || data.R7C4 == null || data.R7C4 == 'NaN' ? '' : data.R7C4
  data.R7C5 = data.R7C5 == undefined || data.R7C5 == NaN || data.R7C5 == null || data.R7C5 == 'NaN' ? '' : data.R7C5
  data.R7C6 = data.R7C6 == undefined || data.R7C6 == NaN || data.R7C6 == null || data.R7C6 == 'NaN' ? '' : data.R7C6
  data.R7C10 = data.R7C10 == undefined || data.R7C10 == NaN || data.R7C10 == null || data.R7C10 == 'NaN' ? '' : data.R7C10
  data.R7_1C2 = data.R7_1C2 == undefined || data.R7_1C2 == NaN || data.R7_1C2 == null || data.R7_1C2 == 'NaN' ? '' : data.R7_1C2
  data.R7_1C4 = data.R7_1C4 == undefined || data.R7_1C4 == NaN || data.R7_1C4 == null || data.R7_1C4 == 'NaN' ? '' : data.R7_1C4
  data.R8C1 = data.R8C1 == undefined || data.R8C1 == NaN || data.R8C1 == null || data.R8C1 == 'NaN' ? '' : data.R8C1
  data.R8C6 = data.R8C6 == undefined || data.R8C6 == NaN || data.R8C6 == null || data.R8C6 == 'NaN' ? '' : data.R8C6
  data.R8C8 = data.R8C8 == undefined || data.R8C8 == NaN || data.R8C8 == null || data.R8C8 == 'NaN' ? '' : data.R8C8
  data.R9C2 = data.R9C2 == undefined || data.R9C2 == NaN || data.R9C2 == null || data.R9C2 == 'NaN' ? '' : data.R9C2
  data.R9C3 = data.R9C3 == undefined || data.R9C3 == NaN || data.R9C3 == null || data.R9C3 == 'NaN' ? '' : data.R9C3
  data.R9C8 = data.R9C8 == undefined || data.R9C8 == NaN || data.R9C8 == null || data.R9C8 == 'NaN' ? '' : data.R9C8
  data.R9C10 = data.R9C10 == undefined || data.R9C10 == NaN || data.R9C10 == null || data.R9C10 == 'NaN' ? '' : data.R9C10
  data.R10C1 = data.R10C1 == undefined || data.R10C1 == NaN || data.R10C1 == null || data.R10C1 == 'NaN' ? '' : data.R10C1
  data.R10C2 = data.R10C2 == undefined || data.R10C2 == NaN || data.R10C2 == null || data.R10C2 == 'NaN' ? '' : data.R10C2
  data.R10C6 = data.R10C6 == undefined || data.R10C6 == NaN || data.R10C6 == null || data.R10C6 == 'NaN' ? '' : data.R10C6
  data.R10C8 = data.R10C8 == undefined || data.R10C8 == NaN || data.R10C8 == null || data.R10C8 == 'NaN' ? '' : data.R10C8
  data.R11C1 = data.R11C1 == undefined || data.R11C1 == NaN || data.R11C1 == null || data.R11C1 == 'NaN' ? '' : data.R11C1
  data.R11C8 = data.R11C8 == undefined || data.R11C8 == NaN || data.R11C8 == null || data.R11C8 == 'NaN' ? '' : data.R11C8
  data.R11C10 = data.R11C10 == undefined || data.R11C10 == NaN || data.R11C10 == null || data.R11C10 == 'NaN' ? '' : data.R11C10
  data.R12C1 = data.R12C1 == undefined || data.R12C1 == NaN || data.R12C1 == null || data.R12C1 == 'NaN' ? '' : data.R12C1
  data.R12C2 = data.R12C2 == undefined || data.R12C2 == NaN || data.R12C2 == null || data.R12C2 == 'NaN' ? '' : data.R12C2
  data.R12C6 = data.R12C6 == undefined || data.R12C6 == NaN || data.R12C6 == null || data.R12C6 == 'NaN' ? '' : data.R12C6
  data.R12C8 = data.R12C8 == undefined || data.R12C8 == NaN || data.R12C8 == null || data.R12C8 == 'NaN' ? '' : data.R12C8
  data.R13C1 = data.R13C1 == undefined || data.R13C1 == NaN || data.R13C1 == null || data.R13C1 == 'NaN' ? '' : data.R13C1
  data.R13C8 = data.R13C8 == undefined || data.R13C8 == NaN || data.R13C8 == null || data.R13C8 == 'NaN' ? '' : data.R13C8
  data.R13C10 = data.R13C10 == undefined || data.R13C10 == NaN || data.R13C10 == null || data.R13C10 == 'NaN' ? '' : data.R13C10
  data.R14C11 = data.R14C11 == undefined || data.R14C11 == NaN || data.R14C11 == null || data.R14C11 == 'NaN' ? '' : data.R14C11
  data.R14C12 = data.R14C12 == undefined || data.R14C12 == NaN || data.R14C12 == null || data.R14C12 == 'NaN' ? '' : data.R14C12
  data.R14C13 = data.R14C13 == undefined || data.R14C13 == NaN || data.R14C13 == null || data.R14C13 == 'NaN' ? '' : data.R14C13
  data.R14C8 = data.R14C8 == undefined || data.R14C8 == NaN || data.R14C8 == null || data.R14C8 == 'NaN' ? '' : data.R14C8
  data.R14C10 = data.R14C10 == undefined || data.R14C10 == NaN || data.R14C10 == null || data.R14C10 == 'NaN' ? '' : data.R14C10
  data.R15C2 = data.R15C2 == undefined || data.R15C2 == NaN || data.R15C2 == null || data.R15C2 == 'NaN' ? '' : data.R15C2
  data.R15C1 = data.R15C1 == undefined || data.R15C1 == NaN || data.R15C1 == null || data.R15C1 == 'NaN' ? '' : data.R15C1
  data.R15C8 = data.R15C8 == undefined ||  data.R15C8 == NaN ||  data.R15C8 == null ||  data.R15C8 == 'NaN' ? '' : data.R15C8
  data.R15C10 = data.R15C10 == undefined || data.R15C10 == NaN || data.R15C10 == null || data.R15C10 == 'NaN' ? '' : data.R15C10
  data.R16C1 = data.R16C1 == undefined || data.R16C1 == NaN || data.R16C1 == null || data.R16C1 == 'NaN' ? '' : data.R16C1
  data.R16C8 = data.R16C8 == undefined || data.R16C8 == NaN || data.R16C8 == null || data.R16C8 == 'NaN' ? '' : data.R16C8
  data.R16C10 = data.R16C10 == undefined || data.R16C10 == NaN || data.R16C10 == null || data.R16C10 == 'NaN' ? '' : data.R16C10
  data.R17C1 = data.R17C1 == undefined || data.R17C1 == NaN || data.R17C1 == null || data.R17C1 == 'NaN' ? '' : data.R17C1
  data.R17C10 = data.R17C10 == undefined || data.R17C10 == NaN || data.R17C10 == null || data.R17C10 == 'NaN' ? '' : data.R17C10
  data.R18C1 = data.R18C1 == undefined || data.R18C1 == NaN || data.R18C1 == null || data.R18C1 == 'NaN' ? '' : data.R18C1
  data.R18C8 = data.R18C8 == undefined || data.R18C8 == NaN || data.R18C8 == null || data.R18C8 == 'NaN' ? '' : data.R18C8
  data.R18C9 = data.R18C9 == undefined || data.R18C9 == NaN || data.R18C9 == null || data.R18C9 == 'NaN' ? '' : data.R18C9
  data.R18C10 = data.R18C10 == undefined || data.R18C10 == NaN || data.R18C10 == null || data.R18C10 == 'NaN' ? '' : data.R18C10
  data.R18C11 = data.R18C11 == undefined || data.R18C11 == NaN || data.R18C11 == null || data.R18C11 == 'NaN' ? '' : data.R18C11
  data.R19C1 = data.R19C1 == undefined || data.R19C1 == NaN || data.R19C1 == null || data.R19C1 == 'NaN' ? '' : data.R19C1
  data.R19C8 = data.R19C8 == undefined || data.R19C8 == NaN || data.R19C8 == null || data.R19C8 == 'NaN' ? '' : data.R19C8
  data.R19C10 = data.R19C10 == undefined || data.R19C10 == NaN || data.R19C10 == null || data.R19C10 == 'NaN' ? '' : data.R19C10
  data.R20C8 = data.R20C8 == undefined || data.R20C8 == NaN || data.R20C8 == null || data.R20C8 == 'NaN' ? '' : data.R20C8
  data.R21C8 = data.R21C8 == undefined || data.R21C8 == NaN || data.R21C8 == null || data.R21C8 == 'NaN' ? '' : data.R21C8
  data.R21C10 = data.R21C10 == undefined || data.R21C10 == NaN || data.R21C10 == null || data.R21C10 == 'NaN' ? '' : data.R21C10
  data.R22C6 = data.R22C6 == undefined || data.R22C6 == NaN || data.R22C6 == null || data.R22C6 == 'NaN' ? '' : data.R22C6
  data.R22C7_N = data.R22C7_N == undefined || data.R22C7_N == NaN || data.R22C7_N == null || data.R22C7_N == 'NaN' ? '' : data.R22C7_N
  data.R22C7_M = data.R22C7_M == undefined || data.R22C7_M == NaN || data.R22C7_M == null || data.R22C7_M == 'NaN' ? '' : data.R22C7_M
  data.R22C7_D = data.R22C7_D == undefined || data.R22C7_D == NaN || data.R22C7_D == null || data.R22C7_D == 'NaN' ? '' : data.R22C7_D
  data.R24_1C3 = data.R24_1C3 == undefined || data.R24_1C3 == NaN || data.R24_1C3 == null || data.R24_1C3 == 'NaN' ? '' : data.R24_1C3
  data.R24_1C4 = data.R24_1C4 == undefined || data.R24_1C4 == NaN || data.R24_1C4 == null || data.R24_1C4 == 'NaN' ? '' : data.R24_1C4
  data.R25C4 = data.R25C4 == undefined || data.R25C4 == NaN || data.R25C4 == null || data.R25C4 == 'NaN' ? '' : data.R25C4
  data.R25C6 = data.R25C6 == undefined || data.R25C6 == NaN || data.R25C6 == null || data.R25C6 == 'NaN' ? '' : data.R25C6
  data.R26_1C3 = data.R26_1C3 == undefined || data.R26_1C3 == NaN ||data.R26_1C3 == null || data.R26_1C3 == 'NaN' ? '' : data.R26_1C3
  data.R26_1C4 = data.R26_1C4 == undefined || data.R26_1C4 == NaN ||data.R26_1C4 == null || data.R26_1C4 == 'NaN' ? '' : data.R26_1C4

  data.R27C3 = data.R27C3 == undefined || data.R27C3 == NaN || data.R27C3 == '' || data.R27C3 == null || data.R27C3 == 'NaN' ? '' : Number(data.R27C3).toFixed(2)
  data.R28C1_Name = data.R28C1_Name == undefined || data.R28C1_Name == NaN || data.R28C1_Name == null || data.R28C1_Name == 'NaN' ? '' : data.R28C1_Name
  data.R28C1_Date = data.R28C1_Date == undefined || data.R28C1_Date == NaN || data.R28C1_Date == null || data.R28C1_Date == 'NaN' ? '' : data.R28C1_Date
  data.R28C3 = data.R28C3 == undefined || data.R28C3 == NaN || data.R28C3 == null || data.R28C3 == 'NaN' ? '' : data.R28C3
  data.R29C5 = data.R29C5 == undefined || data.R29C5 == NaN || data.R29C5 == null || data.R29C5 == 'NaN' ? '' : data.R29C5


  data.R30C1 = data.R30C1 == undefined || data.R30C1 == NaN || data.R30C1 == null || data.R30C1 == 'NaN' ? '' : data.R30C1
  data.R31C1 = data.R31C1 == undefined || data.R31C1 == NaN || data.R31C1 == null || data.R31C1 == 'NaN' ? '' : data.R31C1
  data.R30C3 = data.R30C3 == undefined || data.R30C3 == NaN || data.R30C3 == '' || data.R30C3 == null || data.R30C3 == 'NaN' ? '' : Number(data.R30C3).toFixed(2)
  data.R30C5 = data.R30C5 == undefined || data.R30C5 == NaN || data.R30C5 == null || data.R30C5 == 'NaN' ? '' : data.R30C5
  data.R30_1C2 = data.R30_1C2 == undefined || data.R30_1C2 == NaN || data.R30_1C2 == null || data.R30_1C2 == 'NaN' ? '' : data.R30_1C2
  data.R30_1C3 = data.R30_1C3 == undefined || data.R30_1C3 == NaN || data.R30_1C3 == '' || data.R30_1C3 == null || data.R30_1C3 == 'NaN' ? '' : Number(data.R30_1C3).toFixed(2)
  data.R30_1C4 = data.R30_1C4 == undefined || data.R30_1C4 == NaN || data.R30_1C4 == '' || data.R30_1C4 == null || data.R30_1C4 == 'NaN' ? '' : Number(data.R30_1C4).toFixed(2)
  data.R31C3 = data.R31C3 == undefined || data.R31C3 == NaN || data.R31C3 == null || data.R31C3 == 'NaN' ? '' : data.R31C3
  data.R31C4 = data.R31C4 == undefined || data.R31C4 == NaN || data.R31C4 == '' || data.R31C4 == null || data.R31C4 == 'NaN' ? '' : Number(data.R31C4).toFixed(2)
  data.R31C5 = data.R31C5 == undefined || data.R31C5 == NaN || data.R31C5 == '' || data.R31C5 == null || data.R31C5 == 'NaN' ? '' : Number(data.R31C5).toFixed(2)
  data.R32C1 = data.R32C1 == undefined || data.R32C1 == NaN || data.R32C1 == null || data.R32C1 == 'NaN' ? '' : data.R32C1
  data.R32C3 = data.R32C3 == undefined || data.R32C3 == NaN || data.R32C3 == null || data.R32C3 == 'NaN' ? '' : data.R32C3
  data.R32C4 = data.R32C4 == undefined || data.R32C4 == NaN || data.R32C4 == '' || data.R32C4 == null || data.R32C4 == 'NaN' ? '' : Number(data.R32C4).toFixed(2)
  data.R32C5 = data.R32C5 == undefined || data.R32C5 == NaN || data.R32C5 == '' || data.R32C5 == null || data.R32C5 == 'NaN' ? '' : Number(data.R32C5).toFixed(2)
  data.R32_1C1 = data.R32_1C1 == undefined || data.R32_1C1 == NaN || data.R32_1C1 == null || data.R32_1C1 == 'NaN' ? '' : data.R32_1C1
  data.R32_1C2 = data.R32_1C2 == undefined || data.R32_1C2 == NaN || data.R32_1C2 == null || data.R32_1C2 == 'NaN' ? '' : data.R32_1C2
  data.R32_1C3 = data.R32_1C3 == undefined || data.R32_1C3 == NaN || data.R32_1C3 == null || data.R32_1C3 == 'NaN' ? '' : data.R32_1C3
  data.R33C3 = data.R33C3 == undefined || data.R33C3 == NaN || data.R33C3 == null || data.R33C3 == 'NaN' ? '' : data.R33C3
  data.R33_1C1 = data.R33_1C1 == undefined || data.R33_1C1 == NaN || data.R33_1C1 == null || data.R33_1C1 == 'NaN' ? '' : data.R33_1C1
  data.R34C1 = data.R34C1 == undefined || data.R34C1 == NaN || data.R34C1 == null || data.R34C1 == 'NaN' ? '' : data.R34C1
  
  
  
  let Footer = ''
  Footer += `
  <table style='height:5%;font-size: 82%; width:99%'border:0px solid; border-color: black white white white;>
    <tr>
      <td> 
        <span style="text-align:left;display: inline-block;width: 75%;"> <b> Note: THIS IS NOT A WAREHOUSE RECEIPT - NOT NEGOTIABLE </b> </span>
        <span style="text-align:right;display: inline-block;width: 21%;"> ${data.R34C1} </span>
      </td>   
    </tr>
  </table>`


    return `
    <html>
    <style>
    body:before{
        content: '${data.WaterMark}';
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;  
        color: #0d745e;
        font-size: 650%;
        font-weight: 500px;
        display: grid;
        justify-content: center;
        align-content: center;
        opacity: 0.35;
    }
    @media print {
        @page :footer { display: none }    
        @page :header { display: none }    
        @page { margin-top: 0; margin-bottom: 0; }    
        html,
        body { height: 100%; margin: 0 !important; padding: 0 !important; }
    }     
    </style>
    <br/><br/>
    <table style='font-size: 45%;padding-top: 2%;padding-left: 2%;border-collapse:collapse;width:99%;height:90%;'>
      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 140%;"  colspan="15"> 
              <span style="text-align:left;display: inline-block;width: 23%;">  FSA-1007 (06-01-05) FINAL </span>
              <span style="text-align:center;display: inline-block;width: 50%;"><b> INSPECTION CERTIFICATE AND CALCULATION WORKSHEET </b> </span>
              <span style="text-align:right;display: inline-block;width: 23%;"><b> USDA/FSA/AMS </b> </span>
          </td>
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;border-bottom: none;font-size: 140%;" colspan="7" rowspan="2">
              <span style="text-align:left">  Farm Producer Name/Seller's Name, St. & CO. Code & Farm No. </br> ${data.FarmNo} </br> ${data.Farm_Producer_Name1} </br> ${data.Farm_Producer_Name2} </br> ${data.Farm_Producer_Name3} </br> ${data.Farm_Producer_Name4} </span>
          </td>            
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 140%;"  colspan="4"> 
              <span style="text-align:left">Buying Point No. & Location</br> ${data.R2C2} </span>
          </td> 
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 140%;"  colspan="4"> 
              <span style="text-align:left">  Serial No. </br>  </span>
              <span style="text-align: right;display: inline-block; width: 90%;"> ${data.R2C3} </span>
          </td>        
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">           
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 140%;"  colspan="2"> 
              <span style="text-align:left"> Share % </br> ${data.R2_1C1} </br> ${data.R2_1C2} </br> ${data.R2_1C3} </br> ${data.R2_1C4} </span>
          </td> 
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 140%;"  colspan="6"> 
              <span style="text-align:left"> Applicant/Buyer's Name and No.</br>${data.R2_1C2_11} </br> ${data.R2_1C2_12}  </br> ${data.R2_1C2_13} </span>
          </td>        
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 140%;" colspan="8"> 
              <span style="text-align:left"> <b> SECTION I - INSPECTION CERTIFICATE - FARMERS STOCK PEANUTS </b> </span>
          </td>            
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 140%;"  colspan="3"> 
              <span style="text-align:left">  Whse. Description and Bin No. </br> ${data.R3C2} </span>
          </td> 
          <td style="height: 100%;vertical-align: top;width:5%;border: 1px solid black;border-collapse: collapse;font-size: 140%;"  colspan="4" rowspan="2"> 
              <span style="text-align:left"> Delivery Point, if not the same as Whse. </br></br> ${data.R3C3} </span>
          </td>
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 120%;" colspan="8"> 
              <span style="text-align:left"> This certificate is issued pursuant to the Agricultural Marketing Act of 1946 as amended (7 U.S.C. 1621 et. seq.) and is 
              admissible as prima-facie evidence in all courts in the United States. Any person who knowingly shall falsely make, alter, 
              forge, or counterfeit this certificate, electronic image, or participate in any such action is subject to a fine not more than 
              $1000.00, imprisonment for not less than 1 year, or both </span>
          </td>            
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 140%;"  colspan="3"> 
              <span style="text-align:left"> Type Storage ${data.R4C2} </span>
          </td>       
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 140%;" colspan="9"> 
              <span style="text-align:left"> Vehicle No.  ${data.R5C1} </span>
          </td>            
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 140%;"  colspan="4"> 
              <span style="text-align:left"> Weight Ticket No. ${data.R5C2} </span>
          </td> 
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 140%;"  colspan="1"> 
              <span style="text-align:left"> In &nbsp X </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 140%;"  colspan="1"> 
              <span style="text-align:left"> OUT </span>
          </td>         
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 140%;"  colspan="3" rowspan="2"> 
              <span style="text-align:left"> Inspection Memo No. ${data.R6C1} </br> ${data.R6C2} </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 140%;"  colspan="3" rowspan="2"> 
              <span style="text-align:left"> Type of Inspection </br> ${data.R6C3}&nbsp</span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 140%;"  colspan="3" rowspan="2"> 
              <span style="text-align:left"> Date Inspected </br> ${data.R6C4} </span>
          </td>            
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 140%;"  colspan="6"> 
              <span style="text-align:middle"> <b>SECTION II - CALCULATION WORKSHEET</b> </span>
          </td>       
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="2"> 
              <span style="text-align:left"> Seg. ${data.R6_1C1} </span>
          </td> 
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
              <span style="text-align:left"> A. Weight Including Vehicle  </span>
          </td> 
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align: right;"  colspan="1"> 
              <span style="text-align: right; display: inline-block; width: 85%;">  ${data.R6_1C3} lbs.</span>
          </td>       
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="1" rowspan="2"> 
              <span style="text-align:left">  No. Bags ${data.R7C1}</span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="1" rowspan="2"> 
              <span style="text-align:left">  Bulk </br> ${data.R7C2} </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="1" rowspan="2"> 
              <span style="text-align:left">  Type </br> ${data.R7C3} </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="2" rowspan="2"> 
              <span style="text-align:left"> Seg. </br> ${data.R7C4} </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="2" rowspan="2"> 
              <span style="text-align:left"> Crop Year: </br> ${data.R7C5} </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="2" rowspan="2"> 
              <span style="text-align:left"> Time </br> ${data.R7C6} </span>
          </td>            
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
              <span style="text-align:left"> <b> HMC </b> </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
          <span style="text-align:right;width: 75%;display: inline-block;"> % </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
              <span style="text-align:left"> B. Weight of Vehicle </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align: right;"  colspan="1"> 
              <span style="text-align: right; display: inline-block; width: 85%;"> ${data.R7C10} </span>
          </td>
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
              <span style="text-align:left"> FM </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
            <span style="text-align:right;width: 75%;display: inline-block;"> ${data.R7_1C2} </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
              <span style="text-align:left"> C. Gross Weight (A minus B)</span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align: right;"  colspan="1"> 
              <span style="text-align: right; display: inline-block; width: 85%;"> ${data.R7_1C4} lbs </span>
          </td>
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="5"> 
              <span style="text-align:left;display: inline-block;width: 47%;"> FOREIGN MATERIAL </span>
              <span style="text-align:right;display: inline-block;width: 47%;"> ${data.R8C1} </span>
          </td>             
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="4"> 
              <span style="text-align:center;display:inline-block; width:98%;"><b> VALENCIA TYPE ONLY </b></span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
            <span style="text-align:left"> LSK </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
            <span style="text-align:right;width: 75%;display: inline-block;"> ${data.R8C6} </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
            <span style="text-align:left">D. Foreign Material (% of FM x C)</span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align: right;"  colspan="1"> 
            <span style="text-align: right; display: inline-block; width: 85%;"> ${data.R8C8} lbs. </span>
          </td>   
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="5"> 
          <span style="text-align:left;display: inline-block;width: 47%;"> LSK </span>
          <span style="text-align:right;display: inline-block;width: 47%;"> ${data.R9C2} </span>
        </td>             
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="4"> 
          <span style="text-align:left"> CRACKED/BROKEN SHELLS </span>
          <span style="text-align:left"> ${data.R9C3} </span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
          <span style="text-align:left"> MST </span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
          <span style="text-align:right;width: 75%;display: inline-block;"> ${data.R9C8} </span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
          <span style="text-align:left;"> E. Weight Less FM (C minus D)</span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"text-align: right;"  colspan="1"> 
          <span style="text-align: right; display: inline-block; width: 100%;"> ${data.R9C10} </span>
        </td>        
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
      <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="5"> 
        <span style="text-align:left;display: inline-block;width: 47%;"> MOISTURE </span>
        <span style="text-align:right;display: inline-block;width: 47%;"> ${data.R10C1} </span>
      </td>
      <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="4"> 
        <span style="text-align:left"> DISCOLOURED SHELLS </span>
        <span style="text-align:left"> ${data.R10C2} </span>
      </td>
      <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
        <span style="text-align:left"> SMK </span>
      </td>
      <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
        <span style="text-align:right;width: 75%;display: inline-block;"> ${data.R10C6} </span>
      </td>
      <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left"> F. Excess Moisture (% of EM x E)</span>
      </td>
      <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align: right;"  colspan="1"> 
        <span style="text-align: right; display: inline-block; width: 85%;"> ${data.R10C8} lbs. </span>
      </td>        
    </tr>

    <tr style="border: 1px solid black;border-collapse:collapse;">
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="5"> 
      <span style="text-align:left;display: inline-block;width: 47%;"> FREEZE DAMAGE </span>
      <span style="text-align:right;display: inline-block;width: 47%;"> ${data.R11C1} </span>
    </td>             
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="4"> 
      <span style="text-align:center;display:inline-block; width:98%;"><b> VIRGINIA TYPE ONLY </b></span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
      <span style="text-align:left"> SS </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
      <span style="text-align:right;width: 75%;display: inline-block;"> ${data.R11C8} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left"> G. NET WEIGHT (E minus F)  </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align: right;"  colspan="1"> 
      <span style="text-align: right; display: inline-block; width: 85%;"> ${data.R11C10} lbs. </span>
    </td>        
  </tr>

  <tr style="border: 1px solid black;border-collapse:collapse;">
  <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="5" rowspan="2"> 
    <span style="text-align:left;display: inline-block;width: 47%;"> CONCEALED RMD  </span>
    <span style="text-align:right;display: inline-block;width: 47%;"> ${data.R12C1}  </span>
  </td> 
  <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="4"> 
    <span style="text-align:left"> FANCY</span>
    <span style="text-align:left"> ${data.R12C2} </span>
  </td>
  <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
    <span style="text-align:left"> SMKRS </span>
  </td>
  <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
    <span style="text-align:right;width: 75%;display: inline-block;"> ${data.R12C6} </span>
  </td>
  <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
    <span style="text-align:left"> H. LSK (% of LSK x C)  </span>
  </td>
  <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align: right;"  colspan="1"> 
    <span style="text-align: right; display: inline-block; width: 85%;"> ${data.R12C8} lbs. </span>
  </td>        
    </tr>

    <tr style="border: 1px solid black;border-collapse:collapse;">
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="4"> 
    <span style="text-align:left"> ELK ${data.R13C1} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
    <span style="text-align:left"> OK </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
    <span style="text-align:right;width: 75%;display: inline-block;"> ${data.R13C8} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
    <span style="text-align:left"> I. Net Weight excluding LSK (G minus H)</span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align: right;"  colspan="1"> 
    <span style="text-align: right; display: inline-block; width: 85%;"> ${data.R13C10} </span>
    </td>        
    </tr>

    <tr style="border: 1px solid black;border-collapse:collapse;">
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="9"> 
    <span style="text-align:left;width:22%;display: inline-block;"> SMK </span>
    <span style="text-align:center;width:50%;display: inline-block;"> ${data.R14C11} % + SOUND SPLITS ${data.R14C12} %= </span>
    <span style="text-align:right;width:25%;display: inline-block;"> ${data.R14C13} % </span>

    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
    <span style="text-align:left"> FRZ </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
    <span style="text-align:right;width: 75%;display: inline-block;"> ${data.R14C8} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
    <span style="text-align:left"> J. Kernel Value Per Ton/Lb. (Exc. LSK) </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align: right;"  colspan="1"> 
    <span style="text-align: right; display: inline-block; width: 85%;">  $/&cent; ${data.R14C10} </span>
    </td>        
    </tr>

    <tr style="border: 1px solid black;border-collapse:collapse;">
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;border-right:none;border-bottom: none;font-size: 150%;padding-top: 1%;vertical-align:top;"  colspan="5" rowspan="8"> 
      <span style="text-align:left"> REMARKS </br></br></span>
      <span style="text-align:left"> ${data.R15C2} </br></span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="4"> 
    <span style="text-align:left;display: inline-block;width: 74%;"> OTHER KERNELS </span>
    <span style="text-align:right;display: inline-block;width: 24%;"> ${data.R15C1} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
    <span style="text-align:left"> C RMD </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
    <span style="text-align:right;width: 75%;display: inline-block;"> ${data.R15C8} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
    <span style="text-align:left"> K. ELK Premium </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align: right;"  colspan="1"> 
    <span style="text-align: right; display: inline-block; width: 85%;"> $/&cent ${data.R15C10} </span>
    </td>        
    </tr>

    <tr style="border: 1px solid black;border-collapse:collapse;">
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="4"> 
    <span style="text-align:left;display: inline-block;width: 74%;"> DAMAGE </span>
    <span style="text-align:right;display: inline-block;width: 24%;"> ${data.R16C1} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1" rowspan="2"> 
    <span style="text-align:left"> Total </br> Kernels </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1" rowspan="2"> 
    <span style="text-align:right;width: 75%;display: inline-block;"> ${data.R16C8} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
    <span style="text-align:left"> L. Total (J + K) </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align: right;"  colspan="1"> 
    <span style="text-align: right; display: inline-block; width: 85%;"> $/&cent ${data.R16C10} </span>
    </td>        
    </tr>


    <tr style="border: 1px solid black;border-collapse:collapse;">
    <td style="width:5%;border: 1px solid black;border-collasigner_namepse: collapse;font-size: 130%;"  colspan="4"> 
    <span style="text-align:left;display: inline-block;width: 74%;"> TOTAL KERNELS </span>
    <span style="text-align:right;display: inline-block;width: 24%;"> ${data.R17C1} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
    <span style="text-align:left"> M. Damage </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
    <span style="text-align:left"> Excess FM </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
    <span style="text-align:left"> Excess Splits </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1" rowspan="2"> 
    <span style="text-align:left"> TOTAL </br></span>
    <span style="text-align: right; display: inline-block; width: 100%;"> $/&cent ${data.R17C10} </span>
    </td>        
    </tr>

    <tr style="border: 1px solid black;border-collapse:collapse;">
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="4"> 
    <span style="text-align:left;display: inline-block;width: 47%;"> HULLS </span>
    <span style="text-align:right;display: inline-block;width: 47%;"> ${data.R18C1} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
    <span style="text-align:left"> DAM </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
    <span style="text-align:right;width: 75%;display: inline-block;"> ${data.R18C8} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan=""> 
    <span style="text-align:left"> ${data.R18C9} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
    <span style="text-align:left"> ${data.R18C10} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
    <span style="text-align:left"> ${data.R18C11} </span>
    </td>        
    </tr>

    <tr style="border: 1px solid black;border-collapse:collapse;">            
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;border-right: none;"  colspan="3" rowspan ="2"> 
    <span style="text-align:left;display: inline-block;"> TOTAL KERNELS & HULLS (EXC. LSK) </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;border-left: none;"  colspan="1" rowspan ="2">
    <span style="text-align:right;width: 82%;display: inline-block;"> ${data.R19C1} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
    <span style="text-align:left"> HULLS </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
    <span style="text-align:right;width: 75%;display: inline-block;"> ${data.R19C8} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3" rowspan="2"> 
    <span style="text-align:left"> N. Net Value Per Ton/Lb. Exc. LSK (L minus M) </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align: right;"  colspan="1" rowspan="2"> 
    <span style="text-align: right; display: inline-block; width: 85%;">  $/&cent ${data.R19C10} </span>
    </td>        
    </tr>

    <tr style="border: 1px solid black;border-collapse:collapse;">
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
    <span style="text-align:left"> ELK </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
    <span style="text-align:right;width: 75%;display: inline-block;"> ${data.R20C8} </span>
    </td>       
    </tr>

    <tr style="border: 1px solid black;border-collapse:collapse;">
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;border-left:none;font-size: 130%;border-bottom: none;"  colspan="4" rowspan="2"> 
        <span style="text-align:left"> </span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
        <span style="text-align:left"> FANCY </span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
        <span style="text-align:right;width: 75%;display: inline-block;"> ${data.R21C8} </span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 125%;"  colspan="3"> 
        <span style="text-align:left">O.Value Per Lb.Exc.LSK (N divided by 2000)</span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align: right;"  colspan="1"> 
        <span style="text-align: right; display: inline-block; width: 85%;"> ${data.R21C10} &cent </span>
        </td>        
    </tr>
    
    <tr style="border: 1px solid black;border-collapse:collapse;border-bottom:none;">
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
        <span style="text-align:left"> Tot Krnls & Hulls (Exc. LSK) </span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
        <span style="text-align:right;width: 75%;display: inline-block;"> ${data.R22C6} </span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="4"> 
        <span style="text-align:left">  P. Value Per Pound Including LSK </br> &nbsp&nbsp N or O x I = $ <u>&nbsp&nbsp&nbsp&nbsp${data.R22C7_N} </u> </br> &nbsp&nbsp H x $ ${data.R22C7_M} = $ <u>&nbsp&nbsp&nbsp&nbsp${data.R22C7_D} </u></span>
        </td>       
    </tr>

    <tr style="border: 1px solid black;border-collapse:collapse;border-top:none;">
        <td style="width:5%;border-collapse: collapse;font-size: 130%;border:none;"  colspan="5"> 
            <span style="text-align:left;display: inline-block;width: 42%;"> ${data.RemarkDate}  </span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 150%;border-top: none; border-left: none"  colspan="6"> 
            <span style="text-align:right;display: inline-block;width: 96%;"> ${data.RemarksSupersede} </span>
        </td>        
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
            <span style="text-align:left;display: inline-block;width: 42%;"> TOTAL =  </span>
            <span style="text-align:right;display: inline-block;width: 42%;"> $ ${data.R24_1C3} &divide G = </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
            <span style="text-align: right; display: inline-block; width: 85%;"> ${data.R24_1C4} &cent </span>
        </td>    
    </tr>

    <tr style="border: 1px solid black;border-collapse:collapse;">
        <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 120%;"  colspan="6" rowspan="3"> 
        <span style="text-align:left"> I, the undersigned, a duly authorized inspector of the United States Department of Agriculture, do hereby certify that, at the request of the applicant and on the date inspected indicated above, samples of the above described products were inspected and the quality and/or condition as shown by said samples were as herein stated. </span>
        </td>            
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 125%;"  colspan="2"> 
        <span style="text-align:left"> Applicant No. ${data.R25C4}</span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 125%;"  colspan="1">  
        <span style="text-align:left"> R. Unrecptd/Stored </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 125%;"  colspan="2"> 
        <span style="text-align:left"> Applicant No. </span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 125%;"  colspan="1"> 
        <span style="text-align:left"> S. Receipted </span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 125%;"  colspan="2"> 
        <span style="text-align:left">  Applicant No. ${data.R25C6}</span>     
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 125%;"  colspan="1"> 
        <span style="text-align:left">  T. Commercial </span>
        </td>     
    </tr>

    <tr style="border: 1px solid black; border-collapse:collapse;">          
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left;display: inline-block; width: 45%;"> LBS. (Line G)  </span>
        <span style="text-align:right;display: inline-block; width: 45%;">  ${data.R26_1C3} </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left; display: inline-block; width: 45%;"> LBS. (Line G) </span>        
        <span style="text-align:right; display: inline-block; width: 45%;">  </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left;display: inline-block; width: 45%;""> LBS. (Line G)  </span>
        <span style="text-align:right; display: inline-block; width: 100%;">  ${data.R26_1C4} </span>
        </td>      
    </tr>

    <tr style="border: 1px solid black;border-collapse:collapse;">          
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left;display: inline-block; width: 45%;"> x Line P = $ </span>
        <span style="text-align:right;display: inline-block; width: 45%;"> </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left;display: inline-block; width: 45%;"> x Line P = $ </span>
        <span style="text-align:right;display: inline-block; width: 45%;"> </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left;display: inline-block; width: 45%;"> x Line P = $ </span>
        <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R27C3} </span>
        </td>
    </tr>

    <tr style="border: 1px solid black;border-collapse:collapse;">
        <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="6" rowspan="2"> 
          <span style="text-align:left;display: inline-block; width: 45%;padding-top:2%;"> Signature of Inspector </br></br></br></span>
          <span style="text-align: right;display: inline-block; width: 45%;padding-top:2%;"> Date Signed </br></br></br></span>
          <span style="text-align:left;display: inline-block; width: 45%;">  ${data.R28C1_Name} </span>
          <span style="text-align: right;display: inline-block; width: 45%;">  ${data.R28C1_Date}</span>
        </td>            
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left;display: inline-block; width: 45%;"> x </b> </span>
        <span style="text-align:right;display: inline-block; width: 45%;"> % </b> </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left;display: inline-block; width: 45%;"> x </span>
        <span style="text-align:right;display: inline-block; width: 45%;"> % </b> </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left;display: inline-block; width: 45%;"> x </span>
        <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R28C3 == '' ? '' : Number(data.R28C3).toFixed(2)} % </b> </span>  
        </td>    

        
    </tr>

    <tr style="border: 1px solid black;border-collapse:collapse;">           
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left"> Value of Segment </br> = $ </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left"> Value of Segment </br> = $ </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left"> Value of Segment </br> = $ </span>
        <span style="text-align: right;display: inline-block; width: 100%;"> ${data.R28_1C2 == '' ? '' : Number(data.R28_1C2).toFixed(2)} </span>

        
        </td>    
    </tr>

    <tr style="border: 1px solid black;border-collapse:collapse;">
        <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 120%;"  colspan="4" > 
        <span style="text-align:left"> I, the undersigned, a duly licensed weigher, do hereby certify that this certificate is issued under the United States Warehouse Act and in accordance with the regulations thereunder </span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="2" > 
        <span style="text-align:left"> <b> Deductions </b> </br> 1. Peanut Promotion</span>
        </td>            
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;background-color: lightgrey;"  colspan="3"> 
        <span style="text-align:left"> </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;background-color: lightgrey;"  colspan="3"> 
        <span style="text-align:left"> </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left"> $ </span>
        <span style="text-align:right; display: inline-block; width: 100%;"> ${data.R29C5} </span>
        </td>    
    </tr>

    <tr style="border: 1px solid black;border-collapse:collapse;">
        <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 150%;"  colspan="4" rowspan="2" > 
        <span style="text-align:left"> Signature of Licensed Weigher </br></br> ${data.R30C1} </span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="2" > 
        <span style="text-align:left"> 2. Tax - Ser. Fee </span>
        </td>            
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left"> $ </span>
        <span style="text-align:right; display: inline-block; width: 90%;">  ${data.R30C3} </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left"> $  </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left"> $ </span>
        <span style="text-align:right; display: inline-block; width: 100%;"> ${data.R30C5} </span>
        </td>    
    </tr>

    <tr style="border: 1px solid black;border-collapse:collapse;">
        <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="2" > 
        <span style="text-align:left"> 3. ${data.R30_1C2} </span>
        </td>            
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left"> $ </span>
        <span style="text-align:right;display: inline-block; width: 90%;"> ${data.R30_1C3}</span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left"> $ </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left">  $  </span>
        <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R30_1C4} </span>
        </td>    
    </tr>
    

    <tr style="border: 1px solid black;border-collapse:collapse;">
        <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="4" > 
        <span style="text-align:left"> </br> ${data.R31C1} </span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="2" > 
        <span style="text-align:left"> 4. ${data.R31C3} </span>
        </td>            
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left"> $ </span>
        <span style="text-align:right;display: inline-block; width: 90%;"> ${data.R31C4} </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left"> $  </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left"> $  </span>
        <span style="text-align:right; display: inline-block; width: 100%;"> ${data.R31C5} </span>
        </td>    
    </tr>

    <tr style="border: 1px solid black;border-collapse:collapse;">
        <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="4" rowspan="2" > 
        <span style="text-align:left">  Date Signed </br></br> ${data.R32C1} </span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="2" > 
        <span style="text-align:left"> 5. ${data.R32C3}</span>
        </td>            
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left"> $ </span>
        <span style="text-align:right;display: inline-block; width: 90%;"> ${data.R32C4} </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left"> $ </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left"> $ </span>
        <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R32C5} </span>
        </td>    
    </tr>

    <tr style="border: 1px solid black;border-collapse:collapse;">
        <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="2" > 
        <span style="text-align:left"> 6.${data.R32_1C1}</span>
        </td>            
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left"> $ </span>
        <span style="text-align:right;display: inline-block; width: 90%;"> ${data.R32_1C2} </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left"> $ </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left"> $ </span>
        <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R32_1C3} </span>
        </td>    
    </tr>

    <tr style="border: 1px solid black;border-collapse:collapse;">
        <td style="width:5%;border: 1px solid black;border-collapse:collapse;border-bottom:none;font-size: 150%;"  colspan="4" rowspan="2"> 
        <span style="text-align:left"> <b> Q. REMARKS </b> </span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="2" > 
        <span style="text-align:left"> 7. Net Amount </span>
        </td>            
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left"> $ </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left"> $ </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left"> $ </span>
        <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R33C5} </span>
        </td>    
    </tr>

    <tr style="border: 1px solid black;border-collapse:collapse;border-bottom:none;">
        <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="11" > 
        <span style="text-align:left"> Date Delivered for Immediate Sale: &nbsp&nbsp&nbsp ${data.R33_1C1} </span>
        </td>   
    </tr>

    <tr style="border: 1px solid black;border-collapse:collapse;border-top:none;">
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 150%;border-top:none;"  colspan="15"> 
        <span style="text-align:left"> </br> United States Warehouse Act </br> Nut Weight Certificate </br> ${data.WarehouseDetail} ${data.QRemarks} </span>
        <span style="text-align:left;display: inline-block;width: 47%;"> Peanut Variety: ${data.PeanutVariety}</span>
        <span style="text-align:right;display: inline-block;width: 47%;"> ${data.BottomRightNum}</span>
    </td>   
    </tr>

    ${Footer}
    </table></html>`
}

const rptInspectionCertificate_onedoc = async (data, reciepentType) =>{
  let Action = data.type
  let Status = data.status
  let mbPrintSelected = false
  let mbPrintFirst = false
  let FV95number = data.SC95No
  let Settle_num = data.s1007No
  let TypIC = {}
  let MarrRemarksPremiumDeductions = ""

  let msPDF_SettlementNo = ''
  let msPDF_InspectionNo = ''
  let msPDF_BP = ''

  let lstrxml = ''
  let LstrXML_Rev = ''
  let LstrXMLContracts = ''
  let LstrXMLSeedContracts = ''
  let LstrXML_Q_Remarks = ''
  let LstrXML_Q_RemSeed = ''
  let LintVendorSplit_P = 0
  let LintVendorSplit_S = 0
  let LstrApplicantName = ''
  let colVendorPSArray = []
  let colVendorPSObj = {}
  let sPrevPremCode = ''
  let nIteration = 0
  let bDisplayTons = 0
  let sContract = ''
  let sSeedContract = ''
  let nVendorIndex = 0
  let sAdditionVendorInfo = ''
  let sGpcOwned = ''
  let Lstrfedname = ''
  let nQRemarkLines = 0
  let nQRemarksOnLine = 0
  let g = ''
  let nMinHistoryRev = 0
  let dblOptionPayment = 0
  let sSymbol = ''
  let LstrXML_Subs = []
  let LstrWtTicketNos_XML = []
  let LstrWtTicketNos = []
  let LstrVehicleNos = []
  let LblnPrint1007Storage = ''

  let sQContract = ""
  let sQVendor = ""
  let sQPounds = ""
  let sHoldContract = ""
  TypIC.Purch_LBS_LineG = ''
  TypIC.Purch_xLineP = 0
  TypIC.Purch_ValOfSegment = 0

  dblOptionPayment = 0
  TypIC.ReceipentType = reciepentType
  if(isNaN(FV95number) == false) {
    TypIC.ComputerSerial = FV95number
  }
  
  if(FV95number == null || FV95number == undefined || FV95number == '') {
    TypIC.msPDF_SettlementNo = Settle_num
    if(cropYearFromLS() < 2015) {
      TypIC.msPDF_InspectionNo = "NO_FV95"
    } 
    else {
      TypIC.msPDF_InspectionNo = "NO_SC95"
    }
    TypIC.ComputerSerial = "0".repeat(7 - Settle_num.length) + Settle_num
    TypIC.BuyingPoint_Location = ""
    let data = {}
    data.func_id = 'PN1060'
    data.settlement_num = Settle_num
    let FuncID = 'PN9000'
    
    lstrxml = await SettlementService.RetrieveInspectionHeaderDetails(data)
    
    if(lstrxml.length <= 0)
      // return;
    
    if (lstrxml != undefined && lstrxml != null && lstrxml.length > 0) {
      TypIC.msPDF_BP = lstrxml[0].buy_pt_id
      lstrxml = await SettlementService.RetrieveBuyingPointControlDetails(FuncID, null, null, null, TypIC.msPDF_BP)
      if (lstrxml != null && lstrxml != undefined && lstrxml != '') {
        TypIC.BuyingPoint_Location = lstrxml[0].fedBuyPtId
      }
    } 
    else {
      TypIC.msPDF_BP = "NO_LOC"
    }
  } 
  else{
    let nMinHistoryRev = 0
    if(Action == "REPRINT_FROM_HEADER"){
      LstrXML_Rev = await SettlementService.RetrieveInspectHeaderPrint1007Details(FV95number)
      if(LstrXML_Rev != null && LstrXML_Rev != undefined && LstrXML_Rev.length >0){
        if(isNaN(Settle_num)){
          Settle_num = LstrXML_Rev[0].settlement_num
        }
        nMinHistoryRev = LstrXML_Rev[0].min_history_rev
      }
    }

    if(Settle_num == ''){
      Settle_num = "0000000"
    }
    
    if(Status == 'VOID' && nMinHistoryRev >0){
      TypIC.RESIDUE_IND = ""
      lstrxml = await SettlementService.RetrieveInspectionHeaderVoidReprintList(FV95number,Settle_num,'1007',null,null)
    }
    else if (Status == "RESIDUE") {
      TypIC.RESIDUE_IND = "RESIDUE"
      lstrxml = await SettlementService.RetrieveInspectHeaderResidueDetails(null, FV95number)
    } 
    else {
      TypIC.RESIDUE_IND = ""
      lstrxml = await SettlementService.RetrieveInspectionHeaderList(FV95number, null, '1007', null)
      // if(lstrxml.length <= 0){
      //   goTo(thisObj, 'Settlements#FV95Search#DEFAULT#false#Click')
      //   return true;
      // }
    }

    // if(lstrxml != undefined && lstrxml != null && lstrxml != ''){
        TypIC.ContractList = ""
        TypIC.msPDF_SettlementNo = Settle_num
        TypIC.msPDF_InspectionNo = FV95number
        TypIC.msPDF_BP = lstrxml.length == 0 ? '' : lstrxml[0].buy_pt_id
        TypIC.WeightCentNumber = lstrxml.length == 0 ? '' : lstrxml[0].weight_cert_num

      if(TypIC.RESIDUE_IND !== ''){
        TypIC.TypeOfInspection = lstrxml[0].type_of_inspection
        TypIC.ContractList = ""
        TypIC.SeedContractList = ""
        TypIC.Q_Remarks_Seed = ""
      }
      else{
        let LstrXMLContracts = await ContractManagementService.RetrieveApplicationContractDetails(null, null, FV95number, null, null)
        if(LstrXMLContracts!=null && LstrXMLContracts!=undefined && LstrXMLContracts.length>0){
          let sContract = ""
            for(let i = 0; i < LstrXMLContracts.length; i++) {
              if(sContract !== LstrXMLContracts[i].contractNum){
                TypIC.ContractList = TypIC.ContractList + LstrXMLContracts[i].contractNum + ','
                sContract = LstrXMLContracts[i].contractNum
              }
            }
        }
        if (TypIC.ContractList.length > 0){
          TypIC.ContractList = TypIC.ContractList.slice(0, TypIC.ContractList.length - 1)
        }


        let LstrXMLSeedContracts = await ContractManagementService.RetrieveApplicationContractDetails('0', null, FV95number, null, null)
        if(LstrXMLSeedContracts!=null && LstrXMLSeedContracts!=undefined && LstrXMLSeedContracts.length >0){
          sSeedContract = ""
            for (let i = 0; i < LstrXMLSeedContracts.length; i++) {
              if(sSeedContract !== LstrXMLSeedContracts[i].contractNum){
                TypIC.SeedContractList = TypIC.SeedContractList + LstrXMLSeedContracts[i].contractNum + ','
                sSeedContract = LstrXMLSeedContracts[i].contractNum
              }
            }
          if((TypIC.SeedContractList).length >0){
            TypIC.SeedContractList = TypIC.SeedContractList.slice(0, TypIC.SeedContractList.length - 1)
          }
          LstrXML_Q_RemSeed = lstrxml[0].q_seed_remarks
          TypIC.Q_Remarks_Seed = ""
          for(let i =0; i<LstrXML_Q_RemSeed.length; i++){
          if(LstrXML_Q_RemSeed.q_seed_remark != 0){
            TypIC.Q_Remarks_Seed = TypIC.Q_Remarks_Seed + "SAgree: " + LstrXML_Q_RemSeed[i].contract_num + " Vendor: " + LstrXML_Q_RemSeed[i].split_vendor + " Lbs: " + LstrXML_Q_RemSeed[i].appl_lbs_adj + " "
          }}
        }
      }
      TypIC.SettlementDate = lstrxml.length == 0 ? '' :  lstrxml[0].settlement_date

      if(TypIC.RESIDUE_IND != '' && TypIC.RESIDUE_IND != null && TypIC.RESIDUE_IND != undefined ){
        if(TypIC.TypeOfInspection == '' || TypIC.TypeOfInspection == null || TypIC.TypeOfInspection == undefined){
          TypIC.RESIDUE_IND = "RESIDUE"
          TypIC.OperatorSellerName_COCode_FarmNo = "00-000-0000000-000" + "</br> NOT GIVEN"
        }
        else{
          TypIC.RESIDUE_IND = "RESALE"
          TypIC.OperatorSellerName_COCode_FarmNo = lstrxml[0].seller_num.slice(0, 5) + " " + lstrxml[0].seller_name
        }
      }
      else{
        TypIC.OperatorSellerName_COCode_FarmNo = lstrxml.length == 0 ? '' :  lstrxml[0].state_id + " " + lstrxml[0].county_id + " " + lstrxml[0].farm_id + " " + lstrxml[0].farm_suffix
      }
      TypIC.BuyingPoint_Location = lstrxml.length == 0 ? '' : lstrxml[0].buy_pt_fed_id + "   " + lstrxml[0].buy_pt_physical_city + ",  " + lstrxml[0].buy_pt_physical_state
      TypIC.AREA_ID = lstrxml.length == 0 ? '' : lstrxml[0].area_id
      TypIC.ComputerSerial = lstrxml.length == 0 ? '' : String(7 - (lstrxml[0].settlement_num.toString()).length) + lstrxml[0].settlement_num
      TypIC.BottomRightNumber = lstrxml.length == 0 ? '' : lstrxml[0].weight_cert_num
      TypIC.VCSerial = ""
      TypIC.VendorList = ""
      let bNeedsAttachment = false
      sAdditionVendorInfo = ""
      nVendorIndex = 1

      if(TypIC.RESIDUE_IND != '' && TypIC.RESIDUE_IND != null && TypIC.RESIDUE_IND != undefined){
        LstrXML_Subs = []
      }
      else{
        LstrXML_Subs = lstrxml.length == 0 ? [] : lstrxml[0].inspect_vendor_splits
      }

      TypIC.ProducerName1 = ''
      TypIC.ProducerName2 = ''
      TypIC.ProducerName3 = ''
      TypIC.ProducerName4 = ''
      
      TypIC.Share_Percentage1 = ''
      TypIC.Share_Percentage2 = ''
      TypIC.Share_Percentage3 = ''
      TypIC.Share_Percentage4 = ''
      TypIC.Purch_NetAmount  = ''
      for(var i=0; i<LstrXML_Subs.length; i++){
        switch(nVendorIndex.toString()){
          case "1":
            TypIC.ProducerName1 = LstrXML_Subs[i].vendor_name
            if(LstrXML_Subs[i].split_vendor != '' && LstrXML_Subs[i].split_vendor != null && LstrXML_Subs[i].split_vendor != undefined){
              TypIC.VendorList = TypIC.VendorList + LstrXML_Subs[i].split_vendor
            }
            if(LstrXML_Subs[i].split_share_pct != '' && LstrXML_Subs[i].split_share_pct != null && LstrXML_Subs[i].split_share_pct != undefined){
              TypIC.Share_Percentage1 = Number(LstrXML_Subs[i].split_share_pct).toFixed(3)
            }
            else{
              TypIC.Share_Percentage1 = ""
            }
            if(LstrXML_Subs[i].purh_stor_ind == "P"){
              TypIC.Purch_LBS_LineG = Number(TypIC.Purch_LBS_LineG) + Number(LstrXML_Subs[i].net_wt)
              TypIC.Purch_xLineP += Number(LstrXML_Subs[i].basis_grade_amt)
              TypIC.Purch_xPercentage = LstrXML_Subs[i].support_pct
              TypIC.Purch_ValOfSegment = Number(TypIC.Purch_ValOfSegment) + Number(LstrXML_Subs[i].value_of_seg) - Number(LstrXML_Subs[i].opt_value_of_seg)
              dblOptionPayment = dblOptionPayment + Number(LstrXML_Subs[i].opt_value_of_seg)
              TypIC.Purch_NetAmount = Number(TypIC.Purch_NetAmount == undefined ? 0 : TypIC.Purch_NetAmount) + Number(LstrXML_Subs[i].proceeds_amt)
              LintVendorSplit_P = LintVendorSplit_P + 1
            }
            else if(LstrXML_Subs[i].purh_stor_ind == "S"){
              TypIC.Store_LBS_LineG = LstrXML_Subs[i].net_wt
              TypIC.Store_xLineP = LstrXML_Subs[i].basis_grade_amt
              TypIC.Store_xPercentage = LstrXML_Subs[i].support_pct
              TypIC.Store_ValOfSegment = Number(LstrXML_Subs[i].value_of_seg) - Number(LstrXML_Subs[i].opt_value_of_seg)
              dblOptionPayment = dblOptionPayment + Number(LstrXML_Subs[i].opt_value_of_seg)
              LintVendorSplit_S = LintVendorSplit_S + 1
            }
            break;
          
          case "2":
            TypIC.ProducerName2 = LstrXML_Subs[i].vendor_name
            if(LstrXML_Subs[i].split_vendor != '' && LstrXML_Subs[i].split_vendor != null && LstrXML_Subs[i].split_vendor != undefined){
              TypIC.VendorList = TypIC.VendorList & ", " + LstrXML_Subs[i].split_vendor
            }
            if(LstrXML_Subs[i].split_share_pct != '' && LstrXML_Subs[i].split_share_pct != null && LstrXML_Subs[i].split_share_pct != undefined){
              TypIC.Share_Percentage2 = (Number(LstrXML_Subs[i].split_share_pct)).toFixed(3)
            }
            else{
              TypIC.Share_Percentage2 = ""
            }
            if(LstrXML_Subs[i].purh_stor_ind == "P"){
              TypIC.Purch_LBS_LineG = Number(TypIC.Purch_LBS_LineG) + Number(LstrXML_Subs[i].net_wt)
              TypIC.Purch_xLineP += Number(LstrXML_Subs[i].basis_grade_amt)
              TypIC.Purch_xPercentage = LstrXML_Subs[i].support_pct
              TypIC.Purch_ValOfSegment = Number(TypIC.Purch_ValOfSegment) + Number(LstrXML_Subs[i].value_of_seg) - Number(LstrXML_Subs[i].opt_value_of_seg)
              TypIC.Purch_NetAmount = Number(TypIC.Purch_NetAmount == undefined ? 0 : TypIC.Purch_NetAmount) + Number(LstrXML_Subs[i].proceeds_amt)
              dblOptionPayment = dblOptionPayment + Number(LstrXML_Subs[i].opt_value_of_seg)
              LintVendorSplit_P = LintVendorSplit_P + 1
            }
            else if(LstrXML_Subs[i].purh_stor_ind == "S"){
              TypIC.Store_LBS_LineG = Number(TypIC.Store_LBS_LineG == undefined ? 0 :TypIC.Store_LBS_LineG) + Number(LstrXML_Subs[i].net_wt)
              TypIC.Store_xLineP = Number(TypIC.Store_xLineP) + Number(LstrXML_Subs[i].basis_grade_amt)
              TypIC.Store_xPercentage = Number(LstrXML_Subs[i].support_pct)
              TypIC.Store_ValOfSegment = Number(TypIC.Store_ValOfSegment) + Number(LstrXML_Subs[i].value_of_seg) - Number(LstrXML_Subs[i].opt_value_of_seg)
              dblOptionPayment = dblOptionPayment + Number(LstrXML_Subs[i].opt_value_of_seg)
              LintVendorSplit_S = LintVendorSplit_S + 1
            }
            break;          
          case "3":
            TypIC.ProducerName3 = LstrXML_Subs[i].vendor_name
            if(LstrXML_Subs[i].split_vendor != '' && LstrXML_Subs[i].split_vendor != null && LstrXML_Subs[i].split_vendor != undefined){
              TypIC.VendorList = TypIC.VendorList + ", " + LstrXML_Subs[i].split_vendor
            }
            if(LstrXML_Subs[i].split_share_pct != '' && LstrXML_Subs[i].split_share_pct != null && LstrXML_Subs[i].split_share_pct != undefined){
              TypIC.Share_Percentage3 = (Number(LstrXML_Subs[i].split_share_pct)).toFixed(3)
            }
            else{
              TypIC.Share_Percentage3 = ""
            }
            if(LstrXML_Subs[i].purh_stor_ind == "P"){
              TypIC.Purch_LBS_LineG = Number(TypIC.Purch_LBS_LineG) + Number(LstrXML_Subs[i].net_wt)
              TypIC.Purch_xLineP += Number(LstrXML_Subs[i].basis_grade_amt)
              TypIC.Purch_xPercentage = Number(LstrXML_Subs[i].support_pct)
              TypIC.Purch_ValOfSegment = Number(TypIC.Purch_ValOfSegment) + Number(LstrXML_Subs[i].value_of_seg) - Number(LstrXML_Subs[i].opt_value_of_seg)//  Val(GetInside(LstrXML_Subs[i], "<value_of_seg>", "</value_of_seg>")) - Val(GetInside(LstrXML_Subs, "<opt_value_of_seg>", "</opt_value_of_seg>"))
              dblOptionPayment = dblOptionPayment + Number(LstrXML_Subs[i].opt_value_of_seg)
              TypIC.Purch_NetAmount = Number(TypIC.Purch_NetAmount == undefined ? 0 : TypIC.Purch_NetAmount) + Number(LstrXML_Subs[i].proceeds_amt)
              LintVendorSplit_P = LintVendorSplit_P + 1
            }
            else if(LstrXML_Subs[i].purh_stor_ind == "S"){
              TypIC.Store_LBS_LineG = Number(TypIC.Store_LBS_LineG == undefined ? 0 :TypIC.Store_LBS_LineG) + Number(LstrXML_Subs[i].net_wt)
              TypIC.Store_xLineP = Number(TypIC.Store_xLineP) + Number(LstrXML_Subs[i].basis_grade_amt)
              TypIC.Store_xPercentage = Number(LstrXML_Subs[i].support_pct)
              TypIC.Store_ValOfSegment = Number(TypIC.Store_ValOfSegment) + Number(LstrXML_Subs[i].value_of_seg) - Number(LstrXML_Subs[i].opt_value_of_seg)
              dblOptionPayment = dblOptionPayment + LstrXML_Subs[i].opt_value_of_seg
              LintVendorSplit_S = LintVendorSplit_S + 1
            }
            break;
          case "4":
            TypIC.ProducerName4 = LstrXML_Subs[i].vendor_name
            if(LstrXML_Subs[i].split_vendor != '' && LstrXML_Subs[i].split_vendor != null && LstrXML_Subs[i].split_vendor != undefined){
              TypIC.VendorList = TypIC.VendorList + ", " + LstrXML_Subs.split_vendor
            }
            if(LstrXML_Subs[i].split_share_pct != '' && LstrXML_Subs[i].split_share_pct != null && LstrXML_Subs[i].split_share_pct != undefined){
              TypIC.Share_Percentage4 = (Number(LstrXML_Subs[i].split_share_pct)).toFixed(3)
            }
            else{
              TypIC.Share_Percentage4 = ""
            }
            if(LstrXML_Subs[i].purh_stor_ind != '' && LstrXML_Subs[i].purh_stor_ind != null && LstrXML_Subs[i].purh_stor_ind != undefined){
              TypIC.Purch_LBS_LineG = Number(TypIC.Purch_LBS_LineG) + Number(LstrXML_Subs[i].net_wt)
              TypIC.Purch_xLineP += Number(LstrXML_Subs[i].basis_grade_amt)
              TypIC.Purch_xPercentage = Number(LstrXML_Subs[i].support_pct)
              TypIC.Purch_ValOfSegment = Number(TypIC.Purch_ValOfSegment) + Number(LstrXML_Subs[i].value_of_seg) - Number(LstrXML_Subs[i].opt_value_of_seg)
              dblOptionPayment = dblOptionPayment + Number(LstrXML_Subs[i].opt_value_of_seg)
              TypIC.Purch_NetAmount = Number(TypIC.Purch_NetAmount == undefined ? 0 : TypIC.Purch_NetAmount) + Number(LstrXML_Subs[i].proceeds_amt)
              LintVendorSplit_P = LintVendorSplit_P + 1
            }
            else if(LstrXML_Subs[i].purh_stor_ind == "S"){
              TypIC.Store_LBS_LineG = Number(TypIC.Store_LBS_LineG == undefined ? 0 :TypIC.Store_LBS_LineG) + Number(LstrXML_Subs[i].net_wt)
              TypIC.Store_xLineP = Number(TypIC.Store_xLineP) + Number(LstrXML_Subs[i].basis_grade_amt)
              TypIC.Store_xPercentage = LstrXML_Subs[i].support_pct
              TypIC.Store_ValOfSegment = Number(TypIC.Store_ValOfSegment) + Number(LstrXML_Subs[i].value_of_seg) - Number(LstrXML_Subs[i].opt_value_of_seg)
              dblOptionPayment = dblOptionPayment + Number(LstrXML_Subs[i].opt_value_of_seg)
              LintVendorSplit_S = LintVendorSplit_S + 1
            }
            break;          
          default:
            bNeedsAttachment = true
            if(LstrXML_Subs[i].split_vendor != '' && LstrXML_Subs[i].split_vendor != null && LstrXML_Subs[i].split_vendor != undefined){
              TypIC.VendorList = TypIC.VendorList + ", " + LstrXML_Subs[i].split_vendor
            }
            sAdditionVendorInfo = sAdditionVendorInfo + LstrXML_Subs[i].vendor_name + "," + LstrXML_Subs[i].split_share_pct + "," + LstrXML_Subs[i].split_vendor + "|"
            if(LstrXML_Subs.purh_stor_ind == "P"){
              TypIC.Purch_LBS_LineG = Number(TypIC.Purch_LBS_LineG) + Number(LstrXML_Subs[i].net_wt)
              TypIC.Purch_xLineP += Number(LstrXML_Subs[i].basis_grade_amt)
              TypIC.Purch_xPercentage = Number(LstrXML_Subs[i].support_pct)
              TypIC.Purch_ValOfSegment = Number(TypIC.Purch_ValOfSegment) + Number(LstrXML_Subs[i].value_of_seg) - Number(LstrXML_Subs[i].opt_value_of_seg)
              dblOptionPayment = dblOptionPayment + Number(LstrXML_Subs[i].opt_value_of_seg)
              TypIC.Purch_NetAmount = Number(TypIC.Purch_NetAmount == undefined ? 0 : TypIC.Purch_NetAmount) + Number(LstrXML_Subs[i].proceeds_amt)
              LintVendorSplit_P = LintVendorSplit_P + 1
            }
            else if(LstrXML_Subs[i].purh_stor_ind == "S"){
              TypIC.Store_LBS_LineG = Number(TypIC.Store_LBS_LineG == undefined ? 0 :TypIC.Store_LBS_LineG) + Number(LstrXML_Subs[i].net_wt)
              TypIC.Store_xLineP = Number(TypIC.Store_xLineP) + Number(LstrXML_Subs[i].basis_grade_amt)
              TypIC.Store_xPercentage = LstrXML_Subs[i].support_pct
              TypIC.Store_ValOfSegment = Number(TypIC.Store_ValOfSegment) + Number(LstrXML_Subs[i].value_of_seg) - Number(LstrXML_Subs[i].opt_value_of_seg)
              dblOptionPayment = dblOptionPayment + Number(LstrXML_Subs[i].opt_value_of_seg)
              LintVendorSplit_S = LintVendorSplit_S + 1
            }
            break;
        }
        nVendorIndex++
      }

      if(TypIC.RESIDUE_IND != '' && TypIC.RESIDUE_IND != undefined && TypIC.RESIDUE_IND != null){
        TypIC.L13_Fed_Whs_Name = ""
        TypIC.DeliveryPoint_IF_NOT_SAME_AS_WHSE = ""
        TypIC.RemarksGovtVerbage1 = ""
        TypIC.RemarksGovtVerbage2 = ""
        TypIC.RemarksGovtVerbage3 = ""
      }
      else{
        TypIC.RemarksGovtVerbage1 = "United States Warehouse Act <br> Nut Weight Certificate"
        Lstrfedname = await SettlementService.RetrieveFedWareHouseLicenseNameDetails(null, null)
        // if(Lstrfedname.length <=0)
          // return;
        
        for(var i=0; i<Lstrfedname.length; i++){
          TypIC.L13_Fed_Whs_Name = Lstrfedname[i].fed_whs_lic_name.trim()
        }
        let value1 = lstrxml.length == 0 ? '' : lstrxml[0].buy_pt_id + '-' + lstrxml[0].unld_whse_id + '-' + lstrxml[0].unld_bin_id
        let value2 = lstrxml.length == 0 ? '' :lstrxml[0].obl_buy_id + '-' + lstrxml[0].obl_whse_id + '-' + lstrxml[0].obl_bin_id

        if(value1 !== value2){
          LstrXML_Subs = await SettlementService.RetrieveBuyingPointControlDetails('PN9000', null, null, null, lstrxml[0].obl_buy_id)
          if(LstrXML_Subs.length > 0){
            TypIC.DeliveryPoint_IF_NOT_SAME_AS_WHSE = LstrXML_Subs[0].buyPtName
            sGpcOwned = LstrXML_Subs[0].gpcOwned
            if(sGpcOwned == 'Y'){
              TypIC.RemarksGovtVerbage2 = LstrXML_Subs[0].buyPtId + " - " +  LstrXML_Subs[0].physicalCity +  ", " + LstrXML_Subs[0].physicalState
            }
            else{
              TypIC.RemarksGovtVerbage2 =LstrXML_Subs[0].buyPtId + " - " + LstrXML_Subs[0].buyPtName +  ", " + LstrXML_Subs[0].physicalCity + ", " + LstrXML_Subs[0].physicalState
            }
          }
        }
        else{
          let BuyPt = lstrxml.length == 0 ? '' : lstrxml[0].buy_pt_id
          LstrXML_Subs = await SettlementService.RetrieveBuyingPointControlDetails('PN9000', null, null, null, BuyPt)
          // if(LstrXML_Subs.length <= 0)
          //   return;
          // TypIC.DeliveryPoint_IF_NOT_SAME_AS_WHSE = ''
          sGpcOwned = LstrXML_Subs.length == 0 ? '' : LstrXML_Subs[0].gpcOwned
          if(sGpcOwned == 'Y'){
            TypIC.RemarksGovtVerbage2 = lstrxml.length == 0 ? '' : lstrxml[0].buy_pt_id + " - " +  lstrxml[0].buy_pt_physical_city +  ", " + lstrxml[0].buy_pt_physical_state
          }
          else{
            TypIC.RemarksGovtVerbage2 = lstrxml.length == 0 ? '' : lstrxml[0].buy_pt_id + " - " + lstrxml[0].buy_pt_nLdblDataRowsame +  ", " + lstrxml[0].buy_pt_physical_city + ", " + lstrxml[0].buy_pt_physical_state
          }
        }

        TypIC.RemarksGovtVerbage3 = "This Certificate is issued by a weigher licensed under the United States Warehouse Act and the regulations thereunder."
      }
      
      let PnutTypeID = lstrxml.length == 0 ? '' : lstrxml[0].pnut_type_id
      LstrXML_Subs = await ContractManagementService.RetrievePeanutPriceScheduleDetails(TypIC.AREA_ID, PnutTypeID)
      if(LstrXML_Subs != undefined && LstrXML_Subs != null && LstrXML_Subs.length > 0){
        TypIC.lsk_per_pounds = LstrXML_Subs[9].strFieldData
      }

      let LstrApplicantName = await ContractManagementService.RetrieveCompanyDetails(compIdFromLS())
      if(LstrApplicantName.length >0){
        LstrApplicantName = LstrApplicantName[0].compName
      }

      LstrXML_Subs = await SettlementService.RetrieveAreaControlDetails(TypIC.AREA_ID)
      if(LstrXML_Subs[0].displayLbsTon == 'P'){
        bDisplayTons = false
      }
      else{
        bDisplayTons = true
      }       

      if(TypIC.RESIDUE_IND == "RESIDUE"){
        TypIC.TypeOfInspection = ""
        TypIC.Applicant_01_Name_Number = Number(lstrxml[0].handler_loc_num_2) + " " + lstrxml[0].handler_loc_name_2
        TypIC.Applicant_02_Name_Number = ""
        TypIC.Applicant_03_Name_Number = ""
      }
      else if(TypIC.RESIDUE_IND == "RESALE"){
        TypIC.TypeOfInspection = "Resale"
        TypIC.Applicant_01_Name_Number = Number(lstrxml[0].handler_loc_num_2) + " " + lstrxml[0].handler_loc_name_2
        TypIC.Applicant_02_Name_Number = ""
        TypIC.Applicant_03_Name_Number = ""
      }
      else{
        TypIC.TypeOfInspection = ""
        TypIC.Applicant_01_Name_Number = Number(LstrXML_Subs[0].handlerLocNum) + " " + LstrApplicantName
        TypIC.Applicant_02_Name_Number = ""
        TypIC.Applicant_03_Name_Number = ""
      }

      if(TypIC.RESIDUE_IND == ""){TypIC.TypeStorage = "COMM."}
      TypIC.ConvertedPenaltyRate = ""
      TypIC.NoOfBags = ""
      TypIC.Bulk = "X"

    if(lstrxml.length >0){
      TypIC.QuotaBalanceAfterThisSale = ""
      TypIC.ContractBalanceAfterThisSale = ""
      TypIC.DateInspected = lstrxml[0].insp_date_time
      TypIC.whseid = lstrxml[0].unld_whse_id == undefined ? '' : lstrxml[0].unld_whse_id
      TypIC.binid = lstrxml[0].unld_bin_id == undefined ? '' : lstrxml[0].unld_bin_id
      TypIC.WarehouseDesc_BinNo = TypIC.whseid + "-" + TypIC.binid
      TypIC.InspectionMemoNo_FV_95 = lstrxml[0].insp_num
      
      TypIC.Type = lstrxml[0].pnut_type_id
      TypIC.SMK_GR = lstrxml[0].smk_gr
      TypIC.SS_GR = lstrxml[0].ss_gr
      TypIC.OK_GR = lstrxml[0].ok_gr
      TypIC.DAM_SPLIT_GR = lstrxml[0].dam_split_gr
      TypIC.DAM_GR = lstrxml[0].dam_gr
      TypIC.HULL_GR = lstrxml[0].hull_gr
      TypIC.FREZ_DAM_GR = lstrxml[0].frez_dam_gr
      TypIC.CNCL_RMD_GR = lstrxml[0].cncl_rmd_gr
      TypIC.CR_BR_GR = lstrxml[0].cr_br_gr
      TypIC.DCOL_SHEL_GR = lstrxml[0].dcol_shel_gr
      TypIC.HUL_BRIT_GR = lstrxml[0].hul_brit_gr
      TypIC.JUMBO_GR = lstrxml[0].jumbo_gr
      TypIC.ELK_GR = lstrxml[0].elk_gr
      TypIC.FM_GR = lstrxml[0].fm_gr
      TypIC.LSK_GR = lstrxml[0].lsk_gr
      TypIC.FAN_GR = lstrxml[0].fan_gr
      TypIC.TOT_KRS_WT = lstrxml[0].tot_krs_wt
      TypIC.FM_SAMPLE = lstrxml[0].fm_sample
      TypIC.CLN_SAMPLE = lstrxml[0].cln_sample
      TypIC.VL_CLN_SAMPLE = lstrxml[0].vl_cln_sample
      TypIC.OTH_FM = lstrxml[0].oth_fm
      TypIC.FLAVUS_IND = lstrxml[0].flavus_ind
      TypIC.BLUEPAN_WT = lstrxml[0].bluepan_wt
      TypIC.METER_READ = lstrxml[0].meter_read
      TypIC.KERNEL_RPS = lstrxml[0].kernel_rps
      TypIC.KERNEL_RELS = lstrxml[0].kernel_rels
      TypIC.DAMAGED_KRS = lstrxml[0].damaged_krs
      TypIC.ELK_DAMAGE = lstrxml[0].elk_damaged
      TypIC.IRR_DRY_IND = lstrxml[0].irr_dry_ind
      TypIC.CropYear = lstrxml[0].crop_year
      TypIC.Time = lstrxml[0].insp_date_time
      TypIC.ProbingPattern = lstrxml[0].probe_pattern
      TypIC.Dirt = ""
      TypIC.Sticks = ""
      TypIC.Rocks = ""
      TypIC.TrashRaisins = ""
      TypIC.OtherSpecify = ""

      if (lstrxml[0].hmoist_pct == ""){
        TypIC.Seg = lstrxml[0].seg_type
        if(lstrxml[0].fm_pct == ''){
          TypIC.ForiegnMaterial = 0
        }
        else{
          TypIC.ForiegnMaterial = lstrxml[0].fm_pct
        }
        if(lstrxml[0].cr_br_pct == ""){
          TypIC.CrackedBrokenShells = 0
        }
        else{
          TypIC.CrackedBrokenShells = lstrxml[0].cr_br_pct
        }
        if(lstrxml[0].dcol_shel_pct == ""){
          TypIC.DiscoloredShells = 0
        }
        else{
          TypIC.DiscoloredShells = lstrxml[0].dcol_shel_pct
        }
        if(lstrxml[0].lsk_pct == ""){
          TypIC.LSK = 0
        }
        else{
          TypIC.LSK = lstrxml[0].lsk_pct
        }
        if(lstrxml[0].fan_pct == ''){
          TypIC.Fancy = 0
        }        
        else{
          TypIC.Fancy = lstrxml[0].fan_pct
        }
        if(lstrxml[0].moist_pct == ""){
          TypIC.Moisture = 0
        }
        else{
          TypIC.Moisture = lstrxml[0].moist_pct
        }
        if(lstrxml[0].elk_pct == ''){
          TypIC.ELK = 0
        } 
        else{
          TypIC.ELK = lstrxml[0].elk_pct
        }
        if(lstrxml[0].smk_pct == ""){
          TypIC.SMK = 0
        }
        else{
          TypIC.SMK = lstrxml[0].smk_pct
        }
        if(lstrxml[0].ss_pct == ""){
          TypIC.SoundSplits = 0
        }
        else{
          TypIC.SoundSplits = lstrxml[0].ss_pct
        }
        
        TypIC.Total_SMK_SoundSplits = Number(TypIC.SMK) + Number(TypIC.SoundSplits)
        
        if(lstrxml[0].frez_dam_pct == ""){
          TypIC.FreezeDamage = "0.00"
        }
        else{
          TypIC.FreezeDamage = lstrxml[0].frez_dam_pct
        }
        if(lstrxml[0].ok_pct == ''){
          TypIC.OtherKernals = 0
        }
        else{
          TypIC.OtherKernals = lstrxml[0].ok_pct
        }
        if(lstrxml[0].cncl_rmd_pct == ""){
          TypIC.ConcealedRMD = "0.00"
        }
        else{
          TypIC.ConcealedRMD = lstrxml[0].cncl_rmd_pct
        }
        if(lstrxml[0].dam_pct == ""){
          TypIC.Damage = 0
        }
        else{
          TypIC.Damage = lstrxml[0].dam_pct
        }


        
        TypIC.TotalKernals = Number(TypIC.SMK) + Number(TypIC.SoundSplits) + Number(TypIC.OtherKernals) + Number(TypIC.Damage)

        if(lstrxml[0].hull_pct == ""){
          TypIC.Hulls = 0
        }
        else{
          TypIC.Hulls = lstrxml[0].hull_pct
        }

        TypIC.Total_Kernals_Hulls = Number(TypIC.TotalKernals) + Number(TypIC.Hulls)

      }
      else{
        TypIC.Seg = lstrxml[0].hmc_seg
        TypIC.HMC_SEG = lstrxml[0].seg_type
        if(lstrxml[0].hfm_pct == ""){
          TypIC.ForiegnMaterial = 0
        }
        else{
          TypIC.ForiegnMaterial = lstrxml[0].hfm_pct
        }

        if(lstrxml[0].fm_pct == ""){
          TypIC.HMC_FM_PCT = 0
        }
        else{
          TypIC.HMC_FM_PCT = lstrxml[0].fm_pct
        }

        if(lstrxml[0].cr_br_pct == ""){
          TypIC.CrackedBrokenShells = 0
        }
        else{
          TypIC.CrackedBrokenShells = lstrxml[0].cr_br_pct
        }
        if(lstrxml[0].dcol_shel_pct == ''){
          TypIC.DiscoloredShells = 0
        }
        else{
          TypIC.DiscoloredShells = lstrxml[0].dcol_shel_pct
        }
        if(lstrxml[0].hlsk_pct == ""){
          TypIC.LSK = 0
        }
        else{
          TypIC.LSK =lstrxml[0].hlsk_pct
        }
        if(lstrxml[0].lsk_pct == ""){
          TypIC.HMC_LSK_PCT = 0
        }
        else{
          TypIC.HMC_LSK_PCT = lstrxml[0].lsk_pct
        }
        if(lstrxml[0].hfan_pct == ''){
          TypIC.Fancy = 0
        }
        else{
          TypIC.Fancy = lstrxml[0].hfan_pct
        }
        if(lstrxml[0].fan_pct == ""){
          TypIC.HMC_FANCY_PCT = 0
        }
        else{
          TypIC.HMC_FANCY_PCT = lstrxml[0].fan_pct
        }
        if(lstrxml[0].hmoist_pct== ""){
          TypIC.Moisture = 0
        }
        else{
          TypIC.Moisture = lstrxml[0].hmoist_pct
        }
        if(lstrxml[0].moist_pct == ""){
          TypIC.HMC_MOIST = 0
        }
        else{
          TypIC.HMC_MOIST = lstrxml[0].moist_pct
        }
        if(lstrxml[0].helk_pct == ''){
          TypIC.ELK = 0
        }
        else{
          TypIC.ELK = lstrxml[0].helk_pct
        }
        if(lstrxml[0].elk_pct == ''){
          TypIC.HMC_ELK_PCT = 0
        }
        else{
          TypIC.HMC_ELK_PCT = lstrxml[0].elk_pct
        }
        if(lstrxml[0].hsmk_pct == ''){
          TypIC.SMK = 0
        }
        else{
          TypIC.SMK = lstrxml[0].hsmk_pct
        }
        if(lstrxml[0].smk_pct ==''){
          TypIC.HMC_SMK_PCT = 0
        }
        else{
          TypIC.HMC_SMK_PCT = lstrxml[0].smk_pct
        }
        if(lstrxml[0].hss_pct == ''){
          TypIC.SoundSplits = 0
        }
        else{
          TypIC.SoundSplits = lstrxml[0].hss_pct
        }
        if(lstrxml[0].ss_pct == ''){
          TypIC.HMC_SS_PCT = 0
        }
        else{
          TypIC.HMC_SS_PCT = lstrxml[0].ss_pct
        }

        
        TypIC.Total_SMK_SoundSplits = Number(TypIC.SMK) + Number(TypIC.SoundSplits)
        TypIC.HMC_SMKRS_PCT = Number(TypIC.HMC_SMK_PCT) + Number(TypIC.HMC_SS_PCT)
                
        if(lstrxml[0].frez_dam_pct == ''){
          TypIC.FreezeDamage = "0.00"
          TypIC.HMC_FRZ_PCT = "0.00"
        }
        else{
          TypIC.FreezeDamage = lstrxml[0].frez_dam_pct
          TypIC.HMC_FRZ_PCT = lstrxml[0].frez_dam_pct
        }
        if(lstrxml[0].hok_pct == ''){
          TypIC.OtherKernals = 0
        }
        else{
          TypIC.OtherKernals = lstrxml[0].hok_pct
        }
        if(lstrxml[0].ok_pct == ''){
          TypIC.HMC_OK_PCT = 0
        }
        else{
          TypIC.HMC_OK_PCT = lstrxml[0].ok_pct
        }
        if(lstrxml[0].cncl_rmd_pct == ''){
          TypIC.ConcealedRMD = "0.00"
          TypIC.HMC_CRMD_PCT = "0.00"
        }
        else{
          TypIC.ConcealedRMD = lstrxml[0].cncl_rmd_pct
          TypIC.HMC_CRMD_PCT = lstrxml[0].cncl_rmd_pct
        }
        if(lstrxml[0].hdam_pct == ''){
          TypIC.Damage = 0
        }
        else{
          TypIC.Damage = lstrxml[0].hdam_pct
        }
        if(lstrxml[0].dam_pct == ''){
          TypIC.HMC_DAM_PCT = 0
        }
        else{
          TypIC.HMC_DAM_PCT = lstrxml[0].dam_pct
        }

      
        TypIC.TotalKernals = Number(TypIC.SMK) + Number(TypIC.SoundSplits) + Number(TypIC.OtherKernals) + Number(TypIC.Damage)
        TypIC.HMC_TOTKERN_PCT = Number(TypIC.HMC_SMK_PCT) + Number(TypIC.HMC_SS_PCT) + Number(TypIC.HMC_OK_PCT) + Number(TypIC.HMC_DAM_PCT)
                
        if(lstrxml[0].hhull_pct == ''){
          TypIC.Hulls = 0
        }
        else{
          TypIC.Hulls = lstrxml[0].hhull_pct
        }
        if(lstrxml[0].hull_pct == ''){
          TypIC.HMC_HULL_PCT = 0
        }
        else{
          TypIC.HMC_HULL_PCT = lstrxml[0].hull_pct
        }

        TypIC.Total_Kernals_Hulls = Number(TypIC.TotalKernals) + Number(TypIC.Hulls)
        TypIC.HMC_Total_Kernals_Hulls = Number(TypIC.HMC_TOTKERN_PCT) + Number(TypIC.HMC_HULL_PCT)
      }

      TypIC.VA_CrackedBroken = lstrxml[0].va_cr_br_pct
      
      //LstrWtTicketNos_XML = lstrxml[0].inspect_scale_tickets.length == 0 ? [] : lstrxml[0].inspect_scale_tickets//[0].inspect_scale_ticket
      if(lstrxml[0].inspect_scale_tickets.length == 0){
        LstrWtTicketNos_XML = [{
          dry_ticket     : '',
          dry_vehicle_id : '',
          weigher_name   : ''
        }]
      }
      else if(lstrxml[0].inspect_scale_tickets[0].inspect_scale_ticket != undefined){
        LstrWtTicketNos_XML = lstrxml[0].inspect_scale_tickets[0].inspect_scale_ticket
      }
      else{
        LstrWtTicketNos_XML = lstrxml[0].inspect_scale_tickets
      }
    }
      
      LstrWtTicketNos = LstrWtTicketNos_XML.length == 0 ? '' : LstrWtTicketNos_XML[0].dry_ticket
      LstrVehicleNos = LstrWtTicketNos_XML.length == 0 ? '' : LstrWtTicketNos_XML[0].dry_vehicle_id
      TypIC.Signiture = LstrWtTicketNos_XML.length == 0 ? '' : LstrWtTicketNos_XML[0].weigher_name
      
      let Len = (TypIC.Signiture).length
      for (var i = Len - 1 ; i >= 0; i--) {
        if (TypIC.Signiture != "") {
          let s = TypIC.Signiture[i]
          if (isNaN(s)) {
            TypIC.WeigherName = TypIC.Signiture.slice(0, i+1)
            TypIC.WeigherLicense = TypIC.Signiture.slice(i+1,Len)
            break;
          }
        }
      }
      
      TypIC.WeigherName = TypIC.WeigherName == undefined ? '' : TypIC.WeigherName.replace('#', '')
      TypIC.WeigherName = TypIC.WeigherName == undefined ? '' : TypIC.WeigherName.replace('-', '').trim()
            
      if(LstrWtTicketNos_XML.length >= 1){
        for(var i =1; i <LstrWtTicketNos_XML.length; i++){         
          if(LstrWtTicketNos_XML[i].dry_ticket != ''){
            LstrWtTicketNos = LstrWtTicketNos + ", " + LstrWtTicketNos_XML[i].dry_ticket
          }
          if(LstrWtTicketNos_XML[i].dry_vehicle_id != ''){
            LstrVehicleNos = LstrVehicleNos + ", " + LstrWtTicketNos_XML[i].dry_vehicle_id
          }
        }
      }
      
      if(lstrxml.length > 0 && lstrxml != undefined){
        if((lstrxml[0].elec_upload == 'Y') && (lstrxml[0].scale_list != ''))
        LstrWtTicketNos = lstrxml[0].scale_list

        TypIC.WeightTicketNo = LstrWtTicketNos
        TypIC.VehicleNo = LstrVehicleNos
          
        TypIC.RemarksDate = TypIC.AREA_ID == 'SW' ? lstrxml[0].insp_date_time : lstrxml[0].settlement_date
        TypIC.Remarks = lstrxml[0].remarks

        if(TypIC.Seg == "1"){
          switch (lstrxml[0].seed_gen){
            case "C":
              TypIC.RemarksSeed = "Applicant States Certified Seed"
              break;
            case "F":
              TypIC.RemarksSeed = "Applicant States Foundation Seed"
              break;
            case "R":
              TypIC.RemarksSeed = "Applicant States Registered Seed"
              break;
            default:
              TypIC.RemarksSeed = ""
          }
        }

        TypIC.RemarksSupersede = ""
        if(lstrxml[0].superseded_settle !== "" &&  lstrxml[0].superseded_settle !== undefined && lstrxml[0].superseded_settle !== null){
          if(Number(lstrxml[0].superseded_settle) !== 0){
            let superseded_settle = lstrxml[0].superseded_settle.trim()
            if(superseded_settle.length < 7 && superseded_settle.length > 0){
              superseded_settle = '0'.repeat(7-superseded_settle.length) + superseded_settle
            }
            TypIC.RemarksSupersede = "This 1007 Certificate Supersedes: " + superseded_settle
          }
        }
            
        TypIC.A_WeightInclVehicle = lstrxml[0].tare_wt
        TypIC.B_WeightOfVehicle = lstrxml[0].vehicle_wt
        TypIC.C_GrossWeight_AMinusB = lstrxml[0].gross_wt
        TypIC.D_ForeignMaterial_PerOfFMxC = lstrxml[0].fm_wt
        TypIC.E_WeightLessFM_CMinusD = Number(TypIC.C_GrossWeight_AMinusB) - Number(TypIC.D_ForeignMaterial_PerOfFMxC)
        TypIC.F_ExcessMoisture_PerOfEMxE = lstrxml[0].ex_moist_wt
        TypIC.G_NetWeight_EMinusF = lstrxml[0].net_wt
      
        if(TypIC.RESIDUE_IND == "RESIDUE"){
          TypIC.H_LSK_PerOfLSKxC = ""
          TypIC.I_NetWeightExclLSK_GMinusH = ""
          TypIC.J_KernalValPerLB_ExclLSK = ""
          TypIC.K_ELKPremium = ""
          TypIC.L_Total_JPlusK = ""
          TypIC.M_a_Damage = ""
          TypIC.M_b_ExcessFM = ""
          TypIC.M_c_ExcessSplits = ""
          TypIC.M_d_Total = ""
          TypIC.N_NetValLB_ExclLSK_LMinusM = ""
          TypIC.o = ""
          TypIC.P_ValLBInclLSK_a_NxI = ""
          TypIC.P_ValLBInclLSK_b_HxCents = ""
          TypIC.P_Total = ""
          TypIC.P_Total_DivByG = ""
          }
        else if(TypIC.RESIDUE_IND == "RESALE"){
          TypIC.H_LSK_PerOfLSKxC = lstrxml[0].lsk_wt
          TypIC.I_NetWeightExclLSK_GMinusH = TypIC.G_NetWeight_EMinusF - TypIC.H_LSK_PerOfLSKxC
        
          if(TypIC.A_WeightInclVehicle != ''){
            if(bDisplayTons == true){
              TypIC.J_KernalValPerLB_ExclLSK = Number(lstrxml[0].kern_value)
              TypIC.K_ELKPremium = lstrxml[0].elk_prem
              TypIC.L_Total_JPlusK = Number(TypIC.J_KernalValPerLB_ExclLSK) + Number(TypIC.K_ELKPremium)
              TypIC.M_a_Damage = lstrxml[0].dam_discount
              TypIC.M_b_ExcessFM = lstrxml[0].ex_fm_discount
              TypIC.M_c_ExcessSplits = lstrxml[0].ex_ss_discount
              TypIC.M_d_Total = Number(TypIC.M_a_Damage) + Number(TypIC.M_b_ExcessFM) + Number(TypIC.M_c_ExcessSplits)
              TypIC.N_NetValLB_ExclLSK_LMinusM = Number(TypIC.L_Total_JPlusK) - Number(TypIC.M_d_Total)
              TypIC.o = Number(TypIC.N_NetValLB_ExclLSK_LMinusM) / 20
              TypIC.P_ValLBInclLSK_a_NxI = ((Number(TypIC.o) * Number(TypIC.I_NetWeightExclLSK_GMinusH)) * 0.01)
              TypIC.P_ValLBInclLSK_b_HxCents = (Number(TypIC.H_LSK_PerOfLSKxC) * Number(TypIC.lsk_per_pounds))
              TypIC.P_Total = Number(TypIC.P_ValLBInclLSK_a_NxI) + Number(TypIC.P_ValLBInclLSK_b_HxCents)
              TypIC.P_Total_DivByG = (Number(lstrxml[0].net_price) * 100)
            }
            else{
              TypIC.J_KernalValPerLB_ExclLSK = (Number(lstrxml[0].kern_value) / 20)
              TypIC.K_ELKPremium = (Number(lstrxml[0].elk_prem) / 20)
              TypIC.L_Total_JPlusK = Number(TypIC.J_KernalValPerLB_ExclLSK) + Number(TypIC.K_ELKPremium)
              TypIC.M_a_Damage = (Number(lstrxml[0].dam_discountt) / 20)
              TypIC.M_b_ExcessFM = (Number(lstrxml[0].ex_fm_discount) / 20)
              TypIC.M_c_ExcessSplits = (Number(lstrxml[0].ex_ss_discount) / 20)
              TypIC.M_d_Total = (Number(TypIC.M_a_Damage) + Number(TypIC.M_b_ExcessFM) + Number(TypIC.M_c_ExcessSplits))
              TypIC.N_NetValLB_ExclLSK_LMinusM = (Number(TypIC.L_Total_JPlusK) - Number(TypIC.M_d_Total))
              TypIC.o = ""
              TypIC.P_ValLBInclLSK_a_NxI = ((Number(TypIC.N_NetValLB_ExclLSK_LMinusM) * Number(TypIC.I_NetWeightExclLSK_GMinusH)) * 0.01)
              TypIC.P_ValLBInclLSK_b_HxCents = (Number(TypIC.H_LSK_PerOfLSKxC) * Number(TypIC.lsk_per_pounds))
              TypIC.P_Total = (Number(TypIC.P_ValLBInclLSK_a_NxI) + Number(TypIC.P_ValLBInclLSK_b_HxCents))
              TypIC.P_Total_DivByG = (Number(lstrxml[0].net_price) * 100)
            }
          }
        
          TypIC.Purch_ApplicantNo = "1"
          TypIC.Purch_LBS_LineG = Number(lstrxml[0].net_wt)
          TypIC.Purch_xLineP = Number(lstrxml[0].basis_grade_amt)
          TypIC.Purch_xPercentage = lstrxml[0].support_pct
          TypIC.Purch_ValOfSegment = lstrxml[0].value_of_seg
          TypIC.Purch_NetAmount = lstrxml[0].proceeds_amt

          TypIC.Signiture = lstrxml[0].weigher_name
          
          TypIC.Signiture = TypIC.Signiture.replace('#', '')
          TypIC.Signiture = TypIC.Signiture.replace('-', '')

          let Len = (TypIC.Signiture).length
          for (var i = Len - 1 ; i >= 0; i--) {
            if (TypIC.Signiture != "") {
              let s = TypIC.Signiture[i]
              if (isNaN(s)) {
                TypIC.WeigherName = TypIC.Signiture.slice(0, i+1)
                TypIC.WeigherLicense = TypIC.Signiture.slice(i+1,Len)
                break;
              }
            }
          }
          
          TypIC.WeigherName = TypIC.WeigherName == undefined ? '' : TypIC.WeigherName.replace('#', '')
          TypIC.WeigherName = TypIC.WeigherName == undefined ? '' : TypIC.WeigherName.replace('-', '').trim()

        }
        else{
          TypIC.H_LSK_PerOfLSKxC = lstrxml[0].lsk_wt
          TypIC.I_NetWeightExclLSK_GMinusH = ((TypIC.G_NetWeight_EMinusF) - (TypIC.H_LSK_PerOfLSKxC))
          if(TypIC.A_WeightInclVehicle != ""){
            if(bDisplayTons == true){
              TypIC.J_KernalValPerLB_ExclLSK = Number(lstrxml[0].kern_value)
              TypIC.K_ELKPremium = lstrxml[0].elk_prem
              TypIC.L_Total_JPlusK = Number(TypIC.J_KernalValPerLB_ExclLSK) + Number(TypIC.K_ELKPremium)
              TypIC.M_a_Damage = lstrxml[0].dam_discount
              TypIC.M_b_ExcessFM = lstrxml[0].ex_fm_discount
              TypIC.M_c_ExcessSplits = lstrxml[0].ex_ss_discount
              TypIC.M_d_Total = (Number(TypIC.M_a_Damage) + Number(TypIC.M_b_ExcessFM) + Number(TypIC.M_c_ExcessSplits))
              TypIC.N_NetValLB_ExclLSK_LMinusM = (Number(TypIC.L_Total_JPlusK) - Number(TypIC.M_d_Total))
              TypIC.o = Number(TypIC.N_NetValLB_ExclLSK_LMinusM) / 20
              TypIC.P_ValLBInclLSK_a_NxI = ((Number(TypIC.o) * Number(TypIC.I_NetWeightExclLSK_GMinusH)) * 0.01)
              TypIC.P_ValLBInclLSK_b_HxCents = (Number(TypIC.H_LSK_PerOfLSKxC) * Number(TypIC.lsk_per_pounds))
              TypIC.P_Total = (Number(TypIC.P_ValLBInclLSK_a_NxI) + Number(TypIC.P_ValLBInclLSK_b_HxCents))
              TypIC.P_Total_DivByG = (Number(lstrxml[0].net_price) * 100)
            }
            else{
              TypIC.J_KernalValPerLB_ExclLSK = Number(Number(lstrxml[0].kern_value) / 20)
              TypIC.K_ELKPremium = (Number(lstrxml[0].elk_prem) / 20)
              TypIC.L_Total_JPlusK = (Number(TypIC.J_KernalValPerLB_ExclLSK) + Number(TypIC.K_ELKPremium))
              TypIC.M_a_Damage = (Number(lstrxml[0].dam_discount) / 20)
              TypIC.M_b_ExcessFM = (Number(lstrxml[0].ex_fm_discount) / 20)
              TypIC.M_c_ExcessSplits = (Number(lstrxml[0].ex_ss_discount) / 20)
              TypIC.M_d_Total = (Number(TypIC.M_a_Damage) + Number(TypIC.M_b_ExcessFM) + Number(TypIC.M_c_ExcessSplits))
              TypIC.N_NetValLB_ExclLSK_LMinusM = (Number(TypIC.L_Total_JPlusK) - Number(TypIC.M_d_Total))
              TypIC.o = ""
              TypIC.P_ValLBInclLSK_a_NxI = ((Number(TypIC.N_NetValLB_ExclLSK_LMinusM) * Number(TypIC.I_NetWeightExclLSK_GMinusH)) * 0.01)
              TypIC.P_ValLBInclLSK_b_HxCents = (Number(TypIC.H_LSK_PerOfLSKxC) * Number(TypIC.lsk_per_pounds))
              TypIC.P_Total = (Number(TypIC.P_ValLBInclLSK_a_NxI) + Number(TypIC.P_ValLBInclLSK_b_HxCents))
              TypIC.P_Total_DivByG = (Number(lstrxml[0].net_price) * 100)
            }
          }
          else {
            TypIC.J_KernalValPerLB_ExclLSK = ''
            TypIC.K_ELKPremium = ''
            TypIC.L_Total_JPlusK = ''
            TypIC.M_a_Damage = ''
            TypIC.M_b_ExcessFM = ''
            TypIC.M_c_ExcessSplits = ''
            TypIC.M_d_Total = ''
            TypIC.N_NetValLB_ExclLSK_LMinusM = ''
            TypIC.o = ''
            TypIC.P_ValLBInclLSK_a_NxI = ''
            TypIC.P_ValLBInclLSK_b_HxCents = ''
            TypIC.P_Total = ''
            TypIC.P_Total_DivByG = ''
          }
      }
        
        sPrevPremCode = ""
        nIteration = 1
        LstrXML_Subs = (lstrxml.length == 0 || lstrxml[0].inspect_prem_deducts == undefined) ? [] : lstrxml[0].inspect_prem_deducts
        
        TypIC.Store_NetAmount = 0        
        TypIC.Purch_DED_1_MktgAssess = ''
        TypIC.Store_DED_1_MktgAssess = ''        
        TypIC.Purch_DED_2_Tax_SerFee = ''
        TypIC.Store_DED_2_Tax_SerFee = ''
        TypIC.DED_3 = ''
        TypIC.Purch_DED_3 = ''
        TypIC.Store_DED_3 = ''
        TypIC.DED_4 = ''
        TypIC.Purch_DED_4 = ''
        TypIC.Store_DED_4 = ''
        TypIC.DED_6 = ''
        TypIC.Purch_DED_5 = ''
        TypIC.Store_DED_5 = ''
        TypIC.DED_6 = ''
        TypIC.Purch_DED_6 = ''
        TypIC.Store_DED_6 = ''
        let remarkArr1 = [ {}, {} ]
        
        for(var i=0; i < LstrXML_Subs.length; i++){
          LblnPrint1007Storage = LstrXML_Subs[i].print_1007_storage == 'Y'
          if(LstrXML_Subs[i].apply_order == '1'){
            if(nIteration == 1){
              nIteration = 1
            }
            else{
              showMessage(thisObj, "Invalid Apply Orders on Premium Deductions")
              return;
            }
          }
                    
          if(LstrXML_Subs[i].apply_order == '2'){
            if(nIteration <= 2){
              nIteration = 2
            }
            else{
              showMessage(thisObj, "Invalid Apply Orders on Premium Deductions")
              return;
            }
          }
                    
          if((LstrXML_Subs[i].apply_order != '1') && (LstrXML_Subs[i].apply_order != '2') && (nIteration <= 2)){
            nIteration = 2
          }

          if((LstrXML_Subs[i].apply_order != 1) && (LstrXML_Subs[i].apply_order != 2)){
            if(sPrevPremCode != (LstrXML_Subs[i].pd_code)){
              nIteration = nIteration + 1
            }
          }
                    
          if(nIteration == 1){
            let foundRes = []            
            if(lstrxml[0].inspect_vendor_splits.length !== 0){
              foundRes = lstrxml[0].inspect_vendor_splits.find(item => { return item.split_vendor === LstrXML_Subs[i].pd_vendor })
            }
            
            var purhStorInd = foundRes.length == 0 ? 'P' : foundRes.purh_stor_ind
            if(purhStorInd == "P"){
              TypIC.Purch_DED_1_MktgAssess = Number(TypIC.Purch_DED_1_MktgAssess) + Number(LstrXML_Subs[i].pd_amount)
            }
            else{
              if(LblnPrint1007Storage){
                TypIC.Store_DED_1_MktgAssess = Number(TypIC.Store_DED_1_MktgAssess) +  Number(LstrXML_Subs[i].pd_amount)
              }
              else{
                TypIC.Store_DED_1_MktgAssess = Number(TypIC.Store_DED_1_MktgAssess) + Number(0)
              }
              TypIC.Store_NetAmount = Number(TypIC.Store_NetAmount) + Number(LstrXML_Subs[i].pd_amount)
            }
            sPrevPremCode = LstrXML_Subs[i].pd_code
          }
                    
          if(nIteration == 2){
            let foundRes = []
            if(lstrxml[0].inspect_vendor_splits.length !== 0){
              foundRes = lstrxml[0].inspect_vendor_splits.find(item => { return item.split_vendor === LstrXML_Subs[i].pd_vendor })
            }
            
            var purhStorInd = foundRes.length == 0 ? 'P' : foundRes.purh_stor_ind
            if(purhStorInd == "P"){
              TypIC.Purch_DED_2_Tax_SerFee = Number(TypIC.Purch_DED_2_Tax_SerFee) + Number(LstrXML_Subs[i].pd_amount)
            }
            else{
              if(LblnPrint1007Storage){
                TypIC.Store_DED_2_Tax_SerFee = Number(TypIC.Store_DED_2_Tax_SerFee) + Number(LstrXML_Subs[i].pd_amount)
              }
              else{
                TypIC.Store_DED_2_Tax_SerFee = Number(TypIC.Store_DED_2_Tax_SerFee) + Number(0)
              }
                TypIC.Store_NetAmount += Number(LstrXML_Subs[i].pd_amount)
            }
            sPrevPremCode = LstrXML_Subs[i].pd_code
          }
                    
          if(nIteration == 3){
            TypIC.DED_3 = LstrXML_Subs[i].pd_code

            let foundRes = []
            if(lstrxml[0].inspect_vendor_splits.length !== 0){
              foundRes = lstrxml[0].inspect_vendor_splits.find(item => { return item.split_vendor === LstrXML_Subs[i].pd_vendor })
            }
            
            var purhStorInd = foundRes.length == 0 ? 'P' : foundRes.purh_stor_ind

            if(purhStorInd == "P"){
              TypIC.Purch_DED_3 = Number(TypIC.Purch_DED_3) + Number(LstrXML_Subs[i].pd_amount)
            }
            else{
              if(LblnPrint1007Storage == true){
                TypIC.Store_DED_3 = Number(TypIC.Store_DED_3) + Number(LstrXML_Subs[i].pd_amount)
              }
              else{
                TypIC.Store_DED_3 = Number(TypIC.Store_DED_3) + Number(0)
              }
              TypIC.Store_NetAmount += Number(LstrXML_Subs[i].pd_amount)
            }
            sPrevPremCode = LstrXML_Subs[i].pd_code
          }
                    
          if(nIteration == 4){
            TypIC.DED_4 = LstrXML_Subs[i].pd_code

            let foundRes = []
            if(lstrxml[0].inspect_vendor_splits.length !== 0){
              foundRes = lstrxml[0].inspect_vendor_splits.find(item => { return item.split_vendor === LstrXML_Subs[i].pd_vendor })
            }
            
            var purhStorInd = foundRes.length == 0 ? 'P' : foundRes.purh_stor_ind

            if(purhStorInd == "P"){
              TypIC.Purch_DED_4 = Number(TypIC.Purch_DED_4) + Number(LstrXML_Subs[i].pd_amount)
            }
            else{
              if(LblnPrint1007Storage == true){
                TypIC.Store_DED_4 = Number(TypIC.Store_DED_4) + Number(LstrXML_Subs[i].pd_amount)
              }
              else{
                TypIC.Store_DED_4 = Number(TypIC.Store_DED_4) + Number(0)
              }
              TypIC.Store_NetAmount += Number(LstrXML_Subs[i].pd_amount)
            }
            sPrevPremCode = LstrXML_Subs[i].pd_code
          }
                    
          if(nIteration == 5){
            TypIC.DED_5 = LstrXML_Subs[i].pd_code

            let foundRes = []
            if(lstrxml[0].inspect_vendor_splits.length !== 0){
              foundRes = lstrxml[0].inspect_vendor_splits.find(item => { return item.split_vendor === LstrXML_Subs[i].pd_vendor })
            }
            
            var purhStorInd = foundRes.length == 0 ? 'P' : foundRes.purh_stor_ind

            if(purhStorInd == "P"){
              TypIC.Purch_DED_5 = Number(TypIC.Purch_DED_5) + Number(LstrXML_Subs[i].pd_amount)
            }
            else{
              if(LblnPrint1007Storage == true){
                TypIC.Store_DED_5 = Number(TypIC.Store_DED_5) + Number(LstrXML_Subs[i].pd_amount)
              }
              else{
                TypIC.Store_DED_5 = Number(TypIC.Store_DED_5) + Number(0)
              }
              TypIC.Store_NetAmount += Number(LstrXML_Subs[i].pd_amount)
            }
            sPrevPremCode = LstrXML_Subs[i].pd_code
          }
                    
          if(nIteration == 6){
            TypIC.DED_6 = LstrXML_Subs[i].pd_code

            let foundRes = []
            if(lstrxml[0].inspect_vendor_splits.length !== 0){
              foundRes = lstrxml[0].inspect_vendor_splits.find(item => { return item.split_vendor === LstrXML_Subs[i].pd_vendor })
            }
            
            var purhStorInd = foundRes.length == 0 ? '' : foundRes.purh_stor_ind

            // if(purhStorInd == 'P'){              
            //   if(remarkArr1[0][LstrXML_Subs[i].pd_code] == undefined){  
            //     remarkArr1[0][LstrXML_Subs[i].pd_code] = Number(LstrXML_Subs[i].pd_amount)
            //   }
            //   else{
            //     remarkArr1[0][LstrXML_Subs[i].pd_code] += Number(LstrXML_Subs[i].pd_amount)
            //   }
            // }
            // else if(purhStorInd == 'S'){
            //   if(remarkArr1[1][LstrXML_Subs[i].pd_code] == undefined){  
            //     remarkArr1[1][LstrXML_Subs[i].pd_code] = Number(LstrXML_Subs[i].pd_amount)
            //   }
            //   else{
            //     remarkArr1[1][LstrXML_Subs[i].pd_code] += Number(LstrXML_Subs[i].pd_amount)
            //   }
            // }

            if(purhStorInd == "P"){
              TypIC.Purch_DED_6 = Number(TypIC.Purch_DED_6) + Number(LstrXML_Subs[i].pd_amount)
            }
            else{
              if(LblnPrint1007Storage == true){
                TypIC.Store_DED_6 = Number(TypIC.Store_DED_6) + Number(LstrXML_Subs[i].pd_amount)
              }
              else{
                TypIC.Store_DED_6 = Number(TypIC.Store_DED_6) + Number(0)
              }
              TypIC.Store_NetAmount += Number(LstrXML_Subs[i].pd_amount)
            }
            sPrevPremCode = LstrXML_Subs[i].pd_code
          }
          
          if(nIteration > 6){

            let foundRes = []
            if(lstrxml[0].inspect_vendor_splits.length !== 0){
              foundRes = lstrxml[0].inspect_vendor_splits.find(item => { return item.split_vendor === LstrXML_Subs[i].pd_vendor })
            }
            var purhStorInd = foundRes.length == 0 ? '' : foundRes.purh_stor_ind

            if(TypIC.DED_6 != "OTHER"){
              MarrRemarksPremiumDeductions == TypIC.DED_6
              if(LstrXML_Subs.pd_vendor == "P"){ 
                MarrRemarksPremiumDeductions == TypIC.Purch_DED_6
              }
              
              MarrRemarksPremiumDeductions = TypIC.Store_DED_6              
              sPrevPremCode = LstrXML_Subs[i].pd_code
              if((purhStorInd) || (LblnPrint1007Storage == true)){
                  MarrRemarksPremiumDeductions = LstrXML_Subs[i].pd_code
              }
              
              if(purhStorInd == 'P'){
                if(remarkArr1[0][TypIC.DED_6] == undefined){  
                  remarkArr1[0][TypIC.DED_6] = Number(TypIC.Purch_DED_6)
                }
                else{
                  remarkArr1[0][TypIC.DED_6] += Number(TypIC.Purch_DED_6)
                }
              }
              else if(purhStorInd == 'S'){
                if(remarkArr1[1][TypIC.DED_6] == undefined){  
                  remarkArr1[1][TypIC.DED_6] = Number(TypIC.Store_DED_6)
                }
                else{
                  remarkArr1[1][TypIC.DED_6] += Number(TypIC.Store_DED_6)
                }
              }
            } 
            TypIC.DED_6 = "OTHER"          
            
            if(purhStorInd == 'P'){
              if(remarkArr1[0][LstrXML_Subs[i].pd_code] == undefined){  
                remarkArr1[0][LstrXML_Subs[i].pd_code] = Number(LstrXML_Subs[i].pd_amount)
              }
              else{
                remarkArr1[0][LstrXML_Subs[i].pd_code] += Number(LstrXML_Subs[i].pd_amount)
              }
            }
            else if(purhStorInd == 'S'){
              if(remarkArr1[1][LstrXML_Subs[i].pd_code] == undefined){  
                remarkArr1[1][LstrXML_Subs[i].pd_code] = Number(LstrXML_Subs[i].pd_amount)
              }
              else{
                remarkArr1[1][LstrXML_Subs[i].pd_code] += Number(LstrXML_Subs[i].pd_amount)
              }
            }
            
            if(sPrevPremCode != LstrXML_Subs[i].pd_code){
              if(LstrXML_Subs.pd_vendor == 'P'){
                MarrRemarksPremiumDeductions == LstrXML_Subs.pd_code
                MarrRemarksPremiumDeductions == LstrXML_Subs.pd_amount
              }
              else{
                if(LblnPrint1007Storage == true){
                  MarrRemarksPremiumDeductions == LstrXML_Subs.pd_code
                  MarrRemarksPremiumDeductions == LstrXML_Subs.pd_amount
                }
              }
            }
            else{
              if(LstrXML_Subs.pd_vendor == 'P'){
                MarrRemarksPremiumDeductions = MarrRemarksPremiumDeductions + Number(LstrXML_Subs.pd_amount)
              }
              else{
                if(LblnPrint1007Storage == true){
                  MarrRemarksPremiumDeductions = MarrRemarksPremiumDeductions + Number(LstrXML_Subs.pd_amount)
                }
              }
            }
                        
            sPrevPremCode = LstrXML_Subs[i].pd_code
                        
            foundRes = []
            if(lstrxml[0].inspect_vendor_splits.length !== 0){
              foundRes = lstrxml[0].inspect_vendor_splits.find(item => { return item.split_vendor === LstrXML_Subs[i].pd_vendor })
            }
            
            var purhStorInd = foundRes.length == 0 ? 'P' : foundRes.purh_stor_ind
            if(purhStorInd == "P"){
              TypIC.Purch_DED_6 = Number(TypIC.Purch_DED_6) + Number(LstrXML_Subs[i].pd_amount)
            }
            else{
              if(LblnPrint1007Storage == true){
                TypIC.Store_DED_6 = Number(TypIC.Store_DED_6) + Number(LstrXML_Subs[i].pd_amount)
              }
              else{
                TypIC.Store_DED_6 = Number(TypIC.Store_DED_6) + Number(0)
              }
              TypIC.Store_NetAmount += Number(LstrXML_Subs[i].pd_amount)
            }
          }
        }

        if(dblOptionPayment > 0){
          if(TypIC.DED_3 == '' || TypIC.DED_3 == undefined || TypIC.DED_3 == null){
            TypIC.DED_3 = "OPT"
            TypIC.Purch_DED_3 = dblOptionPayment
          }
          else if(TypIC.DED_4 == '' || TypIC.DED_4 == undefined || TypIC.DED_4 == null){
            TypIC.DED_4 = "OPT"
            TypIC.Purch_DED_4 = dblOptionPayment
          }
          else if(TypIC.DED_5 == "" || TypIC.DED_5 == undefined || TypIC.DED_5 == null){
            TypIC.DED_5 = "OPT"
            TypIC.Purch_DED_5 = dblOptionPayment
          }
          else if(TypIC.DED_6 == "" || TypIC.DED_6 == undefined || TypIC.DED_6 == null){
            TypIC.DED_6 = "OPT"
            TypIC.Purch_DED_6 = dblOptionPayment
          }
          else{
            MarrRemarksPremiumDeductions == "OPT"
            MarrRemarksPremiumDeductions == ""
            MarrRemarksPremiumDeductions == dblOptionPayment
            remarkArr1[0]['OPT'] = dblOptionPayment
          }
        }
        
        let qRemarksPremDedP = ''
        let qRemarksPremDedS = ''
        for(let [key, value] of Object.entries(remarkArr1[0])){
          qRemarksPremDedP += (key + ' ' + Number(value).toFixed(2) + ' ')
        }
        if(qRemarksPremDedP !== ''){
          qRemarksPremDedP = 'PUR: ' + qRemarksPremDedP
        }

        for(let [key, value] of Object.entries(remarkArr1[1])){
          qRemarksPremDedS += (key + ' ' + Number(value).toFixed(2) + ' ')
        }
        if(qRemarksPremDedS !== ''){
          qRemarksPremDedS = 'STO: ' + qRemarksPremDedS
        }

        TypIC.QRemarksPremDed = qRemarksPremDedS + '<br/>' + qRemarksPremDedP + '<br/>'

        if(isNaN(TypIC.Store_DED_6) == false)
          TypIC.Store_DED_6 = TypIC.Store_DED_6
                
        if(LintVendorSplit_S > 0){TypIC.Store_ApplicantNo = "1"}
                
        if((LintVendorSplit_P > 0) && (LintVendorSplit_S == 0)){
          TypIC.Purch_ApplicantNo = "1"
        }
        else if((LintVendorSplit_P > 0) && (LintVendorSplit_S > 0)){
          TypIC.Purch_ApplicantNo = "2"
        }
      }

      TypIC.V_Remarks = lstrxml.length == 0 || lstrxml[0].inspect_remarks == undefined || lstrxml[0].inspect_remarks[0].remarks == undefined ? '' : lstrxml[0].inspect_remarks[0].remarks
      TypIC.V_Remarks += lstrxml.length == 0 || lstrxml[0].remarks == undefined ? '' : lstrxml[0].remarks

      if(lstrxml.length == 0 || lstrxml == undefined || lstrxml[0].symbol_ind == undefined)
        sSymbol = ""
      else{
        switch(lstrxml[0].symbol_ind.toLocaleUpperCase().trim()){
          case "TRADEMARK":
            sSymbol = " " + `&trade;`
            break
          case "REGISTERED TRADEMARK":
            sSymbol = " " + `&reg;`
            break
          case "COPYRIGHT":
            sSymbol = " " + `&copy;`
            break
          default:
            sSymbol = ""
        }
      }

      
      TypIC.Variety_Description = lstrxml.length == 0 ? '' : lstrxml[0].pnut_variety_name + sSymbol
      TypIC.Q_Remarks = ""
          
      if(TypIC.RESIDUE_IND != ""){
        bNeedsAttachment = false
        TypIC.Q_Remarks_Attachment = ""
      }
      else{
        LstrXML_Q_Remarks = lstrxml.length == 0 ? '' : lstrxml[0].q_remarks
        nQRemarkLines = 1
        nQRemarksOnLine = 0
        sContract = "FIRST"
        
        if(LstrXML_Q_Remarks.q_remark != 0){
        for(let i =0; i <LstrXML_Q_Remarks.length; i++){
          sQContract = LstrXML_Q_Remarks[i].contract_num == '999999' ? '&nbsp;None&nbsp;&nbsp;' : LstrXML_Q_Remarks[i].contract_num
          sQVendor = LstrXML_Q_Remarks[i].split_vendor
          sQPounds = LstrXML_Q_Remarks[i].net_wt
                    
          if(nQRemarkLines < 5){
            nQRemarksOnLine = nQRemarksOnLine + 1

            if((sHoldContract != sQContract) && (nQRemarksOnLine > 1) && (sHoldContract != "FIRST")){
              TypIC.Q_Remarks = TypIC.Q_Remarks + '<br/>'
              nQRemarksOnLine = 1
              nQRemarkLines = nQRemarkLines + 1
            }
            
            TypIC.Q_Remarks = TypIC.Q_Remarks + "Contract " + sQContract + "  "
            TypIC.Q_Remarks = TypIC.Q_Remarks + "Vendor " + sQVendor + "  "
            TypIC.Q_Remarks = TypIC.Q_Remarks + "Pounds " + Intl.NumberFormat('en-US').format(sQPounds) + " "
                        
            if(nQRemarksOnLine > 2){
              TypIC.Q_Remarks = TypIC.Q_Remarks
              nQRemarksOnLine = 0
              nQRemarkLines = nQRemarkLines + 1
            }
                        
            sHoldContract = sQContract
          }
          else{
            if(nQRemarkLines == 5){
              nQRemarksOnLine = 0
              sHoldContract = "FIRST"
              nQRemarkLines = nQRemarkLines + 1
            }
                        
            bNeedsAttachment = true
            nQRemarksOnLine = nQRemarksOnLine + 1
                        
            if(sHoldContract != sQContract && nQRemarksOnLine > 1 && sHoldContract != "FIRST"){
              TypIC.Q_Remarks_Attachment = TypIC.Q_Remarks_Attachment + '<br/>'
              nQRemarksOnLine = 1
              nQRemarkLines = nQRemarkLines + 1
            }
            
            TypIC.Q_Remarks_Attachment = TypIC.Q_Remarks_Attachment + "Contract " + sQContract + "  "
            TypIC.Q_Remarks_Attachment = TypIC.Q_Remarks_Attachment + "Vendor " + sQVendor + "  "
            TypIC.Q_Remarks_Attachment = TypIC.Q_Remarks_Attachment + "Pounds " + Intl.NumberFormat('en-US').format(sQPounds) + " "
                        
            if(nQRemarksOnLine > 1){
              TypIC.Q_Remarks_Attachment = TypIC.Q_Remarks_Attachment
              nQRemarksOnLine = 0
              nQRemarkLines = nQRemarkLines + 1
            }

            sHoldContract = sQContract
          }
        }}

        if(TypIC.VA_CrackedBroken != "" && TypIC.VA_CrackedBroken != undefined && TypIC.VA_CrackedBroken != null){
          if(nQRemarkLines < 5){
            if(nQRemarksOnLine = 1){TypIC.Q_Remarks = TypIC.Q_Remarks}
            TypIC.Q_Remarks = TypIC.Q_Remarks + "</br></br>VA Cracked and Broken: " + TypIC.VA_CrackedBroken + "%"
            nQRemarkLines = nQRemarkLines + 1
          }
          else{
            bNeedsAttachment = true
            if(nQRemarksOnLine = 1){TypIC.Q_Remarks_Attachment = TypIC.Q_Remarks_Attachment} 
            TypIC.Q_Remarks_Attachment = TypIC.Q_Remarks_Attachment + "</br></br>VA Cracked and Broken: " + TypIC.VA_CrackedBroken + "%"
            nQRemarkLines = nQRemarkLines + 1
          }
        }
      }

      TypIC.inspector_name = lstrxml.length == 0 ? '' : lstrxml[0].inspector_name
      TypIC.signer_name = lstrxml.length == 0 ? '' : lstrxml[0].signer_name
      TypIC.Elec_Upload_Ind = lstrxml.length == 0 ? '' : lstrxml[0].elec_upload
      TypIC.Vehicle_ID_List = lstrxml.length == 0 ? '' : lstrxml[0].vehicle_id_list
    // }
  }
  
  let arr = lstrxml.length == 0 || lstrxml[0].inspect_prem_deducts == undefined ? [] : lstrxml[0].inspect_prem_deducts
  let premDuductArr = []
  let premDuductData = {}
  
  if(arr.length == 0)
    premDuductArr = []
  else{
    for (var i = 0; i < arr.length; i++) { 
      var res = lstrxml[0].inspect_vendor_splits.find(item => { return item.split_vendor === arr[i].pd_vendor })
      if (premDuductData[arr[i].pd_code] == undefined){
        if(res.purh_stor_ind == 'P')
          premDuductData[arr[i].pd_code] = [ 0, Number(arr[i].pd_amount) ]  
        else
        premDuductData[arr[i].pd_code] = [ Number(arr[i].pd_amount), 0 ]  
      }        
      else{
        if(res.purh_stor_ind == 'P')
          premDuductData[arr[i].pd_code][1] += Number(arr[i].pd_amount)
        else
          premDuductData[arr[i].pd_code][0] += Number(arr[i].pd_amount)
      }
    }
    
    premDuductArr = [ [], [], [], [], [], [] ]
    
    for(let [key, value] of Object.entries(premDuductData)){
      if(key == 'GMA')
        premDuductArr[0] = [ value[0], value[1], key ]
      else if(key == 'TAXTX')
        premDuductArr[1] = [ value[0], value[1], key ]
      else if(key == 'P23')
        premDuductArr[2] = [ value[0], value[1], key ]
      else if(key == 'SGNAD')
        premDuductArr[3] = [ value[0], value[1], key ]
      else if((key == 'A/R' || key == 'S/R') && premDuductArr[4].length == 0)
        premDuductArr[4] = [ value[0], value[1], key ]
      else{
        if(premDuductArr[5].length == 0)
          premDuductArr[5] = [ value[0], value[1], key ]       
        else{
          premDuductArr[5][0] += value[0]
          premDuductArr[5][1] += value[1]
        }          
      }      
    }
    let resArr = [ [ '', '', '' ], [ '', '', '' ], [ '', '', '' ], [ '', '', '' ], [ '', '', '' ], [ '', '', '' ] ]
    
    for(var i=0; i<premDuductArr.length; i++){
      if(Number(premDuductArr[0][0]) == 0)
        premDuductArr[0][0] = ''
      if(premDuductArr[i][0] !== undefined)
        premDuductArr[i][0] = Number(premDuductArr[i][0]) == 0 ? '' : Number(premDuductArr[i][0]).toFixed(2)
      if(premDuductArr[i][1] !== undefined)
        premDuductArr[i][1] = Number(premDuductArr[i][1]) == 0 ? '' : Number(premDuductArr[i][1]).toFixed(2)
      
      if(i == 0)
        resArr[i] = [ premDuductArr[i][0], premDuductArr[i][1], 'Peanut Promotion' ]
      if(i == 1)
        resArr[i] = [ premDuductArr[i][0], premDuductArr[i][1], 'Tax - Ser. Free' ]
      if(i == 2)
        resArr[i] = [ premDuductArr[i][0], premDuductArr[i][1], premDuductArr[i][2] ]
      if(i == 3)
        resArr[i] = [ premDuductArr[i][0], premDuductArr[i][1], premDuductArr[i][2] ]
      if(i == 4)
        resArr[i] = [ premDuductArr[i][0], premDuductArr[i][1], premDuductArr[i][2] ]
      if(i == 5)
        resArr[i] = [ premDuductArr[i][0], premDuductArr[i][1], premDuductArr[i][2] ]
    }
    premDuductArr = resArr    
  }
  TypIC.PremuiumDeductions = premDuductArr
  // let s = getData(thisObj, 'ReportPreviewInspectionScreenData').WaterMark != undefined ? getData(thisObj, 'ReportPreviewInspectionScreenData').WaterMark : 'Test'
  // TypIC.WaterMark = s.replace(/(\w)(\w*)/g, function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
  
  // if(TypIC.WaterMark == 'Void'){
  //   TypIC.WaterMark = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test ' + 'Void'
  // }    
  // else if(TypIC.WaterMark == 'No Sale'){
  //   TypIC.WaterMark = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test ' + 'No Sale'
  // }    
  // else if(TypIC.WaterMark == 'Superseded Sc95'){
  //   TypIC.WaterMark = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test ' + 'Superseded'
  // }    
  // else if(TypIC.WaterMark == 'Superseded 1007'){
  //   if(lstrxml[0].insp_status == 'V'){
  //     TypIC.WaterMark = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test ' + 'Superseded'
  //   }      
  //   else if(lstrxml[0].insp_status == 'I'){
  //     TypIC.WaterMark = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test '
  //   }      
  // }    
  // else{
  //   TypIC.WaterMark = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test'
  // }
  
  TypIC.ComputerSerialComment = ''
  if(lstrxml.length >0){
    if(lstrxml[0].insp_status == "R" || lstrxml[0].void_reason_code == "R"){
      TypIC.ComputerSerialComment = " Superseded "
    }
    if(lstrxml[0].superseded_insp.trim() !== ''){
      TypIC.ComputerSerialComment = " Corrected "
    }
    
    TypIC.InspDate = lstrxml[0].insp_date_time
    TypIC.SettDate = lstrxml[0].settlement_date
  }
  let renCode = await InspectionCertificate_Laser_1Document(TypIC)
  return renCode;
}

const InspectionCertificate_Laser_1Document = async (TypIC) =>{
    let strCopyName ="Applicant Original"
    let LintY = 0
    let nLineBreaks = 0
    let nStartCharacter = 0
    let nEndCharacter = 0
    let nBreakCharacter = 0
    
    let strTemp = ''
    let strTempVehicleNo = ''
    let strTempWeightNo = ''
    let strTempRemarks = ''
    let strTempVerbiage = ''
    let intCnt = 0
   
    let CENT_SIGN = ''
    let DIV_SIGN = ''
    CENT_SIGN = '&cent;'
    DIV_SIGN = '÷'
    
    let bExtraWeightTicks = false

    let strStatus = ''
    let lstrxml = ''
    let Var_insp_status = ''
    let Var_void_reason_code = ''
    let Var_settle_correct_cnt = ''
    let Var_superseded_settle = ''
    let Var_superseded_insp = ''
    let strSupersededcomment = ''

    if(TypIC.msPDF_InspectionNo == '' || TypIC.msPDF_InspectionNo == null || TypIC.msPDF_InspectionNo == undefined){
      TypIC.msPDF_InspectionNo == '0000000'
    }

    if(TypIC.RESIDUE_IND == ""){
      let lstrxml = await SettlementService.RetrieveInspectHeaderOneDocumentDetails('PN1060',null,TypIC.msPDF_InspectionNo,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null)
        if(lstrxml != null && lstrxml != undefined && lstrxml.length >0 && lstrxml[0].inspect_headers != undefined && lstrxml[0].inspect_headers != null){
            Var_insp_status = lstrxml[0].inspect_headers[0].insp_status
            Var_void_reason_code = lstrxml[0].inspect_headers[0].void_reason_code
            Var_settle_correct_cnt = lstrxml[0].inspect_headers[0].settle_correct_cnt
            Var_superseded_settle = lstrxml[0].inspect_headers[0].superseded_settle
            Var_superseded_insp = lstrxml[0].inspect_headers[0].superseded_insp
        }
    }
    
    if(TypIC.RESIDUE_IND == "RESIDUE"){
        let lstrxml = await SettlementService.RetrieveInspectHeaderResidueDetails(null, TypIC.msPDF_InspectionNo);
        if(lstrxml != null && lstrxml != undefined && lstrxml.length >0){
            Var_insp_status = lstrxml[0].insp_status
            Var_void_reason_code = lstrxml[0].void_reason_code
            Var_settle_correct_cnt = lstrxml[0].settle_correct_cnt
            Var_superseded_settle = lstrxml[0].superseded_settle
            Var_superseded_insp = lstrxml[0].superseded_insp
        }
    }
    
    strStatus = ""
    
    if(Var_insp_status == "V"){
        if(Var_void_reason_code == "V" || Var_void_reason_code == "F"){
            strStatus = "Void"
        }
        else{
            strStatus = "NoSale"
        }
    }
    
    if(Var_insp_status == "R" || Var_void_reason_code == "R"){
        strStatus = "Superseded"
    }
    
    // if(strStatus == ""){
    //     strStatus = 'GstrVersion'
    // }

    if(strStatus != undefined){
      TypIC.WaterMark = process.env.REACT_APP_ENVIR == 'PROD' ? strStatus : 'Test' + strStatus
    }
    else{
      TypIC.WaterMark = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test'
    } 

    let vsp_Viewer ={}

    strSupersededcomment = " "
    if(Var_insp_status == "R" || Var_void_reason_code == "R"){
       strSupersededcomment = " Superseded "
    }
    
    if(Var_superseded_insp != ""){
       strSupersededcomment = " Corrected "
    }
    strSupersededcomment = TypIC.ComputerSerialComment

    if(isNaN(TypIC.msPDF_InspectionNo) == false){
     
     data.R2C3 = strSupersededcomment + TypIC.msPDF_InspectionNo
    } 
    else{
      data.R2C3 = strSupersededcomment + TypIC.ComputerSerial
    }

    data.FarmNo = TypIC.OperatorSellerName_COCode_FarmNo
    
    data.R2C2  =TypIC.BuyingPoint_Location

    data.Farm_Producer_Name1 = TypIC.ProducerName1 !== undefined ? TypIC.ProducerName1 : ''
    data.Farm_Producer_Name2 = TypIC.ProducerName2 !== undefined ? TypIC.ProducerName2 : ''
    data.Farm_Producer_Name3 = TypIC.ProducerName3 !== undefined ? TypIC.ProducerName3 : ''
    data.Farm_Producer_Name4 = TypIC.ProducerName4 !== undefined ? TypIC.ProducerName4 : ''
    
    data.R2_1C2_11 = TypIC.Applicant_01_Name_Number
    data.R2_1C2_12 = TypIC.Applicant_02_Name_Number
    data.R2_1C2_13 = TypIC.Applicant_03_Name_Number

    data.R3C2 = TypIC.WarehouseDesc_BinNo == undefined ? '-' : TypIC.WarehouseDesc_BinNo
    data.R3C3 = TypIC.DeliveryPoint_IF_NOT_SAME_AS_WHSE == undefined ? '' : TypIC.DeliveryPoint_IF_NOT_SAME_AS_WHSE
    
    data.R4C2 = TypIC.TypeStorage == undefined ? '' : TypIC.TypeStorage
           
    strTempVehicleNo = ""
    strTempWeightNo = "" 
    let bExtraVehicleIds = false
    if(TypIC.Elec_Upload_Ind == "Y"){
       if(TypIC.Vehicle_ID_List != ""){
        data.R5C1= TypIC.Vehicle_ID_List == undefined ? '' : TypIC.Vehicle_ID_List
       }
       else{
            strTempVehicleNo = TypIC.VehicleNo
            data.R5C1 = strTempVehicleNo == undefined ? '' : strTempVehicleNo
            bExtraVehicleIds = false
       }
    }
    else{
        strTempVehicleNo = TypIC.VehicleNo
        data.R5C1 = strTempVehicleNo == undefined ? '' : strTempVehicleNo
        bExtraVehicleIds = false
    }
            
    bExtraWeightTicks = false
    if(TypIC.Elec_Upload_Ind == "Y"){
        strTempWeightNo = TypIC.WeightTicketNo
        data.R5C2 = strTempWeightNo
        bExtraWeightTicks = false
    }
    else{
      data.R5C2 = getTicketNo(intCnt, TypIC.WeightTicketNo, bExtraWeightTicks)
    }
     
    
    data.R6C1 = TypIC.ProbingPattern == undefined ? '' : TypIC.ProbingPattern
    data.R6C2 = TypIC.TypeOfInspection
    data.R6C3 = TypIC.DateInspected == undefined ? '' : moment(TypIC.DateInspected).format('MM/DD/YYYY')
    
    data.R6_1C3 = TypIC.A_WeightInclVehicle == undefined ? '' : TypIC.A_WeightInclVehicle
    data.R7C10 = TypIC.B_WeightOfVehicle == undefined ? '' : TypIC.B_WeightOfVehicle

    data.R7C1 = TypIC.NoOfBags == undefined ? '' : TypIC.NoOfBags
    data.R7C2 = TypIC.Bulk == undefined ? '' : TypIC.Bulk
    data.R7C3 = TypIC.Type == undefined ? '' : TypIC.Type
    data.R7C4 = TypIC.Seg == undefined ? '' : TypIC.Seg
    data.R7C5 = TypIC.CropYear == undefined ? '' : TypIC.CropYear
    data.R7C6 = TypIC.Time == undefined ? '' : moment(TypIC.Time).format('hh:mm A')

    data.R7_1C2 = TypIC.HMC_FM_PCT == undefined ? '' : TypIC.HMC_FM_PCT

    data.R7_1C4 = TypIC.C_GrossWeight_AMinusB == undefined ? '' : TypIC.C_GrossWeight_AMinusB
  
    data.R8C2 = TypIC.FM_SAMPLE == '' || TypIC.FM_SAMPLE == undefined || TypIC.FM_SAMPLE == NaN || TypIC.FM_SAMPLE == null || TypIC.FM_SAMPLE == 'NaN' ? '0.0' : TypIC.FM_SAMPLE
    data.R8C6 = TypIC.HMC_LSK_PCT == undefined ? '' : TypIC.HMC_LSK_PCT

    data.R8C8 = TypIC.D_ForeignMaterial_PerOfFMxC == undefined ? '' : TypIC.D_ForeignMaterial_PerOfFMxC

    data.R9C3 = TypIC.ForiegnMaterial == undefined ? '' : TypIC.ForiegnMaterial
    data.R9C2 = TypIC.FM_GR == '' || TypIC.FM_GR == undefined || TypIC.FM_GR == NaN || TypIC.FM_GR == null || TypIC.FM_GR == 'NaN' ? '0.0' : TypIC.FM_GR

    data.R9C5 = " "
   
    if(TypIC.Type == "VL"){
      data.R9C5 = TypIC.VL_CLN_SAMPLE + " GR. "
    }
   
    data.R9C8 = TypIC.HMC_MOIST == undefined ? '' : TypIC.HMC_MOIST

    data.R9C10 = TypIC.E_WeightLessFM_CMinusD == undefined ? '' : TypIC.E_WeightLessFM_CMinusD

    data.R10C1 = TypIC.OTH_FM == undefined ? '' : TypIC.OTH_FM

    data.R10C3 = ""
    
    if(TypIC.Type == "VL"){
      data.R10C3 = TypIC.CR_BR_GR + " GR. "
    }
    
    data.R10C4 = " "
    
    if(TypIC.Type == "VL"){
      data.R10C4 = TypIC.CrackedBrokenShells + " % "
    }
    
    data.R10C6 = TypIC.HMC_SMK_PCT == undefined ? '' : TypIC.HMC_SMK_PCT

    data.R10C8 = TypIC.F_ExcessMoisture_PerOfEMxE == undefined ? '' : TypIC.F_ExcessMoisture_PerOfEMxE

    data.R11C3 = TypIC.LSK == undefined ? '' : TypIC.LSK

    data.R11C2 = TypIC.LSK_GR == '' || TypIC.LSK_GR == undefined || TypIC.LSK_GR == null || TypIC.LSK_GR == NaN || TypIC.LSK_GR == 'NaN' ? '0.0' : TypIC.LSK_GR
    
    data.R11C5 = " "
    
    if(TypIC.Type == "VL"){
      data.R11C5 = TypIC.DCOL_SHEL_GR + " GR. "
    }
    
    data.R11C6 = ""
    
    if(TypIC.Type == "VL"){
      data.R11C6 = TypIC.DiscoloredShells + " % "
    }

    data.R11C8 = TypIC.HMC_SS_PCT == undefined ? '' : TypIC.HMC_SS_PCT

    data.R11C10 = TypIC.G_NetWeight_EMinusF == undefined ? '' : TypIC.G_NetWeight_EMinusF

  

    data.R12C2 = TypIC.CLN_SAMPLE == '' || TypIC.CLN_SAMPLE == undefined || TypIC.CLN_SAMPLE == null || TypIC.CLN_SAMPLE == NaN || TypIC.CLN_SAMPLE == 'NaN' ? '0.0' : TypIC.CLN_SAMPLE

    data.R12C6 = TypIC.HMC_SMKRS_PCT == undefined ? '' : TypIC.HMC_SMKRS_PCT
    
    data.R12C8 = TypIC.H_LSK_PerOfLSKxC == undefined ? '' : TypIC.H_LSK_PerOfLSKxC

    data.R13C3 = TypIC.Moisture == undefined ? '0' : Number(TypIC.Moisture).toFixed(0) + '%'

    data.R13C2 = TypIC.METER_READ == undefined ? '0.0' : Number(TypIC.METER_READ).toFixed(1)
    
    if(TypIC.Type == "VA"){
      data.R13C5 = TypIC.CLN_SAMPLE + " GR. "
    }
    data.R13C5 = data.R13C5 == undefined ? '' : data.R13C5
    data.R13C8 = TypIC.HMC_OK_PCT == undefined ? '' : TypIC.HMC_OK_PCT
    
    data.R13C10 = TypIC.I_NetWeightExclLSK_GMinusH == undefined ? '' : TypIC.I_NetWeightExclLSK_GMinusH

    data.R14C2 = TypIC.TOT_KRS_WT == undefined ? '0.0' : Number(TypIC.TOT_KRS_WT).toFixed(1)

    data.R14C3 = ""

    data.R14C5 = " "
    
    if(TypIC.Type == "VA"){
      data.R14C5 = TypIC.BLUEPAN_WT + " GR. "
    }
  
    data.R14C6 = " "
    
    data.R14C8 = TypIC.HMC_FRZ_PCT == undefined ? '' : TypIC.HMC_FRZ_PCT

    data.R14C10 = TypIC.J_KernalValPerLB_ExclLSK == null || TypIC.J_KernalValPerLB_ExclLSK == undefined || TypIC.J_KernalValPerLB_ExclLSK == '' || TypIC.J_KernalValPerLB_ExclLSK == NaN || TypIC.J_KernalValPerLB_ExclLSK == 'NaN' ? '' : Number(TypIC.J_KernalValPerLB_ExclLSK).toFixed(2)
    strTempRemarks = strTempRemarks & TypIC.Remarks
    
    if(bExtraVehicleIds){
        strTempRemarks = "* Additional Vehicle Nos.: " + strTempVehicleNo + strTempRemarks
    }
           
    strTempRemarks = strTempRemarks + " " + TypIC.RemarksDate + " " + TypIC.RemarksSupersede

    data.R15C3 = ""
    data.R15C2 = TypIC.DAMAGED_KRS == '' || TypIC.DAMAGED_KRS == undefined || TypIC.DAMAGED_KRS == null || TypIC.DAMAGED_KRS == NaN || TypIC.DAMAGED_KRS == 'NaN' ? '0.0' : TypIC.DAMAGED_KRS

    data.R15C5 = " "
    
    if(TypIC.Type == "VA"){
      data.R15C5 = TypIC.FAN_GR + " GR. "
    }
    
    data.R15C6 =" "
    
    if(TypIC.Type == "VA"){
      data.R15C6 = TypIC.Fancy + " % "
    }    

    data.R15C8 = TypIC.HMC_CRMD_PCT == undefined ? '' : TypIC.HMC_CRMD_PCT
    data.R15C10 = TypIC.K_ELKPremium == '' || TypIC.K_ELKPremium == undefined || TypIC.K_ELKPremium == null || TypIC.K_ELKPremium == NaN || TypIC.K_ELKPremium == 'NaN'? '' : Number(TypIC.K_ELKPremium).toFixed(2)

    data.R16C3 = TypIC.SMK == undefined ? '' : Number(TypIC.SMK).toFixed(0) + '%'
    
    data.R16C2 = TypIC.SMK_GR == '' || TypIC.SMK_GR == undefined || TypIC.SMK_GR == null || TypIC.SMK_GR == NaN || TypIC.SMK_GR == 'NaN' ? '0.0' : TypIC.SMK_GR
    
    data.R16C5 = " "
    
    if(TypIC.Type == "VA")
      data.R16C5 = TypIC.KERNEL_RELS + " GR. "
    
    
    data.R16C6 = ""
    
    data.R16C8 = TypIC.HMC_TOTKERN_PCT == undefined ? '' : TypIC.HMC_TOTKERN_PCT

    data.R16C10 = TypIC.L_Total_JPlusK == undefined || TypIC.L_Total_JPlusK == null || TypIC.L_Total_JPlusK == '' || TypIC.L_Total_JPlusK == NaN || TypIC.L_Total_JPlusK == 'NaN'? '' : Number(TypIC.L_Total_JPlusK).toFixed(2)

    data.R17C3 = TypIC.SoundSplits == undefined ? '' : TypIC.SoundSplits
    
    data.R17C2 = TypIC.SS_GR == '' || TypIC.SS_GR == undefined || TypIC.SS_GR == null || TypIC.SS_GR == NaN || TypIC.SS_GR == 'NaN' ? '0.0' : TypIC.SS_GR

    data.R17C5 = " "
    
    if(TypIC.Type == "VA"){
      data.R17C5 = TypIC.ELK_DAMAGE + " GR. "
    }
 
    data.R17C6  =""
    data.R17C10 = TypIC.M_d_Total == undefined || TypIC.M_d_Total == null || TypIC.M_d_Total == NaN || TypIC.M_d_Total == 'NaN' ? '' : Number(TypIC.M_d_Total).toFixed(3)

    
    data.R18C3 = TypIC.Total_SMK_SoundSplits == undefined ? '' : TypIC.Total_SMK_SoundSplits

    data.R18C2 = ""
    data.R18C6 = ""
    
    if(TypIC.Type == "VA"){
      data.R18C6 = TypIC.ELK_GR + " GR. "
    }
    
    data.R18C5 = ""
    
    if(TypIC.Type == "VA"){
      data.R18C5 = TypIC.ELK + " % "
    }
    
    data.R18C8 = TypIC.HMC_DAM_PCT == undefined || TypIC.HMC_DAM_PCT == null || TypIC.HMC_DAM_PCT == '' || TypIC.HMC_DAM_PCT == NaN || TypIC.HMC_DAM_PCT == 'NaN' ? '' : TypIC.HMC_DAM_PCT
    data.R18C9 = TypIC.M_a_Damage == undefined || TypIC.M_a_Damage == NaN || TypIC.M_a_Damage == 'NaN' || TypIC.M_a_Damage == '' || TypIC.M_a_Damage == null ? '' : Number(TypIC.M_a_Damage).toFixed(3)
    data.R18C10 = TypIC.M_b_ExcessFM == undefined || TypIC.M_b_ExcessFM == NaN || TypIC.M_b_ExcessFM == 'NaN' || TypIC.M_b_ExcessFM == '' || TypIC.M_b_ExcessFM == null ? '' : Number(TypIC.M_b_ExcessFM).toFixed(3)
    data.R18C11 = TypIC.M_c_ExcessSplits == undefined || TypIC.M_c_ExcessSplits == NaN || TypIC.M_c_ExcessSplits == 'NaN' || TypIC.M_c_ExcessSplits == '' || TypIC.M_c_ExcessSplits == null ? '' : Number(TypIC.M_c_ExcessSplits).toFixed(3)
    
    data.R19C3 = TypIC.OtherKernals == undefined ? '' : TypIC.OtherKernals
    
    data.R19C2 = TypIC.OK_GR == '' || TypIC.OK_GR == undefined || TypIC.OK_GR == null || TypIC.OK_GR == NaN || TypIC.OK_GR == 'NaN' ? '0.0' : TypIC.OK_GR
     
    data.R19C5 = ""
    
    if(TypIC.Type == "VA"){
      data.R19C5 = TypIC.KERNEL_RPS + " GR. "
    }

    data.R19C6 = ""
    data.R19C8 = TypIC.HMC_HULL_PCT !== undefined ? TypIC.HMC_HULL_PCT + " " : ''
    
    data.R19C10 = TypIC.N_NetValLB_ExclLSK_LMinusM == NaN || TypIC.N_NetValLB_ExclLSK_LMinusM == 'NaN' || TypIC.N_NetValLB_ExclLSK_LMinusM == undefined || TypIC.N_NetValLB_ExclLSK_LMinusM == null || TypIC.N_NetValLB_ExclLSK_LMinusM == '' ? '' : Number(TypIC.N_NetValLB_ExclLSK_LMinusM).toFixed(3)
    
    data.R20C3 = ""
    data.R20C2 = TypIC.DAM_SPLIT_GR == '' || TypIC.DAM_SPLIT_GR == undefined || TypIC.DAM_SPLIT_GR == null || TypIC.DAM_SPLIT_GR == NaN || TypIC.DAM_SPLIT_GR == 'NaN' ? '0.0' : TypIC.DAM_SPLIT_GR

    data.R20C5 = TypIC.FREZ_DAM_GR == '' || TypIC.FREZ_DAM_GR == undefined || TypIC.FREZ_DAM_GR == null || TypIC.FREZ_DAM_GR == NaN || TypIC.FREZ_DAM_GR == 'NaN' ? '0.0' : TypIC.FREZ_DAM_GR

    data.R20C6 = TypIC.FreezeDamage == undefined ? '0.00' : Number(TypIC.FreezeDamage).toFixed(2)

    data.R20C8 = TypIC.HMC_ELK_PCT == undefined ? '' : TypIC.HMC_ELK_PCT
    
    data.R21C3 = TypIC.Damage == undefined ? '' : TypIC.Damage
    data.R21C2 = TypIC.DAM_GR == '' || TypIC.DAM_GR == undefined || TypIC.DAM_GR == null || TypIC.DAM_GR == NaN || TypIC.DAM_GR == 'NaN' ? '0.0' : TypIC.DAM_GR
     
    data.R21C5 = TypIC.CNCL_RMD_GR == '' || TypIC.CNCL_RMD_GR == undefined || TypIC.CNCL_RMD_GR == null || TypIC.CNCL_RMD_GR == NaN || TypIC.CNCL_RMD_GR == 'NaN' ? '0.0' : TypIC.CNCL_RMD_GR

    data.R21C6 = TypIC.ConcealedRMD == undefined ? '0.00' : Number(TypIC.ConcealedRMD).toFixed(2)

    data.R21C8 = TypIC.HMC_FANCY_PCT == undefined ? '' : TypIC.HMC_FANCY_PCT
    
    if(TypIC.o == undefined || TypIC.o == null || TypIC.o == '')
      data.R21C10 = ''
    else
      data.R21C10 = Number(TypIC.o).toFixed(5)
     
    data.R22C3 = TypIC.TotalKernals == undefined ? '' : TypIC.TotalKernals
   
    data.R22C2 = ""
    if(TypIC.FLAVUS_IND == "Y"){
      data.R22C4 = " A. FLAVUS FOUND"
    }
    else{
      data.R22C4 = " A. FLAVUS NOT FOUND"
    }
    
    data.R22C6 = TypIC.HMC_Total_Kernals_Hulls == undefined ? '' : TypIC.HMC_Total_Kernals_Hulls

    data.R23C3 = TypIC.Hulls == undefined ? '' : TypIC.Hulls
    
    data.R23C2 = TypIC.HULL_GR == '' || TypIC.HULL_GR == undefined || TypIC.HULL_GR == null || TypIC.HULL_GR == NaN || TypIC.HULL_GR == 'NaN' ? '0.0' : TypIC.HULL_GR
    
    data.R23C4 = TypIC.Total_Kernals_Hulls == undefined ? '' : TypIC.Total_Kernals_Hulls
    
    data.R22C7_N = TypIC.P_ValLBInclLSK_a_NxI == '' || TypIC.P_ValLBInclLSK_a_NxI == undefined || TypIC.P_ValLBInclLSK_a_NxI == null || TypIC.P_ValLBInclLSK_a_NxI == NaN || TypIC.P_ValLBInclLSK_a_NxI == 'NaN' ? '' : Number(TypIC.P_ValLBInclLSK_a_NxI).toFixed(2)

    
    data.R22C7_M = TypIC.lsk_per_pounds == '' || TypIC.lsk_per_pounds == undefined || TypIC.lsk_per_pounds == null || TypIC.lsk_per_pounds == NaN || TypIC.lsk_per_pounds == 'NaN' ? '' : Number(TypIC.lsk_per_pounds).toFixed(2)
    data.R22C7_D = TypIC.P_ValLBInclLSK_b_HxCents >= 0  ? Number(TypIC.P_ValLBInclLSK_b_HxCents).toFixed(2) : ''

    data.R24C3 = strTempRemarks
    strTempRemarks = ""
    
    data.R24_1C3 = TypIC.P_Total == '' || TypIC.P_Total == undefined || TypIC.P_Total ==  null || TypIC.P_Total ==  NaN || TypIC.P_Total ==  'NaN' ? '' : Number(TypIC.P_Total).toFixed(2)

    
    if(TypIC.P_Total_DivByG == undefined || TypIC.P_Total_DivByG == null || TypIC.P_Total_DivByG == '')
      data.R24_1C4 = ''
    else
      data.R24_1C4 = Number(TypIC.P_Total_DivByG).toFixed(5)


    data.R25C4 = TypIC.Store_ApplicantNo == undefined ? '' : TypIC.Store_ApplicantNo
    data.R25C6 = TypIC.Purch_ApplicantNo == undefined ? '' : TypIC.Purch_ApplicantNo
    
    data.R26_1C3 = TypIC.Store_LBS_LineG == undefined ? '' : TypIC.Store_LBS_LineG 
    data.R26_1C4 = TypIC.Purch_LBS_LineG == undefined ? '' : TypIC.Purch_LBS_LineG
    
    data.R27C3 = ''
    data.R27C4 = ''
    data.R27C5 = TypIC.Purch_xLineP == "" || TypIC.Purch_xLineP == undefined || TypIC.Purch_xLineP == null ? '' : Number(TypIC.Purch_xLineP).toFixed(2)
    

    data.R28C3 = ""
    data.R28C4 = ""
    data.R28C5 = TypIC.Purch_xPercentage != "" && TypIC.Purch_xPercentage != undefined && TypIC.Purch_xPercentage != null ? Number(TypIC.Purch_xPercentage) : ''
    
    if(TypIC.inspector_name == ""){
      data.R28C1_Name = TypIC.signer_name
    }
    else{
      data.R28C1_Name = TypIC.inspector_name
    }
    
    if(TypIC.AREA_ID == "SW" && data.R28C1_Name != "" && data.R28C1_Name != undefined){
      data.R28C1_Date = moment(TypIC.DateInspected).format('MM/DD/YYYY')
    }
    else{
      data.R28C1_Date = ''
    }

    data.R28_1C2 = ''
    data.R28_1C3 = ''
    if(TypIC.Purch_ValOfSegment != "" && TypIC.Purch_ValOfSegment != undefined && TypIC.Purch_ValOfSegment != null){
      data.R28_1C4 = Number(TypIC.Purch_ValOfSegment)
    }
    else{
      data.R28_1C4 = ''
    }
    
    data.R29C1 = "Peanut Promotion"
    
    data.R29C3 = ""
    data.R29C4 = ""
    data.R29C5 = ""
    
    if(TypIC.Store_DED_1_MktgAssess != "" && TypIC.Store_DED_1_MktgAssess != undefined && TypIC.Store_DED_1_MktgAssess != null){
      data.R29C4 = Number(TypIC.Store_DED_1_MktgAssess)
    } else{
      data.R29C4 = ''
    }
    if(TypIC.Purch_DED_1_MktgAssess != "" && TypIC.Purch_DED_1_MktgAssess != undefined && TypIC.Purch_DED_1_MktgAssess != null){
      data.R29C5 = Number(TypIC.Purch_DED_1_MktgAssess)
    }else{
      data.R29C5 = ''
    }
    
    data.R30C1 = TypIC.WeigherName == undefined ? '' : TypIC.WeigherName
    data.R31C1 = TypIC.WeigherLicense == undefined ? '' : TypIC.WeigherLicense
    
    data.R30C3 = ""
    data.R30C4 = ""
    data.R30C5 = ""
    
    // if(TypIC.Store_DED_2_Tax_SerFee != "" && TypIC.Store_DED_2_Tax_SerFee != undefined && TypIC.Store_DED_2_Tax_SerFee != null){
    //   data.R30C4 = Number(TypIC.Store_DED_2_Tax_SerFee)
    // }
    // else{
    //   data.R30C4 = ''
    // }
    if(TypIC.Purch_DED_2_Tax_SerFee != "" && TypIC.Purch_DED_2_Tax_SerFee != undefined && TypIC.Purch_DED_2_Tax_SerFee != null){
      data.R30C5 = Number(TypIC.Purch_DED_2_Tax_SerFee)
    }else{
      data.R30C5 = ''
    }

  //  'DRAW & FILL ROW 37 BOXES

    data.R30_1C2 = ""
    data.R30_1C3 = ""
    data.R30_1C4 = ""

    if(TypIC.Store_DED_3 != "" || TypIC.Purch_DED_3 != ""){
      data.R30_1C2 = TypIC.DED_3 == undefined ? '' : TypIC.DED_3
    }
    else{
      data.R30_1C2 = ''
    }
    // if(TypIC.Store_DED_3 != ""){
    //   data.R30_1C3 = TypIC.Store_DED_3 == undefined ? '' : Number(TypIC.Store_DED_3)
    // }
    data.R30_1C3 = ''
    if(TypIC.Purch_DED_3 != ""){
      data.R30_1C4 = TypIC.Purch_DED_3 == undefined ? '' : Number(TypIC.Purch_DED_3)
    }
    else{
      data.R30_1C4 = ''
    }
  
    data.R31C3 = ""

    if(TypIC.Store_DED_4 != "" || TypIC.Purch_DED_4 != ""){
      data.R31C3 = TypIC.DED_4 == undefined ? '' : TypIC.DED_4
    }
    data.R31C4 = ''
    // if(TypIC.Store_DED_4 != ""){
    //   data.R31C4 = TypIC.DED_4 == undefined ? '' : Number(TypIC.Store_DED_4)
    // }
    data.R31C5 = ''
    if(TypIC.Purch_DED_4 != ""){
      data.R31C5 = TypIC.DED_4 == undefined ? '' : Number(TypIC.Purch_DED_4)
    }
    
    data.R32C1 = TypIC.SettlementDate == undefined || TypIC.SettlementDate == '' || TypIC.SettlementDate == null ? '' : moment(TypIC.SettlementDate).format('MM/DD/YYYY')

    data.R32C3 = ""
    data.R32C4 = ""
    data.R32C5 = ""

    if(TypIC.Store_DED_5 != "" || TypIC.Purch_DED_5 != ""){
      data.R32C3 = TypIC.DED_5 == undefined ? '' : TypIC.DED_5
    }
    data.R32C4 = ''
    // if(TypIC.Store_DED_5 != ""){
    //   data.R32C4 = TypIC.DED_5 == undefined ? '' : Number(TypIC.Store_DED_5)
    // }
    if(TypIC.Purch_DED_5 != ""){
      data.R32C5 = TypIC.DED_5 == undefined ? '' : Number(TypIC.Purch_DED_5)
    }
    
    data.R32_1C1 = ""
    data.R32_1C2 = ""
    data.R32_1C3 = ""

    if(TypIC.Store_DED_6 != "" || TypIC.Purch_DED_6 != ""){
      data.R32_1C1  = TypIC.DED_6 == undefined ? '' : TypIC.DED_6
    }
    data.R32_1C2 = ''
    // if(TypIC.Store_DED_6 != ""){
    //   data.R32_1C2 = TypIC.DED_6 == undefined ? '' : Number(TypIC.Store_DED_6)
    // }
    if(TypIC.Purch_DED_6 != ""){
      data.R32_1C3 = TypIC.DED_6 == undefined ? '' : Number(TypIC.Purch_DED_6)
    }
    
    if(TypIC.msPDF_InspectionNo != "NO_FV95" && TypIC.msPDF_InspectionNo != "NO_SC95"){
        if(TypIC.Variety_Description != ""){
          
        }
    }
    if(TypIC.RemarksGovtVerbage1 != ""){
        
    }
    strTempVerbiage = ""
    if(TypIC.msPDF_InspectionNo != "NO_FV95" && TypIC.msPDF_InspectionNo != "NO_SC95"){
        if(TypIC.RemarksGovtVerbage2 != "- , ," && TypIC.RemarksGovtVerbage2 != ""){
          TypIC.strTempVerbiage = TypIC.L13_Fed_Whs_Name + ", (" + TypIC.RemarksGovtVerbage2 + ")"
        }
    }
    
    strTempVerbiage = ""
    
    let strAddTick = ''
    let intNumber = 0
    let nLineVariance = 0
    let strTemp2 = ''
        
    let bStoragePD = false
    let bPurchasePD = false
        
    strTemp = ""
    
    if(bExtraWeightTicks){
        for(let i = intCnt; i<astr.length; i++){
            strAddTick = strAddTick & astr(i)
        }
        
        if(strTempWeightNo != ""){
            strTemp = strTemp + "* Additional Weight Ticket Nos.: " + strTempWeightNo
        }
        else{
            strTemp = strTemp + "* Additional Weight Ticket Nos.: " + strAddTick
        }
    }        
    
    strTemp = ""
    strTemp2 = ""
    
    if(TypIC.msPDF_InspectionNo != "NO_FV95" && TypIC.msPDF_InspectionNo != "NO_SC95"){
        strTemp = "STO: "
        
        if(bStoragePD){
            if(strTemp2 != ""){
            
            }
        }
        
        strTemp = ""
        strTemp2 = ""
        
        strTemp = "PUR: "
        
        if(bPurchasePD){
            if(strTemp2 != ""){
            }
        }
    
        strTemp = ""
        strTemp2 = ""
    }
    
    data.R33C3 = " "
    data.R33C4 = " "
    data.R33C5 = " "

    if( TypIC.Purch_NetAmount != undefined && TypIC.Purch_NetAmount.toString() != '')
    {   
     data.R33C5 = Number(TypIC.Purch_NetAmount) >= 0 ? Number(TypIC.Purch_NetAmount).toFixed(2) : ''
    }
    else 
    {
      data.R33C5 = ''
    }

    
    let insDate = TypIC.DateInspected
    let settDate = TypIC.SettlementDate
    
    if(insDate != "" && settDate != ""){
        if((new Date(new Date(insDate).setDate(new Date(insDate).getDate() + 3))) > new Date(TypIC.SettlementDate)){
          data.R33_1C1 = moment(new Date(new Date(settDate).setDate(new Date(settDate).getDate()))).format('MM/DD/YYYY')
        }
        else {
          data.R33_1C1 = moment(new Date(new Date(insDate).setDate(new Date(insDate).getDate() + 3))).format('MM/DD/YYYY')
        }
    }
    else{
      data.R33_1C1 = ''
    }
    data.R34C1 = TypIC.ReceipentType

    data.R2_1C1 = TypIC.Share_Percentage1 == undefined || TypIC.Share_Percentage1 == '' ? '' : Number(TypIC.Share_Percentage1).toFixed(3)
    data.R2_1C2 = TypIC.Share_Percentage2 == undefined || TypIC.Share_Percentage2 == '' ? '' : Number(TypIC.Share_Percentage2).toFixed(3)
    data.R2_1C3 = TypIC.Share_Percentage3 == undefined || TypIC.Share_Percentage3 == '' ? '' : Number(TypIC.Share_Percentage3).toFixed(3)
    data.R2_1C4 = TypIC.Share_Percentage4 == undefined || TypIC.Share_Percentage4 == '' ? '' : Number(TypIC.Share_Percentage4).toFixed(3)
    
    data.R6_1C1 = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" + (TypIC.HMC_SEG == undefined || TypIC.HMC_SEG == null || TypIC.HMC_SEG == '' ? '' : TypIC.HMC_SEG)

    data.R8C3 = ''
    data.R9C6 = ''
    data.R12C3 = ''
    data.R13C6 = ''

    data.R24C1 = ''
    data.R24C1 += TypIC.V_Remarks + '</br>'
    TypIC.InspDate = TypIC.InspDate == undefined ? '' : moment(TypIC.InspDate).format('MM-DD')
    TypIC.SettDate = TypIC.SettDate == undefined ? '' : moment(TypIC.SettDate).format('MM-DD')
    data.R24C1 += (TypIC.AREA_ID.toLocaleUpperCase().trim() == 'SW' ? TypIC.InspDate : TypIC.SettDate)
    data.R24C1 += TypIC.RemarksSupersede == undefined ? '' : '&nbsp&nbsp&nbsp&nbsp' + TypIC.RemarksSupersede
    
    TypIC.RemarksGovtVerbage1 = TypIC.RemarksGovtVerbage1 == undefined ? '' : TypIC.RemarksGovtVerbage1 + '<br/>'
    TypIC.strTempVerbiage = TypIC.strTempVerbiage == undefined ? '' : TypIC.strTempVerbiage + `</br></br>`
    TypIC.QRemarksPremDed = TypIC.QRemarksPremDed == undefined ? '' : TypIC.QRemarksPremDed

    data.R33C2 = TypIC.RemarksGovtVerbage1 + TypIC.strTempVerbiage
    data.R33C2 += TypIC.QRemarksPremDed
    data.R33C2 += ((TypIC.Q_Remarks == undefined ? '<br/>' : TypIC.Q_Remarks) + '<br/>')

    TypIC.Variety_Description = TypIC.Variety_Description == undefined || TypIC.Variety_Description == 'undefined' || TypIC.Variety_Description == '' || TypIC.Variety_Description == null ? '' : '</br>Peanut Variety: ' + TypIC.Variety_Description
    TypIC.IRR_DRY_IND = TypIC.IRR_DRY_IND == undefined || TypIC.IRR_DRY_IND == 'undefined' ? '' : TypIC.IRR_DRY_IND
    TypIC.RemarksSeed = TypIC.RemarksSeed == undefined || TypIC.RemarksSeed == 'undefined' ? '' : TypIC.RemarksSeed
    
    if(msPDF_InspectionNo !== "NO_FV95" && msPDF_InspectionNo !== "NO_SC95"){
      if(TypIC.Variety_Description.trim() !== '')
      data.R33C2 += (TypIC.Variety_Description + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + TypIC.IRR_DRY_IND + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + TypIC.RemarksSeed)
    }
    data.R33C3 = TypIC.WeightCentNumber == undefined ? '' : TypIC.WeightCentNumber
    
    let res = []
    data.R29C1 = 'Peanut Promotion'
    data.R29C3 = TypIC.Store_DED_1_MktgAssess == '' || TypIC.Store_DED_1_MktgAssess == undefined ? '' : Number(TypIC.Store_DED_1_MktgAssess).toFixed(2)
    data.R29C5 = TypIC.Purch_DED_1_MktgAssess == '' || TypIC.Purch_DED_1_MktgAssess == undefined ? '' : Number(TypIC.Purch_DED_1_MktgAssess).toFixed(2)

    data.R30C2 = 'Tax - Ser. Fee'
    data.R30C3 = TypIC.Store_DED_2_Tax_SerFee == '' || TypIC.Store_DED_2_Tax_SerFee == undefined ? '' : Number(TypIC.Store_DED_2_Tax_SerFee).toFixed(2)
    data.R30C5 = TypIC.Purch_DED_2_Tax_SerFee == '' || TypIC.Purch_DED_2_Tax_SerFee == undefined ? '' : Number(TypIC.Purch_DED_2_Tax_SerFee).toFixed(2)

    data.R30_1C1 = TypIC.DED_3 == '' || TypIC.DED_3 == undefined ? '' :TypIC.DED_3
    data.R30_1C2 = TypIC.Store_DED_3 == undefined || TypIC.Store_DED_3.toString() == ''  ? '' : Number(TypIC.Store_DED_3).toFixed(2)
    data.R30_1C4 = TypIC.Purch_DED_3 == '' || TypIC.Purch_DED_3 == undefined ? '' : Number(TypIC.Purch_DED_3).toFixed(2)

    data.R31C2 = TypIC.DED_4 == '' || TypIC.DED_4 == undefined ? '' :TypIC.DED_4
    data.R31C3 = TypIC.Store_DED_4 == '' || TypIC.Store_DED_4 == undefined ? '' : Number(TypIC.Store_DED_4).toFixed(2)
    data.R31C5 = TypIC.Purch_DED_4 == '' || TypIC.Purch_DED_4 == undefined ? '' : Number(TypIC.Purch_DED_4).toFixed(2)

    data.R32C2 = TypIC.DED_5 == '' || TypIC.DED_5 == undefined ? '' :TypIC.DED_5
    data.R32C3 = TypIC.Store_DED_5 == '' || TypIC.Store_DED_5 == undefined ? '' : Number(TypIC.Store_DED_5).toFixed(2)
    data.R32C5 = TypIC.Purch_DED_5 == '' || TypIC.Purch_DED_5 == undefined ? '' : Number(TypIC.Purch_DED_5).toFixed(2)
    
    data.R32_1C0 = TypIC.DED_6 == '' || TypIC.DED_6 == undefined ? '' :TypIC.DED_6
    data.R32_1C1 = TypIC.Store_DED_6 == '' || TypIC.Store_DED_6 == undefined ? '' : Number(TypIC.Store_DED_6).toFixed(2)
    data.R32_1C3 = TypIC.Purch_DED_6 == '' || TypIC.Purch_DED_6 == undefined ? '' : Number(TypIC.Purch_DED_6).toFixed(2)
      
    // for(var i=0; i<TypIC.PremuiumDeductions.length; i++){
    //   if(i == 0){
    //     data.R29C1 = TypIC.PremuiumDeductions[i][2] == undefined ? '' : TypIC.PremuiumDeductions[i][2]
    //     data.R29C3 = TypIC.PremuiumDeductions[i][0] == undefined ? '' : TypIC.PremuiumDeductions[i][0]
    //     data.R29C5 = TypIC.PremuiumDeductions[i][1] == undefined ? '' : TypIC.PremuiumDeductions[i][1]
    //   }
    //   else if(i == 1){
    //     data.R30C2 = TypIC.PremuiumDeductions[i][2] == undefined ? '' : TypIC.PremuiumDeductions[i][2]
    //     data.R30C3 = TypIC.PremuiumDeductions[i][0] == undefined ? '' : TypIC.PremuiumDeductions[i][0]
    //     data.R30C5 = TypIC.PremuiumDeductions[i][1] == undefined ? '' : TypIC.PremuiumDeductions[i][1]
    //   }
    //   if(i == 2){
    //     data.R30_1C1 = TypIC.PremuiumDeductions[i][2] == undefined ? '' : TypIC.PremuiumDeductions[i][2]
    //     data.R30_1C2 = TypIC.PremuiumDeductions[i][0] == undefined ? '' : TypIC.PremuiumDeductions[i][0]
    //     data.R30_1C4 = TypIC.PremuiumDeductions[i][1] == undefined ? '' : TypIC.PremuiumDeductions[i][1]
    //   }
    //   else if(i == 3){
    //     data.R31C2 = TypIC.PremuiumDeductions[i][2] == undefined ? '' : TypIC.PremuiumDeductions[i][2]
    //     data.R31C3 = TypIC.PremuiumDeductions[i][0] == undefined ? '' : TypIC.PremuiumDeductions[i][0]
    //     data.R31C5 = TypIC.PremuiumDeductions[i][1] == undefined ? '' : TypIC.PremuiumDeductions[i][1]
    //   }
    //   else if(i == 4){
    //     data.R32C2 = TypIC.PremuiumDeductions[i][2] == undefined ? '' : TypIC.PremuiumDeductions[i][2]
    //     data.R32C3 = TypIC.PremuiumDeductions[i][0] == undefined ? '' : TypIC.PremuiumDeductions[i][0]
    //     data.R32C5 = TypIC.PremuiumDeductions[i][1] == undefined ? '' : TypIC.PremuiumDeductions[i][1]
    //   }
    //   else if(i == 5){
    //     data.R32_1C0 = TypIC.PremuiumDeductions[i][2] == undefined ? '' : TypIC.PremuiumDeductions[i][2]
    //     data.R32_1C1 = TypIC.PremuiumDeductions[i][0] == undefined ? '' : TypIC.PremuiumDeductions[i][0]
    //     data.R32_1C3 = TypIC.PremuiumDeductions[i][1] == undefined ? '' : TypIC.PremuiumDeductions[i][1]
    //   }
    // }

    data.WaterMark = TypIC.WaterMark
    let renderCode = await TradeSettleReprintTemplate(data)
    return renderCode
}

async function TradeSettleReprintTemplate (data){
    return `<html>
    <style>
    body:before{
        content: '${data.WaterMark}';
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;  
        color: #0d745e;
        font-size: 650%;
        font-weight: 500px;
        display: grid;
        justify-content: center;
        align-content: center;
        opacity: 0.35;
    }
    @media print {
        @page :footer { display: none }    
        @page :header { display: none }    
        @page { margin-top: 0; margin-bottom: 0; }    
        html,
        body { height: 100%; margin: 0 !important; padding: 0 !important; }
    }     
    </style>
    <br>
    <p style="page-break-after: always">
    <table style="font-size: 40%;padding-left: 2%;border: 1px solid black;border-collapse:collapse;font-family:'Arial';color:black;width:99%;">
      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 140%;"  colspan="7"> 
              <span style="text-align:left">  FSA-1007 (06-01-05) FINAL &nbsp &nbspUSDA/FSA/AMS  </span></br>
              <span style="text-align:left"><b> INSPECTION CERTIFICATE AND CALCULATION WORKSHEET </b> </span>
          </td>            
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 120%;"  colspan="8"> 
              <span style="text-align:left"> The authority for collecting the information is Pub. L 107-171. This authority allows for the collection of 
              information without prior OMB approval mandated by the Paperwork Reduction Act of 1995. The time required to 
              complete this information collection is estimated to average 30 minutes per response, including the time for 
              reviewing instructions, searching existing data sources, gathering and maintaining the data needed, completing 
              and reviewing the collection of information.  </span>
          </td> 
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;border-bottom: none;font-size: 140%;" colspan="7" rowspan="2">
              <span style="text-align:left">  Farm Producer Name/Seller's Name, St. & CO. Code & Farm No. </br> ${data.FarmNo} </br> ${data.Farm_Producer_Name1} </br> ${data.Farm_Producer_Name2} </br> ${data.Farm_Producer_Name3} </br> ${data.Farm_Producer_Name4} </span>
          </td>            
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 140%;"  colspan="4"> 
              <span style="text-align:left">Buying Point No. & Location</br> ${data.R2C2} </span>
          </td> 
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 140%;"  colspan="4"> 
              <span style="text-align:left;">  Serial No. </br> </span>
              <span style="text-align: right;display: inline-block; width: 92%;">  ${data.R2C3} </span>
          </td>        
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">           
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 140%;text-align:center;"  colspan="2"> 
              <span style="text-align:left"> Share % </br> ${data.R2_1C1} </br> ${data.R2_1C2} </br> ${data.R2_1C3} </br> ${data.R2_1C4} </span>
          </td> 
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 140%;"  colspan="6"> 
              <span style="text-align:left"> Applicant/Buyer's Name and No.</br> 1. ${data.R2_1C2_11} </br> 2. ${data.R2_1C2_12}  </br> 3. ${data.R2_1C2_13} </span>
          </td>        
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 140%;" colspan="8"> 
              <span style="text-align:left"> <b> SECTION I - INSPECTION CERTIFICATE - FARMERS STOCK PEANUTS </b> </span>
          </td>            
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 140%;"  colspan="3"> 
              <span style="text-align:left">  Whse. Description and Bin No. </br> ${data.R3C2} </span>
          </td> 
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 140%;"  colspan="4" rowspan="2"> 
              <span style="text-align:left"> Delivery Point, if not the same as Whse. </br></br> ${data.R3C3} </span>
          </td>
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 120%;" colspan="8"> 
              <span style="text-align:left"> This certificate is issued pursuant to the Agricultural Marketing Act of 1946 as amended (7 U.S.C. 1621 et. seq.) and is 
              admissible as prima-facie evidence in all courts in the United States. Any person who knowingly shall falsely make, alter, 
              forge, or counterfeit this certificate, electronic image, or participate in any such action is subject to a fine not more than 
              $1000.00, imprisonment for not less than 1 year, or both </span>
          </td>            
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 140%;"  colspan="3"> 
              <span style="text-align:left"> Type Storage ${data.R4C2} </span>
          </td>       
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 140%;" colspan="9"> 
              <span style="text-align:left"> Vehicle No.  ${data.R5C1} </span>
          </td>            
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 140%;"  colspan="4"> 
              <span style="text-align:left"> Weight Ticket No. ${data.R5C2} </span>
          </td> 
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 140%;"  colspan="1"> 
              <span style="text-align:left"> In &nbsp X </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 140%;"  colspan="1"> 
              <span style="text-align:left"> OUT </span>
          </td>         
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 140%;"  colspan="3" rowspan="2"> 
              <span style="text-align:left"> P.P. No. </br> ${data.R6C1} </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 140%;"  colspan="3" rowspan="2"> 
              <span style="text-align:left"> Type of Inspection </br> ${data.R6C2}&nbsp</span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 140%;"  colspan="3" rowspan="2"> 
              <span style="text-align:left"> Date Inspected </br> ${data.R6C3} </span>
          </td>            
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 140%;"  colspan="6"> 
              <span style="text-align:left"> <b>SECTION II - CALCULATION WORKSHEET</b> </span>
          </td>       
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="2"> 
              <span style="text-align:left"> Seg. ${data.R6_1C1} </span>
          </td> 
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
              <span style="text-align:left"> A. Weight Including Vehicle  </span>
          </td> 
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align:right;"  colspan="1"> 
              <span style="text-align:right;">  ${data.R6_1C3} lbs.</span>
          </td>       
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="1" rowspan="2"> 
              <span style="text-align:left">  No. Bags ${data.R7C1}</span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="1" rowspan="2"> 
              <span style="text-align:left">  Bulk </br> ${data.R7C2} </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="1" rowspan="2"> 
              <span style="text-align:left">  Type </br> ${data.R7C3} </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="2" rowspan="2"> 
              <span style="text-align:left"> Seg. </br> ${data.R7C4} </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="2" rowspan="2"> 
              <span style="text-align:left"> Crop Year: </br> ${data.R7C5} </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="2" rowspan="2"> 
              <span style="text-align:left"> Time </br> ${data.R7C6} </span>
          </td>            
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
              <span style="text-align:left"> <b> HMC </b> </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align:center"  colspan="1"> 
          <span style=""> % </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
              <span style="text-align:left"> B. Weight of Vehicle </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align:right;"  colspan="1"> 
              <span style="text-align:right;"> ${data.R7C10} lbs. </span>
          </td>
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
              <span style="text-align:left"> FM </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
            <span style="text-align:left"> ${data.R7_1C2} </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
              <span style="text-align:left"> C. Gross Weight (A minus B)</span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align:right;"  colspan="1"> 
              <span style="text-align:right;"> ${data.R7_1C4} lbs. </span>
          </td>
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="3"> 
              <span style="text-align:left"> WT. OF FM SAMPLE </span>
          </td>            
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
              <span style="text-align:left"> ${data.R8C2} GR. </span>
          </td> 
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;background-color: lightgrey;font-size: 130%;"  colspan="1"> 
              <span style="text-align:left"> ${data.R8C3 == '' ? ''  : Number(data.R8C3).toFixed(0) + '%'} </span>
          </td> 
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="4"> 
              <span style="text-align:left"> VALENCIA TYPE ONLY </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
            <span style="text-align:left"> LSK </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
            <span style="text-align:left"> ${data.R8C6} </span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
            <span style="text-align:left">D. Foreign Material (% of FM x C)</span>
          </td>
          <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align:right;"  colspan="1"> 
            <span style="text-align:right;"> ${data.R8C8} lbs. </span>
          </td>   
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
          <span style="text-align:left">  FOREIGN MATERIAL </span>
        </td>            
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
          <span style="text-align:left"> ${data.R9C2} GR. </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
          <span style="text-align:left"> ${data.R9C3 = Number(data.R9C3).toFixed(0) + '%'} </span>
        </td> 
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="2"> 
          <span style="text-align:left"> SAMPLE WT </span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
          <span style="text-align:left"> ${data.R9C5} </span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
          <span style="text-align:left"> ${data.R9C6} </span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
          <span style="text-align:left"> MST </span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
          <span style="text-align:left"> ${data.R9C8} </span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
          <span style="text-align:left"> E. Weight Less FM (C minus D)</span>
        </td>
        <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align:right;"  colspan="1"> 
          <span style="text-align:right;width:100%;"> ${data.R9C10} lbs. </span>
        </td>        
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
      <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="5"> 
        <span style="text-align:left;display: inline-block; width: 45%;"> OTHER/UNUSUAL FM </span>
        <span style="text-align:right;display: inline-block; width: 37%;"> ${data.R10C1} </span>
      </td>
      <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="2"> 
        <span style="text-align:left"> CRACKED/BROKEN </span>
      </td>
      <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
        <span style="text-align:left"> ${data.R10C3 == '' ? ''  : Number(data.R10C3).toFixed(0) +'%'} </span>
      </td>
      <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
        <span style="text-align:left"> ${data.R10C4} </span>
      </td>
      <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
        <span style="text-align:left"> SMK </span>
      </td>
      <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
        <span style="text-align:left"> ${data.R10C6} </span>
      </td>
      <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
        <span style="text-align:left"> F. Excess Moisture (% of EM x E)</span>
      </td>
      <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align:right;"  colspan="1"> 
        <span style="text-align:right;"> ${data.R10C8} lbs. </span>
      </td>        
    </tr>

    <tr style="border: 1px solid black;border-collapse:collapse;">
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left"> LSK </span>
    </td>            
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
      <span style="text-align:left"> ${data.R11C2} GR. </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
      <span style="text-align:left"> ${data.R11C3 = Number(data.R11C3).toFixed(0) + '%'} </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="2"> 
      <span style="text-align:left"> DISCOLORED </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
      <span style="text-align:left"> ${data.R11C5} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
      <span style="text-align:left"> ${data.R11C6} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
      <span style="text-align:left"> SS </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
      <span style="text-align:left"> ${data.R11C8} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left"> G. NET WEIGHT (E minus F)  </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align:right;"  colspan="1"> 
      <span style="text-align:right;"> ${data.R11C10} lbs. </span>
    </td>        
  </tr>

  <tr style="border: 1px solid black;border-collapse:collapse;">
  <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="3"> 
    <span style="text-align:left"> CLEANED SAMPLE WT  </span>
  </td>            
  <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
    <span style="text-align:left"> ${data.R12C2} GR. </span>
  </td> 
  <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;background-color: lightgrey;"  colspan="1"> 
    <span style="text-align:left"> ${data.R12C3 == '' ? ''  : Number(data.R12C3).toFixed(0) + '%'} </span>
  </td> 
  <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="4"> 
    <span style="text-align:left"> VIRGINIA TYPE ONLY </span>
  </td>
  <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
    <span style="text-align:left"> SMKRS </span>
  </td>
  <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
    <span style="text-align:left"> ${data.R12C6} </span>
  </td>
  <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
    <span style="text-align:left"> H. LSK (% of LSK x C)  </span>
  </td>
  <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align:right;"  colspan="1"> 
    <span style="text-align:right;"> ${data.R12C8} lbs. </span>
  </td>        
</tr>

<tr style="border: 1px solid black;border-collapse:collapse;">
<td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="3"> 
  <span style="text-align:left"> METER READING/MOISTURE </span>
</td>            
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R13C2} </span>
</td> 
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R13C3} </span>
</td> 
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="2"> 
  <span style="text-align:left"> SAMPLE WT </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R13C5} </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;background-color: lightgrey;"  colspan="1"> 
  <span style="text-align:left"> ${data.R13C6} </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> OK </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R13C8} </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
  <span style="text-align:left"> I. Net Weight excluding LSK (G minus H)</span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align:right;"  colspan="1"> 
  <span style="text-align:right;"> ${data.R13C10} lbs. </span>
</td>        
</tr>

<tr style="border: 1px solid black;border-collapse:collapse;">
<td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="3"> 
  <span style="text-align:left"> TOTAL KRS </span>
</td>            
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R14C2} GR. </span>
</td> 
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;background-color: lightgrey;"  colspan="1"> 
  <span style="text-align:left"> ${data.R14C3 == '' ? ''  : Number(data.R14C3).toFixed(0) + '%'} </span>
</td> 
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="2"> 
  <span style="text-align:left"> BLUE PAN WT </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R14C5} </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;background-color: lightgrey;"  colspan="1"> 
  <span style="text-align:left"> ${data.R14C6} </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> FRZ </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R14C8} </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
  <span style="text-align:left"> J. Kernel Value Per Ton/Lb. (Exc. LSK) </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align:right;"  colspan="1"> 
  <span style="text-align:right;">  $/&cent; ${data.R14C10} </span>
</td>        
</tr>

<tr style="border: 1px solid black;border-collapse:collapse;">
<td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="3"> 
  <span style="text-align:left"> DAMAGED KRS </span>
</td>            
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R15C2} GR. </span>
</td> 
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;background-color: lightgrey;"  colspan="1"> 
  <span style="text-align:left"> ${data.R15C3 == '' ? ''  : Number(data.R15C3).toFixed(0) +'%'} </span>
</td> 
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="2"> 
  <span style="text-align:left"> FANCY </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R15C5} </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R15C6} </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> C RMD </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R15C8} </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
  <span style="text-align:left"> K. ELK Premium </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align:right;"  colspan="1"> 
  <span style="text-align:right;"> $/&cent; ${data.R15C10} </span>
</td>        
</tr>

<tr style="border: 1px solid black;border-collapse:collapse;">
<td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="3"> 
  <span style="text-align:left"> SOUND MATURE KRS </span>
</td>            
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R16C2} GR. </span>
</td> 
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R16C3} </span>
</td> 
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="2"> 
  <span style="text-align:left"> KERNELS RELS </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R16C5} </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;background-color: lightgrey;"  colspan="1"> 
  <span style="text-align:left"> ${data.R16C6} </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1" rowspan="2"> 
  <span style="text-align:left"> Total </br> Kernels </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1" rowspan="2"> 
  <span style="text-align:left"> ${data.R16C8} </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
  <span style="text-align:left"> L. Total (J + K) </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align:right;"  colspan="1"> 
  <span style="text-align:right;"> $/&cent; ${data.R16C10} </span>
</td>        
</tr>


<tr style="border: 1px solid black;border-collapse:collapse;">
<td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="3"> 
  <span style="text-align:left"> SOUND SPLITS </span>
</td>            
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R17C2} GR. </span>
</td> 
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R17C3 = Number(data.R17C3).toFixed(0) + '%'} </span>
</td> 
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="2"> 
  <span style="text-align:left"> ELK DAMAGE </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R17C5} </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;background-color: lightgrey;"  colspan="1"> 
  <span style="text-align:left"> ${data.R17C6} </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> M. Damage </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> Excess FM </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> Excess Splits </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1" rowspan="2"> 
  <span style="text-align:left"> TOTAL </br> </span>
  <span style="text-align:right;"> $/&cent; ${data.R17C10} </span>
</td>        
</tr>

<tr style="border: 1px solid black;border-collapse:collapse;">
<td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="3"> 
  <span style="text-align:left"> TOTAL SMK </span>
</td>            
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;background-color: lightgrey;"  colspan="1"> 
  <span style="text-align:left"> ${data.R18C2} </span>
</td> 
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R18C3 = Number(data.R18C3).toFixed(0) + '%'} </span>
</td> 
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="2"> 
  <span style="text-align:left"> NET ELK </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R18C6} </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R18C5} </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> DAM </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R18C8} </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan=""> 
  <span style="text-align:left"> ${data.R18C9} </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R18C10} </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R18C11} </span>
</td>        
</tr>

<tr style="border: 1px solid black;border-collapse:collapse;">
<td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="3"> 
  <span style="text-align:left"> OTHER KERNELS </span>
</td>            
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R19C2} GR. </span>
</td> 
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R19C3 = Number(data.R19C3).toFixed(0) + '%'} </span>
</td> 
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="2"> 
  <span style="text-align:left"> KERNELS RPS </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R19C5} </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;background-color: lightgrey;"  colspan="1"> 
  <span style="text-align:left"> ${data.R19C6} </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> HULLS </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R19C8} </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3" rowspan="2"> 
  <span style="text-align:left"> N. Net Value Per Ton/Lb. Exc. LSK (L minus M) </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align:right;"  colspan="1" rowspan="2"> 
  <span style="text-align:right;">  $/&cent; ${data.R19C10} </span>
</td>        
</tr>

<tr style="border: 1px solid black;border-collapse:collapse;">
<td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="3"> 
  <span style="text-align:left"> DAMAGED SPLITS </span>
</td>            
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R20C2} GR.  </span>
</td> 
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%; background-color: lightgrey;"  colspan="1"> 
  <span style="text-align:left"> ${data.R20C3 == '' ? ''  : Number(data.R20C3).toFixed(0) + '%'} </span>
</td> 
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="2"> 
  <span style="text-align:left"> FREEZE DAMAGE </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R20C5} GR. </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R20C6} % </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ELK </span>
</td>
<td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
  <span style="text-align:left"> ${data.R20C8} </span>
</td>       
</tr>

<tr style="border: 1px solid black;border-collapse:collapse;">
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left"> TOTAL DAMAGE </span>
    </td>            
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
      <span style="text-align:left"> ${data.R21C2}  GR. </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
      <span style="text-align:left"> ${data.R21C3 = Number(data.R21C3).toFixed(0) +'%'} </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="2"> 
      <span style="text-align:left"> CONCEALED RMD </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
      <span style="text-align:left"> ${data.R21C5} GR. </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
      <span style="text-align:left"> ${data.R21C6} % </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
      <span style="text-align:left"> FANCY </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
      <span style="text-align:left"> ${data.R21C8} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 125%;"  colspan="3"> 
      <span style="text-align:left">O.Value Per Lb.Exc.LSK (N divided by 2000)</span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align:right;"  colspan="1"> 
      <span style="text-align:right;"> ${data.R21C10} &cent; </span>
    </td>        
  </tr>
  
  <tr style="border: 1px solid black;border-collapse:collapse;">
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left"> TOTAL KERNELS </span>
    </td>            
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;background-color: lightgrey;"  colspan="1"> 
      <span style="text-align:left"> ${data.R22C2} </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
      <span style="text-align:left"> ${data.R22C3 = Number(data.R22C3).toFixed(0) + '%'} </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="4"> 
      <span style="text-align:left"> ${data.R22C4} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1" rowspan="2"> 
      <span style="text-align:left"> Tot Krnls & Hulls (Exc. LSK) </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1" rowspan="2"> 
      <span style="text-align:left"> ${data.R22C6} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="4" rowspan="2"> 
      <span style="text-align:left"> P. Value Per Pound Including LSK </br> &nbsp&nbsp N or O x I = $ <u> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${data.R22C7_N} </u>
                                                                      </br> &nbsp&nbsp H x $ ${data.R22C7_M} = $ <u> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${data.R22C7_D} </u> </span>
    </td>       
  </tr>

  <tr style="border: 1px solid black;border-collapse:collapse;">
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left"> HULLS </span>
    </td>            
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
      <span style="text-align:left"> ${data.R23C2} GR. </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="1"> 
      <span style="text-align:left"> ${data.R23C3 = Number(data.R23C3).toFixed(0)+'%'} </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="4"> 
      <span style="text-align:left"> TOTAL KERNELS & HULLS &nbsp&nbsp&nbsp&nbsp ${Number(data.R23C4).toFixed(2)} % </span>
    </td>  
  </tr>

  <tr style="border: 1px solid black;border-collapse:collapse;">
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 150%;"  colspan="9"> 
      <span style="text-align:left">  <b> REMARKS </b></br></br> ${data.R24C1} </br></span>
    </td>            
    <td style="width:5%;border: 1px solid black;${data.R24C3}border-collapse: collapse;font-size: 130%;"  colspan="5"> 
      <span style="text-align:left"> TOTAL = $ &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp; ${data.R24_1C3} &divide; G = </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;text-align:right;"  colspan="1"> 
      <span style="text-align:right"> ${data.R24_1C4} &cent; </span>
    </td>    
  </tr>

  <tr style="border: 1px solid black;border-collapse:collapse;">
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="6" rowspan="3"> 
      <span style="text-align:left"> I, the undersigned, a duly authorized inspector of the United States Department of Agriculture, do hereby certify that, at the request of the applicant and on the date inspected indicated above, samples of the above described products were inspected and the quality and/or condition as shown by said samples were as herein stated. </span>
    </td>            
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 125%;"  colspan="2"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> Applicant No. </br></span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R25C4}</span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 125%;"  colspan="1"> 
      <span style="text-align:left"> R. Unrecptd/Stored </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 125%;"  colspan="2"> 
      <span style="text-align:left">  Applicant No. </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 125%;"  colspan="1"> 
      <span style="text-align:left"> S. Receipted </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 125%;"  colspan="2"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> Applicant No. </br></span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R25C6} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 125%;"  colspan="1"> 
      <span style="text-align:left">  T. Commercial </span>
    </td>     
  </tr>

  <tr style="border: 1px solid black;border-collapse:collapse;">          
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> LBS. (Line G) </span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R26_1C3} </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left"> LBS. (Line G)  </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> LBS. (Line G)  </span>
      <span style="text-align:right;display: inline-block; width: 100%;">  ${data.R26_1C4} </span>
    </td>      
  </tr>

  <tr style="border: 1px solid black;border-collapse:collapse;">          
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> x Line P = $  </span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R27C3} </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> x Line P = $ </span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R27C4} </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> x Line P = $ </span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R27C5} </span>
    </td>
  </tr>

  <tr>
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="6" rowspan="2"> 
      <span style="text-align:left;display: inline-block; width: 47%;"> Signature of Inspector </br></br></br></span>
      <span style="text-align: right;display: inline-block; width: 47%;"> Date Signed </br></br></br></span>
      <span style="text-align:left;display: inline-block; width: 47%;">  ${data.R28C1_Name} </span>
      <span style="text-align: right;display: inline-block; width: 47%;">  ${data.R28C1_Date} </span>
    </td>            
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;">  x  </span>
      <span style="text-align: right;display: inline-block; width: 100%;"> ${data.R28C3 == '' ? '' : Number(data.R28C3).toFixed(2)} % </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> x </span>
      <span style="text-align: right;display: inline-block; width: 100%;"> ${data.R28C4 == '' ? '' : Number(data.R28C4).toFixed(2)} %  </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> x </span>
      <span style="text-align: right;display: inline-block; width: 100%;"> ${data.R28C5 == '' ? '' : Number(data.R28C5).toFixed(2)} % </span>
    </td>    
  </tr>

  <tr>           
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> Value of Segment </br> = $ </span>
      <span style="text-align: right;display: inline-block; width: 100%;"> ${data.R28_1C2 == '' ? '' : Number(data.R28_1C2).toFixed(2)} </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> Value of Segment </br> = $ </span>
      <span style="text-align: right;display: inline-block; width: 100%;"> ${data.R28_1C3 == '' ? '' : Number(data.R28_1C3).toFixed(2)} </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> Value of Segment </br> = $ </span>
      <span style="text-align: right;display: inline-block; width: 100%;"> ${data.R28_1C4 == '' ? '' : Number(data.R28_1C4).toFixed(2)} </span>
    </td>    
  </tr>

  <tr>
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="4" > 
      <span style="text-align:left"> I, the undersigned, a duly licensed weigher, do hereby certify that this certificate is issued under the United States Warehouse Act and in accordance with the regulations thereunder </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="2" > 
      <span style="text-align:left"> <b> Deductions </b> </br> 1. ${data.R29C1} </span>
    </td>            
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;background-color: lightgrey;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> $ </span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R29C3} </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;background-color: lightgrey;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> $ </span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R29C4}
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> $ </span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R29C5} </span>
    </td>    
  </tr>

  <tr>
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 150%;"  colspan="4" rowspan="2" > 
      <span style="text-align:left"> Signature of Licensed Weigher </br></br> ${data.R30C1} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="2" > 
      <span style="text-align:left"> 2. ${data.R30C2} </span>
    </td>            
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> $ </span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R30C3} </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> $ </span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R30C4} </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> $ </span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R30C5} </span>
    </td>    
  </tr>

  <tr>
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="2" > 
      <span style="text-align:left"> 3. ${data.R30_1C1}</span>
    </td>            
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> $ </span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R30_1C2} </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> $ </span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R30_1C3} </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> $ </span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${ data.R30_1C4} </span>
    </td>    
  </tr>

  <tr>
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="4" > 
      <span style="text-align:left"> ${data.R31C1} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="2" > 
      <span style="text-align:left"> 4. ${data.R31C2} </span>
    </td>            
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> $ </span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R31C3} </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> $ </span>
      <span style="text-align:right;display: inline-block; width: 100%;">${data.R31C4} </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> $ </span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R31C5} </span>
    </td>    
  </tr>

  <tr>
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="4" rowspan="2" > 
      <span style="text-align:left">  Date Signed </br> ${data.R32C1} </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="2" > 
      <span style="text-align:left"> 5. ${data.R32C2} </span>
    </td>            
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> $ </span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R32C3} </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> $ </span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R32C4} </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> $ </span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R32C5} </span>
    </td>    
  </tr>

  <tr>
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="2" > 
      <span style="text-align:left"> 6. ${data.R32_1C0}</span>
    </td>            
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> $ </span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R32_1C1} </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> $ </span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R32_1C2} </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> $ </span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R32_1C3} </span>
    </td>    
  </tr>

  <tr>
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;border-bottom:none;font-size: 150%;"  colspan="4" rowspan="2"> 
      <span style="text-align:left"> <b> Q. REMARKS </b> </span>
    </td>
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="2" > 
      <span style="text-align:left"> 7. Net Amount </span>
    </td>            
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left"> $ &nbsp&nbsp&nbsp;  </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> $ </span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R33C4} </span>
    </td> 
    <td style="width:5%;border: 1px solid black;border-collapse: collapse;font-size: 130%;"  colspan="3"> 
      <span style="text-align:left;display: inline-block; width: 100%;"> $ </span>
      <span style="text-align:right;display: inline-block; width: 100%;"> ${data.R33C5} </span>
    </td>
  </tr>

  <tr>
    <td style="width:5%;border: 1px solid black;border-collapse:collapse;font-size: 130%;"  colspan="11" > 
      <span style="text-align:left"> Date Delivered for Immediate Sale: &nbsp&nbsp&nbsp ${data.R33_1C1} </span>
    </td>   
  </tr>

  <tr>
  <td style="width:5%;border: 1px solid black;border-collapse:collapse;border-top:none;font-size: 150%;"  colspan="15"> 
    <span style="text-align:left;display: inline-block;width: 78%;"> ${data.R33C2} </span>
    <span style="text-align:right;display: inline-block;width: 20%;"> ${data.R33C3} </span>
  </td>   
</tr>

  <tr style="border: 1px solid black;border-collapse:collapse;border-color:black white white white;">
    <td style="width:5%;font-size: 150%;border: 1px solid black;border-collapse:collapse;border-color:black white white white;text-align:left;"  colspan="13"> 
      <span style="text-align:left"> <b> Note: THIS IS NOT A WAREHOUSE RECEIPT - NOT NEGOTIABLE </b> </span>
    </td>
    <td style="width:5%;font-size: 150%;color:black white white white;text-align:right;"  colspan="2" > 
      <span style="text-align:right"> <b> ${data.R34C1} </b> </span>
    </td>   
  </tr>
</table> </p>
</html>`
}

async function rptTradeSettlementWorkSheetTemplate (Obj) {
    return `<html>
    <style>
    body:before{
        content: '${process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test'}';
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;  
        color: #0d745e;
        font-size: 250px;
        font-weight: 500px;
        display: grid;
        justify-content: center;
        align-content: center;
        opacity: 0.35;
    }
    @media print {
        @page :footer { display: none }    
        @page :header { display: none }    
        @page { margin-top: 0; margin-bottom: 0; }    
        html,
        body { height: 100%; margin: 0 !important; padding: 0 !important; }
    }     
    </style>
    <p style="page-break-after: always">

    <table style="align-items: center;width: 100%;font-size:70%;padding-top:2%;font-family:'Arial'">
      <tr style="padding-right: 1%;">
        <td style="padding-left:0.01%; padding-right: 1%;">                	
            <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
        </td>
        <td style="padding-left:8%;text-align:center;" colspan="2">
            <br/>
            <span style="font-size:135%;color: black;">
                <b> <u> ${Obj.H1} ${Obj.H2} </u> </b>
                <b> <br/> ${Obj.Date} ${Obj.Time} </b>
            </span>
        </td>
        <td style="padding-left:16%;">
            <span style="text-align-last: left;color:black;font-size:130%;"> <b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
            <span style="text-align: right;color:black;font-size:60%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> (GOLDEN/HANDLER)  </b> <br/></span>
            <span style="text-align: right;color:black;font-size:60%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; <b> 100 NORTH POINT CENTER EAST, SUITE 400</b> <br/></span>
            <span style="text-align: right;color:black;font-size:60%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; <b> ALPHARETTA, GEORGIA 30022-8209 </b> <br/></span>
        </td>
      </tr>
     </table>
     <table style='font-size: 70%;padding-top: 2%;padding-left: 2%;border: 1px solid black;border-collapse:collapse;width:99%;'>
      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:20%;border: 1px solid black;border-collapse:collapse;"  colspan="1"> 
              <span style="text-align:left"> <b>Crop Year: ${Obj.R1C1} </b> </span>
          </td>            
          <td style="width:20%;border: 1px solid black;border-collapse: collapse;"  colspan="1"> 
              <span style="text-align:left"> <b>SC95 #: ${Obj.R1C2}</b> </span>
          </td> 
          <td style="width:20%;border: 1px solid black;border-collapse: collapse;"  colspan="1"> 
              <span style="text-align:left"> <b>Fed.BP #: ${Obj.R1C3}</b> </span>
          </td> 
          <td style="width:20%;border: 1px solid black;border-collapse: collapse;"  colspan="2"> 
              <span style="text-align:left"> <b>1007#: ${Obj.R1C4}</b> </span>
          </td>          
      </tr>
  
      <tr style="text-align:left;border: 1px solid black;border-collapse:collapse;">
          <td style="width:35%;border: 1px solid black;" colspan="2"> 
              <span style="text-align:center"> <b> Buying Point: ${Obj.R2C1} </b> </span>
          </td>            
          <td style="width:35%;border: 1px solid black;" colspan="3"> 
              <span style="text-align:center"> <b> Inspection Date & Time: ${Obj.R2C2} </b> </span>
          </td>         
      </tr>
      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"><b>State: ${Obj.R3C1}</b> </span>
          </td>            
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> <b>County: ${Obj.R3C2}</b> </span>
          </td>
          <td style="width:25%;border: 1px solid black;" colspan="3"> 
              <span style="text-align:center"> <b>Farm #/Name: ${Obj.R3C3}</b> </span>
          </td>           
      </tr>

      <tr style="text-align:left;border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;text-align:center;" colspan="3"> 
              <span style=""> <b> Vendor </b> </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:center;" colspan="2"> 
              <span style="text-align:center"> <b> Share % </b> </span>
          </td>            
      </tr>

      ${Obj.VendorData}

      <tr style="text-align:left;border: 1px solid black;border-collapse:collapse;">
          <td style="width:10%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> Seg:${Obj.R6C1} </span>
          </td>            
          <td style="width:10%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> Type: ${Obj.R6C2} </span>
          </td> 
          <td style="width:10%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> Variety: ${Obj.R6C3} </span>
          </td>            
          <td style="width:10%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> Generation: ${Obj.R6C4} </span>
          </td>
          <td style="width:10%;border: 1px solid black;font-size:80%;" colspan="2"> 
              <span style="text-align:center"> Organic: ${Obj.R6C5} </span>
          </td>           
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> Meets Seed Spec:${Obj.R7C1} </span>
          </td>            
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> Probing Pattern: ${Obj.R7C2} </span>
          </td> 
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> Warehouse: ${Obj.R7C3} </span>
          </td>            
          <td style="width:25%;border: 1px solid black;" colspan="3"> 
              <span style="text-align:center"> Bin: ${Obj.R7C4} </span>
          </td>            
      </tr>

      <tr style="text-align:center;border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> <b> Weight Ticket # </b> </span>
          </td>            
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> <b> Date </b> </span>
          </td> 
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> <b> Gross Weight of Peanuts </b>  </span>
          </td>            
          <td style="width:25%;border: 1px solid black;" colspan="3"> 
              <span style="text-align:center"> <b> Vehicle # </b> </span>
          </td>            
      </tr>

      ${Obj.R9C1}
      ${Obj.eTicketData}

<tr style="border: 1px solid black;border-collapse:collapse;">
  <td style="width:25%;border: 1px solid black;" colspan="5"> 
      <span style="text-align:center"> Cure Clean Ticket No: ${Obj.R11C1} </span>
  </td>                       
</tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> Oleic: H-M-L None </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:center;" colspan="1"> 
              <span style="text-align:center"> ${Obj.R12C2} </span>
          </td> 
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> A. Weight Including Vehicle </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="2"> 
              <span style="text-align:center"> ${Obj.R12C4} &nbsp; </span>
          </td>            
      </tr>
      
      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> Grades: </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
              <span style="text-align:center"> ${Obj.R13C2} &nbsp; </span>
          </td> 
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center">B. Weight of Vehicle </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="2"> 
              <span style="text-align:center"> ${Obj.R13C4} &nbsp; </span>
          </td>            
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> &nbsp;&nbsp;&nbsp; FM </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
              <span style="text-align:center"> ${Obj.R14C2} &nbsp;</span>
          </td> 
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> C. Gross Weight(A-B) </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="2"> 
              <span style="text-align:center"> ${Obj.R14C4} &nbsp;</span>
          </td>            
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp; LSK </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
              <span style="text-align:center"> ${Obj.R15C2} &nbsp;</span>
          </td> 
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> D. Foreign Material (FM x C) </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="2"> 
              <span style="text-align:center"> ${Obj.R15C4} &nbsp;</span>
          </td>            
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp; Moisture </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
              <span style="text-align:center"> ${Obj.R16C2} &nbsp;</span>
          </td> 
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> E. Weight Less FM (C-D) </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="2"> 
              <span style="text-align:center"> ${Obj.R16C4} &nbsp;</span>
          </td>            
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:left;display: inline-block; width:74%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SMK 1</span>
              <span style="text-align:right;display: inline-block; width:24%;"> ${Obj.R17C2} &nbsp;</span>
          </td>            
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
          </td> 
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> F. Excess Moisture (EM x E) </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="2"> 
              <span style="text-align:left;"> ${Obj.R17C4} &nbsp;</span>
          </td>            
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:left;display: inline-block; width:74%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SMK2 </span>
              <span style="text-align:right;display: inline-block; width:24%;"> ${Obj.R18C2} &nbsp;</span>
          </td>            
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
          </td> 
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> G. Net Weight (E-F) </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="2"> 
              <span style="text-align:center"> ${Obj.R18C4} &nbsp;</span>
          </td>            
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:left;display: inline-block; width:74%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SMK3 </span>
              <span style="text-align:right;display: inline-block; width:24%;"> ${Obj.R19C2} &nbsp;</span>
          </td>            
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
          </td> 
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> H. LSK (LSK x C) </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="2"> 
              <span style="text-align:center"> ${Obj.R19C4} &nbsp;</span>
          </td>            
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:left;display: inline-block; width:74%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SMK4 </span>
              <span style="text-align:right;display: inline-block; width:24%;"> ${Obj.R20C2} &nbsp;</span>
          </td>            
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
          </td> 
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> I. Net Weight Excluding LSK (G - H) </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:left;text-align:right;" colspan="2"> 
              <span style="text-align:left;"> ${Obj.R20C4} &nbsp;</span>
          </td>            
      </tr>
      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp; Total SMK </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
              <span style="text-align:center"> ${Obj.R21C2} &nbsp;</span>
          </td> 
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> J. Kernal Value/Ton (Excluding LSK) </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="2"> 
              <span style="text-align:left;"> ${Obj.R21C4} &nbsp;</span>
          </td>            
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp; SS </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
              <span style="text-align:center"> ${Obj.R22C2} &nbsp;</span>
          </td> 
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> K. ELK Premium  </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="2"> 
              <span style="text-align:left;"> ${Obj.R22C4} &nbsp;</span>
          </td>            
      </tr>
      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp; Total (SMK + SS) </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
              <span style="text-align:center"> ${Obj.R23C2} &nbsp;</span>
          </td> 
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> L. Total (J + K) </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="2"> 
              <span style="text-align:left;"> ${Obj.R23C4} &nbsp;</span>
          </td>            
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> &nbsp;&nbsp;&nbsp; Freeze Damage </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
              <span style="text-align:center"> ${Obj.R24C2} &nbsp;</span>
          </td> 
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align: left;display: inline-block;width: 73%;"> Damage </span>
              <span style="text-align: right;display: inline-block;width: 23%;"> ${Obj.R24C4} </span>
          </td>            
          <td style="width:25%;border: 1px solid black;" colspan="2"> 
              <span style="text-align:center"> </span>
          </td>            
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> &nbsp;&nbsp;&nbsp; Concealed RMD </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
              <span style="text-align:center"> ${Obj.R25C2} &nbsp;</span>
          </td> 
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align: left;display: inline-block;width: 73%;"> Excess FM </span>
              <span style="text-align: right;display: inline-block;width: 23%;"> ${Obj.R25C4}  </span>
          </td>            
          <td style="width:25%;border: 1px solid black;" colspan="2"> 
              <span style="text-align:center"> </span>
          </td>            
      </tr>
      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> &nbsp;&nbsp;&nbsp; Total Damage </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
              <span style="text-align:center"> ${Obj.R26C2} &nbsp;</span>
          </td> 
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align: left;display: inline-block;width: 73%;"> Excess Splits </span>
              <span style="text-align: right;display: inline-block;width: 23%;"> ${Obj.R26C4} </span>
          </td>            
          <td style="width:25%;border: 1px solid black;" colspan="2"> 
              <span style="text-align:center"> </span>
          </td>            
      </tr>
      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> &nbsp;&nbsp;&nbsp; Other Kernals  </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
              <span style="text-align:center"> ${Obj.R27C2} &nbsp;</span>
          </td> 
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align: left;display: inline-block;width: 73%;">  Flavus Splits </span>
              <span style="text-align: right;display: inline-block;width: 23%;"> ${Obj.R27C4} </span>
          </td>            
          <td style="width:25%;border: 1px solid black;" colspan="2"> 
              <span style="text-align:center"> </span>
          </td>            
      </tr>
     
      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> &nbsp;&nbsp;&nbsp; Total Kernals </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
              <span style="text-align:left;"> ${Obj.R28C2}&nbsp; </span>
          </td> 
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> M. Total Discounts </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="2"> 
              <span style="text-align:center"> ${Obj.R28C4}&nbsp; </span>
          </td>            
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> &nbsp;&nbsp;&nbsp; Hulls  </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
              <span style="text-align:left;"> ${Obj.R29C2}&nbsp; </span>
          </td> 
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> N. Value/Ton Excluding LSK (L - M) </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="2"> 
              <span style="text-align:center"> ${Obj.R29C4}&nbsp; </span>
          </td>            
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> &nbsp;&nbsp;&nbsp; Total Kernals & Hulls </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
              <span style="text-align:left;"> ${Obj.R30C2}&nbsp; </span>
          </td> 
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center"> Estimated Value Per Pound </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="2"> 
              <span style="text-align:center"> ${Obj.R30C4} &cent; &nbsp;</span>
          </td>            
      </tr>
      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp; Vicam </span>
          </td>            
          <td style="width:25%;border: 1px solid black;color:black white black black;text-align:right;" colspan="1"> 
              <span style="text-align:center"> ${Obj.R31C2} &nbsp;</span>
          </td>                                             
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp; Hull Brightness </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
              <span style="text-align:center"> ${Obj.R32C2} &nbsp;</span>
          </td> 
          <td style="width:16%;border: 1px solid black;" colspan="1">                 
          </td>
          <td style="width:12%;border: 1px solid black;text-align:center;" colspan="1">   
              <span style="text-align:center"> Storage </span>              
          </td>
          <td style="width:20%;border: 1px solid black;text-align:center;" colspan="1">  
              <span style="text-align:center"> Purchase </span>               
          </td>                                             
      </tr>
      
      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp; In Shell Jumbo </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
              <span style="text-align:center"> ${Obj.R33C2} &nbsp; </span>
          </td> 
          <td style="width:16%;border: 1px solid black;" colspan="1">   
              <span style="text-align:center"> LBS </span>              
          </td>
          <td style="width:12%;border: 1px solid black;text-align:right;" colspan="1">       
              <span style="text-align:center"> ${Number(Obj.R33C4).toFixed(0)} &nbsp;</span>          
          </td>
          <td style="width:20%;border: 1px solid black;text-align:right;" colspan="1">  
              <span style="text-align:center"> ${Number(Obj.R33C5).toFixed(0)} &nbsp;</span>               
          </td>                                   
      </tr>
      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
              <span style="text-align:center;"> Fancy </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
              <span style="text-align:center"> ${Obj.R34C2} &nbsp;</span>
          </td> 
          <td style="width:16%;border: 1px solid black;" colspan="1">   
              <span style="text-align:center"> x Line P </span>              
          </td>
          <td style="width:12%;border: 1px solid black;text-align:right;" colspan="1">       
              <span style="text-align:center"> ${Number(Obj.R34C4).toFixed(2)} &nbsp;</span>          
          </td>
          <td style="width:20%;border: 1px solid black;text-align:right;" colspan="1">  
              <span style="text-align:center"> ${Number(Obj.R34C5).toFixed(2)} &nbsp;</span>               
          </td>                                   
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
              <span style="text-align:center;"> ELK </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
              <span style="text-align:center"> ${Obj.R35C2} &nbsp;</span>
          </td> 
          <td style="width:16%;border: 1px solid black;" colspan="1">   
              <span style="text-align:center"> x </span>              
          </td>
          <td style="width:12%;border: 1px solid black;text-align:right;" colspan="1">       
              <span style="text-align:center"> ${Number(Obj.R35C4).toFixed(2)} &nbsp;</span>          
          </td>
          <td style="width:20%;border: 1px solid black;text-align:right;" colspan="1">  
              <span style="text-align:center"> ${Number(Obj.R35C5).toFixed(2)} &nbsp;</span>               
          </td>                                   
      </tr>
      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
              <span style="text-align:center;"> Cracked & Broken </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
              <span style="text-align:center"> ${Obj.R36C2} &nbsp;</span>
          </td> 
          <td style="width:16%;border: 1px solid black;" colspan="1">   
              <span style="text-align:center"> Farmer Stock Cost </span>              
          </td>
          <td style="width:12%;border: 1px solid black;text-align:right;" colspan="1">       
              <span style="text-align:center"> ${Number(Obj.R36C4).toFixed(2)} &nbsp;</span>          
          </td>
          <td style="width:20%;border: 1px solid black;text-align:right;" colspan="1">  
              <span style="text-align:center"> ${Number(Obj.R36C5).toFixed(2)} &nbsp;</span>               
          </td>                                   
      </tr>
      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;border: 1px solid black;" colspan="1"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp; Discolored </span>
          </td>            
          <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
              <span style="text-align:center"> ${Obj.R37C2} &nbsp;</span>
          </td> 
          <td style="width:16%;border: 1px solid black;" colspan="1">   
              <span style="text-align:center"> Value of Segment </span>              
          </td>
          <td style="width:12%;border: 1px solid black;text-align:right;" colspan="1">       
              <span style="text-align:center"> ${Number(Obj.R37C4).toFixed(2)} &nbsp;</span>          
          </td>
          <td style="width:20%;border: 1px solid black;text-align:right;" colspan="1">  
              <span style="text-align:center"> ${Number(Obj.R37C5).toFixed(2)} &nbsp;</span>               
          </td>                                   
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;color:black black white ;;" colspan="2"> 
          </td>            
          <td style="width:12%;border: 1px solid black;" colspan="1">
                <span style="text-align:center"> Option Payment </span>                 
          </td>
          <td style="width:20%;border: 1px solid black;text-align:right;" colspan="1">  
              <span style="text-align:center"> ${Number(Obj.R38C2).toFixed(2)} &nbsp;</span>               
          </td>   
          <td style="width:20%;border: 1px solid black;text-align:right;" colspan="1">  
              <span style="text-align:center"> ${Number(Obj.R38C3).toFixed(2)} &nbsp;</span>               
          </td>                                
      </tr>

      <tr style="border: 1px solid black;border-collapse:collapse;">
          <td style="width:25%;color:black black white white;" colspan="2"> 
          </td>            
          <td style="width:12%;border: 1px solid black;" colspan="1">       
              <span style="text-align:center"> Premium / Deductions: </span>          
          </td>
          <td style="width:20%;border: 1px solid black;text-align:right;" colspan="1">  
              <span style="text-align:center"> ${Obj.R39C2} &nbsp;</span>               
          </td>   
          <td style="width:20%;border: 1px solid black;text-align:right;" colspan="1">  
              <span style="text-align:center"> ${Obj.R39C3} &nbsp;</span>               
          </td>                                
      </tr>

    ${Obj.PremDeductsData}

      <tr style="border: 1px solid black;border-collapse:collapse;text-align:center;border-top: none;">
          <td style="width:25%;" colspan="2"> 
              <span style="text-align:center;font-size: 100%;border-top: none;">  </span>
          </td>            
          <td style="width:12%;border: 1px solid black;" colspan="1">       
              <span style="text-align:center"> Net Amount </span>          
          </td>
          <td style="width:20%;border: 1px solid black;text-align:right;" colspan="1">  
              <span style="text-align:center"> ${Number(Obj.R59C2).toFixed(2)} &nbsp;</span>               
          </td>   
          <td style="width:20%;border: 1px solid black;text-align:right;" colspan="1">  
              <span style="text-align:center"> ${Number(Obj.R59C3).toFixed(2)} &nbsp;</span>               
          </td>                                
      </tr>
  </table>  
    </p>
    </html>
    `
}

async function getHeader(pageCount){
  var renderHeader = '';
  renderHeader = `  
  <table style="align-items: center;width: 100%;font-size:80%;">
      <tr>
                  <td style="padding-left:2%;padding-right:1%;"colspan="1">                	
                  <img src=${imgUrl} width="130" height="130" className="d-inline-block align-top" alt="Golden Peanut Company" />
                  </td>
                  <td style="padding-left:10%; padding-right: 0.1%;"colspan="1">
                      <br/>
                      <span style="font-size:140%;color: black;">
                          <b> title </b>
                      </span>
                  </td>
                  <td style="padding-left:0%; padding-right:2%;padding-top: 0%; vertical-align: top;text-align: end;"colspan="1">
                      <span style="text-align-last: left;color:black;font-size: 130%;"> <b>GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
                      <span style="text-align: right;color:black;font-size: 80%;"><b> 100 NORTH POINT CENTER EAST, SUITE 400 </b> <br/></span>
                      <span style="text-align: right;color:black;font-size: 80%;"><b> ALPHARETTA, GEORGIA 30022-8262 </b><br/><br/><br/></span>
                      <span style="text-align: right;color:black;font-size: 100%; horizontal-align: right;"> Page pageCount of Check: <b> Check_Num</b><br/><br/> </span>
                      <span style="text-align: right;color:black;font-size: 100%; horizontal-align: right;">  <b> Check Status: Check_Status </b> </span>
                      </td>
              </tr>
</table>`
  return renderHeader;
}

async function getOptionContract() {
        let pageCount = 1;
        var renderHeader = ''
        renderHeader = await getHeader(pageCount)
        var optionPage = `<html>
    <p>
    ${renderHeader}
    </p>`
      return optionPage + `</html>` 
}

const getBlobURL = (code, type) => {
    const blob = new Blob([code], { type });
    return URL.createObjectURL(blob)
}

function formatDate(dateVal) {
  var newDate = new Date(dateVal);
  var sMonth = (newDate.getMonth() + 1);
  var sDay = padValue(newDate.getDate());
  var sYear = newDate.getFullYear();
  var sHour = newDate.getHours();
  var sMinute = padValue(newDate.getMinutes());
  var sSecond = padValue(newDate.getSeconds());
  var sAMPM = "AM";

  var iHourCheck = parseInt(sHour);

  if (iHourCheck > 12) {
      sAMPM = "PM";
      sHour = iHourCheck - 12;
  }
  else if (iHourCheck === 0) {
      sHour = "12";
  }
  return sMonth + "/" + sDay + "/" + sYear + " " + sHour + ":" + sMinute + ":" + sSecond + " " + sAMPM;
}

function padValue(value) {
  return (value < 10) ? "0" + value : value;
}

const rptCorrectionDocument = async(FV95number, BuyPtId, bDisplayForm, bBypassCorrectionLogging) => {
  try{
    msReport = "CORRECTION"
    
    let lstrxml = await SettlementService.RetrieveInspectHeaderList(compIdFromLS(), cropYearFromLS(), FV95number, BuyPtId, null)
    
    if(lstrxml.length > 0){
      if(!bBypassCorrectionLogging){
        if(lstrxml[0].bypass_correction_logging == "true")
          bBypassCorrectionLogging = true
      }
    }
    let renCode = redoReport(FV95number, BuyPtId, lstrxml)
//INC4823700 to fix, prod specific issue
    //if(process.env.REACT_APP_ENVIR == 'PROD'){
     // if(!mbPrintSelected)
     //   return
    //}

    if(!bDisplayForm){
     if(mbPrintSelected){
        if(mbPrintPDF){
          
        }          
     }
   }
    return renCode
  }  
  catch(err){
    errorHandler(err)
  }
}

const redoReport = async(FV95number, BuyPtId, lstrxml) => {
  try{
    msPDF_SettlementNo = lstrxml[0].settlement_num
    msPDF_InspectionNo = FV95number
    msPDF_BP = BuyPtId

    let LblnFirst = false
    let renCode = await CorrectionDocument(lstrxml, false)

    if(LblnFirst){
      LblnFirst = false
      bBypassCorrectionLogging = true
      redoReport(FV95number, BuyPtId, lstrxml)
    }
    return renCode;
  }
  catch(err){
    errorHandler(err)
  }
}

const CorrectionDocumentHeader = async(obj) => {
  try{
    return `<table style="align-items: center;width: 100%;font-size:80%;">  
    <tr style="padding-left:1%; padding-right: 1%;">
                  <td style="padding-left:0.01%; padding-right: 1%;">                	
                  <img src=${imgUrl} ${imgDimension} className="d-inline-block align-top" alt="Golden Peanut Company" />
                  </td>
                  <td style="padding-left:10%;text-align: center;">
                      <br/>
                      <span style="font-size:110%;color: black;">
                          <b> <u> Correction Document <br/> ${obj.heading} </u> </b>
                          <b> <br/> ${formatDate(new Date(obj.changeDate))} </b>
                      </span>
                  </td>
                  <td style="padding-left:${obj.heading.slice(0,-2) == 'Correction ' ? 21 : 12}%;">
                      <span style="text-align-last: left;color:black;font-size:90%;"> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;<b> GOLDEN PEANUT COMPANY, LLC </b> <br/></span>
                      <span style="text-align: right;color:black;font-size:60%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b> (GOLDEN / HANDLER) </b> <br/></span>
                      <span style="text-align: right;color:black;font-size:60%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; <b> 100 NORTH POINT CENTER EAST, SUITE 400</b> <br/></span>
                      <span style="text-align: right;color:black;font-size:60%;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b> ALPHARETTA, GEORGIA 30022-8209 </b> <br/></span>
                  </td>
      </tr>
  </table>
  <table style="font-size: 65%;padding-top: 1%;padding-left: 1%;padding-bottom:2%;width:99%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
      <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
          <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Crop Year:  </b> &nbsp; ${obj.cropyear} </span>
          </td>
          <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Fed. BP #: </b> &nbsp; ${obj.fedBP} </span>
          </td>
          <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; ${obj.sc95Label} </b> &nbsp; ${obj.sc95} </span>
          </td>
          <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; 1007#: &nbsp; </b> ${obj.s1007} </span>
          </td>          
      </tr>
      <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
          <td style="width:50%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;" colspan="2"> 
              <span style="text-align:left"> <b> &nbsp; Buying Point: </b> &nbsp; ${obj.buyingPt} </span>
          </td>
          <td style="width:50%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"  colspan="2">
              <span style="text-align:left"> <b> &nbsp; Inspection Date & Time: </b> &nbsp; ${obj.inspDate}  </span>
          </td>        
      </tr>
  </table>`
  }
  catch(err){
    errorHandler(err)
  }
}

const CorrectionDocument = async(pstrXML, bBypassCorrectionLogging) => {
  try{
    let sPrevPremCode = ""
    let dblPremAmt = 0
    let dblTotalCharges = 0
    let sNew1007 = []
    let sOld1007 = []
    let sNewVendorSplits = ""
    let sOldVendorSplits = ""
    let sNewScaleTiks = ""
    let sOldScaleTiks = ""
    let sNewPremDeds = ""
    let sOldPremDeds = ""
    let nListIncrement = 0
    let sCharges = ""
    let dblOldSeg = 0
    let dblNewSeg = 0
    let dblOldDed = 0
    let dblNewDed = 0
    let dblOldNet = 0
    let dblNewNet = 0
    let dblOldFarmerStockCost = 0
    let dblNewFarmerStockCost = 0
    let dblOldOptionPayment = 0
    let dblNewOptionPayment = 0
    let bPrintAmount = false
    let colOldVendors = []
    let colNewVendors = []
    let LarrChanges = [ "", "", "" ]
    let LstrDateTime = ""
    let sNewTicket_Numbers_Cure_Clean = ""
    let sOldTicket_Numbers_Cure_Clean = ""
    let sVACrackedBroken = ""
    
    let lstrxml = pstrXML
    
    if(lstrxml[0].inspect_headers.length > 0){
      sNew1007 = lstrxml[0].inspect_headers[0];
      sNewTicket_Numbers_Cure_Clean = sNew1007.ticket_numbers_cure_clean
      if(lstrxml[0].inspect_headers.length > 1){
        sOld1007 = lstrxml[0].inspect_headers[1];
        sOldTicket_Numbers_Cure_Clean = sOld1007.ticket_numbers_cure_clean
      }
      else{
        sOld1007 = []
        sOldTicket_Numbers_Cure_Clean = ""
      }
    }    

    if(sOldTicket_Numbers_Cure_Clean !== sNewTicket_Numbers_Cure_Clean){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, sOldTicket_Numbers_Cure_Clean, sNewTicket_Numbers_Cure_Clean , "Cure Clean Ticket No(s):")
      }
    }

    LarrChanges[0] = "DESCRIPTION OF CORRECTION(s)"
    LstrDateTime = sOld1007.chg_date_time
    LarrChanges[1] = "ORIGINAL - " + LstrDateTime
    LstrDateTime = sNew1007.chg_date_time
    let pageGap = 0
    LarrChanges[2] = "NEW - " + LstrDateTime
    let stateCountyFarmCorrection = ``
    
    if(sOld1007.state_abbr !== sNew1007.state_abbr){
      LarrChanges.push([ "State:", sOld1007.state_abbr, sNew1007.state_abbr ])
      if(!bBypassCorrectionLogging){
        stateCountyFarmCorrection += `<tr style="">
        <td style="width:30%;text-align:center;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          <b> State: </b>
        </td>            
        <td style="width:35%;text-align:center;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          <b> ${sOld1007.state_abbr} </b>
        </td>
        <td style="width:35%;text-align:center;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          <b> ${sNew1007.state_abbr} </b>
        </td>
      </tr>`
      pageGap++;
      CorrectionLog(sNew1007, sOld1007.state_abbr, sNew1007.state_abbr, "State:")
      }   
    }
    if(sOld1007.county_id !== sNew1007.county_id){
      LarrChanges.push([ "County:", sOld1007.county_id, sNew1007.county_id ])
      if(!bBypassCorrectionLogging){
        stateCountyFarmCorrection += `<tr style="">
        <td style="width:30%;text-align:center;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          <b> County: </b>
        </td>            
        <td style="width:35%;text-align:center;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          <b> ${sOld1007.county_id} </b>
        </td>
        <td style="width:35%;text-align:center;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          <b> ${sNew1007.county_id} </b>
        </td>
      </tr>`
      pageGap++;
      CorrectionLog(sNew1007, sOld1007.county_id  , sNew1007.county_id, "County:")
      }        
    }
    if(sOld1007.farm_id + sOld1007.farm_suffix !== sNew1007.farm_id + sNew1007.farm_suffix){
      LarrChanges.push([ "Farm #:", sOld1007.farm_id, sNew1007.farm_id ])
      if(!bBypassCorrectionLogging){
        stateCountyFarmCorrection += `<tr style="">
        <td style="width:30%;text-align:center;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          <b> Farm #: </b>
        </td>            
        <td style="width:35%;text-align:center;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          <b> ${sOld1007.farm_id + ' ' + sOld1007.farm_suffix} </b>
        </td>
        <td style="width:35%;text-align:center;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          <b> ${sNew1007.farm_id + ' ' + sNew1007.farm_suffix} </b>
        </td>
      </tr>`
      pageGap++;
      CorrectionLog(sNew1007, sOld1007.farm_id  , sNew1007.farm_id, "Farm #:")
      } 
    }

    colOldVendors = []
    colNewVendors = []

    nListIncrement = 0

    sNewVendorSplits = sNew1007.inspect_vendor_splits
    sOldVendorSplits = sOld1007.inspect_vendor_splits
    
    let vendorDataGrid = [ '', '', '', '', '', '' ]
    while(nListIncrement < 20){      
      let oldObj = {}
      let newObj = {}
      
      if(sOldVendorSplits[nListIncrement].split_vendor !== ""){
        oldObj = {
          vendor      : " " + sOldVendorSplits[nListIncrement].split_vendor + " - " + sOldVendorSplits[nListIncrement].vendor_name,
          suppPct     : sOldVendorSplits[nListIncrement].split_share_pct,
          purStoreInd : sOldVendorSplits[nListIncrement].purh_stor_ind == "P" ? "PUR" : "STO"
        }
        if(sOldVendorSplits[nListIncrement].purh_stor_ind == "P"){
          dblOldSeg += (Number(sOldVendorSplits[nListIncrement].value_of_seg) - Number(sOldVendorSplits[nListIncrement].opt_value_of_seg))
          dblOldFarmerStockCost += Number(sOldVendorSplits[nListIncrement].value_of_seg)
          dblOldOptionPayment += Number(sOldVendorSplits[nListIncrement].opt_value_of_seg)
          dblOldDed += (Number(sOldVendorSplits[nListIncrement].premium_amt) + Number(sOldVendorSplits[nListIncrement].deduct_amt))
          dblOldNet += Number(sOldVendorSplits[nListIncrement].proceeds_amt)
        }    
      }    

      if(sNewVendorSplits[nListIncrement].split_vendor !== ""){
        newObj = {
          vendor      : " " + sNewVendorSplits[nListIncrement].split_vendor + " - " + sNewVendorSplits[nListIncrement].vendor_name,
          suppPct     : sNewVendorSplits[nListIncrement].split_share_pct,
          purStoreInd : sNewVendorSplits[nListIncrement].purh_stor_ind == "P" ? "PUR" : "STO"
        }
        if(sNewVendorSplits[nListIncrement].purh_stor_ind == "P"){
          dblNewSeg += (Number(sNewVendorSplits[nListIncrement].value_of_seg) - Number(sNewVendorSplits[nListIncrement].opt_value_of_seg))
          dblNewFarmerStockCost += Number(sNewVendorSplits[nListIncrement].value_of_seg)
          dblNewOptionPayment += Number(sNewVendorSplits[nListIncrement].opt_value_of_seg)
          dblNewDed += (Number(sNewVendorSplits[nListIncrement].premium_amt) + Number(sNewVendorSplits[nListIncrement].deduct_amt))
          dblNewNet += Number(sNewVendorSplits[nListIncrement].proceeds_amt)
        }        
      }

      if(sOldVendorSplits[nListIncrement].split_vendor !== sNewVendorSplits[nListIncrement].split_vendor || sOldVendorSplits[nListIncrement].split_share_pct !== sNewVendorSplits[nListIncrement].split_share_pct || sOldVendorSplits[nListIncrement].purh_stor_ind !== sNewVendorSplits[nListIncrement].purh_stor_ind){
        if(!bBypassCorrectionLogging){
          CorrectionLog(sNew1007, oldObj.vendor, newObj.vendor, "Vendor:")
          CorrectionLog(sNew1007, oldObj.suppPct, newObj.suppPct, "Share%:")
          CorrectionLog(sNew1007, oldObj.purStoreInd, newObj.purStoreInd, "DISP:")
        }
      }

      vendorDataGrid[0] += ('&nbsp;' + oldObj.vendor + '<br>')
      vendorDataGrid[1] += ('&nbsp;' + newObj.vendor + '<br>')
      vendorDataGrid[2] += (oldObj.suppPct + '%<br>')
      vendorDataGrid[3] += (newObj.suppPct + '%<br>')
      vendorDataGrid[4] += (oldObj.purStoreInd + '<br>')
      vendorDataGrid[5] += (newObj.purStoreInd + '<br>')

      nListIncrement++
      if(nListIncrement >= sOldVendorSplits.length)
        break;
    }
    
    let vendorDataArr = `<tr>
        <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
          <span style="text-align:center;"> ${vendorDataGrid[0].slice(0, -5)} </span>
        </td>
        <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:left;"> 
          <span style="text-align:center;"> ${vendorDataGrid[1].slice(0, -5)} </span>
        </td>
        <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
          <span style="text-align:left"> ${vendorDataGrid[2].slice(0, -4)} </span>
        </td>
        <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
          <span style="text-align:left"> ${vendorDataGrid[3].slice(0, -4)} </span>
        </td>
        <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
          <span style="text-align:left"> ${vendorDataGrid[4].slice(0, -4)} </span>
        </td>
        <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
          <span style="text-align:left"> ${vendorDataGrid[5].slice(0, -4)} </span>
        </td>          
      </tr>`

    let scaleTicketArr = ``
    nListIncrement = 0

    sNewScaleTiks = sNew1007.inspect_scale_ticket
    sOldScaleTiks = sOld1007.inspect_scale_ticket

    while(nListIncrement < 8){      
      scaleTicketArr += `<tr>
        <td style="width:16%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
          <span style="text-align:center;"> &nbsp; ${sOldScaleTiks[nListIncrement].dry_ticket} </span>
        </td>
        <td style="width:16%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
          <span style="text-align:center;"> &nbsp; ${sNewScaleTiks[nListIncrement].dry_ticket} </span>
        </td>
        <td style="width:18%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
          <span style="text-align:center;"> &nbsp; ${sOldScaleTiks[nListIncrement].dry_peanut_wt} </span>
        </td>
        <td style="width:18%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
          <span style="text-align:center;"> &nbsp; ${sNewScaleTiks[nListIncrement].dry_peanut_wt} </span>
        </td>
        <td style="width:16%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
          <span style="text-align:center;"> &nbsp; ${sOldScaleTiks[nListIncrement].dry_vehicle_id} </span>
        </td>
        <td style="width:16%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
          <span style="text-align:center;"> &nbsp; ${sNewScaleTiks[nListIncrement].dry_vehicle_id} </span>
        </td>
      </tr>`

      if(sOldScaleTiks[nListIncrement].dry_ticket !== sNewScaleTiks[nListIncrement].dry_ticket || sOldScaleTiks[nListIncrement].dry_peanut_wt !== sNewScaleTiks[nListIncrement].dry_peanut_wt || sOldScaleTiks[nListIncrement].dry_vehicle_id !== sNewScaleTiks[nListIncrement].dry_vehicle_id){
        if(!bBypassCorrectionLogging){
          CorrectionLog(sNew1007, sOldScaleTiks[nListIncrement].dry_ticket, sNewScaleTiks[nListIncrement].dry_ticket, "Wt. Ticket #:")
          CorrectionLog(sNew1007, sOldScaleTiks[nListIncrement].dry_peanut_wt, sNewScaleTiks[nListIncrement].dry_peanut_wt, "Wt. of Peanuts:")
          CorrectionLog(sNew1007, sOldScaleTiks[nListIncrement].dry_vehicle_id, sNewScaleTiks[nListIncrement].dry_vehicle_id, "Vehicle #:")
        }
      }

      nListIncrement++
      if(nListIncrement >= sOldScaleTiks.length)
        break;      
    }
    
    let LstrSCText = ""
    if(sNew1007.superseded_settle !== "")
      LstrSCText = "Superseded " + sNew1007.superseded_settle.trim()
    if(sNew1007.settle_correct_cnt !== "" && Number(sNew1007.settle_correct_cnt) !== 0){
      if(LstrSCText !== "")
        LstrSCText += " & "
      LstrSCText += ("Correction #" + sNew1007.settle_correct_cnt)
    }

    let sSymbolOrg = sOld1007.symbol_ind == "TRADEMARK" ? `&trade;` : sOld1007.symbol_ind == "REGISTERED TRADEMARK" ? `&reg;`  : sOld1007.symbol_ind == "COPYRIGHT" ? `&copy;` : ''
    let sSymbolNew = sNew1007.symbol_ind == "TRADEMARK" ? `&trade;` : sNew1007.symbol_ind == "REGISTERED TRADEMARK" ? `&reg;`  : sNew1007.symbol_ind == "COPYRIGHT" ? `&copy;` : ''
    
    sPrevPremCode = ""
    dblPremAmt = 0
    dblTotalCharges = 0
    sCharges = ""
    bPrintAmount = false
    sOldPremDeds =  sOld1007.inspect_prem_deducts;
    
    for(var i=0; i<sOldPremDeds.length; i++){
      if(sPrevPremCode !== sOldPremDeds[i].pd_code){
        if(bPrintAmount){
          sCharges += (Number(dblPremAmt).toFixed(2) + " ")
          dblPremAmt = 0
          bPrintAmount = false
        }
        sPrevPremCode = sOldPremDeds[i].pd_code
        sCharges += (sOldPremDeds[i].pd_code + " $")

        dblPremAmt += Number(sOldPremDeds[i].pd_amount)
        bPrintAmount = true
      }
      else{
        dblPremAmt += Number(sOldPremDeds[i].pd_amount)
        bPrintAmount = true
      }
      dblTotalCharges += Number(sOldPremDeds[i].pd_amount)
    }
    
    if(bPrintAmount){
      sCharges += Number(dblPremAmt).toFixed(2)
      dblPremAmt = 0
      bPrintAmount = false
    }
    let orgTotal = Number(dblTotalCharges).toFixed(2).toString() + ' : ' +  sCharges

    sPrevPremCode = ""
    dblPremAmt = 0
    dblTotalCharges = 0
    sCharges = ""
    bPrintAmount = false
    sNewPremDeds = sNew1007.inspect_prem_deducts;
    
    for(var i=0; i<sNewPremDeds.length; i++){
      if(sPrevPremCode !== sNewPremDeds[i].pd_code){
        if(bPrintAmount){
          sCharges += (Number(dblPremAmt).toFixed(2) + " ")
          dblPremAmt = 0
          bPrintAmount = false
        }
        sPrevPremCode = sNewPremDeds[i].pd_code
        sCharges += (sNewPremDeds[i].pd_code + " $")

        dblPremAmt += Number(sNewPremDeds[i].pd_amount)
        bPrintAmount = true
      }
      else{
        dblPremAmt += Number(sNewPremDeds[i].pd_amount)
        bPrintAmount = true
      }
      dblTotalCharges += Number(sNewPremDeds[i].pd_amount)
    }
    
    if(bPrintAmount){
      sCharges += Number(dblPremAmt).toFixed(2)
      dblPremAmt = 0
      bPrintAmount = false
    }

    let newTotal = Number(dblTotalCharges).toFixed(2).toString() + ' : ' + sCharges
    let sRemarks_correction = print_ContractVendor_Splts(lstrxml[0])
    
    if(sVACrackedBroken !== '' && sVACrackedBroken != undefined && sVACrackedBroken != null)
      sRemarks_correction += ("VA Cracked and Broken: " + Number(sVACrackedBroken).toFixed(2))
    
    let vendorCorrectionData = ``
    for(var i=0; i<sNew1007.inspect_vendor_splits.length; i++){
      if((sOld1007.inspect_vendor_splits[i].split_share_pct !== sNew1007.inspect_vendor_splits[i].split_share_pct) || (sOld1007.inspect_vendor_splits[i].purh_stor_ind !== sNew1007.inspect_vendor_splits[i].purh_stor_ind)){
        vendorCorrectionData += `<tr>
        <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>  Vendor: </b>
        </td>            
        <td style="width:35%;text-align:left;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          &nbsp; <b> ${sOld1007.inspect_vendor_splits[i].split_vendor} - ${sOld1007.inspect_vendor_splits[i].vendor_name} </b> 
        </td>
        <td style="width:35%;text-align:left;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          &nbsp; <b> ${sNew1007.inspect_vendor_splits[i].split_vendor} - ${sNew1007.inspect_vendor_splits[i].vendor_name} </b> 
        </td>
        </tr>`
        pageGap++;
        vendorCorrectionData += `<tr>
        <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; Share %:
        </td>            
        <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          ${sOld1007.inspect_vendor_splits[i].split_share_pct} % &nbsp; 
        </td>
        <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          ${sNew1007.inspect_vendor_splits[i].split_share_pct} % &nbsp; 
        </td>
        </tr>`
        pageGap++;
        vendorCorrectionData += `<tr>
        <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; DISP:
        </td>            
        <td style="width:35%;text-align:left;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          &nbsp; ${sOld1007.inspect_vendor_splits[i].purh_stor_ind == 'P' ? 'PUR' : 'STO' } &nbsp; 
        </td>
        <td style="width:35%;text-align:left;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          &nbsp; ${sNew1007.inspect_vendor_splits[i].purh_stor_ind == 'P' ? 'PUR' : 'STO' } &nbsp; 
        </td>
        </tr>`
        pageGap++;
      }         
    }

    let oldContractNumber = ''
    for(var i=0; i<sOld1007.contract_applications.length; i++){
      oldContractNumber += (sOld1007.contract_applications[i].contract_numbers + ', ')
    }

    let newContractNumber = ''
    for(var i=0; i<sNew1007.contract_applications.length; i++){
      newContractNumber += (sNew1007.contract_applications[i].contract_numbers + ', ')
    }
        
    if(oldContractNumber.slice(-2) == ', ')
      oldContractNumber = oldContractNumber.slice(0,-2)
    if(newContractNumber.slice(-2) == ', ')
      newContractNumber = newContractNumber.slice(0,-2)

    let deductionData = ``
    if(oldContractNumber !== newContractNumber){
    deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Contract(s):
      </td>            
      <td style="width:35%;text-align:left;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp; ${oldContractNumber}
      </td>
      <td style="width:35%;text-align:left;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp; ${newContractNumber}
      </td>
    </tr>`
    if(!bBypassCorrectionLogging){
      CorrectionLog(sNew1007, oldContractNumber, newContractNumber, "Contract(s):")
    }
    pageGap++;
    }

    if(sOld1007.oleic_ind !== sNew1007.oleic_ind){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Oleic:
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${sOld1007.oleic_ind}
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${sNew1007.oleic_ind}
      </td>
    </tr>`
    if(!bBypassCorrectionLogging){
      CorrectionLog(sNew1007, sOld1007.oleic_ind, sNew1007.oleic_ind, "Oleic:")
    }
    pageGap++;
    }
    
    if(sOld1007.unld_whse_id + ' - ' + sOld1007.unld_bin_id !== sNew1007.unld_whse_id + ' - ' + sNew1007.unld_bin_id){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
        Unld. Whse. Bin.:
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${sOld1007.unld_whse_id  + ' - ' + sOld1007.unld_bin_id}
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${sNew1007.unld_whse_id + ' - ' + sNew1007.unld_bin_id}
      </td>
    </tr>`
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, sOld1007.unld_whse_id  + ' - ' + sOld1007.unld_bin_id, sNew1007.unld_whse_id  + ' - ' + sNew1007.unld_bin_id, "Unld. Whse. Bin.:")
      }
      pageGap++;
    }
    
    if(sOld1007.obl_buy_id + ' - ' + sOld1007.obl_whse_id !== sNew1007.obl_buy_id + ' - ' + sNew1007.obl_whse_id){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
        Obligation:
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${sOld1007.obl_buy_id  + ' - ' + sOld1007.obl_whse_id}
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${sNew1007.obl_buy_id + ' - ' + sNew1007.obl_whse_id}
      </td>
    </tr>`
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, sOld1007.obl_buy_id  + ' - ' + sOld1007.obl_whse_id, sNew1007.obl_buy_id  + ' - ' + sNew1007.obl_whse_id, "Obligation:")
      }
      pageGap++;
    }
    
    if(sOld1007.organic_ind !== sNew1007.organic_ind){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
        Organic:
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${sOld1007.organic_ind}
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${sNew1007.organic_ind}
      </td>
    </tr>`
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, sOld1007.organic_ind, sNew1007.organic_ind, "Organic:")
      }
      pageGap++;
    }
    
    for(var i=0; i<sOldScaleTiks.length; i++){
      if(i == 8){
        break;
      }
      if(sOldScaleTiks[i].dry_ticket !== sNewScaleTiks[i].dry_ticket || sOldScaleTiks[i].dry_peanut_wt !== sNewScaleTiks[i].dry_peanut_wt || sOldScaleTiks[i].dry_vehicle_id !== sNewScaleTiks[i].dry_vehicle_id){
        deductionData += `<tr>
        <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <b> Wt. Ticket #: </b>
        </td>            
        <td style="width:35%;text-align:left;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          &nbsp; <b> ${sOldScaleTiks[i].dry_ticket} </b>
        </td>
        <td style="width:35%;text-align:left;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          &nbsp; <b> ${sNewScaleTiks[i].dry_ticket} </b>
        </td>
        </tr>`
        pageGap++;
        deductionData += `<tr>
        <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
          Wt. of Peanuts:
        </td>            
        <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          ${sOldScaleTiks[i].dry_peanut_wt}
        </td>
        <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          ${sNewScaleTiks[i].dry_peanut_wt}
        </td>
        </tr>`
        pageGap++;
        deductionData += `<tr>
        <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
          Vehicle#:
        </td>            
        <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          ${sOldScaleTiks[i].dry_vehicle_id}  &nbsp;
        </td>
        <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          ${sNewScaleTiks[i].dry_vehicle_id}  &nbsp;
        </td>
        </tr>`
        pageGap++;
      }
    }

    if(sOld1007.dry_ticket !== sNew1007.dry_ticket){      
    }
    
    if(sOld1007.dry_peanut_wt !== sNew1007.dry_peanut_wt){
    }
    
    if(sOld1007.dry_vehicle_id !== sNew1007.dry_vehicle_id){      
    }

    if(sOld1007.tare_wt !== sNew1007.tare_wt){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
        A. Weight Including Vehicle:
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${sOld1007.tare_wt} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${sNew1007.tare_wt} &nbsp;
      </td>
      </tr>`
      pageGap++;
    }

    if(sOld1007.vehicle_wt !== sNew1007.vehicle_wt){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        B. Weight of Vehicle:
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${sOld1007.vehicle_wt} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${sNew1007.vehicle_wt} &nbsp;
      </td>
      </tr>`
      pageGap++;
    }

    if(sOld1007.gross_wt !== sNew1007.gross_wt){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        C. Gross Weight (A - B):
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${sOld1007.gross_wt} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${sNew1007.gross_wt} &nbsp;
      </td>
      </tr>`
      pageGap++;
    }

    if(sOld1007.fm_wt !== sNew1007.fm_wt){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        D. Foreign Material (FM * C):
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${sOld1007.fm_wt} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${sNew1007.fm_wt} &nbsp;
      </td>
      </tr>`
      pageGap++;
    }

    if(Number(sOld1007.gross_wt) - Number(sOld1007.fm_wt) !== Number(sNew1007.gross_wt) - Number(sNew1007.fm_wt)){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        E. Weight Less FM (C - D):
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sOld1007.gross_wt) - Number(sOld1007.fm_wt)} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sNew1007.gross_wt) - Number(sNew1007.fm_wt)} &nbsp;
      </td>
      </tr>`
      pageGap++;
    }

    if(sOld1007.ex_moist_wt !== sNew1007.ex_moist_wt){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        F. Excess Moisture (EM * E):
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${sOld1007.ex_moist_wt} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${sNew1007.ex_moist_wt} &nbsp;
      </td>
      </tr>`
      pageGap++;
    }

    if(sOld1007.net_wt !== sNew1007.net_wt){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        G. Net Weight (E - F):
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${sOld1007.net_wt} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${sNew1007.net_wt} &nbsp;
      </td>
      </tr>`
      pageGap++;
    }

    if(sOld1007.lsk_wt !== sNew1007.lsk_wt){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        H. LSK (LSK * C):
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${sOld1007.lsk_wt} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${sNew1007.lsk_wt} &nbsp;
      </td>
      </tr>`
      pageGap++;
    }

    if(Number(sOld1007.net_wt) - Number(sOld1007.lsk_wt) !== Number(sNew1007.net_wt) - Number(sNew1007.lsk_wt)){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        I. Net Weight Excl LSK (G - H):
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sOld1007.net_wt) - Number(sOld1007.lsk_wt)} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sNew1007.net_wt) - Number(sNew1007.lsk_wt)} &nbsp;
      </td>
      </tr>`
      pageGap++;
    }

    if(Number(sOld1007.kern_value) !== Number(sNew1007.kern_value)){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        J. Kernel Value/Ton (Excluding LSK):
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sOld1007.kern_value)} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sNew1007.kern_value)} &nbsp;
      </td>
      </tr>`
      pageGap++;
    }

    if(Number(sOld1007.elk_prem) !== Number(sNew1007.elk_prem)){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        K. ELK Premium:
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sOld1007.elk_prem)} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sNew1007.elk_prem)} &nbsp;
      </td>
      </tr>`
      pageGap++;
    }

    if(Number(sOld1007.kern_value) - Number(sOld1007.elk_prem) !== Number(sNew1007.kern_value) - Number(sNew1007.elk_prem)){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        L. Total (J + K):
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sOld1007.kern_value) - Number(sOld1007.elk_prem)} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sNew1007.kern_value) - Number(sNew1007.elk_prem)} &nbsp;
      </td>
      </tr>`
      pageGap++;
    }

    if(Number(sOld1007.dam_discount) !== Number(sNew1007.dam_discount)){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Damage
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sOld1007.dam_discount)} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sNew1007.dam_discount)} &nbsp;
      </td>
      </tr>`
      pageGap++;
    }

    if(Number(sOld1007.ex_fm_discount) !== Number(sNew1007.ex_fm_discount)){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Excess FM"
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sOld1007.ex_fm_discount)} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sNew1007.ex_fm_discount)} &nbsp;
      </td>
    </tr>`
    pageGap++;
    }
    
    if(Number(sOld1007.ex_ss_discount) !== Number(sNew1007.ex_ss_discount)){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Excess Splits
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sOld1007.ex_ss_discount)} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sNew1007.ex_ss_discount)} &nbsp;
      </td>
    </tr>`
    pageGap++;
    }
    
    if(Number(sOld1007.flavus_discount) !== Number(sNew1007.flavus_discount)){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Flavus Splits
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sOld1007.flavus_discount)} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sNew1007.flavus_discount)} &nbsp;
      </td>
      </tr>`
      pageGap++;
    }

    if(Number(sOld1007.dam_discount) !== Number(sNew1007.dam_discount)){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        M. Total Discounts:
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sOld1007.dam_discount)} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sNew1007.dam_discount)} &nbsp;
      </td>
      </tr>`
      pageGap++;
    }

    if(Number(sOld1007.net_les_lsk_val) !== Number(sNew1007.net_les_lsk_val)){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        N. Value/Ton Excl LSK (L- M):
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sOld1007.net_les_lsk_val)} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sNew1007.net_les_lsk_val)} &nbsp;
      </td>
      </tr>`
      pageGap++;
    }

    if(Number(sOld1007.net_price) !== Number(sNew1007.net_price)){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Estimated Value Per Pound:
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sOld1007.net_price)} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sNew1007.net_price)} &nbsp;
      </td>
      </tr>`
      pageGap++;
    }

    if(Number(sOld1007.basis_grade_amt) !== Number(sNew1007.basis_grade_amt)){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Total (100% Basis Grade):
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sOld1007.basis_grade_amt).toFixed(2)} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${Number(sNew1007.basis_grade_amt).toFixed(2) } &nbsp;
      </td>
    </tr>`
    pageGap++;
    }

    dblOldFarmerStockCost = dblOldFarmerStockCost == 0 ? '0.00' : Intl.NumberFormat('en-US').format(Number(dblOldFarmerStockCost).toFixed(2))
    dblNewFarmerStockCost = dblNewFarmerStockCost == 0 ? '0.00' : Intl.NumberFormat('en-US').format(Number(dblNewFarmerStockCost).toFixed(2))
    if(dblOldFarmerStockCost !== dblNewFarmerStockCost){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farmer Stock Cost
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${dblOldFarmerStockCost} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${dblNewFarmerStockCost} &nbsp;
      </td>
    </tr>`
    pageGap++;
    }
    
    dblOldSeg = dblOldSeg == 0 ? '0.00' : Intl.NumberFormat('en-US').format(Number(dblOldSeg).toFixed(2))
    dblNewSeg = dblNewSeg == 0 ? '0.00' : Intl.NumberFormat('en-US').format(Number(dblNewSeg).toFixed(2))
    if(dblOldSeg !== dblNewSeg){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Segment Value:
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${dblOldSeg} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${dblNewSeg} &nbsp;
      </td>
    </tr>`
    pageGap++;
    }
    
    dblOldOptionPayment = dblOldOptionPayment == 0 ? '0.00' : Intl.NumberFormat('en-US').format(Number(dblOldOptionPayment).toFixed(2))
    dblNewOptionPayment = dblNewOptionPayment == 0 ? '0.00' : Intl.NumberFormat('en-US').format(Number(dblNewOptionPayment).toFixed(2))
    if(dblOldOptionPayment !== dblNewOptionPayment){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Option Payment
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${dblOldOptionPayment} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${dblNewOptionPayment} &nbsp;
      </td>
    </tr>`
    pageGap++;
    }
    
    dblOldDed = dblOldDed == 0 ? '0.00' : Intl.NumberFormat('en-US').format(Number(dblOldDed).toFixed(2))
    dblNewDed = dblNewDed == 0 ? '0.00' : Intl.NumberFormat('en-US').format(Number(dblNewDed).toFixed(2))
    if(dblOldDed !== dblNewDed){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Deductions
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${dblOldDed} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${dblNewDed} &nbsp;
      </td>
    </tr>`
    pageGap++;
    }
    
    dblOldNet = dblOldNet == 0 ? '0.00' : Intl.NumberFormat('en-US').format(Number(dblOldNet).toFixed(2))
    dblNewNet = dblNewNet == 0 ? '0.00' : Intl.NumberFormat('en-US').format(Number(dblNewNet).toFixed(2))
    if(dblOldNet !== dblNewNet){
      deductionData += `<tr>
      <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Net
      </td>            
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${dblOldNet} &nbsp;
      </td>
      <td style="width:35%;text-align:right;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
        ${dblNewNet} &nbsp;
      </td>
    </tr>`
    pageGap++;
    }

    dblOldNet = Number(dblOldNet) <= 0 ? '0.00' : dblOldNet
    dblNewNet = Number(dblNewNet) <= 0 ? '0.00' : dblNewNet
    
    let correctionTable = ``
    if(stateCountyFarmCorrection == '' && vendorCorrectionData == '' && deductionData == ''){
      correctionTable = ''
    }
    else{
      stateCountyFarmCorrection = `<tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <td style="width:30%;text-align:center;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          <b> ${LarrChanges[0]} </b>
        </td>            
        <td style="width:35%;text-align:center;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          <b> ${LarrChanges[1]} </b>
        </td>
        <td style="width:35%;text-align:center;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
          <b> ${LarrChanges[2]} </b>
        </td>
      </tr>` + stateCountyFarmCorrection;
      pageGap++;
      correctionTable = `<table style="font-size: 75%;padding-top:10%;width:99%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
          
      ${stateCountyFarmCorrection}
      ${vendorCorrectionData}
      ${deductionData}
    </table>`
    }

    if(sOld1007.tare_wt !== sNew1007.tare_wt){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007,sOld1007.tare_wt, sNew1007.tare_wt, "A. Weight Including Vehicle:")
      }
    }

    if(sOld1007.vehicle_wt !== sNew1007.vehicle_wt){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, sOld1007.vehicle_wt, sNew1007.vehicle_wt, "B. Weight of Vehicle:")
      }
    }

    if(sOld1007.gross_wt !== sNew1007.gross_wt){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, sOld1007.gross_wt, sNew1007.gross_wt, "C. Gross Weight (A - B):")
      }
    }

    if(sOld1007.fm_wt !== sNew1007.fm_wt){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, sOld1007.fm_wt, sNew1007.fm_wt, "D. Foreign Material (FM * C):")
      }
    }

    if(Number(sOld1007.gross_wt) - Number(sOld1007.fm_wt) !== Number(sNew1007.gross_wt) - Number(sNew1007.fm_wt)){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, Number(sOld1007.gross_wt) - Number(sOld1007.fm_wt), Number(sNew1007.gross_wt) - Number(sNew1007.fm_wt), "E. Weight Less FM (C - D):")
      }
    }

    if(sOld1007.ex_moist_wt !== sNew1007.ex_moist_wt){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, sOld1007.ex_moist_wt, sNew1007.ex_moist_wt, "F. Excess Moisture (EM * E):")
      }
    }

    if(sOld1007.net_wt !== sNew1007.net_wt){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, sOld1007.net_wt, sNew1007.net_wt, "G. Net Weight (E - F):")
      }
    }

    if(sOld1007.lsk_wt !== sNew1007.lsk_wt){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, sOld1007.lsk_wt, sNew1007.lsk_wt, "H. LSK (LSK * C):")
      }
    }

    if(Number(sOld1007.net_wt) - Number(sOld1007.lsk_wt) !== Number(sNew1007.net_wt) - Number(sNew1007.lsk_wt)){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, Number(sOld1007.net_wt) - Number(sOld1007.lsk_wt), Number(sNew1007.net_wt) - Number(sNew1007.lsk_wt), "I. Net Weight Excl LSK (G - H):")
      }
    }

    if(sOld1007.kern_value !== sNew1007.kern_value){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, sOld1007.kern_value, sNew1007.kern_value, "J. Kernel Value/Ton (Excluding LSK):")
      }
    }

    if(sOld1007.elk_prem !== sNew1007.elk_prem){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, sOld1007.elk_prem, sNew1007.elk_prem, "K. ELK Premium:")
      }
    }

    if(Number(sOld1007.kern_value) - Number(sOld1007.elk_prem) !== Number(sNew1007.kern_value) - Number(sNew1007.elk_prem)){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, Number(sOld1007.kern_value) - Number(sOld1007.elk_prem), Number(sNew1007.kern_value) - Number(sNew1007.elk_prem), "L. Total (J + K):")
      }
    }

    if(sOld1007.dam_discount !== sNew1007.dam_discount){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, sOld1007.dam_discount, sNew1007.dam_discount, "Damage")
      }
    }

    if(sOld1007.ex_fm_discount !== sNew1007.ex_fm_discount){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, sOld1007.ex_fm_discount, sNew1007.ex_fm_discount, "Excess FM")
      }
    }

    if(sOld1007.ex_ss_discount !== sNew1007.ex_ss_discount){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, sOld1007.ex_ss_discount, sNew1007.ex_ss_discount, "Excess Splits")
      }
    }

    if(sOld1007.flavus_discount !== sNew1007.flavus_discount){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, sOld1007.flavus_discount, sNew1007.flavus_discount, "Flavus Splits")
      }
    }

    if(sOld1007.dam_discount !== sNew1007.dam_discount){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, sOld1007.flavus_discount, sNew1007.flavus_discount, "M. Total Discounts:")
      }
    }

    if(sOld1007.net_les_lsk_val !== sNew1007.net_les_lsk_val){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, sOld1007.net_les_lsk_val, sNew1007.net_les_lsk_val, "N. Value/Ton Excl LSK (L- M):")
      }
    }

    if(sOld1007.net_price !== sNew1007.net_price){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, sOld1007.net_price, sNew1007.net_price, "Estimated Value Per Pound:")
      }
    }

    if(sOld1007.basis_grade_amt !== sNew1007.basis_grade_amt){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, sOld1007.basis_grade_amt, sNew1007.basis_grade_amt, "Total (100% Basis Grade):")
      }
    }
    
    if(dblOldFarmerStockCost !== dblNewFarmerStockCost){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, dblOldFarmerStockCost, dblNewFarmerStockCost, "Farmer Stock Cost")
      }
    }

    if(dblOldSeg !== dblNewSeg){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, dblOldSeg, dblNewSeg, "Segment Value")
      }
    }

    if(dblOldOptionPayment !== dblNewOptionPayment){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, dblOldOptionPayment, dblNewOptionPayment, "Option Payment")
      }
    }

    if(dblOldDed !== dblNewDed){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, dblOldDed, dblNewDed, "Deductions")
      }
    }

    if(dblOldNet !== dblNewNet){
      if(!bBypassCorrectionLogging){
        CorrectionLog(sNew1007, dblOldNet, dblNewNet, "Net")
      }
    }

    var obj = {
      heading : LstrSCText,
      sc95Label : Number(cropYearFromLS()) < 2015 ? " FV 95 #:" : " SC 95 #:",
      changeDate : sNew1007.chg_date_time,
      cropyear : cropYearFromLS(),
      fedBP : sNew1007.buy_pt_fed_id,
      sc95  : sNew1007.insp_num,
      s1007 : sNew1007.settlement_num,
      buyingPt : sNew1007.buy_pt_id + " - " + sNew1007.buy_pt_name + " ",
      inspDate : sNew1007.insp_date_time,
      orgState : sNew1007.state_abbr,
      orgCounty : sNew1007.county_id,
      orgFarm : sOld1007.farm_id + " " + sOld1007.farm_suffix,
      newState : sOld1007.state_abbr,
      newCounty : sOld1007.county_id,
      newFarm : sNew1007.farm_id + " " + sNew1007.farm_suffix,
      vendorData : vendorDataArr,
      oldContractNumber : oldContractNumber, 
      newContractNumber : newContractNumber, 
      orgSeg : sOld1007.seg_type,
      newSeg : sNew1007.seg_type,
      orgType : sOld1007.pnut_type_name,
      newType : sNew1007.pnut_type_name,
      orgVariety : sOld1007.pnut_variety_name + " " + sSymbolOrg,
      newVariety : sNew1007.pnut_variety_name + " " + sSymbolNew,
      orgGen : sOld1007.seed_gen,
      newGen : sNew1007.seed_gen,
      orgSpec : sOld1007.seed_meeting_spec_ind == '' ? '' : sOld1007.seed_meeting_spec_ind == 'Y' ? 'Yes' : 'No',
      newSpec : sNew1007.seed_meeting_spec_ind == '' ? '' : sNew1007.seed_meeting_spec_ind == 'Y' ? 'Yes' : 'No',
      orgOliec : sOld1007.oleic_ind,
      newOliec : sNew1007.oleic_ind,
      orgUnWhseBin : sOld1007.unld_whse_id + " - " + sOld1007.unld_bin_id,
      newUnWhseBin : sNew1007.unld_whse_id + " - " + sNew1007.unld_bin_id,
      orgObligation : sOld1007.obl_buy_id + " - " + sOld1007.obl_whse_id + " - " + sOld1007.obl_bin_id,
      newObligation : sNew1007.obl_buy_id + " - " + sNew1007.obl_whse_id + " - " + sNew1007.obl_bin_id,
      orgOrganic : sOld1007.organic_ind,
      newOrganic : sNew1007.organic_ind,
      scaleTicketData : scaleTicketArr,
      scaleListData: lstrxml[0].inspect_headers[0].elec_upload == "Y" ? await printscalelist(lstrxml) : '',
      orgCureClean :  sOldTicket_Numbers_Cure_Clean,
      newCureClean :  sNewTicket_Numbers_Cure_Clean,
      probingPattern : sNew1007.probe_pattern,
      oldTareWt : sOld1007.tare_wt,
      newTareWt : sNew1007.tare_wt,
      oldVehicleWt : sOld1007.vehicle_wt,
      newVehicleWt : sNew1007.vehicle_wt,
      fmPct : Number(sNew1007.fm_pct).toFixed(2),
      oldGrossWeight : sOld1007.gross_wt,
      newGrossWeight : sNew1007.gross_wt,
      lskPct : sNew1007.lsk_pct,
      oldForeignMaterial : sOld1007.fm_wt,
      newForeignMaterial : sNew1007.fm_wt,
      moisturePct : !isNaN(sNew1007.hmoist_pct) ? sNew1007.hmoist_pct : sNew1007.moist_pct,
      oldWeightLess : Number(sOld1007.gross_wt) - Number(sOld1007.fm_wt),
      newWeightLess : Number(sNew1007.gross_wt) - Number(sNew1007.fm_wt),
      smk1 : isNaN(sNew1007.smk_1_pct) ? '' : sNew1007.smk_1_pct,
      oldMoistWt : sOld1007.ex_moist_wt,
      newMoistWt : sNew1007.ex_moist_wt,
      smk2 : isNaN(sNew1007.smk_2_pct) ? '' : sNew1007.smk_2_pct,
      oldNetWt : sOld1007.net_wt,
      newNetWt : sNew1007.net_wt,
      smk3 : isNaN(sNew1007.smk_3_pct) ? '' : sNew1007.smk_3_pct,
      oldLskWt : sOld1007.lsk_wt,
      newLskWt : sNew1007.lsk_wt,
      smk4 : isNaN(sNew1007.smk_4_pct) ? '' : sNew1007.smk_4_pct,
      oldNetWtExLsk : Number(sOld1007.net_wt) - Number(sOld1007.lsk_wt),
      newNetWtExLsk : Number(sNew1007.net_wt) - Number(sNew1007.lsk_wt),
      smkPct : sNew1007.smk_pct,
      oldKer : Number(sOld1007.kern_value).toFixed(2),
      newKer : Number(sNew1007.kern_value).toFixed(2),
      ssPct : sNew1007.ss_pct,
      oldELK : Number(sOld1007.elk_prem).toFixed(2),
      newELK : Number(sNew1007.elk_prem).toFixed(2),
      totalSMKSS : Number(Number(sNew1007.smk_pct) + Number(sNew1007.ss_pct)).toFixed(2),
      oldtotalJK : Number(Number(sOld1007.kern_value) + Number(sOld1007.elk_prem)).toFixed(2),
      newtotalJK : Number(Number(sNew1007.kern_value) + Number(sNew1007.elk_prem)).toFixed(2),
      damage : Number(sNew1007.frez_dam_pct).toFixed(2),
      oldDamage : Number(sOld1007.dam_discount).toFixed(2),
      newDamage : Number(sNew1007.dam_discount).toFixed(2),
      cnclRMd : Number(sNew1007.cncl_rmd_pct).toFixed(2),
      oldcnclRMd : Number(sOld1007.ex_fm_discount).toFixed(2),
      newcnclRMd : Number(sNew1007.ex_fm_discount).toFixed(2),
      totalDam : Number(sNew1007.dam_pct).toFixed(2),
      oldExSplit : Number(sOld1007.ex_ss_discount).toFixed(2),
      newExSplit : Number(sNew1007.ex_ss_discount).toFixed(2),
      otherKer : Number(sNew1007.ok_pct).toFixed(2),
      totalKer : Number(Number(sNew1007.smk_pct) + Number(sNew1007.ss_pct) + Number(sNew1007.dam_pct) + Number(sNew1007.ok_pct)).toFixed(2),
      oldFlavus : Number(sOld1007.flavus_discount).toFixed(2),
      newFlavus : Number(sNew1007.flavus_discount).toFixed(2),
      oldDis : Number(Number(sOld1007.dam_discount) + Number(sOld1007.ex_fm_discount) + Number(sOld1007.ex_ss_discount) + Number(sOld1007.flavus_discount)).toFixed(2),
      newDis : Number(Number(sNew1007.dam_discount) + Number(sNew1007.ex_fm_discount) + Number(sNew1007.ex_ss_discount) + Number(sNew1007.flavus_discount)).toFixed(2),
      hulls : Number(sNew1007.hull_pct).toFixed(2),
      oldLM : Number(sOld1007.net_les_lsk_val).toFixed(2),
      newLM : Number(sNew1007.net_les_lsk_val).toFixed(2),
      totalKerHull : Number(Number(sNew1007.smk_pct) + Number(sNew1007.ss_pct) + Number(sNew1007.dam_pct) + Number(sNew1007.ok_pct) + Number(sNew1007.hull_pct)).toFixed(2),
      vicam : sNew1007.vicam_ppb,
      oldPerPound : Number(Number(sOld1007.net_price) * 100).toFixed(5),
      newPerPound : Number(Number(sNew1007.net_price) * 100).toFixed(5),
      hullBright : Number(sNew1007.hul_brit_pct).toFixed(2),
      oldBasicGrade : Intl.NumberFormat('en-US').format(Number(sOld1007.basis_grade_amt).toFixed(2)),
      newBasicGrade : Intl.NumberFormat('en-US').format(Number(sNew1007.basis_grade_amt).toFixed(2)),
      jumboPCt : Number(sNew1007.jumbo_pct).toFixed(2),
      fanPCt : Number(sNew1007.fan_pct).toFixed(2),
      elkPCt : Number(sNew1007.elk_pct).toFixed(2),
      crbrPCt : Number(sNew1007.cr_br_pct).toFixed(2),
      dColPCt : Number(sNew1007.dcol_shel_pct).toFixed(2),
      landType : sNew1007.irr_dry_ind !== '' ? CodeToEnglish('LANDTYPE', sNew1007.irr_dry_ind) : ""
    }
    
    pageGap = 20 - pageGap <= 20 ? 20 : 20 - pageGap
    
    return `<html>
      <style>
      body:before{
          content: '${process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test'}';
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: -1;  
          color: #0d745e;
          font-size: 250px;
          font-weight: 500px;
          display: grid;
          justify-content: center;
          align-content: center;
          opacity: 0.35;
      }
      @media print {
        @page :footer { display: none }
        @page :header { display: none }
        @page { margin-top : 0; margin-bottom: 0; }
        html, body {
          height:100%; 
          margin: 0 !important; 
          padding: 0 !important;
        }      
      }    
      </style>
      
      <p style="page-break-after: always">
        <br/>
        ${await CorrectionDocumentHeader(obj)}
        <br/>
        <table style="font-size: 70%;padding-top:0.5%;padding-left: 1%;width:99%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
          <td style="width:16%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Org. State: </b></span>
              <br/>
              <span style="text-align:right"> &nbsp; ${obj.orgState} </span>
          </td>
          <td style="width:16%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; New State </b></span>
              <br/>
              <span style="text-align:right;font-size:90%;"> &nbsp; ${obj.newState} </span>
          </td>
          <td style="width:16%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Org. County: </b></span>
              <br/>
              <span style="text-align:right"> &nbsp; ${obj.orgCounty} </span>
          </td>
          <td style="width:16%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; New County: </b></span>
              <br/>
              <span style="text-align:right"> &nbsp; <b> </b> ${obj.newCounty} </span>
          </td>
          <td style="width:18%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Org. Farm #: </b></span>
              <br/>
              <span style="text-align:right"> &nbsp; ${obj.orgFarm} </span>
          </td>
          <td style="width:18%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; New Farm #: </b></span>
              <br/>
              <span style="text-align:right"> &nbsp; ${obj.newFarm} </span>
          </td>          
      </tr>
      </table>

      <table style="font-size: 70%;padding-top: 0.5%;padding-left: 1%;width:99%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
          <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
              <span style="text-align:center;"> <b> &nbsp; Original Vendor </b></span>
          </td>
          <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
              <span style="text-align:center;"> <b> &nbsp; New Vendor </b></span>
          </td>
          <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
              <span style="text-align:left"> <b> Original Share % </b></span>
          </td>
          <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
              <span style="text-align:left"> <b> &nbsp; New Share % </b></span>
          </td>
          <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
              <span style="text-align:left"> <b> &nbsp; DISP </b></span>
          </td>
          <td style="width:12.5%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
              <span style="text-align:left"> <b> &nbsp; New DISP </b></span>
          </td>          
        </tr>
      ${obj.vendorData}
      </table>

      <table style="font-size: 70%;padding-top:0.5%;padding-left: 1%;width:99%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
          <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> <b> &nbsp; Original Contract(s): </b> ${obj.oldContractNumber} </span>
          </td>
        </tr>
        <tr>
          <td style="width:30%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> <b> &nbsp; New Contract(s): </b> ${obj.newContractNumber} </span>
          </td>
        </tr>
      </table>

      <table style="font-size: 70%;padding-top: 0.5%;padding-left: 1%;width:99%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
          <td style="width:7%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> <b> &nbsp; Org Seg </b></span>
              <br/>
              <span style="text-align:right;"> &nbsp; ${obj.orgSeg} </span>
          </td>
          <td style="width:7%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> <b> &nbsp; New Seg </b></span>
              <br/>
              <span style="text-align:right;"> &nbsp; ${obj.newSeg} </span>
          </td>
          <td style="width:8%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Org Type </b></span>
              <br/>
              <span style="text-align:right;"> &nbsp; ${obj.orgType} </span>
          </td>
          <td style="width:8%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; New Type </b></span>
              <br/>
              <span style="text-align:right;"> &nbsp; ${obj.newType} </span>
          </td>
          <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Org Variety </b></span>
              <br/>
              <span style="text-align:right;"> &nbsp; ${obj.orgVariety} </span>
          </td>
          <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; New Variety </b></span>
              <br/>
              <span style="text-align:right;"> &nbsp; ${obj.newVariety} </span>
          </td>   
          <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Org Gen </b></span>
              <br/>
              <span style="text-align:right;"> &nbsp; ${obj.orgGen} </span>
          </td>
          <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; New Gen </b></span>
              <br/>
              <span style="text-align:right;"> &nbsp; ${obj.newGen} </span>
          </td>
          <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; Org Meets Seed Sp. </b></span>
              <br/>
              <span style="text-align:right;"> &nbsp; ${obj.orgSpec} </span>
          </td>
          <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:left"> <b> &nbsp; New Meets Seed Sp. </b></span>
              <br/>
              <span style="text-align:right;"> &nbsp; ${obj.newSpec} </span>
          </td>        
        </tr>
      </table>

      <table style="font-size: 70%;padding-top: 0.5%;padding-left: 1%;width:99%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
      <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
            <span style="text-align:center;"> <b> &nbsp; Org Oleic </b></span>
            <br/>
            <span style="text-align:right;"> &nbsp; ${obj.orgOliec} </span>
        </td>
        <td style="width:10%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
            <span style="text-align:center;"> <b> &nbsp; New Oleic </b></span>
            <br/>
            <span style="text-align:right;"> &nbsp; ${obj.newOliec} </span>
        </td>
        <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
            <span style="text-align:left"> <b> Org. Unld. Whse. Bin.: </b></span>
            <br/>
            <span style="text-align:right;"> &nbsp; ${obj.orgUnWhseBin} </span>
        </td>
        <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
            <span style="text-align:left"> <b> &nbsp; New Unld. Whse. Bin.: </b></span>
            <br/>
            <span style="text-align:right;"> &nbsp; ${obj.newUnWhseBin} </span>
        </td>
        <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
            <span style="text-align:left"> <b> &nbsp; Org. Obligation: </b></span>
            <br/>
            <span style="text-align:right;"> &nbsp; ${obj.orgObligation} </span>
        </td>
        <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
            <span style="text-align:left"> <b> &nbsp; New Obligation: </b></span>
            <br/>
            <span style="text-align:right;"> &nbsp; ${obj.newObligation} </span>
        </td>
      </tr>
    </table>  


    <table style="font-size: 70%;padding-top: 0.5%;padding-left: 1%;width:99%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
      <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <td style="width:50%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
            <span style="text-align:center;"> <b> &nbsp; Org Organic </b> &nbsp; ${obj.orgOrganic} </span>
        </td>
        <td style="width:50%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
            <span style="text-align:center;"> <b> &nbsp; New Organic </b> &nbsp; ${obj.newOrganic} </span>
        </td>
      </tr>
    </table>

    <table style="font-size: 70%;padding-top: 0.5%;padding-left: 1%;width:99%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
      <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <td style="width:16%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
            <span style="text-align:center;"> <b> &nbsp; Org Wt. Ticket # </b> </span>
        </td>
        <td style="width:16%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
            <span style="text-align:center;"> <b> &nbsp; New Wt. Ticket # </b> </span>
        </td>
        <td style="width:18%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
            <span style="text-align:center;"> <b> &nbsp; Org Wt. of Peanuts </b> </span>
        </td>
        <td style="width:18%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
            <span style="text-align:center;"> <b> &nbsp; New Wt. of Peanuts </b> </span>
        </td>
        <td style="width:16%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
            <span style="text-align:center;"> <b> &nbsp; Org Vehicle # </b> </span>
        </td>
        <td style="width:16%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
            <span style="text-align:center;"> <b> &nbsp; New Vehicle # </b> </span>
        </td>
      </tr>
      ${obj.scaleTicketData}
    </table>
    <br/>
      ${obj.scaleListData}
    <br/>
      <table style="font-size: 70%;padding-top: 0.1%;padding-left: 1%;width:99%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
          <td style="width:100%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
            <span style="text-align:center;"> &nbsp; Org. Cure Clean Ticket No(s): &nbsp; ${obj.orgCureClean} </span>
          </td>
        </tr>
        <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
          <td style="width:100%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
            <span style="text-align:center;"> &nbsp; New Cure Clean Ticket No(s): &nbsp; ${obj.newCureClean} </span>
          </td>
        </tr>
      </table>

        <table style="font-size: 70%;padding-top: 0.2%;padding-left: 1%;width:99%;">
          <tr>
            <td style="width:100%;"> 
              <span style="text-align:center;"> <b> &nbsp; Value shown below does not represent Market Loan Value or Final Statement Value. </b> </span>
            </td>
          </tr> 
        </table>

        <table style="font-size: 70%;padding-left: 1%;width:99%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;">  </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;">  </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;">  </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
              <span> <b> Original </b> </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:center;"> 
              <span> <b> New </b> </span>
            </td>
          </tr>

          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; Probing Pattern </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.probingPattern} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; A. Weight Including Vehicle </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.oldTareWt} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.newTareWt} &nbsp; </span>
            </td>
          </tr>

          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; Grades: </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;">  </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; B. Weight of Vehicle </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.oldVehicleWt} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.newVehicleWt} &nbsp; </span>
            </td>
          </tr>

          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; FM </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.fmPct} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; C. Gross Weight (A - B) </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.oldGrossWeight} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.newGrossWeight} &nbsp; </span>
            </td>
          </tr>

          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; LSK </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.lskPct} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; D. Foreign Material (FM * C)  </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.oldForeignMaterial} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.newForeignMaterial} &nbsp; </span>
            </td>
          </tr>

          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Moisture </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.moisturePct} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; E. Weight Less FM (C - D)  </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.oldWeightLess} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.newWeightLess} &nbsp; </span>
            </td>
          </tr>

          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SMK 1 </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.smk1} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; F. Excess Moisture (EM * E)  </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.oldMoistWt} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.newMoistWt} &nbsp; </span>
            </td>
          </tr>

          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SMK 2 </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.smk2} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; G. Net Weight (E - F)  </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.oldNetWt} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.newNetWt} &nbsp; </span>
            </td>
          </tr>

          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SMK 3 </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.smk3} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; H. LSK (LSK * C)  </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.oldLskWt} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.newLskWt} &nbsp; </span>
            </td>
          </tr>

          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SMK 4 </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.smk4} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; I. Net Weight Excl LSK (G - H)  </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.oldNetWtExLsk} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.newNetWtExLsk} &nbsp; </span>
            </td>
          </tr>

          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Total SMK </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.smkPct} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; J. Kernel Value/Ton (Excluding LSK)  </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.oldKer} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.newKer} &nbsp; </span>
            </td>
          </tr>

          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SS </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.ssPct} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; K. ELK Premium  </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.oldELK} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.newELK} &nbsp; </span>
            </td>
          </tr>
      
          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Total (SMK + SS)  </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.totalSMKSS} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; L. Total (J + K)  </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.oldtotalJK} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.newtotalJK} &nbsp; </span>
            </td>
          </tr>
        
          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Freeze Damage </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.damage} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Damage </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.oldDamage} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.newDamage} &nbsp; </span>
            </td>
          </tr>

          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Concealed RMD </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.cnclRMd} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Excess FM </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.oldcnclRMd} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.newcnclRMd} &nbsp; </span>
            </td>
          </tr>

          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Total Damage </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.totalDam} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Excess Splits  </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.oldExSplit} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.newExSplit} &nbsp; </span>
            </td>
          </tr>

          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Other Kernals </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.otherKer} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Flavus Splits  </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.oldFlavus} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.newFlavus} &nbsp; </span>
            </td>
          </tr>
          
          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Total Kernals </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.totalKer} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; M. Total Discounts  </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.oldDis} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.newDis} &nbsp; </span>
            </td>
          </tr>

          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Hulls </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.hulls} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; N. Value/Ton Excl LSK (L- M)  </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.oldLM} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.newLM} &nbsp; </span>
            </td>
          </tr>

          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Total Kernals & Hulls </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.totalKerHull} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span>  &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span>  &nbsp; </span>
            </td>
          </tr>

          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Vicam </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.vicam} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; Estimated Value Per Pound </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.oldPerPound} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.newPerPound} &nbsp; </span>
            </td>
          </tr>

          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Hull Brightness </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.hullBright} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; Total (100% Basis Grade) </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.oldBasicGrade} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${obj.newBasicGrade} &nbsp; </span>
            </td>
          </tr>

          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In Shell Jumbo </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.jumboPCt} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              | Farmer Stock Cost </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${dblOldFarmerStockCost} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${dblNewFarmerStockCost} &nbsp; </span>
            </td>
          </tr>

          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fancy </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.fanPCt} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              | Segment Value </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${dblOldSeg} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${dblNewSeg} &nbsp; </span>
            </td>
          </tr>

          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ELK </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.elkPCt} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              | Option Payment </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${dblOldOptionPayment} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${dblNewOptionPayment} &nbsp; </span>
            </td>
          </tr>

          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Cracked and Broken </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.crbrPCt} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              | Deductions </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${dblOldDed} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${dblNewDed} &nbsp; </span>
            </td>
          </tr> 

          <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Discolored </span>
            </td>
            <td style="width:20%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span style="text-align:center;"> ${obj.dColPCt} &nbsp; </span>
            </td>
            <td style="width:25%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
              <span style="text-align:center;"> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              | Net </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${dblOldNet} &nbsp; </span>
            </td>
            <td style="width:15%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;text-align:right;"> 
              <span> ${dblNewNet} &nbsp; </span>
            </td>
          </tr>
        </table>

        <table style="font-size: 70%;padding-top: 0.2%;padding-left: 1%;width:99%;">
          <tr>
            <td style="width:80%;"> 
              <span style="text-align:center;"> <b> &nbsp; Value shown above does not represent Market Loan Value or Final Statement Value. </b> </span>
            </td>
            <td style="width:20%;font-size:150%;">
              ${sNew1007.weight_cert_num}
            </td>
          </tr> 
        </table>
        <table style="font-size: 70%;padding-top: 0.2%;padding-left: 1%;width:99%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;">
          <tr style="border: 1px;border-color: white;border-style: solid;border-collapse:collapse;">
            <td style="width:100%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;"> 
              <span> <b> Deductions: </b> </span>
              <br/> 
              Org. Total $ ${orgTotal}
              <br/> 
              New Total $ ${newTotal}
            </td>            
          </tr>           
        </table>
        <br/> <br/>
        <table style="font-size: 70%;padding-top:0.2%;padding-left: 1%;width:99%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:center;">
          <tr style="border: 1px;border-color: white;border-style: solid;border-collapse:collapse;">
            <td style="width:100%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;"> 
              Page 1 of 2      
            </td>            
          </tr>           
        </table>
      </p>

      <p style="page-break-after: always">
        ${await CorrectionDocumentHeader(obj)} 
        <br/> <br/>
        ${correctionTable}        

        <table style="font-size: 75%;padding-top: 2.2%;width:99%;">
          <tr>
            <td> 
              ${obj.landType}
              <br/>
              ${sRemarks_correction}
              <br/>              
            </td>            
          </tr>           
        </table>
        ${'<br/>'.repeat(pageGap)}
        <table style="font-size: 70%;padding-top:0.2%;padding-left: 1%;width:99%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;text-align:center;">
          <tr style="border: 1px;border-color: white;border-style: solid;border-collapse:collapse;">
            <td style="width:100%;border: 1px;border-color: white;border-style: solid;border-collapse:collapse;"> 
              Page 2 of 2      
            </td>            
          </tr>           
      </table>
      </p>
      </html>`      
  }
  catch(err){
    errorHandler(err)
  }
}

const printscalelist = async(xml) => {
  try{
    return `<table style="font-size: 75%;padding-top: 0.1%;padding-left: 1%;width:99%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
      <tr style="border: 1px;border-color: black;border-style: solid;border-collapse:collapse;">
        <td style="width:50%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
            <span style="text-align:center;"> &nbsp;&nbsp; E-Weight Tkt: ${xml[0].inspect_headers[0].scale_list} </span>
        </td>
        <td style="width:50%;border: 1px;border-color: black;border-style: solid;border-collapse:collapse;"> 
            <span style="text-align:center;"> &nbsp;&nbsp; E-Vehicle No: ${xml[0].inspect_headers[0].vehicle_list} </span>
        </td>
      </tr>
    </table>`
  }
  catch(err){
    errorHandler(err)
  }
}

function errorHandler(err){
  if(err.message == "Cannot read properties of null (reading 'focus')")
    alert('Please allow your browser to display pop up window and then click on ok to show the print window.')
  else
    showMessage(thisObj, err.message)
}

async function Print() {
  try {
    if(selectedCheckBox.length !== 0)
      selectedCheckBox = selectedCheckBox.slice(1)
    var code = await GetHtmlCode()
    state.renderCode = code
    //after reprint flag should be N for FV95SearchReprint  //changes for PRB0052917
    setData(thisObj,"FV95SearchReprint","N");
    state.renderUrl = getBlobURL(code, 'text/html')
    document.getElementById("docFrame").setAttribute("src", state.renderUrl);
    
    setLoading(false)
    var type = 'text/html' 
    var css = '@page { size: landscape; }',
              head = document.head || document.getElementsByTagName('head')[0],
              style = document.createElement('style');
    style.type = 'text/css';
    style.media = 'print';
    let layoutType = ''
    let reportData = getData(thisObj, "ReportPreviewInspectionScreenData")
    
    if(reportData.method_name == reportType.correctionDocument){
      layoutType = 'PORTRAIT'
    }
    else if(reportData.method_name == reportType.settleWorkSheet){
      layoutType = 'PORTRAIT'
    }
    else if(reportData.method_name == reportType.inspectionOneDoc){
      layoutType = 'PORTRAIT'
    }
    else if(reportData.method_name == reportType.inspectionTwoDoc){
      layoutType = 'PORTRAIT'
    }
    else {
      layoutType = 'PORTRAIT'
    }    
    alert("Suggested layout type for the document is " + layoutType + " for better visualization.")

    if (style.styleSheet)
      style.styleSheet.cssText = css;
    else
      style.appendChild(document.createTextNode(css));
    head.appendChild(style);
    
    var fileName = ''
    const dataUrl1 = window.URL.createObjectURL(new Blob([code], { type }));
    const pdfWindow = window.open(dataUrl1, '', 'width=1000,height=850,scrollbars=1,menuBar=1,name=' + fileName)
    pdfWindow.focus()
    pdfWindow.addEventListener('beforeprint', event => {})
    pdfWindow.print()
    pdfWindow.addEventListener('afterprint', (event) => {
      //pdfWindow.close() //Commented this line as PDF window was getting closed before print operation
    })
    return code;
  }
  catch (err) {
      errorHandler(err);
  }
}

const onbtnPrintClick = async() => {
  try{
    setLoading(true)
    let reportData = getData(thisObj, "ReportPreviewInspectionScreenData")
    selectedCheckBox = thisObj.values.chkboxReprint
    let rcptArr = []
    if((reportData.method_name == reportType.inspectionOneDoc || reportData.method_name == reportType.inspectionTwoDoc) && selectedCheckBox.length !== 0){
      for(var i=0; i< selectedCheckBox.length; i++){
        if(selectedCheckBox[i] == '1')
          rcptArr.push('Applicant Original')
        else if(selectedCheckBox[i] == '2')
          rcptArr.push('FSIS')
        else if(selectedCheckBox[i] == '3')
          rcptArr.push('SELLER')
      }
      await rptInspectionReprint(rcptArr, reportData, reportData.method_name)
      return
    }

    selectedCheckBox = thisObj.values.chkboxReprint
    setData(thisObj, 'selectedCheckBox', thisObj.values.chkboxReprint)
    await Print()
  }
  catch(err){
      errorHandler(err);
  }
}
thisObj.onbtnPrintClick = onbtnPrintClick;



const print_ContractVendor_Splts = (xml) =>{
  let LstrXML_Q_Remarks = ''
  let LstrXML_Q_RemSeed = '' 
  let nQRemarkLines = ''
  let sRemarks_2 = ''  
  let sQContract = ''
  let sQVendor = ''
  let sQPounds = ''   
  let LstrXMLSeedContracts = ''
  let sSeedContractList = ''
  let sSeedContract = ''
      
  let sRemarks_correction = ""
     
  LstrXML_Q_RemSeed = xml.inspect_headers[0].q_seed_remarks
  for(let i =0; i<LstrXML_Q_RemSeed.length; i++){
    if(LstrXML_Q_RemSeed.q_seed_remark != 0){
      sQContract = LstrXML_Q_RemSeed[i].contract_num
      sQVendor = LstrXML_Q_RemSeed[i].split_vendor
      sQPounds = LstrXML_Q_RemSeed[i].appl_lbs_adj.includes(',') ? LstrXML_Q_RemSeed[i].appl_lbs_adj : Intl.NumberFormat('en-US').format(LstrXML_Q_RemSeed[i].appl_lbs_adj)

      sRemarks_correction += ("SeedAgree " + sQContract)
      sRemarks_correction += ("&nbsp;Vendor " + sQVendor)
      sRemarks_correction += ("&nbsp;Pounds " + sQPounds + "<br/>")
    }
  }

  LstrXML_Q_Remarks = xml.inspect_headers[0].q_remarks
  for(let i =0; i<LstrXML_Q_Remarks.length; i++){
    if(LstrXML_Q_Remarks.q_remark != 0){
      sQContract = LstrXML_Q_Remarks[i].contract_num  == '999999' ? '&nbsp;None&nbsp;&nbsp;' : LstrXML_Q_Remarks[i].contract_num 
      sQVendor = LstrXML_Q_Remarks[i].split_vendor
      sQPounds = LstrXML_Q_Remarks[i].net_wt.includes(',') ? LstrXML_Q_Remarks[i].net_wt : Intl.NumberFormat('en-US').format(LstrXML_Q_Remarks[i].net_wt)
      
      sRemarks_correction += ("Contract " + sQContract)
      sRemarks_correction += ("&nbsp;Vendor " + sQVendor)
      sRemarks_correction += ("&nbsp;Pounds " + sQPounds + "<br/>")
                     
  }}
  return sRemarks_correction
}

  const getTicketNo = (intCnt, strVal, bExtraWeightTicks) => {
    let intNum = 0
    let intCount = 0
    let strPrt = ''
    let intPrev = 0
    intNum = 25
    let strNum = ''
    let astr = []
    let astrr = []
    
    if (strVal == "" || strVal == undefined || strVal == null)
      return '';
    
    astrr = strVal.split(",");
    for (let i = 0; i < astrr.length; i++) {
      astrr[i] = astrr[i] + ","
      astr.push(astrr[i])
    }
    
    for (let i = 0; i < astr.length; i++) {
      if ((astr[i]).length <= (intNum - intPrev)) {
        strPrt = strPrt + astr[i]
        intCnt = intCount
      }
      else {
        bExtraWeightTicks = true
        return strPrt
      }
      intPrev = intPrev + (astr[i]).length
    }
    return strPrt.slice(0,-1)
  }

const CodeToEnglish = (sType,sCode) =>{
    let sReturn = ''
    if(sType == "LANDTYPE"){
      switch(sCode){
        case "I":
          sReturn = "Irrigated"
        case "D":
          sReturn = "Dryland"
        case "U":
          sReturn = "Unknown"
        default:
          sReturn = ""
      }
    }    
    return sReturn;
}

const CorrectionLog = async (sNew1007, sOldValue, sNewValue, sFieldDesc) =>{
    let sCorrBP = ''
    let sCorrFV95 = ''
    let sCorrRev = ''
    let sCorr1007 = ''
    let sCorrCnt = ''
    let lstrxml = ''

    if(sOldValue == sNewValue)
      return;
        
    sFieldDesc = sFieldDesc.replace(":", "");
        
    sCorrBP = sNew1007.buy_pt_id
    sCorrFV95 = sNew1007.insp_num
    sCorrRev = sNew1007.revision_num
    sCorr1007 = sNew1007.settlement_num
    sCorrCnt = sNew1007.settle_correct_cnt

    let DataObj = {}
    DataObj.comp_id = compIdFromLS()
    DataObj.crop_year = cropYearFromLS()
    DataObj.buy_pt_id = sCorrBP
    DataObj.insp_num = sCorrFV95
    DataObj.revision_num = sCorrRev
    DataObj.settle_num = sCorr1007
    DataObj.correct_cnt = sCorrCnt
    DataObj.field_desc = sFieldDesc
    DataObj.old_value = sOldValue
    DataObj.new_value = sNewValue

    let response = await SettlementService.CreateInspectCorrectionLogDetails(DataObj)
}

const rptSettlementWorkSheet = async (FV95number) => {
  try{
  let lstrxml = ''
  let LstrXML_Subs = []
  let sPrevPremCode = ''
  let dblStorPremAmt = ''
  let dblPurhPremAmt = ''
  let dblStorCharges = ''
  let dblPurhCharges = ''
  let sCropYear = ''
  let sFV95Number = ''
  let sFedBPNumber = ''
  let sSettlementNumber = ''
  let sBuyingPoint = ''
  let sInspectionDateTime = ''
  let sState = ''
  let sCounty = ''
  let sFarmNumber = ''
  let sFarmsuffix = ''
  let sProducer = ''
  let colVendors = {}
  let colVendor = {}
  let sPeanutSeg = ''
  let sPeanutType = ''
  let sPeanutVariety = ''
  let sPeanutGeneration = ''
  let sSeedMeetingSpecInd = ''
  let sProbingPattern = ''
  let sWarehouse = ''
  let sBin = ''
  let colScaleTickets = {}
  let colScaleTicket = {}
  let sOleic = ''
  let sA_WeightInclVehicle = ''
  let sB_WeightOfVehicle = ''
  let sFM = ''
  let sC_GrossWeight_A_minus_B = ''
  let sLSK = ''
  let sD_ForiegnMaterial_FM_x_C = ''
  let sMoisture = ''
  let sE_Weight_less_FM_C_minus_D = ''
  let sSMK1 = ''
  let sF_ExcessMoisture_FM_x_E = ''
  let sSMK2 = ''
  let sG_NetWeight_E_minus_F = ''
  let sSMK3 = ''
  let sH_LSK_LSK_x_C = ''
  let sSMK4 = ''
  let sI_NetWeightExclLSK_G_minus_H = ''
  let sTotalSMK = ''
  let sJ_KernalValue_Ton_ExclLSK = ''
  let sSS = ''
  let sK_ELKPremium = ''
  let sTotal_SMK_plus_SS = ''
  let sL_Total_J_plus_k = ''
  let sFreezeDamage = ''
  let sL_a_Damage = ''
  let sConcealedRMD = ''
  let sL_b_ExcessFM = ''
  let sTotalDamage = ''
  let sL_c_ExcessSplits = ''
  let sOtherKernals = ''
  let sAflatoxin = ''
  let sM_TotalDiscounts = ''
  let sHULLS = ''
  let sN_Value_Ton_ExclLSK_L_minus_M = ''
  let sTotalKernal_Hulls = ''
  let sVicam = ''
  let sEstimatedValue = ''
  let sHullBrightness = ''
  let sInShellJumbo = ''
  let sFancy = ''
  let sELK = ''
  let sCrackedAndBroken = ''
  let sDiscolored = ''
  let sRemarks = ''
  let sAuditCount = ''
  let colPremDeducts = ''
  let colPremDeduct = ''
  let sOrganicInd = ''
  let MaxVendors = 20
  let MaxTickets = 5
  let MaxPremDeds = 19

  let sTICKET_NUMBERS_CURE_CLEAN = ""
  let nVendor = 0
  let nTicket = 0
  let nPremDed = 0

  let lSPounds = 0
  let lPPounds = 0
  let curSBasisGradeAmt = ''
  let curPBasisGradeAmt = ''
  let curSSupport = ''
  let curPSupport = ''
  let curSSeg = ''
  let curSProceeds = ''
  let curPProceeds = ''
  let LstrText = ''
  let sLandType = ''
  let LintYPremDed = 0
  let sSymbol = ''
  let sVACrackedBroken = ''
  let sRemarks_2 = ''
  let nQRemarkLines = 0
  
  msReport = "SETTLEMENT_WORKSHEET"
  
  lstrxml = await SettlementService.RetrieveInspectionHeaderList(FV95number, null, null, null)
  if(lstrxml.length <= 0){
    goTo(thisObj, 'Settlements#FV95Search#DEFAULT#false#Click')
    return ;
  }    
  
  let res = []
  for(var i=0; i< lstrxml[0].inspect_prem_deducts.length; i++){
    if(!res.find(item => item.pd_code == lstrxml[0].inspect_prem_deducts[i].pd_code)){
      res.push({
        pd_code     : lstrxml[0].inspect_prem_deducts[i].pd_code,
        pd_name     : lstrxml[0].inspect_prem_deducts[i].pd_code + ' - ' + lstrxml[0].inspect_prem_deducts[i].pd_desc + (lstrxml[0].inspect_prem_deducts[i].payee_vendor !== '' ? ' - (' + lstrxml[0].inspect_prem_deducts[i].payee_vendor + ')' : ''),
        storageAmt  : 0,
        purhcaseAmt : 0
      })
    }
  }
  
  for(var i=0; i< lstrxml[0].inspect_prem_deducts.length; i++){
    for(var j=0; j<lstrxml[0].inspect_vendor_splits.length; j++){
      if(lstrxml[0].inspect_prem_deducts[i].pd_vendor == lstrxml[0].inspect_vendor_splits[j].split_vendor){
        if(lstrxml[0].inspect_vendor_splits[j].purh_stor_ind == 'P'){
         for(var k=0; k<res.length; k++) {
          if(res[k].pd_code == lstrxml[0].inspect_prem_deducts[i].pd_code){
            res[k].purhcaseAmt += Number(lstrxml[0].inspect_prem_deducts[i].pd_amount)
            break;
          }          
         }
        }
        else{
          for(var k=0; k<res.length; k++) {
            if(res[k].pd_code == lstrxml[0].inspect_prem_deducts[i].pd_code){
              res[k].storageAmt += Number(lstrxml[0].inspect_prem_deducts[i].pd_amount)
              break;
            }
           }
        }
      }
    }
  }

  let lbsP = 0
  let lbsS = 0
  let xLinePP = 0
  let xLinePS = 0
  let xP = 0
  let xS = 0
  let farmerStockP = 0
  let farmerStockS = 0
  let valueOfSegementP = 0
  let valueOfSegementS = 0
  let optionPaymentP = 0
  let optionPaymentS = 0
  let totalP = 0
  let totalS = 0

  for(var i=0; i<lstrxml[0].inspect_vendor_splits.length; i++){
    if(lstrxml[0].inspect_vendor_splits[i].purh_stor_ind.toLocaleUpperCase() == 'P'){
      lbsP += Number(lstrxml[0].inspect_vendor_splits[i].net_wt)
      xLinePP += Number(lstrxml[0].inspect_vendor_splits[i].basis_grade_amt)
      xP = Number(lstrxml[0].inspect_vendor_splits[i].support_pct)
      farmerStockP += Number(lstrxml[0].inspect_vendor_splits[i].value_of_seg)
      valueOfSegementP += (Number(lstrxml[0].inspect_vendor_splits[i].value_of_seg) - Number(lstrxml[0].inspect_vendor_splits[i].opt_value_of_seg))
      optionPaymentP += Number(lstrxml[0].inspect_vendor_splits[i].opt_value_of_seg)
      totalP += Number(lstrxml[0].inspect_vendor_splits[i].proceeds_amt)
    }
    else{
      lbsS += Number(lstrxml[0].inspect_vendor_splits[i].net_wt)
      xLinePS += Number(lstrxml[0].inspect_vendor_splits[i].basis_grade_amt)
      xS = Number(lstrxml[0].inspect_vendor_splits[i].support_pct)
      farmerStockS += Number(lstrxml[0].inspect_vendor_splits[i].value_of_seg)
      valueOfSegementS += Number(0)
      optionPaymentS += Number(lstrxml[0].inspect_vendor_splits[i].value_of_seg)
      totalS += Number(lstrxml[0].inspect_vendor_splits[i].proceeds_amt)
    }
  }

  if(lstrxml != undefined && lstrxml != null && lstrxml != ''){
      sCropYear = lstrxml[0].crop_year
      sFV95Number = lstrxml[0].insp_num
      sFedBPNumber = lstrxml[0].buy_pt_fed_id
      sSettlementNumber = lstrxml[0].settlement_num //(7 - (lstrxml.settlement_num).length).toString() + (lstrxml.settlement_num)
      
      sBuyingPoint = lstrxml[0].buy_pt_nLdblDataRowsame + "   (" + lstrxml[0].buy_pt_id + ")"
      sInspectionDateTime = moment(lstrxml[0].insp_date_time).format('MM/DD/YYYY hh:mm A')
      sState = lstrxml[0].state_abbr
      sCounty = lstrxml[0].county_id
      sFarmNumber = lstrxml[0].farm_id
      sFarmsuffix = lstrxml[0].farm_suffix
      sLandType = lstrxml[0].irr_dry_ind
      
      msPDF_SettlementNo = sSettlementNumber
      msPDF_InspectionNo = sFV95Number
      msPDF_BP = lstrxml[0].buy_pt_id

      sOrganicInd = lstrxml[0].organic_ind
      
      sTICKET_NUMBERS_CURE_CLEAN = lstrxml[0].ticket_numbers_cure_clean
      
      LstrText = ""
      if(lstrxml[0].superseded_settle != '')
          LstrText = "Superseded " + lstrxml[0].superseded_settle
      
      if(lstrxml[0].settle_correct_cnt >0){
          if(LstrText != '')
              LstrText = LstrText + " & "
          LstrText = LstrText + "Correction #" + lstrxml[0].settle_correct_cnt
      }
      
      LstrXML_Subs = lstrxml[0].inspect_vendor_splits
      
      sProducer = lstrxml[0].farm_name
      let colVendorsArray = []
      let colVendorsObj = {}
      for(let i = 0; i<LstrXML_Subs.length; i++){
      if(LstrXML_Subs.inspect_vendor_split != ''){
        colVendorsObj.split_vendor = LstrXML_Subs[i].split_vendor
        colVendorsObj.split_remit = LstrXML_Subs[i].split_remit
        colVendorsObj.vendor_name = LstrXML_Subs[i].vendor_name
        colVendorsObj.taxid = LstrXML_Subs[i].taxid
        colVendorsObj.remit_name = LstrXML_Subs[i].remit_name
        colVendorsObj.address1 = LstrXML_Subs[i].address1
        colVendorsObj.address2 = LstrXML_Subs[i].address2
        colVendorsObj.city = LstrXML_Subs[i].city
        colVendorsObj.state = LstrXML_Subs[i].state
        colVendorsObj.zipcode = LstrXML_Subs[i].zipcode
        colVendorsObj.split_share_pct = LstrXML_Subs[i].split_share_pct
        colVendorsObj.whse_rcpt_bp = LstrXML_Subs[i].whse_rcpt_bp
        colVendorsObj.whse_rcpt_num = LstrXML_Subs[i].whse_rcpt_num

          switch (LstrXML_Subs.purh_stor_ind) {
            case "P":
              colVendorsObj.purh_stor_ind = LstrXML_Subs.whse_rcpt_num == '' ? 'P' : 'S'
              break;
            case "S", "D", "R":
              if(LstrXML_Subs.whse_rcpt_num == '')
                colVendorsObj.purh_stor_ind = "S"              
              break;
          }

          colVendorsObj.gross_wt = LstrXML_Subs[i].gross_wt
          colVendorsObj.net_wt = LstrXML_Subs[i].net_wt
          colVendorsObj.lsk_wt = LstrXML_Subs[i].lsk_wt
          colVendorsObj.basis_grade_amt = LstrXML_Subs[i].basis_grade_amt
          colVendorsObj.value_per_ton = LstrXML_Subs[i].value_per_ton
          colVendorsObj.support_pct = LstrXML_Subs[i].support_pct
         
         colVendorsObj.value_of_seg = LstrXML_Subs[i].value_of_seg

         
         colVendorsObj.opt_value_of_seg = LstrXML_Subs[i].opt_value_of_seg
          colVendorsObj.premium_amt = LstrXML_Subs[i].premium_amt
          colVendorsObj.deduct_amt = LstrXML_Subs[i].deduct_amt
          colVendorsObj.proceeds_amt = LstrXML_Subs[i].proceeds_amt

          colVendorsArray.push(colVendorsObj)
          colVendorsObj = {}  
      }}
      
      sPeanutSeg = lstrxml[0].seg_type
      sPeanutType = "(" + lstrxml[0].pnut_type_id + ") " + lstrxml[0].pnut_type_name
      
      switch(lstrxml[0].symbol_ind.toLocaleUpperCase().trim()){
          case "TRADEMARK":
            sSymbol = " " + `&trade;`
            break
          case "REGISTERED TRADEMARK":
            sSymbol = " " + `&reg;`
            break
          case "COPYRIGHT":
            sSymbol = " " + `&copy;`
            break
          default:
            sSymbol = ""
      }
      
      sPeanutVariety = "(" + lstrxml[0].pnut_variety_id + ") " + lstrxml[0].pnut_variety_name + sSymbol
      sPeanutGeneration = lstrxml[0].gen_produced_name
      sSeedMeetingSpecInd = lstrxml[0].seed_meeting_spec_ind
      sProbingPattern = lstrxml[0].probing_pattern !== undefined ? lstrxml[0].probing_pattern : '' //lstrxml[0].probe_pattern //probing_pattern
      sWarehouse = lstrxml[0].unld_whse_id
      sBin = lstrxml[0].unld_bin_id
      
      LstrXML_Subs = lstrxml[0].inspect_scale_tickets
      
      let colScaleTicketsArray = []
      let colScaleTicketsObj = {}
      
      for( let i=0; i<LstrXML_Subs.length; i++){
      if(LstrXML_Subs != '' && LstrXML_Subs != undefined && LstrXML_Subs != null){
        colScaleTicketsObj.dry_ticket = LstrXML_Subs[i].dry_ticket
        colScaleTicketsObj.weigher_name = LstrXML_Subs[i].weigher_name
        colScaleTicketsObj.dry_date = LstrXML_Subs[i].dry_date
        colScaleTicketsObj.dry_carrier = LstrXML_Subs[i].dry_carrier
        colScaleTicketsObj.dry_dispatch = LstrXML_Subs[i].dry_dispatch
        colScaleTicketsObj.dry_vehicle_id = LstrXML_Subs[i].dry_vehicle_id
        colScaleTicketsObj.dry_tare_wt = LstrXML_Subs[i].dry_tare_wt
        colScaleTicketsObj.dry_vehicle_wt = LstrXML_Subs[i].dry_vehicle_wt
        colScaleTicketsObj.dry_peanut_wt = LstrXML_Subs[i].dry_peanut_wt
        colScaleTicketsObj.green_ticket = LstrXML_Subs[i].green_ticket
        colScaleTicketsObj.green_date = LstrXML_Subs[i].green_date
        colScaleTicketsObj.green_carrier = LstrXML_Subs[i].green_carrier
        colScaleTicketsObj.green_dispatch = LstrXML_Subs[i].green_dispatch
        colScaleTicketsObj.green_vehicle_id = LstrXML_Subs[i].green_vehicle_id
        colScaleTicketsObj.green_tare_wt = LstrXML_Subs[i].green_tare_wt
        colScaleTicketsObj.green_vehicle_wt = LstrXML_Subs[i].green_vehicle_wt
        colScaleTicketsObj.green_peanut_wt = LstrXML_Subs[i].green_peanut_wt
        colScaleTicketsObj.cure_ticket = LstrXML_Subs[i].cure_ticket
        colScaleTicketsObj.moisture_pct = LstrXML_Subs[i].moisture_pct
        colScaleTicketsObj.cure_schedule = LstrXML_Subs[i].cure_schedule
        colScaleTicketsObj.cost_to_cure = LstrXML_Subs[i].cost_to_cure
        colScaleTicketsObj.clean_schedule = LstrXML_Subs[i].clean_schedule
        colScaleTicketsObj.cost_to_clean = LstrXML_Subs[i].cost_to_clean

        colScaleTicketsArray.push(colScaleTicketsObj)
        colScaleTicketsObj ={}
      }}
      
      sOleic = lstrxml[0].oleic_ind
      sA_WeightInclVehicle = lstrxml[0].tare_wt
      sB_WeightOfVehicle = lstrxml[0].vehicle_wt
      sFM = lstrxml[0].fm_pct
      sC_GrossWeight_A_minus_B = lstrxml[0].gross_wt
      sLSK = lstrxml[0].lsk_pct
      sD_ForiegnMaterial_FM_x_C = lstrxml[0].fm_wt
      
      if(isNaN(lstrxml[0].hmoist_pct) == false)
        sMoisture = lstrxml[0].hmoist_pct
      else
        sMoisture = lstrxml[0].moist_pct
      
       if((sC_GrossWeight_A_minus_B == '' || sC_GrossWeight_A_minus_B == undefined ) && (sD_ForiegnMaterial_FM_x_C == '' || sD_ForiegnMaterial_FM_x_C == undefined )){
        document.getElementById("Settlements_ReportPreviewInspectionPopUp").childNodes[0].click();
        return ;
       } 
      sE_Weight_less_FM_C_minus_D = sC_GrossWeight_A_minus_B - sD_ForiegnMaterial_FM_x_C
      sSMK1 = lstrxml[0].smk_1_pct
      if(sSMK1 != '')
          sSMK1 = lstrxml[0].smk_1_pct
      
      sF_ExcessMoisture_FM_x_E = lstrxml[0].ex_moist_wt
      sSMK2 = lstrxml[0].smk_2_pct
      if(sSMK2 != "")
          sSMK2 = lstrxml[0].smk_2_pct
      
      sG_NetWeight_E_minus_F = lstrxml[0].net_wt
      sSMK3 = lstrxml[0].smk_3_pct
      if(sSMK3 != '')
          sSMK3 = lstrxml[0].smk_3_pct
    
      sH_LSK_LSK_x_C = lstrxml[0].lsk_wt
      sSMK4 = lstrxml[0].smk_4_pct
      if(sSMK4 != "")
          sSMK4 = lstrxml[0].smk_4_pct
      
      sI_NetWeightExclLSK_G_minus_H = (sG_NetWeight_E_minus_F) - (sH_LSK_LSK_x_C)
      sTotalSMK = lstrxml[0].smk_pct
      sJ_KernalValue_Ton_ExclLSK = lstrxml[0].kern_value
      sSS = lstrxml[0].ss_pct
      sK_ELKPremium = lstrxml[0].elk_prem
      sTotal_SMK_plus_SS = (sTotalSMK) + (sSS)
      sL_Total_J_plus_k = (sJ_KernalValue_Ton_ExclLSK) + (sK_ELKPremium)
      sFreezeDamage = lstrxml[0].frez_dam_pct
      sL_a_Damage = lstrxml[0].dam_discount
      sConcealedRMD = lstrxml[0].cncl_rmd_pct
      sL_b_ExcessFM = lstrxml[0].ex_fm_discount
      sTotalDamage = lstrxml[0].dam_pct
      sL_c_ExcessSplits = lstrxml[0].ex_ss_discount
      
      sOtherKernals = lstrxml[0].ok_pct
      sAflatoxin = lstrxml[0].flavus_discount
      
      let sTotalKernals = (sTotal_SMK_plus_SS) + (sTotalDamage) + (sOtherKernals)
      sM_TotalDiscounts = (sL_a_Damage) + (sL_b_ExcessFM) + (sL_c_ExcessSplits) + (sAflatoxin)
      sHULLS = lstrxml[0].hull_pct
      sN_Value_Ton_ExclLSK_L_minus_M = lstrxml[0].net_les_lsk_val
      sTotalKernal_Hulls = (sTotalKernals) + (sHULLS)
      
      sVicam = lstrxml[0].vicam_ppb != '' ? lstrxml[0].vicam_ppb : ''
      
      sEstimatedValue = ((lstrxml[0].net_price) * 100)
      sHullBrightness = lstrxml[0].hul_brit_pct
      sInShellJumbo = lstrxml[0].jumbo_pct
      sFancy = lstrxml[0].fan_pct
      sELK = lstrxml[0].elk_pct
      sCrackedAndBroken = lstrxml[0].cr_br_pct
      sDiscolored = lstrxml[0].dcol_shel_pct
      sVACrackedBroken = lstrxml[0].va_cr_br_pct
      
      sRemarks = lstrxml[0].inspect_remarks[0].remarks + '</br></br>'
    
      let LstrXML_Q_Remarks = ''
      let LstrXMLSeedContracts = ''  
      let sSeedContractList = ''     
      let sSeedContract = ''
      let LstrXML_Q_RemSeed = ''
             
      nQRemarkLines = 0
      
      LstrXML_Q_RemSeed = lstrxml[0].q_seed_remarks
      for( let i=0; i<LstrXML_Q_RemSeed.length; i++){
      if(LstrXML_Q_RemSeed.q_seed_remark != 0){
          sRemarks = sRemarks + "SeedAgree " + LstrXML_Q_RemSeed[i].contract_num + "  "
          sRemarks = sRemarks + "Vendor " + LstrXML_Q_RemSeed[i].split_vendor + "  "
          sRemarks = sRemarks + "Pounds " + Intl.NumberFormat('en-US').format(LstrXML_Q_RemSeed[i].appl_lbs_adj) + "<br/>"
          nQRemarkLines = nQRemarkLines + 1
      }}
      
      LstrXML_Q_Remarks = lstrxml[0].q_remarks
      sRemarks_2 = ""
      
      sPrevPremCode = ""
      dblStorPremAmt = 0
      dblStorCharges = 0
      dblPurhPremAmt = 0
      dblPurhCharges = 0
      LstrXML_Subs = lstrxml[0].inspect_prem_deducts
      
      let colPremDeductsArray = []
      let colPremDeductsObj = {}

      for(let i=0; i <LstrXML_Subs.length; i++){
      if(LstrXML_Subs.inspect_prem_deduct != 0){
          if(sPrevPremCode != LstrXML_Subs.pd_code){
              if(dblPurhPremAmt != 0 || dblStorPremAmt != 0){
                colPremDeductsObj.purchase_amt = dblPurhPremAmt
                colPremDeductsObj.storage_amt = dblStorPremAmt
                dblPurhPremAmt = 0
                dblStorPremAmt = 0
                
                colPremDeductsArray.push(colPremDeductsObj)
                colPremDeductsObj ={}
              }

              var colPremDeductArray = []
              var colPremDeductObj = {}
              colPremDeductObj.pd_code = LstrXML_Subs.pd_code + " - " + LstrXML_Subs.pd_desc + " - (" + LstrXML_Subs.payee_vendor + ")"
              sPrevPremCode = LstrXML_Subs.pd_code             
          }
          else{              
          }
      }}

      if(dblPurhPremAmt != 0 || dblStorPremAmt != 0){
        colPremDeductObj.purchase_amt = dblPurhPremAmt
        colPremDeductObj.storage_amt = dblStorPremAmt
        dblPurhPremAmt = 0
        dblStorPremAmt = 0

        colPremDeductArray.push(colPremDeductObj)
        colPremDeductObj ={}
      }
      sAuditCount = lstrxml[0].revision_num
            
      for(let nTicket = 1; nTicket<= MaxTickets; nTicket++){
          if(nTicket <= colScaleTicketsArray.length){
              let colScaleTicket = colScaleTicketsArray[nTicket]              
          }
      }
      
    if(lstrxml.elec_upload == "Y"){
      
    }
      lSPounds = 0
      lPPounds = 0
      for(let i =0; i<colVendorsArray.length; i++){
          if(colVendorsArray[i].purh_stor_ind == "P")
              lPPounds += colVendorsArray[i].net_wt
          else
              lSPounds += colVendorsArray[i].net_wt
      }
      
      curSBasisGradeAmt = 0
      curPBasisGradeAmt = 0
      for(let i=0; i<colVendorsArray.length; i++){
          if(colVendorsArray[i].purh_stor_ind == "P")
              curPBasisGradeAmt = curPBasisGradeAmt + colVendorsArray[i].basis_grade_amt
          else
              curSBasisGradeAmt = curSBasisGradeAmt + colVendorsArray[i].basis_grade_amt
      }
      
      curSSupport = 0
      curPSupport = 0
      for(let i=0; i<colVendorsArray.length; i++){
          if(colVendorsArray[i].purh_stor_ind == "P")
              curPSupport = colVendorsArray[i].support_pct
          else
              curSSupport = colVendorsArray[i].support_pct
      }
      
      let curPSeg = 0
      
      for(let i=0; i<colVendorsArray.length; i++){
          if(colVendorsArray[i].purh_stor_ind == "P")
              curPSeg = curPSeg + colVendorsArray[i].value_of_seg
          else
              curSSeg = curSSeg + colVendorsArray[i].value_of_seg
      }

      curSSeg = 0
      curPSeg = 0
      for(let i=0; i<colVendorsArray.length; i++){
          if(colVendorsArray[i].purh_stor_ind == "P")
              curPSeg = curPSeg + colVendorsArray[i].value_of_seg - colVendorsArray[i].opt_value_of_seg
          else
              curSSeg = curSSeg + colVendorsArray[i].value_of_seg 
      }
      
      curSSeg = 0
      curPSeg = 0
      for(let i=0; i<colVendorsArray.length; i++){
          if(colVendorsArray[i].purh_stor_ind == "P")
              curPSeg = curPSeg + colVendorsArray[i].opt_value_of_seg          
          else
              curSSeg = curSSeg + colVendorsArray[i].value_of_seg
      }
            
      for(let nPremDed=1; nPremDed<= MaxPremDeds; nPremDed++){
          if(nPremDed <= colPremDeductsArray.length){
              let colPremDeduct = colPremDeductsArray[nPremDed]
          }
      }

      curSProceeds = 0
      curPProceeds = 0
      for(let i=0; i<colVendorsArray.length; i++){
          if(colVendorsArray[i].purh_stor_ind == "P")
              curPProceeds = curPProceeds + colVendorsArray[i].proceeds_amt          
          else
              curSProceeds = curSProceeds + colVendorsArray[i].proceeds_amt
      }
    
      if(mbPrintSelected == true){
          if(mbPrintPDF == true){
              PdfSelected("W", msPDF_SettlementNo, msPDF_BP)
          }
      } 
  }
    let sQContract = ''
    let sQVendor = ''
    let sQPounds = ''
    // sRemarks = ""
  
    let vendor = ``  
    let splitPct = ``
    for(var i=0; i<lstrxml[0].inspect_vendor_splits.length; i++){
      vendor += `(${lstrxml[0].inspect_vendor_splits[i].split_vendor}) &nbsp;
      ${lstrxml[0].inspect_vendor_splits[i].vendor_name} &nbsp;
      ${lstrxml[0].inspect_vendor_splits[i].city} &nbsp;
      ${lstrxml[0].inspect_vendor_splits[i].state} &nbsp;
      ${lstrxml[0].inspect_vendor_splits[i].zipcode} &nbsp; <br>`

      splitPct += `${trimValue(lstrxml[0].inspect_vendor_splits[i].split_share_pct)}<br>`
    }

    let vendorData = `<tr> <td style="width:25%;border: 1px solid black;" colspan="3"> ${vendor} </td> 
                           <td style="width:25%;border: 1px solid black;text-align:center;" colspan="2"> ${splitPct} </td>
                      </tr>`

    for(var i=0; i< lstrxml[0].q_remarks.length; i++){
      sQContract = lstrxml[0].q_remarks[i].contract_num == "999999" ? '&nbsp; None&nbsp;&nbsp;' : lstrxml[0].q_remarks[i].contract_num
      sQVendor = lstrxml[0].q_remarks[i].split_vendor
      sQPounds = lstrxml[0].q_remarks[i].net_wt
      if(i<10){
        sRemarks += ("Contract " + sQContract + " ")
        sRemarks += ("Vendor " + sQVendor + " ")
        sRemarks += ("Pounds " + Intl.NumberFormat('en-US').format(sQPounds) + "</br>")
      }
      else{
        sRemarks_2 += "Contract " + sQContract + "  "
        sRemarks_2 += "Vendor " + sQVendor + "  "
        sRemarks_2 += "Pounds " + Intl.NumberFormat('en-US').format(sQPounds) + "  </br>"
      }
    }

    if(sVACrackedBroken != ""){
      if(nQRemarkLines < 10){
          sRemarks = sRemarks + "</br>VA&nbspCracked&nbspand&nbspBroken: " + sVACrackedBroken + "%"
          nQRemarkLines = nQRemarkLines + 1
      }
      else{
          sRemarks_2 = sRemarks_2 + "</br>VA&nbspCracked&nbspand&nbspBroken: " + sVACrackedBroken + "%"
          nQRemarkLines = nQRemarkLines + 1
      }
    }
    
    let h2Value = ''
    if(lstrxml[0].superseded_settle !== '')
      h2Value = "Superseded " + lstrxml[0].superseded_settle.trim()
    
    if(Number(lstrxml[0].settle_correct_cnt)> 0){
      if(h2Value !== '')
        h2Value += '& '
      h2Value += ("Correction #" + lstrxml[0].settle_correct_cnt.trim())
    }

    let scaleLstData = ``
    if(lstrxml != undefined && lstrxml[0].inspect_scale_tickets != undefined &&lstrxml[0].inspect_scale_tickets.length > 0 && lstrxml[0].inspect_scale_tickets[0].inspect_scale_ticket != undefined ){
    for(var i=0; i<lstrxml[0].inspect_scale_tickets[0].inspect_scale_ticket.length; i++){
      if(i == Number(lstrxml[0].inspect_scale_tickets[i].nControlIndex)-1){
        scaleLstData += `<tr style="border: 1px solid black;border-collapse:collapse;">
        <td style="width:25%;border: 1px solid black;text-align:right;" colspan="1"> 
          <span style="text-align:center;">${lstrxml[0].inspect_scale_tickets[0].inspect_scale_ticket[i].dry_ticket} </span>
        </td>            
        <td style="width:25%;border: 1px solid black;text-align:center;" colspan="1"> 
          <span style="text-align:center"> ${lstrxml[0].inspect_scale_tickets[0].inspect_scale_ticket[i].dry_date.split(' ')[0]} </span>
        </td> 
        <td style="width:25%;border: 1px solid black;text-align:center;" colspan="1"> 
          <span style="text-align:center"> ${lstrxml[0].inspect_scale_tickets[0].inspect_scale_ticket[i].dry_peanut_wt} </span>
        </td>            
        <td style="width:25%;border: 1px solid black;text-align:center;" colspan="3"> 
          <span style="text-align:center"> ${lstrxml[0].inspect_scale_tickets[0].inspect_scale_ticket[i].dry_vehicle_id} </span>
        </td>            
      </tr>`
      }
    }
  }
    
    let currentDate = ((new Date()).getMonth() + 1) + '/' + (new Date()).getDate() + '/' + (new Date()).getFullYear() + '&nbsp;'
    let hours = (new Date().getHours()) == 0 ? "12" : (new Date().getHours()) > 12 ? (new Date().getHours()) - 12 : (new Date().getHours());
    let minutes = ((new Date()).getMinutes()) < 10 ? "0" + ((new Date()).getMinutes()) : ((new Date()).getMinutes())
    let seconds = ((new Date()).getSeconds()) < 10 ? "0" + ((new Date()).getSeconds()) : ((new Date()).getSeconds())
    let currentTime = hours + ':' + minutes + ':' + seconds
    currentTime += ((new Date().getHours()) >= 12 ? ' PM' : ' AM') 
    
    let Obj = {}
    Obj.H1 = 'Settlement Worksheet'
    Obj.H2 = h2Value == '' ? '' : `<br>` + h2Value
    Obj.Date = currentDate
    Obj.Time = currentTime
    Obj.R1C1 = sCropYear
    Obj.R1C2 = "0".repeat(7 - sFV95Number.length) + sFV95Number
    Obj.R1C3 = sFedBPNumber
    Obj.R1C4 = "0".repeat(7 - sSettlementNumber.length) + sSettlementNumber
    Obj.R2C1 = sBuyingPoint
    Obj.R2C2 = sInspectionDateTime
    Obj.R3C1 = sState
    Obj.R3C2 = sCounty
    Obj.R3C3 = sFarmNumber + ' - ' + sFarmsuffix + ' - ' + sProducer
    Obj.VendorData = vendorData
    Obj.R6C1 = lstrxml[0].seg_type
    Obj.R6C2 = '(' + lstrxml[0].pnut_type_id + ') ' + lstrxml[0].pnut_type_name 
    Obj.R6C3 = '(' + lstrxml[0].pnut_variety_id + ') ' + lstrxml[0].pnut_variety_name + sSymbol
    Obj.R6C4 = (lstrxml[0].gen_produced_name == undefined  || lstrxml[0].gen_produced_name == null) ? '' : lstrxml[0].gen_produced_name
    Obj.R6C5 = lstrxml[0].organic_ind
    Obj.R7C1 = lstrxml[0].seed_meeting_spec_ind == 'N' ? 'No' : lstrxml[0].seed_meeting_spec_ind == 'Y' ? 'Yes' : ''
    Obj.R7C2 = lstrxml[0].probe_pattern
    Obj.R7C3 = lstrxml[0].unld_whse_id
    Obj.R7C4 = lstrxml[0].unld_bin_id
    Obj.R9C1 = scaleLstData
    Obj.R9C4 = ''
    Obj.R10C1 = lstrxml[0].scale_list
    Obj.R10C2 = lstrxml[0].vehicle_list
    Obj.R11C1 = lstrxml[0].ticket_numbers_cure_clean
    Obj.R12C2 = lstrxml[0].oleic_ind
    Obj.R12C4 = lstrxml[0].tare_wt
    Obj.R13C2 = ''
    Obj.R13C4 = lstrxml[0].vehicle_wt
    Obj.R14C2 = Number(lstrxml[0].fm_pct).toFixed(2)
    Obj.R14C4 = lstrxml[0].gross_wt
    Obj.R15C2 = lstrxml[0].lsk_pct
    Obj.R15C4 = lstrxml[0].fm_wt
    Obj.R16C2 = (!isNaN(lstrxml[0].hmoist_pct) && lstrxml[0].hmoist_pct != null && lstrxml[0].hmoist_pct != undefined && lstrxml[0].hmoist_pct != '') ? Number(lstrxml[0].hmoist_pct).toFixed(2) : Number(lstrxml[0].moist_pct).toFixed(2)
    Obj.R16C4 = Number(Number(lstrxml[0].gross_wt) - Number(lstrxml[0].fm_wt))
    Obj.R17C2 = lstrxml[0].smk_1_pct !== '' ? Number(lstrxml[0].smk_1_pct).toFixed(2) : ''
    Obj.R17C4 = lstrxml[0].ex_moist_wt
    Obj.R18C2 = lstrxml[0].smk_2_pct !== '' ? Number(lstrxml[0].smk_2_pct).toFixed(2) : ''
    Obj.R18C4 = lstrxml[0].net_wt
    Obj.R19C2 = lstrxml[0].smk_3_pct !== '' ? Number(lstrxml[0].smk_3_pct).toFixed(2) : ''
    Obj.R19C4 = lstrxml[0].lsk_wt
    Obj.R20C2 = lstrxml[0].smk_4_pct !== '' ? Number(lstrxml[0].smk_4_pct).toFixed(2) : ''
    Obj.R20C4 = Number(Number(lstrxml[0].net_wt) - Number(lstrxml[0].lsk_wt))
    Obj.R21C2 = Number(lstrxml[0].smk_pct).toFixed(2)
    Obj.R21C4 = lstrxml[0].kern_value
    Obj.R22C2 = Number(lstrxml[0].ss_pct).toFixed(2)
    Obj.R22C4 = Number(lstrxml[0].elk_prem).toFixed(2)
    Obj.R23C2 = Number(Number(lstrxml[0].smk_pct) + Number(lstrxml[0].ss_pct)).toFixed(2)
    Obj.R23C4 = Number(Number(lstrxml[0].kern_value) + Number(lstrxml[0].elk_prem)).toFixed(2)
    Obj.R24C2 = Number(lstrxml[0].frez_dam_pct).toFixed(2)
    Obj.R24C4 = Number(lstrxml[0].dam_discount).toFixed(2)
    Obj.R25C2 = Number(lstrxml[0].cncl_rmd_pct).toFixed(2)
    Obj.R25C4 = Number(lstrxml[0].ex_fm_discount).toFixed(2)
    Obj.R26C2 = Number(lstrxml[0].dam_pct).toFixed(2)
    Obj.R26C4 = Number(lstrxml[0].ex_ss_discount).toFixed(2)
    Obj.R27C2 = Number(lstrxml[0].ok_pct).toFixed(2)
    Obj.R27C4 = Number(lstrxml[0].flavus_discount).toFixed(2)
    Obj.R28C2 = (Number(lstrxml[0].smk_pct) + Number(lstrxml[0].ss_pct) + Number(lstrxml[0].dam_pct) + Number(lstrxml[0].ok_pct)).toFixed(2)
    Obj.R28C4 = (Number(lstrxml[0].dam_discount) + Number(lstrxml[0].ex_fm_discount) + Number(lstrxml[0].ex_ss_discount) + Number(lstrxml[0].flavus_discount)).toFixed(2)
    Obj.R29C2 = Number(lstrxml[0].hull_pct).toFixed(2)
    Obj.R29C4 = Number(lstrxml[0].net_les_lsk_val).toFixed(2)
    Obj.R30C2 = (Number(lstrxml[0].smk_pct) + Number(lstrxml[0].ss_pct) + Number(lstrxml[0].dam_pct) + Number(lstrxml[0].ok_pct) + Number(lstrxml[0].hull_pct)).toFixed(2)
    Obj.R30C4 = (Number(lstrxml[0].net_price) * 100).toFixed(5)
    Obj.R31C2 = lstrxml[0].vicam_ppb !== "" ? lstrxml[0].vicam_ppb : ""
    Obj.R32C2 = lstrxml[0].hul_brit_pct
    Obj.R33C2 = lstrxml[0].jumbo_pct
    Obj.R33C4 = Number(lbsS).toFixed(2) 
    Obj.R33C5 = Number(lbsP).toFixed(2) 
    Obj.R34C2 = lstrxml[0].fan_pct
    Obj.R34C4 = Number(xLinePS).toFixed(2) 
    Obj.R34C5 = Number(xLinePP).toFixed(2) 
    Obj.R35C2 = lstrxml[0].elk_pct
    Obj.R35C4 = Number(xS).toFixed(2) 
    Obj.R35C5 = Number(xP).toFixed(2) 
    Obj.R36C2 = lstrxml[0].cr_br_pct
    Obj.R36C4 = Number(farmerStockS).toFixed(2) 
    Obj.R36C5 = Number(farmerStockP).toFixed(2) 
    Obj.R37C2 = lstrxml[0].dcol_shel_pct
    
    Obj.R37C4 = Number(valueOfSegementS).toFixed(2) 
    Obj.R37C5 = Number(valueOfSegementP).toFixed(2) 
    
    Obj.R38C2 = Number(optionPaymentS).toFixed(2) 
    Obj.R38C3 = Number(optionPaymentP).toFixed(2) 
    
    Obj.R39C2 = ''
    Obj.R39C3 = ''
    Obj.R59C2 = Number(totalS).toFixed(2) 
    Obj.R59C3 = Number(totalP).toFixed(2) 
    Obj.AuditCount = 'Audit Count: ' + lstrxml[0].revision_num
    Obj.Remarks = 'Remarks:'
    Obj.Contract = sRemarks
    Obj.LandType = lstrxml[0].irr_dry_ind !== '' && lstrxml[0].irr_dry_ind !== undefined && lstrxml[0].irr_dry_ind !== null ? lstrxml[0].irr_dry_ind.toLocaleUpperCase().trim() : ''
    
    var remarkArr = Obj.Contract.split(' ')
    Obj.Contract = ''
    for(var i=0; i<remarkArr.length; i++){
      if(remarkArr[i].trim() == 'Pounds'){
        Obj.Contract += remarkArr[i] + ' ' + remarkArr[i+1]
        i = i+2
        if(i >= remarkArr.length)
          break
      }
      Obj.Contract += remarkArr[i] + ' '
    }
    var ArrayContract= Obj.Contract.split('</br>')    
    var ArrayObjContract=ArrayContract.filter(n=>n)
    
    let premDeduct = []
    let premDeductLst = []

    for(var i=0; i<lstrxml[0].inspect_prem_deducts.length; i++){
      if(!premDeduct.includes(lstrxml[0].inspect_prem_deducts[i].pd_code + ' - ' + lstrxml[0].inspect_prem_deducts[i].pd_desc)){
        premDeductLst.push(lstrxml[0].inspect_prem_deducts[i])
        premDeduct.push(lstrxml[0].inspect_prem_deducts[i].pd_code + ' - ' + lstrxml[0].inspect_prem_deducts[i].pd_desc)
      }      
    }
    let resTransfer = []
    for(var i=0;i<res.length; i++){
       //COMMENTTED FOR PRB0053555
     // if(Number(res[i].storageAmt).toFixed(2) !== Number(res[i].purhcaseAmt).toFixed(2)){
        resTransfer.push(res[i])
     // }
    }
    res = resTransfer
    
    let premDeductData = ''
    for(var i=0; i<19; i++){
      let desc = ''
      let sAmt = ''
      let pAmt = ''

      if(i>=5 && i>= res.length)
        break
      if(i < res.length){
        sAmt = Number(res[i].storageAmt).toFixed(2) 
        pAmt = Number(res[i].purhcaseAmt).toFixed(2) 
        desc = `&nbsp;&nbsp;` +  res[i].pd_name 
      }
      if(i == 0){
        premDeductData += `<tr>
        <td style="width:25%;color:black black white white;" colspan="2"> 
          <span style="text-align:center;font-size: 100%;"> ${Obj.AuditCount} </span>
        </td>            
        <td style="width:12%;border: 1px solid black;" colspan="1">       
          <span style="text-align:center"> 
            ${desc}
          </span>          
        </td>
        <td style="width:20%;border: 1px solid black;text-align:right;" height=15 colspan="1">  
          <span style="text-align:center"> ${sAmt} &nbsp;</span>               
        </td>   
        <td style="width:20%;border: 1px solid black;text-align:right;" colspan="1">  
          <span style="text-align:center"> ${pAmt} &nbsp;</span>               
        </td>                                
      </tr>`
      }
      else if(i == 1){
        premDeductData += `<tr>
        <td style="width:25%;color:black black white white;" colspan="2"> 
          <span style="text-align:center;font-size: 100%;"> ${Obj.Remarks} </span>
        </td>            
        <td style="width:12%;border: 1px solid black;" colspan="1">       
          <span style="text-align:center"> 
            ${desc}
          </span>          
        </td>
        <td style="width:20%;border: 1px solid black;text-align:right;" height=15 colspan="1">  
          <span style="text-align:center"> ${sAmt} &nbsp;</span>               
        </td>   
        <td style="width:20%;border: 1px solid black;text-align:right;" colspan="1">  
          <span style="text-align:center"> ${pAmt} &nbsp;</span>               
        </td>                                
      </tr>`
      }
      else if(i == 2){
        premDeductData += `<tr>
        <td style="width:25%;" colspan="2"> 
          <span style="text-align:center;font-size: 100%;color:black black white white;"> ${Obj.LandType} </span>
        </td>            
        <td style="width:12%;border: 1px solid black;" colspan="1">       
          <span style="text-align:center"> 
            ${desc}
          </span>          
        </td>
        <td style="width:20%;border: 1px solid black;text-align:right;" height=15 colspan="1">  
          <span style="text-align:center"> ${sAmt} &nbsp;</span>               
        </td>   
        <td style="width:20%;border: 1px solid black;text-align:right;" colspan="1">  
          <span style="text-align:center"> ${pAmt} &nbsp;</span>               
        </td>                                
      </tr>`
      }
      else if(i == (res.length-1)){
        premDeductData += `<tr>
        <td style="width:25%;" colspan="2";border-bottom: none;> 
          <span style="text-align:center;font-size: 100%;border-bottom: none;">  </span>
        </td>            
        <td style="width:12%;border: 1px solid black;" colspan="1">       
          <span style="text-align:center"> 
            ${desc}
          </span>          
        </td>
        <td style="width:20%;border: 1px solid black;text-align:right;" height=15 colspan="1">  
          <span style="text-align:center"> ${sAmt} &nbsp;</span>               
        </td>   
        <td style="width:20%;border: 1px solid black;text-align:right;" colspan="1">  
          <span style="text-align:center"> ${pAmt} &nbsp;</span>               
        </td>                                
      </tr>`
      for(var cnt=0; cnt<ArrayObjContract.length; cnt++){
        premDeductData += 
        `<tr>
        <td style="width:25%;" colspan="2";border-bottom: none;> 
          <span style="text-align:center;font-size: 100%;border-bottom: none;"> ${ArrayObjContract[cnt]} </span>
        </td>            
        <td style="width:12%;border: 1px solid black;" colspan="1">       
          <span style="text-align:center"> 
            
          </span>          
        </td>
        <td style="width:20%;border: 1px solid black;text-align:right;" height=15 colspan="1">  
          <span style="text-align:center">  &nbsp;</span>               
        </td>   
        <td style="width:20%;border: 1px solid black;text-align:right;" colspan="1">  
          <span style="text-align:center"></span>               
        </td>                                
      </tr>`
        }
      }
      else{
        premDeductData += `<tr>
        <td style="width:25%;" colspan="2"> 
          <span style="text-align:center;font-size: 100%;"> </span>
        </td>            
        <td style="width:12%;border: 1px solid black;" colspan="1">       
          <span style="text-align:center"> 
            ${desc}
          </span>          
        </td>
        <td style="width:20%;border: 1px solid black;text-align:right;" height=15 colspan="1">  
          <span style="text-align:center"> ${sAmt} &nbsp;</span>               
        </td>   
        <td style="width:20%;border: 1px solid black;text-align:right;" colspan="1">  
          <span style="text-align:center"> ${pAmt} &nbsp;</span>               
        </td>                                
      </tr>`
      }
    }
    Obj.PremDeductsData = premDeductData

    Obj.eTicketData = ''
    if(lstrxml[0].elec_upload.toLocaleUpperCase(  ) == 'Y'){
      Obj.eTicketData = `<tr style="border: 1px solid black;border-collapse:collapse;">
        <td style="width:25%;border: 1px solid black;" colspan="2"> 
          <span style="text-align:center"> <b> E-Weight Tkt: </b>${Obj.R10C1}  </span>
        </td>            
        <td style="width:25%;border: 1px solid black;" colspan="3"> 
          <span style="text-align:center"> <b> E-Vehicle No: </b> ${Obj.R10C2} </span>
        </td>            
      </tr>`
    }
    return rptTradeSettlementWorkSheetTemplate(Obj)
  }
  catch(err){
    errorHandler(err)
  }
}

  function trimValue(num) {
    try {
      if(num == undefined)
        num = 0
      return Number(num).toString().split('').reverse().join("").split('').reverse().join("")
    }
    catch (err) {
      errorHandler(err)
      return num
    }
  }

const onbtnCloseClick = () => {
  try{
    document.getElementById("Settlements_ReportPreviewInspectionPopUp").childNodes[0].click();
    return true;
  }
  catch(err){
      errorHandler(err);
  }
}
thisObj.onbtnCloseClick = onbtnCloseClick;

// END_USER_CODE-USER_METHODS
  
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
        
            <ContractManagement_header headerData={state.headerData} />
            <h1 className="pageHeader">{state.Label}</h1>
            
            <Loading loading={loading}></Loading>

            <Form noValidate className="row">
              <GroupBoxWidget
                conf={state.grpbxReportPreviewInspection}
                screenConf={state}
              >
                <GroupBoxWidget
                  conf={state.grpbxPreview}
                  screenConf={state}
                ></GroupBoxWidget>
                <iframe src={state.renderUrl} id="docFrame" width="100%" height="500px" style={{background:"White"}}></iframe>
                <ButtonWidget
                  conf={state.btnPrintSetup}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <CheckboxGroupWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxReprint}
                  screenConf={state}
                ></CheckboxGroupWidget>
                <ButtonWidget
                  conf={state.btnSaveAsPDF}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
              </GroupBoxWidget>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_ReportPreviewInspection);