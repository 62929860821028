/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  setValue,
  getValue,
  getData,
  setData,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  DateWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  TextAreaWidget,
  enable,
  disable,
  show,
  hide,
  isEnabled,
  setLabel,
  getLabel,
  clearValue,
  goTo,
} from "../../shared/WindowImports";

import "./FV95Residue.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService"
import { SettlementService } from "../Service/SettlementService";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
//import styled from "styled-components";
//import async from "react-bootstrap-typeahead/lib/behaviors/async";
import moment from "moment";
import NullifyCheck from "../../AccountPayment/NullifyCheck/NullifyCheck";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
// END_USER_CODE-USER_IMPORTS
function Settlements_FV95Residue(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "FV95Residue",
    windowName: "FV95Residue",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.FV95Residue",
    // START_USER_CODE-USER_FV95Residue_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "SC95 - Residue",
      scrCode: "PN1030R",
    },
    // END_USER_CODE-USER_FV95Residue_PROPERTIES
    btnCalculate: {
      name: "btnCalculate",
      type: "ButtonWidget",
      parent: "grpbxValueCalculation",
      Label: "Calculate",
      CharWidth: "20",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnCalculate_PROPERTIES

      // END_USER_CODE-USER_btnCalculate_PROPERTIES
    },
    btnConvertHMC: {
      name: "btnConvertHMC",
      type: "ButtonWidget",
      parent: "grpbxHMC",
      Label: "Convert HMC",
      CharWidth: "25",
      Enabled: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnConvertHMC_PROPERTIES

      // END_USER_CODE-USER_btnConvertHMC_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Exit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    chkboxCornFound: {
      name: "chkboxCornFound",
      type: "CheckBoxWidget",
      parent: "grpbxGradeCalculation",
      ColSpan: "2",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCornFound_PROPERTIES

      // END_USER_CODE-USER_chkboxCornFound_PROPERTIES
    },
    chkboxFlavusFound: {
      name: "chkboxFlavusFound",
      type: "CheckBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxFlavusFound_PROPERTIES

      // END_USER_CODE-USER_chkboxFlavusFound_PROPERTIES
    },
    chkboxResale: {
      name: "chkboxResale",
      type: "CheckBoxWidget",
      parent: "grpbxCMAInformation",
      Label: "Resale",
      ColSpan: "2",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxResale_PROPERTIES

      // END_USER_CODE-USER_chkboxResale_PROPERTIES
    },
    chkboxSendBack: {
      name: "chkboxSendBack",
      type: "CheckBoxWidget",
      parent: "grpbxActions",
      Label: "Send Residue Load back to the Upload Failure Screen",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxSendBack_PROPERTIES

      // END_USER_CODE-USER_chkboxSendBack_PROPERTIES
    },
    ddEdibleOil: {
      name: "ddEdibleOil",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Edible Oil:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddEdibleOil_PROPERTIES

      // END_USER_CODE-USER_ddEdibleOil_PROPERTIES
    },

    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmInformation",
      Label: "Location:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation_PROPERTIES

      // END_USER_CODE-USER_ddLocation_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Peanut Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddSeg: {
      name: "ddSeg",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "Seg:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeg_PROPERTIES

      // END_USER_CODE-USER_ddSeg_PROPERTIES
    },
    ddSignOfWeigher: {
      name: "ddSignOfWeigher",
      type: "DropDownFieldWidget",
      parent: "grpbxSignature",
      Label: "Signtaure of Weigher:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSignOfWeigher_PROPERTIES

      // END_USER_CODE-USER_ddSignOfWeigher_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    // lblAddedByValue: {
    //   name: "lblAddedByValue",
    //   type: "LabelWidget",
    //   parent: "grpbxActions",
    //   Label: ".",
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

    //   // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    // },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    // lblChangedByValue: {
    //   name: "lblChangedByValue",
    //   type: "LabelWidget",
    //   parent: "grpbxActions",
    //   Label: ".",
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

    //   // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    // },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblCornFound: {
      name: "lblCornFound",
      type: "LabelWidget",
      parent: "grpbxGradeCalculation",
      Label: "Corn Found:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCornFound_PROPERTIES

      // END_USER_CODE-USER_lblCornFound_PROPERTIES
    },
    lblDateTimeFormat: {
      name: "lblDateTimeFormat",
      type: "LabelWidget",
      parent: "grpbxFarmInformation",
      Label: "mm/dd/yyyy hh:mm AM/PM",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblDateTimeFormat_PROPERTIES

      // END_USER_CODE-USER_lblDateTimeFormat_PROPERTIES
    },
    // lblFarmInformation: {
    //   name: "lblFarmInformation",
    //   type: "LabelWidget",
    //   parent: "grpbxFarmInformation",
    //   Label: "Farm Information",
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_lblFarmInformation_PROPERTIES

    //   // END_USER_CODE-USER_lblFarmInformation_PROPERTIES
    // },
    lblFlavusFound: {
      name: "lblFlavusFound",
      type: "LabelWidget",
      parent: "grpbxGradeCalculation",
      Label: "Flavus Found:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFlavusFound_PROPERTIES

      // END_USER_CODE-USER_lblFlavusFound_PROPERTIES
    },
    lblGradeCalculation: {
      name: "lblGradeCalculation",
      type: "LabelWidget",
      parent: "grpbxGradeCalculation",
      Label: "Grade Calculation",
      ColSpan: "4",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblGradeCalculation_PROPERTIES

      // END_USER_CODE-USER_lblGradeCalculation_PROPERTIES
    },
    lblGrams1: {
      name: "lblGrams1",
      type: "LabelWidget",
      parent: "grpbxGradeCalculation",
      Label: "Grams",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblGrams1_PROPERTIES

      // END_USER_CODE-USER_lblGrams1_PROPERTIES
    },
    lblGrams2: {
      name: "lblGrams2",
      type: "LabelWidget",
      parent: "grpbxGradeCalculation",
      Label: "Grams",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblGrams2_PROPERTIES

      // END_USER_CODE-USER_lblGrams2_PROPERTIES
    },
    lblHMCConvertedGrades: {
      name: "lblHMCConvertedGrades",
      type: "LabelWidget",
      parent: "grpbxHMC",
      Label: "HMC Converted Grades:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblHMCConvertedGrades_PROPERTIES

      // END_USER_CODE-USER_lblHMCConvertedGrades_PROPERTIES
    },
    lblInspectorRemarks: {
      name: "lblInspectorRemarks",
      type: "LabelWidget",
      parent: "grpbxInspectorRemarks",
      Label: "Inspector Remarks",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblInspectorRemarks_PROPERTIES

      // END_USER_CODE-USER_lblInspectorRemarks_PROPERTIES
    },
    lblName: {
      name: "lblName",
      type: "LabelWidget",
      parent: "grpbxCMAInformation",
      Label: "Name",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblName_PROPERTIES

      // END_USER_CODE-USER_lblName_PROPERTIES
    },
    lblNumber: {
      name: "lblNumber",
      type: "LabelWidget",
      parent: "grpbxCMAInformation",
      Label: "Number",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNumber_PROPERTIES

      // END_USER_CODE-USER_lblNumber_PROPERTIES
    },
    lblPct1: {
      name: "lblPct1",
      type: "LabelWidget",
      parent: "grpbxGradeCalculation",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct1_PROPERTIES

      // END_USER_CODE-USER_lblPct1_PROPERTIES
    },
    lblPct2: {
      name: "lblPct2",
      type: "LabelWidget",
      parent: "grpbxGradeCalculation",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct2_PROPERTIES

      // END_USER_CODE-USER_lblPct2_PROPERTIES
    },
    lblPeanutInformation: {
      name: "lblPeanutInformation",
      type: "LabelWidget",
      parent: "grpbxPeanutInfo",
      Label: "Peanut Information",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPeanutInformation_PROPERTIES

      // END_USER_CODE-USER_lblPeanutInformation_PROPERTIES
    },
    lblPerLb: {
      name: "lblPerLb",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "c/lb",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPerLb_PROPERTIES

      // END_USER_CODE-USER_lblPerLb_PROPERTIES
    },
    lblPerLb2: {
      name: "lblPerLb2",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "c/lb",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPerLb2_PROPERTIES

      // END_USER_CODE-USER_lblPerLb2_PROPERTIES
    },
    lblPerLb3: {
      name: "lblPerLb3",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "c/lb",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPerLb3_PROPERTIES

      // END_USER_CODE-USER_lblPerLb3_PROPERTIES
    },
    lblPerTon: {
      name: "lblPerTon",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "$/ton",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPerTon_PROPERTIES

      // END_USER_CODE-USER_lblPerTon_PROPERTIES
    },
    lblValueCalculation: {
      name: "lblValueCalculation",
      type: "LabelWidget",
      parent: "grpbxValueCalculation",
      Label: "Value Calculation",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValueCalculation_PROPERTIES

      // END_USER_CODE-USER_lblValueCalculation_PROPERTIES
    },
    radioHighMoisture: {
      name: "radioHighMoisture",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxCMAInformation",
      Label: "High Moisture ?:",
      ofTypeDomain: "d_boolean",
      checked: true,
      // START_USER_CODE-USER_radioHighMoisture_PROPERTIES

      // END_USER_CODE-USER_radioHighMoisture_PROPERTIES
    },
    txt1007: {
      name: "txt1007",
      type: "TextBoxWidget",
      parent: "grpbxFarmInformation",
      Label: "1007 #:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007_PROPERTIES

      // END_USER_CODE-USER_txt1007_PROPERTIES
    },
    txtAccessFMLb: {
      name: "txtAccessFMLb",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAccessFMLb_PROPERTIES

      // END_USER_CODE-USER_txtAccessFMLb_PROPERTIES
    },
    txtAccessFMTon: {
      name: "txtAccessFMTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Excess FM:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAccessFMTon_PROPERTIES

      // END_USER_CODE-USER_txtAccessFMTon_PROPERTIES
    },
    txtAFlavusLb: {
      name: "txtAFlavusLb",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAFlavusLb_PROPERTIES

      // END_USER_CODE-USER_txtAFlavusLb_PROPERTIES
    },
    txtAFlavusTon: {
      name: "txtAFlavusTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "A. Flavus:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAFlavusTon_PROPERTIES

      // END_USER_CODE-USER_txtAFlavusTon_PROPERTIES
    },
    txtarInspectorRemarks: {
      name: "txtarInspectorRemarks",
      type: "TextAreaWidget",
      parent: "grpbxInspectorRemarks",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtarInspectorRemarks_PROPERTIES

      // END_USER_CODE-USER_txtarInspectorRemarks_PROPERTIES
    },
    txtBluePanWt: {
      name: "txtBluePanWt",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Blue Pan Wt:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBluePanWt_PROPERTIES

      // END_USER_CODE-USER_txtBluePanWt_PROPERTIES
    },
    txtBuyerName: {
      name: "txtBuyerName",
      type: "TextBoxWidget",
      parent: "grpbxCMAInformation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyerName_PROPERTIES

      // END_USER_CODE-USER_txtBuyerName_PROPERTIES
    },
    txtBuyerNumber: {
      name: "txtBuyerNumber",
      type: "TextBoxWidget",
      parent: "grpbxCMAInformation",
      Label: "Buyer:",
      LengthRange: { MinLength: 0, MaxLength: 5 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyerNumber_PROPERTIES

      // END_USER_CODE-USER_txtBuyerNumber_PROPERTIES
    },
    txtCleanSampleWt: {
      name: "txtCleanSampleWt",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Clean Sample Wt:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCleanSampleWt_PROPERTIES

      // END_USER_CODE-USER_txtCleanSampleWt_PROPERTIES
    },
    txtConcealedRMDGr: {
      name: "txtConcealedRMDGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Concealed RMD:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtConcealedRMDGr_PROPERTIES

      // END_USER_CODE-USER_txtConcealedRMDGr_PROPERTIES
    },
    txtConcealedRMDPct: {
      name: "txtConcealedRMDPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtConcealedRMDPct_PROPERTIES

      // END_USER_CODE-USER_txtConcealedRMDPct_PROPERTIES
    },
    txtCrkOrBrkShellsGr: {
      name: "txtCrkOrBrkShellsGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Crk, or Brk Shells:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCrkOrBrkShellsGr_PROPERTIES

      // END_USER_CODE-USER_txtCrkOrBrkShellsGr_PROPERTIES
    },
    txtCrkOrBrkShellsPct: {
      name: "txtCrkOrBrkShellsPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCrkOrBrkShellsPct_PROPERTIES

      // END_USER_CODE-USER_txtCrkOrBrkShellsPct_PROPERTIES
    },
    txtCRMD: {
      name: "txtCRMD",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "C RMD:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCRMD_PROPERTIES

      // END_USER_CODE-USER_txtCRMD_PROPERTIES
    },
    txtDAM: {
      name: "txtDAM",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "DAM:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDAM_PROPERTIES

      // END_USER_CODE-USER_txtDAM_PROPERTIES
    },
    txtDamagedKRS: {
      name: "txtDamagedKRS",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Damaged KRS:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDamagedKRS_PROPERTIES

      // END_USER_CODE-USER_txtDamagedKRS_PROPERTIES
    },
    txtDamageLbs: {
      name: "txtDamageLbs",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDamageLbs_PROPERTIES

      // END_USER_CODE-USER_txtDamageLbs_PROPERTIES
    },
    txtDamageSplits: {
      name: "txtDamageSplits",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Damage Splits:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDamageSplits_PROPERTIES

      // END_USER_CODE-USER_txtDamageSplits_PROPERTIES
    },
    txtDamageTon: {
      name: "txtDamageTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Damage:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDamageTon_PROPERTIES

      // END_USER_CODE-USER_txtDamageTon_PROPERTIES
    },
    txtDiscoloredShellsGr: {
      name: "txtDiscoloredShellsGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Discolored Shells:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDiscoloredShellsGr_PROPERTIES

      // END_USER_CODE-USER_txtDiscoloredShellsGr_PROPERTIES
    },
    txtDiscoloredShellsPct: {
      name: "txtDiscoloredShellsPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDiscoloredShellsPct_PROPERTIES

      // END_USER_CODE-USER_txtDiscoloredShellsPct_PROPERTIES
    },
    txtELK: {
      name: "txtELK",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "ELK:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtELK_PROPERTIES

      // END_USER_CODE-USER_txtELK_PROPERTIES
    },
    txtELKDamage: {
      name: "txtELKDamage",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "ELK Damage:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtELKDamage_PROPERTIES

      // END_USER_CODE-USER_txtELKDamage_PROPERTIES
    },
    txtELKGr: {
      name: "txtELKGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "ELK:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtELKGr_PROPERTIES

      // END_USER_CODE-USER_txtELKGr_PROPERTIES
    },
    txtELKPct: {
      name: "txtELKPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtELKPct_PROPERTIES

      // END_USER_CODE-USER_txtELKPct_PROPERTIES
    },
    txtELKPremiumLb: {
      name: "txtELKPremiumLb",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtELKPremiumLb_PROPERTIES

      // END_USER_CODE-USER_txtELKPremiumLb_PROPERTIES
    },
    txtELKPremiumTon: {
      name: "txtELKPremiumTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "ELK Premium:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtELKPremiumTon_PROPERTIES

      // END_USER_CODE-USER_txtELKPremiumTon_PROPERTIES
    },
    txtEVehicleNum: {
      name: "txtEVehicleNum",
      type: "TextBoxWidget",
      parent: "grpbxWeightTickets",
      Label: "E-Vehicle No.:",
      ColSpan: "6",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEVehicleNum_PROPERTIES

      // END_USER_CODE-USER_txtEVehicleNum_PROPERTIES
    },
    txtEWeightTkt: {
      name: "txtEWeightTkt",
      type: "TextBoxWidget",
      parent: "grpbxWeightTickets",
      Label: "E-Weight Tkt:",
      ColSpan: "6",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEWeightTkt_PROPERTIES

      // END_USER_CODE-USER_txtEWeightTkt_PROPERTIES
    },
    txtEWtInclVeh: {
      name: "txtEWtInclVeh",
      type: "TextBoxWidget",
      parent: "grpbxWeightTickets",
      Label: "E-Wt.Incl.Veh:",
      ColSpan: "2",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEWtInclVeh_PROPERTIES

      // END_USER_CODE-USER_txtEWtInclVeh_PROPERTIES
    },
    txtEWtOfPnut: {
      name: "txtEWtOfPnut",
      type: "TextBoxWidget",
      parent: "grpbxWeightTickets",
      Label: "E-Wt. of Pnut:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEWtOfPnut_PROPERTIES

      // END_USER_CODE-USER_txtEWtOfPnut_PROPERTIES
    },
    txtEWtOfVeh: {
      name: "txtEWtOfVeh",
      type: "TextBoxWidget",
      parent: "grpbxWeightTickets",
      Label: "E-Wt. of Veh:",
      ColSpan: "2",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEWtOfVeh_PROPERTIES

      // END_USER_CODE-USER_txtEWtOfVeh_PROPERTIES
    },
    txtExcessMoisture: {
      name: "txtExcessMoisture",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Excess Moisture:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtExcessMoisture_PROPERTIES

      // END_USER_CODE-USER_txtExcessMoisture_PROPERTIES
    },
    txtExcessSplitsLb: {
      name: "txtExcessSplitsLb",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtExcessSplitsLb_PROPERTIES

      // END_USER_CODE-USER_txtExcessSplitsLb_PROPERTIES
    },
    txtExcessSplitsTon: {
      name: "txtExcessSplitsTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "ExcessSplits",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtExcessSplitsTon_PROPERTIES

      // END_USER_CODE-USER_txtExcessSplitsTon_PROPERTIES
    },
    txtFANCY: {
      name: "txtFANCY",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "FANCY:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFANCY_PROPERTIES

      // END_USER_CODE-USER_txtFANCY_PROPERTIES
    },
    txtFancyGr: {
      name: "txtFancyGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Fancy:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFancyGr_PROPERTIES

      // END_USER_CODE-USER_txtFancyGr_PROPERTIES
    },
    txtFancyPct: {
      name: "txtFancyPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFancyPct_PROPERTIES

      // END_USER_CODE-USER_txtFancyPct_PROPERTIES
    },
    txtFM: {
      name: "txtFM",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "FM:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFM_PROPERTIES

      // END_USER_CODE-USER_txtFM_PROPERTIES
    },
    txtFMSampleWt: {
      name: "txtFMSampleWt",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "FM Sample Wt:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFMSampleWt_PROPERTIES

      // END_USER_CODE-USER_txtFMSampleWt_PROPERTIES
    },
    txtForeignMaterial: {
      name: "txtForeignMaterial",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Foreign Material:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtForeignMaterial_PROPERTIES

      // END_USER_CODE-USER_txtForeignMaterial_PROPERTIES
    },
    txtForeignMaterialGr: {
      name: "txtForeignMaterialGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Foreign Material:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtForeignMaterialGr_PROPERTIES

      // END_USER_CODE-USER_txtForeignMaterialGr_PROPERTIES
    },
    txtForeignMaterialPct: {
      name: "txtForeignMaterialPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtForeignMaterialPct_PROPERTIES

      // END_USER_CODE-USER_txtForeignMaterialPct_PROPERTIES
    },
    txtFreezeDamageGr: {
      name: "txtFreezeDamageGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Freeze Damage:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFreezeDamageGr_PROPERTIES

      // END_USER_CODE-USER_txtFreezeDamageGr_PROPERTIES
    },
    txtFreezeDamagePct: {
      name: "txtFreezeDamagePct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFreezeDamagePct_PROPERTIES

      // END_USER_CODE-USER_txtFreezeDamagePct_PROPERTIES
    },
    txtFRZ: {
      name: "txtFRZ",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "FRZ:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFRZ_PROPERTIES

      // END_USER_CODE-USER_txtFRZ_PROPERTIES
    },
    txtG: {
      name: "txtG",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "/G:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtG_PROPERTIES

      // END_USER_CODE-USER_txtG_PROPERTIES
    },
    txtGrossWeight: {
      name: "txtGrossWeight",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Gross Weight:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGrossWeight_PROPERTIES

      // END_USER_CODE-USER_txtGrossWeight_PROPERTIES
    },
    txtH: {
      name: "txtH",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "H x :",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtH_PROPERTIES

      // END_USER_CODE-USER_txtH_PROPERTIES
    },
    txtHullBrightness: {
      name: "txtHullBrightness",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Hulls Brightness:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHullBrightness_PROPERTIES

      // END_USER_CODE-USER_txtHullBrightness_PROPERTIES
    },
    txtHullBrightnessPct: {
      name: "txtHullBrightnessPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHullBrightnessPct_PROPERTIES

      // END_USER_CODE-USER_txtHullBrightnessPct_PROPERTIES
    },
    txtHULLS: {
      name: "txtHULLS",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "HULLS:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHULLS_PROPERTIES

      // END_USER_CODE-USER_txtHULLS_PROPERTIES
    },
    txtHullsGr: {
      name: "txtHullsGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Hulls:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHullsGr_PROPERTIES

      // END_USER_CODE-USER_txtHullsGr_PROPERTIES
    },
    txtHullsPct: {
      name: "txtHullsPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHullsPct_PROPERTIES

      // END_USER_CODE-USER_txtHullsPct_PROPERTIES
    },
    txtInspectionDateTime: {
      name: "txtInspectionDateTime",
      type: "DateTimeWidget",
      parent: "grpbxFarmInformation",
      Label: "Inspection Date/Time:",
      LengthRange: { MinLength: 0, MaxLength: 14 },
      ofTypeDomain: "d_dateTime",
      // START_USER_CODE-USER_txtInspectionDateTime_PROPERTIES

      // END_USER_CODE-USER_txtInspectionDateTime_PROPERTIES
    },
    txtJumboGr: {
      name: "txtJumboGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Jumbo:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtJumboGr_PROPERTIES

      // END_USER_CODE-USER_txtJumboGr_PROPERTIES
    },
    txtJumboPct: {
      name: "txtJumboPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtJumboPct_PROPERTIES

      // END_USER_CODE-USER_txtJumboPct_PROPERTIES
    },
    txtKernelsREL: {
      name: "txtKernelsREL",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Kernels REL:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKernelsREL_PROPERTIES

      // END_USER_CODE-USER_txtKernelsREL_PROPERTIES
    },
    txtKernelsRPS: {
      name: "txtKernelsRPS",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Kernels RPS:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKernelsRPS_PROPERTIES

      // END_USER_CODE-USER_txtKernelsRPS_PROPERTIES
    },
    txtKernelValueLb: {
      name: "txtKernelValueLb",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKernelValueLb_PROPERTIES

      // END_USER_CODE-USER_txtKernelValueLb_PROPERTIES
    },
    txtKernelValueTon: {
      name: "txtKernelValueTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Kernel Value:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKernelValueTon_PROPERTIES

      // END_USER_CODE-USER_txtKernelValueTon_PROPERTIES
    },
    txtlblTotalDiscountsLb: {
      name: "txtlblTotalDiscountsLb",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtlblTotalDiscountsLb_PROPERTIES

      // END_USER_CODE-USER_txtlblTotalDiscountsLb_PROPERTIES
    },

    txtLoadType: {
      name: "txtLoadType",
      type: "TextBoxWidget",
      parent: "grpbxFarmInformation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoadType_PROPERTIES
      //Visible:false
      // END_USER_CODE-USER_txtLoadType_PROPERTIES
    },
    txtLSK: {
      name: "txtLSK",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "LSK:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSK_PROPERTIES

      // END_USER_CODE-USER_txtLSK_PROPERTIES
    },
    txtLSKGr: {
      name: "txtLSKGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "LSK:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKGr_PROPERTIES

      // END_USER_CODE-USER_txtLSKGr_PROPERTIES
    },
    txtLSKPct: {
      name: "txtLSKPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKPct_PROPERTIES

      // END_USER_CODE-USER_txtLSKPct_PROPERTIES
    },
    txtLSKvc: {
      name: "txtLSKvc",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "LSK:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKvc_PROPERTIES

      // END_USER_CODE-USER_txtLSKvc_PROPERTIES
    },
    txtMeterReading: {
      name: "txtMeterReading",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Meter Reading:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMeterReading_PROPERTIES

      // END_USER_CODE-USER_txtMeterReading_PROPERTIES
    },
    txtMoisture: {
      name: "txtMoisture",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Moisture:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMoisture_PROPERTIES

      // END_USER_CODE-USER_txtMoisture_PROPERTIES
    },
    txtMST: {
      name: "txtMST",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "MST:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMST_PROPERTIES

      // END_USER_CODE-USER_txtMST_PROPERTIES
    },

    txtN: {
      name: "txtN",
      type: "TextBoxWidget",
      parent: "grpbxFarmInformation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtN_PROPERTIES
      //Visible:false
      // END_USER_CODE-USER_txtN_PROPERTIES
    },
    txtNetValPerTonExclSLK: {
      name: "txtNetValPerTonExclSLK",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Net Value Per Ton Excluding SLK:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetValPerTonExclSLK_PROPERTIES

      // END_USER_CODE-USER_txtNetValPerTonExclSLK_PROPERTIES
    },
    txtNetWeight: {
      name: "txtNetWeight",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Net Weight:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetWeight_PROPERTIES

      // END_USER_CODE-USER_txtNetWeight_PROPERTIES
    },
    txtNetWtExclLSK: {
      name: "txtNetWtExclLSK",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Net Weight Excluding LSK:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetWtExclLSK_PROPERTIES

      // END_USER_CODE-USER_txtNetWtExclLSK_PROPERTIES
    },
    txtOI: {
      name: "txtOI",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "O x I $:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOI_PROPERTIES

      // END_USER_CODE-USER_txtOI_PROPERTIES
    },
    txtOK: {
      name: "txtOK",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "OK:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOK_PROPERTIES

      // END_USER_CODE-USER_txtOK_PROPERTIES
    },
    txtOtherForeignMaterials: {
      name: "txtOtherForeignMaterials",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Other Foreign Materials:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOtherForeignMaterials_PROPERTIES

      // END_USER_CODE-USER_txtOtherForeignMaterials_PROPERTIES
    },
    txtOtherKernelsGr: {
      name: "txtOtherKernelsGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Other Kernels:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOtherKernelsGr_PROPERTIES

      // END_USER_CODE-USER_txtOtherKernelsGr_PROPERTIES
    },
    txtOtherKernelsPct: {
      name: "txtOtherKernelsPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOtherKernelsPct_PROPERTIES

      // END_USER_CODE-USER_txtOtherKernelsPct_PROPERTIES
    },
    txtProbingPattern: {
      name: "txtProbingPattern",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Probing Pattern:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtProbingPattern_PROPERTIES

      // END_USER_CODE-USER_txtProbingPattern_PROPERTIES
    },
    txtRevisionNumber: {
      name: "txtRevisionNumber",
      type: "TextBoxWidget",
      parent: "grpbxFarmInformation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRevisionNumber_PROPERTIES
      // END_USER_CODE-USER_txtRevisionNumber_PROPERTIES
    },


    txtSC95: {
      name: "txtSC95",
      type: "TextBoxWidget",
      parent: "grpbxFarmInformation",
      Label: "SC95 #",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSC95_PROPERTIES

      // END_USER_CODE-USER_txtSC95_PROPERTIES
    },
    txtSeg: {
      name: "txtSeg",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "Seg:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeg_PROPERTIES

      // END_USER_CODE-USER_txtSeg_PROPERTIES
    },
    txtSellerName: {
      name: "txtSellerName",
      type: "TextBoxWidget",
      parent: "grpbxCMAInformation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSellerName_PROPERTIES

      // END_USER_CODE-USER_txtSellerName_PROPERTIES
    },
    txtSellerNumber: {
      name: "txtSellerNumber",
      type: "TextBoxWidget",
      parent: "grpbxCMAInformation",
      Label: "Seller:",
      LengthRange: { MinLength: 0, MaxLength: 5 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSellerNumber_PROPERTIES

      // END_USER_CODE-USER_txtSellerNumber_PROPERTIES
    },
    txtSettlementDate: {
      name: "txtSettlementDate",
      type: "DateTimeWidget",
      parent: "grpbxFarmInformation",
      Label: "Settlement Date:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_dateTime",
      // START_USER_CODE-USER_txtSettlementDate_PROPERTIES

      // END_USER_CODE-USER_txtSettlementDate_PROPERTIES
    },
    txtSignOfInspector: {
      name: "txtSignOfInspector",
      type: "TextBoxWidget",
      parent: "grpbxSignature",
      Label: "Signature of Inspector:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSignOfInspector_PROPERTIES

      // END_USER_CODE-USER_txtSignOfInspector_PROPERTIES
    },
    txtSMK: {
      name: "txtSMK",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "SMK:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK_PROPERTIES

      // END_USER_CODE-USER_txtSMK_PROPERTIES
    },
    // txtSMK1Gr: {
    //   name: "txtSMK1Gr",
    //   type: "TextBoxWidget",
    //   parent: "grpbxGradeCalculation",
    //   Label: "SMK 1(Screen Size)",
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtSMK1Gr_PROPERTIES

    //   // END_USER_CODE-USER_txtSMK1Gr_PROPERTIES
    // },
    // txtSMK1Pct: {
    //   name: "txtSMK1Pct",
    //   type: "TextBoxWidget",
    //   parent: "grpbxGradeCalculation",
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtSMK1Pct_PROPERTIES

    //   // END_USER_CODE-USER_txtSMK1Pct_PROPERTIES
    // },
    // txtSMK2Gr: {
    //   name: "txtSMK2Gr",
    //   type: "TextBoxWidget",
    //   parent: "grpbxGradeCalculation",
    //   Label: "SMK 2(Screen Size)",
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtSMK2Gr_PROPERTIES

    //   // END_USER_CODE-USER_txtSMK2Gr_PROPERTIES
    // },
    // txtSMK2Pct: {
    //   name: "txtSMK2Pct",
    //   type: "TextBoxWidget",
    //   parent: "grpbxGradeCalculation",
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtSMK2Pct_PROPERTIES

    //   // END_USER_CODE-USER_txtSMK2Pct_PROPERTIES
    // },
    // txtSMK3Gr: {
    //   name: "txtSMK3Gr",
    //   type: "TextBoxWidget",
    //   parent: "grpbxGradeCalculation",
    //   Label: "SMK 3(Screen Size)",
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtSMK3Gr_PROPERTIES

    //   // END_USER_CODE-USER_txtSMK3Gr_PROPERTIES
    // },
    // txtSMK3Pct: {
    //   name: "txtSMK3Pct",
    //   type: "TextBoxWidget",
    //   parent: "grpbxGradeCalculation",
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtSMK3Pct_PROPERTIES

    //   // END_USER_CODE-USER_txtSMK3Pct_PROPERTIES
    // },
    // txtSMK4Gr: {
    //   name: "txtSMK4Gr",
    //   type: "TextBoxWidget",
    //   parent: "grpbxGradeCalculation",
    //   Label: "SMK 4(Screen Size)",
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtSMK4Gr_PROPERTIES

    //   // END_USER_CODE-USER_txtSMK4Gr_PROPERTIES
    // },
    // txtSMK4Pct: {
    //   name: "txtSMK4Pct",
    //   type: "TextBoxWidget",
    //   parent: "grpbxGradeCalculation",
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtSMK4Pct_PROPERTIES

    //   // END_USER_CODE-USER_txtSMK4Pct_PROPERTIES
    // },
    txtSMKRS: {
      name: "txtSMKRS",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "SMKRS:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMKRS_PROPERTIES

      // END_USER_CODE-USER_txtSMKRS_PROPERTIES
    },
    txtSMKRSGr: {
      name: "txtSMKRSGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "SMKRS:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMKRSGr_PROPERTIES

      // END_USER_CODE-USER_txtSMKRSGr_PROPERTIES
    },
    txtSMKRSPct: {
      name: "txtSMKRSPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMKRSPct_PROPERTIES

      // END_USER_CODE-USER_txtSMKRSPct_PROPERTIES
    },
    txtSoundSplitsGr: {
      name: "txtSoundSplitsGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Sound Splits:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSoundSplitsGr_PROPERTIES

      // END_USER_CODE-USER_txtSoundSplitsGr_PROPERTIES
    },
    txtSoundSplitsPct: {
      name: "txtSoundSplitsPct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSoundSplitsPct_PROPERTIES

      // END_USER_CODE-USER_txtSoundSplitsPct_PROPERTIES
    },
    txtSS: {
      name: "txtSS",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "SS:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSS_PROPERTIES

      // END_USER_CODE-USER_txtSS_PROPERTIES
    },

    txtTKC: {
      name: "txtTKC",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTKC_PROPERTIES
      // END_USER_CODE-USER_txtTKC_PROPERTIES
    },
    txtTotalDamageGr: {
      name: "txtTotalDamageGr",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Total Damage:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalDamageGr_PROPERTIES

      // END_USER_CODE-USER_txtTotalDamageGr_PROPERTIES
    },
    txtTotalDamagePct: {
      name: "txtTotalDamagePct",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalDamagePct_PROPERTIES

      // END_USER_CODE-USER_txtTotalDamagePct_PROPERTIES
    },
    txtTotalDiscountsTon: {
      name: "txtTotalDiscountsTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Total Discounts:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalDiscountsTon_PROPERTIES

      // END_USER_CODE-USER_txtTotalDiscountsTon_PROPERTIES
    },
    txtTotalKernels: {
      name: "txtTotalKernels",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "Total Kernels:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalKernels_PROPERTIES

      // END_USER_CODE-USER_txtTotalKernels_PROPERTIES
    },
    txtTotalKernelsHulls: {
      name: "txtTotalKernelsHulls",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Total Kernels & Hulls:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalKernelsHulls_PROPERTIES

      // END_USER_CODE-USER_txtTotalKernelsHulls_PROPERTIES
    },
    txtTotalKrnls: {
      name: "txtTotalKrnls",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Total Kernels:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalKrnls_PROPERTIES

      // END_USER_CODE-USER_txtTotalKrnls_PROPERTIES
    },
    txtTotalLb: {
      name: "txtTotalLb",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalLb_PROPERTIES

      // END_USER_CODE-USER_txtTotalLb_PROPERTIES
    },
    txtTotalLoadVal: {
      name: "txtTotalLoadVal",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Total Value of Load (g):",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalLoadVal_PROPERTIES

      // END_USER_CODE-USER_txtTotalLoadVal_PROPERTIES
    },
    txtTotalSMK: {
      name: "txtTotalSMK",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Total SMK:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalSMK_PROPERTIES

      // END_USER_CODE-USER_txtTotalSMK_PROPERTIES
    },
    txtTotalTon: {
      name: "txtTotalTon",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Total:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalTon_PROPERTIES

      // END_USER_CODE-USER_txtTotalTon_PROPERTIES
    },
    txtTotKrnlsHulls: {
      name: "txtTotKrnlsHulls",
      type: "TextBoxWidget",
      parent: "grpbxHMC",
      Label: "Tot krnls + hulls(excl. lsk):",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotKrnlsHulls_PROPERTIES

      // END_USER_CODE-USER_txtTotKrnlsHulls_PROPERTIES
    },
    txtValenciaCleanSampleWt: {
      name: "txtValenciaCleanSampleWt",
      type: "TextBoxWidget",
      parent: "grpbxGradeCalculation",
      Label: "Valencia Clean Sample Wt:",
      ColSpan: "2",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValenciaCleanSampleWt_PROPERTIES

      // END_USER_CODE-USER_txtValenciaCleanSampleWt_PROPERTIES
    },
    txtValPerPoundExclSLK: {
      name: "txtValPerPoundExclSLK",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Value Per Pound Excluding LSK:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValPerPoundExclSLK_PROPERTIES

      // END_USER_CODE-USER_txtValPerPoundExclSLK_PROPERTIES
    },
    txtValPerPoundInclSLK: {
      name: "txtValPerPoundInclSLK",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Value Per Pound Including LSK:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValPerPoundInclSLK_PROPERTIES

      // END_USER_CODE-USER_txtValPerPoundInclSLK_PROPERTIES
    },
    txtWtIncludingVehicle: {
      name: "txtWtIncludingVehicle",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Wt. Including Vehicle:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtIncludingVehicle_PROPERTIES

      // END_USER_CODE-USER_txtWtIncludingVehicle_PROPERTIES
    },
    txtWtlessFM: {
      name: "txtWtlessFM",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Weight Less FM:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtlessFM_PROPERTIES

      // END_USER_CODE-USER_txtWtlessFM_PROPERTIES
    },
    txtWtOfVehicle: {
      name: "txtWtOfVehicle",
      type: "TextBoxWidget",
      parent: "grpbxValueCalculation",
      Label: "Wt. of Vehicle:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtOfVehicle_PROPERTIES

      // END_USER_CODE-USER_txtWtOfVehicle_PROPERTIES
    },
    grpbxFarmInformation: {
      name: "grpbxFarmInformation",
      type: "GroupBoxWidget",
      parent: "FV95Residue",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxFarmInformation_PROPERTIES

      // END_USER_CODE-USER_grpbxFarmInformation_PROPERTIES
    },
    grpbxCMAInformation: {
      name: "grpbxCMAInformation",
      type: "GroupBoxWidget",
      parent: "FV95Residue",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxCMAInformation_PROPERTIES

      // END_USER_CODE-USER_grpbxCMAInformation_PROPERTIES
    },
    grpbxWeightTickets: {
      name: "grpbxWeightTickets",
      type: "GroupBoxWidget",
      parent: "FV95Residue",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_grpbxWeightTickets_PROPERTIES

      // END_USER_CODE-USER_grpbxWeightTickets_PROPERTIES
    },
    grpbxPeanutInfo: {
      name: "grpbxPeanutInfo",
      type: "GroupBoxWidget",
      parent: "FV95Residue",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxPeanutInfo_PROPERTIES

      // END_USER_CODE-USER_grpbxPeanutInfo_PROPERTIES
    },
    grpbxInspectorRemarks: {
      name: "grpbxInspectorRemarks",
      type: "GroupBoxWidget",
      parent: "FV95Residue",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxInspectorRemarks_PROPERTIES

      // END_USER_CODE-USER_grpbxInspectorRemarks_PROPERTIES
    },
    grpbxGradeCalculation: {
      name: "grpbxGradeCalculation",
      type: "GroupBoxWidget",
      parent: "FV95Residue",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxGradeCalculation_PROPERTIES

      // END_USER_CODE-USER_grpbxGradeCalculation_PROPERTIES
    },
    grpbxHMC: {
      name: "grpbxHMC",
      type: "GroupBoxWidget",
      parent: "FV95Residue",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxHMC_PROPERTIES

      // END_USER_CODE-USER_grpbxHMC_PROPERTIES
    },
    grpbxValueCalculation: {
      name: "grpbxValueCalculation",
      type: "GroupBoxWidget",
      parent: "FV95Residue",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxValueCalculation_PROPERTIES

      // END_USER_CODE-USER_grpbxValueCalculation_PROPERTIES
    },
    grpbxSignature: {
      name: "grpbxSignature",
      type: "GroupBoxWidget",
      parent: "FV95Residue",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxSignature_PROPERTIES

      // END_USER_CODE-USER_grpbxSignature_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "FV95Residue",
      Height: "",
      Width: "",
      ColsForTabLandscape: "7",
      HasLabel: false,
      Cols: "7",
      ColsForTabPotrait: "7",
      ColsForLargeDesktop: "7",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  const [revisionno, setRevNo] = useState(0)
  const [loading, setLoading] = useState(false);
  const [formLoadComplete, setformLoadComplete] = useState(false);
  const [activityAfterformLoad, setActivityAfterformLoad] = useState(false);
  const [disableGradeCalculationsBoolean, setdisableGradeCalculationsBoolean] = useState('');

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    setValue(thisObj, "ddLocation", '')
    setValue(thisObj, "ddPeanutType", '')
    setValue(thisObj, "ddSeg", '')
    setValue(thisObj, "ddEdibleOil", '')
    setValue(thisObj, "ddSignOfWeigher", '')
    // hide(thisObj,'txtLoadType')
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    //setData(thisObj, 'selectedRowReload', true);
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    //let loadType = getData(thisObj, 'FV95ResidueScreenData');
    //FormLoad()
  }, [setRevNo]);


  useEffect(() => {
    FormLoad()

  }, []);

  useEffect(() => {
    if (formLoadComplete == true) {
      setLoading(true)
      let loadType = getData(thisObj, 'FV95ResidueScreenData');
      //let loadType = 'ADD';
      if (loadType.type == 'ADD') {
        document.getElementById('btnOk').innerHTML = "Create";
      }

      else if (loadType.type == 'EDIT') {
        document.getElementById('btnOk').innerHTML = "Save";
        setValue(thisObj, 'ddLocation', loadType.C_ORG_BUY_POINT)
        setValue(thisObj, 'txtSC95', loadType.C_FV95_NUM)
        hide(thisObj, 'chkboxResale', false)
        show(thisObj, 'btnPrint')
        show(thisObj, 'btnDelete')
        disable(thisObj, 'ddLocation')
        disable(thisObj, 'txtSC95')
        disable(thisObj, 'txtInspectionDateTime')
        EditForm(loadType);
      }
      else {
        document.getElementById('btnOk').innerHTML = "Ok";
      }
      setValue(thisObj, 'txtN', 'N')
      enable(thisObj, 'radioHighMoisture')
      enable(thisObj, 'ddPeanutType')

      //ontxtNChange()
      setActivityAfterformLoad(true)

      setTimeout(() => {
        setLoading(false)
      }, 3000);
    }
  }, [formLoadComplete])

  useEffect(() => {
    if (activityAfterformLoad == true) {
      LocationApi()
    }
  }, [activityAfterformLoad])


  async function FormLoad() {
    setLoading(true)
    let loadType = getData(thisObj, 'FV95ResidueScreenData');
    //let loadType = 'ADD'
    await bFillBuyingPointList();// Method for binding Buying Points
    await bFillTypeList(); // Method for binding Peanut Type dropdown
    await bFillSegmentList(); // Method for binding Segments
    await bFillEdibleOil();//Method for binding Edible Oil   
    setValue(thisObj, 'radioHighMoisture', '2')
    let opt_hmYESNO = getValue(thisObj, "radioHighMoisture");
    setValue(thisObj, 'txtLoadType', loadType.type)

    if (loadType.type == 'ADD') {
      show(thisObj, 'chkboxResale')
      hide(thisObj, 'btnPrint', false)
      hide(thisObj, 'btnDelete', false)
      enable(thisObj, 'ddLocation')
      enable(thisObj, 'txtSC95')
      enable(thisObj, 'txtInspectionDateTime')
    }
    else if (loadType.type == 'EDIT') {
      setValue(thisObj, 'ddLocation', loadType.C_ORG_BUY_POINT)
      setValue(thisObj, 'txtSC95', loadType.C_FV95_NUM)
      hide(thisObj, 'chkboxResale', false)
      show(thisObj, 'btnPrint')
      show(thisObj, 'btnDelete')
      disable(thisObj, 'ddLocation')
      disable(thisObj, 'txtSC95')
      disable(thisObj, 'txtInspectionDateTime')
    }

    clearValue(thisObj, "txtSeg")
    clearValue(thisObj, "txtFM")
    clearValue(thisObj, "txtLSK")
    clearValue(thisObj, "txtMST")
    clearValue(thisObj, "txtSMK")
    clearValue(thisObj, "txtSS")
    clearValue(thisObj, "txtOK")
    clearValue(thisObj, "txtFRZ")
    clearValue(thisObj, "txtCRMD")
    clearValue(thisObj, "txtDAM")
    clearValue(thisObj, "txtHULLS")
    clearValue(thisObj, "txtELK")
    clearValue(thisObj, "txtFANCY")
    clearValue(thisObj, "txtSMKRS")
    clearValue(thisObj, "txtTotalKrnls")
    clearValue(thisObj, "txtTotalKernelsHulls")

    //document.getElementsByClassName("txtTKC")[0].style.visibility = "hidden";
    hide(thisObj, "txtTKC", false)
    document.getElementById("txtDamagedKRS").style.backgroundColor = "gray";
    document.getElementById("txtELKDamage").style.backgroundColor = "gray";
    setformLoadComplete(true)
    setLoading(false)
  }

  //LocationApi call 
  async function LocationApi() {
    let js = []
    let data = await ContractManagementService.RetrieveBuyingPointControlDetails('PN1150', null, null, null, null)
    //let data = response
    if (data !== undefined && data.length !== 0) {
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].gram_entry_ind }
        js.push(obj)
      }
      let BuyingPointList = js
      let SelectedBuyingPointValue = getValue(thisObj, "ddLocation")
      let lstGramEntryInd
      if (SelectedBuyingPointValue !== "" && SelectedBuyingPointValue !== undefined) {
        lstGramEntryInd = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).description;
      }
      EnabelDisableLast(lstGramEntryInd, getValue(thisObj, "ddPeanutType"))
    }
  }

  // Method for binding Buying Points
  const bFillBuyingPointList = async () => {
    let js = []
    let ParentData = getData(thisObj, "FV95ResidueScreenData")
    //js.push({ key: '', description: '>>>All Buying Points<<<' });
    let data = await ContractManagementService.RetrieveBuyingPointControlDetails('PN1150', null, null, null, null)
    //let data = response
    if (data !== undefined && data.length !== 0) {
      for (var i = 0; i < data.length; i++) {
        let obj = { area_id: data[i].area_id, gram_entry_ind: data[i].gram_entry_ind, key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddLocation: {
            ...state["ddLocation"],
            valueList: js
          }
        }
      })
      if (js.length > 0) {
        setValue(thisObj, 'ddLocation', js[0].key)
        if (ParentData.type == 'EDIT') {
          await bFillWeigherList(ParentData.C_ORG_BUY_POINT)
        }
        else {
          await bFillWeigherList(js[0].key)
        }
        let BuyingPointList = thisObj.state['ddLocation'].valueList;
        let SelectedBuyingPointValue = getValue(thisObj, "ddLocation", '')
        let lstGramEntryInd = null;
        let lstpnutTypeId = null;
        let buyingPointId = null
        if (SelectedBuyingPointValue !== "" && SelectedBuyingPointValue !== undefined) {
          buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
          lstGramEntryInd = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).gram_entry_ind;
        }
        EnabelDisableGradeEntry(lstGramEntryInd, null);
      }
    }
  }

  //Method for binding Peanut Type dropdown
  const bFillTypeList = async () => {
    let js = []
    //js.push({ key: '', description: '>>> All Types <<<' })
    let data = await ContractManagementService.RetrievePeanutTypeControls(null)
    //let data = response

    if (data !== undefined && data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeName }
        js.push(obj)
      }
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddPeanutType: {
          ...state["ddPeanutType"],
          valueList: js,
        }
      }
    })
    if (js.length > 0) {
      setValue(thisObj, 'ddPeanutType', js[0].key)
      onPeanutTypeChange();
    }

  }
  // Method for binding Segment list  
  const bFillSegmentList = async () => {
    let js = []
    //js.push({ key: '', description: '>>> All Segs <<<' })
    js.push({ key: '1', description: 'Seg 1' })
    js.push({ key: '2', description: 'Seg 2' })
    js.push({ key: '3', description: 'Seg 3' })
    js.push({ key: 'X', description: 'X' })

    thisObj.setState(current => {
      return {
        ...current,
        ddSeg: {
          ...state["ddSeg"],
          valueList: js
        }
      }
    })
    if (js.length > 0) {
      setValue(thisObj, 'ddSeg', js[0].key)
    }
  }
  //Method for binding Edible Oil
  const bFillEdibleOil = async () => {
    let js = []
    //js.push({ key: '', description: '>>> All <<<' })
    js.push({ key: 'E', description: 'Edible' })
    js.push({ key: 'O', description: 'Oil' })
    thisObj.setState(current => {
      return {
        ...current,
        ddEdibleOil: {
          ...state["ddEdibleOil"],
          valueList: js
        }
      }
    })
    if (js.length > 0) {
      setValue(thisObj, 'ddEdibleOil', js[0].key)
    }
  }


  //Buying Point dropdown change event
  const onddLocationChange = async () => {
    try {
      setLoading(true)
      let BuyingPointList = thisObj.state['ddLocation'].valueList;
      let SelectedBuyingPointValue = getValue(thisObj, "ddLocation", '')
      let PnutTypeList=thisObj.state['ddPeanutType'].valueList;
      let SelectedPnutTypeValue = getValue(thisObj, "ddPeanutType", '')
      let lstGramEntryInd = null;
      let lstpnutTypeId = null;
      let buyingPointId = null
      if (SelectedBuyingPointValue !== "" && SelectedBuyingPointValue !== undefined) {
        buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
        lstGramEntryInd = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).gram_entry_ind;
      }

      if (SelectedPnutTypeValue !== "" && SelectedPnutTypeValue !== undefined) {
        lstpnutTypeId = PnutTypeList.find(elem => elem.key === SelectedPnutTypeValue).key;
      }
      //Bind WeigherList based on Buying Point
      await bFillWeigherList(buyingPointId);
      EnabelDisableGradeEntry(lstGramEntryInd, lstpnutTypeId);
      setLoading(false)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Buying Point dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddLocationChange = onddLocationChange;



  const bFillWeigherList = async (buyingPointId) => {

    let js = [];
    let obj = ({ key: '', description: '' });
    js.push(obj)
    if (buyingPointId !== null && buyingPointId !== '') {
      let data = await SettlementService.RetrieveWeigherControlList(buyingPointId, null, null)

      if (data !== null && data !== undefined && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          obj = { key: data[i].weigher_name, description: data[i].weigher_name }
          js.push(obj);
        }
      }

    }
    thisObj.setState(current => {
      return {
        ...current,
        ddSignOfWeigher: {
          ...state["ddSignOfWeigher"],
          valueList: js
        }
      }
    });
    // if (js.length > 0) {
    //   setValue(thisObj, 'ddSignOfWeigher', js[0].key)
    // }
  }

  const GetDocumentDetailsByBuyingPointId = (buyingPointId) => {
    let docDetails = null;
    if (buyingPointId !== null && buyingPointId !== '') {
      SettlementService.RetrieveCheck1007OneDocumentDetails(buyingPointId)
        .then(response => {
          let data = response;

          if (data !== null && data !== undefined && data.length > 0) {
            docDetails = data[0].onedoc_ind;
          }
        })
    }
    thisObj.setState(current => {
      return {
        ...current,
        DocumentDetails: {
          ...state["DocumentDetails"],
          valueDetails: docDetails
        }
      }
    });
  }









  //Peanut Type dropdown change event
  const onPeanutTypeChange = () => {
    try {
      setLoading(true)
      let PnutTypeList = thisObj.state['ddPeanutType'].valueList;
      let SelectedPnutTypeValue = getValue(thisObj, "ddPeanutType", '')
      let BuyingPointList = thisObj.state['ddLocation'].valueList;
      let SelectedBuyingPointValue = getValue(thisObj, "ddLocation", '')

      let lstGramEntryInd = null;
      let lstpnutTypeId = null;
      if (SelectedPnutTypeValue !== "" && SelectedPnutTypeValue !== undefined) {
        lstpnutTypeId = PnutTypeList.find(elem => elem.key === SelectedPnutTypeValue).key;
        // EnabelDisableGradeEntry(lstGramEntryInd, lstpnutTypeId);
      }

      if (SelectedBuyingPointValue !== "" && SelectedBuyingPointValue !== undefined) {
        // buyingPointId = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).key;
        lstGramEntryInd = BuyingPointList.find(elem => elem.key === SelectedBuyingPointValue).gram_entry_ind;
      }
      EnabelDisableGradeEntry(lstGramEntryInd, lstpnutTypeId);
      setLoading(false)

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Peanut Type dropdown change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onddPeanutTypeChange = onPeanutTypeChange;


  //RadioHighMoistrue click
  const onradioHighMoistureChange = () => {
    try {

      let opt_hmYESNO = getValue(thisObj, "radioHighMoisture");
      if (opt_hmYESNO == "2") {
        setValue(thisObj, "txtSeg", "");
        setValue(thisObj, "txtFM", "");
        setValue(thisObj, "txtLSK", "");
        setValue(thisObj, "txtMST", "");
        setValue(thisObj, "txtSMK", "");
        setValue(thisObj, "txtSS", "");
        setValue(thisObj, "txtOK", "");
        setValue(thisObj, "txtFRZ", "");
        setValue(thisObj, "txtCRMD", "");
        setValue(thisObj, "txtDAM", "");
        setValue(thisObj, "txtHULLS", "");
        setValue(thisObj, "txtELK", "");
        setValue(thisObj, "txtFANCY", "");
        setValue(thisObj, "txtSMKRS", "");
        setValue(thisObj, "txtTotalKrnls", "");
        setValue(thisObj, "txtTotalKernelsHulls", "");
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception occured in radioHighMoisture"
        )
      }
      return false;
    }
    return true;
  }
  thisObj.onradioHighMoistureChange = onradioHighMoistureChange;


  //textbox onBlur events



  const ontxtEWtInclVehBlur = () => {

    if (IsNumeric.test(Number(getValue(thisObj, 'txtEWtInclVeh')))) {
      setValue(thisObj, 'txtEWtInclVeh', Math.round(getValue(thisObj, 'txtEWtInclVeh')) === 'NaN' ? 0 : Math.round(getValue(thisObj, 'txtEWtInclVeh')))
      setValue(thisObj, 'txtGrossWeight', Math.round(getValue(thisObj, 'txtEWtOfPnut')) === 'NaN' ? 0 : Math.round(getValue(thisObj, 'txtEWtOfPnut')))
    }
    else {
      clearValue(thisObj, 'txtEWtInclVeh')
    }

  }
  thisObj.ontxtEWtInclVehBlur = ontxtEWtInclVehBlur;

  const ontxtBluePanWtBlur = () => {
    setValue(thisObj, 'txtBluePanWt', Number(getValue(thisObj, 'txtBluePanWt')).toFixed(2) === 'NaN' ? 0 : Number(getValue(thisObj, 'txtBluePanWt')).toFixed(2))

  }
  thisObj.ontxtBluePanWtBlur = ontxtBluePanWtBlur;

  const ontxtKernelsRELBlur = () => {
    setValue(thisObj, 'txtKernelsREL', Number(getValue(thisObj, 'txtKernelsREL')).toFixed(2) === 'NaN' ? 0 : Number(getValue(thisObj, 'txtKernelsREL')).toFixed(2))

  }
  thisObj.ontxtKernelsRELBlur = ontxtKernelsRELBlur;

  const ontxtKernelsRPSBlur = () => {
    setValue(thisObj, 'txtKernelsRPS', Number(getValue(thisObj, 'txtKernelsRPS')).toFixed(2) === 'NaN' ? 0 : Number(getValue(thisObj, 'txtKernelsRPS')).toFixed(2))

  }
  thisObj.ontxtKernelsRPSBlur = ontxtKernelsRPSBlur;

  const ontxtMeterReadingBlur = () => {
    setValue(thisObj, 'txtMeterReading', Math.round(getValue(thisObj, 'txtMeterReading')).toFixed(2) === 'NaN' ? 0 : Number(getValue(thisObj, 'txtMeterReading')).toFixed(2))

  }
  thisObj.ontxtMeterReadingBlur = ontxtMeterReadingBlur;




  const ontxtConcealedRMDGrBlur = () => {
    setValue(thisObj, 'txtConcealedRMDGr', Number(getValue(thisObj, 'txtConcealedRMDGr')).toFixed(2) === 'NaN' ? 0 : Number(getValue(thisObj, 'txtConcealedRMDGr')).toFixed(2))

  }
  thisObj.ontxtConcealedRMDGrBlur = ontxtConcealedRMDGrBlur;



  const ontxtConcealedRMDPctBlur = () => {
    setValue(thisObj, 'txtConcealedRMDPct', Math.round(getValue(thisObj, 'txtConcealedRMDPct')).toFixed(2) === 'NaN' ? 0 : Math.round(getValue(thisObj, 'txtConcealedRMDPct')).toFixed(2))

  }
  thisObj.ontxtConcealedRMDPctBlur = ontxtConcealedRMDPctBlur;


  const ontxtCrkOrBrkShellsGrBlur = () => {
    setValue(thisObj, 'txtCrkOrBrkShellsGr', Number(getValue(thisObj, 'txtCrkOrBrkShellsGr')).toFixed(2) === 'NaN' ? 0.0 : Number(getValue(thisObj, 'txtCrkOrBrkShellsGr')).toFixed(2))
  }
  thisObj.ontxtCrkOrBrkShellsGrBlur = ontxtCrkOrBrkShellsGrBlur;


  const ontxtCrkOrBrkShellsPctBlur = () => {
    setValue(thisObj, 'txtCrkOrBrkShellsPct', Math.round(getValue(thisObj, 'txtCrkOrBrkShellsPct')).toFixed(2) === 'NaN' ? 0.0 : Math.round(getValue(thisObj, 'txtCrkOrBrkShellsPct')).toFixed(2))

  }
  thisObj.ontxtCrkOrBrkShellsPctBlur = ontxtCrkOrBrkShellsPctBlur;

  const ontxtDamageSplitsBlur = () => {
    setValue(thisObj, 'txtDamageSplits', Number(getValue(thisObj, 'txtDamageSplits')).toFixed(2) === 'NaN' ? 0.0 :Number(getValue(thisObj, 'txtDamageSplits')).toFixed(2))

  }
  thisObj.ontxtDamageSplitsBlur = ontxtDamageSplitsBlur;

  const ontxtDamagedKRSBlur = () => {
    setValue(thisObj, 'txtDamagedKRS', Math.round(getValue(thisObj, 'txtDamagedKRS')).toFixed(2) === 'NaN' ? 0.0 : Math.round(getValue(thisObj, 'txtDamagedKRS')).toFixed(2))

  }
  thisObj.ontxtDamagedKRSBlur = ontxtDamagedKRSBlur;

  const ontxtDiscoloredShellsGrBlur = () => {
    setValue(thisObj, 'txtDiscoloredShellsGr', Number(getValue(thisObj, 'txtDiscoloredShellsGr')).toFixed(2) === 'NaN' ? 0.0 : Number(getValue(thisObj, 'txtDiscoloredShellsGr')).toFixed(2))

  }
  thisObj.ontxtDiscoloredShellsGrBlur = ontxtDiscoloredShellsGrBlur;

  const ontxtDiscoloredShellsPctBlur = () => {
    setValue(thisObj, 'txtDiscoloredShellsPct', Math.round(getValue(thisObj, 'txtDiscoloredShellsPct')).toFixed(2) === 'NaN' ? 0.0 : Math.round(getValue(thisObj, 'txtDiscoloredShellsPct')).toFixed(2))

  }
  thisObj.ontxtDiscoloredShellsPctBlur = ontxtDiscoloredShellsPctBlur;


  const ontxtELKDamageBlur = () => {
    setValue(thisObj, 'txtELKDamage', Math.round(getValue(thisObj, 'txtELKDamage')).toFixed(2) === 'NaN' ? 0.0 : Math.round(getValue(thisObj, 'txtELKDamage')).toFixed(2))

  }
  thisObj.ontxtELKDamageBlur = ontxtELKDamageBlur;


  const ontxtELKPctBlur = () => {
    setValue(thisObj, 'txtELKPct', Math.round(getValue(thisObj, 'txtELKPct')))

  }
  thisObj.ontxtELKPctBlur = ontxtELKPctBlur;

  const ontxtFancyGrBlur = () => {
    setValue(thisObj, 'txtFancyGr', Number(getValue(thisObj, 'txtFancyGr')).toFixed(2) === 'NaN' ? 0.0 : Number(getValue(thisObj, 'txtFancyGr')).toFixed(2))

  }
  thisObj.ontxtFancyGrBlur = ontxtFancyGrBlur;

  const ontxtFancyPctBlur = () => {
    setValue(thisObj, 'txtFancyPct', Math.round(getValue(thisObj, 'txtFancyPct')).toFixed(2) === 'NaN' ? 0.0 : Math.round(getValue(thisObj, 'txtFancyPct')).toFixed(2))

  }
  thisObj.ontxtFancyPctBlur = ontxtFancyPctBlur;


  const ontxtForeignMaterialGrBlur = () => {
    setValue(thisObj, 'txtForeignMaterialGr', Math.round(getValue(thisObj, 'txtForeignMaterialGr')).toFixed(2) === 'NaN' ? 0.0 : Number(getValue(thisObj, 'txtForeignMaterialGr')).toFixed(2))

  }
  thisObj.ontxtForeignMaterialGrBlur = ontxtForeignMaterialGrBlur;


  const ontxtForeignMaterialPctBlur = () => {
    setValue(thisObj, 'txtForeignMaterialPct', Math.round(getValue(thisObj, 'txtForeignMaterialPct')).toFixed(2) === 'NaN' ? 0.0 : Math.round(getValue(thisObj, 'txtForeignMaterialPct')).toFixed(2))

  }
  thisObj.ontxtForeignMaterialPctBlur = ontxtForeignMaterialPctBlur;

  const ontxtFreezeDamageGrBlur = () => {
    setValue(thisObj, 'txtFreezeDamageGr', Number(getValue(thisObj, 'txtFreezeDamageGr')).toFixed(2) === 'NaN' ? 0.0 : Number(getValue(thisObj, 'txtFreezeDamageGr')).toFixed(2))

  }
  thisObj.ontxtFreezeDamageGrBlur = ontxtFreezeDamageGrBlur;

  const ontxtFreezeDamagePctBlur = () => {
    setValue(thisObj, 'txtFreezeDamagePct', Math.round(getValue(thisObj, 'txtFreezeDamagePct')).toFixed(2) === 'NaN' ? 0.0 : Math.round(getValue(thisObj, 'txtFreezeDamagePct')).toFixed(2))

  }
  thisObj.ontxtFreezeDamagePctBlur = ontxtFreezeDamagePctBlur;

  // const ontxtSC95Blur = () => {
  //   setValue(thisObj, 'txtSC95', getValue(thisObj, 'txtSC95').toString().toUpperCase())

  // }
  // thisObj.ontxtSC95Blur = ontxtSC95Blur;


  const ontxtHullBrightnessBlur = () => {
    setValue(thisObj, 'txtHullBrightness', Number(getValue(thisObj, 'txtHullBrightness')).toFixed(2) === 'NaN' ? 0.0 : Number(getValue(thisObj, 'txtHullBrightness')).toFixed(2))

  }
  thisObj.ontxtHullBrightnessBlur = ontxtHullBrightnessBlur;


  const ontxtHullBrightnessPctBlur = () => {
    setValue(thisObj, 'txtHullBrightnessPct', Math.round(getValue(thisObj, 'txtHullBrightnessPct')).toFixed(2) === 'NaN' ? 0.0 : Math.round(getValue(thisObj, 'txtHullBrightnessPct')).toFixed(2))

  }
  thisObj.ontxtHullBrightnessPctBlur = ontxtHullBrightnessPctBlur;


  const ontxtHullsGrBlur = () => {
    setValue(thisObj, 'txtHullsGr', Math.round(getValue(thisObj, 'txtHullsGr')).toFixed(2) === 'NaN' ? 0.0 : Number(getValue(thisObj, 'txtHullsGr')).toFixed(2))

  }
  thisObj.ontxtHullsGrBlur = ontxtHullsGrBlur;


  const ontxtHullsPctBlur = () => {
    setValue(thisObj, 'txtHullsPct', Math.round(getValue(thisObj, 'txtHullsPct')).toFixed(2) === 'NaN' ? 0.00 : Math.round(getValue(thisObj, 'txtHullsPct')).toFixed(2))

  }
  thisObj.ontxtHullsPctBlur = ontxtHullsPctBlur;



  const ontxtJumboGrBlur = () => {
    setValue(thisObj, 'txtJumboGr', Number(getValue(thisObj, 'txtJumboGr')).toFixed(2) === 'NaN' ? 0.0 : Number(getValue(thisObj, 'txtJumboGr')).toFixed(2))

  }
  thisObj.ontxtJumboGrBlur = ontxtJumboGrBlur;


  const ontxtJumboPctBlur = () => {
    setValue(thisObj, 'txtJumboPct', Math.round(getValue(thisObj, 'txtJumboPct')).toFixed(2) === 'NaN' ? 0.0 : Math.round(getValue(thisObj, 'txtJumboPct')).toFixed(2))

  }
  thisObj.ontxtJumboPctBlur = ontxtJumboPctBlur;


  const ontxtLSKGrBlur = () => {
    setValue(thisObj, 'txtLSKGr', Number(getValue(thisObj, 'txtLSKGr')).toFixed(2) === 'NaN' ? 0.0 :  Number(getValue(thisObj, 'txtLSKGr')).toFixed(2))

  }
  thisObj.ontxtLSKGrBlur = ontxtLSKGrBlur;


  const ontxtMoistureBlur = () => {
    setValue(thisObj, 'txtMoisture', Math.round(getValue(thisObj, 'txtMoisture')).toFixed(2) === 'NaN' ? 0.0 : Math.round(getValue(thisObj, 'txtMoisture')).toFixed(2))

  }
  thisObj.ontxtMoistureBlur = ontxtMoistureBlur;

  const ontxtOtherKernelsGrBlur = () => {
    setValue(thisObj, 'txtOtherKernelsGr', Number(getValue(thisObj, 'txtOtherKernelsGr')).toFixed(2) === 'NaN' ? 0.0 : Number(getValue(thisObj, 'txtOtherKernelsGr')).toFixed(2))

  }
  thisObj.ontxtOtherKernelsGrBlur = ontxtOtherKernelsGrBlur;


  const ontxtOtherKernelsPctBlur = () => {
    setValue(thisObj, 'txtOtherKernelsPct', Math.round(getValue(thisObj, 'txtOtherKernelsPct')).toFixed(2) === 'NaN' ? 0.0 : Math.round(getValue(thisObj, 'txtOtherKernelsPct')).toFixed(2))

  }
  thisObj.ontxtOtherKernelsPctBlur = ontxtOtherKernelsPctBlur;


  const ontxtSoundSplitsGrBlur = () => {
    setValue(thisObj, 'txtSoundSplitsGr', Number(getValue(thisObj, 'txtSoundSplitsGr')).toFixed(2) === 'NaN' ? 0.0 : Number(getValue(thisObj, 'txtSoundSplitsGr')).toFixed(2))

  }
  thisObj.ontxtSoundSplitsGrBlur = ontxtSoundSplitsGrBlur;



  const ontxtSoundSplitsPctBlur = () => {
    setValue(thisObj, 'txtSoundSplitsPct', Math.round(getValue(thisObj, 'txtSoundSplitsPct')).toFixed(2) === 'NaN' ? 0.0 : Math.round(getValue(thisObj, 'txtSoundSplitsPct')).toFixed(2))

  }
  thisObj.ontxtSoundSplitsPctBlur = ontxtSoundSplitsPctBlur;


  const ontxtTotalDamageGrBlur = () => {
    setValue(thisObj, 'txtTotalDamageGr', Math.round(getValue(thisObj, 'txtTotalDamageGr')).toFixed(2) === 'NaN' ? 0.0 : Number(getValue(thisObj, 'txtTotalDamageGr')).toFixed(2))

  }
  thisObj.ontxtTotalDamageGrBlur = ontxtTotalDamageGrBlur;

  const ontxtTotalDamagePctBlur = () => {
    setValue(thisObj, 'txtTotalDamagePct', Math.round(getValue(thisObj, 'txtTotalDamagePct')).toFixed(2) === 'NaN' ? 0.0 : Math.round(getValue(thisObj, 'txtTotalDamagePct')).toFixed(2))

  }
  thisObj.ontxtTotalDamagePctBlur = ontxtTotalDamagePctBlur;


  const ontxtWtOfVehicleBlur = () => {
    setValue(thisObj, 'txtWtIncludingVehicle', getValue(thisObj, 'txtWtIncludingVehicle'))
    setValue(thisObj, 'txtWtOfVehicle', getValue(thisObj, 'txtWtOfVehicle'))
    setValue(thisObj, 'txtGrossWeight', getValue(thisObj, 'txtWtOfPnuts'))
  }
  thisObj.ontxtWtOfVehicleBlur = ontxtWtOfVehicleBlur;










  //===========================================================
  //   Grade Calculation
  //===========================================================
  //TextBox change and Blur Events.
  //============================================================


  const IsNumeric = new RegExp('^[+-]?([0-9]*[.])?[0-9]+') //new RegExp('^[0-9]+$');
  const ontxtFMSampleWtChange = () => {
    let fmSambleWt = getValue(thisObj, "txtFMSampleWt");
    let foreignMaterialGr = getValue(thisObj, "txtForeignMaterialGr");
    let foreignMaterialPct = "";
    let lSKPct = "";
    let lSKGr = getValue(thisObj, "txtLSKGr");

    if (IsNumeric.test(fmSambleWt) && IsNumeric.test(foreignMaterialGr)) {
      if (fmSambleWt != 0) {
        foreignMaterialPct = (foreignMaterialGr / fmSambleWt) * 100;
        setValue(thisObj, "txtForeignMaterialPct", Math.round(foreignMaterialPct).toFixed(2))
      }
      else {
        setValue(thisObj, "txtForeignMaterialPct", 0)
      }
    }
    else {
      clearValue(thisObj, "txtForeignMaterialPct")
    }


    if (IsNumeric.test(fmSambleWt) && IsNumeric.test(lSKGr)) {
      if (fmSambleWt != 0) {
        lSKPct = (lSKGr / fmSambleWt) * 100;
        setValue(thisObj, "txtlSKPct", Math.round(lSKPct).toFixed(2))
      }
      else {
        setValue(thisObj, "txtLSKPct", 0)
      }
    }

    else {
      clearValue(thisObj, "txtLSKPct")
    }

  }
  thisObj.ontxtFMSampleWtChange = ontxtFMSampleWtChange;


  const ontxtFMSampleWtBlur = () => {
    let tempValue = getValue(thisObj, 'txtFMSampleWt')
    tempValue = Number(tempValue)
    setValue(thisObj, 'txtFMSampleWt', tempValue.toFixed(2) === 'NaN' ? 0.0 : tempValue.toFixed(1))
  }
  thisObj.ontxtFMSampleWtBlur = ontxtFMSampleWtBlur;




  const ontxtCleanSampleWtChange = () => {
    let cleanSampleWt = getValue(thisObj, "txtCleanSampleWt");
    let sMKRSGr = getValue(thisObj, "txtSMKRSGr");
    let sMKRSPct = "";

    if (IsNumeric.test(cleanSampleWt) && IsNumeric.test(sMKRSGr)) {
      if (cleanSampleWt != 0) {
        sMKRSPct = (sMKRSGr / cleanSampleWt) * 100;
        setValue(thisObj, "txtSMKRSPct", Math.round(sMKRSPct).toFixed(2))
      }
      else {
        setValue(thisObj, "txtSMKRSPct", 0)
      }
    }

    else {
      clearValue(thisObj, "txtSMKRSPct")
    }


    if(cleanSampleWt == '' || Number(cleanSampleWt) == 0){
      setValue(thisObj, 'txtTotalSMK', '0.00')
      setValue(thisObj, 'txtTotalKrnls', '0.00')
      setValue(thisObj, 'txtTotalKernelsHulls', '0.00')
      setValue(thisObj, 'txtSMKRSPct', '')
      setValue(thisObj, 'txtSoundSplitsPct', '')
      setValue(thisObj, 'txtOtherKernelsPct', '')
      setValue(thisObj, 'txtTotalDamagePct', '')
      setValue(thisObj, 'txtFreezeDamagePct', '')
      setValue(thisObj, 'txtConcealedRMDPct', '')
      setValue(thisObj, 'txtHullsPct', '')
    }

    let soundSplitsGr = getValue(thisObj, "txtSMKRSGr");
    let soundSplitsPct = "";
    if (IsNumeric.test(Number(cleanSampleWt)) && IsNumeric.test(Number(soundSplitsGr))) {
      if (cleanSampleWt != 0) {
        soundSplitsPct = (soundSplitsGr / cleanSampleWt) * 100;
        setValue(thisObj, "txtSMKRSPct", Math.round(soundSplitsPct).toFixed(2))
      }
      else {
        setValue(thisObj, "txtSMKRSPct", "0")
      }
    }

    else {
      clearValue(thisObj, "txtSMKRSPct")
    }



    let otherKernelsGr = getValue(thisObj, "txtOtherKernelsGr");
    let otherKernalsPct = "";

    if (IsNumeric.test(cleanSampleWt) && IsNumeric.test(otherKernelsGr)) {
      if (cleanSampleWt != 0) {
        otherKernalsPct = (otherKernelsGr / cleanSampleWt) * 100;
        setValue(thisObj, "txtOtherKernelsPct", Math.round(otherKernalsPct).toFixed(2))
      }
      else {
        setValue(thisObj, "txtOtherKernelsPct", 0)
      }
    }

    else {
      clearValue(thisObj, "txtOtherKernelsPct")
    }


    let totalDamageGr = getValue(thisObj, "txtTotalDamageGr");
    let totalDamagePct = "";

    if (IsNumeric.test(cleanSampleWt) && IsNumeric.test(totalDamageGr)) {
      if (cleanSampleWt != 0) {
        totalDamagePct = (totalDamageGr / cleanSampleWt) * 100;
        setValue(thisObj, "txtTotalDamagePct", Math.round(totalDamagePct).toFixed(2))
      }
      else {
        setValue(thisObj, "txtTotalDamagePct", 0)
      }
    }

    else {
      clearValue(thisObj, "txtTotalDamagePct", "")
    }


    let hullsGr = getValue(thisObj, "txtHullsGr");
    let hullsPct = "";

    if (IsNumeric.test(cleanSampleWt) && IsNumeric.test(hullsGr)) {
      if (cleanSampleWt != 0) {
        hullsPct = (hullsGr / cleanSampleWt) * 100;
        setValue(thisObj, "txtHullsPct", Math.round(hullsPct).toFixed(2))
        ontxtHullsPctChange()
      }
      else {
        setValue(thisObj, "txtHullsPct", 0)
        ontxtHullsPctChange()
      }
    }

    else {
      clearValue(thisObj, "txtHullsPct")
      ontxtHullsPctChange()
    }

    let concealedRMDGr = getValue(thisObj, "txtConcealedRMDGr");
    let concealedRMDPct = "";

    if (IsNumeric.test(cleanSampleWt) && IsNumeric.test(concealedRMDGr)) {
      if (cleanSampleWt != 0) {
        concealedRMDPct = (concealedRMDGr / cleanSampleWt) * 100;
        setValue(thisObj, "txtConcealedRMDPct", Math.round(concealedRMDPct).toFixed(2))
      }
      else {
        setValue(thisObj, "txtConcealedRMDPct", 0)
      }
    }

    else {
      clearValue(thisObj, "txtConcealedRMDPct")
    }

    let eLKGr = getValue(thisObj, "txtELKGr");
    let eLKPct = "";

    if (IsNumeric.test(cleanSampleWt) && IsNumeric.test(eLKGr)) {
      if (cleanSampleWt != 0) {
        eLKPct = (eLKGr / cleanSampleWt) * 100;
        setValue(thisObj, "txtELKPct", Math.round(eLKPct).toFixed(2))
      }
      else {
        setValue(thisObj, "txtELKPct", 0)
      }
    }

    else {
      clearValue(thisObj, "txtELKPct")
    }

    let freezeDamageGr = getValue(thisObj, "txtFreezeDamageGr");
    let freezeDamagePct = "";

    if (IsNumeric.test(cleanSampleWt) && IsNumeric.test(freezeDamageGr)) {
      if (cleanSampleWt != 0) {
        freezeDamagePct = (freezeDamageGr / cleanSampleWt) * 100;
        setValue(thisObj, "txtFreezeDamagePct", Math.round(freezeDamagePct).toFixed(2))
      }
      else {
        setValue(thisObj, "txtFreezeDamagePct", 0)
      }
    }
    else {
      clearValue(thisObj, "txtFreezeDamagePct", "")
    }



    let hullBrightnessGr = getValue(thisObj, "txtHullBrightness");
    let hullBrightnessPct = "";

    if (IsNumeric.test(cleanSampleWt) && IsNumeric.test(hullBrightnessGr)) {
      if (cleanSampleWt != 0) {
        hullBrightnessPct = (hullBrightnessGr / cleanSampleWt) * 100;
        setValue(thisObj, "txtHullBrightnessPct", Math.round(hullBrightnessPct).toFixed(2))
      }
      else {
        setValue(thisObj, "txtHullBrightnessPct", 0)
      }
    }
    else {
      clearValue(thisObj, "txtHullBrightnessPct")
    }




    let jumboGr = getValue(thisObj, "txtJumboGr");
    let jumboPct = "";

    if (IsNumeric.test(cleanSampleWt) && IsNumeric.test(jumboGr)) {
      if (cleanSampleWt != 0) {
        jumboPct = (jumboGr / cleanSampleWt) * 100;
        setValue(thisObj, "txtJumboPct", Math.round(jumboPct).toFixed(2))
      }
      else {
        setValue(thisObj, "txtJumboPct", 0)
      }
    }
    else {
      clearValue(thisObj, "txtJumboPct")
    }

    let fancyGr = getValue(thisObj, "txtFancyGr");
    let fancyPct = "";

    if (IsNumeric.test(cleanSampleWt) && IsNumeric.test(fancyGr)) {
      if (cleanSampleWt != 0) {
        fancyPct = (fancyGr / cleanSampleWt) * 100;
        setValue(thisObj, "txtFancyPct", Math.round(fancyPct).toFixed(2))
      }
      else {
        setValue(thisObj, "txtFancyPct", 0)
      }
    }
    else {
      clearValue(thisObj, "txtFancyPct")
    }
  }
  thisObj.ontxtCleanSampleWtChange = ontxtCleanSampleWtChange;

  const ontxtCleanSampleWtBlur = () => {
    setValue(thisObj, 'txtCleanSampleWt', Math.round(getValue(thisObj, 'txtCleanSampleWt')).toFixed(2) === 'NaN' ? 0.0 : Number(getValue(thisObj, 'txtCleanSampleWt')).toFixed(1))
  }
  thisObj.ontxtCleanSampleWtBlur = ontxtCleanSampleWtBlur;




  const ontxtValenciaCleanSampleWtChange = () => {

    let vLClnSampleWt = getValue(thisObj, "txtValenciaCleanSampleWt") === undefined ? 0 : getValue(thisObj, "txtValenciaCleanSampleWt") === "" ? 0 : getValue(thisObj, "txtValenciaCleanSampleWt") === null ? 0 : getValue(thisObj, "txtValenciaCleanSampleWt");
    let crkOrBrkShellsGr = getValue(thisObj, "txtCrkOrBrkShellsGr") === undefined ? 0 : getValue(thisObj, "txtCrkOrBrkShellsGr") === "" ? 0 : getValue(thisObj, "txtCrkOrBrkShellsGr") === null ? 0 : getValue(thisObj, "txtCrkOrBrkShellsGr");
    let crkOrBrkShellsPct = "";

    if (IsNumeric.test(Number(vLClnSampleWt)) && IsNumeric.test(Number(crkOrBrkShellsGr))) {
      if (vLClnSampleWt != 0) {
        crkOrBrkShellsPct = Math.round((crkOrBrkShellsGr / vLClnSampleWt) * 100);
        setValue(thisObj, "txtCrkOrBrkShellsPct", Math.round(crkOrBrkShellsPct).toFixed(2))
      }
      else {
        setValue(thisObj, "txtCrkOrBrkShellsPct", 0)
      }
    }

    else {
      clearValue(thisObj, "txtCrkOrBrkShellsPct")
    }

    let discoloredShellsGR = getValue(thisObj, "txtDiscoloredShellsGr") === undefined ? 0 : getValue(thisObj, "txtDiscoloredShellsGr") === null ? 0 : getValue(thisObj, "txtDiscoloredShellsGr") === "" ? 0 : getValue(thisObj, "txtDiscoloredShellsGr");
    let discoloredShellsPct = "";

    if (IsNumeric.test(vLClnSampleWt) && IsNumeric.test(discoloredShellsGR)) {
      if (vLClnSampleWt != 0) {
        discoloredShellsPct = (Number(discoloredShellsGR) / Number(vLClnSampleWt)) * 100;
        setValue(thisObj, "txtDiscoloredShellsPct", Math.round(discoloredShellsPct).toFixed(2))
      }
      else {
        setValue(thisObj, "txtDiscoloredShellsPct", 0)
      }
    }

    else {
      clearValue(thisObj, "txtDiscoloredShellsPct")
    }


  }
  thisObj.ontxtValenciaCleanSampleWtChange = ontxtValenciaCleanSampleWtChange;

  const ontxtValenciaCleanSampleWtBlur = () => {
    setValue(thisObj, 'txtValenciaCleanSampleWt', Number(getValue(thisObj, 'txtValenciaCleanSampleWt')).toFixed(2) === 'NaN' ? 0.0 : Number(getValue(thisObj, 'txtValenciaCleanSampleWt')).toFixed(2))

  }
  thisObj.ontxtValenciaCleanSampleWtBlur = ontxtValenciaCleanSampleWtBlur;



  const ontxtSMKRSGrChange = () => {
    if (isEnabled(thisObj, "txtSMKRSGr")) {
      let clnSampleWt = getValue(thisObj, "txtCleanSampleWt") === undefined ? 0 : getValue(thisObj, "txtCleanSampleWt") === null ? 0 : getValue(thisObj, "txtCleanSampleWt") === "" ? 0 : getValue(thisObj, "txtCleanSampleWt");
      let sMKRSGr = getValue(thisObj, "txtSMKRSGr") === undefined ? 0 : getValue(thisObj, "txtSMKRSGr") === null ? 0 : getValue(thisObj, "txtSMKRSGr") === "" ? 0 : getValue(thisObj, "txtSMKRSGr");
      let sMKRSPct = "";

      if (IsNumeric.test(Number(clnSampleWt)) && IsNumeric.test(Number(sMKRSGr))) {
        if (Number(clnSampleWt) != 0) {
          sMKRSPct = (Number(sMKRSGr) / Number(clnSampleWt)) * 100;
          setValue(thisObj, "txtSMKRSPct", Math.round(sMKRSPct).toFixed(2))
          ontxtSMKRSPctChange();
        }
        else {
          setValue(thisObj, "txtSMKRSPct", 0)
          ontxtSMKRSPctChange();
        }
      }

      else {
        clearValue(thisObj, "txtSMKRSPct")
        ontxtSMKRSPctChange();
      }

    }


  }
  thisObj.ontxtSMKRSGrChange = ontxtSMKRSGrChange;


  const ontxtSMKRSGrBlur = () => {
    let data = getValue(thisObj, 'txtSMKRSGr')
    if(data == '' || data == null || data == undefined)
      return
    setValue(thisObj, 'txtSMKRSGr', Number(data).toFixed(2))

  }
  thisObj.ontxtSMKRSGrBlur = ontxtSMKRSGrBlur;


  const ontxtSMKRSPctChange = () => {
    let krspct = getValue(thisObj, 'txtSMKRSPct') === undefined ? 0 : getValue(thisObj, 'txtSMKRSPct') === null ? 0 : getValue(thisObj, 'txtSMKRSPct') === "" ? 0 : getValue(thisObj, 'txtSMKRSPct')
    let soundSplitPct = getValue(thisObj, 'txtSoundSplitsPct') === undefined ? 0 : getValue(thisObj, 'txtSoundSplitsPct') === null ? 0 : getValue(thisObj, 'txtSoundSplitsPct') === "" ? 0 : getValue(thisObj, 'txtSoundSplitsPct')
    let totalSMk = Number(krspct) + Number(soundSplitPct);
    setValue(thisObj, 'txtTotalSMK', Math.round(totalSMk).toFixed(2))
    ontxtTotalSMKChange();
  }
  thisObj.ontxtSMKRSPctChange = ontxtSMKRSPctChange;


  const ontxtSMKRSPctBlur = () => {
    setValue(thisObj, 'txtSMKRSPct', Math.round(getValue(thisObj, 'txtSMKRSPct')).toFixed(2) === 'NaN' ? 0.0 : Math.round(getValue(thisObj, 'txtSMKRSPct')).toFixed(2))


  }
  thisObj.ontxtSMKRSPctBlur = ontxtSMKRSPctBlur;








  const ontxtConcealedRMDGrChange = () => {
    if (isEnabled(thisObj, "txtConcealedRMDGr")) {
      let cleanSampleWt = getValue(thisObj, "txtCleanSampleWt") === undefined ? 0 : getValue(thisObj, "txtCleanSampleWt") === null ? 0 : getValue(thisObj, "txtCleanSampleWt") === "" ? 0 : getValue(thisObj, "txtCleanSampleWt");
      let concealedRMDGr = getValue(thisObj, "txtConcealedRMDGr") === undefined ? 0 : getValue(thisObj, "txtConcealedRMDGr") === null ? 0 : getValue(thisObj, "txtConcealedRMDGr") === "" ? 0 : getValue(thisObj, "txtConcealedRMDGr");
      let concealedRMDPct = "";

      if (IsNumeric.test(Number(cleanSampleWt)) && IsNumeric.test(Number(concealedRMDGr))) {
        if (Number(cleanSampleWt) != 0) {
          concealedRMDPct = (Number(concealedRMDGr) / Number(cleanSampleWt)) * 100;
          setValue(thisObj, "txtConcealedRMDPct", Math.round(concealedRMDPct).toFixed(2))
        }
        else {
          setValue(thisObj, "txtConcealedRMDPct", 0)
        }
      }

      else {
        clearValue(thisObj, "txtConcealedRMDPct")
      }

    }

  }
  thisObj.ontxtConcealedRMDGrChange = ontxtConcealedRMDGrChange;

  const ontxtCrkOrBrkShellsGrChange = () => {
    if (isEnabled(thisObj, "txtCrkOrBrkShellsGr")) {
      let vLClnSampleWt = getValue(thisObj, "txtValenciaCleanSampleWt");
      let crkOrBrkShellsGr = getValue(thisObj, "txtCrkOrBrkShellsGr");
      let crkOrBrkShellsPct = "";

      if (IsNumeric.test(vLClnSampleWt) && IsNumeric.test(crkOrBrkShellsGr)) {
        if (vLClnSampleWt != 0) {
          crkOrBrkShellsPct = (crkOrBrkShellsGr / vLClnSampleWt) * 100;
          setValue(thisObj, "txtConcealedRMDPct", Math.round(crkOrBrkShellsPct).toFixed(2))
        }
        else {
          setValue(thisObj, "txtConcealedRMDPct", 0)
        }
      }

      else {
        clearValue(thisObj, "txtConcealedRMDPct")
      }

    }

  }
  thisObj.ontxtCrkOrBrkShellsGrChange = ontxtCrkOrBrkShellsGrChange;

  const ontxtDiscoloredShellsGrChange = () => {
    if (isEnabled(thisObj, "txtDiscoloredShellsGr")) {
      let vLClnSampleWt = getValue(thisObj, "txtValenciaCleanSampleWt") === undefined ? 0 : getValue(thisObj, "txtValenciaCleanSampleWt") === null ? 0 : getValue(thisObj, "txtValenciaCleanSampleWt") === "" ? 0 : getValue(thisObj, "txtValenciaCleanSampleWt");
      let discoloredShellsGR = getValue(thisObj, "txtDiscoloredShellsGr") === undefined ? 0 : getValue(thisObj, "txtDiscoloredShellsGr") === null ? 0 : getValue(thisObj, "txtDiscoloredShellsGr") === "" ? 0 : getValue(thisObj, "txtDiscoloredShellsGr");
      let discoloredShellsPct = "";

      if (IsNumeric.test(Number(vLClnSampleWt)) && IsNumeric.test(Number(discoloredShellsGR))) {
        if (Number(vLClnSampleWt) != 0) {
          discoloredShellsPct = (Number(discoloredShellsGR) / Number(vLClnSampleWt)) * 100;
          setValue(thisObj, "txtDiscoloredShellsPct", Math.round(discoloredShellsPct).toFixed(2))
        }
        else {
          setValue(thisObj, "txtDiscoloredShellsPct", 0)
        }
      }

      else {
        clearValue(thisObj, "txtDiscoloredShellsPct")
      }

    }


  }
  thisObj.ontxtDiscoloredShellsGrChange = ontxtDiscoloredShellsGrChange;


  const ontxtELKGrChange = () => {

    if (isEnabled(thisObj, "txtELKGr")) {
      let clnSampleWt = getValue(thisObj, "txtCleanSampleWt") === undefined ? 0 : getValue(thisObj, "txtCleanSampleWt") === null ? 0 : getValue(thisObj, "txtCleanSampleWt") === "" ? 0 : getValue(thisObj, "txtCleanSampleWt");
      let eLKGr = getValue(thisObj, "txtELKGr") === undefined ? 0 : getValue(thisObj, "txtELKGr") === null ? 0 : getValue(thisObj, "txtELKGr") === "" ? 0 : getValue(thisObj, "txtELKGr");
      let eLKPct = "";

      if (IsNumeric.test(Number(clnSampleWt)) && IsNumeric.test(Number(eLKGr))) {
        if (Number(clnSampleWt) != 0) {
          eLKPct = (Number(eLKGr) / Number(clnSampleWt)) * 100;
          setValue(thisObj, "txtELKPct", Math.round(eLKPct).toFixed(2))
        }
        else {
          setValue(thisObj, "txtELKPct", 0)
        }
      }

      else {
        clearValue(thisObj, "txtELKPct")
      }

    }

  }
  thisObj.ontxtELKGrChange = ontxtELKGrChange;


  const ontxtFancyGrChange = () => {
    if (isEnabled(thisObj, "txtFancyGr")) {
      let clnSampleWt = getValue(thisObj, "txtCleanSampleWt") === undefined ? 0 : getValue(thisObj, "txtCleanSampleWt") === null ? 0 : getValue(thisObj, "txtCleanSampleWt") === "" ? 0 : getValue(thisObj, "txtCleanSampleWt");
      let fancyGr = getValue(thisObj, "txtFancyGr") === undefined ? 0 : getValue(thisObj, "txtFancyGr") === null ? 0 : getValue(thisObj, "txtFancyGr") === "" ? 0 : getValue(thisObj, "txtFancyGr");
      let fancyPct = "";

      if (IsNumeric.test(Number(clnSampleWt)) && IsNumeric.test(Number(fancyGr))) {
        if (clnSampleWt != 0) {
          fancyPct = (Number(fancyGr) / Number(clnSampleWt)) * 100;
          setValue(thisObj, "txtFancyPct", Math.round(fancyPct).toFixed(2))
        }
        else {
          setValue(thisObj, "txtFancyPct", 0)
        }
      }

      else {
        clearValue(thisObj, "txtFancyPct")
      }

    }

  }
  thisObj.ontxtFancyGrChange = ontxtFancyGrChange;




  const ontxtForeignMaterialGrChange = async () => {
    if (isEnabled(thisObj, "txtForeignMaterialGr")) {
      let fMSampleWt = getValue(thisObj, "txtFMSampleWt") === undefined ? 0 : getValue(thisObj, "txtFMSampleWt") === null ? 0 : getValue(thisObj, "txtFMSampleWt") === "" ? 0 : getValue(thisObj, "txtFMSampleWt");
      let foreignMaterialGr = getValue(thisObj, "txtForeignMaterialGr") === undefined ? 0 : getValue(thisObj, "txtForeignMaterialGr") === null ? 0 : getValue(thisObj, "txtForeignMaterialGr") === "" ? 0 : getValue(thisObj, "txtForeignMaterialGr");
      let foreignMaterialPct = "";

      if (IsNumeric.test(Number(fMSampleWt)) && IsNumeric.test(Number(foreignMaterialGr))) {
        if (Number(fMSampleWt) != 0) {
          foreignMaterialPct = (Number(foreignMaterialGr) / Number(fMSampleWt)) * 100;
          setValue(thisObj, "txtForeignMaterialPct", Math.round(foreignMaterialPct).toFixed(2))
        }
        else {
          setValue(thisObj, "txtForeignMaterialPct", 0)
        }
      }

      else {
        clearValue(thisObj, "txtForeignMaterialPct")
      }

    }

    let opt_hmYESNO = getValue(thisObj, "radioHighMoisture");
    let buyingPointList = thisObj.state['ddLocation'].valueList;
    let selectedBuyingPointValue = getValue(thisObj, "ddLocation", '');
    let pnutTypeList = thisObj.state['ddPeanutType'].valueList;
    let selectedPnutTypeValue = getValue(thisObj, "ddPeanutType", '')
    let lstAreaId = null;
    let lstPeanutTypeID = null;
    if (selectedBuyingPointValue !== "" && selectedBuyingPointValue !== undefined) {
      lstAreaId = buyingPointList.find(elem => elem.key === selectedBuyingPointValue).area_id;
    }
    else {
      showMessage("Please select Location")
      return false;
    }
    if (selectedPnutTypeValue !== "" && selectedPnutTypeValue !== undefined) {
      lstPeanutTypeID = pnutTypeList.find(elem => elem.key === selectedPnutTypeValue).key;
    }
    else {
      showMessage("Please select Peanut Type")
      return false;
    }
    if (opt_hmYESNO == "2") {
      let weightincludingvehicle = getValue(thisObj, "txtEWtInclVeh") === undefined ? 0 : getValue(thisObj, "txtEWtInclVeh")
      let weightofvehicle = getValue(thisObj, "txtEWtOfVeh") === undefined ? 0 : getValue(thisObj, "txtEWtOfVeh")
      let moisturepct = getValue(thisObj, "txtMoisture") === undefined ? 0 : getValue(thisObj, "txtMoisture")
      let cr_br_pct = getValue(thisObj, "txtCrkOrBrkShellsPct") === undefined ? 0 : getValue(thisObj, "txtCrkOrBrkShellsPct")
      let dcol_shel_pct = getValue(thisObj, "txtDiscoloredShellsPct") === undefined ? 0 : getValue(thisObj, "txtDiscoloredShellsPct")
      let fan_pct = getValue(thisObj, "txtFancyPct") === undefined ? 0 : getValue(thisObj, "txtFancyPct")
      let foreignmaterialpct = getValue(thisObj, "txtForeignMaterialPct") === undefined ? 0 : getValue(thisObj, "txtForeignMaterialPct")
      let lskpct = getValue(thisObj, "txtLSKPct") === undefined ? 0 : getValue(thisObj, "txtLSKPct")
      let smk = getValue(thisObj, "txtSMKRSPct") === undefined ? 0 : getValue(thisObj, "txtSMKRSPct")
      let sspct = getValue(thisObj, "txtSoundSplitsPct") === undefined ? 0 : getValue(thisObj, "txtSoundSplitsPct")
      let okpct = getValue(thisObj, "txtOtherKernelsPct") === undefined ? 0 : getValue(thisObj, "txtOtherKernelsPct")
      let elkpct = getValue(thisObj, "txtELKPct") === undefined ? 0 : getValue(thisObj, "txtELKPct")
      let dam_pct = getValue(thisObj, "txtTotalDamagePct") === undefined ? 0 : getValue(thisObj, "txtTotalDamagePct")
      CalculateValues(lstAreaId, lstPeanutTypeID, weightincludingvehicle, weightofvehicle, moisturepct, cr_br_pct,
        dcol_shel_pct, fan_pct, foreignmaterialpct, lskpct, smk, sspct, okpct, elkpct, dam_pct);
    }
    else {
      let weightincludingvehicle = getValue(thisObj, "txtEWtInclVeh") === undefined ? 0 : getValue(thisObj, "txtEWtInclVeh")
      let weightofvehicle = getValue(thisObj, "txtEWtOfVeh") === undefined ? 0 : getValue(thisObj, "txtEWtOfVeh")
      let moisturepct = getValue(thisObj, "txtMoisture") === undefined ? 0 : getValue(thisObj, "txtMoisture")
      let cr_br_pct = getValue(thisObj, "txtCrkOrBrkShellsPct") === undefined ? 0 : getValue(thisObj, "txtCrkOrBrkShellsPct")
      let dcol_shel_pct = getValue(thisObj, "txtDiscoloredShellsPct") === undefined ? 0 : getValue(thisObj, "txtDiscoloredShellsPct")
      let fancy = getValue(thisObj, "txtFANCY") === undefined ? 0 : getValue(thisObj, "txtFANCY")
      let fm = getValue(thisObj, "txtFM") === undefined ? 0 : getValue(thisObj, "txtFM")
      let lsk = getValue(thisObj, "txtLSK") === undefined ? 0 : getValue(thisObj, "txtLSK")
      let smk = getValue(thisObj, "txtSMK") === undefined ? 0 : getValue(thisObj, "txtSMK")
      let ss = getValue(thisObj, "txtSS") === undefined ? 0 : getValue(thisObj, "txtSS")
      let ok = getValue(thisObj, "txtOK") === undefined ? 0 : getValue(thisObj, "txtOK")
      let elk = getValue(thisObj, "txtELK") === undefined ? 0 : getValue(thisObj, "txtELK")
      let dam = getValue(thisObj, "txtDAM") === undefined ? 0 : getValue(thisObj, "txtDAM")

      CalculateValues(lstAreaId, lstPeanutTypeID, weightincludingvehicle, weightofvehicle, moisturepct, cr_br_pct,
        dcol_shel_pct, fancy, fm, lsk, smk, ss, ok, elk, dam);
    }

    if (document.getElementById('txtN').value == "") {
      document.getElementById('btnPrint').innerHTML = "Print";
      show(thisObj, 'btnDelete')
    }
    else {
      document.getElementById('btnPrint').innerHTML = "Reprint";
      show(thisObj, 'btnDelete')
    }

  }
  thisObj.ontxtForeignMaterialGrChange = ontxtForeignMaterialGrChange;


  const CalculateValues = async (lstAreaId, lstPeanutTypeID, weightincludingvehicle, weightofvehicle, moisturepct,
    cr_br_pct, dcol_shel_pct, fan_pct, foreignmaterialpct, lskpct, smk, sspct, okpct,
    elkpct, dam_pct) => {
    let retrieveValueCalculationList = await SettlementService.RetrieveValueCalculationList(
      lstAreaId, lstPeanutTypeID, cr_br_pct, dcol_shel_pct, fan_pct, dam_pct, sspct, 0, foreignmaterialpct,
      smk, sspct, okpct, elkpct, lskpct, moisturepct, weightincludingvehicle,
      weightofvehicle, foreignmaterialpct);
    if (retrieveValueCalculationList.length > 0) {
      setValue(thisObj, 'txtWtIncludingVehicle', retrieveValueCalculationList[0].weight_including_vehicle.toString())
      setValue(thisObj, 'txtWtOfVehicle', retrieveValueCalculationList[0].weight_of_vehicle.toString())
      setValue(thisObj, 'txtGrossWeight', retrieveValueCalculationList[0].gross_weight.toString())
      setValue(thisObj, 'txtForeignMaterial', retrieveValueCalculationList[0].foreign_material_weight.toString())
      setValue(thisObj, 'txtWtlessFM', retrieveValueCalculationList[0].weight_less_fm.toString())
      setValue(thisObj, 'txtExcessMoisture', retrieveValueCalculationList[0].excess_moisture_weight.toString())
      setValue(thisObj, 'txtNetWeight', retrieveValueCalculationList[0].net_weight.toString())
      setValue(thisObj, 'txtLSKvc', retrieveValueCalculationList[0].lsk_weight.toString())
      setValue(thisObj, 'txtNetWtExclLSK', retrieveValueCalculationList[0].net_weight_excluding_lsk.toString())
      setValue(thisObj, 'txtKernelValueTon', retrieveValueCalculationList[0].kernel_value_dollars_per_ton.toFixed(2))
      setValue(thisObj, 'txtKernelValueLb', retrieveValueCalculationList[0].kernel_value_cents_per_lbs.toFixed(5))
      setValue(thisObj, 'txtELKPremiumTon', retrieveValueCalculationList[0].elk_premium_dollars_per_ton.toFixed(2))
      setValue(thisObj, 'txtELKPremiumLb', retrieveValueCalculationList[0].elk_premium_cents.toFixed(5))
      setValue(thisObj, 'txtTotalTon', retrieveValueCalculationList[0].total_dollars_per_ton.toFixed(2))
      setValue(thisObj, 'txtTotalLb', retrieveValueCalculationList[0].total_cents_per_lbs.toFixed(5))
      setValue(thisObj, 'txtDamageTon', retrieveValueCalculationList[0].damaged_discount_dollars_per_ton.toFixed(2))
      setValue(thisObj, 'txtDamageLbs', retrieveValueCalculationList[0].damaged_discount_cents_per_lbs.toFixed(5))
      setValue(thisObj, 'txtAccessFMTon', retrieveValueCalculationList[0].excess_fm_discount_dollars_per_ton.toFixed(2))
      setValue(thisObj, 'txtAccessFMLb', retrieveValueCalculationList[0].excess_fm_discount_cents_per_lbs.toFixed(5))
      setValue(thisObj, 'txtExcessSplitsTon', retrieveValueCalculationList[0].excess_splits_discount_dollars_per_ton.toFixed(2))
      setValue(thisObj, 'txtExcessSplitsLb', retrieveValueCalculationList[0].excess_splits_discount_cents_per_lbs.toFixed(5))
      setValue(thisObj, 'txtAFlavusTon', retrieveValueCalculationList[0].flavus_discount_dollars_per_ton.toFixed(2))
      setValue(thisObj, 'txtAFlavusLb', retrieveValueCalculationList[0].flavus_discount_cents_per_lbs.toFixed(5))
      setValue(thisObj, 'txtTotalDiscountsTon', retrieveValueCalculationList[0].total_discounts_dollars_per_ton.toFixed(2))
      setValue(thisObj, 'txtlblTotalDiscountsLb', retrieveValueCalculationList[0].total_discounts_cents_per_lbs.toFixed(5))
      setValue(thisObj, 'txtNetValPerTonExclSLK', retrieveValueCalculationList[0].net_value_per_ton_excluding_lsk.toFixed(2))
      setValue(thisObj, 'txtValPerPoundExclSLK', retrieveValueCalculationList[0].value_per_pound_excluding_lsk.toFixed(5))
      setValue(thisObj, 'txtOI', retrieveValueCalculationList[0].value_times_weight.toFixed(2))
      //setValue(thisObj, 'txtValPerPoundInclSLK', retrieveValueCalculationList[0].lsk_per_pounds)
      setLabel(thisObj, 'txtH', "H x " + retrieveValueCalculationList[0].lsk_per_pounds + ":");
      setValue(thisObj, 'txtH', retrieveValueCalculationList[0].lsk_time_lsk_per_pounds.toFixed(2))
      setValue(thisObj, 'txtTotalLoadVal', retrieveValueCalculationList[0].total_value_of_load.toFixed(2))
      setValue(thisObj, 'txtG', retrieveValueCalculationList[0].value_per_pound_including_lsk.toFixed(5))
      if (IsNumeric.test(retrieveValueCalculationList[0].total_kernel_content)) {
        setValue(thisObj, 'txtTKC', retrieveValueCalculationList[0].total_kernel_content.toFixed(2))
      }

    }
  }

  const ontxtFreezeDamageGrChange = () => {

    if (isEnabled(thisObj, "txtFreezeDamageGr")) {
      let cleanSampleWt = getValue(thisObj, "txtCleanSampleWt") === undefined ? 0 : getValue(thisObj, "txtCleanSampleWt") === null ? 0 : getValue(thisObj, "txtCleanSampleWt") === "" ? 0 : getValue(thisObj, "txtCleanSampleWt");
      let freezeDamageGr = getValue(thisObj, "txtFreezeDamageGr") === undefined ? 0 : getValue(thisObj, "txtFreezeDamageGr") === null ? 0 : getValue(thisObj, "txtFreezeDamageGr") === "" ? 0 : getValue(thisObj, "txtFreezeDamageGr");
      let freezeDamagePct = "";
      if (IsNumeric.test(Number(cleanSampleWt)) && IsNumeric.test(Number(freezeDamageGr))) {
        if (Number(cleanSampleWt) != 0) {
          freezeDamagePct = (Number(freezeDamageGr) / Number(cleanSampleWt)) * 100;
          setValue(thisObj, "txtFreezeDamagePct", Math.round(freezeDamagePct).toFixed(2))
        }
        else {
          setValue(thisObj, "txtFreezeDamagePct", 0)
        }
      }

      else {
        clearValue(thisObj, "txtFreezeDamagePct")
      }

    }


  }
  thisObj.ontxtFreezeDamageGrChange = ontxtFreezeDamageGrChange;



  const ontxtHullBrightnessChange = () => {
    if (isEnabled(thisObj, "txtHullBrightness")) {
      let cleanSampleWt = getValue(thisObj, "txtCleanSampleWt") === undefined ? 0 : getValue(thisObj, "txtCleanSampleWt") === null ? 0 : getValue(thisObj, "txtCleanSampleWt") === "" ? 0 : getValue(thisObj, "txtCleanSampleWt");
      let hullBrightnessGr = getValue(thisObj, "txtHullBrightness") === undefined ? 0 : getValue(thisObj, "txtHullBrightness") === null ? 0 : getValue(thisObj, "txtHullBrightness") === "" ? 0 : getValue(thisObj, "txtHullBrightness");
      let hullBrightnessPct = "";

      if (IsNumeric.test(Number(cleanSampleWt)) && IsNumeric.test(Number(hullBrightnessGr))) {
        if (Number(cleanSampleWt) != 0) {
          hullBrightnessPct = (Number(hullBrightnessGr) / Number(cleanSampleWt)) * 100;
          setValue(thisObj, "txtHullBrightnessPct", Math.round(hullBrightnessPct).toFixed(2))
        }
        else {
          setValue(thisObj, "txtHullBrightnessPct", 0)
        }
      }

      else {
        clearValue(thisObj, "txtHullBrightnessPct")
      }

    }



  }
  thisObj.ontxtHullBrightnessChange = ontxtHullBrightnessChange;



  const ontxtHullsGrChange = () => {
    if (isEnabled(thisObj, "txtHullsGr")) {
      let cleanSampleWt = getValue(thisObj, "txtCleanSampleWt") === undefined ? 0 : getValue(thisObj, "txtCleanSampleWt") === null ? 0 : getValue(thisObj, "txtCleanSampleWt") === "" ? 0 : getValue(thisObj, "txtCleanSampleWt");
      let hullsGr = getValue(thisObj, "txtHullsGr") === undefined ? 0 : getValue(thisObj, "txtHullsGr") === null ? 0 : getValue(thisObj, "txtHullsGr") === "" ? 0 : getValue(thisObj, "txtHullsGr");
      let hullsPct = "";

      if (IsNumeric.test(Number(cleanSampleWt)) && IsNumeric.test(Number(hullsGr))) {
        if (Number(cleanSampleWt) != 0) {
          hullsPct = (Number(hullsGr) / Number(cleanSampleWt)) * 100;
          setValue(thisObj, "txtHullsPct", Math.round(hullsPct).toFixed(2))
          ontxtHullsPctChange()
        }
        else {
          setValue(thisObj, "txtHullsPct", 0)
          ontxtHullsPctChange()
        }
      }

      else {
        clearValue(thisObj, "txtHullsPct")
        ontxtHullsPctChange()
      }

    }

  }
  thisObj.ontxtHullsGrChange = ontxtHullsGrChange;



  const ontxtHullsPctChange = () => {
    let totalKRnls = getValue(thisObj, 'txtTotalKrnls') === undefined ? 0 : getValue(thisObj, 'txtTotalKrnls') === null ? 0 : getValue(thisObj, 'txtTotalKrnls') === "" ? 0 : getValue(thisObj, 'txtTotalKrnls')
    let hullsPct = getValue(thisObj, 'txtHullsPct') === undefined ? 0 : getValue(thisObj, 'txtHullsPct') === null ? 0 : getValue(thisObj, 'txtHullsPct') === "" ? 0 : getValue(thisObj, 'txtHullsPct')
    let totalKernalsandHulls = Number(totalKRnls) + Number(hullsPct)
    setValue(thisObj, 'txtTotalKernelsHulls', Math.round(totalKernalsandHulls).toFixed(2));
  }

  thisObj.ontxtHullsPctChange = ontxtHullsPctChange;



  const ontxtJumboGrChange = () => {
    if (isEnabled(thisObj, "txtJumboGr")) {
      let cleanSampleWt = getValue(thisObj, "txtCleanSampleWt") === undefined ? 0 : getValue(thisObj, "txtCleanSampleWt") === null ? 0 : getValue(thisObj, "txtCleanSampleWt") === "" ? 0 : getValue(thisObj, "txtCleanSampleWt");
      let jumboGr = getValue(thisObj, "txtJumboGr") === undefined ? 0 : getValue(thisObj, "txtJumboGr") === null ? 0 : getValue(thisObj, "txtJumboGr") === "" ? 0 : getValue(thisObj, "txtJumboGr");
      let jumboPct = "";

      if (IsNumeric.test(Number(cleanSampleWt)) && IsNumeric.test(Number(jumboGr))) {
        if (Number(cleanSampleWt) != 0) {
          jumboPct = (Number(jumboGr) / Number(cleanSampleWt)) * 100;
          setValue(thisObj, "txtJumboPct", Math.round(jumboPct).toFixed(2))
        }
        else {
          setValue(thisObj, "txtJumboPct", 0)
        }
      }

      else {
        clearValue(thisObj, "txtJumboPct")
      }

    }


  }
  thisObj.ontxtJumboGrChange = ontxtJumboGrChange;

  const ontxtLSKGrChange = () => {

    if (isEnabled(thisObj, "txtLSKGr")) {
      let fMSampleWt = getValue(thisObj, "txtFMSampleWt") === undefined ? 0 : getValue(thisObj, "txtFMSampleWt") === null ? 0 : getValue(thisObj, "txtFMSampleWt") === "" ? 0 : getValue(thisObj, "txtFMSampleWt");
      let lSKGr = getValue(thisObj, "txtLSKGr") === undefined ? 0 : getValue(thisObj, "txtLSKGr") === null ? 0 : getValue(thisObj, "txtLSKGr") === "" ? 0 : getValue(thisObj, "txtLSKGr");
      let lSKPct = "";

      if (IsNumeric.test(Number(fMSampleWt)) && IsNumeric.test(Number(lSKGr))) {
        if (Number(fMSampleWt) != 0) {
          lSKPct = (Number(lSKGr) / Number(fMSampleWt)) * 100;
          setValue(thisObj, "txtLSKPct", Math.round(lSKPct).toFixed(2))
        }
        else {
          setValue(thisObj, "txtLSKPct", "0")
        }
      }

      else {
        setValue(thisObj, "txtLSKPct", "")
      }

    }



  }
  thisObj.ontxtLSKGrChange = ontxtLSKGrChange;


  const ontxtMoistureChange = async () => {

    let opt_hmYESNO = getValue(thisObj, "radioHighMoisture");
    let buyingPointList = thisObj.state['ddLocation'].valueList;
    let selectedBuyingPointValue = getValue(thisObj, "ddLocation", '');
    let pnutTypeList = thisObj.state['ddPeanutType'].valueList;
    let selectedPnutTypeValue = getValue(thisObj, "ddPeanutType", '')
    let lstAreaId = null;
    let lstPeanutTypeID = null;
    if (selectedBuyingPointValue !== "" && selectedBuyingPointValue !== undefined) {
      lstAreaId = buyingPointList.find(elem => elem.key === selectedBuyingPointValue).area_id;
    }
    if (selectedPnutTypeValue !== "" && selectedPnutTypeValue !== undefined) {
      lstPeanutTypeID = pnutTypeList.find(elem => elem.key === selectedPnutTypeValue).key;
    }
    if (opt_hmYESNO == "2") {
      let weightincludingvehicle = getValue(thisObj, "txtEWtInclVeh") === undefined ? 0 : getValue(thisObj, "txtEWtInclVeh")
      let weightofvehicle = getValue(thisObj, "txtEWtOfVeh") === undefined ? 0 : getValue(thisObj, "txtEWtOfVeh")
      let moisturepct = getValue(thisObj, "txtMoisture") === undefined ? 0 : getValue(thisObj, "txtMoisture")
      let cr_br_pct = getValue(thisObj, "txtCrkOrBrkShellsPct") === undefined ? 0 : getValue(thisObj, "txtCrkOrBrkShellsPct")
      let dcol_shel_pct = getValue(thisObj, "txtDiscoloredShellsPct") === undefined ? 0 : getValue(thisObj, "txtDiscoloredShellsPct")
      let fan_pct = getValue(thisObj, "txtFancyPct") === undefined ? 0 : getValue(thisObj, "txtFancyPct")
      let foreignmaterialpct = getValue(thisObj, "txtForeignMaterialPct") === undefined ? 0 : getValue(thisObj, "txtForeignMaterialPct")
      let lskpct = getValue(thisObj, "txtLSKPct") === undefined ? 0 : getValue(thisObj, "txtLSKPct")
      let smk = getValue(thisObj, "txtSMKRSPct") === undefined ? 0 : getValue(thisObj, "txtSMKRSPct")
      let sspct = getValue(thisObj, "txtSoundSplitsPct") === undefined ? 0 : getValue(thisObj, "txtSoundSplitsPct")
      let okpct = getValue(thisObj, "txtOtherKernelsPct") === undefined ? 0 : getValue(thisObj, "txtOtherKernelsPct")
      let elkpct = getValue(thisObj, "txtELKPct") === undefined ? 0 : getValue(thisObj, "txtELKPct")
      let dam_pct = getValue(thisObj, "txtTotalDamagePct") === undefined ? 0 : getValue(thisObj, "txtTotalDamagePct")
      CalculateValues(lstAreaId, lstPeanutTypeID, weightincludingvehicle, weightofvehicle, moisturepct, cr_br_pct,
        dcol_shel_pct, fan_pct, foreignmaterialpct, lskpct, smk, sspct, okpct, elkpct, dam_pct);
    }
    else {
      let weightincludingvehicle = getValue(thisObj, "txtEWtInclVeh") === undefined ? 0 : getValue(thisObj, "txtEWtInclVeh")
      let weightofvehicle = getValue(thisObj, "txtEWtOfVeh") === undefined ? 0 : getValue(thisObj, "txtEWtOfVeh")
      let moisturepct = getValue(thisObj, "txtMoisture") === undefined ? 0 : getValue(thisObj, "txtMoisture")
      let cr_br_pct = getValue(thisObj, "txtCrkOrBrkShellsPct") === undefined ? 0 : getValue(thisObj, "txtCrkOrBrkShellsPct")
      let dcol_shel_pct = getValue(thisObj, "txtDiscoloredShellsPct") === undefined ? 0 : getValue(thisObj, "txtDiscoloredShellsPct")
      let fancy = getValue(thisObj, "txtFANCY") === undefined ? 0 : getValue(thisObj, "txtFANCY")
      let fm = getValue(thisObj, "txtFM") === undefined ? 0 : getValue(thisObj, "txtFM")
      let lsk = getValue(thisObj, "txtLSK") === undefined ? 0 : getValue(thisObj, "txtLSK")
      let smk = getValue(thisObj, "txtSMK") === undefined ? 0 : getValue(thisObj, "txtSMK")
      let ss = getValue(thisObj, "txtSS") === undefined ? 0 : getValue(thisObj, "txtSS")
      let ok = getValue(thisObj, "txtOK") === undefined ? 0 : getValue(thisObj, "txtOK")
      let elk = getValue(thisObj, "txtELK") === undefined ? 0 : getValue(thisObj, "txtELK")
      let dam = getValue(thisObj, "txtDAM") === undefined ? 0 : getValue(thisObj, "txtDAM")

      CalculateValues(lstAreaId, lstPeanutTypeID, weightincludingvehicle, weightofvehicle, moisturepct, cr_br_pct,
        dcol_shel_pct, fancy, fm, lsk, smk, ss, ok, elk, dam);
    }
  }
  thisObj.ontxtMoistureChange = ontxtMoistureChange;




  const ontxtOtherKernelsGrChange = () => {

    if (isEnabled(thisObj, "txtOtherKernelsGr")) {
      let clnSampleWt = getValue(thisObj, "txtCleanSampleWt") === undefined ? 0 : getValue(thisObj, "txtCleanSampleWt") === null ? 0 : getValue(thisObj, "txtCleanSampleWt") === "" ? 0 : getValue(thisObj, "txtCleanSampleWt");
      let txtOtherKernelsGr = getValue(thisObj, "txtOtherKernelsGr") === undefined ? 0 : getValue(thisObj, "txtOtherKernelsGr") === null ? 0 : getValue(thisObj, "txtOtherKernelsGr") === "" ? 0 : getValue(thisObj, "txtOtherKernelsGr");
      let otherKernalsPct = "";

      if (IsNumeric.test(clnSampleWt) && IsNumeric.test(txtOtherKernelsGr)) {
        if (clnSampleWt != 0) {
          otherKernalsPct = (Number(txtOtherKernelsGr) / Number(clnSampleWt)) * 100;
          setValue(thisObj, "txtOtherKernelsPct", otherKernalsPct)
          ontxtOtherKernelsPctChange();
        }
        else {
          setValue(thisObj, "txtOtherKernelsPct", 0)
          ontxtOtherKernelsPctChange();
        }
      }

      else {
        clearValue(thisObj, "txtOtherKernelsPct")
        ontxtOtherKernelsPctChange();
      }

    }


  }
  thisObj.ontxtOtherKernelsGrChange = ontxtOtherKernelsGrChange;


  const ontxtOtherKernelsPctChange = () => {
    let totalSMK = getValue(thisObj, 'txtTotalSMK') === undefined ? 0 : getValue(thisObj, 'txtTotalSMK') === null ? 0 : getValue(thisObj, 'txtTotalSMK') === "" ? 0 : getValue(thisObj, 'txtTotalSMK')
    let kernelpct = getValue(thisObj, 'txtOtherKernelsPct') === undefined ? 0 : getValue(thisObj, 'txtOtherKernelsPct') === null ? 0 : getValue(thisObj, 'txtOtherKernelsPct') === "" ? 0 : getValue(thisObj, 'txtOtherKernelsPct')
    let damagePct = getValue(thisObj, 'txtTotalDamagePct') === undefined ? 0 : getValue(thisObj, 'txtTotalDamagePct') === null ? 0 : getValue(thisObj, 'txtTotalDamagePct') === "" ? 0 : getValue(thisObj, 'txtTotalDamagePct')
    let totalKernals = Number(totalSMK) + Number(kernelpct) + Number(damagePct);
    setValue(thisObj, 'txtTotalKrnls', Math.round(totalKernals).toFixed(2));
    ontxtTotalKrnlsChange();
  }
  thisObj.ontxtOtherKernelsPctChange = ontxtOtherKernelsPctChange;








  const ontxtSoundSplitsGrChange = () => {

    if (isEnabled(thisObj, "txtSoundSplitsGr")) {
      let clnSampleWt = getValue(thisObj, "txtCleanSampleWt") === undefined ? 0 : getValue(thisObj, "txtCleanSampleWt") === null ? 0 : getValue(thisObj, "txtCleanSampleWt") === "" ? 0 : Number(getValue(thisObj, "txtCleanSampleWt"));
      let soundSplitsGr = getValue(thisObj, "txtSoundSplitsGr") === undefined ? 0 : getValue(thisObj, "txtSoundSplitsGr") === null ? 0 : getValue(thisObj, "txtSoundSplitsGr") === "" ? 0 : Number(getValue(thisObj, "txtSoundSplitsGr"));
      let soundSplitsPct = "";

      if (IsNumeric.test(clnSampleWt) && IsNumeric.test(soundSplitsGr)) {
        if (clnSampleWt != 0) {
          soundSplitsPct = (Number(soundSplitsGr) / Number(clnSampleWt)) * 100;
          setValue(thisObj, "txtSoundSplitsPct", Math.round(soundSplitsPct).toFixed(2))
          ontxtSoundSplitsPctChange();
        }
        else {
          setValue(thisObj, "txtSoundSplitsPct", 0)
          ontxtSoundSplitsPctChange();
        }
      }

      else {
        clearValue(thisObj, "txtSoundSplitsPct")
        ontxtSoundSplitsPctChange();
      }

    }

  }
  thisObj.ontxtSoundSplitsGrChange = ontxtSoundSplitsGrChange;


  const ontxtSoundSplitsPctChange = () => {
    let smkrsPct = getValue(thisObj, 'txtSMKRSPct') === null ? 0 : getValue(thisObj, 'txtSMKRSPct') === "" ? 0 : getValue(thisObj, 'txtSMKRSPct') === undefined ? 0 : Number(getValue(thisObj, 'txtSMKRSPct'))
    let sspct = getValue(thisObj, 'txtSoundSplitsPct') === null ? 0 : getValue(thisObj, 'txtSoundSplitsPct') === "" ? 0 : getValue(thisObj, 'txtSoundSplitsPct') === undefined ? 0 : Number(getValue(thisObj, 'txtSoundSplitsPct'))
    let totalSMk = Number(smkrsPct) + Number(sspct);
    setValue(thisObj, 'txtTotalSMK', Math.round(totalSMk).toFixed(2));
    ontxtTotalSMKChange();
  }
  thisObj.ontxtSoundSplitsPctChange = ontxtSoundSplitsPctChange;


  const ontxtTotalDamageGrChange = () => {
    if (isEnabled(thisObj, "txtTotalDamageGr")) {
      let clnSampleWt = getValue(thisObj, "txtCleanSampleWt") === undefined ? 0 : getValue(thisObj, "txtCleanSampleWt") === null ? 0 : getValue(thisObj, "txtCleanSampleWt") === "" ? 0 : Number(getValue(thisObj, "txtCleanSampleWt"))
      let totalDamageGr = getValue(thisObj, "txtTotalDamageGr") === undefined ? 0 : getValue(thisObj, "txtTotalDamageGr") === null ? 0 : getValue(thisObj, "txtTotalDamageGr") === "" ? 0 : Number(getValue(thisObj, "txtTotalDamageGr"))
      let totalDamagePct = "";

      if (IsNumeric.test(Number(clnSampleWt)) && IsNumeric.test(Number(totalDamageGr))) {
        if (Number(clnSampleWt) != 0) {
          totalDamagePct = (Number(totalDamageGr) / Number(clnSampleWt)) * 100;
          setValue(thisObj, "txtTotalDamagePct", Math.round(totalDamagePct).toFixed(2))
          ontxtTotalDamagePctChange();
        }
        else {
          setValue(thisObj, "txtTotalDamagePct", 0)
          ontxtTotalDamagePctChange();
        }
      }

      else {
        clearValue(thisObj, "txtTotalDamagePct")
        ontxtTotalDamagePctChange();
      }

    }


  }
  thisObj.ontxtTotalDamageGrChange = ontxtTotalDamageGrChange;

  const ontxtTotalDamagePctChange = () => {
    let totalSMK = getValue(thisObj, 'txtTotalSMK') === undefined ? 0 : getValue(thisObj, 'txtTotalSMK') === null ? 0 : getValue(thisObj, 'txtTotalSMK') === "" ? 0 : getValue(thisObj, 'txtTotalSMK')
    let otherkrnlPct = getValue(thisObj, 'txtOtherKernelsPct') === undefined ? 0 : getValue(thisObj, 'txtOtherKernelsPct') === null ? 0 : getValue(thisObj, 'txtOtherKernelsPct') === "" ? 0 : getValue(thisObj, 'txtOtherKernelsPct')
    let totalDamagePct = getValue(thisObj, 'txtTotalDamagePct') === undefined ? 0 : getValue(thisObj, 'txtTotalDamagePct') === null ? 0 : getValue(thisObj, 'txtTotalDamagePct') === "" ? 0 : getValue(thisObj, 'txtTotalDamagePct')
    let totalKernals = Number(totalSMK) + Number(otherkrnlPct) + Number(totalDamagePct);
    setValue(thisObj, 'txtTotalKrnls', Math.round(totalKernals).toFixed(2));
    ontxtTotalKrnlsChange();
  }
  thisObj.ontxtTotalDamagePctChange = ontxtTotalDamagePctChange;



  function FormatNumber(value, fraction = 2) {
    let formattedNo;
    if (value != null && value != undefined) {
      if (value.toString().indexOf(",") == -1) {
        formattedNo = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: fraction,
          maximumFractionDigits: fraction,
        }).format(value);
      }
      else {
        formattedNo = value.toString();
      }
    } else {
      formattedNo = "";
    }
    return formattedNo;
  }


  const ontxtEWtInclVehChange = async () => {
    let wtInclVeh = getValue(thisObj, "txtEWtInclVeh") === undefined ? 0 : getValue(thisObj, "txtEWtInclVeh") === null ? 0 : getValue(thisObj, "txtEWtInclVeh") === "" ? 0 : getValue(thisObj, "txtEWtInclVeh");
    let wtOfVeh = getValue(thisObj, "txtEWtOfVeh") === undefined ? 0 : getValue(thisObj, "txtEWtOfVeh") === null ? 0 : getValue(thisObj, "txtEWtOfVeh") === "" ? 0 : getValue(thisObj, "txtEWtOfVeh");

    if (IsNumeric.test(Number(wtInclVeh)) && IsNumeric.test(Number(wtOfVeh))) {
      let lblWtOfPnuts = Math.round(Number(wtInclVeh) - Number(wtOfVeh));
      setValue(thisObj, 'txtEWtOfPnut', Math.round(lblWtOfPnuts));
    }

    else {
      clearValue(thisObj, "txtEWtOfPnut")
    }

    let opt_hmYESNO = getValue(thisObj, "radioHighMoisture");
    let buyingPointList = thisObj.state['ddLocation'].valueList;
    let selectedBuyingPointValue = getValue(thisObj, "ddLocation", '');
    let pnutTypeList = thisObj.state['ddPeanutType'].valueList;
    let selectedPnutTypeValue = getValue(thisObj, "ddPeanutType", '')
    let lstAreaId = null;
    let lstPeanutTypeID = null;
    if (selectedBuyingPointValue !== "" && selectedBuyingPointValue !== undefined) {
      lstAreaId = buyingPointList.find(elem => elem.key === selectedBuyingPointValue).area_id;
    }
    if (selectedPnutTypeValue !== "" && selectedPnutTypeValue !== undefined) {
      lstPeanutTypeID = pnutTypeList.find(elem => elem.key === selectedPnutTypeValue).key;
    }
    if (opt_hmYESNO == "2") {
      let weightincludingvehicle = getValue(thisObj, "txtEWtInclVeh") === undefined ? 0 : getValue(thisObj, "txtEWtInclVeh")
      let weightofvehicle = getValue(thisObj, "txtEWtOfVeh") === undefined ? 0 : getValue(thisObj, "txtEWtOfVeh")
      let moisturepct = getValue(thisObj, "txtMoisture") === undefined ? 0 : getValue(thisObj, "txtMoisture")
      let cr_br_pct = getValue(thisObj, "txtCrkOrBrkShellsPct") === undefined ? 0 : getValue(thisObj, "txtCrkOrBrkShellsPct")
      let dcol_shel_pct = getValue(thisObj, "txtDiscoloredShellsPct") === undefined ? 0 : getValue(thisObj, "txtDiscoloredShellsPct")
      let fan_pct = getValue(thisObj, "txtFancyPct") === undefined ? 0 : getValue(thisObj, "txtFancyPct")
      let foreignmaterialpct = getValue(thisObj, "txtForeignMaterialPct") === undefined ? 0 : getValue(thisObj, "txtForeignMaterialPct")
      let lskpct = getValue(thisObj, "txtLSKPct") === undefined ? 0 : getValue(thisObj, "txtLSKPct")
      let smk = getValue(thisObj, "txtSMKRSPct") === undefined ? 0 : getValue(thisObj, "txtSMKRSPct")
      let sspct = getValue(thisObj, "txtSoundSplitsPct") === undefined ? 0 : getValue(thisObj, "txtSoundSplitsPct")
      let okpct = getValue(thisObj, "txtOtherKernelsPct") === undefined ? 0 : getValue(thisObj, "txtOtherKernelsPct")
      let elkpct = getValue(thisObj, "txtELKPct") === undefined ? 0 : getValue(thisObj, "txtELKPct")
      let dam_pct = getValue(thisObj, "txtTotalDamagePct") === undefined ? 0 : getValue(thisObj, "txtTotalDamagePct")

      CalculateValues(lstAreaId, lstPeanutTypeID, weightincludingvehicle, weightofvehicle, moisturepct, cr_br_pct,
        dcol_shel_pct, fan_pct, foreignmaterialpct, lskpct, smk, sspct, okpct, elkpct, dam_pct);
    }
    else {
      let weightincludingvehicle = getValue(thisObj, "txtEWtInclVeh") === undefined ? 0 : getValue(thisObj, "txtEWtInclVeh")
      let weightofvehicle = getValue(thisObj, "txtEWtOfVeh") === undefined ? 0 : getValue(thisObj, "txtEWtOfVeh")
      let moisturepct = getValue(thisObj, "txtMoisture") === undefined ? 0 : getValue(thisObj, "txtMoisture")
      let cr_br_pct = getValue(thisObj, "txtCrkOrBrkShellsPct") === undefined ? 0 : getValue(thisObj, "txtCrkOrBrkShellsPct")
      let dcol_shel_pct = getValue(thisObj, "txtDiscoloredShellsPct") === undefined ? 0 : getValue(thisObj, "txtDiscoloredShellsPct")
      let fancy = getValue(thisObj, "txtFANCY") === undefined ? 0 : getValue(thisObj, "txtFANCY")
      let fm = getValue(thisObj, "txtFM") === undefined ? 0 : getValue(thisObj, "txtFM")
      let lsk = getValue(thisObj, "txtLSK") === undefined ? 0 : getValue(thisObj, "txtLSK")
      let smk = getValue(thisObj, "txtSMK") === undefined ? 0 : getValue(thisObj, "txtSMK")
      let ss = getValue(thisObj, "txtSS") === undefined ? 0 : getValue(thisObj, "txtSS")
      let ok = getValue(thisObj, "txtOK") === undefined ? 0 : getValue(thisObj, "txtOK")
      let elk = getValue(thisObj, "txtELK") === undefined ? 0 : getValue(thisObj, "txtELK")
      let dam = getValue(thisObj, "txtDAM") === undefined ? 0 : getValue(thisObj, "txtDAM")

      CalculateValues(lstAreaId, lstPeanutTypeID, weightincludingvehicle, weightofvehicle, moisturepct, cr_br_pct,
        dcol_shel_pct, fancy, fm, lsk, smk, ss, ok, elk, dam);
    }

  }
  thisObj.ontxtEWtInclVehChange = ontxtEWtInclVehChange;


  const ontxtEWtOfVehChange = async () => {
    let wtInclVeh = getValue(thisObj, "txtEWtInclVeh") === undefined ? 0 : getValue(thisObj, "txtEWtInclVeh") === null ? 0 : getValue(thisObj, "txtEWtInclVeh") === "" ? 0 : getValue(thisObj, "txtEWtInclVeh");
    let wtOfVeh = getValue(thisObj, "txtEWtOfVeh") === undefined ? 0 : getValue(thisObj, "txtEWtOfVeh") === null ? 0 : getValue(thisObj, "txtEWtOfVeh") === "" ? 0 : getValue(thisObj, "txtEWtOfVeh");

    if (IsNumeric.test(Number(wtInclVeh)) && IsNumeric.test(Number(wtOfVeh))) {
      let lblWtOfPnuts = Number(wtInclVeh) - Number(wtOfVeh);
      setValue(thisObj, 'txtEWtOfPnut', Math.round(lblWtOfPnuts));
    }

    else {
      clearValue(thisObj, "txtEWtOfPnut", "")
      clearValue(thisObj, "txtEWtOfVeh", "")
    }

    let opt_hmYESNO = getValue(thisObj, "radioHighMoisture");
    let buyingPointList = thisObj.state['ddLocation'].valueList;
    let selectedBuyingPointValue = getValue(thisObj, "ddLocation", '');
    let pnutTypeList = thisObj.state['ddPeanutType'].valueList;
    let selectedPnutTypeValue = getValue(thisObj, "ddPeanutType", '')
    let lstAreaId = null;
    let lstPeanutTypeID = null;
    if (selectedBuyingPointValue !== "" && selectedBuyingPointValue !== undefined) {
      lstAreaId = buyingPointList.find(elem => elem.key === selectedBuyingPointValue).area_id;
    }
    if (selectedPnutTypeValue !== "" && selectedPnutTypeValue !== undefined) {
      lstPeanutTypeID = pnutTypeList.find(elem => elem.key === selectedPnutTypeValue).key;
    }
    if (opt_hmYESNO == "2") {
      let weightincludingvehicle = getValue(thisObj, "txtEWtInclVeh") === undefined ? 0 : getValue(thisObj, "txtEWtInclVeh") === null ? 0 : getValue(thisObj, "txtEWtInclVeh") === "" ? 0 : getValue(thisObj, "txtEWtInclVeh")
      let weightofvehicle = getValue(thisObj, "txtEWtOfVeh") === undefined ? 0 : getValue(thisObj, "txtEWtOfVeh") === null ? 0 : getValue(thisObj, "txtEWtOfVeh") === "" ? 0 : getValue(thisObj, "txtEWtOfVeh")
      let moisturepct = getValue(thisObj, "txtMoisture") === undefined ? 0 : getValue(thisObj, "txtMoisture") === null ? 0 : getValue(thisObj, "txtMoisture") === "" ? 0 : getValue(thisObj, "txtMoisture")
      let cr_br_pct = getValue(thisObj, "txtCrkOrBrkShellsPct") === undefined ? 0 : getValue(thisObj, "txtCrkOrBrkShellsPct") === null ? 0 : getValue(thisObj, "txtCrkOrBrkShellsPct") === "" ? 0 : getValue(thisObj, "txtCrkOrBrkShellsPct")
      let dcol_shel_pct = getValue(thisObj, "txtDiscoloredShellsPct") === undefined ? 0 : getValue(thisObj, "txtDiscoloredShellsPct") === null ? 0 : getValue(thisObj, "txtDiscoloredShellsPct") === "" ? 0 : getValue(thisObj, "txtDiscoloredShellsPct")
      let fan_pct = getValue(thisObj, "txtFancyPct") === undefined ? 0 : getValue(thisObj, "txtFancyPct") === null ? 0 : getValue(thisObj, "txtFancyPct") === "" ? 0 : getValue(thisObj, "txtFancyPct")
      let foreignmaterialpct = getValue(thisObj, "txtForeignMaterialPct") === undefined ? 0 : getValue(thisObj, "txtForeignMaterialPct") === null ? 0 : getValue(thisObj, "txtForeignMaterialPct") === "" ? 0 : getValue(thisObj, "txtForeignMaterialPct")
      let lskpct = getValue(thisObj, "txtLSKPct") === undefined ? 0 : getValue(thisObj, "txtLSKPct") === null ? 0 : getValue(thisObj, "txtLSKPct") === "" ? 0 : getValue(thisObj, "txtLSKPct")
      let smk = getValue(thisObj, "txtSMKRSPct") === undefined ? 0 : getValue(thisObj, "txtSMKRSPct") === null ? 0 : getValue(thisObj, "txtSMKRSPct") === "" ? 0 : getValue(thisObj, "txtSMKRSPct")
      let sspct = getValue(thisObj, "txtSoundSplitsPct") === undefined ? 0 : getValue(thisObj, "txtSoundSplitsPct") === null ? 0 : getValue(thisObj, "txtSoundSplitsPct") === "" ? 0 : getValue(thisObj, "txtSoundSplitsPct")
      let okpct = getValue(thisObj, "txtOtherKernelsPct") === undefined ? 0 : getValue(thisObj, "txtOtherKernelsPct") === null ? 0 : getValue(thisObj, "txtOtherKernelsPct") === "" ? 0 : getValue(thisObj, "txtOtherKernelsPct")
      let elkpct = getValue(thisObj, "txtELKPct") === undefined ? 0 : getValue(thisObj, "txtELKPct") === null ? 0 : getValue(thisObj, "txtELKPct") === "" ? 0 : getValue(thisObj, "txtELKPct")
      let dam_pct = getValue(thisObj, "txtTotalDamagePct") === undefined ? 0 : getValue(thisObj, "txtTotalDamagePct") === null ? 0 : getValue(thisObj, "txtTotalDamagePct") === "" ? 0 : getValue(thisObj, "txtTotalDamagePct")
      CalculateValues(lstAreaId, lstPeanutTypeID, weightincludingvehicle, weightofvehicle, moisturepct, cr_br_pct,
        dcol_shel_pct, fan_pct, foreignmaterialpct, lskpct, smk, sspct, okpct, elkpct, dam_pct);
    }
    else {
      let weightincludingvehicle = getValue(thisObj, "txtEWtInclVeh") === undefined ? 0 : getValue(thisObj, "txtEWtInclVeh") === null ? 0 : getValue(thisObj, "txtEWtInclVeh") === "" ? 0 : getValue(thisObj, "txtEWtInclVeh")
      let weightofvehicle = getValue(thisObj, "txtEWtOfVeh") === undefined ? 0 : getValue(thisObj, "txtEWtOfVeh") === null ? 0 : getValue(thisObj, "txtEWtOfVeh") === "" ? 0 : getValue(thisObj, "txtEWtOfVeh")
      let moisturepct = getValue(thisObj, "txtMoisture") === undefined ? 0 : getValue(thisObj, "txtMoisture") === null ? 0 : getValue(thisObj, "txtMoisture") === "" ? 0 : getValue(thisObj, "txtMoisture")
      let cr_br_pct = getValue(thisObj, "txtCrkOrBrkShellsPct") === undefined ? 0 : getValue(thisObj, "txtCrkOrBrkShellsPct") === null ? 0 : getValue(thisObj, "txtCrkOrBrkShellsPct") === "" ? 0 : getValue(thisObj, "txtCrkOrBrkShellsPct")
      let dcol_shel_pct = getValue(thisObj, "txtDiscoloredShellsPct") === undefined ? 0 : getValue(thisObj, "txtDiscoloredShellsPct") === null ? 0 : getValue(thisObj, "txtDiscoloredShellsPct") === "" ? 0 : getValue(thisObj, "txtDiscoloredShellsPct")
      let fancy = getValue(thisObj, "txtFANCY") === undefined ? 0 : getValue(thisObj, "txtFANCY") === null ? 0 : getValue(thisObj, "txtFANCY") === "" ? 0 : getValue(thisObj, "txtFANCY")
      let fm = getValue(thisObj, "txtFM") === undefined ? 0 : getValue(thisObj, "txtFM") === null ? 0 : getValue(thisObj, "txtFM") === "" ? 0 : getValue(thisObj, "txtFM")
      let lsk = getValue(thisObj, "txtLSK") === undefined ? 0 : getValue(thisObj, "txtLSK") === null ? 0 : getValue(thisObj, "txtLSK") === "" ? 0 : getValue(thisObj, "txtLSK")
      let smk = getValue(thisObj, "txtSMK") === undefined ? 0 : getValue(thisObj, "txtSMK") === null ? 0 : getValue(thisObj, "txtSMK") === "" ? 0 : getValue(thisObj, "txtSMK")
      let ss = getValue(thisObj, "txtSS") === undefined ? 0 : getValue(thisObj, "txtSS") === null ? 0 : getValue(thisObj, "txtSS") === "" ? 0 : getValue(thisObj, "txtSS")
      let ok = getValue(thisObj, "txtOK") === undefined ? 0 : getValue(thisObj, "txtOK") === null ? 0 : getValue(thisObj, "txtOK") === "" ? 0 : getValue(thisObj, "txtOK")
      let elk = getValue(thisObj, "txtELK") === undefined ? 0 : getValue(thisObj, "txtELK") === null ? 0 : getValue(thisObj, "txtELK") === "" ? 0 : getValue(thisObj, "txtELK")
      let dam = getValue(thisObj, "txtDAM") === undefined ? 0 : getValue(thisObj, "txtDAM") === null ? 0 : getValue(thisObj, "txtDAM") === "" ? 0 : getValue(thisObj, "txtDAM")

      CalculateValues(lstAreaId, lstPeanutTypeID, weightincludingvehicle, weightofvehicle, moisturepct, cr_br_pct,
        dcol_shel_pct, fancy, fm, lsk, smk, ss, ok, elk, dam);
    }


  }
  thisObj.ontxtEWtOfVehChange = ontxtEWtOfVehChange;

  const ontxtEWtOfVehBlur = () => {
    let wtOfVeh = getValue(thisObj, "txtEWtOfVeh") === undefined ? 0 : getValue(thisObj, "txtEWtOfVeh") === null ? 0 : getValue(thisObj, "txtEWtOfVeh") === "" ? 0 : getValue(thisObj, "txtEWtOfVeh");
    let wtPenuts = getValue(thisObj, 'txtEWtOfPnut') === undefined ? 0 : getValue(thisObj, "txtEWtOfPnut") === null ? 0 : getValue(thisObj, "txtEWtOfPnut") === "" ? 0 : getValue(thisObj, "txtEWtOfPnut")
    setValue(thisObj, 'txtEWtOfVeh', (Math.round(Number(wtOfVeh))).toString())
    setValue(thisObj, 'txtWtOfVehicle', (Math.round(Number(wtOfVeh))).toString())
    setValue(thisObj, 'txtGrossWeight', (Math.round(Number(wtPenuts))).toString())
  }

  thisObj.ontxtEWtOfVehBlur = ontxtEWtOfVehBlur;




  //Label change events
  const ontxtNChange = () => {
    let elecUploadInd = document.getElementById('txtN').value.toString();
    if (elecUploadInd.toUpperCase() == 'Y') {
      disable(thisObj, 'radioHighMoisture')
      disable(thisObj, 'ddPeanutType')
      //disable(thisObj, "grpbxGradeCalculation")
      setdisableGradeCalculationsBoolean(true)
      setData(thisObj, "setdisableGradeCalculationsBoolean", true)
    }
    else {
      enable(thisObj, 'radioHighMoisture')
      enable(thisObj, 'ddPeanutType')
      //enable(thisObj, "grpbxGradeCalculation")
      setdisableGradeCalculationsBoolean(false)
      setData(thisObj, "setdisableGradeCalculationsBoolean", false)
    }

  }
  thisObj.ontxtNChange = ontxtNChange;


  const ontxtLoadTypeChange = () => {
    if (document.getElementById('txtLoadType').value != undefined || document.getElementById('txtLoadType').value != null || document.getElementById('txtLoadType').value != "") {
      if (document.getElementById('txtLoadType').value.toString().toUpperCase() == 'ADD') {
        // setLabel(thisObj, 'btnOk', 'Create')
        document.getElementById('btnOk').innerHTML = "Create";
        show(thisObj, 'chkboxResale')
        hide(thisObj, 'btnPrint', false)
        hide(thisObj, 'btnDelete', false)
        enable(thisObj, 'ddLocation')
        enable(thisObj, 'txtSC95')
        enable(thisObj, 'txtInspectionDateTime')
      }
      else if (document.getElementById('txtLoadType').value.toString().toUpperCase() == 'EDIT') {
        document.getElementById('btnOk').innerHTML = "Save";
        hide(thisObj, 'chkboxResale', false)
        show(thisObj, 'btnPrint')
        show(thisObj, 'btnDelete')
        disable(thisObj, 'ddLocation')
        disable(thisObj, 'txtSC95')
        disable(thisObj, 'txtInspectionDateTime')
      }
    }

  }
  thisObj.ontxtLoadTypeChange = ontxtLoadTypeChange;

  const ontxtTotalKrnlsChange = () => {
    let totalKrns = getValue(thisObj, 'txtTotalKrnls') === undefined ? 0 : getValue(thisObj, 'txtTotalKrnls') === null ? 0 : getValue(thisObj, 'txtTotalKrnls') === "" ? 0 : getValue(thisObj, 'txtTotalKrnls')
    let hullsPct = getValue(thisObj, 'txtHullsPct') === undefined ? 0 : getValue(thisObj, 'txtHullsPct') === null ? 0 : getValue(thisObj, 'txtHullsPct') === "" ? 0 : getValue(thisObj, 'txtHullsPct')
    let totalKernalsandHulls = Number(totalKrns) + Number(hullsPct);
    setValue(thisObj, 'txtTotalKernelsHulls', Math.round(totalKernalsandHulls).toFixed(2));

  }
  thisObj.ontxtTotalKrnlsChange = ontxtTotalKrnlsChange;


  const ontxtTotalSMKChange = () => {
    let totalSm = getValue(thisObj, 'txtTotalSMK') === undefined ? 0 : getValue(thisObj, 'txtTotalSMK') === null ? 0 : getValue(thisObj, 'txtTotalSMK') === "" ? 0 : getValue(thisObj, 'txtTotalSMK')
    let otherkPct = getValue(thisObj, 'txtOtherKernelsPct') === undefined ? 0 : getValue(thisObj, 'txtOtherKernelsPct') === null ? 0 : getValue(thisObj, 'txtOtherKernelsPct') === "" ? 0 : getValue(thisObj, 'txtOtherKernelsPct')
    let damagedPct = getValue(thisObj, 'txtTotalDamagePct') === undefined ? 0 : getValue(thisObj, 'txtTotalDamagePct') === null ? 0 : getValue(thisObj, 'txtTotalDamagePct') === "" ? 0 : getValue(thisObj, 'txtTotalDamagePct')
    let totalKernals = Number(totalSm) + Number(otherkPct) + Number(damagedPct);
    setValue(thisObj, 'txtTotalKrnls', Math.round(totalKernals).toFixed(2));
    ontxtTotalKrnlsChange();
  }
  thisObj.ontxtTotalSMKChange = ontxtTotalSMKChange;


  //button click event

  const onbtnDeleteClick = async () => {

    if (confirm("Are you sure you wish to delete this Residue Inspection? Confirm Deletion")) {

      let rs = await bUpdateResidueInspection("DELETE")
      if (rs) {
        setValue(thisObj, 'txtLoadType', 'ADD')
        setValue(thisObj, 'txtN', 'N')
        enable(thisObj, 'radioHighMoisture')
        enable(thisObj, 'ddPeanutType')
        enable(thisObj, "grpbxGradeCalculation")
        setdisableGradeCalculationsBoolean(false)
        setData(thisObj, "setdisableGradeCalculationsBoolean", false)


        setLabel(thisObj, 'lblAddedBy', '')
        setLabel(thisObj, 'lblChangedBy', '')
        document.getElementById('btnOk').innerHTML = "Create";
        show(thisObj, 'chkboxResale')
        hide(thisObj, 'btnPrint', false)
        hide(thisObj, 'btnDelete', false)
        enable(thisObj, 'ddLocation')
        enable(thisObj, 'txtSC95')
        enable(thisObj, 'txtInspectionDateTime')
        showMessage(thisObj, "Residue Inspection has been successfully deleted.", true)
        // document.getElementById("Settlements_FV95ResiduePopUp").childNodes[0].click();
      }

    }


  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick;

  const onbtnExitClick = () => {
    try {
      document.getElementById("Settlements_FV95ResiduePopUp").childNodes[0].click();
    }
    catch (err) {
      if (err instanceof EvalError) {
        alert(err.message);
      } else {
        alert("Exception in PreSubmit Event code for widget:btnExit event:Click");
      }
    }
  }
  thisObj.onbtnExitClick = onbtnExitClick;

  const onbtnOkClick = async () => {

    if (getValue(thisObj, 'chkboxSendBack')) {
      if (confirm("Are you sure you wish to send this Residue Load back to the Upload Failure Screen?")) {
        ProceedSave()
      }
    }
    else {
      ProceedSave()
    }
    async function ProceedSave() {
      let opt_hmYESNO = getValue(thisObj, "radioHighMoisture")
      if (opt_hmYESNO == "1") {
        ConvertHMCGrades();
      }

      let buyptId = null;
      let lstAreaId = null;
      let buyingPointList = thisObj.state['ddLocation'].valueList;
      let selectedBuyingPointValue = getValue(thisObj, "ddLocation", '');
      if (selectedBuyingPointValue !== "" && selectedBuyingPointValue !== undefined) {
        buyptId = buyingPointList.find(elem => elem.key === selectedBuyingPointValue).key;
        lstAreaId = buyingPointList.find(elem => elem.key === selectedBuyingPointValue).area_id;
      }
      await SettlementService.RetrieveCheck1007OneDocumentDetails(buyptId).then(response => {
        let data = response;
        if (data.length > 0) {
          setData(thisObj, 'DocumentDetailsStatus', data[0]);
        }

      })
      const compId = sessionStorage.getItem('compId')
      const cropYear = sessionStorage.getItem('year');
      let res = await SettlementService.RetrieveAreaControlDetails(lstAreaId)
      //let res = response;
      if (res.length > 0) {
        setData(thisObj, 'AreaControlDetails', res[0]);
      }

      let validResponse = bFormValid("OK")
      if (validResponse) {

        if (getValue(thisObj, 'txt1007') === "" || getValue(thisObj, 'txt1007') === null || getValue(thisObj, 'txt1007') === undefined) {
          document.getElementById('btnPrint').innerHTML = "Print";
          show(thisObj, 'btnDelete')
        }
        else {
          document.getElementById('btnPrint').innerHTML = "Reprint";
          show(thisObj, 'btnDelete')
        }
        if (getValue(thisObj, 'txtLoadType') == 'ADD') {
          let updtAddRspns = await bUpdateResidueInspection("ADD")
          setValue(thisObj, 'txtLoadType', 'EDIT')
          bFillForm()
          ontxtLoadTypeChange();

        }
        else {
          let updtUpdateRspns = await bUpdateResidueInspection("UPDATE")
          setValue(thisObj, 'txtLoadType', 'EDIT')
          bFillForm()
          ontxtLoadTypeChange();
        }


      }

    }

  }
  thisObj.onbtnOkClick = onbtnOkClick;

  const ConvertHMCGrades = async () => {
    try {
      let buyingPointList = thisObj.state['ddLocation'].valueList;
      let selectedBuyingPointValue = getValue(thisObj, "ddLocation", '');
      let pnutTypeList = thisObj.state['ddPeanutType'].valueList;
      let selectedPnutTypeValue = getValue(thisObj, "ddPeanutType", '')
      let segTypeList = thisObj.state['ddSeg'].valueList;
      let selectedSegTypeValue = getValue(thisObj, "ddSeg", '')
      let lstAreaId = null;
      let lstPeanutTypeID = null;
      let segType = null;
      if (selectedBuyingPointValue !== "" && selectedBuyingPointValue !== undefined) {
        lstAreaId = buyingPointList.find(elem => elem.key === selectedBuyingPointValue).area_id;
      }
      if (selectedPnutTypeValue !== "" && selectedPnutTypeValue !== undefined) {
        lstPeanutTypeID = pnutTypeList.find(elem => elem.key === selectedPnutTypeValue).key;
      }
      if (selectedSegTypeValue !== "" && selectedSegTypeValue !== undefined) {
        segType = segTypeList.find(elem => elem.key === selectedSegTypeValue).key;
      }

      let moistureReading = getValue(thisObj, 'txtMoisture') == undefined ? 0.00 : getValue(thisObj, 'txtMoisture') == null ? 0.00 : getValue(thisObj, 'txtMoisture').trim()
      let foreignMaterialPct = getValue(thisObj, 'txtForeignMaterialPct') == undefined ? 0.00 : getValue(thisObj, 'txtForeignMaterialPct') == null ? 0.00 : getValue(thisObj, 'txtForeignMaterialPct')
      let lSKPct = getValue(thisObj, 'txtLSKPct') == undefined ? 0.00 : getValue(thisObj, 'txtLSKPct') === null ? 0.00 : getValue(thisObj, 'txtLSKPct') === "" ? 0.00 : getValue(thisObj, 'txtLSKPct')
      let sMKRSPct = getValue(thisObj, 'txtSMKRSPct') == undefined ? 0.00 : getValue(thisObj, 'txtSMKRSPct') === null ? 0.00 : getValue(thisObj, 'txtSMKRSPct') === "" ? 0.00 : getValue(thisObj, 'txtSMKRSPct')
      let soundSplitsPct = getValue(thisObj, 'txtSoundSplitsPct') == undefined ? 0.00 : getValue(thisObj, 'txtSoundSplitsPct') === null ? 0.00 : getValue(thisObj, 'txtSoundSplitsPct') === "" ? 0.00 : getValue(thisObj, 'txtSoundSplitsPct')
      let totalSMk = getValue(thisObj, 'txtTotalSMK') == undefined ? 0.00 : getValue(thisObj, 'txtTotalSMK') === null ? 0.00 : getValue(thisObj, 'txtTotalSMK') === "" ? 0.00 : getValue(thisObj, 'txtTotalSMK')
      let otherKernelsPct = getValue(thisObj, 'txtOtherKernelsPct') == undefined ? 0.00 : getValue(thisObj, 'txtOtherKernelsPct') === null ? 0.00 : getValue(thisObj, 'txtOtherKernelsPct') === "" ? 0.00 : getValue(thisObj, 'txtOtherKernelsPct')
      let freexeDamagePct = getValue(thisObj, 'txtFreezeDamagePct') == undefined ? 0.00 : getValue(thisObj, 'txtFreezeDamagePct') === null ? 0.00 : getValue(thisObj, 'txtFreezeDamagePct') === "" ? 0.00 : getValue(thisObj, 'txtFreezeDamagePct')
      let concealedRMDPct = getValue(thisObj, 'txtConcealedRMDPct') == undefined ? 0.00 : getValue(thisObj, 'txtConcealedRMDPct') === null ? 0.00 : getValue(thisObj, 'txtConcealedRMDPct') === "" ? 0.00 : getValue(thisObj, 'txtConcealedRMDPct')
      let totalKernels = getValue(thisObj, 'lblTotalKernels') == undefined ? 0.00 : getValue(thisObj, 'lblTotalKernels') === null ? 0.00 : getValue(thisObj, 'lblTotalKernels') === "" ? 0.00 : getValue(thisObj, 'lblTotalKernels')
      let totalDamagePct = getValue(thisObj, 'txtTotalDamagePct') == undefined ? 0.00 : getValue(thisObj, 'txtTotalDamagePct') === null ? 0.00 : getValue(thisObj, 'txtTotalDamagePct') === "" ? 0.00 : getValue(thisObj, 'txtTotalDamagePct')
      let hullsPct = getValue(thisObj, 'txtHullsPct') == undefined ? 0.00 : getValue(thisObj, 'txtHullsPct') === null ? 0.00 : getValue(thisObj, 'txtHullsPct') === "" ? 0.00 : getValue(thisObj, 'txtHullsPct')
      let eLKPct = getValue(thisObj, 'txtELKPct') == undefined ? 0.00 : getValue(thisObj, 'txtELKPct') === null ? 0.00 : getValue(thisObj, 'txtELKPct') === "" ? 0.00 : getValue(thisObj, 'txtELKPct')
      let fancyPct = getValue(thisObj, 'txtFancyPct') == undefined ? 0.00 : getValue(thisObj, 'txtFancyPct') === null ? 0.00 : getValue(thisObj, 'txtFancyPct') === "" ? 0.00 : getValue(thisObj, 'txtFancyPct')

      let converthmcgradeobj = {
        "moist_pct": moistureReading,
        "fm_pct": foreignMaterialPct,
        "lsk_pct": lSKPct,
        "smk_pct": sMKRSPct,
        "ss_pct": soundSplitsPct,
        "smkrs_pct": totalSMk,
        "ok_pct": otherKernelsPct,
        "frz_pct": freexeDamagePct,
        "crmd_pct": concealedRMDPct,
        "totkernpct": totalKernels,
        "dampct": totalDamagePct,
        "hullspct": hullsPct,
        "elkpct": eLKPct,
        "fancypct": fancyPct
      }


      let updateConvertHighMoistureToCGrade = await SettlementService.UpdateConvertHighMoistureToCGrade(lstAreaId, lstPeanutTypeID, segType, converthmcgradeobj);
      if (updateConvertHighMoistureToCGrade.status == 200 && updateConvertHighMoistureToCGrade.result.length > 0) {
        setValue(thisObj, 'txtSeg', Math.round(updateConvertHighMoistureToCGrade.result[0].seg_type).toFixed(2))
        setValue(thisObj, 'txtFM', Math.round(updateConvertHighMoistureToCGrade.result[0].fm_pct).toFixed(2))
        setValue(thisObj, 'txtLSK', Math.round(updateConvertHighMoistureToCGrade.result[0].lsk_pct).toFixed(2))
        if (IsNumeric.test(Number(updateConvertHighMoistureToCGrade.result[0].kc_moist_pct))) {
          setValue(thisObj, 'txtMST', Math.round(updateConvertHighMoistureToCGrade.result[0].kc_moist_pct).toFixed(2))
        }
        else {
          let mstRdng = getValue(thisObj, 'txtMoisture') === undefined ? 0.00 : getValue(thisObj, 'txtMoisture') === null ? 0.00 : getValue(thisObj, 'txtMoisture') === "" ? 0.00 : getValue(thisObj, 'txtMoisture')
          setValue(thisObj, 'txtMST', getValue(thisObj, mstRdng))
        }
        setValue(thisObj, 'txtSMK', Math.round(updateConvertHighMoistureToCGrade.result[0].smk_pct).toFixed(2))
        setValue(thisObj, 'txtSS', Math.round(updateConvertHighMoistureToCGrade.result[0].ss_pct).toFixed(2))

        setValue(thisObj, 'txtSMKRS', Math.round(
          Number(updateConvertHighMoistureToCGrade.result[0].smk_pct) +
          Number(updateConvertHighMoistureToCGrade.result[0].ss_pct)
        ).toFixed(2))

        setValue(thisObj, 'txtOK', Math.round(updateConvertHighMoistureToCGrade.result[0].ok_pct).toFixed(2))
        setValue(thisObj, 'txtFRZ', getValue(thisObj, 'txtFreezeDamagePct') == undefined ? 0 : getValue(thisObj, 'txtFreezeDamagePct'))
        setValue(thisObj, 'txtCRMD', getValue(thisObj, 'txtConcealedRMDPct') == undefined ? 0 : getValue(thisObj, 'txtConcealedRMDPct'))
        setValue(thisObj, 'txtDAM', Math.round(updateConvertHighMoistureToCGrade.result[0].dam_pct).toFixed(2))
        let txtSmkrs = getValue(thisObj, 'txtSMKRS') === undefined ? 0 : getValue(thisObj, 'txtSMKRS') === null ? 0 : getValue(thisObj, 'txtSMKRS') === "" ? 0 : getValue(thisObj, 'txtSMKRS')
        let txtDAM = getValue(thisObj, 'txtDAM') === undefined ? 0 : getValue(thisObj, 'txtDAM') === null ? 0 : getValue(thisObj, 'txtDAM') === "" ? 0 : getValue(thisObj, 'txtDAM')
        let txtOK = getValue(thisObj, 'txtOK') === undefined ? 0 : getValue(thisObj, 'txtOK') === null ? 0 : getValue(thisObj, 'txtOK') === "" ? 0 : getValue(thisObj, 'txtOK')
        let txttotalKernels = Number(txtSmkrs) + Number(txtDAM) + Number(txtOK)
        setValue(thisObj, 'txtTotalKernels', Math.round(txttotalKernels).toFixed(2))
        setValue(thisObj, 'txtHULLS', Math.round(updateConvertHighMoistureToCGrade.result[0].hulls_pct).toFixed(2))
        setValue(thisObj, 'txtELK', Math.round(updateConvertHighMoistureToCGrade.result[0].elk_pct).toFixed(2))
        setValue(thisObj, 'txtFANCY', Math.round(updateConvertHighMoistureToCGrade.result[0].fancy_pct).toFixed(2))
        setValue(thisObj, 'txtTotKrnlsHulls', Math.round(getValue(thisObj, 'txtTotalKernels') == undefined ? 0.00 : Number(getValue(thisObj, 'txtTotalKernels')) + getValue(thisObj, 'txtHULLS') == undefined ? 0.00 : Number(getValue(thisObj, 'txtHULLS'))).toFixed(2))
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception occured in ConvertHMCGrades()"
        )
      }
      return false;
    }
    return true;
  };




  function bFormValid(strReqAction) {
    try {

      const ValidSC95 = new RegExp('^(([A-Z][0-9]+)|([0-9]+))$');
      if (document.getElementById('txtLoadType').value != undefined || document.getElementById('txtLoadType').value != null || document.getElementById('txtLoadType').value != "") {
        if (document.getElementById('txtLoadType').value.toString().toUpperCase() == 'ADD') {
          let buyingPointList = thisObj.state['ddLocation'].valueList;
          let selectedBuyingPointValue = getValue(thisObj, "ddLocation", '');
          let penutTypetList = thisObj.state['ddPeanutType'].valueList;
          let selectedPnutTypeValue = getValue(thisObj, "ddPeanutType", '');
          let edibleOilList = thisObj.state['ddEdibleOil'].valueList;
          let selectedEdibleOilValue = getValue(thisObj, "ddEdibleOil", '');
          let segList = thisObj.state['ddSeg'].valueList;
          let selectedsegValue = getValue(thisObj, "ddSeg", '');
          let signWeigher = thisObj.state['ddSignOfWeigher'].valueList;
          let selectedsignWeigherValue = getValue(thisObj, "ddSignOfWeigher", '');
          let buyptId = "";
          let pnutTypeId = "";
          let segType = "";
          let edibleOilInd = "";
          let signWeigherId = "";
          if (selectedBuyingPointValue !== "" && selectedBuyingPointValue !== undefined) {
            buyptId = buyingPointList.find(elem => elem.key === selectedBuyingPointValue).key;
          }
          else {
            showMessage(thisObj, "Please select Location", false)
            return false;
          }
          if (selectedPnutTypeValue !== "" && selectedPnutTypeValue !== undefined) {
            pnutTypeId = penutTypetList.find(elem => elem.key === selectedPnutTypeValue).key;
          }
          else {
            showMessage(thisObj, "Please select Peanut Type", false)
            return false;
          }
          if (selectedsegValue !== "" && selectedsegValue !== undefined) {
            segType = segList.find(elem => elem.key === selectedsegValue).key;
          }
          else {
            showMessage(thisObj, "Please select Seg", false)
            return false;
          }
          if (selectedEdibleOilValue !== "" && selectedEdibleOilValue !== undefined) {
            edibleOilInd = edibleOilList.find(elem => elem.key === selectedEdibleOilValue).key;
          }
          else {
            showMessage(thisObj, "Please select EdibleOil", false)
            return false;
          }
          // if (selectedsignWeigherValue !== "" && selectedsignWeigherValue !== undefined) {
          //   signWeigherId = signWeigher.find(elem => elem.key === selectedsignWeigherValue).key;
          // }
          // else {
          //   showMessage(thisObj, "Please select Signweigher", false)
          //   return false;
          // }

          if (getValue(thisObj, 'txtSC95') == undefined) {
            showMessage(thisObj, "SC95 Number is required!" + " " + "Invalid SC95 Number!", false)
            return false;
          }

          let vSc95 = getValue(thisObj, 'txtSC95')

          if (vSc95.trim() == null || vSc95.trim() == "") {
            showMessage(thisObj, "SC95 Number is required!" + " " + "Invalid SC95 Number!", false)
            return false;
          }
          if (vSc95.trim().length != 7) {
            showMessage(thisObj, "SC95 Number must be 7 characters long!" + " " + "Invalid SC95 Number!", false)
            return false;
          }

          let data = getData(thisObj, 'DocumentDetailsStatus');
          if (data != null || data != "") {
            if (data.onedoc_ind == 'Y') {
              if (!IsNumeric.test(vSc95)) {
                showMessage(thisObj, "SC95 Number must be all numeric for locations using One Documents!" + " " + "Invalid SC95 Number!", false)
                return false;
              }
            }
          }
          else {
            if (!ValidSC95.test(vSc95)) {
              showMessage(thisObj, "SC95 Number must be in Format A###### for this location!" + " " + "Invalid SC95 Number!", false)
              return false;
            }
          }
          let vInspDateTime = getValue(thisObj, 'txtInspectionDateTime')

          if ((vInspDateTime === null) || (vInspDateTime === "") || (vInspDateTime === undefined)) {
            showMessage(thisObj, "Inspection Date is required!" + " " + "Invalid Inspection Date!", false)
            return false;
          }

        }
      }
      else {
        showMessage(thisObj, "Load Type is required!" + " " + "Missing Load Type!", false)
        return false;
      }

      if (strReqAction === "PRINT") {

        if (getValue(thisObj, 'chkboxResale')) {
          let vSellerNumber = getValue(thisObj, 'txtSellerNumber') === undefined ? "" : getValue(thisObj, 'txtSellerNumber') === null ? "" : getValue(thisObj, 'txtSellerNumber')
          let vSellerName = getValue(thisObj, 'txtSellerName') === undefined ? "" : getValue(thisObj, 'txtSellerName') === null ? "" : getValue(thisObj, 'txtSellerName')
          let vBuyerNumber = getValue(thisObj, 'txtBuyerNumber') === undefined ? "" : getValue(thisObj, 'txtBuyerNumber') === null ? "" : getValue(thisObj, 'txtBuyerNumber')
          let vBuyerName = getValue(thisObj, 'txtBuyerName') === undefined ? "" : getValue(thisObj, 'txtBuyerName') === null ? "" : getValue(thisObj, 'txtBuyerName')
          if ((vSellerNumber == "") || (vSellerNumber === null)) {
            showMessage(thisObj, "Seller Number is required!" + " " + "Missing Seller Number!", false)
            return false;
          }
          if ((vSellerName == "") || (vSellerName === null)) {
            showMessage(thisObj, "Seller Name is required!" + " " + "Missing Seller Name!", false)
            return false;
          }

          if (vBuyerNumber === undefined || vBuyerNumber === null || vBuyerNumber === "" || vBuyerNumber === 0) {
            showMessage(thisObj, "Buyer Number is required!" + " " + "Missing Buyer Number!", false)
            return false;
          }
          if (vBuyerName === undefined || vBuyerName === null || vBuyerName === "" || vBuyerName === 0 || vBuyerName === "0") {
            showMessage(thisObj, "Buyer Name is required!" + " " + "Missing Buyer Name!", false)
            return false;
          }


          if ((getValue(thisObj, 'ddSignOfWeigher')) == "" || (getValue(thisObj, 'ddSignOfWeigher') == null)) {
            showMessage(thisObj, "Signature of Weigher is required!" + " " + "Missing Signature of Weigher!", false)
            return false;
          }

        }
      }

      if (getValue(thisObj, 'txtN') == 'Y') {
        return true;
      }

      let buyingPointList = thisObj.state['ddLocation'].valueList;
      let selectedBuyingPointValue = getValue(thisObj, "ddLocation", '');
      let lstGramEntryInd = null;
      if (selectedBuyingPointValue !== "" && selectedBuyingPointValue !== undefined) {
        lstGramEntryInd = buyingPointList.find(elem => elem.key === selectedBuyingPointValue).gram_entry_ind;
      }

      let opt_hmYESNO = getValue(thisObj, "radioHighMoisture")
      if (opt_hmYESNO == "1") {
        let moistureReading = getValue(thisObj, 'txtMoisture') === undefined ? 0 : getValue(thisObj, 'txtMoisture') === null ? 0 : getValue(thisObj, 'txtMoisture') === "" ? 0 : getValue(thisObj, 'txtMoisture')
        if (Number(moistureReading) < 10.5) {
          showMessage(thisObj, "Please mark this SC95 as not High Moisture or enter High Moisture percentage!" + " " + "Invalid Moisture Entry!", false)
          return false;
        }
        else if (Number(moistureReading) > 10.49) {
          showMessage(thisObj, "Please mark this SC95 as High Moisture or correct Moisture percent!" + "" + "Invalid Moisture Entry!", false)
          return false;
        }
      }

      let lstAreaId = null;
      if (selectedBuyingPointValue !== "" && selectedBuyingPointValue !== undefined) {
        lstAreaId = buyingPointList.find(elem => elem.key === selectedBuyingPointValue).area_id;
      }
      else {
        showMessage(thisObj, "Please select Location!" + " " + "Missing Location!", false)
        return false;
      }
      let dblMinKernHullPct = null;
      let dblMaxKernHullPct = null;
      let res = getData(thisObj, 'AreaControlDetails');
      if (res != null && res != "") {
        dblMinKernHullPct = res.minKernHullPct;
        dblMaxKernHullPct = res.maxKernHullPct;
      }

      let totalKernalHulls = getValue(thisObj, 'txtTotalKernelsHulls') === undefined ? 0 : getValue(thisObj, 'txtTotalKernelsHulls') === null ? 0 : getValue(thisObj, 'txtTotalKernelsHulls') === "" ? 0 : Number(getValue(thisObj, 'txtTotalKernelsHulls'))
      if (totalKernalHulls < dblMinKernHullPct || totalKernalHulls > dblMaxKernHullPct) {
        showMessage(thisObj, "Total Kernals + Hulls invalid!" + " " + "Verify Grades!", false)
        return false;
      }

    } catch (err) {

      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
        return false;
      }
      else {
        showMessage(
          thisObj, "Exception occured in ValidForm()"
        )
        return false;
      }

    }

    return true;

  }


  const bUpdateResidueInspection = async (strReqAction) => {

    try {
      let buyingPointList = thisObj.state['ddLocation'].valueList;
      let selectedBuyingPointValue = getValue(thisObj, "ddLocation", '');
      let penutTypetList = thisObj.state['ddPeanutType'].valueList;
      let selectedPnutTypeValue = getValue(thisObj, "ddPeanutType", '');
      let edibleOilList = thisObj.state['ddEdibleOil'].valueList;
      let selectedEdibleOilValue = getValue(thisObj, "ddEdibleOil", '');
      let segList = thisObj.state['ddSeg'].valueList;
      let selectedsegValue = getValue(thisObj, "ddSeg", '');
      let buyptId = "";
      let pnutTypeId = "";
      let segType = "";
      let edibleOilInd = "";
      if (selectedBuyingPointValue !== "" && selectedBuyingPointValue !== undefined) {
        buyptId = buyingPointList.find(elem => elem.key === selectedBuyingPointValue).key;
      }
      else {
        showMessage(thisObj, "Please select Location", false)
        return false;
      }
      if (selectedPnutTypeValue !== "" && selectedPnutTypeValue !== undefined) {
        pnutTypeId = penutTypetList.find(elem => elem.key === selectedPnutTypeValue).key;
      }
      else {
        showMessage(thisObj, "Please select Peanut Type", false)
        return false;
      }
      if (selectedEdibleOilValue !== "" && selectedEdibleOilValue !== undefined) {
        edibleOilInd = edibleOilList.find(elem => elem.key === selectedEdibleOilValue).key;
      }
      else {
        showMessage(thisObj, "Please select EdibleOil", false)
        return false;
      }
      if (selectedsegValue !== "" && selectedsegValue !== undefined) {
        segType = segList.find(elem => elem.key === selectedsegValue).key;
      }
      else {
        showMessage(thisObj, "Please select Seg", false)
        return false;
      }
      let inspnum = getValue(thisObj, 'txtSC95') === undefined ? 0 : getValue(thisObj, 'txtSC95')
      let revision_num = Number(revisionno);

      //document.getElementById('txtRevisionNumber').value  === undefined ? 0 : document.getElementById('txtRevisionNumber').value === null ? 0 :  document.getElementById('txtRevisionNumber').value === "" ? 0 :  document.getElementById('txtRevisionNumber').value;
      let insp_date_time = getValue(thisObj, 'txtInspectionDateTime') === undefined ? "" : getValue(thisObj, 'txtInspectionDateTime')
      let high_moist_ind = "N";
      let opt_hmYESNO = getValue(thisObj, "radioHighMoisture");
      let flavusInd = getValue(thisObj, "chkboxFlavusFound");

      if (opt_hmYESNO == "1") {
        high_moist_ind = 'Y';
      }
      else {
        high_moist_ind = 'N';
      }
      if (flavusInd) {
        flavusInd = 'Y';
      }
      else {
        flavusInd = 'N';
      }

      let probePattern = getValue(thisObj, 'txtProbingPattern') === undefined ? "" : getValue(thisObj, 'txtProbingPattern') === null ? "" : getValue(thisObj, 'txtProbingPattern') === '' ? "" : getValue(thisObj, 'txtProbingPattern')
      let fmSample = getValue(thisObj, 'txtFMSampleWt') === undefined ? 0 : getValue(thisObj, 'txtFMSampleWt') === '' ? 0 : getValue(thisObj, 'txtFMSampleWt') === null ? 0 : Number(getValue(thisObj, 'txtFMSampleWt'))
      let cleanSampleWt = getValue(thisObj, 'txtCleanSampleWt') === undefined ? 0 : getValue(thisObj, 'txtCleanSampleWt') === '' ? 0 : getValue(thisObj, 'txtCleanSampleWt') === null ? 0 : Number(getValue(thisObj, 'txtCleanSampleWt'))
      let smkGR = getValue(thisObj, 'txtSMKRSGr') === undefined ? 0 : getValue(thisObj, 'txtSMKRSGr') === '' ? 0 : getValue(thisObj, 'txtSMKRSGr') === null ? 0 : Number(getValue(thisObj, 'txtSMKRSGr'))
      let ssGr = getValue(thisObj, 'txtSoundSplitsGr') === undefined ? 0 : getValue(thisObj, 'txtSoundSplitsGr') === '' ? 0 : getValue(thisObj, 'txtSoundSplitsGr') === null ? 0 : Number(getValue(thisObj, 'txtSoundSplitsGr'))
      let okGR = getValue(thisObj, 'txtOtherKernelsGr') === undefined ? 0 : getValue(thisObj, 'txtOtherKernelsGr') === '' ? 0 : getValue(thisObj, 'txtOtherKernelsGr') === null ? 0 : Number(getValue(thisObj, 'txtOtherKernelsGr'))
      let damageSplitGr = getValue(thisObj, 'txtDamageSplits') === undefined ? 0 : getValue(thisObj, 'txtDamageSplits') === '' ? 0 : getValue(thisObj, 'txtDamageSplits') === null ? 0 : Number(getValue(thisObj, 'txtDamageSplits'))
      let damageTotalSplits = getValue(thisObj, 'txtTotalDamageGr') === undefined ? 0 : getValue(thisObj, 'txtTotalDamageGr') === '' ? 0 : getValue(thisObj, 'txtTotalDamageGr') === null ? 0 : Number(getValue(thisObj, 'txtTotalDamageGr'))
      let hullsgr = getValue(thisObj, 'txtHullsGr') === undefined ? 0 : getValue(thisObj, 'txtHullsGr') === '' ? 0 : getValue(thisObj, 'txtHullsGr') === null ? 0 : Number(getValue(thisObj, 'txtHullsGr'))
      let freezeDamageGr = getValue(thisObj, 'txtFreezeDamageGr') === undefined ? 0 : getValue(thisObj, 'txtFreezeDamageGr') === '' ? 0 : getValue(thisObj, 'txtFreezeDamageGr') === null ? 0 : Number(getValue(thisObj, 'txtFreezeDamageGr'))
      let concealedRMDGr = getValue(thisObj, 'txtConcealedRMDGr') === undefined ? 0 : getValue(thisObj, 'txtConcealedRMDGr') === '' ? 0 : getValue(thisObj, 'txtConcealedRMDGr') === null ? 0 : Number(getValue(thisObj, 'txtConcealedRMDGr'))
      let crkOrBrkShellsGr = getValue(thisObj, 'txtCrkOrBrkShellsGr') === undefined ? 0 : getValue(thisObj, 'txtCrkOrBrkShellsGr') === '' ? 0 : getValue(thisObj, 'txtCrkOrBrkShellsGr') === null ? 0 : Number(getValue(thisObj, 'txtCrkOrBrkShellsGr'))
      let discoloredShellsGR = getValue(thisObj, 'txtDiscoloredShellsGr') === undefined ? 0 : getValue(thisObj, 'txtDiscoloredShellsGr') === '' ? 0 : getValue(thisObj, 'txtDiscoloredShellsGr') === null ? 0 : Number(getValue(thisObj, 'txtDiscoloredShellsGr'))
      let hullsBrightnessGr = getValue(thisObj, 'txtHullBrightness') === undefined ? 0 : getValue(thisObj, 'txtHullBrightness') === '' ? 0 : getValue(thisObj, 'txtHullBrightness') === null ? 0 : Number(getValue(thisObj, 'txtHullBrightness'))
      let jumboGr = getValue(thisObj, 'txtJumboGr') === undefined ? 0 : getValue(thisObj, 'txtJumboGr') === '' ? 0 : getValue(thisObj, 'txtJumboGr') === null ? 0 : Number(getValue(thisObj, 'txtJumboGr'))
      let elkGr = getValue(thisObj, 'txtELKGr') === undefined ? 0 : getValue(thisObj, 'txtELKGr') === '' ? 0 : getValue(thisObj, 'txtELKGr') === null ? 0 : Number(getValue(thisObj, 'txtELKGr'))
      let fmGr = getValue(thisObj, 'txtForeignMaterialGr') === undefined ? 0 : getValue(thisObj, 'txtForeignMaterialGr') === '' ? 0 : getValue(thisObj, 'txtForeignMaterialGr') === null ? 0 : Number(getValue(thisObj, 'txtForeignMaterialGr'))
      let lskGr = getValue(thisObj, 'txtLSKGr') === undefined ? 0 : getValue(thisObj, 'txtLSKGr') === '' ? 0 : getValue(thisObj, 'txtLSKGr') === null ? 0 : Number(getValue(thisObj, 'txtLSKGr'))
      let fancyGr = getValue(thisObj, 'txtFancyGr') === undefined ? 0 : getValue(thisObj, 'txtFancyGr') === '' ? 0 : getValue(thisObj, 'txtFancyGr') === null ? 0 : Number(getValue(thisObj, 'txtFancyGr'))
      let smkrsPct = getValue(thisObj, 'txtSMKRSPct') === undefined ? 0 : getValue(thisObj, 'txtSMKRSPct') === '' ? 0 : getValue(thisObj, 'txtSMKRSPct') === null ? 0 : Number(getValue(thisObj, 'txtSMKRSPct'))
      let sspct = getValue(thisObj, 'txtSoundSplitsPct') === undefined ? 0 : getValue(thisObj, 'txtSoundSplitsPct') === '' ? 0 : getValue(thisObj, 'txtSoundSplitsPct') === null ? 0 : Number(getValue(thisObj, 'txtSoundSplitsPct'))
      let okPct = getValue(thisObj, 'txtOtherKernelsPct') === undefined ? 0 : getValue(thisObj, 'txtOtherKernelsPct') === '' ? 0 : getValue(thisObj, 'txtOtherKernelsPct') === null ? 0 : Number(getValue(thisObj, 'txtOtherKernelsPct'))
      let totalDamagePct = getValue(thisObj, 'txtTotalDamagePct') === undefined ? 0 : getValue(thisObj, 'txtTotalDamagePct') === '' ? 0 : getValue(thisObj, 'txtTotalDamagePct') === null ? 0 : Number(getValue(thisObj, 'txtTotalDamagePct'))
      let tkc = getValue(thisObj, 'txtTKC') === undefined ? 0 : getValue(thisObj, 'txtTKC') === '' ? 0 : getValue(thisObj, 'txtTKC') === null ? 0 : Number(getValue(thisObj, 'txtTKC'))
      let hullsPct = getValue(thisObj, 'txtHullsPct') === undefined ? 0 : getValue(thisObj, 'txtHullsPct') === '' ? 0 : getValue(thisObj, 'txtHullsPct') === null ? 0 : Number(getValue(thisObj, 'txtHullsPct'))
      let fdPct = getValue(thisObj, 'txtFreezeDamagePct') === undefined ? 0 : getValue(thisObj, 'txtFreezeDamagePct') === '' ? 0 : getValue(thisObj, 'txtFreezeDamagePct') === null ? 0 : Number(getValue(thisObj, 'txtFreezeDamagePct'))
      let concealedRMDPct = getValue(thisObj, 'txtConcealedRMDPct') === undefined ? 0 : getValue(thisObj, 'txtConcealedRMDPct') === '' ? 0 : getValue(thisObj, 'txtConcealedRMDPct') === null ? 0 : Number(getValue(thisObj, 'txtConcealedRMDPct'))
      let crkOrBrkShellsPct = getValue(thisObj, 'txtCrkOrBrkShellsPct') === undefined ? 0 : getValue(thisObj, 'txtCrkOrBrkShellsPct') === '' ? 0 : getValue(thisObj, 'txtCrkOrBrkShellsPct') === null ? 0 : Number(getValue(thisObj, 'txtCrkOrBrkShellsPct'))
      let discoloredShellsPct = getValue(thisObj, 'txtDiscoloredShellsPct') === undefined ? 0 : getValue(thisObj, 'txtDiscoloredShellsPct') === '' ? 0 : getValue(thisObj, 'txtDiscoloredShellsPct') === null ? 0 : Number(getValue(thisObj, 'txtDiscoloredShellsPct'))
      let hullsBrightnessPct = getValue(thisObj, 'txtHullBrightnessPct') === undefined ? 0 : getValue(thisObj, 'txtHullBrightnessPct') === '' ? 0 : getValue(thisObj, 'txtHullBrightnessPct') === null ? 0 : Number(getValue(thisObj, 'txtHullBrightnessPct'))
      let jumboPct = getValue(thisObj, 'txtJumboPct') === undefined ? 0 : getValue(thisObj, 'txtJumboPct') === '' ? 0 : getValue(thisObj, 'txtJumboPct') === null ? 0 : getValue(thisObj, 'txtJumboPct') === null ? 0 : Number(getValue(thisObj, 'txtJumboPct'))
      let elkPct = getValue(thisObj, 'txtELKPct') === undefined ? 0 : getValue(thisObj, 'txtELKPct') === '' ? 0 : getValue(thisObj, 'txtELKPct') === null ? 0 : Number(getValue(thisObj, 'txtELKPct'))
      let fmpct = getValue(thisObj, 'txtForeignMaterialPct') === undefined ? 0 : getValue(thisObj, 'txtForeignMaterialPct') === '' ? 0 : getValue(thisObj, 'txtForeignMaterialPct') === null ? 0 : Number(getValue(thisObj, 'txtForeignMaterialPct'))
      let lstPct = getValue(thisObj, 'txtLSKPct') === undefined ? 0 : getValue(thisObj, 'txtLSKPct') === '' ? 0 : getValue(thisObj, 'txtLSKPct') === null ? 0 : Number(getValue(thisObj, 'txtLSKPct'))
      let moistureReading = getValue(thisObj, 'txtMoisture') === undefined ? 0 : getValue(thisObj, 'txtMoisture') === '' ? 0 : getValue(thisObj, 'txtMoisture') === null ? 0 : Number(getValue(thisObj, 'txtMoisture'))
      let fancyPct = getValue(thisObj, 'txtFancyPct') === undefined ? 0 : getValue(thisObj, 'txtFancyPct') === '' ? 0 : getValue(thisObj, 'txtFancyPct') === null ? 0 : Number(getValue(thisObj, 'txtFancyPct'))
      let foreignMaterial = getValue(thisObj, 'txtForeignMaterial') === undefined ? 0 : getValue(thisObj, 'txtForeignMaterial') === '' ? 0 : getValue(thisObj, 'txtForeignMaterial') === null ? 0 : Number(getValue(thisObj, 'txtForeignMaterial'))
      let hseg = getValue(thisObj, 'txtSeg') === undefined ? "" : getValue(thisObj, 'txtSeg') === null ? 0 : getValue(thisObj, 'txtSeg') === null ? 0 : Number(getValue(thisObj, 'txtSeg'))
      let hFm = getValue(thisObj, 'txtFM') === undefined ? 0 : getValue(thisObj, 'txtFM') === '' ? 0 : getValue(thisObj, 'txtFM') === null ? 0 : Number(getValue(thisObj, 'txtFM'))
      let hlsk = getValue(thisObj, 'txtLSK') === undefined ? 0 : getValue(thisObj, 'txtLSK') === '' ? 0 : getValue(thisObj, 'txtLSK') === null ? 0 : Number(getValue(thisObj, 'txtLSK'))
      let hmst = getValue(thisObj, 'txtMST') === undefined ? 0 : getValue(thisObj, 'txtMST') === '' ? 0 : getValue(thisObj, 'txtMST') === null ? 0 : Number(getValue(thisObj, 'txtMST'))
      let hsmk = getValue(thisObj, 'txtSMK') === undefined ? 0 : getValue(thisObj, 'txtSMK') === '' ? 0 : getValue(thisObj, 'txtSMK') === null ? 0 : Number(getValue(thisObj, 'txtSMK'))
      let hss = getValue(thisObj, 'txtSS') === undefined ? 0 : getValue(thisObj, 'txtSS') === '' ? 0 : getValue(thisObj, 'txtSS') === null ? 0 : Number(getValue(thisObj, 'txtSS'))
      let hok = getValue(thisObj, 'txtOK') === undefined ? 0 : getValue(thisObj, 'txtOK') === '' ? 0 : getValue(thisObj, 'txtOK') === null ? 0 : Number(getValue(thisObj, 'txtOK'))
      let hfrz = getValue(thisObj, 'txtFRZ') === undefined ? 0 : getValue(thisObj, 'txtFRZ') === '' ? 0 : getValue(thisObj, 'txtFRZ') === null ? 0 : Number(getValue(thisObj, 'txtFRZ'))
      let hcrmd = getValue(thisObj, 'txtCRMD') === undefined ? 0 : getValue(thisObj, 'txtCRMD') === '' ? 0 : getValue(thisObj, 'txtCRMD') === null ? 0 : Number(getValue(thisObj, 'txtCRMD'))
      let hdam = getValue(thisObj, 'txtDAM') === undefined ? 0 : getValue(thisObj, 'txtDAM') === '' ? 0 : getValue(thisObj, 'txtDAM') === null ? 0 : Number(getValue(thisObj, 'txtDAM'))
      let hHulls = getValue(thisObj, 'txtHULLS') === undefined ? 0 : getValue(thisObj, 'txtHULLS') === '' ? 0 : getValue(thisObj, 'txtHULLS') === null ? 0 : Number(getValue(thisObj, 'txtHULLS'))
      let helk = getValue(thisObj, 'txtELK') === undefined ? 0 : getValue(thisObj, 'txtELK') === '' ? 0 : getValue(thisObj, 'txtELK') === null ? 0 : Number(getValue(thisObj, 'txtELK'))
      let hfancy = getValue(thisObj, 'txtFANCY') === undefined ? 0 : getValue(thisObj, 'txtFANCY') === '' ? 0 : getValue(thisObj, 'txtFANCY') === null ? 0 : Number(getValue(thisObj, 'txtFANCY'))
      let bluepanwt = getValue(thisObj, 'txtBluePanWt') === undefined ? 0 : getValue(thisObj, 'txtBluePanWt') === '' ? 0 : getValue(thisObj, 'txtBluePanWt') === null ? 0 : Number(getValue(thisObj, 'txtBluePanWt'))
      let meterReading = getValue(thisObj, 'txtMeterReading') === undefined ? 0 : getValue(thisObj, 'txtMeterReading') === '' ? 0 : getValue(thisObj, 'txtMeterReading') === null ? 0 : Number(getValue(thisObj, 'txtMeterReading'))
      let kernelRps = getValue(thisObj, 'txtKernelsRPS') === undefined ? 0 : getValue(thisObj, 'txtKernelsRPS') === '' ? 0 : getValue(thisObj, 'txtKernelsRPS') === null ? 0 : Number(getValue(thisObj, 'txtKernelsRPS'))
      let kernelsRel = getValue(thisObj, 'txtKernelsREL') === undefined ? 0 : getValue(thisObj, 'txtKernelsREL') === '' ? 0 : getValue(thisObj, 'txtKernelsREL') === null ? 0 : Number(getValue(thisObj, 'txtKernelsREL'))
      let elkDam = getValue(thisObj, 'txtELKDamage') === undefined ? 0 : getValue(thisObj, 'txtELKDamage') === '' ? 0 : getValue(thisObj, 'txtELKDamage') === null ? 0 : Number(getValue(thisObj, 'txtELKDamage'))
      let damKrs = getValue(thisObj, 'txtDamagedKRS') === undefined ? 0 : getValue(thisObj, 'txtDamagedKRS') === '' ? 0 : getValue(thisObj, 'txtDamagedKRS') === null ? 0 : Number(getValue(thisObj, 'txtDamagedKRS'))
      let vlcClnSampleWt = getValue(thisObj, 'txtValenciaCleanSampleWt') === undefined ? 0 : getValue(thisObj, 'txtValenciaCleanSampleWt') === '' ? 0 : getValue(thisObj, 'txtValenciaCleanSampleWt') === null ? 0 : Number(getValue(thisObj, 'txtValenciaCleanSampleWt'))
      let wtIncludingVehicle = getValue(thisObj, 'txtWtIncludingVehicle') === undefined ? 0 : getValue(thisObj, 'txtWtIncludingVehicle') === '' ? 0 : getValue(thisObj, 'txtWtIncludingVehicle') === null ? 0 : Number(getValue(thisObj, 'txtWtIncludingVehicle'))
      let wtOfVehcle = getValue(thisObj, 'txtWtOfVehicle') === undefined ? 0 : getValue(thisObj, 'txtWtOfVehicle') === '' ? 0 : getValue(thisObj, 'txtWtOfVehicle') === null ? 0 : Number(getValue(thisObj, 'txtWtOfVehicle'))
      let grossWeight = getValue(thisObj, 'txtGrossWeight') === undefined ? 0 : getValue(thisObj, 'txtGrossWeight') === '' ? 0 : getValue(thisObj, 'txtGrossWeight') === null ? 0 : Number(getValue(thisObj, 'txtGrossWeight'))
      let otherForeignMaterial = getValue(thisObj, 'txtOtherForeignMaterials') === undefined ? "" : getValue(thisObj, 'txtOtherForeignMaterials') === '' ? "" : getValue(thisObj, 'txtOtherForeignMaterials')
      let LblExcelssOfMoisture = getValue(thisObj, 'txtExcessMoisture') === undefined ? 0 : getValue(thisObj, 'txtExcessMoisture') === '' ? 0 : getValue(thisObj, 'txtExcessMoisture') === null ? 0 : Number(getValue(thisObj, 'txtExcessMoisture'))
      let lblNetWeight = getValue(thisObj, 'txtNetWeight') === undefined ? 0 : getValue(thisObj, 'txtNetWeight') === '' ? 0 : getValue(thisObj, 'txtNetWeight') === null ? 0 : Number(getValue(thisObj, 'txtNetWeight'))
      let scalelist = getValue(thisObj, 'txtEWeightTkt') === undefined ? "" : getValue(thisObj, 'txtEWeightTkt') === '' ? "" : getValue(thisObj, 'txtEWeightTkt') === null ? "" : getValue(thisObj, 'txtEWeightTkt')
      let vechlist = getValue(thisObj, 'txtEVehicleNum') === undefined ? "" : getValue(thisObj, 'txtEVehicleNum') === '' ? "" : getValue(thisObj, 'txtEVehicleNum') === null ? "" : getValue(thisObj, 'txtEVehicleNum')
      let wtinclVeh = getValue(thisObj, 'txtEWtInclVeh') === undefined ? 0 : getValue(thisObj, 'txtEWtInclVeh') === '' ? 0 : getValue(thisObj, 'txtEWtInclVeh') === null ? 0 : Number(getValue(thisObj, 'txtEWtInclVeh'))
      let wtOfVeh = getValue(thisObj, 'txtEWtOfVeh') === undefined ? 0 : getValue(thisObj, 'txtEWtOfVeh') === '' ? 0 : getValue(thisObj, 'txtEWtOfVeh') === null ? 0 : Number(getValue(thisObj, 'txtEWtOfVeh'))
      let lblWtOfPnuts = getValue(thisObj, 'txtEWtOfPnut') === undefined ? 0 : getValue(thisObj, 'txtEWtOfPnut') === '' ? 0 : getValue(thisObj, 'txtEWtOfPnut') === null ? 0 : Number(getValue(thisObj, 'txtEWtOfPnut'))
      let typeInspection = "";


      if (getValue(thisObj, 'chkboxResale')) {
        typeInspection = "Resale"
      }

      let sellerNum = getValue(thisObj, 'txtSellerNumber') === undefined ? "" : getValue(thisObj, 'txtSellerNumber') === null ? "" : getValue(thisObj, 'txtSellerNumber')
      let sellerName = getValue(thisObj, 'txtSellerName') === undefined ? "" : getValue(thisObj, 'txtSellerName') === null ? "" : getValue(thisObj, 'txtSellerName').trim()
      let buyerNum = getValue(thisObj, 'txtBuyerNumber') === undefined ? 0 : getValue(thisObj, 'txtBuyerNumber') === null ? 0 : getValue(thisObj, 'txtBuyerNumber')
      let buyerName = getValue(thisObj, 'txtBuyerName') === undefined ? "" : getValue(thisObj, 'txtBuyerName') === '' ? "" : getValue(thisObj, 'txtBuyerName') == null ? "" : getValue(thisObj, 'txtBuyerName').trim()
      let signerName = getValue(thisObj, 'txtSignOfInspector') === undefined ? "" : getValue(thisObj, 'txtSignOfInspector') === null ? "" : getValue(thisObj, 'txtSignOfInspector').trim();
      let cboWeigher = getValue(thisObj, "ddSignOfWeigher", '') === undefined ? "" : getValue(thisObj, 'ddSignOfWeigher') === null ? "" : getValue(thisObj, 'ddSignOfWeigher') === "" ? "" : getValue(thisObj, 'ddSignOfWeigher');
      let lblLsk = getValue(thisObj, 'txtLSKvc') === undefined ? 0 : getValue(thisObj, 'txtLSKvc') === '' ? 0 : getValue(thisObj, 'txtLSKvc') === null ? 0 : Number(getValue(thisObj, 'txtLSKvc'))
      let lblKernelValueTon = getValue(thisObj, 'txtKernelValueTon') === undefined ? 0 : getValue(thisObj, 'txtKernelValueTon') === '' ? 0 : Number(getValue(thisObj, 'txtKernelValueTon'))
      let lblElkPremiumTon = getValue(thisObj, 'txtELKPremiumTon') === undefined ? 0 : getValue(thisObj, 'txtELKPremiumTon') === '' ? 0 : Number(getValue(thisObj, 'txtELKPremiumTon'))
      let lblDamageTon = getValue(thisObj, 'txtDamageTon') === undefined ? 0 : getValue(thisObj, 'txtDamageTon') === '' ? 0 : Number(getValue(thisObj, 'txtDamageTon'))
      let lblexcessFmTon = getValue(thisObj, 'txtAccessFMTon') === undefined ? 0 : getValue(thisObj, 'txtAccessFMTon') === '' ? 0 : Number(getValue(thisObj, 'txtAccessFMTon'))
      let lblExcessSplitsTon = getValue(thisObj, 'txtExcessSplitsTon') === undefined ? 0 : getValue(thisObj, 'txtExcessSplitsTon') === '' ? 0 : Number(getValue(thisObj, 'txtExcessSplitsTon'))
      let fFlavusTon = getValue(thisObj, 'txtAFlavusTon') === undefined ? 0 : getValue(thisObj, 'txtAFlavusTon') === '' ? 0 : Number(getValue(thisObj, 'txtAFlavusTon'))
      let netValuePerTonExcludingLS = getValue(thisObj, 'txtNetValPerTonExclSLK') === undefined ? 0 : getValue(thisObj, 'txtNetValPerTonExclSLK') === '' ? 0 : Number(getValue(thisObj, 'txtNetValPerTonExclSLK'))
      let netPrice = getValue(thisObj, 'txtG') === undefined ? 0 : getValue(thisObj, 'txtG') === '' ? 0 : Number(getValue(thisObj, 'txtG')) / 100;
      let totalValueOfLoad = getValue(thisObj, 'txtTotalLoadVal') === undefined ? 0 : getValue(thisObj, 'txtTotalLoadVal') === '' ? 0 : Number(getValue(thisObj, 'txtTotalLoadVal'))
      let remarks = getValue(thisObj, 'txtarInspectorRemarks') === undefined ? "" : getValue(thisObj, 'txtarInspectorRemarks') === '' ? "" : getValue(thisObj, 'txtarInspectorRemarks') === null ? "" : getValue(thisObj, 'txtarInspectorRemarks')
      let sendBack = "";
      if (getValue(thisObj, "chkboxSendBack")) {
        sendBack = "Y"
      }
      else {
        sendBack = "N"
      }
      const user_id = sessionStorage.getItem('userid')
      let residueInspectiondataObj =
      {
        "revision_num": revision_num,
        "user_id": user_id,
        "insp_date_time": insp_date_time,
        "high_moist_ind": high_moist_ind,
        "pnut_type_id": pnutTypeId,
        "seg_type": segType,
        "edible_oil_ind": edibleOilInd,
        "flavus_ind": flavusInd,
        "probe_pattern": probePattern,
        "fm_sample": fmSample,
        "cln_sample": cleanSampleWt,
        "smk_gr": smkGR,
        "ss_gr": ssGr,
        "ok_gr": okGR,
        "dam_split_gr": damageSplitGr,
        "dam_gr": damageTotalSplits,
        "hull_gr": hullsgr,
        "frez_dam_gr": freezeDamageGr,
        "cncl_rmd_gr": concealedRMDGr,
        "cr_br_gr": crkOrBrkShellsGr,
        "dcol_shel_gr": discoloredShellsGR,
        "hul_brit_gr": hullsBrightnessGr,
        "jumbo_gr": jumboGr,
        "elk_gr": elkGr,
        "fm_gr": fmGr,
        "lsk_gr": lskGr,
        "fan_gr": fancyGr,
        "smk_pct": smkrsPct,
        "ss_pct": sspct,
        "ok_pct": okPct,
        "dam_pct": totalDamagePct,
        "tkc_pct": tkc,
        "hull_pct": hullsPct,
        "frez_dam_pct": fdPct,
        "cncl_rmd_pct": concealedRMDPct,
        "cr_br_pct": crkOrBrkShellsPct,
        "dcol_shel_pct": discoloredShellsPct,
        "hul_brit_pct": hullsBrightnessPct,
        "jumbo_pct": jumboPct,
        "elk_pct": elkPct,
        "fm_pct": fmpct,
        "lsk_pct": lstPct,
        "moist_pct": moistureReading,
        "fan_pct": fancyPct,
        "oth_fm": otherForeignMaterial,
        "hmc_seg": hseg,
        "hmc_fm_pct": hFm,
        "hmc_lsk_pct": hlsk,
        "hmc_moist": hmst,
        "hmc_smk_pct": hsmk,
        "hmc_ss_pct": hss,
        "hmc_ok_pct": hok,
        "hmc_frz_pct": hfrz,
        "hmc_crmd_pct": hcrmd,
        "hmc_dam_pct": hdam,
        "hmc_hull_pct": hHulls,
        "hmc_elk_pct": helk,
        "hmc_fancy_pct": hfancy,
        "bluepan_wt": bluepanwt,
        "meter_read": meterReading,
        "kernel_rps": kernelRps,
        "kernel_rels": kernelsRel,
        "elk_damaged": elkDam,
        "damaged_krs": damKrs,
        "vl_cln_sample": vlcClnSampleWt,
        "tare_wt": wtIncludingVehicle,
        "vehicle_wt": wtOfVehcle,
        "gross_wt": grossWeight,
        "fm_wt": foreignMaterial,
        "ex_moist_wt": LblExcelssOfMoisture,
        "net_wt": lblNetWeight,
        "handler_loc_num_2": buyerNum,
        "handler_loc_name_2": buyerName,
        "signer_name": signerName,
        "inspector_name": signerName,
        "weigher_name": cboWeigher,
        "scale_ticket_list": scalelist,
        "vehicle_id_list": vechlist,
        "e_wt_including_vehicle": wtinclVeh,
        "e_wt_of_vehicle": wtOfVeh,
        "e_wt_of_peanuts": lblWtOfPnuts,
        "type_of_inspection": typeInspection,
        "seller_num": sellerNum,
        "seller_name": sellerName,
        "lsk_wt": lblLsk,
        "kern_value": lblKernelValueTon,
        "elk_prem": lblElkPremiumTon,
        "dam_discount": lblDamageTon,
        "ex_fm_discount": lblexcessFmTon,
        "ex_ss_discount": lblExcessSplitsTon,
        "flavus_discount": fFlavusTon,
        "net_les_lsk_val": netValuePerTonExcludingLS,
        "net_price": netPrice,
        "basis_grade_amt": totalValueOfLoad,
        "remarks": remarks,
        "send_back": sendBack
      }

      let residueInspectionDetailsResponse = await SettlementService.UpdateResidueInspectionDetails(buyptId, inspnum, strReqAction, residueInspectiondataObj);
      if (residueInspectionDetailsResponse.status == 200) {
        if (strReqAction === "ADD") {
          showMessage(thisObj, "Residue Inspection has been successfully added.", true)
        }
        if (strReqAction === "UPDATE") {
          showMessage(thisObj, "Residue Inspection has been successfully updated.", true)
        }

      }
      else if (residueInspectionDetailsResponse.status == 500) {
        showMessage(thisObj, residueInspectionDetailsResponse.message, false)
        return false;
      }
      else {
        showMessage(thisObj, "Something went wrong!", false)
        return false;
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception occured in updateResidue"
        )
      }
      return false;
    }
    return true;
  };
  const EditForm = async (loadType) => {
    setLoading(true)
    setValue(thisObj, 'ddLocation', loadType.C_ORG_BUY_POINT)
    setValue(thisObj, 'txtSC95', loadType.C_FV95_NUM)
    let EditForm = false;
    try {
      let buyingPointId = loadType.C_ORG_BUY_POINT;
      let strFV95Num = loadType.C_FV95_NUM
      if (buyingPointId !== "" && buyingPointId !== undefined) {
        let retrieveInspectHeaderResidueDetails = await SettlementService.RetrieveInspectHeaderResidueDetails(buyingPointId, strFV95Num);
        if (retrieveInspectHeaderResidueDetails.length > 0) {
          setValue(thisObj, 'ddLocation', buyingPointId)
          setValue(thisObj, 'txtSC95', retrieveInspectHeaderResidueDetails[0].insp_num.trim())
          //setData(thisObj, 'datRevisionNumber', retrieveInspectHeaderResidueDetails[0].revision_num)
          setRevNo(Number(retrieveInspectHeaderResidueDetails[0].revision_num))
          setValue(thisObj, 'txtInspectionDateTime', moment(Date.parse(retrieveInspectHeaderResidueDetails[0].insp_date_time)).format('MM-DD-YYYY hh:mm a'))
          setValue(thisObj, 'txt1007', retrieveInspectHeaderResidueDetails[0].settlement_num)
          setValue(thisObj, 'txtSettlementDate', retrieveInspectHeaderResidueDetails[0].settlement_date == '0001-01-01T00:00:00' ? null : moment(Date.parse(retrieveInspectHeaderResidueDetails[0].settlement_date)).format('MM-DD-YYYY hh:mm a'))
          setValue(thisObj, 'txtN', retrieveInspectHeaderResidueDetails[0].elec_upload.trim())
          if (retrieveInspectHeaderResidueDetails[0].elec_upload.trim().toUpperCase() == 'Y') {
            disable(thisObj, 'radioHighMoisture')
            disable(thisObj, 'ddPeanutType')
            //disable(thisObj, "grpbxGradeCalculation")
            setdisableGradeCalculationsBoolean(true)
            setData(thisObj, "setdisableGradeCalculationsBoolean", true)

          }
          else {
            enable(thisObj, 'radioHighMoisture')
            enable(thisObj, 'ddPeanutType')
            //enable(thisObj, "grpbxGradeCalculation")
            setdisableGradeCalculationsBoolean(false)
            setData(thisObj, "setdisableGradeCalculationsBoolean", false)
          }

          //Signature binding
          setValue(thisObj, 'lblAddedByValue', retrieveInspectHeaderResidueDetails[0].add_user + " " + moment(Date.parse(retrieveInspectHeaderResidueDetails[0].add_date_time)).format('MM/DD/YYYY hh:mm A'))
          setValue(thisObj, 'lblChangedByValue', retrieveInspectHeaderResidueDetails[0].chg_user + " " + moment(Date.parse(retrieveInspectHeaderResidueDetails[0].chg_date_time)).format('MM/DD/YYYY hh:mm A'))



          setValue(thisObj, 'txtEWeightTkt', retrieveInspectHeaderResidueDetails[0].inspect_scale_tickets[0].dry_ticket)
          setValue(thisObj, 'txtEVehicleNum', retrieveInspectHeaderResidueDetails[0].inspect_scale_tickets[0].dry_vehicle_id)
          setValue(thisObj, 'txtEWtInclVeh', (Math.round(retrieveInspectHeaderResidueDetails[0].e_wt_including_vehicle)).toString())
          setValue(thisObj, 'txtEWtOfVeh', (Math.round(retrieveInspectHeaderResidueDetails[0].e_wt_of_vehicle)).toString())
          setValue(thisObj, 'txtEWtOfPnut', (Math.round(retrieveInspectHeaderResidueDetails[0].e_wt_of_peanuts)).toString())





          let txtEWtInclVeh = Number(retrieveInspectHeaderResidueDetails[0].e_wt_including_vehicle);
          let txtEWtOfVeh = Number(retrieveInspectHeaderResidueDetails[0].e_wt_of_vehicle);
          if (IsNumeric.test(txtEWtInclVeh) && IsNumeric.test(txtEWtOfVeh)) {
            setValue(thisObj, 'txtEWtOfPnut', Math.round(Number(txtEWtInclVeh) - Number(txtEWtOfVeh)))
          }

          //Radio button himoisture
          if (retrieveInspectHeaderResidueDetails[0].hmc_seg.trim() == "" || retrieveInspectHeaderResidueDetails[0].hmc_seg.trim() == null) {
            setValue(thisObj, 'radioHighMoisture', '2')
          }
          else {
            setValue(thisObj, 'radioHighMoisture', '1')
          }

          // ddPeanutType dropDown binding

          if (retrieveInspectHeaderResidueDetails[0].pnut_type_id != null && retrieveInspectHeaderResidueDetails[0].pnut_type_id != "" && retrieveInspectHeaderResidueDetails[0].pnut_type_id != undefined) {
            setValue(thisObj, 'ddPeanutType', retrieveInspectHeaderResidueDetails[0].pnut_type_id)
          }

          // ddEdibleOil dropdwon binding
          if (retrieveInspectHeaderResidueDetails[0].edible_oil_ind != null && retrieveInspectHeaderResidueDetails[0].edible_oil_ind != "" && retrieveInspectHeaderResidueDetails[0].edible_oil_ind != undefined) {
            setValue(thisObj, 'ddEdibleOil', retrieveInspectHeaderResidueDetails[0].edible_oil_ind)
          }

          //Grade Calculation

          setValue(thisObj, 'txtarInspectorRemarks', retrieveInspectHeaderResidueDetails[0].remarks)
          setValue(thisObj, 'txtFMSampleWt', (retrieveInspectHeaderResidueDetails[0].fm_sample).toFixed(1))
          setValue(thisObj, 'txtCleanSampleWt', Number(retrieveInspectHeaderResidueDetails[0].cln_sample).toFixed(1))
          setValue(thisObj, 'txtProbingPattern', retrieveInspectHeaderResidueDetails[0].probe_pattern)
          setValue(thisObj, 'txtValenciaCleanSampleWt', Math.round(retrieveInspectHeaderResidueDetails[0].vl_cln_sample).toFixed(2))
          setValue(thisObj, 'txtSMKRSGr', (retrieveInspectHeaderResidueDetails[0].smk_gr).toFixed(2))
          setValue(thisObj, 'txtSoundSplitsGr', Math.round(retrieveInspectHeaderResidueDetails[0].ss_gr).toFixed(2))
          setValue(thisObj, 'txtOtherKernelsGr', Number(retrieveInspectHeaderResidueDetails[0].ok_gr).toFixed(2))
          setValue(thisObj, 'txtDamageSplits', Math.round(retrieveInspectHeaderResidueDetails[0].dam_split_gr).toFixed(2))
          setValue(thisObj, 'txtTotalDamageGr', Number(retrieveInspectHeaderResidueDetails[0].dam_gr).toFixed(2))
          setValue(thisObj, 'txtFreezeDamageGr', Math.round(retrieveInspectHeaderResidueDetails[0].frez_dam_gr).toFixed(2))
          setValue(thisObj, 'txtConcealedRMDGr', Math.round(retrieveInspectHeaderResidueDetails[0].cncl_rmd_gr).toFixed(2))
          setValue(thisObj, 'txtKernelsREL', Math.round(retrieveInspectHeaderResidueDetails[0].kernel_rels).toFixed(2))
          setValue(thisObj, 'txtKernelsRPS', Math.round(retrieveInspectHeaderResidueDetails[0].kernel_rps).toFixed(2))
          setValue(thisObj, 'txtHullsGr', Number(retrieveInspectHeaderResidueDetails[0].hull_gr).toFixed(2))
          setValue(thisObj, 'txtDamagedKRS', Math.round(retrieveInspectHeaderResidueDetails[0].damaged_krs).toFixed(2))
          setValue(thisObj, 'txtCrkOrBrkShellsGr', Math.round(retrieveInspectHeaderResidueDetails[0].cr_br_gr).toFixed(2))
          setValue(thisObj, 'txtCrkOrBrkShellsPct', Math.round(retrieveInspectHeaderResidueDetails[0].cr_br_pct).toFixed(2))
          setValue(thisObj, 'txtDiscoloredShellsGr', Math.round(retrieveInspectHeaderResidueDetails[0].dcol_shel_gr))
          setValue(thisObj, 'txtDiscoloredShellsPct', Math.round(retrieveInspectHeaderResidueDetails[0].dcol_shel_pct).toFixed(2))
          setValue(thisObj, 'txtHullBrightness', Math.round(retrieveInspectHeaderResidueDetails[0].hul_brit_gr))
          setValue(thisObj, 'txtHullBrightnessPct', Math.round(retrieveInspectHeaderResidueDetails[0].hul_brit_pct).toFixed(2))
          setValue(thisObj, 'txtJumboGr', Math.round(retrieveInspectHeaderResidueDetails[0].jumbo_gr).toFixed(2))
          setValue(thisObj, 'txtJumboPct', Math.round(retrieveInspectHeaderResidueDetails[0].jumbo_pct).toFixed(2))
          setValue(thisObj, 'txtELKGr', Math.round(retrieveInspectHeaderResidueDetails[0].elk_gr).toFixed(2))
          setValue(thisObj, 'txtForeignMaterialGr', Number(retrieveInspectHeaderResidueDetails[0].fm_gr).toFixed(2))
          setValue(thisObj, 'txtOtherForeignMaterials', retrieveInspectHeaderResidueDetails[0].oth_fm)
          setValue(thisObj, 'txtLSKGr', Math.round(retrieveInspectHeaderResidueDetails[0].lsk_gr).toFixed(2))
          setValue(thisObj, 'txtMeterReading', Number(retrieveInspectHeaderResidueDetails[0].meter_read).toFixed(2))
          setValue(thisObj, 'txtBluePanWt', Math.round(retrieveInspectHeaderResidueDetails[0].bluepan_wt).toFixed(2))
          setValue(thisObj, 'txtFancyGr', Math.round(retrieveInspectHeaderResidueDetails[0].fan_gr).toFixed(2))
          setValue(thisObj, 'txtELKDamage', Math.round(retrieveInspectHeaderResidueDetails[0].elk_damaged).toFixed(2))
          setValue(thisObj, 'txtTKC', Math.round(retrieveInspectHeaderResidueDetails[0].tkc_pct).toFixed(2))
          setValue(thisObj, 'txtWtIncludingVehicle', retrieveInspectHeaderResidueDetails[0].tare_wt)
          setValue(thisObj, 'txtWtOfVehicle', retrieveInspectHeaderResidueDetails[0].vehicle_wt)
          setValue(thisObj, 'txtGrossWeight', retrieveInspectHeaderResidueDetails[0].gross_wt)
          setValue(thisObj, 'txtForeignMaterial', retrieveInspectHeaderResidueDetails[0].fm_wt)
          setValue(thisObj, 'txtExcessMoisture', retrieveInspectHeaderResidueDetails[0].ex_moist_wt)
          setValue(thisObj, 'txtNetWeight', retrieveInspectHeaderResidueDetails[0].net_wt)
          setValue(thisObj, 'txtLSKvc', retrieveInspectHeaderResidueDetails[0].lsk_wt)
          setValue(thisObj, 'txtKernelValueTon', retrieveInspectHeaderResidueDetails[0].kern_value)
          setValue(thisObj, 'txtELKPremiumTon', retrieveInspectHeaderResidueDetails[0].elk_prem)
          setValue(thisObj, 'txtDamageTon', retrieveInspectHeaderResidueDetails[0].dam_discount)
          setValue(thisObj, 'txtAccessFMTon', retrieveInspectHeaderResidueDetails[0].ex_fm_discount)
          setValue(thisObj, 'txtExcessSplitsTon', retrieveInspectHeaderResidueDetails[0].ex_ss_discount)
          setValue(thisObj, 'txtAFlavusTon', retrieveInspectHeaderResidueDetails[0].flavus_discount)
          setValue(thisObj, 'txtNetValPerTonExclSLK', retrieveInspectHeaderResidueDetails[0].net_les_lsk_val)
          setValue(thisObj, 'txtTotalLoadVal', retrieveInspectHeaderResidueDetails[0].basis_grade_amt)
          setValue(thisObj, 'txtG', Number(retrieveInspectHeaderResidueDetails[0].net_price) * 100)


          // Re-sale check box binding based on inspection type
          if (retrieveInspectHeaderResidueDetails[0].type_of_inspection == "") {
            setValue(thisObj, 'chkboxResale', true)
          }
          else {
            setValue(thisObj, 'chkboxResale', false)
          }

          setValue(thisObj, 'txtSellerNumber', retrieveInspectHeaderResidueDetails[0].seller_num)
          setValue(thisObj, 'txtSellerName', retrieveInspectHeaderResidueDetails[0].seller_name)
          setValue(thisObj, 'txtBuyerNumber', retrieveInspectHeaderResidueDetails[0].handler_loc_num_2)
          setValue(thisObj, 'txtBuyerName', retrieveInspectHeaderResidueDetails[0].handler_loc_name_2)
          setValue(thisObj, 'txtSignOfInspector', retrieveInspectHeaderResidueDetails[0].signer_name)


          //Weigher drop down binding
          if (retrieveInspectHeaderResidueDetails[0].weigher_name != null && retrieveInspectHeaderResidueDetails[0].weigher_name != undefined && retrieveInspectHeaderResidueDetails[0].weigher_name != "") {
            setValue(thisObj, 'ddSignOfWeigher', retrieveInspectHeaderResidueDetails[0].weigher_name)
          }

          let mdblMaxMoistPct = Number(retrieveInspectHeaderResidueDetails[0].max_moist)
          if (retrieveInspectHeaderResidueDetails[0].max_moist <= 0) {
            mdblMaxMoistPct = 7;
          }

          //chkboxFlavusFound binding
          if (retrieveInspectHeaderResidueDetails[0].flavus_ind == 'Y' || retrieveInspectHeaderResidueDetails[0].flavus_ind == 'y') {
            setValue(thisObj, 'chkboxFlavusFound', true)
          }
          else {
            setValue(thisObj, 'chkboxFlavusFound', false)
          }

          let grossWt = getValue(thisObj, 'txtGrossWeight') === undefined ? 0 : getValue(thisObj, 'txtGrossWeight') === null ? 0 : getValue(thisObj, 'txtGrossWeight') === "" ? 0 : Number(getValue(thisObj, 'txtGrossWeight'))
          let frmtrls = getValue(thisObj, 'txtForeignMaterial') === undefined ? 0 : getValue(thisObj, 'txtForeignMaterial') === null ? 0 : getValue(thisObj, 'txtForeignMaterial') === "" ? 0 : Number(getValue(thisObj, 'txtForeignMaterial'))
          if (IsNumeric.test(grossWt) && IsNumeric.test(frmtrls)) {
            let wtlesfm = Number(grossWt) - Number(frmtrls)
            setValue(thisObj, 'txtWtlessFM', wtlesfm)
            if (wtlesfm <= 0) {
              setValue(thisObj, 'txtWtlessFM', 0)
            }
          }


          //ddSeg dropdown binding
          let opt_hmYESNO = retrieveInspectHeaderResidueDetails[0].hmc_seg === null ? "2" : retrieveInspectHeaderResidueDetails[0].hmc_seg === "" ? "2" : retrieveInspectHeaderResidueDetails[0].hmc_seg === undefined ? "2" : "1";
          if (opt_hmYESNO == "1") {
            if (retrieveInspectHeaderResidueDetails[0].hmc_seg != "" && retrieveInspectHeaderResidueDetails[0].hmc_seg != "1" && retrieveInspectHeaderResidueDetails[0].hmc_seg != "2" && retrieveInspectHeaderResidueDetails[0].hmc_seg != "3" && retrieveInspectHeaderResidueDetails[0].hmc_seg != "X") {
              setValue(thisObj, 'ddSeg', retrieveInspectHeaderResidueDetails[0].hmc_seg)
            }
            setValue(thisObj, 'ddSeg', retrieveInspectHeaderResidueDetails[0].hmc_seg) //required even if above if condition fails

            setValue(thisObj, 'txtSMKRSPct', Math.round(retrieveInspectHeaderResidueDetails[0].smk_pct).toFixed(2))
            setValue(thisObj, 'txtSoundSplitsPct', Math.round(retrieveInspectHeaderResidueDetails[0].ss_pct).toFixed(2))
            setValue(thisObj, 'txtOtherKernelsPct', Math.round(retrieveInspectHeaderResidueDetails[0].ok_pct).toFixed(2))
            setValue(thisObj, 'txtTotalDamagePct', Math.round(retrieveInspectHeaderResidueDetails[0].dam_pct).toFixed(2))
            setValue(thisObj, 'txtFreezeDamagePct', Math.round(retrieveInspectHeaderResidueDetails[0].frez_dam_pct).toFixed(2))
            setValue(thisObj, 'txtConcealedRMDPct', Math.round(retrieveInspectHeaderResidueDetails[0].cncl_rmd_pct).toFixed(2))
            setValue(thisObj, 'txtHullsPct', Math.round(retrieveInspectHeaderResidueDetails[0].hull_pct).toFixed(2))
            setValue(thisObj, 'txtELKPct', Math.round(retrieveInspectHeaderResidueDetails[0].elk_pct).toFixed(2))
            setValue(thisObj, 'txtForeignMaterialPct', Math.round(retrieveInspectHeaderResidueDetails[0].fm_pct).toFixed(2))
            setValue(thisObj, 'txtLSKPct', Math.round(retrieveInspectHeaderResidueDetails[0].lsk_pct).toFixed(2))
            setValue(thisObj, 'txtMoisture', Math.round(retrieveInspectHeaderResidueDetails[0].moist_pct).toFixed(2))
            setValue(thisObj, 'txtFancyPct', Math.round(retrieveInspectHeaderResidueDetails[0].fan_pct).toFixed(2))



            let totalSMk = Number(retrieveInspectHeaderResidueDetails[0].smk_pct) + Number(retrieveInspectHeaderResidueDetails[0].ss_pct);
            setValue(thisObj, 'txtTotalSMK', Math.round(totalSMk).toFixed(2))
            let totalKernals = Number(retrieveInspectHeaderResidueDetails[0].smk_pct) + Number(retrieveInspectHeaderResidueDetails[0].ok_pct) + Number(retrieveInspectHeaderResidueDetails[0].dam_pct) + Number(retrieveInspectHeaderResidueDetails[0].ss_pct);
            let totalKernalsandHulls = Number(totalKernals) + Number(retrieveInspectHeaderResidueDetails[0].hull_pct)
            setValue(thisObj, 'txtTotalKrnls', Math.round(totalKernals).toFixed(2))
            setValue(thisObj, 'txtTotalKernelsHulls', Math.round(totalKernalsandHulls).toFixed(2))

            //Convert Grade binding
            setValue(thisObj, 'txtSeg', Math.round(retrieveInspectHeaderResidueDetails[0].seg_type).toFixed(2))
            setValue(thisObj, 'txtFM', Math.round(retrieveInspectHeaderResidueDetails[0].hfm_pct).toFixed(2))
            setValue(thisObj, 'txtLSK', Math.round(retrieveInspectHeaderResidueDetails[0].hlsk_pct).toFixed(2))
            setValue(thisObj, 'txtMST', Math.round(retrieveInspectHeaderResidueDetails[0].hmoist_pct).toFixed(2))
            setValue(thisObj, 'txtSMK', Math.round(retrieveInspectHeaderResidueDetails[0].hsmk_pct).toFixed(2))
            setValue(thisObj, 'txtSS', Math.round(retrieveInspectHeaderResidueDetails[0].hss_pct).toFixed(2))
            setValue(thisObj, 'txtOK', Math.round(retrieveInspectHeaderResidueDetails[0].hok_pct).toFixed(2))
            setValue(thisObj, 'txtFRZ', Math.round(retrieveInspectHeaderResidueDetails[0].hfrez_dam_pct).toFixed(2))
            setValue(thisObj, 'txtDAM', Math.round(retrieveInspectHeaderResidueDetails[0].hdam_pct).toFixed(2))
            setValue(thisObj, 'txtHULLS', Math.round(retrieveInspectHeaderResidueDetails[0].hhull_pct).toFixed(2))
            setValue(thisObj, 'txtELK', Math.round(retrieveInspectHeaderResidueDetails[0].helk_pct).toFixed(2))
            setValue(thisObj, 'txtFANCY', Math.round(retrieveInspectHeaderResidueDetails[0].hfan_pct).toFixed(2))

            let txtSMKRS = Number(retrieveInspectHeaderResidueDetails[0].hsmk_pct) + Number(retrieveInspectHeaderResidueDetails[0].hss_pct);
            setValue(thisObj, 'txtSMKRS', Math.round(txtSMKRS).toFixed(2))

            let txtTotalKernels = Number(txtSMKRS) + Number(retrieveInspectHeaderResidueDetails[0].hdam_pct) + Number(retrieveInspectHeaderResidueDetails[0].hok_pct)
            setValue(thisObj, 'txtTotalKernels', Math.round(txtTotalKernels).toFixed(2))

            let txtTotKrnlsHulls = Number(txtTotalKernels) + Number(retrieveInspectHeaderResidueDetails[0].hhull_pct)
            setValue(thisObj, 'txtTotKrnlsHulls', Math.round(txtTotKrnlsHulls).toFixed(2))
          }

          else {

            if (retrieveInspectHeaderResidueDetails[0].seg_type.trim() != "" && retrieveInspectHeaderResidueDetails[0].seg_type.trim() != "1" && retrieveInspectHeaderResidueDetails[0].seg_type.trim() != "2" && retrieveInspectHeaderResidueDetails[0].seg_type.trim() != "3" && retrieveInspectHeaderResidueDetails[0].seg_type.trim() != "X") {
              let js = []
              js.push({ key: retrieveInspectHeaderResidueDetails[0].seg_type, description: retrieveInspectHeaderResidueDetails[0].seg_type })
              thisObj.setState(current => {
                return {
                  ...current,
                  ddSeg: {
                    ...state["ddSeg"],
                    valueList: js
                  }
                }
              })
              setValue(thisObj, 'ddSeg', retrieveInspectHeaderResidueDetails[0].seg_type)
            }
            setValue(thisObj, 'ddSeg', retrieveInspectHeaderResidueDetails[0].seg_type) //required even if above if condition fails

            setValue(thisObj, 'txtSMKRSPct', Math.round(retrieveInspectHeaderResidueDetails[0].smk_pct).toFixed(2))
            setValue(thisObj, 'txtSoundSplitsPct', Math.round(retrieveInspectHeaderResidueDetails[0].ss_pct).toFixed(2))
            setValue(thisObj, 'txtOtherKernelsPct', Math.round(retrieveInspectHeaderResidueDetails[0].ok_pct).toFixed(2))
            setValue(thisObj, 'txtTotalDamagePct', Math.round(retrieveInspectHeaderResidueDetails[0].dam_pct).toFixed(2))
            setValue(thisObj, 'txtFreezeDamagePct', Math.round(retrieveInspectHeaderResidueDetails[0].frez_dam_pct).toFixed(2))
            setValue(thisObj, 'txtConcealedRMDPct', Math.round(retrieveInspectHeaderResidueDetails[0].cncl_rmd_pct).toFixed(2))
            setValue(thisObj, 'txtHullsPct', Math.round(retrieveInspectHeaderResidueDetails[0].hull_pct).toFixed(2))
            setValue(thisObj, 'txtELKPct', Math.round(retrieveInspectHeaderResidueDetails[0].elk_pct).toFixed(2))
            setValue(thisObj, 'txtForeignMaterialPct', Math.round(retrieveInspectHeaderResidueDetails[0].fm_pct).toFixed(2))
            setValue(thisObj, 'txtLSKPct', Math.round(retrieveInspectHeaderResidueDetails[0].lsk_pct).toFixed(2))
            setValue(thisObj, 'txtMoisture', Math.round(retrieveInspectHeaderResidueDetails[0].moist_pct).toFixed(2))
            setValue(thisObj, 'txtFancyPct', Math.round(retrieveInspectHeaderResidueDetails[0].fan_pct).toFixed(2))

            let totalSMk = Number(retrieveInspectHeaderResidueDetails[0].smk_pct) + Number(retrieveInspectHeaderResidueDetails[0].ss_pct);
            setValue(thisObj, 'txtTotalSMK', Math.round(totalSMk).toFixed(2))
            let totalKernals = Number(retrieveInspectHeaderResidueDetails[0].smk_pct) + Number(retrieveInspectHeaderResidueDetails[0].ok_pct) + Number(retrieveInspectHeaderResidueDetails[0].dam_pct) + Number(retrieveInspectHeaderResidueDetails[0].ss_pct);
            let totalKernalsandHulls = Number(totalKernals) + Number(retrieveInspectHeaderResidueDetails[0].hull_pct)
            setValue(thisObj, 'txtTotalKrnls', Math.round(totalKernals).toFixed(2))
            setValue(thisObj, 'txtTotalKernelsHulls', Math.round(totalKernalsandHulls).toFixed(2))

            //Clearing Convert Grade HMc
            clearValue(thisObj, 'txtSeg')
            clearValue(thisObj, 'txtFM')
            clearValue(thisObj, 'txtLSK')
            clearValue(thisObj, 'txtMST')
            clearValue(thisObj, 'txtSMK')
            clearValue(thisObj, 'txtSS')
            clearValue(thisObj, 'txtOK')
            clearValue(thisObj, 'txtFRZ')
            clearValue(thisObj, 'txtCRMD')
            clearValue(thisObj, 'txtDAM')
            clearValue(thisObj, 'txtHULLS')
            clearValue(thisObj, 'txtELK')
            clearValue(thisObj, 'txtFANCY')
            clearValue(thisObj, 'txtSMKRS')
            clearValue(thisObj, 'txtTotalKernels')
            clearValue(thisObj, 'txtTotKrnlsHulls')
          }

          if (opt_hmYESNO == "2") {
            let weightincludingvehicle = Number(retrieveInspectHeaderResidueDetails[0].e_wt_including_vehicle)
            let weightofvehicle = Number(retrieveInspectHeaderResidueDetails[0].e_wt_of_vehicle)
            let moisturepct = Number(retrieveInspectHeaderResidueDetails[0].moist_pct)
            let cr_br_pct = Number(retrieveInspectHeaderResidueDetails[0].cr_br_pct)
            let dcol_shel_pct = Number(retrieveInspectHeaderResidueDetails[0].dcol_shel_pct)
            let fan_pct = Number(retrieveInspectHeaderResidueDetails[0].fan_pct)
            let foreignmaterialpct = Number(retrieveInspectHeaderResidueDetails[0].fm_pct)
            let lskpct = Number(retrieveInspectHeaderResidueDetails[0].lsk_pct)
            let smk = Number(retrieveInspectHeaderResidueDetails[0].smk_pct)
            let sspct = Number(retrieveInspectHeaderResidueDetails[0].ss_pct)
            let okpct = Number(retrieveInspectHeaderResidueDetails[0].ok_pct)
            let elkpct = Number(retrieveInspectHeaderResidueDetails[0].elk_pct)
            let dam_pct = Number(retrieveInspectHeaderResidueDetails[0].dam_pct)
            let areaId = retrieveInspectHeaderResidueDetails[0].area_id
            let pnut_type_id = retrieveInspectHeaderResidueDetails[0].pnut_type_id
            CalculateValues(areaId, pnut_type_id, weightincludingvehicle, weightofvehicle, moisturepct, cr_br_pct,
              dcol_shel_pct, fan_pct, foreignmaterialpct, lskpct, smk, sspct, okpct, elkpct, dam_pct);
          }
          else {
            let weightincludingvehicle = Number(retrieveInspectHeaderResidueDetails[0].e_wt_including_vehicle)
            let weightofvehicle = Number(retrieveInspectHeaderResidueDetails[0].e_wt_of_vehicle)
            let moisturepct = Number(retrieveInspectHeaderResidueDetails[0].moist_pct)
            let cr_br_pct = Number(retrieveInspectHeaderResidueDetails[0].cr_br_pct)
            let dcol_shel_pct = Number(retrieveInspectHeaderResidueDetails[0].dcol_shel_pct)
            let fancy = Number(retrieveInspectHeaderResidueDetails[0].hfan_pct)
            let fm = Number(retrieveInspectHeaderResidueDetails[0].hfm_pct)
            let lsk = Number(retrieveInspectHeaderResidueDetails[0].hlsk_pct)
            let smk = Number(retrieveInspectHeaderResidueDetails[0].hsmk_pct)
            let ss = Number(retrieveInspectHeaderResidueDetails[0].hss_pct)
            let ok = Number(retrieveInspectHeaderResidueDetails[0].hok_pct)
            let elk = Number(retrieveInspectHeaderResidueDetails[0].helk_pct)
            let dam = Number(retrieveInspectHeaderResidueDetails[0].hdam_pct)

            let areaId = retrieveInspectHeaderResidueDetails[0].area_id
            let pnut_type_id = retrieveInspectHeaderResidueDetails[0].pnut_type_id

            CalculateValues(areaId, pnut_type_id, weightincludingvehicle, weightofvehicle, moisturepct, cr_br_pct,
              dcol_shel_pct, fancy, fm, lsk, smk, ss, ok, elk, dam);
          }
          disable(thisObj, 'ddLocation')
          if (retrieveInspectHeaderResidueDetails[0].settlement_num === "" || retrieveInspectHeaderResidueDetails[0].settlement_num === null || retrieveInspectHeaderResidueDetails[0].settlement_num === 0 || retrieveInspectHeaderResidueDetails[0].settlement_num === undefined) {
            document.getElementById('btnPrint').innerHTML = "Print";
            show(thisObj, 'btnDelete')
          }
          else {
            document.getElementById('btnPrint').innerHTML = "Reprint";
            show(thisObj, 'btnDelete')
          }
          EditForm = true;
        }

      }
    }
    catch (err) {
      setLoading(false)
      EditForm = false;
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception occured in EditForm()"
        )
      }
      return EditForm;
    }
    setLoading(false)
    return EditForm;
  };


  const bFillForm = async () => {
    setLoading(true)
    let bFillForm = false;
    let s1007N = "";
    try {
      let selectedBuyingPointValue = getValue(thisObj, "ddLocation", '')
      let strFV95Num = getValue(thisObj, 'txtSC95').trim()
      if (selectedBuyingPointValue !== "" && selectedBuyingPointValue !== undefined) {
        let retrieveInspectHeaderResidueDetails = await SettlementService.RetrieveInspectHeaderResidueDetails(selectedBuyingPointValue, strFV95Num);
        if (retrieveInspectHeaderResidueDetails.length > 0) {
          setValue(thisObj, 'ddLocation', retrieveInspectHeaderResidueDetails[0].buy_pt_id)
          setValue(thisObj, 'txtSC95', retrieveInspectHeaderResidueDetails[0].insp_num.trim())
          setRevNo(Number(retrieveInspectHeaderResidueDetails[0].revision_num))
          setValue(thisObj, 'txtInspectionDateTime', moment(Date.parse(retrieveInspectHeaderResidueDetails[0].insp_date_time)).format('MM-DD-YYYY hh:mm a'))
          setValue(thisObj, 'txt1007', retrieveInspectHeaderResidueDetails[0].settlement_num)
          s1007N = retrieveInspectHeaderResidueDetails[0].settlement_num
          setData(thisObj, 's1007N', s1007N)
          setValue(thisObj, 'txtSettlementDate', retrieveInspectHeaderResidueDetails[0].settlement_date == '0001-01-01T00:00:00' ? null : moment(Date.parse(retrieveInspectHeaderResidueDetails[0].settlement_date)).format('MM-DD-YYYY hh:mm a'))
          setValue(thisObj, 'txtN', retrieveInspectHeaderResidueDetails[0].elec_upload.trim())
          if (retrieveInspectHeaderResidueDetails[0].elec_upload.trim().toUpperCase() == 'Y') {
            disable(thisObj, 'radioHighMoisture')
            disable(thisObj, 'ddPeanutType')
            //disable(thisObj, "grpbxGradeCalculation")
            setdisableGradeCalculationsBoolean(true)
            setData(thisObj, "setdisableGradeCalculationsBoolean", true)
          }
          else {
            enable(thisObj, 'radioHighMoisture')
            enable(thisObj, 'ddPeanutType')
            //enable(thisObj, "grpbxGradeCalculation")
            setdisableGradeCalculationsBoolean(false)
            setData(thisObj, "setdisableGradeCalculationsBoolean", false)
          }

          //Signature binding
          setValue(thisObj, 'lblAddedByValue', retrieveInspectHeaderResidueDetails[0].add_user + " " + moment(Date.parse(retrieveInspectHeaderResidueDetails[0].add_date_time)).format('MM/DD/YYYY hh:mm A'))
          setValue(thisObj, 'lblChangedByValue', retrieveInspectHeaderResidueDetails[0].chg_user + " " + moment(Date.parse(retrieveInspectHeaderResidueDetails[0].chg_date_time)).format('MM/DD/YYYY hh:mm A'))

          setValue(thisObj, 'txtEWeightTkt', retrieveInspectHeaderResidueDetails[0].inspect_scale_tickets[0].dry_ticket)
          setValue(thisObj, 'txtEVehicleNum', retrieveInspectHeaderResidueDetails[0].inspect_scale_tickets[0].dry_vehicle_id)
          setValue(thisObj, 'txtEWtInclVeh', (Math.round(retrieveInspectHeaderResidueDetails[0].e_wt_including_vehicle)).toString())
          setValue(thisObj, 'txtEWtOfVeh', (Math.round(retrieveInspectHeaderResidueDetails[0].e_wt_of_vehicle)).toString())
          setValue(thisObj, 'txtEWtOfPnut', (Math.round(retrieveInspectHeaderResidueDetails[0].e_wt_of_peanuts)).toString())


          let txtEWtInclVeh = Number(retrieveInspectHeaderResidueDetails[0].e_wt_including_vehicle);
          let txtEWtOfVeh = Number(retrieveInspectHeaderResidueDetails[0].e_wt_of_vehicle);
          if (IsNumeric.test(txtEWtInclVeh) && IsNumeric.test(txtEWtOfVeh)) {
            setValue(thisObj, 'txtEWtOfPnut', (Math.round(Number(txtEWtInclVeh) - Number(txtEWtOfVeh))).toString())
          }

          //Radio button himoisture
          if (retrieveInspectHeaderResidueDetails[0].hmc_seg.trim() == "" || retrieveInspectHeaderResidueDetails[0].hmc_seg.trim() == null) {
            setValue(thisObj, 'radioHighMoisture', '2')
          }
          else {
            setValue(thisObj, 'radioHighMoisture', '1')
          }

          // ddPeanutType dropDown binding

          if (retrieveInspectHeaderResidueDetails[0].pnut_type_id != null && retrieveInspectHeaderResidueDetails[0].pnut_type_id != "" && retrieveInspectHeaderResidueDetails[0].pnut_type_id != undefined) {
            setValue(thisObj, 'ddPeanutType', retrieveInspectHeaderResidueDetails[0].pnut_type_id)
          }

          // ddEdibleOil dropdwon binding
          if (retrieveInspectHeaderResidueDetails[0].edible_oil_ind != null && retrieveInspectHeaderResidueDetails[0].edible_oil_ind != "" && retrieveInspectHeaderResidueDetails[0].edible_oil_ind != undefined) {
            setValue(thisObj, 'ddEdibleOil', retrieveInspectHeaderResidueDetails[0].edible_oil_ind)
          }

          //Grade Calculation

          setValue(thisObj, 'txtarInspectorRemarks', retrieveInspectHeaderResidueDetails[0].remarks)
          setValue(thisObj, 'txtFMSampleWt', Math.round(retrieveInspectHeaderResidueDetails[0].fm_sample).toFixed(1))
          setValue(thisObj, 'txtCleanSampleWt', Number(retrieveInspectHeaderResidueDetails[0].cln_sample).toFixed(1))
          setValue(thisObj, 'txtProbingPattern', retrieveInspectHeaderResidueDetails[0].probe_pattern)
          setValue(thisObj, 'txtValenciaCleanSampleWt', Math.round(retrieveInspectHeaderResidueDetails[0].vl_cln_sample).toFixed(2))
          setValue(thisObj, 'txtSMKRSGr', (retrieveInspectHeaderResidueDetails[0].smk_gr).toFixed(2))
          setValue(thisObj, 'txtSoundSplitsGr', Math.round(retrieveInspectHeaderResidueDetails[0].ss_gr).toFixed(2))
          setValue(thisObj, 'txtOtherKernelsGr', Number(retrieveInspectHeaderResidueDetails[0].ok_gr).toFixed(2))
          setValue(thisObj, 'txtDamageSplits', Math.round(retrieveInspectHeaderResidueDetails[0].dam_split_gr).toFixed(2))
          setValue(thisObj, 'txtTotalDamageGr', Number(retrieveInspectHeaderResidueDetails[0].dam_gr).toFixed(2))
          setValue(thisObj, 'txtFreezeDamageGr', Math.round(retrieveInspectHeaderResidueDetails[0].frez_dam_gr).toFixed(2))
          setValue(thisObj, 'txtConcealedRMDGr', Math.round(retrieveInspectHeaderResidueDetails[0].cncl_rmd_gr).toFixed(2))
          setValue(thisObj, 'txtKernelsREL', Math.round(retrieveInspectHeaderResidueDetails[0].kernel_rels).toFixed(2))
          setValue(thisObj, 'txtKernelsRPS', Math.round(retrieveInspectHeaderResidueDetails[0].kernel_rps).toFixed(2))
          setValue(thisObj, 'txtHullsGr', Number(retrieveInspectHeaderResidueDetails[0].hull_gr).toFixed(2))
          setValue(thisObj, 'txtDamagedKRS', Math.round(retrieveInspectHeaderResidueDetails[0].damaged_krs).toFixed(2))
          setValue(thisObj, 'txtCrkOrBrkShellsGr', Math.round(retrieveInspectHeaderResidueDetails[0].cr_br_gr).toFixed(2))
          setValue(thisObj, 'txtCrkOrBrkShellsPct', Math.round(retrieveInspectHeaderResidueDetails[0].cr_br_pct).toFixed(2))
          setValue(thisObj, 'txtDiscoloredShellsGr', Math.round(retrieveInspectHeaderResidueDetails[0].dcol_shel_gr))
          setValue(thisObj, 'txtDiscoloredShellsPct', Math.round(retrieveInspectHeaderResidueDetails[0].dcol_shel_pct).toFixed(2))
          setValue(thisObj, 'txtHullBrightness', Math.round(retrieveInspectHeaderResidueDetails[0].hul_brit_gr))
          setValue(thisObj, 'txtHullBrightnessPct', Math.round(retrieveInspectHeaderResidueDetails[0].hul_brit_pct).toFixed(2))
          setValue(thisObj, 'txtJumboGr', Math.round(retrieveInspectHeaderResidueDetails[0].jumbo_gr).toFixed(2))
          setValue(thisObj, 'txtJumboPct', Math.round(retrieveInspectHeaderResidueDetails[0].jumbo_pct).toFixed(2))
          setValue(thisObj, 'txtELKGr', Math.round(retrieveInspectHeaderResidueDetails[0].elk_gr).toFixed(2))
          setValue(thisObj, 'txtForeignMaterialGr', Number(retrieveInspectHeaderResidueDetails[0].fm_gr).toFixed(2))
          setValue(thisObj, 'txtOtherForeignMaterials', retrieveInspectHeaderResidueDetails[0].oth_fm)
          setValue(thisObj, 'txtLSKGr', Math.round(retrieveInspectHeaderResidueDetails[0].lsk_gr).toFixed(2))
          setValue(thisObj, 'txtMeterReading', Number(retrieveInspectHeaderResidueDetails[0].meter_read).toFixed(2))
          setValue(thisObj, 'txtBluePanWt', Math.round(retrieveInspectHeaderResidueDetails[0].bluepan_wt).toFixed(2))
          setValue(thisObj, 'txtFancyGr', Math.round(retrieveInspectHeaderResidueDetails[0].fan_gr).toFixed(2))
          setValue(thisObj, 'txtELKDamage', Math.round(retrieveInspectHeaderResidueDetails[0].elk_damaged).toFixed(2))
          setValue(thisObj, 'txtTKC', Math.round(retrieveInspectHeaderResidueDetails[0].tkc_pct).toFixed(2))
          setValue(thisObj, 'txtWtIncludingVehicle', retrieveInspectHeaderResidueDetails[0].tare_wt)
          setValue(thisObj, 'txtWtOfVehicle', retrieveInspectHeaderResidueDetails[0].vehicle_wt)
          setValue(thisObj, 'txtGrossWeight', retrieveInspectHeaderResidueDetails[0].gross_wt)
          setValue(thisObj, 'txtForeignMaterial', retrieveInspectHeaderResidueDetails[0].fm_wt)
          setValue(thisObj, 'txtExcessMoisture', retrieveInspectHeaderResidueDetails[0].ex_moist_wt)
          setValue(thisObj, 'txtNetWeight', retrieveInspectHeaderResidueDetails[0].net_wt)
          setValue(thisObj, 'txtLSKvc', retrieveInspectHeaderResidueDetails[0].lsk_wt)
          setValue(thisObj, 'txtKernelValueTon', retrieveInspectHeaderResidueDetails[0].kern_value)
          setValue(thisObj, 'txtELKPremiumTon', retrieveInspectHeaderResidueDetails[0].elk_prem)
          setValue(thisObj, 'txtDamageTon', retrieveInspectHeaderResidueDetails[0].dam_discount)
          setValue(thisObj, 'txtAccessFMTon', retrieveInspectHeaderResidueDetails[0].ex_fm_discount)
          setValue(thisObj, 'txtExcessSplitsTon', retrieveInspectHeaderResidueDetails[0].ex_ss_discount)
          setValue(thisObj, 'txtAFlavusTon', retrieveInspectHeaderResidueDetails[0].flavus_discount)
          setValue(thisObj, 'txtNetValPerTonExclSLK', retrieveInspectHeaderResidueDetails[0].net_les_lsk_val)
          setValue(thisObj, 'txtTotalLoadVal', retrieveInspectHeaderResidueDetails[0].basis_grade_amt)
          setValue(thisObj, 'txtG', Number(retrieveInspectHeaderResidueDetails[0].net_price) * 100)


          // Re-sale check box binding based on inspection type
          if (retrieveInspectHeaderResidueDetails[0].type_of_inspection == "") {
            setValue(thisObj, 'chkboxResale', true)
          }
          else {
            setValue(thisObj, 'chkboxResale', false)
          }


          setValue(thisObj, 'txtSellerNumber', retrieveInspectHeaderResidueDetails[0].seller_num)
          setValue(thisObj, 'txtSellerName', retrieveInspectHeaderResidueDetails[0].seller_name)
          setValue(thisObj, 'txtBuyerNumber', retrieveInspectHeaderResidueDetails[0].handler_loc_num_2)
          setValue(thisObj, 'txtBuyerName', retrieveInspectHeaderResidueDetails[0].handler_loc_name_2)
          setValue(thisObj, 'txtSignOfInspector', retrieveInspectHeaderResidueDetails[0].signer_name)


          //Weigher drop down binding
          if (retrieveInspectHeaderResidueDetails[0].weigher_name != null && retrieveInspectHeaderResidueDetails[0].weigher_name != undefined && retrieveInspectHeaderResidueDetails[0].weigher_name != "") {
            setValue(thisObj, 'ddSignOfWeigher', retrieveInspectHeaderResidueDetails[0].weigher_name)
          }

          let mdblMaxMoistPct = Number(retrieveInspectHeaderResidueDetails[0].max_moist)
          if (retrieveInspectHeaderResidueDetails[0].max_moist <= 0) {
            mdblMaxMoistPct = 7;
          }


          //chkboxFlavusFound binding
          if (retrieveInspectHeaderResidueDetails[0].flavus_ind == 'Y' || retrieveInspectHeaderResidueDetails[0].flavus_ind == 'y') {
            setValue(thisObj, 'chkboxFlavusFound', true)
          }
          else {
            setValue(thisObj, 'chkboxFlavusFound', false)
          }

          let grossWt = getValue(thisObj, 'txtGrossWeight') === undefined ? 0 : getValue(thisObj, 'txtGrossWeight') === null ? 0 : getValue(thisObj, 'txtGrossWeight') === "" ? 0 : Number(getValue(thisObj, 'txtGrossWeight'))
          let frmtrls = getValue(thisObj, 'txtForeignMaterial') === undefined ? 0 : getValue(thisObj, 'txtForeignMaterial') === null ? 0 : getValue(thisObj, 'txtForeignMaterial') === "" ? 0 : Number(getValue(thisObj, 'txtForeignMaterial'))
          if (IsNumeric.test(grossWt) && IsNumeric.test(frmtrls)) {
            let wtlesfm = Number(grossWt) - Number(frmtrls)
            setValue(thisObj, 'txtWtlessFM', wtlesfm)
            if (wtlesfm <= 0) {
              setValue(thisObj, 'txtWtlessFM', 0)
            }
          }


          //ddSeg dropdown binding
          let opt_hmYESNO = retrieveInspectHeaderResidueDetails[0].hmc_seg === null ? "2" : retrieveInspectHeaderResidueDetails[0].hmc_seg === "" ? "2" : retrieveInspectHeaderResidueDetails[0].hmc_seg === undefined ? "2" : "1";
          if (opt_hmYESNO == "1") {
            if (retrieveInspectHeaderResidueDetails[0].hmc_seg != "" && retrieveInspectHeaderResidueDetails[0].hmc_seg != "1" && retrieveInspectHeaderResidueDetails[0].hmc_seg != "2" && retrieveInspectHeaderResidueDetails[0].hmc_seg != "3" && retrieveInspectHeaderResidueDetails[0].hmc_seg != "X") {
              setValue(thisObj, 'ddSeg', retrieveInspectHeaderResidueDetails[0].hmc_seg)
            }
            setValue(thisObj, 'ddSeg', retrieveInspectHeaderResidueDetails[0].hmc_seg) //required even if above if condition fails

            setValue(thisObj, 'txtSMKRSPct', Math.round(retrieveInspectHeaderResidueDetails[0].smk_pct).toFixed(2))
            setValue(thisObj, 'txtSoundSplitsPct', Math.round(retrieveInspectHeaderResidueDetails[0].ss_pct).toFixed(2))
            setValue(thisObj, 'txtOtherKernelsPct', Math.round(retrieveInspectHeaderResidueDetails[0].ok_pct).toFixed(2))
            setValue(thisObj, 'txtTotalDamagePct', Math.round(retrieveInspectHeaderResidueDetails[0].dam_pct).toFixed(2))
            setValue(thisObj, 'txtFreezeDamagePct', Math.round(retrieveInspectHeaderResidueDetails[0].frez_dam_pct).toFixed(2))
            setValue(thisObj, 'txtConcealedRMDPct', Math.round(retrieveInspectHeaderResidueDetails[0].cncl_rmd_pct).toFixed(2))
            setValue(thisObj, 'txtHullsPct', Math.round(retrieveInspectHeaderResidueDetails[0].hull_pct).toFixed(2))
            setValue(thisObj, 'txtELKPct', Math.round(retrieveInspectHeaderResidueDetails[0].elk_pct).toFixed(2))
            setValue(thisObj, 'txtForeignMaterialPct', Math.round(retrieveInspectHeaderResidueDetails[0].fm_pct).toFixed(2))
            setValue(thisObj, 'txtLSKPct', Math.round(retrieveInspectHeaderResidueDetails[0].lsk_pct).toFixed(2))
            setValue(thisObj, 'txtMoisture', Math.round(retrieveInspectHeaderResidueDetails[0].moist_pct).toFixed(2))
            setValue(thisObj, 'txtFancyPct', Math.round(retrieveInspectHeaderResidueDetails[0].fan_pct).toFixed(2))



            let totalSMk = Number(retrieveInspectHeaderResidueDetails[0].smk_pct) + Number(retrieveInspectHeaderResidueDetails[0].ss_pct);
            setValue(thisObj, 'txtTotalSMK', Math.round(totalSMk).toFixed(2))
            let totalKernals = Number(retrieveInspectHeaderResidueDetails[0].smk_pct) + Number(retrieveInspectHeaderResidueDetails[0].ok_pct) + Number(retrieveInspectHeaderResidueDetails[0].dam_pct) + Number(retrieveInspectHeaderResidueDetails[0].ss_pct);
            let totalKernalsandHulls = Number(totalKernals) + Number(retrieveInspectHeaderResidueDetails[0].hull_pct)
            setValue(thisObj, 'txtTotalKrnls', Math.round(totalKernals).toFixed(2))
            setValue(thisObj, 'txtTotalKernelsHulls', Math.round(totalKernalsandHulls).toFixed(2))


            //Convert Grade binding
            setValue(thisObj, 'txtSeg', Math.round(retrieveInspectHeaderResidueDetails[0].seg_type).toFixed(2))
            setValue(thisObj, 'txtFM', Math.round(retrieveInspectHeaderResidueDetails[0].hfm_pct).toFixed(2))
            setValue(thisObj, 'txtLSK', Math.round(retrieveInspectHeaderResidueDetails[0].hlsk_pct).toFixed(2))
            setValue(thisObj, 'txtMST', Math.round(retrieveInspectHeaderResidueDetails[0].hmoist_pct).toFixed(2))
            setValue(thisObj, 'txtSMK', Math.round(retrieveInspectHeaderResidueDetails[0].hsmk_pct).toFixed(2))
            setValue(thisObj, 'txtSS', Math.round(retrieveInspectHeaderResidueDetails[0].hss_pct).toFixed(2))
            setValue(thisObj, 'txtOK', Math.round(retrieveInspectHeaderResidueDetails[0].hok_pct).toFixed(2))
            setValue(thisObj, 'txtFRZ', Math.round(retrieveInspectHeaderResidueDetails[0].hfrez_dam_pct).toFixed(2))
            setValue(thisObj, 'txtDAM', Math.round(retrieveInspectHeaderResidueDetails[0].hdam_pct).toFixed(2))
            setValue(thisObj, 'txtHULLS', Math.round(retrieveInspectHeaderResidueDetails[0].hhull_pct).toFixed(2))
            setValue(thisObj, 'txtELK', Math.round(retrieveInspectHeaderResidueDetails[0].helk_pct).toFixed(2))
            setValue(thisObj, 'txtFANCY', Math.round(retrieveInspectHeaderResidueDetails[0].hfan_pct).toFixed(2))

            let txtSMKRS = Number(retrieveInspectHeaderResidueDetails[0].hsmk_pct) + Number(retrieveInspectHeaderResidueDetails[0].hss_pct);
            setValue(thisObj, 'txtSMKRS', Math.round(txtSMKRS).toFixed(2))

            let txtTotalKernels = Number(txtSMKRS) + Number(retrieveInspectHeaderResidueDetails[0].hdam_pct) + Number(retrieveInspectHeaderResidueDetails[0].hok_pct)
            setValue(thisObj, 'txtTotalKernels', Math.round(txtTotalKernels).toFixed(2))

            let txtTotKrnlsHulls = Number(txtTotalKernels) + Number(retrieveInspectHeaderResidueDetails[0].hhull_pct)
            setValue(thisObj, 'txtTotKrnlsHulls', Math.round(txtTotKrnlsHulls).toFixed(2))
          }

          else {

            if (retrieveInspectHeaderResidueDetails[0].seg_type.trim() != "" && retrieveInspectHeaderResidueDetails[0].seg_type.trim() != "1" && retrieveInspectHeaderResidueDetails[0].seg_type.trim() != "2" && retrieveInspectHeaderResidueDetails[0].seg_type.trim() != "3" && retrieveInspectHeaderResidueDetails[0].seg_type.trim() != "X") {
              let js = []
              js.push({ key: retrieveInspectHeaderResidueDetails[0].seg_type, description: retrieveInspectHeaderResidueDetails[0].seg_type })
              thisObj.setState(current => {
                return {
                  ...current,
                  ddSeg: {
                    ...state["ddSeg"],
                    valueList: js
                  }
                }
              })
              setValue(thisObj, 'ddSeg', retrieveInspectHeaderResidueDetails[0].seg_type)
            }

            setValue(thisObj, 'ddSeg', retrieveInspectHeaderResidueDetails[0].seg_type) //required even if above if condition fails

            setValue(thisObj, 'txtSMKRSPct', Math.round(retrieveInspectHeaderResidueDetails[0].smk_pct).toFixed(2))
            setValue(thisObj, 'txtSoundSplitsPct', Math.round(retrieveInspectHeaderResidueDetails[0].ss_pct).toFixed(2))
            setValue(thisObj, 'txtOtherKernelsPct', Math.round(retrieveInspectHeaderResidueDetails[0].ok_pct).toFixed(2))
            setValue(thisObj, 'txtTotalDamagePct', Math.round(retrieveInspectHeaderResidueDetails[0].dam_pct).toFixed(2))
            setValue(thisObj, 'txtFreezeDamagePct', Math.round(retrieveInspectHeaderResidueDetails[0].frez_dam_pct).toFixed(2))
            setValue(thisObj, 'txtConcealedRMDPct', Math.round(retrieveInspectHeaderResidueDetails[0].cncl_rmd_pct).toFixed(2))
            setValue(thisObj, 'txtHullsPct', Math.round(retrieveInspectHeaderResidueDetails[0].hull_pct).toFixed(2))
            setValue(thisObj, 'txtELKPct', Math.round(retrieveInspectHeaderResidueDetails[0].elk_pct).toFixed(2))
            setValue(thisObj, 'txtForeignMaterialPct', Math.round(retrieveInspectHeaderResidueDetails[0].fm_pct).toFixed(2))
            setValue(thisObj, 'txtLSKPct', Math.round(retrieveInspectHeaderResidueDetails[0].lsk_pct).toFixed(2))
            setValue(thisObj, 'txtMoisture', Math.round(retrieveInspectHeaderResidueDetails[0].moist_pct).toFixed(2))
            setValue(thisObj, 'txtFancyPct', Math.round(retrieveInspectHeaderResidueDetails[0].fan_pct).toFixed(2))

            let totalSMk = Number(retrieveInspectHeaderResidueDetails[0].smk_pct) + Number(retrieveInspectHeaderResidueDetails[0].ss_pct);
            setValue(thisObj, 'txtTotalSMK', Math.round(totalSMk).toFixed(2))
            let totalKernals = Number(retrieveInspectHeaderResidueDetails[0].smk_pct) + Number(retrieveInspectHeaderResidueDetails[0].ok_pct) + Number(retrieveInspectHeaderResidueDetails[0].dam_pct) + Number(retrieveInspectHeaderResidueDetails[0].ss_pct);
            let totalKernalsandHulls = Number(totalKernals) + Number(retrieveInspectHeaderResidueDetails[0].hull_pct)
            setValue(thisObj, 'txtTotalKrnls', Math.round(totalKernals).toFixed(2))
            setValue(thisObj, 'txtTotalKernelsHulls', Math.round(totalKernalsandHulls).toFixed(2))


            //Clearing Convert Grade HMc
            clearValue(thisObj, 'txtSeg')
            clearValue(thisObj, 'txtFM')
            clearValue(thisObj, 'txtLSK')
            clearValue(thisObj, 'txtMST')
            clearValue(thisObj, 'txtSMK')
            clearValue(thisObj, 'txtSS')
            clearValue(thisObj, 'txtOK')
            clearValue(thisObj, 'txtFRZ')
            clearValue(thisObj, 'txtCRMD')
            clearValue(thisObj, 'txtDAM')
            clearValue(thisObj, 'txtHULLS')
            clearValue(thisObj, 'txtELK')
            clearValue(thisObj, 'txtFANCY')
            clearValue(thisObj, 'txtSMKRS')
            clearValue(thisObj, 'txtTotalKernels')
            clearValue(thisObj, 'txtTotKrnlsHulls')
          }

          if (opt_hmYESNO == "2") {
            let weightincludingvehicle = Number(retrieveInspectHeaderResidueDetails[0].e_wt_including_vehicle)
            let weightofvehicle = Number(retrieveInspectHeaderResidueDetails[0].e_wt_of_vehicle)
            let moisturepct = Number(retrieveInspectHeaderResidueDetails[0].moist_pct)
            let cr_br_pct = Number(retrieveInspectHeaderResidueDetails[0].cr_br_pct)
            let dcol_shel_pct = Number(retrieveInspectHeaderResidueDetails[0].dcol_shel_pct)
            let fan_pct = Number(retrieveInspectHeaderResidueDetails[0].fan_pct)
            let foreignmaterialpct = Number(retrieveInspectHeaderResidueDetails[0].fm_pct)
            let lskpct = Number(retrieveInspectHeaderResidueDetails[0].lsk_pct)
            let smk = Number(retrieveInspectHeaderResidueDetails[0].smk_pct)
            let sspct = Number(retrieveInspectHeaderResidueDetails[0].ss_pct)
            let okpct = Number(retrieveInspectHeaderResidueDetails[0].ok_pct)
            let elkpct = Number(retrieveInspectHeaderResidueDetails[0].elk_pct)
            let dam_pct = Number(retrieveInspectHeaderResidueDetails[0].dam_pct)
            let areaId = retrieveInspectHeaderResidueDetails[0].area_id
            let pnut_type_id = retrieveInspectHeaderResidueDetails[0].pnut_type_id
            CalculateValues(areaId, pnut_type_id, weightincludingvehicle, weightofvehicle, moisturepct, cr_br_pct,
              dcol_shel_pct, fan_pct, foreignmaterialpct, lskpct, smk, sspct, okpct, elkpct, dam_pct);
          }
          else {
            let weightincludingvehicle = Number(retrieveInspectHeaderResidueDetails[0].e_wt_including_vehicle)
            let weightofvehicle = Number(retrieveInspectHeaderResidueDetails[0].e_wt_of_vehicle)
            let moisturepct = Number(retrieveInspectHeaderResidueDetails[0].moist_pct)
            let cr_br_pct = Number(retrieveInspectHeaderResidueDetails[0].cr_br_pct)
            let dcol_shel_pct = Number(retrieveInspectHeaderResidueDetails[0].dcol_shel_pct)
            let fancy = Number(retrieveInspectHeaderResidueDetails[0].hfan_pct)
            let fm = Number(retrieveInspectHeaderResidueDetails[0].hfm_pct)
            let lsk = Number(retrieveInspectHeaderResidueDetails[0].hlsk_pct)
            let smk = Number(retrieveInspectHeaderResidueDetails[0].hsmk_pct)
            let ss = Number(retrieveInspectHeaderResidueDetails[0].hss_pct)
            let ok = Number(retrieveInspectHeaderResidueDetails[0].hok_pct)
            let elk = Number(retrieveInspectHeaderResidueDetails[0].helk_pct)
            let dam = Number(retrieveInspectHeaderResidueDetails[0].hdam_pct)

            let areaId = retrieveInspectHeaderResidueDetails[0].area_id
            let pnut_type_id = retrieveInspectHeaderResidueDetails[0].pnut_type_id

            CalculateValues(areaId, pnut_type_id, weightincludingvehicle, weightofvehicle, moisturepct, cr_br_pct,
              dcol_shel_pct, fancy, fm, lsk, smk, ss, ok, elk, dam);
          }

          if (retrieveInspectHeaderResidueDetails[0].settlement_num === "" || retrieveInspectHeaderResidueDetails[0].settlement_num === null || retrieveInspectHeaderResidueDetails[0].settlement_num === 0 || retrieveInspectHeaderResidueDetails[0].settlement_num === undefined) {
            document.getElementById('btnPrint').innerHTML = "Print";
            show(thisObj, 'btnDelete')
          }
          else {
            document.getElementById('btnPrint').innerHTML = "Reprint";
            show(thisObj, 'btnDelete')
          }
          bFillForm = true;
        }

      }
    }
    catch (err) {
      bFillForm = false;
      setLoading(false)
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception occured in bFillForm()"
        )
      }
      return bFillForm;
    }
    setLoading(false)
    return bFillForm;
  };


  const onbtnPrintClick = async () => {
    if (getValue(thisObj, "chkboxSendBack")) {
      setValue(thisObj, "chkboxSendBack", false)
    }
    let buyingPointList = thisObj.state['ddLocation'].valueList;
    let selectedBuyingPointValue = getValue(thisObj, "ddLocation", '');
    let lstAreaId = null;
    if (selectedBuyingPointValue !== "" && selectedBuyingPointValue !== undefined) {
      lstAreaId = buyingPointList.find(elem => elem.key === selectedBuyingPointValue).area_id;
    }
    let res = await SettlementService.RetrieveAreaControlDetails(lstAreaId)
    //let res = response;
    if (res.length > 0) {
      setData(thisObj, 'AreaControlDetails', res[0]);
    }


    if (document.getElementById('btnPrint').innerHTML == "Reprint") {
      let fv95 = getValue(thisObj, "txtSC95") === undefined ? "" : getValue(thisObj, "txtSC95") === null ? "" : getValue(thisObj, "txtSC95");
      let lbl1007 = getValue(thisObj, "txt1007") === undefined ? "" : getValue(thisObj, "txt1007") === null ? "" : getValue(thisObj, "txt1007");
      let opt_hmYESNO = getValue(thisObj, "radioHighMoisture")
      if (opt_hmYESNO == "1") {
        ConvertHMCGrades();
      }


      if (!bFormValid("PRINT")) {
        return false;
      }
      let rsp = await bUpdateResidueInspection("REPRINT");

      if (!bFillForm()) {
        return false;
      }


      if (IsNumeric.test(fv95)) {
        let rptPrvObj = {
          type: "REPRINT_WITH_CHECKBOX",
          method_name: "rptInspectionCertificate_onedoc",
          SC95No: fv95,
          s1007No: lbl1007,
          status: 'RESIDUE',
          buy_pt_id: '',
          docType: 'Applicant Original',
          WaterMark : 'Test'
        }

        setData(thisObj, "ReportPreviewInspectionScreenData", rptPrvObj)
        goTo(thisObj, "Settlements#ReportPreviewInspection#DEFAULT#true#Click")

      }
      else {
        let rptPrvObj = {
          type: "REPRINT_FROM_HEADER",
          method_name: "rptInspectionCertificate",
          SC95No: fv95,
          s1007No: lbl1007,
          status: 'RESIDUE',
          buy_pt_id: '',
          docType : 'Applicant Original',
          WaterMark : 'Test'
        }
        setData(thisObj, "ReportPreviewInspectionScreenData", rptPrvObj)
        goTo(thisObj, "Settlements#ReportPreviewInspection#DEFAULT#true#Click")
      }
    }
    else {
      let opt_hmYESNO = getValue(thisObj, "radioHighMoisture")
      let fv95 = getValue(thisObj, "txtSC95") === undefined ? "" : getValue(thisObj, "txtSC95") === null ? "" : getValue(thisObj, "txtSC95");
      let lbl1007 = getValue(thisObj, "txt1007") === undefined ? "" : getValue(thisObj, "txt1007") === null ? "" : getValue(thisObj, "txt1007");
      if (opt_hmYESNO == "1") {
        ConvertHMCGrades();
      }
      if (!bFormValid("PRINT")) {
        return false;
      }
      await bUpdateResidueInspection("PRINT");


      let rsbFillForm = await bFillForm()
      if (!rsbFillForm) {
        return false;
      }
      else {
        lbl1007 = getData(thisObj, "s1007N")
        if ((lbl1007 != "") && (lbl1007 != null)) {
          if (IsNumeric.test(fv95)) {
            let rptPrvObj = {
              type: "",
              method_name: "rptInspectionCertificate_onedoc",
              SC95No: fv95,
              s1007No: lbl1007,
              status: 'RESIDUE',
              buy_pt_id: '',
              docType: 'Applicant Original,FSIS,SELLER',
              WaterMark : 'Test'
            }
            setData(thisObj, "ReportPreviewInspectionScreenData", rptPrvObj)
            goTo(thisObj, "Settlements#ReportPreviewInspection#DEFAULT#true#Click")

          }
          else {
            let rptPrvObj = {
              type: "",
              method_name: "rptInspectionCertificate",
              SC95No: fv95,
              s1007No: lbl1007,
              status: 'RESIDUE',
              buy_pt_id: '',
              docType : 'Applicant Original',
              WaterMark : 'Test'
            }
            setData(thisObj, "ReportPreviewInspectionScreenData", rptPrvObj)
            goTo(thisObj, "Settlements#ReportPreviewInspection#DEFAULT#true#Click")
          }
        }
      }

    }
  };
  thisObj.onbtnPrintClick = onbtnPrintClick;


  //EnableDisableGradeEntry

  const EnabelDisableGradeEntry = (lstGramEntryInd, lstpnutTypeId) => {
    try {
      if (lstGramEntryInd == 'Y' || lstGramEntryInd == 'y') {
        enable(thisObj, "txtSMKRSGr");
        disable(thisObj, "txtSMKRSPct");
        enable(thisObj, "txtSoundSplitsGr");
        disable(thisObj, "txtSoundSplitsPct");
        enable(thisObj, "txtOtherKernelsGr");
        disable(thisObj, "txtOtherKernelsPct");
        enable(thisObj, "txtHullsGr");
        enable(thisObj, "txtFreezeDamageGr");
        disable(thisObj, "txtFreezeDamagePct");
        enable(thisObj, "txtConcealedRMDGr");
        disable(thisObj, "txtConcealedRMDPct");
        enable(thisObj, "txtCrkOrBrkShellsGr");
        disable(thisObj, "txtCrkOrBrkShellsPct");
        enable(thisObj, "txtDiscoloredShellsGr");
        disable(thisObj, "txtDiscoloredShellsPct");
        enable(thisObj, "txtHullBrightness");
        disable(thisObj, "txtHullBrightnessPct");
        enable(thisObj, "txtJumboGr");
        disable(thisObj, "txtJumboPct");
        enable(thisObj, "txtELKGr")
        disable(thisObj, "txtELKPct");
        enable(thisObj, "txtForeignMaterialGr");
        disable(thisObj, "txtForeignMaterialPct");
        enable(thisObj, "txtLSKGr");
        disable(thisObj, "txtLSKPct");
        enable(thisObj, "txtFancyGr");
        disable(thisObj, "txtFancyPct");
        enable(thisObj, "txtTotalDamageGr");
        disable(thisObj, "txtTotalDamagePct");
      }
      else {
        clearValue(thisObj, "txtSMKRSGr");
        disable(thisObj, "txtSMKRSGr");
        enable(thisObj, "txtSMKRSPct");
        clearValue(thisObj, "txtSoundSplitsGr");
        disable(thisObj, "txtSoundSplitsGr");
        enable(thisObj, "txtSoundSplitsPct");
        clearValue(thisObj, "txtOtherKernelsGr");
        disable(thisObj, "txtOtherKernelsGr");
        enable(thisObj, "txtOtherKernelsPct");
        clearValue(thisObj, "txtHullsGr");
        disable(thisObj, "txtHullsGr");
        clearValue(thisObj, "txtFreezeDamageGr");
        disable(thisObj, "txtFreezeDamageGr");
        enable(thisObj, "txtFreexeDamagePct");
        clearValue(thisObj, "txtConcealedRMDGr");
        disable(thisObj, "txtConcealedRMDGr");
        enable(thisObj, "txtConcealedRMDPct");
        clearValue(thisObj, "txtCrkOrBrkShellsGr");
        disable(thisObj, "txtCrkOrBrkShellsGr");
        enable(thisObj, "txtCrkOrBrkShellsPct");
        clearValue(thisObj, "txtDiscoloredShellsGr");
        disable(thisObj, "txtDiscoloredShellsGr");
        enable(thisObj, "txtDiscoloredShellsPct");
        clearValue(thisObj, "txtHullBrightness");
        disable(thisObj, "txtHullBrightness");
        enable(thisObj, "txtHullBrightnessPct");
        clearValue(thisObj, "txtJumboGr");
        disable(thisObj, "txtJumboGr");
        enable(thisObj, "txtJumboPct");
        clearValue(thisObj, "txtELKGr");
        disable(thisObj, "txtELKGr");
        enable(thisObj, "txtELKPct");
        disable(thisObj, "txtForeignMaterialGr");
        clearValue(thisObj, "txtForeignMaterialGr");
        enable(thisObj, "txtForeignMaterialPct");
        clearValue(thisObj, "txtLSKGr");
        disable(thisObj, "txtLSKGr");
        enable(thisObj, "txtLSKPct");
        clearValue(thisObj, "txtFancyGr");
        disable(thisObj, "txtFancyGr");
        enable(thisObj, "txtFancyPct");
        clearValue(thisObj, "txtTotalDamageGr");
        disable(thisObj, "txtTotalDamageGr");
        enable(thisObj, "txtTotalDamagePct");
        enable(thisObj, "txtFreezeDamagePct");
      }

      if (lstpnutTypeId == "VA") {
        show(thisObj, "txtHullBrightness", "");
        show(thisObj, "txtHullBrightnessPct", "");
        show(thisObj, "txtJumboGr", "");
        show(thisObj, "txtJumboPct", "");
        show(thisObj, "txtELKGr", "");
        show(thisObj, "txtELKPct", "");
        show(thisObj, "txtFancyGr", "");
        show(thisObj, "txtFancyPct", "");
      }
      else {
        setValue(thisObj, "txtHullBrightness", '0.00');
        hide(thisObj, "txtHullBrightness", false);
        setValue(thisObj, "txtHullBrightnessPct", '0.00');
        hide(thisObj, "txtHullBrightnessPct", false);
        setValue(thisObj, "txtJumboGr", '0.00');
        setValue(thisObj, "txtJumboPct", '0.00');
        hide(thisObj, "txtJumboGr", false);
        hide(thisObj, "txtJumboPct", false);
        setValue(thisObj, "txtELKGr", '0.00');
        setValue(thisObj, "txtELKPct", '0.00');
        hide(thisObj, "txtELKGr", false);
        hide(thisObj, "txtELKPct", false);
        setValue(thisObj, "txtFancyGr", '0.00');
        hide(thisObj, "txtFancyGr", false);
        setValue(thisObj, "txtFancyPct", '0.00');
        hide(thisObj, "txtFancyPct", false);
      }

      if (lstpnutTypeId == "VL") {
        show(thisObj, "txtValenciaCleanSampleWt", "");
        show(thisObj, "txtCrkOrBrkShellsGr", "");
        show(thisObj, "txtCrkOrBrkShellsPct", "");
        show(thisObj, "txtDiscoloredShellsGr", "");
        show(thisObj, "txtDiscoloredShellsPct", "");
      }
      else {
        setValue(thisObj, "txtValenciaCleanSampleWt", '0.00');
        hide(thisObj, "txtValenciaCleanSampleWt", false);
        setValue(thisObj, "txtCrkOrBrkShellsGr", '0.00');
        hide(thisObj, "txtCrkOrBrkShellsGr", false);
        hide(thisObj, "txtCrkOrBrkShellsPct"), false;
        setValue(thisObj, "txtDiscoloredShellsGr", '0.00');
        hide(thisObj, "txtDiscoloredShellsGr", false);
        hide(thisObj, "txtDiscoloredShellsPct", false);
      }

    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception occured in EnabelDisableGradeEntry"
        )
      }
      return false;
    }
    return true;
  };

  //enabledisable for end of the form
  const EnabelDisableLast = (lstGramEntryInd, lstpnutTypeId) => {
    try {
      if (lstGramEntryInd == 'Y' || lstGramEntryInd == 'y') {
        enable(thisObj, "txtSMKRSGr");
        disable(thisObj, "txtSMKRSPct");
        enable(thisObj, "txtSoundSplitsGr");
        disable(thisObj, "txtSoundSplitsPct");
        enable(thisObj, "txtOtherKernelsGr");
        disable(thisObj, "txtOtherKernelsPct");
        enable(thisObj, "txtHullsGr");
        enable(thisObj, "txtFreezeDamageGr");
        disable(thisObj, "txtFreezeDamagePct");
        enable(thisObj, "txtConcealedRMDGr");
        disable(thisObj, "txtConcealedRMDPct");
        enable(thisObj, "txtCrkOrBrkShellsGr");
        disable(thisObj, "txtCrkOrBrkShellsPct");
        enable(thisObj, "txtDiscoloredShellsGr");
        disable(thisObj, "txtDiscoloredShellsPct");
        enable(thisObj, "txtHullBrightness");
        disable(thisObj, "txtHullBrightnessPct");
        enable(thisObj, "txtJumboGr");
        disable(thisObj, "txtJumboPct");
        disable(thisObj, "txtELKPct");
        enable(thisObj, "txtForeignMaterialGr");
        disable(thisObj, "txtForeignMaterialPct");
        enable(thisObj, "txtLSKGr");
        disable(thisObj, "txtLSKPct");
        enable(thisObj, "txtFancyGr");
        disable(thisObj, "txtFancyPct");
        enable(thisObj, "txtTotalDamageGr");
        disable(thisObj, "txtTotalDamagePct");
      }
      else {

        disable(thisObj, "txtSMKRSGr");
        enable(thisObj, "txtSMKRSPct");

        disable(thisObj, "txtSoundSplitsGr");
        enable(thisObj, "txtSoundSplitsPct");

        disable(thisObj, "txtOtherKernelsGr");
        enable(thisObj, "txtOtherKernelsPct");

        disable(thisObj, "txtHullsGr");

        disable(thisObj, "txtFreezeDamageGr");
        enable(thisObj, "txtFreexeDamagePct");

        disable(thisObj, "txtConcealedRMDGr");
        enable(thisObj, "txtConcealedRMDPct");

        disable(thisObj, "txtCrkOrBrkShellsGr");
        enable(thisObj, "txtCrkOrBrkShellsPct");

        disable(thisObj, "txtDiscoloredShellsGr");
        enable(thisObj, "txtDiscoloredShellsPct");

        disable(thisObj, "txtHullBrightness");
        enable(thisObj, "txtHullBrightnessPct");

        disable(thisObj, "txtJumboGr");
        enable(thisObj, "txtJumboPct");

        disable(thisObj, "txtELKGr");
        enable(thisObj, "txtELKPct");
        disable(thisObj, "txtForeignMaterialGr");

        enable(thisObj, "txtForeignMaterialPct");

        disable(thisObj, "txtLSKGr");
        enable(thisObj, "txtLSKPct");

        disable(thisObj, "txtFancyGr");
        enable(thisObj, "txtFancyPct");

        disable(thisObj, "txtTotalDamageGr");
        enable(thisObj, "txtTotalDamagePct");
      }

      if (lstpnutTypeId == "VA") {
        show(thisObj, "txtHullBrightness");
        show(thisObj, "txtHullBrightnessPct");
        show(thisObj, "txtJumboGr");
        show(thisObj, "txtJumboPct");
        show(thisObj, "txtELKGr");
        show(thisObj, "txtELKPct");
        show(thisObj, "txtFancyGr");
        show(thisObj, "txtFancyPct");
      }
      else {
        setValue(thisObj, "txtHullBrightness", '0.00');
        hide(thisObj, "txtHullBrightness", false);
        setValue(thisObj, "txtHullBrightnessPct", '0.00');
        hide(thisObj, "txtHullBrightnessPct", false);
        setValue(thisObj, "txtJumboGr", '0.00');
        setValue(thisObj, "txtJumboPct", '0.00');
        hide(thisObj, "txtJumboGr", false);
        hide(thisObj, "txtJumboPct", false);
        setValue(thisObj, "txtELKGr", '0.00');
        setValue(thisObj, "txtELKPct", '0.00');
        hide(thisObj, "txtELKGr", false);
        hide(thisObj, "txtELKPct", false);
        setValue(thisObj, "txtFancyGr", '0.00');
        hide(thisObj, "txtFancyGr", false);
        setValue(thisObj, "txtFancyPct", '0.00');
        hide(thisObj, "txtFancyPct", false);
      }

      if (lstpnutTypeId == "VL") {
        show(thisObj, "txtValenciaCleanSampleWt");
        show(thisObj, "txtCrkOrBrkShellsGr");
        show(thisObj, "txtCrkOrBrkShellsPct");
        show(thisObj, "txtDiscoloredShellsGr");
        show(thisObj, "txtDiscoloredShellsPct");
      }
      else {
        setValue(thisObj, "txtValenciaCleanSampleWt", '0.00');
        hide(thisObj, "txtValenciaCleanSampleWt", false);
        setValue(thisObj, "txtCrkOrBrkShellsGr", '0.00');
        hide(thisObj, "txtCrkOrBrkShellsGr", false);
        hide(thisObj, "txtCrkOrBrkShellsPct"), false;
        setValue(thisObj, "txtDiscoloredShellsGr", '0.00');
        hide(thisObj, "txtDiscoloredShellsGr", false);
        hide(thisObj, "txtDiscoloredShellsPct", false);
      }

      //if(disableGradeCalculationsBoolean){
      if (getData(thisObj, "setdisableGradeCalculationsBoolean")) {
        disableGradeCalculations()
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(
          thisObj, "Exception occured in EnabelDisableLast"
        )
      }
      return false;
    }
    return true;
  };

  //disable Grade Calculations
  function disableGradeCalculations() {
    //textboxes
    disable(thisObj, "txtValenciaCleanSampleWt")
    disable(thisObj, "txtTotalSMK")
    disable(thisObj, "txtTotalKrnls")
    disable(thisObj, "txtTotalKernelsHulls")
    disable(thisObj, "txtTotalDamagePct")
    disable(thisObj, "txtTotalDamageGr")
    disable(thisObj, "txtSoundSplitsPct")
    disable(thisObj, "txtSoundSplitsGr")
    disable(thisObj, "txtSMKRSPct")
    disable(thisObj, "txtSMKRSGr")
    disable(thisObj, "txtProbingPattern")
    disable(thisObj, "txtOtherKernelsPct")
    disable(thisObj, "txtOtherKernelsGr")
    disable(thisObj, "txtOtherForeignMaterials")
    disable(thisObj, "txtMoisture")
    disable(thisObj, "txtMeterReading")
    disable(thisObj, "txtLSKPct")
    disable(thisObj, "txtLSKGr")
    disable(thisObj, "txtKernelsRPS")
    disable(thisObj, "txtKernelsREL")
    disable(thisObj, "txtJumboPct")
    disable(thisObj, "txtJumboGr")
    disable(thisObj, "txtHullsPct")
    disable(thisObj, "txtHullsGr")
    disable(thisObj, "txtHullBrightnessPct")
    disable(thisObj, "txtHullBrightness")
    disable(thisObj, "txtFreezeDamagePct")
    disable(thisObj, "txtFreezeDamageGr")
    disable(thisObj, "txtForeignMaterialPct")
    disable(thisObj, "txtForeignMaterialGr")
    disable(thisObj, "txtFMSampleWt")
    disable(thisObj, "txtFancyPct")
    disable(thisObj, "txtFancyGr")
    disable(thisObj, "txtELKPct")
    disable(thisObj, "txtELKGr")
    disable(thisObj, "txtELKDamage")
    disable(thisObj, "txtDiscoloredShellsPct")
    disable(thisObj, "txtDiscoloredShellsGr")
    disable(thisObj, "txtDamageSplits")
    disable(thisObj, "txtDamagedKRS")
    disable(thisObj, "txtCrkOrBrkShellsPct")
    disable(thisObj, "txtCrkOrBrkShellsGr")
    disable(thisObj, "txtConcealedRMDPct")
    disable(thisObj, "txtConcealedRMDGr")
    disable(thisObj, "txtCleanSampleWt")
    disable(thisObj, "txtBluePanWt")

    //checkbox
    disable(thisObj, "chkboxFlavusFound")
    disable(thisObj, "chkboxCornFound")

    //labels
    // lblPct2
    // lblPct1
    // lblGrams2
    // lblGrams1
    // lblGradeCalculation
    // lblFlavusFound
    // lblCornFound
  }

  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_FV95Residue*/}

              {/* END_USER_CODE-USER_BEFORE_FV95Residue*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxFarmInformation*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxFarmInformation*/}

              <GroupBoxWidget
                conf={state.grpbxFarmInformation}
                screenConf={state}
              >

                {/* START_USER_CODE-USER_BEFORE_txtLoadType*/}
                {/* END_USER_CODE-USER_BEFORE_txtLoadType*/}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLoadType}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLoadType*/}
                {/* END_USER_CODE-USER_AFTER_txtLoadType*/}
                {/* START_USER_CODE-USER_BEFORE_ddLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddLocation*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddLocation*/}

                {/* START_USER_CODE-USER_BEFORE_txtN*/}
                {/* END_USER_CODE-USER_BEFORE_txtN*/}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtN}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtN*/}
                {/* END_USER_CODE-USER_AFTER_txtN*/}




                {/* START_USER_CODE-USER_BEFORE_txtSC95*/}

                {/* END_USER_CODE-USER_BEFORE_txtSC95*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSC95}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSC95*/}

                {/* END_USER_CODE-USER_AFTER_txtSC95*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRevisionNumber}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>

                {/* START_USER_CODE-USER_BEFORE_txtInspectionDateTime*/}

                {/* END_USER_CODE-USER_BEFORE_txtInspectionDateTime*/}

                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtInspectionDateTime}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_txtInspectionDateTime*/}

                {/* END_USER_CODE-USER_AFTER_txtInspectionDateTime*/}
                {/* START_USER_CODE-USER_BEFORE_lblDateTimeFormat*/}

                {/* END_USER_CODE-USER_BEFORE_lblDateTimeFormat*/}

                <LabelWidget
                  values={values}
                  conf={state.lblDateTimeFormat}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblDateTimeFormat*/}

                {/* END_USER_CODE-USER_AFTER_lblDateTimeFormat*/}
                {/* START_USER_CODE-USER_BEFORE_txt1007*/}

                {/* END_USER_CODE-USER_BEFORE_txt1007*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt1007}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txt1007*/}

                {/* END_USER_CODE-USER_AFTER_txt1007*/}
                {/* START_USER_CODE-USER_BEFORE_txtSettlementDate*/}

                {/* END_USER_CODE-USER_BEFORE_txtSettlementDate*/}

                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSettlementDate}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_txtSettlementDate*/}

                {/* END_USER_CODE-USER_AFTER_txtSettlementDate*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxFarmInformation*/}

              {/* END_USER_CODE-USER_AFTER_grpbxFarmInformation*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxCMAInformation*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCMAInformation*/}

              <GroupBoxWidget
                conf={state.grpbxCMAInformation}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_chkboxResale*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxResale*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxResale}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxResale*/}

                {/* END_USER_CODE-USER_AFTER_chkboxResale*/}
                {/* START_USER_CODE-USER_BEFORE_lblNumber*/}

                {/* END_USER_CODE-USER_BEFORE_lblNumber*/}

                <LabelWidget
                  values={values}
                  conf={state.lblNumber}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblNumber*/}

                {/* END_USER_CODE-USER_AFTER_lblNumber*/}
                {/* START_USER_CODE-USER_BEFORE_lblName*/}

                {/* END_USER_CODE-USER_BEFORE_lblName*/}

                <LabelWidget
                  values={values}
                  conf={state.lblName}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblName*/}

                {/* END_USER_CODE-USER_AFTER_lblName*/}
                {/* START_USER_CODE-USER_BEFORE_txtSellerNumber*/}

                {/* END_USER_CODE-USER_BEFORE_txtSellerNumber*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSellerNumber}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSellerNumber*/}

                {/* END_USER_CODE-USER_AFTER_txtSellerNumber*/}
                {/* START_USER_CODE-USER_BEFORE_txtSellerName*/}

                {/* END_USER_CODE-USER_BEFORE_txtSellerName*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSellerName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSellerName*/}

                {/* END_USER_CODE-USER_AFTER_txtSellerName*/}
                {/* START_USER_CODE-USER_BEFORE_txtBuyerNumber*/}

                {/* END_USER_CODE-USER_BEFORE_txtBuyerNumber*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtBuyerNumber}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtBuyerNumber*/}

                {/* END_USER_CODE-USER_AFTER_txtBuyerNumber*/}
                {/* START_USER_CODE-USER_BEFORE_txtBuyerName*/}

                {/* END_USER_CODE-USER_BEFORE_txtBuyerName*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtBuyerName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtBuyerName*/}

                {/* END_USER_CODE-USER_AFTER_txtBuyerName*/}
                {/* START_USER_CODE-USER_BEFORE_radioHighMoisture*/}

                {/* END_USER_CODE-USER_BEFORE_radioHighMoisture*/}

                <RadioButtonGroupWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.radioHighMoisture}
                  screenConf={state}
                ></RadioButtonGroupWidget>
                {/* START_USER_CODE-USER_AFTER_radioHighMoisture*/}

                {/* END_USER_CODE-USER_AFTER_radioHighMoisture*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCMAInformation*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCMAInformation*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxWeightTickets*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxWeightTickets*/}

              <GroupBoxWidget
                conf={state.grpbxWeightTickets}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtEWeightTkt*/}

                {/* END_USER_CODE-USER_BEFORE_txtEWeightTkt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtEWeightTkt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtEWeightTkt*/}

                {/* END_USER_CODE-USER_AFTER_txtEWeightTkt*/}
                {/* START_USER_CODE-USER_BEFORE_txtEVehicleNum*/}

                {/* END_USER_CODE-USER_BEFORE_txtEVehicleNum*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtEVehicleNum}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtEVehicleNum*/}

                {/* END_USER_CODE-USER_AFTER_txtEVehicleNum*/}
                {/* START_USER_CODE-USER_BEFORE_txtEWtInclVeh*/}

                {/* END_USER_CODE-USER_BEFORE_txtEWtInclVeh*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtEWtInclVeh}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtEWtInclVeh*/}

                {/* END_USER_CODE-USER_AFTER_txtEWtInclVeh*/}
                {/* START_USER_CODE-USER_BEFORE_txtEWtOfVeh*/}

                {/* END_USER_CODE-USER_BEFORE_txtEWtOfVeh*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtEWtOfVeh}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtEWtOfVeh*/}

                {/* END_USER_CODE-USER_AFTER_txtEWtOfVeh*/}
                {/* START_USER_CODE-USER_BEFORE_txtEWtOfPnut*/}

                {/* END_USER_CODE-USER_BEFORE_txtEWtOfPnut*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtEWtOfPnut}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtEWtOfPnut*/}

                {/* END_USER_CODE-USER_AFTER_txtEWtOfPnut*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxWeightTickets*/}

              {/* END_USER_CODE-USER_AFTER_grpbxWeightTickets*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxPeanutInfo*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPeanutInfo*/}

              <GroupBoxWidget conf={state.grpbxPeanutInfo} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblPeanutInformation*/}

                {/* END_USER_CODE-USER_BEFORE_lblPeanutInformation*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPeanutInformation}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPeanutInformation*/}

                {/* END_USER_CODE-USER_AFTER_lblPeanutInformation*/}
                {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_ddSeg*/}

                {/* END_USER_CODE-USER_BEFORE_ddSeg*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSeg}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSeg*/}

                {/* END_USER_CODE-USER_AFTER_ddSeg*/}
                {/* START_USER_CODE-USER_BEFORE_ddEdibleOil*/}

                {/* END_USER_CODE-USER_BEFORE_ddEdibleOil*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddEdibleOil}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddEdibleOil*/}

                {/* END_USER_CODE-USER_AFTER_ddEdibleOil*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPeanutInfo*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPeanutInfo*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxInspectorRemarks*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxInspectorRemarks*/}

              <GroupBoxWidget
                conf={state.grpbxInspectorRemarks}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblInspectorRemarks*/}

                {/* END_USER_CODE-USER_BEFORE_lblInspectorRemarks*/}

                <LabelWidget
                  values={values}
                  conf={state.lblInspectorRemarks}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblInspectorRemarks*/}

                {/* END_USER_CODE-USER_AFTER_lblInspectorRemarks*/}
                {/* START_USER_CODE-USER_BEFORE_txtarInspectorRemarks*/}

                {/* END_USER_CODE-USER_BEFORE_txtarInspectorRemarks*/}

                <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtarInspectorRemarks}
                  screenConf={state}
                ></TextAreaWidget>
                {/* START_USER_CODE-USER_AFTER_txtarInspectorRemarks*/}

                {/* END_USER_CODE-USER_AFTER_txtarInspectorRemarks*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxInspectorRemarks*/}

              {/* END_USER_CODE-USER_AFTER_grpbxInspectorRemarks*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxInspectorRemarks*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxInspectorRemarks*/}

              {/* <GroupBoxWidget
                conf={state.grpbxInspectorRemarks}
                screenConf={state}
              > */}
              {/* START_USER_CODE-USER_BEFORE_lblInspectorRemarks*/}

              {/* END_USER_CODE-USER_BEFORE_lblInspectorRemarks*/}

              {/* <LabelWidget
                  values={values}
                  conf={state.lblInspectorRemarks}
                  screenConf={state}
                ></LabelWidget> */}
              {/* START_USER_CODE-USER_AFTER_lblInspectorRemarks*/}

              {/* END_USER_CODE-USER_AFTER_lblInspectorRemarks*/}
              {/* START_USER_CODE-USER_BEFORE_txtarInspectorRemarks*/}

              {/* END_USER_CODE-USER_BEFORE_txtarInspectorRemarks*/}

              {/* <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtarInspectorRemarks}
                  screenConf={state}
                ></TextAreaWidget> */}
              {/* START_USER_CODE-USER_AFTER_txtarInspectorRemarks*/}

              {/* END_USER_CODE-USER_AFTER_txtarInspectorRemarks*/}
              {/* </GroupBoxWidget> */}
              {/* START_USER_CODE-USER_AFTER_grpbxInspectorRemarks*/}

              {/* END_USER_CODE-USER_AFTER_grpbxInspectorRemarks*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxGradeCalculation*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxGradeCalculation*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget
                conf={state.grpbxGradeCalculation}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblGradeCalculation*/}

                {/* END_USER_CODE-USER_BEFORE_lblGradeCalculation*/}

                <LabelWidget
                  values={values}
                  conf={state.lblGradeCalculation}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblGradeCalculation*/}

                {/* END_USER_CODE-USER_AFTER_lblGradeCalculation*/}
                {/* START_USER_CODE-USER_BEFORE_txtFMSampleWt*/}

                {/* END_USER_CODE-USER_BEFORE_txtFMSampleWt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFMSampleWt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFMSampleWt*/}

                {/* END_USER_CODE-USER_AFTER_txtFMSampleWt*/}
                {/* START_USER_CODE-USER_BEFORE_txtProbingPattern*/}

                {/* END_USER_CODE-USER_BEFORE_txtProbingPattern*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtProbingPattern}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtProbingPattern*/}

                {/* END_USER_CODE-USER_AFTER_txtProbingPattern*/}
                {/* START_USER_CODE-USER_BEFORE_txtCleanSampleWt*/}

                {/* END_USER_CODE-USER_BEFORE_txtCleanSampleWt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCleanSampleWt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCleanSampleWt*/}

                {/* END_USER_CODE-USER_AFTER_txtCleanSampleWt*/}
                {/* START_USER_CODE-USER_BEFORE_txtValenciaCleanSampleWt*/}

                {/* END_USER_CODE-USER_BEFORE_txtValenciaCleanSampleWt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtValenciaCleanSampleWt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtValenciaCleanSampleWt*/}

                {/* END_USER_CODE-USER_AFTER_txtValenciaCleanSampleWt*/}
                {/* START_USER_CODE-USER_BEFORE_lblGrams1*/}

                {/* END_USER_CODE-USER_BEFORE_lblGrams1*/}

                <LabelWidget
                  values={values}
                  conf={state.lblGrams1}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblGrams1*/}

                {/* END_USER_CODE-USER_AFTER_lblGrams1*/}
                {/* START_USER_CODE-USER_BEFORE_lblPct1*/}

                {/* END_USER_CODE-USER_BEFORE_lblPct1*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPct1}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPct1*/}

                {/* END_USER_CODE-USER_AFTER_lblPct1*/}
                {/* START_USER_CODE-USER_BEFORE_lblGrams2*/}

                {/* END_USER_CODE-USER_BEFORE_lblGrams2*/}

                <LabelWidget
                  values={values}
                  conf={state.lblGrams2}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblGrams2*/}

                {/* END_USER_CODE-USER_AFTER_lblGrams2*/}
                {/* START_USER_CODE-USER_BEFORE_lblPct2*/}

                {/* END_USER_CODE-USER_BEFORE_lblPct2*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPct2}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPct2*/}

                {/* END_USER_CODE-USER_AFTER_lblPct2*/}
                {/* START_USER_CODE-USER_BEFORE_txtSMKRSGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtSMKRSGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMKRSGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSMKRSGr*/}

                {/* END_USER_CODE-USER_AFTER_txtSMKRSGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtSMKRSPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtSMKRSPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMKRSPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSMKRSPct*/}

                {/* END_USER_CODE-USER_AFTER_txtSMKRSPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtCrkOrBrkShellsGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtCrkOrBrkShellsGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCrkOrBrkShellsGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCrkOrBrkShellsGr*/}

                {/* END_USER_CODE-USER_AFTER_txtCrkOrBrkShellsGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtCrkOrBrkShellsPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtCrkOrBrkShellsPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCrkOrBrkShellsPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCrkOrBrkShellsPct*/}

                {/* END_USER_CODE-USER_AFTER_txtCrkOrBrkShellsPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtSoundSplitsGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtSoundSplitsGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSoundSplitsGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSoundSplitsGr*/}

                {/* END_USER_CODE-USER_AFTER_txtSoundSplitsGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtSoundSplitsPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtSoundSplitsPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSoundSplitsPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSoundSplitsPct*/}

                {/* END_USER_CODE-USER_AFTER_txtSoundSplitsPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtDiscoloredShellsGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtDiscoloredShellsGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDiscoloredShellsGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDiscoloredShellsGr*/}

                {/* END_USER_CODE-USER_AFTER_txtDiscoloredShellsGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtDiscoloredShellsPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtDiscoloredShellsPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDiscoloredShellsPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDiscoloredShellsPct*/}

                {/* END_USER_CODE-USER_AFTER_txtDiscoloredShellsPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalSMK*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalSMK*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalSMK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalSMK*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalSMK*/}
                {/* START_USER_CODE-USER_BEFORE_txtHullBrightness*/}

                {/* END_USER_CODE-USER_BEFORE_txtHullBrightness*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtHullBrightness}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtHullBrightness*/}

                {/* END_USER_CODE-USER_AFTER_txtHullBrightness*/}
                {/* START_USER_CODE-USER_BEFORE_txtHullBrightnessPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtHullBrightnessPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtHullBrightnessPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtHullBrightnessPct*/}

                {/* END_USER_CODE-USER_AFTER_txtHullBrightnessPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtOtherKernelsGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtOtherKernelsGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOtherKernelsGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOtherKernelsGr*/}

                {/* END_USER_CODE-USER_AFTER_txtOtherKernelsGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtOtherKernelsPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtOtherKernelsPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOtherKernelsPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOtherKernelsPct*/}

                {/* END_USER_CODE-USER_AFTER_txtOtherKernelsPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtJumboGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtJumboGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtJumboGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtJumboGr*/}

                {/* END_USER_CODE-USER_AFTER_txtJumboGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtJumboPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtJumboPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtJumboPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtJumboPct*/}

                {/* END_USER_CODE-USER_AFTER_txtJumboPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtDamageSplits*/}

                {/* END_USER_CODE-USER_BEFORE_txtDamageSplits*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDamageSplits}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDamageSplits*/}

                {/* END_USER_CODE-USER_AFTER_txtDamageSplits*/}
                {/* START_USER_CODE-USER_BEFORE_txtELKGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtELKGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtELKGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtELKGr*/}

                {/* END_USER_CODE-USER_AFTER_txtELKGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtELKPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtELKPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtELKPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtELKPct*/}

                {/* END_USER_CODE-USER_AFTER_txtELKPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalDamageGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalDamageGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalDamageGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalDamageGr*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalDamageGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalDamagePct*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalDamagePct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalDamagePct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalDamagePct*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalDamagePct*/}
                {/* START_USER_CODE-USER_BEFORE_txtForeignMaterialGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtForeignMaterialGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtForeignMaterialGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtForeignMaterialGr*/}

                {/* END_USER_CODE-USER_AFTER_txtForeignMaterialGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtForeignMaterialPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtForeignMaterialPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtForeignMaterialPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtForeignMaterialPct*/}

                {/* END_USER_CODE-USER_AFTER_txtForeignMaterialPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtFreezeDamageGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtFreezeDamageGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFreezeDamageGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFreezeDamageGr*/}

                {/* END_USER_CODE-USER_AFTER_txtFreezeDamageGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtFreezeDamagePct*/}

                {/* END_USER_CODE-USER_BEFORE_txtFreezeDamagePct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFreezeDamagePct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFreezeDamagePct*/}

                {/* END_USER_CODE-USER_AFTER_txtFreezeDamagePct*/}
                {/* START_USER_CODE-USER_BEFORE_txtOtherForeignMaterials*/}

                {/* END_USER_CODE-USER_BEFORE_txtOtherForeignMaterials*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOtherForeignMaterials}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOtherForeignMaterials*/}

                {/* END_USER_CODE-USER_AFTER_txtOtherForeignMaterials*/}
                {/* START_USER_CODE-USER_BEFORE_txtConcealedRMDGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtConcealedRMDGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtConcealedRMDGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtConcealedRMDGr*/}

                {/* END_USER_CODE-USER_AFTER_txtConcealedRMDGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtConcealedRMDPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtConcealedRMDPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtConcealedRMDPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtConcealedRMDPct*/}

                {/* END_USER_CODE-USER_AFTER_txtConcealedRMDPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtLSKGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtLSKGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLSKGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLSKGr*/}

                {/* END_USER_CODE-USER_AFTER_txtLSKGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtLSKPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtLSKPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLSKPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLSKPct*/}

                {/* END_USER_CODE-USER_AFTER_txtLSKPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtKernelsREL*/}

                {/* END_USER_CODE-USER_BEFORE_txtKernelsREL*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtKernelsREL}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtKernelsREL*/}

                {/* END_USER_CODE-USER_AFTER_txtKernelsREL*/}
                {/* START_USER_CODE-USER_BEFORE_txtMoisture*/}

                {/* END_USER_CODE-USER_BEFORE_txtMoisture*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtMoisture}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtMoisture*/}

                {/* END_USER_CODE-USER_AFTER_txtMoisture*/}
                {/* START_USER_CODE-USER_BEFORE_txtKernelsRPS*/}

                {/* END_USER_CODE-USER_BEFORE_txtKernelsRPS*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtKernelsRPS}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtKernelsRPS*/}

                {/* END_USER_CODE-USER_AFTER_txtKernelsRPS*/}
                {/* START_USER_CODE-USER_BEFORE_txtMeterReading*/}

                {/* END_USER_CODE-USER_BEFORE_txtMeterReading*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtMeterReading}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtMeterReading*/}

                {/* END_USER_CODE-USER_AFTER_txtMeterReading*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalKrnls*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalKrnls*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalKrnls}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalKrnls*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalKrnls*/}
                {/* START_USER_CODE-USER_BEFORE_txtBluePanWt*/}

                {/* END_USER_CODE-USER_BEFORE_txtBluePanWt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtBluePanWt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtBluePanWt*/}

                {/* END_USER_CODE-USER_AFTER_txtBluePanWt*/}
                {/* START_USER_CODE-USER_BEFORE_txtHullsGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtHullsGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtHullsGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtHullsGr*/}

                {/* END_USER_CODE-USER_AFTER_txtHullsGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtHullsPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtHullsPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtHullsPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtHullsPct*/}

                {/* END_USER_CODE-USER_AFTER_txtHullsPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtFancyGr*/}

                {/* END_USER_CODE-USER_BEFORE_txtFancyGr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFancyGr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFancyGr*/}

                {/* END_USER_CODE-USER_AFTER_txtFancyGr*/}
                {/* START_USER_CODE-USER_BEFORE_txtFancyPct*/}

                {/* END_USER_CODE-USER_BEFORE_txtFancyPct*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFancyPct}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFancyPct*/}

                {/* END_USER_CODE-USER_AFTER_txtFancyPct*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalKernelsHulls*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalKernelsHulls*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalKernelsHulls}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalKernelsHulls*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalKernelsHulls*/}
                {/* START_USER_CODE-USER_BEFORE_lblFlavusFound*/}

                {/* END_USER_CODE-USER_BEFORE_lblFlavusFound*/}

                <LabelWidget
                  values={values}
                  conf={state.lblFlavusFound}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblFlavusFound*/}

                {/* END_USER_CODE-USER_AFTER_lblFlavusFound*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxFlavusFound*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxFlavusFound*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxFlavusFound}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxFlavusFound*/}

                {/* END_USER_CODE-USER_AFTER_chkboxFlavusFound*/}
                {/* START_USER_CODE-USER_BEFORE_txtDamagedKRS*/}

                {/* END_USER_CODE-USER_BEFORE_txtDamagedKRS*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDamagedKRS}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDamagedKRS*/}

                {/* END_USER_CODE-USER_AFTER_txtDamagedKRS*/}
                {/* START_USER_CODE-USER_BEFORE_txtELKDamage*/}

                {/* END_USER_CODE-USER_BEFORE_txtELKDamage*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtELKDamage}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtELKDamage*/}

                {/* END_USER_CODE-USER_AFTER_txtELKDamage*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxGradeCalculation*/}

              {/* END_USER_CODE-USER_AFTER_grpbxGradeCalculation*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxHMC*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxHMC*/}

              <GroupBoxWidget conf={state.grpbxHMC} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_btnConvertHMC*/}

                {/* END_USER_CODE-USER_BEFORE_btnConvertHMC*/}

                <ButtonWidget
                  conf={state.btnConvertHMC}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnConvertHMC*/}

                {/* END_USER_CODE-USER_AFTER_btnConvertHMC*/}
                {/* START_USER_CODE-USER_BEFORE_lblHMCConvertedGrades*/}

                {/* END_USER_CODE-USER_BEFORE_lblHMCConvertedGrades*/}

                <LabelWidget
                  values={values}
                  conf={state.lblHMCConvertedGrades}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblHMCConvertedGrades*/}

                {/* END_USER_CODE-USER_AFTER_lblHMCConvertedGrades*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeg*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeg*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeg}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSeg*/}

                {/* END_USER_CODE-USER_AFTER_txtSeg*/}
                {/* START_USER_CODE-USER_BEFORE_txtFM*/}

                {/* END_USER_CODE-USER_BEFORE_txtFM*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFM}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFM*/}

                {/* END_USER_CODE-USER_AFTER_txtFM*/}
                {/* START_USER_CODE-USER_BEFORE_txtLSK*/}

                {/* END_USER_CODE-USER_BEFORE_txtLSK*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLSK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLSK*/}

                {/* END_USER_CODE-USER_AFTER_txtLSK*/}
                {/* START_USER_CODE-USER_BEFORE_txtMST*/}

                {/* END_USER_CODE-USER_BEFORE_txtMST*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtMST}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtMST*/}

                {/* END_USER_CODE-USER_AFTER_txtMST*/}
                {/* START_USER_CODE-USER_BEFORE_txtSMK*/}

                {/* END_USER_CODE-USER_BEFORE_txtSMK*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSMK*/}

                {/* END_USER_CODE-USER_AFTER_txtSMK*/}
                {/* START_USER_CODE-USER_BEFORE_txtSS*/}

                {/* END_USER_CODE-USER_BEFORE_txtSS*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSS}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSS*/}

                {/* END_USER_CODE-USER_AFTER_txtSS*/}
                {/* START_USER_CODE-USER_BEFORE_txtSMKRS*/}

                {/* END_USER_CODE-USER_BEFORE_txtSMKRS*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSMKRS}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSMKRS*/}

                {/* END_USER_CODE-USER_AFTER_txtSMKRS*/}
                {/* START_USER_CODE-USER_BEFORE_txtOK*/}

                {/* END_USER_CODE-USER_BEFORE_txtOK*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOK*/}

                {/* END_USER_CODE-USER_AFTER_txtOK*/}
                {/* START_USER_CODE-USER_BEFORE_txtFRZ*/}

                {/* END_USER_CODE-USER_BEFORE_txtFRZ*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFRZ}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFRZ*/}

                {/* END_USER_CODE-USER_AFTER_txtFRZ*/}
                {/* START_USER_CODE-USER_BEFORE_txtCRMD*/}

                {/* END_USER_CODE-USER_BEFORE_txtCRMD*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCRMD}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCRMD*/}

                {/* END_USER_CODE-USER_AFTER_txtCRMD*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalKernels*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalKernels*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalKernels}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalKernels*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalKernels*/}
                {/* START_USER_CODE-USER_BEFORE_txtDAM*/}

                {/* END_USER_CODE-USER_BEFORE_txtDAM*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDAM}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDAM*/}

                {/* END_USER_CODE-USER_AFTER_txtDAM*/}
                {/* START_USER_CODE-USER_BEFORE_txtHULLS*/}

                {/* END_USER_CODE-USER_BEFORE_txtHULLS*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtHULLS}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtHULLS*/}

                {/* END_USER_CODE-USER_AFTER_txtHULLS*/}
                {/* START_USER_CODE-USER_BEFORE_txtELK*/}

                {/* END_USER_CODE-USER_BEFORE_txtELK*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtELK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtELK*/}

                {/* END_USER_CODE-USER_AFTER_txtELK*/}
                {/* START_USER_CODE-USER_BEFORE_txtFANCY*/}

                {/* END_USER_CODE-USER_BEFORE_txtFANCY*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFANCY}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFANCY*/}

                {/* END_USER_CODE-USER_AFTER_txtFANCY*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotKrnlsHulls*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotKrnlsHulls*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotKrnlsHulls}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotKrnlsHulls*/}

                {/* END_USER_CODE-USER_AFTER_txtTotKrnlsHulls*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxHMC*/}

              {/* END_USER_CODE-USER_AFTER_grpbxHMC*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxValueCalculation*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxValueCalculation*/}

              <GroupBoxWidget
                conf={state.grpbxValueCalculation}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblValueCalculation*/}

                {/* END_USER_CODE-USER_BEFORE_lblValueCalculation*/}

                <LabelWidget
                  values={values}
                  conf={state.lblValueCalculation}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblValueCalculation*/}

                {/* END_USER_CODE-USER_AFTER_lblValueCalculation*/}
                {/* START_USER_CODE-USER_BEFORE_btnCalculate*/}

                {/* END_USER_CODE-USER_BEFORE_btnCalculate*/}

                <ButtonWidget
                  conf={state.btnCalculate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCalculate*/}

                {/* END_USER_CODE-USER_AFTER_btnCalculate*/}
                {/* START_USER_CODE-USER_BEFORE_txtWtIncludingVehicle*/}

                {/* END_USER_CODE-USER_BEFORE_txtWtIncludingVehicle*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWtIncludingVehicle}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWtIncludingVehicle*/}

                {/* END_USER_CODE-USER_AFTER_txtWtIncludingVehicle*/}
                {/* START_USER_CODE-USER_BEFORE_txtWtOfVehicle*/}

                {/* END_USER_CODE-USER_BEFORE_txtWtOfVehicle*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWtOfVehicle}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWtOfVehicle*/}

                {/* END_USER_CODE-USER_AFTER_txtWtOfVehicle*/}

                {/* START_USER_CODE-USER_BEFORE_txtTKC*/}

                {/* END_USER_CODE-USER_BEFORE_txtTKC*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTKC}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTKC*/}

                {/* END_USER_CODE-USER_AFTER_txtTKC*/}





                {/* START_USER_CODE-USER_BEFORE_txtGrossWeight*/}

                {/* END_USER_CODE-USER_BEFORE_txtGrossWeight*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtGrossWeight}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtGrossWeight*/}

                {/* END_USER_CODE-USER_AFTER_txtGrossWeight*/}
                {/* START_USER_CODE-USER_BEFORE_txtForeignMaterial*/}

                {/* END_USER_CODE-USER_BEFORE_txtForeignMaterial*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtForeignMaterial}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtForeignMaterial*/}

                {/* END_USER_CODE-USER_AFTER_txtForeignMaterial*/}
                {/* START_USER_CODE-USER_BEFORE_txtWtlessFM*/}

                {/* END_USER_CODE-USER_BEFORE_txtWtlessFM*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWtlessFM}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWtlessFM*/}

                {/* END_USER_CODE-USER_AFTER_txtWtlessFM*/}
                {/* START_USER_CODE-USER_BEFORE_txtExcessMoisture*/}

                {/* END_USER_CODE-USER_BEFORE_txtExcessMoisture*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtExcessMoisture}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtExcessMoisture*/}

                {/* END_USER_CODE-USER_AFTER_txtExcessMoisture*/}
                {/* START_USER_CODE-USER_BEFORE_txtNetWeight*/}

                {/* END_USER_CODE-USER_BEFORE_txtNetWeight*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNetWeight}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNetWeight*/}

                {/* END_USER_CODE-USER_AFTER_txtNetWeight*/}
                {/* START_USER_CODE-USER_BEFORE_txtLSKvc*/}

                {/* END_USER_CODE-USER_BEFORE_txtLSKvc*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLSKvc}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLSKvc*/}

                {/* END_USER_CODE-USER_AFTER_txtLSKvc*/}
                {/* START_USER_CODE-USER_BEFORE_txtNetWtExclLSK*/}

                {/* END_USER_CODE-USER_BEFORE_txtNetWtExclLSK*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNetWtExclLSK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNetWtExclLSK*/}

                {/* END_USER_CODE-USER_AFTER_txtNetWtExclLSK*/}
                {/* START_USER_CODE-USER_BEFORE_lblPerTon*/}

                {/* END_USER_CODE-USER_BEFORE_lblPerTon*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPerTon}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPerTon*/}

                {/* END_USER_CODE-USER_AFTER_lblPerTon*/}
                {/* START_USER_CODE-USER_BEFORE_lblPerLb*/}

                {/* END_USER_CODE-USER_BEFORE_lblPerLb*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPerLb}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPerLb*/}

                {/* END_USER_CODE-USER_AFTER_lblPerLb*/}
                {/* START_USER_CODE-USER_BEFORE_txtKernelValueTon*/}

                {/* END_USER_CODE-USER_BEFORE_txtKernelValueTon*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtKernelValueTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtKernelValueTon*/}

                {/* END_USER_CODE-USER_AFTER_txtKernelValueTon*/}
                {/* START_USER_CODE-USER_BEFORE_txtKernelValueLb*/}

                {/* END_USER_CODE-USER_BEFORE_txtKernelValueLb*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtKernelValueLb}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtKernelValueLb*/}

                {/* END_USER_CODE-USER_AFTER_txtKernelValueLb*/}
                {/* START_USER_CODE-USER_BEFORE_txtELKPremiumTon*/}

                {/* END_USER_CODE-USER_BEFORE_txtELKPremiumTon*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtELKPremiumTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtELKPremiumTon*/}

                {/* END_USER_CODE-USER_AFTER_txtELKPremiumTon*/}
                {/* START_USER_CODE-USER_BEFORE_txtELKPremiumLb*/}

                {/* END_USER_CODE-USER_BEFORE_txtELKPremiumLb*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtELKPremiumLb}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtELKPremiumLb*/}

                {/* END_USER_CODE-USER_AFTER_txtELKPremiumLb*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalTon*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalTon*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalTon*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalTon*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalLb*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalLb*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalLb}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalLb*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalLb*/}
                {/* START_USER_CODE-USER_BEFORE_txtDamageTon*/}

                {/* END_USER_CODE-USER_BEFORE_txtDamageTon*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDamageTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDamageTon*/}

                {/* END_USER_CODE-USER_AFTER_txtDamageTon*/}
                {/* START_USER_CODE-USER_BEFORE_txtDamageLbs*/}

                {/* END_USER_CODE-USER_BEFORE_txtDamageLbs*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDamageLbs}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDamageLbs*/}

                {/* END_USER_CODE-USER_AFTER_txtDamageLbs*/}
                {/* START_USER_CODE-USER_BEFORE_txtAccessFMTon*/}

                {/* END_USER_CODE-USER_BEFORE_txtAccessFMTon*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAccessFMTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAccessFMTon*/}

                {/* END_USER_CODE-USER_AFTER_txtAccessFMTon*/}
                {/* START_USER_CODE-USER_BEFORE_txtAccessFMLb*/}

                {/* END_USER_CODE-USER_BEFORE_txtAccessFMLb*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAccessFMLb}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAccessFMLb*/}

                {/* END_USER_CODE-USER_AFTER_txtAccessFMLb*/}
                {/* START_USER_CODE-USER_BEFORE_txtExcessSplitsTon*/}

                {/* END_USER_CODE-USER_BEFORE_txtExcessSplitsTon*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtExcessSplitsTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtExcessSplitsTon*/}

                {/* END_USER_CODE-USER_AFTER_txtExcessSplitsTon*/}
                {/* START_USER_CODE-USER_BEFORE_txtExcessSplitsLb*/}

                {/* END_USER_CODE-USER_BEFORE_txtExcessSplitsLb*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtExcessSplitsLb}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtExcessSplitsLb*/}

                {/* END_USER_CODE-USER_AFTER_txtExcessSplitsLb*/}
                {/* START_USER_CODE-USER_BEFORE_txtAFlavusTon*/}

                {/* END_USER_CODE-USER_BEFORE_txtAFlavusTon*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAFlavusTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAFlavusTon*/}

                {/* END_USER_CODE-USER_AFTER_txtAFlavusTon*/}
                {/* START_USER_CODE-USER_BEFORE_txtAFlavusLb*/}

                {/* END_USER_CODE-USER_BEFORE_txtAFlavusLb*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAFlavusLb}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAFlavusLb*/}

                {/* END_USER_CODE-USER_AFTER_txtAFlavusLb*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalDiscountsTon*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalDiscountsTon*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalDiscountsTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalDiscountsTon*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalDiscountsTon*/}
                {/* START_USER_CODE-USER_BEFORE_txtlblTotalDiscountsLb*/}

                {/* END_USER_CODE-USER_BEFORE_txtlblTotalDiscountsLb*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtlblTotalDiscountsLb}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtlblTotalDiscountsLb*/}

                {/* END_USER_CODE-USER_AFTER_txtlblTotalDiscountsLb*/}
                {/* START_USER_CODE-USER_BEFORE_txtNetValPerTonExclSLK*/}

                {/* END_USER_CODE-USER_BEFORE_txtNetValPerTonExclSLK*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNetValPerTonExclSLK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNetValPerTonExclSLK*/}

                {/* END_USER_CODE-USER_AFTER_txtNetValPerTonExclSLK*/}
                {/* START_USER_CODE-USER_BEFORE_txtValPerPoundExclSLK*/}

                {/* END_USER_CODE-USER_BEFORE_txtValPerPoundExclSLK*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtValPerPoundExclSLK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtValPerPoundExclSLK*/}

                {/* END_USER_CODE-USER_AFTER_txtValPerPoundExclSLK*/}
                {/* START_USER_CODE-USER_BEFORE_lblPerLb2*/}

                {/* END_USER_CODE-USER_BEFORE_lblPerLb2*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPerLb2}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPerLb2*/}

                {/* END_USER_CODE-USER_AFTER_lblPerLb2*/}
                {/* START_USER_CODE-USER_BEFORE_txtValPerPoundInclSLK*/}

                {/* END_USER_CODE-USER_BEFORE_txtValPerPoundInclSLK*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtValPerPoundInclSLK}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtValPerPoundInclSLK*/}

                {/* END_USER_CODE-USER_AFTER_txtValPerPoundInclSLK*/}
                {/* START_USER_CODE-USER_BEFORE_txtOI*/}

                {/* END_USER_CODE-USER_BEFORE_txtOI*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOI}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOI*/}

                {/* END_USER_CODE-USER_AFTER_txtOI*/}
                {/* START_USER_CODE-USER_BEFORE_txtH*/}

                {/* END_USER_CODE-USER_BEFORE_txtH*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtH}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtH*/}

                {/* END_USER_CODE-USER_AFTER_txtH*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalLoadVal*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalLoadVal*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalLoadVal}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalLoadVal*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalLoadVal*/}
                {/* START_USER_CODE-USER_BEFORE_txtG*/}

                {/* END_USER_CODE-USER_BEFORE_txtG*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtG}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtG*/}

                {/* END_USER_CODE-USER_AFTER_txtG*/}
                {/* START_USER_CODE-USER_BEFORE_lblPerLb3*/}

                {/* END_USER_CODE-USER_BEFORE_lblPerLb3*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPerLb3}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPerLb3*/}

                {/* END_USER_CODE-USER_AFTER_lblPerLb3*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxValueCalculation*/}

              {/* END_USER_CODE-USER_AFTER_grpbxValueCalculation*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxSignature*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSignature*/}

              <GroupBoxWidget conf={state.grpbxSignature} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtSignOfInspector*/}

                {/* END_USER_CODE-USER_BEFORE_txtSignOfInspector*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSignOfInspector}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSignOfInspector*/}

                {/* END_USER_CODE-USER_AFTER_txtSignOfInspector*/}
                {/* START_USER_CODE-USER_BEFORE_ddSignOfWeigher*/}

                {/* END_USER_CODE-USER_BEFORE_ddSignOfWeigher*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSignOfWeigher}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSignOfWeigher*/}

                {/* END_USER_CODE-USER_AFTER_ddSignOfWeigher*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSignature*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSignature*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget> */}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxSendBack*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxSendBack*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxSendBack}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxSendBack*/}

                {/* END_USER_CODE-USER_AFTER_chkboxSendBack*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={state.btnExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget> */}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_FV95Residue*/}

              {/* END_USER_CODE-USER_AFTER_FV95Residue*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_FV95Residue);
