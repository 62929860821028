/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import { Buffer } from 'buffer';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  DateWidget,
  LabelWidget,
  CheckboxWidget,
  TextAreaWidget,
  WhiteSpaceWidget,
  LinkWidget,
  getData,
  disable,
  enable,
  setValue,
  getValue,
  hide,
  show,
  setData,
  goTo
} from "../../shared/WindowImports";

import "./ContractProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import Loading from "../../../Loader/Loading";
import { ContractManagementService } from "../Service/ContractManagementService";
import { CommonKeys, RegEx, Application } from './../../Common/Constants';
// To Share Data
import CommonContext from '../../Store/CommonContext';
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function ContractManagement_ContractProfile(props) {
  const commonContext = useContext(CommonContext);
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  let compId = sessionStorage.getItem('compId');
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "Contract Profile",
    windowName: "ContractProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.ContractProfile",
    // START_USER_CODE-USER_ContractProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Contract Profile",
      scrCode: "PN1010A"
    },
    chckbxPrintCmmnt: {
      name: "chckbxPrintCmmnt",
      type: "CheckBoxWidget",
      parent: "grpbxCntrctCntrl02",
      Label: "Print Comments",
      ofTypeDomain: "d_boolean",
    },
    grpbxContrctFarm: {
      name: "grpbxContrctFarm",
      type: "GroupBoxWidget",
      parent: "grpbxContrctFarm",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxContrctFarm_PROPERTIES
      // END_USER_CODE-USER_grpbxContrctFarm_PROPERTIES
    },
    lblCropYear2: {
      name: "lblCropYear2",
      type: "LabelWidget",
      parent: "grpbxCmnts",
      Label: "lblCropYear",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCropYear2_PROPERTIES

      // END_USER_CODE-USER_lblCropYear2_PROPERTIES
    },
    btnClrAdvnc: {
      name: "btnClrAdvnc",
      type: "ButtonWidget",
      parent: "grpBxVendr",
      Label: "Clear Advance",
      CharWidth: "43",
    },
    // END_USER_CODE-USER_ContractProfile_PROPERTIES
    btnAccDist: {
      name: "btnAccDist",
      type: "ButtonWidget",
      parent: "btnCntnr",
      Label: "Acct Dist",
      CharWidth: "21",
      // START_USER_CODE-USER_btnAccDist_PROPERTIES

      // END_USER_CODE-USER_btnAccDist_PROPERTIES
    },
    btnApprv: {
      name: "btnApprv",
      type: "ButtonWidget",
      parent: "btnCntnr",
      Label: "Approve",
      CharWidth: "17",
      // START_USER_CODE-USER_btnApprv_PROPERTIES

      // END_USER_CODE-USER_btnApprv_PROPERTIES
    },
    btnChemiclAgreemnt: {
      name: "btnChemiclAgreemnt",
      type: "ButtonWidget",
      parent: "grpBxVendr",
      Label: "Chemical Agreement",
      CharWidth: "37",
      // START_USER_CODE-USER_btnChemiclAgreemnt_PROPERTIES

      // END_USER_CODE-USER_btnChemiclAgreemnt_PROPERTIES
    },
    btnCreate: {
      name: "btnCreate",
      type: "ButtonWidget",
      parent: "btnCntnr",
      Label: "Create",
      CharWidth: "15",
      // START_USER_CODE-USER_btnCreate_PROPERTIES

      // END_USER_CODE-USER_btnCreate_PROPERTIES
    },
    btnDeactivate: {
      name: "btnDeactivate",
      type: "ButtonWidget",
      parent: "btnCntnr",
      Label: "Deactivate",
      CharWidth: "22",
      // START_USER_CODE-USER_btnDeactivate_PROPERTIES

      // END_USER_CODE-USER_btnDeactivate_PROPERTIES
    },
    btnEmail: {
      name: "btnEmail",
      type: "ButtonWidget",
      parent: "btnCntnr",
      Label: "Email",
      CharWidth: "13",
      // START_USER_CODE-USER_btnEmail_PROPERTIES

      // END_USER_CODE-USER_btnEmail_PROPERTIES
    },
    btnEnblDate: {
      name: "btnEnblDate",
      type: "ButtonWidget",
      parent: "grpbxContrctCntrl01",
      Label: "Enable Date (Dev/Test Only)",
      CharWidth: "54",
      // START_USER_CODE-USER_btnEnblDate_PROPERTIES

      // END_USER_CODE-USER_btnEnblDate_PROPERTIES
    },
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "btnCntnr",
      Label: "Exit",
      CharWidth: "11",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    btnFlxMktPcngDet: {
      name: "btnFlxMktPcngDet",
      type: "ButtonWidget",
      parent: "grpbxFlex",
      Label: "Flex Market Pricing Details",
      CharWidth: "54",
      // START_USER_CODE-USER_btnFlxMktPcngDet_PROPERTIES

      // END_USER_CODE-USER_btnFlxMktPcngDet_PROPERTIES
    },
    btnFlxMktWrkSht: {
      name: "btnFlxMktWrkSht",
      type: "ButtonWidget",
      parent: "grpbxFlex",
      Label: "Flex Market Work Sheet",
      CharWidth: "45",
      // START_USER_CODE-USER_btnFlxMktWrkSht_PROPERTIES

      // END_USER_CODE-USER_btnFlxMktWrkSht_PROPERTIES
    },
    btnFmAgreement: {
      name: "btnFmAgreement",
      type: "ButtonWidget",
      parent: "grpBxVendr",
      Label: "FM Agreement",
      CharWidth: "27",
      // START_USER_CODE-USER_btnFmAgreement_PROPERTIES

      // END_USER_CODE-USER_btnFmAgreement_PROPERTIES
    },
    btnLookup: {
      name: "btnLookup",
      type: "ButtonWidget",
      parent: "grpbxGrpingNo",
      Label: "...",
      CharWidth: "9",
      // START_USER_CODE-USER_btnLookup_PROPERTIES

      // END_USER_CODE-USER_btnLookup_PROPERTIES
    },
    btnLookupFrm: {
      name: "btnLookupFrm",
      type: "ButtonWidget",
      parent: "grpBxContrctDetls",
      Label: "...",
      CharWidth: "9",
      // START_USER_CODE-USER_btnLookupFrm_PROPERTIES

      // END_USER_CODE-USER_btnLookupFrm_PROPERTIES
    },
    btnPopUp: {
      name: "btnPopUp",
      type: "ButtonWidget",
      parent: "grpBxVendr",
      Label: "...",
      CharWidth: "9",
      // START_USER_CODE-USER_btnPopUp_PROPERTIES

      // END_USER_CODE-USER_btnPopUp_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "btnCntnr",
      Label: "Print",
      CharWidth: "13",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnPymnt: {
      name: "btnPymnt",
      type: "ButtonWidget",
      parent: "btnCntnr",
      Label: "Payments",
      CharWidth: "19",
      // START_USER_CODE-USER_btnPymnt_PROPERTIES

      // END_USER_CODE-USER_btnPymnt_PROPERTIES
    },
    btnRescan: {
      name: "btnRescan",
      type: "ButtonWidget",
      parent: "btnCntnr",
      Label: "Rescan",
      CharWidth: "15",
      // START_USER_CODE-USER_btnRescan_PROPERTIES

      // END_USER_CODE-USER_btnRescan_PROPERTIES
    },
    btnSeedGrwrAgreemnt: {
      name: "btnSeedGrwrAgreemnt",
      type: "ButtonWidget",
      parent: "grpBxVendr",
      Label: "Seed Grower Agreement",
      CharWidth: "43",
    },
    btnSetpExcPrm: {
      name: "btnSetpExcPrm",
      type: "ButtonWidget",
      parent: "grpbxCntrctCntrl02",
      Label: "Setup Exception Premium",
      CharWidth: "46",
    },
    btnSign: {
      name: "btnSign",
      type: "ButtonWidget",
      parent: "btnCntnr",
      Label: "Sign",
      CharWidth: "11",
      // START_USER_CODE-USER_btnSign_PROPERTIES

      // END_USER_CODE-USER_btnSign_PROPERTIES
    },
    btnViewUpldCntrct: {
      name: "btnViewUpldCntrct",
      type: "ButtonWidget",
      parent: "grpbxCntrctCntrl02",
      Label: "View Uploaded Contract",
      CharWidth: "45",
      // START_USER_CODE-USER_btnViewUpldCntrct_PROPERTIES

      // END_USER_CODE-USER_btnViewUpldCntrct_PROPERTIES
    },
    btnVndrLookupFrm2: {
      name: "btnVndrLookupFrm2",
      type: "ButtonWidget",
      parent: "grpBxContrctDetls",
      Label: "...",
      CharWidth: "9",
      // START_USER_CODE-USER_btnVndrLookupFrm2_PROPERTIES

      // END_USER_CODE-USER_btnVndrLookupFrm2_PROPERTIES
    },
    btnVoid: {
      name: "btnVoid",
      type: "ButtonWidget",
      parent: "btnCntnr",
      Label: "Void",
      CharWidth: "11",
      // START_USER_CODE-USER_btnVoid_PROPERTIES

      // END_USER_CODE-USER_btnVoid_PROPERTIES
    },
    chckbxAlwRescn: {
      name: "chckbxAlwRescn",
      type: "CheckBoxWidget",
      parent: "grpbxCntrctCntrl02",
      Label: "Allow Rescan",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chckbxAlwRescn_PROPERTIES

      // END_USER_CODE-USER_chckbxAlwRescn_PROPERTIES
    },
    chckbxHrvst: {
      name: "chckbxHrvst",
      type: "CheckBoxWidget",
      parent: "grpBxContrctDetls",
      Label: "Harvest",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chckbxHrvst_PROPERTIES

      // END_USER_CODE-USER_chckbxHrvst_PROPERTIES
    },
    chckbxMkFrmSpcfc: {
      name: "chckbxMkFrmSpcfc",
      type: "CheckBoxWidget",
      parent: "grpbxStsDates",
      Label: "Make Farm Specific",
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chckbxMkFrmSpcfc_PROPERTIES

      // END_USER_CODE-USER_chckbxMkFrmSpcfc_PROPERTIES
    },
    chckbxOrgnc: {
      name: "chckbxOrgnc",
      type: "CheckBoxWidget",
      parent: "grpbxFarmPlntngIntn",
      Label: "Organic",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chckbxOrgnc_PROPERTIES

      // END_USER_CODE-USER_chckbxOrgnc_PROPERTIES
    },
    chkbxFreezeDmgRng: {
      name: "chkbxFreezeDmgRng",
      type: "CheckBoxWidget",
      parent: "grpBxContrctDetls",
      Label: "Freeze Damage Range",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbxFreezeDmgRng_PROPERTIES

      // END_USER_CODE-USER_chkbxFreezeDmgRng_PROPERTIES
    },
    chkbxHrvst2: {
      name: "chkbxHrvst2",
      type: "CheckBoxWidget",
      parent: "grpBxContrctDetls",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbxHrvst2_PROPERTIES

      // END_USER_CODE-USER_chkbxHrvst2_PROPERTIES
    },
    chkBxSeedGrowr: {
      name: "chkBxSeedGrowr",
      type: "CheckBoxWidget",
      parent: "grpbxFarmPlntngIntn",
      Label: "Seed Grower",

      ofTypeDomain: "d_boolean",
    },
    chkSeedGrower2010: {
      name: "chkSeedGrower2010",
      type: "CheckBoxWidget",
      parent: "grpbxFarmPlntngIntn",
      Label: "Seed Grower",

      ofTypeDomain: "d_boolean",
    },
    contrctControl: {
      name: "contrctControl",
      type: "LabelWidget",
      parent: "grpbxContrctControl",
      Label: "Contract Control",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_contrctControl_PROPERTIES

      // END_USER_CODE-USER_contrctControl_PROPERTIES
    },
    date: {
      name: "date",
      type: "DateWidget",
      parent: "grpbxContrctCntrl01",
      Label: "Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_date_PROPERTIES
      ReadOnly: true,
      Enabled: false
      // END_USER_CODE-USER_date_PROPERTIES
    },
    ddDlvryPrdDesc: {
      name: "ddDlvryPrdDesc",
      type: "DropDownFieldWidget",
      parent: "grpBxDlvryPrdDesc",
      Label: "Delivery Period Description:",
      DataProviderForDropDown: "inline",
      Options: '',
      // START_USER_CODE-USER_ddDlvryPrdDesc_PROPERTIES

      // END_USER_CODE-USER_ddDlvryPrdDesc_PROPERTIES
    },
    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxContrctCntrl01",
      Label: "Location:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation_PROPERTIES

      // END_USER_CODE-USER_ddLocation_PROPERTIES
    },
    ddOleic: {
      name: "ddOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmPlntngIntn",
      Label: "Oleic:",
      DataProviderForDropDown: "inline",
      Options: "High:H, Mid:M, None:N",
      ofTypeDomain: "d_String",
    },
    ddPeaNtVrty: {
      name: "ddPeaNtVrty",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmPlntngIntn",
      Label: "Peanut Variety:",
      DataProviderForDropDown: "inline",
      Options: '',
      optionMaxLength: 15
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmPlntngIntn",
      Label: "Peanut Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
    },
    ddPrc: {
      name: "ddPrc",
      type: "DropDownFieldWidget",
      parent: "grpBxContrctDetls",
      Label: "Price",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      Options: '',
      optionMaxLength: 25,
    },
    ddPrice: {
      name: "ddPrice",
      type: "DropDownFieldWidget",
      parent: "grpBxContrctDetls",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      // START_USER_CODE-USER_ddPrice_PROPERTIES
      optionMaxLength: 25,
      // END_USER_CODE-USER_ddPrice_PROPERTIES
    },
    ddRemit: {
      name: "ddRemit",
      type: "DropDownFieldWidget",
      parent: "grpBxVendr",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      optionMaxLength: 45,
      // START_USER_CODE-USER_ddRemit_PROPERTIES

      // END_USER_CODE-USER_ddRemit_PROPERTIES
    },
    ddSegType: {
      name: "ddSegType",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmPlntngIntn",
      Label: "Seg Type:",
      DataProviderForDropDown: "inline",
      Options: "Seg 1:1, Seg 2:2, Seg 3:3",
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSegType_PROPERTIES

      // END_USER_CODE-USER_ddSegType_PROPERTIES
    },
    ddStat2: {
      name: "ddStat2",
      type: "DropDownFieldWidget",
      parent: "grpbxLienHldr",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      Options: '',
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddStat2_PROPERTIES

      // END_USER_CODE-USER_ddStat2_PROPERTIES
    },
    ddType: {
      name: "ddType",
      type: "DropDownFieldWidget",
      parent: "grpbxContrctCntrl01",
      Label: "Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddType_PROPERTIES

      // END_USER_CODE-USER_ddType_PROPERTIES
    },
    // ddVndr: {
    //   name: "ddVndr",
    //   type: "DropDownFieldWidget",
    //   parent: "grpBxVendr",
    //   DataProviderForDropDown: "inline",
    //   HasLabel: false,
    //   Options: '',
    //   // START_USER_CODE-USER_ddVndr_PROPERTIES

    //   // END_USER_CODE-USER_ddVndr_PROPERTIES
    // },
    txtVndr: {
      name: "txtVndr",
      type: "TextBoxWidget",
      parent: "grpBxVendr",
      HasLabel: false,
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVndr_PROPERTIES
      // END_USER_CODE-USER_txtVndr_PROPERTIES
    },
    dtEnd: {
      name: "dtEnd",
      type: "DateWidget",
      parent: "grpBxContrctDetls",
      Label: "End Date",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_dtEnd_PROPERTIES
      maxDate: "dtStart"
      // END_USER_CODE-USER_dtEnd_PROPERTIES
    },
    dtEnd2: {
      name: "dtEnd2",
      type: "DateWidget",
      parent: "grpBxContrctDetls",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_dtEnd2_PROPERTIES
      maxDate: "dtStrt2"
      // END_USER_CODE-USER_dtEnd2_PROPERTIES
    },
    dtHrvstCmplt: {
      name: "dtHrvstCmplt",
      type: "DateWidget",
      parent: "grpbxStsDates",
      Label: "Harvested Complete Date: mm/dd/yyyy",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_dtHrvstCmplt_PROPERTIES

      // END_USER_CODE-USER_dtHrvstCmplt_PROPERTIES
    },
    dtStart: {
      name: "dtStart",
      type: "DateWidget",
      parent: "grpBxContrctDetls",
      Label: "Start Date",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_dtStart_PROPERTIES
      minDate: "dtEnd"
      // END_USER_CODE-USER_dtStart_PROPERTIES
    },
    dtStrt2: {
      name: "dtStrt2",
      type: "DateWidget",
      parent: "grpBxContrctDetls",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_string",
      // START_USER_CODE-USER_dtStrt2_PROPERTIES
      minDate: "dtEnd2"
      // END_USER_CODE-USER_dtStrt2_PROPERTIES
    },
    grpbx: {
      name: "grpbx",
      type: "LabelWidget",
      parent: "grpbxLienHldr",
      Label: "Lienholders",
      ColSpan: "10",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_grpbx_PROPERTIES

      // END_USER_CODE-USER_grpbx_PROPERTIES
    },
    grpbxCntrctCntrl02: {
      name: "grpbxCntrctCntrl02",
      type: "GroupBoxWidget",
      parent: "grpbxContrctControl",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxCntrctCntrl02_PROPERTIES

      // END_USER_CODE-USER_grpbxCntrctCntrl02_PROPERTIES
    },
    grpbxContrctCntrl01: {
      name: "grpbxContrctCntrl01",
      type: "GroupBoxWidget",
      parent: "grpbxContrctControl",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxContrctCntrl01_PROPERTIES

      // END_USER_CODE-USER_grpbxContrctCntrl01_PROPERTIES
    },
    grpbxFlex: {
      id: "grpbxFlex",
      name: "grpbxFlex",
      type: "GroupBoxWidget",
      parent: "grpBxContrctDetls",
      ColSpan: "12",
      Height: "",
      Width: "",
      ColsForTabLandscape: "8",
      HasLabel: false,
      Cols: "8",
      ColsForTabPotrait: "8",
      ColsForLargeDesktop: "8",
      // START_USER_CODE-USER_grpbxFlex_PROPERTIES

      // END_USER_CODE-USER_grpbxFlex_PROPERTIES
    },
    grpbxGrpingNo: {
      name: "grpbxGrpingNo",
      type: "GroupBoxWidget",
      parent: "grpbxStsDates",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxGrpingNo_PROPERTIES

      // END_USER_CODE-USER_grpbxGrpingNo_PROPERTIES
    },
    grpbxHdingVndr: {
      name: "grpbxHdingVndr",
      type: "LabelWidget",
      parent: "grpBxVendr",
      Label: "Vendor",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_grpbxHdingVndr_PROPERTIES

      // END_USER_CODE-USER_grpbxHdingVndr_PROPERTIES
    },
    lblAcres: {
      name: "lblAcres",
      type: "LabelWidget",
      parent: "grpbxFarmPlntngIntn",
      Label: "Acres",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAcres_PROPERTIES

      // END_USER_CODE-USER_lblAcres_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "btnCntnr",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAvgYld: {
      name: "lblAvgYld",
      type: "LabelWidget",
      parent: "grpbxFarmPlntngIntn",
      Label: "Avg Yield",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAvgYld_PROPERTIES

      // END_USER_CODE-USER_lblAvgYld_PROPERTIES
    },
    lblBasis: {
      name: "lblBasis",
      type: "LabelWidget",
      parent: "grpBxContrctDetls",
      Label: "Basis",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBasis_PROPERTIES

      // END_USER_CODE-USER_lblBasis_PROPERTIES
    },
    lblChngdBy: {
      name: "lblChngdBy",
      type: "LabelWidget",
      parent: "btnCntnr",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChngdBy_PROPERTIES

      // END_USER_CODE-USER_lblChngdBy_PROPERTIES
    },
    lblCllcAdv: {
      name: "lblCllcAdv",
      type: "LabelWidget",
      parent: "grpBxVendr",
      Label: "Collection Advance",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCllcAdv_PROPERTIES

      // END_USER_CODE-USER_lblCllcAdv_PROPERTIES
    },
    lblCntrctDetls: {
      name: "lblCntrctDetls",
      type: "LabelWidget",
      parent: "grpBxContrctDetls",
      Label: "Contract Details",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCntrctDetls_PROPERTIES

      // END_USER_CODE-USER_lblCntrctDetls_PROPERTIES
    },
    lblFlexMarketPricing: {
      name: "lblFlexMarketPricing",
      type: "LabelWidget",
      parent: "grpbxFlex",
      Label: "Flex Market Pricing",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFlexMarketPricing_PROPERTIES

      // END_USER_CODE-USER_lblFlexMarketPricing_PROPERTIES
    },
    lblFrm: {
      name: "lblFrm",
      type: "LabelWidget",
      parent: "grpBxContrctDetls",
      Label: "Firm",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFrm_PROPERTIES

      // END_USER_CODE-USER_lblFrm_PROPERTIES
    },
    lblFrmPlntIntn: {
      name: "lblFrmPlntIntn",
      type: "LabelWidget",
      parent: "grpbxFarmPlntngIntn",
      Label: "Farm Planting Intentions",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFrmPlntIntn_PROPERTIES

      // END_USER_CODE-USER_lblFrmPlntIntn_PROPERTIES
    },
    lblMktPrcng: {
      name: "lblMktPrcng",
      type: "TextBoxWidget",
      parent: "grpbxFlex",
      ColSpan: "5",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_lblMktPrcng_PROPERTIES
      // Enabled:false,
      // END_USER_CODE-USER_lblMktPrcng_PROPERTIES
    },
    lblRemit: {
      name: "lblRemit",
      type: "LabelWidget",
      parent: "grpBxVendr",
      Label: "Remit To",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRemit_PROPERTIES

      // END_USER_CODE-USER_lblRemit_PROPERTIES
    },
    lblSignAdvnc: {
      name: "lblSignAdvnc",
      type: "LabelWidget",
      parent: "grpBxVendr",
      Label: "Signing Advance",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSignAdvnc_PROPERTIES

      // END_USER_CODE-USER_lblSignAdvnc_PROPERTIES
    },
    lblStsDates: {
      name: "lblStsDates",
      type: "LabelWidget",
      parent: "grpbxStsDates",
      Label: "Status & Dates",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblStsDates_PROPERTIES

      // END_USER_CODE-USER_lblStsDates_PROPERTIES
    },
    lblVndr: {
      name: "lblVndr",
      type: "LabelWidget",
      parent: "grpBxVendr",
      Label: "Vendor",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVndr_PROPERTIES

      // END_USER_CODE-USER_lblVndr_PROPERTIES
    },
    lienHldr2: {
      name: "lienHldr2",
      type: "TextBoxWidget",
      parent: "grpbxLienHldr",
      ColSpan: "2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lienHldr2_PROPERTIES

      // END_USER_CODE-USER_lienHldr2_PROPERTIES
    },
    mmddyy: {
      name: "mmddyy",
      type: "LabelWidget",
      parent: "grpbxContrctCntrl01",
      Label: "mm/dd/yyyy",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_mmddyy_PROPERTIES

      // END_USER_CODE-USER_mmddyy_PROPERTIES
    },
    txt211concordia: {
      name: "txt211concordia",
      type: "TextBoxWidget",
      parent: "grpBxVendr",
      Label: "211 Concordia:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txt211concordia_PROPERTIES

      // END_USER_CODE-USER_txt211concordia_PROPERTIES
    },
    txt211Gpc: {
      name: "txt211Gpc",
      type: "TextBoxWidget",
      parent: "grpBxVendr",
      Label: "211 GPC:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txt211Gpc_PROPERTIES

      // END_USER_CODE-USER_txt211Gpc_PROPERTIES
    },
    txtAdd2: {
      name: "txtAdd2",
      type: "TextBoxWidget",
      parent: "grpbxLienHldr",
      ColSpan: "3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAdd2_PROPERTIES

      // END_USER_CODE-USER_txtAdd2_PROPERTIES
    },
    txtAddr: {
      name: "txtAddr",
      type: "TextBoxWidget",
      parent: "grpbxLienHldr",
      Label: "Address",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddr_PROPERTIES

      // END_USER_CODE-USER_txtAddr_PROPERTIES
    },
    txtApp2: {
      name: "txtApp2",
      type: "TextBoxWidget",
      parent: "grpBxContrctDetls",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtApp2_PROPERTIES

      // END_USER_CODE-USER_txtApp2_PROPERTIES
    },
    txtApplied: {
      name: "txtApplied",
      type: "TextBoxWidget",
      parent: "grpBxContrctDetls",
      Label: "Applied",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtApplied_PROPERTIES

      // END_USER_CODE-USER_txtApplied_PROPERTIES
    },
    txtarCmnnts: {
      name: "txtarCmnnts",
      type: "TextAreaWidget",
      parent: "grpbxCmnts",
      Label: "Comments",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtarCmnnts_PROPERTIES

      // END_USER_CODE-USER_txtarCmnnts_PROPERTIES
    },
    txtarExcPrm: {
      name: "txtarExcPrm",
      type: "TextAreaWidget",
      parent: "grpbxCntrctCntrl02",
      Label: "Exception Premiums:",
      Enabled: false,
      Readonly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtAvgYld: {
      name: "txtAvgYld",
      type: "TextBoxWidget",
      parent: "grpbxFarmPlntngIntn",
      Label: "Avg. Yield/Total Acres:",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
    },
    txtAvlLbs: {
      name: "txtAvlLbs",
      type: "TextBoxWidget",
      parent: "grpbxFlex",
      Label: "Available Lbs:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtAvlLbs_PROPERTIES

      // END_USER_CODE-USER_txtAvlLbs_PROPERTIES
    },
    txtbxMax: {
      name: "txtbxMax",
      type: "TextBoxWidget",
      parent: "grpBxContrctDetls",
      Label: "Max %",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_int",
      // START_USER_CODE-USER_txtbxMax_PROPERTIES

      // END_USER_CODE-USER_txtbxMax_PROPERTIES
    },
    txtbxMin: {
      name: "txtbxMin",
      type: "TextBoxWidget",
      parent: "grpBxContrctDetls",
      Label: "Min %",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_int",
    },
    txtCity: {
      name: "txtCity",
      type: "TextBoxWidget",
      parent: "grpbxLienHldr",
      Label: "City",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCity_PROPERTIES

      // END_USER_CODE-USER_txtCity_PROPERTIES
    },
    txtCity2: {
      name: "txtCity2",
      type: "TextBoxWidget",
      parent: "grpbxLienHldr",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCity2_PROPERTIES

      // END_USER_CODE-USER_txtCity2_PROPERTIES
    },
    txtCllcAdv: {
      name: "txtCllcAdv",
      type: "TextBoxWidget",
      parent: "grpBxVendr",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtCllcAdv_PROPERTIES

      // END_USER_CODE-USER_txtCllcAdv_PROPERTIES
    },
    txtCntrctno2: {
      name: "txtCntrctno2",
      type: "TextBoxWidget",
      parent: "grpbxContrctCntrl01",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtCntrctno2_PROPERTIES

      // END_USER_CODE-USER_txtCntrctno2_PROPERTIES
    },
    txtCntrPivtAcrs: {
      name: "txtCntrPivtAcrs",
      type: "TextBoxWidget",
      parent: "grpbxFarmPlntngIntn",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCntrPivtAcrs_PROPERTIES

      // END_USER_CODE-USER_txtCntrPivtAcrs_PROPERTIES
    },
    txtCntrPivtAvgYld: {
      name: "txtCntrPivtAvgYld",
      type: "TextBoxWidget",
      parent: "grpbxFarmPlntngIntn",
      Label: "Center Pivot:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
    },
    txtContrctNo: {
      name: "txtContrctNo",
      type: "TextBoxWidget",
      parent: "grpbxContrctCntrl01",
      Label: "Contract #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtContrctNo_PROPERTIES

      // END_USER_CODE-USER_txtContrctNo_PROPERTIES
    },
    txtDel: {
      name: "txtDel",
      type: "LabelWidget",
      parent: "grpBxContrctDetls",
      Label: "Delivery",
      ColSpan: "10",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDel_PROPERTIES

      // END_USER_CODE-USER_txtDel_PROPERTIES
    },
    dtMtchEndDate: {
      name: "dtMtchEndDate",
      type: "DateWidget",
      parent: "grpBxContrctDetls",
      Label: "Match End Date:",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_date",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_date_PROPERTIES
      // END_USER_CODE-USER_date_PROPERTIES

    },
    txtDryLndAcres: {
      name: "txtDryLndAcres",
      type: "TextBoxWidget",
      parent: "grpbxFarmPlntngIntn",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDryLndAcres_PROPERTIES

      // END_USER_CODE-USER_txtDryLndAcres_PROPERTIES
    },
    txtDryLndAvgYld: {
      name: "txtDryLndAvgYld",
      type: "TextBoxWidget",
      parent: "grpbxFarmPlntngIntn",
      Label: "Dry Land:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDryLndAvgYld_PROPERTIES

      // END_USER_CODE-USER_txtDryLndAvgYld_PROPERTIES
    },
    txtDtApprv: {
      name: "txtDtApprv",
      type: "TextBoxWidget",
      parent: "grpbxStsDates",
      Label: "Date Approved:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtDtApprv_PROPERTIES

      // END_USER_CODE-USER_txtDtApprv_PROPERTIES
    },
    txtDtClose: {
      name: "txtDtClose",
      type: "TextBoxWidget",
      parent: "grpbxStsDates",
      Label: "Date Closed:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtDtClose_PROPERTIES

      // END_USER_CODE-USER_txtDtClose_PROPERTIES
    },
    txtDtCncl: {
      name: "txtDtCncl",
      type: "TextBoxWidget",
      parent: "grpbxStsDates",
      Label: "Date cancelled:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtDtCncl_PROPERTIES

      // END_USER_CODE-USER_txtDtCncl_PROPERTIES
    },
    txtDtSign: {
      name: "txtDtSign",
      type: "TextBoxWidget",
      parent: "grpbxStsDates",
      Label: "Date Signed:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true,
      // START_USER_CODE-USER_txtDtSign_PROPERTIES

      // END_USER_CODE-USER_txtDtSign_PROPERTIES
    },
    txtDtVoided: {
      name: "txtDtVoided",
      type: "TextBoxWidget",
      parent: "grpbxStsDates",
      Label: "Date Voided:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtDtVoided_PROPERTIES

      // END_USER_CODE-USER_txtDtVoided_PROPERTIES
    },
    txtEmail: {
      name: "txtEmail",
      type: "TextBoxWidget",
      parent: "grpBxVendr",
      Label: "Email:",
      ColSpan: "4",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtEmail_PROPERTIES

      // END_USER_CODE-USER_txtEmail_PROPERTIES
    },
    txtEntryDt: {
      name: "txtEntryDt",
      type: "TextBoxWidget",
      parent: "grpbxStsDates",
      Label: "Entry Date:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtEntryDt_PROPERTIES

      // END_USER_CODE-USER_txtEntryDt_PROPERTIES
    },
    txtGrpng: {
      name: "txtGrpng",
      type: "TextBoxWidget",
      parent: "grpbxGrpingNo",
      Label: "Grouping #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtGrpng_PROPERTIES

      // END_USER_CODE-USER_txtGrpng_PROPERTIES
    },
    txtGrpingShare: {
      name: "txtGrpingShare",
      type: "TextBoxWidget",
      parent: "grpbxStsDates",
      Label: "Grouping Share%:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
    },
    txtLnHldrNm: {
      name: "txtLnHldrNm",
      type: "TextBoxWidget",
      parent: "grpbxLienHldr",
      Label: "Lienholder Name/ Assignee",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLnHldrNm_PROPERTIES

      // END_USER_CODE-USER_txtLnHldrNm_PROPERTIES
    },
    lnkMinority: {
      name: "lnkMinority",
      type: "LinkWidget",
      parent: "grpBxVendr",
      Label: "Minority Disclosure",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },

    txtMinDisclsr: {
      name: "txtMinDisclsr",
      type: "TextBoxWidget",
      parent: "grpBxVendr",
      Label: "",
      // HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
    },

    lnkMinDisclsr: {
      name: "lnkMinDisclsr",
      type: "LinkWidget",
      parent: "grpBxVendr",
      Label: "",
      CharWidth: "21",
    },

    txtMktEndDt: {
      name: "txtMktEndDt",
      type: "TextBoxWidget",
      parent: "grpbxFlex",
      Label: "Market Period End Date:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtMktEndDt_PROPERTIES

      // END_USER_CODE-USER_txtMktEndDt_PROPERTIES
    },
    txtName: {
      name: "txtName",
      type: "TextBoxWidget",
      parent: "grpBxVendr",
      Label: "Name:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtName_PROPERTIES

      // END_USER_CODE-USER_txtName_PROPERTIES
    },
    txtNature2: {
      name: "txtNature2",
      type: "TextBoxWidget",
      parent: "grpbxLienHldr",
      ColSpan: "2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNature2_PROPERTIES

      // END_USER_CODE-USER_txtNature2_PROPERTIES
    },
    txtNtrOfLiens: {
      name: "txtNtrOfLiens",
      type: "TextBoxWidget",
      parent: "grpbxLienHldr",
      Label: "Nature of Liens, Assignments or Prior Interests",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNtrOfLiens_PROPERTIES

      // END_USER_CODE-USER_txtNtrOfLiens_PROPERTIES
    },
    txtOthrAcrs: {
      name: "txtOthrAcrs",
      type: "TextBoxWidget",
      parent: "grpbxFarmPlntngIntn",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOthrAcrs_PROPERTIES

      // END_USER_CODE-USER_txtOthrAcrs_PROPERTIES
    },
    txtOthrAvgYld: {
      name: "txtOthrAvgYld",
      type: "TextBoxWidget",
      parent: "grpbxFarmPlntngIntn",
      Label: "Other:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOthrAvgYld_PROPERTIES

      // END_USER_CODE-USER_txtOthrAvgYld_PROPERTIES
    },
    txtPounds: {
      name: "txtPounds",
      type: "TextBoxWidget",
      parent: "grpBxContrctDetls",
      Label: "Pounds",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds_PROPERTIES

      // END_USER_CODE-USER_txtPounds_PROPERTIES
    },
    txtPounds2: {
      name: "txtPounds2",
      type: "TextBoxWidget",
      parent: "grpBxContrctDetls",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPounds2_PROPERTIES

      // END_USER_CODE-USER_txtPounds2_PROPERTIES
    },
    txtPriceLbs: {
      name: "txtPriceLbs",
      type: "TextBoxWidget",
      parent: "grpbxFlex",
      Label: "Priced Lbs:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtPriceLbs_PROPERTIES

      // END_USER_CODE-USER_txtPriceLbs_PROPERTIES
    },
    txtRemngLbs: {
      name: "txtRemngLbs",
      type: "TextBoxWidget",
      parent: "grpbxFlex",
      Label: "Reamaining Lbs:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtRemngLbs_PROPERTIES

      // END_USER_CODE-USER_txtRemngLbs_PROPERTIES
    },
    txtShare: {
      name: "txtShare",
      type: "TextBoxWidget",
      parent: "grpBxVendr",
      Label: "Share %:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShare_PROPERTIES

      // END_USER_CODE-USER_txtShare_PROPERTIES
    },
    txtSignAdv: {
      name: "txtSignAdv",
      type: "TextBoxWidget",
      parent: "grpBxVendr",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSignAdv_PROPERTIES

      // END_USER_CODE-USER_txtSignAdv_PROPERTIES
    },
    txtState: {
      name: "txtState",
      type: "DropDownFieldWidget",
      parent: "grpbxLienHldr",
      Label: "State",
      DataProviderForDropDown: "inline",
      Options: '',
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtState_PROPERTIES

      // END_USER_CODE-USER_txtState_PROPERTIES
    },
    txtStatus: {
      name: "txtStatus",
      type: "TextBoxWidget",
      parent: "grpbxStsDates",
      Label: "Status:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      value: '',
      Enabled: false,
      Readonly: true
    },
    txtTntApp2: {
      name: "txtTntApp2",
      type: "TextBoxWidget",
      parent: "grpBxContrctDetls",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtTntApp2_PROPERTIES

      // END_USER_CODE-USER_txtTntApp2_PROPERTIES
    },
    txtTntAppld: {
      name: "txtTntAppld",
      type: "TextBoxWidget",
      parent: "grpBxContrctDetls",
      Label: "Tentative Applied",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtTntAppld_PROPERTIES

      // END_USER_CODE-USER_txtTntAppld_PROPERTIES
    },
    txtTntOpen: {
      name: "txtTntOpen",
      type: "TextBoxWidget",
      parent: "grpBxContrctDetls",
      Label: "Tentative Open",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtTntOpen_PROPERTIES

      // END_USER_CODE-USER_txtTntOpen_PROPERTIES
    },
    txtTntOpen2: {
      name: "txtTntOpen2",
      type: "TextBoxWidget",
      parent: "grpBxContrctDetls",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtTntOpen2_PROPERTIES

      // END_USER_CODE-USER_txtTntOpen2_PROPERTIES
    },
    txtTotlAcrs: {
      name: "txtTotlAcrs",
      type: "TextBoxWidget",
      parent: "grpbxFarmPlntngIntn",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtTotlAcrs_PROPERTIES

      // END_USER_CODE-USER_txtTotlAcrs_PROPERTIES
    },
    txtTotlApp: {
      name: "txtTotlApp",
      type: "TextBoxWidget",
      parent: "grpBxContrctDetls",
      Label: "Total Application",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtTotlApp_PROPERTIES

      // END_USER_CODE-USER_txtTotlApp_PROPERTIES
    },
    txtTotlApp2: {
      name: "txtTotlApp2",
      type: "TextBoxWidget",
      parent: "grpBxContrctDetls",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      Enabled: false,
      Readonly: true
      // START_USER_CODE-USER_txtTotlApp2_PROPERTIES

      // END_USER_CODE-USER_txtTotlApp2_PROPERTIES
    },
    txtZip: {
      name: "txtZip",
      type: "TextBoxWidget",
      parent: "grpbxLienHldr",
      Label: "Zip Code",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtZip_PROPERTIES

      // END_USER_CODE-USER_txtZip_PROPERTIES
    },
    txtZip2: {
      name: "txtZip2",
      type: "TextBoxWidget",
      parent: "grpbxLienHldr",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtZip2_PROPERTIES

      // END_USER_CODE-USER_txtZip2_PROPERTIES
    },
    whtSpc01: {
      name: "whtSpc01",
      type: "WhiteSpaceWidget",
      parent: "grpbxFarmPlntngIntn",
      HasLabel: false,
      // START_USER_CODE-USER_whtSpc01_PROPERTIES

      // END_USER_CODE-USER_whtSpc01_PROPERTIES
    },
    whtSpc02: {
      name: "whtSpc02",
      type: "WhiteSpaceWidget",
      parent: "grpbxFarmPlntngIntn",
      HasLabel: false,
      // START_USER_CODE-USER_whtSpc02_PROPERTIES

      // END_USER_CODE-USER_whtSpc02_PROPERTIES
    },
    whtSpc03: {
      name: "whtSpc03",
      type: "WhiteSpaceWidget",
      parent: "grpbxFarmPlntngIntn",
      HasLabel: false,
      // START_USER_CODE-USER_whtSpc03_PROPERTIES

      // END_USER_CODE-USER_whtSpc03_PROPERTIES
    },
    grpbxContrctControl: {
      name: "grpbxContrctControl",
      type: "GroupBoxWidget",
      parent: "ContractProfile",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxContrctControl_PROPERTIES

      // END_USER_CODE-USER_grpbxContrctControl_PROPERTIES
    },
    grpbxStsDates: {
      name: "grpbxStsDates",
      type: "GroupBoxWidget",
      parent: "ContractProfile",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxStsDates_PROPERTIES

      // END_USER_CODE-USER_grpbxStsDates_PROPERTIES
    },
    grpbxFarmPlntngIntn: {
      name: "grpbxFarmPlntngIntn",
      type: "GroupBoxWidget",
      parent: "ContractProfile",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxFarmPlntngIntn_PROPERTIES

      // END_USER_CODE-USER_grpbxFarmPlntngIntn_PROPERTIES
    },
    grpBxVendr: {
      name: "grpBxVendr",
      type: "GroupBoxWidget",
      parent: "ContractProfile",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpBxVendr_PROPERTIES

      // END_USER_CODE-USER_grpBxVendr_PROPERTIES
    },
    grpBxDlvryPrdDesc: {
      name: "grpBxDlvryPrdDesc",
      type: "GroupBoxWidget",
      parent: "ContractProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpBxDlvryPrdDesc_PROPERTIES

      // END_USER_CODE-USER_grpBxDlvryPrdDesc_PROPERTIES
    },
    grpBxContrctDetls: {
      name: "grpBxContrctDetls",
      type: "GroupBoxWidget",
      parent: "ContractProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "12",
      HasLabel: false,
      Cols: "12",
      ColsForTabPotrait: "12",
      ColsForLargeDesktop: "12",
      // START_USER_CODE-USER_grpBxContrctDetls_PROPERTIES

      // END_USER_CODE-USER_grpBxContrctDetls_PROPERTIES
    },
    grpbxLienHldr: {
      name: "grpbxLienHldr",
      type: "GroupBoxWidget",
      parent: "ContractProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "10",
      HasLabel: false,
      Cols: "10",
      ColsForTabPotrait: "10",
      ColsForLargeDesktop: "10",
      // START_USER_CODE-USER_grpbxLienHldr_PROPERTIES

      // END_USER_CODE-USER_grpbxLienHldr_PROPERTIES
    },
    grpbxCmnts: {
      name: "grpbxCmnts",
      type: "GroupBoxWidget",
      parent: "ContractProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxCmnts_PROPERTIES

      // END_USER_CODE-USER_grpbxCmnts_PROPERTIES
    },
    btnCntnr: {
      name: "btnCntnr",
      type: "GroupBoxWidget",
      parent: "ContractProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "12",
      HasLabel: false,
      Cols: "12",
      ColsForTabPotrait: "12",
      ColsForLargeDesktop: "12",
      // START_USER_CODE-USER_btnCntnr_PROPERTIES

      // END_USER_CODE-USER_btnCntnr_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#AccountDistributionActivity": {},

        "ContractManagement#AddendumSeedGrower2009": {},

        "ContractManagement#ApplicationToContract": {},

        "ContractManagement#ContractExceptionPremiumSetup": {},

        "ContractManagement#ContractGroupingProfile": {},

        "ContractManagement#ContractScan": {},

        "ContractManagement#FlexibleMarketPricings": {},

        "ContractManagement#FlexMktPrint": {},

        "ContractManagement#VendorLookup": {},

        "ContractManagement#ViewPayments": {},

        "ContractManagement#MainMenu": {},
        "ContractManagement#VendorDisclosure": {},
        "ContractManagement#ReportPreviewContract": {}
      },
      REVERSE: {
        "ContractManagement#AccountDistributionActivity": {},

        "ContractManagement#AddendumSeedGrower2009": {},

        "ContractManagement#ApplicationToContract": {},

        "ContractManagement#ContractExceptionPremiumSetup": {},

        "ContractManagement#ContractGroupingProfile": {},

        "ContractManagement#ContractScan": {},

        "ContractManagement#FlexibleMarketPricings": {},

        "ContractManagement#FlexMktPrint": {},

        "ContractManagement#VendorLookup": {},

        "ContractManagement#ViewPayments": {},

        "ContractManagement#MainMenu": {},
        "ContractManagement#VendorDisclosure": {},
        "ContractManagement#ReportPreviewContract": {}
      },
    },
  };
  let _buttonServices = {
    AccountDistributionActivity: {},
    AddendumSeedGrower2009: {},
    ApplicationToContract: {},
    ContractExceptionPremiumSetup: {},
    ContractGroupingProfile: {},
    ContractScan: {},
    FlexibleMarketPricings: {},
    FlexMktPrint: {},
    VendorLookup: {},
    ViewPayments: {},
    MainMenu: {}
  };
  let _buttonNavigation = {
    btnSetpExcPrm: {
      DEFAULT: [
        "ContractManagement#ContractExceptionPremiumSetup#DEFAULT#true#Click",
      ],
    },
    // lnkMinDisclsr: {
    //   DEFAULT: [
    //     "ContractManagement#VendorDisclosure#DEFAULT#true#Click",
    //   ],
    // },
    // btnExit: {
    //   DEFAULT: [
    //     "ContractManagement#MainMenu#DEFAULT#false#Click",
    //   ],
    // },
    btnPopUp: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
    // btnSeedGrwrAgreemnt: {
    //   DEFAULT: ["ContractManagement#AddendumSeedGrower2009#DEFAULT#true#Click"],
    // },

    // btnLookupFrm: {
    //   DEFAULT: ["ContractManagement#ApplicationToContract#DEFAULT#true#Click"],
    // },
    // btnVndrLookupFrm2: {
    //   DEFAULT: [
    //     "ContractManagement#ApplicationToContract#DEFAULT#true#Click",
    //   ],
    // },
    btnFlxMktPcngDet: {
      DEFAULT: ["ContractManagement#FlexibleMarketPricings#DEFAULT#true#Click"],
    },
    btnFlxMktWrkSht: {
      DEFAULT: ["ContractManagement#FlexMktPrint#DEFAULT#true#Click"],
    },

    // btnSign: {
    //   DEFAULT: ["ContractManagement#ContractScan#DEFAULT#true#Click"],
    // },
    // btnPymnt: {
    //   DEFAULT: ["ContractManagement#ViewPayments#DEFAULT#true#Click"],
    // },
    // btnAccDist: {
    //   DEFAULT: [
    //     "ContractManagement#AccountDistributionActivity#DEFAULT#true#Click",
    //   ],
    // },
    // btnPrint: {
    //   DEFAULT: ["ContractManagement#ReportPreviewContract#DEFAULT#true#Click"],
    // },
    btnLookup: {
      DEFAULT: [
        "ContractManagement#ContractGroupingProfile#DEFAULT#true#Click",
      ],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };

  // Form Load Variables
  const [cmdChemicalAddendum, setCmdChemicalAddendum] = useState('');
  const [cmdForeignMatarial, setcmdForeignMatarial] = useState('');
  const [cmdSeedGrower, setcmdSeedGrower] = useState('');
  const [useTerm, setTerm] = useState('');
  const [useMatchEndDate, setMatchEndDate] = useState('');
  const [useSeedAcres, setSeedAcres] = useState('');
  const [useSeedPrem, setSeedPrem] = useState('');
  const [useSeedPremLimit, setSeedPremLimit] = useState('');
  const [useSeedLbs, setSeedLbs] = useState('');
  const [msSeedTBD, setmsSeedTBD] = useState('');
  const [loading, setLoading] = useState(true);
  const [mdblSignAdv, setmdblSignAdv] = useState('');
  const [formDirty, setFormDirty] = useState(false)
  // const [applicationExist, setApplicationExist] = useState(false);
  var vendorNumber = '';
  var mbHaveVendorName = false;
  var msUploadStatus = '';
  var msApprovedUser = '';
  var lblAddDate = "04/01/2009";
  var peanutTypeDataList = [];
  var MC_VIRUGUARD = 51;
  var MC_OLEIC_MID = "M";
  var dblSigningAdvance;
  var strVariety;
//INC5127041
  var sHeader;
  var price_per_ton;

  var isFormValid = false;

  // Security Roles Parameters
  // var mbSecurityUpdateAfterSigned;
  // var mbException_Button;
  // var mbSecurityUpdatePending;
  // var mbSecurityVarietyChange;
  // var mbSecurityInquiryAfterSigned;
  // var mbSecurityDeletePending;
  // var mbApplicationsExist;
  // let mbSecurityUpdateFarmSpecific = true;

  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    let formState = commonContext.getContractProfile_FormState;
    setValue(thisObj, "lblCropYear2", "CropYear : " + (sessionStorage.getItem('year')));
    // SetData
    setData(thisObj, 'ContractProfile_ExceptionPremiumSetupDetails', null);
    setData(thisObj, 'vendorDetails', null);
    setData(thisObj, 'ContractScan', null);
    setData(thisObj, 'VendorDisclosure', null);
    setData(thisObj, 'SaveContract', null);
    setData(thisObj, 'AddendumSeedGrower', null);
    if (formState == 'edit') {
      // For Edit
      commonContext.setContractProfile_ReportRequest("Save");
      bFillForm();
    } else {
      // For Add
      commonContext.setContractProfile_ReportRequest("Create");
      FormLoad();
    }
  }, [])

  useEffect(() => {
    VendorDetails();
  }, [getData(thisObj, 'vendorDetails')])

  useEffect(() => {
    let exceptionPremiumSetupDetails = getData(thisObj, 'ContractProfile_ExceptionPremiumSetupDetails');
    if (exceptionPremiumSetupDetails !== undefined && exceptionPremiumSetupDetails !== null) {
      let pd_codes = '';
      for (let i = 0; i < exceptionPremiumSetupDetails.length; i++) {
        if (exceptionPremiumSetupDetails[i].contractNum == commonContext.getContractProfile_ContractNumber) {
          if (i == 0) {
            pd_codes += exceptionPremiumSetupDetails[i].pdCode;
          } else {
            pd_codes += ', ' + exceptionPremiumSetupDetails[i].pdCode;
          }
        }
      }
      setValue(thisObj, 'txtarExcPrm', pd_codes);
    }
  }, [getData(thisObj, 'ContractProfile_ExceptionPremiumSetupDetails')]);

  useEffect(() => {
    let msUploadStatus = '';
    let details = getData(thisObj, 'ContractScan');
    if (details !== null && details.method == 'Sign') {
      let bSignedWithOutPDF = false;

      ContractManagementService.RetrievePeanutStaticValues().then(ppsDefault => {
        if (ppsDefault !== undefined && ppsDefault.length > 0) {
          let key = `${compId}` + '_default_overrides';
          if (ppsDefault[0].pps_defaults.hasOwnProperty(key) == true) {
            let value = Object.getOwnPropertyDescriptor(ppsDefault[0].pps_defaults, key).value;
            if (value.contract_scan_sw == 'OFF') {
              bSignedWithOutPDF = true;
            }
          }
        }
        if (bSignedWithOutPDF) {
          msUploadStatus = "";
        } else {
          msUploadStatus = "P";
        }

        if (document.getElementById('chckbxAlwRescn').checked) {
          setValue(thisObj, "chckbxAlwRescn", false);
        }
        bSaveContract("S", "N", msUploadStatus, details.filname).then(response => {
          if (response == true) {
            setData(thisObj, "reload", true);
            goTo(thisObj, "ContractManagement#ContractInquiry#DEFAULT#false#Click");
            return true;
          }
        });
      });
    } else if (details !== null && details.method == 'Rescan') {
      let sScanConractType;
      let sEmail;
      let sSendEmail;
      let msUploadStatus;
      let msApprovedUser;

      let contractNumber = getValue(thisObj, 'txtContrctNo');
      let lblStatus = getValue(thisObj, 'txtStatus');
      if (lblStatus == 'S' || lblStatus == 'Signed') {
        sScanConractType = "P";
      }
      if (lblStatus == "A" || lblStatus == 'Approved') {
        sScanConractType = "R";
        if (confirm("Do you want to email rescanned contract to producer? Click on OK to proceed else click on Cancel.") == true) {
          sSendEmail = "Y";
          ContractManagementService.RetrieveContractDetails(contractNumber, 'PN1010').then(contractDetails => {
            if (contractDetails !== null) {
              msUploadStatus = contractDetails[0].upload_status;
              msApprovedUser = contractDetails[0].approved_user;
            }
            ContractManagementService.RetrieveUserSignatureDetails(msApprovedUser).then(userSignature => {
              if (userSignature.length == 0) {
                alert("Cannot find signature for user: " + msApprovedUser + ". Use Signature Setup to setup the informaiton.");
                return;
              }
              if (userSignature[0].user_email == "") {
                alert("Cannot find your email to use as the sender.");
                sSendEmail = "N";
                return false;
              }
              let vendor = getValue(thisObj, 'txtVndr');
              if (vendor !== undefined || vendor !== '' || vendor !== null) {
                ContractManagementService.RetrieveVendorEmailDetails(null, null, null, vendor, null, null).then(emaillist => {
                  if (emaillist.length == 0) {
                    alert('Cannot find producers email address. Contract will not be sent to producer.');
                    sSendEmail = "N";
                  }
                  if (emaillist[0].vendorEmail1 !== '' || emaillist[0].vendorEmail2 !== '' || emaillist[0].vendorEmail3 !== '') {
                    sEmail = true;
                  } else {
                    alert('Cannot find producers email address. Contract will not be sent to producer.');
                    sSendEmail = "N";
                  }
                  // Update Contract
                  msUploadStatus = sScanConractType;
                  bSaveContract('', sSendEmail, msUploadStatus, details.filname).then(saveContract => {
                    if (!saveContract) {
                      alert("Cannot save contract.");
                    } else {
                      alert("Contract saved successfully.");
                      setData(thisObj, "reload", true)
                      goTo(thisObj, "ContractManagement#ContractInquiry#DEFAULT#false#Click");
                      return true;
                    }
                  });
                });
              }
            });
          });
        } else {
          sSendEmail = "N";
          // Update Contract
          msUploadStatus = sScanConractType;
          bSaveContract('', sSendEmail, msUploadStatus, details.filname).then(saveContract => {
            if (!saveContract) {
              alert("Cannot save contract.");
              return;
            } else {
              alert("Contract saved successfully.");
              setData(thisObj, "reload", true)
              goTo(thisObj, "ContractManagement#ContractInquiry#DEFAULT#false#Click");
              return true;
            }
          });
        }
      } else {
        // Update Contract
        msUploadStatus = sScanConractType;
        bSaveContract('', sSendEmail, msUploadStatus, details.filname).then(saveContract => {
          if (!saveContract) {
            alert("Cannot save contract.");
            return;
          } else {
            alert("Contract saved successfully.");
            setData(thisObj, "reload", true)
            goTo(thisObj, "ContractManagement#ContractInquiry#DEFAULT#false#Click");
            return true;
          }
        });
      }
    }
  }, [getData(thisObj, 'ContractScan')]);

  useEffect(() => {
    let details = getData(thisObj, 'VendorDisclosure');
    if (details !== null) {
      MinorityDisclosure(details.vendorId);
    }
  }, [getData(thisObj, 'VendorDisclosure')]);

  useEffect(() => {
    let details = getData(thisObj, 'AddendumSeedGrower');
    if (details !== null) {
      let sPeanutVarietyList = '';
      if (details !== null) {
        for (let i = 0; i < details.length > 0; i++) {
          if (getData(thisObj, 'msseedTBD') == false) {
            setSeedAcres(details[0].seed_acres);
            setSeedPrem(details[0].prem_per_ton.split(' ')[0]);
            setSeedPremLimit(details[0].price_limit_rec_key);
            setSeedLbs(details[0].cont_lbs);
            sPeanutVarietyList = sPeanutVarietyList + (details[i].seed_acres == undefined ? '' : details[i].seed_acres) +
              "," + (details[i].cont_lbs == undefined ? '' : details[i].cont_lbs) +
              "," + (details[i].appl_lbs == undefined ? '' : details[i].appl_lbs) +
              "," + (details[i].tentative_lbs == 0 ? '' : details[i].tentative_lbs) +
              "," + (details[i].prem_per_ton == undefined ? '' : details[i].prem_per_ton.split(' ')[0]) +
              "," + (details[i].gen_planted == undefined ? '' : details[i].gen_planted) +
              "," + (details[i].gen_produced == undefined ? '' : details[i].gen_produced) +
              "," + details[i].pnut_variety_id + "|"

            setcmdSeedGrower(sPeanutVarietyList);
            setmsSeedTBD(getData(thisObj, 'msseedTBD') == true ? 'Y' : 'N');
          } else {
            setSeedAcres(details[0].seed_acres);
            setSeedPrem(details[0].prem_per_ton.split(' ')[0]);
            setSeedPremLimit(details[0].price_limit_rec_key);
            setSeedLbs(details[0].cont_lbs);
            setcmdSeedGrower(cmdSeedGrower);
            setmsSeedTBD(getData(thisObj, 'msseedTBD') == true ? 'Y' : 'N');
          }
        }
      }
      if ((details == '' || details == null || details == undefined) && msSeedTBD !== 'Y') {
        document.getElementById("btnSeedGrwrAgreemnt").style.backgroundImage = 'linear-gradient(white, #e9ecef 60%, #dde2e6)';
        document.getElementById("btnSeedGrwrAgreemnt").style.backgroundColor = "#d3d9df";
      } else {
        document.getElementById("btnSeedGrwrAgreemnt").style.backgroundImage = "none";
        document.getElementById("btnSeedGrwrAgreemnt").style.backgroundColor = "#AFDBC9";
      }
    }
  }, [getData(thisObj, 'AddendumSeedGrower')]);

  useEffect(() => {
    if (formDirty) {
      commonContext.setFormDirty_Contractprofile(formDirty)
    }
    else {
      commonContext.setFormDirty_Contractprofile(formDirty)
    }
  }, [formDirty]);

  // useEffect(() => {
  //   let reportRequest = getData(thisObj, 'SaveContract');
  //   if (reportRequest == 'Create') {
  //     // Create contract
  //     let type = getValue(thisObj, 'ddType');
  //     let chkSeedGrower2010 = document.getElementById('chkSeedGrower2010').checked;
  //     if (cropYear >= 2010 && type !== 'S' && chkSeedGrower2010 == true) {
  //       if (confirm("Do you want to enter a Seed Agreement for this contract? Click on OK to proceed else click on Cancel.") == true) {
  //         window.location.reload();
  //       } else {
  //         if (confirm("Do you want to create another Contract? Click OK to proceed else click on Cancel to go to Main Menu screen.") == true) {
  //           window.location.reload();
  //         } else {
  //           history.push('/');
  //         }
  //       }
  //     } else {
  //       if (confirm("Do you want to create another Contract? Click OK to proceed else click on Cancel to go to Main Menu screen.") == true) {
  //         window.location.reload();
  //       } else {
  //         history.push('/');
  //       }
  //     }
  //   }
  // }, [getData(thisObj, 'SaveContract')]);

  // bFill Form
  const bFillForm = async () => {
    disable(thisObj, "btnSetpExcPrm");
    hide(thisObj, 'txtShare');
    // For Flex and Floor Market
    document.getElementsByClassName("lblFlexMarketPricing")[0].style.visibility = "hidden";
    document.getElementsByClassName("lblMktPrcng")[0].style.visibility = "hidden";
    document.getElementsByClassName("txtAvlLbs")[0].style.visibility = "hidden";
    document.getElementsByClassName("txtPriceLbs")[0].style.visibility = "hidden";
    document.getElementsByClassName("txtRemngLbs")[0].style.visibility = "hidden";
    document.getElementsByClassName("txtMktEndDt")[0].style.visibility = "hidden";
    document.getElementsByClassName("btnFlxMktPcngDet")[0].style.visibility = "hidden";
    document.getElementsByClassName("btnFlxMktWrkSht")[0].style.visibility = "hidden";
    // For Flex and Floor Market

    document.getElementsByTagName("form")[1].classList.add("withoutFlex");
    setValue(thisObj, "txtSignAdv", '');
    setValue(thisObj, "chkBxSeedGrowr", false);
    hide(thisObj, "btnSeedGrwrAgreemnt");
    hide(thisObj, "btnChemiclAgreemnt");
    hide(thisObj, "btnFmAgreement");
    hide(thisObj, 'btnClrAdvnc');
    document.getElementsByClassName("dtMtchEndDate")[0].style.visibility = "hidden";

    vendorNumber = '';
    // mbApplicationsExist = false;
    // setApplicationExist(false);
    lblAddDate = "04/01/2009";
    GetStateList(compId, cropYear, null, null, false);
    // Get Security Roles and Permission
    // mbSecurityUpdatePending = true;
    // mbSecurityUpdateAfterSigned = true;
    // mbSecurityInquiryAfterSigned = true;
    // mbSecurityVarietyChange = true;
    // mbException_Button = false;
    let securityUpdateAfterSigned = await ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '002', 'U', null);
    if (securityUpdateAfterSigned[0].permission == 'N') {
      // mbSecurityUpdateAfterSigned = false;
      localStorage.setItem('SecurityUpdateAfterSigned', false);
    } else {
      localStorage.setItem('SecurityUpdateAfterSigned', true);
    }

    let securityUpdatePending = await ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '001', 'U', null);
    if (securityUpdatePending[0].permission == 'N') {
      // mbSecurityUpdatePending = false;
      localStorage.setItem('SecurityUpdatePending', false);
    } else {
      localStorage.setItem('SecurityUpdatePending', true);
    }

    // let securityUpdateFarmSpecific = await ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '003', 'U', null);
    // if (securityUpdateFarmSpecific[0].permission == 'N') {
    //   mbSecurityUpdateFarmSpecific = false;
    // } else {
    //   mbSecurityUpdateFarmSpecific = true;
    // }

    let securityInquiryAfterSigned = await ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '002', 'I', null);
    if (securityInquiryAfterSigned[0].permission == 'N') {
      // mbSecurityInquiryAfterSigned = false;
      localStorage.setItem('SecurityInquiryAfterSigned', false);
    } else {
      localStorage.setItem('SecurityInquiryAfterSigned', true);
    }

    let exceptionButton = await ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '007', 'U', null);
    if (exceptionButton[0].permission == 'N') {
      disable(thisObj, "btnSetpExcPrm");
      localStorage.setItem('Exception_Button', false);
    } else {
      // mbException_Button = true;
      localStorage.setItem('Exception_Button', true);
    }

    let securityDeletePending = await ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '001', 'D', null);
    if (securityDeletePending[0].permission == 'N') {
      // mbSecurityDeletePending = false;
      localStorage.setItem('SecurityDeletePending', false);
    } else {
      // mbSecurityDeletePending = true;
      localStorage.setItem('SecurityDeletePending', true);
    }

    let securityVarietyChange = await ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '008', 'U', null);
    if (securityVarietyChange[0].permission == 'N') {
      // mbSecurityVarietyChange = false;
      localStorage.setItem('SecurityVarietyChange', false);
    } else {
      localStorage.setItem('SecurityVarietyChange', true);
    }
    // Get Security Roles and Permission
    mbHaveVendorName = false;
    GetPeanutTypeList();
    GetTypeList();
    GetLocationList();
    setValue(thisObj, 'lblAddedBy', '');
    setValue(thisObj, 'lblChngdBy', '');
    // By default share for vendor always 100%
    setValue(thisObj, "txtShare", 100);
    msUploadStatus = '';
    setmsSeedTBD('');
    if (cropYear >= 2010) {
      document.getElementsByClassName("chkSeedGrower2010")[0].style.visibility = "visible";
      document.getElementsByClassName("chkBxSeedGrowr")[0].style.visibility = "hidden";
    } else {
      document.getElementsByClassName("chkSeedGrower2010")[0].style.visibility = "hidden";
      document.getElementsByClassName("chkBxSeedGrowr")[0].style.visibility = "visible";
    }
    setValue(thisObj, "chkbxFreezeDmgRng", false);
    CheckDamegeRange();

    // For Edit Purpose
    let contractdetails = await ContractManagementService.RetrieveContractDetails(commonContext.getContractProfile_ContractNumber, 'PN1010');
    if (contractdetails.length > 0) {
      //INC5127041
      sHeader = contractdetails[0];
setData(thisObj,"pnutvarietyid",sHeader.pnut_variety_id)
      let sVendorSplits = sHeader.contract_vendor_splits;
      let sLineHolders = sHeader.contract_lienholders;
      let sPricing = sHeader.contract_pricings[0];
      let sComments = sHeader.contract_remarks;
      let sSeedVendorSplits = sHeader.seed_vendor_splits;
      let sExceptPerms = sHeader.contract_except_prems;
      GetDeliveryPeriodList(sHeader.buy_pt_id, null, null, sHeader.delivery_period);

      setValue(thisObj, "ddLocation", sHeader.buy_pt_id);
      setValue(thisObj, "date", ConvertToDate(sHeader.cont_date));
      setValue(thisObj, "txtContrctNo", sHeader.contract_num);
      setValue(thisObj, "txtCntrctno2", sHeader.revision_num);
      setValue(thisObj, "dtHrvstCmplt", sHeader.harvest_complete_date);
      if (sHeader.seg_type !== "") {
        setValue(thisObj, "ddSegType", sHeader.seg_type);
      }
      if (sHeader.cont_type !== "") {
        setValue(thisObj, "ddType", sHeader.cont_type);
      }
      setValue(thisObj, 'txtGrpng', sHeader.grouping_num);
      setValue(thisObj, 'txtGrpingShare', sHeader.grouping_share);

      if (sHeader.allow_rescan == "Y") {
        setValue(thisObj, "chckbxAlwRescn", true);
      } else {
        setValue(thisObj, "chckbxAlwRescn", false);
      }
      
      setmsSeedTBD(sHeader.seed_tbd);
      setSeedAcres(sHeader.seed_acres);
      setSeedPrem(sHeader.prem_per_ton);
      setSeedPremLimit(sHeader.price_limit_rec_key);
      setSeedLbs(sHeader.cont_lbs);

      if (sHeader.pnut_type_id !== "") {
        setValue(thisObj, "ddPeanutType", sHeader.pnut_type_id);
      }

      // if (sHeader.pnut_variety_id != "") {
      let pnut_type_id = sHeader.pnut_type_id !== "" ? sHeader.pnut_type_id : null;
      let buy_pt_id = sHeader.buy_pt_id !== "" ? sHeader.buy_pt_id : null;
      let resp = await ContractManagementService.RetrievePeanutVarietyControls(buy_pt_id, null, null, pnut_type_id, null);
      if (resp.length > 0) {
        let data = resp;
        let js = [];
        let obj;
        obj = { key: '', description: '>>> Not Specified <<<' };
        js.push(obj);
        for (var i = 0; i < data.length; i++) {
          let dataExist = js.find(elem => elem.key == data[i].pnut_variety_id);
          if (dataExist == undefined) {
            obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name };
            js.push(obj);
          }
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddPeaNtVrty: {
              ...state["ddPeaNtVrty"],
              valueList: js
            }
          }
        });
        if (sHeader.pnut_variety_id !== '') {
          setValue(thisObj, "ddPeaNtVrty", sHeader.pnut_variety_id);
        } else {
          setValue(thisObj, "ddPeaNtVrty", '');
        }
      }

      if (sHeader.oleic_ind !== "") {
        setValue(thisObj, "ddOleic", sHeader.oleic_ind);
      } else {
        setValue(thisObj, "ddOleic", "N");
      }

      if (sHeader.cont_type !== 'S') {
        document.getElementsByClassName("chkSeedGrower2010")[0].style.visibility = "visible";
        document.getElementsByClassName("chkBxSeedGrowr")[0].style.visibility = "hidden";
        if (sHeader.seed_grower == "Y") {
          setValue(thisObj, "chkSeedGrower2010", true);
        } else {
          setValue(thisObj, "chkSeedGrower2010", false);
        }
      } else {
        document.getElementsByClassName("chkBxSeedGrowr")[0].style.visibility = "visible";
        document.getElementsByClassName("chkSeedGrower2010")[0].style.visibility = "hidden";
        if (sHeader.seed_grower == "Y") {
          setValue(thisObj, "chkBxSeedGrowr", true);
        } else {
          setValue(thisObj, "chkBxSeedGrowr", false);
        }
        CheckSeedGrower();
      }

      switch (sHeader.cont_status) {
        case 'P':
          setValue(thisObj, 'txtStatus', CommonKeys.Pending);
          break;
        case 'A':
          setValue(thisObj, 'txtStatus', CommonKeys.Approved);
          break;
        case 'S':
          setValue(thisObj, 'txtStatus', CommonKeys.Signed);
          break;
        case 'C':
          setValue(thisObj, 'txtStatus', CommonKeys.Cancelled);
          break;
        case 'V':
          setValue(thisObj, 'txtStatus', CommonKeys.Voided);
          break;
      }
      setValue(thisObj, "txtDtCncl", ConvertToDate(sHeader.auto_cancel_date));
      setValue(thisObj, "txtEntryDt", ConvertToDate(sHeader.add_date_time));
      setValue(thisObj, "txtDtSign", ConvertToDate(sHeader.signing_date_time));
      setValue(thisObj, "txtDtApprv", ConvertToDate(sHeader.approved_date_time));
      setValue(thisObj, "txtDtVoided", ConvertToDate(sHeader.void_date_time));
      setValue(thisObj, "txtDtClose", ConvertToDate(sHeader.closed_date_time));

      setValue(thisObj, 'lblAddedBy', sHeader.add_user + " " + ConvertToDate(sHeader.add_date_time));
      setValue(thisObj, 'lblChngdBy', sHeader.chg_user + " " + ConvertToDate(sHeader.chg_date_time));

      if (sHeader.center_pivot_yield !== "" && sHeader.center_pivot_yield !== "0") {
        setValue(thisObj, "txtCntrPivtAvgYld", sHeader.center_pivot_yield);
      }

      if (sHeader.center_pivot_acres !== "" && sHeader.center_pivot_acres !== "0") {
        setValue(thisObj, "txtCntrPivtAcrs", sHeader.center_pivot_acres);
      }

      if (sHeader.other_yield !== "" && sHeader.other_yield !== "0") {
        setValue(thisObj, "txtOthrAvgYld", sHeader.other_yield);
      }

      if (sHeader.other_acres !== "" && sHeader.other_acres !== "0") {
        setValue(thisObj, "txtOthrAcrs", sHeader.other_acres);
      }

      if (sHeader.dry_land_yield !== "" && sHeader.dry_land_yield !== "0") {
        setValue(thisObj, "txtDryLndAvgYld", sHeader.dry_land_yield);
      }

      if (sHeader.dry_land_acres !== "" && sHeader.dry_land_acres !== "0") {
        setValue(thisObj, "txtDryLndAcres", sHeader.dry_land_acres);
      }

      CalculateTotal(sHeader.center_pivot_yield, sHeader.center_pivot_acres, sHeader.other_yield, sHeader.other_acres, sHeader.dry_land_yield, sHeader.dry_land_acres);

      msUploadStatus = sHeader.upload_status;
      msApprovedUser = sHeader.approved_user;

      if (sHeader.min_frez_dam_pct !== null && sHeader.min_frez_dam_pct !== '') {
        setValue(thisObj, "txtbxMin", sHeader.min_frez_dam_pct);
      } else {
        setValue(thisObj, "txtbxMin", "");
      }

      if (sHeader.max_frez_dam_pct !== null && sHeader.max_frez_dam_pct !== '') {
        setValue(thisObj, "txtbxMax", sHeader.max_frez_dam_pct);
      } else {
        setValue(thisObj, "txtbxMax", "");
      }
//INC5074771
      // if ((sHeader.min_frez_dam_pct == '' || sHeader.min_frez_dam_pct == null) &&
        //   (sHeader.max_frez_dam_pct == '' || sHeader.max_frez_dam_pct == null)) {
      //   setValue(thisObj, "chkbxFreezeDmgRng", false);
      //   CheckDamegeRange();
      // } else {
      //   setValue(thisObj, "chkbxFreezeDmgRng", true);
      //   CheckDamegeRange();
      // }
      if ((sHeader.min_frez_dam_pct === '0.00' || sHeader.min_frez_dam_pct === '' || sHeader.min_frez_dam_pct === null) &&
       (sHeader.max_frez_dam_pct === '0.00' || sHeader.max_frez_dam_pct === '' || sHeader.max_frez_dam_pct === null)) {
        setValue(thisObj, "chkbxFreezeDmgRng", false);
        CheckDamegeRange();
      } else {
        setValue(thisObj, "chkbxFreezeDmgRng", true);
        CheckDamegeRange();
      }

      if (sVendorSplits.length > 0 && sVendorSplits[0].split_vendor !== '') {
        setValue(thisObj, "txtVndr", sVendorSplits[0].split_vendor);
        setData(thisObj, 'ContractProfile_VendorNumber', sVendorSplits[0].split_vendor);


        setValue(thisObj, "txtEmail", '');
        document.getElementsByClassName('lnkMinDisclsr')[0].childNodes[0].childNodes[0].innerText = '';
        setValue(thisObj, "txt211Gpc", '');
        setValue(thisObj, "txt211concordia", '');

        let remitDetails = await ContractManagementService.RetieveRemitDetails(sVendorSplits[0].split_vendor);
        if (remitDetails.length > 0) {
          let js = [];
          for (var i = 0; i < remitDetails.length; i++) {
            let description =
              remitDetails[i].remittoid + '-' +
              remitDetails[i].name + '-' +
              remitDetails[i].address1 + '' +
              (remitDetails[i].address2 == null ? '' : remitDetails[i].address2) +
              (remitDetails[i].address3 == null ? '' : remitDetails[i].address3) +
              (remitDetails[i].address4 == null ? '' : remitDetails[i].address4) + ' ' +
              remitDetails[i].city + ',' +
              remitDetails[i].state + ' ' +
              remitDetails[i].zip;
            let obj = { key: remitDetails[i].remittoid, description: description };
            js.push(obj);
          }
          thisObj.setState(current => {
            return {
              ...current,
              ddRemit: {
                ...state["ddRemit"],
                valueList: js
              },
            }
          });
          let selectedData = remitDetails.find(o => o.remittoid == "000");
          if (selectedData !== null) {
            setValue(thisObj, "ddRemit", selectedData.remittoid);
            setValue(thisObj, "txtName", selectedData.name);
            setValue(thisObj, "txtEmail", selectedData.email1 + ' ' + selectedData.email2 + ' ' + selectedData.email3);
            setValue(thisObj, "txt211Gpc", selectedData.golden_form_211);
            setValue(thisObj, "txt211concordia", selectedData.concordia_form_211);
            document.getElementsByClassName('lnkMinDisclsr')[0].childNodes[0].childNodes[0].innerText = selectedData.minority_disclosure;
          }
        }
      }

      setValue(thisObj, "txtShare", sVendorSplits[0].split_share_pct);
      setValue(thisObj, "txtSignAdv", sVendorSplits[0].advance_amount);
      if (sHeader.cont_type == 'O' || sHeader.cont_type == 'M' || sHeader.cont_type == 'L') {
        setmdblSignAdv('10.00');
      }
      setValue(thisObj, "txtCllcAdv", sVendorSplits[0].advance_collect);

      if (sHeader.cont_status == 'C' && sVendorSplits[0].advance_amount !== sVendorSplits[0].advance_collect) {
        show(thisObj, 'btnClrAdvnc');
      } else {
        hide(thisObj, 'btnClrAdvnc');
      }

      if (sVendorSplits.length > 0) {
        if (sVendorSplits[0].fm_addendum_ind == "Y") {
          setcmdForeignMatarial('Y');
          document.getElementById("btnFmAgreement").style.backgroundImage = "none";
          document.getElementById("btnFmAgreement").style.backgroundColor = "#AFDBC9";
          show(thisObj, "btnFmAgreement");
        } else {
          setcmdForeignMatarial('N');
          document.getElementById("btnFmAgreement").style.backgroundImage = 'linear-gradient(white, #e9ecef 60%, #dde2e6)';
          document.getElementById("btnFmAgreement").style.backgroundColor = "#d3d9df";
          show(thisObj, "btnFmAgreement");
        }
      }

      if (sVendorSplits.length > 0) {
        if (sVendorSplits[0].chemical_addendum_ind == "Y") {
          setCmdChemicalAddendum('Y');
          document.getElementById("btnChemiclAgreemnt").style.backgroundImage = "none";
          document.getElementById("btnChemiclAgreemnt").style.backgroundColor = "#AFDBC9";
          show(thisObj, "btnChemiclAgreemnt");
        } else {
          setCmdChemicalAddendum('N');
          document.getElementById("btnChemiclAgreemnt").style.backgroundImage = 'linear-gradient(white, #e9ecef 60%, #dde2e6)';
          document.getElementById("btnChemiclAgreemnt").style.backgroundColor = "#d3d9df";
          show(thisObj, "btnChemiclAgreemnt");
        }
      }

      if (sVendorSplits.length > 0) {
        let seedGrower = sVendorSplits[0].addendum_seed_growers;
        if (seedGrower.length > 0) {
          let details = seedGrower;
          let sPeanutVarietyList = '';
          for (let i = 0; i < details.length > 0; i++) {
            if (sHeader.seed_tbd == 'N') {
              setSeedAcres(details[0].seed_acres);
              setSeedPrem(details[0].prem_per_ton);
              setSeedPremLimit(details[0].price_limit_rec_key);
              setSeedLbs(details[0].cont_lbs);
              sPeanutVarietyList = sPeanutVarietyList + (details[i].seed_acres == undefined ? '' : details[i].seed_acres) +
                "," + (details[i].cont_lbs == undefined ? '' : details[i].cont_lbs) +
                "," + (details[i].appl_lbs == undefined ? '' : details[i].appl_lbs) +
                "," + (details[i].tentative_lbs == 0 ? '' : details[i].tentative_lbs) +
                "," + (details[i].prem_per_ton == undefined ? '' : details[i].prem_per_ton) +
                "," + (details[i].gen_planted == undefined ? '' : details[i].gen_planted) +
                "," + (details[i].gen_produced == undefined ? '' : details[i].gen_produced) +
                "," + details[i].pnut_variety_id + "|"

              setcmdSeedGrower(sPeanutVarietyList);
              setmsSeedTBD(getData(thisObj, 'msseedTBD') == true ? 'Y' : 'N');
            } else {
              setSeedAcres(details[0].seed_acres);
              setSeedPrem(details[0].prem_per_ton);
              setSeedPremLimit(details[0].price_limit_rec_key);
              setSeedLbs(details[0].cont_lbs);
              setcmdSeedGrower(sPeanutVarietyList);
              setmsSeedTBD(getData(thisObj, 'msseedTBD') == true ? 'Y' : 'N');
            }
          }
          if (seedGrower !== '' && seedGrower !== null) {
            if (sHeader.cont_type == 'S') {
              if (seedGrower[0].appl_lbs > 0) {
                // mbApplicationsExist = true;
                // setApplicationExist(true);
                localStorage.setItem('ApplicationsExist', true);
              } else {
                //INC5123959
                //localStorage.setItem('ApplicationsExist', false);
              }
            }
          }
          if (seedGrower.length == 0 && sHeader.seed_tbd !== 'Y') {
            document.getElementById("btnSeedGrwrAgreemnt").style.backgroundImage = 'linear-gradient(white, #e9ecef 60%, #dde2e6)';
            document.getElementById("btnSeedGrwrAgreemnt").style.backgroundColor = "#d3d9df";
          } else {
            document.getElementById("btnSeedGrwrAgreemnt").style.backgroundImage = "none";
            document.getElementById("btnSeedGrwrAgreemnt").style.backgroundColor = "#AFDBC9";
          }
        } else {
          if (seedGrower.length == 0 && sHeader.seed_tbd !== 'Y') {
            document.getElementById("btnSeedGrwrAgreemnt").style.backgroundImage = 'linear-gradient(white, #e9ecef 60%, #dde2e6)';
            document.getElementById("btnSeedGrwrAgreemnt").style.backgroundColor = "#d3d9df";
          } else {
            document.getElementById("btnSeedGrwrAgreemnt").style.backgroundImage = "none";
            document.getElementById("btnSeedGrwrAgreemnt").style.backgroundColor = "#AFDBC9";
          }
        }
      }

      if (sHeader.organic == "Y") {
        setValue(thisObj, "chckbxOrgnc", true);
      } else {
        setValue(thisObj, "chckbxOrgnc", false);
      }

      if (sLineHolders.length > 0) {
        setValue(thisObj, "txtLnHldrNm", sLineHolders[0].lienholder_name);
        setValue(thisObj, "txtAddr", sLineHolders[0].lienholder_address);
        setValue(thisObj, "txtCity", sLineHolders[0].lienholder_city);
        setValue(thisObj, "txtState", sLineHolders[0].lienholder_state);
        setValue(thisObj, "txtZip", sLineHolders[0].lienholder_zip);
        setValue(thisObj, "txtNtrOfLiens", sLineHolders[0].nature_of_lien);

        setValue(thisObj, "lienHldr2", sLineHolders[1].lienholder_name);
        setValue(thisObj, "txtAdd2", sLineHolders[1].lienholder_address);
        setValue(thisObj, "txtCity2", sLineHolders[1].lienholder_city);
        setValue(thisObj, "ddStat2", sLineHolders[1].lienholder_state);
        setValue(thisObj, "txtZip2", sLineHolders[1].lienholder_zip);
        setValue(thisObj, "txtNature2", sLineHolders[1].nature_of_lien);
      }

      if (sPricing !== undefined && sPricing !== '' && sPricing !== null) {
        let pricingLine = sPricing.pricing_line;
        if (pricingLine == 1) {
          if (sPricing.at_harvest == "Y") {
            setValue(thisObj, "chckbxHrvst", true);
          } else {
            setValue(thisObj, "chckbxHrvst", false);
          }

          if (sPricing.delv_beg_date !== "") {
            setValue(thisObj, 'dtStart', ConvertToDate(sPricing.delv_beg_date));
          }

          if (sPricing.delv_end_date !== "") {
            setValue(thisObj, 'dtEnd', ConvertToDate(sPricing.delv_end_date));
          }

          setValue(thisObj, 'txtPounds', sPricing.cont_lbs);
          let organicInd = document.getElementById('chckbxOrgnc').checked == true ? 'Y' : 'N';
          price_per_ton = sPricing.price_per_ton;
          ContractManagementService.RetrievePeanutPriceDetails('C-FIRM', sHeader.pnut_type_id, sHeader.seg_type, organicInd, sHeader.buy_pt_id, sHeader.contract_num, sHeader.pnut_variety_id, sHeader.oleic_ind, sVendorSplits[0].split_vendor).then(peanutPrice => {
            if (peanutPrice !== undefined && peanutPrice.length > 0) {
              let js = [];
              let description = '';
              for (var i = 0; i < peanutPrice.length; i++) {
                if (peanutPrice[i].pricePerTon !== null) {
                  description = peanutPrice[i].pricePerTon;
                }
                if (peanutPrice[i].oleicInd !== null) {
                  description = description + ' ' + peanutPrice[i].oleicInd;
                }
                if (peanutPrice[i].pricingTypeDesc !== null) {
                  description = description + ' ' + peanutPrice[i].pricingTypeDesc;
                }
                let obj = { key: peanutPrice[i].recordKey, description: description };
                js.push(obj);
              }
              thisObj.setState(current => {
                return {
                  ...current,
                  ddPrc: {
                    ...state["ddPrc"],
                    valueList: js
                  }
                }
              });
            }
            setValue(thisObj, 'ddPrc', sPricing.price_limit_rec_key);
          });

          setValue(thisObj, 'txtApplied', sPricing.appl_lbs);
          if (sPricing.appl_labs !== 0) {
            // mbApplicationsExist = true;
            // setApplicationExist(true);
            localStorage.setItem('ApplicationsExist', true);
          } else {
            //INC5123959
            //localStorage.setItem('ApplicationsExist', false);
          }
          setValue(thisObj, 'txtTntAppld', sPricing.tentative_lbs);
          if (sPricing.tentative_lbs !== 0) {
            // mbApplicationsExist = true;
            // setApplicationExist(true);
            localStorage.setItem('ApplicationsExist', true);
          } else {
            //INC5123959
            //localStorage.setItem('ApplicationsExist', false);
          }

          setValue(thisObj, 'txtTotlApp', (sPricing.appl_lbs + sPricing.tentative_lbs));
          setValue(thisObj, 'txtTntOpen', (sPricing.cont_lbs - (sPricing.appl_lbs + sPricing.tentative_lbs)));
        } else {
          if (sPricing.at_harvest == "Y") {
            setValue(thisObj, "chkbxHrvst2", true);
          } else {
            setValue(thisObj, "chkbxHrvst2", false);
          }

          if (sPricing.delv_beg_date !== "") {
            setValue(thisObj, 'dtStrt2', ConvertToDate(sPricing.delv_beg_date));
          }

          if (sPricing.delv_end_date !== "") {
            setValue(thisObj, 'dtEnd2', ConvertToDate(sPricing.delv_end_date));
          }

          setValue(thisObj, 'txtPounds2', sPricing.cont_lbs);
          let organicInd = document.getElementById('chckbxOrgnc').checked == true ? 'Y' : 'N';
          let type = sHeader.cont_type;
          switch (sHeader.cont_type) {
            case 'O':
              type = 'Option';
              break;
            case 'C':
              type = 'C-BASIS';
              break;
            case 'M':
              type = 'FLEX MKT';
              break;
            case 'S':
              type = 'Seed';
              break;
            case 'L':
              type = 'Floor';
              break;
          }
          price_per_ton = sPricing.price_per_ton;
          ContractManagementService.RetrievePeanutPriceDetails(type, sHeader.pnut_type_id, sHeader.seg_type, organicInd, sHeader.buy_pt_id, sHeader.contract_num, sHeader.pnut_variety_id, sHeader.oleic_ind, sVendorSplits[0].split_vendor).then(peanutPrice => {
            if (peanutPrice !== undefined && peanutPrice.length > 0) {
              let js = [];
              let description = '';
              for (var i = 0; i < peanutPrice.length; i++) {
                if (peanutPrice[i].pricePerTon !== null) {
                  description = peanutPrice[i].pricePerTon;
                }
                if (peanutPrice[i].oleicInd !== null) {
                  description = description + ' ' + peanutPrice[i].oleicInd;
                }
                if (peanutPrice[i].pricingTypeDesc !== null) {
                  description = description + ' ' + peanutPrice[i].pricingTypeDesc;
                }
                let obj = { key: peanutPrice[i].recordKey, description: description };
                js.push(obj);
              }
              thisObj.setState(current => {
                return {
                  ...current,
                  ddPrice: {
                    ...state["ddPrice"],
                    valueList: js
                  }
                }
              });
            }
            setValue(thisObj, 'ddPrice', sPricing.price_limit_rec_key);

            let strFlexMktTerms = "";
            if (type == "Floor") {
              let pricingDetails = peanutPrice.find(x => x.recordKey == sPricing.price_limit_rec_key && x.payRebateAt == 'PRICING');
              if (pricingDetails !== null && pricingDetails !== undefined) {
                strFlexMktTerms = strFlexMktTerms + " Floor Contracts must be fully priced on or before the Price Match End Date.";
              }

              let deliveryDetails = peanutPrice.find(x => x.recordKey == sPricing.price_limit_rec_key && x.payRebateAt == 'DELIVERY');
              if (deliveryDetails !== null && deliveryDetails !== undefined) {
                strFlexMktTerms = strFlexMktTerms + " Floor Contracts must be fully priced on or before inspection of any peanuts."
              }

              let maxoptionpriceDetails = peanutPrice.find(x => x.recordKey == sPricing.price_limit_rec_key && x.maxOptionPrice !== '' && x.maxOptionPrice !== null);
              if (maxoptionpriceDetails !== null && maxoptionpriceDetails !== undefined) {
                strFlexMktTerms = strFlexMktTerms + "  With the Option Price Cap price set as $" + Number(maxoptionpriceDetails.maxOptionPrice) + "."
              }
            }

            if (type == "FLEX MKT") {
              let marketfloorDetails = peanutPrice.find(x => x.recordKey == sPricing.price_limit_rec_key && x.marketFloor !== '' && x.marketFloor !== null);
              if (marketfloorDetails !== null && marketfloorDetails !== undefined) {
                strFlexMktTerms = strFlexMktTerms + "Floor: " + marketfloorDetails.marketFloor.toFixed(5);
              }

              let market_ceilingDetails = peanutPrice.find(x => x.recordKey == sPricing.price_limit_rec_key && x.marketCeiling !== '' && x.marketCeiling !== null);
              if (market_ceilingDetails !== null && market_ceilingDetails !== undefined) {
                strFlexMktTerms = strFlexMktTerms + "  Ceiling: " + market_ceilingDetails.marketCeiling.toFixed(5);
              }

              let rebate_rateDetails = peanutPrice.find(x => x.recordKey == sPricing.price_limit_rec_key && x.rebateRate !== '' && x.rebateRate !== null);
              if (rebate_rateDetails !== null && rebate_rateDetails !== undefined) {
                strFlexMktTerms = strFlexMktTerms + "  @ Rate: " + rebate_rateDetails.rebateRate.toFixed(2) + '/Ton Basis Grade.';
              }

              let mkt_gain_shareDetails = peanutPrice.find(x => x.recordKey == sPricing.price_limit_rec_key && x.mktGainShare !== '' && x.mktGainShare !== null);
              if (mkt_gain_shareDetails !== null && mkt_gain_shareDetails !== undefined) {
                if (strFlexMktTerms !== '') {
                  strFlexMktTerms = strFlexMktTerms + " - OR - ";
                }
                strFlexMktTerms = strFlexMktTerms + Number(mkt_gain_shareDetails.mktGainShare) + "% of Market Gain.";
              }

              let loan_repay_indDetails = peanutPrice.find(x => x.recordKey == sPricing.price_limit_rec_key && x.loanRepayInd !== '' && x.loanRepayInd !== null);
              if (loan_repay_indDetails !== null && loan_repay_indDetails !== undefined) {
                strFlexMktTerms = loan_repay_indDetails.loanRepayInd == 'G' ? strFlexMktTerms + ' Loan Repay Method: GOLDEN' : strFlexMktTerms + ' Loan Repay Method: USDA';
              }

              let multi_pricing_indDetails = peanutPrice.find(x => x.recordKey == sPricing.price_limit_rec_key && x.multiPricingInd !== '' && x.multiPricingInd !== null);
              if (multi_pricing_indDetails !== null && multi_pricing_indDetails !== undefined) {
                strFlexMktTerms = multi_pricing_indDetails.multiPricingInd == 'Y' ? strFlexMktTerms + ' Multi Pricings: YES' : strFlexMktTerms + 'Multi Pricings: NO';
              }

              let mkt_wk_prior_matDetails = peanutPrice.find(x => x.recordKey == sPricing.price_limit_rec_key && x.mktWkPriorMat !== '' && x.mktWkPriorMat !== null);
              if (mkt_wk_prior_matDetails !== null && mkt_wk_prior_matDetails !== undefined) {
                strFlexMktTerms = strFlexMktTerms + " Mkt Weeks Prior Maturity: " + mkt_wk_prior_matDetails.mktWkPriorMat;
              }
            }

            if (sPricing.price_limit_rec_key !== '' && sPricing.price_limit_rec_key !== null) {
              let checkValue = peanutPrice.find(x => x.recordKey == sPricing.price_limit_rec_key);
              if (checkValue !== undefined || checkValue !== null) {
                if (strFlexMktTerms == "") {
                  // hide(thisObj, 'grpbxFlex');
                  document.getElementsByTagName("form")[1].classList.add("withoutFlex");
                } else {
                  // For Flex and Floor Market
                  document.getElementsByClassName("lblFlexMarketPricing")[0].style.visibility = "visible";
                  document.getElementsByClassName("lblMktPrcng")[0].style.visibility = "visible";
                  document.getElementsByClassName("txtAvlLbs")[0].style.visibility = "visible";
                  document.getElementsByClassName("txtPriceLbs")[0].style.visibility = "visible";
                  document.getElementsByClassName("txtRemngLbs")[0].style.visibility = "visible";
                  document.getElementsByClassName("txtMktEndDt")[0].style.visibility = "visible";
                  document.getElementsByClassName("btnFlxMktPcngDet")[0].style.visibility = "visible";
                  document.getElementsByClassName("btnFlxMktWrkSht")[0].style.visibility = "visible";
                  document.getElementsByTagName("form")[1].classList.add("withFlex");
                  if (checkValue.priceMatchEndDate !== undefined && checkValue.priceMatchEndDate !== null) {
                    document.getElementsByClassName("dtMtchEndDate")[0].style.visibility = "visible";
                    setMatchEndDate(checkValue.priceMatchEndDate);
                    setValue(thisObj, 'dtMtchEndDate', checkValue.priceMatchEndDate);
                  }
                  setTerm(strFlexMktTerms);
                  setValue(thisObj, 'lblMktPrcng', strFlexMktTerms);
                }
              }
            }
          });

          setValue(thisObj, 'txtApp2', sPricing.appl_lbs);
          if (sPricing.appl_lbs !== '0') {
            // mbApplicationsExist = true;
            // setApplicationExist(true);
            localStorage.setItem('ApplicationsExist', true);
          } else {
            //INC5123959
            //localStorage.setItem('ApplicationsExist', false);
          }

          setValue(thisObj, 'txtTntApp2', sPricing.tentative_lbs);
          if (sPricing.tentative_lbs !== '0') {
            // mbApplicationsExist = true;
            // setApplicationExist(true);
            localStorage.setItem('ApplicationsExist', true);
          } else {
            //INC5123959
            //localStorage.setItem('ApplicationsExist', false);
          }

          setValue(thisObj, 'txtTotlApp2', (Number(sPricing.appl_lbs) + Number(sPricing.tentative_lbs)).toString());
          setValue(thisObj, 'txtTntOpen2', (Number(sPricing.cont_lbs) - (Number(sPricing.appl_lbs) + Number(sPricing.tentative_lbs))));

          document.getElementsByTagName("form")[1].classList.add("withoutFlex");

          if (sHeader.cont_type == "L" || sHeader.cont_type == "M") {
            document.getElementsByTagName("form")[1].classList.add("withFlex");
            if (sHeader.cont_type == "L") {
              document.getElementsByClassName("lblFlexMarketPricing")[0].childNodes[0].childNodes[0].innerText = "Floor Pricings:";
            }
            if (sHeader.cont_type == "M") {
              document.getElementsByClassName("lblFlexMarketPricing")[0].childNodes[0].childNodes[0].innerText = "Flex Market Pricings:";
            }
            setValue(thisObj, 'txtAvlLbs', sPricing.flex_mkt_lbs_available);
            setValue(thisObj, 'txtPriceLbs', sPricing.flex_mkt_lbs_priced);
            setValue(thisObj, 'txtRemngLbs', (sPricing.flex_mkt_lbs_available - sPricing.flex_mkt_lbs_priced));
            if (sPricing.flex_mkt_end_date !== "") {
              setValue(thisObj, 'txtMktEndDt', ConvertToDate(sPricing.flex_mkt_end_date));
            }
            let permissionDetails = await ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '004', 'I', sHeader.buy_pt_id);
            if (permissionDetails[0].permission == 'Y') {
              show(thisObj, 'btnFlxMktPcngDet');
              if (sHeader.cont_type == "L")
                document.getElementsByClassName("btnFlxMktPcngDet")[0].childNodes[0].childNodes[0].innerText = "Floor Pricing Details";

              if (sHeader.cont_type == "M")
                document.getElementsByClassName("btnFlxMktPcngDet")[0].childNodes[0].childNodes[0].innerText = "Flex Market Pricing Details";

              show(thisObj, 'btnFlxMktWrkSht');

              if (sHeader.cont_type == "L")
                document.getElementsByClassName("btnFlxMktWrkSht")[0].childNodes[0].childNodes[0].innerText = "Floor Pricing Worksheet";

              if (sHeader.cont_type == "M")
                document.getElementsByClassName("btnFlxMktWrkSht")[0].childNodes[0].childNodes[0].innerText = "Flex Market Worksheet";
            } else {
              hide(thisObj, 'btnFlxMktPcngDet');
              hide(thisObj, 'btnFlxMktWrkSht');
            }
          }
        }
      }

      setValue(thisObj, 'txtarCmnnts', sComments == null ? '' : sComments);

      if (sExceptPerms.length > 0) {
        let pd_codes = '';
        for (let i = 0; i < sExceptPerms.length; i++) {
          if (i == 0) {
            pd_codes += sExceptPerms[i].pd_code;
          } else {
            pd_codes += ', ' + sExceptPerms[i].pd_code;
          }
        }
        setValue(thisObj, 'txtarExcPrm', pd_codes);
        // document.getElementById("ddLocation").disabled = true;
        disable(thisObj,'ddLocation')
        setValue(thisObj, "ddLocation", sHeader.buy_pt_id);
      }

      strVariety = sHeader.pnut_variety_id;

      let checkSeedGrowerValue = document.getElementById('chkBxSeedGrowr').checked;
      let chkSeedGrower2010 = document.getElementById('chkSeedGrower2010').checked;
      let mbSecurityUpdateAfterSigned = localStorage.getItem('SecurityUpdateAfterSigned') == 'true' ? true : false;
      let mbApplicationsExist = localStorage.getItem('ApplicationsExist') == 'true' ? true : false;
      if (sHeader.cont_status !== 'S') {
        if (mbApplicationsExist == true && mbSecurityUpdateAfterSigned == true && chkSeedGrower2010 == false) {
          document.getElementById('ddPeaNtVrty').disabled = false;
        }
      } else {
        if (mbApplicationsExist == true && mbSecurityUpdateAfterSigned == true && checkSeedGrowerValue == false) {
          document.getElementById('ddPeaNtVrty').disabled = false;
        }
      }

      let txtStatus = sHeader.cont_status;
      let ddType = sHeader.cont_type;
      let txtContrctNo = sHeader.contract_num;
      let txtApplied = sPricing !== undefined ? sPricing.appl_lbs : '';
      let txtApp2 = sPricing !== undefined ? sPricing.appl_lbs : '';
      let txtGrpng = sHeader.grouping_num;
      let txtDtCncl = ConvertToDate(sHeader.auto_cancel_date);
      EnableDisableControl(txtStatus, ddType, txtContrctNo, txtApplied, txtApp2, txtGrpng, txtDtCncl);
      commonContext.setContractProfile_FormState('');
      if  (document.getElementsByClassName('btnCreate')[1] != undefined){
        document.getElementsByClassName('btnCreate')[1].childNodes[0].childNodes[0].innerText = 'Save';
      }
      if  (document.getElementsByClassName('btnCreate')[0] != undefined){
        document.getElementsByClassName('btnCreate')[0].childNodes[0].childNodes[0].innerText = 'Save';
      }
      bCheckRTP(sHeader.contract_num);
      setLoading(false);
    }
  }
  // bFill Form

  // Methods
  const FormLoad = async () => {
    setValue(thisObj, "date", ConvertToDate(new Date()));
    disable(thisObj, "btnSetpExcPrm");
    hide(thisObj, 'txtShare');
    // For Flex and Floor Market
    document.getElementsByClassName("lblFlexMarketPricing")[0].style.visibility = "hidden";
    document.getElementsByClassName("lblMktPrcng")[0].style.visibility = "hidden";
    document.getElementsByClassName("txtAvlLbs")[0].style.visibility = "hidden";
    document.getElementsByClassName("txtPriceLbs")[0].style.visibility = "hidden";
    document.getElementsByClassName("txtRemngLbs")[0].style.visibility = "hidden";
    document.getElementsByClassName("txtMktEndDt")[0].style.visibility = "hidden";
    document.getElementsByClassName("btnFlxMktPcngDet")[0].style.visibility = "hidden";
    document.getElementsByClassName("btnFlxMktWrkSht")[0].style.visibility = "hidden";
    // For Flex and Floor Market
    setValue(thisObj, "txtSignAdv", '');
    setValue(thisObj, "chkBxSeedGrowr", false);
    hide(thisObj, "btnSeedGrwrAgreemnt");
    hide(thisObj, "btnChemiclAgreemnt");
    hide(thisObj, "btnFmAgreement");
    hide(thisObj, 'btnClrAdvnc');
    localStorage.setItem('ApplicationsExist', false);
    // hide(thisObj, 'dtMtchEndDate');
    document.getElementsByClassName("dtMtchEndDate")[0].style.visibility = "hidden";
    vendorNumber = '';

    // mbApplicationsExist = false;
    // setApplicationExist(false);
    lblAddDate = "04/01/2009";
    // Bind State List to lineholder list
    GetStateList(compId, cropYear, null, null, false);

    // Get Security Roles and Permission
    // mbSecurityUpdatePending = true;
    // mbSecurityUpdateAfterSigned = true;
    // mbSecurityInquiryAfterSigned = true;
    // mbSecurityVarietyChange = true;
    // mbException_Button = false;
    let securityUpdateAfterSigned = await ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '002', 'U', null);
    if (securityUpdateAfterSigned.length > 0 && securityUpdateAfterSigned[0].permission == 'N') {
      // mbSecurityUpdateAfterSigned = false;
      localStorage.setItem('SecurityUpdateAfterSigned', false);
    } else {
      localStorage.setItem('SecurityUpdateAfterSigned', true);
    }

    let securityUpdatePending = await ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '001', 'U', null);
    if (securityUpdatePending.length > 0 && securityUpdatePending[0].permission == 'N') {
      // mbSecurityUpdatePending = false;
      localStorage.setItem('SecurityUpdatePending', false);
    } else {
      localStorage.setItem('SecurityUpdatePending', true);
    }

    // let securityUpdateFarmSpecific = await ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '003', 'U', null);
    // if (securityUpdateFarmSpecific.length > 0 && securityUpdateFarmSpecific[0].permission == 'N') {
    //   mbSecurityUpdateFarmSpecific = false;
    // } else {
    //   mbSecurityUpdateFarmSpecific = true;
    // }

    let securityInquiryAfterSigned = await ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '002', 'I', null);
    if (securityInquiryAfterSigned.length > 0 && securityInquiryAfterSigned[0].permission == 'N') {
      // mbSecurityInquiryAfterSigned = false;
      localStorage.setItem('SecurityInquiryAfterSigned', false);
    } else {
      localStorage.setItem('SecurityInquiryAfterSigned', true);
    }

    let exceptionButton = await ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '007', 'U', null);
    if (exceptionButton.length > 0 && exceptionButton[0].permission == 'N') {
      disable(thisObj, "btnSetpExcPrm");
      localStorage.setItem('Exception_Button', false);
    } else {
      // mbException_Button = true;
      localStorage.setItem('Exception_Button', true);
    }

    let securityDeletePending = await ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '001', 'D', null);
    if (exceptionButton.length > 0 && securityDeletePending[0].permission == 'N') {
      // mbSecurityDeletePending = false;
      localStorage.setItem('SecurityDeletePending', false);
    } else {
      // mbSecurityDeletePending = true;
      localStorage.setItem('SecurityDeletePending', true);
    }

    let securityVarietyChange = await ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '008', 'U', null);
    if (securityVarietyChange.length > 0 && securityVarietyChange[0].permission == 'N') {
      // mbSecurityVarietyChange = false;
      localStorage.setItem('SecurityVarietyChange', false);
    } else {
      localStorage.setItem('SecurityVarietyChange', true);
    }
    // Get Security Roles and Permission
    mbHaveVendorName = false;
    GetPeanutTypeList();
    GetTypeList();
    GetLocationList();
    let txtStatus = getValue(thisObj, "txtStatus");
    let ddType = getValue(thisObj, "ddType");
    let txtContrctNo = getValue(thisObj, "txtContrctNo");
    let txtApplied = getValue(thisObj, 'txtApplied');
    let txtApp2 = getValue(thisObj, 'txtApp2');
    let txtGrpng = getValue(thisObj, "txtGrpng");
    let txtDtCncl = getValue(thisObj, "txtDtCncl");
    EnableDisableControl(txtStatus, ddType, txtContrctNo, txtApplied, txtApp2, txtGrpng, txtDtCncl);
    setValue(thisObj, 'lblAddedBy', '');
    setValue(thisObj, 'lblChngdBy', '');
    // By default share for vendor always 100%
    setValue(thisObj, "txtShare", 100);
    msUploadStatus = '';
    setmsSeedTBD('');
    if (cropYear >= 2010) {
      document.getElementsByClassName("chkSeedGrower2010")[0].style.visibility = "visible";
      document.getElementsByClassName("chkBxSeedGrowr")[0].style.visibility = "hidden";
    } else {
      document.getElementsByClassName("chkSeedGrower2010")[0].style.visibility = "hidden";
      document.getElementsByClassName("chkBxSeedGrowr")[0].style.visibility = "visible";
    }
    setValue(thisObj, "chkbxFreezeDmgRng", false);
    CheckDamegeRange();
    setLoading(false);
  }

  const VendorDetails = () => {
    let vendorDetails = getData(thisObj, 'vendorDetails');
    if (vendorDetails !== null) {
      vendorNumber = vendorDetails.VendorNumber;
      if (vendorNumber == null && vendorNumber == undefined) {
        setValue(thisObj, "txtShare", 100);
      }

      if (!mbHaveVendorName) {
        setValue(thisObj, "txtVndr", vendorDetails.VendorNumber);
        setData(thisObj, 'ContractProfile_VendorNumber', vendorDetails.VendorNumber);
      }

      setValue(thisObj, "txtEmail", '');
      document.getElementsByClassName('lnkMinDisclsr')[0].childNodes[0].childNodes[0].innerText = '';
      setValue(thisObj, "txt211Gpc", '');
      setValue(thisObj, "txt211concordia", '');

      ContractManagementService.RetieveRemitDetails(vendorDetails.VendorNumber).then(response => {
        let data = response;
        if (data.length > 0) {
          let js = [];
          for (var i = 0; i < data.length; i++) {
            let description =
              data[i].remittoid + '-' +
              data[i].name + '-' +
              data[i].address1 + '' +
              (data[i].address2 == null ? '' : data[i].address2) +
              (data[i].address3 == null ? '' : data[i].address3) +
              (data[i].address4 == null ? '' : data[i].address4) + ' ' +
              data[i].city + ',' +
              data[i].state + ' ' +
              data[i].zip;
            let obj = { key: data[i].remittoid, description: description };
            js.push(obj);
          }
          thisObj.setState(current => {
            return {
              ...current,
              ddRemit: {
                ...state["ddRemit"],
                valueList: js
              },
            }
          });
          let selectedData = data.find(o => o.remittoid == "000");
          if (selectedData !== null) {
            setValue(thisObj, "ddRemit", selectedData.remittoid);
            setValue(thisObj, "txtName", selectedData.name);
            setValue(thisObj, "txtEmail", selectedData.email1 + ' ' + selectedData.email2 + ' ' + selectedData.email3);
            setValue(thisObj, "txt211Gpc", selectedData.golden_form_211);
            setValue(thisObj, "txt211concordia", selectedData.concordia_form_211);
            document.getElementsByClassName('lnkMinDisclsr')[0].childNodes[0].childNodes[0].innerText = selectedData.minority_disclosure;
          }
        }
      });
      showhideAddendums(vendorDetails.VendorNumber);
    }
  };

  const GetStateList = (compId, year, peanutTypeId, stateAbbrevations, filterStatus) => {
    ContractManagementService.RetrievePeanutStateControlDetails(peanutTypeId, stateAbbrevations, filterStatus).then(response => {
      let data = response;
      let js = [];
      let obj;
      for (var i = 0; i < data.length; i++) {
        let dataExist = js.find(elem => elem.key == data[i].stateAbbr.trim());
        if (dataExist == undefined) {
          obj = { key: data[i].stateAbbr.trim(), description: data[i].stateAbbr.trim() };
          js.push(obj);
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          txtState: {
            ...state["txtState"],
            valueList: js
          },
          ddStat2: {
            ...state["ddStat2"],
            valueList: js
          }
        }
      });
    });
  };

  const GetTypeList = () => {
    let js = [];
    js.push({ key: 'O', description: 'Option' });
    js.push({ key: 'C', description: 'Contract' });
    js.push({ key: 'M', description: 'FlexMarket' });
    js.push({ key: 'S', description: 'Seed' });
    if (cropYear >= 2011)
      js.push({ key: 'L', description: 'Floor' });
    thisObj.setState(current => {
      return {
        ...current,
        ddType: {
          ...state["ddType"],
          valueList: js
        }
      }
    });
  };

  const GetLocationList = () => {
    ContractManagementService.RetrieveBuyingPointControlDetails('PN1010', '', '', '', '').then(response => {
      let data = response;
      commonContext.addLocation(response);
      if (data !== undefined && data.length > 0) {
        let js = [];
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
          js.push(obj);
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddLocation: {
              ...state["ddLocation"],
              valueList: js
            }
          }
        });
      }
    });
  };

  const GetPeanutTypeList = () => {
    ContractManagementService.RetrievePeanutTypeControls().then(response => {
      let data = response;
      peanutTypeDataList = response;
      let js = [];
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeName }
        js.push(obj);
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutType: {
            ...state["ddPeanutType"],
            valueList: js
          }
        }
      });
    });
  }

  const GetEmptyPeanutVarietyList = () => {
    let js = [];
    let obj = { key: '', description: '>>> Not Specified <<<' };
    js.push(obj);
    thisObj.setState(current => {
      return {
        ...current,
        ddPeaNtVrty: {
          ...state["ddPeaNtVrty"],
          valueList: js
        }
      }
    });
  }

  const GetDeliveryPeriodList = (buyingPointId, areaid, deliveryid, deliveryPeriodId) => {
    ContractManagementService.RetrieveDeliveryPeriodControlDetails(buyingPointId, areaid, deliveryid).then(response => {
      let data = response;
      if (data !== undefined && data.length > 0) {
        let js = [];
        let obj;
        for (var i = 0; i < data.length; i++) {
          obj = { key: data[i].deliveryId, description: data[i].deliveryPeriod }
          js.push(obj);
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddDlvryPrdDesc: {
              ...state["ddDlvryPrdDesc"],
              valueList: js
            }
          }
        });
        if (deliveryPeriodId !== '' && deliveryPeriodId !== null && deliveryPeriodId !== undefined) {
          let ddDlvryPrdDesc = js.find(elem => elem.description == deliveryPeriodId).key;
          setValue(thisObj, "ddDlvryPrdDesc", ddDlvryPrdDesc.toString());
        }
      }
    });
  };

  const GetPriceList = (typeid, pnuttypeid, segTypeid, organicInd, buyptid, contractnum, pnutvarietyid, oleicind, vendornum) => {
    switch (typeid) {
      case 'O':
        typeid = 'Option';
        break;
      case 'C':
        typeid = 'C-BASIS';
        break;
      case 'M':
        typeid = 'FLEX MKT';
        break;
      case 'S':
        typeid = 'Seed';
        break;
      case 'L':
        typeid = 'Floor';
        break;
    }
    ContractManagementService.RetrievePeanutPriceDetails(typeid, pnuttypeid, segTypeid, organicInd, buyptid, contractnum, pnutvarietyid, oleicind, vendornum).then(response => {
      let peanutPrice = response;
      let js = [];
      let obj;
      if (peanutPrice !== undefined && peanutPrice.length > 0) {
        let description = '';
        obj = { key: '', description: 'Select' };
        js.push(obj);
        for (var i = 0; i < peanutPrice.length; i++) {
          if (peanutPrice[i].pricePerTon !== null) {
            description = peanutPrice[i].pricePerTon;
          }
          if (peanutPrice[i].oleicInd !== null) {
            description = description + ' ' + peanutPrice[i].oleicInd;
          }
          if (peanutPrice[i].pricingTypeDesc !== null) {
            description = description + ' ' + peanutPrice[i].pricingTypeDesc;
          }
          obj = { key: peanutPrice[i].recordKey, description: description };
          js.push(obj);
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddPrice: {
              ...state["ddPrice"],
              valueList: js
            }
          }
        });
        setValue(thisObj, 'ddPrice', "Select");// added - 19th Aug'2020 - PRB0054120/INC5884952
        let strFlexMktTerms = "";
        if (typeid == "Floor") {
          let pricingDetails = peanutPrice.find(x => x.payRebateAt == 'PRICING');
          if (pricingDetails !== null && pricingDetails !== undefined) {
            strFlexMktTerms = strFlexMktTerms + " Floor Contracts must be fully priced on or before the Price Match End Date.";
          }

          let deliveryDetails = peanutPrice.find(x => x.payRebateAt == 'DELIVERY');
          if (deliveryDetails !== null && deliveryDetails !== undefined) {
            strFlexMktTerms = strFlexMktTerms + " Floor Contracts must be fully priced on or before inspection of any peanuts."
          }

          let maxoptionpriceDetails = peanutPrice.find(x => x.maxOptionPrice !== '' && x.maxOptionPrice !== null);
          if (maxoptionpriceDetails !== null && maxoptionpriceDetails !== undefined) {
            strFlexMktTerms = strFlexMktTerms + "  With the Option Price Cap price set as $" + Number(maxoptionpriceDetails.maxOptionPrice).toFixed(2) + "."
          }
        }

        if (typeid == "FLEX MKT") {
          let marketfloorDetails = peanutPrice.find(x => x.marketFloor !== '' && x.marketFloor !== null);
          if (marketfloorDetails !== null && marketfloorDetails !== undefined) {
            strFlexMktTerms = strFlexMktTerms + "Floor: " + marketfloorDetails.marketFloor.toFixed(5);
          }

          let market_ceilingDetails = peanutPrice.find(x => x.marketCeiling !== '' && x.marketCeiling !== null);
          if (market_ceilingDetails !== null && market_ceilingDetails !== undefined) {
            strFlexMktTerms = strFlexMktTerms + "  Ceiling: " + market_ceilingDetails.marketCeiling.toFixed(5);
          }

          let rebate_rateDetails = peanutPrice.find(x => x.rebateRate !== '' && x.rebateRate !== null);
          if (rebate_rateDetails !== null && rebate_rateDetails !== undefined) {
            strFlexMktTerms = strFlexMktTerms + "  @ Rate: " + rebate_rateDetails.rebateRate.toFixed(2) + '/Ton Basis Grade.';
          }

          let mkt_gain_shareDetails = peanutPrice.find(x => x.mktGainShare !== '' && x.mktGainShare !== null);
          if (mkt_gain_shareDetails !== null && mkt_gain_shareDetails !== undefined) {
            if (strFlexMktTerms !== '') {
              strFlexMktTerms = strFlexMktTerms + " - OR - ";
            }
            strFlexMktTerms = strFlexMktTerms + Number(mkt_gain_shareDetails.mktGainShare) + "% of Market Gain.";
          }

          let loan_repay_indDetails = peanutPrice.find(x => x.loanRepayInd !== '' && x.loanRepayInd !== null);
          if (loan_repay_indDetails !== null && loan_repay_indDetails !== undefined) {
            strFlexMktTerms = loan_repay_indDetails.loanRepayInd == 'G' ? strFlexMktTerms + ' Loan Repay Method: GOLDEN' : strFlexMktTerms + ' Loan Repay Method: USDA';
          }

          let multi_pricing_indDetails = peanutPrice.find(x => x.multiPricingInd !== '' && x.multiPricingInd !== null);
          if (multi_pricing_indDetails !== null && multi_pricing_indDetails !== undefined) {
            strFlexMktTerms = multi_pricing_indDetails.multiPricingInd == 'Y' ? strFlexMktTerms + ' Multi Pricings: YES' : strFlexMktTerms + 'Multi Pricings: NO';
          }

          let mkt_wk_prior_matDetails = peanutPrice.find(x => x.mktWkPriorMat !== '' && x.mktWkPriorMat !== null);
          if (mkt_wk_prior_matDetails !== null && mkt_wk_prior_matDetails !== undefined) {
            strFlexMktTerms = strFlexMktTerms + " Mkt Weeks Prior Maturity: " + mkt_wk_prior_matDetails.mktWkPriorMat;
          }
        }

        let baisPriceValue = getValue(thisObj, 'ddPrice');
        if (baisPriceValue !== undefined || baisPriceValue !== null || baisPriceValue !== '') {
          let lstBasisPriceMatchEndDate = peanutPrice.find(x => x.priceMatchEndDate !== '' && x.priceMatchEndDate !== null);
          if (strFlexMktTerms !== "") {
            document.getElementsByTagName("form")[1].classList.add("withFlex");
            if (lstBasisPriceMatchEndDate !== undefined && lstBasisPriceMatchEndDate !== null) {
              setMatchEndDate(lstBasisPriceMatchEndDate.priceMatchEndDate);
              setValue(thisObj, 'dtMtchEndDate', lstBasisPriceMatchEndDate.priceMatchEndDate);
            }
            setTerm(strFlexMktTerms);
            setValue(thisObj, 'lblMktPrcng', strFlexMktTerms);
          }
        }
      } else {
        obj = { key: '', description: 'Select' };
        js.push(obj);
        thisObj.setState(current => {
          return {
            ...current,
            ddPrice: {
              ...state["ddPrice"],
              valueList: js
            }
          }
        });
      }
    });
  };

  const GetFirmPriceList = (pnuttypeid, segTypeid, organicInd, buyptid, contractnum, pnutvarietyid, oleicind, vendornum) => {
    ContractManagementService.RetrievePeanutPriceDetails('C-FIRM', pnuttypeid, segTypeid, organicInd, buyptid, contractnum, pnutvarietyid, oleicind, vendornum).then(response => {
      let data = response;
      let js = [];
      let obj;
      if (data !== undefined && data.length > 0) {
        let description = '';
        obj = { key: '', description: 'Select' };
        js.push(obj);
        for (var i = 0; i < data.length; i++) {
          if (data[i].pricePerTon !== null) {
            description = data[i].pricePerTon;
          }
          if (data[i].oleicInd !== null) {
            description = description + ' ' + data[i].oleicInd;
          }
          if (data[i].pricingTypeDesc !== null) {
            description = description + ' ' + data[i].pricingTypeDesc;
          }
          obj = { key: data[i].recordKey, description: description };
          js.push(obj);
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddPrc: {
              ...state["ddPrc"],
              valueList: js
            }
          }
        });
      } else {
        obj = { key: '', description: 'Select' };
        js.push(obj);
        thisObj.setState(current => {
          return {
            ...current,
            ddPrc: {
              ...state["ddPrc"],
              valueList: js
            }
          }
        });
      }
    });
    setValue(thisObj, 'ddPrc', "Select");//added - 19th Aug'2020 - PRB0054120/INC5884952
  };

  const EnableDisableControl = (txtStatus, ddType, txtContrctNo, txtApplied, txtApp2, txtGrpng, txtDtCncl) => {
    disable(thisObj, "btnCreate");
    disable(thisObj, "btnSign");
    disable(thisObj, "btnRescan");
    disable(thisObj, "btnEmail");
    disable(thisObj, "btnApprv");
    disable(thisObj, "btnVoid");
    disable(thisObj, "btnDeactivate");
    enable(thisObj, "btnPrint");
    enable(thisObj, "btnLookupFrm");
    enable(thisObj, "btnVndrLookupFrm2");
    disable(thisObj, "chckbxAlwRescn");

    let status = txtStatus;
    switch (status) {
      case '':
        disable(thisObj, "btnLookupFrm");
        disable(thisObj, "btnVndrLookupFrm2");
        enable(thisObj, "btnCreate");
        disable(thisObj, "btnPrint");
        disable(thisObj, "btnViewUpldCntrct");
        break;
      case 'P':
        enable(thisObj, "btnCreate");
        enable(thisObj, "btnSign");
        enable(thisObj, "btnVoid");
        setValue(thisObj, "txtStatus", CommonKeys.Pending);
        disable(thisObj, "dtHrvstCmplt");
        disable(thisObj, "btnViewUpldCntrct");
        break;
      case 'S':
        enable(thisObj, "btnCreate");
        enable(thisObj, "btnRescan");
        enable(thisObj, "btnApprv");
        enable(thisObj, "btnVoid");
        setValue(thisObj, "txtStatus", CommonKeys.Signed);
        disable(thisObj, "dtHrvstCmplt");
        enable(thisObj, "btnViewUpldCntrct");
        break;
      case 'A':
        enable(thisObj, "btnRescan");
        enable(thisObj, "btnEmail");
        enable(thisObj, "btnCreate");
        enable(thisObj, "btnDeactivate");
        enable(thisObj, "btnVoid");
        setValue(thisObj, "txtStatus", CommonKeys.Approved);
        enable(thisObj, "btnViewUpldCntrct");
        enable(thisObj, "chckbxAlwRescn");
        break;
      case 'V':
        let cancelDate = txtDtCncl;
        if (cancelDate !== "") {
          if (isValidDate(cancelDate)) {
            setValue(thisObj, "txtStatus", CommonKeys.Cancelled);
          } else {
            setValue(thisObj, "txtStatus", CommonKeys.Voided);
          }
        } else {
          setValue(thisObj, "txtStatus", CommonKeys.Voided);
        }
        disable(thisObj, "dtHrvstCmplt");
        disable(thisObj, "btnRescan");
        disable(thisObj, "btnViewUpldCntrct");
        break;
      case 'C':
        setValue(thisObj, "txtStatus", CommonKeys.Closed);
        disable(thisObj, "dtHrvstCmplt");
        disable(thisObj, "btnRescan");
        disable(thisObj, "btnViewUpldCntrct");
        break;
    }

    let type = ddType;
    switch (type) {
      case 'O':
      case undefined:
        setValue(thisObj, "chckbxHrvst", false);
        setValue(thisObj, "dtEnd", "");
        setValue(thisObj, "txtPounds", "");
        setValue(thisObj, "ddPrc", "");
        setValue(thisObj, "dtStart", "");
        setValue(thisObj, "chkbxHrvst2", false);
        disable(thisObj, "chckbxHrvst");
        disable(thisObj, "dtEnd");
        disable(thisObj, "txtPounds");
        document.getElementById('ddPrc').disabled = true;
        disable(thisObj, "dtStart");
        disable(thisObj, "chkbxHrvst2");
        enable(thisObj, "dtEnd2");
        enable(thisObj, "txtPounds2");
        document.getElementById('ddPrice').disabled = false;
        enable(thisObj, "dtStrt2");
        enable(thisObj, "txtSignAdv");
        setValue(thisObj, 'ddDlvryPrdDesc', '');
        document.getElementById('ddDlvryPrdDesc').disabled = true;
        break;
      case 'M':
        setValue(thisObj, "chckbxHrvst", false);
        setValue(thisObj, "dtEnd", "");
        setValue(thisObj, "txtPounds", "");
        setValue(thisObj, "ddPrc", "");
        setValue(thisObj, "dtStart", "");
        setValue(thisObj, "chkbxHrvst2", false);
        disable(thisObj, "chckbxHrvst");
        disable(thisObj, "dtEnd");
        disable(thisObj, "txtPounds");
        document.getElementById('ddPrc').disabled = true;
        disable(thisObj, "dtStart");
        disable(thisObj, "chkbxHrvst2");
        enable(thisObj, "dtEnd2");
        enable(thisObj, "txtPounds2");
        document.getElementById('ddPrice').disabled = false;
        enable(thisObj, "dtStrt2");
        enable(thisObj, "txtSignAdv");
        setValue(thisObj, 'ddDlvryPrdDesc', '');
        document.getElementById('ddDlvryPrdDesc').disabled = true;
        break;
      case 'C':
        setValue(thisObj, "txtSignAdv", "");
        disable(thisObj, "txtSignAdv");
        enable(thisObj, "chckbxHrvst");
        enable(thisObj, "dtEnd");
        enable(thisObj, "txtPounds");
        document.getElementById('ddPrc').disabled = false;
        enable(thisObj, "dtStart");
        enable(thisObj, "chkbxHrvst2");
        enable(thisObj, "dtEnd2");
        enable(thisObj, "txtPounds2");
        document.getElementById('ddPrice').disabled = false;
        enable(thisObj, "dtStrt2");
        document.getElementById('ddDlvryPrdDesc').disabled = false;
        break;
      case 'S':
        setValue(thisObj, "chckbxHrvst", false);
        setValue(thisObj, "dtEnd", "");
        setValue(thisObj, "txtPounds", "");
        setValue(thisObj, "ddPrc", "");
        setValue(thisObj, "dtStart", "");
        setValue(thisObj, "chkbxHrvst2", false);
        setValue(thisObj, "dtEnd2", "");
        setValue(thisObj, "txtPounds2", "");
        setValue(thisObj, "ddPrice", "");
        setValue(thisObj, "dtStrt2", "");
        disable(thisObj, "chckbxHrvst");
        disable(thisObj, "dtEnd");
        disable(thisObj, "txtPounds");
        document.getElementById('ddPrc').disabled = true;
        disable(thisObj, "dtStart");
        disable(thisObj, "chkbxHrvst2");
        disable(thisObj, "dtEnd2");
        disable(thisObj, "txtPounds2");
        document.getElementById('ddPrice').disabled = true;
        disable(thisObj, "dtStrt2");
        disable(thisObj, "txtSignAdv");
        setValue(thisObj, 'ddDlvryPrdDesc', '');
        document.getElementById('ddDlvryPrdDesc').disabled = true;
        break;
      case 'L':
        setValue(thisObj, "chckbxHrvst", false);
        setValue(thisObj, "dtEnd", "");
        setValue(thisObj, "txtPounds", "");
        setValue(thisObj, "ddPrc", "");
        setValue(thisObj, "dtStart", "");
        setValue(thisObj, "chkbxHrvst2", false);
        disable(thisObj, "chckbxHrvst");
        disable(thisObj, "dtEnd");
        disable(thisObj, "txtPounds");
        document.getElementById('ddPrc').disabled = true;
        disable(thisObj, "dtStart");
        disable(thisObj, "chkbxHrvst2");
        enable(thisObj, "dtEnd2");
        enable(thisObj, "txtPounds2");
        document.getElementById('ddPrice').disabled = false;
        enable(thisObj, "dtStrt2");
        enable(thisObj, "txtSignAdv");
        setValue(thisObj, 'ddDlvryPrdDesc', '');
        document.getElementById('ddDlvryPrdDesc').disabled = true;
        break;
    }

    let contractNumber = txtContrctNo;
    if ((contractNumber == "" || contractNumber == undefined) && type == "S") {
      setValue(thisObj, "chkBxSeedGrowr", true);
      CheckSeedGrower();
      enable(thisObj, "chkBxSeedGrowr");
      // document.getElementById('ddPeanutType').disabled = false;
      enable(thisObj,"ddPeanutType")
    }

    function disableAllTextboxes(){
      var textboxes=document.querySelectorAll("input[type='text']");
      textboxes.forEach(function(textbox){
        textbox.disabled=true;
      }); 
    }
    function disableAllCheckboxes(){
      var checkboxes=document.querySelectorAll("input[type='checkbox']");
      checkboxes.forEach(function(checkbox){
        checkbox.disabled=true;
      }); 
    }

    function disableAllDropdowns() {
      var dropdowns = document.querySelectorAll("button[class='dropdown-toggle btn btn-secondary']");
      dropdowns.forEach(function(dropdown){
        dropdown.disabled = true;
      });
    }

    let mbSecurityUpdateAfterSigned = localStorage.getItem('SecurityUpdateAfterSigned') == 'true' ? true : false;
    if (mbSecurityUpdateAfterSigned == false) {
      disable(thisObj, "chckbxAlwRescn");
      disable(thisObj, "btnApprv");
      disable(thisObj, "btnDeactivate");
      if (status !== "P" && status !== "") {
        disable(thisObj, "btnPrint");
        if (status == "S") {
          // allow rescan if contract is in signed status
          enable(thisObj, "btnRescan");
          enable(thisObj, "btnViewUpldCntrct");
          //INC5123959
          disable(thisObj, "btnCreate");
        }
        if (status == "A" || status == "S") {
          // allow rescan if contract is in signed status
          enable(thisObj, "btnViewUpldCntrct");
          //enable(thisObj, "btnCreate");INC5123959
          disable(thisObj, "btnCreate");
          //--commented and added Jun26 PRB0053552
         // disableAllTextboxes();
       //   disableAllCheckboxes();
     //   disableAllDropdowns();
     disable(thisObj,"ddLocation");
     disable(thisObj,"txtContrctNo");
     disable(thisObj,"ddType");
     disable(thisObj,"txtarExcPrm");
     disable(thisObj,"txtStatus");
     disable(thisObj,"txtGrpng");
     disable(thisObj,"txtGrpingShare");
     disable(thisObj,"txtEntryDt");
     disable(thisObj,"txtDtSign");
     disable(thisObj,"txtDtApprv");
     disable(thisObj,"txtDtClose");
     disable(thisObj,"txtDtVoided");
     disable(thisObj,"txtDtCncl");
     disable(thisObj,"dtHrvstCmplt");
     disable(thisObj,"txtarExcPrm");
     disable(thisObj,"txtarExcPrm");
     disable(thisObj,"ddSegType");
     disable(thisObj,"ddPeanutType");
     //disable(thisObj,"ddPeaNtVrty").;
     document.getElementById('ddPeaNtVrty').disabled = true;
     disable(thisObj,"ddOleic");
     disable(thisObj,"chckbxOrgnc");
     disable(thisObj,"chkSeedGrower2010");
     disable(thisObj,"txtCntrPivtAvgYld");
     disable(thisObj,"txtCntrPivtAcrs");
     disable(thisObj,"txtOthrAvgYld");
     disable(thisObj,"txtOthrAcrs");
     disable(thisObj,"txtDryLndAvgYld");
     disable(thisObj,"txtDryLndAcres");
     disable(thisObj,"txtAvgYld");
     disable(thisObj,"txtTotlAcrs");
     disable(thisObj,"txtVndr");
     disable(thisObj,"ddRemit");
     disable(thisObj,"txtSignAdv");
     disable(thisObj,"txtCllcAdv");
     disable(thisObj,"txtName");
     disable(thisObj,"txtEmail");
     disable(thisObj,"txt211Gpc");
     disable(thisObj,"txt211concordia");
     disable(thisObj,"ddDlvryPrdDesc");
     disable(thisObj,"chckbxHrvst");
     disable(thisObj,"dtStart");
     disable(thisObj,"dtEnd");
     //commented and added -19Aug'2024 - INC5884952/PRB0054120
     //disable(thisObj,"ddPrc");
     document.getElementById('ddPrc').disabled = true;
     //end of changes
     disable(thisObj,"txtApplied");
     disable(thisObj,"txtTntAppld");
     disable(thisObj,"txtTotlApp");
     disable(thisObj,"txtTntOpen");
     disable(thisObj,"chkbxHrvst2");
     disable(thisObj,"dtStrt2");
     disable(thisObj,"dtEnd2");
     disable(thisObj,"txtPounds2");
     disable(thisObj,"txtTntApp2");
     disable(thisObj,"txtTotlApp2");
     disable(thisObj,"chkbxFreezeDmgRng");
     disable(thisObj,"txtbxMax");
     disable(thisObj,"txtbxMin");
     disable(thisObj,"txtLnHldrNm");
     disable(thisObj,"txtAddr");
     disable(thisObj,"txtCity");
     disable(thisObj,"txtState");
     disable(thisObj,"txtZip");
     disable(thisObj,"txtNtrOfLiens");
     disable(thisObj,"txtNature2");
     disable(thisObj,"txtZip2");
     disable(thisObj,"ddStat2");
     disable(thisObj,"txtCity2");
     disable(thisObj,"txtAdd2");
     disable(thisObj,"lienHldr2");
     disable(thisObj,"txtarCmnnts");
     disable(thisObj,"lienHldr2");
     document.getElementById('ddPrice').disabled = true;
     disable(thisObj,"chckbxPrintCmmnt");
     // end of changes
          disable(thisObj, "btnFmAgreement");
          disable(thisObj, "btnChemiclAgreemnt");
          disable(thisObj, "txtarCmnnts");
          //enable(thisObj, "dtHrvstCmplt");
          enable(thisObj, "btnEmail");
          //enable(thisObj, "txtarCmnnts");
          //enable(thisObj, "dtHrvstCmplt");
        }
        enable(thisObj, "btnExit");
        enable(thisObj, "btnSeedGrwrAgreemnt");
        enable(thisObj, "btnLookupFrm");
        enable(thisObj, "btnVndrLookupFrm2");
        enable(thisObj, "btnFlxMktPcngDet");
        enable(thisObj, "btnFlxMktWrkSht");
        document.getElementById('chckbxPrintCmmnt').checked = true;

        if (status == 'C') {
          document.getElementById('chckbxPrintCmmnt').checked = true;
        }
      }
    }

    let mbException_Button = localStorage.getItem('Exception_Button') == 'true' ? true : false;
    if (mbException_Button == true) {
      if (contractNumber !== '' && contractNumber !== undefined) {
        if (status !== '' && status !== undefined) {
          if (status !== 'P') {
            if (type !== 'S') {
              enable(thisObj, 'btnSetpExcPrm');
            }
          }
        }
      }
    }

    let mbSecurityUpdatePending = localStorage.getItem('SecurityUpdatePending') == 'true' ? true : false;
    if (mbSecurityUpdatePending == false) {
      disable(thisObj, "btnSign");
      disable(thisObj, "btnRescan");
      disable(thisObj, "btnEmail");
      disable(thisObj, "btnApprv");
      disable(thisObj, "btnVoid");
      disable(thisObj, "btnDeactivate");
      disable(thisObj, "btnCreate");
      disable(thisObj, "chckbxAlwRescn");
    }

    if (document.getElementById('btnDeactivate').enabled == true &&
      (price_per_ton !== '' && txtApplied == '') ||
      (price_per_ton !== '' && txtApp2 == '')) {
      disable(thisObj, "btnDeactivate");
    }

    if (status === 'V') {
      disable(thisObj, "btnPrint");
    } else {
      enable(thisObj, "btnPrint");
    }

    let mbApplicationsExist = localStorage.getItem('ApplicationsExist') == 'true' ? true : false;
    if (mbApplicationsExist == true) {
      disable(thisObj, "btnPopUp");
      disable(thisObj, "btnVoid");
      // disable(thisObj, "ddType");
      document.getElementById('ddType').disabled = true;
      disable(thisObj, "ddLocation");
      // document.getElementById('ddLocation').disabled = true;
      disable(thisObj, "ddSegType");
      disable(thisObj, "ddPeanutType");
      // document.getElementById('ddPeanutType').disabled = true;
      // disable(thisObj, "ddPeaNtVrty");
      document.getElementById('ddPeaNtVrty').disabled = true;
      disable(thisObj, "ddOleic");
      disable(thisObj, "chckbxOrgnc");
      document.getElementById('ddRemit').disabled = true;
      // disable(thisObj, "txtState");
      // disable(thisObj, "ddStat2");
      disable(thisObj, "txtVndr");
      disable(thisObj, "chckbxHrvst");
      disable(thisObj, "chkbxHrvst2");
      document.getElementById('ddPrice').disabled = true;
      disable(thisObj, "txtContrctNo");
      disable(thisObj, "txtCntrctno2");
      disable(thisObj, "dateParent");
      document.getElementById('ddPrc').disabled = true;
      hide(thisObj, "txtShare");
      disable(thisObj, "txtSignAdv");
      disable(thisObj, "chckbxAlwRescn");
    }

    let mbSecurityVarietyChange = localStorage.getItem('SecurityVarietyChange') == 'true' ? true : false;
    if (mbSecurityVarietyChange == true && type !== 'S') {
      document.getElementById('ddPeaNtVrty').disabled = false;
    }

    let group = txtGrpng;
    if (group !== '' && group !== undefined) {
      // document.getElementById('ddLocation').disabled = true;
      disable(thisObj,"ddLocation")
      disable(thisObj, "ddSegType");
      // document.getElementById('ddPeanutType').disabled = true;
      disable(thisObj,"ddPeanutType")
      document.getElementById('ddPeaNtVrty').disabled = true;
      document.getElementById('ddOleic').disabled = true;
      disable(thisObj, "chckbxOrgnc");
      disable(thisObj, "chkBxSeedGrowr");
      disable(thisObj, "txtCntrPivtAvgYld");
      disable(thisObj, "txtCntrPivtAcrs");
      disable(thisObj, "txtOthrAvgYld");
      disable(thisObj, "txtOthrAcrs");
      disable(thisObj, "txtDryLndAvgYld");
      disable(thisObj, "txtDryLndAcres");
      disable(thisObj, "txtVndr");
      hide(thisObj, "txtShare");
      disable(thisObj, "btnVoid");
      disable(thisObj, "btnDeactivate");
      enable(thisObj, "btnLookup");
    } else {
      disable(thisObj, "btnLookup");
    }

    if (process.env.REACT_APP_ENVIR == "TEST" || process.env.REACT_APP_ENVIR == "DEV" || process.env.REACT_APP_ENVIR == "LOCAL" || process.env.REACT_APP_ENVIR == 'UAT') {
      enable(thisObj, "btnEnblDate");
      show(thisObj, 'btnEnblDate');
    } else {
      disable(thisObj, "btnEnblDate");
      hide(thisObj, 'btnEnblDate');
    }

    let mbSecurityInquiryAfterSigned = localStorage.getItem('SecurityInquiryAfterSigned') == 'true' ? true : false;
    if (mbSecurityInquiryAfterSigned == true) {
      enable(thisObj, "btnPymnt");
      enable(thisObj, "btnAccDist");
    }

    let mbSecurityDeletePending = localStorage.getItem('SecurityDeletePending') == 'true' ? true : false;
    if (mbSecurityDeletePending == false && status == 'P') {
      disable(thisObj, 'btnVoid');
    }

    EnableDisableSeedAgreeInfo();
  }

  const EnableDisableSeedAgreeInfo = () => {
    let type = getValue(thisObj, "ddType");
    let checkSeedGrowerValue = document.getElementById('chkBxSeedGrowr').checked;
    if (checkSeedGrowerValue == false) {
      hide(thisObj, "btnSeedGrwrAgreemnt");
    } else {
      if (type !== 'S' && type !== '' && type !== undefined) {
        let vendorName = getValue(thisObj, "txtName");
        if (vendorName !== "" && vendorName !== undefined) {
          show(thisObj, "btnSeedGrwrAgreemnt");
        }
      } else {
        show(thisObj, "btnSeedGrwrAgreemnt");
      }
    }
  }

  function bNoAddendums2009(addendumsValue) {
    switch (addendumsValue) {
      case 'SEED GROWER CHANGE':
        let checkSeedGrowerValue = document.getElementById('chkBxSeedGrowr').checked;
        if (checkSeedGrowerValue == true) {
          setValue(thisObj, 'ddSegType', '1');
          disable(thisObj, 'ddSegType');
          // document.getElementById('ddPeanutType').disabled = true;

          let pnut_type_id = thisObj.values['ddPeanutType'];
          let buy_pt_id = thisObj.values['ddLocation'];
          getPeanutVariety(buy_pt_id, null, null, pnut_type_id, null);
          setValue(thisObj, 'ddPeaNtVrty', '');
          document.getElementById('ddPeaNtVrty').disabled = true;
        } else {
          enable(thisObj, 'ddSegType');
          // document.getElementById('ddPeanutType').disabled = false;
          enable(thisObj,"ddPeanutType")
          document.getElementById('ddPeaNtVrty').disabled = false;
        }
        if ((cmdSeedGrower == "" || cmdSeedGrower == null) && msSeedTBD !== 'Y') {
          document.getElementById("btnSeedGrwrAgreemnt").style.backgroundImage = 'linear-gradient(white, #e9ecef 60%, #dde2e6)';
          document.getElementById("btnSeedGrwrAgreemnt").style.backgroundColor = "#d3d9df";
        } else {
          document.getElementById("btnSeedGrwrAgreemnt").style.backgroundImage = "none";
          document.getElementById("btnSeedGrwrAgreemnt").style.backgroundColor = "#AFDBC9";
        }
        break;
    }
  }

  const CheckDamegeRange = () => {
    let valueDamegeRange = document.getElementById('chkbxFreezeDmgRng').checked;
    if (valueDamegeRange) {
      enable(thisObj, 'txtbxMin');
      enable(thisObj, 'txtbxMax');
    } else {
      disable(thisObj, 'txtbxMin');
      disable(thisObj, 'txtbxMax');
    }
  }

  const CheckSeedGrower = () => {
    let chkSeedGrower = document.getElementById('chkBxSeedGrowr').checked;
    let ddPeaNtVrty = getValue(thisObj, 'ddPeaNtVrty');
    if (chkSeedGrower) {
      if (ddPeaNtVrty !== '' && ddPeaNtVrty !== undefined) {
        showMessage(thisObj, "'Not Specified' should be selected for Seed Grower.");
        setValue(thisObj, 'chkBxSeedGrowr', false);
      }
    }

    if (!chkSeedGrower) {
      setmsSeedTBD('N');
    }
    EnableDisableSeedAgreeInfo();
    bNoAddendums2009('SEED GROWER CHANGE');
  }

  function getPeanutVariety(buy_pt_id, coll_pt_id, area_id, pnut_type_id, pnut_variety_id) {
    ContractManagementService.RetrievePeanutVarietyControls(buy_pt_id, coll_pt_id, area_id, pnut_type_id, pnut_variety_id).then(response => {
      let data = response;
      let js = [];
      let obj;
      obj = { key: '', description: '>>> Not Specified <<<' };
      js.push(obj);
      for (var i = 0; i < data.length; i++) {
        let dataExist = js.find(elem => elem.key == data[i].pnut_variety_id);
        if (dataExist == undefined) {
          obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name };
          js.push(obj);
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddPeaNtVrty: {
            ...state["ddPeaNtVrty"],
            valueList: js
          }
        }
      });
      setValue(thisObj, 'ddPeaNtVrty', '');
      PeanutVarietyChange();
    });
  }

  function showhideAddendums(id) {
    let vendorId = id;
    let type = getValue(thisObj, 'ddType');
    let contractNumber = getValue(thisObj, "txtContrctNo");
    let chkSeedGrowser = document.getElementById('chkBxSeedGrowr').checked;
    let buyingPointId = thisObj.values['ddLocation'];
    let locationDetailsById = commonContext.locationList.find(x => x.buy_pt_id == buyingPointId);

    if (vendorId == undefined || vendorId == null || vendorId == '') {
      hide(thisObj, "btnSeedGrwrAgreemnt");
      hide(thisObj, "btnFmAgreement");
      hide(thisObj, "btnChemiclAgreemnt");
      if ((contractNumber == undefined || contractNumber == '') && type == "S" && chkSeedGrowser == true) {
        show(thisObj, "btnSeedGrwrAgreemnt");
      }
    } else {
      let chkSeedGrower = document.getElementById('chkBxSeedGrowr').checked;
      if (chkSeedGrower) {
        show(thisObj, "btnSeedGrwrAgreemnt");
      } else {
        hide(thisObj, "btnSeedGrwrAgreemnt");
      }

      if (buyingPointId !== undefined && buyingPointId !== '') {
        // Chemical Addendum Check
        if (locationDetailsById !== null) {
          show(thisObj, "btnChemiclAgreemnt");
          document.getElementById("btnChemiclAgreemnt").style.backgroundImage = "none";
          document.getElementById("btnChemiclAgreemnt").style.backgroundColor = "#AFDBC9";
        } else {
          hide(thisObj, "btnChemiclAgreemnt");
          document.getElementById("btnChemiclAgreemnt").style.backgroundImage = 'linear-gradient(white, #e9ecef 60%, #dde2e6)';
          document.getElementById("btnChemiclAgreemnt").style.backgroundColor = "#d3d9df";
        }
      }

      // Foreign Matarial Check
      if (buyingPointId !== undefined && buyingPointId !== '') {
        if (locationDetailsById.fm_agree_ind == 'Y') {
          show(thisObj, "btnFmAgreement");
          document.getElementById("btnFmAgreement").style.backgroundImage = "none";
          document.getElementById("btnFmAgreement").style.backgroundColor = "#AFDBC9";
        } else {
          hide(thisObj, "btnFmAgreement");
          document.getElementById("btnFmAgreement").style.backgroundImage = 'linear-gradient(white, #e9ecef 60%, #dde2e6)';
          document.getElementById("btnFmAgreement").style.backgroundColor = "#d3d9df";
        }
      }
    }
  }

  function isValidDate(date) {
    let dateObj = new Date(date);
    if (dateObj instanceof Date) {
      return true;
    } else {
      return false;
    }
  }

  const ComputeSigningAdvance = async () => {
    let singAdvanceValue = getValue(thisObj, 'txtSignAdv');
    let txtShare = getValue(thisObj, 'txtShare');
    if (lblAddDate > "03/01/2005") {
      if (singAdvanceValue == "" && Number.isInteger(txtShare) && document.getElementById('txtSignAdv').enabled) {
        let ppsDefault = await ContractManagementService.RetrievePeanutStaticValues();
        if (ppsDefault !== undefined && ppsDefault.length > 0) {
          if (Number.isInteger(Number(ppsDefault[0].pps_defaults.ej_default_overrides.signing_advance))) {
            mdblSignAdv = Number(ppsDefault[0].pps_defaults.ej_default_overrides.signing_advance);
            let signingAdvance = mdblSignAdv * (txtShare / 100)
            setValue(thisObj, 'txtSignAdv', signingAdvance);
          }
        }
      }
    } else {
      mdblSignAdv = 100;
      if (singAdvanceValue == "" && Number.isInteger(txtShare) && document.getElementById('txtSignAdv').enabled) {
        (mdblSignAdv * txtShare / 100);
        setValue(thisObj, 'txtSignAdv', value);
      }
    }
  }

  function ConvertToDate(str) {
    if (str !== undefined && str !== '') {
      let date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [mnth, day, date.getFullYear()].join("/");
    } else {
      return '';
    }
  }

  function PeanutTypeChange() {
    let pnut_type_id = thisObj.values['ddPeanutType'];
    let buy_pt_id = thisObj.values['ddLocation'];
    getPeanutVariety(buy_pt_id, null, null, pnut_type_id, null);
    vendorNumber = getValue(thisObj, 'txtVndr');
    showhideAddendums(vendorNumber);
    let type = getValue(thisObj, 'ddType');
    let pnuttypeid = getValue(thisObj, 'ddPeanutType');
    let segtypeid = getValue(thisObj, 'ddSegType');
    let organicInd = document.getElementById('chckbxOrgnc').checked == true ? 'Y' : 'N';
    let contractnum = getValue(thisObj, 'txtContrctNo');
    let pnutvarietyid = getValue(thisObj, 'ddPeaNtVrty');
    let buyingpointId = getValue(thisObj, 'ddLocation');
    let oleicid = getValue(thisObj, 'ddOleic');

    GetFirmPriceList(pnuttypeid, segtypeid, organicInd, buyingpointId, contractnum, pnutvarietyid, oleicid, vendorNumber);
    GetPriceList(type, pnuttypeid, segtypeid, organicInd, buyingpointId, contractnum, pnutvarietyid, oleicid, vendorNumber);
  }

  function LocationChange() {
    let buyingPointId = thisObj.values['ddLocation'];
    if (buyingPointId !== undefined && buyingPointId !== '') {
      let locationDetailsById = commonContext.locationList.find(x => x.buy_pt_id == buyingPointId);
      // Foreign Matarial Check
      if (locationDetailsById.fm_agree_ind == 'Y') {
        setcmdForeignMatarial('Y');
      } else {
        setcmdForeignMatarial('N');
      }
      // Chemical Addendum Check
      if (locationDetailsById !== null) {
        setCmdChemicalAddendum('Y');
      } else {
        setCmdChemicalAddendum('N');
      }

      vendorNumber = getValue(thisObj, 'txtVndr');

      // let typelist = thisObj.state['ddType'].valueList;
      let typeid = thisObj.values['ddType'];
      // let typeid = null;
      // if (typevalue !== undefined && typevalue !== null) {
      //   typeid = typelist.find(elem => elem.key == typevalue).description;
      // }
      let pnuttypeid = getValue(thisObj, 'ddPeanutType');
      let segtypeid = getValue(thisObj, 'ddSegType');
      let organicInd = document.getElementById('chckbxOrgnc').checked == true ? 'Y' : 'N';
      let contractnum = getValue(thisObj, 'txtContrctNo');
      let pnutvarietyid = getValue(thisObj, 'ddPeaNtVrty');
      let oleicid = getValue(thisObj, 'ddOleic');

      // Get Varieties Data
      getPeanutVariety(buyingPointId, null, null, pnuttypeid, null);
      showhideAddendums(vendorNumber);
      // Get Delivery Period Data
      GetDeliveryPeriodList(buyingPointId, null, null, null);
      GetFirmPriceList(pnuttypeid, segtypeid, organicInd, buyingPointId, (contractnum == undefined || null ? 0 : contractnum), pnutvarietyid, oleicid, vendorNumber);
      GetPriceList(typeid, pnuttypeid, segtypeid, organicInd, buyingPointId, (contractnum == undefined || null ? 0 : contractnum), pnutvarietyid, oleicid, vendorNumber);
    }
  }

  function PeanutVarietyChange() {
    let vendorId = getValue(thisObj, 'txtVndr');
    if (vendorId == undefined) {
      vendorId = vendorNumber;
      showhideAddendums(vendorId);
    } else {
      vendorId = getValue(thisObj, 'txtVndr');
      showhideAddendums(vendorId);
    }
    let type = getValue(thisObj, 'ddType');
    let pnuttypeid = getValue(thisObj, 'ddPeanutType');
    let segtypeid = getValue(thisObj, 'ddSegType');
    let organicInd = document.getElementById('chckbxOrgnc').checked == true ? 'Y' : 'N';
    let contractnum = getValue(thisObj, 'txtContrctNo');
    let pnutvarietyid = getValue(thisObj, 'ddPeaNtVrty');
    let buyingpointId = getValue(thisObj, 'ddLocation');
    let oleicid = getValue(thisObj, 'ddOleic');

    GetFirmPriceList(pnuttypeid, segtypeid, organicInd, buyingpointId, contractnum, pnutvarietyid, oleicid, vendorId);
    GetPriceList(type, pnuttypeid, segtypeid, organicInd, buyingpointId, contractnum, pnutvarietyid, oleicid, vendorId);
  }

  function CalculateTotal(txtCntrPivtAvgYld, txtCntrPivtAcrs, txtOthrAvgYld, txtOthrAcrs, txtDryLndAvgYld, txtDryLndAcres) {
    let lCenterPivotYield;
    let lCenterPivotAcres;
    let lOtherYield;
    let lOtherAcres;
    let lDryLandYield;
    let lDryLandAcres;
    let lTotalAcres;
    let lAvgYield;

    if (txtCntrPivtAvgYld == '' || txtCntrPivtAvgYld == undefined) {
      lCenterPivotYield = 0
    } else {
      lCenterPivotYield = txtCntrPivtAvgYld;
    }

    if (txtCntrPivtAcrs == '' || txtCntrPivtAcrs == undefined) {
      lCenterPivotAcres = 0
    } else {
      lCenterPivotAcres = txtCntrPivtAcrs;
    }

    if (txtOthrAvgYld == '' || txtOthrAvgYld == undefined) {
      lOtherYield = 0
    } else {
      lOtherYield = txtOthrAvgYld;
    }

    if (txtOthrAcrs == '' || txtOthrAcrs == undefined) {
      lOtherAcres = 0
    } else {
      lOtherAcres = txtOthrAcrs;
    }

    if (txtDryLndAvgYld == '' || txtDryLndAvgYld == undefined) {
      lDryLandYield = 0
    } else {
      lDryLandYield = txtDryLndAvgYld;
    }

    if (txtDryLndAcres == '' || txtDryLndAcres == undefined) {
      lDryLandAcres = 0
    } else {
      lDryLandAcres = txtDryLndAcres;
    }

    lTotalAcres = Number(lCenterPivotAcres) + Number(lOtherAcres) + Number(lDryLandAcres);

    if (lTotalAcres !== 0) {
      let lblTotalAcres = lTotalAcres;
      let lblAvgYieldPerAcre;
      lAvgYield = ((Number(lCenterPivotYield) * Number(lCenterPivotAcres)) + (Number(lOtherYield) * Number(lOtherAcres)) + (Number(lDryLandAcres) * Number(lDryLandYield))) / lTotalAcres;
      if (lAvgYield == 0) {
        lAvgYield = '';
      } else {
        lblAvgYieldPerAcre = lAvgYield.toFixed();
      }
      setValue(thisObj, 'txtAvgYld', lblAvgYieldPerAcre);
      setValue(thisObj, 'txtTotlAcrs', lblTotalAcres);
    } else {
      setValue(thisObj, 'txtAvgYld', '');
      setValue(thisObj, 'txtTotlAcrs', '');
    }
  }

  const bSaveContract = async (sChangeStatus, sEmailInd, sUploadStatus, sFTPFilename) => {
    let peanutTypeId = getValue(thisObj, 'ddPeanutType');
    let buy_pt_id = getValue(thisObj, 'ddLocation');
    let contractNumber = getValue(thisObj, 'txtContrctNo');
    let pnut_variety_id = getValue(thisObj, 'ddPeaNtVrty');
    let GstrVersion = '';
    if (process.env.REACT_APP_ENVIR == "PROD") {
      GstrVersion = 'P';
    } else {
      GstrVersion = 'T';
    }
    let ddDlvryPrdDesc = null;
    if (pnut_variety_id == undefined || pnut_variety_id == null) {
      pnut_variety_id = '';
    }
    let cont_type = getValue(thisObj, 'ddType');
    if (cont_type == 'C') {
      // Description
      let desclist = thisObj.state['ddDlvryPrdDesc'].valueList;
      let descvalue = thisObj.values['ddDlvryPrdDesc'];
      if (descvalue !== undefined && descvalue !== null && descvalue !== '') {
        ddDlvryPrdDesc = desclist.find(elem => elem.key == descvalue).description;
      }
    }
    let organic = document.getElementById('chckbxOrgnc').checked == true ? 'Y' : 'N'
    let oleic_ind = getValue(thisObj, 'ddOleic');
    if (oleic_ind == 'N') {
      oleic_ind = '';
    }

    let dtHrvstCmplt = getValue(thisObj, "dtHrvstCmplt");
    if (dtHrvstCmplt !== undefined && dtHrvstCmplt !== null && dtHrvstCmplt !== '') {
      dtHrvstCmplt = new Date(dtHrvstCmplt);
    } else {
      dtHrvstCmplt = null;
    }

    let seg_type = getValue(thisObj, 'ddSegType');

    let ddType = getValue(thisObj, 'ddType');
    let seedGrowerValue = '';
    if (ddType !== "S") {
      if (document.getElementById('chkSeedGrower2010').checked) {
        seedGrowerValue = 'Y'
      } else {
        seedGrowerValue = 'N';
      }
    } else {
      if (document.getElementById('chkBxSeedGrowr').checked) {
        seedGrowerValue = 'Y'
      } else {
        seedGrowerValue = 'N';
      }
    }

    let center_pivot_yield = Number(getValue(thisObj, 'txtCntrPivtAvgYld')) > 0 ? getValue(thisObj, 'txtCntrPivtAvgYld') : 0;
    let center_pivot_acres = Number(getValue(thisObj, 'txtCntrPivtAcrs')) > 0 ? Number(getValue(thisObj, 'txtCntrPivtAcrs')) : 0;
    let other_yield = Number(getValue(thisObj, 'txtOthrAvgYld')) > 0 ? getValue(thisObj, 'txtOthrAvgYld') : 0;
    let other_acres = Number(getValue(thisObj, 'txtOthrAcrs')) > 0 ? Number(getValue(thisObj, 'txtOthrAcrs')) : 0;
    let dry_land_yield = Number(getValue(thisObj, 'txtDryLndAvgYld')) > 0 ? getValue(thisObj, 'txtDryLndAvgYld') : 0;
    let dry_land_acres = Number(getValue(thisObj, 'txtDryLndAcres')) > 0 ? Number(getValue(thisObj, 'txtDryLndAcres')) : 0;
    let yield_per_acre = Number(getValue(thisObj, 'txtAvgYld')) > 0 ? Number(getValue(thisObj, 'txtAvgYld')) : 0;

    let inputdate = getValue(thisObj, 'date')
    let cont_date = (new Date(new Date(inputdate).getTime() - (new Date().getTimezoneOffset() * 60000))).toISOString()
    let PRICING_LINE1 = 0;
    let FIRM_BASIS1 = '';
    let AT_HARVEST1 = '';
    let CONT_LBS1 = 0;
    let PRICE_PER_TON1 = 0;
    let PRICE_LIMIT_REC_KEY1 = 0;
    let DELV_BEG_DATE1 = null;
    let DELV_END_DATE1 = null;
    let txtFirmPounds = getValue(thisObj, 'txtPounds');
    let cboFirmPrice = getValue(thisObj, 'ddPrc');
    let txtFirmStartDate = getValue(thisObj, 'dtStart');
    let txtFirmEndDate = getValue(thisObj, 'dtEnd');

    if (txtFirmPounds !== "" && txtFirmPounds !== undefined && cboFirmPrice !== "" && cboFirmPrice !== undefined) {
      PRICING_LINE1 = 1;
      FIRM_BASIS1 = 'F';
      if (document.getElementById('chckbxHrvst').checked) {
        AT_HARVEST1 = 'Y';
      } else {
        AT_HARVEST1 = 'N';
      }
      if (Number.isInteger(Number(txtFirmPounds))) {
        CONT_LBS1 = Number(txtFirmPounds);
      } else {
        CONT_LBS1 = 0;
      }
      PRICE_PER_TON1 = 0
      PRICE_LIMIT_REC_KEY1 = Number(cboFirmPrice);
      DELV_BEG_DATE1 = (new Date(new Date(txtFirmStartDate).getTime() - (new Date().getTimezoneOffset() * 60000))).toISOString();
      DELV_END_DATE1 = (new Date(new Date(txtFirmEndDate).getTime() - (new Date().getTimezoneOffset() * 60000))).toISOString();
    }

    let PRICING_LINE2 = 0;
    let FIRM_BASIS2 = '';
    let AT_HARVEST2 = '';
    let CONT_LBS2 = 0;
    let PRICE_PER_TON2 = '';
    let PRICE_LIMIT_REC_KEY2 = 0;
    let DELV_BEG_DATE2 = null;
    let DELV_END_DATE2 = null;
    let txtBasisPounds = getValue(thisObj, 'txtPounds2');
    let cboBasisPrice = getValue(thisObj, 'ddPrice');
    let txtBasisStartDate = getValue(thisObj, 'dtStrt2');
    let txtBasisEndDate = getValue(thisObj, 'dtEnd2');

    if (txtBasisPounds !== "" && txtBasisPounds !== undefined && cboBasisPrice !== "" && cboBasisPrice !== undefined) {
      PRICING_LINE2 = 2;
      FIRM_BASIS2 = 'B';
      if (document.getElementById('chkbxHrvst2').checked) {
        AT_HARVEST2 = 'Y';
      } else {
        AT_HARVEST2 = 'N';
      }
      if (Number.isInteger(Number(txtBasisPounds))) {
        CONT_LBS2 = Number(txtBasisPounds);
      } else {
        CONT_LBS2 = 0;
      }
      PRICE_PER_TON2 = 0;
      PRICE_LIMIT_REC_KEY2 = Number(cboBasisPrice);
      DELV_BEG_DATE2 = (new Date(new Date(txtBasisStartDate).getTime() - (new Date().getTimezoneOffset() * 60000))).toISOString();
      DELV_END_DATE2 = (new Date(new Date(txtBasisEndDate).getTime() - (new Date().getTimezoneOffset() * 60000))).toISOString();
    }

    let lienholder_name1 = getValue(thisObj, 'txtLnHldrNm');
    let lienholder_address1 = getValue(thisObj, 'txtAddr');
    let lienholder_city1 = getValue(thisObj, 'txtCity');
    let lienholder_state1 = getValue(thisObj, 'txtState');
    let lienholder_zip1 = getValue(thisObj, 'txtZip');
    let nature_of_lien1 = getValue(thisObj, 'txtNtrOfLiens');

    let lienholder_name2 = getValue(thisObj, 'lienHldr2');
    let lienholder_address2 = getValue(thisObj, 'txtAdd2');
    let lienholder_city2 = getValue(thisObj, 'txtCity2');
    let lienholder_state2 = getValue(thisObj, 'ddStat2');
    let lienholder_zip2 = getValue(thisObj, 'txtZip2');
    let nature_of_lien2 = getValue(thisObj, 'txtNature2');

    let split_vendor0 = getValue(thisObj, 'txtVndr');
    let split_remit0 = getValue(thisObj, 'ddRemit');
    let split_share_pct0 = Number(getValue(thisObj, 'txtShare'));
    let advance_amount0 = Number(getValue(thisObj, 'txtSignAdv'));

    let remarks = getValue(thisObj, 'txtarCmnnts');
    let seedtbd = msSeedTBD;
    let emailInd = (sEmailInd !== undefined && sEmailInd !== null) ? sEmailInd : '';
    let uploadstatus = (sUploadStatus !== undefined && sUploadStatus !== null) ? sUploadStatus : '';

    let allow_rescan = '';
    if (document.getElementById('chckbxAlwRescn').checked == false) {
      allow_rescan = 'Y';
    } else {
      allow_rescan = 'N';
    }

    let min_frez_dam_pct = '';
    let max_frez_dam_pct = '';
    if (document.getElementById('chkbxFreezeDmgRng').checked == true) {
      min_frez_dam_pct = Number(getValue(thisObj, 'txtbxMin'));
      max_frez_dam_pct = Number(getValue(thisObj, 'txtbxMax'));
    }
    let userId = sessionStorage.getItem('userid');

    let objSave = {
      "state_abbr": "",
      "county_id": "",
      "farm_id": "",
      "pnut_type_id": peanutTypeId,
      "pnut_variety_id": pnut_variety_id,
      "buy_pt_id": buy_pt_id,
      "delv_loc": "",
      "inspect_loc": "",
      "store_loc": "",
      "shell_loc_id": "",
      "cont_type": cont_type,
      "delivery_period": (ddDlvryPrdDesc == undefined || ddDlvryPrdDesc == null || ddDlvryPrdDesc == '') ? '' : ddDlvryPrdDesc,
      "organic": organic,
      "man_adv_ind": "N",
      "oleic_ind": oleic_ind,
      "harvest_complete_date": dtHrvstCmplt,
      "seg_type": seg_type,
      "seed_grower": seedGrowerValue,
      "center_pivot_yield": center_pivot_yield,
      "center_pivot_acres": center_pivot_acres > 0 ? center_pivot_acres : 0,
      "other_yield": other_yield,
      "other_acres": other_acres > 0 ? other_acres : 0,
      "dry_land_yield": dry_land_yield,
      "dry_land_acres": dry_land_acres > 0 ? dry_land_acres : 0,
      "yield_per_acre": yield_per_acre > 0 ? yield_per_acre : 0,
      "cont_date": cont_date,
      "change_status": sChangeStatus,

      "pricing_line1": PRICING_LINE1,  // 0
      "firm_basis1": FIRM_BASIS1, // ''
      "at_harvest1": AT_HARVEST1, // ''
      "cont_lbs1": CONT_LBS1, // 0
      "price_per_ton1": PRICE_PER_TON1, // 0
      "price_limit_rec_key1": PRICE_LIMIT_REC_KEY1, // 12305
      "delv_beg_date1": DELV_BEG_DATE1,
      "delv_end_date1": DELV_END_DATE1,

      "pricing_line2": PRICING_LINE2, // 0
      "firm_basis2": FIRM_BASIS2, // ''
      "at_harvest2": AT_HARVEST2, // ''
      "cont_lbs2": CONT_LBS2, // 0
      "price_per_ton2": 0, // 0
      "price_limit_rec_key2": PRICE_LIMIT_REC_KEY2, // 0
      "delv_beg_date2": DELV_BEG_DATE2,
      "delv_end_date2": DELV_END_DATE2,

      "lienholder_name1": lienholder_name1 == undefined ? '' : lienholder_name1,
      "lienholder_address1": lienholder_address1 == undefined ? '' : lienholder_address1,
      "lienholder_city1": lienholder_city1 == undefined ? '' : lienholder_city1,
      "lienholder_state1": lienholder_state1 == undefined ? '' : lienholder_state1,
      "lienholder_zip1": lienholder_zip1 == undefined ? '' : lienholder_zip1,
      "nature_of_lien1": nature_of_lien1 == undefined ? '' : nature_of_lien1,

      "lienholder_name2": lienholder_name2 == undefined ? '' : lienholder_name2,
      "lienholder_address2": lienholder_address2 == undefined ? '' : lienholder_address2,
      "lienholder_city2": lienholder_city2 == undefined ? '' : lienholder_city2,
      "lienholder_state2": lienholder_state2 == undefined ? '' : lienholder_state2,
      "lienholder_zip2": lienholder_zip2 == undefined ? '' : lienholder_zip2,
      "nature_of_lien2": nature_of_lien2 == undefined ? '' : nature_of_lien2,

      "split_vendor0": split_vendor0 == undefined ? '' : split_vendor0,
      "split_remit0": split_remit0 == undefined ? '' : split_remit0,
      "split_share_pct0": split_share_pct0 == undefined ? 0 : split_share_pct0,
      "advance_amount0": (advance_amount0 == undefined || advance_amount0 == '') ? 0 : advance_amount0,

      "fm_addendum_ind0": cmdForeignMatarial == '' ? 'N' : cmdForeignMatarial,
      "chemical_addendum_ind0": cmdChemicalAddendum == '' ? 'N' : cmdChemicalAddendum,

      "seed_adden_cont_num": 0,
      "remarks": (remarks == undefined || remarks == null) ? '' : Buffer.from(remarks).toString('base64'),
      "seedvarietylist": (cmdSeedGrower == undefined || cmdSeedGrower == null) ? '' : cmdSeedGrower,
      "seedacres": (useSeedAcres == undefined || useSeedAcres == null || useSeedAcres == '') ? '0' : Number(useSeedAcres),
      "seedprem": (useSeedPrem == undefined || useSeedPrem == null || useSeedPrem == '') ? '0' : useSeedPrem,
      "seedprem_limit_rec_key": (useSeedPremLimit == undefined || useSeedPremLimit == null || useSeedPremLimit == '') ? '0' : useSeedPremLimit,
      "seedlbs": (useSeedLbs == undefined || useSeedLbs == null || useSeedLbs == '') ? '0' : useSeedLbs,
      "seedtbd": (seedtbd == undefined || seedtbd == null) ? '' : seedtbd,
      "farmspecificind": "N",
      "farmspecificlist": "",
      "upload_status": uploadstatus,
      "emailInd": emailInd,
      "allow_rescan": allow_rescan,
      "ftp_filename": (sFTPFilename == undefined || sFTPFilename == null) ? '' : sFTPFilename,
      "min_frez_dam_pct": min_frez_dam_pct.toString(),
      "max_frez_dam_pct": max_frez_dam_pct.toString(),
      "uid": userId,

      "cont_adj_lbs1": 0,
      "cont_adj_lbs2": 0,
      "advance_check0": "",
      "split_vendor1": "",
      "split_remit1": "",
      "split_share_pct1": 0,
      "advance_amount1": 0,
      "advance_check1": "",
      "fm_addendum_ind1": "",
      "chemical_addendum_ind1": "",
      "split_vendor2": "",
      "split_remit2": "",
      "split_share_pct2": 0,
      "advance_amount2": 0,
      "advance_check2": "",
      "fm_addendum_ind2": "",
      "chemical_addendum_ind2": "",
      "split_vendor3": "",
      "split_remit3": "",
      "split_share_pct3": 0,
      "advance_amount3": 0,
      "advance_check3": "",
      "fm_addendum_ind3": "",
      "chemical_addendum_ind3": "",
      "split_vendor4": "",
      "split_remit4": "",
      "split_share_pct4": 0,
      "advance_amount4": 0,
      "advance_check4": "",
      "fm_addendum_ind4": "",
      "chemical_addendum_ind4": "",
      "split_vendor5": "",
      "split_remit5": "",
      "split_share_pct5": 0,
      "advance_amount5": 0,
      "advance_check5": "",
      "fm_addendum_ind5": "",
      "chemical_addendum_ind5": "",
      "split_vendor6": "",
      "split_remit6": "",
      "split_share_pct6": 0,
      "advance_amount6": 0,
      "advance_check6": "",
      "fm_addendum_ind6": "",
      "chemical_addendum_ind6": "",
      "split_vendor7": "",
      "split_remit7": "",
      "split_share_pct7": 0,
      "advance_amount7": 0,
      "advance_check7": "",
      "fm_addendum_ind7": "",
      "chemical_addendum_ind7": "",
      "split_vendor8": "",
      "split_remit8": "",
      "split_share_pct8": 0,
      "advance_amount8": 0,
      "advance_check8": "",
      "fm_addendum_ind8": "",
      "chemical_addendum_ind8": "",
      "split_vendor9": "",
      "split_remit9": "",
      "split_share_pct9": 0,
      "advance_amount9": 0,
      "advance_check9": "",
      "fm_addendum_ind9": "",
      "chemical_addendum_ind9": "",
      "split_vendor10": "",
      "split_remit10": "",
      "split_share_pct10": 0,
      "advance_amount10": 0,
      "advance_check10": "",
      "fm_addendum_ind10": "",
      "chemical_addendum_ind10": "",
      "split_vendor11": "",
      "split_remit11": "",
      "split_share_pct11": 0,
      "advance_amount11": 0,
      "advance_check11": "",
      "fm_addendum_ind11": "",
      "chemical_addendum_ind11": "",
      "split_vendor12": "",
      "split_remit12": "",
      "split_share_pct12": 0,
      "advance_amount12": 0,
      "advance_check12": "",
      "fm_addendum_ind12": "",
      "chemical_addendum_ind12": "",
      "split_vendor13": "",
      "split_remit13": "",
      "split_share_pct13": 0,
      "advance_amount13": 0,
      "advance_check13": "",
      "fm_addendum_ind13": "",
      "chemical_addendum_ind13": "",
      "split_vendor14": "",
      "split_remit14": "",
      "split_share_pct14": 0,
      "advance_amount14": 0,
      "advance_check14": "",
      "fm_addendum_ind14": "",
      "chemical_addendum_ind14": "",
      "split_vendor15": "",
      "split_remit15": "",
      "split_share_pct15": 0,
      "advance_amount15": 0,
      "advance_check15": "",
      "fm_addendum_ind15": "",
      "chemical_addendum_ind15": "",
      "split_vendor16": "",
      "split_remit16": "",
      "split_share_pct16": 0,
      "advance_amount16": 0,
      "advance_check16": "",
      "fm_addendum_ind16": "",
      "chemical_addendum_ind16": "",
      "split_vendor17": "",
      "split_remit17": "",
      "split_share_pct17": 0,
      "advance_amount17": 0,
      "advance_check17": "",
      "fm_addendum_ind17": "",
      "chemical_addendum_ind17": "",
      "split_vendor18": "",
      "split_remit18": "",
      "split_share_pct18": 0,
      "advance_amount18": 0,
      "advance_check18": "",
      "fm_addendum_ind18": "",
      "chemical_addendum_ind18": "",
      "split_vendor19": "",
      "split_remit19": "",
      "split_share_pct19": 0,
      "advance_amount19": 0,
      "advance_check19": "",
      "fm_addendum_ind19": "",
      "chemical_addendum_ind19": "",
      "sc_est_purch_lbs0": 0,
      "sc_act_purch_lbs0": 0,
      "sc_pnut_type_id0": "",
      "sc_pnut_variety_id_10": "",
      "sc_pnut_gen_id_10": "",
      "sc_price_per_lbs_10": 0,
      "sc_pnut_variety_id_20": "",
      "sc_pnut_gen_id_20": "",
      "sc_price_per_lbs_20": 0,
      "sc_pnut_variety_id_30": "",
      "sc_pnut_gen_id_30": "",
      "sc_price_per_lbs_30": 0,
      "sc_est_purch_lbs1": 0,
      "sc_act_purch_lbs1": 0,
      "sc_pnut_type_id1": "",
      "sc_pnut_variety_id_11": "",
      "sc_pnut_gen_id_11": "",
      "sc_price_per_lbs_11": 0,
      "sc_pnut_variety_id_21": "",
      "sc_pnut_gen_id_21": "",
      "sc_price_per_lbs_21": 0,
      "sc_pnut_variety_id_31": "",
      "sc_pnut_gen_id_31": "",
      "sc_price_per_lbs_31": 0,
      "sc_est_purch_lbs2": 0,
      "sc_act_purch_lbs2": 0,
      "sc_pnut_type_id2": "",
      "sc_pnut_variety_id_12": "",
      "sc_pnut_gen_id_12": "",
      "sc_price_per_lbs_12": 0,
      "sc_pnut_variety_id_22": "",
      "sc_pnut_gen_id_22": "",
      "sc_price_per_lbs_22": 0,
      "sc_pnut_variety_id_32": "",
      "sc_pnut_gen_id_32": "",
      "sc_price_per_lbs_32": 0,
      "sc_est_purch_lbs3": 0,
      "sc_act_purch_lbs3": 0,
      "sc_pnut_type_id3": "",
      "sc_pnut_variety_id_13": "",
      "sc_pnut_gen_id_13": "",
      "sc_price_per_lbs_13": 0,
      "sc_pnut_variety_id_23": "",
      "sc_pnut_gen_id_23": "",
      "sc_price_per_lbs_23": 0,
      "sc_pnut_variety_id_33": "",
      "sc_pnut_gen_id_33": "",
      "sc_price_per_lbs_33": 0,
      "sc_est_purch_lbs4": 0,
      "sc_act_purch_lbs4": 0,
      "sc_pnut_type_id4": "",
      "sc_pnut_variety_id_14": "",
      "sc_pnut_gen_id_14": "",
      "sc_price_per_lbs_14": 0,
      "sc_pnut_variety_id_24": "",
      "sc_pnut_gen_id_24": "",
      "sc_price_per_lbs_24": 0,
      "sc_pnut_variety_id_34": "",
      "sc_pnut_gen_id_34": "",
      "sc_price_per_lbs_34": 0,
      "sc_est_purch_lbs5": 0,
      "sc_act_purch_lbs5": 0,
      "sc_pnut_type_id5": "",
      "sc_pnut_variety_id_15": "",
      "sc_pnut_gen_id_15": "",
      "sc_price_per_lbs_15": 0,
      "sc_pnut_variety_id_25": "",
      "sc_pnut_gen_id_25": "",
      "sc_price_per_lbs_25": 0,
      "sc_pnut_variety_id_35": "",
      "sc_pnut_gen_id_35": "",
      "sc_price_per_lbs_35": 0,
      "sc_est_purch_lbs6": 0,
      "sc_act_purch_lbs6": 0,
      "sc_pnut_type_id6": "",
      "sc_pnut_variety_id_16": "",
      "sc_pnut_gen_id_16": "",
      "sc_price_per_lbs_16": 0,
      "sc_pnut_variety_id_26": "",
      "sc_pnut_gen_id_26": "",
      "sc_price_per_lbs_26": 0,
      "sc_pnut_variety_id_36": "",
      "sc_pnut_gen_id_36": "",
      "sc_price_per_lbs_36": 0,
      "sc_est_purch_lbs7": 0,
      "sc_act_purch_lbs7": 0,
      "sc_pnut_type_id7": "",
      "sc_pnut_variety_id_17": "",
      "sc_pnut_gen_id_17": "",
      "sc_price_per_lbs_17": 0,
      "sc_pnut_variety_id_27": "",
      "sc_pnut_gen_id_27": "",
      "sc_price_per_lbs_27": 0,
      "sc_pnut_variety_id_37": "",
      "sc_pnut_gen_id_37": "",
      "sc_price_per_lbs_37": 0,
      "sc_est_purch_lbs8": 0,
      "sc_act_purch_lbs8": 0,
      "sc_pnut_type_id8": "",
      "sc_pnut_variety_id_18": "",
      "sc_pnut_gen_id_18": "",
      "sc_price_per_lbs_18": 0,
      "sc_pnut_variety_id_28": "",
      "sc_pnut_gen_id_28": "",
      "sc_price_per_lbs_28": 0,
      "sc_pnut_variety_id_38": "",
      "sc_pnut_gen_id_38": "",
      "sc_price_per_lbs_38": 0,
      "sc_est_purch_lbs9": 0,
      "sc_act_purch_lbs9": 0,
      "sc_pnut_type_id9": "",
      "sc_pnut_variety_id_19": "",
      "sc_pnut_gen_id_19": "",
      "sc_price_per_lbs_19": 0,
      "sc_pnut_variety_id_29": "",
      "sc_pnut_gen_id_29": "",
      "sc_price_per_lbs_29": 0,
      "sc_pnut_variety_id_39": "",
      "sc_pnut_gen_id_39": "",
      "sc_price_per_lbs_39": 0,
      "sc_est_purch_lbs10": 0,
      "sc_act_purch_lbs10": 0,
      "sc_pnut_type_id10": "",
      "sc_pnut_variety_id_110": "",
      "sc_pnut_gen_id_110": "",
      "sc_price_per_lbs_110": 0,
      "sc_pnut_variety_id_210": "",
      "sc_pnut_gen_id_210": "",
      "sc_price_per_lbs_210": 0,
      "sc_pnut_variety_id_310": "",
      "sc_pnut_gen_id_310": "",
      "sc_price_per_lbs_310": 0,
      "sc_est_purch_lbs11": 0,
      "sc_act_purch_lbs11": 0,
      "sc_pnut_type_id11": "",
      "sc_pnut_variety_id_111": "",
      "sc_pnut_gen_id_111": "",
      "sc_price_per_lbs_111": 0,
      "sc_pnut_variety_id_211": "",
      "sc_pnut_gen_id_211": "",
      "sc_price_per_lbs_211": 0,
      "sc_pnut_variety_id_311": "",
      "sc_pnut_gen_id_311": "",
      "sc_price_per_lbs_311": 0,
      "sc_est_purch_lbs12": 0,
      "sc_act_purch_lbs12": 0,
      "sc_pnut_type_id12": "",
      "sc_pnut_variety_id_112": "",
      "sc_pnut_gen_id_112": "",
      "sc_price_per_lbs_112": 0,
      "sc_pnut_variety_id_212": "",
      "sc_pnut_gen_id_212": "",
      "sc_price_per_lbs_212": 0,
      "sc_pnut_variety_id_312": "",
      "sc_pnut_gen_id_312": "",
      "sc_price_per_lbs_312": 0,
      "sc_est_purch_lbs13": 0,
      "sc_act_purch_lbs13": 0,
      "sc_pnut_type_id13": "",
      "sc_pnut_variety_id_113": "",
      "sc_pnut_gen_id_113": "",
      "sc_price_per_lbs_113": 0,
      "sc_pnut_variety_id_213": "",
      "sc_pnut_gen_id_213": "",
      "sc_price_per_lbs_213": 0,
      "sc_pnut_variety_id_313": "",
      "sc_pnut_gen_id_313": "",
      "sc_price_per_lbs_313": 0,
      "sc_est_purch_lbs14": 0,
      "sc_act_purch_lbs14": 0,
      "sc_pnut_type_id14": "",
      "sc_pnut_variety_id_114": "",
      "sc_pnut_gen_id_114": "",
      "sc_price_per_lbs_114": 0,
      "sc_pnut_variety_id_214": "",
      "sc_pnut_gen_id_214": "",
      "sc_price_per_lbs_214": 0,
      "sc_pnut_variety_id_314": "",
      "sc_pnut_gen_id_314": "",
      "sc_price_per_lbs_314": 0,
      "sc_est_purch_lbs15": 0,
      "sc_act_purch_lbs15": 0,
      "sc_pnut_type_id15": "",
      "sc_pnut_variety_id_115": "",
      "sc_pnut_gen_id_115": "",
      "sc_price_per_lbs_115": 0,
      "sc_pnut_variety_id_215": "",
      "sc_pnut_gen_id_215": "",
      "sc_price_per_lbs_215": 0,
      "sc_pnut_variety_id_315": "",
      "sc_pnut_gen_id_315": "",
      "sc_price_per_lbs_315": 0,
      "sc_est_purch_lbs16": 0,
      "sc_act_purch_lbs16": 0,
      "sc_pnut_type_id16": "",
      "sc_pnut_variety_id_116": "",
      "sc_pnut_gen_id_116": "",
      "sc_price_per_lbs_116": 0,
      "sc_pnut_variety_id_216": "",
      "sc_pnut_gen_id_216": "",
      "sc_price_per_lbs_216": 0,
      "sc_pnut_variety_id_316": "",
      "sc_pnut_gen_id_316": "",
      "sc_price_per_lbs_316": 0,
      "sc_est_purch_lbs17": 0,
      "sc_act_purch_lbs17": 0,
      "sc_pnut_type_id17": "",
      "sc_pnut_variety_id_117": "",
      "sc_pnut_gen_id_117": "",
      "sc_price_per_lbs_117": 0,
      "sc_pnut_variety_id_217": "",
      "sc_pnut_gen_id_217": "",
      "sc_price_per_lbs_217": 0,
      "sc_pnut_variety_id_317": "",
      "sc_pnut_gen_id_317": "",
      "sc_price_per_lbs_317": 0,
      "sc_est_purch_lbs18": 0,
      "sc_act_purch_lbs18": 0,
      "sc_pnut_type_id18": "",
      "sc_pnut_variety_id_118": "",
      "sc_pnut_gen_id_118": "",
      "sc_price_per_lbs_118": 0,
      "sc_pnut_variety_id_218": "",
      "sc_pnut_gen_id_218": "",
      "sc_price_per_lbs_218": 0,
      "sc_pnut_variety_id_318": "",
      "sc_pnut_gen_id_318": "",
      "sc_price_per_lbs_318": 0,
      "sc_est_purch_lbs19": 0,
      "sc_act_purch_lbs19": 0,
      "sc_pnut_type_id19": "",
      "sc_pnut_variety_id_119": "",
      "sc_pnut_gen_id_119": "",
      "sc_price_per_lbs_119": 0,
      "sc_pnut_variety_id_219": "",
      "sc_pnut_gen_id_219": "",
      "sc_price_per_lbs_219": 0,
      "sc_pnut_variety_id_319": "",
      "sc_pnut_gen_id_319": "",
      "sc_price_per_lbs_319": 0,
      "o_prem_per_ton0": 0,
      "o_prem_per_ton1": 0,
      "o_prem_per_ton2": 0,
      "o_prem_per_ton3": 0,
      "o_prem_per_ton4": 0,
      "o_prem_per_ton5": 0,
      "o_prem_per_ton6": 0,
      "o_prem_per_ton7": 0,
      "o_prem_per_ton8": 0,
      "o_prem_per_ton9": 0,
      "o_prem_per_ton10": 0,
      "o_prem_per_ton11": 0,
      "o_prem_per_ton12": 0,
      "o_prem_per_ton13": 0,
      "o_prem_per_ton14": 0,
      "o_prem_per_ton15": 0,
      "o_prem_per_ton16": 0,
      "o_prem_per_ton17": 0,
      "o_prem_per_ton18": 0,
      "o_prem_per_ton19": 0,
      "lp_prem_per_ton0": 0,
      "lp_proof_verified0": "",
      "lp_prem_per_ton1": 0,
      "lp_proof_verified1": "",
      "lp_prem_per_ton2": 0,
      "lp_proof_verified2": "",
      "lp_prem_per_ton3": 0,
      "lp_proof_verified3": "",
      "lp_prem_per_ton4": 0,
      "lp_proof_verified4": "",
      "lp_prem_per_ton5": 0,
      "lp_proof_verified5": "",
      "lp_prem_per_ton6": 0,
      "lp_proof_verified6": "",
      "lp_prem_per_ton7": 0,
      "lp_proof_verified7": "",
      "lp_prem_per_ton8": 0,
      "lp_proof_verified8": "",
      "lp_prem_per_ton9": 0,
      "lp_proof_verified9": "",
      "lp_prem_per_ton10": 0,
      "lp_proof_verified10": "",
      "lp_prem_per_ton11": 0,
      "lp_proof_verified11": "",
      "lp_prem_per_ton12": 0,
      "lp_proof_verified12": "",
      "lp_prem_per_ton13": 0,
      "lp_proof_verified13": "",
      "lp_prem_per_ton14": 0,
      "lp_proof_verified14": "",
      "lp_prem_per_ton15": 0,
      "lp_proof_verified15": "",
      "lp_prem_per_ton16": 0,
      "lp_proof_verified16": "",
      "lp_prem_per_ton17": 0,
      "lp_proof_verified17": "",
      "lp_prem_per_ton18": 0,
      "lp_proof_verified18": "",
      "lp_prem_per_ton19": 0,
      "lp_proof_verified19": "",
      "o_oleic_acres0": 0,
      "o_oleic_acres1": 0,
      "o_oleic_acres2": 0,
      "o_oleic_acres3": 0,
      "o_oleic_acres4": 0,
      "o_oleic_acres5": 0,
      "o_oleic_acres6": 0,
      "o_oleic_acres7": 0,
      "o_oleic_acres8": 0,
      "o_oleic_acres9": 0,
      "o_oleic_acres10": 0,
      "o_oleic_acres11": 0,
      "o_oleic_acres12": 0,
      "o_oleic_acres13": 0,
      "o_oleic_acres14": 0,
      "o_oleic_acres15": 0,
      "o_oleic_acres16": 0,
      "o_oleic_acres17": 0,
      "o_oleic_acres18": 0,
      "o_oleic_acres19": 0,
      "o_cont_lbs0": 0,
      "o_cont_lbs1": 0,
      "o_cont_lbs2": 0,
      "o_cont_lbs3": 0,
      "o_cont_lbs4": 0,
      "o_cont_lbs5": 0,
      "o_cont_lbs6": 0,
      "o_cont_lbs7": 0,
      "o_cont_lbs8": 0,
      "o_cont_lbs9": 0,
      "o_cont_lbs10": 0,
      "o_cont_lbs11": 0,
      "o_cont_lbs12": 0,
      "o_cont_lbs13": 0,
      "o_cont_lbs14": 0,
      "o_cont_lbs15": 0,
      "o_cont_lbs16": 0,
      "o_cont_lbs17": 0,
      "o_cont_lbs18": 0,
      "o_cont_lbs19": 0,
      "seedgen_plant": "",
      "seedgen_produced": "",
      "appsync": GstrVersion + '.' + Application.Major + '.' + Application.Minor + '.' + Application.Revision
    };
    if (contractNumber !== '' && contractNumber !== undefined) {
      let response = await ContractManagementService.UpdateContract(contractNumber, objSave);
      if (response.status == 200) {
        setFormDirty(false)
        return true;
      } else {
        alert(response.message);
        return false;
      }
    } else {
      let response = await ContractManagementService.CreateContract(peanutTypeId, buy_pt_id, objSave);
      if (response.status == 200) {
        alert(response.message);
        setFormDirty(false)
        return response.result;
      }
      else {
        alert(response.message);
        return false;
      }
    }
  }

  const bFormValid = (peanutType) => {
    let txtFirmStartDate = getValue(thisObj, 'dtStart');
    if (txtFirmStartDate == undefined || txtFirmStartDate == '' || txtFirmStartDate == null) {
      txtFirmStartDate = '';
    }
    let txtFirmEndDate = getValue(thisObj, 'dtEnd');
    if (txtFirmEndDate == undefined || txtFirmEndDate == '' || txtFirmEndDate == null) {
      txtFirmEndDate = '';
    }
    let txtBasisStartDate = getValue(thisObj, 'dtStrt2');
    if (txtBasisStartDate == undefined || txtBasisStartDate == '' || txtBasisStartDate == null) {
      txtBasisStartDate = '';
    }
    let txtBasisEndDate = getValue(thisObj, 'dtEnd2');
    if (txtBasisEndDate == undefined || txtBasisEndDate == '' || txtBasisEndDate == null) {
      txtBasisEndDate = '';
    }
    let lblTotalAcres = getValue(thisObj, 'txtTotlAcrs');

    let type = getValue(thisObj, 'ddType');
    if (type == undefined || type == null) {
      alert('Please select Contract Type.');
      isFormValid = false;
      return;
    }

    let ddLocation = getValue(thisObj, 'ddLocation');
    if (ddLocation == undefined || ddLocation == null) {
      alert('Please select Location.');
      isFormValid = false;
      return;
    }

    let ddSegType = getValue(thisObj, 'ddSegType');
    if (ddSegType == undefined || ddSegType == null) {
      alert('Please select Segment Type.');
      isFormValid = false;
      return;
    }

    let ddPeanutType = getValue(thisObj, 'ddPeanutType');
    if (ddPeanutType == undefined || ddPeanutType == null) {
      alert('Please select Peanut Type.');
      isFormValid = false;
      return;
    }

    let ddOleic = getValue(thisObj, 'ddOleic');
    if (ddOleic == undefined || ddOleic == null) {
      alert('Please select Oleic.');
      isFormValid = false;
      return;
    }

    let lblAvgYieldPerAcre = getValue(thisObj, 'txtAvgYld');
    if (lblAvgYieldPerAcre == undefined || lblAvgYieldPerAcre == "") {
      alert("Must enter in at least one Average Yield/Acre amount!");
      isFormValid = false;
      return;
    }

    if (lblTotalAcres == undefined || lblTotalAcres == "") {
      alert("Must enter in at least one Acre amount!");
      isFormValid = false;
      return;
    }

    let txtAvgYieldPerAcreCenterPivot = getValue(thisObj, 'txtCntrPivtAvgYld');
    let txtCenterPivotAcres = getValue(thisObj, 'txtCntrPivtAcrs');

    if ((txtAvgYieldPerAcreCenterPivot !== undefined && txtAvgYieldPerAcreCenterPivot !== "") ||
      (txtCenterPivotAcres !== undefined && txtCenterPivotAcres !== "")) {
      if (txtAvgYieldPerAcreCenterPivot == undefined || txtAvgYieldPerAcreCenterPivot == "") {
        alert("Must enter the Center Pivot Average Yield/Acre if the Center Pivot Acres have been entered!");
        isFormValid = false;
        return;
      }
      if (txtCenterPivotAcres == undefined || txtCenterPivotAcres == "") {
        alert("Must enter the Center Pivot Acres if the Center Pivot Average Yield/Acre has  been entered!");
        isFormValid = false;
        return;
      }
    }

    let txtAvgYieldPerAcreOther = getValue(thisObj, 'txtOthrAvgYld');
    let txtOtherAcres = getValue(thisObj, 'txtOthrAcrs');

    if ((txtAvgYieldPerAcreOther !== undefined && txtAvgYieldPerAcreOther !== "") ||
      (txtOtherAcres !== undefined && txtOtherAcres !== "")) {
      if (txtAvgYieldPerAcreOther == undefined || txtAvgYieldPerAcreOther == "") {
        alert("Must enter Other Average Yield/Acre if Other Acres have been entered!");
        isFormValid = false;
        return;
      }
      if (txtOtherAcres == undefined || txtOtherAcres == "") {
        alert("Must enter Other Acres if Other Average Yield/Acre has  been entered!");
        isFormValid = false;
        return;
      }
    }


    let txtAvgYieldPerAcreDryLand = getValue(thisObj, "txtDryLndAvgYld");
    let txtDryLandAcres = getValue(thisObj, "txtDryLndAcres");

    if ((txtAvgYieldPerAcreDryLand !== undefined && txtAvgYieldPerAcreDryLand !== "") ||
      (txtDryLandAcres !== undefined && txtDryLandAcres !== "")) {
      if (txtAvgYieldPerAcreDryLand == undefined || txtAvgYieldPerAcreDryLand == "") {
        alert("Must enter the Dry Land Average Yield/Acre if the Dry Land Acres have been entered!");
        isFormValid = false;
        return;
      }
      if (txtDryLandAcres == undefined || txtDryLandAcres == "") {
        alert("Must enter the Dry Land Acres if the Dry Land Average Yield/Acre has  been entered!");
        isFormValid = false;
        return;
      }
    }


    if (txtFirmStartDate !== '' && txtFirmEndDate !== '') {
      if (new Date(ConvertToDate(txtFirmEndDate)) < new Date(ConvertToDate(txtFirmStartDate))) {
        alert("Firm end date must be greater than or equal to Firm start date.");
        isFormValid = false;
        return;
      }
    }

    if (txtBasisStartDate !== '' && txtBasisEndDate !== '') {
      if (new Date(ConvertToDate(txtBasisEndDate)) < new Date(ConvertToDate(txtBasisStartDate))) {
        alert("Basis end date must be greater than or equal to basis start date.");
        isFormValid = false;
        return;
      }
    }

    if (txtFirmEndDate !== "") {
      let date = "12/31/" + (cropYear + 1);
      let firmtDate = ConvertToDate(txtFirmEndDate);
      if (new Date(firmtDate) > new Date(date)) {
        alert("Firm end date must be less than " + date);
        isFormValid = false;
        return;
      }
    }

    if (txtBasisEndDate !== "") {
      let date = "12/31/" + (cropYear + 1);
      let basisDate = ConvertToDate(txtBasisEndDate);
      if (new Date(basisDate) > new Date(date)) {
        alert("Basis end date must be less than " + "12/31/" + (cropYear + 1));
        isFormValid = false;
        return;
      }
    }

    let lblStatus = getValue(thisObj, 'txtStatus');
    switch (lblStatus) {
      case 'Pending':
        lblStatus = 'P';
        break;
      case 'Approved':
        lblStatus = 'A';
        break;
      case 'Signed':
        lblStatus = 'S';
        break;
      case 'Cancelled':
        lblStatus = 'C';
        break;
      case 'Voided':
        lblStatus = 'V';
        break;
    }
    if (lblStatus == "A" || lblStatus == "S" || lblStatus == "C" || lblStatus == "V" || (lblStatus == undefined || lblStatus == "")) {
      let vendor = getValue(thisObj, "txtVndr");
      if (vendor == undefined || vendor == '' || vendor == null) {
        alert("At least one vendor is required.");
        isFormValid = false;
        return;
      }
      let dblSplitPercent = 0;
      let txtShare = getValue(thisObj, "txtShare");
      if (txtShare !== undefined) {
        dblSplitPercent = dblSplitPercent + Number(txtShare);
        if (vendor !== '' && Number(txtShare) == 0) {
          alert("Vendor Split Percentages cannot be 0%!!!");
          isFormValid = false;
          return;
        }
      }

      if (Number(dblSplitPercent) !== 100) {
        alert("Vendor Split Percentages must add up to 100%");
        isFormValid = false;
        return;
      }
    }

    if (type == 'M') {
      if (peanutType.length > 0) {
        if (peanutType[0].flexMktAllow !== 'Y') {
          alert("Flex Market not allowed for this Peanut Type.");
          isFormValid = false;
          return;
        }
      } else {
        alert("Flex Market Peanut Type validation could not be retrieved from the database.");
        isFormValid = false;
        return;
      }
    }

    if (type == 'S') {
      if (document.getElementById('chkBxSeedGrowr').checked == false) {
        alert("Seed Grower must be checked on Seed Contracts.");
        isFormValid = false;
        return;
      }
    }

    if (document.getElementById('chkBxSeedGrowr').checked == true) {
      if ((cmdSeedGrower == '' || cmdSeedGrower == null) && msSeedTBD !== 'Y') {
        alert("Seed Agreement information is required.");
        isFormValid = false;
        return;
      }
    }

    if (lblTotalAcres <= 0 && type !== 'O' && type !== 'M' && type !== 'L') {
      alert("The Farm Planting Intentions Total must be greater than 0!!!");
      isFormValid = false;
      return;
    }

    if (type !== "S") {
      let txtFirmPounds = getValue(thisObj, 'txtPounds');
      if (txtFirmPounds == undefined || txtFirmPounds == '' || txtFirmPounds == null) {
        txtFirmPounds = '';
      }
      let txtBasisPounds = getValue(thisObj, 'txtPounds2');
      if (txtBasisPounds == undefined || txtBasisPounds == '' || txtBasisPounds == null) {
        txtBasisPounds = '';
      }
      let ddFirmPrice = getValue(thisObj, 'ddPrc');
      if (ddFirmPrice == undefined || ddFirmPrice == '' || ddFirmPrice == null) {
        ddFirmPrice = '';
      }
      let ddBasisPrice = getValue(thisObj, 'ddPrice');
      if (ddBasisPrice == undefined || ddBasisPrice == '' || ddBasisPrice == null) {
        ddBasisPrice = '';
      }
      if (type == "O" || type == "M" || type == "L") {
        if ((ddFirmPrice !== '' && txtFirmPounds !== "" && ((txtFirmEndDate == "" && txtFirmStartDate == "") || (txtFirmEndDate !== "" && txtFirmStartDate == "") || (txtFirmStartDate !== "" && txtFirmEndDate == ""))) ||
          (ddPrice !== "" && txtBasisPounds !== "" && ((txtBasisStartDate == "" && txtBasisEndDate === "") || (txtBasisEndDate !== "" && txtBasisStartDate == "") || (txtBasisStartDate !== "" && txtBasisEndDate == "")))) {
          alert("Invalid Contract Pricing!!!  Pricing must have a start and end date");
          isFormValid = false;
          return;
        }
      }
//Commented and added - PRB0054120/INC5884952 -19thAug'2024
      // if (ddFirmPrice == '' && txtFirmPounds == "" && ddBasisPrice && txtBasisPounds == "") {
      //   alert("Invalid Contract Pricing!!!  A contract must have at least one pricing.");
      //   isFormValid = false;
      //   return;
      // }

      // if ((ddFirmPrice == '' && txtFirmPounds !== '') ||
      //   (ddBasisPrice == "" && txtBasisPounds !== "") ||
      //   (txtFirmPounds == "" && ddFirmPrice !== "") ||
      //   (txtBasisPounds == "" && ddBasisPrice !== "")) {
      //   alert("Invalid Contract Pricing!!!  A contract must have at least one pricing.");
      //   isFormValid = false;
      //   return;
      // }

      if ((txtFirmPounds!= '' && (ddFirmPrice == '' || ddFirmPrice == "Select"))
      || (txtBasisPounds != '' && (ddBasisPrice == '' || ddBasisPrice == "Select"))){
      alert("Invalid Contract Pricing!!!  A contract must have at least one pricing.");
      isFormValid = false;
      return;
      }

      if ((txtFirmPounds == '' && (ddFirmPrice != '' &&  ddFirmPrice != "Select"))
      || (txtBasisPounds == '' && (ddBasisPrice != '' &&  ddBasisPrice != "Select"))){
      alert("Invalid Contract Pricing!!!  A contract must have at least one pricing.");
      isFormValid = false;
      return;
      }

      if ((txtFirmPounds == "" && (ddFirmPrice == '' || ddFirmPrice == "Select"))
          && (txtBasisPounds == "" && (ddBasisPrice == '' || ddBasisPrice == "Select"))
      ){
      alert("Invalid Contract Pricing!!!  A contract must have at least one pricing.");
      isFormValid = false;
      return;
      }
// end of changes
      if (txtFirmPounds !== "" && ddFirmPrice !== "") {
        if (txtFirmStartDate == "" || txtFirmEndDate == "") {
          alert("Invalid Contract Pricing!!!  A contract pricing that is not at harvest requires the Delivery Start Date and Delivery End Date.");
          isFormValid = false;
          return;
        }
      }

      if (txtBasisPounds !== "" && ddBasisPrice !== "") {
        if (txtBasisStartDate == "" || txtBasisEndDate == "") {
          alert("Invalid Contract Pricing!!!  A contract pricing that is not at harvest requires the Delivery Start Date and Delivery End Date.");
          isFormValid = false;
          return;
        }
      }
    }

// let txtMinFDR = Number(getValue(thisObj, 'txtbxMin'));
    // let txtMaxFDR = Number(getValue(thisObj, 'txtbxMax'));
    // if (document.getElementById('chkbxFreezeDmgRng').checked == true) {
    //   if (txtMinFDR == "" || txtMinFDR == undefined || txtMinFDR == 0) {
    //     alert("Minimun % must be between 0 and 100");
    //     isFormValid = false;
    //     return;
    //   } else if (txtMinFDR < 0 || txtMinFDR > 100) {
    //     alert("Minimun % must be between 0 and 100");
    //     isFormValid = false;
    //     return;
    //   }
    let txtMinFDR = Number(getValue(thisObj, 'txtbxMin'));
    let txtMaxFDR = Number(getValue(thisObj, 'txtbxMax'));
    if (document.getElementById('chkbxFreezeDmgRng').checked == true) {
      if (txtMinFDR === "" || txtMinFDR === undefined || txtMinFDR < 0 || txtMinFDR > 100) {
        alert("Minimum % must be between 0 and 100");
        isFormValid = false;
        return;
      // } else if (txtMinFDR < 0 || txtMinFDR > 100) {
        //   alert("Minimum % must be between 0 and 100");
        //   isFormValid = false;
        //   return;
      }

      if (txtMaxFDR == "" || txtMaxFDR == undefined || txtMaxFDR == 0) {
        alert("Maximum % of Freeze Damage Range must be between 0 and 100");
        isFormValid = false;
        return;
      } else if (txtMaxFDR < 0 || txtMaxFDR > 100) {
        alert("Maximum % of Freeze Damage Range must be between 0 and 100");
        isFormValid = false;
        return;
      }

      if (txtMinFDR > txtMaxFDR) {
        alert("Minimum % cannot be greater than Maximum % for Freeze Damage Range");
        isFormValid = false;
        return;
      }
    }

    let cboPeanutVariety = getValue(thisObj, 'ddPeaNtVrty');
    let cboOleic = getValue(thisObj, 'ddOleic');
    let cboVendor = getValue(thisObj, 'txtVndr');
    if (cboPeanutVariety !== undefined && cboOleic !== undefined) {
      if (cboPeanutVariety == MC_VIRUGUARD && cboOleic == MC_OLEIC_MID) {
        if (cboVendor !== "") {
          alert("Variety is ViruGard, but ViruGard agreement does not exist for every vendor!!!");
          isFormValid = false;
          return;
        }
      }
    }

    let txtSigningAdvance = getValue(thisObj, 'txtSignAdv');
    if (txtSigningAdvance !== "") {
      if (Number.isInteger(Number(txtSigningAdvance))) {
        if (Number(txtSigningAdvance) < 0 || Number(txtSigningAdvance) > 999999999.99) {
          alert("Invalid Signing Advance!!!");
          isFormValid = false;
          return;
        }
      } else {
        alert("Invalid Signing Advance!!!");
        isFormValid = false;
        return;
      }
    }

    dblSigningAdvance = 0;
    if (cboVendor !== "") {
      dblSigningAdvance = dblSigningAdvance + Number(txtSigningAdvance);
    }


    if (lblAddDate > "03/01/2005") {
      if (Number(dblSigningAdvance) !== Number(mdblSignAdv) && Number(dblSigningAdvance) !== 0 && type == 'O') {
        alert("Vendor Signing Advance must add up to 0 or 10");
        isFormValid = false;
        return;
      }
    } else {
      if (Number(dblSigningAdvance) !== Number(mdblSignAdv) && Number(dblSigningAdvance) !== 0 && type == 'O') {
        alert("Vendor Signing Advance must add up to 0 or 100");
        isFormValid = false;
        return;
      }
    }

    let txtFirmPounds = getValue(thisObj, 'txtPounds');
    let lblFirmTentativeOpen = getValue(thisObj, 'txtTntOpen');
    if (txtFirmPounds !== "" && txtFirmPounds !== undefined) {
      if (Number.isInteger(Number(txtFirmPounds))) {
        if (Number(txtFirmPounds) < 0 || Number(txtFirmPounds.Text) > 999999999) {
          alert("Invalid Firm Pounds!!!");
          isFormValid = false;
          return;
        }
        if (Number(lblFirmTentativeOpen) < 0) {
          alert("Open Pounds must be greater than zero!!!");
          isFormValid = false;
          return;
        }
      } else {
        alert("Invalid Firm Pounds!!!");
        isFormValid = false;
        return;
      }
    }

    let txtBasisPounds = getValue(thisObj, 'txtPounds2');
    let lblBasisTentativeOpen = getValue(thisObj, 'txtTntOpen2');
    if (txtBasisPounds !== "" && txtBasisPounds !== undefined) {
      if (Number.isInteger(Number(txtBasisPounds))) {
        if (Number(txtBasisPounds) < 0 || Number(txtBasisPounds) > 999999999) {
          alert("Invalid Basis Pounds!!!");
          isFormValid = false;
          return;
        }
        if (Number(lblBasisTentativeOpen) < 0) {
          alert("Open Pounds must be greater than zero!!!");
          isFormValid = false;
          return;
        }
      } else {
        alert("Invalid Basis Pounds!!!");
        isFormValid = false;
        return;
      }
    }



    let nEstimatedPounds = 0;
    let avgYield = 0;
    let totalAcres = 0;
    let nFirmPounds = 0;
    let nBasisPounds = 0;
    if (lblAvgYieldPerAcre !== '') {
      avgYield = lblAvgYieldPerAcre;
    }
    if (lblTotalAcres !== '') {
      totalAcres = lblTotalAcres;
    }
    nEstimatedPounds = avgYield * totalAcres;

    if (type !== "S") {
      if (txtFirmPounds !== '' && txtFirmPounds !== undefined) {
        if (Number.isInteger(Number(txtFirmPounds))) {
          nFirmPounds = Number(txtFirmPounds);
        } else {
          nFirmPounds = 0
        }
      }
      if (txtBasisPounds !== '' && txtBasisPounds !== undefined) {
        if (Number.isInteger(Number(txtBasisPounds))) {
          nBasisPounds = Number(txtBasisPounds);
        } else {
          nBasisPounds = 0
        }
      }
      let nVariance = (nFirmPounds + nBasisPounds) * 0.1
      let nTest = Math.abs(nEstimatedPounds - (nFirmPounds + nBasisPounds))

      if (nTest > nVariance) {
        alert("Avg. Yield * Acres does not equal the Pounds on the Contract, please check!");
        isFormValid = false;
        return;
      }
    } else {
      let cons_lbs = useSeedLbs > 0 ? useSeedLbs : 0;
      let nVariance = cons_lbs * 0.1
      let nTest = Math.abs(nEstimatedPounds - cons_lbs);
      if (nTest > nVariance) {
        alert("Avg. Yield * Acres does not equal Seed Pounds on the Agreement, please check!");
        isFormValid = false;
        return;
      }
    }

    let txtHarvest = getValue(thisObj, 'dtHrvstCmplt');
    let dtbegindate;
    if (txtHarvest !== undefined && txtHarvest !== "") {
      if (ConvertToDate(txtHarvest) == undefined || ConvertToDate(txtHarvest) == '') {
        alert("Invalid Harvest Complete Date!!!");
        isFormValid = false;
        return;
      }

      if (txtFirmStartDate !== "" && txtBasisStartDate !== "") {
        if (new Date(ConvertToDate(txtFirmStartDate)) > new Date(ConvertToDate(txtBasisStartDate))) {
          dtbegindate = txtFirmStartDate;
        } else {
          dtbegindate = txtBasisStartDate;
        }
      } else if (txtFirmStartDate == "" && txtBasisStartDate == "") {
        dtbegindate = getValue(thisObj, 'date');
      } else if (txtFirmStartDate !== "") {
        dtbegindate = txtFirmStartDate;
      } else if (txtBasisStartDate !== "") {
        dtbegindate = txtBasisStartDate;
      }
    }

    if (txtHarvest > new Date() || txtHarvest < dtbegindate) {
      alert(" The Harvest Complete date must be within the greater of Firm and Basis start date and Today's date.");
      isFormValid = false;
      return;
    } else if (txtHarvest == new Date() || txtHarvest == dtbegindate) {
      isFormValid = true
    }

    let cboDel_per_desc = getValue(thisObj, 'ddDlvryPrdDesc');

    if ((cboDel_per_desc == "" || cboDel_per_desc == undefined) && type == "C") {
      alert(" The Delivery period description must be selected");
      isFormValid = false;
      return;
    }
//INC5127041
  //   if (cropYear >= "2010" && type !== "S") {
  //     let mbSecurityVarietyChange = localStorage.getItem('SecurityVarietyChange') == 'true' ? true : false;
  //     let mbApplicationsExist = localStorage.getItem('ApplicationsExist') == 'true' ? true : false;
  //     if (mbApplicationsExist == true && mbSecurityVarietyChange == true && cboPeanutVariety !== strVariety) {
  //       if (cboPeanutVariety !== "") {
  //         alert("There are applications to the contract.  Peanut variety can only be changed to 'Not Specified' type.");
  //         isFormValid = false;
  //         return;
  //       }
  //     }
  //   }
  //   isFormValid = true;
  // }
  // strVariety = sHeader.pnut_variety_id;
  strVariety = getData(thisObj,"pnutvarietyid")
    if (cropYear >= "2010" && type !== "S") {
      let mbSecurityVarietyChange = localStorage.getItem('SecurityVarietyChange') == 'true' ? true : false;
      let mbApplicationsExist = localStorage.getItem('ApplicationsExist') == 'true' ? true : false;
      if (mbApplicationsExist == true && mbSecurityVarietyChange == true && cboPeanutVariety !== strVariety) {
        if (cboPeanutVariety !== "") {
          alert("There are applications to the contract.  Peanut variety can only be changed to 'Not Specified' type.");
          isFormValid = false;
          return;
        }
      }
    }
    isFormValid = true;
  }

  const Print = (txtContractNumber) => {
    if (txtContractNumber == undefined || txtContractNumber == null || txtContractNumber == '') {
      alert("Contract has not yet been created!!!");
      return false;
    } else {
      setData(thisObj, 'seedArg', msSeedTBD);
      let type = getValue(thisObj, 'ddType');
      setData(thisObj, 'contractType', type);
      setData(thisObj, 'cmdChemicalAddendum', cmdChemicalAddendum);
      setData(thisObj, 'cmdForeignMatarial', cmdForeignMatarial);
      setData(thisObj, 'minorDisclosure', document.getElementsByClassName('lnkMinDisclsr')[0].childNodes[0].childNodes[0].innerText);
      commonContext.setContractProfile_ContractNumber(txtContractNumber);
      let printComment = document.getElementById('chckbxPrintCmmnt').checked == false ? false : true;
      setData(thisObj, 'comments', printComment);
      goTo(thisObj, "ContractManagement#ReportPreviewContract#DEFAULT#true#Click");
      return true;
    }
  }

  const MinorityDisclosure = (vendorid) => {
    let sStatus;
    let sSendEmail;
    let bSkipScan;
    let contractNumber = getValue(thisObj, 'txtContrctNo');
    let sOldUploadStatus;

    ContractManagementService.RetieveRemitDetails(vendorid).then(response => {
      let data = response;
      if (data.length > 0) {
        let selectedData = data.find(o => o.remittoid == "000");
        if (selectedData !== null) {
          document.getElementsByClassName('lnkMinDisclsr')[0].childNodes[0].childNodes[0].innerText = selectedData.minority_disclosure;
        }

        if (commonContext.getContractProfile_ButtonEventName == 'Approve') {
          // Approve Logic
          bSkipScan = false;

          if (process.env.REACT_APP_ENVIR == "TEST" || process.env.REACT_APP_ENVIR == "LOCAL"  || process.env.REACT_APP_ENVIR == 'DEV' || process.env.REACT_APP_ENVIR == 'UAT') {
            if (confirm("Do you want to skip the signature process and just approve. Click on OK to proceed else click on Cancel. You are in " + process.env.REACT_APP_ENVIR) == true) {
              bSkipScan = true;
            }
          }

          ContractManagementService.RetrievePeanutStaticValues().then(response => {
            let ppsDefault = response;
            if (ppsDefault !== undefined && ppsDefault.length > 0) {
              let key = `${compId}` + '_default_overrides';
              if (ppsDefault[0].pps_defaults.hasOwnProperty(key) == true) {
                let value = Object.getOwnPropertyDescriptor(ppsDefault[0].pps_defaults, key).value;
                if (value.contract_scan_sw == 'OFF') {
                  bSkipScan = true;
                }
              }
            }

            if (bSkipScan) {
              // Skip Signature in DEV
              sStatus = "A";
              msUploadStatus = "M";
              if (process.env.REACT_APP_ENVIR == "TEST" || process.env.REACT_APP_ENVIR == "LOCAL" || process.env.REACT_APP_ENVIR == "DEV" || process.env.REACT_APP_ENVIR == 'UAT') {
                msUploadStatus = "C";
              }
              bSaveContract("A", sSendEmail, msUploadStatus).then(result => {
                if (result) {
                  setData(thisObj, "reload", true)
                  goTo(thisObj, "ContractManagement#ContractInquiry#DEFAULT#false#Click");
                  return true;
                }
              });
            } else {
              ContractManagementService.RetrieveContractDetails(contractNumber, 'PN1010').then(contractDetails => {
                if (contractDetails !== null) {
                  msUploadStatus = contractDetails[0].upload_status;
                  msApprovedUser = contractDetails[0].approved_user;
                } else {
                  alert('Problem retrieving contract status.');
                  return false;
                }

                if (msUploadStatus == "") {
                  alert("Cannot find signed PDF contract.  Please scan in signed contract before it can be approved?");
                  return false;
                }

                if (msUploadStatus == "I") {
                  alert("Invalid file was uploaded.  Please rescan the signed contract before it can be approved?");
                  return false;
                }

                if (msUploadStatus !== "C") {
                  alert("Contract has not been processed.  Try again in about 5 minutes?");
                  return false;
                }

                ContractManagementService.RetrieveUserSignatureDetails(sessionStorage.getItem('userid')).then(userSignature => {
                  if (userSignature.length == 0) {
                    alert("Cannot find your approval signature.  Use Signature Setup to setup your information.");
                    return false;
                  }
                  if (userSignature[0].user_id.trim() !== sessionStorage.getItem('userid')) {
                    alert('Cannot find your approval signature.  Use Signature Setup to setup your information.');
                    return false;
                  } else {
                    if (userSignature[0].name == '' || userSignature[0].title == '' || userSignature[0].image == '') {
                      alert('To approve contracts, you must have name, title, and signature.  Use Signature Setup to setup your information.');
                      return false;
                    }
                  }

                  if (confirm('Do you want to email approved contract to producer? Click on OK to proceed else click on Cancel.') == true) {
                    sSendEmail = "Y";
                    if (userSignature.length == 0) {
                      alert("Cannot find your email to use as the sender.");
                      return false;
                    }
                    if (userSignature[0].user_email == "") {
                      alert('Cannot find your email to use as the sender.');
                      sSendEmail = "N";
                      return false;
                    }
                    let vendor = getValue(thisObj, 'txtVndr');
                    ContractManagementService.RetrieveVendorEmailDetails(null, null, null, vendor, null, null).then(vendorDetails => {
                      let emaillist = vendorDetails[0];
                      if (emaillist.vendorEmail1 !== '') {
                        sEmail = true;
                      } else if (emaillist.vendorEmail2 !== '') {
                        sEmail = true;
                      } else if (emaillist.vendorEmail3 !== '') {
                        sEmail = true;
                      } else {
                        alert('Cannot find producers email address.  Contract will not be sent to producer.');
                        sSendEmail = "N";
                      }
                    });
                    msUploadStatus = "M";
                    if (bSkipScan){
                      if (process.env.REACT_APP_ENVIR == "TEST" || process.env.REACT_APP_ENVIR == "LOCAL" || process.env.REACT_APP_ENVIR == "DEV" || process.env.REACT_APP_ENVIR == 'UAT') {
                        msUploadStatus = "C";
                      }
                    }
                    bSaveContract("A", sSendEmail, msUploadStatus).then(result => {
                      if (result) {
                        setData(thisObj, "reload", true)
                        goTo(thisObj, "ContractManagement#ContractInquiry#DEFAULT#false#Click");
                        return true;
                      }
                    });
                  } else {
                    sSendEmail = "N";
                    msUploadStatus = "M";
                    if (bSkipScan){
                      if (process.env.REACT_APP_ENVIR == "TEST" || process.env.REACT_APP_ENVIR == "LOCAL" || process.env.REACT_APP_ENVIR == "DEV" || process.env.REACT_APP_ENVIR == 'UAT') {
                        msUploadStatus = "C";
                      }
                    }
                    bSaveContract("A", sSendEmail, msUploadStatus).then(result => {
                      if (result) {
                        setData(thisObj, "reload", true)
                        goTo(thisObj, "ContractManagement#ContractInquiry#DEFAULT#false#Click");
                        return true;
                      }
                    });
                  }
                });
              });
            }
          });
        }
      }
    });
  }

  // const FillSeedAddendum = () => {
  //   let sHeader = '';
  //   let seed_tbd = '';
  //   let sVendorSplits = '';
  //   let contractDetails = await ContractManagementService.RetrieveContractDetails(contractNumber, 'PN1010');
  //   if (contractDetails !== null) {
  //     let sHeader = contractdetails[0];
  //     seed_tbd = sHeader.seed_tbd;
  //     sVendorSplits = sHeader.contract_vendor_splits;
  //     if (sVendorSplits.length > 0) {
  //       let seedGrower = sVendorSplits[0].addendum_seed_growers;
  //     }
  //   } else {
  //     alert('Problem retrieving contract status.');
  //     return false;
  //   }
  // }

  const bCheckRTP = async (contractNumber) => {
    let checkBatchRPTValue = await ContractManagementService.RetrieveBatchCheckReadyToPayDetails(contractNumber);
    if (checkBatchRPTValue.length == 0) {
      alert("An error occured while updating the database. Please verify that your data is correct and retry. Contact your system administrator if this problem persists.");
      return false;
    } else {
      if (checkBatchRPTValue[0].record_count > 0) {
        if (checkBatchRPTValue[0].batch.length > 0) {
          let vendor = checkBatchRPTValue[0].batch[0].payee_vendor;
          alert('Pending Batched Check: You must release the pending payment to the following vendor ' + vendor + '.' + ' Vendor Information cannot be edited till such time.');
        }
        disable(thisObj, 'btnPopUp');
        document.getElementById('ddRemit').disabled = true;
        disable(thisObj, 'txtVndr');
        disable(thisObj, 'txtShare');
        disable(thisObj, 'txtSignAdv');
      }
    }
  }

  // Methods

  // Events
  // OnChange Event of ddRemit dropdown
  const onddRemitChange = () => {
    let list = thisObj.state['ddRemit'].valueList;
    let val = thisObj.values['ddRemit'];
    if (val !== null && val !== undefined && val !== '') {
      let remitId = list.find(elem => elem.key === val).key;
      ContractManagementService.RetieveRemitDetails(getValue(thisObj, "txtVndr")).then(response => {
        if (response.length > 0) {
          let remitDetails = response.find(o => o.remittoid == remitId);
          if (remitDetails !== null && remitDetails !== undefined) {
            setValue(thisObj, "ddRemit", remitDetails.remittoid);
            setValue(thisObj, "txtName", remitDetails.name);
            setValue(thisObj, "txtEmail", remitDetails.email1);
            setValue(thisObj, "txt211Gpc", remitDetails.golden_form_211);
            setValue(thisObj, "txt211concordia", remitDetails.concordia_form_211);
            document.getElementsByClassName('lnkMinDisclsr')[0].childNodes[0].childNodes[0].innerText = remitDetails.minority_disclosure;
          }
        }
      });
    }
  }
  thisObj.onddRemitChange = onddRemitChange;
  // Onchange Event of ddRemit dropdown

  //Type Chnage Event
  const onddTypeChange = async () => {
    let type = getValue(thisObj, 'ddType');
    let pnuttypeid = getValue(thisObj, 'ddPeanutType');
    let segtypeid = getValue(thisObj, 'ddSegType');
    let organicInd = document.getElementById('chckbxOrgnc').checked == true ? 'Y' : 'N';
    let contractnum = getValue(thisObj, 'txtContrctNo');
    let pnutvarietyid = getValue(thisObj, 'ddPeaNtVrty');
    let buyingpointId = getValue(thisObj, 'ddLocation');
    let oleicid = getValue(thisObj, 'ddOleic');
    vendorNumber = getValue(thisObj, 'txtVndr');

    if (type !== '' && type !== undefined) {
      // Firm Details
      setValue(thisObj, "chckbxHrvst", false);
      setValue(thisObj, "dtStart", '');
      setValue(thisObj, "dtEnd", '');
      setValue(thisObj, "txtPounds", '');

      // Basis Details
      setValue(thisObj, "chkbxHrvst2", false);
      setValue(thisObj, "dtStrt2", '');
      setValue(thisObj, "dtEnd2", '');
      setValue(thisObj, "txtPounds2", '');
    }
    let txtStatus = getValue(thisObj, "txtStatus");
    let txtApplied = getValue(thisObj, 'txtApplied');
    let txtApp2 = getValue(thisObj, 'txtApp2');
    let txtGrpng = getValue(thisObj, "txtGrpng");
    let txtDtCncl = getValue(thisObj, "txtDtCncl");
    EnableDisableControl(txtStatus, type, contractnum, txtApplied, txtApp2, txtGrpng, txtDtCncl);

    if (type == 'O' || type == 'M' || type == 'L') {
      let basisStartDate = ConvertToDate(getValue(thisObj, "dtStrt2")["_d"]);

      let basisEndDate = ConvertToDate(getValue(thisObj, "dtEnd2")["_d"]);

      if (basisStartDate == "") {
        setValue(thisObj, "dtStrt2", "08/15/" + cropYear);
      }
      if (basisEndDate == "") {
        setValue(thisObj, "dtEnd2", "11/30/" + (Number(cropYear) + 1));
      }
      // ComputeSigningAdvance();
      setValue(thisObj, "txtSignAdv", '10.00');
      setmdblSignAdv('10.00');
      // Round Method is calling here. We will check later what output they are expecting.
    } else {
      setValue(thisObj, "txtSignAdv", '');
    }

    GetFirmPriceList(pnuttypeid, segtypeid, organicInd, buyingpointId, contractnum, pnutvarietyid, oleicid, vendorNumber);
    GetPriceList(type, pnuttypeid, segtypeid, organicInd, buyingpointId, contractnum, pnutvarietyid, oleicid, vendorNumber);

    if (cropYear >= "2010" && type !== "S") {
      document.getElementsByClassName("chkSeedGrower2010")[0].style.visibility = "visible";
      document.getElementsByClassName("chkBxSeedGrowr")[0].style.visibility = "hidden";
      setValue(thisObj, "chkBxSeedGrowr", false);
      EnableDisableSeedAgreeInfo();
      bNoAddendums2009('SEED GROWER CHANGE');
    } else {
      document.getElementsByClassName("chkSeedGrower2010")[0].style.visibility = "hidden";
      document.getElementsByClassName("chkBxSeedGrowr")[0].style.visibility = "visible";
      setValue(thisObj, "chkSeedGrower2010", false);
    }
  };
  thisObj.onddTypeChange = onddTypeChange;

  //Type Chane Event

  //Binding Peanut Varity Control on the click of Peanut Type Control
  const onddPeanutTypeChange = () => {
    PeanutTypeChange();
  };
  thisObj.onddPeanutTypeChange = onddPeanutTypeChange;
  //For Peanut Varity Control

  // Checkbox Check Event - Check Damege Range
  const onchkbxFreezeDmgRngChange = () => {
    CheckDamegeRange();
  };
  thisObj.onchkbxFreezeDmgRngChange = onchkbxFreezeDmgRngChange;
  // Checkbox Check Event - Check Damege Range

  // Checkbox Seed Grower Check Event
  const onchkBxSeedGrowrChange = () => {
    CheckSeedGrower();
  };
  thisObj.onchkBxSeedGrowrChange = onchkBxSeedGrowrChange;
  // Checkbox Seed Grower Check Event

  // On Change Location Control
  const onddLocationChange = () => {
    LocationChange();
  };
  thisObj.onddLocationChange = onddLocationChange;
  // On Change Location Control

  // Save Contract
  const onbtnCreateClick = async () => {
    let sStatus = '';
    let sChangeStatusPending = '';

    let pnuttypeid = getValue(thisObj, 'ddPeanutType');
    let peanutType = await ContractManagementService.RetrievePeanutTypeControls(pnuttypeid);
    bFormValid(peanutType);
    if (!isFormValid) {
      return;
    }
    sStatus = getValue(thisObj, 'txtStatus');
    if (document.getElementsByClassName('btnCreate')[0].childNodes[0].childNodes[0].innerText == 'Create') {
      sStatus = 'P';
    }

    if ((sStatus == 'A' || sStatus == 'Approved') &&
      document.getElementById('chckbxAlwRescn').checked == true &&
      document.getElementsByClassName('btnCreate')[0].childNodes[0].childNodes[0].innerText == "Save") {
      sChangeStatusPending = "P";
      sStatus = "P";
    } else {
      sChangeStatusPending = "";
    }

    if ((document.getElementsByClassName('btnCreate')[0].childNodes[0].childNodes[0].innerText == 'Create') == true) {
      let chkSeedGrower2010 = getValue(thisObj, "chkSeedGrower2010");
      setData(thisObj, "chkSeedGrower2010", chkSeedGrower2010);
      setData(thisObj, "parentName", "ContractProfile");
      let result = await bSaveContract(sChangeStatusPending, null, null, null);
      if (result > 0) {
        commonContext.setContractProfile_ContractNumber(result);
        commonContext.setFlexMktPrint_PriceLine("2");
        commonContext.setFlexMktPrint_PriceSuffix("A");
        commonContext.setFlexMktPrint_PrintType("CONTRACT");
        setFormDirty(false)
        // commonContext.setContractProfile_ReportRequest("Create");
        Print(result);
      }
    } else {
      let result = await bSaveContract(sChangeStatusPending, null, null, null);
      if (result == true) {
        if (sChangeStatusPending == 'P') {
          setData(thisObj, "reload", true)
          setFormDirty(false)
          goTo(thisObj, "ContractManagement#ContractInquiry#DEFAULT#false#Click");
          return true;
        } else {
          setData(thisObj, "reload", true)
          setFormDirty(false)
          alert("Changes made to this contract have been successfully changed!!!");
        }
      }
    }
  }
  thisObj.onbtnCreateClick = onbtnCreateClick;
  // Save Contract

  // Chemical Agreement
  const onbtnChemiclAgreemntClick = () => {
    if (cmdChemicalAddendum == 'N' || cmdChemicalAddendum == '') {
      setCmdChemicalAddendum('Y');
      document.getElementById("btnChemiclAgreemnt").style.backgroundImage = "none";
      document.getElementById("btnChemiclAgreemnt").style.backgroundColor = "#AFDBC9";
    }
    else {
      setCmdChemicalAddendum('N');
      document.getElementById("btnChemiclAgreemnt").style.backgroundImage = 'linear-gradient(white, #e9ecef 60%, #dde2e6)';
      document.getElementById("btnChemiclAgreemnt").style.backgroundColor = "#d3d9df";
    }
  }
  thisObj.onbtnChemiclAgreemntClick = onbtnChemiclAgreemntClick;
  // Chemical Agreement

  // Chemical Agreement
  const onbtnFmAgreementClick = () => {
    if (cmdForeignMatarial == 'N' || cmdForeignMatarial == '') {
      setcmdForeignMatarial('Y');
      document.getElementById("btnFmAgreement").style.backgroundImage = "none";
      document.getElementById("btnFmAgreement").style.backgroundColor = "#AFDBC9";
    }
    else {
      setcmdForeignMatarial('N');
      document.getElementById("btnFmAgreement").style.backgroundImage = 'linear-gradient(white, #e9ecef 60%, #dde2e6)';
      document.getElementById("btnFmAgreement").style.backgroundColor = "#d3d9df";
    }
  }
  thisObj.onbtnFmAgreementClick = onbtnFmAgreementClick;
  // Chemical Agreement

  // Peanut Variety Change
  const onddPeaNtVrtyChange = () => {
    PeanutVarietyChange();
  };
  thisObj.onddPeaNtVrtyChange = onddPeaNtVrtyChange;
  // Peanut Variety Change

  //Oleic Chnage Event
  const onddOleicChange = () => {
    vendorNumber = getValue(thisObj, 'txtVndr');
    showhideAddendums(vendorNumber);
    let type = getValue(thisObj, 'ddType');
    let pnuttypeid = getValue(thisObj, 'ddPeanutType');
    let segtypeid = getValue(thisObj, 'ddSegType');
    let organicInd = document.getElementById('chckbxOrgnc').checked == true ? 'Y' : 'N';
    let contractnum = getValue(thisObj, 'txtContrctNo');
    let pnutvarietyid = getValue(thisObj, 'ddPeaNtVrty');
    let buyingpointId = getValue(thisObj, 'ddLocation');
    let oleicid = getValue(thisObj, 'ddOleic');
    GetFirmPriceList(pnuttypeid, segtypeid, organicInd, buyingpointId, contractnum, pnutvarietyid, oleicid, vendorNumber);
    GetPriceList(type, pnuttypeid, segtypeid, organicInd, buyingpointId, contractnum, pnutvarietyid, oleicid, vendorNumber);
  };
  thisObj.onddOleicChange = onddOleicChange;

  //Oleic Chane Event

  //Seg Chnage Event
  const onddSegTypeChange = () => {
    let txtStatus = getValue(thisObj, "txtStatus");
    vendorNumber = getValue(thisObj, 'txtVndr');
    let type = getValue(thisObj, 'ddType');
    let pnuttypeid = getValue(thisObj, 'ddPeanutType');
    let segtypeid = getValue(thisObj, 'ddSegType');
    let organicInd = document.getElementById('chckbxOrgnc').checked == true ? 'Y' : 'N';
    let contractnum = getValue(thisObj, 'txtContrctNo');
    let pnutvarietyid = getValue(thisObj, 'ddPeaNtVrty');
    let buyingpointId = getValue(thisObj, 'ddLocation');
    let oleicid = getValue(thisObj, 'ddOleic');

    GetFirmPriceList(pnuttypeid, segtypeid, organicInd, buyingpointId, contractnum, pnutvarietyid, oleicid, vendorNumber);
    GetPriceList(type, pnuttypeid, segtypeid, organicInd, buyingpointId, contractnum, pnutvarietyid, oleicid, vendorNumber);

    let txtApplied = getValue(thisObj, 'txtApplied');
    let txtApp2 = getValue(thisObj, 'txtApp2');
    let txtGrpng = getValue(thisObj, "txtGrpng");
    let txtDtCncl = getValue(thisObj, "txtDtCncl");
    EnableDisableControl(txtStatus, type, contractnum, txtApplied, txtApp2, txtGrpng, txtDtCncl);
  };
  thisObj.onddSegTypeChange = onddSegTypeChange;
  //Seg Chane Event

  // Check Organic Checkbox
  const onchckbxOrgncChange = () => {
    vendorNumber = getValue(thisObj, 'txtVndr');
    let type = getValue(thisObj, 'ddType');
    let pnuttypeid = getValue(thisObj, 'ddPeanutType');
    let segtypeid = getValue(thisObj, 'ddSegType');
    let organicInd = document.getElementById('chckbxOrgnc').checked == true ? 'Y' : 'N';
    let contractnum = getValue(thisObj, 'txtContrctNo');
    let pnutvarietyid = getValue(thisObj, 'ddPeaNtVrty');
    let buyingpointId = getValue(thisObj, 'ddLocation');
    let oleicid = getValue(thisObj, 'ddOleic');

    GetFirmPriceList(pnuttypeid, segtypeid, organicInd, buyingpointId, contractnum, pnutvarietyid, oleicid, vendorNumber);
    GetPriceList(type, pnuttypeid, segtypeid, organicInd, buyingpointId, contractnum, pnutvarietyid, oleicid, vendorNumber);
  };
  thisObj.onchckbxOrgncChange = onchckbxOrgncChange;
  // Check Organic Checkbox

  // Check Organic Checkbox
  const onbtnEnblDateClick = () => {
    enable(thisObj, 'date');
  };
  thisObj.onbtnEnblDateClick = onbtnEnblDateClick;
  // Check Organic Checkbox

  // cmdSetpExcPrm button click event
  const onbtnSetpExcPrmClick = () => {
    try {
      let stausValue = getValue(thisObj, 'txtStatus');

      let buyingPointlist = thisObj.state['ddLocation'].valueList;
      let buyingPointDescription = '';
      let buyingPointId = thisObj.values['ddLocation'];
      if (buyingPointId !== undefined && buyingPointId !== null) {
        buyingPointDescription = buyingPointlist.find(elem => elem.key == buyingPointId).description;
      }
      let contrctNumber = getValue(thisObj, 'txtContrctNo');
      if (stausValue !== 'P') {
        commonContext.setContractProfile_BuyingPointId(buyingPointId);
        setData(thisObj, "BuyingPointLabel", buyingPointDescription)
        commonContext.setContractProfile_ContractNumber(contrctNumber);
        goTo(thisObj, "ContractManagement#ContractExceptionPremiumSetup#DEFAULT#true#Click");
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };
  thisObj.onbtnSetpExcPrmClick = onbtnSetpExcPrmClick;
  // cmdSetpExcPrm button click event

  // ontxtCntrPivtAvgYldBlur button click event
  const ontxtCntrPivtAvgYldBlur = () => {
    let txtCntrPivtAvgYld = getValue(thisObj, 'txtCntrPivtAvgYld');
    if (txtCntrPivtAvgYld !== undefined && txtCntrPivtAvgYld !== null && txtCntrPivtAvgYld !== '') {
      txtCntrPivtAvgYld = Number(txtCntrPivtAvgYld).toFixed();
      setValue(thisObj, 'txtCntrPivtAvgYld', txtCntrPivtAvgYld);
    }
    let txtCntrPivtAcrs = getValue(thisObj, 'txtCntrPivtAcrs');
    if (txtCntrPivtAcrs !== undefined && txtCntrPivtAcrs !== null && txtCntrPivtAcrs !== '') {
      txtCntrPivtAcrs = Number(txtCntrPivtAcrs).toFixed();
      setValue(thisObj, 'txtCntrPivtAcrs', txtCntrPivtAcrs);
    }
    let txtOthrAvgYld = getValue(thisObj, 'txtOthrAvgYld');
    if (txtOthrAvgYld !== undefined && txtOthrAvgYld !== null && txtOthrAvgYld !== '') {
      txtOthrAvgYld = Number(txtOthrAvgYld).toFixed();
      setValue(thisObj, 'txtOthrAvgYld', txtOthrAvgYld);
    }
    let txtOthrAcrs = getValue(thisObj, 'txtOthrAcrs');
    if (txtOthrAcrs !== undefined && txtOthrAcrs !== null && txtOthrAcrs !== '') {
      txtOthrAcrs = Number(txtOthrAcrs).toFixed();
      setValue(thisObj, 'txtOthrAcrs', txtOthrAcrs);
    }
    let txtDryLndAvgYld = getValue(thisObj, "txtDryLndAvgYld");
    if (txtDryLndAvgYld !== undefined && txtDryLndAvgYld !== null && txtDryLndAvgYld !== '') {
      txtDryLndAvgYld = Number(txtDryLndAvgYld).toFixed();
      setValue(thisObj, 'txtDryLndAvgYld', txtDryLndAvgYld);
    }
    let txtDryLndAcres = getValue(thisObj, "txtDryLndAcres");
    if (txtDryLndAcres !== undefined && txtDryLndAcres !== null && txtDryLndAcres !== '') {
      txtDryLndAcres = Number(txtDryLndAcres).toFixed();
      setValue(thisObj, 'txtDryLndAcres', txtDryLndAcres);
    }
    CalculateTotal(txtCntrPivtAvgYld, txtCntrPivtAcrs, txtOthrAvgYld, txtOthrAcrs, txtDryLndAvgYld, txtDryLndAcres);
  };
  thisObj.ontxtCntrPivtAvgYldBlur = ontxtCntrPivtAvgYldBlur;
  // ontxtCntrPivtAvgYldBlur button click event

  // ontxtCntrPivtAcrsBlur button click event
  const ontxtCntrPivtAcrsBlur = () => {
    let txtCntrPivtAvgYld = getValue(thisObj, 'txtCntrPivtAvgYld');
    if (txtCntrPivtAvgYld !== undefined && txtCntrPivtAvgYld !== null && txtCntrPivtAvgYld !== '') {
      txtCntrPivtAvgYld = Number(txtCntrPivtAvgYld).toFixed();
      setValue(thisObj, 'txtCntrPivtAvgYld', txtCntrPivtAvgYld);
    }
    let txtCntrPivtAcrs = getValue(thisObj, 'txtCntrPivtAcrs');
    if (txtCntrPivtAcrs !== undefined && txtCntrPivtAcrs !== null && txtCntrPivtAcrs !== '') {
      txtCntrPivtAcrs = Number(txtCntrPivtAcrs).toFixed();
      setValue(thisObj, 'txtCntrPivtAcrs', txtCntrPivtAcrs);
    }
    let txtOthrAvgYld = getValue(thisObj, 'txtOthrAvgYld');
    if (txtOthrAvgYld !== undefined && txtOthrAvgYld !== null && txtOthrAvgYld !== '') {
      txtOthrAvgYld = Number(txtOthrAvgYld).toFixed();
      setValue(thisObj, 'txtOthrAvgYld', txtOthrAvgYld);
    }
    let txtOthrAcrs = getValue(thisObj, 'txtOthrAcrs');
    if (txtOthrAcrs !== undefined && txtOthrAcrs !== null && txtOthrAcrs !== '') {
      txtOthrAcrs = Number(txtOthrAcrs).toFixed();
      setValue(thisObj, 'txtOthrAcrs', txtOthrAcrs);
    }
    let txtDryLndAvgYld = getValue(thisObj, "txtDryLndAvgYld");
    if (txtDryLndAvgYld !== undefined && txtDryLndAvgYld !== null && txtDryLndAvgYld !== '') {
      txtDryLndAvgYld = Number(txtDryLndAvgYld).toFixed();
      setValue(thisObj, 'txtDryLndAvgYld', txtDryLndAvgYld);
    }
    let txtDryLndAcres = getValue(thisObj, "txtDryLndAcres");
    if (txtDryLndAcres !== undefined && txtDryLndAcres !== null && txtDryLndAcres !== '') {
      txtDryLndAcres = Number(txtDryLndAcres).toFixed();
      setValue(thisObj, 'txtDryLndAcres', txtDryLndAcres);
    }
    CalculateTotal(txtCntrPivtAvgYld, txtCntrPivtAcrs, txtOthrAvgYld, txtOthrAcrs, txtDryLndAvgYld, txtDryLndAcres);
  };
  thisObj.ontxtCntrPivtAcrsBlur = ontxtCntrPivtAcrsBlur;
  // ontxtCntrPivtAcrsBlur button click event

  // ontxtOthrAvgYldBlur button click event
  const ontxtOthrAvgYldBlur = () => {
    let txtCntrPivtAvgYld = getValue(thisObj, 'txtCntrPivtAvgYld');
    if (txtCntrPivtAvgYld !== undefined && txtCntrPivtAvgYld !== null && txtCntrPivtAvgYld !== '') {
      txtCntrPivtAvgYld = Number(txtCntrPivtAvgYld).toFixed();
      setValue(thisObj, 'txtCntrPivtAvgYld', txtCntrPivtAvgYld);
    }
    let txtCntrPivtAcrs = getValue(thisObj, 'txtCntrPivtAcrs');
    if (txtCntrPivtAcrs !== undefined && txtCntrPivtAcrs !== null && txtCntrPivtAcrs !== '') {
      txtCntrPivtAcrs = Number(txtCntrPivtAcrs).toFixed();
      setValue(thisObj, 'txtCntrPivtAcrs', txtCntrPivtAcrs);
    }
    let txtOthrAvgYld = getValue(thisObj, 'txtOthrAvgYld');
    if (txtOthrAvgYld !== undefined && txtOthrAvgYld !== null && txtOthrAvgYld !== '') {
      txtOthrAvgYld = Number(txtOthrAvgYld).toFixed();
      setValue(thisObj, 'txtOthrAvgYld', txtOthrAvgYld);
    }
    let txtOthrAcrs = getValue(thisObj, 'txtOthrAcrs');
    if (txtOthrAcrs !== undefined && txtOthrAcrs !== null && txtOthrAcrs !== '') {
      txtOthrAcrs = Number(txtOthrAcrs).toFixed();
      setValue(thisObj, 'txtOthrAcrs', txtOthrAcrs);
    }
    let txtDryLndAvgYld = getValue(thisObj, "txtDryLndAvgYld");
    if (txtDryLndAvgYld !== undefined && txtDryLndAvgYld !== null && txtDryLndAvgYld !== '') {
      txtDryLndAvgYld = Number(txtDryLndAvgYld).toFixed();
      setValue(thisObj, 'txtDryLndAvgYld', txtDryLndAvgYld);
    }
    let txtDryLndAcres = getValue(thisObj, "txtDryLndAcres");
    if (txtDryLndAcres !== undefined && txtDryLndAcres !== null && txtDryLndAcres !== '') {
      txtDryLndAcres = Number(txtDryLndAcres).toFixed();
      setValue(thisObj, 'txtDryLndAcres', txtDryLndAcres);
    }
    CalculateTotal(txtCntrPivtAvgYld, txtCntrPivtAcrs, txtOthrAvgYld, txtOthrAcrs, txtDryLndAvgYld, txtDryLndAcres);
  };
  thisObj.ontxtOthrAvgYldBlur = ontxtOthrAvgYldBlur;
  // ontxtOthrAvgYldBlur button click event

  // ontxtOthrAcrsBlur button click event
  const ontxtOthrAcrsBlur = () => {
    let txtCntrPivtAvgYld = getValue(thisObj, 'txtCntrPivtAvgYld');
    if (txtCntrPivtAvgYld !== undefined && txtCntrPivtAvgYld !== null && txtCntrPivtAvgYld !== '') {
      txtCntrPivtAvgYld = Number(txtCntrPivtAvgYld).toFixed();
      setValue(thisObj, 'txtCntrPivtAvgYld', txtCntrPivtAvgYld);
    }
    let txtCntrPivtAcrs = getValue(thisObj, 'txtCntrPivtAcrs');
    if (txtCntrPivtAcrs !== undefined && txtCntrPivtAcrs !== null && txtCntrPivtAcrs !== '') {
      txtCntrPivtAcrs = Number(txtCntrPivtAcrs).toFixed();
      setValue(thisObj, 'txtCntrPivtAcrs', txtCntrPivtAcrs);
    }
    let txtOthrAvgYld = getValue(thisObj, 'txtOthrAvgYld');
    if (txtOthrAvgYld !== undefined && txtOthrAvgYld !== null && txtOthrAvgYld !== '') {
      txtOthrAvgYld = Number(txtOthrAvgYld).toFixed();
      setValue(thisObj, 'txtOthrAvgYld', txtOthrAvgYld);
    }
    let txtOthrAcrs = getValue(thisObj, 'txtOthrAcrs');
    if (txtOthrAcrs !== undefined && txtOthrAcrs !== null && txtOthrAcrs !== '') {
      txtOthrAcrs = Number(txtOthrAcrs).toFixed();
      setValue(thisObj, 'txtOthrAcrs', txtOthrAcrs);
    }
    let txtDryLndAvgYld = getValue(thisObj, "txtDryLndAvgYld");
    if (txtDryLndAvgYld !== undefined && txtDryLndAvgYld !== null && txtDryLndAvgYld !== '') {
      txtDryLndAvgYld = Number(txtDryLndAvgYld).toFixed();
      setValue(thisObj, 'txtDryLndAvgYld', txtDryLndAvgYld);
    }
    let txtDryLndAcres = getValue(thisObj, "txtDryLndAcres");
    if (txtDryLndAcres !== undefined && txtDryLndAcres !== null && txtDryLndAcres !== '') {
      txtDryLndAcres = Number(txtDryLndAcres).toFixed();
      setValue(thisObj, 'txtDryLndAcres', txtDryLndAcres);
    }
    CalculateTotal(txtCntrPivtAvgYld, txtCntrPivtAcrs, txtOthrAvgYld, txtOthrAcrs, txtDryLndAvgYld, txtDryLndAcres);
  };
  thisObj.ontxtOthrAcrsBlur = ontxtOthrAcrsBlur;
  // ontxtOthrAcrsBlur button click event

  // ontxtDryLndAvgYldBlur button click event
  const ontxtDryLndAvgYldBlur = () => {
    let txtCntrPivtAvgYld = getValue(thisObj, 'txtCntrPivtAvgYld');
    if (txtCntrPivtAvgYld !== undefined && txtCntrPivtAvgYld !== null && txtCntrPivtAvgYld !== '') {
      txtCntrPivtAvgYld = Number(txtCntrPivtAvgYld).toFixed();
      setValue(thisObj, 'txtCntrPivtAvgYld', txtCntrPivtAvgYld);
    }
    let txtCntrPivtAcrs = getValue(thisObj, 'txtCntrPivtAcrs');
    if (txtCntrPivtAcrs !== undefined && txtCntrPivtAcrs !== null && txtCntrPivtAcrs !== '') {
      txtCntrPivtAcrs = Number(txtCntrPivtAcrs).toFixed();
      setValue(thisObj, 'txtCntrPivtAcrs', txtCntrPivtAcrs);
    }
    let txtOthrAvgYld = getValue(thisObj, 'txtOthrAvgYld');
    if (txtOthrAvgYld !== undefined && txtOthrAvgYld !== null && txtOthrAvgYld !== '') {
      txtOthrAvgYld = Number(txtOthrAvgYld).toFixed();
      setValue(thisObj, 'txtOthrAvgYld', txtOthrAvgYld);
    }
    let txtOthrAcrs = getValue(thisObj, 'txtOthrAcrs');
    if (txtOthrAcrs !== undefined && txtOthrAcrs !== null && txtOthrAcrs !== '') {
      txtOthrAcrs = Number(txtOthrAcrs).toFixed();
      setValue(thisObj, 'txtOthrAcrs', txtOthrAcrs);
    }
    let txtDryLndAvgYld = getValue(thisObj, "txtDryLndAvgYld");
    if (txtDryLndAvgYld !== undefined && txtDryLndAvgYld !== null && txtDryLndAvgYld !== '') {
      txtDryLndAvgYld = Number(txtDryLndAvgYld).toFixed();
      setValue(thisObj, 'txtDryLndAvgYld', txtDryLndAvgYld);
    }
    let txtDryLndAcres = getValue(thisObj, "txtDryLndAcres");
    if (txtDryLndAcres !== undefined && txtDryLndAcres !== null && txtDryLndAcres !== '') {
      txtDryLndAcres = Number(txtDryLndAcres).toFixed();
      setValue(thisObj, 'txtDryLndAcres', txtDryLndAcres);
    }
    CalculateTotal(txtCntrPivtAvgYld, txtCntrPivtAcrs, txtOthrAvgYld, txtOthrAcrs, txtDryLndAvgYld, txtDryLndAcres);
  };
  thisObj.ontxtDryLndAvgYldBlur = ontxtDryLndAvgYldBlur;
  // ontxtDryLndAvgYldBlur button click event

  // ontxtDryLndAvgYldBlur button click event
  const ontxtDryLndAcresBlur = () => {
    let txtCntrPivtAvgYld = getValue(thisObj, 'txtCntrPivtAvgYld');
    if (txtCntrPivtAvgYld !== undefined && txtCntrPivtAvgYld !== null && txtCntrPivtAvgYld !== '') {
      txtCntrPivtAvgYld = Number(txtCntrPivtAvgYld).toFixed();
      setValue(thisObj, 'txtCntrPivtAvgYld', txtCntrPivtAvgYld);
    }
    let txtCntrPivtAcrs = getValue(thisObj, 'txtCntrPivtAcrs');
    if (txtCntrPivtAcrs !== undefined && txtCntrPivtAcrs !== null && txtCntrPivtAcrs !== '') {
      txtCntrPivtAcrs = Number(txtCntrPivtAcrs).toFixed();
      setValue(thisObj, 'txtCntrPivtAcrs', txtCntrPivtAcrs);
    }
    let txtOthrAvgYld = getValue(thisObj, 'txtOthrAvgYld');
    if (txtOthrAvgYld !== undefined && txtOthrAvgYld !== null && txtOthrAvgYld !== '') {
      txtOthrAvgYld = Number(txtOthrAvgYld).toFixed();
      setValue(thisObj, 'txtOthrAvgYld', txtOthrAvgYld);
    }
    let txtOthrAcrs = getValue(thisObj, 'txtOthrAcrs');
    if (txtOthrAcrs !== undefined && txtOthrAcrs !== null && txtOthrAcrs !== '') {
      txtOthrAcrs = Number(txtOthrAcrs).toFixed();
      setValue(thisObj, 'txtOthrAcrs', txtOthrAcrs);
    }
    let txtDryLndAvgYld = getValue(thisObj, "txtDryLndAvgYld");
    if (txtDryLndAvgYld !== undefined && txtDryLndAvgYld !== null && txtDryLndAvgYld !== '') {
      txtDryLndAvgYld = Number(txtDryLndAvgYld).toFixed();
      setValue(thisObj, 'txtDryLndAvgYld', txtDryLndAvgYld);
    }
    let txtDryLndAcres = getValue(thisObj, "txtDryLndAcres");
    if (txtDryLndAcres !== undefined && txtDryLndAcres !== null && txtDryLndAcres !== '') {
      txtDryLndAcres = Number(txtDryLndAcres).toFixed();
      setValue(thisObj, 'txtDryLndAcres', txtDryLndAcres);
    }
    CalculateTotal(txtCntrPivtAvgYld, txtCntrPivtAcrs, txtOthrAvgYld, txtOthrAcrs, txtDryLndAvgYld, txtDryLndAcres);
  };
  thisObj.ontxtDryLndAcresBlur = ontxtDryLndAcresBlur;
  // ontxtDryLndAvgYldBlur button click event

  // Email button click event
  const onbtnEmailClick = async () => {
    let sEmail;
    let contractNumber = getValue(thisObj, 'txtContrctNo');

    let contractDetails = await ContractManagementService.RetrieveContractDetails(contractNumber, 'PN1010');
    if (contractDetails !== null) {
      msUploadStatus = contractDetails[0].upload_status;
      msApprovedUser = contractDetails[0].approved_user;
    } else {
      showMessage(thisObj, 'Problem retrieving contract status.');
      return;
    }

    if (msUploadStatus !== '' && msUploadStatus !== 'P') {
      let vendor = getValue(thisObj, 'txtVndr');
      let emailList = await ContractManagementService.RetrieveVendorEmailDetails(null, null, null, vendor, null, null);
      if (emailList[0].vendorEmail1 !== '' || emailList[0].vendorEmail2 !== '' || emailList[0].vendorEmail3 !== '') {
        sEmail = true;
      } else {
        alert('Cannot find producers email address.  Contract will not be sent to producer.');
        sEmail = false;
      }

      if (sEmail == true) {
        let contractUpdate = await ContractManagementService.UpdateContractEmail(contractNumber, { 'emailInd': 'Y' })
        if (contractUpdate !== undefined && contractUpdate !== null && contractUpdate !== '') {
          alert('Contract will be sent to vendor.');
        } else {
          alert('Problem with emailing contract.');
        }
      }
    } else {
      alert('Cannot find scanned contract to email.');
    }

  };
  thisObj.onbtnEmailClick = onbtnEmailClick;
  // Email button click event

  // btnRescan Click
  const onbtnRescanClick = async () => {
    let sScanConractType;
    let sEmail;
    let sSendEmail;

    sSendEmail = "N";
    let pnuttypeid = getValue(thisObj, 'ddPeanutType');
    let peanutType = await ContractManagementService.RetrievePeanutTypeControls(pnuttypeid);
    bFormValid(peanutType);
    if (!isFormValid) {
      return;
    }
    let contractNumber = getValue(thisObj, 'txtContrctNo');

    let contractDetails = await ContractManagementService.RetrieveContractDetails(contractNumber, 'PN1010');
    if (contractDetails !== null) {
      msUploadStatus = contractDetails[0].upload_status;
      msApprovedUser = contractDetails[0].approved_user;
    } else {
      alert('Problem retrieving contract status.');
      return;
    }

    if (msUploadStatus !== 'C' && msUploadStatus !== 'I') {
      alert('Contract has not been processed. Try again in about 5 minutes?');
      return;
    }

    let lblStatus = getValue(thisObj, 'txtStatus');
    if (lblStatus == 'S' || lblStatus == 'Signed') {
      sScanConractType = "P";
    } else if (lblStatus == 'A' || lblStatus == 'Approved') {
      sScanConractType = "R";
      let userSignature = await ContractManagementService.RetrieveUserSignatureDetails(msApprovedUser);
      if (userSignature.length == 0) {
        alert("Cannot find user signature details for the user: " + msApprovedUser + '. Use Signature Setup to setup the informaiton.');
        return;
      }
      if (userSignature[0].user_id.trim() !== msApprovedUser || msApprovedUser == "") {
        alert('Cannot find signature for user: ' + msApprovedUser + '.  Use Signature Setup to setup the informaiton.');
        return;
      } else {
        if (userSignature[0].name.trim() == '' || userSignature[0].title.trim() == '' || userSignature[0].image == '') {
          alert('To approve contracts, user:  ' + msApprovedUser + ' must have name, title, and signature.  Use Signature Setup to setup the information.');
          return;
        }
      }
    } else {
      alert('Contract is in status: ' + lblStatus + '. Cannot rescan.');
      return;
    }

    let buyingpointId = getValue(thisObj, 'ddLocation');
    commonContext.setContractProfile_ContractNumber(contractNumber);
    commonContext.setContractProfile_BuyingPointId(buyingpointId);
    commonContext.setContractProfile_ButtonEventName('Rescan');
    goTo(thisObj, "ContractManagement#ContractScan#DEFAULT#true#Click");
    return true;
  };
  thisObj.onbtnRescanClick = onbtnRescanClick;
  // btnRescan Click

  // btnSing Click
  const onbtnSignClick = async () => {
    let sStatus;
    let bSuccessScan;
    let bSignedWithOutPDF;
    let bSkipScan;
    let sFTPFilename = '';

    let pnuttypeid = getValue(thisObj, 'ddPeanutType');
    let peanutType = await ContractManagementService.RetrievePeanutTypeControls(pnuttypeid);
    bFormValid(peanutType);
    if (!isFormValid) {
      return;
    }

    bSkipScan = false;

    if (process.env.REACT_APP_ENVIR == "TEST" || process.env.REACT_APP_ENVIR == "LOCAL" || process.env.REACT_APP_ENVIR == "DEV" || process.env.REACT_APP_ENVIR == 'UAT') {
      if (confirm("Do you want to skip the scanning process and just sign? Click on OK to proceed else click on Cancel. You are in " + process.env.REACT_APP_ENVIR) == true) {
        bSkipScan = true;
        bSuccessScan = true;
        bSignedWithOutPDF = true;
      }
    }

    let ppsDefault = await ContractManagementService.RetrievePeanutStaticValues();
    if (ppsDefault !== undefined && ppsDefault.length > 0) {
      let key = `${compId}` + '_default_overrides';
      if (ppsDefault[0].pps_defaults.hasOwnProperty(key) == true) {
        let value = Object.getOwnPropertyDescriptor(ppsDefault[0].pps_defaults, key).value;
        if (value.contract_scan_sw == 'OFF') {
          bSkipScan = true;
          bSuccessScan = true;
          bSignedWithOutPDF = true;
        }
      }
    }

    if (bSkipScan == false) {
      let contractNumber = getValue(thisObj, 'txtContrctNo');
      let buyingpointId = getValue(thisObj, 'ddLocation');
      commonContext.setContractProfile_ContractNumber(contractNumber);
      commonContext.setContractProfile_BuyingPointId(buyingpointId);
      commonContext.setContractProfile_ButtonEventName('Sign');
      goTo(thisObj, "ContractManagement#ContractScan#DEFAULT#true#Click");
      return true;
    }

    if (bSuccessScan) {
      sStatus = "S";

      if (bSignedWithOutPDF) {
        msUploadStatus = "";
      } else {
        msUploadStatus = "P";
      }

      if (process.env.REACT_APP_ENVIR == "TEST" || process.env.REACT_APP_ENVIR == "LOCAL" || process.env.REACT_APP_ENVIR == "DEV" || process.env.REACT_APP_ENVIR == 'UAT') {
        msUploadStatus = "C";
      }

      if (document.getElementById('chckbxAlwRescn').checked) {
        setValue(thisObj, "chckbxAlwRescn", false);
      }

      let result = await bSaveContract("S", "N", msUploadStatus, null);
      if (result == true) {
        setData(thisObj, "reload", true)
        goTo(thisObj, "ContractManagement#ContractInquiry#DEFAULT#false#Click");
        return true;
      }
    } else {
      showMessage(thisObj, 'Contract was not scanned successfully.  Contract is not signed.')
    }
  };
  thisObj.onbtnSignClick = onbtnSignClick;
  // btnRescan Click

  // btnApprove Click
  const onbtnApprvClick = async () => {
    let sStatus;
    let sSendEmail;
    let bSkipScan;
    let contractNumber = getValue(thisObj, 'txtContrctNo');
    let sOldUploadStatus;
    let sEmail;

    sSendEmail = "N";
    let pnuttypeid = getValue(thisObj, 'ddPeanutType');
    let peanutType = await ContractManagementService.RetrievePeanutTypeControls(pnuttypeid);
    bFormValid(peanutType);
    if (!isFormValid) {
      return;
    }

    if (sessionStorage.getItem('year') >= '2012') {
      let lnkMinDisclsr = document.getElementsByClassName('lnkMinDisclsr')[0].childNodes[0].childNodes[0].innerText;
      if (lnkMinDisclsr !== 'Completed') {
        if (contractNumber !== undefined || contractNumber !== null || contractNumber !== '') {
          commonContext.setContractProfile_ContractNumber(contractNumber);
          commonContext.setContractProfile_ButtonEventName('Approve');

          let lnkMinDisclsr = document.getElementsByClassName('lnkMinDisclsr')[0].childNodes[0].childNodes[0].innerText;
          commonContext.setContractProfile_MinorityStatus(lnkMinDisclsr);

          let vendorId = getValue(thisObj, 'txtVndr');
          commonContext.setContractProfile_VendorId(vendorId);

          let vendorName = getValue(thisObj, 'txtName');
          commonContext.setContractProfile_VendorName(vendorName);

          if (document.getElementById('btnRescan').disabled == true) {
            commonContext.setContractProfile_BtnRescanStatus(false);
          } else {
            commonContext.setContractProfile_BtnRescanStatus(true);
          }
        }
        goTo(thisObj, "ContractManagement#VendorDisclosure#DEFAULT#true#Click");
        return true;
      }
    }

    bSkipScan = false;

    if (process.env.REACT_APP_ENVIR == "TEST" || process.env.REACT_APP_ENVIR == "LOCAL" || process.env.REACT_APP_ENVIR == "DEV" || process.env.REACT_APP_ENVIR == 'UAT') {
      if (confirm("Do you want to skip the signature process and just approve? Click on OK to proceed else click on Cancel. You are in " + process.env.REACT_APP_ENVIR) == true) {
        bSkipScan = true;
      }
    }

    let ppsDefault = await ContractManagementService.RetrievePeanutStaticValues();
    if (ppsDefault !== undefined && ppsDefault.length > 0) {
      let key = `${compId}` + '_default_overrides';
      if (ppsDefault[0].pps_defaults.hasOwnProperty(key) == true) {
        let value = Object.getOwnPropertyDescriptor(ppsDefault[0].pps_defaults, key).value;
        if (value.contract_scan_sw == 'OFF') {
          bSkipScan = true;
        }
      }
    }

    if (bSkipScan) {
      sStatus = "A";
      msUploadStatus = "M";
      if (process.env.REACT_APP_ENVIR == "TEST" || process.env.REACT_APP_ENVIR == "LOCAL" || process.env.REACT_APP_ENVIR == "DEV" || process.env.REACT_APP_ENVIR == 'UAT') {
        msUploadStatus = "C";
      }
      let result = await bSaveContract("A", sSendEmail, msUploadStatus);
      if (result) {
        setData(thisObj, "reload", true)
        goTo(thisObj, "ContractManagement#ContractInquiry#DEFAULT#false#Click");
        return true;
      }
    }

    let contractDetails = await ContractManagementService.RetrieveContractDetails(contractNumber, 'PN1010');
    if (contractDetails !== null) {
      msUploadStatus = contractDetails[0].upload_status;
      msApprovedUser = contractDetails[0].approved_user;
    } else {
      alert('Problem retrieving contract status.');
      return false;
    }

    if (msUploadStatus == "") {
      alert("Cannot find signed PDF contract. Please scan in signed contract before it can be approved?");
      return false;
    }

    if (msUploadStatus == "I") {
      alert("Invalid file was uploaded. Please rescan the signed contract before it can be approved?");
      return false;
    }

    if (msUploadStatus !== "C") {
      alert("Contract has not been processed.  Try again in about 5 minutes?");
      return false;
    }

    let userSignature = await ContractManagementService.RetrieveUserSignatureDetails(sessionStorage.getItem('userid'));
    if (userSignature.length == 0) {
      alert("Cannot find your approval signature.  Use Signature Setup to setup your information.");
      return false;
    }
    if (userSignature[0].user_id.trim() !== sessionStorage.getItem('userid')) {
      alert('Cannot find your approval signature.  Use Signature Setup to setup your information.');
      return false;
    } else {
      if (userSignature[0].name == '' || userSignature[0].title == '' || userSignature[0].image == '') {
        alert('To approve contracts, you must have name, title, and signature.  Use Signature Setup to setup your information.');
        return false;
      }
    }

    if (confirm('Do you want to email approved contract to producer? Click on OK to proceed else click on Cancel.') == true) {
      sSendEmail = "Y";
      if (userSignature.length == 0) {
        alert("Cannot find your email to use as the sender.");
        return false;
      }
      if (userSignature[0].user_email == "") {
        alert('Cannot find your email to use as the sender.');
        sSendEmail = "N";
        return false;
      }

      let vendor = getValue(thisObj, 'txtVndr');
      let vendorDetails = await ContractManagementService.RetrieveVendorEmailDetails(null, null, null, vendor, null, null);
      let emaillist = vendorDetails[0];
      if (emaillist.vendorEmail1 !== '') {
        sEmail = true;
      } else if (emaillist.vendorEmail2 !== '') {
        sEmail = true;
      } else if (emaillist.vendorEmail3 !== '') {
        sEmail = true;
      } else {
        alert('Cannot find producers email address.  Contract will not be sent to producer.');
        sSendEmail = "N";
      }
      msUploadStatus = 'M';
      if (bSkipScan) {
        if (process.env.REACT_APP_ENVIR == "TEST" || process.env.REACT_APP_ENVIR == "LOCAL" || process.env.REACT_APP_ENVIR == "DEV" || process.env.REACT_APP_ENVIR == 'UAT') {
          msUploadStatus = "C";
        }
      }
      let result = await bSaveContract('A', sSendEmail, msUploadStatus);
      if (result) {
        setData(thisObj, "reload", true)
        goTo(thisObj, "ContractManagement#ContractInquiry#DEFAULT#false#Click");
        return true;
      }
    } else {
      sSendEmail = "N";
      msUploadStatus = 'M';
      if (bSkipScan) {
        if (process.env.REACT_APP_ENVIR == "TEST" || process.env.REACT_APP_ENVIR == "LOCAL" || process.env.REACT_APP_ENVIR == "DEV" || process.env.REACT_APP_ENVIR == 'UAT') {
          msUploadStatus = "C";
        }
      }
      let result = await bSaveContract('A', sSendEmail, msUploadStatus);
      if (result) {
        setData(thisObj, "reload", true)
        goTo(thisObj, "ContractManagement#ContractInquiry#DEFAULT#false#Click");
        return true;
      }
    }
  };
  thisObj.onbtnApprvClick = onbtnApprvClick;
  // btnApprove Click

  // btnVoid Click
  const onbtnVoidClick = async () => {
    let sStatus;

    if (confirm("Are you sure you want Void this Contract? Click on OK to proceed else click on Cancel.") == true) {
      let pnuttypeid = getValue(thisObj, 'ddPeanutType');
      let peanutType = await ContractManagementService.RetrievePeanutTypeControls(pnuttypeid);
      bFormValid(peanutType);
      if (!isFormValid) {
        return;
      }
      sStatus = "V";
      let result = await bSaveContract("V", null, null, null);
      if (result) {
        setData(thisObj, "reload", true)
        goTo(thisObj, "ContractManagement#ContractInquiry#DEFAULT#false#Click");
        return true;
      }
    }
  };
  thisObj.onbtnVoidClick = onbtnVoidClick;
  // btnVoid Click

  // btnVoid Click
  const onbtnDeactivateClick = async () => {
    if (confirm("Doing This Will Closeout This Contract!!! Are you sure you want to do this? Click on OK to proceed else click on Cancel.") == true) {
      let pnuttypeid = getValue(thisObj, 'ddPeanutType');
      let peanutType = await ContractManagementService.RetrievePeanutTypeControls(pnuttypeid);
      bFormValid(peanutType);
      if (!isFormValid) {
        return;
      }
      let result = await bSaveContract('C', null, null, null);
      if (result) {
        setData(thisObj, "reload", true)
        goTo(thisObj, "ContractManagement#ContractInquiry#DEFAULT#false#Click");
        return true;
      }
    }
  };
  thisObj.onbtnDeactivateClick = onbtnDeactivateClick;
  // btnVoid Click

  // btnPrint Click
  const onbtnPrintClick = async () => {
    if (formDirty) {
      let boolval = window.confirm("Changes have not been saved!  Do you want to continue without saving?")
      if (boolval == false) {
        return false;
      }
    }
    let contract_num = getValue(thisObj, 'txtContrctNo')
    commonContext.setContractProfile_ContractNumber(contract_num);
    commonContext.setFlexMktPrint_PriceLine("2");
    commonContext.setFlexMktPrint_PriceSuffix("A");
    commonContext.setFlexMktPrint_PrintType("CONTRACT");

    Print(contract_num);
  };
  thisObj.onbtnPrintClick = onbtnPrintClick;
  // btnPrint Click

  // btnPymnt Click
  const onbtnPymntClick = async () => {
    let txtContractNumber = getValue(thisObj, 'txtContrctNo');
    if (txtContractNumber == undefined || txtContractNumber == null || txtContractNumber == '') {
      alert("Contract has not yet been created!!!");
      return false;
    } else {
      let buyingPointId = getValue(thisObj, 'ddLocation');
      commonContext.setContractProfile_ContractNumber(txtContractNumber);
      commonContext.setContractProfile_BuyingPointId(buyingPointId);
      let js = {
        buy_point_id: buyingPointId,
        audit_cont_num: txtContractNumber,
        Function_Name: 'Contract',
      }
      setData(thisObj, 'ReviewPaymentsFormLoadData', js)
      goTo(thisObj, "ContractManagement#ViewPayments#DEFAULT#true#Click");
      return true;
    }
  };
  thisObj.onbtnPymntClick = onbtnPymntClick;
  // btnPymnt Click

  // btnAccDist Click
  const onbtnAccDistClick = async () => {
    let txtContractNumber = getValue(thisObj, 'txtContrctNo');
    if (txtContractNumber == undefined || txtContractNumber == null || txtContractNumber == '') {
      alert("Contract has not yet been created!!!");
    } else {
      setData(thisObj, 'type', 'CONTRACT')
      let buyingPointId = getValue(thisObj, 'ddLocation');
      commonContext.setContractProfile_BuyingPointId(buyingPointId)
      commonContext.setContractProfile_ContractNumber(txtContractNumber);
      goTo(thisObj, "ContractManagement#AccountDistributionActivity#DEFAULT#true#Click");
      return true;
    }

  };
  thisObj.onbtnAccDistClick = onbtnAccDistClick;
  // btnAccDist Click

  // btnLookup Click
  const onbtnLookupClick = async () => {
    let txtGrpng = getValue(thisObj, 'txtGrpng');
    if (txtGrpng == undefined || txtGrpng == null || txtGrpng == '') {
      return;
    }
    setData(thisObj, "GroupingNumber", txtGrpng)
    commonContext.setContractProfile_ContractGroupingNumber(txtGrpng);
  };
  thisObj.onbtnLookupClick = onbtnLookupClick;
  // btnLookup Click

  // chckbxAlwRescn Click
  const onchckbxAlwRescnChange = async () => {
    if (document.getElementById('chckbxAlwRescn').checked == true)
      alert("Allowing rescan will set contract status back to Pending");
  };
  thisObj.onchckbxAlwRescnChange = onchckbxAlwRescnChange;
  // chckbxAlwRescn Click

  // lnkMinDisclsr Click
  const onlnkMinDisclsrClick = async () => {
    let txtContractNumber = getValue(thisObj, 'txtContrctNo');
    if (txtContractNumber !== undefined || txtContractNumber !== null || txtContractNumber !== '') {
      commonContext.setContractProfile_ButtonEventName('MinorityDisclosure');
      commonContext.setContractProfile_ContractNumber(txtContractNumber);

      let lnkMinDisclsr = document.getElementsByClassName('lnkMinDisclsr')[0].childNodes[0].childNodes[0].innerText;
      commonContext.setContractProfile_MinorityStatus(lnkMinDisclsr);

      let vendorId = getValue(thisObj, 'txtVndr');
      commonContext.setContractProfile_VendorId(vendorId);

      let vendorName = getValue(thisObj, 'txtName');
      commonContext.setContractProfile_VendorName(vendorName);

      if (document.getElementById('btnRescan').disabled == true) {
        commonContext.setContractProfile_BtnRescanStatus(false);
      } else {
        commonContext.setContractProfile_BtnRescanStatus(true);
      }
      goTo(thisObj, 'ContractManagement#VendorDisclosure#DEFAULT#true#Click');
      return true;

    }
  };
  thisObj.onlnkMinDisclsrClick = onlnkMinDisclsrClick;
  // lnkMinDisclsr Click

  // btnFlxMktPcngDet Click
  const onbtnFlxMktPcngDetClick = () => {
    let txtContractNumber = getValue(thisObj, 'txtContrctNo');
    let type = getValue(thisObj, 'ddType');
    let txtHarvest = getValue(thisObj, 'dtHrvstCmplt');
    if (txtContractNumber !== undefined || txtContractNumber !== null || txtContractNumber !== '') {
      commonContext.setContractProfile_ContractNumber(txtContractNumber);
      commonContext.setContractProfile_ContractType(type);
      commonContext.setContractProfile_HarvestDate(txtHarvest);
      goTo(thisObj, 'ContractManagement#FlexibleMarketPricings#DEFAULT#true#Click');
      return true;
    }
  };
  thisObj.onbtnFlxMktPcngDetClick = onbtnFlxMktPcngDetClick;
  // btnFlxMktPcngDet Click

  // btnFlxMktWrkSht Click
  const onbtnFlxMktWrkShtClick = () => {
    let type = getValue(thisObj, 'ddType');
    commonContext.setContractProfile_ContractType(type);
    commonContext.setFlexMktPrint_PrintType('WORKSHEET');
    return true;
  };
  thisObj.onbtnFlxMktWrkShtClick = onbtnFlxMktWrkShtClick;
  // btnFlxMktWrkSht Click

  // btnViewUpldCntrct Click
  const onbtnViewUpldCntrctClick = async () => {
    let contractNumber = getValue(thisObj, 'txtContrctNo');
    let contractDetails = await ContractManagementService.RetrieveContractDetails(contractNumber, 'PN1010');
    if (contractDetails !== null) {
      msUploadStatus = contractDetails[0].upload_status;
      msApprovedUser = contractDetails[0].approved_user;
    } else {
      alert('Problem retrieving contract status.');
      return;
    }

    if (msUploadStatus == "I") {
      alert("Invalid file was uploaded. Please rescan the signed contract before it can be approved?");
      return;
    }

    if (msUploadStatus !== "C") {
      alert("Contract has not been processed. Try again in about 5 minutes?");
      return;
    }

    ContractManagementService.RetieveContractUploadFileDetails(contractNumber).then(fileData => {
      if (fileData.length !== 0) {
        let createurl = null;
        let uplodedfilename = fileData.uploadFileName;
        let filepath = fileData.filePath;
        if ((filepath !== null && filepath !== undefined) && (uplodedfilename !== null && uplodedfilename !== undefined)) {
          if (process.env.REACT_APP_ENVIR == 'LOCAL' || process.env.REACT_APP_ENVIR == 'DEV' || process.env.REACT_APP_ENVIR == 'TEST' || process.env.REACT_APP_ENVIR == 'UAT') {
            createurl = (`https://testxml.pps.e-adm.com/pps/contracts/${uplodedfilename}`);
          } else {
            createurl = (`https://xml.pps.e-adm.com/pps/contracts/${uplodedfilename}`);
          }
          window.open(createurl);
        }
      } else {
        alert('Cannot find an uploaded contract file.');
        return;
      }
    });
  };
  thisObj.onbtnViewUpldCntrctClick = onbtnViewUpldCntrctClick;
  // btnViewUpldCntrct Click


  // btnClrAdvnc Click
  const onbtnClrAdvncClick = async () => {
    if (confirm("Are you sure you want to do clear the uncollected portion of the contract signing advance? Click on OK to proceed else click on Cancel.") == true) {
      let contractNumber = getValue(thisObj, 'txtContrctNo');
      let clearAdvance = await ContractManagementService.UpdateClearContractAdvanceDetails(contractNumber);
      if (clearAdvance !== null) {
        let collectionAdvance = getValue(thisObj, 'txtCllcAdv');
        setValue(thisObj, 'txtSignAdv', collectionAdvance !== undefined ? collectionAdvance : '');
        hide(thisObj, 'btnClrAdvnc');
      }
    } else {
      return false;
    }
  };
  thisObj.onbtnClrAdvncClick = onbtnClrAdvncClick;

  // btnClrAdvnc Click

  // btnSeedGrwrAgreemnt Click
  const onbtnSeedGrwrAgreemntClick = async () => {
    let contractNumber = getValue(thisObj, 'txtContrctNo');
    if (contractNumber !== undefined && contractNumber !== null && contractNumber !== '') {
      commonContext.setContractProfile_ContractNumber(contractNumber);
      // setData(thisObj, 'AddendumSeedGrower', cmdSeedGrower);
      // setData(thisObj, "msseedTBD", msSeedTBD);
    }

    if ((cmdSeedGrower == '' || cmdSeedGrower == null) && msSeedTBD !== 'Y') {
      document.getElementById("btnSeedGrwrAgreemnt").style.backgroundImage = 'linear-gradient(white, #e9ecef 60%, #dde2e6)';
      document.getElementById("btnSeedGrwrAgreemnt").style.backgroundColor = "#d3d9df";
      setData(thisObj, 'seedArg', 'Y')
    }
    else {
      document.getElementById("btnSeedGrwrAgreemnt").style.backgroundImage = "none";
      document.getElementById("btnSeedGrwrAgreemnt").style.backgroundColor = "#AFDBC9";
      setData(thisObj, 'seedArg', 'N')
    }

    let data12 = [];
    let obj = {};
    if (thisObj.values.ddPeanutType == undefined || thisObj.values.ddPeanutType == '' || thisObj.values.ddPeanutType == null) {
      obj.peanutTypeId = "RU";
    } else {
      obj.peanutTypeId = thisObj.values.ddPeanutType;
    }
    obj.txtContrctNo = contractNumber
    obj.txtPounds = getValue(thisObj, 'txtPounds');
    obj.companyname = sessionStorage.getItem('Companyname');

    obj.buy_pt_id = thisObj.values.ddLocation;
    obj.seg = thisObj.values.ddSegType;
    obj.organicInd = document.getElementById('chckbxOrgnc').checked == true ? 'Y' : 'N';
    obj.pnutvarietyid = thisObj.values.ddPeaNtVrty;
    obj.oleic_ind = thisObj.values.ddOleic;
    obj.vendorname = getValue(thisObj, 'txtName');
    obj.vid = getValue(thisObj, 'txtVndr');
    obj.pricingtype = thisObj.values.ddType;
    data12.push(obj);
    let lblStatus = getValue(thisObj, 'txtStatus');
    // Security Update After Signed Value
    // let securityUpdateAfterSigned = await ContractManagementService.RetrieveAccessPermissionDetails('PN1010', '002', 'U', null);
    // if (securityUpdateAfterSigned[0].permission == 'N') {
    //   mbSecurityUpdateAfterSigned = false;
    // } else {
    //   mbSecurityUpdateAfterSigned = true;
    // }
    // Security Update After Signed Value
    let mbSecurityUpdateAfterSigned = localStorage.getItem('SecurityUpdateAfterSigned') == 'true' ? true : false;
    // let mbApplicationsExist = localStorage.getItem('ApplicationsExist') == 'true' ? true : false;
    if (!mbSecurityUpdateAfterSigned && lblStatus !== 'Pending' && lblStatus !== "") {
      setData(thisObj, 'ReadOnly', true);
    } else {
      setData(thisObj, 'ReadOnly', false);
    }
    // setData(thisObj, 'mbSecurityUpdateAfterSigned', mbSecurityUpdateAfterSigned)
    // setData(thisObj, 'mbApplicationsExist', mbApplicationsExist);
    setData(thisObj, 'seeddata', data12);
    goTo(thisObj, 'ContractManagement#AddendumSeedGrower2009#DEFAULT#true#Click');
    return true;
  };
  thisObj.onbtnSeedGrwrAgreemntClick = onbtnSeedGrwrAgreemntClick;
  // btnSeedGrwrAgreemnt Click

  // txtbxMin Click
//INC5074771
  const ontxtbxMinChange = async (event) => {
    if (!event.target.value.match(/[0-9]/)) {
      setValue(thisObj, 'txtbxMin', event.target.value.replace(/[^0-9.]/g, ''));
    }
  };
  thisObj.ontxtbxMinChange = ontxtbxMinChange;
  // txtbxMin Click

  // txtbxMax Click
//INC5074771
  const ontxtbxMaxChange = async (event) => {
    if (!event.target.value.match(/[0-9]/)) {
      setValue(thisObj, 'txtbxMax', event.target.value.replace(/[^0-9.]/g, ''));
    }
  };
  thisObj.ontxtbxMaxChange = ontxtbxMaxChange;
  // txtbxMax Click

  // btnExit Click
  const onbtnExitClick = async () => {
    if (formDirty) {
      let boolval = window.confirm("Changes have not been saved!  Do you want to continue without saving?")
      if (boolval == false) {
        return false;
      }
    }
    let contractNumber = getValue(thisObj, 'txtContrctNo');
    if (contractNumber !== undefined) {
      goTo(thisObj, "ContractManagement#ContractInquiry#DEFAULT#false#Click");
      return true;
    } else {
      history.push('/');
    }
  }
  thisObj.onbtnExitClick = onbtnExitClick;
  // btnExit Click

  const onbtnLookupFrmClick = () => {
    try {
      let js = [];
      let obj = {}
      obj.lblContractNumber = commonContext.getContractProfile_ContractNumber
      obj.pricingLine = 1
      //obj.lblVendor =getValue(thisObj, "txtVendor")
      obj.lblVendor = null
      js.push(obj)
      obj = {}
      //setData(thisObj,'bFillPounds',js)
      commonContext.setbFillPounds(js)
      goTo(thisObj, 'ContractManagement#ApplicationToContract#DEFAULT#true#Click');
      return true;
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error on Lookup button click"
        );
      }
      return false;
    }
  };
  thisObj.onbtnLookupFrmClick = onbtnLookupFrmClick;

  const onbtnVndrLookupFrm2Click = () => {
    try {
      let js = [];
      let obj = {};
      obj.lblContractNumber = commonContext.getContractProfile_ContractNumber;
      obj.pricingLine = 2;
      obj.lblVendor = null;
      js.push(obj);
      obj = {};
      commonContext.setbFillPounds(js);
      goTo(thisObj, 'ContractManagement#ApplicationToContract#DEFAULT#true#Click');
      return true;
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error on  second Lookup button click");
      }
      return false;
    }
  };
  thisObj.onbtnVndrLookupFrm2Click = onbtnVndrLookupFrm2Click;

  // Price Change
  const onddPriceChange = () => {
    let basisPriceValue = getValue(thisObj, 'ddPrice');
    let type = getValue(thisObj, 'ddType');
    if (basisPriceValue !== undefined && basisPriceValue !== null && basisPriceValue !== '') {
      if (useTerm !== '' && (type == 'L' || type == 'M')) {
        document.getElementsByClassName("lblFlexMarketPricing")[0].style.visibility = "visible";
        document.getElementsByClassName("lblMktPrcng")[0].style.visibility = "visible";
        if (type == 'L') {
          document.getElementsByClassName('lblFlexMarketPricing')[0].childNodes[0].childNodes[0].innerText = 'Floor Pricings:';
        } else if (type == 'M') {
          document.getElementsByClassName('lblFlexMarketPricing')[0].childNodes[0].childNodes[0].innerText = 'Flex Market Pricings:';
        }
        setValue(thisObj, 'lblMktPrcng', useTerm);
      }
      if (useMatchEndDate !== '' && (type == 'L' || type == 'M')) {
        document.getElementsByClassName("dtMtchEndDate")[0].style.visibility = "visible";
        setValue(thisObj, 'dtMtchEndDate', useMatchEndDate);
      }
    }
  };
  thisObj.onddPriceChange = onddPriceChange;
  // Price Change

  // txtVndr Change
  const ontxtVndrBlur = () => {
    let vendorNumber = getValue(thisObj, 'txtVndr');
//INC5074771
    // if (vendorNumber !== null && vendorNumber !== '' && vendorNumber !== undefined) {
    //   if (!mbHaveVendorName) {
    //     setValue(thisObj, "txtVndr", vendorNumber);
    //     setData(thisObj, 'ContractProfile_VendorNumber', vendorNumber);
    //   }
    if (vendorNumber !== null && vendorNumber !== '' && vendorNumber !== undefined) {
      if (!mbHaveVendorName) {
        const uppercaseVendorNumber = vendorNumber.toUpperCase();
        setValue(thisObj, "txtVndr", uppercaseVendorNumber);
        setData(thisObj, 'ContractProfile_VendorNumber', uppercaseVendorNumber);
      }

      setValue(thisObj, "txtEmail", '');
      document.getElementsByClassName('lnkMinDisclsr')[0].childNodes[0].childNodes[0].innerText = '';
      setValue(thisObj, "txt211Gpc", '');
      setValue(thisObj, "txt211concordia", '');

      ContractManagementService.RetieveRemitDetails(vendorNumber).then(response => {
        let data = response;
        if (data.length > 0) {
          let js = [];
          for (var i = 0; i < data.length; i++) {
            let description =
              data[i].remittoid + '-' +
              data[i].name + '-' +
              data[i].address1 + '' +
              (data[i].address2 == null ? '' : data[i].address2) +
              (data[i].address3 == null ? '' : data[i].address3) +
              (data[i].address4 == null ? '' : data[i].address4) + ' ' +
              data[i].city + ',' +
              data[i].state + ' ' +
              data[i].zip;
            let obj = { key: data[i].remittoid, description: description };
            js.push(obj);
          }
          thisObj.setState(current => {
            return {
              ...current,
              ddRemit: {
                ...state["ddRemit"],
                valueList: js
              },
            }
          });
          let selectedData = data.find(o => o.remittoid == "000");
          if (selectedData !== null) {
            setValue(thisObj, "ddRemit", selectedData.remittoid);
            setValue(thisObj, "txtName", selectedData.name);
            setValue(thisObj, "txtEmail", selectedData.email1 + ' ' + selectedData.email2 + ' ' + selectedData.email3);
            setValue(thisObj, "txt211Gpc", selectedData.golden_form_211);
            setValue(thisObj, "txt211concordia", selectedData.concordia_form_211);
            document.getElementsByClassName('lnkMinDisclsr')[0].childNodes[0].childNodes[0].innerText = selectedData.minority_disclosure;
          }
        }
      });
      showhideAddendums(vendorNumber);
    } else {
      setValue(thisObj, "txtName", '');
      setValue(thisObj, "txtEmail", '');
      document.getElementsByClassName('lnkMinDisclsr')[0].childNodes[0].childNodes[0].innerText = '';
      setValue(thisObj, "txt211Gpc", '');
      setValue(thisObj, "txt211concordia", '');
      setValue(thisObj, "txtShare", 100);
      let js = [];
      thisObj.setState(current => {
        return {
          ...current,
          ddRemit: {
            ...state["ddRemit"],
            valueList: js
          },
        }
      });
    }
    
    let type = getValue(thisObj, 'ddType');
    let pnuttypeid = getValue(thisObj, 'ddPeanutType');
    let segtypeid = getValue(thisObj, 'ddSegType');
    let organicInd = document.getElementById('chckbxOrgnc').checked == true ? 'Y' : 'N';
    let contractnum = getValue(thisObj, 'txtContrctNo');
    let pnutvarietyid = getValue(thisObj, 'ddPeaNtVrty');
    let buyingpointId = getValue(thisObj, 'ddLocation');
    let oleicid = getValue(thisObj, 'ddOleic');

    GetFirmPriceList(pnuttypeid, segtypeid, organicInd, buyingpointId, contractnum, pnutvarietyid, oleicid, vendorNumber);
    GetPriceList(type, pnuttypeid, segtypeid, organicInd, buyingpointId, contractnum, pnutvarietyid, oleicid, vendorNumber);
  
  };
  thisObj.ontxtVndrBlur = ontxtVndrBlur;
  // txtVndr Change

  // txtPounds2 Change
  const ontxtPounds2Change = (event) => {
    let txtBasisPounds = getValue(thisObj, 'txtPounds2') == undefined ? 0 : getValue(thisObj, 'txtPounds2');
    if (Number(txtBasisPounds) > 0) {
      let txtapp2 = getValue(thisObj, 'txtApp2') == undefined ? 0 : getValue(thisObj, 'txtApp2');
      let txtTntApp2 = getValue(thisObj, 'txtTntApp2') == undefined ? 0 : getValue(thisObj, 'txtTntApp2');
//      setValue(thisObj, 'txtTotlApp2', Number(txtapp2 + txtTntApp2).toString());
      let txtTotlApp2 = getValue(thisObj, 'txtTotlApp2') == undefined ? 0 : getValue(thisObj, 'txtTotlApp2');
      setValue(thisObj, 'txtTntOpen2', Number(txtBasisPounds - txtTotlApp2).toString());
    } else {
      setValue(thisObj, 'txtTotlApp2', '');
      setValue(thisObj, 'txtTntOpen2', '');
    }
  }
  thisObj.ontxtPounds2Change = ontxtPounds2Change;
  // txtPounds2 Change

  // txtPounds Change
  const ontxtPoundsChange = (event) => {
    let txtFirmPounds = getValue(thisObj, 'txtPounds') == undefined ? 0 : getValue(thisObj, 'txtPounds');
    if (Number(txtFirmPounds) > 0) {
      let txtApplied = getValue(thisObj, 'txtApplied') == undefined ? 0 : getValue(thisObj, 'txtApplied');
      let txtTntAppld = getValue(thisObj, 'txtTntAppld') == undefined ? 0 : getValue(thisObj, 'txtTntAppld');
      setValue(thisObj, 'txtTotlApp', Number(txtApplied + txtTntAppld).toString());

      let txtTotlApp = getValue(thisObj, 'txtTotlApp') == undefined ? 0 : getValue(thisObj, 'txtTotlApp');
      setValue(thisObj, 'txtTntOpen', Number(txtFirmPounds - txtTotlApp).toString());
    } else {
      setValue(thisObj, 'txtTotlApp', '');
      setValue(thisObj, 'txtTntOpen', '');
    }
  }
  thisObj.ontxtPoundsChange = ontxtPoundsChange;
  // txtPounds Change

  // dtStrt2 Change
  // const ondtStrt2Change = (event) => {
  //   let startDate = getValue(thisObj, 'dtStrt2');
  //   let endDate = getValue(thisObj, 'dtEnd2');
  //   if (new Date(startDate) > new Date(endDate)) {
  //     alert('Basis Start Date should be less than Basis End Date');
  //     setValue(thisObj, 'dtStrt2', '');
  //   }
  // };
  // thisObj.ondtStrt2Change = ondtStrt2Change;
  // // dtStrt2 Change

  // // dtEnd2 Change
  // const ondtEnd2Change = (event) => {
  //   let startDate = getValue(thisObj, 'dtStrt2');
  //   let endDate = getValue(thisObj, 'dtEnd2');
  //   if (new Date(startDate) > new Date(endDate)) {
  //     alert('Basis End Date should be greater than Basis Start Date');
  //     setValue(thisObj, 'dtEnd2', '');
  //   }
  // };
  // thisObj.ondtEnd2Change = ondtEnd2Change;
  // // dtEnd2 Change

  // // dtStart Change
  // const ondtStartChange = (event) => {
  //   let startDate = getValue(thisObj, 'dtStart');
  //   let endDate = getValue(thisObj, 'dtEnd');
  //   if (new Date(startDate) > new Date(endDate)) {
  //     alert('Firm Start Date should be less than Firm End Date');
  //     setValue(thisObj, 'dtStart', '');
  //   }
  // };
  // thisObj.ondtStartChange = ondtStartChange;
  // // dtStart Change

  // // dtEnd Change
  // const ondtEndChange = (event) => {
  //   let startDate = getValue(thisObj, 'dtStart');
  //   let endDate = getValue(thisObj, 'dtEnd');
  //   if (new Date(startDate) > new Date(endDate)) {
  //     alert('Firm End Date should be greater than Firm Start Date');
  //     setValue(thisObj, 'dtEnd', '');
  //   }
  // };
  // thisObj.ondtEndChange = ondtEndChange;
  // dtEnd Change

  // txtSignAdv Change
  const ontxtSignAdvBlur = (event) => {
    let txtSignAdv = getValue(thisObj, 'txtSignAdv');
    if (txtSignAdv.length == 6) {
      setValue(thisObj, 'txtSignAdv', Number(txtSignAdv).toLocaleString().substring(0, 6));
    } else if (txtSignAdv.length == 5) {
      setValue(thisObj, 'txtSignAdv', Number(txtSignAdv).toLocaleString());
    } else if (txtSignAdv.length == 4) {
      setValue(thisObj, 'txtSignAdv', Number(txtSignAdv).toLocaleString() + '.');
    } else {
      txtSignAdv = Number(txtSignAdv).toFixed(2);
      setValue(thisObj, 'txtSignAdv', Number(txtSignAdv).toFixed(2));
    }
  };
  thisObj.ontxtSignAdvBlur = ontxtSignAdvBlur;
  // txtSignAdv Change

  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ContractProfile*/}

              {/* END_USER_CODE-USER_BEFORE_ContractProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxContrctControl*/}
              <GroupBoxWidget
                      conf={state.grpbxContrctFarm}
                      screenConf={state} >
              {/* END_USER_CODE-USER_BEFORE_grpbxContrctControl*/}

              <GroupBoxWidget
                conf={state.grpbxContrctControl}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_contrctControl*/}

                {/* END_USER_CODE-USER_BEFORE_contrctControl*/}

                <LabelWidget
                  values={values}
                  conf={state.contrctControl}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_contrctControl*/}

                {/* END_USER_CODE-USER_AFTER_contrctControl*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxContrctCntrl01*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxContrctCntrl01*/}

                <GroupBoxWidget
                  conf={state.grpbxContrctCntrl01}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_date*/}

                  {/* END_USER_CODE-USER_BEFORE_date*/}

                  <DateWidget
                    onChange={event => {
                      setTimeout(() => {
                        setFormDirty(true)
                      }, 50),
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                    }}
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.date}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_date*/}

                  {/* END_USER_CODE-USER_AFTER_date*/}
                  {/* START_USER_CODE-USER_BEFORE_mmddyy*/}

                  {/* END_USER_CODE-USER_BEFORE_mmddyy*/}

                  <LabelWidget
                    values={values}
                    conf={state.mmddyy}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_mmddyy*/}

                  {/* END_USER_CODE-USER_AFTER_mmddyy*/}
                  {/* START_USER_CODE-USER_BEFORE_btnEnblDate*/}

                  {/* END_USER_CODE-USER_BEFORE_btnEnblDate*/}

                  <ButtonWidget
                    conf={state.btnEnblDate}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnEnblDate*/}

                  {/* END_USER_CODE-USER_AFTER_btnEnblDate*/}
                  {/* START_USER_CODE-USER_BEFORE_txtContrctNo*/}

                  {/* END_USER_CODE-USER_BEFORE_txtContrctNo*/}

                  <TextBoxWidget
                    onChange={event => {
                      setTimeout(() => {
                        setFormDirty(true)
                      }, 50),
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                    }}
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtContrctNo}
                    screenConf={state}
                    onPaste={event => {
                      setTimeout(() => {
                        setFormDirty(true)
                      }, 50),
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                    }}
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtContrctNo*/}

                  {/* END_USER_CODE-USER_AFTER_txtContrctNo*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCntrctno2*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCntrctno2*/}

                  <TextBoxWidget
                    onChange={event => {
                      setTimeout(() => {
                        setFormDirty(true)
                      }, 50),
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                    }}
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCntrctno2}
                    screenConf={state}
                    onPaste={event => {
                      setTimeout(() => {
                        setFormDirty(true)
                      }, 50),
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                    }}
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCntrctno2*/}

                  {/* END_USER_CODE-USER_AFTER_txtCntrctno2*/}
                  {/* START_USER_CODE-USER_BEFORE_ddType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddType*/}

                  <DropDownWidget
                    onChange={event => {
                      setTimeout(() => {
                        setFormDirty(true)
                      }, 50),
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                    }}
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddType*/}

                  {/* END_USER_CODE-USER_AFTER_ddType*/}
                  {/* START_USER_CODE-USER_BEFORE_ddLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_ddLocation*/}

                  <DropDownWidget
                    onChange={event => {
                      setTimeout(() => {
                        setFormDirty(true)
                      }, 50),
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                    }}
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddLocation}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddLocation*/}

                  {/* END_USER_CODE-USER_AFTER_ddLocation*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxContrctCntrl01*/}

                {/* END_USER_CODE-USER_AFTER_grpbxContrctCntrl01*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxCntrctCntrl02*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxCntrctCntrl02*/}

                <GroupBoxWidget
                  conf={state.grpbxCntrctCntrl02}
                  screenConf={state}
                >
                  <ButtonWidget
                    conf={state.btnSetpExcPrm}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_BEFORE_btnViewUpldCntrct*/}

                  {/* END_USER_CODE-USER_BEFORE_btnViewUpldCntrct*/}

                  <ButtonWidget
                    conf={state.btnViewUpldCntrct}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnViewUpldCntrct*/}

                  {/* END_USER_CODE-USER_AFTER_btnViewUpldCntrct*/}
                  <TextAreaWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtarExcPrm}
                    screenConf={state}
                  ></TextAreaWidget>
                  {/* START_USER_CODE-USER_BEFORE_chckbxAlwRescn*/}

                  {/* END_USER_CODE-USER_BEFORE_chckbxAlwRescn*/}

                  <CheckboxWidget
                    onChange={event => {
                      setTimeout(() => {
                        setFormDirty(true)
                      }, 50),
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                    }}
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chckbxAlwRescn}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chckbxAlwRescn*/}

                  {/* END_USER_CODE-USER_AFTER_chckbxAlwRescn*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxCntrctCntrl02*/}

                {/* END_USER_CODE-USER_AFTER_grpbxCntrctCntrl02*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxContrctControl*/}

              {/* END_USER_CODE-USER_AFTER_grpbxContrctControl*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxStsDates*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxStsDates*/}
            
              {/* START_USER_CODE-USER_AFTER_grpbxStsDates*/}

              {/* END_USER_CODE-USER_AFTER_grpbxStsDates*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxFarmPlntngIntn*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxFarmPlntngIntn*/}

              <GroupBoxWidget
                conf={state.grpbxFarmPlntngIntn}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblFrmPlntIntn*/}

                {/* END_USER_CODE-USER_BEFORE_lblFrmPlntIntn*/}

                <LabelWidget
                  values={values}
                  conf={state.lblFrmPlntIntn}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblFrmPlntIntn*/}

                {/* END_USER_CODE-USER_AFTER_lblFrmPlntIntn*/}
                {/* START_USER_CODE-USER_BEFORE_whtSpc03*/}

                {/* END_USER_CODE-USER_BEFORE_whtSpc03*/}

                <WhiteSpaceWidget
                  conf={state.whtSpc03}
                  screenConf={state}
                ></WhiteSpaceWidget>
                {/* START_USER_CODE-USER_AFTER_whtSpc03*/}

                {/* END_USER_CODE-USER_AFTER_whtSpc03*/}
                {/* START_USER_CODE-USER_BEFORE_lblAvgYld*/}

                {/* END_USER_CODE-USER_BEFORE_lblAvgYld*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAvgYld}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAvgYld*/}

                {/* END_USER_CODE-USER_AFTER_lblAvgYld*/}
                {/* START_USER_CODE-USER_BEFORE_lblAcres*/}

                {/* END_USER_CODE-USER_BEFORE_lblAcres*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAcres}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAcres*/}

                {/* END_USER_CODE-USER_AFTER_lblAcres*/}
                {/* START_USER_CODE-USER_BEFORE_ddSegType*/}

                {/* END_USER_CODE-USER_BEFORE_ddSegType*/}

                <DropDownWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSegType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSegType*/}

                {/* END_USER_CODE-USER_AFTER_ddSegType*/}
                {/* START_USER_CODE-USER_BEFORE_whtSpc01*/}

                {/* END_USER_CODE-USER_BEFORE_whtSpc01*/}

                <WhiteSpaceWidget
                  conf={state.whtSpc01}
                  screenConf={state}
                ></WhiteSpaceWidget>
                {/* START_USER_CODE-USER_AFTER_whtSpc01*/}

                {/* END_USER_CODE-USER_AFTER_whtSpc01*/}
                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCntrPivtAvgYld}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_BEFORE_txtCntrPivtAcrs*/}

                {/* END_USER_CODE-USER_BEFORE_txtCntrPivtAcrs*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCntrPivtAcrs}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCntrPivtAcrs*/}

                {/* END_USER_CODE-USER_AFTER_txtCntrPivtAcrs*/}
                {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                <DropDownWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_whtSpc02*/}

                {/* END_USER_CODE-USER_BEFORE_whtSpc02*/}

                {/* <WhiteSpaceWidget
                  conf={state.whtSpc02}
                  screenConf={state}
                ></WhiteSpaceWidget> */}
                {/* START_USER_CODE-USER_AFTER_whtSpc02*/}

                {/* END_USER_CODE-USER_AFTER_whtSpc02*/}
                <CheckboxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chckbxOrgnc}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_BEFORE_txtOthrAvgYld*/}

                {/* END_USER_CODE-USER_BEFORE_txtOthrAvgYld*/}


                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOthrAvgYld}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOthrAvgYld*/}

                {/* END_USER_CODE-USER_AFTER_txtOthrAvgYld*/}
                {/* START_USER_CODE-USER_BEFORE_txtOthrAcrs*/}

                {/* END_USER_CODE-USER_BEFORE_txtOthrAcrs*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOthrAcrs}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOthrAcrs*/}

                {/* END_USER_CODE-USER_AFTER_txtOthrAcrs*/}
                {/* START_USER_CODE-USER_BEFORE_ddPeaNtVrty*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeaNtVrty*/}

                <DropDownWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeaNtVrty}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeaNtVrty*/}

                {/* END_USER_CODE-USER_AFTER_ddPeaNtVrty*/}
                {/* START_USER_CODE-USER_BEFORE_chckbxOrgnc*/}

                {/* END_USER_CODE-USER_BEFORE_chckbxOrgnc*/}

                {/* <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chckbxOrgnc}
                  screenConf={state}
                ></CheckboxWidget> */}
                <CheckboxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkBxSeedGrowr}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chckbxOrgnc*/}

                {/* END_USER_CODE-USER_AFTER_chckbxOrgnc*/}
                {/* START_USER_CODE-USER_BEFORE_txtDryLndAvgYld*/}

                {/* END_USER_CODE-USER_BEFORE_txtDryLndAvgYld*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDryLndAvgYld}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDryLndAvgYld*/}

                {/* END_USER_CODE-USER_AFTER_txtDryLndAvgYld*/}
                {/* START_USER_CODE-USER_BEFORE_txtDryLndAcres*/}

                {/* END_USER_CODE-USER_BEFORE_txtDryLndAcres*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDryLndAcres}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDryLndAcres*/}

                {/* END_USER_CODE-USER_AFTER_txtDryLndAcres*/}

                <DropDownWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddOleic}
                  screenConf={state}
                ></DropDownWidget>

                <CheckboxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkSeedGrower2010}
                  screenConf={state}
                ></CheckboxWidget>

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAvgYld}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_BEFORE_txtTotlAcrs*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotlAcrs*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotlAcrs}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotlAcrs*/}

                {/* END_USER_CODE-USER_AFTER_txtTotlAcrs*/}
              </GroupBoxWidget>
              
              </GroupBoxWidget>
              <GroupBoxWidget conf={state.grpbxStsDates} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblStsDates*/}

                {/* END_USER_CODE-USER_BEFORE_lblStsDates*/}

                <LabelWidget
                  values={values}
                  conf={state.lblStsDates}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblStsDates*/}

                {/* END_USER_CODE-USER_AFTER_lblStsDates*/}
                {/* START_USER_CODE-USER_BEFORE_txtStatus*/}

                {/* END_USER_CODE-USER_BEFORE_txtStatus*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtStatus}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtStatus*/}

                {/* END_USER_CODE-USER_AFTER_txtStatus*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxGrpingNo*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxGrpingNo*/}

                <GroupBoxWidget conf={state.grpbxGrpingNo} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtGrpng*/}

                  {/* END_USER_CODE-USER_BEFORE_txtGrpng*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtGrpng}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtGrpng*/}

                  {/* END_USER_CODE-USER_AFTER_txtGrpng*/}
                  {/* START_USER_CODE-USER_BEFORE_btnLookup*/}

                  {/* END_USER_CODE-USER_BEFORE_btnLookup*/}

                  <ButtonWidget
                    conf={state.btnLookup}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>

                  {/* START_USER_CODE-USER_AFTER_btnLookup*/}

                  {/* END_USER_CODE-USER_AFTER_btnLookup*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxGrpingNo*/}

                {/* END_USER_CODE-USER_AFTER_grpbxGrpingNo*/}
                {/* START_USER_CODE-USER_BEFORE_txtEntryDt*/}

                {/* END_USER_CODE-USER_BEFORE_txtEntryDt*/}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtGrpingShare}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtEntryDt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtEntryDt*/}

                {/* END_USER_CODE-USER_AFTER_txtEntryDt*/}
                {/* START_USER_CODE-USER_BEFORE_txtDtSign*/}

                {/* END_USER_CODE-USER_BEFORE_txtDtSign*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDtSign}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDtSign*/}

                {/* END_USER_CODE-USER_AFTER_txtDtSign*/}
                {/* START_USER_CODE-USER_BEFORE_txtDtApprv*/}

                {/* END_USER_CODE-USER_BEFORE_txtDtApprv*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDtApprv}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDtApprv*/}

                {/* END_USER_CODE-USER_AFTER_txtDtApprv*/}
                {/* START_USER_CODE-USER_BEFORE_txtDtClose*/}

                {/* END_USER_CODE-USER_BEFORE_txtDtClose*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDtClose}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDtClose*/}

                {/* END_USER_CODE-USER_AFTER_txtDtClose*/}
                {/* START_USER_CODE-USER_BEFORE_txtDtVoided*/}

                {/* END_USER_CODE-USER_BEFORE_txtDtVoided*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDtVoided}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDtVoided*/}

                {/* END_USER_CODE-USER_AFTER_txtDtVoided*/}
                {/* START_USER_CODE-USER_BEFORE_txtDtCncl*/}

                {/* END_USER_CODE-USER_BEFORE_txtDtCncl*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDtCncl}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDtCncl*/}

                {/* END_USER_CODE-USER_AFTER_txtDtCncl*/}
                {/* START_USER_CODE-USER_BEFORE_dtHrvstCmplt*/}

                {/* END_USER_CODE-USER_BEFORE_dtHrvstCmplt*/}

                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.dtHrvstCmplt}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_dtHrvstCmplt*/}

                {/* END_USER_CODE-USER_AFTER_dtHrvstCmplt*/}
                {/* START_USER_CODE-USER_BEFORE_chckbxMkFrmSpcfc*/}

                {/* END_USER_CODE-USER_BEFORE_chckbxMkFrmSpcfc*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chckbxMkFrmSpcfc}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chckbxMkFrmSpcfc*/}

                {/* END_USER_CODE-USER_AFTER_chckbxMkFrmSpcfc*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxFarmPlntngIntn*/}

              {/* END_USER_CODE-USER_AFTER_grpbxFarmPlntngIntn*/}
              {/* START_USER_CODE-USER_BEFORE_grpBxVendr*/}

              {/* END_USER_CODE-USER_BEFORE_grpBxVendr*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpBxVendr} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_grpbxHdingVndr*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxHdingVndr*/}

                <LabelWidget
                  values={values}
                  conf={state.grpbxHdingVndr}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxHdingVndr*/}

                {/* END_USER_CODE-USER_AFTER_grpbxHdingVndr*/}
                {/* START_USER_CODE-USER_BEFORE_lblVndr*/}

                {/* END_USER_CODE-USER_BEFORE_lblVndr*/}

                <LabelWidget
                  values={values}
                  conf={state.lblVndr}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblVndr*/}

                {/* END_USER_CODE-USER_AFTER_lblVndr*/}
                {/* START_USER_CODE-USER_BEFORE_lblRemit*/}

                {/* END_USER_CODE-USER_BEFORE_lblRemit*/}

                <LabelWidget
                  values={values}
                  conf={state.lblRemit}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblRemit*/}

                {/* END_USER_CODE-USER_AFTER_lblRemit*/}
                {/* START_USER_CODE-USER_BEFORE_lblSignAdvnc*/}

                {/* END_USER_CODE-USER_BEFORE_lblSignAdvnc*/}

                <LabelWidget
                  values={values}
                  conf={state.lblSignAdvnc}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblSignAdvnc*/}

                {/* END_USER_CODE-USER_AFTER_lblSignAdvnc*/}
                {/* START_USER_CODE-USER_BEFORE_lblCllcAdv*/}

                {/* END_USER_CODE-USER_BEFORE_lblCllcAdv*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCllcAdv}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCllcAdv*/}

                {/* END_USER_CODE-USER_AFTER_lblCllcAdv*/}
                {/* START_USER_CODE-USER_BEFORE_ddVndr*/}

                {/* END_USER_CODE-USER_BEFORE_ddVndr*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event => invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVndr}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_ddVndr*/}

                {/* END_USER_CODE-USER_AFTER_ddVndr*/}
                {/* START_USER_CODE-USER_BEFORE_btnPopUp*/}

                {/* END_USER_CODE-USER_BEFORE_btnPopUp*/}

                <ButtonWidget
                  conf={state.btnPopUp}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPopUp*/}

                {/* END_USER_CODE-USER_AFTER_btnPopUp*/}
                {/* START_USER_CODE-USER_BEFORE_ddRemit*/}

                {/* END_USER_CODE-USER_BEFORE_ddRemit*/}

                <DropDownWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddRemit}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddRemit*/}

                {/* END_USER_CODE-USER_AFTER_ddRemit*/}
                {/* START_USER_CODE-USER_BEFORE_txtSignAdv*/}

                {/* END_USER_CODE-USER_BEFORE_txtSignAdv*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSignAdv}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSignAdv*/}

                {/* END_USER_CODE-USER_AFTER_txtSignAdv*/}
                {/* START_USER_CODE-USER_BEFORE_txtCllcAdv*/}

                {/* END_USER_CODE-USER_BEFORE_txtCllcAdv*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCllcAdv}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCllcAdv*/}

                {/* END_USER_CODE-USER_AFTER_txtCllcAdv*/}
                {/* START_USER_CODE-USER_BEFORE_txtName*/}

                {/* END_USER_CODE-USER_BEFORE_txtName*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtName}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtName*/}

                {/* END_USER_CODE-USER_AFTER_txtName*/}
                <ButtonWidget
                  conf={state.btnClrAdvnc}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }

                ></ButtonWidget>
                {/* START_USER_CODE-USER_BEFORE_txtEmail*/}

                {/* END_USER_CODE-USER_BEFORE_txtEmail*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtEmail}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtEmail*/}

                {/* END_USER_CODE-USER_AFTER_txtEmail*/}
                {/* START_USER_CODE-USER_BEFORE_txt211Gpc*/}

                {/* END_USER_CODE-USER_BEFORE_txt211Gpc*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt211Gpc}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txt211Gpc*/}

                {/* END_USER_CODE-USER_AFTER_txt211Gpc*/}
                {/* START_USER_CODE-USER_BEFORE_txt211concordia*/}

                {/* END_USER_CODE-USER_BEFORE_txt211concordia*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt211concordia}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txt211concordia*/}

                {/* END_USER_CODE-USER_AFTER_txt211concordia*/}


                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtMinDisclsr}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>

                <LinkWidget
                  conf={state.lnkMinDisclsr}
                  screenConf={state}
                  onClick={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></LinkWidget>
                {/* START_USER_CODE-USER_BEFORE_txtShare*/}

                {/* END_USER_CODE-USER_BEFORE_txtShare*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtShare}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtShare*/}

                {/* END_USER_CODE-USER_AFTER_txtShare*/}
                {/* START_USER_CODE-USER_BEFORE_btnSeedGrwrAgreemnt*/}

                {/* END_USER_CODE-USER_BEFORE_btnSeedGrwrAgreemnt*/}

                <ButtonWidget
                  conf={state.btnSeedGrwrAgreemnt}
                  screenConf={state}
                  onClick={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSeedGrwrAgreemnt*/}

                {/* END_USER_CODE-USER_AFTER_btnSeedGrwrAgreemnt*/}
                {/* START_USER_CODE-USER_BEFORE_btnFmAgreement*/}

                {/* END_USER_CODE-USER_BEFORE_btnFmAgreement*/}

                <ButtonWidget
                  conf={state.btnFmAgreement}
                  screenConf={state}
                  onClick={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnFmAgreement*/}

                {/* END_USER_CODE-USER_AFTER_btnFmAgreement*/}
                {/* START_USER_CODE-USER_BEFORE_btnChemiclAgreemnt*/}

                {/* END_USER_CODE-USER_BEFORE_btnChemiclAgreemnt*/}

                <ButtonWidget
                  conf={state.btnChemiclAgreemnt}
                  screenConf={state}
                  onClick={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnChemiclAgreemnt*/}

                {/* END_USER_CODE-USER_AFTER_btnChemiclAgreemnt*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpBxVendr*/}

              {/* END_USER_CODE-USER_AFTER_grpBxVendr*/}
              {/* START_USER_CODE-USER_BEFORE_grpBxDlvryPrdDesc*/}

              {/* END_USER_CODE-USER_BEFORE_grpBxDlvryPrdDesc*/}

              <GroupBoxWidget conf={state.grpBxDlvryPrdDesc} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_ddDlvryPrdDesc*/}

                {/* END_USER_CODE-USER_BEFORE_ddDlvryPrdDesc*/}

                <DropDownWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddDlvryPrdDesc}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddDlvryPrdDesc*/}

                {/* END_USER_CODE-USER_AFTER_ddDlvryPrdDesc*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpBxDlvryPrdDesc*/}

              {/* END_USER_CODE-USER_AFTER_grpBxDlvryPrdDesc*/}
              {/* START_USER_CODE-USER_BEFORE_grpBxContrctDetls*/}

              {/* END_USER_CODE-USER_BEFORE_grpBxContrctDetls*/}
              <GroupBoxWidget conf={state.grpBxContrctDetls} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblCntrctDetls*/}

                {/* END_USER_CODE-USER_BEFORE_lblCntrctDetls*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCntrctDetls}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCntrctDetls*/}

                {/* END_USER_CODE-USER_AFTER_lblCntrctDetls*/}
                {/* START_USER_CODE-USER_BEFORE_txtDel*/}

                {/* END_USER_CODE-USER_BEFORE_txtDel*/}

                <LabelWidget
                  values={values}
                  conf={state.txtDel}
                  screenConf={state}
                ></LabelWidget>
                <DateWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                  }}
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.dtMtchEndDate}
                  screenConf={state}
                ></DateWidget>

                {/* START_USER_CODE-USER_AFTER_txtDel*/}

                {/* END_USER_CODE-USER_AFTER_txtDel*/}
                {/* START_USER_CODE-USER_BEFORE_lblFrm*/}

                {/* END_USER_CODE-USER_BEFORE_lblFrm*/}

                <LabelWidget
                  values={values}
                  conf={state.lblFrm}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblFrm*/}

                {/* END_USER_CODE-USER_AFTER_lblFrm*/}
                {/* START_USER_CODE-USER_BEFORE_chckbxHrvst*/}

                {/* END_USER_CODE-USER_BEFORE_chckbxHrvst*/}

                <CheckboxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chckbxHrvst}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chckbxHrvst*/}

                {/* END_USER_CODE-USER_AFTER_chckbxHrvst*/}
                {/* START_USER_CODE-USER_BEFORE_dtStart*/}

                {/* END_USER_CODE-USER_BEFORE_dtStart*/}

                <DateWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.dtStart}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_dtStart*/}

                {/* END_USER_CODE-USER_AFTER_dtStart*/}
                {/* START_USER_CODE-USER_BEFORE_dtEnd*/}

                {/* END_USER_CODE-USER_BEFORE_dtEnd*/}

                <DateWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.dtEnd}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_dtEnd*/}

                {/* END_USER_CODE-USER_AFTER_dtEnd*/}
                {/* START_USER_CODE-USER_BEFORE_txtPounds*/}

                {/* END_USER_CODE-USER_BEFORE_txtPounds*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPounds}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPounds*/}

                {/* END_USER_CODE-USER_AFTER_txtPounds*/}
                {/* START_USER_CODE-USER_BEFORE_ddPrc*/}

                {/* END_USER_CODE-USER_BEFORE_ddPrc*/}

                <DropDownWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPrc}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPrc*/}

                {/* END_USER_CODE-USER_AFTER_ddPrc*/}
                {/* START_USER_CODE-USER_BEFORE_txtApplied*/}

                {/* END_USER_CODE-USER_BEFORE_txtApplied*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtApplied}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtApplied*/}

                {/* END_USER_CODE-USER_AFTER_txtApplied*/}
                {/* START_USER_CODE-USER_BEFORE_txtTntAppld*/}

                {/* END_USER_CODE-USER_BEFORE_txtTntAppld*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTntAppld}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTntAppld*/}

                {/* END_USER_CODE-USER_AFTER_txtTntAppld*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotlApp*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotlApp*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotlApp}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotlApp*/}

                {/* END_USER_CODE-USER_AFTER_txtTotlApp*/}
                {/* START_USER_CODE-USER_BEFORE_txtTntOpen*/}

                {/* END_USER_CODE-USER_BEFORE_txtTntOpen*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTntOpen}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTntOpen*/}

                {/* END_USER_CODE-USER_AFTER_txtTntOpen*/}
                {/* START_USER_CODE-USER_BEFORE_btnLookupFrm*/}

                {/* END_USER_CODE-USER_BEFORE_btnLookupFrm*/}

                <ButtonWidget
                  conf={state.btnLookupFrm}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnLookupFrm*/}

                {/* END_USER_CODE-USER_AFTER_btnLookupFrm*/}
                {/* START_USER_CODE-USER_BEFORE_lblBasis*/}

                {/* END_USER_CODE-USER_BEFORE_lblBasis*/}

                <LabelWidget
                  values={values}
                  conf={state.lblBasis}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblBasis*/}

                {/* END_USER_CODE-USER_AFTER_lblBasis*/}
                {/* START_USER_CODE-USER_BEFORE_chkbxHrvst2*/}

                {/* END_USER_CODE-USER_BEFORE_chkbxHrvst2*/}

                <CheckboxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkbxHrvst2}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkbxHrvst2*/}

                {/* END_USER_CODE-USER_AFTER_chkbxHrvst2*/}
                {/* START_USER_CODE-USER_BEFORE_dtStrt2*/}

                {/* END_USER_CODE-USER_BEFORE_dtStrt2*/}

                <DateWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.dtStrt2}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_dtStrt2*/}

                {/* END_USER_CODE-USER_AFTER_dtStrt2*/}
                {/* START_USER_CODE-USER_BEFORE_dtEnd2*/}

                {/* END_USER_CODE-USER_BEFORE_dtEnd2*/}

                <DateWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.dtEnd2}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_dtEnd2*/}

                {/* END_USER_CODE-USER_AFTER_dtEnd2*/}
                {/* START_USER_CODE-USER_BEFORE_txtPounds2*/}

                {/* END_USER_CODE-USER_BEFORE_txtPounds2*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPounds2}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPounds2*/}

                {/* END_USER_CODE-USER_AFTER_txtPounds2*/}
                {/* START_USER_CODE-USER_BEFORE_ddPrice*/}

                {/* END_USER_CODE-USER_BEFORE_ddPrice*/}

                <DropDownWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPrice}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPrice*/}

                {/* END_USER_CODE-USER_AFTER_ddPrice*/}
                {/* START_USER_CODE-USER_BEFORE_txtApp2*/}

                {/* END_USER_CODE-USER_BEFORE_txtApp2*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtApp2}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtApp2*/}

                {/* END_USER_CODE-USER_AFTER_txtApp2*/}
                {/* START_USER_CODE-USER_BEFORE_txtTntApp2*/}

                {/* END_USER_CODE-USER_BEFORE_txtTntApp2*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTntApp2}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTntApp2*/}

                {/* END_USER_CODE-USER_AFTER_txtTntApp2*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotlApp2*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotlApp2*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotlApp2}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotlApp2*/}

                {/* END_USER_CODE-USER_AFTER_txtTotlApp2*/}
                {/* START_USER_CODE-USER_BEFORE_txtTntOpen2*/}

                {/* END_USER_CODE-USER_BEFORE_txtTntOpen2*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTntOpen2}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTntOpen2*/}

                {/* END_USER_CODE-USER_AFTER_txtTntOpen2*/}
                {/* START_USER_CODE-USER_BEFORE_btnVndrLookupFrm2*/}

                {/* END_USER_CODE-USER_BEFORE_btnVndrLookupFrm2*/}

                <ButtonWidget
                  conf={state.btnVndrLookupFrm2}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVndrLookupFrm2*/}

                {/* END_USER_CODE-USER_AFTER_btnVndrLookupFrm2*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxFlex*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxFlex*/}

                <GroupBoxWidget conf={state.grpbxFlex} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblFlexMarketPricing*/}

                  {/* END_USER_CODE-USER_BEFORE_lblFlexMarketPricing*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblFlexMarketPricing}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblFlexMarketPricing*/}

                  {/* END_USER_CODE-USER_AFTER_lblFlexMarketPricing*/}
                  {/* START_USER_CODE-USER_BEFORE_lblMktPrcng*/}

                  {/* END_USER_CODE-USER_BEFORE_lblMktPrcng*/}

                  <TextBoxWidget
                    onChange={event => {
                      setTimeout(() => {
                        setFormDirty(true)
                      }, 50),
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                    }}
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.lblMktPrcng}
                    screenConf={state}
                    onPaste={event => {
                      setTimeout(() => {
                        setFormDirty(true)
                      }, 50),
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                    }}
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_lblMktPrcng*/}

                  {/* END_USER_CODE-USER_AFTER_lblMktPrcng*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAvlLbs*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAvlLbs*/}

                  <TextBoxWidget
                    onChange={event => {
                      setTimeout(() => {
                        setFormDirty(true)
                      }, 50),
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                    }}
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAvlLbs}
                    screenConf={state}
                    onPaste={event => {
                      setTimeout(() => {
                        setFormDirty(true)
                      }, 50),
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                    }}
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAvlLbs*/}

                  {/* END_USER_CODE-USER_AFTER_txtAvlLbs*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPriceLbs*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPriceLbs*/}

                  <TextBoxWidget
                    onChange={event => {
                      setTimeout(() => {
                        setFormDirty(true)
                      }, 50),
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }}
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPriceLbs}
                    screenConf={state}
                    onPaste={event => {
                      setTimeout(() => {
                        setFormDirty(true)
                      }, 50),
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                    }}
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPriceLbs*/}

                  {/* END_USER_CODE-USER_AFTER_txtPriceLbs*/}
                  {/* START_USER_CODE-USER_BEFORE_txtRemngLbs*/}

                  {/* END_USER_CODE-USER_BEFORE_txtRemngLbs*/}

                  <TextBoxWidget
                    onChange={event => {
                      setTimeout(() => {
                        setFormDirty(true)
                      }, 50),
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                    }}
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtRemngLbs}
                    screenConf={state}
                    onPaste={event => {
                      setTimeout(() => {
                        setFormDirty(true)
                      }, 50),
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                    }}
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtRemngLbs*/}

                  {/* END_USER_CODE-USER_AFTER_txtRemngLbs*/}
                  {/* START_USER_CODE-USER_BEFORE_txtMktEndDt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMktEndDt*/}

                  <TextBoxWidget
                    onChange={event => {
                      setTimeout(() => {
                        setFormDirty(true)
                      }, 50),
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                    }}
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMktEndDt}
                    screenConf={state}
                    onPaste={event => {
                      setTimeout(() => {
                        setFormDirty(true)
                      }, 50),
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                    }}
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMktEndDt*/}

                  {/* END_USER_CODE-USER_AFTER_txtMktEndDt*/}
                  {/* START_USER_CODE-USER_BEFORE_btnFlxMktPcngDet*/}

                  {/* END_USER_CODE-USER_BEFORE_btnFlxMktPcngDet*/}

                  <ButtonWidget
                    conf={state.btnFlxMktPcngDet}
                    screenConf={state}
                    onClick={event => {
                      setTimeout(() => {
                        setFormDirty(true)
                      }, 50),
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                    }}
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnFlxMktPcngDet*/}

                  {/* END_USER_CODE-USER_AFTER_btnFlxMktPcngDet*/}
                  {/* START_USER_CODE-USER_BEFORE_btnFlxMktWrkSht*/}

                  {/* END_USER_CODE-USER_BEFORE_btnFlxMktWrkSht*/}

                  <ButtonWidget
                    conf={state.btnFlxMktWrkSht}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnFlxMktWrkSht*/}

                  {/* END_USER_CODE-USER_AFTER_btnFlxMktWrkSht*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxFlex*/}

                {/* END_USER_CODE-USER_AFTER_grpbxFlex*/}
                {/* START_USER_CODE-USER_BEFORE_chkbxFreezeDmgRng*/}

                {/* END_USER_CODE-USER_BEFORE_chkbxFreezeDmgRng*/}

                <CheckboxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkbxFreezeDmgRng}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkbxFreezeDmgRng*/}

                {/* END_USER_CODE-USER_AFTER_chkbxFreezeDmgRng*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtbxMin}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtbxMin*/}

                {/* END_USER_CODE-USER_AFTER_txtbxMin*/}
                {/* START_USER_CODE-USER_BEFORE_txtbxMax*/}

                {/* END_USER_CODE-USER_BEFORE_txtbxMax*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtbxMax}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtbxMax*/}

                {/* END_USER_CODE-USER_AFTER_txtbxMax*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpBxContrctDetls*/}

              {/* END_USER_CODE-USER_AFTER_grpBxContrctDetls*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxLienHldr*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxLienHldr*/}
              <GroupBoxWidget conf={state.grpbxLienHldr} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_grpbx*/}

                {/* END_USER_CODE-USER_BEFORE_grpbx*/}

                <LabelWidget
                  values={values}
                  conf={state.grpbx}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_grpbx*/}

                {/* END_USER_CODE-USER_AFTER_grpbx*/}
                {/* START_USER_CODE-USER_BEFORE_txtLnHldrNm*/}

                {/* END_USER_CODE-USER_BEFORE_txtLnHldrNm*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLnHldrNm}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtLnHldrNm*/}

                {/* END_USER_CODE-USER_AFTER_txtLnHldrNm*/}
                {/* START_USER_CODE-USER_BEFORE_txtAddr*/}

                {/* END_USER_CODE-USER_BEFORE_txtAddr*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAddr}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAddr*/}

                {/* END_USER_CODE-USER_AFTER_txtAddr*/}
                {/* START_USER_CODE-USER_BEFORE_txtCity*/}

                {/* END_USER_CODE-USER_BEFORE_txtCity*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCity}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCity*/}

                {/* END_USER_CODE-USER_AFTER_txtCity*/}
                {/* START_USER_CODE-USER_BEFORE_txtState*/}

                {/* END_USER_CODE-USER_BEFORE_txtState*/}

                <DropDownWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtState}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_txtState*/}

                {/* END_USER_CODE-USER_AFTER_txtState*/}
                {/* START_USER_CODE-USER_BEFORE_txtZip*/}

                {/* END_USER_CODE-USER_BEFORE_txtZip*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtZip}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtZip*/}

                {/* END_USER_CODE-USER_AFTER_txtZip*/}
                {/* START_USER_CODE-USER_BEFORE_txtNtrOfLiens*/}

                {/* END_USER_CODE-USER_BEFORE_txtNtrOfLiens*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNtrOfLiens}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNtrOfLiens*/}

                {/* END_USER_CODE-USER_AFTER_txtNtrOfLiens*/}
                {/* START_USER_CODE-USER_BEFORE_lienHldr2*/}

                {/* END_USER_CODE-USER_BEFORE_lienHldr2*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lienHldr2}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_lienHldr2*/}

                {/* END_USER_CODE-USER_AFTER_lienHldr2*/}
                {/* START_USER_CODE-USER_BEFORE_txtAdd2*/}

                {/* END_USER_CODE-USER_BEFORE_txtAdd2*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAdd2}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAdd2*/}

                {/* END_USER_CODE-USER_AFTER_txtAdd2*/}
                {/* START_USER_CODE-USER_BEFORE_txtCity2*/}

                {/* END_USER_CODE-USER_BEFORE_txtCity2*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCity2}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCity2*/}

                {/* END_USER_CODE-USER_AFTER_txtCity2*/}
                {/* START_USER_CODE-USER_BEFORE_ddStat2*/}

                {/* END_USER_CODE-USER_BEFORE_ddStat2*/}

                <DropDownWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddStat2}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddStat2*/}

                {/* END_USER_CODE-USER_AFTER_ddStat2*/}
                {/* START_USER_CODE-USER_BEFORE_txtZip2*/}

                {/* END_USER_CODE-USER_BEFORE_txtZip2*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtZip2}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtZip2*/}

                {/* END_USER_CODE-USER_AFTER_txtZip2*/}
                {/* START_USER_CODE-USER_BEFORE_txtNature2*/}

                {/* END_USER_CODE-USER_BEFORE_txtNature2*/}

                <TextBoxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtNature2}
                  screenConf={state}
                  onPaste={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtNature2*/}

                {/* END_USER_CODE-USER_AFTER_txtNature2*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxLienHldr*/}

              {/* END_USER_CODE-USER_AFTER_grpbxLienHldr*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxCmnts*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCmnts*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxCmnts} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtarCmnnts*/}

                {/* END_USER_CODE-USER_BEFORE_txtarCmnnts*/}

                <TextAreaWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }}
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtarCmnnts}
                  screenConf={state}
                ></TextAreaWidget>
                {/* START_USER_CODE-USER_AFTER_txtarCmnnts*/}

                {/* END_USER_CODE-USER_AFTER_txtarCmnnts*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCmnts*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCmnts*/}
              {/* START_USER_CODE-USER_BEFORE_btnCntnr*/}

              {/* END_USER_CODE-USER_BEFORE_btnCntnr*/}
              <GroupBoxWidget conf={state.btnCntnr} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_btnEmail*/}

                {/* END_USER_CODE-USER_BEFORE_btnEmail*/}

                <ButtonWidget
                  conf={state.btnEmail}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEmail*/}

                {/* END_USER_CODE-USER_AFTER_btnEmail*/}
                {/* START_USER_CODE-USER_BEFORE_btnRescan*/}

                {/* END_USER_CODE-USER_BEFORE_btnRescan*/}

                <ButtonWidget
                  conf={state.btnRescan}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnRescan*/}

                {/* END_USER_CODE-USER_AFTER_btnRescan*/}
                {/* START_USER_CODE-USER_BEFORE_btnSign*/}

                {/* END_USER_CODE-USER_BEFORE_btnSign*/}

                <ButtonWidget
                  conf={state.btnSign}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSign*/}

                {/* END_USER_CODE-USER_AFTER_btnSign*/}
                {/* START_USER_CODE-USER_BEFORE_btnApprv*/}

                {/* END_USER_CODE-USER_BEFORE_btnApprv*/}

                <ButtonWidget
                  conf={state.btnApprv}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnApprv*/}

                {/* END_USER_CODE-USER_AFTER_btnApprv*/}
                {/* START_USER_CODE-USER_BEFORE_btnVoid*/}

                {/* END_USER_CODE-USER_BEFORE_btnVoid*/}

                <ButtonWidget
                  conf={state.btnVoid}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVoid*/}

                {/* END_USER_CODE-USER_AFTER_btnVoid*/}
                {/* START_USER_CODE-USER_BEFORE_btnDeactivate*/}

                {/* END_USER_CODE-USER_BEFORE_btnDeactivate*/}

                <ButtonWidget
                  conf={state.btnDeactivate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDeactivate*/}

                {/* END_USER_CODE-USER_AFTER_btnDeactivate*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
                {/* START_USER_CODE-USER_BEFORE_btnPymnt*/}

                {/* END_USER_CODE-USER_BEFORE_btnPymnt*/}

                <ButtonWidget
                  conf={state.btnPymnt}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPymnt*/}

                {/* END_USER_CODE-USER_AFTER_btnPymnt*/}
                {/* START_USER_CODE-USER_BEFORE_btnAccDist*/}

                {/* END_USER_CODE-USER_BEFORE_btnAccDist*/}

                <ButtonWidget
                  conf={state.btnAccDist}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAccDist*/}

                {/* END_USER_CODE-USER_AFTER_btnAccDist*/}
                {/* START_USER_CODE-USER_BEFORE_btnCreate*/}

                {/* END_USER_CODE-USER_BEFORE_btnCreate*/}

                <ButtonWidget
                  conf={state.btnCreate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCreate*/}

                {/* END_USER_CODE-USER_AFTER_btnCreate*/}
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={state.btnExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
                {/* START_USER_CODE-USER_BEFORE_lblChngdBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChngdBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChngdBy}
                  screenConf={state}
                ></LabelWidget>
                <CheckboxWidget
                  onChange={event => {
                    setTimeout(() => {
                      setFormDirty(true)
                    }, 50),
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                  }}
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chckbxPrintCmmnt}
                  screenConf={state}
                ></CheckboxWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblCropYear2}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChngdBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChngdBy*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_btnCntnr*/}

              {/* END_USER_CODE-USER_AFTER_btnCntnr*/}

              {/* START_USER_CODE-USER_AFTER_ContractProfile*/}

              {/* END_USER_CODE-USER_AFTER_ContractProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}

export default withRouter(ContractManagement_ContractProfile);
