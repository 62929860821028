/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  getData,
  setData,
  getValue,
  setValue,
  goTo,
  disable,
  enable,
  hide,
  show,
  AutocompleteWidget,
  getSelectedRowNumber,
  isEnabled,
  clearValue,
  hideWidgets,
  showWidgets,
  setFieldValues
} from "../../shared/WindowImports";
import { Formatthevalue,Alertmessage,PopupBreak,ConvertToPascalCase } from "../../Common/CommonFunctions"

import "./ManualRTPMaint.scss";

// START_USER_CODE-USER_IMPORTS
import StripPic from "../../../../assets/img/PinStrip.png";
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceApplicationSupportService } from "../Service/SystemMaintenanceApplicationSupportService";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import Loading from "../../../Loader/Loading";

// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_ManualRTPMaint(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const compIdFromLS = sessionStorage.getItem('compId');
  const cropYearFromLS = JSON.parse(sessionStorage.getItem('year'))
  let useridFromLS = sessionStorage.getItem('userid')
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ManualRTPMaint",
    windowName: "ManualRTPMaint",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.ManualRTPMaint",
    // START_USER_CODE-USER_ManualRTPMaint_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Ready to Pay Maintenance",
      scrCode: "PN0210B",
    },
    // END_USER_CODE-USER_ManualRTPMaint_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Add",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxManualRTPMaint",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    ddLoanReduction: {
      name: "ddLoanReduction",
      type: "DropDownFieldWidget",
      parent: "grpbxManualRTPMaint",
      Label: "Loan Redution:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLoanReduction_PROPERTIES

      // END_USER_CODE-USER_ddLoanReduction_PROPERTIES
    },
    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxManualRTPMaint",
      Label: "Location:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",
      Enabled: false,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation_PROPERTIES

      // END_USER_CODE-USER_ddLocation_PROPERTIES
    },
    ddOBLLocation: {
      name: "ddOBLLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxManualRTPMaint",
      DataProviderForDropDown: "inline",
      HasLabel: false,

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOBLLocation_PROPERTIES

      // END_USER_CODE-USER_ddOBLLocation_PROPERTIES
    },
    ddOptions: {
      name: "ddOptions",
      type: "DropDownFieldWidget",
      parent: "grpbxManualRTPMaint",
      Label: "Option:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOptions_PROPERTIES

      // END_USER_CODE-USER_ddOptions_PROPERTIES
    },
    ddStorageInd: {
      name: "ddStorageInd",
      type: "DropDownFieldWidget",
      parent: "grpbxManualRTPMaint",
      Label: "Storage, handling pay Ind:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddStorageInd_PROPERTIES

      // END_USER_CODE-USER_ddStorageInd_PROPERTIES
    },
    ddShrinkPayment: {
      name: "ddShrinkPayment",
      type: "DropDownFieldWidget",
      parent: "grpbxManualRTPMaint",
      Label: "Shrink Payment:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddShrinkPayment_PROPERTIES

      // END_USER_CODE-USER_ddShrinkPayment_PROPERTIES
    },
    ddStatus: {
      name: "ddStatus",
      type: "DropDownFieldWidget",
      parent: "grpbxManualRTPMaint",
      Label: "Status:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddStatus_PROPERTIES

      // END_USER_CODE-USER_ddStatus_PROPERTIES
    },
    ddWarehouseReceiptLocation: {
      name: "ddWarehouseReceiptLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxManualRTPMaint",
      Label: "Whse Rcpt Location:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWarehouseReceiptLocation_PROPERTIES

      // END_USER_CODE-USER_ddWarehouseReceiptLocation_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    txtContract: {
      name: "txtContract",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPMaint",
      Label: "Contract #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContract_PROPERTIES

      // END_USER_CODE-USER_txtContract_PROPERTIES
    },
    txtCounty: {
      name: "txtCounty",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPMaint",
      Label: "County:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCounty_PROPERTIES

      // END_USER_CODE-USER_txtCounty_PROPERTIES
    },
    txtDeliveryAgreement: {
      name: "txtDeliveryAgreement",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPMaint",
      Label: "Delivery Agreement #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDeliveryAgreement_PROPERTIES

      // END_USER_CODE-USER_txtDeliveryAgreement_PROPERTIES
    },
    txtFarm: {
      name: "txtFarm",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPMaint",
      Label: "Farm #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarm_PROPERTIES

      // END_USER_CODE-USER_txtFarm_PROPERTIES
    },
    txtFarmSuffix: {
      name: "txtFarmSuffix",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPMaint",
      Label: "Farm Suffix #:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarmSuffix_PROPERTIES

      // END_USER_CODE-USER_txtFarmSuffix_PROPERTIES
    },
    txtFreightMemo: {
      name: "txtFreightMemo",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPMaint",
      Label: "Freight Memo #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFreightMemo_PROPERTIES

      // END_USER_CODE-USER_txtFreightMemo_PROPERTIES
    },
    txtInspectionSC95: {
      name: "txtInspectionSC95",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPMaint",
      Label: "Inspection (SC95) #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInspectionSC95_PROPERTIES

      // END_USER_CODE-USER_txtInspectionSC95_PROPERTIES
    },
    txtProceeds: {
      name: "txtProceeds",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPMaint",
      Label: "Proceeds $:",
      LengthRange: { MinLength: 0, MaxLength: 12 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtProceeds_PROPERTIES

      // END_USER_CODE-USER_txtProceeds_PROPERTIES
    },
    txtReason: {
      name: "txtReason",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPMaint",
      Label: "Reason:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 100 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtReason_PROPERTIES

      // END_USER_CODE-USER_txtReason_PROPERTIES
    },
    txtSettlement1007: {
      name: "txtSettlement1007",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPMaint",
      Label: "Settlement (1007) #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSettlement1007_PROPERTIES

      // END_USER_CODE-USER_txtSettlement1007_PROPERTIES
    },
    txtState: {
      name: "txtState",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPMaint",
      Label: "State:",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtState_PROPERTIES

      // END_USER_CODE-USER_txtState_PROPERTIES
    },
    txtTradeInspection: {
      name: "txtTradeInspection",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPMaint",
      Label: "Trade Inspection #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradeInspection_PROPERTIES

      // END_USER_CODE-USER_txtTradeInspection_PROPERTIES
    },
    txtTradeSettlement: {
      name: "txtTradeSettlement",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPMaint",
      Label: "Trade Settlement #:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTradeSettlement_PROPERTIES

      // END_USER_CODE-USER_txtTradeSettlement_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPMaint",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtVndr: {
      name: "txtVndr",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPMaint",
      ColSpan: "2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVndr_PROPERTIES

      // END_USER_CODE-USER_txtVndr_PROPERTIES
    },
    txtWarehouseReceipt: {
      name: "txtWarehouseReceipt",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPMaint",
      Label: "Whse Rcpt #:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWarehouseReceipt_PROPERTIES

      // END_USER_CODE-USER_txtWarehouseReceipt_PROPERTIES
    },
    txtVe: {
      name: "txtVe",
      type: "TextBoxWidget",
      parent: "grpbxManualRTPHistSearch",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    grpbxManualRTPMaint: {
      name: "grpbxManualRTPMaint",
      type: "GroupBoxWidget",
      parent: "ManualRTPMaint",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxManualRTPMaint_PROPERTIES

      // END_USER_CODE-USER_grpbxManualRTPMaint_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "ManualRTPMaint",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const LC_ADD = "Add"
  const LC_EDIT = "Change"
  const LC_DELETE = "Delete"
  var isbFormValid = false;
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    setTabIndex();
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    load_form()
    hide(thisObj, 'ddStorageInd')
    disable(thisObj,'txtVndr')
  }, []);
  useEffect(() => {
    let vendor_lookup = getData(thisObj, 'vendorDetails')
    if (vendor_lookup !== null && vendor_lookup !== undefined && vendor_lookup !== '') {
      thisObj.setFieldValue("txtVendor", vendor_lookup.VendorNumber);
      let VenNumber = vendor_lookup.VendorNumber
      bFillRemitToList(vendor_lookup.VendorNumber)
      thisObj.setFieldValue("txtVndr", ConvertToPascalCase(vendor_lookup.vendorName))
      let js = [];
      ContractManagementService.RetieveRemitDetails(VenNumber).then(response => {
        if (response != undefined && response.length > 0) {
          let data = response
          for (var i = 0; i < data.length; i++) {
            var vendorName = ConvertToPascalCase(data[0].name)
            let obj = { key: data[i].remittoid, description: data[i].remittoid + " - " +ConvertToPascalCase(data[i].name) + " - " + ConvertToPascalCase(data[i].city) + " - " + ConvertToPascalCase(data[i].state)}
            js.push(obj)
          }
          setValue(thisObj, "txtVndr", vendorName)
          setValue(thisObj, "ddOBLLocation", js[0].key);
          setData(thisObj, 'vendorName', vendorName)
          setData(thisObj, "remitToLength", js.length);
        }
      })
      thisObj.setState(current => {
        return {
          ...current,
          ddOBLLocation: {
            ...state["ddOBLLocation"],
            valueList: js,
          }
        }
      })
      setData(thisObj, "vendorDetails", null)
    }
  }, [getData(thisObj, 'vendorDetails')]);
  const setTabIndex = () => {
    let elements = [
      'ddLocation',
      'txtVendor',
      'ddOBLLocation',
      'btnVendor',
      'ddStatus',
      'txtProceeds',
      'txtContract',
      'txtInspectionSC95',
      'txtSettlement1007',
      'txtDeliveryAgreement',
      'txtFreightMemo',
      'txtTradeInspection',
      'txtTradeSettlement',
      'txtWarehouseReceipt',
      'ddWarehouseReceiptLocation',
      'txtState',
      'txtCounty',
      'txtFarm',
      'txtFarmSuffix',
      'ddOptions',
      'ddShrinkPayment',
      'ddLoanReduction',
      'txtReason'
    ] 
    elements.map((element,index) => {
      document.querySelectorAll(".modal")[1].querySelector(`#${element}`).tabIndex = index+1;
    })
  }
  const validationBox5 = (value) => {
    var cont = ''
    let formatvalue = ''
    if (value > 0) {
      if (value.length <= 7) {
        for (var i = 0; i < (7 - value.length); i++) {
          cont += '0'
        }
      }
      formatvalue = cont + value;
    } 
    return formatvalue;
  }

  const bFillForm = async () => {
    setLoading(true);
    let frmManualRTPMaintdata = getData(thisObj, 'frmManualRTPMaintdata');
    if (frmManualRTPMaintdata !== null) {
      let data = frmManualRTPMaintdata;
      if (data[0].cmdOk !== LC_ADD) {
        let RetrieveManualReadyToPayMaintenanceDetails = await SystemMaintenanceApplicationSupportService.RetrieveManualReadyToPayMaintenanceDetails(data[0].lblTransactionNumber);
        let lstrxml = RetrieveManualReadyToPayMaintenanceDetails[0]
        await bFillRemitToList(lstrxml.payee_vendor)
        setValue(thisObj, 'ddLocation', lstrxml.buy_pt_id)
        setValue(thisObj, 'txtVendor', lstrxml.payee_vendor)
        setValue(thisObj, 'ddOBLLocation', lstrxml.payee_remit)
        setValue(thisObj, 'ddStatus', lstrxml.rtp_status)
        let rate = lstrxml.rtp_proceeds
        if (rate.length != 0 && rate.length < 10) {
          rate = Number(rate).toFixed(2)
        } else if (rate.length == 10) {
          rate = Number(rate).toFixed(1)
        } else if (rate.length == 11) {
          rate = rate + '.'
        }
        setValue(thisObj, 'txtProceeds', rate);
        setValue(thisObj, 'txtContract',Formatthevalue(lstrxml.audit_cont_num))
        setValue(thisObj, 'txtInspectionSC95', lstrxml.audit_insp_num)
        setValue(thisObj, 'txtSettlement1007',validationBox5(lstrxml.audit_settle_num !==0? lstrxml.audit_settle_num :'') )
        setValue(thisObj, 'txtDeliveryAgreement',parseInt(lstrxml.audit_delv_agree_num) !==0? lstrxml.audit_delv_agree_num :'')
        setValue(thisObj, 'txtFreightMemo', lstrxml.memo_num !==0 && lstrxml.memo_num !==null? lstrxml.memo_num:'')
        setValue(thisObj, 'txtTradeInspection', lstrxml.audit_trade_insp)
        setValue(thisObj, 'txtTradeSettlement', Formatthevalue(lstrxml.audit_trade_settle))
        setValue(thisObj, 'txtWarehouseReceipt',Formatthevalue(lstrxml.audit_whse_rcpt !==0?lstrxml.audit_whse_rcpt:''))
        setValue(thisObj, 'ddWarehouseReceiptLocation', lstrxml.audit_whse_bp)
        setValue(thisObj, 'txtState', lstrxml.audit_state_abbr)
        setValue(thisObj, 'txtCounty', lstrxml.audit_county_id)
        setValue(thisObj, 'txtFarm', lstrxml.audit_farm_id)
        setValue(thisObj, 'txtFarmSuffix', lstrxml.audit_farm_suffix)
        setValue(thisObj, 'ddOptions', lstrxml.option_pay_ind)
        setValue(thisObj, 'ddStorageInd', lstrxml.storage_pay_ind)
        setValue(thisObj, 'ddShrinkPayment', lstrxml.shrink_pay_ind)
        setValue(thisObj, 'ddLoanReduction', lstrxml.loan_reduct_ind)
        setValue(thisObj, 'txtReason', lstrxml.audit_reason)
        setValue(thisObj, 'lblAddedByValue', lstrxml.add_user + " " + moment(String(lstrxml.add_date_time)).format('MM/DD/YYYY hh:mm:ss A'))
        setValue(thisObj, 'lblChangedByValue', lstrxml.chg_user + " " + moment(String(lstrxml.chg_date_time)).format('MM/DD/YYYY hh:mm:ss A'))
      }
    }
    setLoading(false);
  }
  const load_form = async () => {
    await load_formDefaults()
    await bFillForm()
    hide(thisObj, 'ddStorageInd')
    let frmManualRTPMaintdata = getData(thisObj, 'frmManualRTPMaintdata');
    if (frmManualRTPMaintdata !== null) {
      let data = frmManualRTPMaintdata;
      switch (data[0].cmdOk) {
        case LC_ADD:
          document.getElementById('btnOk').innerHTML = 'Add';
          enable(thisObj, 'ddLocation')
          break;
        case LC_EDIT:
          document.getElementById('btnOk').innerHTML = 'Change';
          break;
        case LC_DELETE:
          document.getElementById('btnOk').innerHTML = 'Delete';
          disable(thisObj, 'txtVendor')
          disable(thisObj, 'ddOBLLocation')
          disable(thisObj, 'btnVendor')
          disable(thisObj, 'ddStatus')
          disable(thisObj, 'ddWarehouseReceiptLocation')
          disable(thisObj, 'txtProceeds')
          disable(thisObj, 'txtContract')
          disable(thisObj, 'txtInspectionSC95')
          disable(thisObj, 'txtSettlement1007')
          disable(thisObj, 'txtTradeInspection')
          disable(thisObj, 'txtTradeSettlement')
          disable(thisObj, 'txtWarehouseReceipt')
          disable(thisObj, 'txtDeliveryAgreement')
          disable(thisObj, 'txtState')
          disable(thisObj, 'txtCounty')
          disable(thisObj, 'txtFarm')
          disable(thisObj, 'txtFarmSuffix')
          disable(thisObj, 'ddOptions')
          disable(thisObj, 'ddShrinkPayment')
          disable(thisObj, 'ddStorageInd')
          disable(thisObj, 'ddLoanReduction')
          disable(thisObj, 'txtFreightMemo')
          break;

      }
    }

  }
  const load_formDefaults = async () => {
    setLoading(true);
    let cboLocation = getValue(thisObj, 'ddLocation')
    let cboOBLLocation = getValue(thisObj, 'ddWarehouseReceiptLocation')
    await bFillLocation(cboLocation, '', true)
    await bFillLocation(cboOBLLocation, '', true)
    await bFillRTPStatus()
    await bFillOptionIndication()
    await bFillShrinkPayment()
    await bFillStorageInd()
    await bFillLoanReductInd()
    setLoading(false);
  }

  const bFillLocation = async (cboLoc, lstLoc, blnSecure) => {
    let js = []
    let data = []
    let cboOBLLocation=[]
    cboOBLLocation.push({ key: '', description: '' }) 
    if (blnSecure == true) {
      let response = await ContractManagementService.RetrieveBuyingPointControlDetails('PN0210B', null, null, null, null)
      data = response
    } else {
      let response = await ContractManagementService.RetrieveBuyingPointControlList(compIdFromLS, cropYearFromLS, null, null, null)
      data = response
    }
    for (var i = 0; i < data.length; i++) {
      let obj = {
        key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + ConvertToPascalCase(data[i].buy_pt_name).toString()
      }
      js.push(obj)
      cboOBLLocation.push(obj)
    }
    //setData(thisObj, 'locationData', data)
    thisObj.setState(current => {
      return {
        ...current,
        ddLocation: {
          ...state["ddLocation"],
          valueList: js
        }
      }
    })
    //setData(thisObj, 'ddWarehouseReceiptLocation', data)
    thisObj.setState(current => {
      return {
        ...current,
        ddWarehouseReceiptLocation: {
          ...state["ddWarehouseReceiptLocation"],
          valueList: cboOBLLocation
        }
      }
    })

  }
  async function bFillRTPStatus() {
    try {
      let js = []
      js.push({ key: 'H', description: 'H' })
      js.push({ key: 'R', description: 'R' })

      thisObj.setState(current => {
        return {
          ...current,
          ddStatus: {
            ...state["ddStatus"],
            valueList: js
          },
        }
      });
      return true;
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in fetching location list.");
      }
      return false;
    }
  }
  async function bFillOptionIndication() {
    try {
      let js = []
      js.push({ key: 'N', description: 'N' })
      js.push({ key: 'Y', description: 'Y' })

      thisObj.setState(current => {
        return {
          ...current,
          ddOptions: {
            ...state["ddOptions"],
            valueList: js
          },
        }
      });
      return true;
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in fetching location list.");
      }
      return false;
    }
  }
  async function bFillShrinkPayment() {
    try {
      let js = []
      js.push({ key: 'N', description: 'N' })
      js.push({ key: 'Y', description: 'Y' })

      thisObj.setState(current => {
        return {
          ...current,
          ddShrinkPayment: {
            ...state["ddShrinkPayment"],
            valueList: js
          },
        }
      });
      return true;
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in fetching location list.");
      }
      return false;
    }
  }
  async function bFillStorageInd() {
    try {
      let js = []
      js.push({ key: 'N', description: 'N' })
      js.push({ key: 'Y', description: 'Y' })

      thisObj.setState(current => {
        return {
          ...current,
          ddStorageInd: {
            ...state["ddStorageInd"],
            valueList: js
          },
        }
      });
      return true;
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in fetching location list.");
      }
      return false;
    }
  }
  async function bFillLoanReductInd() {
    try {
      let js = []
      js.push({ key: 'N', description: 'N' })
      js.push({ key: 'Y', description: 'Y' })

      thisObj.setState(current => {
        return {
          ...current,
          ddLoanReduction: {
            ...state["ddLoanReduction"],
            valueList: js
          },
        }
      });
      return true;
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Exception in fetching location list.");
      }
      return false;
    }
  }
  const bformvaild = async () => {
    let ddLocation = getValue(thisObj, 'ddLocation') !==undefined ? getValue(thisObj, 'ddLocation') :''
    let txtVendor = getValue(thisObj, 'txtVendor') !==undefined? getValue(thisObj, 'txtVendor') :''
    let ddOBLLocation = getValue(thisObj, 'ddOBLLocation') !==undefined? getValue(thisObj, 'ddOBLLocation') :''
    let ddStatus = getValue(thisObj, 'ddStatus')!==undefined ? getValue(thisObj, 'ddStatus') :''
    let txtProceeds = getValue(thisObj, 'txtProceeds') !==undefined ? getValue(thisObj, 'txtProceeds') :''
    let txtContract = getValue(thisObj, 'txtContract') !==undefined ? getValue(thisObj, 'txtContract') :''
    let txtInspectionSC95 = getValue(thisObj, 'txtInspectionSC95') !==undefined ? getValue(thisObj, 'txtInspectionSC95') :''
    let txtSettlement1007 = getValue(thisObj, 'txtSettlement1007') !==undefined ? getValue(thisObj, 'txtSettlement1007') :''
    let txtDeliveryAgreement = getValue(thisObj, 'txtDeliveryAgreement') !==undefined ? getValue(thisObj, 'txtDeliveryAgreement') :''
    let txtFreightMemo = getValue(thisObj, 'txtFreightMemo') !==undefined ? getValue(thisObj, 'txtFreightMemo') :''
    let txtTradeInspection = getValue(thisObj, 'txtTradeInspection') !==undefined ? getValue(thisObj, 'txtTradeInspection') :''
    let txtTradeSettlement = getValue(thisObj, 'txtTradeSettlement') !==undefined ? getValue(thisObj, 'txtTradeSettlement') :''
    let txtWarehouseReceipt = getValue(thisObj, 'txtWarehouseReceipt') !==undefined ? getValue(thisObj, 'txtWarehouseReceipt') :''
    let ddWarehouseReceiptLocation = getValue(thisObj, 'ddWarehouseReceiptLocation') !==undefined ? getValue(thisObj, 'ddWarehouseReceiptLocation') :''
    let txtState = getValue(thisObj, 'txtState') !==undefined ? getValue(thisObj, 'txtState') :''
    let txtCounty = getValue(thisObj, 'txtCounty') !==undefined ? getValue(thisObj, 'txtCounty') :''
    let txtFarm = getValue(thisObj, 'txtFarm') !==undefined ? getValue(thisObj, 'txtFarm') :''
    let txtFarmSuffix = getValue(thisObj, 'txtFarmSuffix') !==undefined ? getValue(thisObj, 'txtFarmSuffix') :''
    let ddOptions = getValue(thisObj, 'ddOptions') !==undefined ? getValue(thisObj, 'ddOptions') :''
    let ddStorageInd = getValue(thisObj, 'ddStorageInd') !==undefined ? getValue(thisObj, 'ddStorageInd') :''
    let ddShrinkPayment = getValue(thisObj, 'ddShrinkPayment') !==undefined ? getValue(thisObj, 'ddShrinkPayment') :''
    let ddLoanReduction = getValue(thisObj, 'ddLoanReduction') !==undefined ? getValue(thisObj, 'ddLoanReduction') :''
    let txtReason = getValue(thisObj, 'txtReason') !==undefined ? getValue(thisObj, 'txtReason') :''
    let lblAddedBy = getValue(thisObj, 'lblAddedBy') !==undefined ? getValue(thisObj, 'lblAddedBy') :''
    let lblChangedBy = getValue(thisObj, 'lblChangedBy') !==undefined ? getValue(thisObj, 'lblChangedBy') :''
    let frmManualRTPMaintdata = getData(thisObj, 'frmManualRTPMaintdata');
    let data = frmManualRTPMaintdata;
    if (txtReason == ''  && data[0].cmdOk == LC_DELETE) {
      showMessage(thisObj, 'Audit Reason must be specified. Explain why this ready to pay is being Deleted!', false)
    }
    else if (ddLocation == '') {
       showMessage(thisObj, 'Location must be specified. Select the location for which the ready to pay is being created', false)
    } else if (txtVendor == '') {
      showMessage(thisObj, 'Vendor to must be specified. Specify the vendor to whom the check must be issued', false)
    } else if (ddOBLLocation == '') {
      showMessage(thisObj, 'Remit To must be specified. Select the vendor location to which the check must be issued', false)
    } else if (ddStatus == '') {
      showMessage(thisObj, 'Status must be selected. Select wether the Ready To Pay must be put on Hold or Released status!', false)
    } else if (txtProceeds == '' ) {
      showMessage(thisObj, 'RTP Proceeds ($) must be specified. Specify an amount for this Ready To Pay.', false)
    } else if (txtReason == '' && data[0].cmdOk == LC_ADD) {
      showMessage(thisObj, 'Audit Reason must be specified. Explain why this ready to pay is being manually Added!', false)
    } else if (txtReason == '' && data[0].cmdOk == LC_EDIT) {
      showMessage(thisObj, 'Audit Reason must be specified. Explain why this ready to pay is being manually modified!', false)
    } else if (ddOptions == '') {
      alert(PopupBreak('Option Pay Indicator must be selected.',['Select if this ready to pay can be attributed towards an Option Payment.','"- (Y)es or (N)o!"']))
    } else if (ddShrinkPayment == '') {
      alert(PopupBreak('Shrink Payment Indicator must be selected. ',['Select if this ready to pay can be attributed towards a Shrink Payment.','"- (Y)es or (N)o!"']))
    } else if (ddLoanReduction == '') {
      alert(PopupBreak('Loan Reduction Indicator must be selected.',['Select if this ready to pay can be attributed towards a Loan Reduction Payment.','"- (Y)es or (N)o!"']))
    } else {
        if (txtContract !== '') {
        if (txtInspectionSC95 !== '' || txtSettlement1007 !== '' || txtTradeInspection !== ''  || txtTradeSettlement !== '' ||
          txtWarehouseReceipt !== ''||ddWarehouseReceiptLocation !== '' || txtFreightMemo !== ''|| txtDeliveryAgreement !== '') {
            alert(Alertmessage('The following values must NOT be specified when a contract number is specified.:',
            ["Inspection (SC95) Number " ,
            "Settlement (1007) Number " ,
            "Trade Inspection Number " ,
            "Trade Settlement Number " ,
            "Whse Receipt Number " ,
            "Whse Receipt (Obligation) Location " ,
            "Freight Memo Number " ,
            "Delivery Agreement Number "]))
           isbFormValid = false;
          if(txtInspectionSC95 !==''){
            document.getElementById('txtInspectionSC95').focus();
          }else if(txtSettlement1007 !=='' ){
            document.getElementById('txtSettlement1007').focus();
          }else if(txtTradeInspection !==''){
            document.getElementById('txtTradeInspection').focus();
          }else if(txtWarehouseReceipt !==''){
            document.getElementById('txtWarehouseReceipt').focus();
          }else if(ddWarehouseReceiptLocation !==''){
            document.getElementById('ddWarehouseReceiptLocation').focus();
          }else if(txtFreightMemo !==''){
            document.getElementById('txtFreightMemo').focus();
          }else if(txtDeliveryAgreement !=='' ){
            document.getElementById('txtDeliveryAgreement').focus();
          }
          return;
        }
      }
       if ((txtInspectionSC95 !== '' || txtSettlement1007 !== '') && txtWarehouseReceipt == '') {
        if (txtContract !== '' || txtTradeInspection !== '' || txtTradeSettlement !== ''||
          ddWarehouseReceiptLocation !== '' || txtFreightMemo !== '' || txtWarehouseReceipt !== ''
          || txtDeliveryAgreement !== '') {
          alert(Alertmessage('The following values must NOT be specified when Inspection (SC95) /Settlement (1007) Information is provided.:',
          ['Contract Number ' ,
          'Trade Inspection Number',
          'Trade Settlement Number ',
          'Whse Receipt Number ',
          'Whse Receipt (Obligation) Location ',
          'Freight Memo Number ' ,
          'Delivery Agreement Number' ]))
          if(txtContract !=='' ){
            document.getElementById('txtContract').focus();
          }else if(txtTradeSettlement !==''){
            document.getElementById('txtTradeSettlement').focus();
          }else if(txtTradeInspection !=='' ){
            document.getElementById('txtTradeInspection').focus();
          }else if(txtWarehouseReceipt !=='' ){
            document.getElementById('txtWarehouseReceipt').focus();
          }else if(ddWarehouseReceiptLocation !=='' ){
            document.getElementById('ddWarehouseReceiptLocation').focus();
          }else if(txtFreightMemo !=='' ){
            document.getElementById('txtFreightMemo').focus();
          }else if(txtDeliveryAgreement !==''){
            document.getElementById('txtDeliveryAgreement').focus();
          }
          return;
        }else if ((txtInspectionSC95 == '' || txtSettlement1007 == '') ||
        (txtState == '' || txtCounty == '' || txtFarm == '' || txtFarmSuffix == '')) {
          alert(Alertmessage('When Inspection (SC95) /Settlement (1007) Information is provided...: The following values MUST be specified.\n',
          ['Inspection (SC95) Number' ,
          'Settlement (1007) Number',
          'State',
          'County',
          'Farm',
          'Farm Suffix' ]))
        if(txtInspectionSC95 !=='' || txtInspectionSC95 !==undefined){
          document.getElementById('txtInspectionSC95').focus();
        }else if(txtSettlement1007 !=='' || txtSettlement1007 !==undefined){
          document.getElementById('txtSettlement1007').focus();
        }else if(txtState !=='' || txtState !==undefined){
          document.getElementById('txtState').focus();
        }else if(txtCounty !=='' || txtCounty !==undefined){
          document.getElementById('txtCounty').focus();
        }else if(txtFarm !=='' || txtFarm !==undefined){
          document.getElementById('txtFarm').focus();
        }else if(txtFarmSuffix !=='' || txtFarmSuffix !==undefined){
          document.getElementById('txtFarmSuffix').focus();
        }
        isbFormValid = false;
        return;
      }
      }
       if (txtTradeInspection !== '' || txtTradeSettlement !=='') {
        if (txtContract !=='' || txtInspectionSC95 !=='' || txtSettlement1007 !=='' || 
            txtWarehouseReceipt !=='' || ddWarehouseReceiptLocation !=='' || 
            txtFreightMemo !=='' || txtDeliveryAgreement !=='') {
          alert(Alertmessage('The following values must NOT be specified when Trade Inspection / Trade Settlement Information is provided.\n',
          ['Contract Number' ,
          'Inspection (SC95) Number',
          'Settlement (1007) Number',
          'Whse Receipt Number',
          'Whse Receipt (Obligation) Location',
          'Freight Memo Number',
          'Delivery Agreement Number' ]))
          if(txtContract !=='' ){
            document.getElementById('txtContract').focus();
          }else if(txtInspectionSC95 !=='' || txtInspectionSC95 !==undefined){
            document.getElementById('txtInspectionSC95').focus();
          }else if(txtSettlement1007 !=='' || txtSettlement1007 !==undefined){
            document.getElementById('txtSettlement1007').focus();
          }else if(txtWarehouseReceipt !=='' || txtWarehouseReceipt !==undefined){
            document.getElementById('txtWarehouseReceipt').focus();
          }else if(ddWarehouseReceiptLocation !=='' || ddWarehouseReceiptLocation !==undefined){
            document.getElementById('ddWarehouseReceiptLocation').focus();
          }else if(txtFreightMemo !=='' ){
            document.getElementById('txtFreightMemo').focus();
          }else if(txtDeliveryAgreement !==''){
            document.getElementById('txtDeliveryAgreement').focus();
          }
          isbFormValid = false;
          return;
        }
      }
       if (txtWarehouseReceipt !== ''|| ddWarehouseReceiptLocation !== '' ) {
        if (txtContract !== '' || txtTradeInspection !== '' || txtTradeSettlement !== '' || 
        txtFreightMemo !== '' ||txtDeliveryAgreement !== '' ) {
          alert(Alertmessage('The following values must NOT be specified when Warehouse Receipt Information is provided.:\n',
          ['Contract Number' ,
          'Trade Inspection Number',
          'Trade Settlement Number',
          'Freight Memo Number',
          'Delivery Agreement Number' ]))
          isbFormValid = false;
          if(txtContract !=='' ){
            document.getElementById('txtContract').focus();
          }else if(txtTradeInspection !=='' ){
            document.getElementById('txtTradeInspection').focus();
          }else if(txtTradeSettlement !=='' ){
            document.getElementById('txtTradeSettlement').focus();
          }else if(txtFreightMemo !=='' ){
            document.getElementById('txtFreightMemo').focus();
          }else if(txtDeliveryAgreement !=='' ){
            document.getElementById('txtDeliveryAgreement').focus();
          }
          return;
          if (txtFarm !== '' && txtInspectionSC95 == '' ) {
            alert(Alertmessage('When Warehouse Receipt Information is Provided with a Farm Number.:\n',
          ['Inspection (SC95) Number']))
           isbFormValid = false;
            return;
          } 
          if (txtWarehouseReceipt == '' ||ddWarehouseReceiptLocation == '') {
            alert(Alertmessage('When Warehouse Receipt Information is Provided.:\n',
          ['Whse Receipt Number',
          'Whse Receipt (Obligation) Location']))
           isbFormValid = false;
            return;
          }
        }
      }
       if ((txtFreightMemo !== '' )) {
        if (txtContract !== '' ||txtInspectionSC95 !== '' || txtSettlement1007 !== '' || txtTradeInspection !== '' ||
        txtTradeSettlement !== '' || txtWarehouseReceipt !== '' || ddWarehouseReceiptLocation !== '' || txtDeliveryAgreement !== '' ) {
          alert(Alertmessage('The following values must NOT be specified when Trade Inspection / Trade Settlement Information is provided:\n',
          ['Contract Number' ,
          'Inspection (SC95) Number',
          'Settlement (1007) Number',
          'Trade Inspection Number',
          ' Trade Settlement Number',
          'Whse Receipt Number',
          'Whse Receipt (Obligation) Location',
          'Delivery Agreement Number' ]))
          if(txtContract !=='' ){
            document.getElementById('txtContract').focus();
          }else if(txtInspectionSC95 !=='' ){
            document.getElementById('txtInspectionSC95').focus();
          }else if(txtSettlement1007 !=='' ){
            document.getElementById('txtSettlement1007').focus();
          }else if(txtTradeInspection !=='' ){
            document.getElementById('txtTradeInspection').focus();
          }else if(txtTradeSettlement !=='' ){
            document.getElementById('txtTradeSettlement').focus();
          }else if(txtWarehouseReceipt !=='' || txtWarehouseReceipt !==undefined){
            document.getElementById('txtWarehouseReceipt').focus();
          }else if(ddWarehouseReceiptLocation !=='' ){
            document.getElementById('ddWarehouseReceiptLocation').focus();
          }else if(txtDeliveryAgreement !=='' ){
            document.getElementById('txtDeliveryAgreement').focus();
          }
          isbFormValid = false;
          return;
        }
      }
       if (txtSettlement1007 !== '') {
        if (txtInspectionSC95 == '' ) {
          alert(Alertmessage('The following values MUST be specified.:\n',
          ['Inspection (SC95) Number']))
          document.getElementById('txtInspectionSC95').focus();
          isbFormValid = false;
          return;
        }
      }
       if (txtTradeSettlement !== '' ) {
        if (txtTradeInspection == '') {
          alert(Alertmessage('The following values MUST be specified.:\n',
          ['Trade Inspection Number']))
          document.getElementById('txtTradeInspection').focus();
          isbFormValid = false;
          return;
        }
      }
  
       if (txtDeliveryAgreement !== '' ) {
        if (txtContract !== '' || txtInspectionSC95 !== '' || txtSettlement1007 !== '' 
        || txtTradeInspection !== '' ||txtTradeSettlement !== '' || txtWarehouseReceipt !== '' 
        || ddWarehouseReceiptLocation !== '' || txtFreightMemo !== '' ) {
          alert(Alertmessage('The following values must NOT be specified when a Delivery Agreement number is specified:\n',
          ['Contract Number' ,
          'Inspection (SC95) Number',
          'Settlement (1007) Number',
          'Trade Inspection Number',
          ' Trade Settlement Number',
          'Whse Receipt Number',
          'Whse Receipt (Obligation) Location',
          'Freight Memo Number' ]))
          if(txtContract !=='' ){
            document.getElementById('txtContract').focus();
          }else if(txtInspectionSC95 !=='' ){
            document.getElementById('txtInspectionSC95').focus();
          }else if(txtSettlement1007 !=='' ){
            document.getElementById('txtSettlement1007').focus();
          }else if(txtTradeInspection !=='' ){
            document.getElementById('txtTradeInspection').focus();
          }else if(txtTradeSettlement !=='' ){
            document.getElementById('txtTradeSettlement').focus();
          }else if(txtWarehouseReceipt !==''){
            document.getElementById('txtWarehouseReceipt').focus();
          }else if(ddWarehouseReceiptLocation !=='' ){
            document.getElementById('ddWarehouseReceiptLocation').focus();
          }else if(txtFreightMemo !=='' ){
            document.getElementById('txtFreightMemo').focus();
          }
          isbFormValid = false;
            return;
        }
      }
       if (txtContract == '' && txtInspectionSC95 == '' && txtSettlement1007 == '' && txtTradeInspection == '' &&
      txtTradeSettlement == '' && txtWarehouseReceipt == '' && txtFreightMemo == '' && txtDeliveryAgreement == '' 
      ) {
        alert(Alertmessage('One of the following values must be specified when creating a ready to pay:\n',
        [		"Contract Number ",
        "Inspection (SC95) Number ",
        "Settlement (1007) Number ",
        "Trade Inspection Number ",
        "Trade Settlement Number ",
        "Whse Receipt Number ",
        "Freight Memo Number ",
        "Delivery Agreement Number "  ]))
        if(txtContract !=='' ){
          document.getElementById('txtContract').focus();
        }else if(txtInspectionSC95 !=='' ){
          document.getElementById('txtInspectionSC95').focus();
        }else if(txtSettlement1007 !=='' ){
          document.getElementById('txtSettlement1007').focus();
        }else if(txtTradeInspection !=='' ){
          document.getElementById('txtTradeInspection').focus();
        }else if(txtTradeSettlement !=='' ){
          document.getElementById('txtTradeSettlement').focus();
        }else if(txtWarehouseReceipt !=='' || txtWarehouseReceipt !==undefined){
          document.getElementById('txtWarehouseReceipt').focus();
        }else if(txtDeliveryAgreement !=='' ){
          document.getElementById('txtDeliveryAgreement').focus();
        }else if(txtFreightMemo !==''  ){
          document.getElementById('txtFreightMemo').focus();
        }
        isbFormValid = false;
        return;
      }else{
        isbFormValid = true;
      }
    }
  }
  const ontxtSettlement1007Blur = () => {
    try {
      let txtSettlement1007Change = getValue(thisObj, 'txtSettlement1007')
      var cont = ''
      if (txtSettlement1007Change > 0) {
        if (txtSettlement1007Change.length <= 7) {
          for (var i = 0; i < (7 - txtSettlement1007Change.length); i++) {
            cont += '0'
          }
        }
        var txtSettlement1007 = cont + txtSettlement1007Change;
        setValue(thisObj, 'txtSettlement1007', txtSettlement1007)
      } else {
        setValue(thisObj, 'txtSettlement1007', '')
      }
    }
    catch (err) {
    }

  };
  thisObj.ontxtSettlement1007Blur = ontxtSettlement1007Blur;

  const ontxtContractBlur = () => {
    try {
      let txtContractChange = getValue(thisObj, 'txtContract')
      var cont = ''
      if (txtContractChange > 0) {
        if (txtContractChange.length <= 6) {
          for (var i = 0; i < (6 - txtContractChange.length); i++) {
            cont += '0'
          }
        }
        var contractNumber = cont + txtContractChange;
        setValue(thisObj, 'txtContract', contractNumber)
      } else {
        setValue(thisObj, 'txtContract', '')
      }
    }
    catch (err) {
    }

  };
  thisObj.ontxtContractBlur = ontxtContractBlur;

  // Vendor click button
  const onbtnVendorClick = async () => {
    try {
      goTo(thisObj, "ContractManagement#VendorLookup#DEFAULT#true#Click");
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, "Error on Vendor click event")
      }
      return false
    }
  };
  thisObj.onbtnVendorClick = onbtnVendorClick;

  const bFillRemitToList = (txtVendorNumber) => {
    try {
      let js = [];
      if (txtVendorNumber != undefined && txtVendorNumber != null && txtVendorNumber.length == 6) {
        ContractManagementService.RetieveRemitDetails(txtVendorNumber).then(response => {
          if (response != undefined && response.length > 0) {
            let data = response
            for (var i = 0; i < data.length; i++) {
              var vendorName = ConvertToPascalCase(data[0].name)
              let obj = { key: data[i].remittoid, description: data[i].remittoid + " - " +ConvertToPascalCase(data[i].name) + " - " + ConvertToPascalCase(data[i].city) + " - " + ConvertToPascalCase(data[i].state) }
              js.push(obj)
            }
            setValue(thisObj, "txtVndr", vendorName)
            setData(thisObj, "remitToLength", js.length);
            setData(thisObj, 'vendorName', vendorName);
            thisObj.setState(current => {
              return {
                ...current,
                ddOBLLocation: {
                  ...state["ddOBLLocation"],
                  valueList: js,
                }
              }
            })
          }
        })
      }
     
      setData(thisObj, "remitToLength", null);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred in setting value to Vendor Number textbox"
        );
      }
    }
  };
  const ontxtFarmBlur = () => {
    let txtFarmv = getValue(thisObj, 'txtFarm')
    if (txtFarmv !== '' && txtFarmv !== undefined) {
      setValue(thisObj, 'txtFarm', txtFarmv.toUpperCase())
    }

  }
  thisObj.ontxtFarmBlur = ontxtFarmBlur

  const ontxtFarmSuffixBlur = () => {
    let txtFarmSuffixu = getValue(thisObj, 'txtFarmSuffix')
    if (txtFarmSuffixu !== '' && txtFarmSuffixu !== undefined) {
      setValue(thisObj, 'txtFarmSuffix', txtFarmSuffixu.toUpperCase())
    }

  }
  thisObj.ontxtFarmSuffixBlur = ontxtFarmSuffixBlur

  const ontxtInspectionSC95Blur = () => {
    let txtInspectionSC95u = getValue(thisObj, 'txtInspectionSC95')
    if (txtInspectionSC95u !== '' && txtInspectionSC95u !== undefined) {
      setValue(thisObj, 'txtInspectionSC95', (txtInspectionSC95u.toUpperCase()))
    }

  }
  thisObj.ontxtInspectionSC95Blur = ontxtInspectionSC95Blur

  const ontxtTradeInspectionBlur = () => {
    let txtTradeInspectionu = getValue(thisObj, 'txtTradeInspection')
    if (txtTradeInspectionu !== '' && txtTradeInspectionu !== undefined) {
      setValue(thisObj, 'txtTradeInspection', txtTradeInspectionu.toUpperCase())
    }
  }
  thisObj.ontxtTradeInspectionBlur = ontxtTradeInspectionBlur

  const ontxtProceedsBlur = (value) => {
    try {
      let rate = getValue(thisObj, 'txtProceeds') == undefined ? '' : getValue(thisObj, 'txtProceeds');
      if (Number(rate).toFixed(2) != 'NaN'){
        if (rate.length != 0 && rate.length < 10) {
          rate = Number(rate).toFixed(2)
        } else if (rate.length == 10) {
          rate = Number(rate).toFixed(1)
        } else if (rate.length == 11) {
          rate = rate + '.'
        }
        setValue(thisObj, 'txtProceeds', rate);
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for txt Proceeds."
        );
      }
      return false;
    }
  };
  thisObj.ontxtProceedsBlur = ontxtProceedsBlur;

  const ontxtStateBlur = () => {
    let txtStateu = getValue(thisObj, 'txtState')
    if (txtStateu !== '' && txtStateu !== undefined) {
      setValue(thisObj, 'txtState', txtStateu.toUpperCase())
    }
  }
  thisObj.ontxtStateBlur = ontxtStateBlur

  const ontxtVendorBlur = () => {
    try {
      let txtVendorNumber = getValue(thisObj, 'txtVendor')
      if (txtVendorNumber != undefined && txtVendorNumber != null && txtVendorNumber.length == 6) {
        bFillRemitToList(txtVendorNumber)
      } else {
        setValue(thisObj, 'txtVndr', '')
        disable(thisObj, 'txtVndr')
        let js=[]
        thisObj.setState(current => {
          return {
            ...current,
            ddOBLLocation: {
              ...state["ddOBLLocation"],
              valueList: js,
            }
          }
        })
        document.getElementsByClassName("txtVndr")[0].style.visibility = "hidden";
      }
      setData(thisObj, "remitToLength", null);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, "Error on txtVendor change event")
      }
      return false
    }
    return true;
  }
  thisObj.ontxtVendorBlur = ontxtVendorBlur;

  const ontxtWarehouseReceiptBlur = () => {
    try {
      let txtWarehouseReceiptChange = getValue(thisObj, 'txtWarehouseReceipt')
      var cont = ''
      if (txtWarehouseReceiptChange > 0) {
        if (txtWarehouseReceiptChange.length <= 6) {
          for (var i = 0; i < (6 - txtWarehouseReceiptChange.length); i++) {
            cont += '0'
          }
        }
        var WarehouseReceipt = cont + txtWarehouseReceiptChange;
        setValue(thisObj, 'txtWarehouseReceipt', WarehouseReceipt)
      }
      else {
        setValue(thisObj, 'txtWarehouseReceipt', '')
      }
    }
    catch (err) {
    }

  };
  thisObj.ontxtWarehouseReceiptBlur = ontxtWarehouseReceiptBlur;

  const ontxtTradeSettlementBlur = () => {
    try {
      let ontxtTradeSettlementChange = getValue(thisObj, 'txtTradeSettlement')
      var cont = ''
      if (ontxtTradeSettlementChange > 0) {
        if (ontxtTradeSettlementChange.length <= 7) {
          for (var i = 0; i < (7 - ontxtTradeSettlementChange.length); i++) {
            cont += '0'
          }
        }
        var txtTradeSettlementchan = cont + ontxtTradeSettlementChange;
        setValue(thisObj, 'txtTradeSettlement', txtTradeSettlementchan)
      }
      else {
        setValue(thisObj, 'txtTradeSettlement', '')
      }
    }
    catch (err) {
    }

  };
  thisObj.ontxtTradeSettlementBlur = ontxtTradeSettlementBlur;

  // const ontxtDeliveryAgreementChange = () => {
  //   try {
  //     let txtDeliveryAgreementChange = parseInt(getValue(thisObj, 'txtDeliveryAgreement'))
  //     if (txtDeliveryAgreementChange > 0) {
  //       setValue(thisObj, 'txtDeliveryAgreement', txtDeliveryAgreementChange)
  //     } else {
  //       setValue(thisObj, 'txtDeliveryAgreement', '')
  //     }
  //   }
  //   catch (err) {
  //   }

  // };
  // thisObj.ontxtDeliveryAgreementChange = ontxtDeliveryAgreementChange;

  const ontxtDeliveryAgreementBlur = () => {
    try {
      let txtDeliveryAgreementChange = getValue(thisObj, 'txtDeliveryAgreement')
      if (txtDeliveryAgreementChange > 0) {
        setValue(thisObj, 'txtDeliveryAgreement', txtDeliveryAgreementChange)
      } else {
        setValue(thisObj, 'txtDeliveryAgreement', '')
      }
    }
    catch (err) {
    }

  };
  thisObj.ontxtDeliveryAgreementBlur = ontxtDeliveryAgreementBlur;

  const onbtnOkClick = async () => {
    try {
      let ddLocation = getValue(thisObj, 'ddLocation') !==undefined ? getValue(thisObj, 'ddLocation') :''
      let txtVendor = getValue(thisObj, 'txtVendor') !==undefined? getValue(thisObj, 'txtVendor') :''
      let ddOBLLocation = getValue(thisObj, 'ddOBLLocation') !==undefined? getValue(thisObj, 'ddOBLLocation') :''
      let ddStatus = getValue(thisObj, 'ddStatus')!==undefined ? getValue(thisObj, 'ddStatus') :''
      let txtProceeds = getValue(thisObj, 'txtProceeds') !==undefined ? getValue(thisObj, 'txtProceeds') :''
      let txtContract = getValue(thisObj, 'txtContract') !==undefined ? getValue(thisObj, 'txtContract') :''
      let txtInspectionSC95 = getValue(thisObj, 'txtInspectionSC95') !==undefined ? getValue(thisObj, 'txtInspectionSC95') :''
      let txtSettlement1007 = getValue(thisObj, 'txtSettlement1007') !==undefined ? getValue(thisObj, 'txtSettlement1007') :''
      let txtDeliveryAgreement = getValue(thisObj, 'txtDeliveryAgreement') !==undefined ? getValue(thisObj, 'txtDeliveryAgreement') :''
      let txtFreightMemo = getValue(thisObj, 'txtFreightMemo') !==undefined ? getValue(thisObj, 'txtFreightMemo') :''
      let txtTradeInspection = getValue(thisObj, 'txtTradeInspection') !==undefined ? getValue(thisObj, 'txtTradeInspection') :''
      let txtTradeSettlement = getValue(thisObj, 'txtTradeSettlement') !==undefined ? getValue(thisObj, 'txtTradeSettlement') :''
      let txtWarehouseReceipt = getValue(thisObj, 'txtWarehouseReceipt') !==undefined ? getValue(thisObj, 'txtWarehouseReceipt') :''
      let ddWarehouseReceiptLocation = getValue(thisObj, 'ddWarehouseReceiptLocation') !==undefined ? getValue(thisObj, 'ddWarehouseReceiptLocation') :''
      let txtState = getValue(thisObj, 'txtState') !==undefined ? getValue(thisObj, 'txtState') :''
      let txtCounty = getValue(thisObj, 'txtCounty') !==undefined ? getValue(thisObj, 'txtCounty') :''
      let txtFarm = getValue(thisObj, 'txtFarm') !==undefined ? getValue(thisObj, 'txtFarm') :''
      let txtFarmSuffix = getValue(thisObj, 'txtFarmSuffix') !==undefined ? getValue(thisObj, 'txtFarmSuffix') :''
      let ddOptions = getValue(thisObj, 'ddOptions') !==undefined ? getValue(thisObj, 'ddOptions') :''
      let ddStorageInd = getValue(thisObj, 'ddStorageInd') !==undefined ? getValue(thisObj, 'ddStorageInd') :''
      let ddShrinkPayment = getValue(thisObj, 'ddShrinkPayment') !==undefined ? getValue(thisObj, 'ddShrinkPayment') :''
      let ddLoanReduction = getValue(thisObj, 'ddLoanReduction') !==undefined ? getValue(thisObj, 'ddLoanReduction') :''
      let txtReason = getValue(thisObj, 'txtReason') !==undefined ? getValue(thisObj, 'txtReason') :''
      let lblAddedBy = getValue(thisObj, 'lblAddedBy') !==undefined ? getValue(thisObj, 'lblAddedBy') :''
      let lblChangedBy = getValue(thisObj, 'lblChangedBy') !==undefined ? getValue(thisObj, 'lblChangedBy') :''
        
      let obj = {}
      obj.user_id = useridFromLS
      obj.audit_reason = txtReason
      obj.comp_id = compIdFromLS
      obj.crop_year = cropYearFromLS
      obj.buy_pt_id = ddLocation
      obj.new_payee_vendor = txtVendor
      obj.new_payee_remit = ddOBLLocation
      obj.new_rtp_status = ddStatus
      obj.new_rtp_proceeds = txtProceeds
      obj.new_cont_num = txtContract
      obj.new_insp_num = txtInspectionSC95
      obj.new_settle_num = txtSettlement1007
      obj.new_whse_rcpt = txtWarehouseReceipt
      obj.new_whse_bp = ddWarehouseReceiptLocation
      obj.new_state_abbr = txtState
      obj.new_county_id = txtCounty
      obj.new_farm_id = txtFarm
      obj.new_farm_suffix = txtFarmSuffix
      obj.new_opt_pay_ind = ddOptions
      obj.new_trade_insp = txtTradeInspection
      obj.new_trade_settle = txtTradeSettlement
      obj.new_shrink_pay_ind = ddShrinkPayment
      obj.new_storage_handling_pay_ind = 'N'
      obj.new_fa_memo_num = txtFreightMemo
      obj.new_delv_agree_num = txtDeliveryAgreement
      obj.new_loan_reduct_ind = ddLoanReduction
      let frmManualRTPMaintdata = getData(thisObj, 'frmManualRTPMaintdata');
      if (frmManualRTPMaintdata !== null) {
        let data = frmManualRTPMaintdata;
        if (data[0].cmdOk !== LC_DELETE) {
          await bformvaild()
          if(isbFormValid == true){
          switch (data[0].cmdOk) {
            case LC_ADD:
              setLoading(true)
              let CreateManualReadyToPayMaintenance = await SystemMaintenanceApplicationSupportService.CreateManualReadyToPayMaintenance(obj);
              if (CreateManualReadyToPayMaintenance.status == 200) {
                setLoading(false)
                alert('Manual Ready to Pay was Successfully Created!')
                setData(thisObj, "mbRefresh", "true")
                document.getElementById("SystemMaintenanceApplicationSupport_ManualRTPMaintPopUp").childNodes[0].click()
              }else{
                setLoading(false)
                showMessage(thisObj, 'Ready to Pay was not Created!', false)
              }
              setLoading(false)
              break;
            case LC_EDIT:
              setLoading(true)
              let UpdateManualReadyToPayMaintenance = await SystemMaintenanceApplicationSupportService.UpdateManualReadyToPayMaintenance(data[0].lblTransactionNumber, obj);
              if (UpdateManualReadyToPayMaintenance.status == 200) {
                setLoading(false)
                alert( 'Ready to Pay was Successfully Updated!')
                setData(thisObj, "mbRefresh", "true")
                document.getElementById("SystemMaintenanceApplicationSupport_ManualRTPMaintPopUp").childNodes[0].click()
              }else{
                setLoading(false)
                showMessage(thisObj, 'Ready to Pay was not Updated!', false)
              }
              setLoading(false)
              break;
          }
        }
        }else{
          switch (data[0].cmdOk) {
            case LC_DELETE:
              setLoading(true)
               if (confirm(`W A R N I N G: This will remove the Ready To Pay permanently. This action cannot be undone!`) == true) {
                 let RemoveManualReadyToPayMaintenance = await SystemMaintenanceApplicationSupportService.RemoveManualReadyToPayMaintenance(data[0].lblTransactionNumber, txtReason, obj);
                 if (RemoveManualReadyToPayMaintenance.status == 200) {
                  setLoading(false)
                   alert('Ready to Pay was Successfully Deleted!')
                   setData(thisObj, "mbRefresh", "Delete")
                   document.getElementById("SystemMaintenanceApplicationSupport_ManualRTPMaintPopUp").childNodes[0].click()
                 }
               }
               setLoading(false)
         }
        }
         
      }

    }
    catch (err) {
    }
  }
  thisObj.onbtnOkClick = onbtnOkClick

  const onbtnCancelClick = () => {
    try {

      document.getElementById("SystemMaintenanceApplicationSupport_ManualRTPMaintPopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during btnCancel click event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnCancelClick = onbtnCancelClick;

  const ontxtFreightMemoChange = () => {
    let FreigtmemoValue = getValue(thisObj, 'txtFreightMemo')
    setValue(thisObj, "txtFreightMemo", validateTextBox1(FreigtmemoValue))
  };
  thisObj.ontxtFreightMemoChange = ontxtFreightMemoChange


  const validateTextBox1 = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 46)) {
        res += data[i]
      }
    }
    return res;
  }
  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ManualRTPMaint*/}

              {/* END_USER_CODE-USER_BEFORE_ManualRTPMaint*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxManualRTPMaint*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxManualRTPMaint*/}

              <GroupBoxWidget
                conf={state.grpbxManualRTPMaint}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddLocation*/}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVe}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddLocation*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                {/* START_USER_CODE-USER_BEFORE_ddOBLLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddOBLLocation*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddOBLLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddOBLLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddOBLLocation*/}
                {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                <ButtonWidget
                  conf={state.btnVendor}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                {/* START_USER_CODE-USER_BEFORE_ddStatus*/}

                {/* END_USER_CODE-USER_BEFORE_ddStatus*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddStatus}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddStatus*/}

                {/* END_USER_CODE-USER_AFTER_ddStatus*/}
                {/* START_USER_CODE-USER_BEFORE_txtVndr*/}

                {/* END_USER_CODE-USER_BEFORE_txtVndr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVndr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVndr*/}

                {/* END_USER_CODE-USER_AFTER_txtVndr*/}
                {/* START_USER_CODE-USER_BEFORE_txtProceeds*/}

                {/* END_USER_CODE-USER_BEFORE_txtProceeds*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtProceeds}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtProceeds*/}

                {/* END_USER_CODE-USER_AFTER_txtProceeds*/}
                {/* START_USER_CODE-USER_BEFORE_txtDeliveryAgreement*/}

                {/* END_USER_CODE-USER_BEFORE_txtDeliveryAgreement*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDeliveryAgreement}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDeliveryAgreement*/}

                {/* END_USER_CODE-USER_AFTER_txtDeliveryAgreement*/}
                {/* START_USER_CODE-USER_BEFORE_txtContract*/}

                {/* END_USER_CODE-USER_BEFORE_txtContract*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtContract}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtContract*/}

                {/* END_USER_CODE-USER_AFTER_txtContract*/}
                {/* START_USER_CODE-USER_BEFORE_txtFreightMemo*/}

                {/* END_USER_CODE-USER_BEFORE_txtFreightMemo*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFreightMemo}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFreightMemo*/}

                {/* END_USER_CODE-USER_AFTER_txtFreightMemo*/}
                {/* START_USER_CODE-USER_BEFORE_txtInspectionSC95*/}

                {/* END_USER_CODE-USER_BEFORE_txtInspectionSC95*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtInspectionSC95}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtInspectionSC95*/}

                {/* END_USER_CODE-USER_AFTER_txtInspectionSC95*/}
                {/* START_USER_CODE-USER_BEFORE_txtTradeInspection*/}

                {/* END_USER_CODE-USER_BEFORE_txtTradeInspection*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTradeInspection}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTradeInspection*/}

                {/* END_USER_CODE-USER_AFTER_txtTradeInspection*/}
                {/* START_USER_CODE-USER_BEFORE_txtTradeSettlement*/}

                {/* END_USER_CODE-USER_BEFORE_txtTradeSettlement*/}


                {/* START_USER_CODE-USER_BEFORE_txtSettlement1007*/}

                {/* END_USER_CODE-USER_BEFORE_txtSettlement1007*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSettlement1007}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSettlement1007*/}

                {/* END_USER_CODE-USER_AFTER_txtSettlement1007*/}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTradeSettlement}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTradeSettlement*/}

                {/* END_USER_CODE-USER_AFTER_txtTradeSettlement*/}
                {/* START_USER_CODE-USER_BEFORE_txtWarehouseReceipt*/}

                {/* END_USER_CODE-USER_BEFORE_txtWarehouseReceipt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWarehouseReceipt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtWarehouseReceipt*/}

                {/* END_USER_CODE-USER_AFTER_txtWarehouseReceipt*/}
                {/* START_USER_CODE-USER_BEFORE_ddWarehouseReceiptLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddWarehouseReceiptLocation*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddWarehouseReceiptLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddWarehouseReceiptLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddWarehouseReceiptLocation*/}
                {/* START_USER_CODE-USER_BEFORE_txtState*/}

                {/* END_USER_CODE-USER_BEFORE_txtState*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtState}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtState*/}

                {/* END_USER_CODE-USER_AFTER_txtState*/}
                {/* START_USER_CODE-USER_BEFORE_txtCounty*/}

                {/* END_USER_CODE-USER_BEFORE_txtCounty*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCounty}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCounty*/}

                {/* END_USER_CODE-USER_AFTER_txtCounty*/}
                {/* START_USER_CODE-USER_BEFORE_txtFarm*/}

                {/* END_USER_CODE-USER_BEFORE_txtFarm*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarm}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFarm*/}

                {/* END_USER_CODE-USER_AFTER_txtFarm*/}
                {/* START_USER_CODE-USER_BEFORE_txtFarmSuffix*/}

                {/* END_USER_CODE-USER_BEFORE_txtFarmSuffix*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarmSuffix}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFarmSuffix*/}

                {/* END_USER_CODE-USER_AFTER_txtFarmSuffix*/}
                {/* START_USER_CODE-USER_BEFORE_ddOptions*/}

                {/* END_USER_CODE-USER_BEFORE_ddOptions*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddOptions}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddOptions*/}

                {/* END_USER_CODE-USER_AFTER_ddOptions*/}

                {/* START_USER_CODE-USER_BEFORE_ddStorageInd*/}

                {/* END_USER_CODE-USER_BEFORE_ddStorageInd*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddStorageInd}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddStorageInd*/}

                {/* END_USER_CODE-USER_AFTER_ddStorageInd*/}

                {/* START_USER_CODE-USER_BEFORE_ddShrinkPayment*/}

                {/* END_USER_CODE-USER_BEFORE_ddShrinkPayment*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddShrinkPayment}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddShrinkPayment*/}

                {/* END_USER_CODE-USER_AFTER_ddShrinkPayment*/}
                {/* START_USER_CODE-USER_BEFORE_ddLoanReduction*/}

                {/* END_USER_CODE-USER_BEFORE_ddLoanReduction*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddLoanReduction}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddLoanReduction*/}

                {/* END_USER_CODE-USER_AFTER_ddLoanReduction*/}
                {/* START_USER_CODE-USER_BEFORE_txtReason*/}

                {/* END_USER_CODE-USER_BEFORE_txtReason*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtReason}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtReason*/}

                {/* END_USER_CODE-USER_AFTER_txtReason*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxManualRTPMaint*/}

              {/* END_USER_CODE-USER_AFTER_grpbxManualRTPMaint*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_ManualRTPMaint*/}

              {/* END_USER_CODE-USER_AFTER_ManualRTPMaint*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceApplicationSupport_ManualRTPMaint);
