/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  LabelWidget,
  setValue,
  getValue,
  setData,
  getData,
  show,
  hide,
  goTo,
  enable,
  disable,
} from "../../shared/WindowImports";

import "./VendorProfile.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { returnNumericValues } from './../../Common/Constants';
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_VendorProfile(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "VendorProfile",
    windowName: "VendorProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.VendorProfile",
    // START_USER_CODE-USER_VendorProfile_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Vendor Profile",
      scrCode: "PN0030C",
    },
    // END_USER_CODE-USER_VendorProfile_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    btnVendorMaintenance: {
      name: "btnVendorMaintenance",
      type: "ButtonWidget",
      parent: "grpbxVendorDetails",
      Label: "Vendor Maintenance",
      CharWidth: "37",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendorMaintenance_PROPERTIES

      // END_USER_CODE-USER_btnVendorMaintenance_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxVendorDetails",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    ddRemitTo: {
      name: "ddRemitTo",
      type: "DropDownFieldWidget",
      parent: "grpbxVendorDetails",
      Label: "Remit To:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRemitTo_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo_PROPERTIES
    },
    grpbxSuffix: {
      name: "grpbxSuffix",
      type: "GroupBoxWidget",
      parent: "grpbxVendorDetails",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "4",
      // START_USER_CODE-USER_grpbxSuffix_PROPERTIES

      // END_USER_CODE-USER_grpbxSuffix_PROPERTIES
    },
    lblSuffix: {
      name: "lblSuffix",
      type: "LabelWidget",
      parent: "grpbxSuffix",
      Label: "Suffix",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSuffix_PROPERTIES

      // END_USER_CODE-USER_lblSuffix_PROPERTIES
    },
    txt211Concordia: {
      name: "txt211Concordia",
      type: "TextBoxWidget",
      parent: "grpbxVendorDetails",
      Label: "211 Concordia:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt211Concordia_PROPERTIES

      // END_USER_CODE-USER_txt211Concordia_PROPERTIES
    },
    txt211GPC: {
      name: "txt211GPC",
      type: "TextBoxWidget",
      parent: "grpbxVendorDetails",
      Label: "211 GPC:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt211GPC_PROPERTIES

      // END_USER_CODE-USER_txt211GPC_PROPERTIES
    },
    txtAddedBy: {
      name: "txtAddedBy",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddedBy_PROPERTIES

      // END_USER_CODE-USER_txtAddedBy_PROPERTIES
    },
    txtBuyingPoint: {
      name: "txtBuyingPoint",
      type: "TextBoxWidget",
      parent: "grpbxVendorDetails",
      Label: "Buying Point:",
      ColSpan: "3",
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtBuyingPoint_PROPERTIES
    },
    txtChangedBy: {
      name: "txtChangedBy",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtChangedBy_PROPERTIES

      // END_USER_CODE-USER_txtChangedBy_PROPERTIES
    },
    txtCounty: {
      name: "txtCounty",
      type: "TextBoxWidget",
      parent: "grpbxVendorDetails",
      Label: "County:",
      ColSpan: "3",
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCounty_PROPERTIES

      // END_USER_CODE-USER_txtCounty_PROPERTIES
    },
    txtFarm: {
      name: "txtFarm",
      type: "TextBoxWidget",
      parent: "grpbxVendorDetails",
      Label: "Farm:",
      ColSpan: "4",
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarm_PROPERTIES

      // END_USER_CODE-USER_txtFarm_PROPERTIES
    },
    txtRemitTo: {
      name: "txtRemitTo",
      type: "TextBoxWidget",
      parent: "grpbxVendorDetails",
      ColSpan: "2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRemitTo_PROPERTIES

      // END_USER_CODE-USER_txtRemitTo_PROPERTIES
    },
    txtSharePercent: {
      name: "txtSharePercent",
      type: "TextBoxWidget",
      parent: "grpbxVendorDetails",
      Label: "Share %:",
      ColSpan: "4",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercent_PROPERTIES

      // END_USER_CODE-USER_txtSharePercent_PROPERTIES
    },
    txtState: {
      name: "txtState",
      type: "TextBoxWidget",
      parent: "grpbxVendorDetails",
      Label: "State:",
      ColSpan: "3",
      ReadOnly: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtState_PROPERTIES

      // END_USER_CODE-USER_txtState_PROPERTIES
    },
    txtVendor1: {
      name: "txtVendor1",
      type: "TextBoxWidget",
      parent: "grpbxVendorDetails",
      ColSpan: "2",
      HasLabel: false,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendorVP: {
      name: "txtVendorVP",
      type: "TextBoxWidget",
      parent: "grpbxVendorDetails",
      Label: "Vendor:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    grpbxVendorDetails: {
      name: "grpbxVendorDetails",
      type: "GroupBoxWidget",
      parent: "VendorProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxVendorDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorDetails_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "VendorProfile",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceApplicationSupport#VendorMaintenance": {},
        "ContractManagement#MainMenu": {},
      },
      REVERSE: {
        "SystemMaintenanceApplicationSupport#VendorMaintenance": {},
        "ContractManagement#MainMenu": {},
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btnCancel: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    },
    btnVendor: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    }

  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    formLoad()
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  useEffect(() => {
    let vendor_profile = getData(thisObj, 'vendorprofileDetails')
    let vendor_profile_details = vendor_profile[0];
    if (vendor_profile_details !== null && vendor_profile_details !== undefined && vendor_profile_details !== '') {
      setValue(thisObj, "txtBuyingPoint", vendor_profile_details.lblBuyingPointCaption)
      setValue(thisObj, "txtState", vendor_profile_details.lblStateCaption)
      setValue(thisObj, "txtCounty", vendor_profile_details.lblCountyCaption)
      setValue(thisObj, "txtFarm", vendor_profile_details.lblFarmCaption + " " + vendor_profile_details.lblSuffixCaption)
      if (vendor_profile_details.cmdOkCaption == "Update") {
        setValue(thisObj, "txtChangedBy", vendor_profile_details.lblChangedByCaption)
        setValue(thisObj, "txtAddedBy", vendor_profile_details.lblAddedByCaption)
        setValue(thisObj, "txtVendorVP", vendor_profile_details.txtVendorText)
        setValue(thisObj, "txtSharePercent", Number(vendor_profile_details.txtSharePercent))

      }
      show(thisObj, "txtVendor1");
      document.getElementsByClassName("btnOkButton")[1].innerHTML = vendor_profile_details.cmdOkCaption;
      if (vendor_profile_details.txtVendorEnabled == false) {
        disable(thisObj, "txtVendorVP");
        disable(thisObj, "btnVendor");
      }
      else {
        enable(thisObj, "txtVendorVP")
        enable(thisObj, "btnVendor");
      }
      if (vendor_profile_details.cmdOkCaption == "Update") {
        bFillRemitToList(vendor_profile_details.txtVendorText, vendor_profile_details.remitTo)
      }
      else {
        let js = [];
        js.push({ key: '', description: '>>>Any Remit-To<<<' })
        thisObj.setState(current => {
          return {
            ...current,
            ddRemitTo: {
              ...state["ddRemitTo"],
              valueList: js,
            }
          }
        })
        bFillRemitToList(getValue(thisObj, "txtVendorVP"), "000")
      }
    }
  }, [getData(thisObj, 'vendorprofileDetails')]);


  useEffect(() => {
    let js = [];
    js.push({ key: '', description: '>>>Any Remit-To<<<' })
    thisObj.setState(current => {
      return {
        ...current,
        ddRemitTo: {
          ...state["ddRemitTo"],
          valueList: js,
        }
      }
    })
    let vendor_lookup = getData(thisObj, 'vendorDetails')
    if (vendor_lookup !== null && vendor_lookup !== undefined && vendor_lookup !== '') {

      setValue(thisObj, "txtVendorVP", vendor_lookup.VendorNumber);
      let VenNumber = vendor_lookup.VendorNumber
      show(thisObj, "txtVendor1");
      bFillRemitToList(vendor_lookup.VendorNumber, "000")
    }
    else {
      hide(thisObj, "txtVendor1", false)
    }
  }, [getData(thisObj, 'vendorDetails')]);

  const formLoad = () => {
    setSecurity()
    setValue(thisObj, "txtVendorVP", "");
    setValue(thisObj, "txtVendor1", '')
    hide(thisObj, "lblSuffix", false);
    hide(thisObj, "txtRemitTo", false);
    setLoading(false);
  }
  //checking permissions for accessing vendor maintenance screen
  const setSecurity = () => {
    ContractManagementService.RetrieveAccessPermissionDetails('PN0350', null, 'I')
      .then(response => {
        let secrtyResp = response
        if ((secrtyResp[0].permission).toUpperCase() === "Y") {
          show(thisObj, "btnVendorMaintenance")
        } else {
          hide(thisObj, "btnVendorMaintenance", false)
        }
      })
  }
  //Open vendor maintenance screen
  const onbtnVendorMaintenanceClick = () => {
    try {
      let vendornumber = getValue(thisObj, "txtVendorVP");
      if (vendornumber != "" && vendornumber != null && vendornumber != undefined) {
        let obj = {
          vendorNumber: vendornumber,
          optMinotityOwned:"Not Disclosed"
        }
        setData(thisObj, 'frmVendorMaintenance', obj)
        goTo(thisObj, "SystemMaintenanceApplicationSupport#VendorMaintenance#DEFAULT#true#Click");
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      } else {
        showMessage(thisObj, "Error on button Vendor Maintenance Click event")
      }
      return false
    }
    return true;
  }
  thisObj.onbtnVendorMaintenanceClick = onbtnVendorMaintenanceClick;
  //Add and Edit vendor  from vendor profile screen
  const onbtnOkClick = async (event) => {
    let response
    let VendorNumber = getValue(thisObj, "txtVendorVP");
    let remitto = getValue(thisObj, "ddRemitTo");
    let sharepercent = getValue(thisObj, "txtSharePercent");
    if (VendorNumber == null || VendorNumber == "" || VendorNumber == undefined || remitto == "" || remitto == -1 ) {
      showMessage(thisObj, "A Vendor Number for this vendor profile is invalid!!!")
      document.getElementById('txtVendorVP').focus();
      return false;
    }
    if (sharepercent == "" || sharepercent == null || sharepercent == undefined  ) {
      showMessage(thisObj, "Share Percentage must be greater than 0!!!")
      document.getElementById('txtSharePercent').focus();
      return false;
    }
    else {
      if (sharepercent > 100 || ( sharepercent!="" && sharepercent!=undefined && isNaN(sharepercent))) {
        showMessage(thisObj, "Share Percentage cannot be greater than 100!!!")
        document.getElementById('txtSharePercent').focus();
        return false;
      }
    }

    let vendor_profile = getData(thisObj, 'vendorprofileDetails')
    let vendor_profile_details = vendor_profile[0];
    let buyptid = vendor_profile_details.lblBuyingPointTag
    let stateid = vendor_profile_details.lblStateTag;
    let countyid = vendor_profile_details.lblCountyTag;
    let farmid = vendor_profile_details.lblFarmCaption;
    let farmsuffix = vendor_profile_details.lblSuffixCaption;
    let vendornumber = getValue(thisObj, "txtVendorVP");
    let btntype = vendor_profile_details.cmdOkCaption
    if (btntype == "Add") {
      response = await ContractManagementService.RetrieveFarmVendorSplitDetails(buyptid, stateid, countyid, farmid, farmsuffix, vendornumber)
      if (response.length > 0) {
        showMessage(thisObj, "This vendor already exists on this farm.")
        return false;
      }
      else {
        let dataObj = {
          "state_abbr": stateid,
          "county_id": countyid,
          "farm_id": farmid,
          "farm_suffix": farmsuffix,
          "split_vendor": vendornumber,
          "split_remit": getValue(thisObj, "ddRemitTo"),
          "split_share_pct": sharepercent

        };
        response = await SystemMaintenanceMasterManagementService.CreateFarmVendorSplit(buyptid, dataObj)
        if (response.status == 200) {
          let js = [];
          let obj = {
            VendorNumber: vendornumber, vendorName: getValue(thisObj, "txtVendor1"), SharePercent: getValue(thisObj, "txtSharePercent")
          }
          js.push(obj)
          setData(thisObj, 'dataEnableDropDownsVendorProfile', js)
          document.getElementById("SystemMaintenanceMasterManagement_VendorProfilePopUp").childNodes[0].click();
        }
        else {
          showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.')
        }
      }
    }
    if (btntype == "Update") {
      let dataObj = {
        "split_remit": getValue(thisObj, "ddRemitTo"),
        "split_share_pct": sharepercent

      };
      response = await SystemMaintenanceMasterManagementService.UpdateFarmVendorSplit(buyptid, stateid, countyid, farmid, farmsuffix, vendornumber, dataObj)
      if (response.status == 200) {
        let js = [];
        let obj = {
          VendorNumber: vendornumber, vendorName: getValue(thisObj, "txtVendor1"), SharePercent: getValue(thisObj, "txtSharePercent")
        }
        js.push(obj)
        setData(thisObj, 'dataEnableDropDownsVendorProfile', js)
        setData(thisObj, 'vendorprofileDetails', '')
        document.getElementById("SystemMaintenanceMasterManagement_VendorProfilePopUp").childNodes[0].click();
      }
      else {
        showMessage(thisObj, 'An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.')
        return
      }
    }
    setData(thisObj, 'vendorDetails', null);
  }
  thisObj.onbtnOkClick = onbtnOkClick;
  //closing the vendor profile screen
  const onbtnCancelClick = () => {
    setData(thisObj, 'dataEnableDropDownsVendorProfile', "")
    setData(thisObj, 'vendorprofileDetails', '')
    setData(thisObj, 'vendorDetails', null);
    document.getElementById("SystemMaintenanceMasterManagement_VendorProfilePopUp").childNodes[0].click();
  }
  thisObj.onbtnCancelClick = onbtnCancelClick;
  //Searching the vendors with vendor number from vendor textbox
  const ontxtVendorVPChange = () => {
    try {
      let txtVendorNumber = getValue(thisObj, "txtVendorVP")
      if (txtVendorNumber != undefined && txtVendorNumber != null) {
        if (txtVendorNumber.length == 6) {
          bFillRemitToList(txtVendorNumber, "000")
        }
        else {
          setValue(thisObj, "txtVendor1", '');
          hide(thisObj, "txtVendor1", false);
          const emptyValueList = [{ key: '', description: '>>>Any Remit-To<<<' }];
          thisObj.setState(current => {
            return {
              ...current,
              ddRemitTo: {
                ...state["ddRemitTo"],
                valueList: emptyValueList
              }
            }
          });
          setValue(thisObj, "ddRemitTo", "")
        }

      }

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during ontxtVendorVPChange click event"
        );
      }
      return false;
    }
  }
  thisObj.ontxtVendorVPChange = ontxtVendorVPChange;
  //Binding the locations to the Buying Point dropdown
  const bFillRemitToList = async (txtVendorNumber, remitto) => {
    try{
    setLoading(true);
    let resppp = await ContractManagementService.RetrieveVendorByNumber(txtVendorNumber)
    if (resppp != null && resppp.length > 0 && resppp != undefined) {
      show(thisObj, "txtVendor1");
      setValue(thisObj, 'txtVendor1', resppp[0].name)

      let js = []
      js.push({ key: '', description: '>>>Any Remit-To<<<' })
      let response = await ContractManagementService.RetieveRemitDetails(txtVendorNumber)
      if (response != undefined && response.length > 0) {
        let data = response
        for (var i = 0; i < data.length; i++) {
          var vendorName = data[i].name
          let obj = { key: data[i].remittoid, description: data[i].remittoid + " - " + data[i].name + " - " + data[i].city + " - " + data[i].state }
          if (data[i].number == "000") {
            setValue(thisObj, "txt211GPC", data[i].golden_form_211)
            setValue(thisObj, "txt211Concordia", data[i].concordia_form_211)
          }
          js.push(obj)
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddRemitTo: {
              ...state["ddRemitTo"],
              valueList: js,
            }
          }
        })
       // setData(thisObj, 'vendorDetails', null);
      }
      else {
        hide(thisObj, 'txtVendor1', false)
        setValue(thisObj, "txtVendorVP", '')

      }
      setValue(thisObj, "ddRemitTo", remitto)
    }
    setLoading(false);
  } catch (err) {
    setLoading(false);
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Error occurred during bFillRemitToList click event"
      );
    }
    return false;
  }
  }
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_VendorProfile*/}

              {/* END_USER_CODE-USER_BEFORE_VendorProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxVendorDetails*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxVendorDetails*/}

              <GroupBoxWidget
                conf={state.grpbxVendorDetails}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_txtBuyingPoint*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtBuyingPoint}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_txtBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxSuffix*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxSuffix*/}

                <GroupBoxWidget conf={state.grpbxSuffix} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblSuffix*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSuffix*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSuffix}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSuffix*/}

                  {/* END_USER_CODE-USER_AFTER_lblSuffix*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxSuffix*/}

                {/* END_USER_CODE-USER_AFTER_grpbxSuffix*/}
                {/* START_USER_CODE-USER_BEFORE_txtState*/}

                {/* END_USER_CODE-USER_BEFORE_txtState*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtState}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtState*/}

                {/* END_USER_CODE-USER_AFTER_txtState*/}
                {/* START_USER_CODE-USER_BEFORE_txtCounty*/}

                {/* END_USER_CODE-USER_BEFORE_txtCounty*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCounty}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCounty*/}

                {/* END_USER_CODE-USER_AFTER_txtCounty*/}
                {/* START_USER_CODE-USER_BEFORE_txtFarm*/}

                {/* END_USER_CODE-USER_BEFORE_txtFarm*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarm}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFarm*/}

                {/* END_USER_CODE-USER_AFTER_txtFarm*/}
                {/* START_USER_CODE-USER_BEFORE_txt211GPC*/}

                {/* END_USER_CODE-USER_BEFORE_txt211GPC*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt211GPC}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txt211GPC*/}

                {/* END_USER_CODE-USER_AFTER_txt211GPC*/}
                {/* START_USER_CODE-USER_BEFORE_btnVendorMaintenance*/}

                {/* END_USER_CODE-USER_BEFORE_btnVendorMaintenance*/}

                <ButtonWidget
                  conf={state.btnVendorMaintenance}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVendorMaintenance*/}

                {/* END_USER_CODE-USER_AFTER_btnVendorMaintenance*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendorVP}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                <ButtonWidget
                  conf={state.btnVendor}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                {/* START_USER_CODE-USER_BEFORE_txt211Concordia*/}

                {/* END_USER_CODE-USER_BEFORE_txt211Concordia*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt211Concordia}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txt211Concordia*/}

                {/* END_USER_CODE-USER_AFTER_txt211Concordia*/}
                {/* START_USER_CODE-USER_BEFORE_txtRemitTo*/}

                {/* END_USER_CODE-USER_BEFORE_txtRemitTo*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRemitTo}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRemitTo*/}

                {/* END_USER_CODE-USER_AFTER_txtRemitTo*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendor1*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendor1*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor1}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendor1*/}

                {/* END_USER_CODE-USER_AFTER_txtVendor1*/}
                {/* START_USER_CODE-USER_BEFORE_ddRemitTo*/}

                {/* END_USER_CODE-USER_BEFORE_ddRemitTo*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddRemitTo}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddRemitTo*/}

                {/* END_USER_CODE-USER_AFTER_ddRemitTo*/}
                {/* START_USER_CODE-USER_BEFORE_txtSharePercent*/}

                {/* END_USER_CODE-USER_BEFORE_txtSharePercent*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSharePercent}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSharePercent*/}

                {/* END_USER_CODE-USER_AFTER_txtSharePercent*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxVendorDetails*/}

              {/* END_USER_CODE-USER_AFTER_grpbxVendorDetails*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_txtAddedBy*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAddedBy}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_txtAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_txtChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_txtChangedBy*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtChangedBy}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_txtChangedBy*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_VendorProfile*/}

              {/* END_USER_CODE-USER_AFTER_VendorProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_VendorProfile);
