/* eslint-disable*/ 
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  DateWidget,
  LabelWidget,
  CheckboxWidget,
  TextAreaWidget,
  setValue,
  getValue,
  disable,
  hide,
  show,
  getData,
  setData,
  enable,
  goTo,
  setFieldValues,
  isEnabled
} from "../../shared/WindowImports";

import "./StockTransfer.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import CommonContext from "../../Store/CommonContext";
import { AccountPayment } from "../../AccountPayment/Service/AccountPayment";
import { StockTransferService } from "../Service/StockTransferService";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { SystemMaintenanceMasterManagementService } from "../../SystemMaintenanceMasterManagement/Service/SystemMaintenanceMasterManagementServices";
import { clsTransfer } from "../Common/clsTransfer";
import { clsUser } from "../Common/clsUser";
import { clsPeanutPrice } from "../Common/clsPeanutPrice";
import { clsPeanutType } from "../Common/clsPeanutType";
import { clsBinList } from "../Common/clsBinList";
import { clsBin } from "../Common/clsBin";
import { clsBrokenRules } from "../Common/clsBrokenRules";
import Loading from "../../../Loader/Loading";
import moment from "moment";
import StripPic from "../../../../assets/img/PinStrip.png";
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions";
import { toPascalCase } from "../../Common/Constants";

// END_USER_CODE-USER_IMPORTS
function StockTransfer_StockTransfer(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const commonContext = useContext(CommonContext);
  // START_USER_CODE-USER_PROPERTIES
  let CurrentUser = new clsUser();

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "TransferSearch",
    windowName: "StockTransfer",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "StockTransfer.StockTransfer",
    // START_USER_CODE-USER_StockTransfer_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Stock Transfer",
      scrCode: "PN1080B",
    },
    // END_USER_CODE-USER_StockTransfer_PROPERTIES
    btnAcctDist: {
      name: "btnAcctDist",
      type: "ButtonWidget",
      parent: "grpbxBtns",
      Label: "Acct. Dist.",
      CharWidth: "24",
      // START_USER_CODE-USER_btnAcctDist_PROPERTIES

      // END_USER_CODE-USER_btnAcctDist_PROPERTIES
    },
    btnArrow: {
      name: "btnArrow",
      type: "ButtonWidget",
      parent: "grpbxFromToBin",
      Label: "->",
      CharWidth: "7",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnArrow_PROPERTIES
      iconName: "fa-solid fa-arrow-right",
      // END_USER_CODE-USER_btnArrow_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Close",
      CharWidth: "13",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnDollarVal: {
      name: "btnDollarVal",
      type: "ButtonWidget",
      parent: "grpbxSettlement",
      Label: "Dollar Value",
      CharWidth: "26",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnDollarVal_PROPERTIES

      // END_USER_CODE-USER_btnDollarVal_PROPERTIES
    },
    btnFreightAcc: {
      name: "btnFreightAcc",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Freight Accural",
      CharWidth: "32",
      // START_USER_CODE-USER_btnFreightAcc_PROPERTIES

      // END_USER_CODE-USER_btnFreightAcc_PROPERTIES
    },
    btnFrmBin: {
      name: "btnFrmBin",
      type: "ButtonWidget",
      parent: "grpbxFromToBin",
      Label: "From Bin",
      CharWidth: "19",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnFrmBin_PROPERTIES

      // END_USER_CODE-USER_btnFrmBin_PROPERTIES
    },
    btnNewTrnsfr: {
      name: "btnNewTrnsfr",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "New Transfer",
      CharWidth: "26",
      // START_USER_CODE-USER_btnNewTrnsfr_PROPERTIES
      Visible: false,
      // END_USER_CODE-USER_btnNewTrnsfr_PROPERTIES
    },
    btnPayment: {
      name: "btnPayment",
      type: "ButtonWidget",
      parent: "grpbxBtns",
      Label: "Payments",
      CharWidth: "19",
      // START_USER_CODE-USER_btnPayment_PROPERTIES

      // END_USER_CODE-USER_btnPayment_PROPERTIES
    },
    btnPopup: {
      name: "btnPopup",
      type: "ButtonWidget",
      parent: "grpbxFreight",
      Label: "...",
      CharWidth: "9",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnPopup_PROPERTIES

      // END_USER_CODE-USER_btnPopup_PROPERTIES
    },
    btnPremDeduct: {
      name: "btnPremDeduct",
      type: "ButtonWidget",
      parent: "grpbxBtns",
      Label: "Prem/Deduct",
      CharWidth: "24",
      // START_USER_CODE-USER_btnPremDeduct_PROPERTIES

      // END_USER_CODE-USER_btnPremDeduct_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print",
      CharWidth: "13",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnSave: {
      name: "btnSave",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Save",
      CharWidth: "11",
      // START_USER_CODE-USER_btnSave_PROPERTIES

      // END_USER_CODE-USER_btnSave_PROPERTIES
    },
    btnToBin: {
      name: "btnToBin",
      type: "ButtonWidget",
      parent: "grpbxFromToBin",
      Label: "To Bin",
      CharWidth: "15",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_btnToBin_PROPERTIES

      // END_USER_CODE-USER_btnToBin_PROPERTIES
    },
    btnVoid: {
      name: "btnVoid",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Void",
      CharWidth: "11",
      // START_USER_CODE-USER_btnVoid_PROPERTIES

      // END_USER_CODE-USER_btnVoid_PROPERTIES
    },
    chkboxVicam: {
      name: "chkboxVicam",
      type: "CheckBoxWidget",
      parent: "grpbxCheckbxViacam",
      Label: "Vicam",

      ofTypeDomain: "d_boolean",
    },
    chkboxFlavus: {
      name: "chkboxFlavus",
      type: "CheckBoxWidget",
      parent: "grpbxCheckbxViacam",
      Label: "Flavus Found",

      ofTypeDomain: "d_boolean",
    },
    // chkboxReGrading: {
    //   name: "chkboxReGrading",
    //   type: "CheckBoxGroupFieldWidget",
    //   parent: "grpbxCheckbxViacam",
    //   Options: "A. Flavus Found:1, Vicam:2",
    //   RowSpanForTabPotrait: "2",
    //   RowSpanForLargeDesktop: "2",
    //   RowSpanForTabLandscape: "2",
    //   ColSpan: "1",
    //   HasLabel: false,
    //   RowSpan: "2",

    //   ofTypeDomain: "d_boolean",
    //   // START_USER_CODE-USER_chkboxReGrading_PROPERTIES

    //   // END_USER_CODE-USER_chkboxReGrading_PROPERTIES
    // },
    chkbxCornFnd: {
      name: "chkbxCornFnd",
      type: "CheckBoxWidget",
      parent: "grpbxFromToBin",
      Label: "Corn Found",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbxCornFnd_PROPERTIES

      // END_USER_CODE-USER_chkbxCornFnd_PROPERTIES
    },
    chkbxManualGrd: {
      name: "chkbxManualGrd",
      type: "CheckBoxWidget",
      parent: "grpbxRegrading",
      Label: "Manual Grade",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbxManualGrd_PROPERTIES

      // END_USER_CODE-USER_chkbxManualGrd_PROPERTIES
    },
    chkbxSplitWtRcvd: {
      name: "chkbxSplitWtRcvd",
      type: "CheckBoxWidget",
      parent: "grpbxRecvd",
      Label: "Split Weight:",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbxSplitWtRcvd_PROPERTIES

      // END_USER_CODE-USER_chkbxSplitWtRcvd_PROPERTIES
    },
    chkbxSplitWtShip: {
      name: "chkbxSplitWtShip",
      type: "CheckBoxWidget",
      parent: "grpbxShippd",
      Label: "Split Weight:",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbxSplitWtShip_PROPERTIES

      // END_USER_CODE-USER_chkbxSplitWtShip_PROPERTIES
    },
    ddCountry: {
      name: "ddCountry",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmFreight",
      Label: "County:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCountry_PROPERTIES
      SpecialFirstOption: ""
      // END_USER_CODE-USER_ddCountry_PROPERTIES
    },
    ddDestinationLoc: {
      name: "ddDestinationLoc",
      type: "DropDownFieldWidget",
      parent: "grpbxLocation",
      Label: "Destination Loc:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestinationLoc_PROPERTIES

      // END_USER_CODE-USER_ddDestinationLoc_PROPERTIES
    },
    ddFarm: {
      name: "ddFarm",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmFreight",
      Label: "Farm:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddFarm_PROPERTIES
      SpecialFirstOption: ""
      // END_USER_CODE-USER_ddFarm_PROPERTIES
    },
    ddPayFreight: {
      name: "ddPayFreight",
      type: "DropDownFieldWidget",
      parent: "grpbxLoad",
      Label: "Pay Freight:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPayFreight_PROPERTIES

      // END_USER_CODE-USER_ddPayFreight_PROPERTIES
    },
    ddShippingLoc: {
      name: "ddShippingLoc",
      type: "DropDownFieldWidget",
      parent: "grpbxLocation",
      Label: "Shipping Loc:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddShippingLoc_PROPERTIES

      // END_USER_CODE-USER_ddShippingLoc_PROPERTIES
    },
    ddState: {
      name: "ddState",
      type: "DropDownFieldWidget",
      parent: "grpbxFarmFreight",
      Label: "State:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddState_PROPERTIES
      SpecialFirstOption: ""
      // END_USER_CODE-USER_ddState_PROPERTIES
    },
    ddType: {
      name: "ddType",
      type: "DropDownFieldWidget",
      parent: "grpbxNewPending",
      Label: "Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddType_PROPERTIES

      // END_USER_CODE-USER_ddType_PROPERTIES
    },
    txtUpdtInvent: {
      name: "txtUpdtInvent",
      type: "TextBoxWidget",
      parent: "grpbxUpdateInvnt",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtUpdtInvent_PROPERTIES

      // END_USER_CODE-USER_txtUpdtInvent_PROPERTIES
    },
    ddUpdtInvent: {
      name: "ddUpdtInvent",
      type: "DropDownFieldWidget",
      parent: "grpbxUpdateInvnt",
      Label: "Update Inventory with:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddUpdtInvent_PROPERTIES

      // END_USER_CODE-USER_ddUpdtInvent_PROPERTIES
    },
    txtPnutShippdFrm: {
      name: "txtPnutShippdFrm",
      type: "DropDownFieldWidget",
      parent: "grpbxShippdFrmTo",
      HasLabel: false,
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      SpecialFirstOption: ""
    },
    dtRcvdDate: {
      name: "dtRcvdDate",
      type: "DateWidget",
      parent: "grpbxRecvd",
      Label: "Received Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_dtRcvdDate_PROPERTIES

      // END_USER_CODE-USER_dtRcvdDate_PROPERTIES
    },
    dtShipDate: {
      name: "dtShipDate",
      type: "DateWidget",
      parent: "grpbxShippd",
      Label: "Shipping Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_dtShipDate_PROPERTIES

      // END_USER_CODE-USER_dtShipDate_PROPERTIES
    },
    grpbxBtns: {
      name: "grpbxBtns",
      type: "GroupBoxWidget",
      parent: "grpbxActions",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbxBtns_PROPERTIES

      // END_USER_CODE-USER_grpbxBtns_PROPERTIES
    },
    grpbxCheckbxViacam: {
      name: "grpbxCheckbxViacam",
      type: "GroupBoxWidget",
      parent: "grpbxReGrading",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxCheckbxViacam_PROPERTIES

      // END_USER_CODE-USER_grpbxCheckbxViacam_PROPERTIES
    },
    grpbxDamage: {
      name: "grpbxDamage",
      type: "GroupBoxWidget",
      parent: "grpbxReGrading",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxDamage_PROPERTIES

      // END_USER_CODE-USER_grpbxDamage_PROPERTIES
    },
    grpbxEditInfo: {
      name: "grpbxEditInfo",
      type: "GroupBoxWidget",
      parent: "grpbxActions",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxEditInfo_PROPERTIES

      // END_USER_CODE-USER_grpbxEditInfo_PROPERTIES
    },
    grpbxFarmFreight: {
      name: "grpbxFarmFreight",
      type: "GroupBoxWidget",
      parent: "grpbxGridCorn",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxFarmFreight_PROPERTIES

      // END_USER_CODE-USER_grpbxFarmFreight_PROPERTIES
    },
    grpbxFreight: {
      name: "grpbxFreight",
      type: "GroupBoxWidget",
      parent: "grpbxLoadShippedRcvd",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxFreight_PROPERTIES

      // END_USER_CODE-USER_grpbxFreight_PROPERTIES
    },
    grpbxFreightAcc: {
      name: "grpbxFreightAcc",
      type: "GroupBoxWidget",
      parent: "grpbxSettlementFreight",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxFreightAcc_PROPERTIES

      // END_USER_CODE-USER_grpbxFreightAcc_PROPERTIES
    },
    grpbxMultiStop: {
      name: "grpbxMultiStop",
      type: "GroupBoxWidget",
      parent: "grpbxSettlementFreight",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxFreightAcc_PROPERTIES

      // END_USER_CODE-USER_grpbxFreightAcc_PROPERTIES
    },
    grpbxFromToBin: {
      name: "grpbxFromToBin",
      type: "GroupBoxWidget",
      parent: "grpbxGridCorn",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxFromToBin_PROPERTIES

      // END_USER_CODE-USER_grpbxFromToBin_PROPERTIES
    },
    grpbxGridCorn: {
      name: "grpbxGridCorn",
      type: "GroupBoxWidget",
      parent: "grpbxMid",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxGridCorn_PROPERTIES

      // END_USER_CODE-USER_grpbxGridCorn_PROPERTIES
    },
    grpbxLoad: {
      name: "grpbxLoad",
      type: "GroupBoxWidget",
      parent: "grpbxLoadShippedRcvd",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxLoad_PROPERTIES

      // END_USER_CODE-USER_grpbxLoad_PROPERTIES
    },
    grpbxLoadShippedRcvd: {
      name: "grpbxLoadShippedRcvd",
      type: "GroupBoxWidget",
      parent: "grpbxMid",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxLoadShippedRcvd_PROPERTIES

      // END_USER_CODE-USER_grpbxLoadShippedRcvd_PROPERTIES
    },
    grpbxLocation: {
      name: "grpbxLocation",
      type: "GroupBoxWidget",
      parent: "grpbxStockTransfer",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxLocation_PROPERTIES

      // END_USER_CODE-USER_grpbxLocation_PROPERTIES
    },
    grpbxMid: {
      name: "grpbxMid",
      type: "GroupBoxWidget",
      parent: "grpbxStockTransfer",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxMid_PROPERTIES

      // END_USER_CODE-USER_grpbxMid_PROPERTIES
    },
    grpbxNewPending: {
      name: "grpbxNewPending",
      type: "GroupBoxWidget",
      parent: "grpbxStockTransfer",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxNewPending_PROPERTIES

      // END_USER_CODE-USER_grpbxNewPending_PROPERTIES
    },
    grpbxRecvd: {
      name: "grpbxRecvd",
      type: "GroupBoxWidget",
      parent: "grpbxLoadShippedRcvd",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxRecvd_PROPERTIES

      // END_USER_CODE-USER_grpbxRecvd_PROPERTIES
    },
    grpbxRegrading: {
      name: "grpbxRegrading",
      type: "GroupBoxWidget",
      parent: "grpbxRegrd",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxRegrading_PROPERTIES

      // END_USER_CODE-USER_grpbxRegrading_PROPERTIES
    },
    grpbxReGrading: {
      name: "grpbxReGrading",
      type: "GroupBoxWidget",
      parent: "grpbxRegrd",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_grpbxReGrading_PROPERTIES

      // END_USER_CODE-USER_grpbxReGrading_PROPERTIES
    },
    grpbxRegrd: {
      name: "grpbxRegrd",
      type: "GroupBoxWidget",
      parent: "grpbxRegrdSettlement",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxRegrd_PROPERTIES

      // END_USER_CODE-USER_grpbxRegrd_PROPERTIES
    },
    grpbxRegrdSettlement: {
      name: "grpbxRegrdSettlement",
      type: "GroupBoxWidget",
      parent: "grpbxStockTransfer",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxRegrdSettlement_PROPERTIES

      // END_USER_CODE-USER_grpbxRegrdSettlement_PROPERTIES
    },
    grpbxRemrks: {
      name: "grpbxRemrks",
      type: "GroupBoxWidget",
      parent: "grpbxStockTransfer",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxRemrks_PROPERTIES

      // END_USER_CODE-USER_grpbxRemrks_PROPERTIES
    },
    grpbxSettlement: {
      name: "grpbxSettlement",
      type: "GroupBoxWidget",
      parent: "grpbxSettlementFreight",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxSettlement_PROPERTIES

      // END_USER_CODE-USER_grpbxSettlement_PROPERTIES
    },
    grpbxSettlementFreight: {
      name: "grpbxSettlementFreight",
      type: "GroupBoxWidget",
      parent: "grpbxRegrdSettlement",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxSettlementFreight_PROPERTIES

      // END_USER_CODE-USER_grpbxSettlementFreight_PROPERTIES
    },
    grpbxShippd: {
      name: "grpbxShippd",
      type: "GroupBoxWidget",
      parent: "grpbxLoadShippedRcvd",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxShippd_PROPERTIES

      // END_USER_CODE-USER_grpbxShippd_PROPERTIES
    },
    grpbxShippdFrmTo: {
      name: "grpbxShippdFrmTo",
      type: "GroupBoxWidget",
      parent: "grpbxGridCorn",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxShippdFrmTo_PROPERTIES

      // END_USER_CODE-USER_grpbxShippdFrmTo_PROPERTIES
    },
    grpbxUpdateInvnt: {
      name: "grpbxUpdateInvnt",
      type: "GroupBoxWidget",
      parent: "grpbxLoadShippedRcvd",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxUpdateInvnt_PROPERTIES

      // END_USER_CODE-USER_grpbxUpdateInvnt_PROPERTIES
    },
    grpbxWtVariance: {
      name: "grpbxWtVariance",
      type: "GroupBoxWidget",
      parent: "grpbxGridCorn",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxWtVariance_PROPERTIES

      // END_USER_CODE-USER_grpbxWtVariance_PROPERTIES
    },
    lblAddedDate: {
      name: "lblAddedDate",
      type: "LabelWidget",
      parent: "grpbxEditInfo",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedDate_PROPERTIES

      // END_USER_CODE-USER_lblAddedDate_PROPERTIES
    },
    lblChngdDate: {
      name: "lblChngdDate",
      type: "LabelWidget",
      parent: "grpbxEditInfo",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChngdDate_PROPERTIES

      // END_USER_CODE-USER_lblChngdDate_PROPERTIES
    },
    lblDollar: {
      name: "lblDollar",
      type: "TextBoxWidget",
      parent: "grpbxSettlement",
      Label: ".",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblDollar_PROPERTIES

      // END_USER_CODE-USER_lblDollar_PROPERTIES
    },
    lblEdibleOil: {
      name: "lblEdibleOil",
      type: "LabelWidget",
      parent: "grpbxShippdFrmTo",
      Label: "Edible Oil:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblEdibleOil_PROPERTIES

      // END_USER_CODE-USER_lblEdibleOil_PROPERTIES
    },
    lblFarmFrgtInfo: {
      name: "lblFarmFrgtInfo",
      type: "LabelWidget",
      parent: "grpbxFarmFreight",
      Label: "Farm Freight Information",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFarmFrgtInfo_PROPERTIES

      // END_USER_CODE-USER_lblFarmFrgtInfo_PROPERTIES
    },
    lblFm: {
      name: "lblFm",
      type: "LabelWidget",
      parent: "grpbxSettlement",
      Label: "lb",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFm_PROPERTIES

      // END_USER_CODE-USER_lblFm_PROPERTIES
    },
    lblFrghtAccrl: {
      name: "lblFrghtAccrl",
      type: "LabelWidget",
      parent: "grpbxFreightAcc",
      Label: "Freight Accural",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFrghtAccrl_PROPERTIES

      // END_USER_CODE-USER_lblFrghtAccrl_PROPERTIES
    },
    lblMultiStop: {
      name: "lblMultiStop",
      type: "LabelWidget",
      parent: "grpbxMultiStop",
      Label: "Multi Stop",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFrghtAccrl_PROPERTIES

      // END_USER_CODE-USER_lblFrghtAccrl_PROPERTIES
    },
    lblFrghtAccrual: {
      name: "lblFrghtAccrual",
      type: "LabelWidget",
      parent: "grpbxFreight",
      Label: "Freight Accrual:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFrghtAccrual_PROPERTIES

      // END_USER_CODE-USER_lblFrghtAccrual_PROPERTIES
    },
    lblCompleted: {
      name: "lblCompleted",
      type: "LabelWidget",
      parent: "grpbxFreight",
      Label: "Completed",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCompleted_PROPERTIES

      // END_USER_CODE-USER_lblCompleted_PROPERTIES
    },
    lblg: {
      name: "lblg",
      type: "LabelWidget",
      parent: "grpbxRegrading",
      Label: "g",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblg_PROPERTIES

      // END_USER_CODE-USER_lblg_PROPERTIES
    },
    lblGradeSource: {
      name: "lblGradeSource",
      type: "LabelWidget",
      parent: "grpbxRegrading",
      Label: "Grade Source: INVENTORY",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblGradeSource_PROPERTIES

      // END_USER_CODE-USER_lblGradeSource_PROPERTIES
    },
    lblG: {
      name: "lblG",
      type: "LabelWidget",
      parent: "grpbxRegrading",
      Label: "g",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblG_PROPERTIES

      // END_USER_CODE-USER_lblG_PROPERTIES
    },
    lblGeneration: {
      name: "lblGeneration",
      type: "LabelWidget",
      parent: "grpbxShippdFrmTo",
      Label: "Generation:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblGeneration_PROPERTIES

      // END_USER_CODE-USER_lblGeneration_PROPERTIES
    },
    lblGrams: {
      name: "lblGrams",
      type: "LabelWidget",
      parent: "grpbxReGrading",
      Label: "Grams",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblGrams_PROPERTIES

      // END_USER_CODE-USER_lblGrams_PROPERTIES
    },
    lblGrams2: {
      name: "lblGrams2",
      type: "LabelWidget",
      parent: "grpbxReGrading",
      Label: "Grams",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblGrams2_PROPERTIES

      // END_USER_CODE-USER_lblGrams2_PROPERTIES
    },
    lblGrossWt: {
      name: "lblGrossWt",
      type: "LabelWidget",
      parent: "grpbxSettlement",
      Label: "lb",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblGrossWt_PROPERTIES

      // END_USER_CODE-USER_lblGrossWt_PROPERTIES
    },
    lblKernRidingELKPct: {
      name: "lblKernRidingELKPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblKernRidingELKPct_PROPERTIES

      // END_USER_CODE-USER_lblKernRidingELKPct_PROPERTIES
    },
    lblLb: {
      name: "lblLb",
      type: "LabelWidget",
      parent: "grpbxWtVariance",
      Label: "lb",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLb_PROPERTIES

      // END_USER_CODE-USER_lblLb_PROPERTIES
    },
    lblLessFm: {
      name: "lblLessFm",
      type: "LabelWidget",
      parent: "grpbxSettlement",
      Label: "lb",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLessFm_PROPERTIES

      // END_USER_CODE-USER_lblLessFm_PROPERTIES
    },
    lblLessLsk: {
      name: "lblLessLsk",
      type: "LabelWidget",
      parent: "grpbxSettlement",
      Label: "lb",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLessLsk_PROPERTIES

      // END_USER_CODE-USER_lblLessLsk_PROPERTIES
    },
    lblLoad: {
      name: "lblLoad",
      type: "LabelWidget",
      parent: "grpbxLoad",
      Label: "Load",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLoad_PROPERTIES

      // END_USER_CODE-USER_lblLoad_PROPERTIES
    },
    lblLeasedTrailer: {
      name: "lblLeasedTrailer",
      type: "LabelWidget",
      parent: "grpbxLoad",
      Label: "Leased Trailer",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLeasedTrailer_PROPERTIES

      // END_USER_CODE-USER_lblLeasedTrailer_PROPERTIES
    },
    lblLocation: {
      name: "lblLocation",
      type: "LabelWidget",
      parent: "grpbxLocation",
      Label: "Location",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLocation_PROPERTIES

      // END_USER_CODE-USER_lblLocation_PROPERTIES
    },
    lblLsk: {
      name: "lblLsk",
      type: "LabelWidget",
      parent: "grpbxSettlement",
      Label: "lb",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLsk_PROPERTIES

      // END_USER_CODE-USER_lblLsk_PROPERTIES
    },
    lblMoistr: {
      name: "lblMoistr",
      type: "LabelWidget",
      parent: "grpbxSettlement",
      Label: "lb",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblMoistr_PROPERTIES

      // END_USER_CODE-USER_lblMoistr_PROPERTIES
    },
    lblNetwt: {
      name: "lblNetwt",
      type: "LabelWidget",
      parent: "grpbxSettlement",
      Label: "lb",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNetwt_PROPERTIES

      // END_USER_CODE-USER_lblNetwt_PROPERTIES
    },
    lblNew: {
      name: "lblNew",
      type: "LabelWidget",
      parent: "grpbxNewPending",
      // Label: "New",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNew_PROPERTIES

      // END_USER_CODE-USER_lblNew_PROPERTIES
    },
    chkbxMnlOverRide: {
      name: "chkbxMnlOverRide",
      type: "CheckBoxWidget",
      parent: "grpbxNewPending",
      Label: "Manual Override:",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbxMnlOverRide_PROPERTIES

      // END_USER_CODE-USER_chkbxMnlOverRide_PROPERTIES
    },
    lblOleic: {
      name: "lblOleic",
      type: "LabelWidget",
      parent: "grpbxShippdFrmTo",
      Label: "Oleic:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblOleic_PROPERTIES

      // END_USER_CODE-USER_lblOleic_PROPERTIES
    },
    lblOrganic: {
      name: "lblOrganic",
      type: "LabelWidget",
      parent: "grpbxShippdFrmTo",
      Label: "Organic:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblOrganic_PROPERTIES

      // END_USER_CODE-USER_lblOrganic_PROPERTIES
    },
    lblPct: {
      name: "lblPct",
      type: "LabelWidget",
      parent: "grpbxReGrading",
      Label: "",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct_PROPERTIES

      // END_USER_CODE-USER_lblPct_PROPERTIES
    },
    lblPending: {
      name: "lblPending",
      type: "LabelWidget",
      parent: "grpbxNewPending",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPending_PROPERTIES

      // END_USER_CODE-USER_lblPending_PROPERTIES
    },
    lblPerTon: {
      name: "lblPerTon",
      type: "LabelWidget",
      parent: "grpbxFreightAcc",
      Label: "/ton",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPerTon_PROPERTIES

      // END_USER_CODE-USER_lblPerTon_PROPERTIES
    },
    lblPnutType: {
      name: "lblPnutType",
      type: "LabelWidget",
      parent: "grpbxShippdFrmTo",
      Label: "Peanut Type:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPnutType_PROPERTIES

      // END_USER_CODE-USER_lblPnutType_PROPERTIES
    },
    lblPrcnt: {
      name: "lblPrcnt",
      type: "LabelWidget",
      parent: "grpbxReGrading",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPrcnt_PROPERTIES

      // END_USER_CODE-USER_lblPrcnt_PROPERTIES
    },
    lblPrcnt2: {
      name: "lblPrcnt2",
      type: "LabelWidget",
      parent: "grpbxReGrading",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPrcnt2_PROPERTIES

      // END_USER_CODE-USER_lblPrcnt2_PROPERTIES
    },
    lblReceived: {
      name: "lblReceived",
      type: "LabelWidget",
      parent: "grpbxRecvd",
      Label: "Received",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblReceived_PROPERTIES

      // END_USER_CODE-USER_lblReceived_PROPERTIES
    },
    lblRecvdMsg: {
      name: "lblRecvdMsg",
      type: "LabelWidget",
      parent: "grpbxRecvd",
      Label: "Please Verify Weights!",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRecvdMsg_PROPERTIES

      // END_USER_CODE-USER_lblRecvdMsg_PROPERTIES
    },
    lblRegrading: {
      name: "lblRegrading",
      type: "LabelWidget",
      parent: "grpbxRegrading",
      Label: "Regrading",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRegrading_PROPERTIES

      // END_USER_CODE-USER_lblRegrading_PROPERTIES
    },
    lblSeg: {
      name: "lblSeg",
      type: "LabelWidget",
      parent: "grpbxShippdFrmTo",
      Label: "Seg:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSeg_PROPERTIES

      // END_USER_CODE-USER_lblSeg_PROPERTIES
    },
    lblSettlementInfo: {
      name: "lblSettlementInfo",
      type: "LabelWidget",
      parent: "grpbxSettlement",
      Label: "Settlement Information",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSettlementInfo_PROPERTIES

      // END_USER_CODE-USER_lblSettlementInfo_PROPERTIES
    },
    lblShippdFrm: {
      name: "lblShippdFrm",
      type: "LabelWidget",
      parent: "grpbxShippdFrmTo",
      Label: "Shipped From",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblShippdFrm_PROPERTIES

      // END_USER_CODE-USER_lblShippdFrm_PROPERTIES
    },
    lblShippdTo: {
      name: "lblShippdTo",
      type: "LabelWidget",
      parent: "grpbxShippdFrmTo",
      Label: "Shipped To",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblShippdTo_PROPERTIES

      // END_USER_CODE-USER_lblShippdTo_PROPERTIES
    },
    lblShipped: {
      name: "lblShipped",
      type: "LabelWidget",
      parent: "grpbxShippd",
      Label: "Shipped",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblShipped_PROPERTIES

      // END_USER_CODE-USER_lblShipped_PROPERTIES
    },
    lblVariety: {
      name: "lblVariety",
      type: "LabelWidget",
      parent: "grpbxShippdFrmTo",
      Label: "Variety:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVariety_PROPERTIES

      // END_USER_CODE-USER_lblVariety_PROPERTIES
    },
    lblWhseBin: {
      name: "lblWhseBin",
      type: "LabelWidget",
      parent: "grpbxShippdFrmTo",
      Label: "Whse-Bin#:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWhseBin_PROPERTIES

      // END_USER_CODE-USER_lblWhseBin_PROPERTIES
    },
    txt1007: {
      name: "txt1007",
      type: "TextBoxWidget",
      parent: "grpbxLoad",
      Label: "1007 #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007_PROPERTIES

      // END_USER_CODE-USER_txt1007_PROPERTIES
    },
    txtOtherWt: {
      name: "txtOtherWt",
      type: "TextBoxWidget",
      parent: "grpbxLoad",
      Label: "1007 #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOtherWt_PROPERTIES

      // END_USER_CODE-USER_txtOtherWt_PROPERTIES
    },
    txtAddDest: {
      name: "txtAddDest",
      type: "TextBoxWidget",
      parent: "grpbxLocation",
      Label: "Address:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddDest_PROPERTIES

      // END_USER_CODE-USER_txtAddDest_PROPERTIES
    },
    txtAddedBy: {
      name: "txtAddedBy",
      type: "TextBoxWidget",
      parent: "grpbxEditInfo",
      Label: "Added by;",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddedBy_PROPERTIES

      // END_USER_CODE-USER_txtAddedBy_PROPERTIES
    },
    txtAddShip: {
      name: "txtAddShip",
      type: "TextBoxWidget",
      parent: "grpbxLocation",
      Label: "Address:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddShip_PROPERTIES

      // END_USER_CODE-USER_txtAddShip_PROPERTIES
    },
    txtarCarrAbbr: {
      name: "txtarCarrAbbr",
      type: "TextAreaWidget",
      parent: "grpbxFreight",
      ColSpan: "2",
      HasLabel: false,
      RowSpan: "1",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtarCarrAbbr_PROPERTIES

      // END_USER_CODE-USER_txtarCarrAbbr_PROPERTIES
    },
    txtarRemarks: {
      name: "txtarRemarks",
      type: "TextAreaWidget",
      parent: "grpbxRemrks",
      Label: "Remarks:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtarRemarks_PROPERTIES

      // END_USER_CODE-USER_txtarRemarks_PROPERTIES
    },
    txtCarrAbbr: {
      name: "txtCarrAbbr",
      type: "TextBoxWidget",
      parent: "grpbxFreight",
      Label: "Carrier Abbreviation",
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCarrAbbr_PROPERTIES

      // END_USER_CODE-USER_txtCarrAbbr_PROPERTIES
    },
    txtChngdBy: {
      name: "txtChngdBy",
      type: "TextBoxWidget",
      parent: "grpbxEditInfo",
      Label: "Changed By:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtChngdBy_PROPERTIES

      // END_USER_CODE-USER_txtChngdBy_PROPERTIES
    },
    txtClnSmplWt: {
      name: "txtClnSmplWt",
      type: "TextBoxWidget",
      parent: "grpbxRegrading",
      Label: "Clean Sample Weight:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtClnSmplWt_PROPERTIES

      // END_USER_CODE-USER_txtClnSmplWt_PROPERTIES
    },
    btnOverRide: {
      name: "btnOverRide",
      type: "ButtonWidget",
      parent: "grpbxRegrading",
      Label: "Override Grade Source",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOverRide_PROPERTIES

      // END_USER_CODE-USER_btnOverRide_PROPERTIES
    },
    txtConcealedRMD: {
      name: "txtConcealedRMD",
      type: "TextBoxWidget",
      parent: "grpbxDamage",
      Label: "Concealed RMD:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtConcealedRMD_PROPERTIES

      // END_USER_CODE-USER_txtConcealedRMD_PROPERTIES
    },
    txtConcealedRMDPct: {
      name: "txtConcealedRMDPct",
      type: "TextBoxWidget",
      parent: "grpbxDamage",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtConcealedRMDPct_PROPERTIES

      // END_USER_CODE-USER_txtConcealedRMDPct_PROPERTIES
    },
    txtCrackedBroken: {
      name: "txtCrackedBroken",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Cracked/Broken:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCrackedBroken_PROPERTIES

      // END_USER_CODE-USER_txtCrackedBroken_PROPERTIES
    },
    txtCrackedBrokenPct: {
      name: "txtCrackedBrokenPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCrackedBrokenPct_PROPERTIES

      // END_USER_CODE-USER_txtCrackedBrokenPct_PROPERTIES
    },
    txtDamageSplits: {
      name: "txtDamageSplits",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Damage Splits:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDamageSplits_PROPERTIES

      // END_USER_CODE-USER_txtDamageSplits_PROPERTIES
    },
    txtDamageSplits2: {
      name: "txtDamageSplits2",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDamageSplits2_PROPERTIES

      // END_USER_CODE-USER_txtDamageSplits2_PROPERTIES
    },
    txtDeductnAmt: {
      name: "txtDeductnAmt",
      type: "TextBoxWidget",
      parent: "grpbxFreightAcc",
      Label: "Deduction Amt:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDeductnAmt_PROPERTIES

      // END_USER_CODE-USER_txtDeductnAmt_PROPERTIES
    },
    txtDiscoloredShells: {
      name: "txtDiscoloredShells",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Discolored Shells:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDiscoloredShells_PROPERTIES

      // END_USER_CODE-USER_txtDiscoloredShells_PROPERTIES
    },
    txtDiscoloredShellsPct: {
      name: "txtDiscoloredShellsPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDiscoloredShellsPct_PROPERTIES

      // END_USER_CODE-USER_txtDiscoloredShellsPct_PROPERTIES
    },
    txtDispatch: {
      name: "txtDispatch",
      type: "TextBoxWidget",
      parent: "grpbxLoad",
      Label: "Dispatch#:",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDispatch_PROPERTIES

      // END_USER_CODE-USER_txtDispatch_PROPERTIES
    },
    txtEdiblOilShippdFrm: {
      name: "txtEdiblOilShippdFrm",
      type: "TextBoxWidget",
      parent: "grpbxShippdFrmTo",
      HasLabel: false,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEdiblOilShippdFrm_PROPERTIES

      // END_USER_CODE-USER_txtEdiblOilShippdFrm_PROPERTIES
    },
    txtEdiblOilShippdTo: {
      name: "txtEdiblOilShippdTo",
      type: "TextBoxWidget",
      parent: "grpbxShippdFrmTo",
      HasLabel: false,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEdiblOilShippdTo_PROPERTIES

      // END_USER_CODE-USER_txtEdiblOilShippdTo_PROPERTIES
    },
    txtExcMoisture: {
      name: "txtExcMoisture",
      type: "TextBoxWidget",
      parent: "grpbxSettlement",
      Label: "Excess Moisture:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtExcMoisture_PROPERTIES

      // END_USER_CODE-USER_txtExcMoisture_PROPERTIES
    },
    txtFancy: {
      name: "txtFancy",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Fancy:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFancy_PROPERTIES

      // END_USER_CODE-USER_txtFancy_PROPERTIES
    },
    txtFancyPct: {
      name: "txtFancyPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFancyPct_PROPERTIES

      // END_USER_CODE-USER_txtFancyPct_PROPERTIES
    },
    txtFm: {
      name: "txtFm",
      type: "TextBoxWidget",
      parent: "grpbxSettlement",
      Label: "FM:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFm_PROPERTIES

      // END_USER_CODE-USER_txtFm_PROPERTIES
    },
    txtFmSample: {
      name: "txtFmSample",
      type: "TextBoxWidget",
      parent: "grpbxRegrading",
      Label: "Weight of FM sample:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFmSample_PROPERTIES

      // END_USER_CODE-USER_txtFmSample_PROPERTIES
    },
    txtForeignMaterialPct: {
      name: "txtForeignMaterialPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtForeignMaterialPct_PROPERTIES

      // END_USER_CODE-USER_txtForeignMaterialPct_PROPERTIES
    },
    txtForeignMtrl: {
      name: "txtForeignMtrl",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Foreign Material:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtForeignMtrl_PROPERTIES

      // END_USER_CODE-USER_txtForeignMtrl_PROPERTIES
    },
    txtFreezeDamage: {
      name: "txtFreezeDamage",
      type: "TextBoxWidget",
      parent: "grpbxDamage",
      Label: "Freeze Damage:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFreezeDamage_PROPERTIES

      // END_USER_CODE-USER_txtFreezeDamage_PROPERTIES
    },
    txtFreezeDamagePct: {
      name: "txtFreezeDamagePct",
      type: "TextBoxWidget",
      parent: "grpbxDamage",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFreezeDamagePct_PROPERTIES

      // END_USER_CODE-USER_txtFreezeDamagePct_PROPERTIES
    },
    txtFreightRate: {
      name: "txtFreightRate",
      type: "TextBoxWidget",
      parent: "grpbxFreightAcc",
      Label: "Freight Rate:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFreightRate_PROPERTIES

      // END_USER_CODE-USER_txtFreightRate_PROPERTIES
    },
    txtFrghtAm: {
      name: "txtFrghtAm",
      type: "TextBoxWidget",
      parent: "grpbxFreightAcc",
      Label: "Freight Amt:",
      Enabled: false,
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFrghtAm_PROPERTIES

      // END_USER_CODE-USER_txtFrghtAm_PROPERTIES
    },
    ddGenerationShippdFrm: {
      name: "ddGenerationShippdFrm",
      type: "DropDownFieldWidget",
      parent: "grpbxShippdFrmTo",
      HasLabel: false,
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGenerationShippdFrm_PROPERTIES
      SpecialFirstOption: ""
      // END_USER_CODE-USER_txtGenerationShippdFrm_PROPERTIES
    },
    ddGenerationShippdTo: {
      name: "ddGenerationShippdTo",
      type: "DropDownFieldWidget",
      parent: "grpbxShippdFrmTo",
      HasLabel: false,
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGenerationShippdTo_PROPERTIES
      SpecialFirstOption: ""
      // END_USER_CODE-USER_txtGenerationShippdTo_PROPERTIES
    },
    txtGrossWt: {
      name: "txtGrossWt",
      type: "TextBoxWidget",
      parent: "grpbxSettlement",
      Label: "Gross wt:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGrossWt_PROPERTIES

      // END_USER_CODE-USER_txtGrossWt_PROPERTIES
    },
    txtHullBrightness: {
      name: "txtHullBrightness",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Hull Brightness:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHullBrightness_PROPERTIES

      // END_USER_CODE-USER_txtHullBrightness_PROPERTIES
    },
    txtHullBrightnessPct: {
      name: "txtHullBrightnessPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHullBrightnessPct_PROPERTIES

      // END_USER_CODE-USER_txtHullBrightnessPct_PROPERTIES
    },
    txtHulls: {
      name: "txtHulls",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Hulls:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHulls_PROPERTIES

      // END_USER_CODE-USER_txtHulls_PROPERTIES
    },
    txtHullsPct: {
      name: "txtHullsPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHullsPct_PROPERTIES

      // END_USER_CODE-USER_txtHullsPct_PROPERTIES
    },
    txtInvoice: {
      name: "txtInvoice",
      type: "TextBoxWidget",
      parent: "grpbxFreight",
      Label: "Invoice:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInvoice_PROPERTIES

      // END_USER_CODE-USER_txtInvoice_PROPERTIES
    },
    txtJumbo: {
      name: "txtJumbo",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Jumbo:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtJumbo_PROPERTIES

      // END_USER_CODE-USER_txtJumbo_PROPERTIES
    },
    txtJumboGrPct: {
      name: "txtJumboGrPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtJumboGrPct_PROPERTIES

      // END_USER_CODE-USER_txtJumboGrPct_PROPERTIES
    },
    txtKernRidingELK: {
      name: "txtKernRidingELK",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Kern Riding ELK Screen:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKernRidingELK_PROPERTIES

      // END_USER_CODE-USER_txtKernRidingELK_PROPERTIES
    },
    txtKernRidingPrescribe: {
      name: "txtKernRidingPrescribe",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Kern Riding Prescribed Screen:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKernRidingPrescribe_PROPERTIES

      // END_USER_CODE-USER_txtKernRidingPrescribe_PROPERTIES
    },
    txtKernRidingPrescribePct: {
      name: "txtKernRidingPrescribePct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtKernRidingPrescribePct_PROPERTIES

      // END_USER_CODE-USER_txtKernRidingPrescribePct_PROPERTIES
    },
    txtLegMsg: {
      name: "txtLegMsg",
      type: "TextBoxWidget",
      parent: "grpbxFreight",
      Label: "Leg Message:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLegMsg_PROPERTIES

      // END_USER_CODE-USER_txtLegMsg_PROPERTIES
    },
    txtLsk: {
      name: "txtLsk",
      type: "TextBoxWidget",
      parent: "grpbxSettlement",
      Label: "LSK:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLsk_PROPERTIES

      // END_USER_CODE-USER_txtLsk_PROPERTIES
    },
    txtLSKDamageRiding: {
      name: "txtLSKDamageRiding",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "+LSK Damage Riding:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKDamageRiding_PROPERTIES

      // END_USER_CODE-USER_txtLSKDamageRiding_PROPERTIES
    },
    txtLSKDamageRiding2: {
      name: "txtLSKDamageRiding2",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKDamageRiding2_PROPERTIES

      // END_USER_CODE-USER_txtLSKDamageRiding2_PROPERTIES
    },
    txtLSKFallThru: {
      name: "txtLSKFallThru",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "-LSK Fall Through Screen:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKFallThru_PROPERTIES

      // END_USER_CODE-USER_txtLSKFallThru_PROPERTIES
    },
    txtLSKFallThru2: {
      name: "txtLSKFallThru2",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKFallThru2_PROPERTIES

      // END_USER_CODE-USER_txtLSKFallThru2_PROPERTIES
    },
    txtLSKRidingGr: {
      name: "txtLSKRidingGr",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "+LSK Riding Screen:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKRidingGr_PROPERTIES

      // END_USER_CODE-USER_txtLSKRidingGr_PROPERTIES
    },
    txtLSKRidingGr2: {
      name: "txtLSKRidingGr2",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKRidingGr2_PROPERTIES

      // END_USER_CODE-USER_txtLSKRidingGr2_PROPERTIES
    },
    txtLSKRidingPct: {
      name: "txtLSKRidingPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKRidingPct_PROPERTIES

      // END_USER_CODE-USER_txtLSKRidingPct_PROPERTIES
    },
    txtMileage: {
      name: "txtMileage",
      type: "TextBoxWidget",
      parent: "grpbxFreightAcc",
      Label: "Mileage:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMileage_PROPERTIES

      // END_USER_CODE-USER_txtMileage_PROPERTIES
    },
    txtTripId: {
      name: "txtTripId",
      type: "TextBoxWidget",
      parent: "grpbxMultiStop",
      Label: "Trip ID:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMileage_PROPERTIES
      Enabled: false,
      // END_USER_CODE-USER_txtMileage_PROPERTIES
    },
    txtLeg: {
      name: "txtLeg",
      type: "TextBoxWidget",
      parent: "grpbxMultiStop",
      Label: "Leg #:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMileage_PROPERTIES

      // END_USER_CODE-USER_txtMileage_PROPERTIES
    },
    txtLegOrg: {
      name: "txtLegOrg",
      type: "TextBoxWidget",
      parent: "grpbxMultiStop",
      Label: "Leg Orig. Loc:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMileage_PROPERTIES

      // END_USER_CODE-USER_txtMileage_PROPERTIES
    },
    txtLegDest: {
      name: "txtLegDest",
      type: "TextBoxWidget",
      parent: "grpbxMultiStop",
      Label: "Leg Dest. Loc:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMileage_PROPERTIES

      // END_USER_CODE-USER_txtMileage_PROPERTIES
    },
    txtMileageOvrrd: {
      name: "txtMileageOvrrd",
      type: "TextBoxWidget",
      parent: "grpbxFreight",
      Label: "Mileage Override:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMileageOvrrd_PROPERTIES

      // END_USER_CODE-USER_txtMileageOvrrd_PROPERTIES
    },
    txtMoisture: {
      name: "txtMoisture",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Moisture:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMoisture_PROPERTIES

      // END_USER_CODE-USER_txtMoisture_PROPERTIES
    },
    txtMoisture2: {
      name: "txtMoisture2",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMoisture2_PROPERTIES

      // END_USER_CODE-USER_txtMoisture2_PROPERTIES
    },
    txtNetELK: {
      name: "txtNetELK",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Net ELK:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetELK_PROPERTIES

      // END_USER_CODE-USER_txtNetELK_PROPERTIES
    },
    txtNetELKPct: {
      name: "txtNetELKPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetELKPct_PROPERTIES

      // END_USER_CODE-USER_txtNetELKPct_PROPERTIES
    },
    txtNetLessLsk: {
      name: "txtNetLessLsk",
      type: "TextBoxWidget",
      parent: "grpbxSettlement",
      Label: "Net Less LSK:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetLessLsk_PROPERTIES

      // END_USER_CODE-USER_txtNetLessLsk_PROPERTIES
    },
    txtNetWt: {
      name: "txtNetWt",
      type: "TextBoxWidget",
      parent: "grpbxSettlement",
      Label: "Net Wt:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetWt_PROPERTIES

      // END_USER_CODE-USER_txtNetWt_PROPERTIES
    },
    txtOKDamageRiding: {
      name: "txtOKDamageRiding",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "+ OK Damage Riding:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOKDamageRiding_PROPERTIES

      // END_USER_CODE-USER_txtOKDamageRiding_PROPERTIES
    },
    txtOKDamageRiding2: {
      name: "txtOKDamageRiding2",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOKDamageRiding2_PROPERTIES

      // END_USER_CODE-USER_txtOKDamageRiding2_PROPERTIES
    },
    txtOKFallThru: {
      name: "txtOKFallThru",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "-OK Fall Thru:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOKFallThru_PROPERTIES

      // END_USER_CODE-USER_txtOKFallThru_PROPERTIES
    },
    txtOKFallThru2: {
      name: "txtOKFallThru2",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOKFallThru2_PROPERTIES

      // END_USER_CODE-USER_txtOKFallThru2_PROPERTIES
    },
    txtOKRidingGr: {
      name: "txtOKRidingGr",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "+OK Riding:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOKRidingGr_PROPERTIES

      // END_USER_CODE-USER_txtOKRidingGr_PROPERTIES
    },
    txtOKRidingGr2: {
      name: "txtOKRidingGr2",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOKRidingGr2_PROPERTIES

      // END_USER_CODE-USER_txtOKRidingGr2_PROPERTIES
    },
    txtOKRidingPct: {
      name: "txtOKRidingPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOKRidingPct_PROPERTIES

      // END_USER_CODE-USER_txtOKRidingPct_PROPERTIES
    },
    ddOleicShippdFrm: {
      name: "ddOleicShippdFrm",
      type: "DropDownFieldWidget",
      parent: "grpbxShippdFrmTo",
      HasLabel: false,
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOleicShippdFrm_PROPERTIES
      SpecialFirstOption: ""
      // END_USER_CODE-USER_txtOleicShippdFrm_PROPERTIES
    },
    ddOleicShippdTo: {
      name: "ddOleicShippdTo",
      type: "DropDownFieldWidget",
      parent: "grpbxShippdFrmTo",
      HasLabel: false,
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOleicShippdTo_PROPERTIES
      SpecialFirstOption: ""
      // END_USER_CODE-USER_txtOleicShippdTo_PROPERTIES
    },
    txtOrganicShippdFrm: {
      name: "txtOrganicShippdFrm",
      type: "TextBoxWidget",
      parent: "grpbxShippdFrmTo",
      HasLabel: false,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOrganicShippdFrm_PROPERTIES

      // END_USER_CODE-USER_txtOrganicShippdFrm_PROPERTIES
    },
    txtOrganicShippdTo: {
      name: "txtOrganicShippdTo",
      type: "TextBoxWidget",
      parent: "grpbxShippdFrmTo",
      HasLabel: false,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOrganicShippdTo_PROPERTIES

      // END_USER_CODE-USER_txtOrganicShippdTo_PROPERTIES
    },
    txtOtherFM: {
      name: "txtOtherFM",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Other Foreign Material:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOtherFM_PROPERTIES

      // END_USER_CODE-USER_txtOtherFM_PROPERTIES
    },
    txtPeanutsRecvd: {
      name: "txtPeanutsRecvd",
      type: "TextBoxWidget",
      parent: "grpbxRecvd",
      Label: "Peanuts:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      // START_USER_CODE-USER_txtPeanutsRecvd_PROPERTIES

      // END_USER_CODE-USER_txtPeanutsRecvd_PROPERTIES
    },
    txtPeanutsShip: {
      name: "txtPeanutsShip",
      type: "TextBoxWidget",
      parent: "grpbxShippd",
      Label: "Peanuts:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      // START_USER_CODE-USER_txtPeanutsShip_PROPERTIES

      // END_USER_CODE-USER_txtPeanutsShip_PROPERTIES
    },
    txtPhnDest: {
      name: "txtPhnDest",
      type: "TextBoxWidget",
      parent: "grpbxLocation",
      Label: "Phone#:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPhnDest_PROPERTIES

      // END_USER_CODE-USER_txtPhnDest_PROPERTIES
    },
    txtPhnShip: {
      name: "txtPhnShip",
      type: "TextBoxWidget",
      parent: "grpbxLocation",
      Label: "Phone#:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPhnShip_PROPERTIES

      // END_USER_CODE-USER_txtPhnShip_PROPERTIES
    },
    txtPittingDamage: {
      name: "txtPittingDamage",
      type: "TextBoxWidget",
      parent: "grpbxDamage",
      Label: "Pitting Damage:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPittingDamage_PROPERTIES

      // END_USER_CODE-USER_txtPittingDamage_PROPERTIES
    },
    txtPittingDamagePct: {
      name: "txtPittingDamagePct",
      type: "TextBoxWidget",
      parent: "grpbxDamage",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPittingDamagePct_PROPERTIES

      // END_USER_CODE-USER_txtPittingDamagePct_PROPERTIES
    },
    txtPnutShippdFrm: {
      name: "txtPnutShippdFrm",
      type: "DropDownFieldWidget",
      parent: "grpbxShippdFrmTo",
      HasLabel: false,
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      SpecialFirstOption: ""
    },
    txtPnutShippdTo: {
      name: "txtPnutShippdTo",
      type: "TextBoxWidget",
      parent: "grpbxShippdFrmTo",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPnutShippdTo_PROPERTIES

      // END_USER_CODE-USER_txtPnutShippdTo_PROPERTIES
    },
    txtPremAmnt: {
      name: "txtPremAmnt",
      type: "TextBoxWidget",
      parent: "grpbxFreightAcc",
      Label: "Premium Amt:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPremAmnt_PROPERTIES

      // END_USER_CODE-USER_txtPremAmnt_PROPERTIES
    },
    txtProceedAmt: {
      name: "txtProceedAmt",
      type: "TextBoxWidget",
      parent: "grpbxFreightAcc",
      Label: "Proceed Amt:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtProceedAmt_PROPERTIES

      // END_USER_CODE-USER_txtProceedAmt_PROPERTIES
    },
    txtSclTcktRcvd: {
      name: "txtSclTcktRcvd",
      type: "TextBoxWidget",
      parent: "grpbxRecvd",
      Label: "Scale Ticket #:",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSclTcktRcvd_PROPERTIES

      // END_USER_CODE-USER_txtSclTcktRcvd_PROPERTIES
    },
    txtSclTcktShip: {
      name: "txtSclTcktShip",
      type: "TextBoxWidget",
      parent: "grpbxShippd",
      Label: "Scale Ticket #:",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSclTcktShip_PROPERTIES

      // END_USER_CODE-USER_txtSclTcktShip_PROPERTIES
    },
    txtSegShppdFrm: {
      name: "txtSegShppdFrm",
      type: "TextBoxWidget",
      parent: "grpbxShippdFrmTo",
      HasLabel: false,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSegShppdFrm_PROPERTIES

      // END_USER_CODE-USER_txtSegShppdFrm_PROPERTIES
    },
    txtSegShppdTo: {
      name: "txtSegShppdTo",
      type: "TextBoxWidget",
      parent: "grpbxShippdFrmTo",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSegShppdTo_PROPERTIES

      // END_USER_CODE-USER_txtSegShppdTo_PROPERTIES
    },
    txtSMK1Gr: {
      name: "txtSMK1Gr",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "SMK 1:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK1Gr_PROPERTIES

      // END_USER_CODE-USER_txtSMK1Gr_PROPERTIES
    },
    txtSMK1Gr2: {
      name: "txtSMK1Gr2",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK1Gr2_PROPERTIES

      // END_USER_CODE-USER_txtSMK1Gr2_PROPERTIES
    },
    txtSMK1Pct: {
      name: "txtSMK1Pct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK1Pct_PROPERTIES

      // END_USER_CODE-USER_txtSMK1Pct_PROPERTIES
    },
    txtSMK2Gr: {
      name: "txtSMK2Gr",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "SMK 2:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK2Gr_PROPERTIES

      // END_USER_CODE-USER_txtSMK2Gr_PROPERTIES
    },
    txtSMK2Gr2: {
      name: "txtSMK2Gr2",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK2Gr2_PROPERTIES

      // END_USER_CODE-USER_txtSMK2Gr2_PROPERTIES
    },
    txtSMK2Pct: {
      name: "txtSMK2Pct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK2Pct_PROPERTIES

      // END_USER_CODE-USER_txtSMK2Pct_PROPERTIES
    },
    txtSMK3Gr: {
      name: "txtSMK3Gr",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "SMK 3:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK3Gr_PROPERTIES

      // END_USER_CODE-USER_txtSMK3Gr_PROPERTIES
    },
    txtSMK3Gr2: {
      name: "txtSMK3Gr2",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK3Gr2_PROPERTIES

      // END_USER_CODE-USER_txtSMK3Gr2_PROPERTIES
    },
    txtSMK3Pct: {
      name: "txtSMK3Pct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK3Pct_PROPERTIES

      // END_USER_CODE-USER_txtSMK3Pct_PROPERTIES
    },
    txtSMK4Gr: {
      name: "txtSMK4Gr",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "SMK 4:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK4Gr_PROPERTIES

      // END_USER_CODE-USER_txtSMK4Gr_PROPERTIES
    },
    txtSMK4Gr2: {
      name: "txtSMK4Gr2",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK4Gr2_PROPERTIES

      // END_USER_CODE-USER_txtSMK4Gr2_PROPERTIES
    },
    txtSMK4Pct: {
      name: "txtSMK4Pct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK4Pct_PROPERTIES

      // END_USER_CODE-USER_txtSMK4Pct_PROPERTIES
    },
    txtSoundSplits: {
      name: "txtSoundSplits",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Sound Splits:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSoundSplits_PROPERTIES

      // END_USER_CODE-USER_txtSoundSplits_PROPERTIES
    },
    txtSoundSplitsPct: {
      name: "txtSoundSplitsPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSoundSplitsPct_PROPERTIES

      // END_USER_CODE-USER_txtSoundSplitsPct_PROPERTIES
    },
    txtTotalDamage: {
      name: "txtTotalDamage",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Total Damage:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalDamage_PROPERTIES

      // END_USER_CODE-USER_txtTotalDamage_PROPERTIES
    },
    txtTotalDamagePct: {
      name: "txtTotalDamagePct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalDamagePct_PROPERTIES

      // END_USER_CODE-USER_txtTotalDamagePct_PROPERTIES
    },
    txtTotalELKDamage: {
      name: "txtTotalELKDamage",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Total ELK Damage:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalELKDamage_PROPERTIES

      // END_USER_CODE-USER_txtTotalELKDamage_PROPERTIES
    },
    txtTotalELKDamagePct: {
      name: "txtTotalELKDamagePct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalELKDamagePct_PROPERTIES

      // END_USER_CODE-USER_txtTotalELKDamagePct_PROPERTIES
    },
    txtTotalKernels: {
      name: "txtTotalKernels",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Total Kernels:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalKernels_PROPERTIES

      // END_USER_CODE-USER_txtTotalKernels_PROPERTIES
    },
    txtTotalKernels2: {
      name: "txtTotalKernels2",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalKernels2_PROPERTIES

      // END_USER_CODE-USER_txtTotalKernels2_PROPERTIES
    },
    txtTotalKernelsAndHulls: {
      name: "txtTotalKernelsAndHulls",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Total Kernels and Hulls:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalKernelsAndHulls_PROPERTIES

      // END_USER_CODE-USER_txtTotalKernelsAndHulls_PROPERTIES
    },
    txtTotalKernelsAndHulls2: {
      name: "txtTotalKernelsAndHulls2",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalKernelsAndHulls2_PROPERTIES

      // END_USER_CODE-USER_txtTotalKernelsAndHulls2_PROPERTIES
    },
    txtTotalOK: {
      name: "txtTotalOK",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Total OK:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalOK_PROPERTIES

      // END_USER_CODE-USER_txtTotalOK_PROPERTIES
    },
    txtTotalOKPct: {
      name: "txtTotalOKPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalOKPct_PROPERTIES

      // END_USER_CODE-USER_txtTotalOKPct_PROPERTIES
    },
    txtTotalSLK: {
      name: "txtTotalSLK",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Total LSK:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalSLK_PROPERTIES

      // END_USER_CODE-USER_txtTotalSLK_PROPERTIES
    },
    txtTotalSLKPct: {
      name: "txtTotalSLKPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalSLKPct_PROPERTIES

      // END_USER_CODE-USER_txtTotalSLKPct_PROPERTIES
    },
    txtTotalSMK: {
      name: "txtTotalSMK",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Total SMK:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalSMK_PROPERTIES

      // END_USER_CODE-USER_txtTotalSMK_PROPERTIES
    },
    txtTotalSMK2: {
      name: "txtTotalSMK2",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalSMK2_PROPERTIES

      // END_USER_CODE-USER_txtTotalSMK2_PROPERTIES
    },
    txtTotalSMKRScreen: {
      name: "txtTotalSMKRScreen",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      Label: "Total SMKR Screen:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalSMKRScreen_PROPERTIES

      // END_USER_CODE-USER_txtTotalSMKRScreen_PROPERTIES
    },
    txtTotalSMKRScreenPct: {
      name: "txtTotalSMKRScreenPct",
      type: "TextBoxWidget",
      parent: "grpbxReGrading",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalSMKRScreenPct_PROPERTIES

      // END_USER_CODE-USER_txtTotalSMKRScreenPct_PROPERTIES
    },
    txtTruckLine: {
      name: "txtTruckLine",
      type: "TextBoxWidget",
      parent: "grpbxLoad",
      Label: "Truck Line:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTruckLine_PROPERTIES

      // END_USER_CODE-USER_txtTruckLine_PROPERTIES
    },
    ddVarietyShppdFrm: {
      name: "ddVarietyShppdFrm",
      type: "DropDownFieldWidget",
      parent: "grpbxShippdFrmTo",
      HasLabel: false,
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVarietyShppdFrm_PROPERTIES
      SpecialFirstOption: ""
      // END_USER_CODE-USER_txtVarietyShppdFrm_PROPERTIES
    },
    ddVarietyShppdTo: {
      name: "ddVarietyShppdTo",
      type: "DropDownFieldWidget",
      parent: "grpbxShippdFrmTo",
      HasLabel: false,
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVarietyShppdTo_PROPERTIES
      SpecialFirstOption: ""
      // END_USER_CODE-USER_txtVarietyShppdTo_PROPERTIES
    },
    txtVehicle: {
      name: "txtVehicle",
      type: "TextBoxWidget",
      parent: "grpbxLoad",
      Label: "Vehicle #:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 20 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVehicle_PROPERTIES

      // END_USER_CODE-USER_txtVehicle_PROPERTIES
    },
    txtVicamReading: {
      name: "txtVicamReading",
      type: "TextBoxWidget",
      parent: "grpbxCheckbxViacam",
      Label: "Vicam Reading:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVicamReading_PROPERTIES

      // END_USER_CODE-USER_txtVicamReading_PROPERTIES
    },
    txtVndrId: {
      name: "txtVndrId",
      type: "TextBoxWidget",
      parent: "grpbxFreight",
      Label: "Vendor ID",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVndrId_PROPERTIES

      // END_USER_CODE-USER_txtVndrId_PROPERTIES
    },
    txtWeightVariance: {
      name: "txtWeightVariance",
      type: "TextBoxWidget",
      parent: "grpbxWtVariance",
      Label: "Weight Variance:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWeightVariance_PROPERTIES

      // END_USER_CODE-USER_txtWeightVariance_PROPERTIES
    },
    txtWhseBinShippdFrm: {
      name: "txtWhseBinShippdFrm",
      type: "TextBoxWidget",
      parent: "grpbxShippdFrmTo",
      HasLabel: false,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWhseBinShippdFrm_PROPERTIES

      // END_USER_CODE-USER_txtWhseBinShippdFrm_PROPERTIES
    },
    txtWhseBinShippdTo: {
      name: "txtWhseBinShippdTo",
      type: "TextBoxWidget",
      parent: "grpbxShippdFrmTo",
      HasLabel: false,
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWhseBinShippdTo_PROPERTIES

      // END_USER_CODE-USER_txtWhseBinShippdTo_PROPERTIES
    },
    txtWt: {
      name: "txtWt",
      type: "TextBoxWidget",
      parent: "grpbxFreightAcc",
      Label: "Weight:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWt_PROPERTIES

      // END_USER_CODE-USER_txtWt_PROPERTIES
    },
    txtWtInclVehclRecvd: {
      name: "txtWtInclVehclRecvd",
      type: "TextBoxWidget",
      parent: "grpbxRecvd",
      Label: "Wt including vehicle:",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtInclVehclRecvd_PROPERTIES

      // END_USER_CODE-USER_txtWtInclVehclRecvd_PROPERTIES
    },
    txtWtLessFm: {
      name: "txtWtLessFm",
      type: "TextBoxWidget",
      parent: "grpbxSettlement",
      Label: "Wt Less FM:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtLessFm_PROPERTIES

      // END_USER_CODE-USER_txtWtLessFm_PROPERTIES
    },
    txtWtVehclShip: {
      name: "txtWtVehclShip",
      type: "TextBoxWidget",
      parent: "grpbxShippd",
      Label: "Wt of vehicle:",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtVehclShip_PROPERTIES

      // END_USER_CODE-USER_txtWtVehclShip_PROPERTIES
    },
    txtWtVehicleShip: {
      name: "txtWtVehicleShip",
      type: "TextBoxWidget",
      parent: "grpbxShippd",
      Label: "Wt including vehicle:",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtVehicleShip_PROPERTIES

      // END_USER_CODE-USER_txtWtVehicleShip_PROPERTIES
    },
    txtWtVhclRcvd: {
      name: "txtWtVhclRcvd",
      type: "TextBoxWidget",
      parent: "grpbxRecvd",
      Label: "Wt of vehicle:",
      LengthRange: { MinLength: 0, MaxLength: 9 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtVhclRcvd_PROPERTIES

      // END_USER_CODE-USER_txtWtVhclRcvd_PROPERTIES
    },
    txxtDriver: {
      name: "txxtDriver",
      type: "TextBoxWidget",
      parent: "grpbxLoad",
      Label: "Driver:",
      LengthRange: { MinLength: 0, MaxLength: 35 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txxtDriver_PROPERTIES

      // END_USER_CODE-USER_txxtDriver_PROPERTIES
    },
    grpbxStockTransfer: {
      name: "grpbxStockTransfer",
      type: "GroupBoxWidget",
      parent: "StockTransfer",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxStockTransfer_PROPERTIES

      // END_USER_CODE-USER_grpbxStockTransfer_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "StockTransfer",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "9",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "StockTransfer#StockTransferOldNumber": {},
        "StockTransfer#PremiumDeductionsFrtAccrl": {},
        "ContractManagement#ViewPayments": {},
        "StockTransfer#ReportPreviewTransfer": {},
      },
      REVERSE: {
        "StockTransfer#StockTransferOldNumber": {},
        "StockTransfer#PremiumDeductionsFrtAccrl": {},
        "ContractManagement#ViewPayments": {},
        "StockTransfer#ReportPreviewTransfer": {},
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnVoid: {
    //   DEFAULT: ["StockTransfer#StockTransferOldNumber#DEFAULT#true#Click"],
    // },
    // btnPremDeduct: {
    //   DEFAULT: ["StockTransfer#PremiumDeductionsFrtAccrl#DEFAULT#true#Click"],
    // },
    // btnPayment: {
    //   DEFAULT: ["ContractManagement#ViewPayments#DEFAULT#true#Click"],
    // },
    btnPrint: {
      DEFAULT: ["StockTransfer#ReportPreviewTransfer#DEFAULT#true#Click"],
    },
    btnPopup: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(false);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    setTabIndex();
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    setTimeout(() => {
      document.getElementsByClassName('ddType')[1].firstChild.lastChild.firstChild.firstChild.focus()
    }, 500);
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  async function dataForTransferSearch() {
    try {
      let js = {
        transfer_num: mStockTransfer.mudtProps.transfer_num,
        transfer_type: mStockTransfer.mudtProps.transfer_type,
        xfer_status: mStockTransfer.mudtProps.xfer_status,
        shp_date: mStockTransfer.mudtProps.shp_date,
        peanut_type: mStockTransfer.mudtProps.pnut_type_id,
        seg: mStockTransfer.mudtProps.seg_type,
        shp_buy_pt: mStockTransfer.mudtProps.shp_buy_pt,
        shp_whs_bin: mStockTransfer.mudtProps.shp_whs_bin,
        rec_buy_pt: mStockTransfer.mudtProps.rec_buy_pt,
        rec_whs_bin: mStockTransfer.mudtProps.rec_whs_bin,
        from_variety: mStockTransfer.mudtProps.from_pnut_variety_id,
        to_variety: mStockTransfer.mudtProps.to_pnut_variety_id,
        from_gen: mStockTransfer.mudtProps.from_seed_gen,
        to_gen: mStockTransfer.mudtProps.to_seed_gen,
        from_oleic: mStockTransfer.mudtProps.from_oleic_ind,
        to_oleic: mStockTransfer.mudtProps.to_oleic_ind,
        from_organic: mStockTransfer.mudtProps.from_organic_ind,
        to_organic: mStockTransfer.mudtProps.to_organic_ind,
        rec_peanut_wt: mStockTransfer.mudtProps.rec_peanut_wt,
        shp_peanut_wt: mStockTransfer.mudtProps.shp_peanut_wt,
        inv_weight: await mStockTransfer.WeightVariance(),
        net_wt: mStockTransfer.mudtProps.net_wt,
        frt_accrl_complete: mStockTransfer.mudtProps.frt_accrl_complete,
        frt_scac_id: mStockTransfer.mudtProps.frt_scac_id,
        pay_freight_ind: mStockTransfer.mudtProps.pay_freight_ind,
        other_wt: mStockTransfer.mudtProps.other_wt,
        shp_whouse: mStockTransfer.mudtProps.shp_whouse,
        shp_bin: mStockTransfer.mudtProps.shp_bin,
        rec_whouse: mStockTransfer.mudtProps.rec_whouse,
        rec_bin: mStockTransfer.mudtProps.rec_bin,
        shp_buy_info: mStockTransfer.mudtProps.shp_buy_info,
        rec_buy_info: mStockTransfer.mudtProps.rec_buy_info,
        vendor_info: mStockTransfer.mudtProps.vendor_info,
        WeightVariance: await mStockTransfer.WeightVariance()
      }

      setData(thisObj, "dataForTransferSearch", js)
    } catch (error) {
      errorHandler(error)
    }
  }

  useEffect(() => {
    VendorDetails();
  }, [getData(thisObj, "vendorDetails")]);

  // START_USER_CODE-USER_METHODS//Chinmay

  //----------------Constants and States start----------------
  const compIdFromLS = () => sessionStorage.getItem("compId");
  const cropYearFromLS = () => JSON.parse(sessionStorage.getItem("year"));
  const useridFromLS = () => sessionStorage.getItem("userid");

  const GradingRule = {
    GRADE_FM_SAMPLE_WEIGHT: "FM Sample Weight Is Invalid.",
    GRADE_CLEAN_SAMPLE_WEIGHT: "Clean Sample Weight Is Invalid.",
  };

  const [mStockTransfer, setStockTransfer] = useState(new clsTransfer());
  const [mPeanutPrice, setPeanutPrice] = useState(new clsPeanutPrice());
  const [mPeanutType, setPeanutType] = useState(new clsPeanutType());
  const [mORules, setMORules] = useState(new clsBrokenRules());

  const [triggerLoad, setLoadTrigger] = useState(false);
  const [eventTriggered, setEventTrigger] = useState(false);

  const [recreateTrigger, setRecreateTrigger] = useState(false)
  const [oldObj, setOldObj] = useState(null)
  const [sVendorInfo, setVendorInfo] = useState("")

  const UserTransferRoleList = ["ReadOnly", "Shipper", "Receiver", "Freighter"];
  const PayFreightType = [
    "ShippedWeight",
    "ReceivedWeight",
    "OtherWeight",
    "NoFreight",
  ];

  const TransferStatus = [
    "Pending",
    "Shipped",
    "Received",
    "FreightAccrued",
    "Voided",
  ];

  const ManualEntryMode = ["ManualGrams", "ManualPct"];

  const GRADE_PCT_FORMAT = 2; // "#0.00"
  const GRADE_GRAM_FORMAT = 1; //"#,##0.0"
  const G_WEIGHT_FORMAT = 0; // "###,###,###"
  //----------------Constants and States end------------------

  useEffect(() => {
    if (eventTriggered == true || eventTriggered == "Pending") {
      if (mStockTransfer.BuyingPointChanged.trigger == true) {
        buyingPointChangedTrigger(mStockTransfer.BuyingPointChanged);
        mStockTransfer.BuyingPointChanged.trigger = false;
        mStockTransfer.BuyingPointChanged.isShipping = null;
        mStockTransfer.BuyingPointChanged.buyingPt = "";
      }
      if (mStockTransfer.TransferStatusChanged) {
        transferStatusChangedTrigger();
        mStockTransfer.TransferStatusChanged = false;
      }
      if (mStockTransfer.TransferTypeChanged) {
        transferTypeChangedTrigger();
        mStockTransfer.TransferTypeChanged = false;
      }
      if (mStockTransfer.FreightAccrualStatusChanged.trigger == true) {
        freightAccrualStatusChangedTrigger(mStockTransfer.FreightAccrualStatusChanged);
        mStockTransfer.FreightAccrualStatusChanged.trigger = false;
        mStockTransfer.FreightAccrualStatusChanged.isFreightAccrualCompleted = null;
      }
      if (mStockTransfer.WeightChanged) {
        weightChangedTrigger();
        mStockTransfer.WeightChanged = false;
      }
      if (mStockTransfer.ManualGradeStatusChanged.trigger == true) {
        manualGradeStatusChangedTrigger(mStockTransfer.ManualGradeStatusChanged);
        mStockTransfer.ManualGradeStatusChanged.trigger = false;
        mStockTransfer.ManualGradeStatusChanged.manualGradeMode = "";
      }
      if (mStockTransfer.PeanutValueChanged) {
        peanutValueChangedTrigger();
        mStockTransfer.PeanutValueChanged = false;
      }
      if (mStockTransfer.ShippingBinChanged) {
        shippingBinChangedTrigger();
        mStockTransfer.ShippingBinChanged = false;
      }
      if (mStockTransfer.PropertyChanged) {
        propertyChangedTrigger();
        mStockTransfer.PropertyChanged = false;
      }
      if (mStockTransfer.BuyingPointChanged.trigger == false && mStockTransfer.TransferStatusChanged == false && mStockTransfer.TransferTypeChanged == false && mStockTransfer.FreightAccrualStatusChanged.trigger == false && mStockTransfer.WeightChanged == false && mStockTransfer.ManualGradeStatusChanged.trigger == false && mStockTransfer.PeanutValueChanged == false && mStockTransfer.ShippingBinChanged == false && mStockTransfer.PropertyChanged == false) {
        setEventTrigger(false)
      }
      else {
        eventTriggered == true ? setEventTrigger("Pending") : setEventTrigger(true)
      }
    }
  }, [eventTriggered]);

  const buyingPointChangedTrigger = async (data) => {
    await mStockTransfer_BuyingPointChanged(data.buyingPt, data.isShipping);
  };

  const transferStatusChangedTrigger = async () => {
    await mStockTransfer_TransferStatusChanged();
  };

  const transferTypeChangedTrigger = async () => {
    await mStockTransfer_TransferTypeChanged();
  };

  const freightAccrualStatusChangedTrigger = async (data) => {
    await mStockTransfer_FreightAccrualStatusChanged(
      data.isFreightAccrualCompleted
    );
  };

  const weightChangedTrigger = async () => {
    await mStockTransfer_WeightChanged();
  };

  const manualGradeStatusChangedTrigger = async (data) => {
    await mStockTransfer_ManualGradeStatusChanged(data.manualGradeMode);
  };

  const peanutValueChangedTrigger = async () => {
    await mStockTransfer_PeanutValueChanged();
  };

  const shippingBinChangedTrigger = async () => {
    await mStockTransfer_ShippingBinChanged();
  };

  const propertyChangedTrigger = async () => {
    await mStockTransfer_PropertyChanged();
  };
  //----------------useEffects start--------------------------
  useEffect(() => {
    Initiate();
  }, [getData(thisObj, "StockTransfer")]);

  useEffect(() => {
    let closingBoolean = getData(thisObj, "closingOfSelectFromList");

    if (closingBoolean == true) {
      afterFromListClosing();
      setData(thisObj, "closingOfSelectFromList", false);
    }
  }, [getData(thisObj, "closingOfSelectFromList")]);

  useEffect(() => {
    let Data_from_nextScreen = getData(thisObj, "dataFromNewNumber"); //this boolean will become through frmStockTransferNewNumber.DialogResult
    afterClosingof_stockTransferNewNumber(Data_from_nextScreen)
  }, [getData(thisObj, "dataFromNewNumber")]); //need some boolean from StocktransferNewNumber

  async function afterClosingof_stockTransferNewNumber(Data_from_nextScreen) {
    try {
      if (Data_from_nextScreen !== null && Data_from_nextScreen.DialogResult !== undefined && Data_from_nextScreen.DialogResult == true) {
        setLoading(true)
        setStockTransfer(Data_from_nextScreen?.mStockTransfer) // please test this line
        mStockTransfer.OldTransferNumber("");
        //mStockTransfer.transfer_num(Data_from_nextScreen?.newtransferNumber)  //additional line uncomment if required
        setValue(thisObj, "lblNew", mStockTransfer.mudtProps.transfer_num);
        disable(thisObj, "ddShippingLoc");
        disable(thisObj, "ddType");
        if (mStockTransfer.mFANeedInfo !== "") {
          showMessage(thisObj, mStockTransfer.mFANeedInfo);
          mStockTransfer.mFANeedInfo = "";
        } else {
          let childPageOpening = await createFreight("save2");
          if (childPageOpening) {
            setLoading(false)
            return
          }
        }
        await SetupForm();

        setValue(thisObj, "txtLegMsg", mStockTransfer.mudtProps.leg_mileage != "" && mStockTransfer.mudtProps.leg_mileage != "0" ? FormatNumber(mStockTransfer.mudtProps.leg_mileage, 0) : "");
        setValue(thisObj, "txtOtherWt", mStockTransfer.mudtProps.frt_other_wt != "" && mStockTransfer.mudtProps.frt_other_wt != "0" ? FormatNumber(mStockTransfer.mudtProps.frt_other_wt, 0) : "");
        await mStockTransfer.ApplyEdit();
        await mStockTransfer.BeginEdit();
        mStockTransfer.mbChgShpLoc = false;
        setLoading(false)
        setData(thisObj, "dataFromNewNumber", null);
      }

    } catch (error) {
      errorHandler(error)
    }
  }

  useEffect(() => {
    let boolean = getData(thisObj, "ClosingOfStockTransferOldNumber"); // page closing boolean from frmStockTransferOldNumber

    if (boolean) {
      afterclosingof_stockTransferOldNumber()
      setData(thisObj, "ClosingOfStockTransferOldNumber", false);
    }
  }, [getData(thisObj, "ClosingOfStockTransferOldNumber")]) // need page closing boolean from stockTransferOldNumber

  async function afterclosingof_stockTransferOldNumber() {
    try {
      let frmStockTransferOldNumber = getData(thisObj, "frmStockTransfer");
      if (frmStockTransferOldNumber?.Success) {  //frmStockTransferOldNumber.Success
        setLoading(true)
        await RecreateTransfer(frmStockTransferOldNumber.newShpLocation);
        setData(thisObj, "frmStockTransfer", { Success: false })
        setLoading(false)
      }
      return;
    } catch (error) {
      errorHandler(error)
    }
  }

  useEffect(() => {
    let boolean = getData(thisObj, "closingOfPremiumDeductionsFrtAccrl")

    if (boolean) {
      let updatedValues = getData(thisObj, "DatafromPremiumDeductionFRTAccrl") //always check this values if any doubt in debugging
      if (updatedValues !== null) {

        for (let key in updatedValues) {
          if (updatedValues[key] !== undefined) {
            mStockTransfer[key](updatedValues[key])
          } else {
            mStockTransfer[key]("0")
          }
        }

        let getFreightRate_Data = getData(thisObj, "GetFreightRate")
        if (getFreightRate_Data !== null) {
          mStockTransfer.mudtProps.freight_rate = getFreightRate_Data.freight_rate
          mStockTransfer.mudtProps.freight_amt = getFreightRate_Data.freight_amt
          mStockTransfer.mudtProps.frt_fuel_pct = getFreightRate_Data.frt_fuel_pct
          mStockTransfer.mudtProps.frt_rate_basis = getFreightRate_Data.frt_rate_basis
          mStockTransfer.mudtProps.frt_rate_source = getFreightRate_Data.frt_rate_source
          mStockTransfer.mudtProps.trip_weight = getFreightRate_Data.trip_weight
          setData(thisObj, "GetFreightRate", null)
        }


        setData(thisObj, "DatafromPremiumDeductionFRTAccrl", null)
      }
      let button = getData(thisObj, "btnNameForStockTransfer")

      switch (button) {
        case "void":
          onClosingOfPremiumDeductionFrtAccrl_onFreightButton()
          break;

        case "save1":
          onClosingOfPremiumDeductionFrtAccrl_onSaveButton1()
          break;

        case "save2":
          onClosingOfPremiumDeductionFrtAccrl_onSaveButton2()
          break;

        case "save3":
          onClosingOfPremiumDeductionFrtAccrl_onSaveButton3()
          break;

        default:
          break;
      }
      setEventTrigger(true)


      setData(thisObj, "closingOfPremiumDeductionsFrtAccrl", false)
    }
  }, [getData(thisObj, "closingOfPremiumDeductionsFrtAccrl")])

  async function onClosingOfPremiumDeductionFrtAccrl_onFreightButton() {
    try {
      setLoading(true)
      await FillPayFreightType()
      setValue(thisObj, "txtOtherWt", mStockTransfer.mudtProps.frt_other_wt != "" && mStockTransfer.mudtProps.frt_other_wt != "0" ? FormatNumber(mStockTransfer.mudtProps.frt_other_wt, 0) : "");

      let saveResponse = await mStockTransfer.Save()
      if (!saveResponse && mStockTransfer.mDbError !== "") {
        alert("Save Transfer ERROR: " + mStockTransfer.mDbError);
      }

      await SetupForm();
      await FillFreightAmount();

      setLoading(false)
      if (mStockTransfer.mbFromFrtAccrl) {
        onbtnCloseClick()
      }
    } catch (error) {
      errorHandler(error)
    }
  }

  async function onClosingOfPremiumDeductionFrtAccrl_onSaveButton1() {
    try {
      setLoading(true)
      //remainig from that if condition
      setValue(thisObj, "lblNew", mStockTransfer.mudtProps.transfer_num);
      disable(thisObj, "ddShippingLoc");
      disable(thisObj, "ddType");
      await SetupForm();

      //remaining code outside if-else conditions
      setValue(thisObj, "txtLegMsg", mStockTransfer.mudtProps.leg_mileage != "" && mStockTransfer.mudtProps.leg_mileage != "0" ? FormatNumber(mStockTransfer.mudtProps.leg_mileage, 0) : "");
      setValue(thisObj, "txtOtherWt", mStockTransfer.mudtProps.frt_other_wt != "" && mStockTransfer.mudtProps.frt_other_wt != "0" ? FormatNumber(mStockTransfer.mudtProps.frt_other_wt, 0) : "");
      await mStockTransfer.ApplyEdit();
      await mStockTransfer.BeginEdit();
      mStockTransfer.mbChgShpLoc = false;

      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  }

  async function onClosingOfPremiumDeductionFrtAccrl_onSaveButton2() {
    try {
      setLoading(true)
      //this function is to handle if frtAccurl screens opens after closing of newNumber screen, hence "dataFromNewNumber" is also getting set as null
      await SetupForm();

      setValue(thisObj, "txtLegMsg", mStockTransfer.mudtProps.leg_mileage != "" && mStockTransfer.mudtProps.leg_mileage != "0" ? FormatNumber(mStockTransfer.mudtProps.leg_mileage, 0) : "");
      setValue(thisObj, "txtOtherWt", mStockTransfer.mudtProps.frt_other_wt != "" && mStockTransfer.mudtProps.frt_other_wt != "0" ? FormatNumber(mStockTransfer.mudtProps.frt_other_wt, 0) : "");
      await mStockTransfer.ApplyEdit();
      await mStockTransfer.BeginEdit();
      mStockTransfer.mbChgShpLoc = false;

      setData(thisObj, "dataFromNewNumber", null);
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  }

  async function onClosingOfPremiumDeductionFrtAccrl_onSaveButton3() {
    try {
      setLoading(true)
      //outside of that else condition
      await SetupForm();
      await fillSettlementInfo();
      await FillFreightAmount();
      await FillGradeInfo();
      await FillPayFreightType();

      //outside of main if-else condition
      setValue(thisObj, "txtLegMsg", mStockTransfer.mudtProps.leg_mileage != "" && mStockTransfer.mudtProps.leg_mileage != "0" ? FormatNumber(mStockTransfer.mudtProps.leg_mileage, 0) : "");
      setValue(thisObj, "txtOtherWt", mStockTransfer.mudtProps.frt_other_wt != "" && mStockTransfer.mudtProps.frt_other_wt != "0" ? FormatNumber(mStockTransfer.mudtProps.frt_other_wt, 0) : "");
      await mStockTransfer.ApplyEdit();
      await mStockTransfer.BeginEdit();
      mStockTransfer.mbChgShpLoc = false;

      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  }

  useEffect(() => { //useeffect for custom cross button
    let crossClick = commonContext.getStockTransfer_crossClick
    if (crossClick == true) {
      onbtnCloseClick()
      commonContext.setStockTransfer_crossClick(false)
    }
  }, [commonContext.getStockTransfer_crossClick])
  //----------------useEffects end----------------------------

  //----------------Stock Transfer start-------------------
  useEffect(() => {
    if (triggerLoad) {
      FormLoad(mStockTransfer);
      setLoadTrigger(false)
    }
  }, [triggerLoad]);

  async function Initiate() {
    let data = getData(thisObj, "StockTransfer");
    if (data != undefined) {
      setLoading(true);
      //--Commented this for hiding New Transfer Button as per disscussion with Jeff on 03/04/2024--
      // data.FromFrtAccrl === true
      //   ? hide(thisObj, "btnNewTrnsfr", false)
      //   : show(thisObj, "btnNewTrnsfr", false);
      //--------------------------------------------------------------------------------------------
      CurrentUser.NotSpecifiedIsOn(true);
      if (data.ReCreate == false) {
        if (![null, undefined, ""].includes(data.TransferRole)) {
          await data.mTransfer.SetTransferRole(
            UserTransferRoleList.indexOf(data.TransferRole)
          );
        } else {
          await data.mTransfer.CheckTransferAccessLevel(
            data.mTransfer.shp_buy_pt,
            data.mTransfer.rec_buy_pt,
            data.mTransfer.transfer_type
          );
        }
      }
      else {
        await data.mTransfer.SetTransferRole(data.TransferRole)
      }
      await data.mTransfer.setMaxDateRange();
      data.mTransfer.mbFromFrtAccrl = data.FromFrtAccrl
      setStockTransfer(data.mTransfer);
      setLoadTrigger(true);
      disable(thisObj, "txtVndrId");
    }
  }

  useEffect(() => {
    if (recreateTrigger) {
      RecreateValues()
    }
  }, [recreateTrigger])

  const RecreateValues = async () => {
    mStockTransfer.dispatch_num(oldObj.mudtProps.dispatch_num);
    mStockTransfer.vehichle_num(oldObj.mudtProps.vehichle_num);
    mStockTransfer.settled_1007_num(oldObj.mudtProps.settled_1007_num);
    mStockTransfer.truck_line(oldObj.mudtProps.truck_line);
    mStockTransfer.driver_name(oldObj.mudtProps.driver_name);
    mStockTransfer.frt_lease_pct(oldObj.mudtProps.frt_lease_pct);
    mStockTransfer.pay_freight_ind(oldObj.mudtProps.pay_freight_ind);
    mStockTransfer.frt_other_wt(oldObj.mudtProps.frt_other_wt);

    //'Inventory info
    mStockTransfer.inventory_ind(oldObj.mudtProps.inventory_ind);
    mStockTransfer.other_wt(oldObj.mudtProps.other_wt);

    //'Shipped info
    mStockTransfer.shp_date(oldObj.mudtProps.shp_date);
    if (mStockTransfer.mudtProps.transfer_type !== "NL") {
      mStockTransfer.shp_scale_tick(oldObj.mudtProps.shp_scale_tick);
      mStockTransfer.shp_split_scale(oldObj.mudtProps.shp_split_scale);
      mStockTransfer.shp_tare_wt(oldObj.mudtProps.shp_tare_wt);
      mStockTransfer.shp_vehicle_wt(oldObj.mudtProps.shp_vehicle_wt);
      mStockTransfer.shp_peanut_wt(oldObj.mudtProps.shp_peanut_wt);
    }

    //'Received infor
    if (mStockTransfer.mudtProps.transfer_type !== "NL") {
      mStockTransfer.rec_date(oldObj.mudtProps.rec_date);
      mStockTransfer.rec_scale_tick(oldObj.mudtProps.rec_scale_tick);
      mStockTransfer.rec_split_scale(oldObj.mudtProps.rec_split_scale);
      mStockTransfer.rec_tare_wt(oldObj.mudtProps.rec_tare_wt);
      mStockTransfer.rec_vehicle_wt(oldObj.mudtProps.rec_vehicle_wt);
      mStockTransfer.rec_peanut_wt(oldObj.mudtProps.rec_peanut_wt);
    }

    //'FA info
    mStockTransfer.frt_scac_id(oldObj.mudtProps.frt_scac_id);
    mStockTransfer.frt_vendor(oldObj.mudtProps.frt_vendor);
    mStockTransfer.vendor_info(sVendorInfo);

    //'Peanut info for FF and NL only.
    //'ST peanut info is driven by  Whse/Bin therefore will not be copied.
    if (mStockTransfer.mudtProps.transfer_type == "FF" || mStockTransfer.mudtProps.transfer_type == "NL") {
      mStockTransfer.pnut_type_id(oldObj.mudtProps.pnut_type_id);
    }

    //'Copy grade info only if it was entered manually
    if (mStockTransfer.mudtProps.manual_grade_ind == "Y") {
      mStockTransfer.manual_grade_ind(oldObj.mudtProps.manual_grade_ind);
      mStockTransfer.fm_sample(oldObj.mudtProps.fm_sample);
      mStockTransfer.cln_sample(oldObj.mudtProps.cln_sample);
      mStockTransfer.smk_gr(oldObj.mudtProps.smk_gr);
      mStockTransfer.ss_gr(oldObj.mudtProps.ss_gr);
      mStockTransfer.ok_gr(oldObj.mudtProps.ok_gr);
      mStockTransfer.dam_split_gr(oldObj.mudtProps.dam_split_gr);
      mStockTransfer.dam_gr(oldObj.mudtProps.dam_gr);
      mStockTransfer.hull_gr(oldObj.mudtProps.hull_gr);
      mStockTransfer.frez_dam_gr(oldObj.mudtProps.frez_dam_gr);
      mStockTransfer.pit_dam_gr(oldObj.mudtProps.pit_dam_gr);
      mStockTransfer.cncl_rmd_gr(oldObj.mudtProps.cncl_rmd_gr);
      mStockTransfer.cr_br_gr(oldObj.mudtProps.cr_br_gr);
      mStockTransfer.dcol_shel_gr(oldObj.mudtProps.dcol_shel_gr);
      mStockTransfer.hul_brit_gr(oldObj.mudtProps.hul_brit_gr);
      mStockTransfer.jumbo_gr(oldObj.mudtProps.jumbo_gr);
      mStockTransfer.elk_gr(oldObj.mudtProps.elk_gr);
      mStockTransfer.elk_ride_gr(oldObj.mudtProps.elk_ride_gr);
      mStockTransfer.elk_dam_gr(oldObj.mudtProps.elk_dam_gr);
      mStockTransfer.fm_gr(oldObj.mudtProps.fm_gr);
      mStockTransfer.lsk_gr(oldObj.mudtProps.lsk_gr);
      mStockTransfer.fan_gr(oldObj.mudtProps.fan_gr);
      mStockTransfer.ride_pscrib_gr(oldObj.mudtProps.ride_pscrib_gr);
      mStockTransfer.smk_pct(oldObj.mudtProps.smk_pct);
      mStockTransfer.smk_1_pct(oldObj.mudtProps.smk_1_pct);
      mStockTransfer.smk_2_pct(oldObj.mudtProps.smk_2_pct);
      mStockTransfer.smk_3_pct(oldObj.mudtProps.smk_3_pct);
      mStockTransfer.smk_4_pct(oldObj.mudtProps.smk_4_pct);
      mStockTransfer.ss_pct(oldObj.mudtProps.ss_pct);
      mStockTransfer.ok_pct(oldObj.mudtProps.ok_pct);
      mStockTransfer.ok_ride_pct(oldObj.mudtProps.ok_ride_pct);
      mStockTransfer.ok_fall_pct(oldObj.mudtProps.ok_fall_pct);
      mStockTransfer.ok_dam_pct(oldObj.mudtProps.ok_dam_pct);
      mStockTransfer.dam_pct(oldObj.mudtProps.dam_pct);
      mStockTransfer.tkc_pct(oldObj.mudtProps.tkc_pct);
      mStockTransfer.hull_pct(oldObj.mudtProps.hull_pct);
      mStockTransfer.frez_dam_pct(oldObj.mudtProps.frez_dam_pct);
      mStockTransfer.pit_dam_pct(oldObj.mudtProps.pit_dam_pct);
      mStockTransfer.cncl_rmd_pct(oldObj.mudtProps.cncl_rmd_pct);
      mStockTransfer.cr_br_pct(oldObj.mudtProps.cr_br_pct);
      mStockTransfer.dcol_shel_pct(oldObj.mudtProps.dcol_shel_pct);
      mStockTransfer.hul_brit_pct(oldObj.mudtProps.hul_brit_pct);
      mStockTransfer.jumbo_pct(oldObj.mudtProps.jumbo_pct);
      mStockTransfer.elk_pct(oldObj.mudtProps.elk_pct);
      mStockTransfer.elk_ride_pct(oldObj.mudtProps.elk_ride_pct);
      mStockTransfer.elk_dam_pct(oldObj.mudtProps.elk_dam_pct);
      mStockTransfer.fm_pct(oldObj.mudtProps.fm_pct);
      mStockTransfer.lsk_pct(oldObj.mudtProps.lsk_pct);
      mStockTransfer.lsk_ride_pct(oldObj.mudtProps.lsk_ride_pct);
      mStockTransfer.lsk_fall_pct(oldObj.mudtProps.lsk_fall_pct);
      mStockTransfer.lsk_dam_pct(oldObj.mudtProps.lsk_dam_pct);
      mStockTransfer.moist_pct(oldObj.mudtProps.moist_pct);
      mStockTransfer.fan_pct(oldObj.mudtProps.fan_pct);
      mStockTransfer.ride_pscrib_pct(oldObj.mudtProps.ride_pscrib_pct);
      mStockTransfer.oth_fm(oldObj.mudtProps.oth_fm);
      mStockTransfer.ok_guage(oldObj.mudtProps.ok_guage);
      mStockTransfer.smk_1_guage(oldObj.mudtProps.smk_1_guage);
      mStockTransfer.smk_2_guage(oldObj.mudtProps.smk_2_guage);
      mStockTransfer.smk_3_guage(oldObj.mudtProps.smk_3_guage);
      mStockTransfer.smk_4_guage(oldObj.mudtProps.smk_4_guage);
      mStockTransfer.lsk_guage(oldObj.mudtProps.lsk_guage);
      mStockTransfer.flavus_discount(oldObj.mudtProps.flavus_discount);
      mStockTransfer.flavus_ind(oldObj.mudtProps.flavus_ind);
    }

    //'Remark info
    mStockTransfer.remark(oldObj.mudtProps.remark);
    mStockTransfer.OldTransferNumber(oldObj.mudtProps.transfer_num); //moving previous trnader num to current's old trnasfer num

    //'others
    mStockTransfer.original_xfer(oldObj.mudtProps.original_xfer);
    mStockTransfer.corn_ind(oldObj.mudtProps.corn_ind);
    mStockTransfer.vicam_ind(oldObj.mudtProps.vicam_ind);
    mStockTransfer.vicam_ppb(oldObj.mudtProps.vicam_ppb);

    mStockTransfer.mPrevTransferShpLoc = oldObj.mPrevTransferShpLoc
    mStockTransfer.mPrevTransferRecLoc = oldObj.mPrevTransferRecLoc

    let tempvalue;
    if (mStockTransfer.mudtProps.frt_scac_id !== "" && mStockTransfer.mudtProps.frt_vendor !== "") {
      setValue(thisObj, "txtarCarrAbbr", capitalize(sVendorInfo));
      tempvalue = sVendorInfo;
    } else {
      setValue(thisObj, "txtarCarrAbbr", "");
      tempvalue = sVendorInfo;
    }

    if (mStockTransfer.mudtProps.frt_scac_id !== "" && (tempvalue == "" || mStockTransfer.mudtProps.frt_vendor == "")) {
      await txtCarrAbbrValidate(false);
    }

    setOldObj(null)

    let finalData = {
      "mTransfer": mStockTransfer,
      "TransferRole": mStockTransfer.transferRole,
      "FromFrtAccrl": false,
      "ReCreate": true
    }
    setData(thisObj, 'StockTransfer', finalData);
  }

  const FormLoad = async (mStockTransferObj) => {
    mStockTransferObj.mbFormFilling = true;
    mStockTransferObj.mbStartTransfer = true;
    setPeanutPrice(new clsPeanutPrice());
    if (mORules != undefined) {
      setMORules(null);
    }
    setMORules(new clsBrokenRules());
    await FillTransferData();
    await SetupForm();
    mStockTransferObj.BeginEdit();
    mStockTransferObj.mbFormFilling = false;
    mStockTransferObj.mbStartTransfer = false;
    setStockTransfer(mStockTransferObj)
    if (oldObj != null) {
      setEventTrigger(true)
      setRecreateTrigger(true)
    }
    if (oldObj == null && recreateTrigger) {
      enable(thisObj, "btnSave");
      mStockTransfer.mbChgShpLoc = true;
      mStockTransfer.mPrevTransferShpLoc = "";
      mStockTransfer.mPrevTransferRecLoc = "";
      mStockTransfer.mOldPrevTransferType = "";
      mStockTransfer.mPrevState = "";
      mStockTransfer.mPrevCounty = "";
      mStockTransfer.mPrevFarm = "";
      mStockTransfer.mPrevFarmSuffix = "";
      setRecreateTrigger(false)
      setEventTrigger(true)
    }
    setLoading(false);
    let ExternalFrtAccrlRevTrigger = getData(thisObj, "StockTransfer_ExternalFrtAccrlRev")
    if (ExternalFrtAccrlRevTrigger == true) {
      await ExternalFrtAccrlRev()
      setEventTrigger(true)
      setData(thisObj, "StockTransfer_ExternalFrtAccrlRev", false)
    }
  };

  const FillTransferData = async () => {
    try {
      setValue(thisObj, "chkbxMnlOverRide", false);
      [null, undefined, ""].includes(mStockTransfer.mudtProps.transfer_num)
        ? setValue(thisObj, "lblNew", "*NEW*")
        : setValue(thisObj, "lblNew", mStockTransfer.mudtProps.transfer_num);
      if (mStockTransfer.mOldPrevTransferType == "FF") {
        mStockTransfer.transfer_type("FF");
      }
      await FillTransferType(await mStockTransfer.GetTransferTypeIndex());
      await FillPayFreightType();
      await FillInventoryUpdateWeightType(
        await mStockTransfer.GetInventoryIndicatorIndex()
      );

      if (mStockTransfer.mPrevTransferShpLoc != "") {
        await FillBuyingPoint(mStockTransfer.mPrevTransferShpLoc);
        mStockTransfer.mPrevTransferShpLoc = "";
      } else {
        await FillBuyingPoint(mStockTransfer.mudtProps.shp_buy_pt);
      }

      if (mStockTransfer.mPrevTransferRecLoc != "") {
        await FillDestinationPoint(mStockTransfer.mPrevTransferRecLoc);
        mStockTransfer.mPrevTransferRecLoc = "";
      } else {
        await FillDestinationPoint(mStockTransfer.mudtProps.rec_buy_pt);
      }

      // SetupBinGrid //Not required
      await FillBinData();
      await enableDisableForBinControls()

      if (
        mStockTransfer.mOldPrevTransferType == "FF" &&
        mStockTransfer.mPrevState != ""
      ) {
        await mStockTransfer.state_abbr(mStockTransfer.mPrevState);
      }
      await FillStates();

      if (
        mStockTransfer.mudtProps.transfer_type == "FF" &&
        mStockTransfer.mPrevCounty != ""
      ) {
        mStockTransfer.county_id(mStockTransfer.mPrevCounty);
      }
      await FillCounties(false);

      if (
        mStockTransfer.mudtProps.transfer_type == "FF" &&
        mStockTransfer.mPrevFarm != ""
      ) {
        mStockTransfer.farm_id(mStockTransfer.mPrevFarm);
      }

      if (
        mStockTransfer.mudtProps.transfer_type == "FF" &&
        mStockTransfer.mPrevFarmSuffix != ""
      ) {
        mStockTransfer.farm_suffix(mStockTransfer.mPrevFarmSuffix);
      }
      await FillFarms();

      await FillFreightAmount();
      await SetupGradeEntryMode();

      let objVal = {};
      objVal["lblPending"] = await mStockTransfer.StatusName();
      objVal["txtDispatch"] = mStockTransfer.mudtProps.dispatch_num;
      objVal["txtVehicle"] = mStockTransfer.mudtProps.vehichle_num;
      objVal["txt1007"] = mStockTransfer.mudtProps.settled_1007_num;
      objVal["txtTruckLine"] = mStockTransfer.mudtProps.truck_line;
      objVal["txxtDriver"] = mStockTransfer.mudtProps.driver_name;
      objVal["lblLeasedTrailer"] = await mStockTransfer.LeasedTrailerInfo();

      //Shipped
      objVal["dtShipDate"] = moment(mStockTransfer.mudtProps.shp_date).format(
        "MM/DD/YYYY"
      );
      objVal["txtSclTcktShip"] = mStockTransfer.mudtProps.shp_scale_tick;
      objVal["chkbxSplitWtShip"] =
        await mStockTransfer.GetShippedSplitWeightFlag();
      objVal["txtWtVehicleShip"] = mStockTransfer.mudtProps.shp_tare_wt != "" && mStockTransfer.mudtProps.shp_tare_wt != "0" ? FormatNumber(mStockTransfer.mudtProps.shp_tare_wt, 0) : "";
      objVal["txtWtVehclShip"] = mStockTransfer.mudtProps.shp_vehicle_wt != "" && mStockTransfer.mudtProps.shp_vehicle_wt != "0" ? FormatNumber(mStockTransfer.mudtProps.shp_vehicle_wt, 0) : "";
      objVal["txtPeanutsShip"] = mStockTransfer.mudtProps.shp_peanut_wt != "" ? FormatNumber(mStockTransfer.mudtProps.shp_peanut_wt, 0) : "";

      //Received
      objVal["dtRcvdDate"] = moment(mStockTransfer.mudtProps.rec_date).format(
        "MM/DD/YYYY"
      );
      objVal["txtSclTcktRcvd"] = mStockTransfer.mudtProps.rec_scale_tick;
      objVal["chkbxSplitWtRcvd"] = await mStockTransfer.GetReceivedSplitWeightFlag();
      objVal["txtWtInclVehclRecvd"] = mStockTransfer.mudtProps.rec_tare_wt != "" && mStockTransfer.mudtProps.rec_tare_wt != "0" ? FormatNumber(mStockTransfer.mudtProps.rec_tare_wt, 0) : "";
      objVal["txtWtVhclRcvd"] = mStockTransfer.mudtProps.rec_vehicle_wt != "" && mStockTransfer.mudtProps.rec_vehicle_wt != "0" ? FormatNumber(mStockTransfer.mudtProps.rec_vehicle_wt, 0) : "";
      objVal["txtPeanutsRecvd"] = mStockTransfer.mudtProps.rec_peanut_wt != "" ? FormatNumber(mStockTransfer.mudtProps.rec_peanut_wt, 0) : "";

      //Weight Variance
      await ShowWeightVariance();

      //Freight Accrual 1
      objVal["txtInvoice"] = mStockTransfer.mudtProps.freight_invoice;
      objVal["txtCarrAbbr"] = mStockTransfer.mudtProps.frt_scac_id;
      objVal["txtVndrId"] = mStockTransfer.mudtProps.frt_vendor;

      objVal["txtarCarrAbbr"] = capitalize(mStockTransfer.mudtProps.vendor_info);
      objVal["txtOtherWt"] = mStockTransfer.mudtProps.frt_other_wt != "" && mStockTransfer.mudtProps.frt_other_wt != "0" ? FormatNumber(mStockTransfer.mudtProps.frt_other_wt, 0) : "";
      objVal["txtMileageOvrrd"] = mStockTransfer.mudtProps.frt_mileage_override == "0" ? "" : mStockTransfer.mudtProps.frt_mileage_override;

      //Update Inventory
      objVal["txtUpdtInvent"] = FormatNumber(mStockTransfer.mudtProps.other_wt, 0);
      mStockTransfer.mudtProps.inventory_ind == "O"
        ? show(thisObj, "txtUpdtInvent")
        : hide(thisObj, "txtUpdtInvent", false);

      //Remark
      objVal["txtarRemarks"] = mStockTransfer.mudtProps.remark;

      //Freight Accrual 2
      objVal["txtFreightRate"] = "$" + FormatNumber(mStockTransfer.mudtProps.freight_rate, 2);
      objVal["txtFrghtAm"] = "$" + FormatNumber(mStockTransfer.mudtProps.freight_amt, 2);
      objVal["txtLegMsg"] = mStockTransfer.mudtProps.leg_mileage != "" && mStockTransfer.mudtProps.leg_mileage != "0" ? FormatNumber(mStockTransfer.mudtProps.leg_mileage, 0) : "";
      let tempMileage = await mStockTransfer.FreightMiles();
      objVal["txtMileage"] = tempMileage != "" && tempMileage != "0" ? FormatNumber(tempMileage, 0) : "";
      objVal["txtWt"] = mStockTransfer.mudtProps.trip_weight != "" && mStockTransfer.mudtProps.trip_weight != "0" ? FormatNumber(mStockTransfer.mudtProps.trip_weight, 0) : "";
      objVal["txtPremAmnt"] = "$" + FormatNumber(mStockTransfer.mudtProps.frt_premium_amt, 2);
      objVal["txtDeductnAmt"] = Number(mStockTransfer.mudtProps.frt_deduct_amt) < 0 ? "($" + FormatNumber(Math.abs(mStockTransfer.mudtProps.frt_deduct_amt), 2) + ")" : "$" + FormatNumber(mStockTransfer.mudtProps.frt_deduct_amt, 2);
      objVal["txtProceedAmt"] = "$" + FormatNumber(mStockTransfer.mudtProps.frt_proceeds_amt, 2);

      //MultiStop
      objVal["txtTripId"] = mStockTransfer.mudtProps.trip_id;
      objVal["txtLeg"] = mStockTransfer.mudtProps.leg_count;
      objVal["txtLegOrg"] = mStockTransfer.mudtProps.leg_orig_buy_pt;
      objVal["txtLegDest"] = mStockTransfer.mudtProps.leg_dest_buy_pt;

      //Audit Data
      objVal["txtAddedBy"] = mStockTransfer.mudtProps.add_user;
      objVal["txtChngdBy"] = mStockTransfer.mudtProps.chg_user;
      objVal["lblAddedDate"] = moment(
        mStockTransfer.mudtProps.add_date_time
      ).format("MM/DD/YYYY");
      objVal["lblChngdDate"] = moment(
        mStockTransfer.mudtProps.chg_date_time
      ).format("MM/DD/YYYY");
      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true);

    } catch (error) {
      errorHandler(error)
    }

  };

  function capitalize(str) {
    if (str == undefined) {
      return ""
    }
    var splittedCarriage = str.split("\r\n");
    var capitalized;
    var capitalizedResult = [];
    for (var i = 0; i < splittedCarriage.length; i++) {
      var splittedEnter = splittedCarriage[i].split(" ");
      for (var j = 0; j < splittedEnter.length; j++) {
        capitalized = splittedEnter[j].charAt(0).toUpperCase();
        splittedEnter[j] =
          capitalized + splittedEnter[j].substr(1).toLowerCase();
      }
      capitalizedResult[i] = splittedEnter.join(" ");
    }
    return capitalizedResult.join("\r\n");
  }

  const ShowWeightVariance = async () => {
    try {
      let variance = await mStockTransfer.WeightVariance()
      setValue(thisObj, "txtWeightVariance", FormatNumber(variance, 0))
      let objVal = getData(thisObj, 'objVal')
      let receivedPnut = getValue(thisObj, "txtPeanutsRecvd") || (objVal == null ? '' : objVal['txtPeanutsRecvd'])
      if ((variance >= 500 || variance <= -500) && !(await mStockTransfer.GetShippedSplitWeightFlag() || await mStockTransfer.GetReceivedSplitWeightFlag())) {
        show(thisObj, "lblRecvdMsg")
        document.getElementsByName('txtWeightVariance')[1].style.color = 'red'
        if (![null, undefined, ""].includes(receivedPnut)) { document.getElementsByName('txtPeanutsRecvd')[0].style.color = 'red' }
      }
      else {
        hide(thisObj, "lblRecvdMsg")
        document.getElementsByName('txtWeightVariance')[1].style.color = 'black'
        if (![null, undefined, ""].includes(receivedPnut)) { document.getElementsByName('txtPeanutsRecvd')[0].style.color = 'black' }
      }
    } catch (error) {
      errorHandler(error)
    }

  };

  function FormatNumber(value, fraction = 2) {
    let formattedNo;
    if (value != null && value != undefined) {
      if (value.toString().indexOf(",") == -1) {
        formattedNo = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: fraction,
          maximumFractionDigits: fraction,
        }).format(value);
      } else {
        formattedNo = value.toString();
      }
    } else {
      formattedNo = "";
    }
    return formattedNo;
  }

  const FillStates = async () => {
    try {
      let tmpFormFilling = mStockTransfer.mbFormFilling;
      let selectedIndex = "",
        js = [];
      if (
        mStockTransfer.mudtProps.transfer_type == "FF" &&
        mStockTransfer.mudtProps.shp_buy_pt != ""
      ) {
        mStockTransfer.mbFormFilling = true;
        let response = await StockTransferService.RetrieveStateControlList(
          mStockTransfer.mudtProps.comp_id,
          mStockTransfer.mudtProps.crop_year,
          mStockTransfer.mudtProps.shp_buy_pt,
          "Y"
        );
        mStockTransfer.StateList = response;
        let abbr = mStockTransfer.mudtProps.state_abbr;
        for (let i = 0; i < response.length; i++) {
          js.push({
            key: response[i].state_id,
            description: response[i].state_name,
          });
          if (selectedIndex == "") {
            if (abbr == response[i].state_abbr) {
              selectedIndex = i;
            }
          }
        }
        mStockTransfer.mbFormFilling = tmpFormFilling;
      } else {
        mStockTransfer.StateList = {};
      }

      thisObj.setState((current) => {
        return {
          ...current,
          ddState: {
            ...state["ddState"],
            valueList: js,
          },
        };
      });
      if (selectedIndex != "") {
        setValue(thisObj, "ddState", js[selectedIndex].key);
      } else {
        setValue(thisObj, "ddState", "");
      }
    } catch (error) {
      errorHandler(error)
    }
  };

  const FillCounties = async (onExist) => {
    try {
      let tmpFormFilling = mStockTransfer.mbFormFilling;
      let tempCountyList;
      let selectedIndex = "",
        js = [];
      if (
        mStockTransfer.mudtProps.transfer_type == "FF" &&
        mStockTransfer.mudtProps.state_abbr != ""
      ) {
        mStockTransfer.mbFormFilling = true;
        if (onExist) {
          tempCountyList = mStockTransfer.CountyList;
        } else {
          let response = await StockTransferService.RetrieveCountyControlList(
            mStockTransfer.mudtProps.comp_id,
            mStockTransfer.mudtProps.crop_year,
            mStockTransfer.mudtProps.shp_buy_pt,
            "Y",
            mStockTransfer.mudtProps.state_abbr
          );
          mStockTransfer.CountyList = response;
          tempCountyList = response;
        }
        let id = mStockTransfer.mudtProps.county_id;
        for (let i = 0; i < tempCountyList.length; i++) {
          js.push({
            key: tempCountyList[i].county_id,
            description: tempCountyList[i].county_name,
          });
          if (selectedIndex == "") {
            if (id != "") {
              if (id == tempCountyList[i].county_id) {
                selectedIndex = i;
              }
            }
          }
        }
        mStockTransfer.mbFormFilling = tmpFormFilling;
      } else {
        mStockTransfer.CountyList = {};
      }

      thisObj.setState((current) => {
        return {
          ...current,
          ddCountry: {
            ...state["ddCountry"],
            valueList: js,
          },
        };
      });
      if (selectedIndex != "") {
        setValue(thisObj, "ddCountry", js[selectedIndex].key);
      } else {
        setValue(thisObj, "ddCountry", "");
      }

    } catch (error) {
      errorHandler(error)
    }
  };

  const FillFarms = async () => {
    try {
      let tmpFormFilling = mStockTransfer.mbFormFilling;
      let selectedIndex = "",
        IsHyphen = 0,
        js = [];
      if (
        mStockTransfer.mudtProps.transfer_type == "FF" &&
        mStockTransfer.mudtProps.county_id != ""
      ) {
        mStockTransfer.mbFormFilling = true;
        let response = await StockTransferService.RetrieveFarmControlList(
          mStockTransfer.mudtProps.comp_id,
          mStockTransfer.mudtProps.crop_year,
          mStockTransfer.mudtProps.shp_buy_pt,
          mStockTransfer.mudtProps.county_id,
          mStockTransfer.mudtProps.state_abbr
        );
        mStockTransfer.FarmList = response;
        IsHyphen = mStockTransfer.mudtProps.farm_id.indexOf("-");
        let id =
          IsHyphen == 8
            ? mStockTransfer.mudtProps.farm_id
            : mStockTransfer.mudtProps.farm_id +
            " - " +
            mStockTransfer.mudtProps.farm_suffix;
        for (let i = 0; i < response.length; i++) {
          let uniqueKey = response[i].farm_name.split("-")[0] + "-" + response[i]?.farm_name.split("-")[1].slice(0, response[i].farm_name.split("-")[1].length - 1)
          js.push({
            key: uniqueKey,
            description: response[i].farm_name,
          });
          if (selectedIndex == "") {
            if (id != "") {
              if (id == uniqueKey) {
                selectedIndex = i;
              }
            }
          }
        }
        mStockTransfer.mbFormFilling = tmpFormFilling;
      } else {
        mStockTransfer.FarmList = {};
      }

      thisObj.setState((current) => {
        return {
          ...current,
          ddFarm: {
            ...state["ddFarm"],
            valueList: js,
          },
        };
      });
      if (selectedIndex != "") {
        setValue(thisObj, "ddFarm", js[selectedIndex].key);
      } else {
        setValue(thisObj, "ddFarm", "");
      }

    } catch (error) {
      errorHandler(error)
    }
  };

  const SetupGradeEntryMode = async () => {
    try {
      let mGradesAvailable = false,
        mEnabled = false;
      // let status = getValue(thisObj, "lblPending");
      let status = await mStockTransfer.StatusName()
      mGradesAvailable = !(
        mStockTransfer.transferRole == UserTransferRoleList.indexOf("Shipper") ||
        status != "Received" ||
        mStockTransfer.mudtProps.shp_bin == ""
      );
      if (mGradesAvailable) {
        enable(thisObj, "chkbxManualGrd");
      }
      else {
        disable(thisObj, "chkbxManualGrd");
      }
      setValue(thisObj, "lblPct", await mStockTransfer.GramEntryName());

      for (let i = 0; i < Controls.length; i++) {
        mEnabled = false;
        // if (Controls[i].control == "txtTotalSMKRScreen") {
        //   alert("Name");
        // }
        let obj = Controls[i];
        if (
          obj.control == "" ||
          String("CheckBox,Label,TextBox")
            .toUpperCase()
            .indexOf(String(obj.controlType).toUpperCase()) == -1
        ) {
          continue;
        }
        if (!await EnableTagList("GI,GG,GP", obj.tag)) {
          continue; //No Grade controls allowed
        }
        if (mGradesAvailable) {
          if (mStockTransfer.mudtProps.manual_grade_ind == "N") {
            mEnabled = false;
            if (obj.control == "txtSMK1Gr") {
              mEnabled = true;
            }
            if (obj.control == "txtSMK1Pct") {
              mEnabled = true;
            }
            if (obj.control == "txtSMK2Gr") {
              mEnabled = true;
            }
            if (obj.control == "txtSMK2Pct") {
              mEnabled = true;
            }
            if (obj.control == "txtSMK3Gr") {
              mEnabled = true;
            }
            if (obj.control == "txtSMK3Pct") {
              mEnabled = true;
            }
            if (obj.control == "txtSMK4Gr") {
              mEnabled = true;
            }
            if (obj.control == "txtSMK4Pct") {
              mEnabled = true;
            }
            if (obj.control == "txtOKRidingGr") {
              mEnabled = true;
            }
            if (obj.control == "txtOKRidingPct") {
              mEnabled = true;
            }
            if (obj.control == "txtOKFallThru2") {
              mEnabled = true;
            }
            if (obj.control == "txtOKDamageRiding2") {
              mEnabled = true;
            }
            if (obj.control == "txtLSKRidingGr") {
              mEnabled = true;
            }
            if (obj.control == "txtLSKRidingPct") {
              mEnabled = true;
            }
            if (obj.control == "txtLSKFallThru2") {
              mEnabled = true;
            }
            if (obj.control == "txtLSKDamageRiding2") {
              mEnabled = true;
            }
            if (obj.control == "chkboxVicam") {
              mEnabled = true;
            }
            if (obj.control == "txtVicamReading") {
              mEnabled = true;
            }
            if (
              mStockTransfer.mudtProps.editmoist &&
              obj.control == "txtMoisture2"
            ) {
              mEnabled = true;
            }
          } else {
            if ((await mStockTransfer.GramEntryName()) == "GRAM") {
              mEnabled = await EnableTagList("GI,GG", obj.tag);
            } else {
              mEnabled = await EnableTagList("GI,GP", obj.tag);
            }
          }
        } else {
          mEnabled = false;
        }
        if (mEnabled == true) {
          if (!isEnabled(thisObj, obj.control)) {
            enable(thisObj, obj.control);
          }
        } else {
          if (isEnabled(thisObj, obj.control)) {
            disable(thisObj, obj.control);
          }
        }
      }
      await mStockTransfer.CalculatePeanutValueOld();
      await FillGradeInfo();
      await fillSettlementInfo();

    } catch (error) {
      errorHandler(error)
    }
  };

  const FillGradeInfo = async () => {
    try {
      let tmpFormFilling = mStockTransfer.mbFormFilling;
      mStockTransfer.mbFormFilling = true;

      let tempTotalSmkAndSplitPct = await mStockTransfer.TotalSmkAndSplitPct();
      let tempTotalKernelsPct = await mStockTransfer.TotalKernelsPct();
      let tempTotalKernelsAndHullsPct =
        await mStockTransfer.TotalKernelsAndHullsPct();

      let objVal = {};
      setValue(
        thisObj,
        "chkbxManualGrd",
        mStockTransfer.mudtProps.manual_grade_ind == "Y" ? true : false
      );
      setValue(thisObj, "lblGradeSource", "");
      if (
        mStockTransfer.mbStartTransfer ||
        mStockTransfer.mudtProps.manual_grade_ind != "Y"
      ) {
        //first column
        if (mStockTransfer.mudtProps.grade_source != "") {
          setValue(
            thisObj,
            "lblGradeSource",
            "Grade Source: " + mStockTransfer.mudtProps.grade_source
          );
          show(thisObj, "lblGradeSource");
          show(thisObj, "btnOverRide");
        } else {
          setValue(thisObj, "lblGradeSource", "");
          hide(thisObj, "lblGradeSource", false);
          hide(thisObj, "btnOverRide", false);
        }
        objVal["txtFmSample"] = FormatNumber(Number(mStockTransfer.mudtProps.fm_sample == "" ? 0 : mStockTransfer.mudtProps.fm_sample), GRADE_GRAM_FORMAT);
        objVal["txtClnSmplWt"] = FormatNumber(Number(mStockTransfer.mudtProps.cln_sample == "" ? 0 : mStockTransfer.mudtProps.cln_sample), GRADE_GRAM_FORMAT);
        objVal["txtTotalSMKRScreen"] = FormatNumber(Number(mStockTransfer.mudtProps.smk_gr == "" ? 0 : mStockTransfer.mudtProps.smk_gr), GRADE_GRAM_FORMAT);
        objVal["txtTotalSMKRScreenPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.smk_pct == "" ? 0 : mStockTransfer.mudtProps.smk_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtSMK1Gr"] = mStockTransfer.mudtProps.smk_1_guage == "" ? 0 : mStockTransfer.mudtProps.smk_1_guage;
        objVal["txtSMK1Pct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.smk_1_pct == "" ? 0 : mStockTransfer.mudtProps.smk_1_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtSMK2Gr"] = mStockTransfer.mudtProps.smk_2_guage == "" ? 0 : mStockTransfer.mudtProps.smk_2_guage;
        objVal["txtSMK2Pct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.smk_2_pct == "" ? 0 : mStockTransfer.mudtProps.smk_2_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtSMK3Gr"] = mStockTransfer.mudtProps.smk_3_guage == "" ? 0 : mStockTransfer.mudtProps.smk_3_guage;
        objVal["txtSMK3Pct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.smk_3_pct == "" ? 0 : mStockTransfer.mudtProps.smk_3_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtSMK4Gr"] = mStockTransfer.mudtProps.smk_4_guage == "" ? 0 : mStockTransfer.mudtProps.smk_4_guage;
        objVal["txtSMK4Pct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.smk_4_pct == "" ? 0 : mStockTransfer.mudtProps.smk_4_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtSoundSplits"] = FormatNumber(Number(mStockTransfer.mudtProps.ss_gr == "" ? 0 : mStockTransfer.mudtProps.ss_gr), GRADE_GRAM_FORMAT);
        objVal["txtSoundSplitsPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.ss_pct == "" ? 0 : mStockTransfer.mudtProps.ss_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtTotalSMK2"] = FormatNumber(Number(tempTotalSmkAndSplitPct == "" ? 0 : tempTotalSmkAndSplitPct), GRADE_PCT_FORMAT);
        objVal["txtTotalOK"] = FormatNumber(Number(mStockTransfer.mudtProps.ok_gr == "" ? 0 : mStockTransfer.mudtProps.ok_gr), GRADE_GRAM_FORMAT);
        objVal["txtTotalOKPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.ok_pct == "" ? 0 : mStockTransfer.mudtProps.ok_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtOKRidingGr"] = mStockTransfer.mudtProps.ok_guage == "" ? 0 : mStockTransfer.mudtProps.ok_guage;
        objVal["txtOKRidingPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.ok_ride_pct == "" ? 0 : mStockTransfer.mudtProps.ok_ride_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtOKFallThru2"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.ok_fall_pct == "" ? 0 : mStockTransfer.mudtProps.ok_fall_pct, 1), GRADE_PCT_FORMAT);
        objVal["txtOKDamageRiding2"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.ok_dam_pct == "" ? 0 : mStockTransfer.mudtProps.ok_dam_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtDamageSplits"] = FormatNumber(Number(mStockTransfer.mudtProps.dam_split_gr == "" ? 0 : mStockTransfer.mudtProps.dam_split_gr), GRADE_GRAM_FORMAT);
        objVal["txtTotalDamage"] = FormatNumber(Number(mStockTransfer.mudtProps.dam_gr == "" ? 0 : mStockTransfer.mudtProps.dam_gr), GRADE_GRAM_FORMAT);
        objVal["txtTotalDamagePct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.dam_pct == "" ? 0 : mStockTransfer.mudtProps.dam_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtHulls"] = FormatNumber(Number(mStockTransfer.mudtProps.hull_gr == "" ? 0 : mStockTransfer.mudtProps.hull_gr), GRADE_GRAM_FORMAT);
        objVal["txtHullsPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.hull_pct == "" ? 0 : mStockTransfer.mudtProps.hull_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtTotalKernels2"] = FormatNumber(Number(tempTotalKernelsPct == "" ? 0 : tempTotalKernelsPct), GRADE_PCT_FORMAT);
        objVal["txtTotalKernelsAndHulls2"] = FormatNumber(Number(tempTotalKernelsAndHullsPct == "" ? 0 : tempTotalKernelsAndHullsPct), GRADE_PCT_FORMAT);
        objVal["txtFreezeDamage"] = FormatNumber(Number(mStockTransfer.mudtProps.frez_dam_gr == "" ? 0 : mStockTransfer.mudtProps.frez_dam_gr), GRADE_GRAM_FORMAT);
        objVal["txtFreezeDamagePct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.frez_dam_pct == "" ? 0 : mStockTransfer.mudtProps.frez_dam_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtPittingDamage"] = FormatNumber(Number(mStockTransfer.mudtProps.pit_dam_gr == "" ? 0 : mStockTransfer.mudtProps.pit_dam_gr), GRADE_GRAM_FORMAT);
        objVal["txtPittingDamagePct"] = FormatNumber(Number(mStockTransfer.mudtProps.pit_dam_pct == "" ? 0 : mStockTransfer.mudtProps.pit_dam_pct), GRADE_PCT_FORMAT);
        objVal["txtConcealedRMD"] = FormatNumber(Number(mStockTransfer.mudtProps.cncl_rmd_gr == "" ? 0 : mStockTransfer.mudtProps.cncl_rmd_gr), GRADE_GRAM_FORMAT);
        objVal["txtConcealedRMDPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.cncl_rmd_pct == "" ? 0 : mStockTransfer.mudtProps.cncl_rmd_pct), 2), GRADE_PCT_FORMAT);

        //second column
        objVal["txtCrackedBroken"] = FormatNumber(Number(mStockTransfer.mudtProps.cr_br_gr == "" ? 0 : mStockTransfer.mudtProps.cr_br_gr), GRADE_GRAM_FORMAT);
        objVal["txtCrackedBrokenPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.cr_br_pct == "" ? 0 : mStockTransfer.mudtProps.cr_br_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtDiscoloredShells"] = FormatNumber(Number(mStockTransfer.mudtProps.dcol_shel_gr == "" ? 0 : mStockTransfer.mudtProps.dcol_shel_gr), GRADE_GRAM_FORMAT);
        objVal["txtDiscoloredShellsPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.dcol_shel_pct == "" ? 0 : mStockTransfer.mudtProps.dcol_shel_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtHullBrightness"] = FormatNumber(Number(mStockTransfer.mudtProps.hul_brit_gr == "" ? 0 : mStockTransfer.mudtProps.hul_brit_gr), GRADE_GRAM_FORMAT);
        objVal["txtHullBrightnessPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.hul_brit_pct == "" ? 0 : mStockTransfer.mudtProps.hul_brit_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtJumbo"] = FormatNumber(Number(mStockTransfer.mudtProps.jumbo_gr == "" ? 0 : mStockTransfer.mudtProps.jumbo_gr), GRADE_GRAM_FORMAT);
        objVal["txtJumboGrPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.jumbo_pct == "" ? 0 : mStockTransfer.mudtProps.jumbo_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtKernRidingELK"] = FormatNumber(Number(mStockTransfer.mudtProps.elk_ride_gr == "" ? 0 : mStockTransfer.mudtProps.elk_ride_gr), GRADE_GRAM_FORMAT);
        objVal["lblKernRidingELKPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.elk_ride_pct == "" ? 0 : mStockTransfer.mudtProps.elk_ride_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtTotalELKDamage"] = FormatNumber(Number(mStockTransfer.mudtProps.elk_dam_gr == "" ? 0 : mStockTransfer.mudtProps.elk_dam_gr), GRADE_GRAM_FORMAT);
        objVal["txtTotalELKDamagePct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.elk_dam_pct == "" ? 0 : mStockTransfer.mudtProps.elk_dam_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtNetELK"] = FormatNumber(Number(mStockTransfer.mudtProps.elk_gr == "" ? 0 : mStockTransfer.mudtProps.elk_gr), GRADE_GRAM_FORMAT);
        objVal["txtNetELKPct"] = FormatNumber(Number(mStockTransfer.mudtProps.elk_pct == "" ? 0 : mStockTransfer.mudtProps.elk_pct), GRADE_PCT_FORMAT);
        objVal["txtKernRidingPrescribe"] = FormatNumber(Number(mStockTransfer.mudtProps.ride_pscrib_gr == "" ? 0 : mStockTransfer.mudtProps.ride_pscrib_gr), GRADE_GRAM_FORMAT);
        objVal["txtKernRidingPrescribePct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.ride_pscrib_pct == "" ? 0 : mStockTransfer.mudtProps.ride_pscrib_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtForeignMtrl"] = FormatNumber(Number(mStockTransfer.mudtProps.fm_gr == "" ? 0 : mStockTransfer.mudtProps.fm_gr), GRADE_GRAM_FORMAT);
        objVal["txtForeignMaterialPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.fm_pct == "" ? 0 : mStockTransfer.mudtProps.fm_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtOtherFM"] = mStockTransfer.mudtProps.oth_fm == "" ? 0 : mStockTransfer.mudtProps.oth_fm;
        objVal["txtTotalSLK"] = FormatNumber(Number(mStockTransfer.mudtProps.lsk_gr == "" ? 0 : mStockTransfer.mudtProps.lsk_gr), GRADE_GRAM_FORMAT);
        objVal["txtTotalSLKPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.lsk_pct == "" ? 0 : mStockTransfer.mudtProps.lsk_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtLSKRidingGr"] = mStockTransfer.mudtProps.lsk_guage == "" ? 0 : mStockTransfer.mudtProps.lsk_guage;
        objVal["txtLSKRidingPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.lsk_ride_pct == "" ? 0 : mStockTransfer.mudtProps.lsk_ride_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtLSKFallThru2"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.lsk_fall_pct == "" ? 0 : mStockTransfer.mudtProps.lsk_fall_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtLSKDamageRiding2"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.lsk_dam_pct == "" ? 0 : mStockTransfer.mudtProps.lsk_dam_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtMoisture2"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.moist_pct == "" ? 0 : mStockTransfer.mudtProps.moist_pct), 1), GRADE_PCT_FORMAT);
        objVal["txtFancy"] = FormatNumber(Number(mStockTransfer.mudtProps.fan_gr == "" ? 0 : mStockTransfer.mudtProps.fan_gr), GRADE_GRAM_FORMAT);
        objVal["txtFancyPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.fan_pct == "" ? 0 : mStockTransfer.mudtProps.fan_pct), 1), GRADE_PCT_FORMAT);
        setValue(thisObj, "chkboxFlavus", mStockTransfer.mudtProps.flavus_ind == "Y" ? true : false);
        if (mStockTransfer.mudtProps.vicam_ind == "Y") {
          setValue(thisObj, "chkboxVicam", true);
          objVal["txtVicamReading"] = mStockTransfer.mudtProps.vicam_ppb == "" ? 0 : FormatNumber(mStockTransfer.mudtProps.vicam_ppb, 0);
        } else {
          setValue(thisObj, "chkboxVicam", false);
          objVal["txtVicamReading"] = "";
        }
      } else {
        //first column
        if (!(Number(mStockTransfer.mudtProps.fm_sample) == 0 && getValue(thisObj, "txtFmSample") == "")) {
          objVal["txtFmSample"] = FormatNumber(Number(mStockTransfer.mudtProps.fm_sample == "" ? 0 : mStockTransfer.mudtProps.fm_sample), GRADE_GRAM_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.cln_sample) == 0 && getValue(thisObj, "txtClnSmplWt") == "")) {
          objVal["txtClnSmplWt"] = FormatNumber(Number(mStockTransfer.mudtProps.cln_sample == "" ? 0 : mStockTransfer.mudtProps.cln_sample), GRADE_GRAM_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.smk_gr) == 0 && getValue(thisObj, "txtTotalSMKRScreen") == "")) {
          objVal["txtTotalSMKRScreen"] = FormatNumber(Number(mStockTransfer.mudtProps.smk_gr == "" ? 0 : mStockTransfer.mudtProps.smk_gr), GRADE_GRAM_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.smk_pct) == 0 && getValue(thisObj, "txtTotalSMKRScreenPct") == "")) {
          objVal["txtTotalSMKRScreenPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.smk_pct == "" ? 0 : mStockTransfer.mudtProps.smk_pct), 1), GRADE_PCT_FORMAT);
        }
        objVal["txtSMK1Gr"] = mStockTransfer.mudtProps.smk_1_guage == "" ? 0 : mStockTransfer.mudtProps.smk_1_guage;
        if (!(Number(mStockTransfer.mudtProps.smk_1_pct) == 0 && getValue(thisObj, "txtSMK1Pct") == "")) {
          objVal["txtSMK1Pct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.smk_1_pct == "" ? 0 : mStockTransfer.mudtProps.smk_1_pct), 1), GRADE_PCT_FORMAT);
        }
        objVal["txtSMK2Gr"] = mStockTransfer.mudtProps.smk_2_guage == "" ? 0 : mStockTransfer.mudtProps.smk_2_guage;
        if (!(Number(mStockTransfer.mudtProps.smk_2_pct) == 0 && getValue(thisObj, "txtSMK2Pct") == "")) {
          objVal["txtSMK2Pct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.smk_2_pct == "" ? 0 : mStockTransfer.mudtProps.smk_2_pct), 1), GRADE_PCT_FORMAT);
        }
        objVal["txtSMK3Gr"] = mStockTransfer.mudtProps.smk_3_guage == "" ? 0 : mStockTransfer.mudtProps.smk_3_guage;
        if (!(Number(mStockTransfer.mudtProps.smk_3_pct) == 0 && getValue(thisObj, "txtSMK3Pct") == "")) {
          objVal["txtSMK3Pct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.smk_3_pct == "" ? 0 : mStockTransfer.mudtProps.smk_3_pct), 1), GRADE_PCT_FORMAT);
        }
        objVal["txtSMK4Gr"] = mStockTransfer.mudtProps.smk_4_guage == "" ? 0 : mStockTransfer.mudtProps.smk_4_guage;
        if (!(Number(mStockTransfer.mudtProps.smk_4_pct) == 0 && getValue(thisObj, "txtSMK4Pct") == "")) {
          objVal["txtSMK4Pct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.smk_4_pct == "" ? 0 : mStockTransfer.mudtProps.smk_4_pct), 1), GRADE_PCT_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.ss_gr) == 0 && getValue(thisObj, "txtSoundSplits") == "")) {
          objVal["txtSoundSplits"] = FormatNumber(Number(mStockTransfer.mudtProps.ss_gr == "" ? 0 : mStockTransfer.mudtProps.ss_gr), GRADE_GRAM_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.ss_pct) == 0 && getValue(thisObj, "txtSoundSplitsPct") == "")) {
          objVal["txtSoundSplitsPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.ss_pct == "" ? 0 : mStockTransfer.mudtProps.ss_pct), 1), GRADE_PCT_FORMAT);
        }
        objVal["txtTotalSMK2"] = FormatNumber(Number(tempTotalSmkAndSplitPct == "" ? 0 : tempTotalSmkAndSplitPct), GRADE_PCT_FORMAT);
        if (!(Number(mStockTransfer.mudtProps.ok_gr) == 0 && getValue(thisObj, "txtTotalOK") == "")) {
          objVal["txtTotalOK"] = FormatNumber(Number(mStockTransfer.mudtProps.ok_gr == "" ? 0 : mStockTransfer.mudtProps.ok_gr), GRADE_GRAM_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.ok_pct) == 0 && getValue(thisObj, "txtTotalOKPct") == "")) {
          objVal["txtTotalOKPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.ok_pct == "" ? 0 : mStockTransfer.mudtProps.ok_pct), 1), GRADE_PCT_FORMAT);
        }
        objVal["txtOKRidingGr"] = mStockTransfer.mudtProps.ok_guage == "" ? 0 : mStockTransfer.mudtProps.ok_guage;
        if (!(Number(mStockTransfer.mudtProps.ok_ride_pct) == 0 && getValue(thisObj, "txtOKRidingPct") == "")) {
          objVal["txtOKRidingPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.ok_ride_pct == "" ? 0 : mStockTransfer.mudtProps.ok_ride_pct), 1), GRADE_PCT_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.ok_fall_pct) == 0 && getValue(thisObj, "txtOKFallThru2") == "")) {
          objVal["txtOKFallThru2"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.ok_fall_pct == "" ? 0 : mStockTransfer.mudtProps.ok_fall_pct), 1), GRADE_PCT_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.ok_dam_pct) == 0 && getValue(thisObj, "txtOKDamageRiding2") == "")) {
          objVal["txtOKDamageRiding2"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.ok_dam_pct == "" ? 0 : mStockTransfer.mudtProps.ok_dam_pct), 1), GRADE_PCT_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.dam_split_gr) == 0 && getValue(thisObj, "txtDamageSplits") == "")) {
          objVal["txtDamageSplits"] = FormatNumber(Number(mStockTransfer.mudtProps.dam_split_gr == "" ? 0 : mStockTransfer.mudtProps.dam_split_gr), GRADE_GRAM_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.dam_gr) == 0 && getValue(thisObj, "txtTotalDamage") == "")) {
          objVal["txtTotalDamage"] = FormatNumber(Number(mStockTransfer.mudtProps.dam_gr == "" ? 0 : mStockTransfer.mudtProps.dam_gr), GRADE_GRAM_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.dam_pct) == 0 && getValue(thisObj, "txtTotalDamagePct") == "")) {
          objVal["txtTotalDamagePct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.dam_pct == "" ? 0 : mStockTransfer.mudtProps.dam_pct), 1), GRADE_PCT_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.hull_gr) == 0 && getValue(thisObj, "txtHulls") == "")) {
          objVal["txtHulls"] = FormatNumber(Number(mStockTransfer.mudtProps.hull_gr == "" ? 0 : mStockTransfer.mudtProps.hull_gr), GRADE_GRAM_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.hull_pct) == 0 && getValue(thisObj, "txtHullsPct") == "")) {
          objVal["txtHullsPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.hull_pct == "" ? 0 : mStockTransfer.mudtProps.hull_pct), 1), GRADE_PCT_FORMAT);
        }
        objVal["txtTotalKernels2"] = FormatNumber(Number(tempTotalKernelsPct == "" ? 0 : tempTotalKernelsPct), GRADE_PCT_FORMAT);
        objVal["txtTotalKernelsAndHulls2"] = FormatNumber(Number(tempTotalKernelsAndHullsPct == "" ? 0 : tempTotalKernelsAndHullsPct), GRADE_PCT_FORMAT);
        if (!(Number(mStockTransfer.mudtProps.frez_dam_gr) == 0 && getValue(thisObj, "txtFreezeDamage") == "")) {
          objVal["txtFreezeDamage"] = FormatNumber(Number(mStockTransfer.mudtProps.frez_dam_gr == "" ? 0 : mStockTransfer.mudtProps.frez_dam_gr), GRADE_GRAM_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.frez_dam_pct) == 0 && getValue(thisObj, "txtFreezeDamagePct") == "")) {
          objVal["txtFreezeDamagePct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.frez_dam_pct == "" ? 0 : mStockTransfer.mudtProps.frez_dam_pct), 1), GRADE_PCT_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.pit_dam_gr) == 0 && getValue(thisObj, "txtPittingDamage") == "")) {
          objVal["txtPittingDamage"] = FormatNumber(Number(mStockTransfer.mudtProps.pit_dam_gr == "" ? 0 : mStockTransfer.mudtProps.pit_dam_gr), GRADE_GRAM_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.pit_dam_pct) == 0 && getValue(thisObj, "txtPittingDamagePct") == "")) {
          objVal["txtPittingDamagePct"] = FormatNumber(Number(mStockTransfer.mudtProps.pit_dam_pct == "" ? 0 : mStockTransfer.mudtProps.pit_dam_pct), GRADE_PCT_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.cncl_rmd_gr) == 0 && getValue(thisObj, "txtConcealedRMD") == "")) {
          objVal["txtConcealedRMD"] = FormatNumber(Number(mStockTransfer.mudtProps.cncl_rmd_gr == "" ? 0 : mStockTransfer.mudtProps.cncl_rmd_gr), GRADE_GRAM_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.cncl_rmd_pct) == 0 && getValue(thisObj, "txtConcealedRMDPct") == "")) {
          objVal["txtConcealedRMDPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.cncl_rmd_pct == "" ? 0 : mStockTransfer.mudtProps.cncl_rmd_pct), 2), GRADE_PCT_FORMAT);
        }

        //second column
        if (!(Number(mStockTransfer.mudtProps.cr_br_gr) == 0 && getValue(thisObj, "txtCrackedBroken") == "")) {
          objVal["txtCrackedBroken"] = FormatNumber(Number(mStockTransfer.mudtProps.cr_br_gr == "" ? 0 : mStockTransfer.mudtProps.cr_br_gr), GRADE_GRAM_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.cr_br_pct) == 0 && getValue(thisObj, "txtCrackedBrokenPct") == "")) {
          objVal["txtCrackedBrokenPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.cr_br_pct == "" ? 0 : mStockTransfer.mudtProps.cr_br_pct), 1), GRADE_PCT_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.dcol_shel_gr) == 0 && getValue(thisObj, "txtDiscoloredShells") == "")) {
          objVal["txtDiscoloredShells"] = FormatNumber(Number(mStockTransfer.mudtProps.dcol_shel_gr == "" ? 0 : mStockTransfer.mudtProps.dcol_shel_gr), GRADE_GRAM_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.dcol_shel_pct) == 0 && getValue(thisObj, "txtDiscoloredShellsPct") == "")) {
          objVal["txtDiscoloredShellsPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.dcol_shel_pct == "" ? 0 : mStockTransfer.mudtProps.dcol_shel_pct), 1), GRADE_PCT_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.hul_brit_gr) == 0 && getValue(thisObj, "txtHullBrightness") == "")) {
          objVal["txtHullBrightness"] = FormatNumber(Number(mStockTransfer.mudtProps.hul_brit_gr == "" ? 0 : mStockTransfer.mudtProps.hul_brit_gr), GRADE_GRAM_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.hul_brit_pct) == 0 && getValue(thisObj, "txtHullBrightnessPct") == "")) {
          objVal["txtHullBrightnessPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.hul_brit_pct == "" ? 0 : mStockTransfer.mudtProps.hul_brit_pct), 1), GRADE_PCT_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.jumbo_gr) == 0 && getValue(thisObj, "txtJumbo") == "")) {
          objVal["txtJumbo"] = FormatNumber(Number(mStockTransfer.mudtProps.jumbo_gr == "" ? 0 : mStockTransfer.mudtProps.jumbo_gr), GRADE_GRAM_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.jumbo_pct) == 0 && getValue(thisObj, "txtJumboGrPct") == "")) {
          objVal["txtJumboGrPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.jumbo_pct == "" ? 0 : mStockTransfer.mudtProps.jumbo_pct), 1), GRADE_PCT_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.elk_ride_gr) == 0 && getValue(thisObj, "txtKernRidingELK") == "")) {
          objVal["txtKernRidingELK"] = FormatNumber(Number(mStockTransfer.mudtProps.elk_ride_gr == "" ? 0 : mStockTransfer.mudtProps.elk_ride_gr), GRADE_GRAM_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.elk_ride_pct) == 0 && getValue(thisObj, "lblKernRidingELKPct") == "")) {
          objVal["lblKernRidingELKPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.elk_ride_pct == "" ? 0 : mStockTransfer.mudtProps.elk_ride_pct), 1), GRADE_PCT_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.elk_dam_gr) == 0 && getValue(thisObj, "txtTotalELKDamage") == "")) {
          objVal["txtTotalELKDamage"] = FormatNumber(Number(mStockTransfer.mudtProps.elk_dam_gr == "" ? 0 : mStockTransfer.mudtProps.elk_dam_gr), GRADE_GRAM_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.elk_dam_pct) == 0 && getValue(thisObj, "txtTotalELKDamagePct") == "")) {
          objVal["txtTotalELKDamagePct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.elk_dam_pct == "" ? 0 : mStockTransfer.mudtProps.elk_dam_pct), 1), GRADE_PCT_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.elk_gr) == 0 && getValue(thisObj, "txtNetELK") == "")) {
          objVal["txtNetELK"] = FormatNumber(Number(mStockTransfer.mudtProps.elk_gr == "" ? 0 : mStockTransfer.mudtProps.elk_gr), GRADE_GRAM_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.elk_pct) == 0 && getValue(thisObj, "txtNetELKPct") == "")) {
          objVal["txtNetELKPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.elk_pct == "" ? 0 : mStockTransfer.mudtProps.elk_pct), 1), GRADE_PCT_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.ride_pscrib_gr) == 0 && getValue(thisObj, "txtKernRidingPrescribe") == "")) {
          objVal["txtKernRidingPrescribe"] = FormatNumber(Number(mStockTransfer.mudtProps.ride_pscrib_gr == "" ? 0 : mStockTransfer.mudtProps.ride_pscrib_gr), GRADE_GRAM_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.ride_pscrib_pct) == 0 && getValue(thisObj, "txtKernRidingPrescribePct") == "")) {
          objVal["txtKernRidingPrescribePct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.ride_pscrib_pct == "" ? 0 : mStockTransfer.mudtProps.ride_pscrib_pct), 1), GRADE_PCT_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.fm_gr) == 0 && getValue(thisObj, "txtForeignMtrl") == "")) {
          objVal["txtForeignMtrl"] = FormatNumber(Number(mStockTransfer.mudtProps.fm_gr == "" ? 0 : mStockTransfer.mudtProps.fm_gr), GRADE_GRAM_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.fm_pct) == 0 && getValue(thisObj, "txtForeignMaterialPct") == "")) {
          objVal["txtForeignMaterialPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.fm_pct == "" ? 0 : mStockTransfer.mudtProps.fm_pct), 1), GRADE_PCT_FORMAT);
        }
        if (!(mStockTransfer.mudtProps.oth_fm == "" && getValue(thisObj, "txtOtherFM") == "")) {
          objVal["txtOtherFM"] = mStockTransfer.mudtProps.oth_fm == "" ? 0 : mStockTransfer.mudtProps.oth_fm;
        }
        if (!(Number(mStockTransfer.mudtProps.lsk_gr) == 0 && getValue(thisObj, "txtTotalSLK") == "")) {
          objVal["txtTotalSLK"] = FormatNumber(Number(mStockTransfer.mudtProps.lsk_gr == "" ? 0 : mStockTransfer.mudtProps.lsk_gr), GRADE_GRAM_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.lsk_pct) == 0 && getValue(thisObj, "txtTotalSLKPct") == "")) {
          objVal["txtTotalSLKPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.lsk_pct == "" ? 0 : mStockTransfer.mudtProps.lsk_pct), 1), GRADE_PCT_FORMAT);
        }
        objVal["txtLSKRidingGr"] = mStockTransfer.mudtProps.lsk_guage == "" ? 0 : mStockTransfer.mudtProps.lsk_guage;
        if (!(Number(mStockTransfer.mudtProps.lsk_ride_pct) == 0 && getValue(thisObj, "txtLSKRidingPct") == "")) {
          objVal["txtLSKRidingPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.lsk_ride_pct == "" ? 0 : mStockTransfer.mudtProps.lsk_ride_pct), 1), GRADE_PCT_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.lsk_fall_pct) == 0 && getValue(thisObj, "txtLSKFallThru2") == "")) {
          objVal["txtLSKFallThru2"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.lsk_fall_pct == "" ? 0 : mStockTransfer.mudtProps.lsk_fall_pct), 1), GRADE_PCT_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.lsk_dam_pct) == 0 && getValue(thisObj, "txtLSKDamageRiding2") == "")) {
          objVal["txtLSKDamageRiding2"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.lsk_dam_pct == "" ? 0 : mStockTransfer.mudtProps.lsk_dam_pct), 1), GRADE_PCT_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.moist_pct) == 0 && getValue(thisObj, "txtMoisture2") == "")) {
          objVal["txtMoisture2"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.moist_pct == "" ? 0 : mStockTransfer.mudtProps.moist_pct), 1), GRADE_PCT_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.fan_gr) == 0 && getValue(thisObj, "txtFancy") == "")) {
          objVal["txtFancy"] = FormatNumber(Number(mStockTransfer.mudtProps.fan_gr == "" ? 0 : mStockTransfer.mudtProps.fan_gr), GRADE_GRAM_FORMAT);
        }
        if (!(Number(mStockTransfer.mudtProps.fan_pct) == 0 && getValue(thisObj, "txtFancyPct") == "")) {
          objVal["txtFancyPct"] = FormatNumber(RoundOff(Number(mStockTransfer.mudtProps.fan_pct == "" ? 0 : mStockTransfer.mudtProps.fan_pct), 1), GRADE_PCT_FORMAT);
        }
        setValue(thisObj, "chkboxFlavus", mStockTransfer.mudtProps.flavus_ind == "Y" ? true : false);
        if (mStockTransfer.mudtProps.vicam_ind == "Y") {
          setValue(thisObj, "chkboxVicam", true);
          if (!(Number(mStockTransfer.mudtProps.vicam_ppb) == 0 && getValue(thisObj, "txtVicamReading") == "")) {
            objVal["txtVicamReading"] = mStockTransfer.mudtProps.vicam_ppb == "" ? 0 : FormatNumber(mStockTransfer.mudtProps.vicam_ppb, 0);
          }
        } else {
          setValue(thisObj, "chkboxVicam", false);
          objVal["txtVicamReading"] = "";
        }
      }
      setFieldValues(thisObj, objVal, true);
      mStockTransfer.mbFormFilling = tmpFormFilling;
    } catch (error) {
      errorHandler(error);
    }
  };

  const CheckFormRules = async () => {
    try {
      mORules.RuleBroken(
        GradingRule.GRADE_FM_SAMPLE_WEIGHT,
        mStockTransfer.mudtProps.manual_grade_ind == "Y" &&
        !(await mStockTransfer.FreightAccrualCompleted()) &&
        mStockTransfer.mudtProps.transfer_type == "ST" &&
        (mStockTransfer.mudtProps.xfer_status == "R" ||
          mStockTransfer.mNewStatus == TransferStatus.indexOf("Received")) &&
        !isNumeric(ExcelNumericDataConvert(getValue(thisObj, "txtFmSample"), [','])),
        false
      );
      mORules.RuleBroken(
        GradingRule.GRADE_CLEAN_SAMPLE_WEIGHT,
        mStockTransfer.mudtProps.manual_grade_ind == "Y" &&
        !(await mStockTransfer.FreightAccrualCompleted()) &&
        mStockTransfer.mudtProps.transfer_type == "ST" &&
        (mStockTransfer.mudtProps.xfer_status == "R" ||
          mStockTransfer.mNewStatus == TransferStatus.indexOf("Received")) &&
        !isNumeric(ExcelNumericDataConvert(getValue(thisObj, "txtFmSample"), [','])),
        false
      );

    } catch (error) {
      errorHandler(error)
    }

  };

  const RoundOff = (value, decimal = 0) => {
    let calValue = value != "" ? Number(Number(value).toFixed(1)).toFixed(2) : value
    return calValue
  };

  const SetupForm = async () => {
    try {
      //return;
      (await mStockTransfer.CanOverrideTransferNumber()) == true
        ? show(thisObj, "chkbxMnlOverRide")
        : hide(thisObj, "chkbxMnlOverRide", false);
      let statusValue = await mStockTransfer.StatusName();
      setValue(thisObj, "lblPending", statusValue);
      setTimeout(() => {
        if (statusValue == 'Void') {
          document.getElementsByClassName('lblPending')[0].style.color = 'red'
        } else {
          document.getElementsByClassName('lblPending')[0].style.color = 'black'
        }
      }, 500);
      setValue(thisObj, "lblPct", await mStockTransfer.GramEntryName());
      await SetupGradeEntryMode();
      let accrualStatus = await mStockTransfer.FreightAccrualCompleted()
      if (accrualStatus) {
        show(thisObj, "lblCompleted")
      } else {
        hide(thisObj, "lblCompleted", false)
      }

      for (let i = 0; i < Controls.length; i++) {
        // if (Controls[i].control == "txtWtVehicleShip") {
        //   alert("Name");
        // }
        // if(Controls[i].tag == "SL"){
        //   alert("Tag")
        // }
        await SetupControl(
          Controls[i].control,
          Controls[i].controlType,
          Controls[i].tag,
          mStockTransfer.transferRole
        );
      }

      const newValue = await mStockTransfer.IsNew();
      if (newValue == true) {
        enable(thisObj, "ddShippingLoc");
        enable(thisObj, "ddType");
      } else {
        disable(thisObj, "ddShippingLoc");
        disable(thisObj, "ddType");
      }
      mStockTransfer.mudtProps.inventory_ind == "O"
        ? show(thisObj, "txtUpdtInvent")
        : hide(thisObj, "txtUpdtInvent");
      mStockTransfer.mudtProps.pay_freight_ind == "O"
        ? show(thisObj, "txtOtherWt")
        : hide(thisObj, "txtOtherWt");
      mStockTransfer.mudtProps.trip_id > 0
        ? show(thisObj, "grpbxMultiStop")
        : hide(thisObj, "grpbxMultiStop");
      mStockTransfer.mudtProps.xfer_status != "V"
        ? enable(thisObj, "grpbxShippdFrmTo")
        : disable(thisObj, "grpbxShippdFrmTo");
      mStockTransfer.mudtProps.transfer_type != "NL"
        ? enable(thisObj, "grpbxSettlement")
        : disable(thisObj, "grpbxSettlement");
      mStockTransfer.mudtProps.pay_freight_ind != "N"
        ? enable(thisObj, "grpbxFreightAcc")
        : disable(thisObj, "grpbxFreightAcc");
      await ShowWeightVariance();
      await setupButtons();
      await enableDisableForBinControls();

    } catch (error) {
      errorHandler(error)
    }
  };

  const FillTransferType = async (selectedValue) => {
    let js = [];
    js.push({ key: "0", description: "Stock Transfer" });
    js.push({ key: "1", description: "Farm Freight" });
    js.push({ key: "2", description: "No Load" });
    thisObj.setState((current) => {
      return {
        ...current,
        ddType: {
          ...state["ddType"],
          valueList: js,
        },
      };
    });
    setValue(thisObj, "ddType", selectedValue);
  };

  const FillPayFreightType = async () => {
    try {
      let tmpFormFilling = mStockTransfer.mbFormFilling;
      mStockTransfer.mbFormFilling = true;
      let transferType = mStockTransfer.mudtProps.transfer_type;
      let js = [];
      let value = "";
      switch (transferType) {
        case "ST":
          switch (mStockTransfer.mudtProps.pay_freight_ind) {
            case "S":
              value = String(PayFreightType.indexOf("ShippedWeight"));
              break;
            case "R":
              value = String(PayFreightType.indexOf("ReceivedWeight"));
              break;
            case "O":
              value = String(PayFreightType.indexOf("OtherWeight"));
              break;
            case "N":
              value = String(PayFreightType.indexOf("NoFreight"));
              break;
          }
          js.push(
            {
              key: PayFreightType.indexOf("ShippedWeight"),
              description: "Shipped Weight",
            },
            {
              key: PayFreightType.indexOf("ReceivedWeight"),
              description: "Received Weight",
            },
            {
              key: PayFreightType.indexOf("OtherWeight"),
              description: "Other Weight",
            },
            {
              key: PayFreightType.indexOf("NoFreight"),
              description: "No Freight",
            }
          );
          break;

        case "FF":
          if (
            mStockTransfer.mudtProps.pay_freight_ind == "S" ||
            mStockTransfer.mudtProps.pay_freight_ind == "N"
          ) {
            mStockTransfer.pay_freight_ind("R");
          }

          switch (mStockTransfer.mudtProps.pay_freight_ind) {
            case "R":
              value = String(PayFreightType.indexOf("ReceivedWeight"));
              break;
            case "O":
              value = String(PayFreightType.indexOf("OtherWeight"));
              break;
          }

          js.push(
            {
              key: PayFreightType.indexOf("ReceivedWeight"),
              description: "Received Weight",
            },
            {
              key: PayFreightType.indexOf("OtherWeight"),
              description: "Other Weight",
            }
          );
          break;

        case "NL":
          mStockTransfer.pay_freight_ind("O");
          if (mStockTransfer.mudtProps.pay_freight_ind == "O") {
            value = String(PayFreightType.indexOf("OtherWeight"));
          }
          js.push({
            key: PayFreightType.indexOf("OtherWeight"),
            description: "Other Weight",
          });
          if (tmpFormFilling) {
            mStockTransfer.Dirty(false);
          }
          await FillFreightAmount();
          break;
      }
      mStockTransfer.mbFormFilling = tmpFormFilling;

      thisObj.setState((current) => {
        return {
          ...current,
          ddPayFreight: {
            ...state["ddPayFreight"],
            valueList: js,
          },
        };
      });
      setValue(thisObj, "ddPayFreight", value);
    } catch (error) {
      errorHandler(error)
    }
  };

  const FillFreightAmount = async () => {
    let objVal = {};
    objVal["txtFreightRate"] = "$" + FormatNumber(mStockTransfer.mudtProps.freight_rate, 2);
    objVal["txtFrghtAm"] = "$" + FormatNumber(mStockTransfer.mudtProps.freight_amt, 2);
    let tempMileage = await mStockTransfer.FreightMiles();
    objVal["txtMileage"] = tempMileage != "" && tempMileage != "0" ? FormatNumber(tempMileage, 0) : "";
    objVal["txtWt"] = mStockTransfer.mudtProps.trip_weight != "" && mStockTransfer.mudtProps.trip_weight != "0" ? FormatNumber(mStockTransfer.mudtProps.trip_weight, 0) : "";
    objVal["txtPremAmnt"] = "$" + FormatNumber(mStockTransfer.mudtProps.frt_premium_amt, 2);
    objVal["txtDeductnAmt"] = Number(mStockTransfer.mudtProps.frt_deduct_amt) < 0 ? "($" + FormatNumber(Math.abs(mStockTransfer.mudtProps.frt_deduct_amt), 2) + ")" : "$" + FormatNumber(mStockTransfer.mudtProps.frt_deduct_amt, 2);
    objVal["txtProceedAmt"] = "$" + FormatNumber(mStockTransfer.mudtProps.frt_proceeds_amt, 2);
    //Multistop
    objVal["txtTripId"] = mStockTransfer.mudtProps.trip_id;
    objVal["txtLeg"] = mStockTransfer.mudtProps.leg_count;
    objVal["txtLegOrg"] = mStockTransfer.mudtProps.leg_orig_buy_pt;
    objVal["txtLegDest"] = mStockTransfer.mudtProps.leg_dest_buy_pt;
    setFieldValues(thisObj, objVal, true);
  };

  const FillInventoryUpdateWeightType = async (selectedValue) => {
    let js = [];
    js.push({ key: "0", description: "Shipped Weight" });
    js.push({ key: "1", description: "Received Weight" });
    js.push({ key: "2", description: "Other Weight" });
    thisObj.setState((current) => {
      return {
        ...current,
        ddUpdtInvent: {
          ...state["ddUpdtInvent"],
          valueList: js,
        },
      };
    });
    setValue(thisObj, "ddUpdtInvent", selectedValue);
  };

  const FillBuyingPoint = async (selectedValue) => {
    try {
      let js = [];
      // js.push({key: "", description: ""})
      let selectedIndex, address, phone;
      mStockTransfer.ShippingLocationList = {};
      let data =
        await ContractManagementService.RetrieveBuyingPointControlDetails(
          "PN1080",
          null,
          null,
          null,
          null
        );
      mStockTransfer.ShippingLocationList = data;
      for (var i = 0; i < data.length; i++) {
        let obj = {
          key: data[i].buy_pt_id,
          description:
            data[i].buy_pt_id.trim() + " - " + data[i].buy_pt_name.trim(),
        };
        if (data[i].buy_pt_id == selectedValue) {
          selectedIndex = i;
          address = data[i].physical_city + ", " + data[i].physical_state;
          phone = data[i].buy_pt_phone;
          mStockTransfer.mbAutoNumber =
            data[i].xfer_auto_num_ind == "Y" ? true : false;
        }
        js.push(obj);
      }

      if (
        mStockTransfer.ShippingLocationList.length == 1 &&
        !Number(selectedIndex) >= 0
      ) {
        selectedIndex = 0;
        address = capitalize(data[i].physical_city) + ", " + data[i].physical_state;
        phone = data[i].buy_pt_phone;
      }

      if (Number(selectedIndex) >= 0) {
        await mStockTransfer.SetShippingPointInfo(
          mStockTransfer.ShippingLocationList[selectedIndex]
        );
      }

      thisObj.setState((current) => {
        return {
          ...current,
          ddShippingLoc: {
            ...state["ddShippingLoc"],
            valueList: js,
          },
        };
      });

      if (Number(selectedIndex) != NaN && selectedIndex != undefined) {
        setValue(
          thisObj,
          "ddShippingLoc",
          mStockTransfer.ShippingLocationList[selectedIndex].buy_pt_id
        );
        setValue(thisObj, "txtAddShip", address);
        setValue(thisObj, "txtPhnShip", phone);
      }
    } catch (error) {
      errorHandler(error)
    }
  };

  const FillDestinationPoint = async (selectedValue) => {
    try {
      let js = [];
      // js.push({key: "", description: ""})
      let selectedIndex, address, phone;

      if (mStockTransfer.mudtProps.shp_buy_pt == "") {
        setValue(thisObj, "ddDestinationLoc", "");
        setValue(thisObj, "txtAddShip", "");
        setValue(thisObj, "txtPhnShip", "");
        setValue(thisObj, "txtAddDest", "");
        setValue(thisObj, "txtPhnDest", "");
        return;
      }
      mStockTransfer.DestinationLocationList = {};
      let data = await SettlementService.RetrieveTransferBuyingPointDetails(
        mStockTransfer.mudtProps.shp_buy_pt,
        null
      );
      mStockTransfer.DestinationLocationList = data;
      for (var i = 0; i < data.length; i++) {
        let obj = {
          key: data[i].buy_pt_id,
          description:
            data[i].buy_pt_id.trim() + " - " + data[i].xfer_buy_pt_name.trim(),
        };
        if (data[i].buy_pt_id == selectedValue) {
          selectedIndex = i;
          address =
            capitalize(data[i].xfer_buy_pt_physical_city) +
            ", " +
            data[i].xfer_buy_pt_physical_state;
          phone = data[i].xfer_buy_pt_phone;
          await mStockTransfer.SetReceivingPointInfo(data[i]);
          setEventTrigger(true)
          setValue(thisObj, "lblPct", await mStockTransfer.GramEntryName());
          await SetupGradeEntryMode();
        }
        js.push(obj);
      }
      if (
        mStockTransfer.DestinationLocationList.length == 1 &&
        !Number(selectedIndex) >= 0
      ) {
        selectedIndex = 0;
        if (mStockTransfer.mudtProps.rec_buy_pt == "") {
          await mStockTransfer.rec_buy_pt(
            mStockTransfer.DestinationLocationList[0].buy_pt_id
          );
        }
      }

      thisObj.setState((current) => {
        return {
          ...current,
          ddDestinationLoc: {
            ...state["ddDestinationLoc"],
            valueList: js,
          },
        };
      });

      if (Number(selectedIndex) != NaN && selectedIndex != undefined) {
        setValue(
          thisObj,
          "ddDestinationLoc",
          mStockTransfer.DestinationLocationList[selectedIndex].buy_pt_id
        );
        setValue(thisObj, "txtAddDest", address);
        setValue(thisObj, "txtPhnDest", phone);
      }

    } catch (error) {
      errorHandler(error)
    }
  };

  const ClearBinInfo = async (IsShippingBin = false) => {
    try {
      let bin = new clsBin()
      let controlList = [
        "ddVarietyShppdTo",
        "ddGenerationShippdTo",
        "ddOleicShippdTo",
      ];

      if (IsShippingBin) {
        controlList = [
          "ddVarietyShppdFrm",
          "ddVarietyShppdTo",
          "ddGenerationShippdFrm",
          "ddGenerationShippdTo",
          "ddOleicShippdFrm",
          "ddOleicShippdTo",
        ];
        setValue(thisObj, "txtOrganicShippdFrm", "");
        setValue(thisObj, "txtEdiblOilShippdFrm", "");
        setValue(thisObj, "txtWhseBinShippdFrm", "");
        await mStockTransfer.SetShippingBinInfo(bin, mPeanutType)
        setEventTrigger(true)
        await ClearPeanutInfo();
        await FillBinData();
      }

      for (let i = 0; i < controlList.length; i++) {
        setValue(thisObj, controlList[i], "")
      }
      await mStockTransfer.SetReceivingBinInfo(bin)
      setEventTrigger(true)
      setValue(thisObj, "txtOrganicShippdTo", "");
      setValue(thisObj, "txtEdiblOilShippdTo", "");
      setValue(thisObj, "txtWhseBinShippdTo", "");
      await enableDisableForBinControls()
    } catch (error) {
      errorHandler(error);
    }
  };

  const ClearPeanutInfo = async () => {
    try {
      setValue(thisObj, "txtPnutShippdFrm", "")
      setValue(thisObj, "txtSegShppdFrm", "");

      mStockTransfer.pnut_type_id("");
      mStockTransfer.seg_type("");
      mStockTransfer.mSegment = "";
      mStockTransfer.mPeanutTypeID = "";
    } catch (error) {
      errorHandler(error);
    }
  };

  const UpdateGradeInfo = async () => {
    try {
      let objVal = {};
      if (mStockTransfer.mudtProps.manual_grade_ind !== "Y") {
        switch (mStockTransfer.mudtProps.manualentrymode) {
          case ManualEntryMode.indexOf("ManualGrams"): //ManualGrams
            //'First Column
            objVal["txtTotalSMKRScreenPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.smk_pct, 1), GRADE_PCT_FORMAT);
            objVal["txtSoundSplitsPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.ss_pct, 1), GRADE_PCT_FORMAT);
            objVal["txtTotalOKPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.ok_pct, 1), GRADE_PCT_FORMAT);
            objVal["txtTotalDamagePct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.dam_pct, 1), GRADE_PCT_FORMAT);
            objVal["txtHullsPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.hull_pct, 1), GRADE_PCT_FORMAT);
            objVal["txtFreezeDamagePct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.frez_dam_pct, 1), GRADE_PCT_FORMAT);
            objVal["txtPittingDamagePct"] = FormatNumber(mStockTransfer.mudtProps.pit_dam_gr, GRADE_PCT_FORMAT);
            objVal["txtConcealedRMDPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.cncl_rmd_pct, 2), GRADE_PCT_FORMAT);
            //'Second Column
            objVal["txtCrackedBrokenPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.cr_br_pct, 1), GRADE_PCT_FORMAT);
            objVal["txtDiscoloredShellsPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.dcol_shel_pct, 1), GRADE_PCT_FORMAT);
            objVal["txtHullBrightnessPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.hul_brit_pct, 1), GRADE_PCT_FORMAT);
            objVal["txtJumboGrPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.jumbo_pct, 1), GRADE_PCT_FORMAT);
            objVal["lblKernRidingELKPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.elk_ride_pct, 1), GRADE_PCT_FORMAT);
            objVal["txtTotalELKDamagePct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.elk_dam_pct, 1), GRADE_PCT_FORMAT);
            objVal["txtNetELKPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.elk_pct, 1), GRADE_PCT_FORMAT);
            objVal["txtKernRidingPrescribePct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.ride_pscrib_pct, 1), GRADE_PCT_FORMAT);
            objVal["txtForeignMaterialPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.fm_pct, 1), GRADE_PCT_FORMAT);
            objVal["txtTotalSLKPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.lsk_pct, 1), GRADE_PCT_FORMAT);
            objVal["txtFancyPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.fan_pct, 1), GRADE_PCT_FORMAT);
            break;

          case ManualEntryMode.indexOf("ManualPct"): //ManualPct
            //'First column
            objVal["txtTotalSMKRScreen"] = FormatNumber(Number(mStockTransfer.mudtProps.smk_gr), GRADE_GRAM_FORMAT);
            objVal["txtSoundSplits"] = FormatNumber(Number(mStockTransfer.mudtProps.ss_gr), GRADE_GRAM_FORMAT);
            objVal["txtSoundSplitsPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.ss_pct, 1), GRADE_PCT_FORMAT);
            objVal["txtTotalOK"] = FormatNumber(Number(mStockTransfer.mudtProps.ok_gr), GRADE_GRAM_FORMAT);
            objVal["txtOKRidingPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.ok_ride_pct, 1), GRADE_PCT_FORMAT);
            objVal["txtTotalDamage"] = FormatNumber(Number(mStockTransfer.mudtProps.dam_gr), GRADE_GRAM_FORMAT);
            objVal["txtHulls"] = FormatNumber(Number(mStockTransfer.mudtProps.hull_gr), GRADE_GRAM_FORMAT);
            objVal["txtFreezeDamage"] = FormatNumber(Number(mStockTransfer.mudtProps.frez_dam_gr), GRADE_GRAM_FORMAT);
            objVal["txtPittingDamage"] = FormatNumber(Number(mStockTransfer.mudtProps.pit_dam_gr), GRADE_GRAM_FORMAT);
            objVal["txtConcealedRMD"] = FormatNumber(Number(mStockTransfer.mudtProps.cncl_rmd_gr), GRADE_GRAM_FORMAT);

            //'Second Column
            objVal["txtCrackedBroken"] = FormatNumber(Number(mStockTransfer.mudtProps.cr_br_gr), GRADE_GRAM_FORMAT);
            objVal["txtDiscoloredShells"] = FormatNumber(Number(mStockTransfer.mudtProps.dcol_shel_gr), GRADE_GRAM_FORMAT);
            objVal["txtHullBrightness"] = FormatNumber(Number(mStockTransfer.mudtProps.hul_brit_gr), GRADE_GRAM_FORMAT);
            objVal["txtJumbo"] = FormatNumber(Number(mStockTransfer.mudtProps.jumbo_gr), GRADE_GRAM_FORMAT);
            objVal["txtKernRidingELK"] = FormatNumber(Number(mStockTransfer.mudtProps.elk_ride_gr), GRADE_GRAM_FORMAT);
            objVal["txtTotalELKDamage"] = FormatNumber(Number(mStockTransfer.mudtProps.elk_dam_gr), GRADE_GRAM_FORMAT);
            objVal["txtNetELK"] = FormatNumber(Number(mStockTransfer.mudtProps.elk_gr), GRADE_GRAM_FORMAT);
            objVal["txtKernRidingPrescribe"] = FormatNumber(Number(mStockTransfer.mudtProps.ride_pscrib_gr), GRADE_GRAM_FORMAT);
            objVal["txtForeignMtrl"] = FormatNumber(Number(mStockTransfer.mudtProps.fm_gr), GRADE_GRAM_FORMAT);
            objVal["txtTotalSLK"] = FormatNumber(Number(mStockTransfer.mudtProps.lsk_gr), GRADE_GRAM_FORMAT);
            objVal["txtFancy"] = FormatNumber(Number(mStockTransfer.mudtProps.fan_gr), GRADE_GRAM_FORMAT);
            break;

          default:
            break;
        }
      } else {
        switch (mStockTransfer.mudtProps.manualentrymode) {
          case ManualEntryMode.indexOf("ManualGrams"): //ManualGrams

            //'First column
            if (!(mStockTransfer.mudtProps.smk_pct == 0 && getValue(thisObj, "txtTotalSMKRScreenPct") == "")
            ) {
              objVal["txtTotalSMKRScreenPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.smk_pct, 1), GRADE_PCT_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.ss_pct == 0 && getValue(thisObj, "txtSoundSplitsPct") == "")
            ) {
              objVal["txtSoundSplitsPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.ss_pct, 1), GRADE_PCT_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.ok_pct == 0 && getValue(thisObj, "txtTotalOKPct") == "")
            ) {
              objVal["txtTotalOKPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.ok_pct, 1), GRADE_PCT_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.dam_pct == 0 && getValue(thisObj, "txtTotalDamagePct") == "")
            ) {
              objVal["txtTotalDamagePct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.dam_pct, 1), GRADE_PCT_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.hull_pct == 0 && getValue(thisObj, "txtHullsPct") == "")
            ) {
              objVal["txtHullsPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.hull_pct, 1), GRADE_PCT_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.frez_dam_pct == 0 && getValue(thisObj, "txtFreezeDamagePct") == "")
            ) {
              objVal["txtFreezeDamagePct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.frez_dam_pct, 1), GRADE_PCT_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.pit_dam_gr == 0 && getValue(thisObj, "txtPittingDamagePct") == "")
            ) {
              objVal["txtPittingDamagePct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.pit_dam_gr, 1), GRADE_PCT_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.cncl_rmd_pct == 0 && getValue(thisObj, "txtConcealedRMDPct") == "")
            ) {
              objVal["txtConcealedRMDPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.cncl_rmd_pct, 1), GRADE_PCT_FORMAT);
            }
            //'Second Column

            if (!(mStockTransfer.mudtProps.cr_br_pct == 0 && getValue(thisObj, "txtCrackedBrokenPct") == "")
            ) {
              objVal["txtCrackedBrokenPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.cr_br_pct, 1), GRADE_PCT_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.dcol_shel_pct == 0 && getValue(thisObj, "txtDiscoloredShellsPct") == "")
            ) {
              objVal["txtDiscoloredShellsPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.dcol_shel_pct, 1), GRADE_PCT_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.hul_brit_pct == 0 && getValue(thisObj, "txtHullBrightnessPct") == "")
            ) {
              objVal["txtHullBrightnessPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.hul_brit_pct, 1), GRADE_PCT_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.jumbo_pct == 0 && getValue(thisObj, "txtJumboGrPct") == "")
            ) {
              objVal["txtJumboGrPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.jumbo_pct, 1), GRADE_PCT_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.elk_ride_pct == 0 && getValue(thisObj, "lblKernRidingELKPct") == "")
            ) {
              objVal["lblKernRidingELKPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.elk_ride_pct, 1), GRADE_PCT_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.elk_dam_pct == 0 && getValue(thisObj, "txtTotalELKDamagePct") == "")
            ) {
              objVal["txtTotalELKDamagePct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.elk_dam_pct, 1), GRADE_PCT_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.elk_pct == 0 && getValue(thisObj, "txtNetELKPct") == "")
            ) {
              objVal["txtNetELKPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.elk_pct, 1), GRADE_PCT_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.ride_pscrib_pct == 0 && getValue(thisObj, "txtKernRidingPrescribePct") == "")
            ) {
              objVal["txtKernRidingPrescribePct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.ride_pscrib_pct, 1), GRADE_PCT_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.fm_pct == 0 && getValue(thisObj, "txtForeignMaterialPct") == "")
            ) {
              objVal["txtForeignMaterialPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.fm_pct, 1), GRADE_PCT_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.lsk_pct == 0 && getValue(thisObj, "txtTotalSLKPct") == "")
            ) {
              objVal["txtTotalSLKPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.lsk_pct, 1), GRADE_PCT_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.fan_pct == 0 && getValue(thisObj, "txtFancyPct") == "")
            ) {
              objVal["txtFancyPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.fan_pct, 1), GRADE_PCT_FORMAT);
            }
            break;

          case ManualEntryMode.indexOf("ManualPct"): //ManualPct
            //'First column
            if (!(mStockTransfer.mudtProps.smk_gr == 0 && getValue(thisObj, "txtTotalSMKRScreen") == "")
            ) {
              objVal["txtTotalSMKRScreen"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.smk_gr, 1), GRADE_GRAM_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.ss_gr == 0 && getValue(thisObj, "txtSoundSplits") == "")
            ) {
              objVal["txtSoundSplits"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.ss_gr, 1), GRADE_GRAM_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.ss_pct == 0 && getValue(thisObj, "txtSoundSplitsPct") == "")
            ) {
              objVal["txtSoundSplitsPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.ss_pct, 1), GRADE_PCT_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.ok_gr == 0 && getValue(thisObj, "txtTotalOK") == "")
            ) {
              objVal["txtTotalOK"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.ok_gr, 1), GRADE_GRAM_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.ok_ride_pct == 0 && getValue(thisObj, "txtOKRidingPct") == "")
            ) {
              objVal["txtOKRidingPct"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.ok_ride_pct, 1), GRADE_PCT_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.dam_gr == 0 && getValue(thisObj, "txtTotalDamage") == "")
            ) {
              objVal["txtTotalDamage"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.dam_gr, 1), GRADE_GRAM_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.hull_gr == 0 && getValue(thisObj, "txtHulls") == "")
            ) {
              objVal["txtHulls"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.hull_gr, 1), GRADE_GRAM_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.frez_dam_gr == 0 && getValue(thisObj, "txtFreezeDamage") == "")
            ) {
              objVal["txtFreezeDamage"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.frez_dam_gr, 1), GRADE_GRAM_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.pit_dam_gr == 0 && getValue(thisObj, "txtPittingDamage") == "")
            ) {
              objVal["txtPittingDamage"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.pit_dam_gr, 1), GRADE_GRAM_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.cncl_rmd_gr == 0 && getValue(thisObj, "txtConcealedRMD") == "")
            ) {
              objVal["txtConcealedRMD"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.cncl_rmd_gr, 1), GRADE_GRAM_FORMAT);
            }

            //'Second Column
            if (!(mStockTransfer.mudtProps.cr_br_gr == 0 && getValue(thisObj, "txtCrackedBroken") == "")
            ) {
              objVal["txtCrackedBroken"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.cr_br_gr, 1), GRADE_GRAM_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.dcol_shel_gr == 0 && getValue(thisObj, "txtDiscoloredShells") == "")
            ) {
              objVal["txtDiscoloredShells"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.dcol_shel_gr, 1), GRADE_GRAM_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.hul_brit_gr == 0 && getValue(thisObj, "txtHullBrightness") == "")
            ) {
              objVal["txtHullBrightness"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.hul_brit_gr, 1), GRADE_GRAM_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.jumbo_gr == 0 && getValue(thisObj, "txtJumbo") == "")
            ) {
              objVal["txtJumbo"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.jumbo_gr, 1), GRADE_GRAM_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.elk_ride_gr == 0 && getValue(thisObj, "txtKernRidingELK") == "")
            ) {
              objVal["txtKernRidingELK"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.elk_ride_gr, 1), GRADE_GRAM_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.elk_dam_gr == 0 && getValue(thisObj, "txtTotalELKDamage") == "")
            ) {
              objVal["txtTotalELKDamage"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.elk_dam_gr, 1), GRADE_GRAM_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.elk_gr == 0 && getValue(thisObj, "txtNetELK") == "")
            ) {
              objVal["txtNetELK"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.elk_gr, 1), GRADE_GRAM_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.ride_pscrib_gr == 0 && getValue(thisObj, "txtKernRidingPrescribe") == "")
            ) {
              objVal["txtKernRidingPrescribe"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.ride_pscrib_gr, 1), GRADE_GRAM_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.fm_gr == 0 && getValue(thisObj, "txtForeignMtrl") == "")
            ) {
              objVal["txtForeignMtrl"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.fm_gr, 1), GRADE_GRAM_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.lsk_gr == 0 && getValue(thisObj, "txtTotalSLK") == "")
            ) {
              objVal["txtTotalSLK"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.lsk_gr, 1), GRADE_GRAM_FORMAT);
            }
            if (!(mStockTransfer.mudtProps.fan_gr == 0 && getValue(thisObj, "txtFancy") == "")
            ) {
              objVal["txtFancy"] = FormatNumber(RoundOff(mStockTransfer.mudtProps.fan_gr, 1), GRADE_GRAM_FORMAT);
            }
            break;

          default:
            break;
        }
      }

      //'Update Totals
      objVal["txtTotalSMK2"] = await mStockTransfer.TotalSmkAndSplitPct();
      objVal["txtTotalKernels2"] = await mStockTransfer.TotalKernelsPct();
      objVal["txtTotalKernelsAndHulls2"] = await mStockTransfer.TotalKernelsAndHullsPct();
      setFieldValues(thisObj, objVal, true);
    } catch (error) {
      errorHandler(error);
    }
  };

  const ExternalPremDeductView = async () => {
    await onbtnPremDeductClick();
  };

  const ExternalFrtAccrlRev = async () => {
    await onbtnFreightAccClick();
  };

  const RecreateTransfer = async (newLocation) => {
    try {
      //'Note: only information independent of shipping location should be copied.

      //'Shipped location
      mStockTransfer.mPrevTransferShpLoc = newLocation;

      //'Received location
      if (mStockTransfer.mudtProps.transfer_type == "FF") {
        mStockTransfer.mPrevTransferRecLoc = mStockTransfer.mPrevTransferShpLoc;
      } else {
        mStockTransfer.mPrevTransferRecLoc = mStockTransfer.mudtProps.rec_buy_pt;
      }
      setOldObj(mStockTransfer);

      //'vendor info
      setVendorInfo(getValue(thisObj, "txtarCarrAbbr"));

      //setStockTransfer(new clsTransfer());
      let newTransfer = new clsTransfer()
      //mStockTransfer.transfer_type(tempST.mudtProps.transfer_type)    ////confirm here and below, which line is needed
      newTransfer.transfer_type(mStockTransfer.mudtProps.transfer_type);

      let finalData = {
        "mTransfer": newTransfer,
        "TransferRole": mStockTransfer.transferRole,
        "FromFrtAccrl": false,
        "ReCreate": true
      }
      setData(thisObj, 'StockTransfer', finalData);
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };

  //--------------------------------Existing integrations done by others

  async function GetBinInfo(BuyingPointID, FormCaption, Col) {
    try {
      let mBinList = new clsBinList();
      let prevPeanutType = mStockTransfer.mudtProps.pnut_type_id,
        prevSeg = mStockTransfer.mudtProps.seg_type,
        prevOrganic = mStockTransfer.mudtProps.from_organic_ind,
        mFilter = "";
      let listData;

      if ((Col == 1)) {
        listData = await mBinList.Load(
          compIdFromLS(),
          cropYearFromLS(),
          BuyingPointID
        );
      } else {
        listData = await mBinList.Load(
          compIdFromLS(),
          cropYearFromLS(),
          BuyingPointID,
          prevPeanutType,
          prevSeg,
          prevOrganic
        );

        mFilter =
          " [" +
          await PeanutTypeName(prevPeanutType) +
          ", Seg " +
          prevSeg +
          "," +
          (prevOrganic == "Y" ? "Organic]" : "Non Organic]");
      }

      //setting data for next screen
      let dataJs = {
        spname: "PROC_WHOUSE_BIN_INVENTORY_LIST",
        buy_pt_id: BuyingPointID,
        buttonName: FormCaption,
        peanutType: prevPeanutType,
        segNum: prevSeg,
        organic: prevOrganic,
        mFilter: mFilter,
        Col: Col,
        listData: listData,
      };
      setData(thisObj, "dataForSelectFromList", dataJs);
      goTo(thisObj, "StockTransfer#SelectFromList#DEFAULT#true#Click");
    } catch (error) {
      errorHandler(error);
    }
  }

  async function afterFromListClosing() {
    try {
      setLoading(true)
      let data = getData(thisObj, "frmStockTransferInq");
      let input = getData(thisObj, "dataForSelectFromList");

      let prevPeanutType = mStockTransfer.mudtProps.pnut_type_id,
        prevSeg = mStockTransfer.mudtProps.seg_type,
        prevOrganic = mStockTransfer.mudtProps.from_organic_ind;
      let mSelectedBin = new clsBin();

      if (data !== undefined && data !== null) {
        let values = getData(thisObj, "SelectedItem");
        for (let key in values) {
          try {
            mSelectedBin[key.toUpperCase()](values[key]);
          } catch (e) {
          }

        }

        if (input.Col == 1) {
          mStockTransfer.mPeanutTypeID = mSelectedBin.mudtProps.PNUT_TYPE_ID;
          mStockTransfer.mSegment = mSelectedBin.mudtProps.SEG_TYPE;
          mStockTransfer.mOrganic = mSelectedBin.mudtProps.ORGANIC_IND;

          let newPeanutData;
          if (mStockTransfer.mPeanutTypeID !== prevPeanutType) {
            let new_peanut_type = await CurrentUser.GetPeanutType(mStockTransfer.mPeanutTypeID)
            //setPeanutType(new_peanut_type);
            newPeanutData = new_peanut_type
          }

          await mStockTransfer.SetShippingBinInfo(mSelectedBin, newPeanutData == undefined ? mPeanutType : newPeanutData);
          setEventTrigger(true)

          if (mStockTransfer.mudtProps.manual_grade_ind !== "Y") {
            await FillGradeInfo();
            await SetupGradeEntryMode();
          }

          if (
            mStockTransfer.mPeanutTypeID !== prevPeanutType ||
            mStockTransfer.mSegment !== prevSeg ||
            prevOrganic !== mStockTransfer.mOrganic
          ) {
            await ClearBinInfo();
          }
        } else {
          await mStockTransfer.SetReceivingBinInfo(mSelectedBin);
          setEventTrigger(true)
        }

        await mStockTransfer.pnut_type_id(values.pnut_type_id)
        setEventTrigger(true)
        await bFillPeanutVariety(input.Col == 1 ? mStockTransfer.mudtProps.shp_buy_pt : mStockTransfer.mudtProps.rec_buy_pt, values.pnut_type_id, input.Col == 1 ? true : false)

        let objVal = {};
        objVal["txtPnutShippdFrm"] = values.pnut_type_id;
        objVal["txtSegShppdFrm"] = data.txtcolSegNum;

        if (input.Col == 1) {
          objVal["ddVarietyShppdFrm"] = values.pnut_variety_id == "" ? "Not Specified" : values.pnut_variety_id;
          objVal["ddGenerationShippdFrm"] = values.seed_gen == "" ? "Not Specified" : values.seed_gen;
          objVal["ddOleicShippdFrm"] = values.oleic_ind == "" ? "Not Specified" : values.oleic_ind;
          objVal["txtOrganicShippdFrm"] = data.txtcolOrganic;
          objVal["txtEdiblOilShippdFrm"] = data.txtcolEdible;
          objVal["txtWhseBinShippdFrm"] = values.whouse_num + "-" + values.bin_num;
        }
        else {
          objVal["ddVarietyShppdTo"] = values.pnut_variety_id == "" ? "Not Specified" : values.pnut_variety_id;
          objVal["ddGenerationShippdTo"] = values.seed_gen == "" ? "Not Specified" : values.seed_gen;
          objVal["ddOleicShippdTo"] = values.oleic_ind == "" ? "Not Specified" : values.oleic_ind;
          objVal["txtOrganicShippdTo"] = data.txtcolOrganic;
          objVal["txtEdiblOilShippdTo"] = data.txtcolEdible;
          objVal["txtWhseBinShippdTo"] = values.whouse_num + "-" + values.bin_num;
        }
        setFieldValues(thisObj, objVal, true);
        await enableDisableForBinControls()
        await setupButtons()
        await mStockTransfer.CalculatePeanutValueOld();  // this 2 lines are for #577547
        await fillSettlementInfo()
      }
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  }

  const onbtnFrmBinClick = () => {
    try {
      GetBinInfo(mStockTransfer.mudtProps.shp_buy_pt, "From Bin", 1);
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.onbtnFrmBinClick = onbtnFrmBinClick;

  const onbtnToBinClick = () => {
    try {
      let Destination_dropdown = getValue(thisObj, "ddDestinationLoc");
      if (
        Destination_dropdown == undefined ||
        Destination_dropdown == null ||
        Destination_dropdown == ""
      ) {
        showMessage(thisObj, "Please, select destination buying point first!");
      } else {
        if (
          mStockTransfer.mudtProps.shp_bin == undefined ||
          mStockTransfer.mudtProps.shp_bin == null || mStockTransfer.mudtProps.shp_bin == ""
        ) {
          showMessage(thisObj, "Please, select From bin first!");
        } else {
          GetBinInfo(mStockTransfer.mudtProps.rec_buy_pt, "To Bin", 2);
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.onbtnToBinClick = onbtnToBinClick;

  const onbtnPaymentClick = () => {
    try {
      let js = {
        frt_vendor: mStockTransfer.mudtProps.frt_vendor,
        rec_buy_pt_ID: mStockTransfer.mudtProps.rec_buy_pt,
        shp_buy_pt: mStockTransfer.mudtProps.shp_buy_pt,
        audit_xfer_num: mStockTransfer.mudtProps.transfer_num,
        Function_Name: "Transfer",
      };
      setData(thisObj, "ReviewPaymentsFormLoadData", js);
      goTo(thisObj, "ContractManagement#ViewPayments#DEFAULT#true#Click");
    } catch (err) {
      errorHandler(err);
    }
  };
  thisObj.onbtnPaymentClick = onbtnPaymentClick;

  const onbtnAcctDistClick = () => {
    try {
      setData(thisObj, "type", "TRANSFER");
      commonContext.setStockTrans_BuyingPointId(
        mStockTransfer.mudtProps.shp_buy_pt
      );
      commonContext.setStockTransfer_Number(
        mStockTransfer.mudtProps.transfer_num
      );
      goTo(
        thisObj,
        "ContractManagement#AccountDistributionActivity#DEFAULT#true#Click"
      );
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.onbtnAcctDistClick = onbtnAcctDistClick;

  const onbtnPrintClick = async () => {
    try {
      //alert("Note: Add Shiping Location Logic need to implement.");

      let XferStatus = await mStockTransfer.StatusName()
      let objReportPrevTransfer = {};
      objReportPrevTransfer.TransferNumber =
        mStockTransfer.mudtProps.transfer_num;
      objReportPrevTransfer.ShpBuyPT = mStockTransfer.mudtProps.shp_buy_pt;
      objReportPrevTransfer.XFERStatus = XferStatus
      objReportPrevTransfer.TransferType = mStockTransfer.mudtProps.transfer_type;
      objReportPrevTransfer.SCAC = getValue(thisObj, "txtCarrAbbr");
      setData(thisObj, "ReportTitle", "BillOfLading");
      setData(thisObj, "ReportPreviewTransferForRPTbill", objReportPrevTransfer);
      goTo(thisObj, "StockTransfer#ReportPreviewTransfer#DEFAULT#true#Click");
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.onbtnPrintClick = onbtnPrintClick;

  const onbtnCloseClick = async () => {
    try {
      let pageClosing = await Form_QueryUnload()
      if (pageClosing) {
        dataForTransferSearch()
        setData(thisObj, "ClosingOfStockTransfer", true)
        document.getElementById("StockTransfer_StockTransferPopUp").childNodes[0].click();
      }
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  const VendorDetails = () => {
    try {
      let vendorDetails = getData(thisObj, "vendorDetails");
      if (vendorDetails !== null) {
        AccountPayment.RetrieveScacVendorLookupDetails(
          vendorDetails.VendorNumber,
          ""
        ).then((response) => {
          if (response.length > 0) {
            if (response[0].scac !== "") {
              mStockTransfer.frt_scac_id(response[0].scac);
              mStockTransfer.frt_vendor(response[0].number);
              setValue(thisObj, "txtCarrAbbr", response[0].scac);
              setValue(thisObj, "txtVndrId", response[0].number);
              setValue(
                thisObj,
                "txtarCarrAbbr",
                response[0].name +
                "," +
                response[0].address +
                "," +
                response[0].city +
                "," +
                response[0].state
              );
            } else {
              setData(thisObj, "vendorDetails", null);
              alert("The SCAC does not exist or does not have an active status!");
              document.getElementById("txtCarrAbbr").focus()
            }
          }
        });
      }
    } catch (error) {
      errorHandler(error)
    }
  };

  const ontxtCarrAbbrBlur = async () => {
    try {
      setLoading(true)
      setData(thisObj, "changedfromtextboxed", true)
      await txtCarrAbbrValidate(false);
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtCarrAbbrBlur = ontxtCarrAbbrBlur;

  const onbtnPremDeductClick = () => {
    try {
      let premiumFrtAccrl = {
        cropYear: mStockTransfer.mudtProps.crop_year,
        tripId: getValue(thisObj, "txtTripId"),
        lblFreightAccrualMileage: ExcelNumericDataConvert(getValue(thisObj, "txtMileage"), [',']),
        txtFreightAccrualMileageOverride: getValue(thisObj, "txtMileageOvrrd"),
        lblNetWeight: ExcelNumericDataConvert(getValue(thisObj, "txtNetWt"), [',', '$']),
        lblLSK: ExcelNumericDataConvert(getValue(thisObj, "txtLsk"), [',', '$']),
        lblWeightLessFM: ExcelNumericDataConvert(getValue(thisObj, "txtWtLessFm"), [',', '$']),
        lblNetLessLSK: ExcelNumericDataConvert(getValue(thisObj, "txtNetLessLsk"), [',', '$']),
        lblFreightRate: ExcelNumericDataConvert(getValue(thisObj, "txtFreightRate"), [',', '$']),
        lblFreightRateBasis: getValue(thisObj, "lblPerTon"),
        lblFreightAmount: ExcelNumericDataConvert(getValue(thisObj, "txtFrghtAm"), [',', '$'])
      };
      setData(thisObj, "premiumFrtAccrl", premiumFrtAccrl);

      let dataJs = {
        mTransfer: mStockTransfer,
        lblCropYear: mStockTransfer.mudtProps.crop_year,
        lblTripID: getValue(thisObj, "txtTripId"),
        ViewPremiumsDeductionsFrtAccrl: true,
        btnName: "btnPremDeduct"
      };

      setData(thisObj, "PremiumDeductionsFrtAccrl", dataJs)
      goTo(thisObj, "StockTransfer#PremiumDeductionsFrtAccrl#DEFAULT#true#Click");

    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.onbtnPremDeductClick = onbtnPremDeductClick;

  const createFreight = async (btnName = "") => {
    try {
      let bNoSCAC = false;
      let lblPending = getValue(thisObj, "lblPending");
      let ddPayFreight = getValue(thisObj, "ddPayFreight");
      let txtarCarrAbbr = getValue(thisObj, "txtarCarrAbbr");

      if (
        lblPending === "Received" &&
        mStockTransfer.mudtProps.frt_scac_id !== "" &&
        (await mStockTransfer.FreightAccrualCompleted()) !== true &&
        mStockTransfer.mudtProps.trip_id == 0 &&
        mStockTransfer.transferRole > UserTransferRoleList.indexOf("Shipper") &&
        ddPayFreight !== PayFreightType.indexOf("NoFreight")
      ) {
        if (txtarCarrAbbr === "") {
          alert("Freight Accrual cannot be completed.");
        } else {
          let validate = await txtCarrAbbrValidate(bNoSCAC);
          if (validate) {
            return false;
          }
          let premiumFrtAccrl = {
            cropYear: mStockTransfer.mudtProps.crop_year,
            lblAutorun: "Y",
            tripId: getValue(thisObj, "txtTripId"),
          };
          setData(thisObj, "premiumFrtAccrl", premiumFrtAccrl);
          let dataJs = {
            mTransfer: mStockTransfer,
            lblCropYear: mStockTransfer.mudtProps.crop_year,
            lblTripID: getValue(thisObj, "txtTripId"),
            lblAutorun: "Y",
            ReadPremiumsDeductionsFrtAccrl: true,
            btnName: btnName
          };
          setData(thisObj, "PremiumDeductionsFrtAccrl", dataJs)
          goTo(
            thisObj,
            "StockTransfer#PremiumDeductionsFrtAccrl#DEFAULT#true#Click"
          );
          return true
        }
      } else if (
        mStockTransfer.mudtProps.frt_scac_id === "" &&
        ddPayFreight === PayFreightType.indexOf("NoFreight")
      ) {
        mStockTransfer.frt_scac_id("");
        mStockTransfer.frt_vendor("");
        setValue(thisObj, "txtVndrId", "");
        setValue(thisObj, "txtCarrAbbr", "");
        setValue(thisObj, "txtarCarrAbbr", "");
        if (!mStockTransfer.save) {
          alert("Save Transfer ERROR");
        }
      }
      return false;

    } catch (error) {
      errorHandler(error)
      return false
    }
  };

  const txtCarrAbbrValidate = async (cancel) => {
    try {
      let txtCarrAbbr = getValue(thisObj, "txtCarrAbbr");
      let ddPayFreight = getValue(thisObj, "ddPayFreight");
      let txtVndrId = getValue(thisObj, "txtVndrId");
      if (txtCarrAbbr !== null && txtCarrAbbr !== "" && txtCarrAbbr !== undefined && ddPayFreight !== "No Freight") {
        let response;
        let textboxboolean = getData(thisObj, "changedfromtextboxed")
        if (textboxboolean) {
          response = await AccountPayment.RetrieveScacVendorLookupDetails("", txtCarrAbbr)
          setData(thisObj, "changedfromtextboxed", false)
        } else {
          response = await AccountPayment.RetrieveScacVendorLookupDetails(txtVndrId, txtCarrAbbr)
        }
        if (response.length > 0) {
          if (response[0].scac !== "") {
            mStockTransfer.frt_scac_id(txtCarrAbbr.toUpperCase());
            mStockTransfer.frt_vendor(response[0].number);
            setValue(thisObj, "txtVndrId", response[0].number);
            setValue(thisObj, "txtCarrAbbr", response[0].scac);
            let carAbbrValue = response[0].name + "\r\n" + response[0].address + "\r\n" + response[0].city + ", " + response[0].state
            setValue(thisObj, "txtarCarrAbbr", capitalize(carAbbrValue));
            mStockTransfer.vendor_info(capitalize(carAbbrValue));
          } else {
            alert("The SCAC does not exist or does not have an active status!");
            setValue(thisObj, "txtCarrAbbr", mStockTransfer.mudtProps.frt_scac_id);
            cancel = true;
          }
        } else {
          alert("The SCAC does not exist or does not have an active status!");
          setValue(thisObj, "txtCarrAbbr", mStockTransfer.mudtProps.frt_scac_id);
          cancel = true;
        }
      } else {
        mStockTransfer.frt_scac_id("");
        mStockTransfer.frt_vendor("");
        setValue(thisObj, "txtCarrAbbr", "")
        setValue(thisObj, "txtVndrId", "")
        setValue(thisObj, "txtarCarrAbbr", "")
      }

      if (cancel) {
        document.getElementById("txtCarrAbbr").focus()
      }
      return cancel;

    } catch (error) {
      errorHandler(error)
    }
  };

  const onchkboxVicamChange = () => {
    try {
      setLoading(true)
      if (mStockTransfer.mbFormFilling) {
        return false;
      }
      let chkVicam = getValue(thisObj, "chkboxVicam");
      mStockTransfer.vicam_ind(chkVicam === true ? "Y" : "N");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.onchkboxVicamChange = onchkboxVicamChange;

  const onbtnDollarValClick = async () => {
    try {
      setLoading(true)
      if (mPeanutPrice === null || mPeanutPrice === undefined) {
        setPeanutPrice(new clsPeanutPrice());
      }
      await mStockTransfer.CalculatePeanutValueOld();
      await fillSettlementInfo();
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.onbtnDollarValClick = onbtnDollarValClick;

  const onbtnFreightAccClick = async () => {
    try {
      setLoading(true)
      let freightAccrualCompleted = await mStockTransfer.FreightAccrualCompleted();
      if (freightAccrualCompleted) {
        if (mStockTransfer.mudtProps.trip_id > 0) {
          showMessage(thisObj,
            "This transfer is part of trip # " + mStockTransfer.mudtProps.trip_id +
            ". Cannot reverse freight accrual. Use Multi-Transfer Profile."
          );
          setLoading(false)
          return false;
        }

        if (mStockTransfer.mudtProps.frt_rate_source.toUpperCase() === "PERMIT") {
          await mStockTransfer.frt_other_wt(0);
          setValue(thisObj, "txtOtherWt", "");
        }
        await mStockTransfer.ReverseFreightAccrualOld()
        setEventTrigger(true)
        let saveOutput = await mStockTransfer.Save()
        let errorOutput = await mStockTransfer.mDbError
        //please uncomment below code later
        if (!saveOutput && errorOutput !== "") {
          showMessage(thisObj, "Save Transfer ERROR: " + errorOutput);
        }

        await SetupForm();
        await FillFreightAmount();
        setLoading(false)
        return false;
      }

      await mStockTransfer.CheckBusinessRules();
      await CheckFormRules();

      let isVoidvalue = await mStockTransfer.isValid()
      let mRule_count = await mORules.Count()
      let mRuleErrors = {}
      if (!isVoidvalue || mRule_count > 0) {
        mRuleErrors = await mStockTransfer.GetBrokenRuleList()
        if (mRule_count > 0) {
          let mORuleList = mORules.BrokenRuleList;
          for (let key in mORuleList) {
            mRuleErrors[key] = (mORuleList[key])
          }
        }
        setData(thisObj, "DataForErrorList", mRuleErrors)
        goTo(thisObj, "StockTransfer#TransferErrorList#DEFAULT#true#Click");
        setLoading(false)
        return true
      }

      await mStockTransfer.CalculatePeanutValueOld();
      await fillSettlementInfo();

      let saveResponse = await mStockTransfer.Save()
      if (!saveResponse) {
        alert("Save Transfer ERROR: " + mStockTransfer.mDbError);
        setLoading(false)
        return;
      }

      if (mStockTransfer.mFANeedInfo !== "") {
        alert(mStockTransfer.mFANeedInfo);
        await mStockTransfer.FANeedInfo("");
        setLoading(false)
        return;
      }

      let childPageOpening = await GetFreightAccrual();
      if (childPageOpening) {
        setLoading(false)
        return
      }
      await FillPayFreightType()
      setValue(thisObj, "txtOtherWt", mStockTransfer.mudtProps.frt_other_wt != "" && mStockTransfer.mudtProps.frt_other_wt != "0" ? FormatNumber(mStockTransfer.mudtProps.frt_other_wt, 0) : "");

      saveResponse = await mStockTransfer.Save()
      if (!saveResponse && mStockTransfer.mDbError !== "") {
        alert("Save Transfer ERROR: " + mStockTransfer.mDbError);
      }

      await SetupForm();
      await FillFreightAmount();
      setEventTrigger(true)
      if (mStockTransfer.mbFromFrtAccrl) {
        setLoading(false)
        onbtnCloseClick()
      }
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.onbtnFreightAccClick = onbtnFreightAccClick;

  const onbtnSaveClick = async () => {
    setLoading(true)
    await mStockTransfer.CheckBusinessRules();
    await CheckFormRules();
    let isValidValue = await mStockTransfer.isValid()
    let mRule_count = await mORules.Count()
    let mRuleErrors = {}
    if (!isValidValue || mRule_count > 0) {
      mRuleErrors = await mStockTransfer.GetBrokenRuleList()
      if (mRule_count > 0) {
        let mORuleList = mORules.BrokenRuleList;
        for (let key in mORuleList) {
          mRuleErrors[key] = (mORuleList[key])
        }
      }
      setData(thisObj, "DataForErrorList", mRuleErrors)
      goTo(thisObj, "StockTransfer#TransferErrorList#DEFAULT#true#Click");
      setLoading(false)
      return true;
    }

    if (mStockTransfer.mudtProps.transfer_type == "ST") {
      if (mStockTransfer.mudtProps.frt_accrl_complete == "N") {
        //collecting data for api
        let data = {};
        let sf_buy_pt = mStockTransfer.mudtProps.shp_buy_pt == "" ? null : mStockTransfer.mudtProps.shp_buy_pt;
        let st_buy_pt = mStockTransfer.mudtProps.rec_buy_pt == "" ? null : mStockTransfer.mudtProps.rec_buy_pt;
        let sf_whse_num = mStockTransfer.mudtProps.shp_whouse == "" ? null : mStockTransfer.mudtProps.shp_whouse;
        let st_whse_num = mStockTransfer.mudtProps.rec_whouse == "" ? null : mStockTransfer.mudtProps.rec_whouse;
        let sf_bin = mStockTransfer.mudtProps.shp_bin == "" ? null : mStockTransfer.mudtProps.shp_bin;
        let st_bin = mStockTransfer.mudtProps.rec_bin == "" ? null : mStockTransfer.mudtProps.rec_bin;
        let lbsofpeanut_old = mStockTransfer.mudtProps.gross_wt == "" ? null : mStockTransfer.mudtProps.gross_wt;
        let lbsofpeanut = mStockTransfer.mudtProps.gross_wt == "" ? null : mStockTransfer.mudtProps.gross_wt;
        let netweight = mStockTransfer.mudtProps.net_wt == "" ? null : mStockTransfer.mudtProps.net_wt;
        let netweight_old = mStockTransfer.mudtProps.net_wt == "" ? null : mStockTransfer.mudtProps.net_wt;
        let tempStatus = await mStockTransfer.GetTransferStatusID(mStockTransfer.mNewStatus);
        let status = tempStatus == "" ? null : tempStatus;
        let status_old = mStockTransfer.mudtProps.xfer_status == "" ? null : mStockTransfer.mudtProps.xfer_status;

        let apiResponse = await StockTransferService.UpdateCheckWarehouseBinInventory(sf_buy_pt, sf_whse_num, sf_bin, st_buy_pt, st_whse_num, st_bin, lbsofpeanut_old, lbsofpeanut, netweight_old, netweight, status_old, status, data); //api call
        if (apiResponse.status !== 200) {
          let confirmbox = window.confirm(apiResponse.message + "\n" + " Do you want to continue with this process?");
          if (confirmbox == false) {
            setLoading(false)
            return;
          }
        }
      }
    }

    //'Form Bin peanut variety is not saving under a specific condition
    //'that cannot be recreate.
    try {
      let sTemp;
      let FreightAccrualCompleted_Value = await mStockTransfer.FreightAccrualCompleted()
      if (FreightAccrualCompleted_Value == false && (mStockTransfer.mNewStatus == TransferStatus.indexOf("Shipped") || mStockTransfer.mNewStatus == TransferStatus.indexOf("Received")) && mStockTransfer.mudtProps.transfer_type == "ST") {
        if (mPeanutType == undefined || mPeanutType == null) {
          let peanutValue = await CurrentUser.GetPeanutType(mStockTransfer.mudtProps.pnut_type_id)
          setPeanutType(peanutValue);
        }
        if (mPeanutType.VarietyList == undefined || mPeanutType.VarietyList == null) {
          mPeanutType.GetVarietyList(cropYearFromLS(), mStockTransfer.mudtProps.shp_buy_pt);
        }
        if (mStockTransfer.mudtProps.shp_bin !== "" && mStockTransfer.mudtProps.from_pnut_variety_id !== getValue(thisObj, "ddVarietyShppdFrm")) {
          await mStockTransfer.from_pnut_variety_id(getValue(thisObj, "ddVarietyShppdFrm")); //here and in above if condition getvalue is used instead of  mPeanutType.VarietyList.GetItemID(vsfBin.Cell(flexcpText, 3, 1))
        }

        if (mStockTransfer.mudtProps.shp_bin !== "" && mStockTransfer.mudtProps.from_seed_gen?.toUpperCase() !== (getValue(thisObj, "ddGenerationShippdFrm") == "N" ? "" : getValue(thisObj, "ddGenerationShippdFrm")?.toUpperCase())) {
          sTemp = "";
          sTemp = getValue(thisObj, "ddGenerationShippdFrm")?.toUpperCase();
          if (sTemp == "N") {
            sTemp = " ";
          }
          await mStockTransfer.from_seed_gen(sTemp);
        }

        if (mStockTransfer.mudtProps.shp_bin !== "" && mStockTransfer.mudtProps.from_oleic_ind?.toUpperCase() !== (getValue(thisObj, "ddOleicShippdFrm") == "N" ? "" : getValue(thisObj, "ddOleicShippdFrm")?.toUpperCase())) {
          sTemp = "";
          sTemp = getValue(thisObj, "ddOleicShippdFrm")?.toUpperCase();
          if (sTemp == "N") {
            sTemp = " ";
          }
          mStockTransfer.from_oleic_ind(sTemp);
        }

        if (mStockTransfer.mudtProps.rec_bin !== "" && mStockTransfer.mudtProps.to_pnut_variety_id !== getValue(thisObj, "ddVarietyShppdTo")) {
          await mStockTransfer.to_pnut_variety_id(getValue(thisObj, "ddVarietyShppdTo")); //here getvalue is used instead of  mPeanutType.VarietyList.GetItemID(vsfBin.Cell(flexcpText, 3, 2))
        }
      }
      await P_Variety();
      setLoading(false)
    } catch (error) {
      showMessage(thisObj, "error occured , now moving to P_Variety method"); //temporary line, please remove after button code completes
      await P_Variety();
      errorHandler(error);
    }
  };
  thisObj.onbtnSaveClick = onbtnSaveClick;

  const P_Variety = async () => {
    try {
      let new_value = await mStockTransfer.IsNew();
      if (new_value) {
        mStockTransfer.bAutoNumber = true;
        if (mStockTransfer.msOld_Trans_Num !== "") {
          if (await use_OldTransferNumber()) {
            mStockTransfer.bAutoNumber = false;
          } else {
            if (await mStockTransfer.CanGenerateTransferNumber()) {
              let confirmation = window.confirm("Transfer #" + mStockTransfer.msOld_Trans_Num + " already exists at shipping location " + mStockTransfer.mudtProps.shp_buy_pt + ".  Do you want to use an automatic number?");
              if (confirmation) {
                mStockTransfer.bAutoNumber = true;
              } else {
                mStockTransfer.bAutoNumber = false;
              }
            } else {
              showMessage(thisObj, "Transfer #" + mStockTransfer.msOld_Trans_Num + " already exists at shipping location" + mStockTransfer.mudtProps.shp_buy_pt + ".  You need to enter a new transfer number.");
              mStockTransfer.bAutoNumber = false;
            }
            mStockTransfer.OldTransferNumber("");
          }
        }

        if (await mStockTransfer.CanGenerateTransferNumber() && mStockTransfer.bAutoNumber == true) {
          let apiresponse = await mStockTransfer.CreateTransfer();
          if (apiresponse == false) {
            //showMessage(thisObj, "Create Transfer ERROR: " + mStockTransfer.DbError())
            showMessage(thisObj, "Create Transfer ERROR: " + mStockTransfer.mDbError);
          } else {
            if (mStockTransfer.mFANeedInfo !== "") {
              showMessage(thisObj, mStockTransfer.mFANeedInfo);
              await mStockTransfer.FANeedInfo("");
            } else {
              if (mStockTransfer.mFANeedInfo !== "") {
                showMessage(thisObj, mStockTransfer.mFANeedInfo);
                mStockTransfer.FANeedInfo("");
              } else {
                let childpageOpening = await createFreight("save1");
                if (childpageOpening) {
                  return;
                }
              }
            }
            setValue(thisObj, "lblNew", mStockTransfer.mudtProps.transfer_num);
            disable(thisObj, "ddShippingLoc");
            disable(thisObj, "ddType");
            await SetupForm();
            //'cmdUpdate.enabled = False  '2011-034
          }
        } else {
          //here frmStockTransferNewNumber need to open and check some code on
          //if data needed to be setted for next screen
          setData(thisObj, "mTransferStockTransferNewNumber", mStockTransfer);
          goTo(thisObj, "StockTransfer#StockTransferNewNumber#DEFAULT#true#Click");
          return;
          //remaining code is on closing of child page , hence check useefect
        }
      } else {
        let saveValue = await mStockTransfer.Save();
        if (saveValue == false) {
          let errorMessage = mStockTransfer.mDbError;
          showMessage(thisObj, "Save Transfer ERROR: " + errorMessage);
        } else {
          if (mStockTransfer.mFANeedInfo !== "") {
            showMessage(thisObj, mStockTransfer.mFANeedInfo);
            await mStockTransfer.FANeedInfo("");
          } else {
            let childPageopening = await createFreight("save3");
            if (childPageopening) {
              return
            }
          }
          await SetupForm();
          await fillSettlementInfo();
          await FillFreightAmount();
          await FillGradeInfo();
          await FillPayFreightType();
        }
      }

      setValue(thisObj, "txtLegMsg", mStockTransfer.mudtProps.leg_mileage != "" && mStockTransfer.mudtProps.leg_mileage != "0" ? FormatNumber(mStockTransfer.mudtProps.leg_mileage, 0) : "");
      setValue(thisObj, "txtOtherWt", mStockTransfer.mudtProps.frt_other_wt != "" && mStockTransfer.mudtProps.frt_other_wt != "0" ? FormatNumber(mStockTransfer.mudtProps.frt_other_wt, 0) : "");
      await mStockTransfer.ApplyEdit();
      await mStockTransfer.BeginEdit();
      mStockTransfer.mbChgShpLoc = false;
    } catch (error) {
      errorHandler(error);
    }
  };

  const Form_Activate = () => {
    try {
      if (mStockTransfer.mudtProps.xfer_status !== "P") {
        if (isEnabled(thisObj, "txtDispatch")) {
          document.getElementById("txtDispatch").focus();
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const Form_QueryUnload = async (cancel) => {
    try {
      cancel = 0
      if (mStockTransfer.mbChgShpLoc) {
        showMessage(thisObj, "You must save New Transfer before continuing!");
        cancel = 1;
        return false;
      }

      let isDirtyValue = await mStockTransfer.IsDirty()
      if (isDirtyValue && mStockTransfer.mudtProps.xfer_status !== "V") {
        let confirmation = window.confirm("Save Transfer Changes?");
        if (confirmation) {
          await mStockTransfer.CheckBusinessRules();
          await CheckFormRules();

          let isValidValue = await mStockTransfer.isValid()
          let mRule_count = await mORules.Count()
          let mRuleErrors = {}
          if (!isValidValue || mRule_count > 0) {
            cancel = 1;
            mRuleErrors = await mStockTransfer.GetBrokenRuleList()
            if (mRule_count > 0) {
              let mORuleList = mORules.BrokenRuleList;
              for (let key in mORuleList) {
                mRuleErrors[key] = (mORuleList[key])
              }
            }
            setData(thisObj, "DataForErrorList", mRuleErrors)
            goTo(thisObj, "StockTransfer#TransferErrorList#DEFAULT#true#Click");
            return false
          } else {
            await onbtnSaveClick();
            return false;
          }
        }
        else {
          cancel = 0
        }
      }

      // let isValidValue = await mStockTransfer.isValid()
      let FreightAccrualCompleted_Value = await mStockTransfer.FreightAccrualCompleted()
      if (
        cancel === 0 &&
        mStockTransfer.mbFromFrtAccrl &&
        mStockTransfer.mudtProps.xfer_status !== "V" &&
        !FreightAccrualCompleted_Value
      ) {
        let confirmBoolean = window.confirm(
          "Are you sure you wish to exit back to the Freight Accrual Payment Release screen without first completing the Freight Accrual for this Stock Transfer?"
        );
        if (!confirmBoolean) {
          cancel = 1
        }
      }

      return cancel == 0 ? true : false
    } catch (error) {
      errorHandler(error);
      return false
    }
  };

  const bSaveTransfer = async () => {
    try {
      let output = await mStockTransfer.Save();
      if (!output) {
        showMessage(thisObj, "Save Transfer ERROR: " + mStockTransfer.mDbError);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const setupButtons = async () => {
    try {
      document.getElementById("btnVoid").innerText =
        mStockTransfer.mudtProps.xfer_status === "V" ? "UnVoid" : "Void";
      if (!(await mStockTransfer.IsNew())) {
        enable(thisObj, "btnVoid");
      }
      else {
        disable(thisObj, "btnVoid")
      }
      if (
        mStockTransfer.mudtProps.manual_grade_ind === "Y" &&
        mStockTransfer.mNewStatus === TransferStatus.indexOf("Received")
      ) {
        enable(thisObj, "btnDollarVal");
      }
      else {
        disable(thisObj, "btnDollarVal");
      }
      if (mStockTransfer.mudtProps.xfer_status !== "P") {
        enable(thisObj, "btnPrint");
      }
      else {
        disable(thisObj, "btnPrint");
      }
      if (mStockTransfer.mudtProps.frt_accrl_complete === "N") {
        document.getElementById("btnFreightAcc").innerText = "Freight Accrual";
        if (
          mStockTransfer.mudtProps.xfer_status === "R" &&
          mStockTransfer.transferRole > UserTransferRoleList.indexOf("Shipper") &&
          mStockTransfer.mudtProps.pay_freight_ind !== "N" &&
          (await mStockTransfer.IsNew()) === false &&
          mStockTransfer.mudtProps.trip_id == "0"
        ) {
          enable(thisObj, "btnFreightAcc");
        }
        else {
          disable(thisObj, "btnFreightAcc")
        }
      } else {
        document.getElementById("btnFreightAcc").innerText =
          "Reverse Freight Accrual";
        if (
          mStockTransfer.mudtProps.xfer_status === "R" &&
          mStockTransfer.transferRole === UserTransferRoleList.indexOf("Freighter")
        ) {
          enable(thisObj, "btnFreightAcc");
        }
        else {
          disable(thisObj, "btnFreightAcc");
        }
      }
      if (
        mStockTransfer.mudtProps.xfer_status !== "V" &&
        mStockTransfer.mudtProps.shp_buy_pt !== "" &&
        mStockTransfer.mudtProps.transfer_type === "ST" &&
        mStockTransfer.transferRole >= UserTransferRoleList.indexOf("Shipper")
      ) {
        enable(thisObj, "btnFrmBin");
      }
      else {
        disable(thisObj, "btnFrmBin");
      }
      if (
        mStockTransfer.mudtProps.xfer_status !== "V" &&
        mStockTransfer.mudtProps.rec_buy_pt !== "" &&
        mStockTransfer.mudtProps.transfer_type === "ST" &&
        mStockTransfer.transferRole > UserTransferRoleList.indexOf("Shipper")
      ) {
        enable(thisObj, "btnToBin");
      }
      else {
        disable(thisObj, "btnToBin");
      }
      if (mStockTransfer.mudtProps.frt_accrl_complete === "Y") {
        disable(thisObj, "btnFrmBin");
        disable(thisObj, "btnToBin");
        enable(thisObj, "btnPayment");
        enable(thisObj, "btnAcctDist");
        enable(thisObj, "btnPremDeduct");
      }
      else {
        disable(thisObj, "btnPayment");
        disable(thisObj, "btnAcctDist");
        disable(thisObj, "btnPremDeduct");
      }
      if (
        (await mStockTransfer.IsDirty()) &&
        mStockTransfer.mudtProps.xfer_status !== "V"
      ) {
        enable(thisObj, "btnSave");
      }
      else {
        disable(thisObj, "btnSave");
      }
    } catch (error) {
      errorHandler(error)
    }
  };

  const fillSettlementInfo = async () => {
    try {
      let objVal = {};
      objVal["txtGrossWt"] = FormatNumber(mStockTransfer.mudtProps.gross_wt, 0);
      objVal["txtFm"] = FormatNumber(mStockTransfer.mudtProps.fm_wt, 0);
      objVal["txtWtLessFm"] = FormatNumber(await mStockTransfer.WeightLessFM(), 0);
      objVal["txtExcMoisture"] = FormatNumber(mStockTransfer.mudtProps.ex_moist_wt, 0);
      objVal["txtNetWt"] = FormatNumber(mStockTransfer.mudtProps.net_wt, 0);
      objVal["txtLsk"] = FormatNumber(mStockTransfer.mudtProps.lsk_wt, 0);
      objVal["txtNetLessLsk"] = FormatNumber(await mStockTransfer.WeightLessLSK(), 0);
      objVal["lblDollar"] = "$" + FormatNumber(mStockTransfer.mudtProps.basis_grade_amt, 2);
      setFieldValues(thisObj, objVal, true);
      await setupButtons();
    } catch (error) {
      errorHandler(error)
    }
  };

  const use_OldTransferNumber = async () => {//check
    try {
      let transferList = await StockTransferService.RetrieveTransferHeaderDetails(
        mStockTransfer.msOld_Trans_Num,
        mStockTransfer.mudtProps.transfer_type,
        mStockTransfer.mudtProps.shp_buy_pt
      );
      if (transferList.length > 0) {
        return false;
      }
      return true;
    } catch (error) {
      errorHandler(error)
    }
  };

  const validateSecurity = async () => {
    if (mStockTransfer.transferRole === UserTransferRoleList.indexOf("ReadOnly")) {
      return false;
    } else if (mStockTransfer.transferRole === UserTransferRoleList.indexOf("Shipper")) {
      if ((await mStockTransfer.StatusName()) === "Pending" || (await mStockTransfer.StatusName()) === "Shipped") {
        return true;
      } else {
        return false;
      }
    } else if (mStockTransfer.transferRole === UserTransferRoleList.indexOf("Receiver")) {
      if ((await mStockTransfer.StatusName()) === "Pending" || (await mStockTransfer.StatusName()) === "Shipped" || (await mStockTransfer.StatusName()) === "Received") {
        return true;
      } else {
        return false;
      }
    } else if (mStockTransfer.transferRole === UserTransferRoleList.indexOf("Freighter")) {
      return true;
    } else {
      return false;
    }
  };

  const displayText = (sText, controlName) => {
    try {
      let control = isEnabled(thisObj, [controlName]);
      let value = (getValue(thisObj, [controlName]));
      if (sText === "") {
        setValue(thisObj, [controlName], sText);
      } else if (
        mStockTransfer.mudtProps.manual_grade_ind === "Y" && control === true
        //&& value === "" && sText === 0 //commented this condition as in new app state already have a value
      ) {
        setValue(thisObj, [controlName], sText);
      }
    } catch (error) {
      errorHandler(error)
    }
  };

  const ontxtClnSmplWtBlur = async () => {
    try {

      if (
        mStockTransfer.mudtProps.manual_grade_ind === "Y" &&
        !(await mStockTransfer.FreightAccrualCompleted()) &&
        mStockTransfer.mudtProps.transfer_type === "ST" &&
        (mStockTransfer.mudtProps.xfer_status === "R" ||
          mStockTransfer.mNewStatus === TransferStatus.indexOf("Received"))
      ) {
        mORules.RuleBroken(GradingRule.GRADE_CLEAN_SAMPLE_WEIGHT, true, false);
        assignGramValue(mStockTransfer, "cln_sample", "txtClnSmplWt");
      } else {
        mORules.RuleBroken(GradingRule.GRADE_FM_SAMPLE_WEIGHT, false, false);
      }
      displayText(assignGramValue(mStockTransfer, "cln_sample", "txtClnSmplWt"), "txtClnSmplWt")
      setEventTrigger(true)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtClnSmplWtBlur = ontxtClnSmplWtBlur;

  const ontxtConcealedRMDBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignGramValue(mStockTransfer, "cncl_rmd_gr", "txtConcealedRMD"),
        "txtConcealedRMD"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtConcealedRMDBlur = ontxtConcealedRMDBlur;

  const ontxtConcealedRMDPctBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignPercentValue(mStockTransfer, "cncl_rmd_pct", "txtConcealedRMDPct"),
        "txtConcealedRMDPct"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtConcealedRMDPctBlur = ontxtConcealedRMDPctBlur;

  const ontxtCrackedBrokenBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignGramValue(mStockTransfer, "cr_br_gr", "txtCrackedBroken"),
        "txtCrackedBroken"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtCrackedBrokenBlur = ontxtCrackedBrokenBlur;

  const ontxtCrackedBrokenPctBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignPercentValue(mStockTransfer, "cr_br_pct", "txtCrackedBrokenPct"),
        "txtCrackedBrokenPct"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtCrackedBrokenPctBlur = ontxtCrackedBrokenPctBlur;

  const ontxtDamageSplitsBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignGramValue(mStockTransfer, "dam_split_gr", "txtDamageSplits"),
        "txtDamageSplits"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtDamageSplitsBlur = ontxtDamageSplitsBlur

  const ontxtDiscoloredShellsBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignGramValue(mStockTransfer, "dcol_shel_gr", "txtDiscoloredShells"),
        "txtDiscoloredShells"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtDiscoloredShellsBlur = ontxtDiscoloredShellsBlur;

  const ontxtDiscoloredShellsPctBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignPercentValue(
          mStockTransfer,
          "dcol_shel_pct",
          "txtDiscoloredShellsPct"
        ), "txtDiscoloredShellsPct"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtDiscoloredShellsPctBlur = ontxtDiscoloredShellsPctBlur;

  const ontxtNetELKBlur = () => {
    try {
      setLoading(true)
      displayText(assignGramValue(mStockTransfer, "elk_gr", "txtNetELK"), "txtNetELK");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtNetELKBlur = ontxtNetELKBlur;

  const ontxtNetELKPctBlur = () => {
    try {
      setLoading(true)
      displayText(assignPercentValue(mStockTransfer, "elk_pct", "txtNetELKPct"), "txtNetELKPct");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtNetELKPctBlur = ontxtNetELKPctBlur;

  const ontxtFancyBlur = () => {
    try {
      setLoading(true)
      displayText(assignGramValue(mStockTransfer, "fan_gr", "txtFancy"), "txtFancy");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtFancyBlur = ontxtFancyBlur;

  const ontxtFancyPctBlur = () => {
    try {
      setLoading(true)
      displayText(assignPercentValue(mStockTransfer, "fan_pct", "txtFancyPct"), "txtFancyPct");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtFancyPctBlur = ontxtFancyPctBlur;

  const ontxtFmSampleBlur = async () => {
    try {
      setLoading(true)
      let value = ExcelNumericDataConvert(getValue(thisObj, "txtFmSample"), [',', '$']);
      let freightAccrualCompleted =
        await mStockTransfer.FreightAccrualCompleted();

      if (
        mStockTransfer.mudtProps.manual_grade_ind === "Y" &&
        !freightAccrualCompleted &&
        mStockTransfer.mudtProps.transfer_type === "ST" &&
        (mStockTransfer.mudtProps.xfer_status === "R" ||
          mStockTransfer.mNewStatus === TransferStatus.indexOf("Received")) &&
        value > 0
      ) {
        mORules.RuleBroken(GradingRule.GRADE_FM_SAMPLE_WEIGHT, true, false);
        assignGramValue(mStockTransfer, "fm_sample", "txtFmSample");
      } else {
        mORules.RuleBroken(GradingRule.GRADE_FM_SAMPLE_WEIGHT, false, false);
      }
      displayText(assignGramValue(mStockTransfer, "fm_sample", "txtFmSample"), "txtFmSample");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtFmSampleBlur = ontxtFmSampleBlur;

  const ontxtForeignMtrlBlur = () => {
    try {
      setLoading(true)
      displayText(assignGramValue(mStockTransfer, "fm_gr", "txtForeignMtrl"), "txtForeignMtrl");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtForeignMtrlBlur = ontxtForeignMtrlBlur;

  const ontxtForeignMaterialPctBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignPercentValue(mStockTransfer, "fm_pct", "txtForeignMaterialPct"),
        "txtForeignMaterialPct"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtForeignMaterialPctBlur = ontxtForeignMaterialPctBlur;

  const ontxtFreezeDamageBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignGramValue(mStockTransfer, "frez_dam_gr", "txtFreezeDamage"),
        "txtFreezeDamage"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtFreezeDamageBlur = ontxtFreezeDamageBlur;

  const ontxtFreezeDamagePctBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignPercentValue(mStockTransfer, "frez_dam_pct", "txtFreezeDamagePct"),
        "txtFreezeDamagePct"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtFreezeDamagePctBlur = ontxtFreezeDamagePctBlur;

  const ontxtHullBrightnessBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignGramValue(
          mStockTransfer,
          "hul_brit_gr",
          "txtHullBrightness"
        ), "txtHullBrightness"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtHullBrightnessBlur = ontxtHullBrightnessBlur;

  const ontxtHullBrightnessPctBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignPercentValue(mStockTransfer, "hul_brit_pct", "txtHullBrightnessPct"),
        "txtHullBrightnessPct"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtHullBrightnessPctBlur = ontxtHullBrightnessPctBlur;

  const ontxtHullsBlur = () => {
    try {
      setLoading(true)
      if (mStockTransfer.mbFormFilling) {
        setLoading(false)
        return false;
      }
      displayText(assignGramValue(mStockTransfer, "hull_gr", "txtHulls"),
        "txtHulls");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtHullsBlur = ontxtHullsBlur;

  const ontxtHullsPctBlur = () => {
    try {
      setLoading(true)
      displayText(assignPercentValue(mStockTransfer, "hull_pct", "txtHullsPct"), "txtHullsPct");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtHullsPctBlur = ontxtHullsPctBlur;

  const ontxtJumboBlur = () => {
    try {
      setLoading(true)
      displayText(assignGramValue(mStockTransfer, "jumbo_gr", "txtJumbo"), "txtJumbo");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtJumboBlur = ontxtJumboBlur;

  const ontxtJumboGrPctBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignPercentValue(mStockTransfer, "jumbo_pct", "txtJumboGrPct"),
        "txtJumboGrPct"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtJumboGrPctBlur = ontxtJumboGrPctBlur;

  const ontxtKernRidingELKBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignGramValue(mStockTransfer, "elk_ride_gr", "txtKernRidingELK"),
        "txtKernRidingELK"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtKernRidingELKBlur = ontxtKernRidingELKBlur;

  const onlblKernRidingELKPctBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignPercentValue(mStockTransfer, "elk_ride_pct", "lblKernRidingELKPct"),
        "lblKernRidingELKPct"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.onlblKernRidingELKPctBlur = onlblKernRidingELKPctBlur;

  const ontxtKernRidingPrescribeBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignGramValue(
          mStockTransfer,
          "ride_pscrib_gr",
          "txtKernRidingPrescribe"
        ), "txtKernRidingPrescribe"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtKernRidingPrescribeBlur = ontxtKernRidingPrescribeBlur;

  const ontxtKernRidingPrescribePctBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignPercentValue(
          mStockTransfer,
          "ride_pscrib_pct",
          "txtKernRidingPrescribePct"
        ), "txtKernRidingPrescribePct"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtKernRidingPrescribePctBlur = ontxtKernRidingPrescribePctBlur;

  const ontxtLSKDamageRiding2Blur = () => {
    try {
      setLoading(true)
      displayText(
        assignPercentValue(mStockTransfer, "lsk_dam_pct", "txtLSKDamageRiding2"),
        "txtLSKDamageRiding2"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtLSKDamageRiding2Blur = ontxtLSKDamageRiding2Blur;

  const ontxtLSKFallThru2Blur = () => {
    try {
      setLoading(true)
      displayText(
        assignPercentValue(mStockTransfer, "lsk_fall_pct", "txtLSKFallThru2"),
        "txtLSKFallThru2"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtLSKFallThru2Blur = ontxtLSKFallThru2Blur;

  const ontxtTotalSLKBlur = () => {
    try {
      setLoading(true)
      displayText(assignGramValue(mStockTransfer, "lsk_gr", "txtTotalSLK"),
        "txtTotalSLK"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtTotalSLKBlur = ontxtTotalSLKBlur;

  const ontxtTotalSLKPctBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignPercentValue(mStockTransfer, "lsk_pct", "txtTotalSLKPct"),
        "txtTotalSLKPct"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtTotalSLKPctBlur = ontxtTotalSLKPctBlur;

  const ontxtLSKRidingPctBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignPercentValue(mStockTransfer, "lsk_ride_pct", "txtLSKRidingPct"),
        "txtLSKRidingPct"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtLSKRidingPctBlur = ontxtLSKRidingPctBlur;

  const ontxtMoisture2Blur = () => {
    try {
      setLoading(true)
      displayText(assignGramValue(mStockTransfer, "moist_pct", "txtMoisture2"), "txtMoisture2");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtMoisture2Blur = ontxtMoisture2Blur;

  const ontxtOKRidingPctBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignPercentValue(mStockTransfer, "ok_ride_pct", "txtOKRidingPct"),
        "txtOKRidingPct"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtOKRidingPctBlur = ontxtOKRidingPctBlur;

  const ontxtOKDamageRiding2Blur = () => {
    try {
      setLoading(true)
      displayText(
        assignPercentValue(mStockTransfer, "ok_dam_pct", "txtOKDamageRiding2"),
        "txtOKDamageRiding2"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtOKDamageRiding2Blur = ontxtOKDamageRiding2Blur;

  const ontxtOKFallThru2Blur = () => {
    try {
      setLoading(true)
      displayText(
        assignPercentValue(mStockTransfer, "ok_fall_pct", "txtOKFallThru2"),
        "txtOKFallThru2"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtOKFallThru2Blur = ontxtOKFallThru2Blur;

  const ontxtTotalOKBlur = () => {
    try {
      setLoading(true)
      displayText(assignGramValue(mStockTransfer, "ok_gr", "txtTotalOK"), "txtTotalOK");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtTotalOKBlur = ontxtTotalOKBlur;

  const ontxtTotalOKPctBlur = () => {
    try {
      setLoading(true)
      displayText(assignPercentValue(mStockTransfer, "ok_pct", "txtTotalOKPct"), "txtTotalOKPct");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtTotalOKPctBlur = ontxtTotalOKPctBlur;

  const ontxtPittingDamageBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignGramValue(mStockTransfer, "pit_dam_gr", "txtPittingDamage"),
        "txtPittingDamage"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtPittingDamageBlur = ontxtPittingDamageBlur;

  const ontxtPittingDamagePctBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignPercentValue(
          mStockTransfer,
          "pit_dam_pct",
          "txtPittingDamagePct"
        ),
        "txtPittingDamagePct"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtPittingDamagePctBlur = ontxtPittingDamagePctBlur;

  const ontxtSMK1GrBlur = () => {
    try {
      setLoading(true)
      displayText(assignNumericValue(mStockTransfer, "smk_1_guage", "txtSMK1Gr"), "txtSMK1Gr");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtSMK1GrBlur = ontxtSMK1GrBlur;

  const ontxtSMK1PctBlur = () => {
    try {
      setLoading(true)
      displayText(assignPercentValue(mStockTransfer, "smk_1_pct", "txtSMK1Pct"), "txtSMK1Pct");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtSMK1PctBlur = ontxtSMK1PctBlur;

  const ontxtSMK2PctBlur = () => {
    try {
      setLoading(true)
      displayText(assignPercentValue(mStockTransfer, "smk_2_pct", "txtSMK2Pct"), "txtSMK2Pct");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtSMK2PctBlur = ontxtSMK2PctBlur;

  const ontxtSMK3PctBlur = () => {
    try {
      setLoading(true)
      displayText(assignPercentValue(mStockTransfer, "smk_3_pct", "txtSMK3Pct"), "txtSMK3Pct");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtSMK3PctBlur = ontxtSMK3PctBlur;

  const ontxtSMK4PctBlur = () => {
    try {
      setLoading(true)
      displayText(assignPercentValue(mStockTransfer, "smk_4_pct", "txtSMK4Pct"), "txtSMK4Pct");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtSMK4PctBlur = ontxtSMK4PctBlur;

  const ontxtTotalSMKRScreenBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignGramValue(mStockTransfer, "smk_gr", "txtTotalSMKRScreen"),
        "txtTotalSMKRScreen"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtTotalSMKRScreenBlur = ontxtTotalSMKRScreenBlur;

  const ontxtTotalSMKRScreenPctBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignPercentValue(mStockTransfer, "smk_pct", "txtTotalSMKRScreenPct"),
        "txtTotalSMKRScreenPct"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtTotalSMKRScreenPctBlur = ontxtTotalSMKRScreenPctBlur;

  const ontxtSoundSplitsBlur = () => {
    try {
      setLoading(true)
      displayText(assignGramValue(mStockTransfer, "ss_gr", "txtSoundSplits"), "txtSoundSplits");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtSoundSplitsBlur = ontxtSoundSplitsBlur;

  const ontxtSoundSplitsPctBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignPercentValue(mStockTransfer, "ss_pct", "txtSoundSplitsPct"),
        "txtSoundSplitsPct"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtSoundSplitsPctBlur = ontxtSoundSplitsPctBlur;

  const ontxtTotalDamageBlur = () => {
    try {
      setLoading(true)
      displayText(assignGramValue(mStockTransfer, "dam_gr", "txtTotalDamage"), "txtTotalDamage");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtTotalDamageBlur = ontxtTotalDamageBlur;

  const ontxtTotalDamagePctBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignPercentValue(mStockTransfer, "dam_pct", "txtTotalDamagePct"),
        "txtTotalDamagePct"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtTotalDamagePctBlur = ontxtTotalDamagePctBlur;

  const ontxtTotalELKDamageBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignGramValue(mStockTransfer, "elk_dam_gr", "txtTotalELKDamage"),
        "txtTotalELKDamage"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtTotalELKDamageBlur = ontxtTotalELKDamageBlur;

  const ontxtTotalELKDamagePctBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignPercentValue(mStockTransfer, "elk_dam_pct", "txtTotalELKDamagePct"),
        "txtTotalELKDamagePct"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtTotalELKDamagePctBlur = ontxtTotalELKDamagePctBlur;

  const ontxtVicamReadingBlur = () => {
    try {
      setLoading(true)
      displayText(
        assignGramValue(mStockTransfer, "vicam_ppb", "txtVicamReading"),
        "txtVicamReading"
      );
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.ontxtVicamReadingBlur = ontxtVicamReadingBlur;

  const onddTypeChange = async () => {
    try {
      setLoading(true)
      // Validation Condition
      if (mStockTransfer.mbFormFilling) {
        setLoading(false)
        return false;
      }

      let cboTransferType = getValue(thisObj, "ddType");
      if (cboTransferType == '1') {
        if (![null, undefined, ""].includes(getValue(thisObj, "ddShippingLoc"))) {
          await mStockTransfer.CheckTransferAccessLevel(
            mStockTransfer.mudtProps.shp_buy_pt,
            mStockTransfer.mudtProps.shp_buy_pt,
            mStockTransfer.mudtProps.transfer_type
          );
        }
      }

      await mStockTransfer.SetTransferType(cboTransferType);
      await FillPayFreightType();
      await SetupForm();

      if (mStockTransfer.mPrevTransferType !== mStockTransfer.mudtProps.transfer_type) {
        await mStockTransfer.state_abbr("");
        await FillStates();
        await mStockTransfer.county_id("");
        await FillCounties(false);
        await mStockTransfer.farm_id("");
        await mStockTransfer.farm_suffix("");
        await FillFarms();
      }

      await enableDisableForBinControls()

      if ((mStockTransfer.mPrevTransferType === "ST" && mStockTransfer.mudtProps.transfer_type === "NL") || (mStockTransfer.mPrevTransferType === "NL" && mStockTransfer.mudtProps.transfer_type === "ST")) {
        setLoading(false)
        return false;
      }

      if (mStockTransfer.mudtProps.transfer_type === "FF") {
        let cboShippingLocation = getValue(thisObj, "ddShippingLoc");
        if (![null, undefined, ""].includes(cboShippingLocation)) {
          await mStockTransfer.CheckTransferAccessLevel(
            mStockTransfer.mudtProps.shp_buy_pt,
            mStockTransfer.mudtProps.shp_buy_pt,
            mStockTransfer.mudtProps.transfer_type
          );
        }

        let shippinglist = mStockTransfer.ShippingLocationList;
        let shippingInfo;
        if (cboShippingLocation !== undefined && cboShippingLocation !== null) {
          shippingInfo = shippinglist.find(
            (elem) => elem.buy_pt_id == cboShippingLocation
          );
        }

        // let js = [];
        // let obj;
        // obj = { key: "", description: "" };
        // js.push(obj);
        // thisObj.setState((current) => {
        //   return {
        //     ...current,
        //     ddDestinationLoc: {
        //       ...state["ddDestinationLoc"],
        //       valueList: js,
        //     },
        //   };
        // });
        setValue(thisObj, "ddDestinationLoc", "")

        if (shippingInfo !== undefined) {
          let jsDestination = [];
          let objDest;
          objDest = { key: shippingInfo.buy_pt_id, description: shippingInfo.buy_pt_id + " - " + shippingInfo.buy_pt_name };
          jsDestination.push(objDest);
          thisObj.setState((current) => {
            return {
              ...current,
              ddDestinationLoc: {
                ...state["ddDestinationLoc"],
                valueList: jsDestination,
              },
            };
          });
          setValue(thisObj, "ddDestinationLoc", shippingInfo.buy_pt_id)
          await mStockTransfer.SetReceivingPointInfo(shippingInfo);
          setEventTrigger(true)
          await mStockTransfer.rec_buy_pt(mStockTransfer.mudtProps.shp_buy_pt);
          setEventTrigger(true)
          setValue(thisObj, "txtAddDest", getValue(thisObj, "txtAddShip"));
          setValue(thisObj, "txtPhnDest", getValue(thisObj, "txtPhnShip"));
          await mStockTransfer.manual_grade_ind("Y");
          await SetupForm();
        }
      } else {
        setValue(thisObj, "txtAddDest", "");
        setValue(thisObj, "txtPhnDest", "");
        if (mStockTransfer.mudtProps.shp_buy_pt === "") {
          let js = [];
          let obj;
          obj = { key: "", description: "" };
          js.push(obj);
          thisObj.setState((current) => {
            return {
              ...current,
              ddDestinationLoc: {
                ...state["ddDestinationLoc"],
                valueList: js,
              },
            };
          });
        } else {
          // FillDestinationPointComboBox DestinationLocationList, cboDestinationLocation, mStockTransfer.REC_BUY_PT
        }
      }
      setEventTrigger(true)
      await enableDisableForBinControls()
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.onddTypeChange = onddTypeChange;

  const onddPayFreightChange = async () => {
    try {
      setLoading(true)
      if (mStockTransfer.mbFormFilling) {
        setLoading(false)
        return false;
      }
      let cboPayFreightType = getValue(thisObj, "ddPayFreight");
      await mStockTransfer.SetPayFreightType(cboPayFreightType);

      if (cboPayFreightType === 3) {
        await FillFreightAmount();
      }
      await SetupForm();
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.onddPayFreightChange = onddPayFreightChange;

  const onddUpdtInventChange = async () => {
    try {
      setLoading(true)
      if (mStockTransfer.mbFormFilling) {
        setLoading(false)
        return false;
      }
      let cboInventoryUpdateWeightType = getValue(thisObj, "ddUpdtInvent");
      await mStockTransfer.SetInventoryIndicator(cboInventoryUpdateWeightType);
      if (mStockTransfer.mudtProps.inventory_ind === "O") {
        show(thisObj, "txtUpdtInvent")
      } else {
        hide(thisObj, "txtUpdtInvent", false);
      }
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.onddUpdtInventChange = onddUpdtInventChange;

  const onddCountryChange = async () => {
    try {
      setLoading(true)
      if (mStockTransfer.mbFormFilling) {
        setLoading(false)
        return false;
      }
      let ddCountry = getValue(thisObj, "ddCountry");
      await mStockTransfer.county_id(ddCountry);
      await mStockTransfer.farm_id("");
      await mStockTransfer.farm_suffix("");
      await FillFarms();
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.onddCountryChange = onddCountryChange;

  const onddStateChange = async () => {
    try {
      setLoading(true)
      if (mStockTransfer.mbFormFilling) {
        setLoading(false)
        return false;
      }
      let ddState = getValue(thisObj, "ddState");
      await mStockTransfer.state_abbr(
        mStockTransfer.StateList.find((elem) => elem.state_id === ddState).state_abbr
      );
      await mStockTransfer.county_id("");
      await FillCounties(false);
      await mStockTransfer.farm_id("");
      await mStockTransfer.farm_suffix("");
      await FillFarms();
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.onddStateChange = onddStateChange;

  const onddFarmChange = async () => {
    try {
      setLoading(true)
      if (mStockTransfer.mbFormFilling) {
        setLoading(false)
        return false;
      }
      let ddFarm = getValue(thisObj, "ddFarm");
      let Farm_Suffix_FromList = "";
      if (ddFarm !== "") {
        if (ddFarm !== "") {
          Farm_Suffix_FromList = ddFarm;
        }
      }
      await mStockTransfer.farm_id(ddFarm);
      await mStockTransfer.farm_suffix(Farm_Suffix_FromList);
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.onddFarmChange = onddFarmChange;
  //----------------methods from modMain file start-----------------

  const DigitsDecimal = (data) => {
    try {
      // allows digits and dot to be entered
      let res = "",
        decimalPoints = 0;
      for (var i = 0; i < data.length; i++) {
        if (
          (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) ||
          data[i].charCodeAt(0) == 46
        ) {
          if (data[i] == ".") {
            decimalPoints += 1;
          }
          if (decimalPoints > 1) {
            break;
          }
          res += data[i];
        }
      }
      return res;
    } catch (error) {
      errorHandler(error);
    }
  };

  const Digitsonly = (data) => {
    try {
      var res = "";
      for (var i = 0; i < data.length; i++) {
        if (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) {
          res += data[i];
        }
      }
      return res;
    } catch (error) {
      errorHandler(error);
    }
  };

  const bFillPeanutType = async () => {
    let js = [];
    js.push({ key: "RU", description: "Runner" });
    js.push({ key: "SP", description: "Spanish" });
    js.push({ key: "VA", description: "Virginia" });
    js.push({ key: "VL", description: "Valencia" });
    thisObj.setState((current) => {
      return {
        ...current,
        txtPnutShippdFrm: {
          ...state["txtPnutShippdFrm"],
          valueList: js,
        },
      };
    });
  };

  const bFillPeanutVariety = async (buyingPointId, pnut_type_id, isShipping) => {
    try {
      let js = [];
      js.push({ key: "Not Specified", description: "Not Specified" });
      if (![null, undefined, ""].includes(buyingPointId) && ![null, undefined, ""].includes(pnut_type_id)) {
        let data = await SystemMaintenanceMasterManagementService.RetrievePeanutVarietyFilterDetails(compIdFromLS(), cropYearFromLS(), buyingPointId, pnut_type_id)
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            let varietyName = data[i].name
            if (String(data[i].symbol_ind).trim() != "") {
              switch (String(data[i].symbol_ind).trim().toUpperCase()) {
                case "TRADEMARK":
                  varietyName = String(varietyName).trim() + " \u2122";
                  break;
                case "REGISTERED TRADEMARK":
                  varietyName = String(varietyName).trim() + " \xAE";
                  break;
                case "COPYRIGHT":
                  varietyName = String(varietyName).trim() + " \xA9";
                  break;
                default:
                  varietyName = String(varietyName).trim();
                  break;
              }
            }
            let isSelected = data[i].isselected == "Y" ? true : false;
            if (!isSelected) {
              let obj = { key: data[i].id, description: varietyName, oleic_ind: data[i].oleic_ind }
              js.push(obj)
            }
          }
        }
      }
      if (isShipping) {
        thisObj.setState((current) => {
          return {
            ...current,
            ddVarietyShppdFrm: {
              ...state["ddVarietyShppdFrm"],
              valueList: js,
            },
          };
        });
      }
      else {
        thisObj.setState((current) => {
          return {
            ...current,
            ddVarietyShppdTo: {
              ...state["ddVarietyShppdTo"],
              valueList: js,
            },
          };
        });
      }
    } catch (error) {
      errorHandler(error)
    }
  }

  const bFillSeedGeneration = async () => {
    let js = [];
    js.push({ key: "Not Specified", description: "Not Specified" });
    js.push({ key: "F", description: "Foundation" });
    js.push({ key: "R", description: "Registered" });
    js.push({ key: "C", description: "Certified" });
    thisObj.setState((current) => {
      return {
        ...current,
        ddGenerationShippdFrm: {
          ...state["ddGenerationShippdFrm"],
          valueList: js,
        },
      };
    });
    thisObj.setState((current) => {
      return {
        ...current,
        ddGenerationShippdTo: {
          ...state["ddGenerationShippdTo"],
          valueList: js,
        },
      };
    });
  };

  const bFillOleic = async () => {
    let js = [];
    js.push({ key: "Not Specified", description: "Not Specified" });
    js.push({ key: "H", description: "High" });
    js.push({ key: "M", description: "Medium" });
    // js.push({ key: "L", description: "Low" });
    thisObj.setState((current) => {
      return {
        ...current,
        ddOleicShippdFrm: {
          ...state["ddOleicShippdFrm"],
          valueList: js,
        },
      };
    });
    thisObj.setState((current) => {
      return {
        ...current,
        ddOleicShippdTo: {
          ...state["ddOleicShippdTo"],
          valueList: js,
        },
      };
    });
  };

  const FillBinData = async () => {
    let tmpFormFilling = mStockTransfer.mbFormFilling;
    mStockTransfer.mbFormFilling = true;
    await bFillPeanutType();
    await bFillPeanutVariety(mStockTransfer.mudtProps.shp_buy_pt, mStockTransfer.mudtProps.pnut_type_id, true)
    await bFillPeanutVariety(mStockTransfer.mudtProps.rec_buy_pt, mStockTransfer.mudtProps.pnut_type_id, false)
    await bFillSeedGeneration();
    await bFillOleic();

    setValue(
      thisObj,
      "chkbxCornFnd",
      mStockTransfer.mudtProps.corn_ind == "Y" ? true : false
    );

    setValue(
      thisObj,
      "txtPnutShippdFrm",
      mStockTransfer.mudtProps.pnut_type_id
    );
    setValue(
      thisObj,
      "txtSegShppdFrm",
      await SegmentName(mStockTransfer.mudtProps.seg_type)
    );

    //from bin values
    if (mStockTransfer.mudtProps.shp_bin != "") {
      setValue(
        thisObj,
        "ddVarietyShppdFrm",
        mStockTransfer.mudtProps.from_pnut_variety_id == ""
          ? "Not Specified"
          : mStockTransfer.mudtProps.from_pnut_variety_id
      );
      setValue(
        thisObj,
        "ddGenerationShippdFrm",
        mStockTransfer.mudtProps.from_seed_gen == ""
          ? "Not Specified"
          : mStockTransfer.mudtProps.from_seed_gen
      );
      setValue(
        thisObj,
        "ddOleicShippdFrm",
        mStockTransfer.mudtProps.from_oleic_ind == ""
          ? "Not Specified"
          : mStockTransfer.mudtProps.from_oleic_ind
      );
      setValue(
        thisObj,
        "txtOrganicShippdFrm",
        mStockTransfer.mudtProps.from_organic_ind
      );
      setValue(
        thisObj,
        "txtEdiblOilShippdFrm",
        await EdibleName(mStockTransfer.mudtProps.from_edible_oil_ind)
      );
      setValue(
        thisObj,
        "txtWhseBinShippdFrm",
        await WhouseBin(
          mStockTransfer.mudtProps.shp_whouse,
          mStockTransfer.mudtProps.shp_bin
        )
      );
    }

    //to bin values
    if (mStockTransfer.mudtProps.rec_bin != "") {
      setValue(
        thisObj,
        "ddVarietyShppdTo",
        mStockTransfer.mudtProps.to_pnut_variety_id == ""
          ? "Not Specified"
          : mStockTransfer.mudtProps.to_pnut_variety_id
      );
      setValue(
        thisObj,
        "ddGenerationShippdTo",
        mStockTransfer.mudtProps.to_seed_gen == ""
          ? "Not Specified"
          : mStockTransfer.mudtProps.to_seed_gen
      );
      setValue(
        thisObj,
        "ddOleicShippdTo",
        mStockTransfer.mudtProps.to_oleic_ind == ""
          ? "Not Specified"
          : mStockTransfer.mudtProps.to_oleic_ind
      );
      setValue(
        thisObj,
        "txtOrganicShippdTo",
        mStockTransfer.mudtProps.to_organic_ind
      );
      setValue(
        thisObj,
        "txtEdiblOilShippdTo",
        await EdibleName(mStockTransfer.mudtProps.to_edible_oil_ind)
      );
      setValue(
        thisObj,
        "txtWhseBinShippdTo",
        await WhouseBin(
          mStockTransfer.mudtProps.rec_whouse,
          mStockTransfer.mudtProps.rec_bin
        )
      );
    }
    mStockTransfer.mbFormFilling = tmpFormFilling;
  };

  async function PeanutTypeName(value) {
    switch (value) {
      case "SP":
        return "Spanish";
      case "RU":
        return "Runner";
      case "VA":
        return "Virginia";
      case "VL":
        return "Valencia";
      default:
        break;
    }
  }

  async function SegmentName(value) {
    switch (value) {
      case "1":
      case "2":
      case "3":
        return "Seg " + value;
      default:
        return " ";
    }
  }

  const EdibleName = async (value) => {
    switch (value) {
      case "E":
        return "Edible";
      case "O":
        return "Oil";
      default:
        return "Not Specified";
    }
  };

  const WhouseBin = async (whse, bin) => {
    if (whse.trim() != "" && bin.trim() != "") {
      return whse + "-" + bin;
    } else {
      return " ";
    }
  };

  // const assignGramValue = (object, propertyName, value) => {
  //   if (value > 0) {
  //     if (propertyName === "CLN_SAMPLE") {
  //       object.cln_sample(value);
  //     } else if (propertyName === "CNCL_RMD_GR") {
  //       object.cncl_rmd_gr(value);
  //     } else if (propertyName === "CR_BR_GR") {
  //       object.cr_br_gr(value);
  //     } else if (propertyName === "DAM_SPLIT_GR") {
  //       object.dam_split_gr(value);
  //     } else if (propertyName === "DCOL_SHEL_GR") {
  //       object.dcol_shel_gr(value);
  //     } else if (propertyName === "ELK_GR") {
  //       object.elk_gr(value);
  //     } else if (propertyName === "FAN_GR") {
  //       object.fan_gr(value);
  //     } else if (propertyName === "FM_SAMPLE") {
  //       object.fm_sample(value);
  //     } else if (propertyName === "FM_GR") {
  //       object.fm_gr(value);
  //     } else if (propertyName === "FREZ_DAM_GR") {
  //       object.frez_dam_gr(value);
  //     } else if (propertyName === "HUL_BRIT_GR") {
  //       object.hul_brit_gr(value);
  //     } else if (propertyName === "JUMBO_GR") {
  //       object.jumbo_gr(value);
  //     } else if (propertyName === "ELK_RIDE_GR") {
  //       object.elk_ride_gr(value);
  //     } else if (propertyName === "RIDE_PSCRIB_GR") {
  //       object.ride_pscrib_gr(value);
  //     } else if (propertyName === "LSK_GR") {
  //       object.lsk_gr(value);
  //     } else if (propertyName === "MOIST_PCT") {
  //       object.moist_pct(value);
  //     } else if (propertyName === "OK_GR") {
  //       object.ok_gr(value);
  //     } else if (propertyName === "PIT_DAM_GR") {
  //       object.pit_dam_gr(value);
  //     } else if (propertyName === "SMK_GR") {
  //       object.smk_gr(value);
  //     } else if (propertyName === "SS_GR") {
  //       object.ss_gr(value);
  //     } else if (propertyName === "DAM_GR") {
  //       object.dam_gr(value);
  //     } else if (propertyName === "ELK_DAM_GR") {
  //       object.elk_dam_gr(value);
  //     } else if (propertyName === "VICAM_PPB") {
  //       object.vicam_ppb(value);
  //     }
  //     return Number(value).toLocaleString();
  //   } else {
  //     return 0;
  //   }
  // };

  // const assignPercentValue = (object, propertyName, value) => {
  //   value = getValue(thisObj, value);
  //   let tempValue = value;
  //   if (isNumeric(value)) {
  //     value = Number(value).toFixed(GRADE_PCT_FORMAT);

  //     if (propertyName === "CNCL_RMD_PCT") {
  //       object.cncl_rmd_pct(value);
  //     } else if (propertyName === "CR_BR_PCT") {
  //       object.cr_br_pct(value);
  //     } else if (propertyName === "DCOL_SHEL_PCT") {
  //       object.dcol_shel_pct(value);
  //     } else if (propertyName === "ELK_PCT") {
  //       object.elk_pct(value);
  //     } else if (propertyName === "FAN_PCT") {
  //       object.fan_pct(value);
  //     } else if (propertyName === "FM_PCT") {
  //       object.fm_pct(value);
  //     } else if (propertyName === "FREZ_DAM_PCT") {
  //       object.frez_dam_pct(value);
  //     } else if (propertyName === "HUL_BRIT_PCT") {
  //       object.hul_brit_pct(value);
  //     } else if (propertyName === "HULL_PCT") {
  //       object.hull_pct(value);
  //     } else if (propertyName === "JUMBO_PCT") {
  //       object.jumbo_pct(value);
  //     } else if (propertyName === "ELK_RIDE_PCT") {
  //       object.elk_ride_pct(value);
  //     } else if (propertyName === "RIDE_PSCRIB_PCT") {
  //       object.ride_pscrib_pct(value);
  //     } else if (propertyName === "LSK_DAM_PCT") {
  //       object.lsk_dam_pct(value);
  //     } else if (propertyName === "LSK_FALL_PCT") {
  //       object.lsk_fall_pct(value);
  //     } else if (propertyName === "LSK_PCT") {
  //       object.lsk_pct(value);
  //     } else if (propertyName === "LSK_RIDE_PCT") {
  //       object.lsk_ride_pct(value);
  //     } else if (propertyName === "OK_RIDE_PCT") {
  //       object.ok_ride_pct(value);
  //     } else if (propertyName === "OK_DAM_PCT") {
  //       object.ok_dam_pct(value);
  //     } else if (propertyName === "OK_FALL_PCT") {
  //       object.ok_fall_pct(value);
  //     } else if (propertyName === "OK_PCT") {
  //       object.ok_pct(value);
  //     } else if (propertyName === "PIT_DAM_PCT") {
  //       object.pit_dam_pct(value);
  //     } else if (propertyName === "SMK_1_PCT") {
  //       object.smk_1_pct(value);
  //     } else if (propertyName === "SMK_2_PCT") {
  //       object.smk_2_pct(value);
  //     } else if (propertyName === "SMK_3_PCT") {
  //       object.smk_3_pct(value);
  //     } else if (propertyName === "SMK_4_PCT") {
  //       object.smk_4_pct(value);
  //     } else if (propertyName === "SMK_PCT") {
  //       object.smk_pct(value);
  //     } else if (propertyName === "SS_PCT") {
  //       object.ss_pct(value);
  //     } else if (propertyName === "DAM_PCT") {
  //       object.dam_pct(value);
  //     } else if (propertyName === "ELK_DAM_PCT") {
  //       object.elk_dam_pct(value);
  //     }

  //     return Number(Number(tempValue).toFixed(1)).toFixed(GRADE_PCT_FORMAT);
  //   } else {
  //     return 0;
  //   }
  // };

  const assignGramValue = (object, propertyName, value) => {
    let input = ExcelNumericDataConvert(getValue(thisObj, value), [',', '$']);
    if (isNumeric(input)) {
      object[propertyName](input);
      return FormatNumber(Number(input), GRADE_GRAM_FORMAT);
    }
    else {
      object[propertyName](0);
      return "";
    }
  }

  const assignPercentValue = (object, propertyName, value) => {
    let input = ExcelNumericDataConvert(getValue(thisObj, value), [',', '$']);
    if (isNumeric(input)) {
      object[propertyName](input);
      return FormatNumber(Number(input), GRADE_PCT_FORMAT);
    }
    else {
      object[propertyName](0);
      return "";
    }
  }

  const assignNumericValue = (object, propertyName, value) => {
    let input = ExcelNumericDataConvert(getValue(thisObj, value), [',', '$']);
    if (isNumeric(input)) {
      object[propertyName](input);
      return FormatNumber(Number(input), G_WEIGHT_FORMAT);
    } else {
      object[propertyName](0);
      return "";
    }
  };

  function StrConv(str) {
    return str?.toLowerCase().replace(/\b\w/g, function (char) {
      return char?.toUpperCase();
    });
  }

  function isNumeric(value) {
    // Use the isNaN function to check if the value is a valid number
    // Also, use the typeof operator to ensure the value is not a non-numeric string
    return (
      !isNaN(parseFloat(value)) && isFinite(value) && typeof value !== "boolean"
    );
  }

  const onchkbxCornFndChange = async () => {
    try {
      setLoading(true)
      if (mStockTransfer.mbFormFilling) {
        setLoading(false)
        return false;
      }
      let valueCornFnd = getValue(thisObj, "chkbxCornFnd");
      await mStockTransfer.corn_ind(valueCornFnd === true ? "Y" : "N");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.onchkbxCornFndChange = onchkbxCornFndChange;

  const mStockTransfer_TransferStatusChanged = async () => {
    if (mStockTransfer.mbFormFilling) {
      return false;
    }
    await SetupForm();

    let mSourceBin = new clsBin();
    let strInspectNum = "";
    let strSettleNum = "";

    if (
      mStockTransfer.mudtProps.manual_grade_ind !== "Y" &&
      mStockTransfer.mudtProps.transfer_type === "ST" &&
      mStockTransfer.mNewStatus === TransferStatus.indexOf("Received") &&
      mStockTransfer.mudtProps.shp_bin !== ""
    ) {
      strInspectNum = "";
      strSettleNum = "";

      if (mStockTransfer.mudtProps.override_source === "") {
        let txt1007 = getValue(thisObj, "txt1007");
        if (
          mStockTransfer.mudtProps.grade_source === "SETTLE 1007" ||
          (txt1007 !== "" && txt1007 > 0)
        ) {
          strSettleNum = txt1007;
        } else {
          if (
            mStockTransfer.mudtProps.grade_source !== "INVENTORY" &&
            mStockTransfer.mudtProps.grade_source !== ""
          ) {
            strInspectNum = mStockTransfer.mudtProps.grade_source;
          }
        }
      } else {
        strInspectNum = mStockTransfer.mudtProps.override_source;
      }

      await mSourceBin.load(
        mStockTransfer.mudtProps.shp_buy_pt,
        mStockTransfer.mudtProps.shp_whouse,
        mStockTransfer.mudtProps.shp_bin,
        mStockTransfer.mudtProps.transfer_num,
        strInspectNum,
        strSettleNum
      );

      if (
        mStockTransfer.mudtProps.override_source === "" &&
        getValue(thisObj, "txt1007") !== "" &&
        mSourceBin.mudtProps.GRADE_SOURCE !== "SETTLED 1007"
      ) {
        alert(
          "The 1007 Settlement # " +
          getValue(thisObj, "txt1007") +
          " was not found in the system. Verfiy and correct 1007 number."
        );
      }

      if (
        mStockTransfer.mudtProps.xfer_status === "R" ||
        mStockTransfer.mNewStatus === TransferStatus.indexOf("Received")
      ) {
        if (await mSourceBin.HasGrades() === false) {
          const inputVal = window.confirm(
            "Do you want to key in an Override Grade Source Inspection number?"
          );
          if (inputVal === true) {
            onbtnOverRideClick();
            strInspectNum = mStockTransfer.mudtProps.override_source;
            strSettleNum = "";
            mSourceBin.load(
              mStockTransfer.mudtProps.shp_buy_pt,
              mStockTransfer.mudtProps.shp_whouse,
              mStockTransfer.mudtProps.shp_bin,
              mStockTransfer.mudtProps.transfer_num,
              strInspectNum,
              strSettleNum
            );
          }
          if (await mSourceBin.HasGrades() === false) {
            alert(
              "There is no grade data for this bin! Please, use Manual Grade mode"
            );
            setValue(thisObj, "chkbxManualGrd", true);
          } else {
            await mStockTransfer.SetShippingBinInfo(
              mSourceBin,
              await CurrentUser.GetPeanutType(mStockTransfer.mudtProps.pnut_type_id),
              true
            );
            setEventTrigger(true)
            await FillGradeInfo();
          }
        } else {
          await mStockTransfer.SetShippingBinInfo(
            mSourceBin,
            await CurrentUser.GetPeanutType(mStockTransfer.mudtProps.pnut_type_id),
            true
          );
          setEventTrigger(true)
          await FillGradeInfo();
        }
      }
    }
  };

  const onbtnOverRideClick = async () => {
    let strOverrideSource = prompt("Enter the Inspection Number for the Source Grades.");
    await mStockTransfer.override_source(strOverrideSource);
    await mStockTransfer.Dirty(true)
    await mStockTransfer_TransferStatusChanged()

    if (strOverrideSource !== mStockTransfer.mudtProps.grade_source) {
      let msgOverride = "";
      if ([null, undefined].includes(strOverrideSource)) {
        msgOverride = "No uploaded Regrade Inspection was found for SC95#"
      }
      else {
        msgOverride = "No uploaded Regrade Inspection was found for SC95# " + strOverrideSource
      }
      alert(msgOverride);
    }
  };
  thisObj.onbtnOverRideClick = onbtnOverRideClick;

  const mStockTransfer_WeightChanged = async () => {
    setValue(thisObj, "txtPeanutsShip", FormatNumber(mStockTransfer.mudtProps.shp_peanut_wt, 0));
    setValue(thisObj, "txtPeanutsRecvd", FormatNumber(mStockTransfer.mudtProps.rec_peanut_wt, 0));

    if (mStockTransfer.mudtProps.transfer_type === "FF") {
      setValue(thisObj, "txtWtVehicleShip", getValue(thisObj, "txtWtInclVehclRecvd"));
      mStockTransfer.shp_tare_wt(mStockTransfer.mudtProps.rec_tare_wt);
      mStockTransfer.shp_vehicle_wt(mStockTransfer.mudtProps.rec_vehicle_wt);
      setValue(thisObj, "txtPeanutsShip", FormatNumber(mStockTransfer.mudtProps.rec_peanut_wt, 0));
    }
    await ShowWeightVariance();
    await fillSettlementInfo();
  };

  const mStockTransfer_VendorChanged = async (VendorInfo) => {
    setValue(thisObj, "txtCarrAbbr", mStockTransfer.mudtProps.frt_scac_id);
    setValue(thisObj, "txtVndrId", mStockTransfer.mudtProps.frt_vendor);
    setValue(thisObj, "txtarCarrAbbr", VendorInfo);
    if (mStockTransfer.mudtProps.frt_vendor === "") {
      alert("Vendor not found or is inactive!");
    }
  };

  const mStockTransfer_TransferTypeChanged = async () => {
    await ClearBinInfo(true);
    await ClearPeanutInfo();
    await FillBinData();
  };

  const mStockTransfer_ShippingBinChanged = async () => {
    if (mStockTransfer.mbFormFilling) {
      return false;
    }
    await SetupGradeEntryMode();
  };

  const mStockTransfer_PropertyChanged = async () => {
    if (mStockTransfer.mbFormFilling) {
      return false;
    }
    await setupButtons();
    await UpdateGradeInfo();
  };

  const mStockTransfer_PeanutValueChanged = async () => {
    if (mStockTransfer.mbFormFilling) {
      return false;
    }
    await fillSettlementInfo();
  };

  const mStockTransfer_ManualGradeStatusChanged = async (ManualGradeMode) => {
    if (mStockTransfer.mbFormFilling) {
      return false;
    }
    await SetupGradeEntryMode();
  };

  const mStockTransfer_FreightAccrualStatusChanged = async (
    FreighAccrualCompleted
  ) => {
    if (mStockTransfer.mbFormFilling) {
      return false;
    }
    await SetupGradeEntryMode();
    await SetupForm();
    await FillFreightAmount();
  };

  //----------------methods from modMain file end-----------------

  //----------------methods from clsTransfer file start-----------------

  //-----------------------------------------------------
  // 	List of tags
  //   SL - Shipping Location
  //   SD - Shipped Date
  //   RL - receiving Location
  //   LI - Load Info
  //   SI - Shipped Info
  //   RI - Received Info
  //   FA - Freight Accrual
  //   BI - Bin Info
  //   FF - Farm Freight
  //   GG - Grade  Grams
  //   GP - Grade Percent
  //   GI - Grade Info (for both Gram & Percent)
  //   FL - from Location combo
  //   DL - Destination Location Combo
  //   CA - Carrier Abbreviation
  //   DN - Dispatch number
  //   RM - Remarks
  //------------------------------------------------------

  const Controls = [
    { control: "btnFrmBin", controlType: "Button", tag: "SL" },
    { control: "txtSclTcktShip", controlType: "TextBox", tag: "SL" },
    { control: "chkbxSplitWtShip", controlType: "CheckBox", tag: "SL" },
    { control: "txtWtVehicleShip", controlType: "TextBox", tag: "SL" },
    { control: "txtWtVehclShip", controlType: "TextBox", tag: "SL" },
    { control: "dtShipDate", controlType: "DatePicker", tag: "SD" },
    { control: "dtRcvdDate", controlType: "DatePicker", tag: "RL" },
    { control: "txtWtVhclRcvd", controlType: "TextBox", tag: "RL" },
    { control: "txtWtInclVehclRecvd", controlType: "TextBox", tag: "RL" },
    { control: "txtSclTcktRcvd", controlType: "TextBox", tag: "RL" },
    { control: "chkbxSplitWtRcvd", controlType: "CheckBox", tag: "RL" },
    { control: "ddUpdtInvent", controlType: "Dropdown", tag: "RL" },
    { control: "txtUpdtInvent", controlType: "TextBox", tag: "RL" },
    { control: "btnToBin", controlType: "Button", tag: "RL" },
    { control: "txtOtherWt", controlType: "TextBox", tag: "FA" },
    { control: "ddPayFreight", controlType: "Dropdown", tag: "FA" },
    { control: "ddState", controlType: "Dropdown", tag: "FF" },
    { control: "ddCountry", controlType: "Dropdown", tag: "FF" },
    { control: "ddFarm", controlType: "Dropdown", tag: "FF" },
    { control: "txtTotalELKDamage", controlType: "TextBox", tag: "GG" },
    { control: "txtKernRidingELK", controlType: "TextBox", tag: "GG" },
    { control: "txtFancy", controlType: "TextBox", tag: "GG" },
    { control: "txtPittingDamage", controlType: "TextBox", tag: "GG" },
    { control: "txtTotalSLK", controlType: "TextBox", tag: "GG" },
    { control: "txtForeignMtrl", controlType: "TextBox", tag: "GG" },
    { control: "txtNetELK", controlType: "TextBox", tag: "GG" },
    { control: "txtJumbo", controlType: "TextBox", tag: "GG" },
    { control: "txtHullBrightness", controlType: "TextBox", tag: "GG" },
    { control: "txtDiscoloredShells", controlType: "TextBox", tag: "GG" },
    { control: "txtCrackedBroken", controlType: "TextBox", tag: "GG" },
    { control: "txtConcealedRMD", controlType: "TextBox", tag: "GG" },
    { control: "txtFreezeDamage", controlType: "TextBox", tag: "GG" },
    { control: "txtHulls", controlType: "TextBox", tag: "GG" },
    { control: "txtTotalDamage", controlType: "TextBox", tag: "GG" },
    { control: "txtTotalOK", controlType: "TextBox", tag: "GG" },
    { control: "txtSoundSplits", controlType: "TextBox", tag: "GG" },
    { control: "txtTotalSMKRScreen", controlType: "TextBox", tag: "GG" },
    { control: "txtTotalELKDamagePct", controlType: "TextBox", tag: "GP" },
    { control: "lblKernRidingELKPct", controlType: "TextBox", tag: "GP" },
    { control: "txtFancyPct", controlType: "TextBox", tag: "GP" },
    { control: "txtPittingDamagePct", controlType: "TextBox", tag: "GP" },
    { control: "txtTotalSLKPct", controlType: "TextBox", tag: "GP" },
    { control: "txtForeignMaterialPct", controlType: "TextBox", tag: "GP" },
    { control: "txtNetELKPct", controlType: "TextBox", tag: "GP" },
    { control: "txtJumboGrPct", controlType: "TextBox", tag: "GP" },
    { control: "txtHullBrightnessPct", controlType: "TextBox", tag: "GP" },
    { control: "txtDiscoloredShellsPct", controlType: "TextBox", tag: "GP" },
    { control: "txtCrackedBrokenPct", controlType: "TextBox", tag: "GP" },
    { control: "txtConcealedRMDPct", controlType: "TextBox", tag: "GP" },
    { control: "txtFreezeDamagePct", controlType: "TextBox", tag: "GP" },
    { control: "txtHullsPct", controlType: "TextBox", tag: "GP" },
    { control: "txtTotalDamagePct", controlType: "TextBox", tag: "GP" },
    { control: "txtTotalOKPct", controlType: "TextBox", tag: "GP" },
    { control: "txtSoundSplitsPct", controlType: "TextBox", tag: "GP" },
    { control: "txtTotalSMKRScreenPct", controlType: "TextBox", tag: "GP" },
    { control: "txtFmSample", controlType: "TextBox", tag: "GI" },
    { control: "txtVicamReading", controlType: "TextBox", tag: "GI" },
    { control: "txtKernRidingPrescribe", controlType: "TextBox", tag: "GI" },
    { control: "txtKernRidingPrescribePct", controlType: "TextBox", tag: "GI" },
    { control: "chkboxVicam", controlType: "CheckBox", tag: "GI" },
    { control: "txtLSKDamageRiding2", controlType: "TextBox", tag: "GI" },
    { control: "txtLSKFallThru2", controlType: "TextBox", tag: "GI" },
    { control: "txtLSKRidingPct", controlType: "TextBox", tag: "GI" },
    { control: "txtLSKRidingGr", controlType: "TextBox", tag: "GI" },
    { control: "txtOKDamageRiding2", controlType: "TextBox", tag: "GI" },
    { control: "txtOKFallThru2", controlType: "TextBox", tag: "GI" },
    { control: "txtOKRidingPct", controlType: "TextBox", tag: "GI" },
    { control: "txtOKRidingGr", controlType: "TextBox", tag: "GI" },
    { control: "txtClnSmplWt", controlType: "TextBox", tag: "GI" },
    { control: "chkboxFlavus", controlType: "CheckBox", tag: "GI" },
    { control: "txtMoisture2", controlType: "TextBox", tag: "GI" },
    { control: "txtOtherFM", controlType: "TextBox", tag: "GI" },
    { control: "txtDamageSplits", controlType: "TextBox", tag: "GI" },
    { control: "txtSMK4Pct", controlType: "TextBox", tag: "GI" },
    { control: "txtSMK3Pct", controlType: "TextBox", tag: "GI" },
    { control: "txtSMK2Pct", controlType: "TextBox", tag: "GI" },
    { control: "txtSMK4Gr", controlType: "TextBox", tag: "GI" },
    { control: "txtSMK3Gr", controlType: "TextBox", tag: "GI" },
    { control: "txtSMK2Gr", controlType: "TextBox", tag: "GI" },
    { control: "txtSMK1Pct", controlType: "TextBox", tag: "GI" },
    { control: "txtSMK1Gr", controlType: "TextBox", tag: "GI" },
    { control: "txtTotalKernels2", controlType: "Label", tag: "GI" },
    { control: "txtTotalSMK2", controlType: "Label", tag: "GI" },
    { control: "ddShippingLoc", controlType: "Dropdown", tag: "FL" },
    { control: "ddDestinationLoc", controlType: "Dropdown", tag: "DL" },
    { control: "btnOverRide", controlType: "Button", tag: "CA" },
    { control: "txtCarrAbbr", controlType: "TextBox", tag: "CA" },
    { control: "btnPopup", controlType: "Button", tag: "CA" },
    { control: "chkbxCornFnd", controlType: "CheckBox", tag: "DN" },
    { control: "txt1007", controlType: "TextBox", tag: "DN" },
    { control: "txtDispatch", controlType: "TextBox", tag: "DN" },
    { control: "txxtDriver", controlType: "TextBox", tag: "DN" },
    { control: "txtTruckLine", controlType: "TextBox", tag: "DN" },
    { control: "txtVehicle", controlType: "TextBox", tag: "DN" },
    { control: "txtarRemarks", controlType: "TextBox", tag: "RM" },
    { control: "txtInvoice", controlType: "TextBox", tag: "IA" },
    { control: "txtMileageOvrrd", controlType: "TextBox", tag: "MA" },
  ];

  const SetupControl = async (control, controlType, tag, userRole) => {
    if (
      control == "" ||
      String("Button,CheckBox,DatePicker,Dropdown,TextBox")
        .toUpperCase()
        .indexOf(String(controlType).toUpperCase()) == -1
    ) {
      return;
    }
    if (await EnableTagList("GI,GG,GP", tag)) {
      return; //No Grade controls allowed
    }
    let tStatus,
      tType,
      tPermission,
      tManualMode,
      tFreightAccrualComplete,
      tShipperReceivedException,
      mEnabled;

    //Step 1  Find group enabled status based on Transfer Type
    switch (mStockTransfer.mudtProps.transfer_type) {
      case "ST":
        tType = await EnableTagList(
          "SL,FL,SD,DL,RL,ST,GI,GP,GG,FA,CA,DN,MA,RM",
          tag
        );
        break;
      case "FF":
        tType = await EnableTagList(
          "RL,FL,ST,FF,ST,GI,GP,GG,FA,CA,DN,MA,RM",
          tag
        );
        break;
      case "NL":
        tType = await EnableTagList("SD,FL,DL,ST,GI,GP,GG,FA,CA,DN,MA,RM", tag);
        break;
    }

    //Step 2  Find group enabled status based on Transfer Status
    switch (
    await mStockTransfer.GetTransferStatusID(mStockTransfer.mNewStatus)
    ) {
      case "P":
        tStatus = await EnableTagList(
          "SD,FL,SL,DL,RL,GI,GP,GG,FF,FA,CA,DN,MA,RM",
          tag
        );
        break;
      case "S":
        tStatus = await EnableTagList(
          "SL,SD,RL,DL,FF,GI,GP,GG,FA,CA,DN,MA,RM",
          tag
        );
        break;
      case "R":
        tStatus = await EnableTagList(
          "SL,SD,RL,DL,GI,GP,FF,GG,FA,CA,DN,MA,RM",
          tag
        );
        break;
      case "V":
        tStatus = await EnableTagList("", tag);
        break;
    }

    //Step 3  Find group enabled status based on User Role
    switch (userRole) {
      case UserTransferRoleList.indexOf("Shipper"):
        tPermission = await EnableTagList(
          "SD,FL,SL,DL,ST,ST,GI,GP,GG,CA,DN,RM",
          tag
        );
        break;
      case UserTransferRoleList.indexOf("Receiver"):
        tPermission = await EnableTagList(
          "SD,SL,FL,FA,DL,RL,ST,FF,ST,GI,GP,GG,CA,DN,RM",
          tag
        );
        break;
      case UserTransferRoleList.indexOf("Freighter"):
        tPermission = await EnableTagList(
          "SD,SL,DL,FL,RL,ST,FF,ST,GI,GP,GG,FA,CA,DN,MA,RM",
          tag
        );
        break;
    }

    //Step 5 Find group enabled status bases on Freight Accrual Complete Flag
    if (mStockTransfer.mudtProps.frt_accrl_complete == "Y") {
      //If Freight Accrual completed then lock everything
      tFreightAccrualComplete = await EnableTagList("DN, RM, FF", tag);
    } else {
      tFreightAccrualComplete = await EnableTagList(
        "SL,FL,SD,DL,RL,ST,GI,GP,GG,FA,FF,CA,DN,MA,RM",
        tag
      );
    }

    //Step 6 Shipper Received exception - Shiipe in Received status has read only access
    tShipperReceivedException = !(
      mStockTransfer.mudtProps.xfer_status == "R" &&
      userRole == UserTransferRoleList.indexOf("Shipper")
    );

    mEnabled =
      tStatus *
      tType *
      tPermission *
      tFreightAccrualComplete *
      tShipperReceivedException;

    //bColor = IIf(mEnabled, G_CONTROL_ENABLED_BACK_COLOR, G_CONTROL_DISABLED_BACK_COLOR)

    // Select Case TypeName(ctrl)
    // Case "ComboBox", "TextBox", "MaskEdBox"
    //     ctrl.enabled = mEnabled
    //     ctrl.BackColor = bColor
    // Case "CommandButton", "CheckBox"
    //     ctrl.enabled = mEnabled
    // Case "DTPicker"
    //     ctrl.enabled = mEnabled
    // Case Else
    //     MsgBox TypeName(ctrl)
    // End Select

    if (mEnabled == true) {
      if (!isEnabled(thisObj, control)) {
        enable(thisObj, control);
        // enableWidgets(thisObj,control,true)
      }
    } else {
      if (isEnabled(thisObj, control)) {
        disable(thisObj, control);
        // enableWidgets(thisObj,control,false)
      }
    }

    //enableDisableForBinControls()
  };

  const EnableTagList = async (TagList, Tag) => {
    return String(TagList).toUpperCase().indexOf(String(Tag).toUpperCase()) >= 0;
  };

  const enableDisableForBinControls = async () => {
    try {
      if (mStockTransfer.mudtProps.xfer_status == "V") {
        disable(thisObj, "txtPnutShippdFrm")
        disable(thisObj, "ddVarietyShppdFrm")
        disable(thisObj, "ddGenerationShippdFrm")
        disable(thisObj, "ddOleicShippdFrm")
        disable(thisObj, "ddVarietyShppdTo")
        disable(thisObj, "ddGenerationShippdTo")
        disable(thisObj, "ddOleicShippdTo")
        return
      }

      if (mStockTransfer.mudtProps.transfer_type == "FF" || mStockTransfer.mudtProps.transfer_type == "NL") {
        enable(thisObj, "txtPnutShippdFrm")
      }
      else {
        disable(thisObj, "txtPnutShippdFrm")
      }

      if (mStockTransfer.mudtProps.shp_bin != "" && mStockTransfer.mudtProps.transfer_type == "ST") {
        enable(thisObj, "ddVarietyShppdFrm")
        enable(thisObj, "ddGenerationShippdFrm")
        enable(thisObj, "ddOleicShippdFrm")
      }
      else {
        disable(thisObj, "ddVarietyShppdFrm")
        disable(thisObj, "ddGenerationShippdFrm")
        disable(thisObj, "ddOleicShippdFrm")
      }

      if (mStockTransfer.mudtProps.rec_bin != "" && mStockTransfer.mudtProps.transfer_type == "ST") {
        enable(thisObj, "ddVarietyShppdTo")
        enable(thisObj, "ddGenerationShippdTo")
        enable(thisObj, "ddOleicShippdTo")
      }
      else {
        disable(thisObj, "ddVarietyShppdTo")
        disable(thisObj, "ddGenerationShippdTo")
        disable(thisObj, "ddOleicShippdTo")
      }

    } catch (error) {
      errorHandler(error)
    }
  }

  //----------------methods from clsTransfer file end-------------------

  //---------------Change event for Textboxes Starts-----------

  const onddShippingLocChange = async () => {
    try {
      setLoading(true)
      if (
        getValue(thisObj, "ddShippingLoc") != mStockTransfer.mudtProps.shp_buy_pt
      ) {
        let data = await mStockTransfer.ShippingLocationList.filter(
          (rows) => getValue(thisObj, "ddShippingLoc") == rows.buy_pt_id
        );
        await mStockTransfer.SetShippingPointInfo(data[0]);
        await mStockTransfer.state_abbr("");
        await FillStates();
        await mStockTransfer.county_id("");
        await FillCounties(false);
        await mStockTransfer.farm_id("");
        await mStockTransfer.farm_suffix("");
        await FillFarms();
      }

      if (mStockTransfer.mudtProps.transfer_type == "FF") {
        await mStockTransfer.CheckTransferAccessLevel(
          mStockTransfer.shp_buy_pt,
          mStockTransfer.shp_buy_pt,
          mStockTransfer.transfer_type
        );
        if (
          mStockTransfer.transferRole == UserTransferRoleList.indexOf("Shipper")
        ) {
          alert("You don't have permissions to do Farm Transfer!");
          mStockTransfer.mbFormFilling = true;
          setValue(thisObj, "ddType", "0");
          setLoading(false)
          return;
        }
      } else {
        await mStockTransfer.CheckTransferAccessLevel(
          mStockTransfer.mudtProps.shp_buy_pt,
          mStockTransfer.mudtProps.rec_buy_pt,
          mStockTransfer.mudtProps.transfer_type
        );
      }
      await SetupForm();
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.onddShippingLocChange = onddShippingLocChange;

  const onddDestinationLocChange = async () => {
    try {
      setLoading(true)
      let data = await mStockTransfer.DestinationLocationList.filter(
        (rows) => getValue(thisObj, "ddDestinationLoc") == rows.buy_pt_id
      );
      await mStockTransfer.SetReceivingPointInfo(data[0]);
      setEventTrigger(true)
      if (await mStockTransfer.ReceivingPointChanged()) {
        await ClearBinInfo();
      }
      await mStockTransfer.CheckTransferAccessLevel(
        mStockTransfer.mudtProps.shp_buy_pt,
        mStockTransfer.mudtProps.rec_buy_pt,
        mStockTransfer.mudtProps.transfer_type
      );
      await SetupForm();
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.onddDestinationLocChange = onddDestinationLocChange;

  const mStockTransfer_BuyingPointChanged = async (
    BuingPointID,
    IsShippingPoint
  ) => {
    if (mStockTransfer.mbFormFilling == true) {
      return;
    }
    await mStockTransfer.CheckTransferAccessLevel(
      mStockTransfer.mudtProps.shp_buy_pt,
      mStockTransfer.mudtProps.rec_buy_pt,
      mStockTransfer.mudtProps.transfer_type
    );
    if (IsShippingPoint) {
      let data = await mStockTransfer.ShippingLocationList.filter(
        (rows) => BuingPointID == rows.buy_pt_id
      );
      setValue(thisObj, "txtAddShip", capitalize(data[0].physical_city) + ", " + data[0].physical_state);
      setValue(thisObj, "txtPhnShip", data[0].buy_pt_phone);
      mStockTransfer.mbAutoNumber = data[0].xfer_auto_num_ind == "Y" ? true : false;
      switch (mStockTransfer.mudtProps.transfer_type) {
        case "ST":
        case "NL":
          await FillDestinationPoint("");
          break;
        case "FF":
          mStockTransfer.DestinationLocationList = {};
          let js = [
            {
              key: data[0].buy_pt_id,
              description: data[0].buy_pt_id.trim() + " - " + data[0].buy_pt_name.trim(),
            },
          ];

          mStockTransfer.DestinationLocationList = data;

          if (mStockTransfer.DestinationLocationList != null) {
            await mStockTransfer.SetReceivingPointInfo(data[0]);
            setEventTrigger(true)
          }
          mStockTransfer.rec_buy_pt(mStockTransfer.mudtProps.shp_buy_pt);

          thisObj.setState((current) => {
            return {
              ...current,
              ddDestinationLoc: {
                ...state["ddDestinationLoc"],
                valueList: js,
              },
            };
          });
          setValue(thisObj, "ddDestinationLoc", js[0].key);
          setValue(
            thisObj,
            "txtAddDest",
            capitalize(data[0].physical_city) + ", " + data[0].physical_state
          );
          setValue(thisObj, "txtPhnDest", data[0].buy_pt_phone);

          if (mStockTransfer.mudtProps.county_id != "") {
            mStockTransfer.county_id("");
            if (mStockTransfer.CountyList != null) {
              await FillCounties(true);
            }
          }
          mStockTransfer.farm_id("");
          mStockTransfer.farm_suffix("");
          mStockTransfer.FarmList = {};
          thisObj.setState((current) => {
            return {
              ...current,
              ddFarm: {
                ...state["ddFarm"],
                valueList: [],
              },
            };
          });
      }
    } else {
      if (mStockTransfer.mudtProps.transfer_type == "FF") {
        let data = await mStockTransfer.ShippingLocationList.filter(
          (rows) => BuingPointID == rows.buy_pt_id
        );
        setValue(thisObj, "txtAddDest", capitalize(data[0].physical_city) + ", " + data[0].physical_state);
        setValue(thisObj, "txtPhnDest", data[0].buy_pt_phone);
      } else {
        let destination = await mStockTransfer.DestinationLocationList.filter(
          (rows) => BuingPointID == rows.buy_pt_id
        );
        setValue(thisObj, "txtAddDest", capitalize(destination[0].xfer_buy_pt_physical_city) + ", " + destination[0].xfer_buy_pt_physical_state);
        setValue(thisObj, "txtPhnDest", destination[0].xfer_buy_pt_phone);
      }
    }
    await ClearBinInfo(IsShippingPoint);
  };

  //peanut type dropdown change
  const ontxtPnutShippdFrmChange = async () => { // new function , based on vsfBin_AfterEdit
    try {
      setLoading(true)
      let value = getValue(thisObj, "txtPnutShippdFrm");
      mStockTransfer.pnut_type_id(value);
      await bFillPeanutVariety(mStockTransfer.mudtProps.shp_buy_pt, value, true);
      await bFillPeanutVariety(mStockTransfer.mudtProps.rec_buy_pt, value, false);
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  }
  thisObj.ontxtPnutShippdFrmChange = ontxtPnutShippdFrmChange;

  //peanut variety dropdown change 
  const onddVarietyShppdFrmChange = async () => { // new function , based on vsfBin_AfterEdit
    try {
      setLoading(true)
      let value = getValue(thisObj, "ddVarietyShppdFrm");
      await mStockTransfer.from_pnut_variety_id(value);

      let peanutVarietyList = thisObj.state.ddVarietyShppdFrm.valueList;
      let varietyName = "";
      let oleic_for_newVariety = "Not Specified";
      if (value !== undefined && value !== null && peanutVarietyList !== undefined && peanutVarietyList.length > 0) {
        varietyName = peanutVarietyList.find(elem => elem.key == value)?.description;
        oleic_for_newVariety = peanutVarietyList.find(elem => elem.key == value)?.oleic_ind;
      }
      mStockTransfer.from_pnut_variety_name(varietyName);
      mStockTransfer.from_oleic_ind(oleic_for_newVariety);
      setValue(thisObj, "ddOleicShippdFrm", oleic_for_newVariety);
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  }
  thisObj.onddVarietyShppdFrmChange = onddVarietyShppdFrmChange;

  const onddVarietyShppdToChange = async () => { // new function , based on vsfBin_AfterEdit
    try {
      setLoading(true)
      let value = getValue(thisObj, "ddVarietyShppdTo")
      await mStockTransfer.to_pnut_variety_id(value)

      let peanutVarietyList = thisObj.state.ddVarietyShppdTo.valueList
      let varietyName = "";
      let oleic_for_newVariety = "Not Specified";
      if (value !== undefined && value !== null && peanutVarietyList !== undefined && peanutVarietyList.length > 0) {
        varietyName = peanutVarietyList.find(elem => elem.key == value).description;
        oleic_for_newVariety = peanutVarietyList.find(elem => elem.key == value).oleic_ind;
      }
      mStockTransfer.to_pnut_variety_name(varietyName)
      mStockTransfer.to_oleic_ind(oleic_for_newVariety)
      setValue(thisObj, "ddOleicShippdTo", oleic_for_newVariety)
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  }
  thisObj.onddVarietyShppdToChange = onddVarietyShppdToChange;

  //seed gen dropdown change
  const onddGenerationShippdFrmChange = async () => {  // new function , based on vsfBin_AfterEdit
    try {
      setLoading(true)
      let value = getValue(thisObj, "ddGenerationShippdFrm")
      await mStockTransfer.from_seed_gen(value)
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  }
  thisObj.onddGenerationShippdFrmChange = onddGenerationShippdFrmChange;

  const onddGenerationShippdToChange = async () => {  // new function , based on vsfBin_AfterEdit
    try {
      setLoading(true)
      let value = getValue(thisObj, "ddGenerationShippdTo")
      await mStockTransfer.to_seed_gen(value)
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  }
  thisObj.onddGenerationShippdToChange = onddGenerationShippdToChange;

  //Oleic Dropdown change
  const onddOleicShippdFrmChange = async () => {  // new function , based on vsfBin_AfterEdit
    try {
      setLoading(true)
      let value = getValue(thisObj, "ddOleicShippdFrm")
      await mStockTransfer.from_oleic_ind(value)
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  }
  thisObj.onddOleicShippdFrmChange = onddOleicShippdFrmChange;

  const onddOleicShippdToChange = async () => {  // new function , based on vsfBin_AfterEdit
    try {
      setLoading(true)
      let value = getValue(thisObj, "ddOleicShippdTo")
      await mStockTransfer.to_oleic_ind(value)
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  }
  thisObj.onddOleicShippdToChange = onddOleicShippdToChange;

  let textboxChange_DigitsDecimal = [
    "txtClnSmplWt",
    "txtConcealedRMD",
    "txtConcealedRMDPct",
    "txtCrackedBroken",
    "txtCrackedBrokenPct",
    "txtDamageSplits",
    "txtDiscoloredShells",
    "txtDiscoloredShellsPct",
    "txtNetELK",
    "txtNetELKPct",
    "txtFancy",
    "txtFancyPct",
    "txtFmSample",
    "txtForeignMtrl",
    "txtForeignMaterialPct",
    "txtFreezeDamage",
    "txtFreezeDamagePct",
    "txtHullBrightness",
    "txtHullBrightnessPct",
    "txtHulls",
    "txtHullsPct",
    "txtJumbo",
    "txtJumboGrPct",
    "txtKernRidingELK",
    "lblKernRidingELKPct",
    "txtKernRidingPrescribe",
    "txtKernRidingPrescribePct",
    "txtLSKDamageRiding2",
    "txtLSKFallThru2",
    "txtTotalSLK",
    "txtTotalSLKPct",
    "txtLSKRidingPct",
    "txtOKRidingPct",
    "txtOKDamageRiding2",
    "txtOKFallThru2",
    "txtTotalOK",
    "txtPittingDamage",
    "txtPittingDamagePct",
    "txtSMK1Pct",
    "txtSMK2Pct",
    "txtSMK3Pct",
    "txtSMK4Pct",
    "txtTotalSMKRScreen",
    "txtTotalSMKRScreenPct",
    "txtSoundSplits",
    "txtSoundSplitsPct",
    "txtTotalDamage",
    "txtTotalDamagePct",
    "txtTotalELKDamage",
    "txtTotalELKDamagePct",
    "txtVicamReading",
  ];

  //their old app names = [txtCleanSampleWt, txtConcealedRMDGr,  txtConcealedRMDPct,  txtCrkOrBrkShellsGr, txtCrkOrBrkShellsPct, txtDamageSplits,  txtDiscoloredShellsGr,
  // txtDiscoloredShellsPct,  txtELKGr, txtELKPct,  txtFancyGr,  txtFancyPct,  txtFMSampleWt,  txtForeignMaterialGr, txtForeignMaterialPct, txtFreezeDamageGr,  txtFreezeDamagePct,
  // txtHullBrightnessGr,  txtHullBrightnessPct,  txtHullsGr, txtHullsPct,  txtJumboGr, txtJumboPct, txtKernRidElkGr,  txtKernRidElkPct,  txtKernRidPrescGr,  txtKernRidPresPct,
  // txtLskDamageReadingPct, txtLSKFallThroughPct,  txtLSKGr, txtLSKPct,  txtLSKRidingPct, txtOKRidingPct, txtOtherKernalsDamagePct,  txtOtherKernalsFallThroughPct,  txtOtherKernelsGr,
  // txtPittingDamageGr,  txtPittingDamagePct,  txtSMk1Pct,txtSMk2Pct,txtSMk3Pct,txtSMK4Pct,txtSMKRSGr, txtSMKRSPct,  txtSoundSplitsGr,  txtSoundSplitsPct, txtTotalDamageGr,
  // txtTotalDamagePct,  txtTotalElkDamGr,  txtTotalElkDamPct,  txtVicamReading ]

  let textboxChange_Digitsonly = [
    "txtMileageOvrrd",
    "txtOtherWt",
    "txtLSKRidingGr",
    "txtOKRidingGr",
    "txtUpdtInvent",
    "txtWtInclVehclRecvd",
    "txtWtVhclRcvd",
    "txtWtVehicleShip",
    "txtWtVehclShip",
    "txtSMK1Gr",
    "txtSMK2Gr",
    "txtSMK3Gr",
  ];

  // their old names = [txtFreightAccrualMileageOverride, txtFreightAccrualOtherWeight, txtLSKRidingScreenSize, txtOKRidingScreenSize, txtOtherWeight, txtReceivedGrossWeight, txtReceivedTareWeight, txtShippedGrossWeight, txtShippedTareWeight, txtSMK1, txtSMK2, txtSMK3]

  let objectForChangeEvents = {};

  for (let i = 0; i < textboxChange_DigitsDecimal.length; i++) {
    thisObj["on" + textboxChange_DigitsDecimal[i] + "Change"] =
      objectForChangeEvents["on" + textboxChange_DigitsDecimal[i] + "Change"] =
      () => {
        try {
          let value = getValue(thisObj, [textboxChange_DigitsDecimal[i]]);
          value = DigitsDecimal(value);
          setValue(thisObj, [textboxChange_DigitsDecimal[i]], value);
        } catch (error) {
          errorHandler(error);
        }
      };
  }

  for (let i = 0; i < textboxChange_Digitsonly.length; i++) {
    thisObj["on" + textboxChange_Digitsonly[i] + "Change"] =
      objectForChangeEvents["on" + textboxChange_Digitsonly[i] + "Change"] =
      () => {
        try {
          let value = getValue(thisObj, [textboxChange_Digitsonly[i]]);
          value = Digitsonly(value);
          setValue(thisObj, [textboxChange_Digitsonly[i]], value);
        } catch (error) {
          errorHandler(error);
        }
      };
  }

  const ontxtInvoiceChange = () => {
    try {
      setLoading(true)
      let data = getValue(thisObj, "txtInvoice");
      let res = "";
      for (var i = 0; i < data.length; i++) {
        if (data[i].charCodeAt(0) !== 38 && data[i].charCodeAt(0) !== 39) {
          res += data[i];
        }
      }
      setValue(thisObj, "txtInvoice", res);
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtInvoiceChange = ontxtInvoiceChange;

  const ontxtDispatchChange = () => {
    try {
      setLoading(true)
      let data = getValue(thisObj, "txtDispatch");
      let res = "";
      for (var i = 0; i < data.length; i++) {
        if (data[i].charCodeAt(0) !== 38 && data[i].charCodeAt(0) !== 39) {
          res += data[i];
        }
      }
      setValue(thisObj, "txtDispatch", res?.toUpperCase());
      mStockTransfer.dispatch_num(res?.toUpperCase())
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtDispatchChange = ontxtDispatchChange;

  // const ontxxtDriverChange = () => {
  //   try {
  //     setLoading(true)
  //     let data = getValue(thisObj, "txxtDriver");
  //     let res = "";
  //     for (var i = 0; i < data.length; i++) {
  //       if (data[i].charCodeAt(0) !== 38 && data[i].charCodeAt(0) !== 39) {
  //         res += data[i];
  //       }
  //     }
  //     setValue(thisObj, "txxtDriver", res);
  //     setLoading(false)
  //   } catch (error) {
  //     errorHandler(error);
  //   }
  // };
  // thisObj.ontxxtDriverChange = ontxxtDriverChange;

  const ontxtSclTcktRcvdChange = () => {
    try {
      setLoading(true)
      let data = getValue(thisObj, "txtSclTcktRcvd");
      let res = "";
      for (var i = 0; i < data.length; i++) {
        if (data[i].charCodeAt(0) !== 38 && data[i].charCodeAt(0) !== 39) {
          res += data[i];
        }
      }
      setValue(thisObj, "txtSclTcktRcvd", res);
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtSclTcktRcvdChange = ontxtSclTcktRcvdChange;

  const ontxtarRemarksChange = async () => {
    try {
      //setLoading(true)
      let data = getValue(thisObj, "txtarRemarks");
      let res = "";
      for (var i = 0; i < data.length; i++) {
        if (data[i].charCodeAt(0) !== 38 && data[i].charCodeAt(0) !== 39) {
          res += data[i];
        }
      }
      setValue(thisObj, "txtarRemarks", res);
      mStockTransfer.mudtProps.remark = res
      //setEventTrigger(true)
      if (!isEnabled(thisObj, "btnSave")) enable(thisObj, "btnSave")

      //setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtarRemarksChange = ontxtarRemarksChange;

  const ontxtSclTcktShipChange = () => {
    try {
      setLoading(true)
      let data = getValue(thisObj, "txtSclTcktShip");
      let res = "";
      for (var i = 0; i < data.length; i++) {
        if (data[i].charCodeAt(0) !== 38 && data[i].charCodeAt(0) !== 39) {
          res += data[i];
        }
      }
      setValue(thisObj, "txtSclTcktShip", res);
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtSclTcktShipChange = ontxtSclTcktShipChange;

  const ontxt1007Change = () => {
    try {
      setLoading(true)
      let data = getValue(thisObj, "txt1007");
      let res = "";
      for (var i = 0; i < data.length; i++) {
        if (data[i].charCodeAt(0) !== 38 && data[i].charCodeAt(0) !== 39) {
          res += data[i];
        }
      }
      setValue(thisObj, "txt1007", res);
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxt1007Change = ontxt1007Change;

  const ontxtTruckLineChange = () => {
    try {
      setLoading(true)
      let data = getValue(thisObj, "txtTruckLine");
      let res = "";
      for (var i = 0; i < data.length; i++) {
        if (data[i].charCodeAt(0) !== 38 && data[i].charCodeAt(0) !== 39) {
          res += data[i];
        }
      }
      setValue(thisObj, "txtTruckLine", res);
      mStockTransfer.truck_line(res)
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtTruckLineChange = ontxtTruckLineChange;

  const ontxtVehicleChange = () => {
    try {
      setLoading(true)
      let data = getValue(thisObj, "txtVehicle");
      let res = "";
      for (var i = 0; i < data.length; i++) {
        if (data[i].charCodeAt(0) !== 38 && data[i].charCodeAt(0) !== 39) {
          res += data[i];
        }
      }
      setValue(thisObj, "txtVehicle", res);
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtVehicleChange = ontxtVehicleChange;
  //---------------Change event for Textboxes Ends-----------

  //---------------Blur events for TextBoxes Starts ----------

  const ontxt1007Blur = () => {
    try {
      setLoading(true)
      let value = getValue(thisObj, "txt1007");
      if (mStockTransfer.mudtProps.settled_1007_num !== value) {
        mStockTransfer.settled_1007_num(value?.toUpperCase());
        mStockTransfer_TransferStatusChanged()
      }
      setValue(thisObj, "txt1007", value?.toUpperCase());
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxt1007Blur = ontxt1007Blur;

  const ontxtDispatchBlur = () => {
    try {
      setLoading(true)
      let value = getValue(thisObj, "txtDispatch");
      setValue(thisObj, "txtDispatch", value?.toUpperCase());
      mStockTransfer.dispatch_num(value?.toUpperCase());
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtDispatchBlur = ontxtDispatchBlur;

  const ontxxtDriverBlur = (event) => {
    try {
      setLoading(true)
      let value = event?.target?.value //getValue(thisObj, "txxtDriver");
      value = StrConv(value);
      setValue(thisObj, "txxtDriver", toPascalCase(value));
      mStockTransfer.driver_name(toPascalCase(value));
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxxtDriverBlur = ontxxtDriverBlur;

  const ontxtOtherFMBlur = () => {
    try {
      setLoading(true)
      let value = getValue(thisObj, "txtOtherFM");
      mStockTransfer.oth_fm(value);
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtOtherFMBlur = ontxtOtherFMBlur;

  const ontxtInvoiceBlur = () => {
    try {
      setLoading(true)
      let value = getValue(thisObj, "txtInvoice");
      setValue(thisObj, "txtInvoice", value?.toUpperCase());
      mStockTransfer.freight_invoice(value);
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtInvoiceBlur = ontxtInvoiceBlur;

  const ontxtMileageOvrrdBlur = () => {
    try {
      setLoading(true)
      if (mStockTransfer.mbFormFilling) {
        setLoading(false)
        return;
      }
      let value = getValue(thisObj, "txtMileageOvrrd");
      if (isNumeric(value)) {
        mStockTransfer.frt_mileage_override(value);
      } else {
        mStockTransfer.frt_mileage_override(0);
      }
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtMileageOvrrdBlur = ontxtMileageOvrrdBlur;

  const ontxtVehicleBlur = async () => {
    try {
      setLoading(true)
      let value = getValue(thisObj, "txtVehicle");
      setValue(thisObj, "txtVehicle", value?.toUpperCase());
      await mStockTransfer.vehichle_num(value?.toUpperCase());

      //setting value for label, check if it works
      setValue(thisObj, "lblLeasedTrailer", await (mStockTransfer.LeasedTrailerInfo()));
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtVehicleBlur = ontxtVehicleBlur;

  const ontxtTruckLineBlur = () => {
    try {
      setLoading(true)
      let value = getValue(thisObj, "txtTruckLine");
      mStockTransfer.truck_line(value);
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtTruckLineBlur = ontxtTruckLineBlur;

  const ontxtSclTcktShipBlur = () => {
    try {
      setLoading(true)
      let value = getValue(thisObj, "txtSclTcktShip");
      mStockTransfer.shp_scale_tick(value);
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtSclTcktShipBlur = ontxtSclTcktShipBlur;

  const ontxtarRemarksBlur = async () => {
    try {
      setLoading(true)
      let value = getValue(thisObj, "txtarRemarks");
      await mStockTransfer.remark(value);
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtarRemarksBlur = ontxtarRemarksBlur;

  const ontxtWtVhclRcvdBlur = () => {
    try {
      setLoading(true)
      let value = assignNumericValue(
        mStockTransfer,
        "rec_vehicle_wt",
        "txtWtVhclRcvd"
      );
      setValue(thisObj, "txtWtVhclRcvd", value != "" ? FormatNumber(value, 0) : "");
      if (mStockTransfer.mudtProps.transfer_type == "FF") {
        setValue(thisObj, "txtWtVehclShip", value);
        setValue(thisObj, "txtPeanutsShip", getValue(thisObj, "txtPeanutsRecvd"));
      }
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtWtVhclRcvdBlur = ontxtWtVhclRcvdBlur;

  const ontxtSclTcktRcvdBlur = () => {
    try {
      setLoading(true)
      let value = getValue(thisObj, "txtSclTcktRcvd");
      mStockTransfer.rec_scale_tick(value);
      if (mStockTransfer.mudtProps.transfer_type == "FF") {
        setValue(thisObj, "txtSclTcktShip", value);
      }
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtSclTcktRcvdBlur = ontxtSclTcktRcvdBlur;

  const ondtRcvdDateChange = async (e) => {
    try {
      setLoading(true)
      let value = e.target.value
      if (moment(value).isValid()) {
        await mStockTransfer.rec_date(moment(value).format("MM/DD/YYYY"));
        if (mStockTransfer.mudtProps.transfer_type == "FF") {
          setValue(thisObj, "dtShipDate", moment(value).format("MM/DD/YYYY"));
        }
        setEventTrigger(true)
      } else {
        // setValue(thisObj, "dtRcvdDate", "")
        if (mStockTransfer.mudtProps.rec_date !== "") {
          await mStockTransfer.rec_date("");
          setEventTrigger(true)
        }
      }
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ondtRcvdDateChange = ondtRcvdDateChange;

  const ondtShipDateChange = async (e) => {
    try {
      setLoading(true)
      let value = e.target.value
      if (moment(value).isValid()) {
        await mStockTransfer.shp_date(moment(value).format("MM/DD/YYYY"));
        if (mStockTransfer.mudtProps.transfer_type == "NL") {
          setValue(thisObj, "dtRcvdDate", moment(value).format("MM/DD/YYYY"));
        }
        setEventTrigger(true)
      } else {
        // setValue(thisObj, "dtShipDate", "")
        if (mStockTransfer.mudtProps.shp_date !== "") {
          await mStockTransfer.shp_date("");
          setEventTrigger(true)
        }
      }
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ondtShipDateChange = ondtShipDateChange;

  const onchkbxManualGrdChange = async () => {
    try {
      setLoading(true)
      let tmpFormFilling = false;
      let bResetGrades = false;
      let strInspectNum = "";
      let strSettleNum = "";

      if (mStockTransfer.mbFormFilling) {
        setLoading(false)
        return false;
      }

      tmpFormFilling = mStockTransfer.mbFormFilling;
      mStockTransfer.mbFormFilling = true;

      if (mStockTransfer.mudtProps.manual_grade_ind === "Y") {
        const inputVal = window.confirm(
          "All manual grade data will be replaced with current grades from shipping bin! Are you sure?"
        );
        if (inputVal == true) {
          let mSourceBin = new clsBin();
          strInspectNum = "";
          strSettleNum = "";
          let txt1007Number = getValue(thisObj, "txt1007");

          if (mStockTransfer.mudtProps.override_source === "") {
            if (String(mStockTransfer.mudtProps.grade_source).trim() === "SETTLE 1007" || (String(txt1007Number).trim() !== "" && isNumeric(txt1007Number))) {
              strSettleNum = String(txt1007Number).trim();
            } else {
              if (String(mStockTransfer.mudtProps.grade_source).trim() !== "INVENTORY" && String(mStockTransfer.mudtProps.grade_source).trim() !== "") {
                strInspectNum = String(mStockTransfer.mudtProps.grade_source).trim();
              }
            }
          } else {
            strInspectNum = String(mStockTransfer.mudtProps.override_source).trim();
          }

          await mSourceBin.load(mStockTransfer.mudtProps.shp_buy_pt, mStockTransfer.mudtProps.shp_whouse, mStockTransfer.mudtProps.shp_bin, mStockTransfer.mudtProps.transfer_num, strInspectNum, strSettleNum);

          if (String(txt1007Number).trim() !== "" && String(mSourceBin.mudtProps.GRADE_SOURCE).trim() !== "SETTLED 1007") {
            alert("The 1007 Settlement # " + txt1007Number + " was not found in the system. Verfiy and correct 1007 number.");
          }

          if (await mSourceBin.HasGrades() === false && (mStockTransfer.mudtProps.xfer_status === "R" || mStockTransfer.mNewStatus === TransferStatus.indexOf("Received"))) {
            const inputVal = window.confirm("Do you want to key in an Override Grade Source Inspection number?");
            if (inputVal == true) {
              await onbtnOverRideClick();
              strInspectNum = mStockTransfer.mudtProps.override_source;
              strSettleNum = "";
              await mSourceBin.load(mStockTransfer.mudtProps.shp_buy_pt, mStockTransfer.mudtProps.shp_whouse, mStockTransfer.mudtProps.shp_bin, mStockTransfer.mudtProps.transfer_num, strInspectNum, strSettleNum);
            }
            if (await mSourceBin.HasGrades() === false && (mStockTransfer.mudtProps.xfer_status === "R" || mStockTransfer.mNewStatus === TransferStatus.indexOf("Received"))) {
              alert("There is no grade data for this bin! Please, use Manual Grade mode");
              setValue(thisObj, "chkbxManualGrd", true);
              mStockTransfer.mbFormFilling = false;
              setLoading(false)
              return;
            } else {
              await mStockTransfer.manual_grade_ind("N");
              show(thisObj, "btnOverRide");
              bResetGrades = false;
            }
          } else {
            await mStockTransfer.manual_grade_ind("N");
            show(thisObj, "btnOverRide");
            bResetGrades = false;
          }
          await mStockTransfer.SetShippingBinInfo(mSourceBin, await CurrentUser.GetPeanutType(mStockTransfer.mudtProps.pnut_type_id), true);
          setEventTrigger(true)
        } else {
          setValue(thisObj, "chkbxManualGrd", true);
          mStockTransfer.mbFormFilling = false;
          setLoading(false)
          return;
        }
      } else {
        await mStockTransfer.manual_grade_ind("Y");
        hide(thisObj, "btnOverRide", false);
        await mStockTransfer.ClearGradeInfo();
        await FillGradeInfo();
        bResetGrades = true;
      }
      await SetupGradeEntryMode()
      mStockTransfer.mbFormFilling = tmpFormFilling
      if (bResetGrades) {
        await FillInitialManualGrade()
      }
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.onchkbxManualGrdChange = onchkbxManualGrdChange;

  const onchkbxMnlOverRideChange = async () => {
    try {
      setLoading(true)
      if (mStockTransfer.mbFormFilling) {
        setLoading(false)
        return false;
      }
      let chkManualOverride = getValue(thisObj, "chkbxMnlOverRide");
      await mStockTransfer.manualoverride(chkManualOverride);
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.onchkbxMnlOverRideChange = onchkbxMnlOverRideChange;

  const onchkbxSplitWtRcvdChange = () => {
    try {
      setLoading(true)
      let chkbxSplitWtRcvd = getValue(thisObj, "chkbxSplitWtRcvd");
      if (mStockTransfer.mbFormFilling) {
        return false;
      }
      let chkRSplitWeight = getValue(thisObj, "chkbxSplitWtRcvd");
      mStockTransfer.rec_split_scale(chkbxSplitWtRcvd === true ? "Y" : "N");

      ShowWeightVariance();
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.onchkbxSplitWtRcvdChange = onchkbxSplitWtRcvdChange;

  const onchkbxSplitWtShipChange = () => {
    try {
      setLoading(true)
      if (mStockTransfer.mbFormFilling) {
        return false;
      }
      let chkShippedSplitWeight = getValue(thisObj, "chkbxSplitWtShip");
      mStockTransfer.shp_split_scale(chkShippedSplitWeight === true ? "Y" : "N");

      ShowWeightVariance();
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.onchkbxSplitWtShipChange = onchkbxSplitWtShipChange;

  const GetFreightAccrual = async () => {
    // let nListIndex = 0;
    // let lstrxml = "";
    // let LstrList = "";
    let bNoSCAC = false;

    if (mStockTransfer.mudtProps.frt_scac_id === "") {
      showMessage(thisObj, "Please, Enter Vendor SCAC code first!");
      return false;
    }

    if (!mStockTransfer.mudtProps.transfer_type === "NL") {
      if (moment(mStockTransfer.mudtProps.shp_date).isValid()) {
        let isEnabled = isEnabled(thisObj, "dtShipDate");
        if (isEnabled === true) {
          return false;
        }
      }
      if (moment(mStockTransfer.mudtProps.rec_date).isValid()) {
        return false;
      }
    }

    if (getValue(thisObj, "txtarCarrAbbr") == undefined || getValue(thisObj, "txtarCarrAbbr") == "") {
      showMessage(thisObj, "Invalid vendor number.  Freight Accrual cannot be processed.");
      return false;
    } else {
      bNoSCAC = await txtCarrAbbrValidate(bNoSCAC);
      if (bNoSCAC) {
        return false;
      }
    }

    let dataJs = {
      mTransfer: mStockTransfer,
      lblCropYear: mStockTransfer.mudtProps.crop_year,
      lblTripID: getValue(thisObj, "txtTripId"),
      ReadPremiumsDeductionsFrtAccrl: true,
      btnName: "void"
    };
    setData(thisObj, "PremiumDeductionsFrtAccrl", dataJs);
    goTo(thisObj, "StockTransfer#PremiumDeductionsFrtAccrl#DEFAULT#true#Click");
    return true;
  };

  const ontxtOtherWtBlur = () => {
    try {
      setLoading(true)
      if (mStockTransfer.mbFormFilling) {
        return;
      }
      let value = assignNumericValue(
        mStockTransfer,
        "frt_other_wt",
        "txtOtherWt"
      );
      setValue(thisObj, "txtOtherWt", value != "" ? FormatNumber(value, 0) : "");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtOtherWtBlur = ontxtOtherWtBlur;

  const ontxtLSKRidingGrBlur = () => {
    try {
      setLoading(true)
      let value = assignNumericValue(
        mStockTransfer,
        "lsk_guage",
        "txtLSKRidingGr"
      );
      setValue(thisObj, "txtLSKRidingGr", value);
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtLSKRidingGrBlur = ontxtLSKRidingGrBlur;

  const ontxtOKRidingGrBlur = () => {
    try {
      setLoading(true)
      let value = assignNumericValue(
        mStockTransfer,
        "ok_guage",
        "txtOKRidingGr"
      );
      setValue(thisObj, "txtOKRidingGr", value);
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtOKRidingGrBlur = ontxtOKRidingGrBlur;

  const ontxtUpdtInventBlur = () => {
    try {
      setLoading(true)
      let value = assignNumericValue(
        mStockTransfer,
        "other_wt",
        "txtUpdtInvent"
      );
      setValue(thisObj, "txtUpdtInvent", value != "" ? FormatNumber(value, 0) : "");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtUpdtInventBlur = ontxtUpdtInventBlur;

  const ontxtWtInclVehclRecvdBlur = () => {
    try {
      setLoading(true)
      let value = assignNumericValue(
        mStockTransfer,
        "rec_tare_wt",
        "txtWtInclVehclRecvd"
      );
      setValue(thisObj, "txtWtInclVehclRecvd", value != "" ? FormatNumber(value, 0) : "");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtWtInclVehclRecvdBlur = ontxtWtInclVehclRecvdBlur;

  const ontxtWtVehicleShipBlur = () => {
    try {
      setLoading(true)
      if (mStockTransfer.mbFormFilling) {
        setLoading(false)
        return;
      }
      let value = assignNumericValue(
        mStockTransfer,
        "shp_tare_wt",
        "txtWtVehicleShip"
      );
      setValue(thisObj, "txtWtVehicleShip", value != "" ? FormatNumber(value, 0) : "");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtWtVehicleShipBlur = ontxtWtVehicleShipBlur;

  const ontxtWtVehclShipBlur = () => {
    try {
      setLoading(true)
      let value = assignNumericValue(
        mStockTransfer,
        "shp_vehicle_wt",
        "txtWtVehclShip"
      );
      setValue(thisObj, "txtWtVehclShip", value != "" ? FormatNumber(value, 0) : "");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtWtVehclShipBlur = ontxtWtVehclShipBlur;

  const ontxtSMK2GrBlur = () => {
    try {
      setLoading(true)
      let value = assignNumericValue(
        mStockTransfer,
        "smk_2_guage",
        "txtSMK2Gr"
      );
      setValue(thisObj, "txtSMK2Gr", value);
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtSMK2GrBlur = ontxtSMK2GrBlur;

  const ontxtSMK3GrBlur = () => {
    try {
      setLoading(true)
      let value = assignNumericValue(
        mStockTransfer,
        "smk_3_guage",
        "txtSMK3Gr"
      );
      setValue(thisObj, "txtSMK3Gr", value);
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtSMK3GrBlur = ontxtSMK3GrBlur;

  const ontxtSMK4GrBlur = () => {
    try {
      setLoading(true)
      let value = assignNumericValue(
        mStockTransfer,
        "smk_4_guage",
        "txtSMK4Gr"
      );
      setValue(thisObj, "txtSMK4Gr", value);
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error);
    }
  };
  thisObj.ontxtSMK4GrBlur = ontxtSMK4GrBlur;

  //--Commented this for hiding New Transfer Button as per disscussion with Jeff on 03/04/2024--
  // const onbtnNewTrnsfrClick = async () => {
  //   try {
  //     if (mStockTransfer.mbChgShpLoc) {
  //       showMessage(thisObj, "You must save New Transfer before continuing!");
  //       return false;
  //     }

  //     let isDirty = await mStockTransfer.IsDirty();
  //     if (isDirty) {
  //       let msgResult = window.confirm("Save Transfer Changes?")
  //       if (msgResult === true) {
  //         await mStockTransfer.CheckBusinessRules();
  //         CheckFormRules();
  //         let isVoidValue = await mStockTransfer.isValid()
  //         if (!isVoidValue) {
  //           let errorList = await mStockTransfer.GetBrokenRuleList()
  //           setData(thisObj, "DataForErrorList", errorList)
  //           goTo(thisObj, "StockTransfer#TransferErrorList#DEFAULT#true#Click");
  //           return true;
  //         }
  //       } else {
  //         await onbtnSaveClick()
  //       }
  //     }

  //     let mPrevTransferShpLoc = mStockTransfer.shp_buy_pt();
  //     let mPrevTransferRecLoc = mStockTransfer.rec_buy_pt();
  //     let GetTransferTypeIndex = await mStockTransfer.GetTransferTypeIndex();
  //     let mOldPrevTransferType = GetTransferTypeIndex === "1" ? "FF" : "";
  //     let mPrevState = mStockTransfer.state_abbr();
  //     let mPrevCounty = mStockTransfer.county_id();
  //     let mPrevFarm = mStockTransfer.farm_id();
  //     let mPrevFarmSuffix = mStockTransfer.farm_suffix();

  //     setStockTransfer(new clsTransfer());

  //     mStockTransfer.mPrevTransferShpLoc = mPrevTransferShpLoc
  //     mStockTransfer.mPrevTransferRecLoc = mPrevTransferRecLoc
  //     mStockTransfer.mOldPrevTransferType = mOldPrevTransferType
  //     mStockTransfer.mPrevState = mPrevState
  //     mStockTransfer.mPrevCounty = mPrevCounty
  //     mStockTransfer.mPrevFarm = mPrevFarm
  //     mStockTransfer.mPrevFarmSuffix = mPrevFarmSuffix

  //     await FormLoad();

  //     mStockTransfer.mPrevTransferShpLoc = "";
  //     mStockTransfer.mPrevTransferRecLoc = "";
  //     mStockTransfer.mOldPrevTransferType = "";
  //     mStockTransfer.mPrevState = "";
  //     mStockTransfer.mPrevCounty = "";
  //     mStockTransfer.mPrevFarm = "";
  //     mStockTransfer.mPrevFarmSuffix = "";
  //   } catch (error) {
  //     errorHandler(error)
  //   }
  // };
  // thisObj.onbtnNewTrnsfrClick = onbtnNewTrnsfrClick;
  //--------------------------------------------------------------------------------------------

  const onbtnVoidClick = async () => {
    try {
      setLoading(true)
      let bEnableSave = false;
      setData(thisObj, "bEnableSave", false)
      let TRIP_ID = mStockTransfer.mudtProps.trip_id;
      if (TRIP_ID > 0) {
        showMessage(thisObj,
          "Transfer cannot be voided because it is part of trip #" +
          mStockTransfer.mudtProps.trip_id + "."
        );
        setLoading(false)
        return false;
      }

      let FreightAccrualCompleted = await mStockTransfer.FreightAccrualCompleted();
      if (FreightAccrualCompleted) {
        showMessage(thisObj, "Freight Accrual has been completed for this transfer.You have to reverse freight accrual first!");
        setLoading(false)
        return false;
      } else {
        let xfer_status = mStockTransfer.mudtProps.xfer_status;
        switch (xfer_status) {
          case "V":
            let booleanresp = window.confirm("Are you sure you want to unvoid the transfer number: " + mStockTransfer.mudtProps.transfer_num + "?")
            if (booleanresp === true) {
              let VoidUnvoid = await mStockTransfer.VoidUnvoid(false)
              if (VoidUnvoid) {
                bEnableSave = true;
                setData(thisObj, "bEnableSave", true)
              }
            }
            break;
          default:
            if (
              mStockTransfer.mudtProps.transfer_num !== "" &&
              mStockTransfer.ShippingLocationList.length > 1
            ) {
              mStockTransfer.mbChgShpLoc = false;
              let confirmation = window.confirm("Are you voiding because of incorrect shipping location?")
              if (confirmation === true) {
                if (mStockTransfer.mudtProps.trip_id > 0) {
                  showMessage(thisObj, "To change shipping location, transfer cannot be part of a multi-stop");
                  setLoading(false)
                  return false;
                }
                let validation = await validateSecurity()
                if (validation === false) {
                  showMessage(thisObj, "Invalid security!");
                  setLoading(false)
                  return false;
                }
                let cboShippingLocation = getValue(thisObj, "ddShippingLoc");
                let shippinglist = mStockTransfer.ShippingLocationList;
                let shipDescription = "";
                if (
                  cboShippingLocation !== undefined &&
                  cboShippingLocation !== null
                ) {
                  shipDescription = shippinglist.find(
                    (elem) => elem.buy_pt_id == cboShippingLocation
                  ).buy_pt_name;
                }
                let dataObj = {
                  ShipLocList: shippinglist,
                  mTransfer: mStockTransfer,
                  lblCurrentLocation: shipDescription,
                  transfer_type: mStockTransfer.mudtProps.transfer_type,
                };
                setData(thisObj, "frmStockTransferOldNumber", dataObj);
                setLoading(false)
                goTo(
                  thisObj,
                  "StockTransfer#StockTransferOldNumber#DEFAULT#true#Click"
                );
                return true;  //stopping below process till user returns from next page, below code will resume in useeffect
              }
            }

            let confirmbox = window.confirm("Are you sure you want to void the transfer number: " + mStockTransfer.mudtProps.transfer_num + "?")
            if (confirmbox) {
              await mStockTransfer.VoidUnvoid(true)
              mStockTransfer.mbFormFilling = true
              await FillTransferData()
              mStockTransfer.mbFormFilling = false
            }
        }
        await SetupForm()
      }

      if (bEnableSave) {
        enable(thisObj, "btnSave");
      }
      let FRT_SCAC_ID = mStockTransfer.mudtProps.frt_scac_id;
      let lblFreightAccrualVendorInfo = mStockTransfer.mudtProps.frt_scac_id;
      let FRT_VENDOR = mStockTransfer.mudtProps.frt_vendor;
      if (FRT_SCAC_ID !== "" && (lblFreightAccrualVendorInfo === "" || FRT_VENDOR === "")) {
        txtCarrAbbrValidate(false);  //this functions might need change , bacuse props are cnaceling cancellaions
      }
      setLoading(false)

    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.onbtnVoidClick = onbtnVoidClick;

  const FillInitialManualGrade = async () => {
    let objVal = {};
    objVal["txtFmSample"] = mStockTransfer.mudtProps.fm_sample === 0 ? "" : mStockTransfer.mudtProps.fm_sample;
    objVal["txtClnSmplWt"] = mStockTransfer.mudtProps.cln_sample === 0 ? "" : mStockTransfer.mudtProps.cln_sample;
    objVal["txtTotalSMKRScreen"] = mStockTransfer.mudtProps.smk_gr === 0 ? "" : mStockTransfer.mudtProps.smk_gr;
    objVal["txtTotalSMKRScreenPct"] = mStockTransfer.mudtProps.smk_pct === 0 ? "" : mStockTransfer.mudtProps.smk_pct;
    objVal["txtSMK1Pct"] = mStockTransfer.mudtProps.smk_1_pct === 0 ? "" : mStockTransfer.mudtProps.smk_1_pct;
    objVal["txtSMK2Pct"] = mStockTransfer.mudtProps.smk_2_pct === 0 ? "" : mStockTransfer.mudtProps.smk_2_pct;
    objVal["txtSMK3Pct"] = mStockTransfer.mudtProps.smk_3_pct === 0 ? "" : mStockTransfer.mudtProps.smk_3_pct;
    objVal["txtSMK4Pct"] = mStockTransfer.mudtProps.smk_4_pct === 0 ? "" : mStockTransfer.mudtProps.smk_4_pct;
    objVal["txtTotalOK"] = mStockTransfer.mudtProps.ok_gr === 0 ? "" : mStockTransfer.mudtProps.ok_gr;
    objVal["txtTotalOKPct"] = mStockTransfer.mudtProps.ok_pct === 0 ? "" : mStockTransfer.mudtProps.ok_pct;
    objVal["txtOKRidingPct"] = mStockTransfer.mudtProps.ok_ride_pct === 0 ? "" : mStockTransfer.mudtProps.ok_ride_pct;
    objVal["txtOKFallThru2"] = mStockTransfer.mudtProps.ok_fall_pct === 0 ? "" : mStockTransfer.mudtProps.ok_fall_pct;
    objVal["txtOKDamageRiding2"] = mStockTransfer.mudtProps.ok_dam_pct === 0 ? "" : mStockTransfer.mudtProps.ok_dam_pct;
    objVal["txtDamageSplits"] = mStockTransfer.mudtProps.dam_split_gr === 0 ? "" : mStockTransfer.mudtProps.dam_split_gr;
    objVal["txtTotalDamage"] = mStockTransfer.mudtProps.dam_gr === 0 ? "" : mStockTransfer.mudtProps.dam_gr;
    objVal["txtTotalDamagePct"] = mStockTransfer.mudtProps.dam_pct === 0 ? "" : mStockTransfer.mudtProps.dam_pct;
    objVal["txtHulls"] = mStockTransfer.mudtProps.hull_gr === 0 ? "" : mStockTransfer.mudtProps.hull_gr;
    objVal["txtHullsPct"] = mStockTransfer.mudtProps.hull_pct === 0 ? "" : mStockTransfer.mudtProps.hull_pct;
    objVal["txtFreezeDamage"] = mStockTransfer.mudtProps.frez_dam_gr === 0 ? "" : mStockTransfer.mudtProps.frez_dam_gr;
    objVal["txtFreezeDamagePct"] = mStockTransfer.mudtProps.frez_dam_pct === 0 ? "" : mStockTransfer.mudtProps.frez_dam_pct;
    objVal["txtPittingDamage"] = mStockTransfer.mudtProps.pit_dam_gr === 0 ? "" : mStockTransfer.mudtProps.pit_dam_gr;
    objVal["txtPittingDamagePct"] = mStockTransfer.mudtProps.pit_dam_pct === 0 ? "" : mStockTransfer.mudtProps.pit_dam_pct;
    objVal["txtConcealedRMD"] = mStockTransfer.mudtProps.cncl_rmd_gr === 0 ? "" : mStockTransfer.mudtProps.cncl_rmd_gr;
    objVal["txtConcealedRMDPct"] = mStockTransfer.mudtProps.cncl_rmd_pct === 0 ? "" : mStockTransfer.mudtProps.cncl_rmd_pct;
    objVal["txtSoundSplits"] = mStockTransfer.mudtProps.ss_gr === 0 ? "" : mStockTransfer.mudtProps.ss_gr;
    objVal["txtSoundSplitsPct"] = mStockTransfer.mudtProps.ss_pct === 0 ? "" : mStockTransfer.mudtProps.ss_pct;

    // Second Column
    objVal["txtCrackedBroken"] = mStockTransfer.mudtProps.cr_br_gr === 0 ? "" : mStockTransfer.mudtProps.cr_br_gr;
    objVal["txtCrackedBrokenPct"] = mStockTransfer.mudtProps.cr_br_pct === 0 ? "" : mStockTransfer.mudtProps.cr_br_pct;
    objVal["txtDiscoloredShells"] = mStockTransfer.mudtProps.dcol_shel_gr === 0 ? "" : mStockTransfer.mudtProps.ss_pct;
    objVal["txtDiscoloredShellsPct"] = mStockTransfer.mudtProps.dcol_shel_pct === 0 ? "" : mStockTransfer.mudtProps.dcol_shel_pct;
    objVal["txtHullBrightness"] = mStockTransfer.mudtProps.hul_brit_gr === 0 ? "" : mStockTransfer.mudtProps.hul_brit_gr;
    objVal["txtHullBrightnessPct"] = mStockTransfer.mudtProps.hul_brit_pct === 0 ? "" : mStockTransfer.mudtProps.hul_brit_pct;
    objVal["txtJumbo"] = mStockTransfer.mudtProps.jumbo_gr === 0 ? "" : mStockTransfer.mudtProps.jumbo_gr;
    objVal["txtJumboGrPct"] = mStockTransfer.mudtProps.jumbo_pct === 0 ? "" : mStockTransfer.mudtProps.jumbo_pct;
    objVal["txtKernRidingELK"] = mStockTransfer.mudtProps.elk_ride_gr === 0 ? "" : mStockTransfer.mudtProps.elk_ride_gr;
    objVal["lblKernRidingELKPct"] = mStockTransfer.mudtProps.elk_ride_pct === 0 ? "" : mStockTransfer.mudtProps.elk_ride_pct;
    objVal["txtTotalELKDamage"] = mStockTransfer.mudtProps.elk_dam_gr === 0 ? "" : mStockTransfer.mudtProps.elk_dam_gr;
    objVal["txtTotalELKDamagePct"] = mStockTransfer.mudtProps.elk_dam_pct === 0 ? "" : mStockTransfer.mudtProps.elk_dam_pct;
    objVal["txtNetELK"] = mStockTransfer.mudtProps.elk_gr === 0 ? "" : mStockTransfer.mudtProps.elk_gr;
    objVal["txtNetELKPct"] = mStockTransfer.mudtProps.elk_pct === 0 ? "" : mStockTransfer.mudtProps.elk_pct;
    objVal["txtKernRidingPrescribe"] = mStockTransfer.mudtProps.ride_pscrib_gr === 0 ? "" : mStockTransfer.mudtProps.ride_pscrib_gr;
    objVal["txtKernRidingPrescribePct"] = mStockTransfer.mudtProps.ride_pscrib_pct === 0 ? "" : mStockTransfer.mudtProps.ride_pscrib_pct;
    objVal["txtForeignMtrl"] = mStockTransfer.mudtProps.fm_gr === 0 ? "" : mStockTransfer.mudtProps.fm_gr;
    objVal["txtForeignMaterialPct"] = mStockTransfer.mudtProps.fm_pct === 0 ? "" : mStockTransfer.mudtProps.fm_pct;
    objVal["txtOtherFM"] = mStockTransfer.mudtProps.oth_fm;
    objVal["txtTotalSLK"] = mStockTransfer.mudtProps.lsk_gr === 0 ? "" : mStockTransfer.mudtProps.lsk_gr;
    objVal["txtTotalSLKPct"] = mStockTransfer.mudtProps.lsk_pct === 0 ? "" : mStockTransfer.mudtProps.lsk_pct;
    objVal["txtLSKRidingPct"] = mStockTransfer.mudtProps.lsk_ride_pct === 0 ? "" : mStockTransfer.mudtProps.lsk_ride_pct;
    objVal["txtLSKFallThru2"] = mStockTransfer.mudtProps.lsk_fall_pct === 0 ? "" : mStockTransfer.mudtProps.lsk_fall_pct;
    objVal["txtLSKDamageRiding2"] = mStockTransfer.mudtProps.lsk_dam_pct === 0 ? "" : mStockTransfer.mudtProps.lsk_dam_pct;
    objVal["txtMoisture2"] = mStockTransfer.mudtProps.moist_pct === 0 ? "" : mStockTransfer.mudtProps.moist_pct;
    objVal["txtFancy"] = mStockTransfer.mudtProps.fan_gr === 0 ? "" : mStockTransfer.mudtProps.fan_gr;
    objVal["txtFancyPct"] = mStockTransfer.mudtProps.fan_pct === 0 ? "" : mStockTransfer.mudtProps.fan_pct;
    objVal["chkboxFlavus"] = false;

    if (mStockTransfer.mudtProps.vicam_ind === "Y") {
      objVal["chkboxVicam"] = true;
      objVal["txtVicamReading"] = mStockTransfer.mudtProps.vicam_ind;
    } else {
      objVal["chkboxVicam"] = false;
      objVal["txtVicamReading"] = "";
    }
    setFieldValues(thisObj, objVal, true);
  };

  const onchkboxFlavusChange = async () => {
    try {
      setLoading(true)
      if (mStockTransfer.mbFormFilling) {
        setLoading(false)
        return false;
      }
      let value = getValue(thisObj, "chkboxFlavus");
      mStockTransfer.flavus_ind(value === true ? "Y" : "N");
      setEventTrigger(true)
      setLoading(false)
    } catch (error) {
      errorHandler(error)
    }
  };
  thisObj.onchkboxFlavusChange = onchkboxFlavusChange;

  //---------------Blur events for TextBoxes end ----------

  //----------------Stock Transfer end-------------------

  //----------- error handler for all try-catch methods----------
  function errorHandler(err) {
    setLoading(false);
    console.log(err)
    showMessage(
      thisObj,
      err instanceof EvalError
        ? err.message
        : "Something went wrong. Please try again."
    );
  }
  const setTabIndex = () => {
    let elements = [
      'ddType',
      'ddShippingLoc',
      'ddDestinationLoc',
      'txtDispatch',
      'txtVehicle',
      'txt1007',
      'txtTruckLine',
      'txxtDriver',
      'ddPayFreight',
      'dtShipDate',
      'dtShipDateCal',
      'txtSclTcktShip',
      'chkbxSplitWtShip',
      'txtWtVehicleShip',
      'txtWtVehclShip',
      'txtPeanutsShip',
      'dtRcvdDate',
      'dtRcvdDateCal',
      'txtSclTcktRcvd',
      'chkbxSplitWtRcvd',
      'txtWtInclVehclRecvd',
      'txtWtVhclRcvd',
      'txtPeanutsRecvd',
      'chkbxCornFnd',
      'btnFrmBin',
      'btnToBin',
      'txtInvoice',
      'txtCarrAbbr',
      'txtVndrId',
      'btnPopup',
      'txtLegMsg',
      'txtMileageOvrrd',
      'txtarCarrAbbr',
      'ddUpdtInvent',
      'txtWeightVariance',
      'ddState',
      'ddCountry',
      'ddFarm',
      'chkbxManualGrd',
      'txtFmSample',
      'txtClnSmplWt',
      'txtTotalSMKRScreen',
      'txtTotalSMKRScreenPct',
      'txtSMK1Gr2',
      'txtSMK1Pct',
      'txtSMK3Gr2',
      'txtSMK2Pct',
      'txtSMK3Gr2',
      'txtSMK3Pct',
      'txtSMK4Gr2',
      'txtSMK4Pct',
      'txtSoundSplits',
      'txtSoundSplitsPct',
      'txtTotalSMK',
      'txtTotalSMK2',
      'txtTotalOK',
      'txtTotalOKPct',
      'txtOKRidingGr2',
      'txtOKRidingPct',
      'txtOKFallThru',
      'txtOKFallThru2',
      'txtOKDamageRiding',
      'txtOKDamageRiding2',
      'txtDamageSplits',
      'txtDamageSplits2',
      'txtTotalDamage',
      'txtTotalDamagePct',
      'txtTotalKernels',
      'txtTotalKernels2',
      'txtHulls',
      'txtHullsPct',
      'txtTotalKernelsAndHulls',
      'txtTotalKernelsAndHulls2',
      'txtFreezeDamage',
      'txtFreezeDamagePct',
      'txtPittingDamage',
      'txtPittingDamagePct',
      'txtConcealedRMD',
      'txtConcealedRMDPct',
      'txtCrackedBroken',
      'txtCrackedBrokenPct',
      'txtDiscoloredShells',
      'txtDiscoloredShellsPct',
      'txtHullBrightness',
      'txtHullBrightnessPct',
      'txtJumbo',
      'txtJumboGrPct',
      'txtKernRidingELK',
      'lblKernRidingELKPct',
      'txtTotalELKDamage',
      'txtTotalELKDamagePct',
      'txtNetELK',
      'txtNetELKPct',
      'txtKernRidingPrescribe',
      'txtKernRidingPrescribePct',
      'txtForeignMtrl',
      'txtForeignMaterialPct',
      'txtOtherFM',
      'txtTotalSLK',
      'txtTotalSLKPct',
      'txtLSKRidingGr2',
      'txtLSKRidingPct',
      'txtLSKFallThru',
      'txtLSKFallThru2',
      'txtLSKDamageRiding',
      'txtLSKDamageRiding2',
      'txtMoisture',
      'txtMoisture2',
      'txtFancy',
      'txtFancyPct',
      'chkboxFlavus',
      'chkboxVicam',
      'txtVicamReading',
      'txtarRemarks',
    ]
    elements.map((element, index) => {
      let tabelem = document.querySelectorAll(".modal")[0].querySelector(`#${element}`);
      if (tabelem !== null) {
        tabelem.tabIndex = index + 100;
      }
    })
  }
  // END_USER_CODE-USER_METHODS//Chinmay
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
        setValues,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        thisObj.setValues = setValues = setValues;
        return (
          <div
            className={
              _kaledo.guiModuleName +
              "-" +
              state.windowName +
              " col-lg-12" +
              " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={(vlaues) => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_StockTransfer*/}

              {/* END_USER_CODE-USER_BEFORE_StockTransfer*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxStockTransfer*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxStockTransfer*/}

              <GroupBoxWidget
                conf={state.grpbxStockTransfer}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxNewPending*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxNewPending*/}

                <GroupBoxWidget conf={state.grpbxNewPending} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblNew*/}

                  {/* END_USER_CODE-USER_BEFORE_lblNew*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblNew}
                    screenConf={state}
                  ></LabelWidget>
                  <CheckboxWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkbxMnlOverRide}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_lblNew*/}

                  {/* END_USER_CODE-USER_AFTER_lblNew*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPending*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPending*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPending}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPending*/}

                  {/* END_USER_CODE-USER_AFTER_lblPending*/}
                  {/* START_USER_CODE-USER_BEFORE_ddType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddType*/}

                  <DropDownWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddType*/}

                  {/* END_USER_CODE-USER_AFTER_ddType*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxNewPending*/}

                {/* END_USER_CODE-USER_AFTER_grpbxNewPending*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxLocation*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxLocation*/}

                <GroupBoxWidget conf={state.grpbxLocation} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_lblLocation*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblLocation}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblLocation*/}

                  {/* END_USER_CODE-USER_AFTER_lblLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_ddShippingLoc*/}

                  {/* END_USER_CODE-USER_BEFORE_ddShippingLoc*/}

                  <DropDownWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddShippingLoc}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddShippingLoc*/}

                  {/* END_USER_CODE-USER_AFTER_ddShippingLoc*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAddShip*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAddShip*/}

                  <TextBoxWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAddShip}
                    screenConf={state}
                    onPaste={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAddShip*/}

                  {/* END_USER_CODE-USER_AFTER_txtAddShip*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPhnShip*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPhnShip*/}

                  <TextBoxWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPhnShip}
                    screenConf={state}
                    onPaste={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPhnShip*/}

                  {/* END_USER_CODE-USER_AFTER_txtPhnShip*/}
                  {/* START_USER_CODE-USER_BEFORE_ddDestinationLoc*/}

                  {/* END_USER_CODE-USER_BEFORE_ddDestinationLoc*/}

                  <DropDownWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestinationLoc}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddDestinationLoc*/}

                  {/* END_USER_CODE-USER_AFTER_ddDestinationLoc*/}
                  {/* START_USER_CODE-USER_BEFORE_txtAddDest*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAddDest*/}

                  <TextBoxWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAddDest}
                    screenConf={state}
                    onPaste={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAddDest*/}

                  {/* END_USER_CODE-USER_AFTER_txtAddDest*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPhnDest*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPhnDest*/}

                  <TextBoxWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPhnDest}
                    screenConf={state}
                    onPaste={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPhnDest*/}

                  {/* END_USER_CODE-USER_AFTER_txtPhnDest*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxLocation*/}

                {/* END_USER_CODE-USER_AFTER_grpbxLocation*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxMid*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxMid*/}

                <GroupBoxWidget conf={state.grpbxMid} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_grpbxLoadShippedRcvd*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxLoadShippedRcvd*/}

                  <GroupBoxWidget
                    conf={state.grpbxLoadShippedRcvd}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_grpbxLoad*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxLoad*/}

                    <GroupBoxWidget conf={state.grpbxLoad} screenConf={state}>
                      {/* START_USER_CODE-USER_BEFORE_lblLoad*/}

                      {/* END_USER_CODE-USER_BEFORE_lblLoad*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblLoad}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblLoad*/}

                      {/* END_USER_CODE-USER_AFTER_lblLoad*/}
                      {/* START_USER_CODE-USER_BEFORE_txtDispatch*/}

                      {/* END_USER_CODE-USER_BEFORE_txtDispatch*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtDispatch}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtDispatch*/}

                      {/* END_USER_CODE-USER_AFTER_txtDispatch*/}
                      {/* START_USER_CODE-USER_BEFORE_txtTruckLine*/}

                      {/* END_USER_CODE-USER_BEFORE_txtTruckLine*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtTruckLine}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtTruckLine*/}

                      {/* END_USER_CODE-USER_AFTER_txtTruckLine*/}
                      {/* START_USER_CODE-USER_BEFORE_txtVehicle*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVehicle*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtVehicle}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtVehicle*/}

                      {/* END_USER_CODE-USER_AFTER_txtVehicle*/}
                      {/* START_USER_CODE-USER_BEFORE_txxtDriver*/}

                      {/* END_USER_CODE-USER_BEFORE_txxtDriver*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txxtDriver}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txxtDriver*/}

                      {/* END_USER_CODE-USER_AFTER_txxtDriver*/}
                      {/* START_USER_CODE-USER_BEFORE_txt1007*/}

                      {/* END_USER_CODE-USER_BEFORE_txt1007*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txt1007}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txt1007*/}

                      {/* END_USER_CODE-USER_AFTER_txt1007*/}
                      {/* START_USER_CODE-USER_BEFORE_ddPayFreight*/}

                      {/* END_USER_CODE-USER_BEFORE_ddPayFreight*/}

                      <DropDownWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddPayFreight}
                        screenConf={state}
                      ></DropDownWidget>
                      <LabelWidget
                        values={values}
                        conf={state.lblLeasedTrailer}
                        screenConf={state}
                      ></LabelWidget>
                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtOtherWt}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_ddPayFreight*/}

                      {/* END_USER_CODE-USER_AFTER_ddPayFreight*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxLoad*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxLoad*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxShippd*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxShippd*/}

                    <GroupBoxWidget conf={state.grpbxShippd} screenConf={state}>
                      {/* START_USER_CODE-USER_BEFORE_lblShipped*/}

                      {/* END_USER_CODE-USER_BEFORE_lblShipped*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblShipped}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblShipped*/}

                      {/* END_USER_CODE-USER_AFTER_lblShipped*/}
                      {/* START_USER_CODE-USER_BEFORE_dtShipDate*/}

                      {/* END_USER_CODE-USER_BEFORE_dtShipDate*/}

                      <DateWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.dtShipDate}
                        screenConf={state}
                      ></DateWidget>
                      {/* START_USER_CODE-USER_AFTER_dtShipDate*/}

                      {/* END_USER_CODE-USER_AFTER_dtShipDate*/}
                      {/* START_USER_CODE-USER_BEFORE_txtWtVehicleShip*/}

                      {/* END_USER_CODE-USER_BEFORE_txtWtVehicleShip*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtWtVehicleShip}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtWtVehicleShip*/}

                      {/* END_USER_CODE-USER_AFTER_txtWtVehicleShip*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSclTcktShip*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSclTcktShip*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSclTcktShip}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSclTcktShip*/}

                      {/* END_USER_CODE-USER_AFTER_txtSclTcktShip*/}
                      {/* START_USER_CODE-USER_BEFORE_txtWtVehclShip*/}

                      {/* END_USER_CODE-USER_BEFORE_txtWtVehclShip*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtWtVehclShip}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtWtVehclShip*/}

                      {/* END_USER_CODE-USER_AFTER_txtWtVehclShip*/}
                      {/* START_USER_CODE-USER_BEFORE_chkbxSplitWtShip*/}

                      {/* END_USER_CODE-USER_BEFORE_chkbxSplitWtShip*/}

                      <CheckboxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.chkbxSplitWtShip}
                        screenConf={state}
                      ></CheckboxWidget>
                      {/* START_USER_CODE-USER_AFTER_chkbxSplitWtShip*/}

                      {/* END_USER_CODE-USER_AFTER_chkbxSplitWtShip*/}
                      {/* START_USER_CODE-USER_BEFORE_txtPeanutsShip*/}

                      {/* END_USER_CODE-USER_BEFORE_txtPeanutsShip*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtPeanutsShip}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtPeanutsShip*/}

                      {/* END_USER_CODE-USER_AFTER_txtPeanutsShip*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxShippd*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxShippd*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxRecvd*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxRecvd*/}

                    <GroupBoxWidget conf={state.grpbxRecvd} screenConf={state}>
                      {/* START_USER_CODE-USER_BEFORE_lblReceived*/}

                      {/* END_USER_CODE-USER_BEFORE_lblReceived*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblReceived}
                        screenConf={state}
                      ></LabelWidget>
                      <LabelWidget
                        values={values}
                        conf={state.lblRecvdMsg}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblReceived*/}

                      {/* END_USER_CODE-USER_AFTER_lblReceived*/}
                      {/* START_USER_CODE-USER_BEFORE_dtRcvdDate*/}

                      {/* END_USER_CODE-USER_BEFORE_dtRcvdDate*/}

                      <DateWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.dtRcvdDate}
                        screenConf={state}
                      ></DateWidget>
                      {/* START_USER_CODE-USER_AFTER_dtRcvdDate*/}

                      {/* END_USER_CODE-USER_AFTER_dtRcvdDate*/}
                      {/* START_USER_CODE-USER_BEFORE_txtWtInclVehclRecvd*/}

                      {/* END_USER_CODE-USER_BEFORE_txtWtInclVehclRecvd*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtWtInclVehclRecvd}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtWtInclVehclRecvd*/}

                      {/* END_USER_CODE-USER_AFTER_txtWtInclVehclRecvd*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSclTcktRcvd*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSclTcktRcvd*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSclTcktRcvd}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSclTcktRcvd*/}

                      {/* END_USER_CODE-USER_AFTER_txtSclTcktRcvd*/}
                      {/* START_USER_CODE-USER_BEFORE_txtWtVhclRcvd*/}

                      {/* END_USER_CODE-USER_BEFORE_txtWtVhclRcvd*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtWtVhclRcvd}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtWtVhclRcvd*/}

                      {/* END_USER_CODE-USER_AFTER_txtWtVhclRcvd*/}
                      {/* START_USER_CODE-USER_BEFORE_chkbxSplitWtRcvd*/}

                      {/* END_USER_CODE-USER_BEFORE_chkbxSplitWtRcvd*/}

                      <CheckboxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.chkbxSplitWtRcvd}
                        screenConf={state}
                      ></CheckboxWidget>
                      {/* START_USER_CODE-USER_AFTER_chkbxSplitWtRcvd*/}

                      {/* END_USER_CODE-USER_AFTER_chkbxSplitWtRcvd*/}
                      {/* START_USER_CODE-USER_BEFORE_txtPeanutsRecvd*/}

                      {/* END_USER_CODE-USER_BEFORE_txtPeanutsRecvd*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtPeanutsRecvd}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtPeanutsRecvd*/}

                      {/* END_USER_CODE-USER_AFTER_txtPeanutsRecvd*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxRecvd*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxRecvd*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxFreight*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxFreight*/}

                    <GroupBoxWidget
                      conf={state.grpbxFreight}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_lblFrghtAccrual*/}

                      {/* END_USER_CODE-USER_BEFORE_lblFrghtAccrual*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblFrghtAccrual}
                        screenConf={state}
                      ></LabelWidget>
                      <LabelWidget
                        values={values}
                        conf={state.lblCompleted}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblFrghtAccrual*/}

                      {/* END_USER_CODE-USER_AFTER_lblFrghtAccrual*/}
                      {/* START_USER_CODE-USER_BEFORE_txtLegMsg*/}

                      {/* END_USER_CODE-USER_BEFORE_txtLegMsg*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtLegMsg}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtLegMsg*/}

                      {/* END_USER_CODE-USER_AFTER_txtLegMsg*/}
                      {/* START_USER_CODE-USER_BEFORE_txtInvoice*/}

                      {/* END_USER_CODE-USER_BEFORE_txtInvoice*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtInvoice}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtInvoice*/}

                      {/* END_USER_CODE-USER_AFTER_txtInvoice*/}
                      {/* START_USER_CODE-USER_BEFORE_txtMileageOvrrd*/}

                      {/* END_USER_CODE-USER_BEFORE_txtMileageOvrrd*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtMileageOvrrd}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtMileageOvrrd*/}

                      {/* END_USER_CODE-USER_AFTER_txtMileageOvrrd*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCarrAbbr*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCarrAbbr*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCarrAbbr}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCarrAbbr*/}

                      {/* END_USER_CODE-USER_AFTER_txtCarrAbbr*/}
                      {/* START_USER_CODE-USER_BEFORE_txtarCarrAbbr*/}

                      {/* END_USER_CODE-USER_BEFORE_txtarCarrAbbr*/}

                      <TextAreaWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtarCarrAbbr}
                        screenConf={state}
                      ></TextAreaWidget>
                      {/* START_USER_CODE-USER_AFTER_txtarCarrAbbr*/}

                      {/* END_USER_CODE-USER_AFTER_txtarCarrAbbr*/}
                      {/* START_USER_CODE-USER_BEFORE_txtVndrId*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVndrId*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtVndrId}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtVndrId*/}

                      {/* END_USER_CODE-USER_AFTER_txtVndrId*/}
                      {/* START_USER_CODE-USER_BEFORE_btnPopup*/}

                      {/* END_USER_CODE-USER_BEFORE_btnPopup*/}

                      <ButtonWidget
                        conf={state.btnPopup}
                        screenConf={state}
                        onClick={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btnPopup*/}

                      {/* END_USER_CODE-USER_AFTER_btnPopup*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxFreight*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxFreight*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxUpdateInvnt*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxUpdateInvnt*/}

                    <GroupBoxWidget
                      conf={state.grpbxUpdateInvnt}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_ddUpdtInvent*/}

                      {/* END_USER_CODE-USER_BEFORE_ddUpdtInvent*/}

                      <DropDownWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddUpdtInvent}
                        screenConf={state}
                      ></DropDownWidget>
                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtUpdtInvent}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_ddUpdtInvent*/}

                      {/* END_USER_CODE-USER_AFTER_ddUpdtInvent*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxUpdateInvnt*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxUpdateInvnt*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxLoadShippedRcvd*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxLoadShippedRcvd*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxGridCorn*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxGridCorn*/}

                  <GroupBoxWidget conf={state.grpbxGridCorn} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_grpbxShippdFrmTo*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxShippdFrmTo*/}

                    <GroupBoxWidget
                      conf={state.grpbxShippdFrmTo}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_lblShippdFrm*/}

                      {/* END_USER_CODE-USER_BEFORE_lblShippdFrm*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblShippdFrm}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblShippdFrm*/}

                      {/* END_USER_CODE-USER_AFTER_lblShippdFrm*/}
                      {/* START_USER_CODE-USER_BEFORE_lblShippdTo*/}

                      {/* END_USER_CODE-USER_BEFORE_lblShippdTo*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblShippdTo}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblShippdTo*/}

                      {/* END_USER_CODE-USER_AFTER_lblShippdTo*/}
                      {/* START_USER_CODE-USER_BEFORE_lblPnutType*/}

                      {/* END_USER_CODE-USER_BEFORE_lblPnutType*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblPnutType}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblPnutType*/}

                      {/* END_USER_CODE-USER_AFTER_lblPnutType*/}
                      {/* START_USER_CODE-USER_BEFORE_txtPnutShippdFrm*/}

                      {/* END_USER_CODE-USER_BEFORE_txtPnutShippdFrm*/}

                      <DropDownWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtPnutShippdFrm}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_txtPnutShippdFrm*/}

                      {/* END_USER_CODE-USER_AFTER_txtPnutShippdFrm*/}
                      {/* START_USER_CODE-USER_BEFORE_txtPnutShippdTo*/}

                      {/* END_USER_CODE-USER_BEFORE_txtPnutShippdTo*/}

                      {/* <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtPnutShippdTo}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget> */}
                      {/* START_USER_CODE-USER_AFTER_txtPnutShippdTo*/}

                      {/* END_USER_CODE-USER_AFTER_txtPnutShippdTo*/}
                      {/* START_USER_CODE-USER_BEFORE_lblSeg*/}

                      {/* END_USER_CODE-USER_BEFORE_lblSeg*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblSeg}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblSeg*/}

                      {/* END_USER_CODE-USER_AFTER_lblSeg*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSegShppdFrm*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSegShppdFrm*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSegShppdFrm}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSegShppdFrm*/}

                      {/* END_USER_CODE-USER_AFTER_txtSegShppdFrm*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSegShppdTo*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSegShppdTo*/}

                      {/* <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSegShppdTo}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget> */}
                      {/* START_USER_CODE-USER_AFTER_txtSegShppdTo*/}

                      {/* END_USER_CODE-USER_AFTER_txtSegShppdTo*/}
                      {/* START_USER_CODE-USER_BEFORE_lblVariety*/}

                      {/* END_USER_CODE-USER_BEFORE_lblVariety*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblVariety}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblVariety*/}

                      {/* END_USER_CODE-USER_AFTER_lblVariety*/}
                      {/* START_USER_CODE-USER_BEFORE_txtVarietyShppdFrm*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVarietyShppdFrm*/}

                      {/* <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtVarietyShppdFrm}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget> */}
                      <DropDownWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddVarietyShppdFrm}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_txtVarietyShppdFrm*/}

                      {/* END_USER_CODE-USER_AFTER_txtVarietyShppdFrm*/}
                      {/* START_USER_CODE-USER_BEFORE_txtVarietyShppdTo*/}

                      {/* END_USER_CODE-USER_BEFORE_txtVarietyShppdTo*/}

                      {/* <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtVarietyShppdTo}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget> */}
                      <DropDownWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddVarietyShppdTo}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_txtVarietyShppdTo*/}

                      {/* END_USER_CODE-USER_AFTER_txtVarietyShppdTo*/}
                      {/* START_USER_CODE-USER_BEFORE_lblGeneration*/}

                      {/* END_USER_CODE-USER_BEFORE_lblGeneration*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblGeneration}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblGeneration*/}

                      {/* END_USER_CODE-USER_AFTER_lblGeneration*/}
                      {/* START_USER_CODE-USER_BEFORE_txtGenerationShippdFrm*/}

                      {/* END_USER_CODE-USER_BEFORE_txtGenerationShippdFrm*/}

                      {/* <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtGenerationShippdFrm}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget> */}
                      <DropDownWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddGenerationShippdFrm}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_txtGenerationShippdFrm*/}

                      {/* END_USER_CODE-USER_AFTER_txtGenerationShippdFrm*/}
                      {/* START_USER_CODE-USER_BEFORE_txtGenerationShippdTo*/}

                      {/* END_USER_CODE-USER_BEFORE_txtGenerationShippdTo*/}

                      {/* <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtGenerationShippdTo}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget> */}
                      <DropDownWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddGenerationShippdTo}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_txtGenerationShippdTo*/}

                      {/* END_USER_CODE-USER_AFTER_txtGenerationShippdTo*/}
                      {/* START_USER_CODE-USER_BEFORE_lblOleic*/}

                      {/* END_USER_CODE-USER_BEFORE_lblOleic*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblOleic}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblOleic*/}

                      {/* END_USER_CODE-USER_AFTER_lblOleic*/}
                      {/* START_USER_CODE-USER_BEFORE_txtOleicShippdFrm*/}

                      {/* END_USER_CODE-USER_BEFORE_txtOleicShippdFrm*/}

                      {/* <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtOleicShippdFrm}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget> */}
                      <DropDownWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddOleicShippdFrm}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_txtOleicShippdFrm*/}

                      {/* END_USER_CODE-USER_AFTER_txtOleicShippdFrm*/}
                      {/* START_USER_CODE-USER_BEFORE_txtOleicShippdTo*/}

                      {/* END_USER_CODE-USER_BEFORE_txtOleicShippdTo*/}

                      {/* <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtOleicShippdTo}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget> */}
                      <DropDownWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddOleicShippdTo}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_txtOleicShippdTo*/}

                      {/* END_USER_CODE-USER_AFTER_txtOleicShippdTo*/}
                      {/* START_USER_CODE-USER_BEFORE_lblOrganic*/}

                      {/* END_USER_CODE-USER_BEFORE_lblOrganic*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblOrganic}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblOrganic*/}

                      {/* END_USER_CODE-USER_AFTER_lblOrganic*/}
                      {/* START_USER_CODE-USER_BEFORE_txtOrganicShippdFrm*/}

                      {/* END_USER_CODE-USER_BEFORE_txtOrganicShippdFrm*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtOrganicShippdFrm}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtOrganicShippdFrm*/}

                      {/* END_USER_CODE-USER_AFTER_txtOrganicShippdFrm*/}
                      {/* START_USER_CODE-USER_BEFORE_txtOrganicShippdTo*/}

                      {/* END_USER_CODE-USER_BEFORE_txtOrganicShippdTo*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtOrganicShippdTo}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtOrganicShippdTo*/}

                      {/* END_USER_CODE-USER_AFTER_txtOrganicShippdTo*/}
                      {/* START_USER_CODE-USER_BEFORE_lblEdibleOil*/}

                      {/* END_USER_CODE-USER_BEFORE_lblEdibleOil*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblEdibleOil}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblEdibleOil*/}

                      {/* END_USER_CODE-USER_AFTER_lblEdibleOil*/}
                      {/* START_USER_CODE-USER_BEFORE_txtEdiblOilShippdFrm*/}

                      {/* END_USER_CODE-USER_BEFORE_txtEdiblOilShippdFrm*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtEdiblOilShippdFrm}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtEdiblOilShippdFrm*/}

                      {/* END_USER_CODE-USER_AFTER_txtEdiblOilShippdFrm*/}
                      {/* START_USER_CODE-USER_BEFORE_txtEdiblOilShippdTo*/}

                      {/* END_USER_CODE-USER_BEFORE_txtEdiblOilShippdTo*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtEdiblOilShippdTo}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtEdiblOilShippdTo*/}

                      {/* END_USER_CODE-USER_AFTER_txtEdiblOilShippdTo*/}
                      {/* START_USER_CODE-USER_BEFORE_lblWhseBin*/}

                      {/* END_USER_CODE-USER_BEFORE_lblWhseBin*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblWhseBin}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblWhseBin*/}

                      {/* END_USER_CODE-USER_AFTER_lblWhseBin*/}
                      {/* START_USER_CODE-USER_BEFORE_txtWhseBinShippdFrm*/}

                      {/* END_USER_CODE-USER_BEFORE_txtWhseBinShippdFrm*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtWhseBinShippdFrm}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtWhseBinShippdFrm*/}

                      {/* END_USER_CODE-USER_AFTER_txtWhseBinShippdFrm*/}
                      {/* START_USER_CODE-USER_BEFORE_txtWhseBinShippdTo*/}

                      {/* END_USER_CODE-USER_BEFORE_txtWhseBinShippdTo*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtWhseBinShippdTo}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtWhseBinShippdTo*/}

                      {/* END_USER_CODE-USER_AFTER_txtWhseBinShippdTo*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxShippdFrmTo*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxShippdFrmTo*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxFromToBin*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxFromToBin*/}

                    <GroupBoxWidget
                      conf={state.grpbxFromToBin}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_chkbxCornFnd*/}

                      {/* END_USER_CODE-USER_BEFORE_chkbxCornFnd*/}

                      <CheckboxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.chkbxCornFnd}
                        screenConf={state}
                      ></CheckboxWidget>
                      {/* START_USER_CODE-USER_AFTER_chkbxCornFnd*/}

                      {/* END_USER_CODE-USER_AFTER_chkbxCornFnd*/}
                      {/* START_USER_CODE-USER_BEFORE_btnFrmBin*/}

                      {/* END_USER_CODE-USER_BEFORE_btnFrmBin*/}

                      <ButtonWidget
                        conf={state.btnFrmBin}
                        screenConf={state}
                        onClick={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btnFrmBin*/}

                      {/* END_USER_CODE-USER_AFTER_btnFrmBin*/}
                      {/* START_USER_CODE-USER_BEFORE_btnArrow*/}

                      {/* END_USER_CODE-USER_BEFORE_btnArrow*/}

                      <ButtonWidget
                        conf={state.btnArrow}
                        screenConf={state}
                        onClick={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btnArrow*/}

                      {/* END_USER_CODE-USER_AFTER_btnArrow*/}
                      {/* START_USER_CODE-USER_BEFORE_btnToBin*/}

                      {/* END_USER_CODE-USER_BEFORE_btnToBin*/}

                      <ButtonWidget
                        conf={state.btnToBin}
                        screenConf={state}
                        onClick={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btnToBin*/}

                      {/* END_USER_CODE-USER_AFTER_btnToBin*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxFromToBin*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxFromToBin*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxWtVariance*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxWtVariance*/}

                    <GroupBoxWidget
                      conf={state.grpbxWtVariance}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_txtWeightVariance*/}

                      {/* END_USER_CODE-USER_BEFORE_txtWeightVariance*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtWeightVariance}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtWeightVariance*/}

                      {/* END_USER_CODE-USER_AFTER_txtWeightVariance*/}
                      {/* START_USER_CODE-USER_BEFORE_lblLb*/}

                      {/* END_USER_CODE-USER_BEFORE_lblLb*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblLb}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblLb*/}

                      {/* END_USER_CODE-USER_AFTER_lblLb*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxWtVariance*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxWtVariance*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxFarmFreight*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxFarmFreight*/}

                    <GroupBoxWidget
                      conf={state.grpbxFarmFreight}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_lblFarmFrgtInfo*/}

                      {/* END_USER_CODE-USER_BEFORE_lblFarmFrgtInfo*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblFarmFrgtInfo}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblFarmFrgtInfo*/}

                      {/* END_USER_CODE-USER_AFTER_lblFarmFrgtInfo*/}
                      {/* START_USER_CODE-USER_BEFORE_ddState*/}

                      {/* END_USER_CODE-USER_BEFORE_ddState*/}

                      <DropDownWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddState}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddState*/}

                      {/* END_USER_CODE-USER_AFTER_ddState*/}
                      {/* START_USER_CODE-USER_BEFORE_ddCountry*/}

                      {/* END_USER_CODE-USER_BEFORE_ddCountry*/}

                      <DropDownWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddCountry}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddCountry*/}

                      {/* END_USER_CODE-USER_AFTER_ddCountry*/}
                      {/* START_USER_CODE-USER_BEFORE_ddFarm*/}

                      {/* END_USER_CODE-USER_BEFORE_ddFarm*/}

                      <DropDownWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.ddFarm}
                        screenConf={state}
                      ></DropDownWidget>
                      {/* START_USER_CODE-USER_AFTER_ddFarm*/}

                      {/* END_USER_CODE-USER_AFTER_ddFarm*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxFarmFreight*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxFarmFreight*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxGridCorn*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxGridCorn*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxMid*/}

                {/* END_USER_CODE-USER_AFTER_grpbxMid*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxRegrdSettlement*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxRegrdSettlement*/}

                <GroupBoxWidget
                  conf={state.grpbxRegrdSettlement}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_grpbxRegrd*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxRegrd*/}

                  <GroupBoxWidget conf={state.grpbxRegrd} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_grpbxRegrading*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxRegrading*/}

                    <GroupBoxWidget
                      conf={state.grpbxRegrading}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_lblRegrading*/}

                      {/* END_USER_CODE-USER_BEFORE_lblRegrading*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblRegrading}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblRegrading*/}

                      {/* END_USER_CODE-USER_AFTER_lblRegrading*/}
                      {/* START_USER_CODE-USER_BEFORE_chkbxManualGrd*/}

                      {/* END_USER_CODE-USER_BEFORE_chkbxManualGrd*/}

                      <CheckboxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.chkbxManualGrd}
                        screenConf={state}
                      ></CheckboxWidget>
                      {/* START_USER_CODE-USER_AFTER_chkbxManualGrd*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblGradeSource}
                        screenConf={state}
                      ></LabelWidget>
                      {/* END_USER_CODE-USER_AFTER_chkbxManualGrd*/}
                      {/* START_USER_CODE-USER_BEFORE_txtFmSample*/}

                      {/* END_USER_CODE-USER_BEFORE_txtFmSample*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtFmSample}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtFmSample*/}
                      <LabelWidget
                        values={values}
                        conf={state.lblg}
                        screenConf={state}
                      ></LabelWidget>
                      {/* END_USER_CODE-USER_AFTER_txtFmSample*/}
                      {/* START_USER_CODE-USER_BEFORE_lblg*/}

                      {/* END_USER_CODE-USER_BEFORE_lblg*/}


                      <ButtonWidget
                        conf={state.btnOverRide}
                        screenConf={state}
                        onClick={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_lblg*/}

                      {/* END_USER_CODE-USER_AFTER_lblg*/}
                      {/* START_USER_CODE-USER_BEFORE_txtClnSmplWt*/}

                      {/* END_USER_CODE-USER_BEFORE_txtClnSmplWt*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtClnSmplWt}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtClnSmplWt*/}

                      {/* END_USER_CODE-USER_AFTER_txtClnSmplWt*/}
                      {/* START_USER_CODE-USER_BEFORE_lblG*/}

                      {/* END_USER_CODE-USER_BEFORE_lblG*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblG}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblG*/}

                      {/* END_USER_CODE-USER_AFTER_lblG*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxRegrading*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxRegrading*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxReGrading*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxReGrading*/}

                    <GroupBoxWidget
                      conf={state.grpbxReGrading}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_lblPct*/}

                      {/* END_USER_CODE-USER_BEFORE_lblPct*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblPct}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblPct*/}

                      {/* END_USER_CODE-USER_AFTER_lblPct*/}
                      {/* START_USER_CODE-USER_BEFORE_lblGrams*/}

                      {/* END_USER_CODE-USER_BEFORE_lblGrams*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblGrams}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblGrams*/}

                      {/* END_USER_CODE-USER_AFTER_lblGrams*/}
                      {/* START_USER_CODE-USER_BEFORE_lblPrcnt*/}

                      {/* END_USER_CODE-USER_BEFORE_lblPrcnt*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblPrcnt}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblPrcnt*/}

                      {/* END_USER_CODE-USER_AFTER_lblPrcnt*/}
                      {/* START_USER_CODE-USER_BEFORE_lblGrams2*/}

                      {/* END_USER_CODE-USER_BEFORE_lblGrams2*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblGrams2}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblGrams2*/}

                      {/* END_USER_CODE-USER_AFTER_lblGrams2*/}
                      {/* START_USER_CODE-USER_BEFORE_lblPrcnt2*/}

                      {/* END_USER_CODE-USER_BEFORE_lblPrcnt2*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblPrcnt2}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblPrcnt2*/}

                      {/* END_USER_CODE-USER_AFTER_lblPrcnt2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtTotalSMKRScreen*/}

                      {/* END_USER_CODE-USER_BEFORE_txtTotalSMKRScreen*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtTotalSMKRScreen}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtTotalSMKRScreen*/}

                      {/* END_USER_CODE-USER_AFTER_txtTotalSMKRScreen*/}
                      {/* START_USER_CODE-USER_BEFORE_txtTotalSMKRScreenPct*/}

                      {/* END_USER_CODE-USER_BEFORE_txtTotalSMKRScreenPct*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtTotalSMKRScreenPct}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtTotalSMKRScreenPct*/}

                      {/* END_USER_CODE-USER_AFTER_txtTotalSMKRScreenPct*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCrackedBroken*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCrackedBroken*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCrackedBroken}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCrackedBroken*/}

                      {/* END_USER_CODE-USER_AFTER_txtCrackedBroken*/}
                      {/* START_USER_CODE-USER_BEFORE_txtCrackedBrokenPct*/}

                      {/* END_USER_CODE-USER_BEFORE_txtCrackedBrokenPct*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtCrackedBrokenPct}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtCrackedBrokenPct*/}

                      {/* END_USER_CODE-USER_AFTER_txtCrackedBrokenPct*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSMK1Gr*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSMK1Gr*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSMK1Gr}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSMK1Gr*/}

                      {/* END_USER_CODE-USER_AFTER_txtSMK1Gr*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSMK1Gr2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSMK1Gr2*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSMK1Gr2}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSMK1Gr2*/}

                      {/* END_USER_CODE-USER_AFTER_txtSMK1Gr2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSMK1Pct*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSMK1Pct*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSMK1Pct}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSMK1Pct*/}

                      {/* END_USER_CODE-USER_AFTER_txtSMK1Pct*/}
                      {/* START_USER_CODE-USER_BEFORE_txtDiscoloredShells*/}

                      {/* END_USER_CODE-USER_BEFORE_txtDiscoloredShells*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtDiscoloredShells}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtDiscoloredShells*/}

                      {/* END_USER_CODE-USER_AFTER_txtDiscoloredShells*/}
                      {/* START_USER_CODE-USER_BEFORE_txtDiscoloredShellsPct*/}

                      {/* END_USER_CODE-USER_BEFORE_txtDiscoloredShellsPct*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtDiscoloredShellsPct}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtDiscoloredShellsPct*/}

                      {/* END_USER_CODE-USER_AFTER_txtDiscoloredShellsPct*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSMK2Gr*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSMK2Gr*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSMK2Gr}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSMK2Gr*/}

                      {/* END_USER_CODE-USER_AFTER_txtSMK2Gr*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSMK2Gr2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSMK2Gr2*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSMK2Gr2}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSMK2Gr2*/}

                      {/* END_USER_CODE-USER_AFTER_txtSMK2Gr2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSMK2Pct*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSMK2Pct*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSMK2Pct}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSMK2Pct*/}

                      {/* END_USER_CODE-USER_AFTER_txtSMK2Pct*/}
                      {/* START_USER_CODE-USER_BEFORE_txtHullBrightness*/}

                      {/* END_USER_CODE-USER_BEFORE_txtHullBrightness*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtHullBrightness}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtHullBrightness*/}

                      {/* END_USER_CODE-USER_AFTER_txtHullBrightness*/}
                      {/* START_USER_CODE-USER_BEFORE_txtHullBrightnessPct*/}

                      {/* END_USER_CODE-USER_BEFORE_txtHullBrightnessPct*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtHullBrightnessPct}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtHullBrightnessPct*/}

                      {/* END_USER_CODE-USER_AFTER_txtHullBrightnessPct*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSMK3Gr*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSMK3Gr*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSMK3Gr}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSMK3Gr*/}

                      {/* END_USER_CODE-USER_AFTER_txtSMK3Gr*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSMK3Gr2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSMK3Gr2*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSMK3Gr2}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSMK3Gr2*/}

                      {/* END_USER_CODE-USER_AFTER_txtSMK3Gr2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSMK3Pct*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSMK3Pct*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSMK3Pct}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSMK3Pct*/}

                      {/* END_USER_CODE-USER_AFTER_txtSMK3Pct*/}
                      {/* START_USER_CODE-USER_BEFORE_txtJumbo*/}

                      {/* END_USER_CODE-USER_BEFORE_txtJumbo*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtJumbo}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtJumbo*/}

                      {/* END_USER_CODE-USER_AFTER_txtJumbo*/}
                      {/* START_USER_CODE-USER_BEFORE_txtJumboGrPct*/}

                      {/* END_USER_CODE-USER_BEFORE_txtJumboGrPct*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtJumboGrPct}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtJumboGrPct*/}

                      {/* END_USER_CODE-USER_AFTER_txtJumboGrPct*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSMK4Gr*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSMK4Gr*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSMK4Gr}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSMK4Gr*/}

                      {/* END_USER_CODE-USER_AFTER_txtSMK4Gr*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSMK4Gr2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSMK4Gr2*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSMK4Gr2}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSMK4Gr2*/}

                      {/* END_USER_CODE-USER_AFTER_txtSMK4Gr2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSMK4Pct*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSMK4Pct*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSMK4Pct}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSMK4Pct*/}

                      {/* END_USER_CODE-USER_AFTER_txtSMK4Pct*/}
                      {/* START_USER_CODE-USER_BEFORE_txtKernRidingELK*/}

                      {/* END_USER_CODE-USER_BEFORE_txtKernRidingELK*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtKernRidingELK}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtKernRidingELK*/}

                      {/* END_USER_CODE-USER_AFTER_txtKernRidingELK*/}
                      {/* START_USER_CODE-USER_BEFORE_lblKernRidingELKPct*/}

                      {/* END_USER_CODE-USER_BEFORE_lblKernRidingELKPct*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.lblKernRidingELKPct}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_lblKernRidingELKPct*/}

                      {/* END_USER_CODE-USER_AFTER_lblKernRidingELKPct*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSoundSplits*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSoundSplits*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSoundSplits}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSoundSplits*/}

                      {/* END_USER_CODE-USER_AFTER_txtSoundSplits*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSoundSplitsPct*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSoundSplitsPct*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSoundSplitsPct}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSoundSplitsPct*/}

                      {/* END_USER_CODE-USER_AFTER_txtSoundSplitsPct*/}
                      {/* START_USER_CODE-USER_BEFORE_txtTotalELKDamage*/}

                      {/* END_USER_CODE-USER_BEFORE_txtTotalELKDamage*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtTotalELKDamage}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtTotalELKDamage*/}

                      {/* END_USER_CODE-USER_AFTER_txtTotalELKDamage*/}
                      {/* START_USER_CODE-USER_BEFORE_txtTotalELKDamagePct*/}

                      {/* END_USER_CODE-USER_BEFORE_txtTotalELKDamagePct*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtTotalELKDamagePct}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtTotalELKDamagePct*/}

                      {/* END_USER_CODE-USER_AFTER_txtTotalELKDamagePct*/}
                      {/* START_USER_CODE-USER_BEFORE_txtTotalSMK*/}

                      {/* END_USER_CODE-USER_BEFORE_txtTotalSMK*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtTotalSMK}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtTotalSMK*/}

                      {/* END_USER_CODE-USER_AFTER_txtTotalSMK*/}
                      {/* START_USER_CODE-USER_BEFORE_txtTotalSMK2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtTotalSMK2*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtTotalSMK2}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtTotalSMK2*/}

                      {/* END_USER_CODE-USER_AFTER_txtTotalSMK2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtNetELK*/}

                      {/* END_USER_CODE-USER_BEFORE_txtNetELK*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtNetELK}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtNetELK*/}

                      {/* END_USER_CODE-USER_AFTER_txtNetELK*/}
                      {/* START_USER_CODE-USER_BEFORE_txtNetELKPct*/}

                      {/* END_USER_CODE-USER_BEFORE_txtNetELKPct*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtNetELKPct}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtNetELKPct*/}

                      {/* END_USER_CODE-USER_AFTER_txtNetELKPct*/}
                      {/* START_USER_CODE-USER_BEFORE_txtTotalOK*/}

                      {/* END_USER_CODE-USER_BEFORE_txtTotalOK*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtTotalOK}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtTotalOK*/}

                      {/* END_USER_CODE-USER_AFTER_txtTotalOK*/}
                      {/* START_USER_CODE-USER_BEFORE_txtTotalOKPct*/}

                      {/* END_USER_CODE-USER_BEFORE_txtTotalOKPct*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtTotalOKPct}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtTotalOKPct*/}

                      {/* END_USER_CODE-USER_AFTER_txtTotalOKPct*/}
                      {/* START_USER_CODE-USER_BEFORE_txtKernRidingPrescribe*/}

                      {/* END_USER_CODE-USER_BEFORE_txtKernRidingPrescribe*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtKernRidingPrescribe}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtKernRidingPrescribe*/}

                      {/* END_USER_CODE-USER_AFTER_txtKernRidingPrescribe*/}
                      {/* START_USER_CODE-USER_BEFORE_txtKernRidingPrescribePct*/}

                      {/* END_USER_CODE-USER_BEFORE_txtKernRidingPrescribePct*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtKernRidingPrescribePct}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtKernRidingPrescribePct*/}

                      {/* END_USER_CODE-USER_AFTER_txtKernRidingPrescribePct*/}
                      {/* START_USER_CODE-USER_BEFORE_txtOKRidingGr*/}

                      {/* END_USER_CODE-USER_BEFORE_txtOKRidingGr*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtOKRidingGr}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtOKRidingGr*/}

                      {/* END_USER_CODE-USER_AFTER_txtOKRidingGr*/}
                      {/* START_USER_CODE-USER_BEFORE_txtOKRidingGr2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtOKRidingGr2*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtOKRidingGr2}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtOKRidingGr2*/}

                      {/* END_USER_CODE-USER_AFTER_txtOKRidingGr2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtOKRidingPct*/}

                      {/* END_USER_CODE-USER_BEFORE_txtOKRidingPct*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtOKRidingPct}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtOKRidingPct*/}

                      {/* END_USER_CODE-USER_AFTER_txtOKRidingPct*/}
                      {/* START_USER_CODE-USER_BEFORE_txtForeignMtrl*/}

                      {/* END_USER_CODE-USER_BEFORE_txtForeignMtrl*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtForeignMtrl}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtForeignMtrl*/}

                      {/* END_USER_CODE-USER_AFTER_txtForeignMtrl*/}
                      {/* START_USER_CODE-USER_BEFORE_txtForeignMaterialPct*/}

                      {/* END_USER_CODE-USER_BEFORE_txtForeignMaterialPct*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtForeignMaterialPct}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtForeignMaterialPct*/}

                      {/* END_USER_CODE-USER_AFTER_txtForeignMaterialPct*/}
                      {/* START_USER_CODE-USER_BEFORE_txtOKFallThru*/}

                      {/* END_USER_CODE-USER_BEFORE_txtOKFallThru*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtOKFallThru}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtOKFallThru*/}

                      {/* END_USER_CODE-USER_AFTER_txtOKFallThru*/}
                      {/* START_USER_CODE-USER_BEFORE_txtOKFallThru2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtOKFallThru2*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtOKFallThru2}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtOKFallThru2*/}

                      {/* END_USER_CODE-USER_AFTER_txtOKFallThru2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtOtherFM*/}

                      {/* END_USER_CODE-USER_BEFORE_txtOtherFM*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtOtherFM}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtOtherFM*/}

                      {/* END_USER_CODE-USER_AFTER_txtOtherFM*/}
                      {/* START_USER_CODE-USER_BEFORE_txtOKDamageRiding*/}

                      {/* END_USER_CODE-USER_BEFORE_txtOKDamageRiding*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtOKDamageRiding}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtOKDamageRiding*/}

                      {/* END_USER_CODE-USER_AFTER_txtOKDamageRiding*/}
                      {/* START_USER_CODE-USER_BEFORE_txtOKDamageRiding2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtOKDamageRiding2*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtOKDamageRiding2}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtOKDamageRiding2*/}

                      {/* END_USER_CODE-USER_AFTER_txtOKDamageRiding2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtTotalSLK*/}

                      {/* END_USER_CODE-USER_BEFORE_txtTotalSLK*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtTotalSLK}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtTotalSLK*/}

                      {/* END_USER_CODE-USER_AFTER_txtTotalSLK*/}
                      {/* START_USER_CODE-USER_BEFORE_txtTotalSLKPct*/}

                      {/* END_USER_CODE-USER_BEFORE_txtTotalSLKPct*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtTotalSLKPct}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtTotalSLKPct*/}

                      {/* END_USER_CODE-USER_AFTER_txtTotalSLKPct*/}
                      {/* START_USER_CODE-USER_BEFORE_txtDamageSplits*/}

                      {/* END_USER_CODE-USER_BEFORE_txtDamageSplits*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtDamageSplits}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtDamageSplits*/}

                      {/* END_USER_CODE-USER_AFTER_txtDamageSplits*/}
                      {/* START_USER_CODE-USER_BEFORE_txtDamageSplits2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtDamageSplits2*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtDamageSplits2}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtDamageSplits2*/}

                      {/* END_USER_CODE-USER_AFTER_txtDamageSplits2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtLSKRidingGr*/}

                      {/* END_USER_CODE-USER_BEFORE_txtLSKRidingGr*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtLSKRidingGr}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtLSKRidingGr*/}

                      {/* END_USER_CODE-USER_AFTER_txtLSKRidingGr*/}
                      {/* START_USER_CODE-USER_BEFORE_txtLSKRidingGr2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtLSKRidingGr2*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtLSKRidingGr2}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtLSKRidingGr2*/}

                      {/* END_USER_CODE-USER_AFTER_txtLSKRidingGr2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtLSKRidingPct*/}

                      {/* END_USER_CODE-USER_BEFORE_txtLSKRidingPct*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtLSKRidingPct}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtLSKRidingPct*/}

                      {/* END_USER_CODE-USER_AFTER_txtLSKRidingPct*/}
                      {/* START_USER_CODE-USER_BEFORE_txtTotalDamage*/}

                      {/* END_USER_CODE-USER_BEFORE_txtTotalDamage*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtTotalDamage}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtTotalDamage*/}

                      {/* END_USER_CODE-USER_AFTER_txtTotalDamage*/}
                      {/* START_USER_CODE-USER_BEFORE_txtTotalDamagePct*/}

                      {/* END_USER_CODE-USER_BEFORE_txtTotalDamagePct*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtTotalDamagePct}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtTotalDamagePct*/}

                      {/* END_USER_CODE-USER_AFTER_txtTotalDamagePct*/}
                      {/* START_USER_CODE-USER_BEFORE_txtLSKFallThru*/}

                      {/* END_USER_CODE-USER_BEFORE_txtLSKFallThru*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtLSKFallThru}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtLSKFallThru*/}

                      {/* END_USER_CODE-USER_AFTER_txtLSKFallThru*/}
                      {/* START_USER_CODE-USER_BEFORE_txtLSKFallThru2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtLSKFallThru2*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtLSKFallThru2}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtLSKFallThru2*/}

                      {/* END_USER_CODE-USER_AFTER_txtLSKFallThru2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtTotalKernels*/}

                      {/* END_USER_CODE-USER_BEFORE_txtTotalKernels*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtTotalKernels}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtTotalKernels*/}

                      {/* END_USER_CODE-USER_AFTER_txtTotalKernels*/}
                      {/* START_USER_CODE-USER_BEFORE_txtTotalKernels2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtTotalKernels2*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtTotalKernels2}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtTotalKernels2*/}

                      {/* END_USER_CODE-USER_AFTER_txtTotalKernels2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtLSKDamageRiding*/}

                      {/* END_USER_CODE-USER_BEFORE_txtLSKDamageRiding*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtLSKDamageRiding}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtLSKDamageRiding*/}

                      {/* END_USER_CODE-USER_AFTER_txtLSKDamageRiding*/}
                      {/* START_USER_CODE-USER_BEFORE_txtLSKDamageRiding2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtLSKDamageRiding2*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtLSKDamageRiding2}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtLSKDamageRiding2*/}

                      {/* END_USER_CODE-USER_AFTER_txtLSKDamageRiding2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtHulls*/}

                      {/* END_USER_CODE-USER_BEFORE_txtHulls*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtHulls}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtHulls*/}

                      {/* END_USER_CODE-USER_AFTER_txtHulls*/}
                      {/* START_USER_CODE-USER_BEFORE_txtHullsPct*/}

                      {/* END_USER_CODE-USER_BEFORE_txtHullsPct*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtHullsPct}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtHullsPct*/}

                      {/* END_USER_CODE-USER_AFTER_txtHullsPct*/}
                      {/* START_USER_CODE-USER_BEFORE_txtMoisture*/}

                      {/* END_USER_CODE-USER_BEFORE_txtMoisture*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtMoisture}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtMoisture*/}

                      {/* END_USER_CODE-USER_AFTER_txtMoisture*/}
                      {/* START_USER_CODE-USER_BEFORE_txtMoisture2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtMoisture2*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtMoisture2}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtMoisture2*/}

                      {/* END_USER_CODE-USER_AFTER_txtMoisture2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtTotalKernelsAndHulls*/}

                      {/* END_USER_CODE-USER_BEFORE_txtTotalKernelsAndHulls*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtTotalKernelsAndHulls}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtTotalKernelsAndHulls*/}

                      {/* END_USER_CODE-USER_AFTER_txtTotalKernelsAndHulls*/}
                      {/* START_USER_CODE-USER_BEFORE_txtTotalKernelsAndHulls2*/}

                      {/* END_USER_CODE-USER_BEFORE_txtTotalKernelsAndHulls2*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtTotalKernelsAndHulls2}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtTotalKernelsAndHulls2*/}

                      {/* END_USER_CODE-USER_AFTER_txtTotalKernelsAndHulls2*/}
                      {/* START_USER_CODE-USER_BEFORE_txtFancy*/}

                      {/* END_USER_CODE-USER_BEFORE_txtFancy*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtFancy}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtFancy*/}

                      {/* END_USER_CODE-USER_AFTER_txtFancy*/}
                      {/* START_USER_CODE-USER_BEFORE_txtFancyPct*/}

                      {/* END_USER_CODE-USER_BEFORE_txtFancyPct*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtFancyPct}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtFancyPct*/}

                      {/* END_USER_CODE-USER_AFTER_txtFancyPct*/}
                      {/* START_USER_CODE-USER_BEFORE_grpbxDamage*/}

                      {/* END_USER_CODE-USER_BEFORE_grpbxDamage*/}

                      <GroupBoxWidget
                        conf={state.grpbxDamage}
                        screenConf={state}
                      >
                        {/* START_USER_CODE-USER_BEFORE_txtFreezeDamage*/}

                        {/* END_USER_CODE-USER_BEFORE_txtFreezeDamage*/}

                        <TextBoxWidget
                          onChange={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtFreezeDamage}
                          screenConf={state}
                          onPaste={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtFreezeDamage*/}

                        {/* END_USER_CODE-USER_AFTER_txtFreezeDamage*/}
                        {/* START_USER_CODE-USER_BEFORE_txtFreezeDamagePct*/}

                        {/* END_USER_CODE-USER_BEFORE_txtFreezeDamagePct*/}

                        <TextBoxWidget
                          onChange={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtFreezeDamagePct}
                          screenConf={state}
                          onPaste={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtFreezeDamagePct*/}

                        {/* END_USER_CODE-USER_AFTER_txtFreezeDamagePct*/}
                        {/* START_USER_CODE-USER_BEFORE_txtPittingDamage*/}

                        {/* END_USER_CODE-USER_BEFORE_txtPittingDamage*/}

                        <TextBoxWidget
                          onChange={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtPittingDamage}
                          screenConf={state}
                          onPaste={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtPittingDamage*/}

                        {/* END_USER_CODE-USER_AFTER_txtPittingDamage*/}
                        {/* START_USER_CODE-USER_BEFORE_txtPittingDamagePct*/}

                        {/* END_USER_CODE-USER_BEFORE_txtPittingDamagePct*/}

                        <TextBoxWidget
                          onChange={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtPittingDamagePct}
                          screenConf={state}
                          onPaste={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtPittingDamagePct*/}

                        {/* END_USER_CODE-USER_AFTER_txtPittingDamagePct*/}
                        {/* START_USER_CODE-USER_BEFORE_txtConcealedRMD*/}

                        {/* END_USER_CODE-USER_BEFORE_txtConcealedRMD*/}

                        <TextBoxWidget
                          onChange={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtConcealedRMD}
                          screenConf={state}
                          onPaste={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtConcealedRMD*/}

                        {/* END_USER_CODE-USER_AFTER_txtConcealedRMD*/}
                        {/* START_USER_CODE-USER_BEFORE_txtConcealedRMDPct*/}

                        {/* END_USER_CODE-USER_BEFORE_txtConcealedRMDPct*/}

                        <TextBoxWidget
                          onChange={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtConcealedRMDPct}
                          screenConf={state}
                          onPaste={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtConcealedRMDPct*/}

                        {/* END_USER_CODE-USER_AFTER_txtConcealedRMDPct*/}
                      </GroupBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_grpbxDamage*/}

                      {/* END_USER_CODE-USER_AFTER_grpbxDamage*/}
                      {/* START_USER_CODE-USER_BEFORE_grpbxCheckbxViacam*/}

                      {/* END_USER_CODE-USER_BEFORE_grpbxCheckbxViacam*/}

                      <GroupBoxWidget
                        conf={state.grpbxCheckbxViacam}
                        screenConf={state}
                      >
                        {/* START_USER_CODE-USER_BEFORE_chkboxReGrading*/}

                        {/* END_USER_CODE-USER_BEFORE_chkboxReGrading*/}
                        <CheckboxWidget
                          onChange={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.chkboxFlavus}
                          screenConf={state}
                        ></CheckboxWidget>
                        <CheckboxWidget
                          onChange={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.chkboxVicam}
                          screenConf={state}
                        ></CheckboxWidget>
                        {/* <CheckboxGroupWidget
                          onChange={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.chkboxReGrading}
                          screenConf={state}
                        ></CheckboxGroupWidget> */}
                        {/* START_USER_CODE-USER_AFTER_chkboxReGrading*/}

                        {/* END_USER_CODE-USER_AFTER_chkboxReGrading*/}
                        {/* START_USER_CODE-USER_BEFORE_txtVicamReading*/}

                        {/* END_USER_CODE-USER_BEFORE_txtVicamReading*/}

                        <TextBoxWidget
                          onChange={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          onBlur={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                          values={values}
                          touched={touched}
                          errors={errors}
                          conf={state.txtVicamReading}
                          screenConf={state}
                          onPaste={(event) =>
                            invokeEventHandler(
                              event,
                              handleChange,
                              handleBlur,
                              thisObj
                            )
                          }
                        ></TextBoxWidget>
                        {/* START_USER_CODE-USER_AFTER_txtVicamReading*/}

                        {/* END_USER_CODE-USER_AFTER_txtVicamReading*/}
                      </GroupBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_grpbxCheckbxViacam*/}

                      {/* END_USER_CODE-USER_AFTER_grpbxCheckbxViacam*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxReGrading*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxReGrading*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxRegrd*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxRegrd*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxSettlementFreight*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxSettlementFreight*/}

                  <GroupBoxWidget
                    conf={state.grpbxSettlementFreight}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_grpbxSettlement*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxSettlement*/}

                    <GroupBoxWidget
                      conf={state.grpbxSettlement}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_lblSettlementInfo*/}

                      {/* END_USER_CODE-USER_BEFORE_lblSettlementInfo*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblSettlementInfo}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblSettlementInfo*/}

                      {/* END_USER_CODE-USER_AFTER_lblSettlementInfo*/}
                      {/* START_USER_CODE-USER_BEFORE_txtGrossWt*/}

                      {/* END_USER_CODE-USER_BEFORE_txtGrossWt*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtGrossWt}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtGrossWt*/}

                      {/* END_USER_CODE-USER_AFTER_txtGrossWt*/}
                      {/* START_USER_CODE-USER_BEFORE_lblGrossWt*/}

                      {/* END_USER_CODE-USER_BEFORE_lblGrossWt*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblGrossWt}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblGrossWt*/}

                      {/* END_USER_CODE-USER_AFTER_lblGrossWt*/}
                      {/* START_USER_CODE-USER_BEFORE_txtFm*/}

                      {/* END_USER_CODE-USER_BEFORE_txtFm*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtFm}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtFm*/}

                      {/* END_USER_CODE-USER_AFTER_txtFm*/}
                      {/* START_USER_CODE-USER_BEFORE_lblFm*/}

                      {/* END_USER_CODE-USER_BEFORE_lblFm*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblFm}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblFm*/}

                      {/* END_USER_CODE-USER_AFTER_lblFm*/}
                      {/* START_USER_CODE-USER_BEFORE_txtWtLessFm*/}

                      {/* END_USER_CODE-USER_BEFORE_txtWtLessFm*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtWtLessFm}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtWtLessFm*/}

                      {/* END_USER_CODE-USER_AFTER_txtWtLessFm*/}
                      {/* START_USER_CODE-USER_BEFORE_lblLessFm*/}

                      {/* END_USER_CODE-USER_BEFORE_lblLessFm*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblLessFm}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblLessFm*/}

                      {/* END_USER_CODE-USER_AFTER_lblLessFm*/}
                      {/* START_USER_CODE-USER_BEFORE_txtExcMoisture*/}

                      {/* END_USER_CODE-USER_BEFORE_txtExcMoisture*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtExcMoisture}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtExcMoisture*/}

                      {/* END_USER_CODE-USER_AFTER_txtExcMoisture*/}
                      {/* START_USER_CODE-USER_BEFORE_lblMoistr*/}

                      {/* END_USER_CODE-USER_BEFORE_lblMoistr*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblMoistr}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblMoistr*/}

                      {/* END_USER_CODE-USER_AFTER_lblMoistr*/}
                      {/* START_USER_CODE-USER_BEFORE_txtNetWt*/}

                      {/* END_USER_CODE-USER_BEFORE_txtNetWt*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtNetWt}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtNetWt*/}

                      {/* END_USER_CODE-USER_AFTER_txtNetWt*/}
                      {/* START_USER_CODE-USER_BEFORE_lblNetwt*/}

                      {/* END_USER_CODE-USER_BEFORE_lblNetwt*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblNetwt}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblNetwt*/}

                      {/* END_USER_CODE-USER_AFTER_lblNetwt*/}
                      {/* START_USER_CODE-USER_BEFORE_txtLsk*/}

                      {/* END_USER_CODE-USER_BEFORE_txtLsk*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtLsk}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtLsk*/}

                      {/* END_USER_CODE-USER_AFTER_txtLsk*/}
                      {/* START_USER_CODE-USER_BEFORE_lblLsk*/}

                      {/* END_USER_CODE-USER_BEFORE_lblLsk*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblLsk}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblLsk*/}

                      {/* END_USER_CODE-USER_AFTER_lblLsk*/}
                      {/* START_USER_CODE-USER_BEFORE_txtNetLessLsk*/}

                      {/* END_USER_CODE-USER_BEFORE_txtNetLessLsk*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtNetLessLsk}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtNetLessLsk*/}

                      {/* END_USER_CODE-USER_AFTER_txtNetLessLsk*/}
                      {/* START_USER_CODE-USER_BEFORE_lblLessLsk*/}

                      {/* END_USER_CODE-USER_BEFORE_lblLessLsk*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblLessLsk}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblLessLsk*/}

                      {/* END_USER_CODE-USER_AFTER_lblLessLsk*/}
                      {/* START_USER_CODE-USER_BEFORE_btnDollarVal*/}

                      {/* END_USER_CODE-USER_BEFORE_btnDollarVal*/}

                      <ButtonWidget
                        conf={state.btnDollarVal}
                        screenConf={state}
                        onClick={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btnDollarVal*/}

                      {/* END_USER_CODE-USER_AFTER_btnDollarVal*/}
                      {/* START_USER_CODE-USER_BEFORE_lblDollar*/}

                      {/* END_USER_CODE-USER_BEFORE_lblDollar*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.lblDollar}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_lblDollar*/}

                      {/* END_USER_CODE-USER_AFTER_lblDollar*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxSettlement*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxSettlement*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxFreightAcc*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxFreightAcc*/}

                    <GroupBoxWidget
                      conf={state.grpbxFreightAcc}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_lblFrghtAccrl*/}

                      {/* END_USER_CODE-USER_BEFORE_lblFrghtAccrl*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblFrghtAccrl}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblFrghtAccrl*/}

                      {/* END_USER_CODE-USER_AFTER_lblFrghtAccrl*/}
                      {/* START_USER_CODE-USER_BEFORE_txtFreightRate*/}

                      {/* END_USER_CODE-USER_BEFORE_txtFreightRate*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtFreightRate}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtFreightRate*/}

                      {/* END_USER_CODE-USER_AFTER_txtFreightRate*/}
                      {/* START_USER_CODE-USER_BEFORE_lblPerTon*/}

                      {/* END_USER_CODE-USER_BEFORE_lblPerTon*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblPerTon}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblPerTon*/}

                      {/* END_USER_CODE-USER_AFTER_lblPerTon*/}
                      {/* START_USER_CODE-USER_BEFORE_txtFrghtAm*/}

                      {/* END_USER_CODE-USER_BEFORE_txtFrghtAm*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtFrghtAm}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtFrghtAm*/}

                      {/* END_USER_CODE-USER_AFTER_txtFrghtAm*/}
                      {/* START_USER_CODE-USER_BEFORE_txtMileage*/}

                      {/* END_USER_CODE-USER_BEFORE_txtMileage*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtMileage}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtMileage*/}

                      {/* END_USER_CODE-USER_AFTER_txtMileage*/}
                      {/* START_USER_CODE-USER_BEFORE_txtWt*/}

                      {/* END_USER_CODE-USER_BEFORE_txtWt*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtWt}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtWt*/}

                      {/* END_USER_CODE-USER_AFTER_txtWt*/}
                      {/* START_USER_CODE-USER_BEFORE_txtPremAmnt*/}

                      {/* END_USER_CODE-USER_BEFORE_txtPremAmnt*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtPremAmnt}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtPremAmnt*/}

                      {/* END_USER_CODE-USER_AFTER_txtPremAmnt*/}
                      {/* START_USER_CODE-USER_BEFORE_txtDeductnAmt*/}

                      {/* END_USER_CODE-USER_BEFORE_txtDeductnAmt*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtDeductnAmt}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtDeductnAmt*/}

                      {/* END_USER_CODE-USER_AFTER_txtDeductnAmt*/}
                      {/* START_USER_CODE-USER_BEFORE_txtProceedAmt*/}

                      {/* END_USER_CODE-USER_BEFORE_txtProceedAmt*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtProceedAmt}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtProceedAmt*/}

                      {/* END_USER_CODE-USER_AFTER_txtProceedAmt*/}
                    </GroupBoxWidget>

                    <GroupBoxWidget
                      conf={state.grpbxMultiStop}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_lblFrghtAccrl*/}

                      {/* END_USER_CODE-USER_BEFORE_lblFrghtAccrl*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblMultiStop}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblFrghtAccrl*/}

                      {/* END_USER_CODE-USER_AFTER_lblFrghtAccrl*/}

                      {/* START_USER_CODE-USER_BEFORE_txtMileage*/}

                      {/* END_USER_CODE-USER_BEFORE_txtMileage*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtTripId}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtMileage*/}

                      {/* END_USER_CODE-USER_AFTER_txtMileage*/}
                      {/* START_USER_CODE-USER_BEFORE_txtWt*/}

                      {/* END_USER_CODE-USER_BEFORE_txtWt*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtLeg}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtWt*/}

                      {/* END_USER_CODE-USER_AFTER_txtWt*/}
                      {/* START_USER_CODE-USER_BEFORE_txtPremAmnt*/}

                      {/* END_USER_CODE-USER_BEFORE_txtPremAmnt*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtLegOrg}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtPremAmnt*/}

                      {/* END_USER_CODE-USER_AFTER_txtPremAmnt*/}
                      {/* START_USER_CODE-USER_BEFORE_txtDeductnAmt*/}

                      {/* END_USER_CODE-USER_BEFORE_txtDeductnAmt*/}

                      <TextBoxWidget
                        onChange={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtLegDest}
                        screenConf={state}
                        onPaste={(event) =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtDeductnAmt*/}

                      {/* END_USER_CODE-USER_AFTER_txtDeductnAmt*/}
                      {/* START_USER_CODE-USER_BEFORE_txtProceedAmt*/}

                      {/* END_USER_CODE-USER_BEFORE_txtProceedAmt*/}

                      {/* START_USER_CODE-USER_AFTER_txtProceedAmt*/}

                      {/* END_USER_CODE-USER_AFTER_txtProceedAmt*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxFreightAcc*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxFreightAcc*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxSettlementFreight*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxSettlementFreight*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxRegrdSettlement*/}

                {/* END_USER_CODE-USER_AFTER_grpbxRegrdSettlement*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxRemrks*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxRemrks*/}

                <GroupBoxWidget conf={state.grpbxRemrks} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtarRemarks*/}

                  {/* END_USER_CODE-USER_BEFORE_txtarRemarks*/}

                  <TextAreaWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtarRemarks}
                    screenConf={state}
                  ></TextAreaWidget>
                  {/* START_USER_CODE-USER_AFTER_txtarRemarks*/}

                  {/* END_USER_CODE-USER_AFTER_txtarRemarks*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxRemrks*/}

                {/* END_USER_CODE-USER_AFTER_grpbxRemrks*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxStockTransfer*/}

              {/* END_USER_CODE-USER_AFTER_grpbxStockTransfer*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img src={StripPic} className="stripDesign" />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_grpbxEditInfo*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxEditInfo*/}

                <GroupBoxWidget conf={state.grpbxEditInfo} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtAddedBy*/}

                  {/* END_USER_CODE-USER_BEFORE_txtAddedBy*/}

                  <TextBoxWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtAddedBy}
                    screenConf={state}
                    onPaste={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtAddedBy*/}

                  {/* END_USER_CODE-USER_AFTER_txtAddedBy*/}
                  {/* START_USER_CODE-USER_BEFORE_lblAddedDate*/}

                  {/* END_USER_CODE-USER_BEFORE_lblAddedDate*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblAddedDate}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblAddedDate*/}

                  {/* END_USER_CODE-USER_AFTER_lblAddedDate*/}
                  {/* START_USER_CODE-USER_BEFORE_txtChngdBy*/}

                  {/* END_USER_CODE-USER_BEFORE_txtChngdBy*/}

                  <TextBoxWidget
                    onChange={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtChngdBy}
                    screenConf={state}
                    onPaste={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtChngdBy*/}

                  {/* END_USER_CODE-USER_AFTER_txtChngdBy*/}
                  {/* START_USER_CODE-USER_BEFORE_lblChngdDate*/}

                  {/* END_USER_CODE-USER_BEFORE_lblChngdDate*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblChngdDate}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblChngdDate*/}

                  {/* END_USER_CODE-USER_AFTER_lblChngdDate*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxEditInfo*/}

                {/* END_USER_CODE-USER_AFTER_grpbxEditInfo*/}
                {/* START_USER_CODE-USER_BEFORE_btnVoid*/}

                {/* END_USER_CODE-USER_BEFORE_btnVoid*/}

                <ButtonWidget
                  conf={state.btnVoid}
                  screenConf={state}
                  onClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVoid*/}

                {/* END_USER_CODE-USER_AFTER_btnVoid*/}
                {/* START_USER_CODE-USER_BEFORE_btnFreightAcc*/}

                {/* END_USER_CODE-USER_BEFORE_btnFreightAcc*/}

                <ButtonWidget
                  conf={state.btnFreightAcc}
                  screenConf={state}
                  onClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnFreightAcc*/}

                {/* END_USER_CODE-USER_AFTER_btnFreightAcc*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
                {/* START_USER_CODE-USER_BEFORE_btnSave*/}

                {/* END_USER_CODE-USER_BEFORE_btnSave*/}

                <ButtonWidget
                  conf={state.btnSave}
                  screenConf={state}
                  onClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSave*/}

                {/* END_USER_CODE-USER_AFTER_btnSave*/}
                {/* START_USER_CODE-USER_BEFORE_btnNewTrnsfr*/}

                {/* END_USER_CODE-USER_BEFORE_btnNewTrnsfr*/}

                <ButtonWidget
                  conf={state.btnNewTrnsfr}
                  screenConf={state}
                  onClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnNewTrnsfr*/}

                {/* END_USER_CODE-USER_AFTER_btnNewTrnsfr*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxBtns*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxBtns*/}

                <GroupBoxWidget conf={state.grpbxBtns} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_btnPremDeduct*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPremDeduct*/}

                  <ButtonWidget
                    conf={state.btnPremDeduct}
                    screenConf={state}
                    onClick={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPremDeduct*/}

                  {/* END_USER_CODE-USER_AFTER_btnPremDeduct*/}
                  {/* START_USER_CODE-USER_BEFORE_btnPayment*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPayment*/}

                  <ButtonWidget
                    conf={state.btnPayment}
                    screenConf={state}
                    onClick={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPayment*/}

                  {/* END_USER_CODE-USER_AFTER_btnPayment*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAcctDist*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAcctDist*/}

                  <ButtonWidget
                    conf={state.btnAcctDist}
                    screenConf={state}
                    onClick={(event) =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAcctDist*/}

                  {/* END_USER_CODE-USER_AFTER_btnAcctDist*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxBtns*/}

                {/* END_USER_CODE-USER_AFTER_grpbxBtns*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={(event) =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_StockTransfer*/}

              {/* END_USER_CODE-USER_AFTER_StockTransfer*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(StockTransfer_StockTransfer);
